import { useEffect, useMemo, useState } from "react";
import { getDataSDK, getDataSDKResponse } from "../helpers/backendHelper";
import ILanguage from "@vibe/sdk/dist/interfaces/Admin/languages/language/language";
import { sortAlphabeticallyOnProperty } from "../containers/Signup/SignupHealerInd";

const useLanguagesSA = ({
    SearchTerm
}:{
    SearchTerm: string
}) => {
    const [languages, setLanguages] = useState<ILanguage[]>([]);
    const [langFiltered, setLangFiltered] = useState<ILanguage[]>([]);
    const searchParsed = useMemo(() => SearchTerm.toLowerCase(), [SearchTerm]);

    const reloadLanguages = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Admin.getAllLanguages(dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) {
                    setLanguages(results.body as ILanguage[]);
                }
                else{
                    setLanguages([]);
                }
            });
    };

    useEffect(() => {
        if(!SearchTerm || SearchTerm === ''){
            setLangFiltered(languages);
            return;
        }
        setLangFiltered(
            (sortAlphabeticallyOnProperty(languages, 'engName') as ILanguage[])
                .filter(l => l.engName.toLowerCase().includes(searchParsed))
        );
    }, [SearchTerm, languages]);

    useEffect(() => {
        reloadLanguages();
    }, []);

    return { 
        langFiltered, 
        reloadLanguages 
    };
};

export default useLanguagesSA;
