import "./styles.scoped.css";
import React, { useContext, useEffect, useState } from "react";
import { toast } from 'react-toastify';
import Posts from "../../containers/Posts"
import { useDispatch, useSelector } from "react-redux";
import { getMyClubs } from "../../store/actions/clubActions";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store/store";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";
import PostsNew, { postTypeNoParam } from "../Posts/PostsNew";
import styled from "styled-components";
import { tabInterface } from "../../components/FeedCenterSection";
import { ContextSuccess } from "../../Context";

toast.configure();

function Index({ ChatServiceInstance }: { ChatServiceInstance: ChatServiceInterface }) {
    const currentSuccess = useContext(ContextSuccess);
    
    const [tabActive, setTabActive] = useState<number>(0);
    const [typePosts, setTypePosts] = useState<postTypeNoParam>('VIBES');

    const acceptInviteTry = useSelector((state: RootState) => state.getInvites.acceptInviteTry);
    const acceptInviteError = useSelector((state: RootState) => state.getInvites.acceptInviteError);
    const loadingAcceptInvite = useSelector((state: RootState) => state.getInvites.loadingAcceptInvite);
    useEffect(() => {
        if (acceptInviteTry && !loadingAcceptInvite) {
            if (acceptInviteError) {
                console.log({ acceptInviteError });
                currentSuccess?.setSuccessData(false, true, "Ops!", "Error accepting invite");
            }
            else {
                currentSuccess?.setSuccessData(true, true, "Invite accepted successfully!");
            }
        }
    }, [acceptInviteTry, acceptInviteError, loadingAcceptInvite])

    useEffect(() => {
        if (tabActive === 0) {
            setTypePosts('VIBES');
            return;
        }
        if (tabActive === 1) {
            setTypePosts('JOURNEY');
            return;
        }
        if (tabActive === 2) {
            setTypePosts('FRIENDS');
            return;
        }
        if (tabActive === 3) {
            setTypePosts('SAVED');
            return;
        }
    }, [tabActive])

    const tabsBull: tabInterface[] = [
        {
            id: 0,
            label: 'Vibes',
            comingSoon: false,
        },
        // {
        //     id: 1,
        //     label: 'My Journey',
        //     comingSoon: false,
        // },
        {
            id: 2,
            label: "Friends' Vibes",
            comingSoon: false,
        },
        {
            id: 3,
            label: 'Pinned Vibes',
            comingSoon: false,
        }
    ]

    return (
        <CenterInsideWrap>
            <TabsCenterInside>
                {tabsBull.map(t => (
                    <TabCenterInside
                        active={tabActive === t.id}
                        // theme={{ main: tabActive === t.id ? 'active' : 'inactive' }}
                        onClick={() => setTabActive(t.id)}
                    >
                        {t.label}
                    </TabCenterInside>
                ))}
            </TabsCenterInside>
            <PostsNew postRequired={typePosts} />
        </CenterInsideWrap>
    );
}

export default Index;

export const CenterInsideWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 10px;
`;

export const TabsCenterInside = styled.div`
    background-color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: 'Inter';
    border-radius: 8px;
    gap: 10px;
    width: 100%;
`;

export const TabCenterInside = styled.div`
    cursor: pointer;
    padding: 10px 0;
    width: 100%;

    background-color: ${({active = false}:{active?:boolean}) => active? "var(--t-blue)": "transparent"};
    color: ${({active = false}:{active?:boolean}) => active? "white": "var(--t-blue)"};

    :first-child {
        border-radius: 8px 0px 0px 8px;
    }
    :last-child {
        border-radius: 0px 8px 8px 0px;
    }
`;

export const TabsCenterOverlap = styled.div`
    display: flex;
    padding: 10px 0;
    gap: 12px;
    z-index: 3027;
    position: absolute;
    top: -35px;
`;

export const TabCenterOverlap = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    
    padding: 2px 0; 
    font-size: 16px;  
    color: rgb(206, 190, 173);
    cursor: pointer;
    position: relative;

    &.active{
        font-family: 'Inter-Bold';
        border-bottom: 3px solid var(--t-blue);
        color: var(--t-blue);
    }

    &.inactive{
        font-family: 'Inter';
        border-bottom: 0px;
    }
`