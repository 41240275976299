import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { scroller, Element } from 'react-scroll';
import { ScrollCarrouselLeft, ClickerControlCarrouselLeft, ContainerCarrouselLeft } from './styles';

interface CarrouselLeftProps<T> {
    defaultCard?: number;
    renderCard: (t: T) => React.ReactNode,
    elements: T[],
    widthCarrousel: string;
    idCarrousel: string;
}

const CarrouselLeft = <T,>({
    defaultCard = 0,
    elements,
    renderCard,
    widthCarrousel,
    idCarrousel,
}: CarrouselLeftProps<T>) => {
    const [currentCard, setCurrentCard] = useState<number>(defaultCard);

    useEffect(() => {
        scroller.scrollTo(`${idCarrousel}-0`, { smooth: true, horizontal: true, containerId: idCarrousel })
    }, [])

    useEffect(() => {
        scroller.scrollTo(`${idCarrousel}-${currentCard}`, { smooth: true, horizontal: true, containerId: idCarrousel })
    }, [currentCard]);

    const nextCard = () => {
        setCurrentCard((currentCard + 1) % elements.length);
    }

    const prevCard = () => {
        if (currentCard === 0) {
            setCurrentCard(elements.length - 1);
            return;
        }
        setCurrentCard((currentCard - 1) % elements.length);
    }

    return (
        <ScrollCarrouselLeft id={idCarrousel} width={widthCarrousel} theme={{ main: 'Cnt'+idCarrousel.replace(" ", '')}}>
            <ContainerCarrouselLeft>
                {elements.map((el, idx) => (
                    <Element name={`${idCarrousel}-${idx}`}>
                        {renderCard(el)}
                    </Element>
                ))}
            </ContainerCarrouselLeft>
            { elements.length > 0 && <ClickerControlCarrouselLeft onClick={nextCard}>
                <FontAwesomeIcon
                    icon="chevron-right"
                    color='#4A545E'
                    size='xs'
                />
            </ClickerControlCarrouselLeft>}
        </ScrollCarrouselLeft>
    )
}

export default CarrouselLeft;