export const tabsIndividual = [
    {
        id: 101,
        label: 'General',
        active: true
    },
    {
        id: 102,
        label: 'Password',
        active: false
    },
    {
        id: 107,
        label: 'Payment Methods',
        active: 'false'
    },
    {
        id: 103,
        label: 'Publications',
        active: false
    },
    {
        id: 104,
        label: 'Credentials',
        active: false
    },
    {
        id: 105,
        label: 'Education',
        active: false
    },
    {
        id: 106,
        label: 'Services',
        active: false
    }
]

export const tabsCompany = [
    {
        id: 201,
        label: 'General',
        active: true
    },
    {
        id: 202,
        label: 'Admin Account',
        active: false
    },
    {
        id: 203,
        label: 'Password',
        active: false
    },
    {
        id: 205,
        label: 'Payment Methods',
        active: 'false'
    },
    {
        id: 103,
        label: 'Publications',
        active: false
    },
    {
        id: 204,
        label: 'Services',
        active: false
    }
]

export const tabsSeeker = [
    {
        id: 301,
        label: 'General',
        active: true
    },
    {
        id: 302,
        label: 'Interests',
        active: false
    },
    {
        id: 303,
        label: 'Password',
        active: false
    },
    {
        id: 205,
        label: 'Payment Methods',
        active: 'false'
    },
    {
        id: 304,
        label: 'Preferences',
        active: false
    }
]