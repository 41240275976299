export const LOGOUT_CHAT = 'LOGOUT_CHAT';

export const CONNECT_CHAT = 'CONNECT_CHAT';

export const GET_CONVERSATION = 'GET_CONVERSATION';

export const GET_CHATLIST = 'GET_CHATLIST';

export const SEND_MESSAGE = 'SEND_MESSAGE';

export const START_GET_USER_STATUS = 'START_GET_USER_STATUS';
export const GET_USER_STATUS_ERROR = 'GET_USER_STATUS_ERROR';
export const GET_USER_STATUS_SUCCESS = 'GET_USER_STATUS_SUCCESS';

export const START_UPDATE_USER_STATUS = 'START_UPDATE_USER_STATUS';
export const UPDATE_USER_STATUS_ERROR = 'UPDATE_USER_STATUS_ERROR';
export const UPDATE_USER_STATUS_SUCCESS = 'UPDATE_USER_STATUS_SUCCESS';

export const GET_CONVERSATION_MEDIA = 'GET_CONVERSATION_MEDIA';