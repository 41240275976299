import React, { useState } from 'react'
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faArrowDownArrowUp } from "@fortawesome/pro-regular-svg-icons";
import ContainerBalance from "../ContainerBalance";
import PurchaseVibes from "../PurchaseVibes";
import useBalance, { TransactionTabs } from '../../hooks/GV/useBalance';
import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import { ChatServiceInterface } from '../../interfaces/chat/ChatServiceInterface';

interface DetailBalanceProps {
    ChatServiceInstance: ChatServiceInterface
}

const index = (props: DetailBalanceProps) => {

    const [purchaseActive, setPurchaseActive] = useState(false);
    const [activeNotification, setActiveNotification] = useState(false);

    const { currentRows, tab, handleChangeTab } = useBalance();

    const navigate = useNavigate();

    function handleActiveNotification(e: any) {
        setActiveNotification(e)
    }

    return (
        <Container>
            <Header
                handleActiveNotification={handleActiveNotification}
                ChatServiceInstance={props.ChatServiceInstance}
                isGoodVibes={true}
            />
            <DetailBalance>
                <HeaderDashboard>
                    <TitleBalance>
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            size="sm"
                            onClick={() => navigate('../../../../../app')}
                        />
                        Transaction Inquiry
                    </TitleBalance>
                    {/* <InfoTransaction>
                        <Text>Last transaction query was:</Text>
                        <Text>
                            August 04 / 2023 - 12:45 p.m
                        </Text>
                    </InfoTransaction> */}
                </HeaderDashboard>
                <InfoBalance>
                    <Balance>
                        <ContainerBalance showDetails={false} />
                    </Balance>
                    <ButtonPurchase onClick={() => setPurchaseActive(true)}>
                        Purchase Good Vibes
                    </ButtonPurchase>
                </InfoBalance>
                <Tabs>
                    <Tab active={tab === 'ALL'} onClick={() => handleChangeTab('ALL')}>All</Tab>
                    <Tab active={tab === 'DEB'} onClick={() => handleChangeTab('DEB')}>Debit</Tab>
                    <Tab active={tab === 'CRE'} onClick={() => handleChangeTab('CRE')}>Credit</Tab>
                </Tabs>
                <TableInfo>
                    <TableH>
                        Date
                        <FontAwesomeIcon
                            icon={faArrowDownArrowUp}
                            size="sm"
                        />
                    </TableH>
                    <TableH>
                        Transaction
                        <FontAwesomeIcon
                            icon={faArrowDownArrowUp}
                            size="sm"
                        />
                    </TableH>
                    <LastRowH>
                        Amount
                        <FontAwesomeIcon
                            icon={faArrowDownArrowUp}
                            size="sm"
                        />
                    </LastRowH>
                    {currentRows.map((tab) => (
                        <>
                            <TableHG>
                                {tab.date}
                                <FontAwesomeIcon
                                    icon={faArrowDownArrowUp}
                                    size="sm"
                                />
                            </TableHG>
                            <TableHG></TableHG>
                            <TableHG></TableHG>
                            {tab.transactions.map((tab2) => (
                                <>
                                    <TableR>{tab2.date}</TableR>
                                    <TableR>{tab2.name}</TableR>
                                    <LastRow theme={{ main: tab2.type ? '#0F9936' : '#D92B5F' }}>
                                        {tab2.amount}
                                    </LastRow>
                                </>
                            ))}
                        </>
                    ))}
                </TableInfo>
            </DetailBalance>
            <DetailPurchase theme={{ main: purchaseActive ? 'active' : 'inactive' }}>
                <PurchaseVibes purchaseActive={() => setPurchaseActive(false)}/>
            </DetailPurchase>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    background-color: white;
    width: 100vw;
    height: calc(100vh - 80px); //Header height
    margin-top: 80px;
`

const DetailPurchase = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    width: 0px;
    height: calc(100vh - 80px); //Header height
    overflow:hidden;
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.15);
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;

    &.active{
        width:570px;
        
    }
    &.inactive{
        width:0px;
    }

`

const TableR = styled.div`
    background-color: #F2F0EF;
    display: flex;
    gap: 8px;
    padding: 17px 16px;
`

const LastRow = styled(TableR)`
    font-family: "Inter-Bold";
    justify-content: flex-end;
    color: ${props => props.theme.main || 'black'};
`;

const TableH = styled.div`
    background-color: white;
    display: flex;
    gap: 8px;
    padding: 17px 16px;
`

const LastRowH = styled(TableH)`
    justify-content: flex-end;
    color: ${props => props.theme.main || 'black'};
`;

const TableHG = styled.div`
    background-color: #E2E2E5;
    display: flex;
    gap: 8px;
    padding: 17px 16px;
    font-family: "Inter-Bold";
`

const TableInfo = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 48px 1fr;
    min-height: 48px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    font-size: 14px;
    overflow: auto;
    max-height: calc(100vh - 300px);

    
`

const Tabs = styled.div`
    display: flex;
    gap: 18px;
    padding: 20px 0 0px 0;
    border-bottom: 1px solid #cfcfcf;
`

interface TabProps {
    active: boolean
}

const Tab = styled.div`
    border-bottom: 2px solid ${({active}: TabProps) => active ? 'var(--orange-home)' : 'white'};
    min-width: 60px;
    padding: 7px 0;
    cursor: pointer;
`

const InfoBalance = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`
const Balance = styled.div`
    width: 280px;
`
const ButtonPurchase = styled.div`
    width: 250px;
    height: 40px;
    padding: 4px 8px;
    border-radius: 8px;
    background: var(--dark-blue);
    color: white;
    font-family: 'Inter-Bold';
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const HeaderDashboard = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const TitleBalance = styled.div`
    font-size: 24px;
    font-family: "Inter-Bold";
    display: flex;
    gap: 8px;
    align-items: center;
`
const InfoTransaction = styled.div`
    font-family: 'Inter';
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.2px;
    text-align: right;
`
const Text = styled.div``

const DetailBalance = styled.div`
    flex: 1;
    padding: 25px 30px;
`
export default index