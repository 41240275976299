import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEventHandler } from 'react';
import styled from 'styled-components';
import { Loader, spinAnimation } from '../BaseComponents/Loader';

export const IconInputWrapper = styled.div`
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--black);
    /* padding-left: 20px; */
    margin-right: 10px;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
`;

export const IconInputField = styled.input`
    outline: none;
    width: 100%;
    height: 100%;
    background-color: transparent;

    &::placeholder{
        color: var(--black);
        font-family: 'Inter'
    }
`;

interface IconInputProps {
  icon: IconProp,
  iconColor?: string
  value?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  placeholder: string;
  loading: boolean;
}

const IconInput = ({
  icon,
  iconColor,
  value,
  onChange,
  placeholder,
  loading,
}: IconInputProps) => (
  <IconInputWrapper>
    {loading ? (
      <IconSpinner
        icon={icon}
        size='xs'
        color={iconColor ?? '#000'}
      />
    ) : (
      <FontAwesomeIcon
        icon={icon}
        size='xs'
        color={iconColor ?? '#000'}
      />
    )}
    <IconInputField
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  </IconInputWrapper>
)

const IconSpinner = styled(FontAwesomeIcon)`
  /* padding: 0; */
  /* margin: 0 !important; */
  -webkit-animation: ${spinAnimation} 2s linear infinite;
  -moz-animation: ${spinAnimation} 2s linear infinite;
  animation: ${spinAnimation} 2s linear infinite;
`;

export default IconInput;