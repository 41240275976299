import { useState } from 'react'
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import ErrorField from '../BaseComponents/ErrorField';

interface ModalTherapeuticProps {
    showModal: boolean
    onHide: (newValue?: string) => void;
}

const ModalTherapeutic = ({
    showModal,
    onHide,
}: ModalTherapeuticProps) => {

    const [name, setName] = useState<string>('')
    const [nameError, setNameError] = useState(false);

    const handleDiscard = () => {
        onHide();
        setName('');
    }

    const handleSave = () => {
        console.log('name', name)
        if (name === '') {
            setNameError(true);
            return;
        }
        setNameError(false);
        onHide(name);
        setName('');
    }

    return (
        <>
            <Modal className="modalAdd" show={showModal}>
                <Modal.Header>
                    <Modal.Title>
                        <Label>
                            <BackIcon
                                icon={faArrowLeft}
                                size='lg'
                                color={"black"}
                                onClick={handleDiscard}
                            />
                            Add new condition treated
                        </Label>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <Text>
                            We recommend that you add the most relevant areas to this section.<br />
                            They will also appear in your profile.
                        </Text>
                        <Row1>
                            <Labelinput>Name of condition treated</Labelinput>
                            <Input onChange={(e) => setName(e.target.value)}></Input>
                            {(nameError) && (<ErrorField error="Name is required" />)}
                        </Row1>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonCancel onClick={handleDiscard}>Cancel</ButtonCancel>
                    <ButtonSave theme={{ active: name.length > 0 && 'active' }} onClick={handleSave}>Save</ButtonSave>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const Text = styled.div`
    font-family: 'Inter';
    font-size: 14px;
    color: black;
    text-align: left;
`

const Row1 = styled.div`
    width: 100%;
    padding: 0px 0px;
    margin-top: 15px;
`

const Labelinput = styled.div`
    font-family: 'Inter';
    font-size: 14px;
    text-align: left;
    padding: 8px 0;
    width: 100%;
    display: flex;
`

const ButtonCancel = styled.div`
    width: 49%;
    text-align: center;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 1px solid var(--dark-blue);
`
const ButtonSave = styled.div.attrs(props => ({
    className: props.theme.active,
}))`
    width: 49%;
    text-align: center;
    background-color: var(--simple-gray-2);
    pointer-events: none;
    color: var(--simple-gray-3);
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 1px solid var(--simple-gray);

    &.active{
        background-color: var(--dark-blue);
        color: var(--white);
        border: 0px;
        cursor:pointer;
        pointer-events: auto;
    }

`

const Label = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    padding: 15px;
    width: 100%;
`

const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
`

const BackIcon = styled(FontAwesomeIcon)`
    font-size: 22px;
    margin-right: 15px;
    color: black;
    margin-left: -15px !important;
    cursor:pointer;
`;

export default ModalTherapeutic