import { useEffect, useState } from "react";
import { Backend, MicroServices, getDataSDK, getDataSDKResponse } from "../helpers/backendHelper";
import SelectItem from "../interfaces/SelectItem";
import { ICountry } from "@vibe/sdk/dist/interfaces/Roster/Location";

export interface CountryOption extends SelectItem {};

const useCountry = () => {
  const [countries, setCountries] = useState<string[]>([]);
  const [countriesReactSelect, setCountriesReactSelect] = useState<CountryOption[]>([])

  useEffect(() => {
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
          return dataSDK.sdk.Roster.getActiveCountries(dataSDK.token);
      })
      .then((results) => {
        if (results.statusCode !== 200) {
          setCountries([]);
          setCountriesReactSelect([]);
          return;
        }
        const response = results.body as ICountry[];
        setCountries(response.map((country) => country.name));
        setCountriesReactSelect(
          response.map((country) => ({value: country.location, label: country.name}))
        )
      })
  }, []);

  return {countries, countriesReactSelect};
}

export default useCountry;