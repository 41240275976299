import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnResultsContainer, GridHomeTab, LoadMoreResults, RowFiltersContainer, WrapperResultsHomeSearch } from "./styles";
import { ItemFilterHook, SelectedItemHome } from "../../../../interfaces/HomeSearch/ItemFilterHome";
import { Element } from 'react-scroll';
import { useState } from "react";
import FilterTips from "../../../BaseComponents/FilterTips";
import SearchInput from "../../../BaseComponents/SearchInput";

interface ContentResultsProps<T> {
    name: string;
    cards: (t: T) => React.ReactNode;
    elements: T[][];
    filters: ItemFilterHook[];
    filtersActive: number[];
    setFiltersActive: (data: number[]) => void;
    activeRows: number;
    setActiveRows: (data: number) => void;
    setSelectedOption: (data: SelectedItemHome) => void
    selectedOption: SelectedItemHome
}

const ContentResults = <T,>(props: ContentResultsProps<T>) => {

    const [search, setSearch] = useState<string>('');

    const addMoreCarrousels = () => {
        props.setActiveRows(props.activeRows + 2);
    }

    const getFiltered = (listName: any[]) => {
        return listName.filter((item: any) => {
            if (!search) return true;
    
            if (item.name && (
                item.name.toUpperCase().includes(search) ||
                item.name.toLowerCase().includes(search) ||
                item.name.includes(search)
            )) {
                return true;
            }
    
            return false;
        })
    }

    const getElements = () => {
        const elements: any[] = [];
        if (props.elements.length) {
            for (let row = 0; row < props.activeRows; row++) {
                if (props.elements[row] && props.elements[row].length > 0) {
                    getFiltered(props.elements[row]).forEach((e, idx) => {
                        elements.push((
                            <Element name={`el-${idx}`}>
                                {props.cards(e)}
                            </Element>
                        ))
                    })
                }
            }
        }
        return elements;
    }

    return (
        <ColumnResultsContainer>
            <RowFiltersContainer>
                {/* {props.name} */}
                <WrapperResultsHomeSearch>
                    <SearchInput
                        filteredItem={search}
                        setFilteredItem={setSearch}
                        placeholder={'Search by name'}
                    />
                </WrapperResultsHomeSearch>
                <FilterTips
                    filters={props.filters}
                    filtersActive={props.filtersActive}
                    setFiltersActive={props.setFiltersActive}
                    setSelectedOption={props.setSelectedOption}
                    selectedOption={props.selectedOption}
                />
            </RowFiltersContainer>

            <GridHomeTab rows={`${props.activeRows}`} theme={{ main: `result${props.name}` }}>
                {getElements()}
            </GridHomeTab>

            {(props.elements.length > 0 && props.activeRows < props.elements.length) && (
                <LoadMoreResults onClick={addMoreCarrousels}>
                    Load more
                    <FontAwesomeIcon
                        icon="chevron-down"
                        size="sm"
                        color="var(--default-dark)"
                    />
                </LoadMoreResults>
            )}
        </ColumnResultsContainer>
    )
}

export default ContentResults;