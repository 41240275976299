import "./styles.css";
import "./styles.scoped.css";
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getClub } from "../../store/actions/clubActions";
import { editClub } from "../../store/actions/clubActions";
import { toast } from "react-toastify";
import { RootState } from "../../store/store";
import {ClubCode} from "../../interfaces/club/ClubCode";
import { frontendUrl } from "../../AppConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";
import { ContextSuccess } from "../../Context";
import { ClubCenterWrap } from "../ClubViewInformation";

function Index({clubCode}: {clubCode: ClubCode}) {
    const { t, i18n } = useTranslation();
    const currentSuccess = useContext(ContextSuccess);
    const dispatch = useDispatch();
    const { id = "" } = useParams();
    const club = useSelector((state: any) => state.getClub.getClub);
    const clubRootState = useSelector((state: RootState) => state.getClub)

    const [arrayPosts, setArrayPosts] = useState([
        // { id: 1, key: 'isDisplayedOnLandingPage', text: t('infoSetting__isDisplayedOnLandingPage'), value: 'true' },
        // { id: 2, key: 'followersCanPost', text: 'Followers can post', value: 'true' },
        { id: 3, key: 'membersCanPost', text: t('infoSetting__membersCanPost'), value: 'true' },
    ]);

    const arrayPrivacy = [
        { id: 1, key: 'PUBLIC', text: t('infoSetting__public'), desc: t('infoSetting__descPublic') },
        { id: 2, key: 'INVITE_ONLY', text: t('infoSetting__inviteOnly'), desc: t('infoSetting__descinviteOnly') },
        { id: 3, key: 'PRIVATE', text: t('infoSetting__private'), desc: t('infoSetting__descPrivate') },
    ];

    const [arrayNotificactions, setArrayNotifications] = useState([
        { id: 4, key: 'regularContentClub', text: t('infoSetting__regularContentClub'), value: 'None' },
        { id: 5, key: 'regularContentAdvisor', text: t('infoSetting__regularContentAdvisor'), value: 'None' },
        { id: 6, key: 'urgentContentAdvisor', text: t('infoSetting__urgentContentAdvisor'), value: 'None' },
        { id: 7, key: 'activityCreated', text: t('infoSetting__activityCreated'), value: 'None' },
        { id: 8, key: 'activityChanged', text: t('infoSetting__activityChanged'), value: 'None' },
        { id: 9, key: 'activityCancelled', text: t('infoSetting__activityCancelled'), value: 'None' },
        { id: 10, key: 'activityRSVPYes', text: t('infoSetting__activityRSVPYes'), value: 'None' },
        { id: 11, key: 'activityRSVPNo', text: t('infoSetting__activityRSVPNo'), value: 'None' },
        { id: 12, key: 'existingContentAllUsers', text: t('infoSetting__existingContentAllUsers'), value: 'None' },
        { id: 13, key: 'messagingAllUsers', text: t('infoSetting__messagingAllUsers'), value: 'None' },
        { id: 14, key: 'taggingAllUsers', text: t('infoSetting__taggingAllUsers'), value: 'None' },
    ]);

    const [name, setName] = useState<string>(club?.name || '')
    const [privacy, setPrivacy] = useState<string | null>(club?.privacy || null)

    function handleChangePrivacy(e: any) {
        setPrivacy(e.target.value)
    }

    function handleChangePosts(key: string, value: 'true' | 'false') {
        const arrayPostsCopy = [...arrayPosts]
        const idx = arrayPostsCopy.findIndex((item) => item.key === key)
        if (idx !== -1) {
            arrayPostsCopy[idx] = {
                ...arrayPostsCopy[idx],
                value: value
            }
            setArrayPosts(arrayPostsCopy)
        }
    }

    function handleChangeNotifications(key: string, value: 'None' | 'Daily' | 'Inmediate') {
        const arrayNotificactionsCopy = [...arrayNotificactions]
        const idx = arrayNotificactionsCopy.findIndex((item) => item.key === key)
        if (idx !== -1) {
            arrayNotificactionsCopy[idx] = {
                ...arrayNotificactionsCopy[idx],
                value: value
            }
            setArrayNotifications(arrayNotificactionsCopy)
        }
    }

    useEffect(() => {
        dispatch<any>(getClub(id, false));
    }, [id]);

    useEffect(() => {
        dispatch<any>(getClub(id, false));
    }, []);

    useEffect(() => {
        setName(club?.name || '')
        setPrivacy(club?.privacy || null)
        if (club) {
            setArrayPosts(arrayPosts.map((item) => {
                return {
                    ...item,
                    value: club[item.key] === undefined ? 'false' : club[item.key] ? 'true' : 'false'
                }
            }))
            setArrayNotifications(arrayNotificactions.map((item) => {
                return {
                    ...item,
                    value: club[item.key] || 'None'
                }
            }))
        }
    }, [club])

    function saveClub() {
        if (name.trim().length <= 0) {
            currentSuccess?.setSuccessData(false, true, t('toast_clubNameEmpty'));
            return
        }

        dispatch<any>(editClub({
            ...{
                id,
                name,
                privacy,
            },
            ...Object.fromEntries(
                arrayPosts.map(e => [e.key, e.value === 'true'])
            ),
            ...Object.fromEntries(
                arrayNotificactions.map(e => [e.key, e.value])
            )
        }))
            .then((results: boolean) => {
                if (results) {
                    currentSuccess?.setSuccessData(true, true, t('toast_clubEdited'));
                } else {
                    currentSuccess?.setSuccessData(false, true, t('toast_errorOccurred'));
                }
            })
            .catch(() => currentSuccess?.setSuccessData(false, true, t('toast_errorOccurred')))
    }

    return (
        <ClubCenterWrap>
            <div className="infoSettingClub">
                <label className="labelListEvents">View</label>
                <ul>
                    {arrayPosts.map((settingPost: any) => (
                        <li>
                            <div className="listSettingClub">
                                <label htmlFor="">{settingPost.text}</label>
                                <div className="option">
                                    <label className="content-input">
                                        <input
                                            type="radio"
                                            name={`clugSetting-button${settingPost.id}`}
                                            value=""
                                            checked={settingPost.value === 'true'}
                                            onClick={(e) => handleChangePosts(settingPost.key, 'true')} />
                                        <i></i>
                                    </label>
                                    <label htmlFor="students">{t('infoSetting__yes')}</label>
                                </div>
                                <div className="option">
                                    <label className="content-input">
                                        <input
                                            type="radio"
                                            name={`clugSetting-button${settingPost.id}`}
                                            value=""
                                            checked={settingPost.value === 'false'}
                                            //checked={settingPost.value === 'false'}
                                            onClick={(e) => handleChangePosts(settingPost.key, 'false')} />
                                        <i></i>
                                    </label>
                                    <label htmlFor="students">{t('infoSetting__no')}</label>
                                </div>
                            </div>
                        </li>
                    ))}

                </ul>
            </div>
            {clubCode && 'code' in clubCode && (<div className="infoSettingClub">
                <label className="labelListEvents">Club Invite Link</label>
                <div className="InviteMembers--inputDisabled roboto-normal-white-16px">
                    <span>{clubCode?.code ? `${frontendUrl}/s/${clubCode.code}` : ''}</span>
                    <button onClick={() => {navigator.clipboard.writeText(`${frontendUrl}/s/${clubCode?.code}`); currentSuccess?.setSuccessData(true, true, t('toast_linkCopied')); }}>
                    <FontAwesomeIcon
                        icon={faCopy}
                        color={WHITE}
                    />
                    </button>
                </div>
            </div>)}
            <div className="clubPrivacy">
                <label className="labelListEvents">{t('infoSetting__privacy')}</label>
                <ul>
                    {arrayPrivacy.map((settingPrivacy: any) => (
                        <li>
                            <div className="listSettingPrivacy">
                                <div className="option">
                                    <label className="content-input">
                                        <input
                                            type="radio"
                                            name="role-button"
                                            value={settingPrivacy.key}
                                            checked={settingPrivacy.key === privacy}
                                            onClick={(e) => handleChangePrivacy(e)}
                                        />
                                        <i></i>
                                    </label>
                                    <div className="textsPrivacy">
                                        <label htmlFor="students">{settingPrivacy.text}</label>
                                        <span className="privacyDetails">{settingPrivacy.desc}</span>
                                    </div>

                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            {/* <div className="clubNotifications">
                <label className="labelListEvents">{t('infoSetting__notifications')}</label>
                <ul>
                    {arrayNotificactions.map((settingNotif: any) => (
                        <li>
                            <div className="listSettingNotification">
                                <label htmlFor="">{settingNotif.text}</label>
                                <div className="groupOptions">
                                    <div className="option">
                                        <label className="content-input">
                                            <input
                                                type="radio"
                                                name={`clugSetting-button${settingNotif.id}`}
                                                value=""
                                                checked={settingNotif.value === 'Inmediate'}
                                                onClick={(e) => handleChangeNotifications(settingNotif.key, 'Inmediate')} />
                                            <i></i>
                                        </label>
                                        <label htmlFor="students">{t('infoSetting__inmediate')}</label>
                                    </div>
                                    <div className="option">
                                        <label className="content-input">
                                            <input
                                                type="radio"
                                                name={`clugSetting-button${settingNotif.id}`}
                                                value=""
                                                checked={settingNotif.value === 'Daily'}
                                                onClick={(e) => handleChangeNotifications(settingNotif.key, 'Daily')} />
                                            <i></i>
                                        </label>
                                        <label htmlFor="students">{t('infoSetting__daily')}</label>
                                    </div>
                                    <div className="option">
                                        <label className="content-input">
                                            <input
                                                type="radio"
                                                name={`clugSetting-button${settingNotif.id}`}
                                                value=""
                                                checked={settingNotif.value === 'None'}
                                                onClick={(e) => handleChangeNotifications(settingNotif.key, 'None')} />
                                            <i></i>
                                        </label>
                                        <label htmlFor="students">{t('infoSetting__none')}</label>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}

                </ul>
            </div> */}

            <div className="buttonsSettings">
                {clubRootState.loadingEditClub ? (
                    <div className="icon-spinner-third buttonSendSignUp" />
                ) : (
                    <>
                        <button className="buttonCancel" onClick={() => dispatch<any>(getClub(id, false))}>
                            {t('button__cancel')}
                        </button>
                        <button className="buttonSave" onClick={() => saveClub()}>
                            {t('button__save')}
                        </button>
                    </>
                )}
            </div>
        </ClubCenterWrap>
    );
}

export default Index;