import React, {
  KeyboardEventHandler,
  useState,
  useRef,
  useEffect,
} from "react";
import "./styles.css";
import "./styles.scoped.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser, getUserInfoProfile } from "../../store/actions/userActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from "../../components/ClubIconsNavBar";
import { Backend, MicroServices, getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import { IUserGradeResponse } from "@vibe/sdk/interfaces/Admin/User";
import { RootState } from "../../store/store";
import { ClubDigestExtra } from "@vibe/sdk/dist/interfaces/Roster/getUserClubs";
import { getMyClubs } from "../../store/actions/clubActions";
import { CenterInsideWrap } from "../../containers/Bulletin";

function Index() {
  const { username } = useParams();
  const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
  const listClubs = useSelector((state: RootState) => state.getClub.getMyClubs);
  const userProfile: IUserGradeResponse = useSelector((state: RootState) => state.getUser.userProfile);

  const [clubUp, setClubUp] = useState<ClubDigestExtra[]>([]);
  const [clubDown, setClubDown] = useState<ClubDigestExtra[]>([]);

  const dispatch = useDispatch();

  const clubFilter = (clubs: ClubDigestExtra[]) => {
    const up: ClubDigestExtra[] = [];
    const down: ClubDigestExtra[] = [];
    clubs.forEach((club) => {
      const c = listClubs.findIndex(cl => cl.club_id === club.club_id);
      if(c !== -1) up.push(club);
      else down.push(club);
    });
    setClubDown(down);
    setClubUp(up);
  };

  useEffect(() => {
    dispatch(getMyClubs(userInfo?.username || ''));
    dispatch(getUserInfoProfile(username || ''));
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Roster.getUserClubs({ username: username || '' }, dataSDK.token);
      })
      .then((results) => {
        if (results.statusCode === 200) {
          clubFilter(results.body as ClubDigestExtra[]);
        }
        else {
          clubFilter([]);
        }
      })
      .catch(console.error);
  }, [username]);

  return (
    <CenterInsideWrap>
      <div className="containerT">
        <h2 className="titleContainer">
          Common Clubs {clubUp.length}
        </h2>
      </div>

      <div className="listClubTeams">
        {clubUp.map((request: any) => (
          <div className="cardClub">
            <div className="headerCard">
              <div className="containerTitle">
                <p className="titleClub">{request.name}</p>
              </div>
              <img
                src={
                  request.logo ||
                  "https://htmlcolorcodes.com/assets/images/colors/dark-gray-color-solid-background-1920x1080.png"
                }
                alt=""
              />
            </div>
            <div className="countIcon">
              <div className="counts">
                <FontAwesomeIcon
                  icon="list-alt"
                  size="lg"
                  color={WHITE}
                  className="cIcon  "
                />
                <span className="roboto-normal-white-16px">
                  {request.postCount || request.posts || 0}
                </span>
              </div>
              <div className="counts">
                <FontAwesomeIcon
                  icon="calendar-alt"
                  size="lg"
                  color={WHITE}
                  className="cIcon calendar"
                />
                <span className="roboto-normal-white-16px">
                  {request.eventsCount || request.events || 0}
                </span>
              </div>
              <div className="counts">
                <FontAwesomeIcon
                  icon="user-group"
                  size="lg"
                  color={WHITE}
                  className="cIcon  "
                />
                <span className="roboto-normal-white-16px">
                  {request.membersCount || request.members || 0}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="containerT">
        <h2 className="titleContainer">
          {`${userProfile.firstname}'s Clubs`} {clubDown.length}
        </h2>
      </div>

      <div className="listClubTeams">
        {clubDown.map((request: any) => (
          <div className="cardClub">
            <div className="headerCard">
              <div className="containerTitle">
                <p className="titleClub">{request.name}</p>
              </div>
              <img
                src={
                  request.logo ||
                  "https://htmlcolorcodes.com/assets/images/colors/dark-gray-color-solid-background-1920x1080.png"
                }
                alt=""
              />
            </div>
            <div className="countIcon">
              <div className="counts">
                <FontAwesomeIcon
                  icon="list-alt"
                  size="lg"
                  color={WHITE}
                  className="cIcon  "
                />
                <span className="roboto-normal-white-16px">
                  {request.postCount || request.posts || 0}
                </span>
              </div>
              <div className="counts">
                <FontAwesomeIcon
                  icon="calendar-alt"
                  size="lg"
                  color={WHITE}
                  className="cIcon calendar"
                />
                <span className="roboto-normal-white-16px">
                  {request.eventsCount || request.events || 0}
                </span>
              </div>
              <div className="counts">
                <FontAwesomeIcon
                  icon="user-group"
                  size="lg"
                  color={WHITE}
                  className="cIcon  "
                />
                <span className="roboto-normal-white-16px">
                  {request.membersCount || request.members || 0}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
      
    </CenterInsideWrap>
  );
}

export default Index;
