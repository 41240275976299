import "./styles.css";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cancelFriendRequest, createFriendRequest, deleteFriend, rejectFriendRequest, saveNewFriend } from "../../store/actions/connectionActions";
import ProfileConnection from "../ProfileConnection/ProfileConnection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BROWN } from "../SuperCenter/Modalities/Modals/components";
import ModalConnection, { ConnectionAction } from "../ModalConnection";
import { UserConnection } from "@vibe/sdk/dist/interfaces/Roster/getUserConnections";
import { ContextSuccess } from "../../Context";
import { RootState } from "../../store/store";
import { UserConnectionHook } from "../../hooks/users/useUsersList";
import useUserProfileConn from "../../hooks/users/useUserProfileConn";
import { rolesType } from "@vibe/sdk/interfaces/AccessManager/signUpUser";
import { CenterInsideWrap } from "../../containers/Bulletin";
import SearchInput from "../BaseComponents/SearchInput";
import styled from "styled-components";

function Index() {
  const currentSuccess = useContext(ContextSuccess);
  const dispatch = useDispatch();

  const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
  const userProfile = useSelector((state: RootState) => state.getUser.userProfile);

  const [members, setMembers] = useState<UserConnectionHook[]>([]);
  const [valueFilter, setValueFilter] = useState("");
  const [showModalConnection, setShowModalConnection] = useState<boolean>(false);
  const [infoConnection, setInfoConnection] = useState<UserConnection>({
    role: userProfile.roles as rolesType[],
    avatar: userProfile.avatar || '',
    name: `${userProfile.firstname || ''} ${userProfile.lastname || ''}` || '',
    mutualClubs: 0,
    username: userProfile.username,
    relation: []
  });
  const [loadingAction, setLoadingAction] = useState<boolean>(false);

  const { username } = useParams();

  const {
    reloadList,
    allUserConnections,
  } = useUserProfileConn({
    searchName: valueFilter,
    username: username || '',
  });

  const handleChangeName = (e: any) => {
    setValueFilter(e);
  }

  const setShowModal = (user: UserConnection) => {
    setInfoConnection(user);
    setShowModalConnection(true);
  }

  function handleCloseModalDelete() {
    setShowModalConnection(false)
  }

  const handleConnections = (data: ConnectionAction, username: string) => {
    if (data === 'ACCEPT') {
      setLoadingAction(true);
      dispatch<any>(saveNewFriend(userInfo?.username || '', username))
        .then(() => {
          reloadList();
          setLoadingAction(false);
        });
      return;
    }
    if (data === 'DELETE') {
      setLoadingAction(true);
      dispatch<any>(deleteFriend(userInfo?.username || '', username))
        .then(() => {
          reloadList();
          setLoadingAction(false);
        });
      return;
    }
    if (data === 'CANCEL') {
      setLoadingAction(true);
      dispatch<any>(cancelFriendRequest(userInfo?.username || '', username))
        .then(() => {
          reloadList();
          setLoadingAction(false);
        });
      return;
    }
    if (data === 'REJECT') {
      setLoadingAction(true);
      dispatch<any>(rejectFriendRequest(username, userInfo?.username || ''))
        .then(() => {
          reloadList();
          setLoadingAction(false);
        });
      return;
    }
    if (data === 'REQUEST') {
      setLoadingAction(true);
      dispatch<any>(createFriendRequest(userInfo?.username || '', username))
        .then(() => {
          reloadList();
          setLoadingAction(false);
        });
      return;
    }

    currentSuccess?.setSuccessData(false, true, 'No connection action supported.');
  }

  useEffect(() => {
    setMembers(allUserConnections);
  }, [allUserConnections]);

  return (
    <CenterInsideWrap>

      <GridRow>
        <TitleProfile>{members.length} Connections</TitleProfile>
        <SearchInput 
          filteredItem={valueFilter}
          setFilteredItem={setValueFilter}
          backgroundColor ={'var(--t-blue)'}
          border={'0px'}
          placeholder={'Search'}
        />
      </GridRow>

      <ListConn>
        {members .map((member) => (
          <ProfileConnection
            member={member}
            setShowModal={setShowModal}
          />
        ))}
      </ListConn>

      <ModalConnection
        showModal={showModalConnection}
        onHide={handleCloseModalDelete}
        info={infoConnection}
        handleButtons={handleConnections}
        loadingAction={loadingAction}
      />
    </CenterInsideWrap>
  );
}

export default Index;


const TitleProfile = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: var(--t-blue);
  text-align: left;
`;

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 50% calc(50% - 10px);
  grid-template-rows: 40px;
  grid-column-gap: 10px;
  width: 100%;
  gap: 10px;
`;

const ListConn = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
`;