import { AnyAction } from 'redux';
import ChatStateInterface from '../../interfaces/store/chatState';
import { 
  LOGOUT_CHAT, 
  GET_USER_STATUS_ERROR, 
  GET_USER_STATUS_SUCCESS, 
  START_GET_USER_STATUS, 
  START_UPDATE_USER_STATUS, 
  UPDATE_USER_STATUS_ERROR, 
  UPDATE_USER_STATUS_SUCCESS, 
  CONNECT_CHAT, 
  GET_CONVERSATION, 
  GET_CHATLIST, 
  SEND_MESSAGE
} from '../constants/chatConstants';


const initialState: ChatStateInterface = {

  //chat: new ChatServiceClass(),

  loadingGetUserStatus: false,
  getUserStatus: 'available',
  getUserStatusError: undefined,

  loadingUpdateUserStatus: false,
  updateUserStatusError: undefined,
};

export const getChatReducer = (
  state = initialState,
  action: AnyAction,
): ChatStateInterface => {
  switch (action.type) {

    // case CONNECT_CHAT:
    //   state.chat.connect(action.payload)
    //   return state

    // case GET_CONVERSATION:
    //   state.chat.getConversation(action.payload)
    //   return state

    // case GET_CHATLIST:
    //   state.chat.getChatList()
    //   return state

    // case SEND_MESSAGE:
    //   state.chat.sendMessage(action.payload.to,action.payload.message)
    //   return state

    case START_GET_USER_STATUS:
      return {
        ...state,
        loadingGetUserStatus: true,
      }
    case GET_USER_STATUS_SUCCESS:
      return {
        ...state,
        loadingGetUserStatus: false,
        getUserStatus: action.payload,
      }
    case GET_USER_STATUS_ERROR:
      return {
        ...state,
        loadingGetUserStatus: false,
        getUserStatusError: action.payload,
      }

    case START_UPDATE_USER_STATUS:
      return {
        ...state,
        loadingUpdateUserStatus: true,
      }
    case UPDATE_USER_STATUS_SUCCESS:
      return {
        ...state,
        loadingUpdateUserStatus: false,
        getUserStatus: action.payload,
      }
    case UPDATE_USER_STATUS_ERROR:
      return {
        ...state,
        loadingUpdateUserStatus: false,
        updateUserStatusError: action.payload,
      }

    // case LOGOUT_CHAT:
    //   state.chat.disconnect()
    //   return {
    //     ...state,
    //     chat: new ChatServiceClass()
    //   };

    default:
      return state;
  }
};
