import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/esm/Modal"
import styled from "styled-components";
import { cleanHTML, cleanStyleHTML } from "../../../helpers/letterHelper";

const ModalDescription = ({
    showModal,
    setShowModal,
    description,
    title,
}:{
    showModal: boolean,
    setShowModal: (data: boolean) => void,
    description: string,
    title: string
}) => {
    
    return(
        <Modal className="modalProfile" show={showModal} onHide={() => setShowModal(false)} >
            <IconClicker
                onClick={() => setShowModal(false)}
                icon="times"
                size='xl'
                color={'black'}
            />
            <Modal.Header>
                <Title>{title}</Title>
            </Modal.Header>
            <Modal.Body>
                <Description
                    dangerouslySetInnerHTML={{ __html: cleanStyleHTML(description as string ?? '') }}>
                </Description>
            </Modal.Body>
        </Modal>
    )
}

const Description = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    width: 100%;
    padding: 0px;
    text-align: initial;
`

const Title = styled.span`
    font-family: 'Inter-Bold';
    color: black;
    font-size: 16px;
`;

const IconClicker = styled(FontAwesomeIcon)`
    cursor: pointer;
    position: absolute;
    z-index: 9999;
    left: 92%;
`;

export default ModalDescription;