import { UserConnection } from '@vibe/sdk/dist/interfaces/Roster/getUserConnections';
import { rolesType } from '@vibe/sdk/interfaces/AccessManager/signUpUser';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface ProfileConnectionProps {
  member: UserConnection;
  setShowModal: (user: UserConnection) => void;
}

const ProfileConnection = ({
  member,
  setShowModal,
}: ProfileConnectionProps) => {
  const { t, i18n } = useTranslation();

  const getCountClubs = (mutualClubs: number) => {
    return mutualClubs === 1 ?
      (mutualClubs || 0) + ' ' + t('connections__mutualClub')
      :
      (mutualClubs || 0) + ' ' + t('connections__mutualClubs')
  }

  const getConnRole = (roles: rolesType[]) => {
    if (roles.includes('HEALER') && roles.includes('SEEKER'))
      return 'Healer & Seeker';
    return roles.includes('HEALER') ? 'Healer' : 'Seeker';
  }

  return (
    <InfoCard onClick={() => setShowModal(member)}>
      <Avatar src={member.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" />
      <InfoConn>
        <Label>{member.name}</Label>
        <LabelCount>
          {getConnRole(member.role)} - {getCountClubs(member.mutualClubs)}
        </LabelCount>
      </InfoConn>
    </InfoCard>
  );
};

const InfoCard = styled.div`
  display: flex;
  padding: 15px 0px 15px 0px;
  align-items: center;
  position: relative;
  width: 100%;
  gap: 10px;
  cursor: pointer;
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  object-fit: cover;
  border-radius: 50%;
`;

const InfoConn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Label = styled.span`
  color: black;
  font-family: 'Inter-bold';
  font-size: 14px;
  line-height: 16px;
`;

const LabelCount = styled(Label)`
  font-family: 'Inter';
`;

export default ProfileConnection;