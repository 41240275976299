import { AnyAction } from "redux"
import { ThunkAction, ThunkDispatch } from "redux-thunk"
import { GET_BALANCE_ERROR, GET_BALANCE_SUCCESS, GET_TRANSACTIONS_ERROR, GET_TRANSACTIONS_SUCCESS, START_GET_BALANCE, START_GET_TRANSACTIONS, TOGGLE_BALANCE } from "../constants/GVConstants"
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper"

export const toggleBalanceST = (): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: TOGGLE_BALANCE
      })
    })

export const getBalance = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
    new Promise((resolve, reject) => {
      dispatch({ type: START_GET_BALANCE });
      getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
          return dataSDK.sdk.Admin.getMyBalance(username, dataSDK.token);
        })
        .then((response) => {
          if (!response.body || typeof response.body !== 'number') {
            dispatch({ type: GET_BALANCE_ERROR, payload: response });
            return;
          }
          dispatch({ type: GET_BALANCE_SUCCESS, payload: response.body });
        })
        .catch((error) => dispatch({ type: GET_BALANCE_ERROR, payload: error }))
    })

export const getTransactions = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
    new Promise((resolve, reject) => {
      dispatch({ type: START_GET_TRANSACTIONS });
      getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
          return dataSDK.sdk.Admin.getMyTransactions(username, dataSDK.token)
        })
        .then((response) => {
          if (!response.body || !('asReceiver' in response.body)) {
            dispatch({ type: GET_TRANSACTIONS_ERROR, payload: response });
            return;
          }
          dispatch({ type: GET_TRANSACTIONS_SUCCESS, payload: response.body });
        })
        .catch((error) => dispatch({ type: GET_TRANSACTIONS_ERROR, payload: error }))
    })