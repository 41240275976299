import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getTimezones } from '../../store/actions/locationActions';
import { RootState } from '../../store/store';
import { useTranslation } from "react-i18next";
import { ContextSuccess } from '../../Context';
import { IUpdateUserBody } from '@vibe/sdk/dist/interfaces/Admin/updateUser';
import { getUser, updateUser } from '../../store/actions/userActions';
import styled from 'styled-components';
import { Loader } from '../BaseComponents/Loader';
import SelectorLanguage from '../BaseComponents/Language/SelectorLanguage';
import SelectItem from '../../interfaces/SelectItem';
import { BaseFieldState, getFieldState } from '../BaseComponents/utils';
import { IUserGradeResponse } from '@vibe/sdk/interfaces/Admin/User';
import { capitalize } from '../../helpers/letterHelper';

const Preferences = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentSuccess = useContext(ContextSuccess);

  const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);

  const [timezone, setTimezone] = useState("America#Bogota#-05:00");
  const [language, setLanguage] = useState<BaseFieldState<SelectItem>>(getFieldState({value: user.preferredLanguage || '', label: capitalize(user.preferredLanguage || '')}));
  const [loading, setLoading] = useState<boolean>(false);
  const [updated, setUpdated] = useState<boolean>(false);

  const timezones = useSelector((state: RootState) => state.getLocation.timezones || [{
    continent: "America",
    city: 'Bogota',
    country: 'CO',
    DST: '-05:00',
    STD: '-05:00'
  }]);

  useEffect(() => {
    dispatch(getTimezones())
  }, [])

  useEffect(() => {
    setTimezone(getCurrentTimezone());
  }, [timezones])

  const getCurrentTimezone = (): string => {
    const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const continent = (zone || '').split("/")[0];
    const city = (zone || '').split("/")[1];
    const tz = timezones.find(
      (item: any) => item.continent === continent && item.city === city
    );
    if (tz) return `(UTC ${tz.STD}) ${tz.continent}/${tz.city}`;
    else return "(UTC -05:00) America/Bogota";
  };

  const handleLanguage = (e: BaseFieldState<SelectItem>) => {
    setUpdated(true);
    setLanguage(e)
  };

  const handleSave = () => {
    setLoading(true);
    const updateData: IUpdateUserBody = {
      preferredLanguage: language.value?.value
    }
    dispatch<any>(updateUser(
      user.username,
      updateData,
      'OA',
    ))
      .then((results: boolean) => {
        if (results) {
          dispatch(getUser(user.username))
          currentSuccess?.setSuccessData(true, true, t('toast_informationUpdated'));
        }
        else {
          currentSuccess?.setSuccessData(false, true, 'Error updating information.');
        }
      })
      .catch((error: any) => {
        console.error(error);
        currentSuccess?.setSuccessData(false, true, 'Error updating profile', error);
      })
      .finally(() => setLoading(false))
  };

  return (
    <Container>

      <TwoColumns>
        <Column>
          <Label>Your local timezone</Label>
          {timezone}
        </Column>
        <Column>
          <Label>Preferred language</Label>
          <SelectorLanguage
            language={language}
            setLanguage={(data: BaseFieldState<SelectItem>) => handleLanguage(data)}
          />
        </Column>
      </TwoColumns>

      <ContButtn>
        {loading ?
          <WrapperSpinner>
            <Loader />
          </WrapperSpinner> :
          <Button onClick={handleSave} color={updated ? '#2e3031' : '#bbbabc'}>Save</Button>
        }
      </ContButtn>

    </Container>
  )
}

export default Preferences;

const Label = styled.div`
  display: flex;
  gap: 10px;
  font-weight: 600;
`;

const Column = styled.div`
flex: 1;
text-align: left;
display: flex;
flex-direction: column;
gap: 8px;
`

const TwoColumns = styled.div`
  display: flex;
  gap: 32px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 30px 0;
  flex: 1;
  height: 100vh;
`;

interface ButtonProps {
  color: string;
}

const Button = styled.div`
background-color: ${(props: ButtonProps) => props.color};
width: 165px;
height: 40px;
display: flex;
justify-content: center;
padding: 12px 0;
border-radius: 8px;
color: white;
font-family: 'Inter-Bold';
cursor: pointer;
`;

const WrapperSpinner = styled.div`
background-color: #2e3031;
width: 165px;
height: 40px;
display: flex;
justify-content: center;
padding: 12px 0;
border-radius: 8px;
color: white;
font-family: 'Inter-Bold';
`;

const ContButtn = styled.div`
display: flex;
justify-content: flex-end;
`