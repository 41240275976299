import React, { useState } from 'react';
import styled from 'styled-components';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper'

const StyledCardElement = styled(CardElement)`
  background: white;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
`;

const AddCard: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements()

  // State for billing details
  const [billingDetails, setBillingDetails] = useState({
    name: '',
    email: '',
    phone: '',
    address: {
      city: '',
      line1: '',
      state: '',
      postal_code: '',
    },
  });

  const [username, setUsername] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name.includes('.')) {
        const [field, subField] = name.split('.');
        setBillingDetails((prevState: any) => ({
            ...prevState,
            [field]: {
                ...prevState[field],
                [subField]: value
            }
        }));
    } else {
        setBillingDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
};


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement!,
      billing_details: billingDetails,
    });

    if (error) {
      console.log('[error]', error);
    } else {
      if (!paymentMethod) {
        console.log('No paymentMethod')
        return
      }
      const toSend = {username, paymentMethod}
      getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Admin.addPaymentMethod(toSend, dataSDK.token);
      })
      .then((results) => {
        if (results.statusCode === 200) alert('success')
      })
      .catch((error) => {
        console.error(error)
        alert('error')
      })
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        name="username"
        placeholder="Username"
        value={username}
        onChange={e => setUsername(e.target.value)}
      />
      <input
        name="name"
        placeholder="Name"
        value={billingDetails.name}
        onChange={handleInputChange}
      />
      <input
        name="email"
        placeholder="Email"
        value={billingDetails.email}
        onChange={handleInputChange}
      />
      <input
        name="phone"
        placeholder="Phone"
        value={billingDetails.phone}
        onChange={handleInputChange}
      />
      <input
        name="address.line1"
        placeholder="Address Line 1"
        value={billingDetails.address.line1}
        onChange={handleInputChange}
      />
      <input
        name="address.city"
        placeholder="City"
        value={billingDetails.address.city}
        onChange={handleInputChange}
      />
      <input
        name="address.state"
        placeholder="State / Region"
        value={billingDetails.address.state}
        onChange={handleInputChange}
      />
      <input
        name="address.postal_code"
        placeholder="Postal Code"
        value={billingDetails.address.postal_code}
        onChange={handleInputChange}
      />

      <StyledCardElement />
      <button type="submit" disabled={!stripe}>
        Save Card
      </button>
    </form>
  );
};

export default AddCard;
