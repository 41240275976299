import { GuideInfo, IClubExtended } from "@vibe/sdk/interfaces/Club/ClubInterface";
import { frontendUrl } from "../../AppConfig"

export const formatLink = (code: string) => `${frontendUrl}/s/${code}`;

export const MockClubExtended: IClubExtended = {
    advisors: [],
    posts: 0,
    lastPost: '',
    events: 0,
    id: '',
    org_id: '',
    status: '',
    name: '',
    member_type: [],
    gendersAllowed: [],
    club_type: '',
    clubTag: '',
    createdAt: '',
    invitesCount: {
        roster: 0,
        individual: 0,
    },
}