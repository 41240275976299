import { Outlet } from 'react-router-dom';
import { ContentHome, HomeContainer } from './styles';
import { useState } from 'react';
import HeaderHome from '../../components/Home/HeaderHome';
import { ChatServiceInterface } from '../../interfaces/chat/ChatServiceInterface';

interface HomeLayoutProps {
    ChatServiceInstance?: ChatServiceInterface;
}

const HomeLayout = (props: HomeLayoutProps) => {
    return (
        <HomeContainer>
            <HeaderHome ChatServiceInstance={props.ChatServiceInstance}/>

            <ContentHome>
                <Outlet />
            </ContentHome>
        </HomeContainer>
    );
}

export default HomeLayout;