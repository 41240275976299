import { useEffect, useMemo, useState } from "react";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import Benefit from "@vibe/sdk/dist/interfaces/Modalities/modalities/BE/BE";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { getBenefits } from "../../../store/actions/modalityActions";
import { sortAlphabeticallyOnProperty } from "../../../containers/Signup/SignupHealerInd";

const useBenefitsSA = ({
    pageSizeProp,
    searchName,
}: {
    pageSizeProp: number
    searchName: string
}) => {
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(pageSizeProp);

    const [filteredBE, setFilteredBE] = useState<Benefit[]>([]);

    const Benefits = useSelector((state: RootState) => state.getModality.getBE);

    const searchTerm = useMemo(() => searchName.toLowerCase(), [searchName]);

    const benefitsPages:Benefit[][] = useMemo(() => {
        const pages:Benefit[][] = [];

        for (let i = 0; i < Benefits.length; i+=pageSize) {
            pages.push(Benefits.slice(i, i+pageSize));
        }

        if (pages.length === 0) {
            pages.push([]);
        }

        return pages;
    }, [pageSize, Benefits])

    const reloadBenefits = () => {
        dispatch(getBenefits());
    }

    useEffect(() => {
        if (!searchTerm || searchTerm === '') {
            setFilteredBE(sortAlphabeticallyOnProperty(Benefits, 'name'));
            return;
        }
        setFilteredBE(
            (sortAlphabeticallyOnProperty(Benefits, 'name') as Benefit[])
                .filter(i => i.name.toLowerCase().includes(searchTerm))
        );
    }, [searchTerm, Benefits]);
    
    useEffect(() => reloadBenefits(), []);

    return {
        benefitsPages,
        reloadBenefits,
        setPageSize,
        filteredBE
    }

}

export default useBenefitsSA;