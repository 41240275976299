import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { FormContainer, PurchaseGVContainer } from "./purchaseGV.styles"
import { SDK } from "@vibe/sdk"
import { projectEnvironment } from "../../AppConfig"
import { IEnvironment } from "@vibe/sdk/dist/mss/MSS"
import { Stripe } from 'stripe'
import { toast } from "react-toastify"
import { useState } from "react"

const PurchaseGVComponent = () => {

    const stripe = useStripe()
    const elements = useElements()

    const sdk = new SDK({
        environment: projectEnvironment as IEnvironment,
        apiGateway: true
    })

    const [formData, setFormData] = useState({
        amount: 600,
        username: "fcvgv",
        coupon: "AuITcoDw",
    })

    const paymentHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (!stripe || !elements) {
            return
        }

        let paymentIntentId: string
        sdk.Admin.paymentIntent({ ...formData, amount: formData.amount * 100 })
            .then(paymentIntent => {
                const { body, } = paymentIntent
                if (paymentIntent.statusCode >= 400) return Promise.reject(new Error((body ?? { error: 'There was an error' } as any).error))
                const { client_secret, id, amount } = body as Stripe.Response<Stripe.PaymentIntent>

                alert(`You will pay ${amount.toFixed(2)} including charges and discount codes`)
                paymentIntentId = id

                if (!client_secret) return Promise.reject(new Error('No client secret'))

                return stripe.confirmCardPayment(client_secret, {
                    save_payment_method: true,
                    payment_method: {
                        card: elements.getElement(CardElement)!,
                        billing_details: {}
                    }
                })
            })
            .then((response) => {
                const { error, paymentIntent } = response
                if (error ?? paymentIntent?.status !== 'succeeded') return Promise.reject(error)
                return sdk.Admin.confirmPayment({ username: formData.username, paymentIntentId, recharge: true })
            })
            .then((r) => {
                if (r.statusCode >= 400) return Promise.reject(new Error('Unable to confirm payment, try again later'))
                toast.success('Recharge successful')
            })
            .catch((error) => {
                console.log(error)
                toast.error('Recharge failed')
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
    }


    return (
        <PurchaseGVContainer>
            <FormContainer onSubmit={paymentHandler}>
                <h2>Credit Card Payment: </h2>
                <input
                    type="number"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                />
                <input
                    type="text"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                />
                <input
                    type="text"
                    name="coupon"
                    value={formData.coupon}
                    onChange={handleChange}
                />
                <CardElement options={{hidePostalCode: true}} />
                <button >Pay now</button>
            </FormContainer>
        </PurchaseGVContainer>
    )
}

export default PurchaseGVComponent