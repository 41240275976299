import { ContentComingSoon } from '../AdvisorsHome/styles';
import { IconClicker } from '../HeaderHome/styles';
import { TitleHome } from '../SearchHome/styles';
import { QuestionHome, QuestionsContainerHome, QuestionsHome } from './styles';

interface QuestionItem{
    question: string;
    response: string;
    id: string;
}

const FAQHome = () => {
    const questions: QuestionItem[] = [{
        question: 'Why should I use Vibe?',
        response: '',
        id: '1',
    },
    {
        question: 'Is Vibe free?',
        response: '',
        id: '2',
    },
    {
        question: 'What type of services Vibe offers?',
        response: '',
        id: '3',
    },
    {
        question: 'Do you need an account to join Vibe?',
        response: '',
        id: '4',
    },
    {
        question: 'Why should I use Vibe?',
        response: '',
        id: '5',
    },
    {
        question: 'Why should I use Vibe?',
        response: '',
        id: '6',
    },
    {
        question: 'Why should I use Vibe?',
        response: '',
        id: '7',
    },
    {
        question: 'Why should I use Vibe?',
        response: '',
        id: '8',
    },
    {
        question: 'Why should I use Vibe?',
        response: '',
        id: '9',
    }];

    return(
        <QuestionsHome>
            <TitleHome>
                Questions? Answers
            </TitleHome>

            <ContentComingSoon>
                We're moving fast and will update the FAQ shortly.
            </ContentComingSoon>
            
            {/*<QuestionsContainerHome>
                {questions.map( q => (
                    <QuestionHome>
                        {q.question}
                        <IconClicker
                            icon="plus"
                            size="sm"
                            color="black"
                        />
                    </QuestionHome>
                ))}
                </QuestionsContainerHome>*/}
        </QuestionsHome>
    )
}

export default FAQHome;