import { useEffect, useState } from 'react'
import styled from "styled-components";
import {
    Input, listAreasModal
} from "../signupsetuphealerind"
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faSquareCheck, faSearch, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import useModalitiesSA from '../../hooks/Superadmin/Modalities/useModalitiesSA';
import { sortAlphabeticallyOnProperty } from '../../containers/Signup/SignupHealerInd';
import Modality from '@vibe/sdk/interfaces/Modalities/modalities/Modality/Modality';

interface ModalityProps {
    id: string;
    list: Modality[]
}

const ModalModalities = ({
    showModal,
    onHide,
    modalities,
    selectAll,
    setSelectAll,
}: {
    showModal: boolean,
    onHide: (value: string[]) => void,
    modalities: string[],
    selectAll: boolean,
    setSelectAll: (value: boolean) => void,
}) => {

    const { modalitiesPages, reloadModalities } = useModalitiesSA({
        pageSizeProp: 1000,
        searchName: '',
    });

    const [mods, setMods] = useState<string[]>([]);
    const [filteredModality, setFilteredModality] = useState<string>('');

    const modFiltered = () => {
        const mod: ModalityProps[] = [];
        let actualLetter = '';
        let actualList: Modality[] = []
        sortAlphabeticallyOnProperty(getFiltered(modalitiesPages[0]), 'name').forEach((modality) => {
            if (actualLetter === '') actualLetter = modality.name[0].toUpperCase();

            if (
                modality.name[0].toLowerCase() === actualLetter ||
                modality.name[0].toUpperCase() === actualLetter ||
                modality.name[0] === actualLetter
            ) {
                actualList.push(modality);
            }
            else {
                mod.push({
                    id: actualLetter,
                    list: actualList
                });
                actualList = [];
                actualLetter = modality.name[0].toUpperCase();
                actualList.push(modality);
            }
        })
        mod.push({
            id: actualLetter,
            list: actualList
        });

        return mod;
    }

    const getFiltered = (listName: any[]) => {
        return listName.filter((item: any) => {
            if (!filteredModality) return true;

            if (item.name && (
                item.name.toUpperCase().includes(filteredModality) ||
                item.name.toLowerCase().includes(filteredModality) ||
                item.name.includes(filteredModality)
            )) {
                return true;
            }

            return false;
        })
    }

    const handleModalities = (id: string) => {
        if (mods.includes(id)) {
            setMods((mods || []).filter(comm => comm !== id))
            return;
        }

        setMods([...(mods || []), id])
    }

    const handleSelectAll = (value: boolean) => {
        if (!value) {
            setMods([])
        }
        else {
            setMods(modalitiesPages[0].map((mod) => mod.id))
        }

        setSelectAll(value);
    }

    useEffect(() => {
        setMods(modalities);
    }, [modalities])

    useEffect(() => {
        modFiltered();
    }, [filteredModality])

    return (
        <>
            <Modal className="modalSetup" show={showModal}>
                <Modal.Header>
                    <Modal.Title>
                        <Label>
                            <BackIcon
                                icon={faArrowLeft}
                                size='lg'
                                color={"black"}
                                onClick={() => onHide(modalities)}
                            />
                            What modalities or services do yo offer?
                        </Label>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WrapperBody>
                        <InputIcon>
                            <SearchIcon
                                icon={faSearch}
                                size='lg'
                            />
                            <InputPlaceholder
                                placeholder="Find a modality or service"
                                value={filteredModality}
                                onChange={(e) => setFilteredModality(e.currentTarget.value)}
                            />
                        </InputIcon>
                        <ContainerTabs>
                            <List>
                                <ItemList onClick={() => handleSelectAll(!selectAll)}>
                                    <RadioIcon
                                        icon={selectAll ? faSquareCheck : faSquare}
                                        size='lg'
                                        color={"black"}
                                    />
                                    Select All
                                </ItemList>
                            </List>
                            {modFiltered().map((area: ModalityProps) => (
                                <GroupList>
                                    <TitleId>{area.id}</TitleId>
                                    <List>
                                        {area.list.map((list: Modality) => (
                                            <ItemList onClick={() => handleModalities(list.id)}>
                                                <RadioIcon
                                                    icon={mods.includes(list.id) ? faSquareCheck : faSquare}
                                                    size='lg'
                                                    color={"black"}
                                                />
                                                {list.name}
                                            </ItemList>
                                        ))}
                                    </List>
                                </GroupList>
                            ))}
                        </ContainerTabs>
                    </WrapperBody>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonCancel onClick={() => onHide(modalities)}>Cancel</ButtonCancel>
                    <ButtonSave onClick={() => onHide(mods)}>Update Modalities</ButtonSave>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const WrapperBody = styled.div`
    height: 75vh;
`;

const ButtonCancel = styled.div`
    width: 49%;
    text-align: center;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 1px solid var(--dark-blue);
    cursor: pointer;
`
const ButtonSave = styled.div`
    width: 49%;
    text-align: center;
    background-color: var(--dark-blue);
    color: white;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 0px;
    cursor: pointer;
`

const ContainerTabs = styled.div`
    overflow: auto;
    height: 85vh;
    padding-bottom: 250px;
`

const GroupList = styled.div`
`

const ItemList = styled.div`
    display: flex;
    justify-content: start;
    padding: 3px 0px 3px 0;
    align-items: center;
    font-family: 'Inter';
    font-size: 14px;
    margin-left: 0px;
`

const List = styled.div`
    padding: 15px 24px;
`

const TitleId = styled.div`
    background-color: var(--simple-gray-2);
    width: 600px;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0px 24px;
    font-family: 'Inter-Bold';
`

const Label = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    padding: 15px;
    width: 100%;
`

const RadioIcon = styled(FontAwesomeIcon)`
    font-size: 19px;
    margin-right: 20px;
    color: #3B7DED;
    cursor: pointer;
`;

const BackIcon = styled(FontAwesomeIcon)`
    font-size: 22px;
    margin-right: 15px;
    color: black;
    margin-left: -15px !important;
    cursor:pointer;
`;

const SearchIcon = styled(FontAwesomeIcon)`
    font-size: 12px;
    margin-right: 20px;
    color: var(--black);
    margin-left: 20px;
`;

const InputIcon = styled.div`
    display: flex;
    justify-content: space-between;
    width: calc(100% - 48px);
    background-color: white;
    border-radius: 20px;
    align-items: center;
    border: 1px solid var(--simple-gray);
    height: 40px;
    margin: 15px 24px; 
    
    & ${Input}{
        border: 0;
        height: 38px;
    } 
`

export const InputPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0;
    font-size: 15px;
    font-family: 'Inter';
    background-color: transparent;
`;

export const InputOnlyPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--black);
    padding-left: 20px;

    &::placeholder{
        color: var(--black);
        font-family: 'Inter-Bold'
        
    }
`;



export default ModalModalities