import React from 'react';
import './styles.scoped.css'

const Privacy = () => (
  <div style={{ overflow: 'scroll', height: 'calc(100vh - 120px)' }}>
    <div style={{ padding: 16 }}>
      <>
        <p
          className="s1"
          style={{
            paddingTop: "4pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left"
          }}
        >
          Highest Vibrations, Inc. Privacy Policy (001-2403)
        </p>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <ol id="l1">
          <li data-list-text="I.">
            <p
              className="s1"
              style={{ paddingLeft: "11pt", textIndent: "-6pt", textAlign: "left" }}
            >
              {" "}
              Introduction
            </p>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left"
              }}
            >
              Highest Vibrations, Inc. and its affiliates (collectively “VIBE,”
              “Group,” “we,” “us,”) provides a platform for alternative medicine
              providers and consumers and takes very seriously the privacy concerns of
              all of its users.
            </p>
            <p
              style={{
                paddingTop: "7pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              This General Privacy Policy addresses the privacy rights of individuals
              who:
            </p>
            <ul id="l2">
              <li data-list-text="-">
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  <a
                    href="https://www.vibe.love/"
                    style={{
                      color: "black",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      textDecoration: "none",
                      fontSize: "12pt"
                    }}
                    target="_blank"
                  >
                    visit or use our Website;{" "}
                  </a>
                  <span
                    style={{
                      color: "#467885",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      textDecoration: "underline",
                      fontSize: "12pt"
                    }}
                  >
                    https://www.vibe.love
                  </span>
                  ;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  interact with us on behalf of a CUSTOMER in connection with the
                  provision of our Services;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "24pt",
                    textIndent: "-18pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  interact with us on behalf of a SERVICE PROVIDER in connection with
                  the products and services our SERVICE PROVIDER provides to us;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "24pt",
                    textIndent: "-18pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  interact with us on behalf of a BUSINESS PARTNERin connection with
                  our relationship with the BUSINESS PARTNER;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "justify"
                  }}
                >
                  apply to work with us;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "justify"
                  }}
                >
                  receive marketing communications from us; and/or
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "24pt",
                    textIndent: "-18pt",
                    lineHeight: "108%",
                    textAlign: "justify"
                  }}
                >
                  interact with us by registering for, attending and/or otherwise
                  taking part in our trade events, webinars, or conferences or who
                  communicate with us via email, phone, or in- person.
                </p>
                <p
                  style={{
                    paddingTop: "7pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "108%",
                    textAlign: "left"
                  }}
                >
                  This General Privacy Policy, along with our GDPR Privacy Policy set
                  forth below, is designed to assist individuals and businesses that
                  interact with us to understand the types of Personal Data we
                  collect, how that Personal Data is Processed, and the practices we
                  have adopted to protect Personal Data.
                </p>
                <p
                  style={{
                    paddingTop: "7pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "108%",
                    textAlign: "left"
                  }}
                >
                  In the event of a conflict between the GDPR Privacy Policy and the
                  General Privacy Policy, the GDPR Privacy Policy will prevail.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="II.">
            <p
              className="s1"
              style={{
                paddingLeft: "14pt",
                textIndent: "-9pt",
                textAlign: "justify"
              }}
            >
              {" "}
              Definitions
            </p>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              “CCPA” means the California Consumer Privacy Act, as amended, and its
              implementing
            </p>
            <p
              style={{
                paddingTop: "1pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              regulations.
            </p>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              “Controller” means a person or organization that, alone or jointly with
              others, determines the
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              purposes and means of the Processing of Personal Data.
            </p>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              “CUSTOMER” means a business or individual that has, formerly had, or is
              contemplating
            </p>
            <p
              style={{
                paddingTop: "1pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              purchasing or using our Services.
            </p>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              “GDPR” means the EU General Data Protection Regulation 2016/679.
            </p>
            <p
              style={{
                paddingTop: "4pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              “Personal Data” means any information relating to an identified or
              identifiable natural person.
            </p>
            <p
              style={{
                paddingTop: "1pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              the Personal Data we collect may include:
            </p>
            <ul id="l3">
              <li data-list-text="-">
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "24pt",
                    textIndent: "-18pt",
                    lineHeight: "109%",
                    textAlign: "left"
                  }}
                >
                  Identifiers (e.g. name, postal address, email address, phone number,
                  IP address, account name and login information, government
                  identification number, etc.)
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    lineHeight: "15pt",
                    textAlign: "left"
                  }}
                >
                  Demographic Information (e.g. race, color, national origin,
                  religion, sex, age, disability, etc.)
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  Commercial Information (e.g. records of purchases or transactions,
                  payment history, etc.)
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  Financial Information (e.g. account number, debit card or credit
                  card number, etc.)
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  Biometric data (e.g. fingerprints, DNA, retinal scan, voice
                  recording, etc.
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  Electronic Network Activity Information (e.g. browsing history,
                  search history, etc.)
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  Geolocation data
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  Audio, electronic, visual, thermal, olfactory, or similar
                  information
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  Education and Work history (e.g. schools attended, degrees earned,
                  past employers, etc.)
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  Medical Information (e.g. medical history, health insurance
                  information)
                </p>
                <p
                  style={{
                    paddingTop: "9pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left"
                  }}
                >
                  “Privacy Policy” means this General Privacy Policy and the GDPR
                  Privacy Policy, collectively.
                </p>
                <p
                  style={{
                    paddingTop: "9pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  “Process” and “Processing” means any operation or set of operations
                  which is performed upon Personal Data, whether or not by automated
                  means, such as collection, recording, organization, storage,
                  adaptation or alteration, retrieval, consultation, use, disclosure
                  or dissemination, and erasure or destruction.
                </p>
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left"
                  }}
                >
                  “Processor” means a person or organization that engages in
                  Processing.
                </p>
                <p
                  style={{
                    paddingTop: "9pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  “Representative” means an individual who (i) acts on behalf of a
                  CUSTOMER, including, a CUSTOMER'S employees, agents, and
                  representatives, (ii) acts on behalf of a SERVICE PROVIDER,
                  including, a SERVICE PROVIDER’S employees, agents, and
                  representatives, (iii) acts on behalf of a business partner,
                  including a business partner’s employees, agents, and
                  representatives or (iv) otherwise interacts with us in any manner,
                  for example through our Website, in emails, phone calls, or
                  in-person interaction.
                </p>
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  “Sensitive Personal Information” includes, but may not be limited
                  to, Personal Data defined as special category data under the GDPR or
                  as sensitive personal information under the CCPA, which includes
                  among others, information about health, government-issued
                  identification numbers, precise geolocation, genetic data, biometric
                  data for uniquely identifying an individual, racial or ethnic
                  origin, political opinions, religious or philosophical beliefs,
                  trade union membership, or information about an individual’s sex
                  life or sexual orientation.
                </p>
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left"
                  }}
                >
                  “SERVICE PROVIDER” means a supplier, subcontractor, vendor, or other
                  third party who
                </p>
                <p
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                >
                  provides services to us.
                </p>
                <p
                  style={{
                    paddingTop: "9pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left"
                  }}
                >
                  “Services” means the products and services provided by us.
                </p>
                <p
                  style={{
                    paddingTop: "9pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left"
                  }}
                >
                  “Website” means all of the websites and mobile applications
                  maintained by us that display a
                </p>
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left"
                  }}
                >
                  link to the General Privacy Policy and/or the GDPR Privacy Policy.
                </p>
                <p
                  style={{
                    paddingTop: "4pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left"
                  }}
                >
                  “Website Visitor” means an individual who visits the Website or
                  mobile applications
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="III.">
            <p
              className="s1"
              style={{
                paddingTop: "4pt",
                paddingLeft: "17pt",
                textIndent: "-12pt",
                textAlign: "left"
              }}
            >
              {" "}
              Personal Data Collected
            </p>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              For our CUSTOMERS, we may collect the Personal Data of your
              Representatives (e.g., name, job title, business contact details, etc.)
              when your Representatives inquire about, negotiate, purchase, or use any
              of our Services on your behalf.
            </p>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "107%",
                textAlign: "justify"
              }}
            >
              For our SERVICE PROVIDERS, we may collect the Personal Data of your
              Representatives when we inquire about or purchase services from you to
              support our business operations. Personal Data collected may include
              Identifiers, Financial Information, and Geolocation Data.
            </p>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left"
              }}
            >
              For our business partners, we may collect the Personal Data of your
              Representatives in connection with our interactions with you. Personal
              Data collected may include Identifiers, Financial Information, and
              Geolocation Data.
            </p>
            <p
              style={{
                paddingTop: "7pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              For prospective employees, we may collect your Personal Data when you
              visit, browse, or register on our Websites, when you submit an
              application for employment, when you provide additional Personal Data
              during the application and interview process, when you speak to our
              employees during your interview process, and when you otherwise provide
              or authorize us to collect your Personal Data during the application and
              interview process. Personal Data collected as part of the application
              process may include Identifiers and your Education and Work History.
            </p>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              For our Website Visitors, we may collect certain Personal Data from you
              when you visit, browse, register on our Websites, complete a form on our
              Websites, or engage in online support received through our Websites. We
              may also collect certain information about your session when you visit
              our Websites, including internet protocol (IP) addresses, browser type,
              internet service provider, referring/exit pages, operating system,
              date/time stamp and clickstream data, as further outlined below.
            </p>
            <p
              style={{
                paddingTop: "7pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left"
              }}
            >
              We may also collect the Personal Data of Representatives if they (i)
              register for a trade event, webinar, or conference hosted by us, (ii)
              download or request content and information regarding our Services,
              (iii) complete a survey or form, (iv) request online support through our
              Websites, or (v) receive marketing communications from us.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
          </li>
          <li data-list-text="IV.">
            <p
              className="s1"
              style={{ paddingLeft: "18pt", textIndent: "-13pt", textAlign: "left" }}
            >
              {" "}
              Purpose of Personal Data Collection and Processing
            </p>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "justify"
              }}
            >
              We collect and Process Personal Data for the following purposes:
            </p>
            <ul id="l4">
              <li data-list-text="-">
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  To administer and process transactions related to the Services we
                  provide;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  To provide, assess, and improve our CUSTOMER support and CUSTOMER
                  service;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  To personalize your experience using our Services or Websites;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  To communicate with you during contract negotiations or
                  post-contract support;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  To fulfill a legal obligation or to protect our rights;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  To comply with applicable laws and regulations;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingTop: "3pt",
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  To advise you of additional or new Services that may be of interest
                  to your company;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  To administer and manage SERVICE PROVIDERS;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  To work with business partners;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  To improve and protect the integrity and security of our Services
                  and our Websites;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  To enforce any applicable contracts;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "24pt",
                    textIndent: "-18pt",
                    lineHeight: "109%",
                    textAlign: "left"
                  }}
                >
                  To administer a contest, promotion, survey, event, conference,
                  webinar, or other website feature;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    lineHeight: "15pt",
                    textAlign: "left"
                  }}
                >
                  To consider you for employment;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "24pt",
                    textIndent: "-18pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  To send periodic communications (the contact information that you
                  provide may be used to send you information, including marketing,
                  respond to inquiries, and/or other requests or questions);
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  In an emergency, where the health or security of an individual may
                  be endangered;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "24pt",
                    textIndent: "-18pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  In the event of a corporate reorganization, including, but not
                  limited to, merger, acquisition, or sale; and
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "24pt",
                    textIndent: "-18pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  For any other purpose for which you have been notified, and if
                  legally required where appropriate consent has been obtained.
                </p>
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  If you receive marketing communications from us by email, we seek
                  your opt-in consent to send you such communications by email in
                  jurisdictions where that is required. If we track whether or not you
                  open any such email, we seek your opt-in consent to do so in
                  jurisdictions where that is required. You may, in any event,
                  unsubscribe from the receipt of future electronic
                </p>
                <p
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                >
                  communications from us by clicking on the “unsubscribe” link
                  provided in such communications
                </p>
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left"
                  }}
                >
                  <a
                    href="mailto:unsubscribe@vibe.love"
                    style={{
                      color: "black",

                      fontStyle: "normal",
                      fontWeight: "normal",
                      textDecoration: "none",
                      fontSize: "12pt"
                    }}
                    target="_blank"
                  >
                    or by emailing{" "}
                  </a>
                  <span
                    style={{
                      color: "#467885",

                      fontStyle: "normal",
                      fontWeight: "normal",
                      textDecoration: "underline",
                      fontSize: "12pt"
                    }}
                  >
                    unsubscribe@vibe.love
                  </span>
                  .
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="V.">
            <p
              className="s1"
              style={{ paddingLeft: "15pt", textIndent: "-10pt", textAlign: "left" }}
            >
              {" "}
              Security
            </p>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              We implement a variety of security measures designed to maintain the
              safety of your Personal Data when you enter, submit, or access your
              Personal Data, or when it is otherwise collected or Processed by us. We
              take reasonable and appropriate measures to secure your Personal Data.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
          </li>
          <li data-list-text="VI.">
            <p
              className="s1"
              style={{ paddingLeft: "18pt", textIndent: "-13pt", textAlign: "left" }}
            >
              {" "}
              Cookies and Other Technologies
            </p>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              When you visit our Websites, open an email that we send you, or interact
              with the communication features on our Websites, we may collect
              information about your usage or device by automated means or by using
              technologies such as cookies, web server logs, and web beacons. Please
              view VIBE’s Cookie Policy below for information on our practices in
              relation to the use of these technologies.
            </p>
            <p
              style={{
                paddingTop: "7pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left"
              }}
            >
              You may refuse or delete cookies. If you do so, some of the
              functionality of our Website may be impaired. Additionally, you may
              still be identifiable and your usage may still be trackable by
            </p>
            <p
              style={{
                paddingTop: "4pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              other means. Please refer to your browser’s “Help” instructions to learn
              more about how to
            </p>
            <p
              style={{
                paddingTop: "1pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              manage cookies and the use of similar technologies.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
          </li>
          <li data-list-text="VII.">
            <p
              className="s1"
              style={{ paddingLeft: "21pt", textIndent: "-16pt", textAlign: "left" }}
            >
              {" "}
              Disclosures of Personal Data
            </p>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left"
              }}
            >
              Except as otherwise detailed herein, we do not sell, disclose, or
              otherwise transfer Personal Data we have collected from you to outside
              parties.
            </p>
            <ol id="l5">
              <li data-list-text="A.">
                <p
                  style={{
                    paddingTop: "7pt",
                    paddingLeft: "23pt",
                    textIndent: "-18pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  <u>SERVICE PROVIDERS</u>. We share Personal Data with SERVICE
                  PROVIDERS that we have retained to perform certain services and
                  functions on our behalf, and these SERVICE PROVIDERS have agreed to
                  use the Personal Data solely as necessary to perform the services
                  and functions in accordance with our instructions and subject to
                  appropriate nondisclosure limitations.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li data-list-text="B.">
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-18pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  <u>Business Partners</u>. We may share your Personal Data with
                  trusted business partners pursuant to our contractual arrangements
                  with them, which will include appropriate safeguards to protect any
                  Personal Data that we share with these partners. These may be third
                  parties that organize tradeshows, third party consultants and
                  experts, and auditors.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li data-list-text="C.">
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-18pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  <u>Affiliated Entities</u>. We may share Personal Data with entities
                  that are under our common ownership or control (our “Affiliates”).
                  Subject to local requirements, this Personal Data may be used to
                  provide Services offered by our Affiliates, for Affiliates to
                  provide support to the Affiliated entity that is sharing the
                  Personal Data, or for any other purposes described herein. For
                  example, our Affiliates may share Personal Data with one another
                  about our CUSTOMERS, SERVICE PROVIDERS, business partners,
                  Representatives, prospective employees, and Website Visitors for
                  direct marketing purposes.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li data-list-text="D.">
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-18pt",
                    lineHeight: "107%",
                    textAlign: "justify"
                  }}
                >
                  <u>Payment Processing</u>. We work with a payment processing partner
                  to process certain credit card payments. If you make a credit card
                  payment to us, our payment processing provider will store your full
                  name and credit card details.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li data-list-text="E.">
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-18pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  <u>Fraud Prevention and Protection of Legal Rights</u>. We may use
                  and disclose Personal Data to the appropriate legal, judicial, or
                  law enforcement authorities and our advisors and investigators: (i)
                  when we believe, in our sole discretion, that such disclosure is
                  necessary to investigate, prevent, or respond to suspected illegal
                  or fraudulent activity or to protect our safety, rights, or property
                  and those of each of our CUSTOMERS, SERVICE PROVIDERS, business
                  partners, Representatives, Website Visitors, prospective employees,
                  or others; (ii)
                </p>
                <p
                  style={{
                    paddingTop: "4pt",
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  when we suspect abuse of the Website or Services or unauthorized
                  access to any system, spamming, denial of service attacks, or
                  similar attacks; (iii) to exercise or protect legal rights or defend
                  against legal claims; or (iv) to allow us to pursue available
                  remedies or limit the damages that we may sustain.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li data-list-text="F.">
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-18pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  <u>Law Enforcement</u>. We may have to disclose the Personal Data of
                  our CUSTOMERS, SERVICE PROVIDERS, business partners,
                  Representatives, prospective employees, Website Visitors, or others
                  if a court, law enforcement, or other public or government authority
                  with appropriate competency requests that we provide that Personal
                  Data and we believe, in our reasonable discretion, that such request
                  was made in compliance with applicable law.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li data-list-text="G.">
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-18pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  <u>Corporate Reorganization</u>. We may share your Personal Data
                  with a third party in the case of the reorganization, sale, merger,
                  joint venture, assignment, transfer or other disposition of all or
                  any portion of our business, asset or stocks, including in the event
                  of bankruptcy or corporate restructuring. Any Personal Data that an
                  individual submits or that is collected after the reorganization may
                  be subject to a new privacy policy adopted by the successor entity,
                  of which we will inform, where required.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ol>
          </li>
          <li data-list-text="VIII.">
            <p
              className="s1"
              style={{ paddingLeft: "24pt", textIndent: "-19pt", textAlign: "left" }}
            >
              {" "}
              Retention
            </p>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "justify"
              }}
            >
              We will keep the Personal Data that we collect for as long as is
              reasonably necessary to achieve the purpose for which you provided it,
              or to the extent necessary for us to protect our rights, or as required
              by applicable laws.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
          </li>
          <li data-list-text="IX.">
            <p
              className="s1"
              style={{ paddingLeft: "17pt", textIndent: "-12pt", textAlign: "left" }}
            >
              {" "}
              Collection of Personal Data from Children
            </p>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              <a
                href="mailto:privacytos@vibe.love"
                style={{
                  color: "black",

                  fontStyle: "normal",
                  fontWeight: "normal",
                  textDecoration: "none",
                  fontSize: "12pt"
                }}
                target="_blank"
              >
                Our Websites are intended for individuals 18 years of age and older.
                They are not directed at, marketed to, nor intended for, children
                under 18 years of age. We do not knowingly collect any information,
                including Personal Data, from children under 18 years of age. If you
                believe that we have inadvertently collected Personal Data from a
                child under the age of 18, please contact us at{" "}
              </a>
              <span
                style={{
                  color: "#467885",

                  fontStyle: "normal",
                  fontWeight: "normal",
                  textDecoration: "underline",
                  fontSize: "12pt"
                }}
              >
                privacytos@vibe.love
              </span>
              , and we will take immediate steps to delete it.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
          </li>
          <li data-list-text="X.">
            <p
              className="s1"
              style={{ paddingLeft: "14pt", textIndent: "-9pt", textAlign: "left" }}
            >
              {" "}
              Changes to Privacy Policy
            </p>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              When we change this General Privacy Policy, including the GDPR Privacy
              Policy, we will update
            </p>
            <p
              style={{
                paddingTop: "1pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              the “Effective Date” or the “Last Updated Date” for this General Privacy
              Policy and/or the
            </p>
            <p
              style={{
                paddingTop: "1pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              “Effective Date” or the “Last Updated Date” for the respective GDPR
              Privacy Policy portions. We
            </p>
            <p
              style={{
                paddingTop: "4pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "107%",
                textAlign: "justify"
              }}
            >
              encourage you to view this General Privacy Policy, including the GDPR
              Privacy Policy, when interacting with us to ensure you are aware of the
              current terms. We will provide adequate notice of any material changes
              and obtain your consent when legally required when making such changes
              to this General Privacy Policy, including the GDPR Privacy Policy.
            </p>
          </li>
          <li data-list-text="XI.">
            <p
              className="s1"
              style={{
                paddingTop: "4pt",
                paddingLeft: "17pt",
                textIndent: "-12pt",
                textAlign: "left"
              }}
            >
              {" "}
              Inquiries/Contact Us
            </p>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              <a
                href="mailto:privacytos@vibe.love"
                style={{
                  color: "black",

                  fontStyle: "normal",
                  fontWeight: "normal",
                  textDecoration: "none",
                  fontSize: "12pt"
                }}
                target="_blank"
              >
                You may have rights regarding your Personal Data depending on where
                you are and where your Personal Data is Processed. Please contact us
                at the email address below if you have questions in this regard or if
                you wish to update your Personal Data. Please note that if you have an
                account with us, you may update certain information through that
                account. We commit to resolving complaints about your privacy and our
                collection or use of your Personal Data. Should you have any questions
                regarding this General Privacy Policy, the GDPR Privacy Policy or any
                of its or their provisions, you may contact us at:{" "}
              </a>
              <span
                style={{
                  color: "#467885",

                  fontStyle: "normal",
                  fontWeight: "normal",
                  textDecoration: "underline",
                  fontSize: "12pt"
                }}
              >
                privacytos@vibe.love
              </span>
              .
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
          </li>
          <li data-list-text="XII.">
            <p
              className="s1"
              style={{ paddingLeft: "21pt", textIndent: "-16pt", textAlign: "left" }}
            >
              {" "}
              Governing Law; Venue; Waiver of Jury Trial and Class Actions
            </p>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              Unless applicable data protection / privacy laws provide otherwise, (a)
              the Privacy Policy is governed by the laws of the State of Florida,
              U.S.A, (b) you hereby agree that any dispute or claim raised or made by
              you against us relating to the Privacy Policy shall be subject to
              arbitration before a single arbitrator in Miami-Dade County, Florida in
              accordance with the Commercial Arbitration Rules of the American
              Arbitration Association and (c) you hereby waive all rights to bring or
              maintain any court action, jury trial or any class claim, class action,
              class arbitration, or other representative action, claim or proceeding
              against us in a court of law.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
          </li>
          <li data-list-text="XIII.">
            <p
              className="s1"
              style={{ paddingLeft: "24pt", textIndent: "-19pt", textAlign: "left" }}
            >
              {" "}
              Your Data Privacy Rights
            </p>
          </li>
        </ol>
        <p
          className="s3"
          style={{
            paddingTop: "9pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "107%",
            textAlign: "left"
          }}
        >
          <a
            href="mailto:privacytos@vibe.love"
            style={{
              color: "black",

              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "none",
              fontSize: "12pt"
            }}
            target="_blank"
          >
            Depending on where you live you may have certain rights with respect to
            your Personal Data, as described below. You may submit a request to
            exercise one or more of these rights by emailing us at{" "}
          </a>
          <span
            style={{
              color: "#467885",

              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "underline",
              fontSize: "12pt"
            }}
          >
            privacytos@vibe.love
          </span>{" "}
          <span style={{ color: "#000" }}>
            with the subject line “Privacy Rights Request” and letting us know which
            country or US state you live in, or as otherwise described below. Once we
            receive a request to exercise your data privacy rights, we may be required
            to verify your identity before proceeding. We verify identities by
            comparing certain details you provide (such as name, email, and
            relationship) with information we have. In some cases, we may need
            additional details to verify your identity. In certain circumstances, we
            may decline a request to exercise the rights below, particularly where we
            are unable to verify your identity or locate your information in our
            systems, or as otherwise permitted by law.
          </span>
        </p>
        <ol id="l6">
          <li data-list-text="A.">
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "17pt",
                textIndent: "-12pt",
                textAlign: "left"
              }}
            >
              <u>California</u> If you live in California, you have the following
              rights:
            </p>
            <ol id="l7">
              <li data-list-text={1}>
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "23pt",
                    textIndent: "-18pt",
                    lineHeight: "108%",
                    textAlign: "left"
                  }}
                >
                  <u>Right to Know.</u> You can ask us to give you information about
                  our collection and use of your Personal Data. Specifically, you can
                  request that we provide you one or more of the following:
                </p>
                <ol id="l8">
                  <li data-list-text="(i)">
                    <p
                      style={{
                        paddingTop: "7pt",
                        paddingLeft: "35pt",
                        textIndent: "-12pt",
                        textAlign: "left"
                      }}
                    >
                      the categories of Personal Data we collected about you;
                    </p>
                  </li>
                  <li data-list-text="(ii)">
                    <p
                      style={{
                        paddingTop: "8pt",
                        paddingLeft: "38pt",
                        textIndent: "-15pt",
                        textAlign: "left"
                      }}
                    >
                      the categories of sources from which we collected your Personal
                      Data;
                    </p>
                  </li>
                  <li data-list-text="(iii)">
                    <p
                      style={{
                        paddingTop: "4pt",
                        paddingLeft: "41pt",
                        textIndent: "-18pt",
                        textAlign: "left"
                      }}
                    >
                      our purposes for collecting your Personal Data;
                    </p>
                  </li>
                  <li data-list-text="(iv)">
                    <p
                      style={{
                        paddingTop: "9pt",
                        paddingLeft: "41pt",
                        textIndent: "-18pt",
                        textAlign: "left"
                      }}
                    >
                      the categories of third parties to whom we disclose your
                      Personal Data
                    </p>
                  </li>
                  <li data-list-text="(v)">
                    <p
                      style={{
                        paddingTop: "8pt",
                        paddingLeft: "38pt",
                        textIndent: "-15pt",
                        textAlign: "left"
                      }}
                    >
                      the specific pieces of Personal Data we collected about you.
                    </p>
                  </li>
                </ol>
              </li>
              <li data-list-text={2}>
                <p
                  style={{
                    paddingTop: "9pt",
                    paddingLeft: "23pt",
                    textIndent: "-18pt",
                    lineHeight: "108%",
                    textAlign: "left"
                  }}
                >
                  <u>Right to Delete</u>. You can ask us to delete your Personal Data
                  that we collected from you, subject to certain limitations.
                </p>
              </li>
              <li data-list-text={3}>
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-18pt",
                    lineHeight: "106%",
                    textAlign: "left"
                  }}
                >
                  <u>Right to Correct</u>. You can ask us to correct any inaccurate
                  Personal Data that we have about you.
                </p>
              </li>
              <li data-list-text={4}>
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-18pt",
                    textAlign: "left"
                  }}
                >
                  <u>Right to Opt-Out of Sale or Sharing</u>. Our disclosure of your
                  Personal Data to third party
                </p>
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  advertising and analytics providers may constitute a sale or
                  “sharing” (which is a term used to address the sharing of
                  information for advertising purposes) under California privacy law.
                  To the extent that our use constitutes a sale or sharing of your
                  Personal Data, you have the right to opt-out by (a) enabling an
                  opt-out preference signal or Global Privacy Control on your browser,
                  or (b) opting-out of cookies through the cookie banner.
                </p>
              </li>
              <li data-list-text={5}>
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-18pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  <u>
                    Right to Limit Use and Disclosure of Sensitive Personal
                    Information
                  </u>
                  . When we collect information including government identification
                  number, health information, or financial account information, we are
                  deemed to be collecting data that is “sensitive” under California
                  law. Consumers have the right to limit the use and disclosure of
                  sensitive
                </p>
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  information when used or disclosed for a purpose other than what is
                  specified in the CCPA. We do not use it for any purpose that is
                  inconsistent with the CCPA and its implementing regulations. We also
                  do not use it for purposes of making inferences. So, this right does
                  not apply.
                </p>
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  You may also have your authorized agent make a request on your
                  behalf. If you have an agent making a request, we require that (i)
                  the agent provide us proof that you gave permission to submit the
                  request and/or (ii) you verify your identity with us and confirm you
                  gave the agent permission to submit the request.
                </p>
              </li>
            </ol>
          </li>
          <li data-list-text="B.">
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "107%",
                textAlign: "justify"
              }}
            >
              <u>Colorado, Connecticut, Virginia and Utah</u>. Privacy laws in these
              states give consumers certain rights with respect to their Personal
              Data, when they take effect over the course of 2023. Those rights
              include:
            </p>
            <ol id="l9">
              <li data-list-text={6}>
                <p
                  style={{
                    paddingTop: "7pt",
                    paddingLeft: "23pt",
                    textIndent: "-18pt",
                    lineHeight: "108%",
                    textAlign: "left"
                  }}
                >
                  <u>Right to Access Information</u>. You have the right to access and
                  obtain a copy of your Personal Data.
                </p>
              </li>
              <li data-list-text={7}>
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-18pt",
                    lineHeight: "108%",
                    textAlign: "left"
                  }}
                >
                  <u>Right to Request Deletion</u>. You have the right to request that
                  we delete Personal Data provided by or obtained about you.
                </p>
              </li>
              <li data-list-text={8}>
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-18pt",
                    lineHeight: "15pt",
                    textAlign: "left"
                  }}
                >
                  <u>Right to Correct</u>. You have the right to correct inaccuracies
                  in your Personal Data.
                </p>
              </li>
              <li data-list-text={9}>
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-18pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  <u>Right to Opt-Out of Targeted Advertising</u>. You can ask us to
                  not use or disclose your information for the purposes of targeted
                  advertising to you based on your Personal Data obtained from your
                  activity across different businesses, services, websites, etc.
                  Please see the California rights section above for a description of
                  how to exercise your right to opt-out of targeted advertising.
                </p>
                <p
                  style={{
                    paddingTop: "4pt",
                    paddingLeft: "6pt",
                    textIndent: "0pt",
                    lineHeight: "115%",
                    textAlign: "left"
                  }}
                >
                  <a
                    href="mailto:privacytos@vibe.love"
                    style={{
                      color: "black",

                      fontStyle: "normal",
                      fontWeight: "normal",
                      textDecoration: "none",
                      fontSize: "12pt"
                    }}
                    target="_blank"
                  >
                    Residents of Colorado, Connecticut, Virginia and Utah can appeal a
                    refusal to take action on a request by contacting us by email at{" "}
                  </a>
                  <a
                    href="mailto:privacytos@vibe.love"
                    className="s4"
                    target="_blank"
                  >
                    privacytos@vibe.love.
                  </a>
                </p>
              </li>
            </ol>
          </li>
          <li data-list-text="C.">
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left"
              }}
            >
              <u>Canada, Japan and Singapore.</u> If you live in one of these
              countries, you have the following rights:
            </p>
          </li>
        </ol>
        <ol id="l10">
          <li data-list-text={10}>
            <p
              style={{
                paddingTop: "7pt",
                paddingLeft: "23pt",
                textIndent: "-18pt",
                lineHeight: "106%",
                textAlign: "left"
              }}
            >
              <u>Right to Access</u>. You can ask us to (i) confirm that we have
              Personal Data about you and (ii) provide you a copy of that information.
            </p>
          </li>
          <li data-list-text={11}>
            <p
              style={{
                paddingLeft: "23pt",
                textIndent: "-18pt",
                lineHeight: "108%",
                textAlign: "left"
              }}
            >
              <u>Right to Correct</u>. You can ask us to correct any inaccurate or
              incomplete Personal Data that we have about you.
            </p>
          </li>
        </ol>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <p
          className="s1"
          style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
        >
          VIBE’S Cookie Policy
        </p>
        <ol id="l11">
          <li data-list-text={1}>
            <h1
              style={{
                paddingTop: "9pt",
                paddingLeft: "16pt",
                textIndent: "-11pt",
                textAlign: "left"
              }}
            >
              What are Cookies and why does VIBE use them?
            </h1>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              Cookies are small text files that help your web browser navigate through
              a particular website and that are stored on your device when you visit a
              website. VIBE uses cookies and similar technologies to facilitate your
              use of our Website, to customize your browsing experience, to track your
              movements within our Website, and to analyze usage trends and patterns.
              Our Website uses “first party cookies” (i.e., cookies served by us) or
              “third party cookies” (i.e., cookies served by third-parties that we
              work with). Please note that the processing of any
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left"
              }}
            >
              information collected from you in connection with cookies used by our
              Website is governed by our Privacy Policy.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
          </li>
          <li data-list-text={2}>
            <h1
              style={{ paddingLeft: "16pt", textIndent: "-11pt", textAlign: "left" }}
            >
              Types of Cookies used by VIBE.
            </h1>
            <h1
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              The types of cookies which may be used by VIBE from time to time are
              detailed below:
            </h1>
            <ul id="l12">
              <li data-list-text="-">
                <h1
                  style={{
                    paddingTop: "9pt",
                    paddingLeft: "24pt",
                    textIndent: "-18pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  Strictly Necessary Cookies.{" "}
                  <span className="p">
                    These cookies are necessary for our Website to function and cannot
                    be switched off without impairing the functioning of our Website.
                    They are set to respond to actions taken by you to make a request
                    for services, such as setting your privacy preferences or filling
                    in forms. You can set your browser to block or alert you about
                    these cookies, but if you do so, some parts of our Website will
                    not work.
                  </span>
                </h1>
              </li>
              <li data-list-text="-">
                <h1
                  style={{
                    paddingLeft: "24pt",
                    textIndent: "-18pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  Performance Cookies.{" "}
                  <span className="p">
                    These cookies allow us to count visits and traffic sources so we
                    can measure and improve the performance of our Website. They help
                    us to know which pages are the most and least popular and see how
                    visitors move around the Website. All information these cookies
                    collect is aggregated and therefore anonymous. If you do not allow
                    these cookies we will not know when you have visited our Website
                    and will not be able to monitor its performance during your visit.
                  </span>
                </h1>
              </li>
              <li data-list-text="-">
                <h1
                  style={{
                    paddingLeft: "24pt",
                    textIndent: "-18pt",
                    lineHeight: "109%",
                    textAlign: "left"
                  }}
                >
                  Functional Cookies.{" "}
                  <span className="p">
                    These cookies enable our Website to provide enhanced functionality
                    and personalization. They may be set by us or by third party
                    providers whose services we
                  </span>
                </h1>
                <p
                  style={{
                    paddingTop: "4pt",
                    paddingLeft: "24pt",
                    textIndent: "0pt",
                    lineHeight: "108%",
                    textAlign: "left"
                  }}
                >
                  have added to our pages. If you do not allow these cookies then some
                  or all of the services associated with these cookies may not
                  function properly.
                </p>
              </li>
              <li data-list-text="-">
                <h1
                  style={{
                    paddingLeft: "24pt",
                    textIndent: "-18pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  Targeting Cookies.{" "}
                  <span className="p">
                    These cookies may be set through our Website by our advertising
                    partners. They may be used by those companies to build a profile
                    of your interests and show you relevant advertisements on other
                    sites. They do not directly store personal information but are
                    based on uniquely identifying your browser and device. If you do
                    not allow these cookies, you will experience less targeted
                    advertising.
                  </span>
                </h1>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text={3}>
            <h1
              style={{ paddingLeft: "16pt", textIndent: "-11pt", textAlign: "left" }}
            >
              Managing Cookies on the VIBE Website.
            </h1>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "justify"
              }}
            >
              Depending on your region, a pop-up window may appear the first time you
              visit our website, informing you that our website uses cookies, and
              allowing you to set your cookie preferences for your device. Please be
              aware that this does not apply to strictly necessary cookies.
            </p>
            <p
              style={{
                paddingTop: "7pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left"
              }}
            >
              You do not have to accept all of the cookies used by our Website. If you
              wish to delete or block cookies, you can do so at any time by deleting
              existing cookies from your browser and activating the appropriate
              setting on your browser.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
          </li>
          <li data-list-text={4}>
            <h1
              style={{ paddingLeft: "16pt", textIndent: "-11pt", textAlign: "left" }}
            >
              VIBE’s Use of Google Analytics.
            </h1>
          </li>
        </ol>
        <p
          style={{
            paddingTop: "9pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "107%",
            textAlign: "left"
          }}
        >
          This Website also uses Google Analytics, a third-party web analytics tool
          that helps website owners understand how their visitors engage with their
          website. Google Analytics collects information anonymously, and reports web
          site trends without personally identifying individual visitors. Google
          Analytics uses its own cookie to track visitor interactions. The Google
          Analytics cookie is used to store information, such as what time the current
          visit occurred, whether the visitor has been to the site before, and what
          site referred the visitor to the web page. A different Google Analytics
          cookie is used for each website that uses the Google Analytics tool, and
          visitors are not tracked across multiple websites. To disable this type of
          cookie, some browsers will indicate when a cookie is being sent and allow
          you to decline cookies on a case- by-case basis. In addition to declining
          cookies, you can also install the Google Analytics Opt-out Add-on in your
          browser, which prevents Google Analytics from collecting information about
          your website visits. Information collected by the Google Analytics cookie is
          transmitted to, and stored by, Google in accordance with its privacy
          practices.
        </p>
        <p
          style={{
            paddingTop: "8pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left"
          }}
        >
          <a
            href="https://support.google.com/analytics/answer/6004245"
            style={{
              color: "black",

              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "none",
              fontSize: "12pt"
            }}
            target="_blank"
          >
            To see an overview of privacy at Google and how this applies to Google
            Analytics, please visit:{" "}
          </a>
          <span
            style={{
              color: "#0462C1",

              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "underline",
              fontSize: "12pt"
            }}
          >
            https://support.google.com/analytics/answer/6004245
          </span>
          .
        </p>
        <p
          style={{
            paddingTop: "7pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left"
          }}
        >
          <a
            href="https://tools.google.com/dlpage/gaoptout"
            style={{
              color: "black",

              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "none",
              fontSize: "12pt"
            }}
            target="_blank"
          >
            To opt out of Google Analytics, please visit:{" "}
          </a>
          <span
            style={{
              color: "#0462C1",

              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "underline",
              fontSize: "12pt"
            }}
          >
            https://tools.google.com/dlpage/gaoptout
          </span>
          .
        </p>
        <p
          className="s1"
          style={{
            paddingTop: "4pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left"
          }}
        >
          VIBE GDPR Privacy Policy
        </p>
        <ol id="l13">
          <li data-list-text="I.">
            <h1
              style={{
                paddingTop: "9pt",
                paddingLeft: "14pt",
                textIndent: "-9pt",
                textAlign: "left"
              }}
            >
              Scope
            </h1>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left"
              }}
            >
              Please read this document carefully. This GDPR Privacy Policy applies to
              the Processing of Personal Data by our entities located within the EEA,
              including in the UK (listed in Section III below), in their role as
              Controllers, or as otherwise covered by the GDPR, when individuals:
            </p>
            <ul id="l14">
              <li data-list-text="-">
                <p
                  className="s3"
                  style={{
                    paddingTop: "7pt",
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  <a
                    href="https://www.vibe.love/app"
                    style={{
                      color: "black",

                      fontStyle: "normal",
                      fontWeight: "normal",
                      textDecoration: "none",
                      fontSize: "12pt"
                    }}
                    target="_blank"
                  >
                    visit or use our Websites;{" "}
                  </a>
                  <span
                    style={{
                      color: "#467885",

                      fontStyle: "normal",
                      fontWeight: "normal",
                      textDecoration: "underline",
                      fontSize: "12pt"
                    }}
                  >
                    https://www.vibe.love/app
                  </span>{" "}
                  <span style={{ color: "#000" }}>and mobile applications:</span>
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  interact with us on behalf of a CUSTOMER in connection with the
                  provision of our Services;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "24pt",
                    textIndent: "-18pt",
                    lineHeight: "109%",
                    textAlign: "left"
                  }}
                >
                  interact with us on behalf of a SERVICE PROVIDER in connection with
                  the products and services our SERVICE PROVIDER provides to us;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "24pt",
                    textIndent: "-18pt",
                    lineHeight: "109%",
                    textAlign: "left"
                  }}
                >
                  interact with us on behalf of a BUSINESS PARTNERin connection with
                  our relationship with the business partner;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    lineHeight: "15pt",
                    textAlign: "left"
                  }}
                >
                  apply to work with us;
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "-17pt",
                    textAlign: "left"
                  }}
                >
                  receive marketing communications from us; and/or
                </p>
              </li>
              <li data-list-text="-">
                <p
                  style={{
                    paddingLeft: "24pt",
                    textIndent: "-18pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  interact with us by registering for, attending and/or otherwise
                  taking part in our trade events, webinars, or conferences or
                  communicate with us via email, phone, or in-person.
                </p>
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  This GDPR Privacy Policy does not apply to any Personal Data
                  Processed, stored, or hosted by CUSTOMERS using any of our Services
                  or to the extent that we Process Personal Data in the role of a
                  Processor on behalf of our CUSTOMERS. Where we act as Processors on
                  behalf of our CUSTOMERS, that Processing is subject to the
                  protections contained in our data processing agreements with
                  CUSTOMERS. We have no control over, and are not responsible for, any
                  Personal Data that our CUSTOMERS may store or host on their
                  equipment or otherwise process while using our Services. We are not
                  responsible for the privacy or data security practices of our
                  CUSTOMERS, which may differ from those set forth in this GDPR
                  Privacy Policy. For information related to how our CUSTOMERS Process
                  Personal Data, please contact the respective CUSTOMER directly.
                </p>
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  Furthermore, this GDPR Privacy Policy does not apply to any
                  third-party website or service that may be linked to the Websites
                  unless that website or service is controlled by us and displays this
                  GDPR Privacy Policy. We have no control over, and are not
                  responsible for, the data collection and/or handling practices of
                  these third-party websites or services outside our Websites. We
                  encourage you to read the privacy statements of any third-party
                  websites or services linking to (or linked to via) the Website. In
                  the event of a conflict between this GDPR Privacy Policy and the
                  General Privacy Policy, this GDPR Privacy Policy will prevail
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="II.">
            <h1
              style={{
                paddingTop: "8pt",
                paddingLeft: "17pt",
                textIndent: "-12pt",
                textAlign: "left"
              }}
            >
              Definitions
            </h1>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              Please see the definitions as presented in the General Privacy Policy.
            </p>
          </li>
          <li data-list-text="III.">
            <h1
              style={{
                paddingTop: "9pt",
                paddingLeft: "20pt",
                textIndent: "-15pt",
                textAlign: "left"
              }}
            >
              Our Contact Details
            </h1>
            <p
              style={{
                paddingTop: "4pt",
                paddingLeft: "6pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              <a
                href="mailto:privacytos@vibe.love"
                style={{
                  color: "black",

                  fontStyle: "normal",
                  fontWeight: "normal",
                  textDecoration: "none",
                  fontSize: "12pt"
                }}
                target="_blank"
              >
                If you have any questions or concerns as to how your Personal Data is
                Processed, please write to us at{" "}
              </a>
              <a href="mailto:privacytos@vibe.love" className="s4" target="_blank">
                privacytos@vibe.love
              </a>
            </p>
          </li>
          <li data-list-text="IV.">
            <h1
              style={{
                paddingTop: "4pt",
                paddingLeft: "22pt",
                textIndent: "-16pt",
                textAlign: "left"
              }}
            >
              VIBE’s Data Collection Practices
            </h1>
            <ol id="l15">
              <li data-list-text="A.">
                <h1
                  style={{
                    paddingTop: "10pt",
                    paddingLeft: "15pt",
                    textIndent: "-10pt",
                    textAlign: "left"
                  }}
                >
                  <u> ​What Types of Personal Data Does VIBE Collect</u>?
                </h1>
                <p
                  style={{
                    paddingTop: "9pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "107%",
                    textAlign: "left"
                  }}
                >
                  VIBE collects and processes the following categories of Personal
                  Data from CUSTOMERS, SERVICE PROVIDERS, business partners,
                  Representatives, Website Visitors, prospective employees,
                  individuals that receive marketing communications from VIBE and
                  individuals that interact with VIBE by registering for, attending
                  and/or otherwise taking part in VIBE’s trade events, webinars or
                  conferences or who communicate with VIBE via email, phone or in
                  person, in each case to operate its business for the specific
                  purposes identified below.
                </p>
                <ul id="l16">
                  <li data-list-text="-">
                    <h1
                      style={{
                        paddingTop: "7pt",
                        paddingLeft: "24pt",
                        textIndent: "-18pt",
                        lineHeight: "107%",
                        textAlign: "left"
                      }}
                    >
                      Personal Details{" "}
                      <span className="p">
                        may include some combination of the following data, name, date
                        of birth, gender, interests, health conditions, titles,
                        activities, events, as well as, titles, company names,
                        departments, and contact information such as email addresses,
                        physical street addresses, telephone numbers, and social media
                        usernames of individuals.
                      </span>
                    </h1>
                  </li>
                  <li data-list-text="-">
                    <h1
                      style={{
                        paddingLeft: "24pt",
                        textIndent: "-18pt",
                        lineHeight: "107%",
                        textAlign: "justify"
                      }}
                    >
                      Login Credentials{" "}
                      <span className="p">
                        include data such as usernames and passwords of individuals
                        needed to access various CUSTOMER portals or applications used
                        to place Service orders and receive CUSTOMER support or
                        otherwise access VIBE systems.
                      </span>
                    </h1>
                  </li>
                  <li data-list-text="-">
                    <h1
                      style={{
                        paddingLeft: "24pt",
                        textIndent: "-18pt",
                        lineHeight: "107%",
                        textAlign: "left"
                      }}
                    >
                      Unique IDs{" "}
                      <span className="p">
                        include data such as IP addresses and geolocation data that we
                        obtain from (a) Representatives, (b) prospective employees,
                        (c) Website Visitors who access our CUSTOMER portals or
                        Websites, or (d) other individuals that interact with us.
                      </span>
                    </h1>
                  </li>
                  <li data-list-text="-">
                    <h1
                      style={{
                        paddingLeft: "24pt",
                        textIndent: "-18pt",
                        lineHeight: "109%",
                        textAlign: "left"
                      }}
                    >
                      Payment Information{" "}
                      <span className="p">
                        includes data such as bank name, account numbers, routing
                        numbers, check numbers, and wire transfer IDs.
                      </span>
                    </h1>
                  </li>
                  <li data-list-text="-">
                    <h1
                      style={{
                        paddingLeft: "24pt",
                        textIndent: "-18pt",
                        lineHeight: "109%",
                        textAlign: "left"
                      }}
                    >
                      CUSTOMER Support Records{" "}
                      <span className="p">
                        include data such as call details and other similar data
                        regarding CUSTOMER support communications and chat sessions
                        with Representatives.
                      </span>
                    </h1>
                  </li>
                  <li data-list-text="-">
                    <h1
                      style={{
                        paddingLeft: "24pt",
                        textIndent: "-18pt",
                        lineHeight: "107%",
                        textAlign: "left"
                      }}
                    >
                      Access Credentials and Visitation Records{" "}
                      <span className="p">
                        include data such as the dates, times and locations of access
                        to our data centers, photographs of Representatives with
                        access privileges, CCTV recordings, and biometric access
                        credentials, including, fingerprint scans.
                      </span>
                    </h1>
                  </li>
                  <li data-list-text="-">
                    <h1
                      style={{
                        paddingLeft: "24pt",
                        textIndent: "-18pt",
                        lineHeight: "107%",
                        textAlign: "left"
                      }}
                    >
                      Website Records{" "}
                      <span className="p">
                        include data related your interactions with our Websites and
                        other online content such as log data (i.e., preferences and
                        settings, IP addresses, technical information about the device
                        used to visit the Websites, and geolocation information) and
                        traffic data (i.e., pages viewed, date stamps, time spent on a
                        page, click through and clickstream data, queries made, search
                        history, search results selected, comments made, type of
                        service requested, and purchases made).
                      </span>
                    </h1>
                  </li>
                  <li data-list-text="-">
                    <h1
                      style={{
                        paddingLeft: "24pt",
                        textIndent: "-18pt",
                        lineHeight: "107%",
                        textAlign: "left"
                      }}
                    >
                      Education and Work History{" "}
                      <span className="p">
                        includes details such as attended schools, marks/grades, past
                        employers, descriptions of roles performed, locations of
                        employment, and reasons for leaving past employment.
                      </span>
                    </h1>
                  </li>
                  <li data-list-text="-">
                    <h1
                      style={{
                        paddingLeft: "24pt",
                        textIndent: "-18pt",
                        lineHeight: "107%",
                        textAlign: "left"
                      }}
                    >
                      Marketing and Event Records{" "}
                      <span className="p">
                        include the personal details of the Representative signing up
                        to receive marketing materials as well as information
                        collected from Representatives who complete a survey or form.
                        Marketing records also include the personal details of
                        Representatives who register for, attend and/or otherwise take
                        part in our trade events, webinars, or conferences as well as
                        information about these events.
                      </span>
                    </h1>
                  </li>
                </ul>
              </li>
              <li data-list-text="B.">
                <h1
                  style={{
                    paddingTop: "4pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "106%",
                    textAlign: "left"
                  }}
                >
                  <u>
                    {" "}
                    Why Does VIBE Collect Personal Data, What are the Sources of
                    Personal Data, What are the
                  </u>{" "}
                  <u>Purposes for Processing, and What is the Lawful Basis</u>?
                </h1>
              </li>
            </ol>
          </li>
        </ol>
        <p
          style={{
            paddingTop: "8pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "107%",
            textAlign: "left"
          }}
        >
          This section of the GDPR Privacy Policy covers VIBE’s collection of data
          necessary for the establishment of relations with or provision of Services
          to existing CUSTOMERS, the establishment of relationships with or receipt of
          services from our SERVICE PROVIDERS, the establishment of relations with or
          interactions with business partners, interactions with our Website Visitors,
          interactions with applicants for employment, interactions with those that
          receive marketing communications from VIBE and interactions with those that
          register for, attend and/or otherwise take part in VIBE’s trade events,
          webinars or conferences or who communicate with VIBE via email, phone or
          in-person.
        </p>
        <p
          style={{
            paddingTop: "8pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "107%",
            textAlign: "left"
          }}
        >
          The list below sets out the types of Personal Data VIBE Processes, the
          purposes of Processing such Personal Data, and VIBE’s lawful basis for doing
          so. The lawful basis will vary with the type of Processing involved and will
          typically include Processing (i) necessary for VIBE to pursue its legitimate
          business interests, (ii) based on your consent, where this is required by
          data protection laws, and (iii) necessary for VIBE to comply with its legal
          obligations. Where we rely on our legitimate business interests, we have
          explained what the grounds are for that reliance.
        </p>
        <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <h2 style={{ paddingLeft: "12pt", textIndent: "0pt", textAlign: "left" }}>
          VIBE’s Purpose of Processing Personal Data
        </h2>
        <h2
          style={{
            paddingTop: "8pt",
            paddingLeft: "12pt",
            textIndent: "0pt",
            lineHeight: "107%",
            textAlign: "left"
          }}
        >
          To engage in transactions with CUSTOMERS, SERVICE PROVIDERS and business
          partners.{" "}
          <span className="s6">
            When a CUSTOMER places an order for our Services, VIBE Processes the
            following Categories of Personal Data to engage in and administer the
            relevant transactions necessary to deliver and provide such Services to
            its CUSTOMER (i.e., signing a contract or service order, creating an
            account, sending invoices, receiving payments, granting access to CUSTOMER
            portals). VIBE also collects and processes Personal Data when engaging
            with and purchasing products and services from SERVICE PROVIDERS or
            business partners.
          </span>
        </h2>
        <ul id="l17">
          <li data-list-text="-">
            <p
              className="s6"
              style={{
                paddingTop: "8pt",
                paddingLeft: "31pt",
                textIndent: "-17pt",
                textAlign: "left"
              }}
            >
              Personal Details
            </p>
          </li>
          <li data-list-text="-">
            <p
              className="s6"
              style={{
                paddingTop: "1pt",
                paddingLeft: "31pt",
                textIndent: "-17pt",
                textAlign: "left"
              }}
            >
              Login Credentials
            </p>
          </li>
          <li data-list-text="-">
            <p
              className="s6"
              style={{
                paddingTop: "1pt",
                paddingLeft: "31pt",
                textIndent: "-17pt",
                textAlign: "left"
              }}
            >
              Unique IDs
            </p>
          </li>
          <li data-list-text="-">
            <p
              className="s6"
              style={{ paddingLeft: "31pt", textIndent: "-17pt", textAlign: "left" }}
            >
              Payment Information
            </p>
            <h2
              style={{
                paddingTop: "9pt",
                paddingLeft: "12pt",
                textIndent: "0pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              To provide CUSTOMER and technical support
              <span className="s6">
                . VIBE collects and processes the following categories of Personal
                Data to provide CUSTOMERS and their Representatives with technical and
                general support:
              </span>
            </h2>
          </li>
          <li data-list-text="-">
            <p
              className="s6"
              style={{
                paddingTop: "7pt",
                paddingLeft: "31pt",
                textIndent: "-17pt",
                textAlign: "left"
              }}
            >
              Personal Details
            </p>
          </li>
          <li data-list-text="-">
            <p
              className="s6"
              style={{
                paddingTop: "1pt",
                paddingLeft: "31pt",
                textIndent: "-17pt",
                textAlign: "left"
              }}
            >
              Login Credentials
            </p>
          </li>
          <li data-list-text="-">
            <p
              className="s6"
              style={{
                paddingTop: "1pt",
                paddingLeft: "31pt",
                textIndent: "-17pt",
                textAlign: "left"
              }}
            >
              Unique IDs
            </p>
          </li>
          <li data-list-text="-">
            <p
              className="s6"
              style={{ paddingLeft: "31pt", textIndent: "-17pt", textAlign: "left" }}
            >
              CUSTOMER Support Records
            </p>
          </li>
        </ul>
        <table
          style={{ borderCollapse: "collapse", marginLeft: "10.025pt" }}
          cellSpacing={0}
        >
          <tbody>
            <tr style={{ }}>
              <td style={{ }}>
                <p
                  className="s7"
                  style={{
                    paddingLeft: "3pt",
                    paddingRight: "2pt",
                    textIndent: "0pt",
                    lineHeight: "107%",
                    textAlign: "justify"
                  }}
                >
                  To communicate and respond to requests and inquiries.{" "}
                  <span className="s8">
                    When a CUSTOMER, Service Provider, BUSINESS PARTNER or other
                    person or entity contacts us by email, by submitting a contact
                    form on our Website, VIBE collects and Processes the following
                    Categories of Personal Data from the Representatives or other
                    individuals in order to communicate with CUSTOMER, Service
                    Provider, BUSINESS PARTNER or such other person or entity, as
                    applicable, and respond to their requests and inquiries. VIBE also
                    collects and processes the following Personal Data from
                    Representatives who register for a trade event, webinar,
                    conference:
                  </span>
                </p>
                <ul id="l18">
                  <li data-list-text="-">
                    <p
                      className="s8"
                      style={{
                        paddingTop: "8pt",
                        paddingLeft: "21pt",
                        textIndent: "-17pt",
                        textAlign: "left"
                      }}
                    >
                      Personal Details
                    </p>
                  </li>
                  <li data-list-text="-">
                    <p
                      className="s8"
                      style={{
                        paddingLeft: "21pt",
                        textIndent: "-17pt",
                        textAlign: "left"
                      }}
                    >
                      Unique IDs
                    </p>
                  </li>
                  <li data-list-text="-">
                    <p
                      className="s8"
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "21pt",
                        textIndent: "-17pt",
                        textAlign: "left"
                      }}
                    >
                      Website Records
                    </p>
                  </li>
                  <li data-list-text="-">
                    <p
                      className="s8"
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "21pt",
                        textIndent: "-17pt",
                        textAlign: "left"
                      }}
                    >
                      Marketing and Event Records
                    </p>
                    <p
                      className="s7"
                      style={{
                        paddingTop: "9pt",
                        paddingLeft: "2pt",
                        paddingRight: "2pt",
                        textIndent: "0pt",
                        lineHeight: "107%",
                        textAlign: "justify"
                      }}
                    >
                      To market our Services and tailor our marketing and sales
                      activities.{" "}
                      <span className="s8">
                        VIBE may Process the following categories of Personal Data
                        when marketing new and existing Services and features to its
                        CUSTOMERS and other persons and entities and in an effort to
                        personalize such experience. VIBE also collects and processes
                        the following Personal Data from Representatives who register
                        for a trade event, webinar, conference:
                      </span>
                    </p>
                  </li>
                  <li data-list-text="-">
                    <p
                      className="s8"
                      style={{
                        paddingTop: "8pt",
                        paddingLeft: "21pt",
                        textIndent: "-17pt",
                        textAlign: "left"
                      }}
                    >
                      Personal Details
                    </p>
                  </li>
                  <li data-list-text="-">
                    <p
                      className="s8"
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "21pt",
                        textIndent: "-17pt",
                        textAlign: "left"
                      }}
                    >
                      Unique IDs
                    </p>
                  </li>
                  <li data-list-text="-">
                    <p
                      className="s8"
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "21pt",
                        textIndent: "-17pt",
                        textAlign: "left"
                      }}
                    >
                      Website Records
                    </p>
                  </li>
                  <li data-list-text="-">
                    <p
                      className="s8"
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "21pt",
                        textIndent: "-17pt",
                        textAlign: "left"
                      }}
                    >
                      Marketing and Event Records
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr style={{ height: "149pt" }}>
              <td style={{ }}>
                <p
                  className="s7"
                  style={{
                    paddingTop: "12pt",
                    paddingLeft: "3pt",
                    paddingRight: "2pt",
                    textIndent: "0pt",
                    lineHeight: "107%",
                    textAlign: "justify"
                  }}
                >
                  To analyze, improve, and optimize the use, function and performance
                  of our Website and Services.{" "}
                  <span className="s8">
                    VIBE may Process the following categories of Personal Data to
                    analyze, improve, and optimize the use, function and performance
                    of its Website and Services, including for quality assurance and
                    training purposes, as well as for marketing and sales campaigns.
                  </span>
                </p>
                <ul id="l19">
                  <li data-list-text="-">
                    <p
                      className="s8"
                      style={{
                        paddingTop: "7pt",
                        paddingLeft: "21pt",
                        textIndent: "-17pt",
                        textAlign: "left"
                      }}
                    >
                      Personal Details
                    </p>
                  </li>
                  <li data-list-text="-">
                    <p
                      className="s8"
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "21pt",
                        textIndent: "-17pt",
                        textAlign: "left"
                      }}
                    >
                      Unique IDs
                    </p>
                  </li>
                  <li data-list-text="-">
                    <p
                      className="s8"
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "21pt",
                        textIndent: "-17pt",
                        textAlign: "left"
                      }}
                    >
                      Website Records
                    </p>
                  </li>
                  <li data-list-text="-">
                    <p
                      className="s8"
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "21pt",
                        textIndent: "-17pt",
                        textAlign: "left"
                      }}
                    >
                      Marketing and Event Records
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr style={{ height: "98pt" }}>
              <td style={{ }}>
                <p
                  className="s7"
                  style={{
                    paddingTop: "12pt",
                    paddingLeft: "2pt",
                    paddingRight: "2pt",
                    textIndent: "0pt",
                    lineHeight: "107%",
                    textAlign: "justify"
                  }}
                >
                  To comply with applicable laws, regulations and internal policies,
                  practices, and procedures.{" "}
                  <span className="s8">
                    VIBE may be required to disclose certain categories of Personal
                    Data to comply with applicable laws and regulations, for example,
                    to respond to a request from a government agency or to defend a
                    legal claim. Additionally, VIBE may also be required to Process
                    certain categories of Personal Data when conducting internal
                    audits and investigations to ensure compliance with
                  </span>
                </p>
                <p
                  className="s8"
                  style={{
                    paddingLeft: "2pt",
                    textIndent: "0pt",
                    lineHeight: "13pt",
                    textAlign: "justify"
                  }}
                >
                  internal and external policies, practices, and procedures.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          style={{ borderCollapse: "collapse", marginLeft: "10.025pt" }}
          cellSpacing={0}
        >
          <tbody>
            <tr style={{ height: "98pt" }}>
              <td style={{ }}>
                <p
                  className="s7"
                  style={{
                    paddingLeft: "2pt",
                    paddingRight: "2pt",
                    textIndent: "0pt",
                    lineHeight: "107%",
                    textAlign: "justify"
                  }}
                >
                  To effectuate a reorganization, sale, merger, assignment, transfer
                  or other disposition of all or any portion of VIBE’s business.{" "}
                  <span className="s8">
                    In the event VIBE reorganizes its business operations or enters
                    into a transaction involving the sale, merger, assignment,
                    transfer, or disposition of all or part of its business, it may be
                    required to share all of the above categories of Personal Data
                    with a third party. Except as otherwise provided by a bankruptcy
                    or other court, the use and disclosure of all transferred Personal
                    Data will be subject to compliance with applicable data protection
                    laws.
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: "137pt" }}>
              <td style={{ }}>
                <p
                  className="s7"
                  style={{
                    paddingTop: "11pt",
                    paddingLeft: "2pt",
                    paddingRight: "2pt",
                    textIndent: "0pt",
                    lineHeight: "107%",
                    textAlign: "justify"
                  }}
                >
                  To receive applications for employment.{" "}
                  <span className="s8">
                    VIBE may Process the following categories of Personal Data when
                    receiving, reviewing, using, and storing applications for
                    employment, including from prospective employees who visit the
                    Website or other online locations where jobs may be posted, and
                    applications may be submitted:
                  </span>
                </p>
                <ul id="l20">
                  <li data-list-text="-">
                    <p
                      className="s8"
                      style={{
                        paddingTop: "7pt",
                        paddingLeft: "21pt",
                        textIndent: "-17pt",
                        textAlign: "left"
                      }}
                    >
                      Personal Details
                    </p>
                  </li>
                  <li data-list-text="-">
                    <p
                      className="s8"
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "21pt",
                        textIndent: "-17pt",
                        textAlign: "left"
                      }}
                    >
                      Login Credentials
                    </p>
                  </li>
                  <li data-list-text="-">
                    <p
                      className="s8"
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "21pt",
                        textIndent: "-17pt",
                        textAlign: "left"
                      }}
                    >
                      Unique IDs
                    </p>
                  </li>
                  <li data-list-text="-">
                    <p
                      className="s8"
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "21pt",
                        textIndent: "-17pt",
                        lineHeight: "13pt",
                        textAlign: "left"
                      }}
                    >
                      Education and Work History
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{ paddingTop: "2pt", textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <p
          className="s1"
          style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
        >
          VI. Opting Out of Marketing Communications
        </p>
        <p
          style={{
            paddingTop: "9pt",
            paddingLeft: "6pt",
            textIndent: "0pt",
            lineHeight: "115%",
            textAlign: "left"
          }}
        >
          <a
            href="mailto:Unsubsrcibe@vibe.love"
            style={{
              color: "black",

              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "none",
              fontSize: "12pt"
            }}
            target="_blank"
          >
            If at any time you wish for us to cease communicating with you with
            marketing materials, please take advantage of the “” link that you will
            find in any of our written electronic communications or email u
            unsubscribe at{" "}
          </a>
          <span
            style={{
              color: "#467885",

              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "underline",
              fontSize: "11pt"
            }}
          >
            Unsubsrcibe@vibe.love
          </span>
          . Please note you may still receive some communications such as those
          related to the Services you are receiving or in response to inquiries you
          have made to us.
        </p>
        <p style={{ paddingTop: "9pt", textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <p
          className="s1"
          style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
        >
          VII. Sharing with Third Parties
        </p>
        <p
          style={{
            paddingTop: "9pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "107%",
            textAlign: "justify"
          }}
        >
          Except as described below, we will not share or disclose Personal Data with
          or to outside third parties (meaning entities outside of the Group). The
          Group may share Personal Data between each other.
        </p>
        <p
          style={{
            paddingTop: "8pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "107%",
            textAlign: "left"
          }}
        >
          We will never sell Personal Data collected for the purposes of Service
          provision, or otherwise obtained from third parties, nor knowingly permit it
          to be used for marketing purposes by any person outside of the Group.
        </p>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <ol id="l21">
          <li data-list-text="H.">
            <p
              style={{
                paddingLeft: "23pt",
                textIndent: "-18pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              <u>SERVICE PROVIDERS</u>. We may share Personal Data with our SERVICE
              PROVIDERS in connection with advertising, hosting, data analytics,
              information technology and infrastructure, order management and
              fulfillment, billing, contract management, email delivery, auditing,
              events, and other related activities. We provide such Personal Data or
              authorize the processing of such Personal Data only as necessary to
              enable our SERVICE PROVIDERS to perform their designated functions. Our
              contracts with them (1) require them
            </p>
            <p
              style={{
                paddingTop: "4pt",
                paddingLeft: "23pt",
                textIndent: "0pt",
                lineHeight: "107%",
                textAlign: "justify"
              }}
            >
              to act only under our instruction and for the purpose(s) directed by us
              with respect to such Personal Data; and (2) prohibit them from sharing
              such Personal Data with any third parties without our authorization.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
          </li>
          <li data-list-text="I.">
            <p
              style={{
                paddingLeft: "23pt",
                textIndent: "-18pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              <u>Business Partners</u>. We may also share your Personal Data with
              trusted business partners pursuant to our contractual arrangements with
              them, which will include appropriate safeguards to protect any Personal
              Data that we share with these partners. These may include, but are not
              limited to, third parties that organize tradeshows, third party
              consultants and experts, and auditors.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
          </li>
          <li data-list-text="J.">
            <p
              style={{
                paddingLeft: "23pt",
                textIndent: "-18pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              <u>Affiliated Entities</u>. We share Personal Data with our Affiliates.
              Subject to local requirements, this Personal Data may be used to provide
              Services offered by our Affiliates, for the Affiliates to provide
              support to the Affiliated entity that is sharing the Personal Data or
              for any other purposes described in this GDPR Privacy Policy. For
              example, Affiliates may share Personal Data about our CUSTOMERS, SERVICE
              PROVIDERS, business partners, Representatives, prospective employees,
              and Website Visitors for direct marketing purposes.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
          </li>
          <li data-list-text="K.">
            <p
              style={{
                paddingLeft: "23pt",
                textIndent: "-18pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              <u>Payment Processing</u>. We work with a payment processing partner to
              process credit card payments. If you make any credit card payment to us,
              our payment processing provider will store your full name and credit
              card details.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
          </li>
          <li data-list-text="L.">
            <p
              style={{
                paddingLeft: "23pt",
                textIndent: "-18pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              <u>Fraud Prevention and Protection of Legal Rights</u>. We may use and
              disclose Personal Data to the appropriate legal, judicial or law
              enforcement authorities and our advisors and investigators: (i) when we
              believe, in our sole discretion, that such disclosure is necessary to
              investigate, prevent, or respond to suspected illegal or fraudulent
              activity or to protect the safety, rights, or property of the Group and
              of our CUSTOMERS, SERVICE PROVIDERS, business partners, Representatives,
              Website Visitors, prospective employees, or others; (ii) when we suspect
              abuse of the Website or Services or unauthorized access to any system,
              spamming, denial of service attacks, or similar attacks; (iii) to
              exercise or protect legal rights or defend against legal claims; or (iv)
              to allow us to pursue available remedies or limit the damages that we
              may sustain.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
          </li>
          <li data-list-text="M.">
            <p
              style={{
                paddingLeft: "23pt",
                textIndent: "-18pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              <u>Law Enforcement</u>. We may have to disclose the Personal Data of our
              CUSTOMERS, SERVICE PROVIDERS, business partners, Representatives,
              applicants, Website Visitors or others if a court, law enforcement or
              other public or government authority with appropriate
            </p>
            <p
              style={{
                paddingTop: "4pt",
                paddingLeft: "23pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left"
              }}
            >
              competency requests that we provide that Personal Data and we believe,
              in our reasonable discretion, that such request was made in compliance
              with applicable law.
            </p>
          </li>
          <li data-list-text="N.">
            <p
              style={{
                paddingLeft: "23pt",
                textIndent: "-18pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              <u>Corporate Reorganization</u>. We may transfer the Personal Data of
              our CUSTOMERS, SERVICE PROVIDERS, business partners, Representatives,
              Website Visitors or others to a third party in the case of the
              reorganization, sale, merger, joint venture, assignment, transfer or
              other disposition of all or any portion of our business, asset or
              stocks, including in the event of bankruptcy or corporate restructuring.
              Except as otherwise provided by a bankruptcy or other court, the use and
              disclosure of all transferred Personal Data will be subject to
              compliance with applicable data protection laws. Any Personal Data that
              an individual submits or that is collected after the reorganization may
              be subject to a new privacy policy adopted by the successor entity, of
              which we will inform, where required.
            </p>
          </li>
        </ol>
        <ol id="l22">
          <li data-list-text="IX.">
            <h1
              style={{
                paddingTop: "7pt",
                paddingLeft: "20pt",
                textIndent: "-15pt",
                textAlign: "left"
              }}
            >
              Cross-Border Transfers
            </h1>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              For cross-border transfers of EEA, UK or Swiss Personal Data to Group
              Affiliates in the US and/or to third parties, such as SERVICE PROVIDERS
              or business partners in countries outside the EEA/UK/Switzerland that
              are not considered to provide an adequate level of data protection, VIBE
              will adopt safeguards consistent with applicable data protection law
              including, but not limited to,{" "}
              <b>
                transferring such data to a recipient that is covered by a suitable
                framework or other legally adequate transfer mechanism recognized by
                the relevant authorities or courts as providing an adequate level of
                protection for{" "}
              </b>
              personal data, to a recipient that has achieved binding corporate rules
              authorization in accordance with EEA, UK, or Swiss data protection law,
              or to a recipient that has executed appropriate standard contractual
              clauses (“SCCs”) in each case as adopted or approved in accordance with
              EEA, UK, or Swiss data protection law.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
          </li>
          <li data-list-text="X.">
            <h1
              style={{ paddingLeft: "17pt", textIndent: "-12pt", textAlign: "left" }}
            >
              Data Retention
            </h1>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              We will retain Personal Data that we collect and Process where we have a
              justifiable business need to do so and/or for as long as it is needed to
              fulfill the purposes outlined in this GDPR Privacy Policy. We may retain
              Personal Data as required by law, such as for tax, legal, or accounting
              purposes.
            </p>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              With respect to VIBE, video footage of visits to our data centers is
              retained for 90 days. For current CUSTOMERS, badge activity and badge
              holder profiles stored within our access control system are retained for
              the duration of the contract and for up to 12 months thereafter. Such
              information may be retained for longer if it is included in other types
              of records that are subject to a longer retention period.
            </p>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              When, in our reasonable discretion, we have no justifiable business need
              to Process your Personal Data (for example, after all of our necessary
              interactions have ended, our internal record keeping policies no longer
              require us to continue to Process your Personal Data, and we
            </p>
            <p
              style={{
                paddingTop: "4pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left"
              }}
            >
              have no other legal obligations to retain your Personal Data), we will
              either delete it or anonymize it.
            </p>
          </li>
          <li data-list-text="XI.">
            <h1
              style={{
                paddingTop: "4pt",
                paddingLeft: "20pt",
                textIndent: "-15pt",
                textAlign: "left"
              }}
            >
              Data Subject Rights under the GDPR
            </h1>
          </li>
        </ol>
        <p
          style={{
            paddingTop: "9pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "107%",
            textAlign: "left"
          }}
        >
          The GDPR grants individuals who are in the EEA/UK the following rights, with
          some limitations. Individuals may contact us, at the address provided herein
          captioned “Our Contact Details” above to exercise any of those rights and we
          will respond with the requested action or information, or will let you know
          why such rights do not apply to you.
        </p>
        <p
          style={{
            paddingTop: "8pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "106%",
            textAlign: "left"
          }}
        >
          These rights are not absolute and are subject to various conditions under
          applicable data protection and privacy legislation and the laws and
          regulations to which we are subject.
        </p>
        <p
          style={{
            paddingTop: "8pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "107%",
            textAlign: "left"
          }}
        >
          In some cases, the exercise of these rights (for example, erasure,
          objection, restriction or the withholding or withdrawing of consent to
          processing) may make it impossible for us to achieve the purposes identified
          herein, as applicable, of this GDPR Privacy Policy and otherwise provide
          services.
        </p>
        <ul id="l23">
          <li data-list-text="-">
            <p
              style={{
                paddingTop: "7pt",
                paddingLeft: "24pt",
                textIndent: "-18pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              <u>Right Not to Provide Consent or to Withdraw Consent.</u> We may seek
              to rely on your consent in order to Process certain Personal Data. Where
              we do so, you have the right not to provide your consent, and the right
              to withdraw your consent at any time. If you withdraw your consent, this
              will not affect the lawfulness of the Processing conducted based on
              consent before its withdrawal.
            </p>
          </li>
          <li data-list-text="-">
            <p
              style={{
                paddingLeft: "24pt",
                textIndent: "-18pt",
                lineHeight: "108%",
                textAlign: "justify"
              }}
            >
              <u>Right of Access.</u> You have the right to obtain confirmation as to
              whether or not we collect or Process Personal Data concerning you and,
              if this is the case, you have the right to request a copy of such
              Personal Data in digital format.
            </p>
          </li>
          <li data-list-text="-">
            <p
              style={{
                paddingLeft: "24pt",
                textIndent: "-18pt",
                lineHeight: "109%",
                textAlign: "justify"
              }}
            >
              <u>Right of Rectification.</u> You have the right to require that we
              correct any inaccurate Personal Data concerning you, and that we
              complete incomplete Personal Data.
            </p>
          </li>
          <li data-list-text="-">
            <p
              style={{
                paddingLeft: "24pt",
                textIndent: "-18pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              <u>Right of Erasure.</u> In certain circumstances, you have the right to
              request that we erase Personal Data concerning you; for example, if it
              is no longer necessary for the purposes for which it was originally
              collected, and we do not otherwise have a legitimate reason to retain
              it.
            </p>
            <p
              style={{
                paddingTop: "7pt",
                paddingLeft: "6pt",
                textIndent: "0pt",
                lineHeight: "107%",
                textAlign: "justify"
              }}
            >
              We may need to retain certain Personal Data when legally required, for
              internal, record keeping purposes, and/or in order to complete any
              transactions initiated prior to an individual’s request to remove or
              delete their Personal Data. Where we are unable to delete data from our
              systems, we will anonymize it so it will no longer be tied to your
              identity.
            </p>
          </li>
          <li data-list-text="-">
            <p
              style={{
                paddingTop: "7pt",
                paddingLeft: "24pt",
                textIndent: "-18pt",
                lineHeight: "107%",
                textAlign: "justify"
              }}
            >
              <u>Right to Restrict Processing.</u> In certain circumstances, you have
              the right to request that we restrict the Processing of the Personal
              Data that we have collected about you; for example, where you believe
              that the Personal Data that we hold about you is not accurate or
              lawfully held.
            </p>
          </li>
          <li data-list-text="-">
            <p
              style={{
                paddingLeft: "24pt",
                textIndent: "-18pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              <u>Right to Data Portability.</u> In certain circumstances, you have the
              right to receive the Personal Data concerning you that you have provided
              to us in a structured, commonly used, machine readable format, and for
              us to transmit the data to another entity where technically feasible.
            </p>
          </li>
          <li data-list-text="-">
            <p
              style={{
                paddingTop: "3pt",
                paddingLeft: "24pt",
                textIndent: "-18pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              <u>Right to Object to the Processing</u>. In certain circumstances, you
              have the right to request that we stop Processing your Personal Data,
              including where we rely on legitimate interests as legal basis in the
              tables on the details of Processing provided above. If you receive
              commercial electronic communications from us, you can unsubscribe from
              the receipt of future commercial electronic communications from us by
              clicking on the “unsubscribe” link provided in such communications.
              Please also note that if you do opt out of receiving commercial
              electronic communications from us, we may still send you important
              administrative messages (such as updates about your account or changes
              in the Services), and you cannot opt out from receiving these messages,
              unless you stop receiving our Services.
            </p>
          </li>
          <li data-list-text="-">
            <p
              style={{
                paddingLeft: "24pt",
                textIndent: "-18pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              <u>
                Right Not to be Subject to Decisions Based Solely on Automated
                Processing that Produce
              </u>{" "}
              <u>Legal Effects.</u> We do not make decisions based solely on automated
              processing - including profiling - that produces legal effects or
              similarly affects you.
            </p>
          </li>
          <li data-list-text="-">
            <p
              style={{
                paddingLeft: "24pt",
                textIndent: "-18pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              <u>Right to Complain to a Supervisory Authority</u>. You have the right
              to lodge a complaint with a Supervisory Authority if you believe that
              our Processing of Personal Data relating to you is inconsistent with our
              obligations under the GDPR. In this situation, we ask you please
              consider contacting us first, so that we can try and assist with your
              query or address your concern.
            </p>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              To exercise any of your rights as set forth herein, please contact us in
              writing, via email or postal mail as indicated herein “Our Contact
              Details” above, so that we may consider your request under applicable
              law. We may ask that you provide the following Personal Data for us to
              address your request speedily:
            </p>
          </li>
          <li data-list-text="-">
            <p
              style={{
                paddingTop: "7pt",
                paddingLeft: "24pt",
                textIndent: "-18pt",
                lineHeight: "107%",
                textAlign: "justify"
              }}
            >
              The name, User ID, pseudonym, email address, or other identifier you
              have provided to us or if you have not otherwise previously interacted
              with us, your first and last name and an address where we can correspond
              with you;
            </p>
          </li>
          <li data-list-text="-">
            <p
              style={{
                paddingLeft: "23pt",
                textIndent: "-17pt",
                textAlign: "justify"
              }}
            >
              The country in which you are located;
            </p>
          </li>
          <li data-list-text="-">
            <p
              style={{
                paddingTop: "1pt",
                paddingLeft: "24pt",
                textIndent: "-18pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              A clear description of the Personal Data or content you wish to receive
              or to be deleted or corrected, or the action you wish to be taken; and
            </p>
          </li>
          <li data-list-text="-">
            <p
              style={{
                paddingLeft: "24pt",
                textIndent: "-18pt",
                lineHeight: "107%",
                textAlign: "left"
              }}
            >
              Sufficient information to allow us to locate the content or Personal
              Data to be deleted, removed, or corrected.
            </p>
          </li>
        </ul>
        <p
          style={{
            paddingTop: "8pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "107%",
            textAlign: "left"
          }}
        >
          For your protection, we may only implement requests with respect to Personal
          Data that are associated with the particular email address that you use to
          send us your request. In addition, please note that, depending on the nature
          of your inquiry, request, or complaint, we may need to verify your identity
          before implementing your request and may require proof of identity, such as
          in the form of a government issued ID and proof of your physical address. We
          will try to comply with your request as soon as reasonably practicable and
          in any case within the timelines prescribed by applicable laws. However, we
          reserve the right to refuse to act on a request that is manifestly unfounded
          or excessive (for example because it is repetitive) and/or, in some
        </p>
        <p
          style={{
            paddingTop: "4pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left"
          }}
        >
          cases, to charge a fee that takes into account the administrative costs for
          providing the information or the communication or taking the action
          requested.
        </p>
      </>

    </div>
  </div>
);

export default Privacy;
