import React, { useState, useContext, useEffect } from 'react'
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faTimes, faChevronDown, faChevronUp, faCheck, faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { Header, PaymentDetails, Items, TextPayment, calcStripeFee } from './index'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ContextSuccess } from '../../Context';
import { useDispatch, useSelector } from 'react-redux';
import { BaseFieldState, getFieldState } from '../../components/BaseComponents/utils';
import { CountryOption } from '../../hooks/useCountry';
import { StateOption } from '../../hooks/Locations/useActiveState';
import { CityOption } from '../../hooks/Locations/useStateCities';
import { IPrice } from '@vibe/sdk/dist/interfaces/Admin/payment/subscription/product.interface';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper'
import { SDK } from '@vibe/sdk';
import { Stripe } from 'stripe'
import { getUser } from '../../store/actions/userActions';
import ErrorField from '../../components/BaseComponents/ErrorField';
import TwoColumns from '../../components/TwoColumns';
import SelectorCountry from '../../components/BaseComponents/Location/SelectorCountry';
import SelectorState from '../../components/BaseComponents/Location/SelectorState';
import SelectorCity from '../../components/BaseComponents/Location/SelectorCity';
import { getBalance } from '../../store/actions/GVActions';
import useBalance from '../../hooks/GV/useBalance';
import { LoaderMini } from '../BaseComponents/Loader';
import CardsPayment from '../BaseComponents/Payment/Saved/CardsPayment';
import { MockPaymentMethodCard, PaymentMethodCard } from '../EditProfile/PaymentMethod';
import { IUserGradeResponse } from '@vibe/sdk/interfaces/Admin/User';
import { getCountry } from '../../store/actions/locationActions';
import { RootState } from '../../store/store';

interface FormPaymentProps {
    setTabActive: (tag: number) => void;
    setPurchaseActive: (actuve: boolean) => void;
    amount: number
}

const FormPayment = ({
    setTabActive,
    setPurchaseActive,
    amount,
}: FormPaymentProps) => {

    const [showDetail, setShowDetail] = useState(false)
    const stripe = useStripe();
    const elements = useElements();
    const currentSuccess = useContext(ContextSuccess);
    const username = useSelector((state: any) => state.getUser.userInfo?.username || '');
    const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);
    const dispatch = useDispatch();

    const { reloadAll } = useBalance();

    const [country, setCountry] = useState<BaseFieldState<CountryOption>>(getFieldState({ label: '', value: '' }));
    const [state, setState] = useState<BaseFieldState<StateOption>>(getFieldState({ label: '', value: '' }));
    const [address1, setAddress1] = useState<BaseFieldState<string>>(getFieldState(''));
    const [address2, setAddress2] = useState<BaseFieldState<string>>(getFieldState(''));
    const [city, setCity] = useState<BaseFieldState<CityOption>>(getFieldState({ label: '', value: '' }));
    const [zipcode, setZipcode] = useState<BaseFieldState<string>>(getFieldState(''));
    const [nameOnCard, setNameOnCard] = useState<BaseFieldState<string>>(getFieldState(''));
    const [discountCode, setDiscountCode] = useState<BaseFieldState<string>>(getFieldState(''));

    const [searchCountry, setSearchCountry] = useState('');
    const [searchState, setSearchState] = useState('');
    const [searchCity, setSearchCity] = useState('');

    const [loading, setLoading] = useState(false);

    const [lookup_key, set_lookup_key] = useState<IPrice>();
    const [newActive, setNewActive] = useState<boolean>(false);
    const [cardSelected, setCardSelected] = useState<PaymentMethodCard>({ ...MockPaymentMethodCard });

    useEffect(() => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Admin.listPrices();
            })
            .then(lookup_keys => {
                set_lookup_key((lookup_keys.body as IPrice[])[0])
            })
            .catch(e => {
                currentSuccess?.setSuccessData(false, true, e);
            })
    }, [])

    const getCountry = () => {
        //@todo Two-letter country code ([ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2)) dictionary.
        if (country.value?.value === 'COL') return 'CO';
        return 'US';
    }

    const paymentHandler = () => {
        if (!stripe || !elements) {
            return
        }

        let paymentIntentId: string;
        let dataSDK: getDataSDKResponse;

        setLoading(true);
        getDataSDK()
            .then((p: getDataSDKResponse) => {
                dataSDK = p;
                return dataSDK.sdk.Admin.purchaseGV({
                    amount: amount * 100,
                    username,
                    couponId: discountCode.value || undefined
                }, dataSDK.token)
            })
            .then(paymentIntent => {
                const { body, } = paymentIntent;
                if (paymentIntent.statusCode >= 400) return Promise.reject(new Error((body ?? { error: 'There was an error' } as any).error))
                const { client_secret, id } = body as Stripe.Response<Stripe.PaymentIntent>
                paymentIntentId = id

                if (!client_secret) return Promise.reject(new Error('No client secret'))

                if (newActive) return stripe.confirmCardPayment(client_secret, {
                    save_payment_method: true,
                    payment_method: {
                        card: elements.getElement(CardElement)!,
                        billing_details: {
                            address: {
                                city: city.value?.label || '',
                                country: getCountry(),
                                line1: address1.value || '',
                                line2: address2.value || '',
                                postal_code: zipcode.value || '',
                                state: state.value?.label || '',
                            },
                            email: user.email || '',
                            name: `${user.firstname || ''} ${user.lastname || ''}`,
                        }
                    }
                })

                return stripe.confirmCardPayment(client_secret, {
                    save_payment_method: true,
                    payment_method: cardSelected.id,
                })
            })
            .then((response) => {
                console.log(response)
                const { error, paymentIntent } = response
                if (error ?? paymentIntent?.status !== 'succeeded') return Promise.reject(error)
                return dataSDK.sdk.Admin.confirmPayment({ username, paymentIntentId, recharge: true })
            })
            .then((r) => {
                if (r.statusCode >= 400) return Promise.reject(new Error('Unable to confirm payment, try again later'))
                reloadAll();
                currentSuccess?.setPaymentSuccess(true, calcStripeFee(amount) / 100);
            })
            .catch((error) => {
                console.log(error)
                currentSuccess?.setPaymentSuccess(false, calcStripeFee(amount) / 100, error.message ?? 'There was an error, try again later');
            })
            .finally(() => {
                setLoading(false);
                setPurchaseActive(false);
            })
    }

    return (
        <>
            <Header>
                <TextHeader>
                    <FontAwesomeIcon
                        icon={faChevronLeft}
                        size="sm"
                        onClick={() => setTabActive(1)}
                    />
                    Purchase Good Vibes
                </TextHeader>
                <FontAwesomeIcon
                    icon={faTimes}
                    size="sm"
                    onClick={() => setPurchaseActive(false)}
                />
            </Header>
            <Form showDetails={showDetail}>
                <Row>
                    <Label>Address Line 1<Imp>*</Imp></Label>
                    <Input
                        value={address1.value}
                        onChange={(e) => setAddress1({ ...address1, value: e.currentTarget.value })}
                    />
                    {(address1.showError) && (<ErrorField error={address1.error} />)}
                </Row>
                <Row>
                    <Label>Address Line 2</Label>
                    <Input
                        value={address2.value}
                        onChange={(e) => setAddress2({ ...address2, value: e.currentTarget.value })}
                    />
                </Row>
                <TwoColumns
                    left={
                        <WrapperCountrySelect>
                            <Label>Country<Imp>*</Imp></Label>
                            <SelectorCountry
                                country={country}
                                setCountry={setCountry}
                                searchCountry={searchCountry}
                                setSearchCountry={setSearchCountry}
                            />
                            {(country.showError) && (<ErrorField error={country.error} />)}
                        </WrapperCountrySelect>
                    }
                    right={
                        <WrapperCountrySelect>
                            <Label>State / Region<Imp>*</Imp></Label>
                            <SelectorState
                                country={country.value?.value ?? ''}
                                state={state}
                                setState={setState}
                                searchState={searchState}
                                setSearchState={setSearchState}
                            />
                            {(state.showError) && (<ErrorField error={state.error} />)}
                        </WrapperCountrySelect>

                    }
                />

                <TwoColumns
                    left={
                        <WrapperCountrySelect>
                            <Label>City<Imp>*</Imp></Label>
                            <SelectorCity
                                state={state.value?.value}
                                city={city}
                                setCity={setCity}
                                searchCity={searchCity}
                                setSearchCity={setSearchCity}
                            />
                            {(city.showError) && (<ErrorField error={city.error} />)}
                        </WrapperCountrySelect>
                    }
                    right={
                        <WrapperCountrySelect>
                            <Label>Zip / Postal Code<Imp>*</Imp></Label>
                            <Input
                                value={zipcode.value}
                                onChange={(e) => setZipcode({ ...zipcode, value: e.currentTarget.value })}
                            />
                            {(zipcode.showError) && (<ErrorField error={zipcode.error} />)}
                        </WrapperCountrySelect>
                    }
                />
                <Row>
                    <Label>Discount Code</Label>
                    <Input
                        value={discountCode.value}
                        onChange={(e) => setDiscountCode({ ...discountCode, value: e.currentTarget.value })}
                    />
                </Row>
                <CardsPayment
                    setCardActive={setCardSelected}
                    cardActive={cardSelected}
                    setNewActive={setNewActive}
                    newActive={newActive}
                />
                {newActive && <>
                    <Row>
                        <Label>Name on Card</Label>
                        <Input
                            value={nameOnCard.value}
                            onChange={(e) => setNameOnCard({ ...nameOnCard, value: e.currentTarget.value })}
                        />
                    </Row>
                    <CardWrapper>
                        <CardElement options={{hidePostalCode: true}} />
                    </CardWrapper>
                </>}
            </Form>
            <DetailPayment>
                <PaymentDetails>
                    <Title>
                        Payment Details
                        <FontAwesomeIcon
                            icon={showDetail ? faChevronUp : faChevronDown}
                            size="sm"
                            onClick={() => setShowDetail(!showDetail)}
                        />
                    </Title>
                    <ListDown theme={{ main: showDetail ? 'active' : 'inactive' }}>
                        <Items>
                            <TextPayment>Good vibes</TextPayment>
                            <TextPayment>{amount ? amount.toFixed(2) : ''} <VibeLogo /> </TextPayment>
                        </Items>
                        <Items>
                            <TextPayment>Transaction Fee</TextPayment>
                            <TextPayment>{amount ? ((calcStripeFee(amount) - (amount * 100)) / 100).toFixed(2) : ''} US$</TextPayment>
                        </Items>
                        <Items>
                            <TextPayment>Taxes</TextPayment>
                            <TextPayment>{amount ? (0).toFixed(2) : ''} US$</TextPayment>
                        </Items>
                        {/* <Items>
                            <TextPayment>Net</TextPayment>
                            <TextPayment>{amount ? amount.toFixed(2) : ''} US$</TextPayment>
                        </Items> */}
                        <Items>
                            <TextPayment>Total</TextPayment>
                            <TextPayment>{amount ? (calcStripeFee(amount) / 100).toFixed(2) : ''} US$</TextPayment>
                        </Items>


                    </ListDown>
                    <Title>
                        Total
                        <Value>{amount ? (calcStripeFee(amount) / 100).toFixed(2) : ''} US$</Value>
                    </Title>
                    <ButtonContinue onClick={paymentHandler}>
                        {loading ? <LoaderMini /> : (
                            <>
                                Pay Now
                                <FontAwesomeIcon
                                    icon={faChevronRight}
                                    size="sm"
                                />
                            </>
                        )}
                    </ButtonContinue>
                </PaymentDetails>
            </DetailPayment>
        </>
    )
}

const VibeLogo = styled.img`
    content: url(../../../vibeLogo.svg);
    width: 20px;
    height: 20px;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ showDetails }: { showDetails: boolean }) => showDetails ? '260px' : '125px'};
  overflow: scroll;
`;

const WrapperCountrySelect = styled.div`
    margin-bottom: 7px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const DiscountCoupon = styled.span`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
    background-color: var(--simple-gray);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: '#aaa';
`

const Row = styled.div`
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
`;

const TitleForm = styled.div`
  font-family: 'Inter-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
`;

const CardWrapper = styled.div`
  width: 100%;
  border: 1px solid var(--simple-gray);
  border-radius: 10px;
  padding: 10px;
  margin-top: 15px;
`;

const ListDown = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    width: 100%;
    height: 0px;
    overflow:hidden;
    -webkit-transition: height 1s ease-in-out;
    -moz-transition: height 1s ease-in-out;
    -o-transition: height 1s ease-in-out;
    transition: height 1s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 0px;

    &.active{
        height:90px;
    }
    &.inactive{
        height:0px;
    }
`

const Value = styled.div``
const Title = styled.div`
    width: 100%;
    color: var(--default-dark);
    font-family: "Inter-Bold";
    font-size: 16px;
    letter-spacing: 0.1px;
    display: flex;
    justify-content: space-between; 
`

const DetailPayment = styled.div`
    position: absolute;
    padding: 20px 30px;
    width: calc(100%);
    box-shadow: 0px -2px 8px -1px rgba(27, 36, 44, 0.08), 0px -2px 2px -1px rgba(27, 36, 44, 0.04);
    background-color: white;
    bottom: 0;
    left: 0;
`

const ButtonContinue = styled.div`
    color: white;
    border-radius: 8px;
    background: var(--dark-blue);
    height: 42px;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 8px;
    margin-top: 15px;
    width: calc(570px - 60px);
    cursor: pointer;
`

const ContainerInput = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const Row2 = styled.div`
    display: flex;
    gap: 15px;

    & ${ContainerInput}{
        width: 50%
    }
`

const Input = styled.input`
    height: 40px;
    border-radius: 8px;
    border: 1px solid #999796;
    width: 100%;
`

const Label = styled.div`
    color: var(--default-dark);
    font-family: 'Inter';
    font-size: 14px;
    display: flex;
    gap: 5px;
`
const Imp = styled.div`
    color: #FF4D82;
`
const ContainerForm = styled.div`
    display: flex;
    gap: 8px;
    flex-direction: column;
`

const TextHeader = styled.div`
    display: flex;
    gap: 15px;
`

export default FormPayment