import React, { useContext, useEffect, useState } from 'react'
import styled from "styled-components";
import { Imp } from "../../containers/Website/Form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faSquare, faSquareCheck } from "@fortawesome/pro-solid-svg-icons";
import { EventDateInfo, deliveryTypeEvent } from '../../interfaces/Event/CreateEvent';
import SelectItem from '../../interfaces/SelectItem';
import { ContextSuccess } from '../../Context';
import { useTranslation } from 'react-i18next';
import { ContentTab_Event } from './ModalCreateEvent';
import CurrencyInput from "react-currency-input-field";
import TextEditor from "../../components/BaseComponents/TextEditor";
import { IUserGradeResponse } from '@vibe/sdk/interfaces/Admin/User';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { StateOption } from '../../hooks/Locations/useActiveState';
import { CityOption } from '../../hooks/Locations/useStateCities';
import { CountryOption } from '../../hooks/useCountry';
import SelectorCity from '../BaseComponents/Location/SelectorCity';
import SelectorCountry from '../BaseComponents/Location/SelectorCountry';
import SelectorState from '../BaseComponents/Location/SelectorState';
import { BaseFieldState, getFieldState } from '../BaseComponents/utils';
import PopupEventbrite from './PopupEventbrite';
import ErrorField from '../BaseComponents/ErrorField';

const eventType: SelectItem[] = [
    {
        label: 'Online',
        value: 'online'
    },
    {
        label: 'In Person',
        value: 'inPerson'
    },
    {
        label: 'Both ',
        value: 'mixed'
    },
]

const eventCharge: SelectItem[] = [
    {
        label: 'Free',
        value: 'free'
    },
    {
        label: 'Fee-based',
        value: 'fee'
    },
]

const TabDateType = ({
    eventDate,
    setEventDate,
    setTabOk,
    cleanData,
    isUpdate = false,
    isEdit,
    showBrite,
}: {
    eventDate: EventDateInfo
    setEventDate: (data: EventDateInfo) => void
    setTabOk: (data: boolean) => void
    cleanData: boolean
    isUpdate?: boolean
    isEdit: boolean
    showBrite: boolean
}) => {

    const currentSuccess = useContext(ContextSuccess);
    const { t, i18n } = useTranslation();

    const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);

    const [typeFeePerson, setTypeFeePerson] = useState<string>('');
    const [typeFeeOnline, setTypeFeeOnline] = useState<string>('');
    const [deliveryInfo, setDeliveryInfo] = useState<('online' | 'inPerson')[]>(['online']);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showLocationImp, setShowLocationImp] = useState<boolean>(false);

    const [searchCountry, setSearchCountry] = useState('');
    const [searchState, setSearchState] = useState('');
    const [searchCity, setSearchCity] = useState('');
    const [orgCountry, setOrgCountry] = useState<BaseFieldState<CountryOption>>(getFieldState({ label: '', value: '' }));
    const [orgState, setOrgState] = useState<BaseFieldState<StateOption>>(getFieldState({ label: '', value: '' }));
    const [orgCity, setOrgCity] = useState<BaseFieldState<CityOption>>(getFieldState({ label: '', value: '' }));

    const cleanFields = () => {
        setDeliveryInfo(['online']);

        setTypeFeeOnline('');
        setTypeFeePerson('');
        setShowModal(false);
        setShowLocationImp(false);
        setSearchCountry('');
        setSearchState('');
        setSearchCity('');
        setOrgCountry(getFieldState({ label: '', value: '' }));
        setOrgState(getFieldState({ label: '', value: '' }));
        setOrgCity(getFieldState({ label: '', value: '' }));
    }

    const checkDataComplete = () => {
        let isComplete = true;
        if (eventDate.delivery.length === 0) isComplete = false;

        if (eventDate.delivery.length !== 0 && (eventDate.delivery === 'online' || eventDate.delivery === 'mixed')) {
            if ((eventDate.link || '').length === 0) isComplete = false;
        }

        if (eventDate.delivery.length !== 0 && (eventDate.delivery === 'inPerson' || eventDate.delivery === 'mixed')) {
            if ((eventDate.instructions_inPerson || '').length === 0) isComplete = false;
        }

        if (
            (eventDate.country || '').length > 0 ||
            (eventDate.countryCode || '').length > 0 ||
            (eventDate.state || '').length > 0 ||
            (eventDate.stateCode || '').length > 0 ||
            (eventDate.city || '').length > 0 ||
            (eventDate.zipcode || '').length > 0
        ) {
            if ((eventDate.country || '').length === 0 || (eventDate.state || '').length === 0 || (eventDate.city || '').length === 0 || (eventDate.zipcode || '').length === 0) {
                isComplete = false;
            }
        }

        setTabOk(isComplete);
    }

    const checkDeliveryOnEnter = () => {

        if (eventDate.delivery) {
            switch (eventDate.delivery) {
                case 'inPerson':
                    setDeliveryInfo(['inPerson'])

                    if (eventDate.fee_inPerson !== '0') setTypeFeePerson('fee')
                    else setTypeFeePerson('free')

                    break;

                case 'online':
                    setDeliveryInfo(['online'])

                    if (eventDate.fee_online !== '0') setTypeFeeOnline('fee')
                    else setTypeFeeOnline('free')

                    break;

                case 'mixed':
                    setDeliveryInfo(['online', 'inPerson'])

                    if (eventDate.fee_online !== '0') setTypeFeeOnline('fee')
                    else setTypeFeeOnline('free')

                    if (eventDate.fee_inPerson !== '0') setTypeFeePerson('fee')
                    else setTypeFeePerson('free')

                    break;

                default:
                    break;
            }
        }
    }

    const checkLocationOnEnter = () => {
        if (eventDate.country && eventDate.countryCode) setOrgCountry({
            value: {
                value: eventDate.countryCode,
                label: eventDate.country,
            },
            showError: false,
            error: ''
        })

        if (eventDate.state && eventDate.stateCode) setOrgState({
            value: {
                value: eventDate.stateCode,
                label: eventDate.state,
            },
            showError: false,
            error: ''
        })

        if (eventDate.city) setSearchCity(eventDate.city);
    }

    const setCountry = (item: BaseFieldState<CountryOption>) => {
        setOrgCountry(item);
        setEventDate({
            ...eventDate,
            country: item.value?.label || '',
            countryCode: item.value?.value || '',
        });
    }

    const setCity = (item: BaseFieldState<CityOption>) => {
        setOrgCity(item);
        setEventDate({
            ...eventDate,
            city: item.value?.label || '',
            cityCode: item.value?.value || '',
        });
    }

    const setState = (item: BaseFieldState<StateOption>) => {
        setOrgState(item);
        setEventDate({
            ...eventDate,
            state: item.value?.label || '',
            stateCode: item.value?.value || '',
        });
    }

    const handleState = (item: string) => {
        setEventDate({
            ...eventDate,
            state: item,
        });
    }

    const handleCity = (item: string) => {
        setEventDate({
            ...eventDate,
            city: item,
        });
    }

    const handleZipcode = (data: string) => {
        setEventDate({
            ...eventDate,
            zipcode: data,
        })
    }

    const onClickDelivery = (type: deliveryTypeEvent) => {
        switch (type) {
            case 'inPerson':
                setDeliveryInfo(['inPerson']);
                setEventDate({
                    ...eventDate,
                    delivery: type || '',
                    eventBriteOnline: false,
                });
                break;

            case 'online':
                setDeliveryInfo(['online']);
                setEventDate({
                    ...eventDate,
                    delivery: type || '',
                    eventBriteOnline: true,
                });
                break;

            case 'mixed':
                setDeliveryInfo(['online', 'inPerson']);
                setEventDate({
                    ...eventDate,
                    delivery: type || '',
                    eventBriteOnline: true,
                });
                break;

            default:
                break;
        }
    }

    const handleCapacity = (data: number, delivery: string) => {
        if (delivery === 'inPerson') {
            setEventDate({
                ...eventDate,
                maxCapacity_inPerson: data,
            })
            return;
        }

        setEventDate({
            ...eventDate,
            maxCapacity_online: data,
        })
    }

    const handleLink = (data: string, delivery: string) => {
        if (delivery === 'inPerson') {
            setEventDate({
                ...eventDate,
                address_1: data,
            })
            return;
        }

        setEventDate({
            ...eventDate,
            link: data,
        })
    }

    const handleAddress2 = (data: string) => {
        setEventDate({
            ...eventDate,
            address_2: data,
        })
    }

    const handleInstructions = (data: string, delivery: string) => {
        if (delivery === 'inPerson') {
            setEventDate({
                ...eventDate,
                instructions_inPerson: data,
            })
            return;
        }

        setEventDate({
            ...eventDate,
            instructions_online: data,
        })
    }

    const handleSelectFee = (type: string, delivery: string) => {
        if (delivery === 'inPerson') {
            setTypeFeePerson(type);
            if (type === 'free') setEventDate({
                ...eventDate,
                fee_inPerson: '0',
            })
            return;
        }

        setTypeFeeOnline(type);
        if (type === 'free') setEventDate({
            ...eventDate,
            fee_online: '0',
        })
    }

    const typeFee = (delivery: string) => {
        return delivery === 'inPerson' ? typeFeePerson : typeFeeOnline;
    }

    const handleCost = (data: any, delivery: string) => {
        if (delivery === 'inPerson') {
            setEventDate({
                ...eventDate,
                fee_inPerson: data,
            })
            return;
        }

        setEventDate({
            ...eventDate,
            fee_online: data,
        })
    }

    const handleCreateEventbrite = () => {
        if (eventDate.createEventbrite) {
            setEventDate({
                ...eventDate,
                createEventbrite: false,
            })
            setShowModal(false);
            return;
        }

        switch (eventDate.delivery) {
            case 'inPerson':
                setEventDate({
                    ...eventDate,
                    eventBriteOnline: false,
                })
                break;

            default:
                setEventDate({
                    ...eventDate,
                    eventBriteOnline: true,
                })
                break;
        }
        setEventDate({
            ...eventDate,
            createEventbrite: true,
        })
        setShowModal(true);
    }

    useEffect(() => {
        if (cleanData) cleanFields();
    }, [cleanData])

    useEffect(() => {
        if (
            (eventDate.country || '').length > 0 ||
            (eventDate.countryCode || '').length > 0 ||
            (eventDate.state || '').length > 0 ||
            (eventDate.stateCode || '').length > 0 ||
            (eventDate.city || '').length > 0 ||
            (eventDate.zipcode || '').length > 0
        ) {
            setShowLocationImp(true);
        }
        else {
            setShowLocationImp(false);
        }
    }, [eventDate.country, eventDate.countryCode, eventDate.state, eventDate.stateCode, eventDate.city, eventDate.zipcode])

    useEffect(() => {
        checkDataComplete();
        checkDeliveryOnEnter();
        checkLocationOnEnter();
    }, [eventDate])

    return (
        <ContentTab_Event>
            <TitleModal>Delivery method<Imp>*</Imp></TitleModal>
            <ListCheckbox>
                {eventType.map(d => (
                    <ItemList onClick={() => onClickDelivery(d.value as deliveryTypeEvent)}>
                        <IconRadio theme={{ main: eventDate.delivery === d.value ? 'iconCheck' : '' }}>
                            {eventDate.delivery === d.value &&
                                <RadioIcon
                                    icon={faCircle}
                                    size='lg'
                                    color={'var(--absolute-zero)'}
                                />
                            }
                        </IconRadio>
                        {d.label}
                    </ItemList>
                ))}
            </ListCheckbox>

            <FieldsDelivery isMixed={eventDate.delivery === 'mixed'}>

                {deliveryInfo.map((delivery) => (
                    <Fields>

                        <TitleModal>
                            {delivery === 'online' ? 'Online Details' : 'In-Person Details'}
                        </TitleModal>
                        <Column2>
                            <Row1>
                                <Label>Capacity<Imp>*</Imp></Label>
                                <Input
                                    type='number'
                                    value={delivery === 'online' ? (eventDate.maxCapacity_online || '') : (eventDate.maxCapacity_inPerson || '')}
                                    onChange={(e) => handleCapacity(parseInt(e.currentTarget.value), delivery)}
                                />
                            </Row1>

                            <Row1>
                                <Label>{delivery === 'online' ? 'Link' : 'Address Line 1'}<Imp>*</Imp></Label>
                                <Input
                                    value={delivery === 'online' ? (eventDate.link || '') : (eventDate.address_1 || '')}
                                    onChange={(e) => handleLink(e.currentTarget.value, delivery)}
                                />
                            </Row1>
                        </Column2>

                        {delivery !== 'online' && (
                            <>
                                <Row1>
                                    <Label>Address Line 2</Label>
                                    <Input
                                        value={eventDate.address_2 || ''}
                                        onChange={(e) => handleAddress2(e.currentTarget.value)}
                                    />
                                </Row1>
                                <Row2>
                                    <Row1>
                                        <Label>Country {showLocationImp && <Imp>*</Imp>}</Label>
                                        <SelectorCountry
                                            country={orgCountry}
                                            setCountry={setCountry}
                                            searchCountry={searchCountry}
                                            setSearchCountry={setSearchCountry}
                                            onlyActive={false}
                                        />
                                    </Row1>

                                    <Row1>
                                        <Label>State / Region {showLocationImp && <Imp>*</Imp>}</Label>
                                        <SelectorState
                                            country={orgCountry.value?.value ?? ''}
                                            state={orgState}
                                            setState={setState}
                                            searchState={searchState}
                                            setSearchState={setSearchState}
                                        />
                                    </Row1>
                                </Row2>
                                <Row2>

                                    <Row1>
                                        <Label>City {showLocationImp && <Imp>*</Imp>}</Label>
                                        {orgCountry.value?.value === 'USA' ? (
                                            <SelectorCity
                                                state={orgState.value?.value || ''}
                                                city={orgCity}
                                                setCity={setCity}
                                                searchCity={searchCity}
                                                setSearchCity={setSearchCity}
                                            />
                                        ) : (
                                            <Input
                                                value={eventDate.city || ''}
                                                onChange={(e) => handleCity(e.currentTarget.value)}
                                            />
                                        )}
                                    </Row1>

                                    <Row1>
                                        <Label>Zipcode {showLocationImp && <Imp>*</Imp>}</Label>
                                        <Input
                                            value={eventDate.zipcode || ''}
                                            onChange={(e) => handleZipcode(e.currentTarget.value)}
                                        />
                                    </Row1>
                                </Row2>
                            </>
                        )}

                        <Row1 theme={{ height: 'auto' }} >
                            <Label>Instructions<Imp>*</Imp></Label>
                            <TextEditor
                                value={delivery === 'online' ? (eventDate.instructions_online || '') : (eventDate.instructions_inPerson || '')}
                                setValue={(e) => handleInstructions(e, delivery)}
                                charLimit={1000}
                                minHeight={180}
                                placeholder=''
                            />
                        </Row1>

                        <Label>Charge Type<Imp>*</Imp></Label>
                        <ListCheckbox>
                            {eventCharge.map(d => (
                                <ItemList onClick={() => handleSelectFee(d.value, delivery)}>
                                    <IconRadio theme={{ main: typeFee(delivery) === d.value ? 'iconCheck' : '' }}>
                                        {typeFee(delivery) === d.value &&
                                            <RadioIcon
                                                icon={faCircle}
                                                size='lg'
                                                color={'var(--absolute-zero)'}
                                            />
                                        }
                                    </IconRadio>
                                    {d.label}
                                </ItemList>
                            ))}
                        </ListCheckbox>
                        {typeFee(delivery) === 'fee' &&
                            <Row1>
                                <Label>Cost (USD)<Imp>*</Imp></Label>
                                <CurrencyInput
                                    allowDecimals
                                    decimalSeparator="."
                                    id="input-currency-field"
                                    name="input-currency-field-name"
                                    prefix="$"
                                    value={delivery === 'online' ? (eventDate.fee_online || '') : (eventDate.fee_inPerson || '')}
                                    onValueChange={(value) => handleCost(value, delivery)}
                                    step={1}
                                    className={"currencyFee"}
                                />
                                {/* <Input
                                    type='number'
                                    value={delivery === 'online' ? (eventDate.fee_online || '') : (eventDate.fee_inPerson || '')}
                                    onChange={(e) => handleCost(parseFloat(e.currentTarget.value), delivery)}
                                /> */}
                            </Row1>
                        }
                    </Fields>
                ))}
            </FieldsDelivery>
            {
                (showBrite && !isEdit) && <>
                    <TitleModal>Eventbrite</TitleModal>
                    <ItemCheckbox onClick={handleCreateEventbrite}>
                        <SquareIcon
                            icon={eventDate.createEventbrite ? faSquareCheck : faSquare}
                            size='xl'
                            color={eventDate.createEventbrite ? 'black' : "white"}
                            active={eventDate.createEventbrite ?? false}
                        />
                        Do you want to create event in Eventbrite too?
                    </ItemCheckbox>

                    <PopupEventbrite showModal={showModal} hide={() => setShowModal(false)} eventDate={eventDate} setEventDate={setEventDate} />
                </>
            }
        </ContentTab_Event >
    )
}

export default TabDateType

const Column3 = styled.div`
    display: flex;
    width: 100%;
    gap: 10px;

    & > div{
        width: 33%;
    }
`

const Column2 = styled.div`
    display: flex;
    width: 100%;
    gap: 10px;

    & > div{
        width: 50%;
    }
`
const Column = styled.div``

const FieldsDelivery = styled.div`
    //Flex and Grid
    /* 
    display: ${({ isMixed }: { isMixed: boolean }) => isMixed ? 'grid' : 'flex'}
    gap: ${({ isMixed }: { isMixed: boolean }) => isMixed ? '20px' : '10px'};
    flex-direction: column; 
    */

    display: flex;
    flex-direction: column;
    gap: 10px;
`

const Fields = styled.div`
    width: 100%;
    gap: 10px;
    display: flex;
    flex-direction: column;
`

const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
`

const Label = styled.div`    
    width: 100%;
    text-align: left;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`

const Inputs = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const ListCheckbox = styled.div`
    display: flex;
    gap: 16px;
`

const TitleModal = styled.div`
    color: var(--default-dark);
    font-family: Inter-Bold;
    font-size: 16px;
    text-align: left;
    display: flex;
`

const RadioIcon = styled(FontAwesomeIcon)`
    font-size: 14px;
`;

export const SquareIcon = styled(FontAwesomeIcon)`
    border: ${({ active }: { active: boolean }) => active ? 'none' : '2px solid var(--simple-gray)'};
    max-height: ${({ active }: { active: boolean }) => active ? 'fit-content' : '15px'};
    min-width: ${({ active }: { active: boolean }) => active ? 'fit-content' : '15px'};
    border-radius: 4px;
    cursor: pointer;
`;


const ItemList = styled.div`
    display: flex;
    justify-content: start;
    padding: 3px 0px 3px 0;
    align-items: center;
    font-family: 'Inter';
    font-size: 14px;
    gap: 8px;
    cursor: pointer;
`
export const ItemCheckbox = styled.div`
    display: flex;
    justify-content: start;
    padding: 3px 0px 3px 0;
    align-items: center;
    font-family: 'Inter';
    font-size: 14px;
    gap: 8px;
    cursor: pointer;
`;

const Row1 = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: ${props => props.theme.height || '65px'};

    & .currencyFee{
        outline: none;
        width: 100%;
        height: 40px;
        border-radius: 9px;
        border: 0;
        padding: 0 20px;
        font-size: 16px;
        font-family: 'Inter';
        border: 1px solid var(--simple-gray);
    }

`;

const Row2 = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    height: ${props => props.theme.height || '65px'};
    align-items: center;
    justify-content: flex-start;
`;

const IconRadio = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    border-radius: 12px;
    border: 2px solid #999796;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.iconCheck{
        background-color: white;
        border: 2px solid #2A4CD3;
    }
`