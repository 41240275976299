import { tabInterface } from "../../components/FeedCenterSection";
import ItemFilterHome from "./ItemFilterHome";

export const headerHomeTabs: tabInterface[] = [
    {
      id: 1,
      label: 'All',
      comingSoon: false,
    },
    {
      id: 2,
      label: 'Modalities',
      comingSoon: false,
    },
    {
      id: 3,
      label: 'Healers',
      comingSoon: false,
    },
    {
      id: 4,
      label: 'Communities',
      comingSoon: false,
    },
    {
      id: 5,
      label: 'Events',
      comingSoon: false,
    },
  ];

// @Cristian Santos TODO: create hooks if you thinks is necessary to 

export const filtersHomeMods: ItemFilterHome[] = [{
    id: 1,
    icon: "star", //@todo
    dropdown: true,
    label: 'Benefits',
},
{
    id: 2,
    icon: "laptop", //@todo
    dropdown: true,
    label: 'Delivery',
},
{
    id: 3,
    icon: "thumbtack",
    dropdown: true,
    label: 'Location',
}];

export const filtersHomeComms: ItemFilterHome[] = [{
    id: 1,
    icon: "star", //@todo
    dropdown: true,
    label: 'Benefits',
},
{
    id: 2,
    icon: "laptop", //@todo
    dropdown: true,
    label: 'Delivery',
},
{
    id: 3,
    icon: "thumbtack",
    dropdown: true,
    label: 'Location',
}];

export const filtersHomeTPs: ItemFilterHome[] = [{
    id: 1,
    icon: "star", //@todo
    dropdown: true,
    label: 'Benefits',
},
{
    id: 2,
    icon: "laptop", //@todo
    dropdown: true,
    label: 'Delivery',
},
{
    id: 3,
    icon: "thumbtack",
    dropdown: true,
    label: 'Location',
}];

export const filtersHomePOs: ItemFilterHome[] = [{
    id: 1,
    icon: "star", //@todo
    dropdown: true,
    label: 'Benefits',
},
{
    id: 2,
    icon: "laptop", //@todo
    dropdown: true,
    label: 'Delivery',
},
{
    id: 3,
    icon: "thumbtack",
    dropdown: true,
    label: 'Location',
}];

export const filtersHomeEvents: ItemFilterHome[] = [{
    id: 1,
    icon: "calendar",
    dropdown: true,
    label: 'Date',
},
{
    id: 2,
    icon: "laptop",
    dropdown: true,
    label: 'Delivery',
},
{
    id: 3,
    icon: "wallet",
    dropdown: true,
    label: 'Type',
},
{
    id: 4,
    icon: "thumbtack",
    dropdown: true,
    label: 'Location',
}];