import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import FormRestorePassword from "./FormRestorePassword";
import FormForgotPassword from "./FormForgotPassword";

const ModalForgotPassword = ({
    show,
    setShowModal,
}: {
    show: boolean,
    setShowModal: (type: boolean) => void,
}) => {

    const [isRestore, setIsRestore] = useState<boolean>(false);

    const onHide = () => {
        setShowModal(false);
        setIsRestore(false);
    }

    return (
        <Modal className="modalMS" show={show}>
            <IconClicker
                onClick={onHide}
                icon="times"
                size='xl'
                color={'black'}
            />
            <WrapperLogin>
                {isRestore ? (
                    <FormRestorePassword onHide={onHide}/>
                ):(
                    <FormForgotPassword onHide={onHide} goFormRestore={() => setIsRestore(true)}/>
                )}
            </WrapperLogin>
        </Modal>
    )
}

const WrapperLogin =  styled.div`
    padding: 0px 15px 15px 15px;
    margin-top: -15px;
`;
interface WrapProps {
    height: string;
}

const WrapperModals: any = styled.div`
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    height: ${(props: WrapProps) => props.height};
    overflow: scroll;

    width: auto;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(158,158,158,1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(158,158,158,1);
    box-shadow: 0px 0px 10px 0px rgba(158,158,158,1);
    border-radius: 12px;
    padding: 0 25px;
`;

const IconClicker = styled(FontAwesomeIcon)`
    cursor: pointer;
    position: absolute;
    z-index: 9999;
    left: 92%;
`;


export default ModalForgotPassword