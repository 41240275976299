import React from 'react'
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { WHITE, LAVENDER } from "../ClubIconsNavBar";
import { BROWN } from "../SuperCenter/Modalities/Modals/components";
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

const ModalEventBrite = ({
    show,
    onHide,
}: {
    show: boolean
    onHide: () => void
}) => {
    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);

    const handleSync = () => {
        const clientId = process.env.REACT_APP_EVENTBRITE_CLIENT;
        const responseType = process.env.REACT_APP_EVENTBRITE_RESPONSE_TYPE;
        const eventbriteAuthUrl = process.env.REACT_APP_EVENTBRITE_AUTH_URL;
        const baseRedirectUri = process.env.REACT_APP_EVENTBRITE_REDIRECT_URI; //Dashboard URL

        const encodedUsername = encodeURIComponent(userInfo?.username || '');
        const redirectUri = `${baseRedirectUri}?username=${encodedUsername}`;
        const url = `${eventbriteAuthUrl}?response_type=${responseType}&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}`;

        window.open(url, "_blank");
    }

    return (
        <>
            <Modal className="modalEventBrite" show={show} onHide={onHide}>
                <Modal.Header>
                    <LogoEventB />
                    <FontAwesomeIcon
                        icon={faTimes}
                        size='xl'
                        color={'black'}
                        className="icon-times"
                        onClick={(e) => onHide()}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Text>
                        <Container>
                            Event sync with
                            <Logo />
                        </Container>
                        Vibe is integrated with Eventbrite to simplify your event planning
                    </Text>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSync}>
                        Connect to Eventbrite
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            size='sm'
                            color={'white'}
                            style={{
                                fontSize: '16px'
                            }}
                        />
                    </Button>
                    <TextNoEventB>
                        No Eventbrite?
                        <TextBlue onClick={() => {
                            window.open("https://www.eventbrite.com/signin/signup", "_blank");
                        }}>
                            Sign up first
                        </TextBlue>
                    </TextNoEventB>
                    <Info>
                        <Text>
                            You’ll choose which event to connect next
                        </Text>
                        <Text>
                            No ticket holders will be imported until you’re ready
                        </Text>
                    </Info>
                </Modal.Footer>
            </Modal>
        </>
    );
}

const TextBlue = styled.div`
    color: #2A4CD3;
    font-family: Inter-Bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
`

const TextNoEventB = styled.div`
    color: #333;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
`

const Text = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
    color: #333;
    font-family: 'Inter';
    font-size: 16px;
    padding-bottom: 26px;
    border-bottom: 1px solid #c1c9d2;
`

const Info = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    & ${Text}{
        padding-bottom: 0px;
        border-bottom: 0px solid #c1c9d2;
    }

`

const Button = styled.div`
    display: flex;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #F05537;
    height: 48px;
    color: #FFF;
    font-family: Inter-Bold;
    font-size: 16px;
    letter-spacing: 0.1px;
    width: fit-content;
    cursor: pointer;
`



const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    color: #333;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Strong/100 */
    font-family: 'Inter-Bold';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 175% */
    letter-spacing: 0.1px;
`

const logoEventBrite = require("../../assets/img/eventBriteText.png");
const LogoEventB = styled.div`
    background-image: url("${logoEventBrite}");
    width: 100%;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    background-position: center;
`;

const logoVibe = require("../../assets/img/logoVibe.png");
const Logo = styled.div`
    width: 90px;
    height: 35px;
    background-image: url("${logoVibe}");
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
`;

export default ModalEventBrite;