import Article from "@vibe/sdk/dist/interfaces/Modalities/articles/Article";
import { useState } from "react";
import styled from "styled-components";
import ViewArticle, { DividerLine } from "./ViewArticle";
import { TitleRowCardHome, IconUser } from "../../BaseComponents/HomeCards/styles";
import { IconClicker } from "../../Home/HeaderHome/styles";
import { faFile, faLink } from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";

const CardArticle = ({
    article
}: {
    article: Article
}) => {
    const [showArticle, setShowArticle] = useState<boolean>(false);
    const navigate = useNavigate();
    console.log(' ************************************** ', article)

    return (
        <>
            <CardContainerHome>
                <ContainerHeader>
                    <TitleRowCardHome>
                        <TitleRowCardEllipsisWrap onClick={() => window.open(article.link, "_blank")}>
                            {article.name}
                        </TitleRowCardEllipsisWrap>
                        {/* <IconClicker
                            icon="chevron-right"
                            size="sm"
                            color="var(--dark-blue)"
                        /> */}
                    </TitleRowCardHome>
                    <SharedBy>
                        Shared by
                        <span onClick={() => navigate(`../../../../h/${article.createdBy}`)}>
                            {article.createdByFullName}
                        </span>
                    </SharedBy>
                </ContainerHeader>

                <WrapperDescriptionArticle dangerouslySetInnerHTML={{ __html: (article.description || '').replace(/(<([^>]+)>)/gi, "").replace('&nbsp;', ' ') }} />
                <DividerLine />
                <Tags theme={{ main: 'tgAreas' }}>
                    <ListTags>
                        {article?.modalities &&
                            (article?.modalities).map((benefit) => (
                                <List style={{ cursor: 'pointer' }} key={benefit.id}>{benefit.name}</List>
                            ))
                        }
                    </ListTags>
                </Tags>

                <DividerLine />
                <Row>
                    {/* <RowClicker onClick={() => window.open(article.link, "_blank")}>
                        <IconClicker
                            icon={article.type === 'FILE' ? faFile : faLink}
                            size='sm'
                            color="var(--absolute-zero)"
                        />
                        {article.type === 'FILE' ? 'File' : 'Link'}
                    </RowClicker> */}
                    <div>
                        <IconUser src={'../icon_healer.png'} />
                        {article.likesHealer} Healers
                    </div>
                    |
                    <div className="likesSeeker">
                        <IconUser src={'../icon_seeker.png'} />
                        {article.likesSeeker} Seekers
                    </div>
                    
                    
                </Row>
            </CardContainerHome>
            <ViewArticle
                showModal={showArticle}
                hide={() => setShowArticle(false)}
                article={article}
            />
        </>
    )
}

export default CardArticle;

const Row = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    height: fit-content;

    & > div{
        display: flex;
        width: 50%;
        align-items: center;
        gap: 8px;
    }

    & .likesSeeker{
        justify-content: end;
    }
`;

const RowClicker = styled.div`
    display: flex;
    gap: 10px;
    height: fit-content;
    cursor: pointer;
    color: var(--absolute-zero);
    justify-content: flex-start;
    align-items: center;
`;

const WrapperDescriptionArticle = styled.div`
    height: fit-content;
    display: -webkit-box; 
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 3; 
    overflow: hidden; 
    text-overflow: ellipsis;
    width: 100%;
    text-align: left;
`;

const CardContainerHome = styled.div.attrs(
    props => ({
        className: 'cardMods',
    })
)`
    display: flex;
    width: 100%;
    height: fit-content;
    max-height: 300px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    border-radius: 8px;
    border: 1px solid var(--border-subtle-neutral);
    background-color: var(--background-default);

    color: var(--default-dark);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 22px;
    letter-spacing: 0.2px;
`;

const ListTags = styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
`

const List = styled.div`
    font-size: 14px;
    line-height: 24px;
`

const Tags = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    display: flex;
    flex-direction: column;
    gap: 12px;

    &.tgAreas ${List}{
        background-color: #f5e5c4;
        text-align: center;
        padding: 0 5px;
        border-radius: 5px;
    }

`

const SharedBy = styled.div`
    display: flex;
    gap: 5px;
    & span{
        text-decoration: underline;
        cursor: pointer;
    }
`

const ContainerHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
`

const TitleRowCardEllipsisWrap = styled.div`
    //display: flex;
    text-align: left;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: underline;
`;