import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const TabSetup = ({
    active,
    processed,
    text,
    onClick,
}: {
    active: boolean
    processed: boolean
    text: string
    onClick: () => void
}) => {

    const getIcon = () => {
        if (active) return (
            <FontAwesomeIcon
                icon={'circle-dot'}
                size='lg'
                color={"var(--dark-yellow)"}
            />
        )

        if (processed) return (
            <FontAwesomeIcon
                icon={'circle-check'}
                size='lg'
                color={"var(--simple-brown-light)"}
            />
        )

        return (
            <FontAwesomeIcon
                icon={faCircle}
                size='lg'
                color={"var(--simple-blue-light)"}
            />
        )
    }

    return (
        <Wrapper active={active} onClick={onClick}>
            <LineTab processed={processed} active={active} />
            <WrapperText active={active}>
                {getIcon()}
                {text}
            </WrapperText>
        </Wrapper>
    )
}

export default TabSetup;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 3px;
  cursor: pointer;

  @media only screen and (max-width: 500px) {
      flex: none;
      display: ${({ active }: { active: boolean }) => active ? "block" : "none"}
  }
`;

const WrapperText = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: ${({ active }: { active: boolean }) => active ? "var(--simple-brown-dark)" : "var(--light-brown)"};

    @media only screen and (max-width: 500px) {
        flex: none;
        display: ${({ active }: { active: boolean }) => active ? "block" : "none"}
        text-align: left;
        margin-top: 4px;
    }
    
`;

interface LineTabProps {
    processed: boolean
    active: boolean
}

const LineTab = styled.div`
    width: 100%;
    height: 4px;
    border-radius: 5px;
    background-color: ${(props: LineTabProps) => props.active ? "var(--dark-yellow)" : (props.processed ? "var(--lavender)" : "var(--mountain-mist)")};

    @media only screen and (max-width: 500px) {
        flex: none;
        display: ${(props: LineTabProps) => props.active ? "block" : "none" };
    }
`;