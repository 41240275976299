import { useDispatch } from "react-redux";
import { Backend, MicroServices } from "./backendHelper";

export const filterEventsPrim = (results: any[], events: any[]) =>
    new Promise((resolve, reject) => {
        const filtered = [...events, results].filter((item, index) =>
            index === ([...events, results].findIndex((item2) =>
                item2.timestamp === item.timestamp)))
        resolve(filtered.flat().filter((event) => event !== undefined))
    });

export const getInfoEvents = (e: any) =>
    new Promise((resolve, reject) => {
        console.log('getInfoEvents', e)
        if (e?.length > 0) {
            Promise.all(e.map((item: any) => getEventInfo(item)))
                .then((results) => resolve(results))
                .catch((err) => reject(err))
        }
        else {
            resolve([])
        }
    });

export const getEventInfo = (eventId: any) =>
    new Promise((resolve, reject) => {
        Backend(
            MicroServices.Events,
            `/event/${eventId}`)
            .then(async (response) => {
                if (response.status === 404) {
                    console.error('event id does not exist.');
                    return Promise.resolve();
                }
                return response.json()
            })
            .then((results) => {
                resolve(results)
            })
            .catch((error) => {
                reject(error)
            })
    })

export const getMyEventsIds = (username: string, with_limit: boolean, Limit?: string, events?: any[]) =>
    new Promise((resolve, reject) => {
        let limit: string | undefined = undefined;
        if (!Limit) {
            if (with_limit) {
                //Get limit
                const today = new Date()
                const year = today.getFullYear()
                const month = (today.getMonth() + 1) < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
                const day = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()
                let offset = today.getTimezoneOffset() / 60.0
                const offset_part = offset > 0 ? 'm' : 'p'
                offset = Math.abs(offset)
                const offset_info = offset.toString().split('.')
                const off_hours = parseInt(offset_info[0]) < 10 ? `0${offset_info[0]}` : offset_info[0]
                const off_minutes = offset_info[1] ? '30' : '00'
                limit = `${year}-${month}-${day}-${offset_part}-${off_hours}-${off_minutes}`
            }
        }else{
            with_limit ? limit = Limit : limit = undefined
        }

        Backend(
            MicroServices.Events,
            `/events?username=${username}&${limit ? `limit=${limit}` : 'ignoreLimit=true'}`)
            .then((response) => response.json())
            // .then((results: any) => {
            //     return getInfoEvents(results)
            // })
            // .then((resultEvents: any) => {
            //     return filterEventsPrim(resultEvents, events ? events: [])
            // })
            .then((envt: any) => {
                resolve(envt)
            })
            .catch((error: any) => console.error(error, 'Events error'))
    });