import React, { useEffect, useMemo, useState } from 'react'
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { IUserResponse, getUsersResponse } from '@vibe/sdk/interfaces/Admin/User';
import SelectItem from '../../interfaces/SelectItem';
import { sortAlphabeticallyOnProperty } from '../../containers/Signup/SignupHealerInd';

interface useUsersSAProps {
  searchName: string;
}

interface useUsersSAResponse {
  reloadList: () => void;
  allUsersSA: IUserResponse[];
  usersSelector: SelectItem[];
  allFilteredUsersSA: IUserResponse[];
}

const useUsersSA = ({
  searchName,
}: useUsersSAProps): useUsersSAResponse => {

  const [allUsersSA, setAllUsersSA] = useState<IUserResponse[]>([]);
  const [usersSelector, setUsersSelector] = useState<SelectItem[]>([]);
  const [allFilteredUsersSA, setAllFilteredUsersSA] = useState<IUserResponse[]>([]);

  const searchParsed = useMemo(() => searchName.toLowerCase(), [searchName]);

  const reloadList = () => {
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Admin.getUsers({
          roleRequest: 'SA',
        }, dataSDK.token);
      })
      .then((usersR) => {
        if (usersR.statusCode === 200) {
          const users = (usersR.body as getUsersResponse).users;
          setAllUsersSA(sortAlphabeticallyOnProperty(users, 'firstname') as IUserResponse[]);
        }
        else {
          setAllUsersSA([]);
        }
      })
      .catch(console.error);
  }

  const filterUser = (user: IUserResponse): boolean => {
    let acceptedName = true;
    let acceptedRole = true;

    if (searchName && searchName !== '') {
      acceptedName = (user.firstname || '').toLowerCase().includes(searchParsed) ||
        (user.lastname || '').toLowerCase().includes(searchParsed);
    }

    return acceptedRole && acceptedName;
  }

  useEffect(() => {
    if ((!searchName || searchName === '')) {
      setAllFilteredUsersSA(allUsersSA)
      setUsersSelector(allUsersSA.map(o => ({
        label: `${o.firstname} ${o.lastname}` || '',
        value: o.username || '',
      })));
      return;
    }

    const userFiltered = allUsersSA.filter(filterUser);
    setAllFilteredUsersSA(userFiltered)
    setUsersSelector(userFiltered.map(o => ({
      label: `${o.firstname} ${o.lastname}` || '',
      value: o.username || '',
    })));

  }, [searchName, allUsersSA]);

  useEffect(() => reloadList(), []);

  return {
    reloadList,
    allUsersSA,
    usersSelector,
    allFilteredUsersSA,
  }
};

export default useUsersSA;