import { useEffect, useState, useContext } from 'react'
import styled from "styled-components";
import {
    Input, Imp
} from "../signupsetuphealerind"
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faSquareCheck, faCirclePlus, faCross } from '@fortawesome/pro-regular-svg-icons';
import ModalModalities from '../../components/signupsetuphealer/ModalModalities';
import ModalTherapeutic from '../../components/signupsetuphealer/ModalTherapeutic';
import ModalBenefit from '../../components/signupsetuphealer/ModalBenefit';
import Modality from '@vibe/sdk/interfaces/Modalities/modalities/Modality/Modality';
import ModalUnsaved from '../BaseComponents/Modals/ModalUnsaved';
import ButtonSpinner from '../BaseComponents/ButtonSpinner';
import useModalitiesSA from '../../hooks/Superadmin/Modalities/useModalitiesSA';
import useBenefitsSA from '../../hooks/Superadmin/Modalities/useBESA';
import useTherapeuticAreasSA from '../../hooks/Superadmin/Modalities/useTASA';
import Delivery from '@vibe/sdk/interfaces/Modalities/hservices/Delivery';
import { capitalize } from '../../helpers/letterHelper';
import ErrorField from '../BaseComponents/ErrorField';
import IClub from '@vibe/sdk/dist/interfaces/Club/ClubInterface';
import IResponseError from '@vibe/sdk/dist/interfaces/IResponseError';
import { useDispatch, useSelector } from 'react-redux';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { getBenefits, getHealerServices, getTherapeuticAreas } from '../../store/actions/modalityActions';
import { RootState } from '../../store/store';
import IHS from '@vibe/sdk/dist/interfaces/Modalities/hservices/HS';
import { ClickableIcon } from '../SuperCenter/Modalities/Modals/components';
import TextEditor from '../BaseComponents/TextEditor';
import { ContextSuccess } from "../../Context";
import { BaseFieldState, getFieldState } from '../BaseComponents/utils';
import { onHideType } from "../../components/BaseComponents/Modals/ModalUnsaved";

export type deliveryType = 'private' | 'group' | 'corporate' | 'workshop' | 'retreats';

export const basicDelivery = (): Delivery[] => {
    const deliveryTypes: deliveryType[] = ['private', 'group', 'corporate', 'workshop', 'retreats'];
    const deliveryInit: Delivery[] = [];

    deliveryTypes.forEach(deliveryType => {
        deliveryInit.push({
            type: deliveryType,
            inPerson: false,
            online: false,
            combined: false,
        })
    });

    return deliveryInit;
}

const ModalServices = ({
    showModal,
    onHide,
    data,
    edit,
    serviceInfo
}: {
    showModal: boolean,
    onHide: (show: boolean, value?: Modality) => void,
    data: Modality,
    edit?: boolean,
    serviceInfo?: IHS,
}) => {

    const dispatch = useDispatch();
    const currentSuccess = useContext(ContextSuccess);
    const userInfo = useSelector((state: RootState | any) => state.getUser.userInfo);

    const { modalitiesPages, reloadModalities } = useModalitiesSA({
        pageSizeProp: 1000,
        searchName: '',
    });
    const { benefitsPages, reloadBenefits } = useBenefitsSA({
        pageSizeProp: 100,
        searchName: '',
    });
    const { taPages, reloadTherapeuticAreas } = useTherapeuticAreasSA({
        pageSizeProp: 100,
        searchName: '',
    });

    const [tabActive, setTabActive] = useState<number>(1)

    const [showModalModalities, setShowModalModalities] = useState(false);
    const [showModalTherapeutic, setShowModalTherapeutic] = useState(false);
    const [showModalBenefit, setShowModalBenefit] = useState(false);
    const [showUnsavedData, setShowUnsavedData] = useState(false);
    const [loading, setLoading] = useState(false);

    const [selectAllMod, setSelectAllMod] = useState(false);
    const [notApplyMod, setNotApplyMod] = useState(false);
    const [selectAllTA, setSelectAllTA] = useState(false);
    const [notApplyTA, setNotApplyTA] = useState(false);
    const [selectAllBE, setSelectAllBE] = useState(false);
    const [notApplyBE, setNotApplyBE] = useState(false);

    const [modName, setModName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [modDescription, setModDescription] = useState<BaseFieldState<string>>(getFieldState(''));
    const [modModalities, setModModalities] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [modTA, setModTA] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [modBE, setModBE] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [modTACreate, setModTACreate] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [modBECreate, setModBECreate] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [modDelivery, setModDelivery] = useState<BaseFieldState<Delivery[]>>(getFieldState([...basicDelivery()]));

    const cleanFields = () => {
        setModName(getFieldState(''));
        setModDescription(getFieldState(''));
        setModModalities(getFieldState([]));
        setModTA(getFieldState([]));
        setModBE(getFieldState([]));
        setModDelivery(getFieldState([...basicDelivery()]));
        setModTACreate(getFieldState([]));
        setModBECreate(getFieldState([]));

        setTabActive(1);
        setSelectAllMod(false);
        setSelectAllTA(false);
        setSelectAllBE(false);
        setNotApplyMod(false);
        setNotApplyTA(false);
        setNotApplyBE(false);
    }

    const loadData = () => {
        if (edit && serviceInfo) {
            setModName({
                ...modName,
                value: serviceInfo.name,
            });
            setModDescription({
                ...modDescription,
                value: serviceInfo.description,
            });
            setModModalities({
                ...modModalities,
                value: serviceInfo.modalityIds,
            });
            setModTA({
                ...modTA,
                value: serviceInfo.TAIds,
            });
            setModBE({
                ...modBE,
                value: serviceInfo.BEIds,
            });
            setModDelivery({
                ...modDelivery,
                value: serviceInfo.delivery,
            })
        }
    }

    const updateFields = () => {
        cleanFields();
        if (data.name === 'custom') return;

        setModModalities({
            ...modModalities,
            value: [data.id],
        });

        setModName({
            ...modName,
            value: data.name
        });

        if (data.description) {
            setModDescription({
                ...modDescription,
                value: data.description
            });
        }

        if (data.therapeuticAreas) {
            setModTA({
                ...modTA,
                value: data.therapeuticAreas.map((mod) => mod.id)
            });
        }

        if (data.benefits) {
            setModBE({
                ...modBE,
                value: data.benefits.map((mod) => mod.id)
            });
        }

        if (data.parentMSs) {
            setModModalities({
                ...modModalities,
                value: [data.id, ...data.parentMSs.map((mod) => mod.id)]
            });
        }
        else {
            setModModalities({
                ...modModalities,
                value: [data.id]
            });
        }
    }

    const checkData = (): boolean => {
        let hasErrors = false;

        if (!modName.value || modName.value?.length === 0) {
            hasErrors = true;
            setModName({
                ...modName,
                showError: true,
                error: 'Modality name is required'
            })
        }
        else {
            setModName({
                ...modName,
                showError: false,
                error: ''
            })
        }

        if (!modDescription.value || modDescription.value?.length === 0) {
            hasErrors = true;
            setModDescription({
                ...modDescription,
                showError: true,
                error: 'Modality description is required'
            })
        } else {
            setModDescription({
                ...modDescription,
                showError: false,
                error: ''
            })
        }

        return hasErrors;
    }

    const handleCreateService = (toSend: Omit<IHS, "id" | "username">) => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.createHS(userInfo.username, toSend, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 201) {
                    dispatch(getHealerServices(userInfo.username));
                    dispatch(getBenefits());
                    dispatch(getTherapeuticAreas());
                    cleanFields();
                    currentSuccess?.setSuccessData(true, true, 'Service created successfully.');
                    currentSuccess?.setModalsOff();
                }
                else {
                    currentSuccess?.setSuccessData(false, true, (results.body as IResponseError).error || (results.body as IResponseError).message);
                }
            })
            .finally(() => {
                setLoading(false)
                reloadBenefits()
                reloadTherapeuticAreas()
                onHide(false);
            })
    }

    const handleUpdateService = (id: string, toSend: Partial<IHS>) => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.editHS(userInfo.username, id, toSend, dataSDK.token)
            })
            .then((results) => {
                if (results.statusCode === 201) {
                    dispatch(getHealerServices(userInfo.username));
                    dispatch(getBenefits());
                    dispatch(getTherapeuticAreas());
                    cleanFields();
                    currentSuccess?.setSuccessData(true, true, 'Service updated successfully.');
                }
                else {
                    currentSuccess?.setSuccessData(false, true, (results.body as IResponseError).error || (results.body as IResponseError).message);
                }
            })
            .finally(() => {
                setLoading(false)
                reloadBenefits()
                reloadTherapeuticAreas()
                onHide(false);
            })
    }

    const handleOnSaveService = () => {
        setLoading(true);
        if (checkData()) {
            setLoading(false);
            return;
        }

        const toSend: Omit<IHS, "id" | "username"> = {
            name: modName.value || '',
            description: modDescription.value || '',
            modalityIds: modModalities.value || [],
            TAIds: modTA.value || [],
            BEIds: modBE.value || [],
            delivery: modDelivery.value || [],
            benefitsCreate: (modBECreate.value || []).map((BE) => ({
                type: 'NEW',
                name: BE,
            })),
            therapeuticAreasCreate: (modTACreate.value || []).map((TA) => ({
                type: 'NEW',
                name: TA,
            })),
            createdAt: `${new Date().valueOf()}`,
        }

        if (edit && serviceInfo?.id) handleUpdateService(serviceInfo.id, toSend)
        else handleCreateService(toSend)
    }

    const onHideUnsaved = (type: onHideType) => {
        switch (type) {
            case 'close':
                setShowUnsavedData(false);
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                setShowUnsavedData(false);
                currentSuccess?.setModalsOff();
                onHide(false);
                cleanFields();
                break;

            case 'save':
                setShowUnsavedData(false);
                handleOnSaveService();
                break;

            default:
                setShowUnsavedData(false);
                break;
        }
    }

    const handleUnsaved = () => {
        currentSuccess?.setModalWarning(
            'UNSAVED',
            (type) => onHideUnsaved(type),
            'Do you want to save your changes?'
        );
    }

    const handleNext = () => {
        if (tabActive === 1) {
            if (checkData()) return;
        }

        setTabActive(tabActive + 1)
    }

    const handleTab = (tab: number) => {
        if (tabActive === 1) {
            if (checkData()) return;
        }

        setTabActive(tab)
    }

    const handleSelectAllMod = (value: boolean) => {
        if (!value) {
            setModModalities({
                ...modModalities,
                value: []
            })
        }
        else {
            setModModalities({
                ...modModalities,
                value: modalitiesPages[0].map((mod) => mod.id)
            })
        }

        setSelectAllMod(value);
    }

    const handleSelectAllBE = (value: boolean) => {
        if (!value) {
            setModBE({
                ...modBE,
                value: []
            })
        }
        else {
            setModBE({
                ...modBE,
                value: benefitsPages[0].map((mod) => mod.id)
            })
        }
        setSelectAllBE(value);
    }

    const handleSelectAllTA = (value: boolean) => {
        if (!value) {
            setModTA({
                ...modTA,
                value: []
            })
        }
        else {
            setModTA({
                ...modTA,
                value: taPages[0].map((mod) => mod.id)
            })
        }

        setSelectAllTA(value);
    }

    const getTenMod = () => {
        return modalitiesPages[0].slice(0, 11);
    }

    const handleModalities = (id: string) => {
        if (modModalities.value?.includes(id)) {
            setModModalities({
                ...modModalities,
                value: (modModalities.value || []).filter(comm => comm !== id)
            })
            return;
        }

        setModModalities({
            ...modModalities,
            value: [...(modModalities.value || []), id]
        })
    }

    const handleTA = (id: string) => {
        if (modTA.value?.includes(id)) {
            setModTA({
                ...modTA,
                value: (modTA.value || []).filter(comm => comm !== id)
            })
            return;
        }

        setModTA({
            ...modTA,
            value: [...(modTA.value || []), id]
        })
    }

    const handleBE = (id: string) => {
        if (modBE.value?.includes(id)) {
            setModBE({
                ...modBE,
                value: (modBE.value || []).filter(comm => comm !== id)
            })
            return;
        }

        setModBE({
            ...modBE,
            value: [...(modBE.value || []), id]
        })
    }

    const handleDelivery = (type: string, topic: string, data: boolean) => {
        const deliveryList: Delivery[] = modDelivery.value || [];
        const listDelivery = deliveryList.findIndex((deliver) => deliver.type === type);
        switch (topic) {
            case 'online':
                deliveryList[listDelivery].online = data
                break;
            case 'inPerson':
                deliveryList[listDelivery].inPerson = data
                break;
            case 'combined':
                deliveryList[listDelivery].combined = data
                break;
            default:
                break;
        }
        setModDelivery({
            ...modDelivery,
            value: deliveryList,
        })
    }

    const onHideModalities = (modalities: string[]) => {
        setModModalities({
            ...modModalities,
            value: modalities
        })
        setShowModalModalities(false);
    }

    const onHideTherapeutic = (newName?: string) => {
        setShowModalTherapeutic(false);
        if (!newName) return;
        setModTACreate({
            ...modTACreate,
            value: [...(modTACreate.value || []), newName]
        })
    }

    const handleDeleteTA = (TA: string) => {
        setModTACreate({
            ...modTACreate,
            value: (modTACreate.value || []).filter((t) => t !== TA)
        })
    }

    const onHideBenefit = (newName?: string) => {
        setShowModalBenefit(false);
        if (!newName) return;
        setModBECreate({
            ...modBECreate,
            value: [...(modBECreate.value || []), newName]
        })
    }

    const handleDeleteBE = (BE: string) => {
        setModBECreate({
            ...modBECreate,
            value: (modBECreate.value || []).filter((t) => t !== BE)
        })
    }

    const handleOnBlurDescription = (e: string) => {
        setModDescription({
            ...modDescription,
            value: e
        })
    }

    useEffect(() => {
        if (edit) loadData();
        else updateFields();
    }, [data, serviceInfo, edit])

    return (
        <>
            <Modal className="modalSetup" show={showModal}>
                <Modal.Header>
                    <Modal.Title>
                        {(modName.value === '') &&
                            <Tooltip>Start by giving this a name</Tooltip>
                        }
                        <InputOnlyPlaceholder
                            value={modName.value}
                            onChange={(e) => setModName({
                                ...modName,
                                value: e.currentTarget.value
                            })}
                            placeholder="Give it a name" />
                        <IconClicker
                            onClick={handleUnsaved}
                            icon="times"
                            size='xl'
                            color={'black'}
                        />
                    </Modal.Title>
                    {(modName.showError) && (<ErrorField error={modName.error} />)}
                </Modal.Header>
                <Modal.Body>
                    <Tabs>
                        <Tab theme={{ main: tabActive === 1 && 'tabActive' }} onClick={() => { handleTab(1) }}>About</Tab>
                        <Tab theme={{ main: tabActive === 2 && 'tabActive' }} onClick={() => { handleTab(2) }}>Conditions Treated</Tab>
                        <Tab theme={{ main: tabActive === 3 && 'tabActive' }} onClick={() => { handleTab(3) }}>Benefits</Tab>
                        <Tab theme={{ main: tabActive === 4 && 'tabActive' }} onClick={() => { handleTab(4) }}>Delivery</Tab>
                    </Tabs>
                    {tabActive === 1 ?
                        <ContainerTabs>
                            <LabelDescription>Description<Imp>*</Imp></LabelDescription>
                            <TextEditor
                                value={modDescription.value || ''}
                                setValue={handleOnBlurDescription}
                                charLimit={2500}
                                minHeight={120}
                                placeholder={'Please describe the service you are creating..'}
                                showError={modDescription.showError}
                            />
                        </ContainerTabs>
                        :
                        tabActive === 2 ?
                            <>
                                <ContainerTabs>
                                    <Label>Please, select the conditions your service treats</Label>
                                    <ListCheckbox>
                                        <ItemList onClick={() => handleSelectAllTA(!selectAllTA)}>
                                            <RadioIcon
                                                icon={selectAllTA ? faSquareCheck : faSquare}
                                                size='lg'
                                                color={"black"}
                                            />
                                            Select All
                                        </ItemList>
                                        {/* <ItemList onClick={() => setNotApplyTA(!notApplyTA)}>
                                            <RadioIcon
                                                icon={notApplyTA ? faSquareCheck : faSquare}
                                                size='lg'
                                                color={"black"}
                                            />
                                            Doesn't apply
                                        </ItemList> */}
                                    </ListCheckbox>
                                    <ListAreas>
                                        {taPages[0].map((comm) => (
                                            <Area
                                                isSelected={modTA.value?.includes(comm.id) || false}
                                                onClick={() => handleTA(comm.id)}>
                                                {comm.name}
                                            </Area>
                                        ))}
                                        {(modTACreate.value || []).map((TA) => (
                                            <AreaDeleteable isSelected={true}>
                                                {TA}
                                                <ClickableIcon
                                                    icon={'times'}
                                                    size='sm'
                                                    color={'black'}
                                                    onClick={() => handleDeleteTA(TA)}
                                                />
                                            </AreaDeleteable>
                                        ))}
                                    </ListAreas>
                                    <SeeAllModalities onClick={() => setShowModalTherapeutic(true)}>
                                        <PlusIcon
                                            icon={faCirclePlus}
                                            size='lg'
                                            color={"var(--absolute-zero)"}
                                        />
                                        Add another Condition Treated
                                    </SeeAllModalities>
                                </ContainerTabs>
                            </>
                            :
                            tabActive === 3 ?
                                <>
                                    <ContainerTabs>
                                        <Label>What are the benefits?</Label>
                                        <ListCheckbox>
                                            <ItemList onClick={() => handleSelectAllBE(!selectAllBE)}>
                                                <RadioIcon
                                                    icon={selectAllBE ? faSquareCheck : faSquare}
                                                    size='lg'
                                                    color={"black"}
                                                />
                                                Select All
                                            </ItemList>
                                            {/* <ItemList onClick={() => setNotApplyBE(!notApplyBE)}>
                                                <RadioIcon
                                                    icon={notApplyBE ? faSquareCheck : faSquare}
                                                    size='lg'
                                                    color={"black"}
                                                />
                                                Doesn't apply
                                            </ItemList> */}
                                        </ListCheckbox>
                                        <ListAreas>
                                            {benefitsPages[0].map((comm) => (
                                                <Area
                                                    isSelected={modBE.value?.includes(comm.id) || false}
                                                    onClick={() => handleBE(comm.id)}>
                                                    {comm.name}
                                                </Area>
                                            ))}
                                            {(modBECreate.value || []).map((BE) => (
                                                <AreaDeleteable isSelected={true}>
                                                    {BE}
                                                    <ClickableIcon
                                                        icon={'times'}
                                                        size='sm'
                                                        color={'black'}
                                                        onClick={() => handleDeleteBE(BE)}
                                                    />
                                                </AreaDeleteable>
                                            ))}
                                        </ListAreas>
                                        <SeeAllModalities theme={{ border: '0' }} onClick={() => setShowModalBenefit(true)}>
                                            <PlusIcon
                                                icon={faCirclePlus}
                                                size='lg'
                                                color={"var(--absolute-zero)"}
                                            />
                                            Add another benefit
                                        </SeeAllModalities>
                                    </ContainerTabs>
                                </>
                                :
                                <>
                                    <ContainerTabs>
                                        <Label>How do you offer your services?</Label>
                                        <Table>
                                            <Tr>
                                                <Th></Th>
                                                <Th>In person</Th>
                                                <Th>Online</Th>
                                            </Tr>
                                            {(modDelivery.value || []).map((delivery) => (
                                                <Tr>
                                                    <Td>{capitalize(delivery.type)}</Td>
                                                    <Td>
                                                        <CheckIcon
                                                            icon={delivery.inPerson ? faSquareCheck : faSquare}
                                                            size='lg'
                                                            onClick={() => handleDelivery(delivery.type, 'inPerson', !delivery.inPerson)}
                                                        />
                                                    </Td>
                                                    <Td><CheckIcon
                                                        icon={delivery.online ? faSquareCheck : faSquare}
                                                        size='lg'
                                                        onClick={() => handleDelivery(delivery.type, 'online', !delivery.online)}
                                                    /></Td>
                                                </Tr>
                                            ))}
                                        </Table>
                                    </ContainerTabs>
                                </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <ButtonCancel onClick={handleUnsaved}>Cancel</ButtonCancel>
                    {tabActive === 4 ?
                        <WrapperButton theme={{ main: loading ? 'btnLoad' : '' }} >
                            <ButtonSpinner
                                loading={loading}
                                handleClick={handleOnSaveService}
                                text={'Save'}
                                hasIcon={false}
                            />
                        </WrapperButton>
                        :
                        <ButtonSave onClick={handleNext}>
                            Next
                        </ButtonSave>
                    }
                </Modal.Footer>
            </Modal>
            <ModalModalities
                showModal={showModalModalities}
                onHide={onHideModalities}
                modalities={modModalities.value || []}
                selectAll={selectAllMod}
                setSelectAll={setSelectAllMod} />
            <ModalTherapeutic showModal={showModalTherapeutic} onHide={onHideTherapeutic} />
            <ModalBenefit showModal={showModalBenefit} onHide={onHideBenefit} />
            {/* <ModalUnsaved
                show={showUnsavedData}
                onHide={onHideUnsaved}
                content={"Do you want to save your changes?"}
            /> */}
        </>
    )
}

const IconClicker = styled(FontAwesomeIcon)`
    cursor: pointer;
`;

const WrapperError = styled.div`
    padding: 10px;
`;

const WrapperButton = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    width: 50%;
    
    &.btnLoad{
        pointer-events: none;
    }
`;

const Table = styled.table`
    width: 100%;
    border: 1px solid black;
`

const Th = styled.th`
    vertical-align: middle;
    height: 40px;
    border: 1px solid;
    font-family: 'Inter';
    font-size: 14px;
`

const Tr = styled.tr`
    &:nth-child(even) {
        background-color: #EEEEEE;
    }

    & td:first-child {
        font-family: 'Inter';
        font-size: 14px;
        color: var(--default-dark);
        text-align: left;
        padding-left: 10px;
    }
`

const Td = styled.td`
    vertical-align: middle;
    height: 40px;
    border: 1px solid;
    font-size: 14px;
`

const SeeAllModalities = styled.div`
    font-family: 'Inter-Bold';
    font-size: 14px;
    line-height: 17px;
    color: var(--absolute-zero);
    text-align: left;
    margin: 0 15px;
    border-bottom: ${props => props.theme.border || '1'}px solid gray;
    padding-bottom: 13px;
    width: calc(100% - 33px);
    cursor: pointer;
`

const Tooltip = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    width: 400px;
    background-color: var(--simple-blue-dark-2);
    padding: 8px 0;
    border-radius: 8px;
    color: white;
    margin-right: 12px;
    position: relative;

    &::before{
        content: "";
        width: 0;
        height: 0;
        border-left: 6px solid var(--simple-blue-dark-2);
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        position: absolute;
        right: -6px;
        top: 10px;
    }

`

const ButtonCancel = styled.div`
    width: 49%;
    text-align: center;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 1px solid var(--dark-blue);
    cursor: pointer;
`

const ButtonSave = styled.div`
    width: 49%;
    text-align: center;
    background-color: var(--dark-blue);
    color: white;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 0px;
    cursor: pointer;
`

const ContainerTabs = styled.div`
    overflow: auto;
    height: 75vh;
    padding-bottom: 250px;
    padding: 0 24px;
`

const ItemList = styled.div`
    display: flex;
    justify-content: start;
    padding: 3px 0px 3px 0;
    align-items: center;
    font-family: 'Inter';
    font-size: 14px;
    margin-right: 15px;
`

const Label = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    padding: 15px;
    display: flex;
`

const LabelDescription = styled(Label)`
    margin-top: 10px;
`;

const Textarea = styled.div`
    border: 1px solid #747474;
    padding: 0 15px;
    border-radius: 8px;
    font-family: 'Inter';
    margin: 0 15px;
`

const Tabs = styled.div`
    display: flex;
    justify-content: space-evenly;
    border-radius: 8px;
    overflow: hidden;
    padding: 0 24px;
`

const Tab = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    background-color: var(--simple-gray-2);
    width: 33%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: 'Inter';
    cursor: pointer;

    &.tabActive{
        background-color: #E89F4D;
        color: white;
        font-family: 'Inter-Bold';
    }
`

const RadioIcon = styled(FontAwesomeIcon)`
    font-size: 24px;
    margin-right: 15px;
    color: var(--black);
`;

const CheckIcon = styled(FontAwesomeIcon)`
    font-size: 18px;
    color: var(--absolute-zero);
`;

const PlusIcon = styled(FontAwesomeIcon)`
    font-size: 24px;
    margin-right: 10px;
    
`;



export const InputPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0;
    font-size: 15px;
    font-family: 'Inter';
    background-color: transparent;
`;

export const InputOnlyPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--black);
    padding-left: 20px;

    &::placeholder{
        color: var(--black);
        font-family: 'Inter-Bold'
        
    }
`;

interface PropsArea {
    isSelected: boolean;
}

const Area = styled.div`
    border: 1px solid darkgrey;
    width: fit-content;
    background-color: ${(props: PropsArea) => !props.isSelected ? "white" : "var(--lavender)"};
    padding: 7px 12px;
    border-radius: 5px;
    font-family: ${(props: PropsArea) => !props.isSelected ? "Inter-bold" : "Inter"};
    font-size: 14px;
    cursor: pointer;

    &:hover {
        border: 1px solid gray;
    }
`

const AreaDeleteable = styled.div`
    border: 1px solid darkgrey;
    width: fit-content;
    background-color: ${(props: PropsArea) => !props.isSelected ? "white" : "var(--lavender)"};
    padding: 7px 12px;
    border-radius: 5px;
    font-family: ${(props: PropsArea) => !props.isSelected ? "Inter-bold" : "Inter"};
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;

    &:hover {
        border: 1px solid gray;
    }
`
const ListAreas = styled.div`
    width: 100%;
    margin: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding-bottom: 50px;
`

const ListCheckbox = styled.div`
    display: flex;
    margin-left: 15px;
`

export default ModalServices