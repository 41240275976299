import { useContext, useEffect, useState } from 'react'
import styled from "styled-components";
import {
    Input, listAreasModal, Imp, Div_Link
} from "../signupsetuphealerind"
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faSquareCheck, faSearch, faUserPlus, faClone } from '@fortawesome/pro-regular-svg-icons';
import ModalModalities from '../../components/signupsetuphealer/ModalModalities';
import ModalGuide from '../../components/signupsetuphealer/ModalGuide';
import ModalMembers from '../../components/signupsetuphealer/ModalMembers';
import TClub from '@vibe/sdk/dist/interfaces/Club/TClubs/TClub/TClub';
import useModalitiesSA from '../../hooks/Superadmin/Modalities/useModalitiesSA';
import ModalUnsaved from '../BaseComponents/Modals/ModalUnsaved';
import { ProcessInterface } from '../../containers/SetupHealer/SetupHealer';
import ButtonSpinner from '../BaseComponents/ButtonSpinner';
import ErrorField from '../BaseComponents/ErrorField';
import IClub, { IClubExtended } from '@vibe/sdk/dist/interfaces/Club/ClubInterface';
import { useDispatch, useSelector } from 'react-redux';
import { userInfo } from 'os';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { getClubs, getCommunities } from '../../store/actions/clubActions';
import { RootState } from '../../store/store';
import IResponseError from '@vibe/sdk/dist/interfaces/IResponseError';
import { ICreateInviteResponse } from '@vibe/sdk/dist/interfaces/Invite/createInvite';
import { IFetchResponse } from '@vibe/sdk/dist/helpers/fetch.helper';
import { IClubCode } from '@vibe/sdk/dist/interfaces/Club/clubCode';
import { ContextSuccess } from '../../Context';
import { formatLink } from '../../helpers/club/clubHelper';
import { frontendUrl } from '../../AppConfig';
import { IUserGradeResponse } from '@vibe/sdk/dist/interfaces/Admin/User';
import BlankTable from '../BaseComponents/BlankTable';
import TextEditor from '../BaseComponents/TextEditor';
import { BaseFieldState, getFieldState } from '../BaseComponents/utils';
import { onHideType } from "../../components/BaseComponents/Modals/ModalUnsaved";
import { getEmailsSetup } from '../../store/actions/inviteActions';

export interface UserInClub {
    firstName: string;
    lastName: string;
    email: string;
    clubID?: string;
}

const ModalSetup = ({
    showModal,
    onHide,
    data,
    edit,
    clubInfo,
    reloadClubs,
}: {
    showModal: boolean,
    onHide: (show: boolean, value?: TClub) => void,
    data: TClub,
    edit?: boolean,
    clubInfo?: IClubExtended,
    reloadClubs?: () => void;
}) => {

    const currentSuccess = useContext(ContextSuccess);

    const dispatch = useDispatch();

    const organization = useSelector((state: RootState | any) => state.getOrgs.organizationInfo);
    const user: IUserGradeResponse = useSelector((state: RootState | any) => state.getUser.user);

    const { modalitiesPages, reloadModalities } = useModalitiesSA({
        pageSizeProp: 1000,
        searchName: '',
    });

    const [selectAll, setSelectAll] = useState(false);
    const [notApply, setNotApply] = useState(false);

    const [showModalModalities, setShowModalModalities] = useState(false);
    const [showModalGuide, setShowModalGuide] = useState(false);
    const [showModalMembers, setShowModalMembers] = useState(false);

    const [loading, setLoading] = useState(false);

    const [teamName, setTeamName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [teamDescription, setTeamDescription] = useState<BaseFieldState<string>>(getFieldState(''));
    const [teamModalities, setTeamModalities] = useState<BaseFieldState<string[]>>(getFieldState([]));

    //Invites
    const [teamAdvisors, setTeamAdvisors] = useState<BaseFieldState<UserInClub[]>>(getFieldState([]));

    const [teamIndividuallyMembers, setTeamIndividuallyMembers] = useState<BaseFieldState<UserInClub[]>>(getFieldState([]));
    const [teamRosters, setTeamRosters] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [teamMembers, setTeamMembers] = useState<BaseFieldState<string>>(getFieldState(''));
    const [clubCodes, setClubCodes] = useState<Omit<IClubCode, 'club_id'>[]>([]);

    const [invitesRosters, setInvitesRosters] = useState<number>(0);
    const [invitesIndividual, setInvitesIndividual] = useState<number>(0);

    const cleanFields = () => {
        setNotApply(false);
        setSelectAll(false);
        setShowModalModalities(false);
        setShowModalGuide(false);
        setShowModalMembers(false);
        setLoading(false);

        setTeamName(getFieldState(''));
        setTeamDescription(getFieldState(''));
        setTeamModalities(getFieldState([]));

        setInvitesRosters(0);
        setInvitesIndividual(0);

        setTeamAdvisors(getFieldState([]));
        setTeamRosters(getFieldState([]))
        setTeamMembers(getFieldState(''))
        setTeamIndividuallyMembers(getFieldState([]))
        setClubCodes([]);
    }

    const loadData = () => {
        if (edit && clubInfo) {
            setTeamName({
                ...teamName,
                value: clubInfo.name,
            });
            setTeamDescription({
                ...teamDescription,
                value: clubInfo.description,
            })
            setTeamModalities({
                ...teamModalities,
                value: clubInfo.modalitiesIds,
            })
            setTeamAdvisors({
                ...teamAdvisors,
                value: (clubInfo.advInvites || []).map((invite) => ({
                    firstName: invite.name.split(' ')[0],
                    lastName: invite.name.split(' ')[1],
                    email: invite.email,
                }))
            })
            setTeamIndividuallyMembers({
                ...teamIndividuallyMembers,
                value: (clubInfo.invites || [])
                    .filter((invite) =>
                        (clubInfo.advInvites ?? [])
                            .findIndex((adv) => adv.email === invite.email) === -1
                    ).map((invite) => ({
                        firstName: invite.firstName,
                        lastName: invite.lastName,
                        email: invite.email,
                    }))
            })
            setInvitesRosters(clubInfo.invitesCount.roster);
            setInvitesIndividual(clubInfo.invitesCount.individual);
        }
    }

    const updateFields = () => {
        cleanFields();

        setTeamAdvisors({
            ...teamAdvisors,
            value: [{
                firstName: user.firstname || '',
                lastName: user.lastname || '',
                email: user.email || '',
            }]
        })

        if (data.name === 'custom') return;
        if (data.name === 'Staff') setNotApply(true);

        setTeamName({
            ...teamName,
            value: data.name
        });

        setTeamDescription({
            ...teamDescription,
            value: data.description
        });
    }

    const checkMembers = () => {
        let hasInd = false;
        let hasRoster = false;
        let hasLink = false;

        if (teamIndividuallyMembers.value && teamIndividuallyMembers.value?.length > 0) {
            hasInd = true;
        }
        if (teamRosters.value && teamRosters.value?.length > 0) {
            hasRoster = true;
        }
        if (clubCodes && clubCodes.length > 0) {
            hasLink = true;
        }

        return hasInd || hasRoster || hasLink
    }

    const checkData = (): boolean => {
        let hasErrors = false;

        if (!teamName.value || teamName.value?.length === 0) {
            hasErrors = true;
            setTeamName({
                ...teamName,
                showError: true,
                error: 'Internal team name is required'
            })
        }
        else if (teamName.value === data.name) {
            hasErrors = true;
            setTeamName({
                ...teamName,
                showError: true,
                error: 'New name is required'
            })
        }
        else {
            setTeamName({
                ...teamName,
                showError: false,
                error: ''
            })
        }

        if (!teamDescription.value || teamDescription.value?.length === 0) {
            hasErrors = true;
            setTeamDescription({
                ...teamDescription,
                showError: true,
                error: 'Internal team description is required'
            })
        } else {
            setTeamDescription({
                ...teamDescription,
                showError: false,
                error: ''
            })
        }

        if (!teamAdvisors.value || teamAdvisors.value?.length === 0) {
            hasErrors = true;
            setTeamAdvisors({
                ...teamAdvisors,
                showError: true,
                error: 'At least one advisor is required'
            })
        } else {
            setTeamAdvisors({
                ...teamAdvisors,
                showError: false,
                error: ''
            })
        }

        if (!checkMembers()) {
            hasErrors = true;
            setTeamMembers({
                ...teamMembers,
                showError: true,
                error: 'At least one member is required'
            })
        } else {
            setTeamMembers({
                ...teamMembers,
                showError: false,
                error: ''
            })
        }

        return hasErrors;
    }

    const handleOnCreateClub = (toSend: Omit<IClub, 'id'>) => {

        let dataSDK: getDataSDKResponse;
        let id: string = "";
        const errors: string[] = [];

        getDataSDK()
            .then((data: getDataSDKResponse) => {
                dataSDK = data;
                return dataSDK.sdk.Club.createClub(toSend, dataSDK.token);
            })
            .then((results: IFetchResponse<{ id: string } | IResponseError>) => {
                if (results.statusCode === 201) {
                    id = (results.body as { id: string }).id;

                    //Add new guides
                    return Promise.all((teamAdvisors.value || [])
                        .map((advisor: UserInClub) => dataSDK.sdk.Invite.createInvite({
                            email: advisor.email,
                            org_id: organization.id,
                            club_id: id,
                            is_advisor: true,
                            firstName: advisor.firstName,
                            lastName: advisor.lastName,
                        }, dataSDK.token)));
                        currentSuccess?.setModalsOff();
                }
                else {
                    errors.push("Error creating internal team:", (results.body as IResponseError).error || (results.body as IResponseError).message);
                    throw new Error('Failed to create internal team');
                }
            })
            .then((results: IFetchResponse<IResponseError | ICreateInviteResponse>[]) => {

                results.forEach((result) => {
                    if (result.statusCode !== 201) {
                        errors.push("Error adding guides:", (result.body as IResponseError).error || (result.body as IResponseError).message);
                    }
                })

                //Add new individual members
                if ((teamIndividuallyMembers.value || []).length > 0) {
                    return Promise.all((teamIndividuallyMembers.value || [])
                        .map((member: UserInClub) => dataSDK.sdk.Invite.createInvite({
                            email: member.email,
                            org_id: organization.id,
                            club_id: id,
                            is_advisor: false,
                            firstName: member.firstName,
                            lastName: member.lastName,
                        }, dataSDK.token)));
                }
                else {
                    return Promise.resolve([{
                        body: {},
                        statusCode: 201,
                        headers: undefined,
                    }]);
                }
            })
            .then((results: IFetchResponse<IResponseError | ICreateInviteResponse>[]) => {

                results.forEach((result) => {
                    if (result.statusCode !== 201) {
                        errors.push("Error adding individual members:", (result.body as IResponseError).error || (result.body as IResponseError).message);
                    }
                })

                //Add code club link 
                if (clubCodes.length > 0) {
                    return Promise.all(clubCodes.map((c) => dataSDK.sdk.Club.relateClubCodeToClub({
                        code: c.code,
                        club_id: id,
                    }, dataSDK.token)))
                }
                else {
                    return Promise.resolve([{
                        body: {},
                        statusCode: 201,
                        headers: undefined,
                    }]);
                }
            })
            .then((results: IFetchResponse<IResponseError | IClubCode>[]) => {

                if (!results.every((r) => r.statusCode === 201)) {
                    errors.push("Error adding club code:", (results[0].body as IResponseError).error || (results[0].body as IResponseError).message);
                }

                //Invite rosters (if exist)
                if ((teamRosters.value || []).length > 0) {
                    return Promise.all((teamRosters.value || [])
                        .map((roster: string) => dataSDK.sdk.Invite.inviteRoster({
                            file: roster,
                            org_id: organization.id,
                            club_id: id
                        }, dataSDK.token)));
                }
                else {
                    return Promise.resolve([{
                        body: {},
                        statusCode: 200,
                        headers: undefined,
                    }]);
                }
            })
            .then((results: IFetchResponse<IResponseError | undefined>[]) => {

                results.forEach((result) => {
                    if (result.statusCode !== 200) {
                        errors.push("Error adding rosters:", (result.body as IResponseError).error || (result.body as IResponseError).message);
                    }
                })

                dispatch(getCommunities(organization.id, organization.status.startsWith('PE')));
                dispatch(getEmailsSetup(organization.id, organization.status.startsWith('PE')));
                if(reloadClubs) reloadClubs();
                cleanFields();
                onHide(false);
            })
            .catch((error) => {
                currentSuccess?.setSuccessData(false, true, "Error creating internal team", error);
                console.log("Error creating internal team", error);
            })
            .finally(() => {
                if (errors.length > 0) {
                    currentSuccess?.setSuccessData(false, true, "Error creating internal team");
                    console.group('Errors creating internal team');
                    errors.forEach((error) => console.log(error));
                    console.groupEnd();
                }
                else currentSuccess?.setSuccessData(true, true, "Info Saved!", "Your internal team has been created.");
                setLoading(false);
            })
    }

    const handleOnUpdateClub = (toSend: Partial<IClub>, id: string,) => {
        if (!clubInfo) return;

        let dataSDK: getDataSDKResponse;
        const errors: string[] = [];
        getDataSDK()
            .then((data: getDataSDKResponse) => {
                dataSDK = data;
                return dataSDK.sdk.Club.updateClub(id, toSend, dataSDK.token);
            })
            .then((results: IFetchResponse<IClub | IResponseError>): Promise<(IFetchResponse<IResponseError | ICreateInviteResponse | undefined>)[]> => {
                if (results.statusCode !== 200) {
                    errors.push("Error creating internal team:", (results.body as IResponseError).error || (results.body as IResponseError).message);
                    throw new Error('Failed to update internal team');
                }

                const newGuides = (teamAdvisors.value ?? [])
                    .filter((adv) =>
                        (clubInfo.advInvites ?? [])
                            .findIndex((advInv) => advInv.email === adv.email) === -1
                    );

                const toDeleteGuidesEmails = (clubInfo.advInvites ?? [])
                    .filter((adv) =>
                        (teamAdvisors.value ?? [])
                            .findIndex((advInv) => advInv.email === adv.email) === -1
                    )
                    .map(({ email }) => email as string);

                if (newGuides.length > 0 || toDeleteGuidesEmails.length > 0) {
                    return Promise.all([
                        ...(newGuides || [])
                            .map((advisor: UserInClub) => dataSDK.sdk.Invite.createInvite({
                                email: advisor.email,
                                org_id: organization.id,
                                club_id: id,
                                is_advisor: true,
                                firstName: advisor.firstName,
                                lastName: advisor.lastName,
                            }, dataSDK.token)),
                        ...(toDeleteGuidesEmails || [])
                            .map((email: string) => dataSDK.sdk.Invite.hardDeleteInvite({
                                club_id: id,
                                email,
                                invite_type: 'Advisor',
                            }, dataSDK.token))
                    ] as Promise<(IFetchResponse<IResponseError | ICreateInviteResponse | undefined>)>[]);
                } else {
                    return Promise.resolve([{
                        statusCode: 201,
                        headers: undefined,
                    }] as IFetchResponse<IResponseError | undefined>[]);
                }
            })
            .then((results: (IFetchResponse<IResponseError | ICreateInviteResponse | undefined>)[]): Promise<(IFetchResponse<IResponseError | ICreateInviteResponse> | IFetchResponse<IResponseError | undefined>)[]> => {
                results.forEach((result) => {
                    if (result.statusCode !== 201) {
                        errors.push("Error updating guides:", (result.body as IResponseError).error || (result.body as IResponseError).message);
                        throw new Error('Failed to update internal team');
                    }
                })

                const newInvites = (teamIndividuallyMembers.value ?? [])
                    .filter((ind) =>
                        (clubInfo.invites ?? [])
                            .findIndex((newInv) => newInv.email === ind.email) === -1
                    )

                const toDeleteInvites = (clubInfo.invites ?? [])
                    .filter((ind) =>
                        (clubInfo.invites ?? [])
                            .findIndex((newInv) => newInv.email === ind.email) === -1
                    )

                if (newInvites.length > 0 || toDeleteInvites.length > 0) {
                    return Promise.all([
                        ...(newInvites || [])
                            .map((advisor: UserInClub) => dataSDK.sdk.Invite.createInvite({
                                email: advisor.email,
                                org_id: organization.id,
                                club_id: id,
                                is_advisor: true,
                                firstName: advisor.firstName,
                                lastName: advisor.lastName,
                            }, dataSDK.token)),
                        ...(toDeleteInvites || [])
                            .map((invite) => dataSDK.sdk.Invite.hardDeleteInvite({
                                club_id: id,
                                email: invite.email,
                                invite_type: 'End_User',
                            }, dataSDK.token))
                    ] as Promise<(IFetchResponse<IResponseError | ICreateInviteResponse> | IFetchResponse<IResponseError | undefined>)>[]);
                } else {
                    return Promise.resolve([{
                        body: {},
                        statusCode: 201,
                        headers: undefined,
                    }] as IFetchResponse<IResponseError | undefined>[]);
                }

            })
            .then((results: (IFetchResponse<IResponseError | ICreateInviteResponse> | IFetchResponse<IResponseError | undefined>)[]) => {

                results.forEach((result) => {
                    if (result.statusCode !== 201) {
                        errors.push("Error adding individual members:", (result.body as IResponseError).error || (result.body as IResponseError).message);
                    }
                })

                //Add code club link 
                if (clubCodes.length > 0) {
                    return Promise.all(clubCodes.map((c) => dataSDK.sdk.Club.relateClubCodeToClub({
                        code: c.code,
                        club_id: clubInfo!.id,
                    }, dataSDK.token)))
                }
                else {
                    return Promise.resolve([{
                        statusCode: 201,
                        headers: undefined,
                    }] as IFetchResponse<IClubCode>[]);
                }
            })
            .then((results: IFetchResponse<IResponseError | IClubCode>[]) => {

                if (!results.every((r) => r.statusCode === 201)) {
                    errors.push("Error adding club code:", (results[0].body as IResponseError).error || (results[0].body as IResponseError).message);
                }

                //Invite rosters (if exist)
                if ((teamRosters.value || []).length > 0) {
                    return Promise.all((teamRosters.value || [])
                        .map((roster: string) => dataSDK.sdk.Invite.inviteRoster({
                            file: roster,
                            org_id: organization.id,
                            club_id: id
                        }, dataSDK.token)));
                }
                else {
                    return Promise.resolve([{
                        body: {},
                        statusCode: 200,
                        headers: undefined,
                    }]);
                }
            })
            .then((results: IFetchResponse<IResponseError | undefined>[]) => {

                results.forEach((result) => {
                    if (result.statusCode !== 200) {
                        errors.push("Error adding rosters:", (result.body as IResponseError).error || (result.body as IResponseError).message);
                    }
                })

                //Invite rosters (if exist)
                dispatch(getCommunities(organization.id, organization.status.startsWith('PE')));
                dispatch(getEmailsSetup(organization.id, organization.status.startsWith('PE')));
                if(reloadClubs) reloadClubs();
                cleanFields();
                onHide(false);
            })
            .catch((error) => {
                currentSuccess?.setSuccessData(false, true, "Error creating internal team", error);
                console.log("Error creating internal team", error);
            })
            .finally(() => {
                if (errors.length > 0) {
                    currentSuccess?.setSuccessData(false, true, "Error creating internal team");
                    console.group('Errors creating internal team');
                    errors.forEach((error) => console.log(error));
                    console.groupEnd();
                }
                else currentSuccess?.setSuccessData(true, true, "Info Saved!", "Your internal team has been created.");
                setLoading(false);
            })
            .finally(() => setLoading(false))

        //Add new guides
        //Invite Members 

        //cleanFields();
        //onHide(false); 
    }

    const handleOnSaveTeam = () => {
        setLoading(true);

        if (checkData()) {
            setLoading(false);
            return;
        }

        const toSend: Omit<IClub, 'id'> = {
            org_id: organization.id || '',
            status: 'PENDING',
            name: teamName.value || '',
            template_id: data.id || '',
            member_type: data.member_types || [],
            role_type: data.role_assigned || 'COMMITTEE',
            club_type: data.club_type || 'COMM',
            description: teamDescription.value || '',
            gendersAllowed: ['M', 'O', 'P', 'F'],
            modalitiesIds: teamModalities.value || [],
            clubTag: `${organization.name} ${teamName.value}`,
            orgName: organization.name || '',
            orgLogo: organization.logo,
            createdAt: `${new Date().valueOf()}`,
        }

        if (edit && clubInfo?.id) handleOnUpdateClub(toSend, clubInfo?.id)
        else handleOnCreateClub(toSend)
    }

    const onHideUnsaved = (type: onHideType) => {
        switch (type) {
            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                onHide(false);
                currentSuccess?.setModalsOff();
                cleanFields();
                break;

            case 'save':
                currentSuccess?.setModalsOff();
                handleOnSaveTeam();
                break;

            default:
                currentSuccess?.setModalsOff();
                break;
        }
    }

    const handleUnsaved = () => {
        currentSuccess?.setModalWarning(
            'UNSAVED',
            (type) => onHideUnsaved(type),
            'Do you want to save your changes?'
        );
    }

    const getTenMod = () => {
        return modalitiesPages[0].slice(0, 11);
    }

    const handleModalities = (id: string) => {
        if (teamModalities.value?.includes(id)) {
            setTeamModalities({
                ...teamModalities,
                value: (teamModalities.value || []).filter(comm => comm !== id)
            })
            return;
        }

        setTeamModalities({
            ...teamModalities,
            value: [...(teamModalities.value || []), id]
        })
    }

    const handleSelectAll = (value: boolean) => {
        if (!value) {
            setTeamModalities({
                ...teamModalities,
                value: []
            })
        }
        else {
            setTeamModalities({
                ...teamModalities,
                value: modalitiesPages[0].map((mod) => mod.id)
            })
        }

        setSelectAll(value);
    }

    const onHideModalities = (modalities: string[]) => {
        setTeamModalities({
            ...teamModalities,
            value: modalities
        })
        setShowModalModalities(false);
    }

    const onHideGuide = (Advisors: UserInClub[]) => {
        setTeamAdvisors({
            ...teamAdvisors,
            value: Advisors
        })
        setShowModalGuide(false);
    }

    const onHideMembers = (type: 'CANCEL' | 'SUBMIT', individuals: UserInClub[], rosters: string[], codes?: Omit<IClubCode, 'club_id'>[], invites?: number) => {
        if (invites) {
            setInvitesRosters(invites)
        }

        if(type === 'SUBMIT') setInvitesIndividual(individuals.length);

        setTeamIndividuallyMembers({
            ...teamIndividuallyMembers,
            value: individuals
        })
        setTeamRosters({
            ...teamRosters,
            value: rosters
        })
        setClubCodes(codes ?? []);
        setShowModalMembers(false);
    }

    const copyToClipboard = (toCopy: string) => {
        navigator.clipboard.writeText(formatLink(toCopy));
        currentSuccess?.setSuccessData(true, true, 'Copied to clipboard');
    }

    const handleOnBlurDescription = (e: string) => {
        setTeamDescription({
            ...teamDescription,
            value: e
        })
    }

    useEffect(() => {
        if (edit) loadData();
        else updateFields();
    }, [data, clubInfo, edit, showModal])

    return (
        <>
            <Modal className="modalSetup" show={showModal}>
                <Modal.Header>
                    <Modal.Title>
                        {(teamName.value === '' || teamName.value === data.name) &&
                            <Tooltip>Start by giving this a name</Tooltip>
                        }
                        <InputOnlyPlaceholder
                            value={teamName.value || ''}
                            onChange={(e) => setTeamName({
                                ...teamName,
                                value: e.currentTarget.value
                            })}
                            placeholder="Give me a name"
                        />
                        <IconClickable
                            onClick={handleUnsaved}
                            icon="times"
                            size='xl'
                            color={'black'}
                        />
                    </Modal.Title>
                    {(teamName.showError) && (<ErrorField error={teamName.error} />)}
                </Modal.Header>
                <Modal.Body>
                    <ContainerTabs>
                        <Label>What modalities or services does this {data.name} team support?</Label>
                        <ListCheckbox>
                            <ItemList onClick={() => handleSelectAll(!selectAll)}>
                                <RadioIcon
                                    icon={selectAll ? faSquareCheck : faSquare}
                                    size='lg'
                                    color={"black"}
                                />
                                Select All
                            </ItemList>
                            <ItemList onClick={() => setNotApply(!notApply)}>
                                <RadioIcon
                                    icon={notApply ? faSquareCheck : faSquare}
                                    size='lg'
                                    color={"black"}
                                />
                                Doesn't apply
                            </ItemList>
                        </ListCheckbox>
                        <ListAreas>
                            {getTenMod().map((comm) => (
                                <Area
                                    isSelected={teamModalities.value?.includes(comm.id) || false}
                                    onClick={() => handleModalities(comm.id)}>
                                    {comm.name}
                                </Area>
                            ))}
                        </ListAreas>
                        <SeeAllModalities onClick={() => setShowModalModalities(true)}>See all modalities</SeeAllModalities>
                        <Label>Description<Imp>*</Imp></Label>
                        <WrapperEditor>
                            <TextEditor
                                value={teamDescription.value || ''}
                                setValue={handleOnBlurDescription}
                                charLimit={2500}
                                minHeight={120}
                                placeholder={'Please describe the internal team you are creating..'}
                                showError={teamDescription.showError}
                            />
                        </WrapperEditor>
                        <LabelIcon onClick={() => setShowModalGuide(true)}>
                            <IconAdd
                                icon={faUserPlus}
                                size='lg'
                                color={"black"}
                            />
                            Add guide<Imp>*</Imp>
                            {(teamAdvisors.showError) && (<ErrorField error={teamAdvisors.error} />)}
                        </LabelIcon>
                        <WrapperAdvisors>
                            <BlankTable
                                headers={['Guide Name', 'Email']}
                                items={(teamAdvisors.value || []).map((adv) => ({
                                    id: adv.email,
                                    labels: [`${adv.firstName} ${adv.lastName}`, adv.email]
                                }))}
                                maxWidth={'230px'}
                            />
                        </WrapperAdvisors>
                        <SeeAllModalities />
                        <LabelIcon onClick={() => setShowModalMembers(true)}>
                            <IconAdd
                                icon={faUserPlus}
                                size='lg'
                                color={"black"}
                            />
                            Invite members<Imp>*</Imp>
                            {(teamMembers.showError) && (<ErrorField error={teamMembers.error} />)}
                        </LabelIcon>
                        <WrapperAdvisors>
                            {checkMembers() && (
                                <WrapperMembers>
                                    <LabelInfo>{`Individual members invited: ${invitesIndividual}`}</LabelInfo>
                                    <LabelInfo>{`Members invited by roster: ${invitesRosters}`}</LabelInfo>
                                </WrapperMembers>
                            )}
                            {(clubCodes.length > 0) &&
                                <>
                                    <LabelInfoBold>Invitation Links</LabelInfoBold>
                                    <BlankTable
                                        headers={['Link', 'Invites', 'Expiration']}
                                        items={(clubCodes || []).map((adv) => ({
                                            id: adv.code,
                                            labels: [
                                                formatLink(adv.code),
                                                `${adv?.max_members || undefined}`,
                                                adv?.expirationTimestamp ? new Date(adv.expirationTimestamp).toLocaleDateString() : ''
                                            ]
                                        }))}
                                        maxWidth={'242px'}
                                        actions={['COPY']}
                                        actionsHandlers={{
                                            handleCopy: copyToClipboard,
                                        }}
                                    />
                                </>
                            }
                        </WrapperAdvisors>
                    </ContainerTabs>
                </Modal.Body>
                <Modal.Footer>
                    <WrapperButtons>
                        <ButtonCancel onClick={handleUnsaved}>
                            Cancel
                        </ButtonCancel>
                        <WrapperButton>
                            <ButtonSpinner
                                loading={loading}
                                handleClick={handleOnSaveTeam}
                                text={'Save'}
                                hasIcon={false}
                            />
                        </WrapperButton>
                    </WrapperButtons>
                </Modal.Footer>
            </Modal>
            <ModalModalities
                showModal={showModalModalities}
                onHide={onHideModalities}
                modalities={teamModalities.value || []}
                selectAll={selectAll}
                setSelectAll={setSelectAll} />
            <ModalGuide
                showModal={showModalGuide}
                onHide={onHideGuide}
                advisors={teamAdvisors.value || []} />
            <ModalMembers
                showModal={showModalMembers}
                onHide={onHideMembers}
                individuals={teamIndividuallyMembers.value || []}
                rosters={teamRosters.value || []}
                codes={clubCodes || []} />
            {/* <ModalUnsaved
                show={showUnsavedData}
                onHide={onHideUnsaved}
                content={"Do you want to save your changes?"}
            /> */}
        </>
    )
}

const WrapperMembers = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const WrapperEditor = styled.div`
    width: 100%;
    padding: 0px 25px;
`

const Labelinput = styled.div`
    font-family: 'Inter';
    font-size: 14px;
    text-align: left;
    padding: 15px 0;
    width: 100%;
    display: flex;
`

const LabelInfo = styled(Labelinput)`
    font-weight: 100 ;
    font-size: 16px;
    margin-left: 20px;
    padding: 0px;
    margin-top: 10px;
`;

const LabelInfoBold = styled(LabelInfo)`
    font-weight: 700 ;
`;

const WrapperAdvisors = styled.div`
    display: flex;
    width: 90%;
    margin-left: 23px;
    gap: 15px;
    flex-direction: column;
`;

const LabelName = styled.div`
    font-family: 'Inter';
    font-size: 14px;
    text-align: left;
    padding: 15px 0;
    width: 100%;
    display: flex;
    color: black;

    opacity: 0;
    position: absolute;
    transition: ease-in-out all 0.5s;
`

const Avatar = styled.img`
    width: 35px;
    height: 35px;
    min-width: 35px;
  min-height: 35px;
  max-width: 35px;
  max-height: 35px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: -10px;
    border: 1px solid white;
`;

const HoverAdvisor = styled.div`
    background-color: transparent;
    color: black;
    width: 0px;
    padding: 0px 15px;
    transition: ease-in-out all 0.5s;
    display: flex;

    :hover{
        width: 300px;
    }

    :hover ${LabelName}{
        opacity: 1;
        padding-left: 35px;
        padding-top: 12px;
    }
`;

const WrapperError = styled.div`
    padding: 10px;
`;

const WrapperButton = styled.div`
    width: 50%;
`;

const WrapperButtons = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: space-between;
`;

const LabelIcon = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    padding: 15px 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
`

const SeeAllModalities = styled.div`
    font-family: 'Inter-Bold';
    font-size: 14px;
    line-height: 17px;
    color: var(--absolute-zero);
    text-align: left;
    margin: 0 24px;
    border-bottom: 1px solid gray;
    padding-bottom: 13px;
    width: calc(100% - 48px);
    border-bottom: 1px solid gray;
    cursor: pointer;
`

interface PropsArea {
    isSelected: boolean;
}

const Area = styled.div`
    border: 1px solid darkgrey;
    width: fit-content;
    background-color: ${(props: PropsArea) => !props.isSelected ? "white" : "var(--lavender)"};
    padding: 7px 12px;
    border-radius: 5px;
    font-family: ${(props: PropsArea) => !props.isSelected ? "Inter-bold" : "Inter"};
    font-size: 14px;
    cursor: pointer;

    &:hover {
        border: 1px solid gray;
    }
`
const ListAreas = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 14px 24px;
`

const ListCheckbox = styled.div`
    display: flex;
    margin-left: 10px;
`

const Tooltip = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    width: 400px;
    background-color: var(--simple-blue-dark-2);
    padding: 8px 0;
    border-radius: 8px;
    color: white;
    margin-right: 12px;
    position: relative;

    &::before{
        content: "";
        width: 0;
        height: 0;
        border-left: 6px solid var(--simple-blue-dark-2);
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        position: absolute;
        right: -6px;
        top: 10px;
    }

`

const ButtonCancel = styled.div`
    width: 49%;
    text-align: center;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 1px solid var(--dark-blue);
    cursor: pointer;
`
const ButtonSave = styled.div`
    width: 49%;
    text-align: center;
    background-color: var(--dark-blue);
    color: white;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 0px;
`

const ContainerTabs = styled.div`
    overflow: auto;
    height: 75vh;
    padding-bottom: 250px;
`

const Search = styled.div`
`

const GroupList = styled.div`
`

const ItemList = styled.div`
    display: flex;
    justify-content: start;
    padding: 3px 0px 3px 0;
    align-items: center;
    font-family: 'Inter';
    font-size: 14px;
    margin-left: 15px;
`

const List = styled.div`
    padding: 15px;
`

const TitleId = styled.div`
    background-color: var(--simple-gray-2);
    width: 600px;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    font-family: 'Inter-Bold';
`

const Label = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    padding: 15px 24px;
    display: flex;
`

const Textarea = styled.div`
    border: 1px solid #747474;
    padding: 0 15px;
    border-radius: 8px;
    font-family: 'Inter';
    margin: 0 24px;
`

const Tabs = styled.div`
    display: flex;
    justify-content: space-evenly;
    border-radius: 8px;
    overflow: hidden;
    padding: 0 15px;
`

const Tab = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    background-color: var(--simple-gray-2);
    width: 33%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: 'Inter';
    cursor: pointer;

    &.tabActive{
        background-color: #E89F4D;
        color: white;
        font-family: 'Inter-Bold';
    }
`

const RadioIcon = styled(FontAwesomeIcon)`
    font-size: 22px;
    margin-right: 15px;
    color: black;
    cursor: pointer;
`;

const IconAdd = styled(FontAwesomeIcon)`
    font-size: 14px;
    margin-right: 15px;
    color: black;
`;

const SearchIcon = styled(FontAwesomeIcon)`
    font-size: 12px;
    margin-right: 20px;
    color: var(--black);
    margin-left: 20px;
`;

const CopyIcon = styled(SearchIcon)`
    font-size: 16px;
    cursor: pointer;
`

const InputIcon = styled.div`
    display: flex;
    justify-content: space-between;
    width: 567px;
    background-color: white;
    border-radius: 20px;
    align-items: center;
    border: 1px solid var(--simple-gray);
    height: 40px;
    margin: 15px; 
    
    & ${Input}{
        border: 0;
        height: 38px;
    } 
`

export const InputPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0;
    font-size: 15px;
    font-family: 'Inter';
    background-color: transparent;
`;

export const InputOnlyPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--black);
    padding-left: 20px;

    &::placeholder{
        color: var(--black);
        font-family: 'Inter-Bold'
        
    }
`;

const IconClickable = styled(FontAwesomeIcon)`
    cursor: pointer;
`;

export default ModalSetup