import Modal from "react-bootstrap/esm/Modal";
import { IconClicker } from "../../Home/HeaderHome/styles";
import { useContext, useEffect, useState } from "react";
import { ContextSuccess } from "../../../Context";
import { onHideType } from "../../BaseComponents/Modals/ModalUnsaved";
import ContributorCreate from "@vibe/sdk/dist/interfaces/Modalities/contributors/ContributorCreate";
import { mockContributor } from "./Contributors";
import Contributor from "@vibe/sdk/interfaces/Modalities/contributors/Contributor";
import IResponseError from "@vibe/sdk/dist/interfaces/IResponseError";
import { userInfo } from "os";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import styled from "styled-components";
import ButtonSpinner from "../../BaseComponents/ButtonSpinner";
import { Imp } from "../../signupsetuphealerind";
import Selector from "../../BaseComponents/Selector";
import ErrorField from "../../BaseComponents/ErrorField";
import { BaseFieldState, getFieldState } from "../../BaseComponents/utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { getRole } from "../../../hooks/Clubs/useClubsList";
import SelectItem from "../../../interfaces/SelectItem";
import useUsersSA from "../../../hooks/users/useUsersSA";
import { ClickableIcon } from "../SuperAdminComponents/basicForSACenter";
import useAllModalities from "../../../hooks/Superadmin/Modalities/useAllModalities";
import ContributorUpdate from "@vibe/sdk/dist/interfaces/Modalities/contributors/ContributorUpdate";
import Modality from "@vibe/sdk/interfaces/Modalities/modalities/Modality/Modality";

const ModalContributor = ({
    showModal,
    onHide,
    edit = false,
    contributor = { ...mockContributor },
    reload,
    modalitiesHealer,
}: {
    showModal: boolean
    onHide: () => void
    edit?: boolean
    contributor?: Contributor,
    reload: () => void,
    modalitiesHealer: Modality[]
}) => {
    const currentSuccess = useContext(ContextSuccess);

    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);

    const [loading, setLoading] = useState<boolean>(false);
    const [username, setUsername] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: '', value: '' }));
    const [modalities, setModalities] = useState<BaseFieldState<string[]>>(getFieldState([]));

    const [searchUser, setSearchUser] = useState<string>('');

    const { usersSelector } = useUsersSA({
        searchName: searchUser
    });

    const cleanFields = () => {
        setUsername(getFieldState({ label: '', value: '' }));
        setModalities(getFieldState([]));
        setLoading(false);
        setSearchUser('');
    }

    const loadData = () => {
        setUsername({
            ...username,
            value: {
                label: contributor.fullname || '',
                value: contributor.username
            }
        })

        setModalities({
            ...modalities,
            value: contributor.modalities.map(m => (m.id))
        })
    }

    const checkData = (): boolean => {
        let hasErrors = false;

        if (!username.value || !username.value?.value || username.value?.value?.length === 0) {
            hasErrors = true;
            setUsername({
                ...username,
                showError: true,
                error: 'Select a contributor is required'
            })
        }
        else {
            setUsername({
                ...username,
                showError: false,
                error: ''
            })
        }

        if (!modalities.value || modalities.value?.length === 0) {
            hasErrors = true;
            setModalities({
                ...modalities,
                showError: true,
                error: 'Select modalities is required'
            })
        }
        else {
            setModalities({
                ...modalities,
                showError: false,
                error: ''
            })
        }

        return hasErrors;
    }

    const handleCreate = () => {
        const toSend: ContributorCreate = {
            username: username?.value?.value || '',
            modalitiesIds: modalities?.value || [],
            createdBy: userInfo?.username || '',
        }

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.createContributor(toSend, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 201) {
                    reload();
                    cleanFields();
                    onHide();
                    currentSuccess?.setSuccessData(true, true, 'Contributor created successfully.');
                    currentSuccess?.setModalsOff();
                }
                else {
                    currentSuccess?.setSuccessData(false, true, (results.body as IResponseError).error || (results.body as IResponseError).message);
                }
            })
            .catch((error) => {
                console.log(error);
                currentSuccess?.setSuccessData(false, true, 'Error creating contributor', error);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleEdit = () => {
        const toSend: ContributorUpdate = {
            modalitiesIds: modalities?.value || [],
        }

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.updateContributor(contributor.username, toSend, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 201) {
                    reload();
                    cleanFields();
                    onHide();
                    currentSuccess?.setSuccessData(true, true, 'Contributor updated successfully.');
                    currentSuccess?.setModalsOff();
                }
                else {
                    currentSuccess?.setSuccessData(false, true, (results.body as IResponseError).error || (results.body as IResponseError).message);
                }
            })
            .catch((error) => {
                console.log(error);
                currentSuccess?.setSuccessData(false, true, 'Error updating contributor', error);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleOnSave = () => {
        if (checkData()) return;
        setLoading(true);

        if (edit && contributor.username) handleEdit();
        else handleCreate();
    }

    const onHideUnsaved = (type: onHideType) => {
        switch (type) {
            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                onHide();
                cleanFields();
                break;

            case 'save':
                handleOnSave();
                break;

            default:
                break;
        }
    }

    const handleUnsaved = () => {
        currentSuccess?.setModalWarning(
            'UNSAVED',
            (type) => onHideUnsaved(type),
            'Do you want to save your changes?'
        );
    }

    function handleCloseModalDelete(type: onHideType) {
        switch (type) {
          case 'discard':
            currentSuccess?.setModalsOff();
            break;
    
          case 'close':
            currentSuccess?.setModalsOff();
            break;
    
          case 'save':
            currentSuccess?.setLoadingModal(true);
            getDataSDK()
              .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.deleteContributor(contributor.username, dataSDK.token);
              })
              .then((results) => {
                if (results.statusCode === 204) {
                    onHide();
                    cleanFields();
                    currentSuccess?.setModalsOff();
                    currentSuccess?.setSuccessData(true, true, "Contributor unassigned");
                } else {
                  currentSuccess?.setSuccessData(false, true, "There was an error");
                }
              })
              .catch((error) => currentSuccess?.setSuccessData(false, true, "There was an error", error))
              .finally(() => {
                reload()
                currentSuccess?.setLoadingModal(false);
              })
            break;
    
          default:
            break;
        }
      };

    const handleUnassign = () => {
        currentSuccess?.setModalWarning(
          'DELETE',
          (type) => handleCloseModalDelete(type),
          'Do you want to unassign this healer as a contributor?',
          'Unassign'
        );
      }

    const handleModality = (id: string) => {
        if (modalities.value?.includes(id)) {
            setModalities({
                ...modalities,
                value: (modalities.value || []).filter(comm => comm !== id)
            })
            return;
        }

        setModalities({
            ...modalities,
            value: [...(modalities.value || []), id]
        })
    }

    useEffect(() => {
        loadData();
    }, [contributor, edit])

    return (
        <Modal className="modalContributor" show={showModal}>
            <Modal.Header>
                <Modal.Title>
                    {edit ? 'Update' : 'Create'} Contributor
                    <ButtonsUp>
                        {edit && <ButtonDelete onClick={handleUnassign}>Unassign Contributor</ButtonDelete>}
                        <IconClicker
                            onClick={handleUnsaved}
                            icon="times"
                            size='xl'
                            color={'black'}
                        />
                    </ButtonsUp>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {/* {edit ? (
                    <Row1>
                        <Label> Contributor<Imp>*</Imp></Label>
                        <DisabledInput>
                            {username.value?.label || ''}
                        </DisabledInput>
                    </Row1>
                ) : (
                    <Row1>
                        <Label> Contributor<Imp>*</Imp></Label>
                        <WrapperSelector>
                            <Selector
                                options={usersSelector}
                                selected={username}
                                setSelected={setUsername}
                                searchItem={searchUser}
                                setSearchItem={setSearchUser}
                                placeholder={'Select a contributor'}
                            />
                        </WrapperSelector>
                        {(username.showError) && (<ErrorField error={username.error} />)}
                    </Row1>
                )} */}

                <Row1>
                    <Label> Contributor<Imp>*</Imp></Label>
                    <DisabledInput>
                        {username.value?.label || ''}
                    </DisabledInput>
                </Row1>

                <Row1>
                    <Label>Modalities<Imp>*</Imp></Label>
                    {(modalities.showError) && (<ErrorField error={modalities.error} />)}
                    <ListAreas>
                        {modalitiesHealer.map((comm) => (
                            <Area
                                isSelected={modalities.value?.includes(comm.id) || false}
                                onClick={() => handleModality(comm.id)}>
                                {comm.name}
                            </Area>
                        ))}
                    </ListAreas>
                </Row1>
            </Modal.Body>

            <Modal.Footer>
                <ButtonCancel onClick={handleUnsaved}>Cancel</ButtonCancel>
                <WrapperButton>
                    <ButtonSpinner
                        loading={loading}
                        handleClick={handleOnSave}
                        text={'Save'}
                        hasIcon={false}
                    />
                </WrapperButton>
            </Modal.Footer>
        </Modal>
    )
};

export default ModalContributor;

const ButtonsUp = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: flex-start;
`;

const DisabledInput = styled.div`
    outline: none;
    width: 100%;
    height: 100%;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Inter';
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
    min-height: 40px;

    background-color: #f0efed;
    border: var(--simple-gray);
    color: black;
`;

const ListAreas = styled.div`
    width: 100%;
    margin: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
`;

interface PropsArea {
    isSelected: boolean;
}

const Area = styled.div`
    border: 1px solid darkgrey;
    width: fit-content;
    background-color: ${(props: PropsArea) => !props.isSelected ? "white" : "var(--lavender)"};
    padding: 7px 12px;
    border-radius: 5px;
    font-family: ${(props: PropsArea) => !props.isSelected ? "Inter-bold" : "Inter"};
    font-size: 14px;
    cursor: pointer;

    &:hover {
        border: 1px solid gray;
    }
`;


const AreaDeleteable = styled.div`
    border: 1px solid darkgrey;
    width: fit-content;
    background-color: ${(props: PropsArea) => !props.isSelected ? "white" : "var(--lavender)"};
    padding: 7px 12px;
    border-radius: 5px;
    font-family: ${(props: PropsArea) => !props.isSelected ? "Inter-bold" : "Inter"};
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;

    &:hover {
        border: 1px solid gray;
    }
`;

const ButtonCancel = styled.div`
    width: 49%;
    text-align: center;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 1px solid var(--dark-blue);
    cursor: pointer;
`;

const ButtonDelete = styled.div`
    width: 200px;
    text-align: center;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    background-color: var(--t-blue);
    color: white;
    cursor: pointer;
`;

const WrapperButton = styled.div`
    width: 50%;
`;

const Label = styled.div`    
    width: 100%;
    text-align: left;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`;

const Row1 = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: fit-content;
`;

const WrapperSelector = styled.div`
    height: 40px;
    min-height: 40px;
    width: 100%;
`;