import { useEffect, useState } from 'react'
import styled from "styled-components";
import { Imp } from "../signupsetuphealerind"
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPlus, faSearch } from '@fortawesome/pro-regular-svg-icons';
import ErrorField from '../BaseComponents/ErrorField';
import { UserInClub } from './ModalSetup';
import { sortAlphabeticallyOnProperty } from '../../containers/Signup/SignupHealerInd';
import BlankTable from '../BaseComponents/BlankTable';
import { BaseFieldState, getFieldState } from '../BaseComponents/utils';
import { SetupInvites } from '../../interfaces/store/inviteState';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import Selector from '../BaseComponents/Selector';
import SelectItem from '../../interfaces/SelectItem';
import SelectorEmails from '../BaseComponents/SelectorEmails';

export const mockSetupInvites: SetupInvites = {
    firstName: '',
    lastName: '',
    email: '',
}

const ModalGuide = ({
    showModal,
    onHide,
    advisors
}: {
    showModal: boolean,
    onHide: (value: UserInClub[]) => void,
    advisors: UserInClub[]
}) => {

    const setupUsers: SetupInvites[] = useSelector((state: RootState) => state.getInvites.getInvites_setup);

    const [userFirstName, setUserFirstName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userLastName, setUserLastName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userEmail, setUserEmail] = useState<BaseFieldState<SetupInvites>>(getFieldState({ ...mockSetupInvites}));
    const [searchItem, setSearchItem] = useState<string>('');

    const [advs, setAdvs] = useState<UserInClub[]>([]);
    const [filteredModality, setFilteredModality] = useState<string>('');

    const cleanFields = () => {
        setUserFirstName(getFieldState(''));
        setUserLastName(getFieldState(''));
        setUserEmail(getFieldState({ ...mockSetupInvites}));
        setSearchItem('');
    }

    const getFiltered = (listName: any[]) => {
        return listName.filter((item: any) => {
            if (!filteredModality) return true;

            if (item.email && (
                item.email.toUpperCase().includes(filteredModality) ||
                item.email.toLowerCase().includes(filteredModality) ||
                item.email.includes(filteredModality)
            )) {
                return true;
            }

            return false;
        })
    }

    const checkData = (): boolean => {
        let hasErrors = false;

        if (!userFirstName.value || userFirstName.value?.length === 0) {
            hasErrors = true;
            setUserFirstName({
                ...userFirstName,
                showError: true,
                error: 'First name is required'
            })
        } else {
            setUserFirstName({
                ...userFirstName,
                showError: false,
                error: ''
            })
        }

        if (!userLastName.value || userLastName.value?.length === 0) {
            hasErrors = true;
            setUserLastName({
                ...userLastName,
                showError: true,
                error: 'Last name is required'
            })
        } else {
            setUserLastName({
                ...userLastName,
                showError: false,
                error: ''
            })
        }

        if (!userEmail.value || userEmail.value.email.length === 0) {
            hasErrors = true;
            setUserEmail({
                ...userEmail,
                showError: true,
                error: 'Email is required'
            })
        } else {
            setUserEmail({
                ...userEmail,
                showError: false,
                error: ''
            })
        }

        return hasErrors;
    }

    const handleAddGuide = () => {
        if (checkData()) return;

        setAdvs([...advs, {
            firstName: userFirstName.value || '',
            lastName: userLastName.value || '',
            email: userEmail.value?.email || '',
        }])

        cleanFields();
    }

    const handleEraseGuide = (email: string) => {
        setAdvs(advs.filter(adv => adv.email !== email))
    }

    const handleUpdateGuide = (data: any) => { }

    const getGuides = () => {
        return (sortAlphabeticallyOnProperty(getFiltered(advs), 'email').map((comm: UserInClub) => ({
            id: comm.email,
            labels: [`${comm.firstName} ${comm.lastName}`, comm.email]
        })))
    }

    useEffect(() => {
        setAdvs(advisors)
    }, [advisors])

    useEffect(() => {
        if(userEmail.value?.firstName !== ''){
            setUserFirstName({ 
                ...userFirstName, 
                value: userEmail.value?.firstName || ''
            })
        }

        if(userEmail.value?.lastName !== ''){
            setUserLastName({ 
                ...userLastName, 
                value: userEmail.value?.lastName || ''
            })
        }
    }, [userEmail])

    return (
        <>
            <Modal className="modalSetup" show={showModal}>
                <Modal.Header>
                    <Modal.Title>
                        <Label>
                            <BackIcon
                                icon={faArrowLeft}
                                size='lg'
                                color={"black"}
                                onClick={() => onHide(advisors)}
                            />
                            Add Guide
                        </Label>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WrapperBody>

                        <Row1>
                            <Labelinput>Email<Imp>*</Imp></Labelinput>
                            <WrapperInput>
                                <SelectorEmails
                                    options={setupUsers}
                                    selected={userEmail}
                                    setSelected={setUserEmail}
                                    setSearchItem={setSearchItem}
                                    searchItem={searchItem}
                                    backgroundColor={'white'}
                                    border={'1px solid var(--simple-gray)'}
                                    placeholder={' '}
                                />
                            </WrapperInput>
                            {(userEmail.showError) && (<ErrorField error={userEmail.error} />)}
                        </Row1>
                        <Row1>
                            <Labelinput>First name<Imp>*</Imp></Labelinput>
                            <Input
                                value={userFirstName.value}
                                onChange={(e) => setUserFirstName({ ...userFirstName, value: e.currentTarget.value })}
                            />
                            {(userFirstName.showError) && (<ErrorField error={userFirstName.error} />)}
                        </Row1>
                        <Row1>
                            <Labelinput>Last name<Imp>*</Imp></Labelinput>
                            <Input
                                value={userLastName.value}
                                onChange={(e) => setUserLastName({ ...userLastName, value: e.currentTarget.value })}
                            />
                            {(userLastName.showError) && (<ErrorField error={userLastName.error} />)}
                        </Row1>
                        <Row1>
                            <ButtonAdd onClick={handleAddGuide}>
                                Add Guide
                            </ButtonAdd>
                        </Row1>

                        {(advs.length > 0) && (<>
                            <hr />
                            <Row1>
                                <InputIcon>
                                    <SearchIcon
                                        icon={faSearch}
                                        size='lg'
                                    />
                                    <Input
                                        placeholder="Search by Email Address"
                                        value={filteredModality}
                                        onChange={(e) => setFilteredModality(e.currentTarget.value)}
                                    />
                                </InputIcon>
                                <WrapperTable>
                                    <BlankTable
                                        headers={['Name', 'Email']}
                                        items={getGuides()}
                                        actions={['DELETE']}
                                        actionsHandlers={{
                                            handleDelete: handleEraseGuide,
                                            handleUpdate: handleUpdateGuide
                                        }}
                                        maxWidth={'200px'}
                                    />
                                </WrapperTable>
                            </Row1>
                        </>)}
                    </WrapperBody>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonCancel onClick={() => onHide(advisors)}>
                        Cancel
                    </ButtonCancel>
                    <ButtonSave onClick={() => onHide(advs)}>
                        Save Guides
                    </ButtonSave>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const WrapperInput = styled.div`
    height: 40px;
    min-height: 40px;
`;

const WrapperBody = styled.div`
    height: 75vh;
`;

const WrapperTable = styled.div`
    max-height: calc(100vh - 75vh);
    display: flex;
    overflow: scroll;

    :hover::-webkit-scrollbar{
        display: block;
    }

    ::-webkit-scrollbar {
        width: 5px;
        display: block !important;
    }


    ::-webkit-scrollbar-thumb {
        background: var(--t-blue); 
    }

    ::-webkit-scrollbar-thumb:hover {
        background: var(--t-blue); 
    }
`;

const SearchIcon = styled(FontAwesomeIcon)`
    font-size: 12px;
    margin-right: 20px;
    color: var(--black);
    margin-left: 20px;
    cursor: pointer;
`;

const IconPlus = styled(FontAwesomeIcon)`
    color: black;
    font-size: 14px;
    margin-left: 8px
`;

const Row1 = styled.div`
    width: 100%;
    padding: 0 24px;
`

const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
`

const InputIcon = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-radius: 8px;
    align-items: center;
    border: 1px solid var(--simple-gray);
    height: 40px; 
    margin-top: 10px;
    
    & ${Input}{
        border: 0;
        height: 38px;
        background-color: transparent;
        margin: 0 !important;
    } 

    & ${SearchIcon}{
        font-size: 20px;
    }
`

const ButtonCancel = styled.div`
    width: 49%;
    text-align: center;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 1px solid var(--dark-blue);
    cursor: pointer;
`
const ButtonSave = styled.div`
    width: 49%;
    text-align: center;
    background-color: var(--dark-blue);
    color: white;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 0px;
    cursor: pointer;
`

const ButtonAdd = styled.div`
    width: 30%;
    text-align: center;
    background-color: var(--electric-yellow-2);
    color: white;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 0px;
    cursor: pointer;

    margin-top: 20px;
`

const Label = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    padding: 15px;
    width: 100%;
    display: flex;
`

const Labelinput = styled.div`
    font-family: 'Inter';
    font-size: 14px;
    text-align: left;
    padding: 15px 0;
    width: 100%;
    display: flex;
`

const BackIcon = styled(FontAwesomeIcon)`
    font-size: 22px;
    margin-right: 15px;
    color: black;
    margin-left: -15px !important;
    cursor:pointer;
`;

const Area = styled.div`
    border: 1px solid darkgrey;
    width: fit-content;
    background-color: var(--lavender);
    padding: 7px 12px;
    border-radius: 5px;
    font-family: "Inter";
    font-size: 14px;
    cursor: pointer;

    &:hover {
        border: 1px solid gray;
    }
`
const ListAreas = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    overflow: scroll;
`

export default ModalGuide