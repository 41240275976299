import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemFilterHome, { ItemFilterHook, SelectedItemHome } from "../../../interfaces/HomeSearch/ItemFilterHome";
import { FilterItem, FiltersContainer } from "./styled";
import FilterTip from "./FilterTip";

const FilterTips = ({
    filters,
    filtersActive,
    setFiltersActive,
    setSelectedOption,
    selectedOption,
}: {
    filters: ItemFilterHook[]
    filtersActive: number[]
    setFiltersActive: (data: number[]) => void
    setSelectedOption: (data: SelectedItemHome) => void
    selectedOption: SelectedItemHome
}) => {
    return (
        <FiltersContainer>
            {filters.map(i => (
                <FilterTip
                    filter={i}
                    filtersActive={filtersActive}
                    setFiltersActive={setFiltersActive}
                    setSelectedOption={setSelectedOption}
                    selectedOption={selectedOption}
                />
            ))}
        </FiltersContainer>
    )
}

export default FilterTips;