import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getClubs, getMyClubs, getMyClubsExtended } from "../../store/actions/clubActions";
import { ClubDigestExtra } from "@vibe/sdk/dist/interfaces/Roster/getUserClubs";
import { ClubType } from "../../interfaces/club/ClubList";

interface useClubsProps {
  typeP?: ClubType;
}

const useClubs = ({ typeP }: useClubsProps) => {
  const [type, setType] = useState<ClubType>();
  const [searchTerm, setSearchTerm] = useState<string>();

  const dispatch = useDispatch();

  const username = useSelector((state: RootState) => state.getUser.userInfo?.username);
  const myClubs = useSelector((state: RootState) => state.getClub.getMyClubsExtended);
  const allClubs = useSelector((state: RootState) => state.getClub.getClubsOrg);
  const isSuper = useSelector((state: RootState) => state.getUser.userRoles.includes('SUPER'));

  const clubs = useMemo(() => (isSuper ? allClubs : myClubs).filter(({club_type: clubType, name}) => clubType === type && (!searchTerm || name.toLowerCase().includes(searchTerm))), [myClubs, type, searchTerm, isSuper, allClubs]);
  const all = useMemo(() => (isSuper ? allClubs : myClubs), [myClubs, isSuper, allClubs]);

  useEffect(() => {
    setType(typeP);
  }, [typeP]);

  useEffect(() => {
    reloadClubs();
  }, [type, isSuper]);

  const reloadClubs = () => {
    if (!username) return;
    if (isSuper) dispatch(getClubs({ club_type: type }));
    else {
      dispatch(getMyClubsExtended(username));
      dispatch(getMyClubs(username));
    }
  };

  const handleChangeType = (typeP: ClubType) => setType(typeP);

  const handleChangeSearchTerm = (search?: string) => setSearchTerm(search ? search.toLowerCase() : undefined);

  return {
    reloadClubs,
    handleChangeType,
    handleChangeSearchTerm,
    clubs,
    all,
  }
}

export default useClubs