import { faEllipsisVAlt } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ClubChatInfo } from "../../../../interfaces/chat/ClubChatInfo";
import { getClub } from "../../../../store/actions/clubActions";
import { LAVENDER, WHITE } from "../../../ClubIconsNavBar";
import { ClickableIcon } from "../../../SuperCenter/SuperAdminComponents/basicForSACenter";
import OutsideClickModalChatLeft from "../../ChatCenter/components/OutsideClickModalChatLeft";
import { ChatsSection, CreateGroup, RowFlex, TitleLeftPanel } from "../components";
import ClubChatListOption from "./ ClubChatListOption";
import { ChatServiceInterface } from "../../../../interfaces/chat/ChatServiceInterface";
import { RowFlexPlus } from "..";

const ClubListPanel = ({addGroup, ChatServiceInstance} : {addGroup: 'CLUB'|'INDIVIDUAL' | '', ChatServiceInstance: ChatServiceInterface}) => {
    const { id, idGroup } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const club = useSelector((state: any) => state.getClub.getClub);
    const [showModalClubs, setShowModalClubs] = useState<boolean>(false);

    useEffect(() => {
        if (id && idGroup) {
            dispatch<any>(getClub(id, false));
        }

        if(id){
            ChatServiceInstance.getClubChatList(id || '');
        }
    }, [id]);

    useEffect(() => {
        if(ChatServiceInstance.chatConnected){
            ChatServiceInstance.getClubChatList(id || '');
        }
    }, [])

    useEffect(() => {
        if(ChatServiceInstance.chatConnected){
            ChatServiceInstance.getClubChatList(id || '');
        }
    }, [ChatServiceInstance.chatConnected])

    return (
        <>
            <RowFlexPlus>
                <TextWrapper>
                    <TextTitleClub>{`${club?.name || 'Club'} (${ChatServiceInstance.clubChatsList.length})`}</TextTitleClub>
                </TextWrapper>
                <CreateGroup
                    active={addGroup === 'CLUB'}
                >
                    <ClickableIcon
                        icon="plus"
                        size='lg'
                        color={(addGroup === 'CLUB') ? WHITE : 'var(-t-blue)'}
                        onClick={() => navigate(`./group/create/${id}`)}
                    />
                </CreateGroup>
            </RowFlexPlus>
            <ClubListGroups>
                {ChatServiceInstance.clubChatsList.map((clubChat: ClubChatInfo) => (
                    <ClubChatListOption chat={clubChat} ChatServiceInstance={ChatServiceInstance}/>
                ))}
            </ClubListGroups>
        </>
    );
}

export default ClubListPanel;

const ClubListGroups = styled(ChatsSection)`
    height: 100%;
    min-height: 30px;
    max-height: 96px;
`;

export const TextWrapper = styled(RowFlex)`
    overflow: hidden;
    white-space: nowrap;
`;

const TextTitleClub = styled(TitleLeftPanel)`
    white-space: nowrap;
    overflow: hidden;
`;