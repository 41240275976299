import { useEffect, useMemo, useState } from "react";
import { Backend, MicroServices, getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import TherapeuticAreas from "@vibe/sdk/dist/interfaces/Modalities/modalities/TA/TA";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { getTherapeuticAreas } from "../../../store/actions/modalityActions";
import { sortAlphabeticallyOnProperty } from "../../../containers/Signup/SignupHealerInd";

const useTherapeuticAreasSA = ({
    pageSizeProp,
    searchName,
}: {
    pageSizeProp: number
    searchName: string
}) => {
    const dispatch = useDispatch();

    const [pageSize, setPageSize] = useState(pageSizeProp);
    const [filteredTA, setFilteredTA] = useState<TherapeuticAreas[]>([]);
    
    const TherapeuticAreas = useSelector((state: RootState) => state.getModality.getTA);

    const searchTerm = useMemo(() => searchName.toLowerCase(), [searchName]);

    const taPages:TherapeuticAreas[][] = useMemo(() => {
        const pages:TherapeuticAreas[][] = [];

        for (let i = 0; i < TherapeuticAreas.length; i+=pageSize) {
            pages.push(TherapeuticAreas.slice(i, i+pageSize));
        }

        if (pages.length === 0) {
            pages.push([]);
        }

        return pages;
    }, [pageSize, TherapeuticAreas])

    const reloadTherapeuticAreas = () => {
        dispatch(getTherapeuticAreas());
    }

    useEffect(() => {
        if (!searchTerm || searchTerm === '') {
            setFilteredTA(sortAlphabeticallyOnProperty(TherapeuticAreas, 'name'));
            return;
        }
        setFilteredTA(
            (sortAlphabeticallyOnProperty(TherapeuticAreas, 'name') as TherapeuticAreas[])
                .filter(i => i.name.toLowerCase().includes(searchTerm))
        );
    }, [searchTerm, TherapeuticAreas]);
    
    useEffect(() => reloadTherapeuticAreas(), []);

    return {
        taPages,
        reloadTherapeuticAreas,
        setPageSize,
        filteredTA
    }

}

export default useTherapeuticAreasSA;