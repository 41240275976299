import { IOrganizationResponse } from "@vibe/sdk/interfaces/Admin/Organization";

export const MockOrg: IOrganizationResponse  = {
    id: '',
    name: '',
    type: '',
    status: '',
    website: '',
    zipcode: '',
    city: '',
    country: '',
    location: '',
    createdAt: '',
    created_by: '',
    update_at: '',
    yearbook: '',
    newspaper: '',
    setupComplete: true,
    countryCode: '',
}