
import { AnyAction } from 'redux';
import ModalityStateInterface from '../../interfaces/store/ModalityState';
import { START_GET_HEALER_SERVICES, GET_HEALER_SERVICES_SUCCESS, GET_HEALER_SERVICES_ERROR, LOGOUT_MODALITY, START_GET_MODALITIES, GET_MODALITIES_SUCCESS, GET_MODALITIES_ERROR, START_GET_CERTIFICATIONS, GET_CERTIFICATIONS_SUCCESS, GET_CERTIFICATIONS_ERROR, START_GET_BE, GET_BE_SUCCESS, GET_BE_ERROR, START_GET_TA, GET_TA_SUCCESS, GET_TA_ERROR, START_GET_ACTIVE_MODALITIES, GET_ACTIVE_MODALITIES_SUCCESS, GET_ACTIVE_MODALITIES_ERROR, START_GET_ALL_MODALITIES, GET_ALL_MODALITIES_SUCCESS, GET_ALL_MODALITIES_ERROR } from '../constants/healerConstants';

const initialState: ModalityStateInterface = {
    loadingGetHealerServices: false,
    getHealerServices: [],
    getHealerServicesError: undefined,

    loadingGetModalities: false,
    getModalities: [],
    getModalitiesError: undefined,

    loadingGetBE: false,
    getBE: [],
    getBEError: undefined,

    loadingGetTA: false,
    getTA: [],
    getTAError: undefined,

    loadingGetCertifications: false,
    getCertifications: [],
    getCertificationsError: undefined,

    loadingGetActiveModalities: false,
    getActiveModalities: [],
    getActiveModalitiesError: undefined,

    loadingGetAllModalities: false,
    getAllModalities: [],
    getAllModalitiesError: undefined,
};

export const getModalityReducer = (state = initialState, action: AnyAction): ModalityStateInterface => {
    switch (action.type) {

        case START_GET_HEALER_SERVICES:
            return {
                ...state,
                loadingGetHealerServices: true,
            }
        case GET_HEALER_SERVICES_SUCCESS:
            return {
                ...state,
                loadingGetHealerServices: false,
                getHealerServices: action.payload,
            }

        case GET_HEALER_SERVICES_ERROR:
            return {
                ...state,
                loadingGetHealerServices: false,
                getHealerServicesError: action.payload,
            }

        case START_GET_MODALITIES:
            return {
                ...state,
                loadingGetModalities: true,
            }
        case GET_MODALITIES_SUCCESS:
            return {
                ...state,
                loadingGetModalities: false,
                getModalities: action.payload,
            }

        case GET_MODALITIES_ERROR:
            return {
                ...state,
                loadingGetModalities: false,
                getModalitiesError: action.payload,
            }

        case START_GET_BE:
            return {
                ...state,
                loadingGetBE: true,
            }
        case GET_BE_SUCCESS:
            return {
                ...state,
                loadingGetBE: false,
                getBE: action.payload,
            }

        case GET_BE_ERROR:
            return {
                ...state,
                loadingGetBE: false,
                getBEError: action.payload,
            }

        case START_GET_TA:
            return {
                ...state,
                loadingGetTA: true,
            }
        case GET_TA_SUCCESS:
            return {
                ...state,
                loadingGetTA: false,
                getTA: action.payload,
            }

        case GET_TA_ERROR:
            return {
                ...state,
                loadingGetTA: false,
                getTAError: action.payload,
            }

        case START_GET_CERTIFICATIONS:
            return {
                ...state,
                loadingGetCertifications: true,
            }
        case GET_CERTIFICATIONS_SUCCESS:
            return {
                ...state,
                loadingGetCertifications: false,
                getCertifications: action.payload,
            }

        case GET_CERTIFICATIONS_ERROR:
            return {
                ...state,
                loadingGetCertifications: false,
                getCertificationsError: action.payload,
            }
        case START_GET_ACTIVE_MODALITIES:
            return {
                ...state,
                loadingGetActiveModalities: true,
            }
        case GET_ACTIVE_MODALITIES_SUCCESS:
            return {
                ...state,
                loadingGetActiveModalities: false,
                getActiveModalities: action.payload,
            }

        case GET_ACTIVE_MODALITIES_ERROR:
            return {
                ...state,
                loadingGetActiveModalities: false,
                getActiveModalitiesError: action.payload,
            }
        case START_GET_ALL_MODALITIES:
            return {
                ...state,
                loadingGetAllModalities: true,
            }
        case GET_ALL_MODALITIES_SUCCESS:
            return {
                ...state,
                loadingGetAllModalities: false,
                getAllModalities: action.payload,
            }

        case GET_ALL_MODALITIES_ERROR:
            return {
                ...state,
                loadingGetAllModalities: false,
                getAllModalitiesError: action.payload,
            }

        case LOGOUT_MODALITY:
            return initialState;

        default:
            return state;
    }
};
