import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, reloadSession } from '../store/actions/userActions';
import { RootState } from '../store/store';
import { useTranslation } from "react-i18next";
import NotFound from '../containers/NotFound';
import Healers from '../components/SuperCenter/Healers/Healers';
import AdminLayout from '../containers/AdminLayout';
import Dashboard from '../components/Dashboard';
import MySchool from '../components/AdminCenter/MyOrganization/MyOrganization';
import MySchoolUpdate from '../components/AdminCenter/MyOrganization/UpdateMyOrganization';
import Club from '../containers/Club';
import Event from '../containers/Event';
import Bulletin from '../containers/Bulletin';
import AppLayout from '../containers/AppLayout';
import Profile from '../components/Profile';
import Loading from '../containers/Loading';
import { getMyEventsComplete } from '../store/actions/eventActions';
import EventAdmin from '../components/AdminCenter/MyEvents/EventAdmin';
import jwt_decode from "jwt-decode";
import { refreshTokens } from '../store/actions/tokenActions';
import { getEventTypes } from '../store/actions/ahaActions';
import Chat from '../containers/Chat/Chat';
import NoChatSelected from '../components/chat/ChatCenter/NoChatSelected';
import CreateChatGroup from '../components/chat/ChatCenter/GroupChat/CreateChatGroup';
import ChatUser from '../components/chat/ChatCenter/components/ChatUser';
import ChatService from '../hooks/Chat/ChatService';
import LangTable from '../components/SuperCenter/Languages/LangTable';

import Home from '../containers/Website/Home';
import ForgotPassword from '../containers/Website/ForgotPassword';
import RestorePassword from '../containers/Website/RestorePassword';

import ModalitiesView from '../components/SuperCenter/Modalities/ModalitiesView';
import CreateModality from '../components/SuperCenter/Modalities/Create';
import UpdateModality from '../components/SuperCenter/Modalities/Update';

import SetupSeeker from '../containers/SetupSeeker/SetupSeeker';
import SetupHealer from '../containers/SetupHealer/SetupHealer';
import PractitionersView from '../containers/SuperAdmin/Practitioners/PractitionersView';
import SetupHealerInd from '../containers/SetupHealerInd/SetupHealerInd';
import CreatePractitioner from '../components/SuperCenter/Practitioners/Create';
import UpdatePractitioner from '../components/SuperCenter/Practitioners/Update';
import WrapHealerInd from '../containers/Signup/WrapHealerInd';
import WrapCode from '../containers/Signup/WrapCode';
import ModalitiesServices from '../containers/ModalitiesServices/';
import Pricing from '../components/Home/Pricing';
import HealerProfile from '../containers/HealerProfile';
import WrapSeeker from '../containers/Signup/WrapSeeker';
import WrapBusiness from '../containers/Signup/WrapBusiness';
import Post from '../containers/Post';

import TherapeuticAreasView from '../components/SuperCenter/TherapeuticAreas/TherapeuticAreasView';
import AdminCenterClubs from '../components/AdminCenter/Clubs/AdminCenterClubs';
import AdminCenterMembers from '../components/AdminCenter/MyMembers/AdminCenterMembers';
import IPaymentInfo from '../interfaces/user/IPaymentINfo';
import AdminCenterMemberView from '../components/AdminCenter/MyMembers/AdminCenterMemberView';
import AdminCenterMemberUpdate from '../components/AdminCenter/MyMembers/AdminCenterMemberUpdate';
import CountriesTable from '../components/SuperCenter/Countries/CountriesTable';
import InterestsView from '../components/SuperCenter/Interests/InterestsView';
import BenefitsView from '../components/SuperCenter/Benefits/BenefitsView';
import SeeModality from '../components/SuperCenter/Modalities/SeeModality';
import TemplateTeamsView from '../components/SuperCenter/TemplateTeams/TemplateTeamsView';
import Seekers from '../components/SuperCenter/Seekers/Seekers';
import Referrals from '../components/SuperCenter/Referrals/Referrals';
import HealersView from '../components/SuperCenter/Healers/HealersView';
import SeekersView from '../components/SuperCenter/Seekers/SeekersView';
import HealersUpdate from '../components/SuperCenter/Healers/HealersUpdate';
import SeekersUpdate from '../components/SuperCenter/Seekers/SeekersUpdate';
import PurchaseGVComponent from '../components/PurchaseGV/purchaseGV.component'
import AdminCenterServices from '../components/AdminCenter/MyServices/AdminCenterServices';
import AdminCenterDashboard from '../components/AdminCenter/Dashboard/AdminCenterDashboard';
import HomeLayout from '../containers/Home';
import SearchHome from '../components/Home/SearchHome';
import AboutHome from '../components/Home/AboutHome';
import AdvisorsHome from '../components/Home/AdvisorsHome';
import FAQHome from '../components/Home/FAQHome';
import ContactHome from '../components/Home/ContactHome';
import ResultsHome from '../components/Home/ResultsHome';

import EditProfile from '../containers/EditProfile/EditProfile';

import Dashboards from '../components/Dashboards/DashboardsHeader';

import { ContextSuccess } from '../Context';
import ViewMoreModality from '../components/Home/ResultsHome/ViewMoreModality';
import DetailBalance from '../components/DetailBalance';
import { EventbriteAuth } from '../containers/EventbriteAuth/eventbrite-auth.component'
import Contributors from '../components/SuperCenter/Contributors/Contributors';
import Articles from '../components/SuperCenter/Articles/Articles';
import AddCard from '../components/AddCard'
import { acceptInvite } from '../store/actions/inviteActions'
import { toast } from 'react-toastify'
import EventTypesView from '../components/SuperCenter/EventTypes/EventTypesView';
import Terms from '../containers/Docs/terms';
import Privacy from '../containers/Docs/privacy';

function RootStackComponent() {

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  //Chat Hook
  const ChatServiceInstance = ChatService();
  const currentSuccess = useContext(ContextSuccess);
  const navigate = useNavigate();

  //States to control where to go when logging in
  const [showSetupSchool, setShowSetupSchool] = useState<boolean | undefined>(undefined);
  const [showSetupUser, setShowSetupUser] = useState<boolean | undefined>(undefined);
  const [goBulletin, setGoBulletin] = useState<boolean | undefined>(undefined);
  const [eventsOk, setEventsOk] = useState<boolean | undefined>(undefined);
  const [loadingMsj, setLoadingMsj] = useState<string>(t('text__loading'));
  const [searchPreviewHome, setSearchPreviewHome] = useState<string>('');
  const [searchHome, setSearchHome] = useState<string>('');

  //LOGIN_SUCCESS Selectors
  const isLoggedIn = useSelector((state: RootState) => state.getUser.isLoggedIn);
  const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
  const userRoles = useSelector((state: RootState) => state.getUser.userRoles);
  const organization = useSelector((state: RootState) => state.getOrgs.organizationInfo);
  const token = useSelector((state: RootState) => state.getToken.IDToken)

  useEffect(() => console.log('userRoles', userRoles), [userRoles])

  //Selectors from dispatchs run here!
  const user = useSelector((state: RootState) => state.getUser.user);
  const events = useSelector((state: RootState) => state.getEvent.getCompleteEvents)
  const userPayment: IPaymentInfo = useSelector((state: RootState) => state.getUser.paymentInfo);
  
  const everythingIsLoaded = (): boolean => (
    showSetupSchool !== undefined &&
    showSetupUser !== undefined &&
    goBulletin !== undefined &&
    eventsOk !== undefined &&
    isLoggedIn !== undefined &&
    userInfo !== undefined &&
    userRoles !== undefined &&
    organization !== undefined &&
    user !== undefined &&
    events !== undefined
  )

  const setupInfoLoaded = (): boolean => (
    showSetupSchool !== undefined &&
    showSetupUser !== undefined &&
    userRoles !== undefined
  )

  const onKeyDownSearchHome = (e: any) => {
    if (e.keyCode === 13) {
      handleGoResultsHome();
    }
  }

  const handleGoResultsHome = () => {
    // if (searchPreviewHome) {
      setSearchHome(searchPreviewHome ?? '');
      navigate('../../../../../modalities-services');
      return;
    // }
    // currentSuccess?.setSuccessData(false, true, 'Type Something!', 'Give us more info of what you need to raise your vibration.')
  }

  useEffect(() => {
    //Execute LOGIN_SUCCESS related dispatch
    dispatch<any>(reloadSession());
  }, []);

  useEffect(() => {
    if (user && user.username !== '' && !ChatServiceInstance.chatConnected) ChatServiceInstance.connect({
      username: user?.username || '',
      fullName: `${user?.firstname} ${user?.lastname}`,
      avatar: user?.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png',
      status: 'available'
    })
  }, [JSON.stringify(user)])

  useEffect(() => {
    if (ChatServiceInstance.chatConnected) ChatServiceInstance.getChatList()
  }, [ChatServiceInstance.chatConnected])

  useEffect(() => {
    if (token) {
      //dispatch<any>(getEventTypes());
      const decodedToken = jwt_decode(token) as any
      if (`${decodedToken.exp}000` < new Date().valueOf().toString()) {
        //Token expired
        dispatch<any>(refreshTokens())
      }
    }
  }, [token])

  useEffect(() => {
    console.log('org', organization && 'setupComplete' in organization)
    if (organization && 'setupComplete' in organization) {
      setShowSetupSchool(!organization.setupComplete);
    } else {
      setShowSetupSchool(undefined)
    }
  }, [organization]);

  useEffect(() => {
    if (userInfo) {
      dispatch(getUser(userInfo.username, 'OA'));
      dispatch<any>(getMyEventsComplete(userInfo.username, true, undefined, events || []))
        .then((envt: any) => {
          setEventsOk(true)
        })
        .catch((error: any) => {
          setEventsOk(false)
          setLoadingMsj("Couldn't get your events. Please, reload the page!")
          console.error(error, 'Events error')
        })
    } else if (userInfo === undefined) {
      setLoadingMsj(t('text__loading'))
      setShowSetupSchool(undefined)
      setShowSetupUser(undefined)
      setGoBulletin(undefined)
      setEventsOk(undefined)
    }
  }, [userInfo]);

  useEffect(() => {
    if (eventsOk) {
      setGoBulletin(true);
    } else if (eventsOk !== undefined) {
      setGoBulletin(false);
    }
  }, [eventsOk])

  useEffect(() => {
    if (user && typeof user.isSetup === 'boolean') {
      setShowSetupUser(!user.isSetup);
    } else {
      setShowSetupUser(undefined)
    }
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const isAdmin = () => {
    return (userRoles.includes('SUPER')) ?
      'SUPER' :
      (userRoles.includes('ORGADMIN')) ?
        'ORGADMIN' : 'ELSE';
  }

   //ACCEPT INVITE
   const queryParams = new URLSearchParams(location.search)
   const action = queryParams.get('action')
   const usernameFromQuery = queryParams.get('username')
   const tokenFromQuery = queryParams.get('token')
   const isAcceptInvite = !!tokenFromQuery && !!usernameFromQuery && action === 'accept-invite'
   const isLoggedInLocalStorage = !!localStorage.getItem('token')
   useEffect(() => {
       if (isAcceptInvite) {
         if (isLoggedInLocalStorage) dispatch(acceptInvite({token: tokenFromQuery as string, username: usernameFromQuery as string}))
         else currentSuccess?.setSuccessData(false, true, "Ops!", "Please login to accept invite");
         } 
   }, [])

  if (!isLoggedIn) {
    //Login Routes
    return (
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route index element={
            <SearchHome
              searchPreview={searchPreviewHome}
              search={searchHome}
              setSearch={setSearchPreviewHome}
              onKeyDown={onKeyDownSearchHome}
              goResults={handleGoResultsHome} />
          } />
          <Route path="add-card" element={<AddCard />} />
          <Route path="about" element={<AboutHome />} />
          <Route path="advisors" element={<AdvisorsHome />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="faq" element={<FAQHome />} />
          <Route path="contact" element={<ContactHome />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />

          <Route path="modalities-services" element={
            <ResultsHome
              searchPreview={searchPreviewHome}
              search={searchHome}
              setSearch={setSearchPreviewHome}
              onKeyDown={onKeyDownSearchHome}
              goResults={handleGoResultsHome} />
          } />
          <Route path="modality/:id" element={<ViewMoreModality />} />

          <Route path="signup-healer" element={<WrapBusiness />} />
          <Route path="signup-seeker/:token" element={<WrapSeeker />} />
          <Route path="signup/:token" element={<WrapHealerInd />} />
          <Route path="s/:code" element={<WrapCode />} />
          <Route path="post/:id" element={<Post />} />
        </Route>

        <Route path="h/:username" element={<HealerProfile />} />
        

        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="restore-password" element={<RestorePassword />} />

        <Route path="purchase-gv" element={<PurchaseGVComponent />} />

        <Route path="*" element={<Navigate to="/" />} />

      </Routes>
    );
  }

  if (setupInfoLoaded()) {

    //Setup Healer Business and Professional
    if (showSetupSchool &&
      userRoles.includes("ORGADMIN") &&
      !userRoles.includes("INDIVIDUAL") &&
      userRoles.includes("HEALER")) {
      return (
        <Routes>
          <Route path="setup-healer" element={
            <SetupHealer
              setShowSetupOrg={setShowSetupSchool}
              setShowSetupUser={setShowSetupUser}
            />}
          />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="*" element={<Navigate to="/setup-healer" />} />
        </Routes>
      );
    }

    //Setup Healer Individual
    if (showSetupUser && userRoles.includes("INDIVIDUAL") && userRoles.includes("HEALER")) {
      return (
        <Routes>
          <Route path="setup-healer-ind" element={
            <SetupHealerInd
              setShowSetupOrg={setShowSetupSchool}
              setShowSetupUser={setShowSetupUser}
            />}
          />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="*" element={<Navigate to="/setup-healer-ind" />} />
        </Routes>
      );
    }

    //Setup Seeker
    if (showSetupUser && userRoles.includes("SEEKER")) {
      return (
        <Routes>
          <Route path="setup-seeker" element={
            <SetupSeeker setShowSetup={setShowSetupUser} />
          } />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="*" element={<Navigate to="/setup-seeker" />} />
        </Routes>
      );
    }
  }

  //Go to bulletin if ready to go or if SA
  if ((everythingIsLoaded() && goBulletin) || (userRoles && userRoles.includes('SUPER'))) {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/app" />} />
        <Route path="/" element={<HomeLayout />}>
          <Route path="post/:id" element={<Post />} />
        </Route>
        <Route path={"/app"}
          element={<AppLayout
            isAdmin={isAdmin()}
            existEvents={events && events.length > 0}
            ChatServiceInstance={ChatServiceInstance}
          />}>
          <Route index element={<Bulletin ChatServiceInstance={ChatServiceInstance} />} />
          <Route path="clubs/:id" element={<Club ChatServiceInstance={ChatServiceInstance} />} />
          <Route path="connections/:username" element={<Profile ChatServiceInstance={ChatServiceInstance} />} />
          <Route path="userProfile/:username" element={<Profile ChatServiceInstance={ChatServiceInstance} />} />
          <Route path="event/:id" element={<Event ChatServiceInstance={ChatServiceInstance} />} />
        </Route>

        <Route path="/" element={<HomeLayout ChatServiceInstance={ChatServiceInstance} />}>
          <Route path="modalities-services" element={
            <ResultsHome
              searchPreview={searchPreviewHome}
              search={searchHome}
              setSearch={setSearchPreviewHome}
              onKeyDown={onKeyDownSearchHome}
              goResults={handleGoResultsHome} />
          } />
          <Route path="modality/:id" element={<ViewMoreModality />} />
          <Route path="pricing" element={<Pricing />} />

          <Route path="signup-seeker/:token" element={<Navigate to="/app?signupLogged=true" />} />
          <Route path="signup/:token" element={<Navigate to="/app?signupLogged=true" />} />
          <Route path="s/:code" element={<Navigate to="/app?signupLogged=true" />} />
        </Route>

        <Route path="h/:username" element={<HealerProfile ChatServiceInstance={ChatServiceInstance} />} />
        <Route path="edit-healer-ind-profile/:username" element={<EditProfile />} />
        <Route path="edit-healer-profile/:username" element={<EditProfile />} />
        <Route path="edit-userProfile/:username" element={<EditProfile />} />
        <Route path="restore-password" element={<RestorePassword />} />

        <Route path="good-vibes" element={<DetailBalance ChatServiceInstance={ChatServiceInstance} />} />

        <Route path="update-healer/:username" element={
          <SetupHealer
            setShowSetupOrg={setShowSetupSchool}
            setShowSetupUser={setShowSetupUser}
            isUpdate={true}
          />} />
        <Route path="update-healer-ind/:username" element={
          <SetupHealerInd
            setShowSetupOrg={setShowSetupSchool}
            setShowSetupUser={setShowSetupUser}
            isUpdate={true}
          />} />

        <Route path="*" element={<NotFound isLoggedIn={isLoggedIn} />} />

        <Route path="/chat" element={<Chat ChatServiceInstance={ChatServiceInstance} />}>
          <Route index element={<NoChatSelected />} />
          <Route path="conversation/:id" element={<ChatUser ChatServiceInstance={ChatServiceInstance} />} />
          <Route path="conversation/:id/:idGroup" element={<ChatUser ChatServiceInstance={ChatServiceInstance} />} />
          <Route path="group/create/:clubId" element={<CreateChatGroup ChatServiceInstance={ChatServiceInstance} />} />
          <Route path="group/create" element={<CreateChatGroup ChatServiceInstance={ChatServiceInstance} />} />
          <Route path="*" element={<Navigate to="/chat" />} />
        </Route>
       

        {userRoles.includes('SUPER') && (
          <>
            <Route path="/superadmin" element={<AdminLayout />}>

              <Route index element={<Dashboard isSuper={true} />} />

              <Route path="referrals" element={<Referrals />} />
              {/* <Route path="contributors" element={<Contributors />} /> */}
              {/* <Route path="articles" element={<Articles />} /> */}

              <Route path="seekers" element={<Seekers />} />
              <Route path="seekers/:id/view" element={<SeekersView />} />
              <Route path="seekers/:id/update" element={<SeekersUpdate />} />

              <Route path="healers" element={<Healers />} />
              <Route path="healers/:id/view" element={<HealersView />} />
              <Route path="healers/:id/update" element={<HealersUpdate />} />

              <Route path="internal-teams" element={<AdminCenterClubs clubType="COMM" />} />
              <Route path="communities" element={<AdminCenterClubs clubType="CLUB" />} />

              <Route path="template-teams" element={<TemplateTeamsView />} />

              <Route path="modalities" element={<ModalitiesView />} />
              <Route path="event-types" element={<EventTypesView />} />
              <Route path="modalities/:id/view" element={<SeeModality />} />
              <Route path="modalities/create" element={<CreateModality />} />
              <Route path="modalities/:id/update" element={<UpdateModality />} />

              <Route path="benefits" element={<BenefitsView />} />
              <Route path="therapeutic-areas" element={<TherapeuticAreasView />} />
              <Route path="interests" element={<InterestsView />} />
              <Route path="countries" element={<CountriesTable />} />
              <Route path="lang" element={<LangTable />} />

              {/* 
              Not refactored yet.
              <Route path="professional-orgs" element={<YOTable />} />
              <Route path="professional-orgs/create" element={<CreateYO />} />
              <Route path="practitioners" element={<PractitionersView />} />
              <Route path="practitioners/create" element={<CreatePractitioner />} />
              <Route path="practitioners/:id/update" element={<UpdatePractitioner />} /> 
              */}
            </Route>
          </>
        )}

        {(userRoles.includes('ORGADMIN')) && (
          <>
            <Route path="/admin" element={<AdminLayout />}>
              <Route index element={<MySchool />} />
              <Route path="dashboard" element={<AdminCenterDashboard />} />
              <Route path="services" element={<AdminCenterServices />} />
              <Route path="teams" element={<AdminCenterClubs clubType="COMM" />} />
              <Route path="communities" element={<AdminCenterClubs clubType="CLUB" />} />
              {/* <Route path="articles" element={<Articles />} /> */}

              {userPayment.hasActiveSubscription && (
                <>
                  <Route path="members" element={<AdminCenterMembers />} />
                  <Route path="members/:id/view" element={<AdminCenterMemberView />} />
                  <Route path="members/:id/update" element={<AdminCenterMemberUpdate />} />
                </>
              )}

              <Route path="events" element={<EventAdmin />} />
            </Route>
            <Route path="setup-healer" element={<Navigate to="/app" />} />
          </>
        )}

        {userRoles.includes("SEEKER") && (
          <Route path="setup-seeker" element={<Navigate to="/app" />} />
        )}
        <Route path="terms" element={<Terms />} />
        <Route path="privacy" element={<Privacy />} />
      </Routes>
    );
  }

  //Default view after logging in is a message (Loading...)
  return (
    <Routes>
      <Route path="*" element={<Loading message={loadingMsj} />} />
    </Routes>
  );
}

export default RootStackComponent;

