import React, { useContext, useEffect, useState, useRef } from 'react'
import styled, { keyframes } from "styled-components";
import OutsideClickHandler from "react-outside-click-handler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronDown, faCalendar, faEye, faEyeSlash, faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row1, Row2, Imp } from "../Website/Form"
import { BaseFieldState, getFieldState } from '../../components/BaseComponents/utils';
import useActiveCountry, { CountryOption } from '../../hooks/Locations/useActiveCountry';
import { ISignUpOrganizationBody, SignUpOrg } from '@vibe/sdk/dist/interfaces/AccessManager/signUpOrg';
import { orgSignup } from '../../store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { orgType } from '@vibe/sdk/dist/interfaces/Admin/getOrganizations';
import { ISignUpUserBody, SignUpUser, genderType, rolesType } from '@vibe/sdk/dist/interfaces/AccessManager/signUpUser';
import useActiveState, { StateOption } from '../../hooks/Locations/useActiveState';
import useGenders, { GenderOption } from '../../hooks/users/useGenders';
import ErrorField from '../../components/BaseComponents/ErrorField';
import { formatGender, isValidBirthdate, isValidUsername, validatePassword, isValidZipCode } from '../../helpers/userHelper';
import PasswordToolTip from '../../components/Website/PasswordToolTip';
import EmailSent from '../../components/Website/EmailSent';
import SelectItem from '../../interfaces/SelectItem';
import ButtonSpinner from '../../components/BaseComponents/ButtonSpinner';
import DatePicker from "react-datepicker";
import { createUserByInvite, getInvitesByToken } from '../../store/actions/inviteActions';
import { ContextSuccess } from '../../Context';
import { CodeInfo, useClubCode } from '../SignUpCode/hooks';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { DisabledInput } from './SignupSeeker';
import SelectorCountry from '../../components/BaseComponents/Location/SelectorCountry';
import { Form_signup } from './SignupHealer';
import { PostalCodeClean } from '@vibe/sdk/dist/interfaces/Roster/postalCodes';

export const sortAlphabeticallyOnProperty = (list: any[], property: string, ordSelected: 'DESC' | 'ASC' = 'ASC') => {
    if (ordSelected === 'DESC') {
        return list.sort((a, b) => a[property] < b[property] ? 1 : a[property] > b[property] ? -1 : 0)
    }
    return list.sort((a, b) => a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0)
}

export const getFiltered = (listName: SelectItem[], itemSearch: string) => {
    return listName.filter((item: SelectItem) => {
        if (!itemSearch) return true;

        if (item.label && (
            item.label.toUpperCase().includes(itemSearch) ||
            item.label.toLowerCase().includes(itemSearch) ||
            item.label.includes(itemSearch)
        )) {
            return true;
        }

        return false;
    })
}

const SignupHealerInd = ({
    codeInfo,
    emailSent,
    setEmailSent,
    isIndividual = false,
}: {
    codeInfo?: CodeInfo,
    emailSent: boolean,
    setEmailSent: (data: boolean) => void
    isIndividual?: boolean
}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { token, code } = useParams();
    const { activeCountriesReactSelect } = useActiveCountry();
    const { GendersReactSelect } = useGenders();
    const datepickerRef = useRef<any>(null);

    const currentSuccess = useContext(ContextSuccess);

    const invite = useSelector((state: any) => state.getInvites.inviteByToken);

    const [showOptions, setShowOptions] = useState(false)
    const [showOptionsCountry, setShowOptionsCountry] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);
    const [loadingSignup, setLoadingSignup] = useState(false);

    const [searchCountry, setSearchCountry] = useState('');

    const [userCountry, setUserCountry] = useState<BaseFieldState<CountryOption>>(getFieldState({ label: '', value: '' }));
    const [userZip, setUserZip] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userFirstName, setUserFirstName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userLastName, setUserLastName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userEmail, setUserEmail] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userBirthday, setUserBirthday] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userGender, setUserGender] = useState<BaseFieldState<GenderOption>>(getFieldState({ label: 'Select a gender', value: 'P' }));
    const [username, setUsername] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userPassword, setUserPassword] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userToken, setUserToken] = useState<string>('');
    const [parentOrgId, setParentOrgId] = useState<string>('');
    const [businessName, setBusinessName] = useState<BaseFieldState<string>>(getFieldState(''));

    const [zipcodeLengths, setZipcodeLengths] = useState<PostalCodeClean>({ maxLength: 5, minLength: 5 })

    const cleanFields = () => {
        setUserCountry(getFieldState({ label: '', value: '' }));
        setUserFirstName(getFieldState(''));
        setUserLastName(getFieldState(''));
        setUserEmail(getFieldState(''));
        setUserBirthday(getFieldState(''));
        setUserGender(getFieldState({ label: 'Select a gender', value: 'P' }));
        setUsername(getFieldState(''));
        setUserPassword(getFieldState(''));
        setUserToken('');
        setParentOrgId('');
        setBusinessName(getFieldState(''));
    }

    const updateInvite = () => {
        setUserFirstName({
            ...userFirstName,
            value: invite.firstName || ''
        });
        setUserLastName({
            ...userLastName,
            value: invite.lastName || ''
        });
        setUserEmail({
            ...userEmail,
            value: invite.email || ''
        });
        setUsername({
            ...username,
            value: invite.username || ''
        });

        if (invite.gender) {
            setUserGender({
                ...userGender,
                value: {
                    label: formatGender(invite.gender || ''),
                    value: invite.gender || ''
                }
            });
        }

        setParentOrgId(invite.org_id || '');
    }

    const checkDataSecond = (): boolean => {
        let hasErrors = false;

        if (!userFirstName.value || userFirstName.value?.length === 0) {
            hasErrors = true;
            setUserFirstName({
                ...userFirstName,
                showError: true,
                error: 'First name is required'
            })
        } else {
            setUserFirstName({
                ...userFirstName,
                showError: false,
                error: ''
            })
        }

        if (!userLastName.value || userLastName.value?.length === 0) {
            hasErrors = true;
            setUserLastName({
                ...userLastName,
                showError: true,
                error: 'Last name is required'
            })
        } else {
            setUserLastName({
                ...userLastName,
                showError: false,
                error: ''
            })
        }

        if (!userEmail.value || userEmail.value?.length === 0) {
            hasErrors = true;
            setUserEmail({
                ...userEmail,
                showError: true,
                error: 'Email is required'
            })
        } else {
            setUserEmail({
                ...userEmail,
                showError: false,
                error: ''
            })
        }

        if (!userBirthday.value || userBirthday.value?.length === 0) {
            hasErrors = true;
            setUserBirthday({
                ...userBirthday,
                showError: true,
                error: 'Date of Birth is required'
            })
        } else {
            setUserBirthday({
                ...userBirthday,
                showError: false,
                error: ''
            })
        }

        if (!isValidBirthdate(userBirthday.value)) {
            hasErrors = true;
            setUserBirthday({
                ...userBirthday,
                showError: true,
                error: 'You must be at least 18 years old'
            })
        } else {
            setUserBirthday({
                ...userBirthday,
                showError: false,
                error: ''
            })
        }

        if (
            !userGender.value?.label || userGender.value?.label.length === 0 || userGender.value?.label === 'Select a gender' ||
            !userGender.value?.value || userGender.value?.value.length === 0
        ) {
            hasErrors = true;
            setUserGender({
                ...userGender,
                showError: true,
                error: 'Gender is required'
            })
        } else {
            setUserGender({
                ...userGender,
                showError: false,
                error: ''
            })
        }

        const errorUsername = isValidUsername(username.value || '');
        if (errorUsername !== '') {
            hasErrors = true;
            setUsername({
                ...username,
                showError: true,
                error: errorUsername
            })
        } else {
            setUsername({
                ...username,
                showError: false,
                error: ''
            })
        }

        const errorPassword = validatePassword(userPassword.value || '');
        if (errorPassword.lastError !== '') {
            hasErrors = true;
            setUserPassword({
                ...userPassword,
                showError: true,
                error: errorPassword.lastError === 'Password is required' ?
                    errorPassword.lastError : `Password needs ${errorPassword.lastError.toLowerCase()}`
            })
        } else {
            setUserPassword({
                ...userPassword,
                showError: false,
                error: ''
            })
        }

        console.log('userCountry.value', userCountry.value, !userCountry.value?.value || userCountry.value?.value.length === 0 ||
            !userCountry.value?.label || userCountry.value?.label.length === 0)

        if (
            !userCountry.value?.value || userCountry.value?.value.length === 0 ||
            !userCountry.value?.label || userCountry.value?.label.length === 0
        ) {
            hasErrors = true;
            setUserCountry({
                ...userCountry,
                showError: true,
                error: 'Country is required'
            })
        } else {
            setUserCountry({
                ...userCountry,
                showError: false,
                error: ''
            })
        }

        const errorZipCode = isValidZipCode(userZip.value || '', zipcodeLengths, userCountry.value?.label);
        if (errorZipCode !== '') {
            hasErrors = true;
            setUserZip({
                ...userZip,
                showError: true,
                error: errorZipCode
            })
        } else {
            setUserZip({
                ...userZip,
                showError: false,
                error: ''
            })
        }

        return hasErrors;
    }

    const getOrgName = (): string => {
        return businessName.value?.trim().length ? businessName.value.trim() : (userFirstName.value && userLastName.value) ? `${userFirstName.value} ${userLastName.value}` : ''
    }

    const signupInd_NoInvite = (toSend: ISignUpOrganizationBody) => {
        dispatch<any>(orgSignup(toSend, true))
            .then((results: string) => {
                if (!results.includes('error')) {
                    cleanFields();
                    setEmailSent(true);
                }
                else {
                    currentSuccess?.setSuccessData(false, true, results.split('/')[0], results.split('/')[1])
                }
            })
            .catch((error: any) => {
                currentSuccess?.setSuccessData(false, true, "Error creating user and org.", error);
                console.log(error)
            })
            .finally(() => setLoadingSignup(false));
    }

    const signupInd_Invite = (toSend: ISignUpOrganizationBody) => {
        dispatch<any>(createUserByInvite(userToken, { user: toSend.user } as ISignUpUserBody))
            .then((results: boolean) => {
                if (results) {
                    return dispatch(orgSignup({
                        organization: toSend.organization,
                        user: { userName: username.value || '' }
                    }));
                }
                else {
                    currentSuccess?.setSuccessData(false, true, "Error creating user by invite!", " Try again.");
                    return Promise.resolve(false);
                }
            })
            .then((results: string) => {
                if (!results.includes('error')) {
                    cleanFields();
                    setEmailSent(true);
                }
                else {
                    currentSuccess?.setSuccessData(false, true, results.split('/')[0], results.split('/')[1])
                }
            })
            .catch((error: any) => {
                currentSuccess?.setSuccessData(false, true, "Error creating user.", error);
                console.log(error)
            })
            .finally(() => setLoadingSignup(false));
    }

    const signup_Code = (toSend: ISignUpOrganizationBody) => {
        if (!codeInfo) return;
        const toSendUser = toSend.user as SignUpUser
        toSendUser.roles = [[codeInfo.clubInfo?.role_assigned as rolesType || 'SEEKER']];
        toSendUser.organizations = [codeInfo.clubInfo?.org_id || ''];
        toSendUser.organizationsNames = [];

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.AccessManager.signUpUserCode(
                    { user: toSend.user } as ISignUpUserBody,
                    { code: code },
                    dataSDK.token);
            })
            .then((response) => {
                if (response.statusCode === 201) {
                    if (codeInfo.clubInfo?.role_assigned === 'HEALER') {
                        return dispatch<any>(orgSignup({
                            organization: toSend.organization,
                            user: { userName: username.value || '' }
                        }));
                    }
                    else {
                        return Promise.resolve(true);
                    }
                }
                else {
                    currentSuccess?.setSuccessData(false, true, "Error creating user by code.");
                    return Promise.resolve(false);
                }
            })
            .then((results: string) => {
                if (!results.includes('error')) {
                    cleanFields();
                    setEmailSent(true);
                }
                else {
                    currentSuccess?.setSuccessData(false, true, results.split('/')[0], results.split('/')[1])
                }
            })
            .catch((error: any) => {
                currentSuccess?.setSuccessData(false, true, "Error creating user.", error);
                console.log(error)
            })
            .finally(() => setLoadingSignup(false))
    }

    const handleSignup = () => {
        setLoadingSignup(true);

        if (checkDataSecond()) {
            setLoadingSignup(false);
            return;
        }

        const toSend: ISignUpOrganizationBody = {
            organization: {
                organizationName: getOrgName(),
                organizationType: 'INDEPENDENT',
                zipcode: userZip.value || '',
                countryCode: userCountry.value?.value || '',
                country: userCountry.value?.label || '',
            },
            user: {
                email: userEmail.value || '',
                givenName: userFirstName.value || '',
                familyName: userLastName.value || '',
                birthdate: userBirthday.value || '',
                gender: userGender.value?.value as genderType || 'P',
                userName: username.value || '',
                password: userPassword.value || '',
                zipcode: userZip.value || '',
                countryCode: userCountry.value?.value || '',
                organizations: [parentOrgId || ''],
                organizationsNames: [getOrgName()],
                roles: [['HEALER']],
                preferredLanguage: 'english',
                clubs: [],
                clubsAdvisor: [],
                clubsFollower: [],
                phoneNumber: 30000000000,
            },
        }

        console.log('To Send:', toSend);

        if (codeInfo) signup_Code(toSend);
        else if (!userToken) signupInd_NoInvite(toSend);
        else signupInd_Invite(toSend);
    }

    const getMaxDate = () => {
        const date = new Date();
        date.setFullYear(date.getFullYear() - 18);
        return date;
    }

    const handleOnBlur = (e: any) => {
        const date = new Date(e.target.value);
        const date2 = new Date(userBirthday?.value || '');

        if (date.toDateString() !== date2.toDateString()) {
            currentSuccess?.setSuccessData(false, true, 'You must be 18 years old or higher');
        }
    };

    const clickCalendar = () => {
        if (datepickerRef) {
            datepickerRef.current.setFocus(true)
        }
    }

    useEffect(() => {
        if (token) {
            setUserToken(token);
            dispatch(getInvitesByToken({ token: token }));
        }
    }, [token]);

    useEffect(() => {
        if (invite.status === 'USED') {
            navigate("/");
            currentSuccess?.setSuccessData(false, true, 'Invite already used');
        }
        else updateInvite();
    }, [invite]);

    useEffect(() => {
        if (userCountry.value?.value) {
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Roster.postalCodes(userCountry.value?.value, dataSDK.token)
                })
                .then((results) => {
                    if (results.body && 'maxLength' in results.body) {
                        setZipcodeLengths(results.body)
                    }
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }, [userCountry])

    return (
        <>
            {!emailSent ? (
                <Form_signup>
                    <TitleForm>
                        {invite.username ? 'Please, complete your registration.' : 'Please Signup'}
                    </TitleForm>
                    {codeInfo && <SubTitleForm>{`You are registering to ${codeInfo.clubInfo?.club_name} of ${codeInfo.clubInfo?.org_name}`}</SubTitleForm>}
                    <Inputs>
                        <Row2>
                            <Label>First name<Imp>*</Imp></Label>
                            <Input
                                value={userFirstName.value}
                                onChange={(e) => setUserFirstName({ ...userFirstName, value: e.currentTarget.value })}
                            />
                            {(userFirstName.showError) && (<ErrorField error={userFirstName.error} />)}
                        </Row2>
                        <Row2>
                            <Label>Last name<Imp>*</Imp></Label>
                            <Input
                                value={userLastName.value}
                                onChange={(e) => setUserLastName({ ...userLastName, value: e.currentTarget.value })}
                            />
                            {(userLastName.showError) && (<ErrorField error={userLastName.error} />)}
                        </Row2>
                    </Inputs>
                    <Inputs>
                        <Row1>
                            <Label>Business name</Label>
                            <Input
                                value={businessName.value}
                                onChange={(e) => setBusinessName({ ...businessName, value: e.currentTarget.value })}
                            />
                        </Row1>
                    </Inputs>
                    <Inputs>
                        <Row1>
                            <Label>Email<Imp>*</Imp></Label>
                            {(invite.email) ? (
                                <DisabledInput>{userEmail.value}</DisabledInput>
                            ) : (
                                <Input
                                    value={userEmail.value}
                                    onChange={(e) => setUserEmail({ ...userEmail, value: e.currentTarget.value })}
                                />
                            )}
                            {(userEmail.showError) && (<ErrorField error={userEmail.error} />)}
                        </Row1>
                    </Inputs>
                    <Inputs>
                        <Row2>
                            <Label>Date of Birth<Imp>*</Imp></Label>
                            <InputIcon theme={{ main: 'datePickerYearMonth' }}>
                                <DatePicker
                                    ref={datepickerRef}
                                    placeholderText={'MM  /  DD  /  YYYY'}
                                    selected={userBirthday.value ? new Date(userBirthday.value || '') : null}
                                    onChange={(date: Date) => setUserBirthday({
                                        ...userBirthday,
                                        value: date.toLocaleDateString()
                                    })}
                                    onBlur={handleOnBlur}
                                    maxDate={getMaxDate()}
                                    className="inputDatePicker"
                                    showYearDropdown
                                    showMonthDropdown
                                    yearDropdownItemNumber={100}
                                />
                                <StyledFontAwesomeIcon
                                    icon={faCalendar}
                                    size='lg'
                                    onClick={clickCalendar}
                                />
                            </InputIcon>
                            {(userBirthday.showError) && (<ErrorField error={userBirthday.error} />)}
                        </Row2>
                        <Row2>
                            <Label>Gender<Imp>*</Imp></Label>
                            <Select onClick={() => setShowOptions(!showOptions)}>
                                <Labelselect>{userGender.value?.label !== '' ? userGender.value?.label : 'Select an option'}</Labelselect>
                                <StyledFontAwesomeIcon
                                    icon={faChevronDown}
                                    size='sm'
                                />
                            </Select>
                            {showOptions &&
                                <Items>
                                    {GendersReactSelect.map((gender, index) => (
                                        <Item onClick={() => {
                                            setShowOptions(!showOptions)
                                            setUserGender({
                                                ...userGender,
                                                value: gender
                                            })
                                        }
                                        }
                                            key={index}>
                                            {gender.label}
                                        </Item>
                                    ))}
                                </Items>
                            }
                            {(userGender.showError) && (<ErrorField error={userGender.error} />)}
                        </Row2>
                    </Inputs>
                    <Inputs>
                        <Row2>
                            <Label>Country<Imp>*</Imp></Label>
                            <SelectorCountry
                                country={userCountry}
                                setCountry={setUserCountry}
                                searchCountry={searchCountry}
                                setSearchCountry={setSearchCountry}
                            />
                            {(userCountry.showError) && (<ErrorField error={userCountry.error} />)}
                        </Row2>
                        <Row2>
                            <Label>Zip / Postal Code<Imp>*</Imp></Label>
                            <Input
                                value={userZip.value}
                                onChange={(e) => setUserZip({ ...userZip, value: e.currentTarget.value })}
                            />
                            {(userZip.showError) && (<ErrorField error={userZip.error} />)}
                        </Row2>
                    </Inputs>
                    <Inputs>
                        <Row2>
                            <Label>Username<Imp>*</Imp></Label>
                            <Input
                                value={username.value?.toLowerCase()}
                                onChange={(e) => setUsername({ ...username, value: (e.currentTarget.value).toLowerCase() })}
                                autoComplete={"unInput"}
                            />
                            {(username.showError) && (<ErrorField error={username.error} />)}
                        </Row2>
                        <Row2>
                            <Label>Password<Imp>*</Imp></Label>
                            <InputIcon_Healer
                                onClick={() => setShowPasswordTooltip(true)}
                            >
                                <Input
                                    type={showPassword ? 'text' : 'password'}
                                    value={userPassword.value}
                                    onChange={(e) => setUserPassword({ ...userPassword, value: e.currentTarget.value })}
                                    autoComplete={"new-password"}
                                />
                                <StyledFontAwesomeIcon
                                    icon={showPassword ? faEyeSlash : faEye}
                                    size='sm'
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            </InputIcon_Healer>
                            {(showPasswordTooltip) && (<PasswordToolTip password={userPassword.value || ''} setShowModal={setShowPasswordTooltip} />)}
                            {(userPassword.showError) && (<ErrorField error={userPassword.error} />)}
                        </Row2>
                    </Inputs>
                    <TextTerms>
                        If you are already part of vibe, <LoginBtn onClick={() => currentSuccess?.setLoginModal(true, true, token, true)}>log in</LoginBtn>
                    </TextTerms>
                    <TextTerms>
                        By clicking Sign up, you agree to our <NavLink to="/terms">Terms of Service</NavLink> and <NavLink to="/privacy">Privacy Policy</NavLink>
                    </TextTerms>
                    <WrapperButton>
                        <ButtonSpinner
                            loading={loadingSignup}
                            handleClick={handleSignup}
                            text={'Sign Up'}
                            hasIcon={true}
                            icon={'arrow-right'}
                        />
                    </WrapperButton>
                </Form_signup>
            ) : (
                <EmailSent type={"individual"} from={"individual"} />
            )}
        </>
    )
}

const Labelselect = styled.div`
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 10px;
    text-overflow: ellipsis;
    text-align: left;
`

const WrapperButton = styled.div`
    margin-top: 10px;
    width: 100%;
    @media only screen and (max-width: 500px) {
        margin-bottom: 20px;
   }
`;

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 500px) {
        height: fit-content;
    }
`;

const Form = styled.div`
   width: 410px;
   margin: 0px auto;
   @media only screen and (max-width: 500px) {
        width: 100%;
        padding: 0 24px;
   }
   
`;

const TitleForm = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
`;

const SubTitleForm = styled(TitleForm)`
    font-weight: 400;
    font-family: 'Inter';
    font-size: 16px;
`;

export const Logo = styled.div`
    width: 110px;
    height: 50px;
    background-image: url("./logoVibe.png");
    background-size: contain;
    background-repeat: no-repeat;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: black;
    cursor: pointer;
`;

const TextTerms = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%; 
    text-align: initial;
`

const LoginBtn = styled.span`
    border: none;
    background: none;
    text-decoration: underline;
    cursor: pointer;
`;

const NavLink = styled(Link)`
  text-decoration: underline;
`;

const StyledFontAwesomeIconSend = styled(FontAwesomeIcon)`
    color: white;
    margin-left: 15px;
    font-size: 16px;
`;

const IconDropdownInput = styled(StyledFontAwesomeIcon)`
    position: absolute;
    z-index: 2;
    top: 55%;
    left:80%;
`;

const ButtonSend = styled.div`
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    border-radius: 11px;
    background-color: var(--simple-blue-dark-2);
    border: 0;
    color: white;
    font-weight: 700;
    margin-top: 25px;
    margin-right: 26px;
    position: relative;
    cursor: pointer;
    font-size: 24px;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
`
export const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
`

const InputIcon = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    border-radius: 9px;
    align-items: center;
    padding-right: 15px;
    border: 1px solid var(--simple-gray);
    height: 40px;
    
    & ${Input}{
        border: 0;
        height: 38px;
    } 
`

export const InputPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 43px;
    border-radius: 9px;
    border: 0;
    padding: 0 0px 0 20px;
    font-size: 15px;
    font-family: 'Inter';
`;

const Select = styled.div`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
`
const Select_Input = styled.input`
    position: relative;
    outline: none;
    width: 100%;
    height: 45px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Arial;
    font-size: 16px;
    border: 1px solid var(--simple-gray);
    overflow: hidden;
    text-overflow: ellipsis;

    &::placeholder{
        color: var(--simple-gray);
        font-family: Arial;
    }
`

const Label = styled.div`    
    width: 100%;
    text-align: left;
    margin: 3px 0;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`
const Inputs = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 500px) {
        padding: 0px;
        gap: 15px;
        
    }
`

const Items = styled.ul`
    width: 100%;
    position: absolute;
    top: 65px;
    z-index: 1;
    overflow-y: scroll;
    max-height: 200px;
    border-radius: 12px;
`

const Item = styled.li`
    background-color: white;
    padding: 10px 20px;
    text-align: left;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;

    &:last-child {
        border-radius: 0 0 10px 10px;
        -webkit-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        -moz-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
    }

    &:hover {
        background-color: #e8e8e8;
    }
`

const InputIcon_Healer = styled(InputIcon)`
    position: relative;
`;


const LoginTitle = styled.p`
    font-size: 24px;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
`;

export default SignupHealerInd;