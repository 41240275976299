export const padNumber = (n: number, pad: number): string => String(n).padStart(pad, '0');

export const truncateDecimals = (num: number, digits: number) => {
    let numS = num.toString(),
        decPos = numS.indexOf('.'),
        substrLength = decPos == -1 ? numS.length : 1 + decPos + digits,
        trimmedResult = numS.substr(0, substrLength),
        finalResult = isNaN((trimmedResult as any)) ? 0 : trimmedResult;

    return parseFloat((finalResult as any));
}

export const formatCompactNumber = (num: number) => {
    const formatter = Intl.NumberFormat("en", { notation: "compact" });
    return formatter.format(num);
}