import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/esm/Modal";
import styled from "styled-components";
import { IconClicker } from "../../Home/HeaderHome/styles";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";

const PaymentSuccessful = ({
    show,
    success,
    amount,
    error,
    close
}: {
    show: boolean
    close: () => void
    success: boolean
    amount: number
    error?: string
}) => {
    return (<Modal show={show} className="ModalSuccessPay">
        <Modal.Header>
            <IconClicker
                icon={faTimes}
                color='#6C6F75'
                size="lg"
                onClick={close}
            />
        </Modal.Header>
        <Modal.Body>
            <FontAwesomeIcon
                icon={success ? faCheckCircle : faTimesCircle}
                color={success ? '#A3CC52' : '#cc5a52'}
                size='xl'
            />
            <Title>{success ? 'Payment Successful' : 'Payment Failed'}</Title>
            {success ? `We’ve processed your charge for ${amount} US$` : (error ? error : `Please try again later.`)}
        </Modal.Body>
    </Modal>)
}

export default PaymentSuccessful;

const Title = styled.div`
    color: #333;

    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;