import styled from "styled-components";
import { RowIconCardHome } from "../../../BaseComponents/HomeCards/styles";

export const BaseCardPanelRight = styled.div`
    display: flex;
    flex-direction: column;
    width: 571px;
    height: 100vh;
    padding: 24px 32px;
    align-items: flex-start;
    gap: 16px;
    position: absolute;
    right: ${({show}:{show: boolean}) => show ? '0' : '-1000%'};
    bottom: 0;
    background: var(--background-default);
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.15);
    z-index: 10000000;

    -webkit-transition: all 0.5s ease 0.5s;
    -moz-transition: all 0.5s ease 0.5s;
    -o-transition: all 0.5s ease 0.5s;
    -ms-transition: all 0.5s ease 0.5s;
    -webkit-transition: all 0.5s ease 0.5s;
    transition: all 0.5s ease 0.5s;

    @media only screen and (max-width: 770px) {
        width: 100%;
    }
`;

export const TitlePanelLeft = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    width: 100%;

    color: var(--default-dark);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Inter';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.1px;
`;

export const TitlePhotoWrapper = styled(TitlePanelLeft)`
    justify-content: flex-start;
`;

export const RowAliasesPanelRight = styled(RowIconCardHome)`
    width: 100%;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
`;

export const DescriptionPanelRight = styled.div`
    color: var(--default-dark);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
    width: 100%;
    text-align: left;
`;

export const ButtonViewMorePanelRight = styled.div`
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    background-color: var(--orange-home);
    color: white;
    padding: 4px 8px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 700;
`;