import Modal from 'react-bootstrap/Modal';
import ModalIconClose from '../Modals/ModalIconClose';
import styled from 'styled-components';
import TwoColumns from '../TwoColumns';
import Label from '../../containers/Forms/Label';
import { Imp } from '../signupsetuphealerind';
import SelectorCountry from '../BaseComponents/Location/SelectorCountry';
import { useContext, useEffect, useState } from 'react';
import { CountryOption } from '../../hooks/useCountry';
import { StateOption } from '../../hooks/Locations/useActiveState';
import ErrorField from '../BaseComponents/ErrorField';
import SelectorState from '../BaseComponents/Location/SelectorState';
import { Input } from '../signupsetuphealer';
import { CityOption } from '../../hooks/Locations/useStateCities';
import SelectorCity from '../BaseComponents/Location/SelectorCity';
import { AddressElement, CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentFormButton } from '../PaymentForm/PaymentForm.styles';
import { IPrice } from '@vibe/sdk/dist/interfaces/Admin/payment/subscription/product.interface';
import { ContextSuccess } from '../../Context';
import { Stripe } from 'stripe'
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../store/actions/userActions';
import { Loader, LoaderMini } from '../BaseComponents/Loader';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { SDK } from '@vibe/sdk';
import { BaseFieldState, getFieldState } from '../BaseComponents/utils';
import Button from 'react-bootstrap/Button';
import { onHideType } from '../BaseComponents/Modals/ModalUnsaved';
import { ISavePaymentMethodRequest } from '@vibe/sdk/dist/interfaces/Admin/payment/paymentIntent.interface';
import { IUserGradeResponse } from '@vibe/sdk/interfaces/Admin/User';
import { RootState } from '../../store/store';
import { Address, BillingDetails, PaymentMethod } from '@stripe/stripe-js';
import ButtonSpinner from '../BaseComponents/ButtonSpinner';

interface ModalPaymentProps {
  showModal: boolean;
  onHide: () => void;
  reload: () => void;
}

const ModalAddPayment = ({
  showModal,
  onHide,
  reload
}: ModalPaymentProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const currentSuccess = useContext(ContextSuccess);
  const username = useSelector((state: RootState) => state.getUser.userInfo?.username || '');
  const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);
  const dispatch = useDispatch();

  const [country, setCountry] = useState<BaseFieldState<CountryOption>>(getFieldState({ label: '', value: '' }));
  const [state, setState] = useState<BaseFieldState<StateOption>>(getFieldState({ label: '', value: '' }));
  const [address1, setAddress1] = useState<BaseFieldState<string>>(getFieldState(''));
  const [address2, setAddress2] = useState<BaseFieldState<string>>(getFieldState(''));
  const [city, setCity] = useState<BaseFieldState<CityOption>>(getFieldState({ label: '', value: '' }));
  const [zipcode, setZipcode] = useState<BaseFieldState<string>>(getFieldState(''));
  const [nameOnCard, setNameOnCard] = useState<BaseFieldState<string>>(getFieldState(''));
  const [discountCode, setDiscountCode] = useState<BaseFieldState<string>>(getFieldState(''));

  const [searchCountry, setSearchCountry] = useState('');
  const [searchState, setSearchState] = useState('');
  const [searchCity, setSearchCity] = useState('');

  const [address, setAddress] = useState<BillingDetails>();

  const [loading, setLoading] = useState(false);

  const getCountry = () => {
    //@todo Two-letter country code ([ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2)) dictionary.
    if(country.value?.value === 'COL') return 'CO';
    return 'US';
  }

  const addPaymentMethod = () => {
    if (!stripe || !elements) {
      return
    }

    let paymentMethod: PaymentMethod;

    setLoading(true);
    stripe
      .createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)!,
        billing_details: {
          address: {
            city: city.value?.label || '',
            country: getCountry(),
            line1: address1.value || '',
            line2: address2.value || '',
            postal_code: zipcode.value || '',
            state: state.value?.label || '',
          },
          email: user.email || '',
          name: `${user.firstname || ''} ${user.lastname || ''}`,
        },
      })
      .then((result) => {
        paymentMethod = result.paymentMethod as PaymentMethod;
        return getDataSDK();
      })
      .then((dataSDK: getDataSDKResponse) => {
        const toSend: ISavePaymentMethodRequest = {
          paymentMethod,
          username,
        }
        return dataSDK.sdk.Admin.addPaymentMethod(toSend, dataSDK.token);
      })
      .then(results => {
        if (results.statusCode === 201) {
          reload();
          currentSuccess?.setSuccessData(true, true, 'New card added successfully.');
          clearForm();
          onHide();
        }
        else {
          currentSuccess?.setSuccessData(false, true, 'There was an error, try again later');
          console.log(results);
        }
      })
      .catch((error) => {
        console.log(error)
        currentSuccess?.setSuccessData(false, true, error.message ?? 'There was an error, try again later');
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const clearForm = () => {
    setCountry(getFieldState({ label: '', value: '' }));
    setState(getFieldState({ label: '', value: '' }));
    setAddress1(getFieldState(''));
    setAddress2(getFieldState(''));
    setCity(getFieldState({ label: '', value: '' }));
    setZipcode(getFieldState(''));
    setNameOnCard(getFieldState(''));
    setDiscountCode(getFieldState(''));
    setSearchCountry("")
    setSearchState("")
    setSearchCity("")
  }

  const handleDiscard = (data: string) => {
    currentSuccess?.setModalWarning(
      'UNSAVED',
      (type) => onHideDiscard(type, data),
      'Do you want to discard card changes?'
    );
  }

  const onHideDiscard = (type: onHideType, id: string) => {
    switch (type) {
      case 'save':
        currentSuccess?.setModalsOff();
        addPaymentMethod();
        break;

      case 'close':
        currentSuccess?.setModalsOff();
        break;

      case 'discard':
        clearForm();
        onHide();
        currentSuccess?.setModalsOff();
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    clearForm()
  }, [showModal])

  return (
    <Modal show={showModal} className="ModalAddPayment">
      <Modal.Body>
        <ModalHeader>
          <TitleForm>Add Card</TitleForm>
          <ModalIconClose onClick={onHide} />
        </ModalHeader>
        <Form>
          <LabelTitle>Billing Address</LabelTitle>
          {/* <AddressElement options={{ mode: 'billing' }} /> */}
          <Row>
            <Label>Address Line 1<Imp>*</Imp></Label>
            <Input
              value={address1.value}
              onChange={(e) => setAddress1({ ...address1, value: e.currentTarget.value })}
            />
            {(address1.showError) && (<ErrorField error={address1.error} />)}
          </Row>
          <Row>
            <Label>Address Line 2</Label>
            <Input
              value={address2.value}
              onChange={(e) => setAddress1({ ...address2, value: e.currentTarget.value })}
            />
            {(address2.showError) && (<ErrorField error={address2.error} />)}
          </Row>
          <TwoColumns
            left={
              <WrapperCountrySelect>
                <Label>Country<Imp>*</Imp></Label>
                <SelectorCountry
                  country={country}
                  setCountry={setCountry}
                  searchCountry={searchCountry}
                  setSearchCountry={setSearchCountry}
                />
                {(country.showError) && (<ErrorField error={country.error} />)}
              </WrapperCountrySelect>
            }
            right={
              <WrapperCountrySelect>
                <Label>State / Region<Imp>*</Imp></Label>
                <SelectorState
                  country={country.value?.value ?? ''}
                  state={state}
                  setState={setState}
                  searchState={searchState}
                  setSearchState={setSearchState}
                />
                {(state.showError) && (<ErrorField error={state.error} />)}
              </WrapperCountrySelect>
            }
          />

          <TwoColumns
            left={
              <>
                <Label>City<Imp>*</Imp></Label>
                <SelectorCity
                  state={state.value?.value}
                  city={city}
                  setCity={setCity}
                  searchCity={searchCity}
                  setSearchCity={setSearchCity}
                />
                {(city.showError) && (<ErrorField error={city.error} />)}</>
            }
            right={
              <>
                <Label>Zip / Postal Code<Imp>*</Imp></Label>
                <Input
                  value={zipcode.value}
                  onChange={(e) => setZipcode({ ...zipcode, value: e.currentTarget.value })}
                />
                {(zipcode.showError) && (<ErrorField error={zipcode.error} />)}
              </>
            }
          />

          <LabelTitle>Card Information</LabelTitle>
          <CardWrapper>
            <CardElement options={{hidePostalCode: true}} />
          </CardWrapper>
        </Form>
        <Buttons>
          <ButtonCancel onClick={() => handleDiscard('')}>
            Cancel
          </ButtonCancel>
          <WrapperButton>
            <ButtonSpinner
              loading={loading}
              handleClick={addPaymentMethod}
              text={'Confirm'}
              hasIcon={false}
            />
          </WrapperButton>
        </Buttons>
      </Modal.Body>
    </Modal>
  )
}

const WrapperButton = styled.div`
  width: 50%;
`;

const ButtonCancel = styled.div`
  background: #FFFFFF;
  border: 1px solid var(--t-blue);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 14px 20px;
  width: 50%;
  color: var(--t-blue);
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
`

const ButtonSave = styled.div`
  background: var(--t-blue);
  border: 1px solid var(--t-blue);
  border-radius: 12px;
  padding: 14px 20px;
  width: 50%;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
`

const Buttons = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 10px;
`

const LabelTitle = styled.div`
  color: #333;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.1px;
  text-align: left;
  margin-top: 15px;
`

const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperCountrySelect = styled.div`
    margin-bottom: 7px;
`

const Row = styled.div`
  width: '100%';
  margin-top: 5px;
  margin-bottom: 5px;
`;

const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TitleForm = styled.div`
  font-family: 'Inter-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
`;

const CardWrapper = styled.div`
  width: 100%;
  border: 1px solid var(--simple-gray);
  border-radius: 10px;
  padding: 10px;
`;

export default ModalAddPayment;