import i18n from "i18next";
import { initReactI18next } from "react-i18next";

/*
Para los archivos JSON que se importan abajo:

Lady, usa keys únicos pero fáciles de recordar con la métodología BEM
Más info sobre BEM en: https://en.bem.info/methodology/quick-start/
Por ejemplo:
  "0": "Sign Up" 🚫
  "signup": "Sign Up" 🤬
  "signup__title": "Sign Up" ✅

*/

import enTranslations from './translation/en.json';
import esTranslations from './translation/es.json';


const resources = {
  en: {
    translation: enTranslations,
  },
  es: {
    translation: esTranslations,
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    //lng: "es",
    interpolation: {
      escapeValue: false,
    }
  });

  export default i18n;