import styled from "styled-components";

const Label = styled.div`    
    width: 100%;
    text-align: left;
    margin: 3px 0;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`;

export default Label;