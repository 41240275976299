import { AnyAction } from "redux"
import { ThunkAction, ThunkDispatch } from "redux-thunk"
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper"
import { GET_COMMTABLE_ERROR, GET_COMMTABLE_SUCCESS, GET_EVTABLE_ERROR, GET_EVTABLE_SUCCESS, GET_GENERALDASH_ERROR, GET_GENERALDASH_SUCCESS, GET_HSTABLE_ERROR, GET_HSTABLE_SUCCESS, START_GET_COMMTABLE, START_GET_EVTABLE, START_GET_GENERALDASH, START_GET_HSTABLE } from "../constants/DashboardConstants";

export const getGeneralDashboard = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
    new Promise((resolve, reject) => {
      dispatch({ type: START_GET_GENERALDASH });
      getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
          return dataSDK.sdk.Dashboard.getGeneralInfo(username, dataSDK.token)
        })
        .then((response) => {
          if (!response.body || !("services" in response.body)) {
            dispatch({ type: GET_GENERALDASH_ERROR, payload: response });
            return;
          }
          dispatch({ type: GET_GENERALDASH_SUCCESS, payload: response.body });
        })
        .catch((error) => dispatch({ type: GET_GENERALDASH_ERROR, payload: error }))
    })

export const getServicesDashboardTable = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
    new Promise((resolve, reject) => {
      dispatch({ type: START_GET_HSTABLE });
      getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
          return dataSDK.sdk.Dashboard.getModalitiesTable(username, dataSDK.token)
        })
        .then((response) => {
          if (!response.body || !Array.isArray(response.body)) {
            dispatch({ type: GET_HSTABLE_ERROR, payload: response });
            return;
          }
          dispatch({ type: GET_HSTABLE_SUCCESS, payload: response.body });
        })
        .catch((error) => dispatch({ type: GET_HSTABLE_ERROR, payload: error }))
    })

export const getClubsDashboardTable = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
    new Promise((resolve, reject) => {
      dispatch({ type: START_GET_COMMTABLE });
      getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
          return dataSDK.sdk.Dashboard.getCommunitiesTable(username, dataSDK.token)
        })
        .then((response) => {
          if (!response.body || !Array.isArray(response.body)) {
            dispatch({ type: GET_COMMTABLE_ERROR, payload: response });
            return;
          }
          dispatch({ type: GET_COMMTABLE_SUCCESS, payload: response.body });
        })
        .catch((error) => dispatch({ type: GET_COMMTABLE_ERROR, payload: error }))
    })

export const getEventsDashboardTable = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
    new Promise((resolve, reject) => {
      dispatch({ type: START_GET_EVTABLE });
      getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
          return dataSDK.sdk.Dashboard.getEventsTable(username, dataSDK.token)
        })
        .then((response) => {
          if (!response.body || !Array.isArray(response.body)) {
            dispatch({ type: GET_EVTABLE_ERROR, payload: response });
            return;
          }
          dispatch({ type: GET_EVTABLE_SUCCESS, payload: response.body });
        })
        .catch((error) => dispatch({ type: GET_EVTABLE_ERROR, payload: error }))
    })

