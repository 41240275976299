import { useEffect, useState, useContext } from 'react'
import styled from "styled-components";
import { Imp, listAreasModal } from "../signupsetuphealerind"
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-thin-svg-icons";
import { faCirclePlus, faPen, faTrash, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import DatePicker from "react-datepicker";
import useActiveState, { StateOption } from '../../hooks/Locations/useActiveState';
import useActiveCountry, { CountryOption } from '../../hooks/Locations/useActiveCountry';
import SelectItem from '../../interfaces/SelectItem';
import { IEducation } from '@vibe/sdk/dist/interfaces/Roster/UserInfo';
import { IUpdateUserEducationBody } from '@vibe/sdk/dist/interfaces/Roster/updateUserEducation';
import ErrorField from '../BaseComponents/ErrorField';
import ButtonSpinner from '../BaseComponents/ButtonSpinner';
import ModalUnsaved from '../BaseComponents/Modals/ModalUnsaved';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { getUserEducation } from '../../store/actions/userActions';
import IResponseError from '@vibe/sdk/dist/interfaces/IResponseError';
import SelectorCountry from '../BaseComponents/Location/SelectorCountry';
import SelectorState from '../BaseComponents/Location/SelectorState';
import { ContextSuccess } from "../../Context";
import { BaseFieldState, getFieldState } from '../BaseComponents/utils';
import { onHideType } from "../../components/BaseComponents/Modals/ModalUnsaved";
import SelectorCity from '../BaseComponents/Location/SelectorCity';
import { CityOption } from '../../hooks/Locations/useStateCities';
import SelectorYearMonth from '../SelectorYearMonth';

export const getFiltered = (listName: SelectItem[], itemSearch: string) => {
    return listName.filter((item: SelectItem) => {
        if (!itemSearch) return true;

        if (item.label && (
            item.label.toUpperCase().includes(itemSearch) ||
            item.label.toLowerCase().includes(itemSearch) ||
            item.label.includes(itemSearch)
        )) {
            return true;
        }

        return false;
    })
}

const ModalDegrees = ({
    showModal,
    onHide,
    data,
    edit,
    educationInfo,
}: {
    showModal: boolean,
    onHide: (show: boolean, value?: IEducation | IUpdateUserEducationBody) => void,
    data: IEducation | IUpdateUserEducationBody,
    edit?: boolean,
    educationInfo?: IEducation,
}) => {

    const dispatch = useDispatch();
    const currentSuccess = useContext(ContextSuccess);
    const [searchState, setSearchState] = useState('');
    const [searchCountry, setSearchCountry] = useState('');
    const [searchCity, setSearchCity] = useState('');

    const username = useSelector((state: RootState | any) => state.getUser.userInfo.username);

    const [loading, setLoading] = useState(false);

    const [degreeName, setDegreeName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [degreeYear, setDegreeYear] = useState<BaseFieldState<string>>(getFieldState(''));
    const [degreeConcentration, setDegreeConcentration] = useState<BaseFieldState<string>>(getFieldState(''));
    const [degreeUniversity, setDegreeUniversity] = useState<BaseFieldState<string>>(getFieldState(''));
    const [degreeCountry, setDegreeCountry] = useState<BaseFieldState<CountryOption>>(getFieldState({ label: '', value: '' }));
    const [degreeState, setDegreeState] = useState<BaseFieldState<CountryOption>>(getFieldState({ label: '', value: '' }));
    const [degreeCity, setDegreeCity] = useState<BaseFieldState<CityOption>>(getFieldState({ label: '', value: '' }));
    const [year, setYear] = useState<string>('');
    const [month, setMonth] = useState<string>('');

    const cleanFields = () => {
        setDegreeName(getFieldState(''));
        setDegreeYear(getFieldState(''));
        setDegreeConcentration(getFieldState(''));
        setDegreeUniversity(getFieldState(''));
        setDegreeCountry(getFieldState({ label: '', value: '' }));
        setDegreeState(getFieldState({ label: '', value: '' }));
        setDegreeCity(getFieldState({ label: '', value: '' }));
    }

    const loadData = () => {
        if (educationInfo) {
            setDegreeName(getFieldState(educationInfo.degree_name));
            setDegreeYear(getFieldState(educationInfo.graduation_year));
            setDegreeConcentration(getFieldState(educationInfo.concentration));
            setDegreeUniversity(getFieldState(educationInfo.college));
            setDegreeCountry(getFieldState({ label: educationInfo.country, value: educationInfo.country }));
            setDegreeState(getFieldState({ label: educationInfo.state, value: educationInfo.state }));
            setDegreeCity(getFieldState({ label: educationInfo.city, value: educationInfo.city }));
        }
    }



    const checkData = (): boolean => {
        let hasErrors = false;

        if (!degreeName.value || degreeName.value?.length === 0) {
            hasErrors = true;
            setDegreeName({
                ...degreeName,
                showError: true,
                error: 'Degree name is required'
            })
        } else {
            setDegreeName({
                ...degreeName,
                showError: false,
                error: ''
            })
        }

        if (!degreeYear.value || degreeYear.value?.length === 0) {
            hasErrors = true;
            setDegreeYear({
                ...degreeYear,
                showError: true,
                error: 'Degree date is required'
            })
        } else {
            setDegreeYear({
                ...degreeYear,
                showError: false,
                error: ''
            })
        }

        if (!degreeConcentration.value || degreeConcentration.value?.length === 0) {
            hasErrors = true;
            setDegreeConcentration({
                ...degreeConcentration,
                showError: true,
                error: 'Degree Concentration is required'
            })
        } else {
            setDegreeConcentration({
                ...degreeConcentration,
                showError: false,
                error: ''
            })
        }

        if (!degreeUniversity.value || degreeUniversity.value?.length === 0) {
            hasErrors = true;
            setDegreeUniversity({
                ...degreeUniversity,
                showError: true,
                error: 'Degree University is required'
            })
        } else {
            setDegreeUniversity({
                ...degreeUniversity,
                showError: false,
                error: ''
            })
        }

        if (!degreeCountry.value || degreeCountry.value.value.length === 0) {
            hasErrors = true;
            setDegreeCountry({
                ...degreeCountry,
                showError: true,
                error: 'Degree country is required'
            })
        } else {
            setDegreeCountry({
                ...degreeCountry,
                showError: false,
                error: ''
            })
        }

        if (!degreeState.value || degreeState.value.value.length === 0) {
            hasErrors = true;
            setDegreeState({
                ...degreeState,
                showError: true,
                error: 'Degree State is required'
            })
        } else {
            setDegreeState({
                ...degreeState,
                showError: false,
                error: ''
            })
        }

        if (!degreeCity.value || degreeCity.value.value.length === 0) {
            hasErrors = true;
            setDegreeCity({
                ...degreeCity,
                showError: true,
                error: 'Degree City is required'
            })
        } else {
            setDegreeCity({
                ...degreeCity,
                showError: false,
                error: ''
            })
        }

        return hasErrors;
    }

    const handleOnCreateDegree = (toSend: IUpdateUserEducationBody) => {
        setLoading(true)
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Roster.updateUserEducation(username, toSend, dataSDK.token)
            })
            .then((results: any) => {
                if (results.statusCode === 201) {
                    currentSuccess?.setSuccessData(true, true, 'Degree created successfully.');
                    dispatch(getUserEducation(username));
                    cleanFields();
                    onHide(false);
                    currentSuccess?.setModalsOff();
                }
                else {
                    currentSuccess?.setSuccessData(false, true, (results.body as IResponseError).error || (results.body as IResponseError).message);
                    console.log((results.body as IResponseError).error || (results.body as IResponseError).message)
                }
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const handleOnUpdateDegree = (toSend: IUpdateUserEducationBody) => {
        setLoading(true)
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Roster.updateUserEducation(username, toSend, dataSDK.token)
            })
            .then((results: any) => {
                if (results.statusCode === 201) {
                    currentSuccess?.setSuccessData(true, true, 'Degree updated successfully.');
                    dispatch(getUserEducation(username));
                    cleanFields();
                    onHide(false);
                }
                else {
                    currentSuccess?.setSuccessData(false, true, (results.body as IResponseError).error || (results.body as IResponseError).message);
                    console.log((results.body as IResponseError).error || (results.body as IResponseError).message)
                }
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const handleOnSaveDegree = () => {
        setLoading(true);
        if (checkData()) {
            setLoading(false);
            return;
        }

        const toSend: IUpdateUserEducationBody = {
            college: degreeUniversity.value ?? '',
            degree_name: degreeName.value ?? '',
            graduation_year: degreeYear.value ?? '',
            concentration: degreeConcentration.value ?? '',
            country: degreeCountry.value?.label ?? '',
            state: degreeState.value?.label ?? '',
            city: degreeCity.value?.label ?? '',
            from: 2000,
            to: 2000,
        }

        if (edit && educationInfo) handleOnUpdateDegree(toSend)
        else handleOnCreateDegree(toSend)

    }

    const onHideUnsaved = (type: onHideType) => {
        switch (type) {
            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                onHide(false);
                cleanFields();
                break;

            case 'save':
                currentSuccess?.setModalsOff();
                handleOnSaveDegree();
                break;

            default:
                currentSuccess?.setModalsOff();
                break;
        }
    }

    const handleUnsaved = () => {
        currentSuccess?.setModalWarning(
            'UNSAVED',
            (type) => onHideUnsaved(type),
            'Do you want to save your changes?'
        );
    }

    useEffect(() => {
        if (year !== '' && month !== '') {
            setDegreeYear({
                ...degreeYear,
                value: `${year}-${month}`
            })
        }
    }, [year, month])

    useEffect(() => loadData(), [])

    return (
        <>
            <Modal className="modalSetup" show={showModal}>
                <Modal.Header>
                    <Modal.Title>
                        <Title>Add Degree</Title>
                        <FontAwesomeIcon
                            onClick={handleUnsaved}
                            icon="times"
                            size='xl'
                            color={'black'}
                            className=""
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WrapperBody>
                        <Row1>
                            <Label>College or University<Imp>*</Imp></Label>
                            <Input value={degreeUniversity.value}
                                onChange={(e) => setDegreeUniversity({
                                    ...degreeUniversity,
                                    value: e.currentTarget.value
                                })}
                            />
                            {(degreeUniversity.showError) && (<ErrorField error={degreeUniversity.error} />)}
                        </Row1>
                        <Row1>
                            <Label>Degree<Imp>*</Imp></Label>
                            <Input
                                value={degreeName.value}
                                onChange={(e) => setDegreeName({
                                    ...degreeName,
                                    value: e.currentTarget.value
                                })}
                            />
                            {(degreeName.showError) && (<ErrorField error={degreeName.error} />)}
                        </Row1>
                        <Row1>
                            <Label>Concentration<Imp>*</Imp></Label>
                            <Input value={degreeConcentration.value}
                                onChange={(e) => setDegreeConcentration({
                                    ...degreeConcentration,
                                    value: e.currentTarget.value
                                })}
                            />
                            {(degreeConcentration.showError) && (<ErrorField error={degreeConcentration.error} />)}
                        </Row1>
                        <Row1>
                            <Label>Date of Graduation<Imp>*</Imp></Label>
                            <InputIcon theme={{ main: 'datePickerYearMonthList' }}>
                                <SelectorYearMonth
                                    setYear={(date: string) => setYear(date)}
                                    setMonth={(date: string) => setMonth(date)}
                                    year={degreeYear.value?.split("-")[0]}
                                    month={degreeYear.value?.split("-")[1]}
                                />
                                {/* <DatePicker
                                    placeholderText={'YYYY / MM'}
                                    value={degreeYear.value}
                                    onChange={(date: Date) => setDegreeYear({
                                        ...degreeYear,
                                        value: `${date.getFullYear()}-${date.getMonth() < 9 ? '0' : ''}${date.getMonth() + 1}`
                                    })}
                                    className={"inputDatePicker"}
                                    showYearDropdown
                                    showMonthDropdown
                                />
                                <StyledFontAwesomeIcon
                                    icon={faCalendar}
                                    size='lg'
                                /> */}
                            </InputIcon>
                            {(degreeYear.showError) && (<ErrorField error={degreeYear.error} />)}
                        </Row1>
                        <Inputs>
                            <Row3>
                                <Label>Country<Imp>*</Imp></Label>
                                <SelectorCountry
                                    country={degreeCountry}
                                    setCountry={setDegreeCountry}
                                    searchCountry={searchCountry}
                                    setSearchCountry={setSearchCountry}
                                    onlyActive={false}
                                />
                                {(degreeCountry.showError) && (<ErrorField error={degreeCountry.error} />)}
                            </Row3>
                            <Row3>
                                <Label>State / Region<Imp>*</Imp></Label>
                                <SelectorState
                                    country={degreeCountry.value?.value ?? ''}
                                    state={degreeState}
                                    setState={setDegreeState}
                                    searchState={searchState}
                                    setSearchState={setSearchState}
                                />
                                {(degreeState.showError) && (<ErrorField error={degreeState.error} />)}
                            </Row3>
                            <Row3>
                                <Label>City<Imp>*</Imp></Label>
                                <SelectorCity
                                    state={degreeState.value?.value}
                                    city={degreeCity}
                                    setCity={setDegreeCity}
                                    searchCity={searchCity}
                                    setSearchCity={setSearchCity}
                                />
                                {/* <Input value={degreeCity.value}
                                    onChange={(e) => setDegreeCity({
                                        ...degreeCity,
                                        value: e.currentTarget.value
                                    })}
                                /> */}
                                {(degreeCity.showError) && (<ErrorField error={degreeCity.error} />)}
                            </Row3>
                        </Inputs>
                    </WrapperBody>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonCancel onClick={handleUnsaved}>Cancel</ButtonCancel>
                    <WrapperButton>
                        <ButtonSpinner
                            loading={loading}
                            handleClick={handleOnSaveDegree}
                            text={'Save'}
                            hasIcon={false}
                        />
                    </WrapperButton>
                </Modal.Footer>
            </Modal>
            {/* <ModalUnsaved
                show={showUnsavedData}
                onHide={onHideUnsaved}
                content={"Do you want to save your changes?"}
            /> */}
        </>
    )
}

const WrapperBody = styled.div`
    display: flex;
    flex-direction: column;
    height: 75vh;
`;

const WrapperButton = styled.div`
    width: 50%;
`;

const List = styled.div`
    padding: 0 24px;
`

const Item = styled.li`
    background-color: white;
    padding: 10px 20px;
    text-align: left;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;

    &:last-child {
        border-radius: 0 0 10px 10px;
        -webkit-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        -moz-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
    }

    &:hover {
        background-color: #e8e8e8;
    }
`

const Items = styled.ul`
    width: 100%;
    position: absolute;
    top: 84px;
    z-index: 1;
    overflow-y: scroll;
    max-height: 200px;
    border-radius: 0px 0px 9px 9px;
    border:1px solid var(--simple-gray)
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: black;
    cursor: pointer;
`;

const IconDropdownInput = styled(StyledFontAwesomeIcon)`
    position: absolute;
    z-index: 2;
    top: 55%;
    left:89%;
`;

const Select_Input = styled.input`
    position: relative;
    outline: none;
    width: 100%;
    height: 45px;
    border-radius: ${props => props.theme.active ? '9px 9px 0px 0px' : '9px'};
    border: 0;
    padding: 0 20px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Arial;
    font-size: 16px;
    border: 1px solid var(--simple-gray);

    &::placeholder{
        color: var(--simple-gray);
        font-family: Arial;
    }
`

const IconDrop = styled.img`
    width: 28px;
`

const File = styled.div`
    display: flex;
    align-items: center;

    & ${IconDrop}{
        width: 18px;
        margin-left: 10px;
    }
`

const TableIcon = styled(FontAwesomeIcon)`
    font-size: 18px;
`;

const TableList = styled.table`
    width: 100%;
    background-color: white;
    border-radius: 8px;
`

const Tr = styled.tr`
    & td:last-child,
    & th:last-child {
        width: 85px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

const Th = styled.th`
    vertical-align: middle;
    height: 40px;
    border-bottom: 1px solid var(--simple-gray-2);
    font-family: 'Inter-Bold';
    font-size: 14px;
    padding: 0 15px;
    text-align: left;
`

const Td = styled.td`
    vertical-align: middle;
    height: 40px;
    border-bottom: 1px solid var(--simple-gray-2);
    font-family: 'Inter';
    font-size: 14px; 
    padding: 0 15px; 
    text-align: left;
`

const SeeAllModalities = styled.div`
    font-family: 'Inter-Bold';
    font-size: 14px;
    line-height: 17px;
    color: var(--absolute-zero);
    text-align: left;
    margin: 0 24px;
    border-bottom: ${props => props.theme.border || '1'}px solid gray;
    padding-bottom: 13px;
    width: calc(100% - 33px);
    cursor: pointer;
    margin-top: 20px;
`

const PlusIcon = styled(FontAwesomeIcon)`
    font-size: 24px;
    margin-right: 10px;
`;


const Inputs = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 7px;
    gap: 20px;
    padding: 0 24px;
`

const ButtonCancel = styled.div`
    width: 49%;
    text-align: center;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 1px solid var(--dark-blue);
`
const ButtonSave = styled.div`
    width: 49%;
    text-align: center;
    background-color: var(--dark-blue);
    color: white;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 0px;
`

export const InputPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0;
    font-size: 15px;
    font-family: 'Inter';
    background-color: transparent;
`;

export const InputOnlyPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--black);
    padding-left: 20px;

    &::placeholder{
        color: var(--black);
        font-family: 'Inter-Bold'
        
    }
`;

const Title = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    width: 100%;
    text-align: left;
`

const Row1 = styled.div`
    width: 100%;
    padding: 0 24px;
`

const Row3 = styled.div`
    width: 33%;
    position: relative;
`

const Label = styled.div`    
    width: 100%;
    text-align: left;
    margin: 18px 0  8px 0;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`
const Input = styled.input`
    outline: none;
    width: 100%;
    height: 45px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
`


const InputIcon = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    border-radius: 9px;
    align-items: center;
    padding-right: 15px;
    border: 1px solid var(--simple-gray);
    height: 45px;
    position: relative;

    & .react-datepicker{
        border: 1px solid var(--simple-gray-2);
        border-radius: 11px;
        overflow: hidden;
    }

    & .react-datepicker-wrapper{
        text-align: left;
    }
    
    & ${Input}{
        border: 0;
        height: 38px;
    } 
`


const DropPhoto = styled.div`
    width: 100%;
    margin: 7px 0 20px 0;
    text-align: initial;
    border: 1px dashed var(--simple-gray);
    height: 106px;
    border-radius: 12px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
`

const ContIconDrop = styled.div`
    width: 74px;
    height: 74px;
    border-radius: 12px;
    background-color: #F2F5FF;
    display: flex;
    justify-content: center;
    align-items: center;
`

const TextDrop = styled.div`
    font-family: 'Inter';
    width: calc(100% - 74px);
    font-size: 14px;
    line-height: 17px;
    padding-left: 16px;
    color: var(--simple-brown-dark);
    text-align: center;
`

const SubtextDrop = styled.span`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: var(--onyx);
    margin-top: 4px;
`

const TextBlue = styled.span`
    color: blue
`

export default ModalDegrees