import styled from "styled-components";
import { capitalize } from "../../../helpers/letterHelper";
import { useTranslation } from "react-i18next";
import { MediaInterface } from "../../../interfaces/chat/MediaInterface";
import { ClickableIcon } from "../../../containers/SuperAdmin/TClubs/components";
import { LAVENDER } from "../../ClubIconsNavBar";
import { BROWN } from "../../SuperCenter/Modalities/Modals/components";

export const NoFiles = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: var(--t-blue);
    margin-top: 20px;
`;

export const ParticipantsTitle = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-align: flex-start;
    color: var(--white);
`;

export const ParticipantWrap = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    gap: 15px;
`;

export const ColumFlexParticipant = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const Text = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    width: 100%;
    color: white;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const TextTypeMember = styled(Text)`
    color: var(--lavender);
    font-size: 14px;
    line-height: 16px;
`;

export const AvatarParticipant = styled.img`
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    object-fit: cover;
    border-radius: 50%;
`;

export const ImageContainerParticipant = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--onyx);
    border-radius: 36px;
    position: relative;
    width: fit-content;
`;

const SelectorWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 95%;
`;

interface SelectorProps {
    active: boolean;
    borderLeft: boolean;
    borderRight: boolean;
}

const Selector = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    height: 40px;
    background-color: ${(props: SelectorProps) => props.active ? 'var(--lavender)' : 'var(--t-blue)'};
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: ${(props: SelectorProps) => props.active ? 700 : 400};
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;

    border-top-left-radius: ${(props: SelectorProps) => props.borderLeft ? '8px' : '0px'};
    border-bottom-left-radius: ${(props: SelectorProps) => props.borderLeft ? '8px' : '0px'};
    border-top-right-radius: ${(props: SelectorProps) => props.borderRight ? '8px' : '0px'};
    border-bottom-right-radius: ${(props: SelectorProps) => props.borderRight ? '8px' : '0px'};

    cursor: pointer;
`;

interface ResourcesSelectorProps {
    values: any[];
    selectedValue: string;
    setSelectedValue: (value: string) => void;
}

export const ResourcesSelector = ({
    values,
    selectedValue,
    setSelectedValue
}: ResourcesSelectorProps) => {
    const { t, i18n } = useTranslation();
    return (
        <SelectorWrapper>
            {values.map((value: any[]) => (
                <Selector
                    active={selectedValue === value[0]}
                    borderLeft={values[0] === value ? true : false}
                    borderRight={values[values.length - 1] === value ? true : false}
                    onClick={() => setSelectedValue(value[0])}
                >
                    {capitalize(t(`chat_${value[0]}`))} {`(${value[1]})`}
                </Selector>
            ))}
        </SelectorWrapper>
    )
}

const ChatMediaWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat( 
        auto-fit, 
        minmax( 
            90px, 
            1fr 
        ) 
    );
    gap: 5px;
    width: 85%;
    height: auto;
    margin-top: 10px;
    overflow-y: scroll; //@todo add correct scroll bar
    overflow-x: hidden;
`;

const Media = styled.img`
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
    border-radius: 8px;
`;

const ChatResources = styled.div`
    display: flex;
    flex-direction: column;
    width: 85%;
    height: auto;
    margin-top: 10px;
    gap: 5px;
    overflow-y: scroll;
    overflow-x: hidden; //@todo add correct scroll bar
`;

const ResourceTitle = styled.p`
    font-family: 'Inter';
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: var(--lavender);

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
`;

const ResourceDescription = styled(ResourceTitle)`
    font-weight: 400;
    color: var(--white);
`;

interface ChatMediaProps {
    media: string[];
}

export const ChatMedia = ({
    media
}: ChatMediaProps) => {
    const { t, i18n } = useTranslation();
    return (
        <ChatMediaWrapper>
            {(media.length === 0) ? (
                <NoFiles>{t('chat_noMedia')}</NoFiles>
            ) : (
                media.map((media: string) => (
                    <Media src={media || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} />
                ))
            )}
        </ChatMediaWrapper>
    )
}

const ChatFilesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

const ResourcePoint = styled.div`
    width: 5px;
    height: 5px;
    border-radius: 50px;
    background-color: var(--white);
`;

const FileInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    width: 100%;

    overflow: hidden;
    white-space: nowrap;
`;

const RowFlex = styled(ChatFilesWrapper)`
    gap: 7px;
`;

interface ChatFilesProps {
    files: MediaInterface[];
}

export const ChatFiles = ({
    files
}: ChatFilesProps) => {
    const { t, i18n } = useTranslation();
    return (
        <ChatResources>
            {(files.length === 0) ? (
                <NoFiles>{t('chat_noFiles')}</NoFiles>
            ) : (
                files.map((file: MediaInterface) => (
                    <ChatFilesWrapper>
                        <a
                            href={file.file}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <ClickableIcon
                                icon='file' //@todo Icon by file type
                                size='lg'
                                color={BROWN}
                            />
                            <FileInfo>
                                <ResourceTitle>{file.title}</ResourceTitle>
                                <RowFlex>
                                    <ResourceDescription>{file.size}</ResourceDescription>
                                    <ResourcePoint />
                                    <ResourceDescription>{file.fileType}</ResourceDescription>
                                </RowFlex>
                            </FileInfo>
                        </a>
                    </ChatFilesWrapper>
                ))
            )}
        </ChatResources>
    )
}

interface ChatLinksProps {
    links: MediaInterface[];
}

export const ChatLinks = ({
    links
}: ChatLinksProps) => {
    const { t, i18n } = useTranslation();
    return (
        <ChatResources>
            {(links.length === 0) ? (
                <NoFiles>{t('chat_noLinks')}</NoFiles>
            ) : (
                links.map((file: MediaInterface) => (
                    <ChatFilesWrapper>
                        <a
                            href={file.file}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <ClickableIcon //@todo image preview of link
                                icon='link'
                                size='lg'
                                color={BROWN}
                            />
                            <FileInfo>
                                <ResourceTitle>{file.title}</ResourceTitle> {/*@todo title of link page */}
                                <ResourceDescription>{file.file}</ResourceDescription>
                            </FileInfo>
                        </a>
                    </ChatFilesWrapper>
                ))
            )}
        </ChatResources>
    )
}

export const ParticipantsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    gap: 15px;
    width: 100%;
    background: var(--t-blue);
    border-radius: 12px;
    max-height: 325px;
    min-height: 105px;
`;

export const ScrollParticipants = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;

    :hover::-webkit-scrollbar{
        display: block;
    }

    ::-webkit-scrollbar {
        width: 5px;
        display: none;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--lavender); 
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: var(--light-brown); 
    }
`;