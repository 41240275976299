import React, { useEffect, useMemo, useState } from 'react'
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { sortAlphabeticallyOnProperty } from '../../containers/Signup/SignupHealerInd';
import { ClubDigestExtra } from '@vibe/sdk/dist/interfaces/Roster/getUserClubs';
import { IClubExtended } from '@vibe/sdk/interfaces/Club/ClubInterface';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { IUserGradeResponse } from '@vibe/sdk/dist/interfaces/Admin/User';
import { getClubs } from '../../store/actions/clubActions';
import { getOrganizationInfo } from '../../store/actions/orgsActions';
import { roleUseClubType } from '../Clubs/useClubsList';
import { ClubType } from '../../interfaces/club/ClubList';
import { getUsersOrg, getUsersWithLimit } from '../../store/actions/userActions';
import { IUserResponse } from '@vibe/sdk/interfaces/Admin/User';
import { UserConnection } from '@vibe/sdk/dist/interfaces/Roster/getUserConnections';
import { getMyConnections } from '../../store/actions/connectionActions';
import { isConnectionActive } from '../../helpers/Connections/getConnections';
import { relationTypeCard } from '../../components/BaseComponents/Connections/ConnectionCard';
import { rolesType } from '@vibe/sdk/interfaces/AccessManager/signUpUser';
import { MockConnection } from '../../interfaces/user/Connection';

export type UseUserRole = 'ALL' | 'HEALER' | 'SEEKER' | 'STAFF' | 'ADVISORY' | 'COMMITTEE';
export type UseUserStatus = 'ALL' | 'DELETED' | 'ACTIVE' | 'SUSPENDED';

export interface UserConnectionHook extends UserConnection {
  relationComplete: relationTypeCard;
}

interface useUsersProps {
  searchName: string;
  role: roleUseClubType;
  searchRole: UseUserRole;
  searchStatus?: UseUserStatus;
  notHealers?: boolean;
  orgId?: string;
  from?: 'Superadmin' | '',
  withSuper?: boolean
}

interface useUsersResponse {
  reloadList: () => void;
  allFilteredUsers: IUserResponse[];
  allSystemUsers: UserConnectionHook[];
  allOrgUsers: UserConnectionHook[];
  myFriends: UserConnectionHook[];
  myFriendRequests: UserConnectionHook[];
  mySentRequests: UserConnectionHook[];
}

const useUsersList = ({
  searchName,
  role,
  searchRole,
  searchStatus,
  notHealers,
  orgId,
  from,
  withSuper = false
}: useUsersProps): useUsersResponse => {

  const dispatch = useDispatch();

  const users: IUserResponse[] = useSelector((state: RootState) => state.getUser.usersLimit);
  const usersOrg: IUserResponse[] = useSelector((state: RootState) => state.getUser.usersOrg);
  const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);
  const connections: UserConnection[] = useSelector((state: RootState) => state.getConnection.userConnections);

  const [allFilteredUsers, setAllFilteredUsers] = useState<IUserResponse[]>([]);
  const [allSystemUsers, setAllSystemUsers] = useState<UserConnectionHook[]>([]);
  const [allOrgUsers, setAllOrgUsers] = useState<UserConnectionHook[]>([]);
  const [myFriends, setMyFriends] = useState<UserConnectionHook[]>([]);
  const [myFriendRequests, setMyFriendRequests] = useState<UserConnectionHook[]>([]);
  const [mySentRequests, setMySentRequests] = useState<UserConnectionHook[]>([]);

  const searchParsed = useMemo(() => searchName.toLowerCase(), [searchName]);
  const orgIndex = useMemo(() => role !== 'SUPER' ? user.rolesByOrg.findIndex((roles) => roles.includes('ORGADMIN')) : 0, [user]);

  const reloadList = () => {

    let orgID = '';

    if (role === 'IND' || role === 'ORG') orgID = user.organizations[orgIndex];
    if (role === 'SEEK') orgID = user.organizations[0]; // first organization that invite him

    if (orgID !== '') {
      dispatch(getUsersOrg(orgID))
    }

    dispatch(getUsersWithLimit({
      roleRequest: role === 'SUPER' ? 'SA' : 'OA'
    }));
    dispatch(getMyConnections(user.username));
  }

  const filterUser = (user: IUserResponse): boolean => {
    let acceptedName = true;
    let acceptedRole = true;
    let acceptedStatus = true;
    let acceptedNotHealers = true;
    let acceptedOrg = true;

    if (searchName && searchName !== '') {
      if(from === 'Superadmin') acceptedName = (user.username || '').toLowerCase().includes(searchParsed)
      else acceptedName = (user.firstname || '').toLowerCase().includes(searchParsed) ||
        (user.lastname || '').toLowerCase().includes(searchParsed) ||
        (user.email || '').toLowerCase().includes(searchParsed);
    }

    if (searchRole && searchRole !== 'ALL') {
      if(searchRole === 'SEEKER'){
        acceptedRole = user.roles?.includes(searchRole) && !user.roles?.includes('HEALER');
      }
      else acceptedRole = user.roles?.includes(searchRole);
    }

    if(notHealers){
      acceptedNotHealers = !user.roles?.includes('HEALER');
    }

    if(searchStatus && searchStatus !== 'ALL'){
      acceptedStatus = user.status === searchStatus;
    }

    if (orgId && orgId !== 'ALL') {
      acceptedOrg = user.organizations.includes(orgId);
    }

    return acceptedRole && acceptedName && acceptedStatus && acceptedNotHealers && acceptedOrg;
  }

  const filterUserConn = (user: UserConnectionHook): boolean => {
    let acceptedName = true;
    let acceptedRole = true;
    let acceptedNotHealers = true;

    if (searchName && searchName !== '') {
      acceptedName = (user.name || '').toLowerCase().includes(searchParsed);
    }

    if (searchRole && searchRole !== 'ALL') {
      if(searchRole === 'SEEKER'){
        acceptedRole = user.role?.includes(searchRole) && !user.role?.includes('HEALER');
      }
      else acceptedRole = user.role?.includes(searchRole);
    }

    if(notHealers){
      acceptedNotHealers = !user.role?.includes('HEALER');
    }

    return acceptedRole && acceptedName && acceptedNotHealers;
  }

  const mapConnectionOrg = () => {
    const usOrg: UserConnectionHook[] = [];

    (usersOrg || []).forEach((user) => {
      const connection = isConnectionActive(user.username || '', connections);

      let connMapped: UserConnectionHook = {
        role: user.roles as rolesType[],
        avatar: user.avatar || '',
        name: `${user.firstname || ''} ${user.lastname || ''}`,
        mutualClubs: connection.connection?.mutualClubs || 0,
        username: user.username,
        relation: [],
        relationComplete: connection.relation,
      }

      if (connection.connection) {
        connMapped = {
          ...connection.connection,
          relationComplete: connection.relation,
        }
      }

      if (filterUserConn(connMapped)) usOrg.push(connMapped);
    })

    setAllOrgUsers(usOrg);
  }

  const mapConnectionUsers = () => {
    const friends: UserConnectionHook[] = [];
    const pending: UserConnectionHook[] = [];
    const requests: UserConnectionHook[] = [];
    const allSystem: UserConnectionHook[] = [];

    (users || []).forEach((user) => {
      const connection = isConnectionActive(user.username || '', connections);

      let connMapped: UserConnectionHook = {
        role: user.roles as rolesType[],
        avatar: user.avatar || '',
        name: `${user.firstname || ''} ${user.lastname || ''}`,
        mutualClubs: connection.connection?.mutualClubs || 0,
        username: user.username,
        relation: [],
        relationComplete: connection.relation,
      }

      if (connection.connection) {
        connMapped = {
          ...connection.connection,
          relationComplete: connection.relation,
        }
      }

      if (filterUserConn(connMapped)) {
        if (role !== 'SEEK') allSystem.push(connMapped);
        else if (connMapped.mutualClubs !== 0) allSystem.push(connMapped);

        if (connMapped.relationComplete === 'FRIEND') friends.push(connMapped);
        if (connMapped.relationComplete === 'REQUESTED') requests.push(connMapped);
        if (connMapped.relationComplete === 'PENDING') pending.push(connMapped);
      }
    })

    setMyFriends(friends);
    setMyFriendRequests(pending);
    setMySentRequests(requests);
    setAllSystemUsers(allSystem);
  }

  useEffect(() => {
    mapConnectionOrg();
    mapConnectionUsers();

    if (
      (!searchName || searchName === '') && 
      (!searchRole || searchRole === 'ALL') 
      && !notHealers && (!orgId || orgId === 'ALL')) {
      setAllFilteredUsers(
        sortAlphabeticallyOnProperty(role !== 'SUPER' ? (usersOrg || []) : (users || []), 'firstname') as IUserResponse[]
      );
      return;
    }

    setAllFilteredUsers(
      (sortAlphabeticallyOnProperty(role !== 'SUPER' ? (usersOrg || []) : (users || []), 'firstname') as IUserResponse[])
        .filter(filterUser));

  }, [searchName, users, usersOrg, connections, searchRole, notHealers, searchStatus, orgId, from]);

  useEffect(() => reloadList(), []);

  return {
    reloadList,
    allFilteredUsers: withSuper ? allFilteredUsers : allFilteredUsers.filter(u => !(u?.roles ||[]).includes('SUPER')),
    allSystemUsers,
    allOrgUsers,
    myFriends,
    myFriendRequests,
    mySentRequests,
  }
};

export default useUsersList;