import { loadStripe } from "@stripe/stripe-js";
import { IUserGradeResponse } from "@vibe/sdk/dist/interfaces/Admin/User";
import IPaymentInfo from "../interfaces/user/IPaymentINfo";

export const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? ''
)

export const getPaymentInfo = (user: IUserGradeResponse) => {
    const paymentInfo: IPaymentInfo = {
        hasActiveSubscription: false,
    }

    if(user.stripeId) paymentInfo.stripeId = user.stripeId;
    if(user.stripeSubscriptionId) paymentInfo.stripeSubscriptionId = user.stripeSubscriptionId;
    if(user.subscriptionEndDate){
        paymentInfo.subscriptionEndDate = user.subscriptionEndDate;

        const now = new Date();
        const end = new Date(parseInt(user.subscriptionEndDate));

        console.log('PAYMENT', now, end)

        if(end >= now) paymentInfo.hasActiveSubscription = true;
    }

    return paymentInfo;
}