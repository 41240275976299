import styled from "styled-components";
import { TitleHome } from "../SearchHome/styles";

export const ContactHomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 24px 10px;
    width: 1150px;
    height: calc(100vh - 120px); // Header
    overflow: scroll;
    
    @media only screen and (max-width: 500px) {
        padding: 24px 16px;
        gap: 0;
    }
`;

export const SubtitleHome = styled(TitleHome)`
margin-top: 10px;
    font-size: 36px;
    @media only screen and (max-width: 770px) {
        font-size: 26px;
    }
`;

export const ContactHomeForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    //padding: 0px 200px;

    color: black;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px;
    letter-spacing: 0.2px;
    
    @media only screen and (max-width: 500px) {
        padding: 24px 0px;
    }
`;

export const InputWrapContactHome = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
`;

export const InputLabelContactHome = styled.div`
    display: flex;
    gap: 5px;
    align-items: flex-start;
`;

export const Row2ContactHome = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
`;

export const ImpContactHome = styled.p`
    color: #FF4D82;
`

export const InputContactHome = styled.input`
    width: 100%;
    outline: none;
    color: black;
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid var(--simple-gray);
    background: var(--background-default);
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px;
    letter-spacing: 0.2px;
    height: 40px;

    &placeholder {
        color: var(--placeholder-default);
    }
`;

export const WrapperSelectContactHome = styled.div`
    height: 40px;
`;

export const ContainerText = styled.div`
    background-color: #FCF6EF;
    padding: 10px;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 6px;
    border: 5px solid white;

    &::before {
        z-index: -1;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: var(--default-menu);
        border-radius: 10px;
        left: 13px;
        bottom: -13px;
    }
`

export const TitleContactUs = styled.div`

  font-size: 32px;
  font-weight: 700;
  font-family: 'Source Serif 4', serif;
  color: var(--default-menu);
  text-align: center;
  line-height: normal;

  @media only screen and (max-width: 770px) {
    font-size: 29px;
  }
`;

export const SubtitleContactUs = styled.div`
    font-size: 30px;
    font-weight: 500;
    font-family: 'Source Serif 4', serif;
    color: var(--default-menu);
    text-align: center;
    line-height: normal;
    @media only screen and (max-width: 770px) {
        font-size: 26px;
    }
`;

export const ContainerSubText = styled.div`
    color: #1A1A1A;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`

export const RowButton = styled.div`
    display: flex;
    justify-content: end;

    & button{
        width: auto;
        background-color: var(--orange-home);
    }
`