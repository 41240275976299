import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/esm/Modal";
import styled from "styled-components";
import ErrorField from "../../../BaseComponents/ErrorField";
import { BaseFieldState, getFieldState } from "../../../BaseComponents/utils";
import { useContext, useState } from "react";
import { Imp } from "../../../signupsetuphealerind";
import ButtonSpinner from "../../../BaseComponents/ButtonSpinner";
import { ContextSuccess } from "../../../../Context";
import { onHideType } from "../../../BaseComponents/Modals/ModalUnsaved";
import { getDataSDK, getDataSDKResponse } from "../../../../helpers/backendHelper";
import { useTranslation } from "react-i18next";
import SelectorCountry from "../../../BaseComponents/Location/SelectorCountry";
import { CountryOption } from "../../../../hooks/Locations/useActiveCountry";

const CreateListIntModal = ({
    show,
    setShow,
    reloadList,
}: {
    show: boolean
    setShow: (data: boolean) => void
    reloadList: () => void
}) => {
    const { t } = useTranslation();
    const currentSuccess = useContext(ContextSuccess);

    const [name, setName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [searchCountry, setSearchCountry] = useState('');
    const [degreeCountry, setDegreeCountry] = useState<BaseFieldState<CountryOption>>(getFieldState({ label: '', value: '' }));
    const [loading, setLoading] = useState<boolean>(false);

    const cleanForm = () => {
        setName(getFieldState(''));
        setDegreeCountry(getFieldState({ label: '', value: '' }));
    }

    const errorsInForm = () => {
        let errors = false;

        if (!name.value || name.value.length <= 0) {
            errors = true;
            setName({
                ...name,
                showError: true,
                error: 'Name is required',
            })
        } else {
            setName(getFieldState(name.value));
        }

        if (!degreeCountry.value || degreeCountry.value.value.length === 0) {
            errors = true;
            setDegreeCountry({
                ...degreeCountry,
                showError: true,
                error: 'Country is required'
            })
        } else {
            setDegreeCountry({
                ...degreeCountry,
                showError: false,
                error: ''
            })
        }

        return errors;
    }

    const onHide = (type: onHideType) => {
        switch (type) {
            case 'save':
                save();
                currentSuccess?.setModalsOff();
                break;
            case 'close':
                currentSuccess?.setModalsOff();
                break;
            case 'discard':
                cleanForm();
                currentSuccess?.setModalsOff();
                setShow(false);
                break;
            default:
                break;
        }
    }

    const discard = () => {
        if (name.value === '') {
            setShow(false);
            return;
        }
        currentSuccess?.setModalWarning('UNSAVED', onHide, 'Do you want to discard changes?')
    }

    const save = () => {
        if (errorsInForm()) return;
        setLoading(true);
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Connections.createINTList({ 
                    name: name.value || '' ,
                    type: 'CSP',
                    countries: [degreeCountry.value?.value || 'USA'],
                }, dataSDK.token);
            })
            .then((response) => {
                if (response.statusCode === 201) {
                    reloadList();
                    currentSuccess?.setSuccessData(true, true, t('toast_listCreated'));
                    cleanForm();
                    setShow(false);
                    return;
                }
                currentSuccess?.setSuccessData(false, true, t('toast_errorAnError'));
            })
            .finally(() => setLoading(false))
    }

    return (
        <Modal show={show} onHide={discard}>
            <Modal.Header>
                <Modal.Title>
                    <Content>Create New List of Interests</Content>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Label>Name<Imp>*</Imp></Label>
                    <Input
                        value={name.value}
                        onChange={(e) => setName({ ...name, value: e.currentTarget.value })}
                    />
                    {(name.showError) && (<ErrorField error={name.error} />)}
                </Row>
                <Row>
                    <Label>Country<Imp>*</Imp></Label>
                    <SelectorCountry
                        country={degreeCountry}
                        setCountry={setDegreeCountry}
                        searchCountry={searchCountry}
                        setSearchCountry={setSearchCountry}
                    />
                    {(degreeCountry.showError) && (<ErrorField error={degreeCountry.error} />)}
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <WrapButtons>
                    <Button onClick={discard}>Cancel</Button>
                    <WrapSpinner>
                        <ButtonSpinner
                            loading={loading}
                            handleClick={save}
                            text={'Create'}
                            hasIcon={false}
                        />
                    </WrapSpinner>
                </WrapButtons>
            </Modal.Footer>
        </Modal>
    )
}

const WrapSpinner = styled.div`
    width: 75%;
`;

const WrapButtons = styled.span`
    width: 100%;
    display: flex;
    gap: 15px;
`;

const Content = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  text-align: left;
  color: var(--simple-blue-dark);
  margin-top: 30px;
  margin-left: 10px;
`;

const Label = styled.div`    
    width: 100%;
    text-align: left;
    margin: 3px 0;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`;

const Row = styled.div`
    width: '100%';
    display: flex;
    flex-direction: column;
`;

const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
    margin-bottom: 10px;
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 200px;
  min-width: 100px;
  height: 100%;
  padding: 10px 20px;
  border-radius: 11px;
  background-color: white;
  border: 1px solid var(--t-blue);

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: var(--t-blue);
  gap: 10px;

  cursor: pointer;
`;

export default CreateListIntModal;