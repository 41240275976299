import styled from "styled-components";

export const ContainerCarrouselLeft = styled.div`
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
  gap: ${({gap = "16px"}:{ gap?: string}) => gap};

  // & > div > div {
  //   width: 100%;
  // }
  // & > div {
  //   width: 100%;
  // }

`;

export const ScrollCarrouselLeft = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
  height: fit-content;
  min-height: 50px; //Height of the button
  overflow-y: hidden;
  overflow-x: scroll;
  display: flex;
  width: ${({width}:{width: string}) => width};
  position: relative;
  justify-content: space-between;

  @media only screen and (max-width: 770px) {
    width: 100%;

  }

  &.CntTopPractitioners ${ContainerCarrouselLeft},
  &.CntTopPractitioners ${ContainerCarrouselLeft} > div,
  &.CntTopPractitioners ${ContainerCarrouselLeft} > div > div,
  &.CntTopHealers ${ContainerCarrouselLeft},
  &.CntTopHealers ${ContainerCarrouselLeft} > div,
  &.CntTopHealers ${ContainerCarrouselLeft} > div > div{
    max-width: 315px;
  }
`

export const ClickerControlCarrouselLeft = styled.div`
  cursor: pointer;
  display: flex;
  height: 48px;
  width: 48px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 24px);
  right: 0;
  position: sticky;

  border-radius: 50%;
  border: 1px solid var(--border-subtle-neutral);
  background: var(--background-default);
  box-shadow: 0px 1px 2px 0px rgba(27, 36, 44, 0.12);
  -webkit-box-shadow: 0px 1px 2px 0px rgba(27, 36, 44, 0.12);
  -moz-box-shadow: 0px 1px 2px 0px rgba(27, 36, 44, 0.12);

  @media only screen and (max-width: 770px) {
    display: none;
    
  }
`;