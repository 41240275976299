import { faEyeSlash, faEye } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { RootState } from '../../store/store';
import { refreshTokens } from '../../store/actions/tokenActions';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { ContextSuccess } from '../../Context';
import { Loader, Spinner } from '../BaseComponents/Loader';
import ModalForgotPassword from '../ForgotPassword/ModalForgotPassword';
import PasswordToolTip from '../Website/PasswordToolTip';

const Password = () => {

  const dispatch = useDispatch();
  const currentSuccess = useContext(ContextSuccess);

  const [loading, setLoading] = useState<boolean>(false);

  const [currentPass, setCurrentPass] = useState<string>('')
  const [newPass, setNewPass] = useState<string>('')
  const [newPass2, setNewPass2] = useState<string>('')

  const [showPass1, setShowPass1] = useState<boolean>(false)
  const [showPass2, setShowPass2] = useState<boolean>(false)
  const [showPass3, setShowPass3] = useState<boolean>(false)
  const [showForgot, setShowForgot] = useState<boolean>(false)

  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);

  const username = useSelector((root: RootState) => root.getUser.userInfo?.username);
  const accessToken = useSelector((root: RootState) => root.getToken.accessToken);

  useEffect(() => {
    dispatch(refreshTokens())
  }, []);

  const cleanUp = () => {
    setCurrentPass('')
    setNewPass('')
    setNewPass2('')
    setShowPass1(false)
    setShowPass2(false)
    setShowPass3(false)
  }

  const handleChangePasswords = () => {
    if (newPass !== newPass2) {
      currentSuccess?.setSuccessData(false, true, "New passwords don't match");
      return;
    }

    setLoading(true);
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.AccessManager.changePassword(username ?? '', currentPass, newPass, accessToken ?? '', dataSDK.token);
      })
      .then((results) => {
        if (results.statusCode === 200) {
          currentSuccess?.setSuccessData(true, true, 'Password changed!');
          cleanUp();
        } else {
          console.log(results.body)
          currentSuccess?.setSuccessData(false, true, 'Error changing password', results.body?.message ?? '');
        }
      })
      .catch(e => {
        currentSuccess?.setSuccessData(false, true, 'Error changing password', e);
        console.log(e)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Container>
      <OneColumn>
        <Column>
          <Label>Current Password<Imp>*</Imp></Label>
          <InputIcon>
            <Input
              value={currentPass}
              type={showPass1 ? 'text' : 'password'}
              onChange={(e) => setCurrentPass(e.target.value)}
            />
            <StyledFontAwesomeIcon
              icon={showPass1 ? faEyeSlash : faEye}
              size='sm'
              onClick={() => setShowPass1(!showPass1)}
            />
          </InputIcon>
        </Column>
      </OneColumn>
      <OneColumn>
        <Column>
          <Label>New Password<Imp>*</Imp></Label>
          <InputIcon
            onClick={() => setShowPasswordTooltip(true)}
          >
            <Input
              value={newPass}
              type={showPass2 ? 'text' : 'password'}
              onChange={(e) => setNewPass(e.target.value)}
            />
            <StyledFontAwesomeIcon
              icon={showPass2 ? faEyeSlash : faEye}
              size='sm'
              onClick={() => setShowPass2(!showPass2)}
            />
          </InputIcon>
          {(showPasswordTooltip) && (<PasswordToolTip password={newPass} setShowModal={setShowPasswordTooltip} bottom />)}
        </Column>
      </OneColumn>
      <OneColumn>
        <Column>
          <Label>Repeat New Password<Imp>*</Imp></Label>
          <InputIcon>
            <Input
              value={newPass2}
              type={showPass3 ? 'text' : 'password'}
              onChange={(e) => setNewPass2(e.target.value)}
            />
            <StyledFontAwesomeIcon
              icon={showPass3 ? faEyeSlash : faEye}
              size='sm'
              onClick={() => setShowPass3(!showPass3)}
            />
          </InputIcon>
        </Column>
      </OneColumn>
      <OneColumn>
        <Column>
          <LabelSub onClick={() => setShowForgot(true)}>Forgot password</LabelSub>
        </Column>
      </OneColumn>
      <ContButtn>
        {loading ?
          <WrapperSpinner>
            <Loader />
          </WrapperSpinner> :
          <Button onClick={handleChangePasswords} color={currentPass.length && newPass.length && newPass2.length ? '#2e3031' : '#bbbabc'}>Save</Button>}
      </ContButtn>

      <ModalForgotPassword show={showForgot} setShowModal={setShowForgot} />
    </Container>
  )
}

const WrapperSpinner = styled.div`
  background-color: #2e3031;
  width: 165px;
  height: 40px;
  display: flex;
  justify-content: center;
  padding: 12px 0;
  border-radius: 8px;
  color: white;
  font-family: 'Inter-Bold';
`;

const LabelSub = styled.div`
  text-decoration: underline;
  margin: 7px 0;
  cursor: pointer;
`

const ContButtn = styled.div`
  display: flex;
  justify-content: flex-end;
`

interface ButtonProps {
  color: string;
}

const Button = styled.div`
  background-color: ${(props: ButtonProps) => props.color};
  width: 165px;
  height: 40px;
  display: flex;
  justify-content: center;
  padding: 12px 0;
  border-radius: 8px;
  color: white;
  font-family: 'Inter-Bold';
  cursor: pointer;
`

const OneColumn = styled.div``

export const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
    margin: 7px 0;

`

const InputIcon = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    border-radius: 9px;
    align-items: center;
    padding-right: 15px;
    border: 1px solid var(--simple-gray);
    height: 40px;
    
    & ${Input}{
        border: 0;
        height: 38px;
    } 
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: black;
    cursor: pointer;
    margin-left: 10px;
`;

const Imp = styled.p`
    color: red;
    margin-left: 5px;
`

const Column = styled.div`
  flex: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`
const Label = styled.div`
  display: flex;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 30px 0;
  height: 100vh;
  width: 400px;
`

export default Password