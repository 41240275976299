import React, { useEffect, useState, useContext, useMemo } from 'react';
import Header from "../../components/Header";
import { Outlet, useSearchParams, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import { ChatServiceInterface } from '../../interfaces/chat/ChatServiceInterface';
import styled from "styled-components";
import { ContextSuccess } from "../../Context";
import FeedLeftSectionInd from '../../components/FeedLeftSectionInd';
import FeedCenterSection from '../../components/FeedCenterSection';
import FeedRightSection from '../../components/FeedRightSection';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfoProfile } from '../../store/actions/userActions';
import { getClubs, getClubsImAdv, getMyClubs, getMyClubsExtended } from '../../store/actions/clubActions';
import { RootState } from '../../store/store';
import { IUserGradeResponse } from '@vibe/sdk/dist/interfaces/Admin/User';
import { getOrgProfile, getOrgHealers } from '../../store/actions/orgsActions';
import { getHealerServices } from '../../store/actions/modalityActions';
import { getPostsHealer, getPostsHealerFeatured, getPostsOrg, reloadPostsOrg } from '../../store/actions/postActions';
import { getEvents, getHealerIndEvents, getHealerOrgEvents } from '../../store/actions/eventActions';
import { Helmet } from 'react-helmet-async';
import { IOrganizationExtended } from '@vibe/sdk/interfaces/Admin/Organization';

const HealerProfile = ({
    ChatServiceInstance
}: {
    ChatServiceInstance?: ChatServiceInterface
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const currentSuccess = useContext(ContextSuccess);
    const [searchParams, setSearchParams] = useSearchParams();

    const { username } = useParams();

    const [activeNotification, setActiveNotification] = useState(false);
    const [tabActiveBack, setTabActiveBack] = useState('');
    const [seeAll, setSeeAll] = useState(false);
    const [name, setName] = useState<string>('');

    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
    const isLoggedIn = useSelector((state: RootState) => state.getUser.isLoggedIn);
    const userProfile: IUserGradeResponse = useSelector((state: RootState) => state.getUser.userProfile);
    const orgProfile: IOrganizationExtended = useSelector((state: RootState) => state.getOrgs.orgHealerProfile);
    const usernamep = useSelector((state: RootState) => state.getUser.userInfo?.username || '');


    const getCommAndOrg = () => {
        dispatch(getClubsImAdv(userProfile.username));

        if (userProfile.roles.includes('INDIVIDUAL')) {
            dispatch(getPostsHealer(userProfile.username));
            dispatch(getPostsHealerFeatured(userProfile.username));

            const orgIndex = userProfile.rolesByOrg.findIndex((roles) => roles.includes('ORGADMIN'))
            if (orgIndex !== -1) {
                dispatch(getOrgProfile(userProfile.organizations[orgIndex]));
                dispatch(getOrgHealers(userProfile.organizations[orgIndex]));
                dispatch(getHealerIndEvents(userProfile.username, usernamep));
            }
        }
        else {
            dispatch(getOrgProfile(userProfile.organizations[0]));
            dispatch(getOrgHealers(userProfile.organizations[0]));
            dispatch(reloadPostsOrg(userProfile.organizations[0]));
            dispatch(getPostsHealerFeatured(userProfile.username));
            dispatch(getHealerOrgEvents(userProfile.organizations[0], usernamep));
        }
    }

    useEffect(() => {
        const logged = searchParams.get('signupLogged');
        const notification = searchParams.get('notifications');
        if (logged) {
            currentSuccess?.setSuccessData(false, true, t('toast_pleaseLogout'));
            navigate('/app');
        }
        if (notification) {
            setActiveNotification(true);
        }

        if (username) {
            dispatch(getUserInfoProfile(username || ''));
            dispatch(getHealerServices(username || ''));
        }

        if (isLoggedIn) {
            dispatch(getMyClubs(userInfo?.username || ''));
        }
    }, [])

    useEffect(() => {
        dispatch(getUserInfoProfile(username || ''));
        dispatch(getHealerServices(username || ''));
        if (isLoggedIn) {
            dispatch(getMyClubs(userInfo?.username || ''));
        }
    }, [username])

    useEffect(() => {
        if (userProfile) {
            getCommAndOrg();

            if (userProfile.roles.includes('INDIVIDUAL')) {
                setName(`${userProfile.firstname} ${userProfile.lastname}`);
            }
            else{
                setName(orgProfile.name);
            }
        }
    }, [userProfile])

    useEffect(() => {
        if (orgProfile && userProfile.organizations.length > 0) {
            const healerOrg = userProfile.rolesByOrg.findIndex(roleList => roleList.includes('ORGADMIN'));
            dispatch(getClubs({
                club_type: 'CLUB',
                pending: (orgProfile.status || '').startsWith('PE'),
                deleted: false,
                org_id: healerOrg ? userProfile.organizations[healerOrg] : userProfile.organizations[0],
            }));
        }
        dispatch(getMyClubsExtended(userProfile.username));
    }, [orgProfile, userProfile])

    return (
        <PrincipalContainer>

            <Helmet>
                <title>{name} - Vibe</title>
                <meta property="og:title" content={`${name} - Vibe`} />
                <meta property="og:description" content={`Please connect with ${name} on Vibe`} />
                <meta property="twitter:title" content={`${name} - Vibe`} />
                <meta property="twitter:description" content={`Please connect with ${name} on Vibe`} />
                <meta property="title" content={`${name} - Vibe`} />
                <meta property="description" content={`Please connect with ${name} on Vibe`} />
            </Helmet>

            <Header
                handleActiveNotification={setActiveNotification}
                ChatServiceInstance={ChatServiceInstance}
                isHealerProfile={true}
            />
            <ContainerFeedHealer>
                <ContainerFeed>
                    <FeedLeftSectionInd tabActiveBack={tabActiveBack} seeAllSection={seeAll} />
                    <FeedCenterSection tabActiveBack={setTabActiveBack} seeAllSection={setSeeAll} />
                    <FeedRightSection showInfo={false} />
                </ContainerFeed>
            </ContainerFeedHealer>

        </PrincipalContainer>
    );
}

const ContainerFeedHealer = styled.div`
    width: 100vw;
    padding: 0px 136px;

    @media only screen and (max-width: 900px) {
        padding: 0px;
    }
`

const SubHeader = styled.div`
    margin-top: 80px;
    height: 72px;
    border-bottom: 1px solid var(--border-gray);
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 25px;
    background-color: var(--white);
`

const ContainerFeed = styled.div`
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    gap: 15px;
    overflow: hidden;
    margin-top: 80px;

    @media only screen and (max-width: 900px) {
        flex-direction: column;
        width: 100%;
        height: auto;
        padding: 0px;
        margin: 0px;
    }
`

const PrincipalContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;

    a {
        display: contents;
        text-decoration: none;
    }
`;

export default HealerProfile;