import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import ContentPayment from './ContentPayment';

function ModalPayInfo({
    show,
    onHide,
    content,
}: {
    show: boolean,
    onHide: () => void,
    content: string,
}) {
    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                className="modalInfoPayment"
            >
                <Modal.Header>
                    <Modal.Title>
                        {/* <FontAwesomeIcon
                            icon="circle-exclamation"
                            size='lg'
                            color={'#333'}
                        /> */}
                        <Content>{content || 'Needs an active subscription!'}</Content>
                    </Modal.Title>
                    <FontAwesomeIcon
                        icon='times'
                        size='xl'
                        color='black'
                        className="icon-times"
                        onClick={onHide}
                    />
                </Modal.Header>
                <Modal.Body>
                    <ContentPayment />
                </Modal.Body>
            </Modal>
        </>
    );
}

const Content = styled.span`
  font-family: 'Inter-bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: normal;
  text-align: left;
  color: #333;
`;

export default ModalPayInfo;