import { FromInspirationHome, InspirationContent } from "./styles";

export interface IInspiration {
    quote: string
    from: string
}

const CarrouselInspiration = ({
    inspiration,
    width,
}:{
    inspiration: IInspiration
    width: string
}) => {
    return(
        <InspirationContent width={width}>
            "{inspiration.quote}"
            <FromInspirationHome>- {inspiration.from} -</FromInspirationHome>
        </InspirationContent>
    )
}

export default CarrouselInspiration;