import { AnyAction, Reducer } from "redux";
import TClubsState from "../../interfaces/store/TClubsState";
import {
    GET_TCLUBS_ERROR,
    GET_TCLUBS_SUCCESS,
    GET_TCOMM_ERROR,
    GET_TCOMM_SUCCESS,
    GET_TTEAM_ERROR,
    GET_TTEAM_SUCCESS,
    LOGOUT_TCLUBS,
    START_GET_TCLUBS,
    START_GET_TCOMM,
    START_GET_TTEAM,
} from "../constants/TClubsConstants";
import TClub from "@vibe/sdk/dist/interfaces/Club/TClubs/TClub/TClub";

const initialState: TClubsState = {
    loadingTClubs: false,
    TClubsError: undefined,
    TClubs: [],
    TClubsTags: [],

    loadingTComm: false,
    TCommError: undefined,
    TComm: [],

    loadingTTeam: false,
    TTeamError: undefined,
    TTeam: [],
};

export const getTClubsReducer: Reducer<TClubsState> = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_GET_TCLUBS:
        return {
            ...state,
            loadingTClubs: true,
        }
    case GET_TCLUBS_SUCCESS:
        return {
            ...state,
            loadingTClubs: false,
            TClubs: action.payload,
            TClubsTags: ((action.payload as TClub[])
                .map(({tags}) => tags)
                .flat()
                .filter((tag, idx, list) => list.indexOf(tag) === idx) as string[])
        }
    case GET_TCLUBS_ERROR:
        return {
            ...state,
            loadingTClubs: false,
            TClubsError: action.payload,
        }


    case START_GET_TCOMM:
        return {
            ...state,
            loadingTComm: true,
        }
    case GET_TCOMM_SUCCESS:
        return {
            ...state,
            loadingTComm: false,
            TComm: action.payload,
        }
    case GET_TCOMM_ERROR:
        return {
            ...state,
            loadingTComm: false,
            TCommError: action.payload,
        }


    case START_GET_TTEAM:
        return {
            ...state,
            loadingTTeam: true,
        }
    case GET_TTEAM_SUCCESS:
        return {
            ...state,
            loadingTTeam: false,
            TTeam: action.payload,
        }
    case GET_TTEAM_ERROR:
        return {
            ...state,
            loadingTTeam: false,
            TTeamError: action.payload,
        }
    

    case LOGOUT_TCLUBS:
      return initialState;

    default:
      return state;
  }
};
