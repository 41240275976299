import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import './styles.css'
import { RootState } from "../../store/store";
import { IClubExtended } from "@vibe/sdk/interfaces/Club/ClubInterface";

function DropdownReachPost({ postType, setPostType }: { postType: string, setPostType: any }) {

  const { t, i18n } = useTranslation();

  const userRoles = useSelector((state: RootState) => state.getUser.userRoles);
  const listClubsExtended: IClubExtended[] = useSelector((state: RootState) => state.getClub.getMyClubsExtended || []);
  const clubsAdvisor: IClubExtended[] = useSelector((state: RootState) => state.getClub.clubsImAdv);
  const listClubsDigest = useSelector((state: RootState) => state.getClub.getMyClubs || []);

  const [availableClubs, setAvailableClubs] = useState<IClubExtended[]>([]);

  useEffect(() => {
    if (userRoles.includes('SUPER')) {
      setPostType('ALL')
    }
  }, [userRoles])

  useEffect(() => {
    const available: IClubExtended[] = clubsAdvisor;
    const availableIDs: string[] = clubsAdvisor.map(ca => ca.id);

    listClubsExtended.forEach(c => {
      if (c.membersCanPost || c.followersCanPost) {
        const clubDigest = listClubsDigest.find(digest => digest?.club_id === c.id);

        if (
          clubDigest &&
          (
            (c.membersCanPost && (clubDigest?.roles || []).includes('MEMBER')) ||
            (c.followersCanPost && (clubDigest?.roles || []).includes('FOLLOWER'))
          ) &&
          !availableIDs.includes(c.id)
        ) {
          available.push(c);
          availableIDs.push(c.id);
        }
      }
    })

    setAvailableClubs(available);
  }, [listClubsExtended, listClubsDigest, clubsAdvisor])

  return (
    <>
      {(!userRoles.includes('SUPER')) && (
        <div className="reachPost-container">
          <select
            name=""
            id=""
            value={postType}
            onChange={(event) => setPostType(event.target.value)}
          >
            <option value="" disabled>
              {t('label_selectWherPost')}
            </option>

            {(!userRoles.includes('SUPER')) && (
              <option value="WALL">My Wall</option>
            )}

            {userRoles.includes('ORGADMIN') && (
              <option value="ORG">{'Organization Bulletin'}</option>
            )}

            {availableClubs?.length > 0 &&
              availableClubs.map((club: IClubExtended) => (
                <option value={club.id}>{`${club.name} - ${club.orgName}`}</option>
              ))}

          </select>
        </div>)}
    </>
  );
}

export default DropdownReachPost;