import "./styles.css";
import { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { deleteInviteAdmin, resentInviteAdmin } from "../../store/actions/inviteActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GRAY, LAVENDER } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { BROWN } from "../SuperCenter/Modalities/Modals/components";
import { ClubCenterWrap } from "../ClubViewInformation";
import styled from "styled-components";
import SearchInput from "../BaseComponents/SearchInput";
import BlankTable from "../BaseComponents/BlankTable";
import { capitalize } from "../../helpers/letterHelper";
import { ContextSuccess } from "../../Context";

function Index(props: any) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const currentSuccess = useContext(ContextSuccess);

    const [listInvites, setListInvites] = useState([])
    const [listChange, setListChange] = useState(false);
    const [valueFilter, setValueFilter] = useState("");
    const [idLoader, setIdLoader] = useState<string>('');

    useEffect(() => {
        if (props.infoClub && props.infoClub.org_id && props.infoClub.id && props.infoClub.status) {
            Backend(
                MicroServices.Invite,
                `/invites?pending=${(props.infoClub.status === 'PENDING') ? true : false}&club_id=${props.infoClub.id}&org_id=${props.infoClub.org_id}&light=true`)
                .then((response) => response.json())
                .then((results) => {
                    const openInvites = results.filter((invite: any) => (props.infoClub.status === 'PENDING') ? invite.status !== 'USED' : (invite.status !== 'USED' && invite.status !== 'NEW'))
                    setListInvites(openInvites);
                })
        }

        if (props.invitesAdded) {
            props.setInvitesAdded(false);
        }

    }, [props.infoClub, listChange, props.invitesAdded])

    const deleteOpenInvite = (inv_id: string) => {
        dispatch<any>(deleteInviteAdmin(inv_id))
            .then((results: string) => {
                if (results === 'true') {
                    setListChange(!listChange)
                    currentSuccess?.setSuccessData(true, true, 'Invite deleted');
                    return
                }
                currentSuccess?.setSuccessData(false, true, 'Error deleting invite', results);
            });
    }

    const formatStatus = (status: string) => {
        if(status === 'NEW') return 'Pending Approval'; // Org is not approved yet
        return capitalize(status);
    }

    const formatId = (invite: any) => {
        return (invite.role === "SECOND#ADVISOR" || invite.role === "STAFF#ADVISOR") ?
            `Advisor#${invite.club_id}#${invite.email}` : `End_User#${invite.club_id}#${invite.email}`;
    }

    const resentInvite = (inv_id: string) => {
        setIdLoader(inv_id)
        dispatch<any>(resentInviteAdmin(inv_id))
            .then((results: string) => {
                if (results === 'true') {
                    setListChange(!listChange)
                    currentSuccess?.setSuccessData(true, true, 'Invite resent');
                    setIdLoader('')
                    return
                }
                currentSuccess?.setSuccessData(false, true, 'Error resending invite', results);
            });
    };

    return (
        <ClubCenterInvites>
            <Row>
                <TitleMembers>{listInvites.length} Open Invites</TitleMembers>
                <SearchInput
                    filteredItem={valueFilter}
                    setFilteredItem={setValueFilter}
                    backgroundColor={'var(--t-blue)'}
                    border={'0px'}
                    placeholder={`Search by name`}
                />
            </Row>

            <Scroll>
                <BlankTable
                    headers={['Name', 'User Status', 'Invite Status']}
                    items={listInvites.filter((item: any) => {
                        if (!valueFilter) return true;
                        if (item.firstName.toUpperCase().includes(valueFilter) ||
                            item.firstName.toLowerCase().includes(valueFilter) ||
                            item.lastName.toUpperCase().includes(valueFilter) ||
                            item.lastName.toLowerCase().includes(valueFilter) ||
                            item.firstName.includes(valueFilter) ||
                            item.lastName.includes(valueFilter)
                        ) {
                            return true;
                        }
                        return false;
                    }).map((adv: any) => ({
                        id: adv.SK,
                        labels: [`${adv.firstName} ${adv.lastName}`, 'Non Registered User', formatStatus(adv.status)]
                    }))}
                    idLoader={idLoader}
                    maxWidth={'230px'}
                    actions={['RESENT', 'DELETE']}
                    actionsHandlers={{
                        handleDelete: deleteOpenInvite,
                        handleResent: resentInvite,
                    }}
                />
            </Scroll>
        </ClubCenterInvites>
    );
}

export default Index;

const Scroll = styled.div`
    display: flex;
    width: 100%;
    overflow-y: scroll;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 50% calc(50% - 10px);
  grid-template-rows: 40px;
  grid-column-gap: 10px;
  width: 100%;
  gap: 10px;
`;

const TitleMembers = styled.div`
  font-family: 'Inter-bold';
  font-size: 22px;
  text-align: left;
  color: var(--t-blue);
`;

const ClubCenterInvites = styled(ClubCenterWrap)`
    overflow: hidden;
`