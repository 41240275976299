import { AnyAction } from "@reduxjs/toolkit";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import {
  GET_INVITES_LIMIT_ERROR,
  GET_INVITES_LIMIT_SUCCESS,
  INVITE_TYPE_ERROR,
  INVITE_TYPE_SUCCESS,
  START_GET_INVITES_LIMIT,
  START_INVITE_TYPE,
  INVITE_BY_TOKEN_SUCCESS,
  START_GET_INVITE_BY_TOKEN,
  INVITE_BY_TOKEN_ERROR,
  CREATE_USER_BY_INVITE_SUCCESS,
  START_CREATE_USER_BY_INVITE,
  CREATE_USER_BY_INVITE_ERROR,
  START_GET_ORG_INVITES,
  GET_ORG_INVITES_SUCCESS,
  GET_ORG_INVITES_ERROR,
  DELETE_INVITE_ERROR,
  DELETE_INVITE_SUCCESS,
  START_DELETE_INVITE,
  RESEND_INVITE_ERROR,
  RESEND_INVITE_SUCCESS,
  START_RESEND_INVITE,
  START_VALIDATE_INVITE_ROSTER,
  VALIDATE_INVITE_ROSTER_ERROR,
  VALIDATE_INVITE_ROSTER_SUCCESS,
  START_CREATE_INVITE_ROSTER,
  CREATE_INVITE_ROSTER_ERROR,
  CREATE_INVITE_ROSTER_SUCCESS,
  CREATE_INVITE_SUCCESS,
  START_CREATE_INVITE,
  CREATE_INVITE_ERROR,
  START_GET_COMM_INVITES,
  GET_COMM_INVITES_SUCCESS,
  GET_COMM_INVITES_ERROR,
  START_GET_INVITES_SETUP,
  GET_INVITES_SETUP_ERROR,
  GET_INVITES_SETUP_SUCCESS,
  START_ACCEPT_INVITE,
  ACCEPT_INVITE_SUCCESS,
  ACCEPT_INVITE_ERROR,
} from "../constants/inviteConstants";
import getInviteParams from '../../interfaces/invite/getInviteParams';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backend, MicroServices, getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import i18n from '../../i18n';
import { userInfo } from "os";
import IResponseError from "@vibe/sdk/dist/interfaces/IResponseError";
import { ISignUpUserBody, ISignUpUserQuery } from "@vibe/sdk/dist/interfaces/AccessManager/signUpUser";
import { IResendInviteBody } from "@vibe/sdk/dist/interfaces/AccessManager/resendInvite";
import { IGetInvitesRequest, IGetInvitesResponse } from "@vibe/sdk/dist/interfaces/Invite/getInvites";
import { SetupInvites } from "../../interfaces/store/inviteState";
import { reloadSession } from "./userActions";
import { refreshTokens } from "./tokenActions";

toast.configure()

export const getInvitesWithLimit = ({
  page = 0,
  id = 'ASC',
  email = '',
  club = '',
  inviteToken = '',
  status = '',
  type = '',
  roleRequest = '',
  lastEvaluatedKey = '',
  limit = 10000
}: getInviteParams): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    let params = '';
    params += page === 0 ? '' : `&lastEvaluatedKey=${lastEvaluatedKey}`;
    params += status === '' ? '' : `&status=${status}`;
    params += type === '' ? '' : `&type=${type}`;
    dispatch({ type: START_GET_INVITES_LIMIT });
    Backend(
      MicroServices.Admin,
      `/invites?limit=${limit}${params}`
    )
      .then((response) => response.json())
      .then((results) => {
        console.log(results.lastEvaluatedKey);
        dispatch({
          type: GET_INVITES_LIMIT_SUCCESS,
          payload: {
            invites: results.invites,
            lastEvaluatedKey: results.lastEvaluatedKey,
            page,
          },
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_INVITES_LIMIT_ERROR,
          payload: error,
        });
      })
  });

export const getOrganizationType = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_INVITE_TYPE });
    Backend(
      MicroServices.Roster,
      `/invites/types`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: INVITE_TYPE_SUCCESS,
          payload: results.orgStatus,
        })
      })
      .catch((error) => {
        dispatch({
          type: INVITE_TYPE_ERROR,
          payload: error,
        });
      })
  });

export const getInvitesByToken = ({
  token = ''
}: getInviteParams): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_INVITE_BY_TOKEN });

    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
          return dataSDK.sdk.Invite.getInviteByToken(token, dataSDK.token);
      })
      .then((results) => {
        if(results.statusCode === 200){
          dispatch({
            type: INVITE_BY_TOKEN_SUCCESS,
            payload: results.body,
          })
        }
        else{
          dispatch({
            type: INVITE_BY_TOKEN_ERROR,
            payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: INVITE_BY_TOKEN_ERROR,
          payload: error,
        });
      })
  });

export const createUserByInvite = (token: string, form: ISignUpUserBody): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_CREATE_USER_BY_INVITE });
    const {t} = i18n;

    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
          return dataSDK.sdk.AccessManager.signUpUser(form, {
            inv_token: token,
        }, dataSDK.token);
      })
      .then((response) => {
        if (response.statusCode === 201) {
          dispatch({
            type: CREATE_USER_BY_INVITE_SUCCESS,
            payload: true,
          })
          resolve(true);
        }
        else{
          dispatch({
            type: CREATE_USER_BY_INVITE_ERROR,
            payload: (response.body as IResponseError).error || (response.body as IResponseError).message,
          });
          resolve(false);
        }
      })
      .catch((error) => {
        dispatch({
          type: CREATE_USER_BY_INVITE_ERROR,
          payload: error,
        });
        reject(error)
      })
  });

export const createGeneralUserByInvite = (token: string, form: any): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_CREATE_USER_BY_INVITE });
    const {t} = i18n;
    Backend(
      MicroServices.AccessManager,
      `/auth/sign-up-user?inv_token=${token}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user: {
            givenName: form.givenName,
            familyName: form.familyName,
            gender: form.gender,
            email: form.email,
            userName: form.userName,
            zipcode: form.zipcode,
            organizationsNames: [form.organizationsNames],
            organizations: [form.organizations],
            roles: [[form.roles]],
            password: form.password,
            phoneNumber: form?.phoneNumber || 0,
            preferredLanguage: form?.preferredLanguage || '',
            location: form?.location || '',
            city: form?.city || '',
            country: form?.country || '',
            birthdate: form.birthdate,
            clubs: form.clubs,
            clubsAdvisor: [],
            clubsFollower: [],
            countryCode: form.countryCode
          }
        }),
      }
    )
      .then((response) => {
        if (response.status === 201) {
          dispatch({
            type: CREATE_USER_BY_INVITE_SUCCESS,
            payload: true,
          })
          resolve(true);
          return;
        }
        else if (response.status === 400) {
          return response.json();
        }
        else {
          //toast.error(t('toast_ThereWasErrorTry'));
          console.log(response);
        }
        return Promise.reject()
      })
      .then((results) => {

        console.log(results);

        if (results) {
          const error = results.error?.message || '';

          if (error === '') {
            //toast.error(t('toast_errorCheckData'));
          }
          else {
            //toast.error(`${t('toast_ThereWasError')} ${error}`);
          }

          return Promise.reject();
        }

      })
      .catch((error) => {
        dispatch({
          type: CREATE_USER_BY_INVITE_ERROR,
          payload: error,
        });
        reject(error)
      })
  });

export const getOrgInvites = (orgId: string, pending: boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_ORG_INVITES });
    Backend(
      MicroServices.Invite,
      `/invites?pending=${pending}&org_id=${orgId}`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_ORG_INVITES_SUCCESS,
          payload: results,
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_ORG_INVITES_ERROR,
          payload: error,
        });
      })
  });

export const getEmailsSetup = (orgId: string, pending: boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_INVITES_SETUP });

    const request: IGetInvitesRequest = {
      pending: pending? 'true' : 'false',
      org_id: orgId,
      light: true,
    }

    getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
            return dataSDK.sdk.Invite.getInvites(request,dataSDK.token);
        })
      .then((results) => {
        if(results.statusCode === 200){
          const emails: string[] = [];
          const users: SetupInvites[] = [];

          (results.body as IGetInvitesResponse[]).forEach((i) => {
            if(!emails.includes(i.email)){
              emails.push(i.email);
              users.push({
                firstName: i.firstName || '',
                lastName: i.lastName || '',
                email: i.email,
              });
            }
          })
          dispatch({
            type: GET_INVITES_SETUP_SUCCESS,
            payload: users,
          })
        }
        else{
          dispatch({
            type: GET_INVITES_SETUP_ERROR,
            payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_INVITES_SETUP_ERROR,
          payload: error,
        });
      })
  });

export const deleteInviteAdmin = (invite_id: string): ThunkAction<Promise<string>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_DELETE_INVITE });
    getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
            return dataSDK.sdk.Invite.deleteInvite(encodeURIComponent(invite_id),dataSDK.token);
        })
      .then((results) => {
        if (results.statusCode !== 204) {
          resolve((results.body as IResponseError).error || (results.body as IResponseError).message)
          dispatch({
            type: DELETE_INVITE_ERROR,
            payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
          });
          return;
        }
        dispatch({
          type: DELETE_INVITE_SUCCESS,
        });
        resolve('true');
      })
      .catch((error) => {
        dispatch({
          type: DELETE_INVITE_ERROR,
          payload: error,
        });
      })
  });

export const resentInviteAdmin = (invite_id: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_RESEND_INVITE });
    const {t} = i18n;
    const toSend: IResendInviteBody= {
      SK: invite_id
    }

    getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
            return dataSDK.sdk.AccessManager.resendInvite(toSend,dataSDK.token);
        })
      .then((results) => {
        if (results.statusCode !== 200) {
          resolve((results.body as IResponseError).error || (results.body as IResponseError).message)
          dispatch({
            type: RESEND_INVITE_ERROR,
            payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
          });
          return;
        }
        dispatch({
          type: RESEND_INVITE_SUCCESS,
        });
        resolve('true');
      })
      .catch((error) => {
        dispatch({
          type: RESEND_INVITE_ERROR,
          payload: error,
        });
      })
  });

export const validateInviteRoster = (
  fileBase64: string,
  noStudentInvite = false,
  postalCodeLength = 5,
): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_VALIDATE_INVITE_ROSTER });

    let status = 0;

    Backend(
      MicroServices.Invite,
      `/invites/validate?staff=${noStudentInvite}&postalCodeLength=${postalCodeLength}`,
      {
        method: 'POST',
        body: JSON.stringify({
          file: fileBase64,
        })
      })
      .then((response) => {
        if (response.status === 200) {
          status = 200;
        } else if (response.status === 400) {
          status = 400;
        }
        return response.json();
      })
      .then((response) => {
        console.log(response);
        dispatch({
          type: VALIDATE_INVITE_ROSTER_SUCCESS,
          payload: response,
        });
        resolve(status);
      })
      .catch((error) => {
        dispatch({
          type: VALIDATE_INVITE_ROSTER_ERROR,
          payload: error,
        });
      })
  });

export const createInviteRoster = (
  fileBase64: string,
  club_id: string,
  org_id: string
): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_CREATE_INVITE_ROSTER });
    const {t} = i18n;
    Backend(
      MicroServices.Invite,
      `/invites/inviteRoster`, {
      method: 'POST',
      body: JSON.stringify({
        file: fileBase64,
        org_id: org_id,
        club_id: club_id,
        is_advisor: false,
      })
    })
      .then((response) => {
        dispatch({
          type: CREATE_INVITE_ROSTER_SUCCESS,
          payload: response,
        });
        if (response.status === 200) {
          resolve(200);
          return;
        } else {
          resolve(response.status);
          return;
        }
      })
      .catch((error) => {
        dispatch({
          type: CREATE_INVITE_ROSTER_ERROR,
          payload: error,
        });
        //toast.error(t('toast_errorSendInvites'));
      })
  });

interface InviteBody {
  email: string;
  org_id: string;
  club_id: string;
  firstName: string;
  lastName: string;
  is_advisor: boolean;
}

export const createInvite = (invite: InviteBody): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_CREATE_INVITE });
    Backend(
      MicroServices.Invite,
      `/invites`,
      {
        method: 'POST',
        body: JSON.stringify(invite)
      }
    )
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: CREATE_INVITE_SUCCESS,
          payload: results,
        })
        resolve(results);
      })
      .catch((error) => {
        dispatch({
          type: CREATE_INVITE_ERROR,
          payload: error,
        });
      })
  });

export const getCommunityInvites = (org_id:string,pending:boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_COMM_INVITES });
    Backend(
      MicroServices.Invite,
      `/invites/community?org_id=${org_id}&pending=${pending}`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_COMM_INVITES_SUCCESS,
          payload: results,
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_COMM_INVITES_ERROR,
          payload: error,
        });
      })
  });

  export interface IAcceptInvite {
    token: string;
    username: string;
  }

export const acceptInvite = ({
    token,
    username,
  }: IAcceptInvite): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
      dispatch({ type: START_ACCEPT_INVITE });
  
      getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
            return dataSDK.sdk.Invite.acceptInvite(token, dataSDK.token, undefined, username);
        })
        .then(async (results) => {
          if(results.statusCode === 201){
            await dispatch<any>(reloadSession());
            dispatch({
              type: ACCEPT_INVITE_SUCCESS,
            })
          }
          else{
            dispatch({
              type: ACCEPT_INVITE_ERROR,
              payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: ACCEPT_INVITE_ERROR,
            payload: error,
          });
        })
    });