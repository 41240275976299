import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemFilterHome, { ItemFilterHook, SelectedItemHome } from "../../../interfaces/HomeSearch/ItemFilterHome";
import { FilterItem, FilterPanelItem, FilterPanelItems, FiltersContainer } from "./styled";
import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { sortAlphabeticallyOnProperty } from "../../../containers/Signup/SignupHealerInd";

const FilterTip = ({
    filter,
    filtersActive,
    setFiltersActive,
    setSelectedOption,
    selectedOption,
}: {
    filter: ItemFilterHook
    filtersActive: number[]
    setFiltersActive: (data: number[]) => void
    setSelectedOption: (data: SelectedItemHome) => void
    selectedOption: SelectedItemHome
}) => {
    const [showOptions, setShowOptions] = useState<boolean>(false);

    const handleClickFilter = () => {
        if (!showOptions) {
            setShowOptions(true)
        }
    }

    useEffect(() => console.log('filter', filter), [filter])

    const filterIsActive = () => {
        return filtersActive.includes(filter.id);
    }

    const getLabel = () => {
        return filterIsActive() ? selectedOption.label : filter.label;
    }

    return (
        <FilterItem
            active={filterIsActive()}
            onClick={() => !showOptions && handleClickFilter()}
            key={filter.id}
        >
            <FontAwesomeIcon
                icon={filterIsActive() ? "check" : filter.icon}
                size="sm"
                color="var(--m-3-sys-light-on-surface-variant)"
            />
            {getLabel()}
            {filter.dropdown && <FontAwesomeIcon
                icon="caret-down"
                size="sm"
                color="var(--m-3-sys-light-on-surface-variant)"
            />}

            {showOptions &&
                <OutsideClickHandler onOutsideClick={() => setShowOptions(false)}>
                    <FilterPanelItems>
                        {sortAlphabeticallyOnProperty(filter.options, 'label').map((activeFilter, index) => (
                            <FilterPanelItem
                                onClick={() => {
                                    setShowOptions(false)
                                    if (filtersActive.includes(filter.id) && selectedOption.value === activeFilter.value) {
                                        setFiltersActive(filtersActive.filter(i => i !== filter.id));
                                        setSelectedOption({value: '', label: ''})
                                        return;
                                    }
                                    setSelectedOption(activeFilter)
                                    setFiltersActive([...filtersActive, filter.id]);
                                }}
                                key={index}
                            >
                                {activeFilter.label}
                            </FilterPanelItem>
                        ))}
                    </FilterPanelItems>
                </OutsideClickHandler>
            }
        </FilterItem>
    )
}

export default FilterTip;