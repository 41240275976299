import { useState } from 'react'
import styled from "styled-components";
import SignupHealerInd from "./SignupHealerInd";
import { useNavigate, useParams } from "react-router-dom";
import { useClubCode } from "../SignUpCode/hooks";
import SignupSeeker from "./SignupSeeker";
import { Container_Signup, ContainerForm_Signup, ContainerForm_Signup_Invite, ContainerInfo_Signup, Logo, SubtitleSignupWrappers, TitleSignupWrappers } from './WrapBusiness';

const WrapCode = () => {
    const navigate = useNavigate();
    const { code } = useParams();
    const codeInfo = useClubCode(code || "");
    const [emailSent, setEmailSent] = useState<boolean>(false)

    const getErrorMessage = (message: string) => {
        if (codeInfo.isExpired) {
            return (
                <TitleForm>This code is expired</TitleForm>
            );
        }
        if (codeInfo.isFull) {
            return (
                <TitleForm>This community is full</TitleForm>
            );
        }

        return (
            <>
                <TitleForm>Error getting community</TitleForm>
                <SubTitleForm>{message || ''}</SubTitleForm>
            </>
        )
    }

    const getSignupForm = () => {
        if (codeInfo.loadingClubInfo) return (
            <Form>
                <TitleForm>Loading community information...</TitleForm>
            </Form>
        );

        if (codeInfo.errorClubInfo) return (
            <Form>
                {getErrorMessage(codeInfo.errorClubInfo.message || '')}
            </Form>
        )

        if (codeInfo.clubInfo?.role_assigned === 'HEALER') return <SignupHealerInd emailSent={emailSent} setEmailSent={setEmailSent} codeInfo={codeInfo} />;
        
        return <SignupSeeker codeInfo={codeInfo} />
    }

    return (
        <Container_Signup>
            <ContainerForm_Signup>
                {getSignupForm()}
            </ContainerForm_Signup>
            <ContainerInfo_Signup>
                <TitleSignupWrappers>Congratulations!</TitleSignupWrappers>
                <SubtitleSignupWrappers>You've been invited to raise your vibration through Vibe.</SubtitleSignupWrappers>
                <SubtitleSignupWrappers>Please complete your registration to begin your journey.<br />Your personal information will not be shared with anyone, but online communities are more positive and safer when people register with their real identity.</SubtitleSignupWrappers>
            </ContainerInfo_Signup>
        </Container_Signup>
    )
}

const Form = styled.div`
   width: 100%;
   display: flex;
   flex-direction: column;
   @media only screen and (max-width: 500px) {

   }
   
`;

const TitleForm = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin: 25px 0 15px 0;
`;

const SubTitleForm = styled(TitleForm)`
    font-weight: 400;
    font-family: 'Inter';
    font-size: 22px;
    margin: 0px 0 15px 0;
`;

const Title = styled.h1`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 35px;
`;

const Subtitle = styled.h2`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    padding: 20px 0;
`;

const Text = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
`;

export default WrapCode;