import { UserConnection } from "@vibe/sdk/dist/interfaces/Roster/getUserConnections";

export default interface Connection {
    usernameConnection: string;
    avatar: string;
    firstname: string;
    lastname: string;
    classYear?: string;
    common: number;
    birthday?: string;
    friends: boolean;
    rolesConnection?: string[];
    requestFrom?: boolean;
    requestTo?: boolean;

    status?: string;
}

export const MockConnection: UserConnection = {
    role: [],
    avatar: '',
    name: '',
    mutualClubs: 0,
    username: '',
    relation: []
}