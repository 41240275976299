import { AnyAction } from "redux";
import { GeneralDashboard } from "@vibe/sdk/dist/interfaces/Dashboard/general";
import { GET_COMMTABLE_ERROR, GET_COMMTABLE_SUCCESS, GET_EVTABLE_ERROR, GET_EVTABLE_SUCCESS, GET_GENERALDASH_ERROR, GET_GENERALDASH_SUCCESS, GET_HSTABLE_ERROR, GET_HSTABLE_SUCCESS, LOGOUT_DASHBOARD, START_GET_COMMTABLE, START_GET_EVTABLE, START_GET_GENERALDASH, START_GET_HSTABLE } from "../constants/DashboardConstants";
import { CommunityTable, EventTable, ServiceTable } from "@vibe/sdk/dist/interfaces/Dashboard/tables";

interface DashboardState {
  loadingGeneral: boolean
  general?: GeneralDashboard
  generalError?: any

  loadingServicesTable: boolean
  servicesTable: ServiceTable[]
  serviceTableError?: any

  loadingClubsTable: boolean
  clubsTable: CommunityTable[]
  clubsTableError?: any

  loadingEventsTable: boolean
  eventsTable: EventTable[]
  eventsTableError?: any
}

const initialState: DashboardState = {
  loadingGeneral: false,
  general: undefined,
  generalError: undefined,

  loadingServicesTable: false,
  servicesTable: [],
  serviceTableError: undefined,

  loadingClubsTable: false,
  clubsTable: [],
  clubsTableError: undefined,

  loadingEventsTable: false,
  eventsTable: [],
  eventsTableError: undefined,
};

export const getDashboardReducer = (state = initialState, action: AnyAction): DashboardState => {
  switch (action.type) {
    case START_GET_GENERALDASH:
      return {
        ...state,
        loadingGeneral: true,
      }
    case GET_GENERALDASH_SUCCESS:
      return {
        ...state,
        loadingGeneral: false,
        general: action.payload,
      }
    case GET_GENERALDASH_ERROR:
      return {
        ...state,
        loadingGeneral: false,
        generalError: action.payload,
      }

    case START_GET_HSTABLE:
      return {
        ...state,
        loadingServicesTable: true,
      }
    case GET_HSTABLE_SUCCESS:
      return {
        ...state,
        loadingServicesTable: false,
        servicesTable: action.payload,
      }
    case GET_HSTABLE_ERROR:
      return {
        ...state,
        loadingServicesTable: false,
        serviceTableError: action.payload,
      }

    case START_GET_COMMTABLE:
      return {
        ...state,
        loadingClubsTable: true,
      }
    case GET_COMMTABLE_SUCCESS:
      return {
        ...state,
        loadingClubsTable: false,
        clubsTable: action.payload,
      }
    case GET_COMMTABLE_ERROR:
      return {
        ...state,
        loadingClubsTable: false,
        clubsTableError: action.payload,
      }

    case START_GET_EVTABLE:
      return {
        ...state,
        loadingEventsTable: true,
      }
    case GET_EVTABLE_SUCCESS:
      return {
        ...state,
        loadingEventsTable: false,
        eventsTable: action.payload,
      }
    case GET_EVTABLE_ERROR:
      return {
        ...state,
        loadingEventsTable: false,
        eventsTableError: action.payload,
      }

    case LOGOUT_DASHBOARD:
      return initialState

    default:
      return state;
  }
};
