import styled from "styled-components";
import {
    TitleContent, SubText, Imp,
    Row2, TextArea
} from "../../components/signupsetuphealerind"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faUserPen } from "@fortawesome/pro-thin-svg-icons";
import { ProcessInterface } from "../../containers/SetupHealer/SetupHealer";
import { IUpdateUserBody } from "@vibe/sdk/dist/interfaces/Admin/updateUser";
import { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { uploadImage } from "../../store/actions/orgsActions";
import { uploadAsset, updateUser, updateUserBio, getUser } from "../../store/actions/userActions";
import { RootState } from "../../store/store";
import ErrorField from "../BaseComponents/ErrorField";
import { LogoInterface, Scroll_Content_Signup } from "../signupsetuphealer/Introduction";
import AddProfilePhoto from "../BaseComponents/ProfilePhoto/AddProfilePhoto";
import TextEditor from "../BaseComponents/TextEditor";
import { BaseFieldState, getFieldState } from "../BaseComponents/utils";
import ModalImageEditorCrop from "../ModalImageEditorCrop";

const Introduction = ({
    processData,
}: {
    processData: ProcessInterface
}) => {

    const dispatch = useDispatch();

    const user = useSelector((state: RootState | any) => state.getUser.user);
    const userInfo = useSelector((state: any) => state.getUser.userInfo);

    const [newLogo, setNewLogo] = useState<boolean>(false);
    const [companyStatement, setCompanyStatement] = useState<BaseFieldState<string>>(getFieldState(''));
    const [logo, setLogo] = useState<BaseFieldState<LogoInterface>>(getFieldState({ file: '', fileLink: '' }));
    const [showModalImage, setShowModalImage] = useState(false);
    const [activeCrop, setActiveCrop] = useState(false);

    const checkProcessed = (): boolean => {
        let complete = true;

        if (!user.biography && companyStatement.value?.length === 0) {
            complete = false;
        }

        return complete;
    }

    const CheckDataStep = (): boolean => {
        let hasErrors = false;

        if (!companyStatement.value || companyStatement.value?.length === 0) {
            hasErrors = true;
            setCompanyStatement({
                ...companyStatement,
                showError: true,
                error: 'Company statement is required'
            })
        } else {
            setCompanyStatement({
                ...companyStatement,
                showError: false,
                error: ''
            })
        }

        return hasErrors;
    }

    const handleSaveImage = () => new Promise((resolve, reject) => {
        const dataFile = URL.createObjectURL(logo.value?.file as any);
        let contentType: any;
        let file: any;
        fetch(dataFile)
            .then(res => res.blob())
            .then(blob => {
                contentType = dataFile.split(';')[0].split(':')[1];
                const extension = contentType.split('/')[1]
                file = new File([blob], 'profile' + userInfo?.username, { type: contentType });

                if (file) {
                    return dispatch<any>(uploadAsset(
                        file.type,
                        file.name + '.' + extension,
                        "profile_photo"
                    ))
                }
                else {
                    return Promise.reject(new Error("File not found"));
                }
            })
            .then((results: any) => {
                if (results.body) {

                    dispatch<any>(uploadImage(
                        results.body,
                        contentType,
                        file,
                    ))
                        .then((result: any) => {
                            if (result.status === 200) {
                                const urlImage = results.body.split('?')[0];
                                setLogo({
                                    ...logo,
                                    value: {
                                        ...logo.value as LogoInterface,
                                        fileLink: urlImage,
                                    }
                                })
                                resolve(urlImage);
                            }
                            else {
                                console.log(result)
                                processData.setProcess("off");
                            }
                        })
                        .catch((error: any) => {
                            console.log(error)
                            processData.setProcess("off");
                        })

                }
            })
    });

    const handleClick = async () => {

        if (CheckDataStep()) {
            processData.setProcess("off");
            return;
        }

        const updateData: IUpdateUserBody = {}

        let logoLink: string  = '';

        if (newLogo && logo.value?.file !== '') {
            logoLink= await handleSaveImage() as string;
        }

        if (newLogo) {
            updateData.avatar = logoLink || '';
        }

        Promise.all([
            dispatch<any>(updateUser(
                userInfo.username,
                updateData,
                'OA',
            )),
            dispatch<any>(updateUserBio(
                userInfo.username,
                companyStatement.value || '',
            )),
        ])
            .then((results: any) => {
                dispatch<any>(getUser(userInfo.username))
                    .then((results: any) => {
                        processData.setNewData(false);
                        processData.setProcess("continue");
                        processData.setTabsProcessed([...processData.tabsProcessed, 1])
                    })
            })
    }

    useEffect(() => {
        if (processData.process === 'loading' && processData.step === 1) handleClick();
    }, [processData.process])

    useEffect(() => {
        if (user.biography) setCompanyStatement(getFieldState(user.biography));
        if (user.avatar) setLogo(getFieldState({
            file: user.avatar,
            fileLink: user.avatar
        }));
        if (checkProcessed()) processData.setTabsProcessed([...processData.tabsProcessed, 1]);
    }, [user])

    const handleOnBlurDescription = (e: string) => {
        setCompanyStatement({
            ...companyStatement,
            value: e
        })
        processData.setNewData(true);
    }

    function handleCloseModal(e: any, type: any) {
        setShowModalImage(() => false);
    };

    function handleCropImage(e: any) {

        const dataFile = URL.createObjectURL(logo.value?.file as any);
        let contentType: any;
        let file: any;

        fetch(e)
            .then(res => res.blob())
            .then(blob => {

                // contentType = logo?.value?.file.type;
                contentType = dataFile.split(';')[0].split(':')[1];
                const extension = contentType.split('/')[1]
                file = new File([blob], 'Logo' + userInfo.username, { type: contentType });

                setActiveCrop(true)
                setLogo({
                    ...logo,
                    value: {
                        fileLink: '',
                        file: file,
                    }
                })
            })
    };

    const showModalCrop = (e: BaseFieldState<LogoInterface>, isDelete = false) => {
        setLogo(e); 
        if(!isDelete) setShowModalImage(true); 
        setActiveCrop(false);
    }

    return (
        <Scroll_Content_Signup>
            <TitleContent theme={{ align: 'center' }}>{`${user.firstname || 'Hey'}, you can manifest the life of your dream.`}</TitleContent>
            <ContainerPhoto>
                <ModalImageEditorCrop preview={logo} handleSaveImage={handleCropImage} show={showModalImage} onHide={handleCloseModal} isProfilePhoto={true} />
                <AddProfilePhoto
                    title={'Add an avatar'}
                    logo={logo}
                    setLogo={(e: BaseFieldState<LogoInterface>, isDelete?: boolean) => showModalCrop(e, isDelete)}
                    setNewLogo={setNewLogo}
                    processData={processData}
                />
                <Row>
                    <SubText>Personal statement<Imp>*</Imp></SubText>
                    <TextEditor
                        value={companyStatement.value || ''}
                        setValue={handleOnBlurDescription}
                        charLimit={1000}
                        minHeight={153}
                        placeholder={'Please describe yourself...'}
                        showError={companyStatement.showError}
                    />
                </Row>
            </ContainerPhoto>
        </Scroll_Content_Signup>
    )
}

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
`

const ContainerSeeker = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px;
    align-items: center;
`;

const ContainerPhoto = styled.div`
    display: grid;
    grid-template-columns: 30% calc(70% - 20px);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    width: 100%;

    @media only screen and (max-width: 1000px) {
        grid-template-columns: 1fr;
        grid-row-gap: 35px;
    }
    
`

interface DropPhotoProps {
    padding: string
}

const DropPhoto = styled.div`
    width: 100%;
    margin: 10px 0 0px 0;
    text-align: initial;
    border: 1px dashed var(--simple-gray);
    border-radius: 12px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${(props: DropPhotoProps) => props.padding};

    cursor: pointer;
`

const ContIconDrop = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 12px;
    background-color: #F2F5FF;
    display: flex;
    justify-content: center;
    align-items: center;
`

const TextDrop = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding-left: 16px;
    width: calc(100% - 48px);
    color: var(--simple-brown-dark);
`

const SubtextDrop = styled.span`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: var(--onyx);
    margin-top: 4px;
`

const IconDrop = styled(FontAwesomeIcon)`
    color: black;
    font-size: 18px;
`;

const TextBlue = styled.span`
    color: blue
`

const IconMedia = styled.div`
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: relative;
    margin-right: 10px;
    background-image: url(./${props => props.theme.main}.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
`

export default Introduction