import React, { useContext, useEffect, useState } from 'react'
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-thin-svg-icons";
import { useSelector, useDispatch } from 'react-redux';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { getOrganizationInfo } from '../../store/actions/orgsActions';
import { RootState } from '../../store/store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ButtonSpinner from '../BaseComponents/ButtonSpinner';
import { ProcessType } from '../../containers/SetupHealer/SetupHealer';
import { getIdOrgUser } from '../../helpers/userHelper';
import { ContextSuccess } from '../../Context';
import IPaymentInfo from '../../interfaces/user/IPaymentINfo';
import { FooterCon_Setup } from '../signupsetup/Footer';

const Footer = ({
    handleTabActive,
    tabActive,
    setShowSetupOrg,
    setShowSetupUser,
    setProcess,
    process,
    isUpdate = false,
}: {
    handleTabActive: (value: number) => void,
    tabActive: number,
    setShowSetupOrg: (value: boolean) => void,
    setShowSetupUser: (value: boolean) => void,
    setProcess: (value: ProcessType) => void,
    process: ProcessType
    isUpdate?: boolean
}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentSuccess = useContext(ContextSuccess);

    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
    const user = useSelector((state: RootState) => state.getUser.user);
    const userPayment: IPaymentInfo = useSelector((state: RootState) => state.getUser.paymentInfo);

    const [loading, setLoading] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const setupOrgComplete = () => {
        setLoading(true);
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return Promise.all([
                    dataSDK.sdk.AccessManager.setupComplete(getIdOrgUser(user.rolesByOrg, user.organizations), userInfo?.username || '', dataSDK.token),
                    dataSDK.sdk.AccessManager.setupCompleteUser(userInfo?.username || '', dataSDK.token),
                ]);
            })
            .then((results) => {
                dispatch(getOrganizationInfo(getIdOrgUser(user.rolesByOrg, user.organizations)))
                setLoading(false);
                setShowSetupOrg(false);
                setShowSetupUser(false);
                navigate("/app");
            })
    }

    const movePage = (tab: number, type: string) => {
        if (tab >= 1 && type === 'next' && tab <= 6) {
            handleTabActive(tab + 1)
        }
        setLoading(false);
    }

    function handleTab(tab: number, type: string) {

        if (tab !== 1 && type === 'back') {
            handleTabActive(tab - 1);
            return;
        }

        if (type === 'complete') {
            setupOrgComplete();
            return;
        } else if (tab !== 1) {
            setProcess('continue');
        } else {
            setLoading(true);
            setProcess('loading');
        }
    }

    const handleUpdate = () => {
        setProcess('loading');
        setLoading(true);
    }

    const handleTabUpdate = (tab: number, type: 'NEXT' | 'EXIT') => {
        if (type === 'EXIT') {
            const bulletin = searchParams.get('bulletin');
            if (bulletin === 'true') {
                navigate('../../../../../../../app');
            }
            else {
                navigate(`../../../../../../../h/${userInfo?.username || ''}`);
            }
        }
        else{
            handleTabActive(tab + 1)
        }
    }

    const updateSuccess = () => {
        setLoading(false);
        currentSuccess?.setSuccessData(true, true, 'Data updated!', 'Your profile has been updated successfully.');
        handleTabUpdate(1, 'NEXT');
    }

    useEffect(() => {
        if (process === 'continue' && tabActive !== 7 && !isUpdate) movePage(tabActive, "next");
        if (process === 'continue' && isUpdate) updateSuccess();
        if (process === 'off') setLoading(false);
        if (process === 'loading') setLoading(true);
    }, [process])

    if (isUpdate) return (
        <FooterCon_Setup isFirstTab={tabActive === 1}>
            {tabActive !== 1 && (
                <ButtonBack onClick={() => handleTab(tabActive, 'back')}>
                    <IconButtonFtr
                        icon={faArrowLeft}
                        size='lg'
                        color={"black"}
                        style={{
                            fontSize: '16px'
                        }}
                    />
                    Back
                </ButtonBack>
            )}

            {tabActive === 1 ? (
                <WrapperButton>
                    <ButtonSpinner
                        loading={loading}
                        handleClick={handleUpdate}
                        text={'Save & Continue'}
                        hasIcon={true}
                        icon={'arrow-right'}
                    />
                </WrapperButton>
            ) : (
                <WrapperButtonNext>
                    <ButtonSpinner
                        loading={loading}
                        handleClick={() => handleTabUpdate(tabActive, tabActive === 3 ? 'EXIT' : 'NEXT')}
                        text={tabActive === 3 ? 'Exit' : 'Next'}
                        hasIcon={tabActive === 3 ? false : true}
                        icon={'arrow-right'}
                    />
                </WrapperButtonNext>
            )}
        </FooterCon_Setup>
    )

    return (
        <FooterCon_Setup isFirstTab={tabActive === 1}>

            {tabActive !== 1 && (
                <ButtonBack onClick={() => handleTab(tabActive, 'back')}>
                    <IconButtonFtr
                        icon={faArrowLeft}
                        size='lg'
                        color={"black"}
                        style={{
                            fontSize: '16px'
                        }}
                    />
                    Back
                </ButtonBack>
            )}

            {tabActive === 6 ? (
                <WrapperButton>
                    <ButtonSpinner
                        loading={loading}
                        handleClick={() => (user.organizations.length === 1 && !userPayment.hasActiveSubscription) ? handleTab(tabActive, 'next') : handleTab(tabActive, 'complete')}
                        text={(user.organizations.length === 1 && !userPayment.hasActiveSubscription) ? 'Next' : 'Submit'}
                        hasIcon={true}
                        icon={'arrow-right'}
                    />
                </WrapperButton>
            ) : (tabActive === 7 && user.organizations.length === 1 && userPayment.hasActiveSubscription) ? (
                <WrapperButton>
                    <ButtonSpinner
                        loading={loading}
                        handleClick={() => handleTab(tabActive, 'complete')}
                        text={'Submit'}
                        hasIcon={true}
                        icon={'arrow-right'}
                    />
                </WrapperButton>
            ) : !([6, 7].includes(tabActive)) ? (
                <WrapperButtonNext>
                    <ButtonSpinner
                        loading={loading}
                        handleClick={() => handleTab(tabActive, 'next')}
                        text={'Next'}
                        hasIcon={true}
                        icon={'arrow-right'}
                    />
                </WrapperButtonNext>
            ) : <></>}

        </FooterCon_Setup>
    )
}

const WrapperButton = styled.div`
    width: 25%;
`;

const WrapperButtonNext = styled.div`
    width: 15%;
`;

const ButtonNext = styled.div`
    background-color: var(--simple-blue-dark-2);
    padding: 12px 4px 12px 16px;
    border-radius: 8px;
    color: white;
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`

const ButtonBack = styled.div`
    background-color: white;
    border: 1px solid var(--simple-blue-dark-2);
    padding: 12px 16px 12px 4px;
    border-radius: 8px;
    color: black;
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`

const IconButtonFtr = styled(FontAwesomeIcon)`
    padding: 0 10px
`;


export default Footer