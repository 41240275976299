import Modal from "react-bootstrap/esm/Modal";
import { IconClicker } from "../../Home/HeaderHome/styles";
import { useContext, useEffect, useState } from "react";
import { ContextSuccess } from "../../../Context";
import { onHideType } from "../../BaseComponents/Modals/ModalUnsaved";
import styled from "styled-components";
import ButtonSpinner from "../../BaseComponents/ButtonSpinner";
import { Imp } from "../../signupsetuphealerind";
import { BaseFieldState, getFieldState } from "../../BaseComponents/utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import SelectItem from "../../../interfaces/SelectItem";
import useUsersSA from "../../../hooks/users/useUsersSA";
import useAllModalities from "../../../hooks/Superadmin/Modalities/useAllModalities";
import { SelectMultiWrapper } from "../../BaseComponents/SelectMultiWrapper";
import { DropdownIndicator, customStylesRSMultiWhite } from "../../../helpers/selectHelper";
import { FileRejection, useDropzone } from "react-dropzone";
import { LogoInterface } from "../../signupsetuphealer/Introduction";
import { getOrgHealers, uploadImage } from "../../../store/actions/orgsActions";
import { uploadAsset } from "../../../store/actions/userActions";
import IResponseError from "@vibe/sdk/dist/interfaces/IResponseError";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import ArticleCreate from "@vibe/sdk/dist/interfaces/Modalities/articles/ArticleCreate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorField from "../../BaseComponents/ErrorField";
import TextEditor from "../../BaseComponents/TextEditor";
import { IUserGradeResponse } from "@vibe/sdk/interfaces/Admin/User";
import Contributor from "@vibe/sdk/dist/interfaces/Modalities/contributors/Contributor";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { ArticleType } from "@vibe/sdk/dist/interfaces/Modalities/articles/Article";
import IHealer from "@vibe/sdk/interfaces/Admin/IHealer";
import { IGetUploadAssetLinkResponse } from "@vibe/sdk/dist/interfaces/Modalities/articles/getUploadAssetLink";
import IHS from "@vibe/sdk/interfaces/Modalities/hservices/HS";

interface ModalityOptions<T> {
    value: T;
    label: string;
    __isNew__: boolean;
}

export interface SelectComplete extends SelectItem {
    disabled?: boolean;
}

const ModalArticle = ({
    showModal,
    onHide,
    reload,
    mod,
    hl,
    type,
    isIndividual = false,
}: {
    showModal: boolean
    onHide: () => void
    reload: () => void
    mod?: string
    hl: string
    type: ArticleType
    isIndividual: boolean
}) => {
    const currentSuccess = useContext(ContextSuccess);
    const dispatch = useDispatch();

    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
    const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);
    const userRoles = useSelector((state: RootState) => state.getUser.userRoles);
    const orgHealers: IHealer[] = useSelector((state: RootState) => state.getOrgs.orgHealers);
    const userProfile: IUserGradeResponse = useSelector((state: RootState) => state.getUser.userProfile);

    const [loading, setLoading] = useState<boolean>(false);
    const [format, setFormat] = useState<'LINK' | 'FILE'>('LINK');
    const [name, setName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [description, setDescription] = useState<BaseFieldState<string>>(getFieldState(''));
    const [link, setLink] = useState<BaseFieldState<string>>(getFieldState(''));
    const [file, setFile] = useState<BaseFieldState<LogoInterface>>(getFieldState({ file: '', fileLink: '' }));

    const [modalityOptions, setModalityOptions] = useState<ModalityOptions<string>[]>([]);
    const [modalitiesSelected, setModalitiesSelected] = useState<ModalityOptions<string>[]>([]);
    const [modalities, setModalities] = useState<BaseFieldState<string[]>>(getFieldState([]));

    const [healerOptions, setHealerOptions] = useState<ModalityOptions<string>[]>([]);
    const [healersSelected, setHealersSelected] = useState<ModalityOptions<string>[]>([]);
    const [healers, setHealers] = useState<BaseFieldState<string[]>>(getFieldState([]));

    const [searchUser, setSearchUser] = useState<string>('');

    // const { usersSelector } = useUsersSA({
    //     searchName: searchUser
    // });

    const {
        modalitiesPages,
    } = useAllModalities({
        pageSizeProp: 1000,
        searchName: '',
    });

    const getModContributor = () => {
        if (userProfile.username && userInfo?.username && userProfile.username === userInfo?.username) {
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Modalities.getHSList(userInfo?.username || '', dataSDK.token);
                })
                .then((results) => {
                    if (results.statusCode === 200) {
                        const result = results.body as IHS[];
                        console.log(result);
                        setModalityOptions(result.map(us => ({
                            value: us?.modalityIds?.[0] ?? '',
                            label: us.name,
                            __isNew__: true,
                        })))
                    } else setModalityOptions([]);
                })
                .catch(console.error);
        } else {
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Modalities.getContributor(userInfo?.username || '', dataSDK.token);
                })
                .then((results) => {
                    if (results.statusCode === 200) {
                        const result = results.body as Contributor;
                        setModalityOptions(result.modalities.map(us => ({
                            value: us.id,
                            label: us.name,
                            __isNew__: true,
                        })))
                    } else setModalityOptions([]);
                })
                .catch(console.error);
        }
    }

    const reloadMod = (type?: 'clean' | 'other') => {
        if (mod) {
            if (type && type === 'clean') {
                const modEntrance = modalityOptions.find(modOP => modOP.value === mod);
                if (modEntrance) setModalitiesSelected(previousState => [modEntrance]);
                setModalities(getFieldState([mod]));
                return;
            }

            const modInside = modalitiesSelected.find(f => f.value === mod);
            if (!modInside) {
                const modEntrance = modalityOptions.find(modOP => modOP.value === mod);
                if (modEntrance) setModalitiesSelected(previousState => [modEntrance, ...previousState]);

                const modSelected = (modalities.value || []).find(modOP => modOP === mod);
                if (!modSelected) setModalities(prev => ({
                    value: [...(prev.value || []), mod],
                    showError: false,
                    error: '',
                }));
            }
        }
    }

    const reloadHeal = (type?: 'clean' | 'other') => {
        if (hl) {
            if (type && type === 'clean') {
                const modEntrance = healerOptions.find(modOP => modOP.value === hl);
                if (modEntrance) setHealersSelected(previousState => [modEntrance]);
                setHealers(getFieldState([hl]));
                return;
            }

            const modInside = healersSelected.find(f => f.value === hl);
            if (!modInside) {
                const modEntrance = healerOptions.find(modOP => modOP.value === hl);
                if (modEntrance) setHealersSelected(previousState => [modEntrance, ...previousState]);

                const modSelected = (healers.value || []).find(modOP => modOP === hl);
                if (!modSelected) setHealers(prevHealers => ({
                    value: [...(prevHealers.value || []), hl],
                    showError: false,
                    error: '',
                }));
            }
        }
    }

    useEffect(() => {
        setHealerOptions(/*usersSelector*/orgHealers.map(us => ({
            value: us.username,
            label: us.name,
            __isNew__: true,
        })))
    }, [orgHealers /*usersSelector*/])

    useEffect(() => {
        reloadMod();
    }, [mod, modalityOptions])

    useEffect(() => {
        if (user.organizations) {
            dispatch(getOrgHealers(user.organizations[0]));
        }
    }, [])

    useEffect(() => {
        reloadHeal();
    }, [hl, healerOptions])

    useEffect(() => {
        // if (userRoles.includes('SUPER')) {
        //     setModalityOptions(modalitiesPages[0].map(us => ({
        //         value: us.id,
        //         label: us.name,
        //         __isNew__: true,
        //     })))
        // }
        // else 
        getModContributor();
    }, [modalitiesPages, userRoles])

    const articleType: SelectComplete[] = [
        {
            label: 'Link',
            value: 'LINK',
            disabled: false,
        },
        {
            label: 'File',
            value: 'FILE',
            disabled: false,
        },
    ]

    const onDrop = (acceptedFile: File[], rejectedFiles: FileRejection[]) => {
        if (rejectedFiles.length > 0) return;
        console.log(acceptedFile[0])
        setFile({
            ...file,
            value: {
                fileLink: '',
                file: acceptedFile[0],
            }
        })
    }

    const maxSize = 2 * 1024 * 1024;

    const { getRootProps, getInputProps, open, isDragReject, fileRejections } = useDropzone({
        onDrop,
        noClick: true,
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'application/pdf': [],
        },
        maxSize
    })

    const isFileTooLarge = fileRejections.some(file => file.file.size > maxSize);

    const cleanFields = () => {
        setHealers(getFieldState([]));
        setModalities(getFieldState([]));
        setHealersSelected([]);
        setModalitiesSelected([]);
        setLoading(false);
        setSearchUser('');
        setFormat('LINK');
        setName(getFieldState(''));
        setDescription(getFieldState(''));
        setLink(getFieldState(''));
        setFile(getFieldState({ file: '', fileLink: '' }));
        reloadMod('clean');
        reloadHeal('clean');
    }

    const checkData = (): boolean => {
        let hasErrors = false;

        if (!name.value || name.value?.length === 0) {
            hasErrors = true;
            setName({
                ...name,
                showError: true,
                error: 'Name is required'
            })
        }
        else {
            setName({
                ...name,
                showError: false,
                error: ''
            })
        }

        if (!description.value || description.value?.length === 0) {
            hasErrors = true;
            setDescription({
                ...description,
                showError: true,
                error: 'Description is required'
            })
        }
        else {
            setDescription({
                ...description,
                showError: false,
                error: ''
            })
        }

        if (!healers.value || healers.value?.length === 0) {
            hasErrors = true;
            setHealers({
                ...healers,
                showError: true,
                error: 'Select healers is required'
            })
        }
        else {
            setHealers({
                ...healers,
                showError: false,
                error: ''
            })
        }

        if (!modalities.value || modalities.value?.length === 0) {
            hasErrors = true;
            setModalities({
                ...modalities,
                showError: true,
                error: 'Select modalities is required'
            })
        }
        else {
            setModalities({
                ...modalities,
                showError: false,
                error: ''
            })
        }

        if (format === 'LINK') {
            if (!link.value || link.value?.length === 0) {
                hasErrors = true;
                setLink({
                    ...link,
                    showError: true,
                    error: 'Link is required'
                })
            }
            else {
                setLink({
                    ...link,
                    showError: false,
                    error: ''
                })
            }
        }
        else {
            if (!file.value || file.value?.file.length === 0) {
                hasErrors = true;
                setFile({
                    ...file,
                    showError: true,
                    error: 'File is required'
                })
            }
            else {
                setFile({
                    ...file,
                    showError: false,
                    error: ''
                })
            }
        }

        return hasErrors;
    }

    const handleArticle = () => new Promise((resolve, reject) => {
        const dataFile = file.value?.file;

        let contentType: any = '';
        let name: any = '';

        try {
            contentType = dataFile.type;
        }
        catch (error) {
            console.log('Error getting file contentType');
            reject(new Error('Error uploading file'));
            return;
        }

        try {
            name = dataFile.name;
        }
        catch (error) {
            console.log('Error getting file name');
            reject(new Error('Error uploading file'));
            return;
        }

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.getUploadAssetLink({
                    ContentType: contentType,
                    Key: name,
                },
                    dataSDK.token);
            })
            .then((results: any) => {

                const body = results.body as IGetUploadAssetLinkResponse;

                if (results.statusCode === 201) {
                    fetch(body.body, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': decodeURIComponent(contentType)
                        },
                        body: dataFile
                    })
                        .then((result: any) => {

                            if (result.status === 200) {
                                const urlImage = (body.body || '').split('?')[0];
                                setFile({
                                    ...file,
                                    value: {
                                        ...file.value as any,
                                        fileLink: urlImage,
                                    }
                                })
                                resolve(urlImage);
                            }
                            else {
                                console.log('Error putting file.', result)
                                reject(new Error('Error uploading file'));
                                return;
                            }
                        })
                        .catch((error: any) => {
                            console.log(error)
                            reject(new Error('Error uploading file'));
                            return;
                        })

                }
                else {
                    console.log('Error getting upload asset link.');
                    reject(new Error('Error uploading file'));
                    return;
                }
            })
    });

    const handleCreate = async () => {
        const toSend: ArticleCreate = {
            name: name.value || '',
            createdBy: userInfo?.username || '',
            modalitiesIds: modalities?.value || [],
            healersIds: healers?.value || [],
            artType: type,
            type: format,
            link: link.value || '',
            createdByFullName: `${user.firstname} ${user.lastname}`,
            description: description.value || '',
            likesHealer: 0,
            likesSeeker: 0,
        }

        if (file.value?.file) {
            try {
                toSend.link = await handleArticle() as string;
            } catch (error) {
                currentSuccess?.setSuccessData(false, true, 'Error uploading file', 'Try again later!');
                setLoading(false);
                return;
            }
        }

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.createArticle(toSend, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 201) {
                    reload();
                    cleanFields();
                    onHide();
                    currentSuccess?.setSuccessData(true, true, 'article created successfully.');
                    currentSuccess?.setModalsOff();
                }
                else {
                    currentSuccess?.setSuccessData(false, true, (results.body as IResponseError).message || 'There was an error.');
                }
            })
            .catch((error) => {
                console.log(error);
                currentSuccess?.setSuccessData(false, true, 'Error creating article', error);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleOnSave = () => {
        if (checkData()) return;
        setLoading(true);

        handleCreate();
    }

    const onHideUnsaved = (type: onHideType) => {
        switch (type) {
            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                onHide();
                cleanFields();
                break;

            case 'save':
                handleOnSave();
                break;

            default:
                break;
        }
    }

    const handleUnsaved = () => {
        currentSuccess?.setModalWarning(
            'UNSAVED',
            (type) => onHideUnsaved(type),
            'Do you want to save your changes?'
        );
    }

    const onChangeModality = (updatedList: ModalityOptions<string>[]) => {
        setModalities({
            ...modalities,
            value: updatedList.map(item => item.value),
        })
        setModalitiesSelected(updatedList);
    }

    const onChangeHealer = (updatedList: ModalityOptions<string>[]) => {
        setHealers({
            ...healers,
            value: updatedList.map(item => item.value),
        })
        setHealersSelected(updatedList);
    }

    return (
        <Modal className="modalArticle" show={showModal}>
            <Modal.Header>
                <Modal.Title>
                    Create {type === 'ART' ? 'Article' : 'Research'}
                    <IconClicker
                        onClick={handleUnsaved}
                        icon="times"
                        size='xl'
                        color={'black'}
                    />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row1>
                    <Label>Name<Imp>*</Imp></Label>
                    <InputArticle
                        value={name.value}
                        onChange={(e) => setName({
                            ...name,
                            value: e.currentTarget.value
                        })}
                    />
                    {(name.showError) && (<ErrorField error={name.error} />)}
                </Row1>
                <Row1>
                    <Label>Description<Imp>*</Imp></Label>
                    <TextEditor
                        value={description.value || ''}
                        setValue={(e: string) => setDescription({
                            ...description,
                            value: e
                        })}
                        charLimit={1000}
                        maxHeight={'200px'}
                        placeholder='Please describe your article...'
                        showError={description.showError}
                    />
                </Row1>
                <Row1>
                    <Label>Upload Article<Imp>*</Imp></Label>
                    {type === 'RES' && <ListCheckbox>
                        {articleType.map(d => (
                            <ItemList onClick={() => d.disabled ? {} : setFormat(d.value as 'FILE' | 'LINK')} isDisabled={d.disabled}>
                                <IconRadio theme={{ main: format === d.value ? 'iconCheck' : d.disabled ? 'iconDisabled' : '' }}>
                                    {format === d.value &&
                                        <RadioIcon
                                            icon={faCircle}
                                            size='lg'
                                            color={'var(--absolute-zero)'}
                                        />
                                    }
                                </IconRadio>
                                {d.label}
                            </ItemList>
                        ))}
                    </ListCheckbox>}
                    {format === 'LINK' ? (
                        <Row1>
                            <InputArticle
                                value={link.value}
                                onChange={(e) => setLink({
                                    ...link,
                                    value: e.currentTarget.value
                                })}
                                placeholder="Enter link"
                            />
                            {(link.showError) && (<ErrorField error={link.error} />)}
                        </Row1>
                    ) : (
                        <Row1 {...getRootProps()}>
                            <input {...getInputProps()} />
                            {file.value && (file.value.file || file.value?.fileLink.length > 0) ? (
                                <DropPhoto onClick={open}>
                                    <ContIconDrop>
                                        <IconDrop src='../../fileUpload.svg' />
                                    </ContIconDrop>
                                    <TextDrop>
                                        File uploaded
                                    </TextDrop>
                                </DropPhoto>
                            ) : (
                                <DropPhoto onClick={open}>
                                    <ContIconDrop>
                                        <IconDrop src='../../fileUpload.svg' />
                                    </ContIconDrop>
                                    <TextDrop>
                                        <TextBlue>Choose</TextBlue> or Drag & Drop file to upload
                                        <br />
                                        <SubtextDrop>
                                            PDF, JPG or PNG (2mb max)
                                        </SubtextDrop>
                                    </TextDrop>
                                </DropPhoto>
                            )}

                            {(file.showError) && (<ErrorField error={file.error} />)}
                            {isDragReject && <ErrorField error={'Sorry, only PNG, JPEG, and PDF files are allowed'} />}
                            {isFileTooLarge && <ErrorField error={'File is too large (max 2MB)'} />}
                        </Row1>
                    )}
                </Row1>

                <Row1>
                    <Label>{isIndividual ? 'Contributor' : 'Healers Related'}<Imp>*</Imp></Label>
                    {isIndividual ? (
                        <DisabledInput>
                            {healersSelected.length > 0 ? (healersSelected[0].label || '') : ''}
                        </DisabledInput>
                    ) : (
                        <SelectMultiWrapper
                            options={healerOptions}
                            styles={customStylesRSMultiWhite()}
                            components={{
                                DropdownIndicator
                            }}
                            value={healersSelected || []}
                            onChangeValue={onChangeHealer}
                            placeholder="Select Multiple Healers"
                            showError={healers.showError}
                            error={healers.error}
                        />
                    )}
                </Row1>

                <Row1>
                    <Label>Modalities<Imp>*</Imp></Label>
                    <SelectMultiWrapper
                        options={modalityOptions}
                        styles={customStylesRSMultiWhite()}
                        components={{
                            DropdownIndicator
                        }}
                        value={modalitiesSelected || []}
                        onChangeValue={onChangeModality}
                        placeholder="Select Multiple Modalities"
                        showError={modalities.showError}
                        error={modalities.error}
                    />
                </Row1>
            </Modal.Body>

            <Modal.Footer>
                <ButtonCancel onClick={handleUnsaved}>Cancel</ButtonCancel>
                <WrapperButton>
                    <ButtonSpinner
                        loading={loading}
                        handleClick={handleOnSave}
                        text={'Save'}
                        hasIcon={false}
                    />
                </WrapperButton>
            </Modal.Footer>
        </Modal>
    )
};

export default ModalArticle;

const DisabledInput = styled.div`
    outline: none;
    width: 100%;
    height: 100%;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Inter';
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
    min-height: 40px;

    background-color: #f0efed;
    border: var(--simple-gray);
    color: black;
`;

const ListCheckbox = styled.div`
    display: flex;
    gap: 16px;
`;

const ItemList = styled.div`
    display: flex;
    justify-content: start;
    padding: 3px 0px 3px 0;
    align-items: center;
    font-family: 'Inter';
    font-size: 14px;
    gap: 8px;
    cursor: ${({ isDisabled = false }: { isDisabled?: boolean }) => isDisabled ? 'default' : 'pointer'};
    color: ${({ isDisabled = false }: { isDisabled?: boolean }) => isDisabled ? 'var(--simple-gray)' : 'black'};
`;

const TextBlue = styled.span`
    color: blue;
`;

const IconDrop = styled.img`
    width: 28px;
`;

const DropPhoto = styled.div`
    width: 100%;
    margin: 7px 0 4px 0;
    text-align: initial;
    border: 1px dashed var(--simple-gray);
    height: 106px;
    border-radius: 12px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
`

const ContIconDrop = styled.div`
    width: 74px;
    height: 74px;
    border-radius: 12px;
    background-color: #F2F5FF;
    display: flex;
    justify-content: center;
    align-items: center;
`

const TextDrop = styled.div`
    font-family: 'Inter';
    width: calc(100% - 74px);
    font-size: 14px;
    line-height: 17px;
    padding-left: 16px;
    color: var(--simple-brown-dark);
    text-align: center;
`

const SubtextDrop = styled.span`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: var(--onyx);
    margin-top: 4px;
`

const InputArticle = styled.input`
    outline: none;
    width: 100%;
    height: 100%;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Inter';
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
    min-height: 40px;

    background-color: white;
    border: 1px solid var(--simple-gray);
    color: black;

    &::placeholder{
        color: var(--simple-gray);
        font-family: 'Inter';
    }
`;

const ListAreas = styled.div`
    width: 100%;
    margin: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
`;

interface PropsArea {
    isSelected: boolean;
}

const Area = styled.div`
    border: 1px solid darkgrey;
    width: fit-content;
    background-color: ${(props: PropsArea) => !props.isSelected ? "white" : "var(--lavender)"};
    padding: 7px 12px;
    border-radius: 5px;
    font-family: ${(props: PropsArea) => !props.isSelected ? "Inter-bold" : "Inter"};
    font-size: 14px;
    cursor: pointer;

    &:hover {
        border: 1px solid gray;
    }
`;


const AreaDeleteable = styled.div`
    border: 1px solid darkgrey;
    width: fit-content;
    background-color: ${(props: PropsArea) => !props.isSelected ? "white" : "var(--lavender)"};
    padding: 7px 12px;
    border-radius: 5px;
    font-family: ${(props: PropsArea) => !props.isSelected ? "Inter-bold" : "Inter"};
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;

    &:hover {
        border: 1px solid gray;
    }
`;

const ButtonCancel = styled.div`
    width: 49%;
    text-align: center;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 1px solid var(--dark-blue);
    cursor: pointer;
`;

const WrapperButton = styled.div`
    width: 50%;
`;

const Label = styled.div`    
    width: 100%;
    text-align: left;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`;

const Row1 = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: fit-content;
`;

const RadioIcon = styled(FontAwesomeIcon)`
    font-size: 14px;
`;

const IconRadio = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    border-radius: 12px;
    border: 2px solid #999796;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.iconCheck{
        background-color: white;
        border: 2px solid #2A4CD3;
    }

    &.iconDisabled{
        border: 2px solid var(--simple-gray-2);
    }
`