import Modal from "react-bootstrap/esm/Modal";
import styled from "styled-components";
import ErrorField from "../../../BaseComponents/ErrorField";
import { BaseFieldState, getFieldState } from "../../../BaseComponents/utils";
import { useContext, useState } from "react";
import ButtonSpinner from "../../../BaseComponents/ButtonSpinner";
import { ContextSuccess } from "../../../../Context";
import { onHideType } from "../../../BaseComponents/Modals/ModalUnsaved";
import { getDataSDK, getDataSDKResponse } from "../../../../helpers/backendHelper";
import { useTranslation } from "react-i18next";
import { IAssignINTToINTListBody, INTList, Interest } from "@vibe/sdk/dist/interfaces/Connections/newInterests";

const AssignIntModal = ({
    show,
    setShow,
    reloadList,
    intList,
    interest,
}: {
    show: boolean
    setShow: (data: boolean) => void
    reloadList: () => void
    intList: INTList |'0'
    interest: Interest
}) => {
    const { t } = useTranslation();
    const currentSuccess = useContext(ContextSuccess);

    const [name, setName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [loading, setLoading] = useState<boolean>(false);

    const onHide = (type: onHideType) => {
        switch (type) {
            case 'save':
                save();
                currentSuccess?.setModalsOff();
                break;
            case 'close':
                currentSuccess?.setModalsOff();
                break;
            case 'discard':
                setName(getFieldState(''));
                currentSuccess?.setModalsOff();
                setShow(false);
                break;
            default:
                break;
        }
    }

    const discard = () => {
        if (name.value === '') {
            setShow(false);
            return;
        }
        currentSuccess?.setModalWarning('UNSAVED', onHide, 'Do you want to discard changes?')
    }

    const save = () => {
        setLoading(true);
        if(intList === '0'){
            currentSuccess?.setSuccessData(false, true, 'Error getting active list.');
            return;
        }

        const toSend: IAssignINTToINTListBody = {
            intId: interest.id
        }
        if(name.value !== '') toSend.assignedName = name.value;

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Connections.assignINTToINTList(toSend, intList.id, dataSDK.token);
            })
            .then((response) => {
                if (response.statusCode === 201) {
                    reloadList();
                    currentSuccess?.setSuccessData(true, true, t('toast_interestAssigned'));
                    setName(getFieldState(''));
                    setShow(false);
                    return;
                }
                currentSuccess?.setSuccessData(false, true, t('toast_errorAnError'));
            })
            .finally(() => setLoading(false))
    }

    return (
        <Modal show={show} onHide={discard}>
            <Modal.Header>
                <Modal.Title>
                    <Content>Assign Interest {intList !== '0' ? `to ${intList.name}` : ''}</Content>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Label>Assigned Name</Label>
                    <Input
                        placeholder={interest.name}
                        value={name.value}
                        onChange={(e) => setName({ ...name, value: e.currentTarget.value })}
                    />
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <WrapButtons>
                    <Button onClick={discard}>Cancel</Button>
                    <WrapSpinner>
                        <ButtonSpinner
                            loading={loading}
                            handleClick={save}
                            text={'Assign'}
                            hasIcon={false}
                        />
                    </WrapSpinner>
                </WrapButtons>
            </Modal.Footer>
        </Modal>
    )
}

const WrapSpinner = styled.div`
    width: 75%;
`;

const WrapButtons = styled.span`
    width: 100%;
    display: flex;
    gap: 15px;
`;

const Content = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  text-align: left;
  color: var(--simple-blue-dark);
  margin-top: 30px;
  margin-left: 10px;
`;

const Label = styled.div`    
    width: 100%;
    text-align: left;
    margin: 3px 0;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`;

const Row = styled.div`
    width: '100%';
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 200px;
  min-width: 100px;
  height: 100%;
  padding: 10px 20px;
  border-radius: 11px;
  background-color: white;
  border: 1px solid var(--t-blue);

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: var(--t-blue);
  gap: 10px;

  cursor: pointer;
`;

export default AssignIntModal;