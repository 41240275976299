import { SearchEngineResponse, searchBase } from "@vibe/sdk/dist/interfaces/Connections/SearchEngine"
import { ICommunityHomeCard } from "../../components/BaseComponents/HomeCards/CommunityHomeCard"
import { IHealerCard } from "../../components/BaseComponents/HomeCards/HealerCard"
import { IModalityCard } from "../../components/BaseComponents/HomeCards/ModalityCard"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { searchEngine } from "../../store/actions/connectionActions"
import { RootState } from "../../store/store"
import { IInspiration } from "../../components/Home/ResultsHome/CarrouselInspiration/Content"
import { getPages, mapCommunityHomeCard, mapEventHomeCard, mapHealerCard, mapModalityCard, mapQuoteCard } from "../../helpers/searchBarHelper"
import { ActivePanelDataType } from "../../interfaces/HomeSearch/ItemFilterHome"
import { EventCardInterface } from "../../components/BaseComponents/Events/EventCard"

interface useSearchEngineProps {
    filters?: any[] //@todo
    search: string
    category?: searchBase
    size?: number
    pageSize?: number
}

interface useSearchEngineResponse {
    // "Filtered" are all the results not paged but filtered
    modalitiesFiltered: IModalityCard[]
    communitiesFiltered: ICommunityHomeCard[]
    healersFiltered: IHealerCard[]    //If necessary, They're Top Healers
    profOrganizationsFiltered: IHealerCard[]
    topPractitionersFiltered: IHealerCard[]
    eventsFiltered: EventCardInterface[]

    //"Pages" are results filtered by page size
    modalitiesPages: IModalityCard[][]
    communitiesPages: ICommunityHomeCard[][]
    healersPages: IHealerCard[][]    //If necessary, They're Top Healers
    profOrganizationsPages: IHealerCard[][]
    topPractitionersPages: IHealerCard[][]
    eventsPages: EventCardInterface[][]

    // Other props
    quotes: IInspiration[]
    reloadEngine: () => void
    doInitialSearch: () => void
    loadingSearchEngine: boolean
    totalResults: number
    showingResults: number
}

const useSearchEngine = ({
    filters,
    search,
    size = 1000000000000,
    category = 'Everything',
    pageSize = 6
}: useSearchEngineProps): useSearchEngineResponse => {
    const dispatch = useDispatch();

    const searchParsed = useMemo(() => search, [search]);
    const results: SearchEngineResponse = useSelector((state: RootState) => state.getConnection.SearchEngine);
    const loadingSearchEngine = useSelector((state: RootState) => state.getConnection.loadingSearchEngine);

    const [modalitiesFiltered, setModalitiesFiltered] = useState<IModalityCard[]>([]);
    const [communitiesFiltered, setCommunitiesFiltered] = useState<ICommunityHomeCard[]>([]);
    const [healersFiltered, setHealersFiltered] = useState<IHealerCard[]>([]);
    const [topPractitionersFiltered, setTopPractitionersFiltered] = useState<IHealerCard[]>([]);
    const [profOrganizationsFiltered, setProfOrganizationsFiltered] = useState<IHealerCard[]>([]);
    const [eventsFiltered, setEventsFiltered] = useState<EventCardInterface[]>([]);
    const [quotes, setQuotes] = useState<IInspiration[]>([]);

    const modalitiesPages: IModalityCard[][] = useMemo(() => getPages(modalitiesFiltered, pageSize), [pageSize, modalitiesFiltered]);
    const communitiesPages: ICommunityHomeCard[][] = useMemo(() => getPages(communitiesFiltered, pageSize), [pageSize, communitiesFiltered]);
    const healersPages: IHealerCard[][] = useMemo(() => getPages(healersFiltered, pageSize), [pageSize, healersFiltered]);
    const topPractitionersPages: IHealerCard[][] = useMemo(() => getPages(topPractitionersFiltered, pageSize), [pageSize, topPractitionersFiltered]);
    const profOrganizationsPages: IHealerCard[][] = useMemo(() => getPages(profOrganizationsFiltered, pageSize), [pageSize, profOrganizationsFiltered]);
    const eventsPages: EventCardInterface[][] = useMemo(() => getPages(eventsFiltered, pageSize), [pageSize, eventsFiltered]);

    const showingResults = useMemo(() => {
        console.log([
            modalitiesPages[0].length,
            communitiesPages[0].length,
            // healersPages[0].length,
            topPractitionersPages[0].length,
            profOrganizationsPages[0].length,
            eventsPages[0].length,
        ])
        return [
            modalitiesPages[0].length,
            communitiesPages[0].length,
            // healersPages[0].length,
            topPractitionersPages[0].length,
            profOrganizationsPages[0].length,
            eventsPages[0].length,
        ].reduce((acc, curr) => acc + curr, 0)
    }, [
        modalitiesPages,
        communitiesPages,
        healersPages,
        topPractitionersPages,
        profOrganizationsPages,
        eventsPages,
    ])

    const reloadEngine = () => {
        if (searchParsed !== '') dispatch(searchEngine({ search: searchParsed, size, category }));
        else console.error('Search info for Search Engine cannot be empty!');
    }

    const doInitialSearch = () => dispatch(searchEngine({ search: " ", size: 10, category }));

    const getHealersFiltered = () => {
        const allHealersFiltered = mapHealerCard(results.healers);
        setHealersFiltered(allHealersFiltered);

        const tps: IHealerCard[] = [];
        const pos: IHealerCard[] = [];

        allHealersFiltered.forEach((h) => {
            if (h.type === 'TP') tps.push(h);
            if (h.type === 'PO') pos.push(h);
        })

        setTopPractitionersFiltered(tps);
        setProfOrganizationsFiltered(pos);
    }

    useEffect(() => reloadEngine(), [search, category]);
    useEffect(() => reloadEngine(), []);

    useEffect(() => {
        setModalitiesFiltered(mapModalityCard(results.modalities));
        setCommunitiesFiltered(mapCommunityHomeCard(results.communities));
        getHealersFiltered();
        setEventsFiltered(mapEventHomeCard(results.events));
        setQuotes(mapQuoteCard(results.quotes));
    }, [results]); //@todo [filters]

    return {
        // "Filtered" are all the results not paged but filtered
        modalitiesFiltered,
        communitiesFiltered,
        healersFiltered,
        topPractitionersFiltered,
        profOrganizationsFiltered,
        eventsFiltered,

        //"Pages" are results filtered by page size
        modalitiesPages,
        communitiesPages,
        healersPages,
        profOrganizationsPages,
        topPractitionersPages,
        eventsPages,

        //Other results
        quotes,
        reloadEngine,
        doInitialSearch,
        loadingSearchEngine,

        totalResults: results.counts.Total,
        showingResults,
    }
}

export default useSearchEngine;