import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ContextSuccess } from "../../Context";

const EmailSent = ({from, type}:{from: string, type: string}) => {
    const navigate = useNavigate();
    const currentSuccess = useContext(ContextSuccess);
    return (
        <ContainerForm>
            <TextMessage>Vibe has forwarded you an email...</TextMessage>
            <IconEmail />
            <SubText
                onClick={() => currentSuccess?.setLoginModal(true, true)}
                isModal={currentSuccess?.showLoginModal ?? false}
            >
                Please confirm your registration to <TBold>  login  </TBold> {type === 'seeker' ? 'to Vibe and personalize your account.' : 'to Vibe and complete your healer profile.'}
            </SubText>
        </ContainerForm>
    )
}

export const Logo = styled.div`
    width: 100%;
    height: 50px;
    background-image: url(./logoVibe.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    top: 32px;
`;

interface PropsContainer{
    fromInd: boolean;
}

const ContainerForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    background-color: transparent;
    width: 100%;
    padding: 33px 16px;
    grid-area: 2 / 1 / 3 / 2;
    height: 100%;
`;

const TBold = styled.p`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    margin: 0 5px;
    text-decoration: underline;
    cursor: pointer;
`

const TextMessage = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
`

const SubText = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    display: flex;
    flex-direction: ${({isModal}:{isModal: boolean}) => isModal? 'column': 'row'};

    @media only screen and (max-width: 700px) {
        display: block;

        & ${TBold}{
            display: inline-block;
        }
    }
`
const IconEmail = styled.div`
    width: 124px;
    height: 92px;
    background-image: url(../iconMessage.svg);
    background-size: contain;
    background-repeat: no-repeat;
`

export default EmailSent;