import { useContext, useState } from "react";
import styled from "styled-components";
import SearchInput from "../../BaseComponents/SearchInput";
import BlankTable from "../../BaseComponents/BlankTable";
import { onHideType } from "../../BaseComponents/Modals/ModalUnsaved";
import { ContextSuccess } from "../../../Context";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Title } from "../SuperAdminComponents/basicForSACenter";
import useTClubsSA from "../../../hooks/tclub/useTClubsSA";
import { capitalize } from "../../../helpers/letterHelper";
import ModalCreateTemTeam from "./ModalCreateTemTeam";
import TClub from "@vibe/sdk/dist/interfaces/Club/TClubs/TClub/TClub";

const mockTemplateTeam: TClub = {
    id: '',
    name: '',
    club_type: 'COMM',
    description: '',
    member_types: [],
    role_assigned: '',
}

const TemplateTeamsView = () => {
    const currentSuccess = useContext(ContextSuccess);

    const [searchName, setSearchName] = useState<string>('');
    const [showCreate, setShowCreate] = useState<boolean>(false);
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [Selected, setSelected] = useState<TClub>({...mockTemplateTeam});

    const {  
        reloadTClubs,
        filteredTT
    } = useTClubsSA({
        pageSizeProp: 1000,
        searchName,
    });

    const handleUpdate = (data: string) => {
        const template = filteredTT.find(t => t.id === data);
        if(template) setSelected(template);
        setShowEdit(true);
    }

    const onHideDelete = (type: onHideType, id: string) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);
                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        return dataSDK.sdk.Club.deleteTC(id, dataSDK.token)
                    })
                    .then((response) => {
                        if (response.statusCode === 201) {
                            reloadTClubs();
                            currentSuccess?.setSuccessData(true, true, 'Template Team Deleted.');
                            currentSuccess?.setModalsOff();
                        } else {
                            currentSuccess?.setSuccessData(false, true, 'Error deleting Template Team.');
                        }
                    })
                    .finally(() => currentSuccess?.setLoadingModal(false))
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                break;

            default:
                break;
        }
    }

    const handleDelete = (data: string) => {
        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => onHideDelete(type, data),
            'Do you want to delete this Template Team?'
        );
    }

    return (
        <ContentOrganizations>
            <Title>Template Teams</Title>
            <WrapperRow>
                <Button onClick={() => setShowCreate(true)}>
                    <StyledFontAwesomeIcon
                        size='sm'
                        color='white'
                        icon='plus'
                    />
                    Create Template Team
                </Button>
                <WrapInput>
                    <SearchInput
                        filteredItem={searchName}
                        setFilteredItem={setSearchName}
                        border={'1px solid var(--simple-gray)'}
                        placeholder={`Search by name`}
                    />
                </WrapInput>
            </WrapperRow>
            <Scroll>
                <BlankTable
                    headerColored
                    border
                    headers={['Name', 'Description', 'Role Assigned']}
                    items={filteredTT.map(org => ({
                        id: org.id,
                        labels: [
                            org.name,
                            org.description ?? '',
                            capitalize(org.role_assigned)
                        ],
                    }))}
                    actions={['UPDATE', 'DELETE']}
                    actionsHandlers={{
                        handleUpdate,
                        handleDelete,
                    }}
                    backgroundColor={'white'}
                />
            </Scroll>
            <ModalCreateTemTeam
                showModal={showCreate}
                setShowModal={setShowCreate}
                reloadTeams={reloadTClubs}
            />
            <ModalCreateTemTeam
                showModal={showEdit}
                setShowModal={setShowEdit}
                reloadTeams={reloadTClubs}
                edit
                data={Selected}
            />
        </ContentOrganizations>
    );
}

export default TemplateTeamsView;

const WrapInput = styled.div`
    width: 50%;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const ContentOrganizations = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  align-items: flex-start;
  gap: 15px;
  flex-direction: column;
`;

const WrapperRow = styled.div`
  display: flex;
  width: 100%;
  gap: 25px;
  align-items: flex-end;
  justify-content: space-between;
`;

const Scroll = styled.div`
  display: flex;
  width: 100%;
  overflow-y: scroll;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 245px;
  min-width: 100px;
  height: 100%;
  padding: 10px 20px;
  border-radius: 11px;
  background-color: var(--simple-blue-dark);
  border: 0;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: white;
  gap: 10px;

  cursor: pointer;
`;