import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const ErrorField = ({error} : {error: string}) => {

    return(
        <ErrorWrapper>
            <StyledFontAwesomeIcon
                icon="triangle-exclamation"
                size='sm'
            />
            <Error>{error}</Error>
        </ErrorWrapper>
    )
}

const ErrorWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 7px;
    background-color: var(--maroon-flush-light);

    align-items: center;
    justify-items: flex-start;
    margin-top: 3px;
    width: fit-content;
    border-radius: 8px;
`;

const Error = styled.p`
    color: var(--maroon-flush);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: var(--maroon-flush);
    cursor: pointer;
`;

export default ErrorField;