import styled, { css, keyframes } from "styled-components";

export const listAdv = [
    {
        id: 1,
        name: 'Rebecca Pollock MD',
        location: "Los Angeles, California",
        avatar: 'https://picsum.photos/200',
        interests: [
            "Happiness & Joy ", "Love", "Health", "Inner peace", "Freedom"
        ],
        description: "I express myself best through dance, and singing...",
        icons: {

        }
    },
    {
        id: 2,
        name: 'Rebecca Pollock MD',
        location: "Los Angeles, California",
        avatar: 'https://picsum.photos/200',
        interests: [
            "Happiness & Joy ", "Love", "Health", "Inner peace", "Freedom"
        ],
        description: "I express myself best through dance, and singing..."
    },
    {
        id: 3,
        name: 'Rebecca Pollock MD',
        location: "Los Angeles, California",
        avatar: 'https://picsum.photos/200',
        interests: [
            "Happiness & Joy ", "Love", "Health", "Inner peace", "Freedom"
        ],
        description: "I express myself best through dance, and singing..."
    },
]

export const listAreasModal = [
    {
        id: 'A',
        list: [
            "Ayurveda",
        ],

    },
    {
        id: 'B',
        list: [
            "Breathwork",
        ],
    },
    {
        id: 'C',
        list: [
            "CBD",
        ],
    },
    {
        id: 'H',
        list: [
            "Herbalist","Hormone Therapy & Anti Aging","Hypnotherapists"
        ],
    },
    {
        id: 'M',
        list: [
            "Manifestation","Medical Matijuana","Meditation"
        ],
    },
    {
        id: 'C',
        list: [
            "CBD",
        ],
    },
    {
        id: 'H',
        list: [
            "Herbalist","Hormone Therapy & Anti Aging","Hypnotherapists"
        ],
    },
    {
        id: 'M',
        list: [
            "Manifestation","Medical Matijuana","Meditation"
        ],
    },
]

export const listComm = [
    {
        id: 1,
        name: 'Ayurveba',
        healer: 'James Pollock',
        join: false,
        follow: false,
    },
    {
        id: 2,
        name: 'Ayurveba',
        healer: 'James Pollock',
        join: false,
        follow: false,
    },
    {
        id: 3,
        name: 'Ayurveba',
        healer: 'James Pollock',
        join: true,
        follow: false,
    },
    {
        id: 1,
        name: 'Ayurveba',
        healer: 'James Pollock',
        join: false,
        follow: false,
    },
    {
        id: 2,
        name: 'Ayurveba',
        healer: 'James Pollock',
        join: true,
        follow: false,
    },
    {
        id: 3,
        name: 'Ayurveba',
        healer: 'James Pollock',
        join: false,
        follow: false,
    },
    {
        id: 1,
        name: 'Ayurveba',
        healer: 'James Pollock',
        join: true,
        follow: false,
    },
    {
        id: 2,
        name: 'Ayurveba',
        healer: 'James Pollock',
        join: true,
        follow: false,
    },
    {
        id: 3,
        name: 'Ayurveba',
        healer: 'James Pollock',
        join: false,
        follow: false,
    },
]

export const listConnections = [
    {
        id: 2,
        name: 'Rebecca Pollock',
        avatar: 'https://picsum.photos/200/200',
        interests: 'Energy, Weightloss, Abundance, Content &...',
        friend: true,
    },
    {
        id: 2,
        name: 'Rebecca Pollock',
        avatar: 'https://picsum.photos/200/200',
        interests: 'Energy, Weightloss, Abundance, Content &...',
        friend: false,
    },
    {
        id: 2,
        name: 'Rebecca Pollock',
        avatar: 'https://picsum.photos/200/200',
        interests: 'Energy, Weightloss, Abundance, Content &...',
        friend: true,
    },
    {
        id: 2,
        name: 'Rebecca Pollock',
        avatar: 'https://picsum.photos/200/200',
        interests: 'Energy, Weightloss, Abundance, Content &...',
        friend: true,
    },
    {
        id: 2,
        name: 'Rebecca Pollock',
        avatar: 'https://picsum.photos/200/200',
        interests: 'Energy, Weightloss, Abundance, Content &...',
        friend: false,
    },
    {
        id: 2,
        name: 'Rebecca Pollock',
        avatar: 'https://picsum.photos/200/200',
        interests: 'Energy, Weightloss, Abundance, Content &...',
        friend: false,
    },
    {
        id: 2,
        name: 'Rebecca Pollock',
        avatar: 'https://picsum.photos/200/200',
        interests: 'Energy, Weightloss, Abundance, Content &...',
        friend: true,
    },
    {
        id: 2,
        name: 'Rebecca Pollock',
        avatar: 'https://picsum.photos/200/200',
        interests: 'Energy, Weightloss, Abundance, Content &...',
        friend: true,
    },
]

export const listAreas = [
    'Happiness & Joy', 'Love', 'Fullfillment & Purpose', 'Inner Peace', 'Freedom', 'Creativity', 'Youthfullness',
    'Happiness & Joy', 'Love', 'Fullfillment & Purpose', 'Inner Peace', 'Freedom', 'Creativity', 'Youthfullness',
    'Happiness & Joy', 'Love', 'Fullfillment & Purpose', 'Inner Peace', 'Freedom', 'Creativity', 'Youthfullness'
]

export const ContentWrap = styled.div`
    width: 1250px;
    margin-bottom: 2px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export const TitleContent = styled.p`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    text-align: ${props => props.theme.align || 'left'};
    
`;

export const IconHealer = styled.div`
    width: 32px;
    height: 32px;
    background-color: var(--electric-yellow-2);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 10px;
    background-image: url(../icon_healer.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 65% auto;

    &:hover::after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-right: 10px solid transparent;
        border-top: 10px solid transparent;
        border-left: 10px solid transparent;
        border-bottom: 10px solid var(--simple-blue-dark-2);
        top: 23px;
        z-index: 1
    }
    &:hover::before {
        content: "Healer";
        background-color: var(--simple-blue-dark-2);
        position: relative;
        top: 40px;
        padding: 4px 11px;
        font-size: 12px;
        font-family: 'Inter';
        border-radius: 10px;
        z-index: 1
    }
`

export const IconSeeker = styled.div`
    width: 17px;
    height: 17px;
    background-color: var(--simple-green);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: relative;
    background-image: url(../icon_seeker.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 65% auto;
    margin-right: 3px;

    &:hover::after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-right: 10px solid transparent;
        border-top: 10px solid transparent;
        border-left: 10px solid transparent;
        border-bottom: 10px solid var(--simple-blue-dark-2);
        top: 23px;
        z-index: 1
    }
    &:hover::before {
        content: "Seeker";
        background-color: var(--simple-blue-dark-2);
        position: relative;
        top: 40px;
        padding: 4px 11px;
        font-size: 12px;
        font-family: 'Inter';
        border-radius: 10px;
        z-index: 1
    }
`

export const SubText = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
`

export const ButtonBlue = styled.div`
    background-color: var(--simple-blue-dark-2);
    margin-bottom: 16px;
    padding: 12px 0;
    border-radius: 8px;
    color: white;
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
`

export const ButtonBrown = styled.div`
    background-color: #CEBEAD;
    padding: 12px 0;
    border-radius: 8px;
    color: black;
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
`


export const ButtonWhite = styled.div`
    background-color: #ffffff;
    border: 1px solid #29313b;
    margin-bottom: 16px;
    padding: 7px 0;
    border-radius: 8px;
    color: black;
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const CardItem = styled.div`
    width: 212px;
    border: 1px solid var(--mountain-mist);
    background-color: white;
    border-radius: 10px;
    margin: 0 12px 14px 12px;
    padding: 17px;
    color: black;
    text-align: center;
`

export const CardItemRw = styled.div`
    width: 48%;
    border: 1px solid var(--mountain-mist);
    background-color: white;
    border-radius: 10px;
    padding: 17px;
    color: black;
    text-align: center;
`

export const InfoUser = styled.div`
    display: flex;
`

export const Detail = styled.div`
    text-align: left;
    margin-left: 10px;
    line-height: 22px;
    width: 280px;
`

export const Name = styled.div`
    color: black;
    font-size: 20px;
    font-weight: 900;
    font-family: Inter-Bold;
`

export const Location = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 9.85185px;
    text-align: initial;
    padding: 4px 0;
    color: black;
`

export const Description = styled.div`
    text-align: left;
    margin: 0;
    font-family: 'Inter';
    font-size: 14px;
    color: black;
    line-height: 13px;
    margin-top: 12px;
`

export const AvatarConn = styled.img`
    width: 56px;
    height: 56px;
    min-width: 56px;
  min-height: 56px;
  max-width: 56px;
  max-height: 56px;
    border-radius: 50%;
    margin-top: 6px;
    margin-bottom: 14px;
    object-fit: cover;
`

export const NameConnection = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align:center;
`

export const Interests = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin: 18px 0;
    line-height: 17px;
`

export const ListComm = styled.div`
    width: 100%;
    padding: 15px 12px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: 520px;
`

export const TitleCard = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align:left;
`

export const Imp = styled.p`
    color: red;
    margin-left: 5px;
`

export const Accordion = styled.div`
    height: 290px;
    overflow: scroll;
`

export const ContainerMedia = styled.div`
    width: 100%;
    height: 200px;
    border-radius: 6px;
    display:flex;
    gap: 20px;
`

export const MediaHeader = styled.div`
    font-family: 'Inter-Bold';
    padding: 14px 0;
    border-bottom: 1px solid var(--simple-gray);
    font-size: 16px;
    line-height: 16px;
    display: flex;
    justify-content: space-between; 
    width: 100%;
    align-items: center;
`

export const MediaOption = styled.div`
    font-family: 'Inter-Bold';
    font-size: 16px;
    line-height: 16px;
    display: flex;
    justify-content: space-between; 
    width: 100%;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid var(--simple-gray);
`

export const ContIconDrop = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #F2F5FF;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ContainerInputs = styled.div`
    margin-top: 10px;
    width: 100%;
`

export const Inputs = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 7px;
    gap: 20px;
`

export const RadioButtons = styled.div`
    width: 100%;
    display: flex;
    justify-content: start;
    margin-bottom: 7px;
`

export const Row2 = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
`

export const Row2_70 = styled.div`
    width: 100%;
    height: 100%;
`

export const Row2_avatar = styled.div`
    width: 100%;
`;

export const Label = styled.div`    
    width: 100%;
    text-align: left;
    margin: 3px 0;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`
export const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
`

export const Div_Link = styled.div`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Row1 = styled.div`
    width: 100%;
    position: relative;
`

export const TextArea = styled.div`
    width: 100%;
    border-radius: 12px;
    background-color: white;
    border: 1px solid var(--simple-gray);

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: var(--onyx);
    margin-top: 10px;
    padding: 10px;
    height: 155px;
`

export const MediaFooter = styled.div`
    display: flex;
    justify-content: ${props => props.theme.main || 'space-between'};    
    align-items: center;
`

export const ButtonAdd = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: var(--absolute-zero);
    width: 130px;
    display: flex;
    justify-content: space-between;
    align-items: center; 
`

export const ButtonSave = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    width: 71px;
    height: 37px;
    background: var(--simple-blue-dark-2);
    border-radius: 8px;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
`