import { useEffect, useState } from "react"
import { Backend, MicroServices } from "../helpers/backendHelper";
import { GenderClean } from "../interfaces/personalization/genders";

const COLORS: Readonly<string[]> = ['#1DB382', '#003DDB', '#7400F9', '#A885D7'];

const useGender = () => {
  const [genders, setGenders] = useState<GenderClean[]>([]);
  const [genderChart, setGenderChart] = useState<string[]>([]);
  const [colorChart, setColorChart] = useState<string[]>([])

  useEffect(() => {
    Backend(
      MicroServices.Roster,
      '/p13n/genders'
    )
      .then((response) => response.json())
      .then((genders) => setGenders(genders))
  }, [])

  useEffect(() => {
    const copyOfGenders = [...genders].map((gender) => gender.label);
    const copyOfColors = [...COLORS];
    const genderChartAux: string[] = [];
    const colorChartAux: string[] = [];

    for (let i = 0; i < genders.length; i++) {
      genderChartAux.push(copyOfGenders.shift() || '');
      colorChartAux.push(copyOfColors.shift() || '');
    }

    setGenderChart([...genderChartAux]);
    setColorChart([...colorChartAux]);
  }, [genders]);
  
  const setCountry = (country: string) => {
    if (!country) return;
    Backend(
      MicroServices.Roster,
      `/p13n/genders?country=${country}`
    )
      .then((response) => response.json())
      .then((genders) => setGenders(genders))
  }

  return {
    genders,
    setCountry,
    chart: [
      genderChart,
      colorChart,
    ]
  }
}

export default useGender;