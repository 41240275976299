import styled from "styled-components";
import { OrgJoinedExtended } from "./OtherOrgs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons";
import { getMonth, unixToDateFormatted } from "../../../helpers/dateHelper";
import { isDate } from "../../../guards/isDate";
import { formatCompactNumber } from "../../../helpers/numberHelper";
import { getColorsInitials, getInitials } from "../../../helpers/letterHelper";
import { NoAvatarHealerCardHome, AvatarHealerCardHome } from "../../BaseComponents/HomeCards/styles";

const OrgCard = ({
    active,
    data,
    onActivation,
}: {
    active: boolean,
    data: OrgJoinedExtended,
    onActivation: (id: string, action: boolean) => void,
}) => {
    const dateToFormat = (date: Date): string =>
        isDate(date)
            ? `${getMonth(date.getMonth())} ${date.getDate()}, ${date.getFullYear()}`
            : '';

    const getHealerNoAvatar = () => {
        const num = [...data.orgName].reduce((acc, curr) => acc + curr.charCodeAt(0), 0);
        const colors = getColorsInitials(num);
        return <NoAvatarHealerCardHome
            background_color={colors.background}
            text_color={colors.color}
        >
            {getInitials(data.orgName)}
        </NoAvatarHealerCardHome>;
    }

    return (<Card theme={{ main: active ? 'active' : '' }} key={data.orgId}>
        <CardItem>
            <IconRotate
                icon={faArrowsRotate}
                color={"black"}
                onClick={() => onActivation(data.orgId, true)}
            />

            <RowFlex>
                {data.logo ? (
                    <AvatarHealerCardHome src={data.logo} />
                ) : (
                    getHealerNoAvatar()
                )}
                <ColumnFlex>
                    <TitleCard>{data.orgName}</TitleCard>
                    <LocationText>{data.state},{' '}{data.country}</LocationText>
                </ColumnFlex>
            </RowFlex>

            <RowFlex>
                <IconUser src={'../icon_seeker.png'} />
                {formatCompactNumber(data.seekers)} Seekers
            </RowFlex>

            <ColumnFlex>
                <RowSpacedFlex>
                    <BasicCard>{data.admin.name}</BasicCard>
                    Admin
                </RowSpacedFlex>
                <EmailLabel>{data.admin.email}</EmailLabel>
            </ColumnFlex>

        </CardItem>
        <CardBack>
            <IconRotate
                icon={faArrowsRotate}
                color={"black"}
                onClick={() => onActivation(data.orgId, false)}
            />
            <ColumnFlex>
                <TitleCard>{data.orgName}</TitleCard>
                {data.state},{' '}{data.country}
            </ColumnFlex>

            <ColumnFlex>
                <RowSpacedFlex>
                    <TitleCard>{data.roles}</TitleCard>
                    Your role
                </RowSpacedFlex>
                <RowSpacedFlex>
                    <BasicCard>{unixToDateFormatted(data.dateJoined, dateToFormat)}</BasicCard>
                    Date joined
                </RowSpacedFlex>
            </ColumnFlex>

            <a href={data.website} target="_blank" rel="noopener noreferrer">
                <WebStyle>{data.website || ''}</WebStyle>
            </a>
        </CardBack>
    </Card>)
}

export default OrgCard;

const IconUser = styled.img`
    width: 18px;
    height: 18px;
    object-fit: contain;
`;

const WebStyle = styled.div`
    color: var(--absolute-zero);
    text-align: left;
    width: 100%;
    font-size: 12px;
    cursor: pointer;
    font-family: 'Inter-Bold';
    text-decoration: underline;
`

const ColumnFlex = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const LocationText = styled.div`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const RowSpacedFlex = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: #6B6B6B;
    white-space: nowrap;

    font-feature-settings: 'clig' off, 'liga' off;
    /* Medium/50 */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.2px;
`;

const RowFlex = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
`;

const IconRotate = styled(FontAwesomeIcon)`
    position: absolute;
    top: 11px;
    font-size: 15px;
    right: 11px;
    background-color: var(--simple-gray-2);
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
`;

const TitleCard = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align:left;
    color: #333;
`

const BasicCard = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    color: #333;
`

const EmailLabel = styled(BasicCard)`
    font-size: 12px;
`;

const TitleCardBack = styled(TitleCard)`
    margin-bottom: 15px;
`;

const CardItem = styled.div`
    width: 280px;
    height: 176px;
    border: 1px solid var(--mountain-mist);
    background-color: white;
    border-radius: 10px;
    padding: 17px;
    text-align: center;
    position: relative;
    transform: perspective(500px) rotateY(0deg);
    color: #333;

    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.2px;
`;

const CardBack = styled.div`
    width: 280px;
    height: 176px;
    border: 1px solid var(--mountain-mist);
    background-color: white;
    border-radius: 10px;
    padding: 17px;
    transform: perspective(500px) rotateY(180deg);
    color: #333;

    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.2px;
`;

const ScrollGuide = styled.div`
    flex-direction: column;
    gap: 5px;
    overflow: scroll;
    display: none;
`;

const Card = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    display: flex;
    width: 280px;
    height: 176px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 8px;
    overflow: hidden;

    /* &:hover {
        -webkit-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        -moz-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
    } */
    
    & > div{
        position: absolute;
        display: flex;
        backface-visibility: hidden;
        transition: 0.5s;
        flex-direction: column;
        gap: 15px;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
    }

    &.active ${CardItem}{
        transform: perspective(500px) rotateY(180deg)
    }

    &.active ${CardBack}{
        transform: perspective(500px) rotateY(360deg);
    }

    &.active ${CardBack} ${ScrollGuide}{
        display: flex;
    }

    &.active .seeActive{
        height: 245px;
    }

`;