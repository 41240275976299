import React, { useContext, useState, useRef } from 'react'
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faTimes, faChevronDown, faChevronUp, faCheck, faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import Form from './Form'
import Payment from './Payment'
import { ContextSuccess } from '../../Context';

export const calcStripeFee = (n: number) => Math.round(((n * 100) + (0.3 * 100)) / (1 - 0.029));

const index = (props: any) => {
    const currentSuccess = useContext(ContextSuccess);

    const [activeVibes, setActiveVibes] = useState(false)
    const [tabActive, setTabActive] = useState(1)

    const inputAmount = useRef<HTMLInputElement>(null);

    const [amount, setAmount] = useState<number>();

    const closePurchase = (e: boolean) => {
        props.purchaseActive(e)
        setTabActive(1)
    }

    const handleAmountChips = (amount: number) => {
        if (amount === 800) {
            setAmount(undefined);
            if (inputAmount.current) {
                inputAmount.current.focus();
            }
        } else {
            setAmount(amount);
        }

    }

    const handleGoToPay = () => {
        if (!amount || amount <= 0) {
            currentSuccess?.setSuccessData(false, true, "Amount is required");
            return;
        }
        setTabActive(2);
    }

    return (
        <Container>
            {tabActive === 1 ?
                <Container1>
                    <Header>
                        Purchase Good Vibes
                        <FontAwesomeIcon
                            icon={faTimes}
                            size="sm"
                            onClick={() => props.purchaseActive(false)}
                        />
                    </Header>
                    <Card>
                        <HeaderC>
                            <Logo></Logo>
                            <Vibes>
                                1 Good Vibe = $ 1.00 USD.
                            </Vibes>
                        </HeaderC>
                        <OptionsCard>
                            <TitleOpt>
                                Your Good vibes will be usefull for
                                <FontAwesomeIcon
                                    icon={!activeVibes ? faChevronDown : faChevronUp}
                                    size="sm"
                                    onClick={() => setActiveVibes(!activeVibes)}
                                />
                            </TitleOpt>
                            <ContainerOptions theme={{ main: activeVibes ? 'active' : 'inactive' }}>
                                <OptionCard>
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        size="sm"
                                    />
                                    To join VIP Communities
                                </OptionCard>
                                <OptionCard>
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        size="sm"
                                    />
                                    Pay for a ticket to an in-person events
                                </OptionCard>
                                <OptionCard>
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        size="sm"
                                    />
                                    Pay for attendance of an online session
                                </OptionCard>
                                <OptionCard>
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        size="sm"
                                    />
                                    Send & receive tips
                                </OptionCard>
                                <OptionCard>
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        size="sm"
                                    />
                                    To contribute to a fundraiser
                                </OptionCard>
                            </ContainerOptions>
                        </OptionsCard>
                    </Card>
                    <SetAmount>
                        Set Amount
                        <InputAmount ref={inputAmount} type='number' value={amount} onChange={(e) => setAmount(Number(e.currentTarget.value))} />
                        <TextAmount>$ 2.5 USD - Minimum Purchase Amount</TextAmount>
                        <OptionsAmount>
                            <Option onClick={() => handleAmountChips(5)}>5</Option>
                            <Option onClick={() => handleAmountChips(10)}>10</Option>
                            <Option onClick={() => handleAmountChips(20)}>20</Option>
                            <Option onClick={() => handleAmountChips(50)}>50</Option>
                            <Option onClick={() => handleAmountChips(100)}>100</Option>
                            <Option onClick={() => handleAmountChips(200)}>200</Option>
                            <Option onClick={() => handleAmountChips(800)}>Custom</Option>
                        </OptionsAmount>
                    </SetAmount>
                    <PaymentDetails>
                        <Title>Payment Details</Title>
                        <List>
                            <Items>
                                <TextPayment>Good vibes</TextPayment>
                                <TextPayment>{amount ? amount.toFixed(2) : ''} <VibeLogo />  </TextPayment>
                            </Items>
                            <Items>
                                <TextPayment>Transaction Fee</TextPayment>
                                <TextPayment>{amount ? ((calcStripeFee(amount) - (amount * 100)) / 100).toFixed(2) : ''} US$</TextPayment>
                            </Items>
                            <Items>
                                <TextPayment>Taxes</TextPayment>
                                <TextPayment>{amount ? (0).toFixed(2) : ''} US$</TextPayment>
                            </Items>
                            {/* <Items>
                                <TextPayment>Net</TextPayment>
                                <TextPayment>{amount ? amount.toFixed(2) : ''} US$</TextPayment>
                            </Items> */}
                            <Items>
                                <TextPayment>Total</TextPayment>
                                <TextPayment>{amount ? (calcStripeFee(amount) / 100).toFixed(2) : ''} US$</TextPayment>
                            </Items>
                        </List>
                    </PaymentDetails>
                    <ButtonContinue onClick={handleGoToPay}>
                        Proceed with Payment
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            size="sm"
                        />
                    </ButtonContinue>
                </Container1>
                :
                tabActive === 2 ?
                    <Container1>
                        <Form
                            setTabActive={setTabActive}
                            setPurchaseActive={closePurchase}
                            amount={amount!}
                        />
                    </Container1>
                    :
                    <Payment purchaseActive={closePurchase} />
            }
        </Container>
    )
}

const Container1 = styled.div`
    height: calc(100vh - 80px); //Header height
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    padding: 20px 30px;
`;

const VibeLogo = styled.img`
    content: url(../../../vibeLogo.svg);
    width: 20px;
    height: 20px;
`;

const TextHeader = styled.div`
    display: flex;
    gap: 15px;
`

const ButtonContinue = styled.div`
    color: white;
    border-radius: 8px;
    background: var(--dark-blue);
    height: 42px;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 8px;
    margin-top: 15px;
    position: fixed;
    width: calc(570px - 60px);
    bottom: 20px;
    cursor: pointer;
`

export const PaymentDetails = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`
export const Title = styled.div`
    color: var(--default-dark);
    font-family: "Inter-Bold";
    font-size: 16px;
    letter-spacing: 0.1px;
`
export const List = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const Items = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 7px 0;
    border-bottom: 1px solid #f2f2f2;
`

export const TextPayment = styled.div`
    color: #181818;
    font-family: 'Inter';
    font-size: 14px;
    letter-spacing: 0.2px;
    display: flex;
    gap: 5px;
    align-items: center;
`

export const TextAmount = styled.div`
    font-family: 'Inter';
    font-size: 12px;
    letter-spacing: 0.2px;
    width: 100%;
    text-align: left;
    margin-top: -10px;
`

const OptionsAmount = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: center;
    padding-bottom: 16px;
    border-bottom: 1px solid #F2F2F2;
`

const Option = styled.div`
    border: 1px solid #999796;
    min-width: 40px;
    justify-content: center;
    display: flex;
    height: 32px;
    padding: 4px 12px;
    align-items: center;
    border-radius: 16px;
    cursor: pointer;
`

const SetAmount = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Inter';
    font-size: 16px;
    gap: 15px;
`
const InputAmount = styled.input`
    height: 50px;
    border-bottom: 1px solid black;
    width: 100%;
    font-size: 24px;
    text-align: center;
`

const ContainerOptions = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    height: 0px;
    overflow:hidden;
    -webkit-transition: height 1s ease-in-out;
    -moz-transition: height 1s ease-in-out;
    -o-transition: height 1s ease-in-out;
    transition: height 1s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 25px;

    &.active{
        height:160px;
    }
    &.inactive{
        height:0px;
    }
`

const Card = styled.div`
    width: 100%;
    margin: 0 auto;
    border-radius: 8px;
    border: 1px solid #F2F2F2;
    background: #F2F0EF;
    box-shadow: 0px 2px 8px -1px rgba(27, 36, 44, 0.08), 0px 2px 2px -1px rgba(27, 36, 44, 0.04);
`

const HeaderC = styled.div`
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid #F2F2F2;
    background: #FFF;
    display: flex;
    padding: 8px 24px;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    font-family: "Inter-Bold";
`

const TitleOpt = styled.div`
    border-radius: 8px 8px 0px 0px;
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    font-family: "Inter-Bold";
    font-size: 16px;
    font-style: normal;
    letter-spacing: 0.1px;
`

const OptionsCard = styled.div``
const OptionCard = styled.div`
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
`


const logoVibe = require("../../assets/img/vibeBalance.png");
const Logo = styled.img`
    width: 24px;
    height: 24px;
    content: url("../../../vibeLogo.svg");
    background-size: contain;
    background-repeat: no-repeat;
`;
const Vibes = styled.div``

const Container = styled.div`
    height: calc(100vh - 80px); //Header height
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
`
export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    font-family: "Inter-Bold";
    font-size: 18px;
`


export default index