import { css } from "styled-components";

export const ValignTextMiddle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const RobotocondensedRegularNormalWhite16 = css`
  color: var(--white);
  font-family: var(--font-family-roboto_condensed-regular);
  font-size: var(--font-size-l2);
  font-weight: 400;
  font-style: normal;
`;

export const Fontawesome5proRegularNormalLavende = css`
  color: var(--lavender);
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: var(--font-size-xl2);
  font-weight: 400;
  font-style: normal;
`;

export const RobotocondensedBoldWhite16px = css`
  color: var(--white);
  font-family: var(--font-family-roboto_condensed);
  font-size: var(--font-size-l2);
  font-weight: 700;
  font-style: normal;
`;

export const RobotocondensedBoldLavender16px = css`
  color: var(--lavender);
  font-family: var(--font-family-roboto_condensed);
  font-size: var(--font-size-l2);
  font-weight: 700;
  font-style: normal;
`;

export const RobotoBoldWhite16px = css`
  color: var(--white);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l2);
  font-weight: 700;
  font-style: normal;
`;

export const Fontawesome5proSolidNormalAbsoluteZ = css`
  color: var(--absolute-zero);
  font-family: var(--font-family-font_awesome_5_pro-solid);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`;

export const RobotocondensedRegularNormalAbsolut = css`
  color: var(--absolute-zero);
  font-family: var(--font-family-roboto_condensed-regular);
  font-size: var(--font-size-l2);
  font-weight: 400;
  font-style: normal;
`;

export const RobotocondensedRegularNormalLavende = css`
  color: var(--lavender);
  font-family: var(--font-family-roboto_condensed-regular);
  font-size: var(--font-size-l2);
  font-weight: 400;
  font-style: normal;
`;
