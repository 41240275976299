import styled from "styled-components";
import { ChatServiceInterface } from "../../../../interfaces/chat/ChatServiceInterface";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useContext } from "react";
import { ChatGroupParticipant } from "../../../../interfaces/chat/ChatGroupParticipant";
import { CircleStatusHeader } from "../../../Header";
import { ClickableIcon } from "../../../../containers/SuperAdmin/TClubs/components";
import { WHITE } from "../../../ClubIconsNavBar";
import { useParams } from "react-router-dom";
import { CheckBoxes } from "../components/components";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ContextSuccess } from "../../../../Context";
import { Loader } from "../../../BaseComponents/Loader";

toast.configure();

const CreateChatGroup = ({ ChatServiceInstance }: { ChatServiceInstance: ChatServiceInterface }) => {
    const { t, i18n } = useTranslation();
    const currentSuccess = useContext(ContextSuccess);
    const { clubId = "" } = useParams();

    const [groupName, setGroupName] = useState<string>('');
    const [participantsAdded, setParticipantsAdded] = useState<ChatGroupParticipant[]>([]);
    const [isSending, setIsSending] = useState<boolean>(false);
    const [chatSearched, setParticipantSearched] = useState<string>('');

    const checkBoxesClubOptions: Record<string, string> = {
        'All': "ALL",
        'Advisors': "ADVISOR",
        'Officers': "OFFICER",
        'Members': "MEMBER",
        'Followers': "FOLLOWER"
    };
    const [checkBoxesSelected, setCheckBoxesSelected] = useState<Record<string, string>>({});
    const [checkBoxesDisabled, setCheckBoxesDisabled] = useState<Record<string, string>>({});

    useEffect(() => {
        ChatServiceInstance.getPossibleParticipants(clubId !== '' ? clubId : undefined)
    }, [])

    useEffect(() => {
        ChatServiceInstance.getPossibleParticipants(clubId !== '' ? clubId : undefined)
    }, [clubId])

    useEffect(() => {
        setParticipantsAdded(ChatServiceInstance.participantsToAdd.filter((participant) => participant.added === true));
    }, [ChatServiceInstance.participantsToAdd])

    const eraseParticipant = (username: string, memberType?: string) => {
        setParticipantsAdded(participantsAdded.filter((participant) => participant.username !== username));
        ChatServiceInstance.updateParticipantsToAdd(ChatServiceInstance.participantsToAdd.map((participant) => {
            if (memberType) {
                if (participant.username === username && participant.memberType === memberType) {
                    participant.added = false;
                }
            }
            else {
                if (participant.username === username) {
                    participant.added = false;
                }
            }
            return participant;
        }));

    }

    const addParticipant = (username: string, memberType?: string) => {
        ChatServiceInstance.updateParticipantsToAdd(ChatServiceInstance.participantsToAdd.map((participant) => {
            if (memberType) {
                if (participant.username === username && participant.memberType === memberType) {
                    participant.added = true;
                }
            }
            else {
                if (participant.username === username) {
                    participant.added = true;
                }
            }
            return participant;
        }));
    }

    const handleCheckBoxes = (event: any) => {
        if (event.target.value === 'All') {
            if (event.target.checked) {
                const disabledBoxes: Record<string, string> = {};
                for (let b in checkBoxesClubOptions) {
                    if (b !== 'All') {
                        disabledBoxes[b] = checkBoxesClubOptions[b];
                    }
                }
                setCheckBoxesSelected(checkBoxesClubOptions);
                setCheckBoxesDisabled(disabledBoxes);
                ChatServiceInstance.participantsToAdd.forEach((participant) => addParticipant(participant.username));
            }
            else {
                setCheckBoxesSelected({});
                setCheckBoxesDisabled({});
                ChatServiceInstance.participantsToAdd.forEach((participant) => eraseParticipant(participant.username));
            }
        }
        else {
            let updateList: Record<string, string> = { ...(checkBoxesSelected || {}) };
            if (event.target.checked) {
                updateList[event.target.value] = checkBoxesClubOptions[event.target.value];
                ChatServiceInstance.participantsToAdd.forEach((participant) => addParticipant(participant.username, checkBoxesClubOptions[event.target.value]));
                setCheckBoxesSelected(updateList);
            } else {
                let eraseList: Record<string, string> = {};
                for (let b in updateList) {
                    if (b !== event.target.value) {
                        eraseList[b] = updateList[b];
                    }
                }
                ChatServiceInstance.participantsToAdd.forEach((participant) => eraseParticipant(participant.username, checkBoxesClubOptions[event.target.value]));
                setCheckBoxesSelected(eraseList);
            }
        }
    }

    const checkGuards = () => {
        if (groupName === '') {
            currentSuccess?.setSuccessData(false, true, t('chat_toast_add_name'));
            return false;
        }

        if (participantsAdded.length === 0) {
            currentSuccess?.setSuccessData(false, true, t('chat_toast_add_participants'));
            return false;
        }

        return true;
    }

    const handleSearchParticipant = (event: React.FormEvent<HTMLInputElement>) => {
        setParticipantSearched(event.currentTarget.value);
    }

    const getFilteredParticipants = (listName: ChatGroupParticipant[]) => {
        return listName.filter((item: ChatGroupParticipant) => {
            if (!chatSearched) return true;
            if (item.fullName.toUpperCase().includes(chatSearched) ||
                item.fullName.toLowerCase().includes(chatSearched) ||
                item.fullName.includes(chatSearched)
            ) {
                return true;
            }
            return false;
        })
    }

    const handleCreate = () => {
        setIsSending(true);
        if (!checkGuards()) {
            setIsSending(false);
            return;
        }

        if (clubId !== '') {
            ChatServiceInstance.createGroupChat(
                false, //viewable is the option for clubs that can be seen by any member of the club but only some can talk
                groupName,
                Object.keys(checkBoxesSelected).includes('Officers'),
                Object.keys(checkBoxesSelected).includes('Members'),
                Object.keys(checkBoxesSelected).includes('Followers'),
                Object.keys(checkBoxesSelected).includes('Advisors'),
                participantsAdded.map((usr) => usr.username), //list of usernames to be added in the chat (If a user is included in some 'checked' role, the user can be in the list but it is unnecessary. Use the list for 'special' users)
                clubId
            )
        } else {
            ChatServiceInstance.createGroupChat(
                false, //viewable for non-club group chat is unused (future feature)
                groupName,
                undefined,
                undefined,
                undefined,
                undefined,
                participantsAdded.map((usr) => usr.username),
                undefined);
        }

        setIsSending(false);
    }

    return (
        <Header>
            <TitleWrapper>
                <Title>{t("chat_new_group")}</Title>
                <CreateButton
                    onClick={handleCreate}
                    disabled={isSending}
                >
                    {(isSending) ? (
                        <Loader />
                    ) : t("chat_create_group")}
                </CreateButton>
            </TitleWrapper>
            <AddName
                placeholder={t("chat_new_group_name")}
                value={groupName}
                onChange={(e) => setGroupName(e.currentTarget.value)}
            />
            <RemainParticipants>
                <TextRemain>{`${participantsAdded.length} ${t("chat_new_group_participants")}`}</TextRemain>
                <RowFlex>
                    {participantsAdded.map((participant) => (
                        <ColumnFlex>
                            <ImageContainer>
                                {(Object.keys(checkBoxesSelected).length === 0) && (
                                    <CleanParticipant>
                                        <ClickableIcon
                                            icon="xmark"
                                            size='xs'
                                            color={WHITE}
                                            onClick={() => eraseParticipant(participant.username)}
                                        />
                                    </CleanParticipant>
                                )}
                                <CircleStatusHeader status={participant.status || 'available'} />
                                <Avatar src={participant.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} />
                            </ImageContainer>
                            <NameWrapper>
                                <Text>{participant.fullName}</Text>
                            </NameWrapper>
                        </ColumnFlex>
                    ))}
                </RowFlex>
            </RemainParticipants>
            <TitleWrapParticipants>
                <TitleParticipants>{t("chat_add_participants")}</TitleParticipants>
                <ChatSearchWrapper>
                    <ClickableIcon
                        icon="magnifying-glass"
                        size='sm'
                        color={WHITE}
                    />
                    <InputSearchParticipants
                        placeholder={t('comm__search')}
                        value={chatSearched}
                        onChange={handleSearchParticipant}
                    />
                </ChatSearchWrapper>
            </TitleWrapParticipants>
            {(clubId !== '') && (
                <MemberWrapper>
                    <CheckBoxes
                        options={checkBoxesClubOptions}
                        selected={checkBoxesSelected}
                        disabled={checkBoxesDisabled}
                        onChangeMethod={handleCheckBoxes}
                    />
                </MemberWrapper>
            )}
            <ParticipantsList>
                {getFilteredParticipants(ChatServiceInstance.participantsToAdd).map((participant) => (
                    <ParticipantWrapper
                        onClick={() => addParticipant(participant.username)}
                    >
                        <InfoWrapper>
                            <ImageContainerParticipants>
                                <CircleStatusHeader status={participant.status || 'available'} />
                                <Avatar src={participant.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} />
                            </ImageContainerParticipants>
                            <Text>{participant.fullName}</Text>
                        </InfoWrapper>
                        <RoundedAdd active={participant.added} />
                    </ParticipantWrapper>
                ))}
            </ParticipantsList>
        </Header>
    );
}

export default CreateChatGroup;

const ChatSearchWrapper = styled.div`
    display: flex;
    flex-direction: row;
    background-color: var(--t-blue);
    border-radius: 5px;
    width: 190px;
    justify-items: space-between;
    align-items: center;
    padding: 5px;
`;

const InputSearchParticipants = styled.input`
    box-sizing: border-box;
    width: 100%;
    height: 25px;
    background-color: transparent;
    padding-left: 10px;
    color: white;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    outline: none;
    border: 0px;

    ::placeholder {
        color: white;
        font-family: 'Inter';
        font-weight: 400;
        font-size: 16px;
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 27px;
    align-items: flex-start;
    height: 100%;
`;

const Avatar = styled.img`
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    object-fit: cover;
    border-radius: 50%;
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--onyx);
    border-radius: 36px;
    position: relative;
    width: fit-content;
`;

const ImageContainerParticipants = styled(ImageContainer)`
    margin-left: 0px;
`;

const AddName = styled.input`
    box-sizing: border-box;
    width: 94.5%;
    height: 37px;
    background-color: var(--t-blue);
    padding-left: 10px;
    color: white;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    outline: none;
    border: 0px;
    border-radius: 5px;

    margin-left: 20px;

    ::placeholder {
        color: white;
        font-family: 'Inter';
        font-weight: 400;
        font-size: 16px;
    }
`;

const RowFlex = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    margin-top: 15px;
    overflow: scroll;
`;

const MemberWrapper = styled.div`
    box-sizing: border-box;
    width: 80%;
    margin: 10px 0px 10px 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TitleWrapper = styled.div`
    box-sizing: border-box;
    width: 94.5%;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
`;

const TitleWrapParticipants = styled(TitleWrapper)`
    margin: 10px 0px 5px 20px;
`;

const ColumnFlex = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    width: 100px;
`;

const Title = styled.h1`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 28px;
    text-align: center;
    color: var(-- t-blue);
`;

const TitleParticipants = styled(Title)`
    font-size: 20px;
    line-height: 22px;
    margin-top: 10px;
    color: var(--t-blue);
    padding-bottom: 10px;
`;

const Subtitle = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: var(--lavender);
`;

const Text = styled(Subtitle)`
    color: var(--t-blue);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const TextRemain = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: var(--t-blue);
    position: absolute;
    left: 10px;
    top: -10px;
    background-color: var(--background-light);
    padding: 0px 5px 0px 5px;
`;

const NameWrapper = styled.div`
    display: flex;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    justify-content: center;
`;

const RemainParticipants = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 110px;
    //height: 100%;
    border: 1px solid var(--t-blue);
    border-radius: 5px;
    margin-top: 10px;

    box-sizing: border-box;
    width: 94.5%;
    background-color: transparent;
    margin-left: 20px;

    padding: 0px 10px 8px 10px;
`;

export const CleanParticipant = styled.div`
    padding: 2px 4px 0px 5px;
    position: absolute;
    left: 30px;
    top: 0px;
    border-radius: 100px;
    background-color: var(--onyx);
`;

const CreateButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 74px;
    height: 40px;
    background: var(--t-blue);
    border-radius: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: white;
    border: 0px;
    cursor: pointer;
`;

const ParticipantsList = styled.div`
    display: grid;
    grid-template-columns: repeat( 
        auto-fit, 
        minmax( 
            150px, 
            215px  
        ) 
    );
    gap: 15px;
    width: 94.5%;
    height: auto;
    margin: 0px 0px 0px 20px;
    overflow-y: scroll; //@todo add correct scroll bar
    overflow-x: hidden;
`;

const ParticipantWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 215px;
    min-width: 150px;
    gap: 5px;
    justify-content: space-between;

    cursor: pointer;
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 195px;
    min-width: 100px;
    gap: 5px;
    justify-content: flex-start;
`;

interface RoundedAddProps {
    active: boolean
}

const RoundedAdd = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50px;
    border: 2px solid var(--t-blue);
    background-color: ${(props: RoundedAddProps) => props.active ? "var(--t-blue)" : "transparent"};
`;