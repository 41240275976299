import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import { ClubType } from "../../../interfaces/club/ClubList";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useClubsList, { getRole } from "../../../hooks/Clubs/useClubsList";
import SelectItem from "../../../interfaces/SelectItem";
import { RootState } from "../../../store/store";
import { unixToDateFormatted } from "../../../helpers/dateHelper";
import BlankTable from "../../BaseComponents/BlankTable";
import Selector from "../../BaseComponents/Selector";
import SearchInput from "../../BaseComponents/SearchInput";
import IPaymentInfo from "../../../interfaces/user/IPaymentINfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { getTCommsByCountry } from "../../../store/actions/TClubsActions";
import { getHealerServices } from "../../../store/actions/modalityActions";
import ModalCommunities from "../../signupsetuphealer/ModalCommunities";
import ModalSetup from "../../signupsetuphealer/ModalSetup";
import TClub from "@vibe/sdk/dist/interfaces/Club/TClubs/TClub/TClub";
import { customModality, customService } from "../../signupsetuphealer/Services";
import { customTComm } from "../../signupsetuphealer/InternalTeams";
import useModalitiesSA from "../../../hooks/Superadmin/Modalities/useModalitiesSA";
import IHS from "@vibe/sdk/interfaces/Modalities/hservices/HS";
import { PAGE_SIZES } from "../MyEvents/EventAdmin";
import { ContextSuccess } from "../../../Context";

import { onHideType } from "../../BaseComponents/Modals/ModalUnsaved";
import Modality from "@vibe/sdk/interfaces/Modalities/modalities/Modality/Modality";
import { BaseFieldState, getFieldState } from "../../BaseComponents/utils";
import { sortAlphabeticallyOnProperty } from "../../../containers/Signup/SignupHealerInd";
import Delivery from "@vibe/sdk/interfaces/Modalities/hservices/Delivery";
import useBenefitsSA from "../../../hooks/Superadmin/Modalities/useBESA";
import useTherapeuticAreasSA from "../../../hooks/Superadmin/Modalities/useTASA";
import ModalServices from "../../signupsetuphealer/ModalServices";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import IResponseError from "@vibe/sdk/dist/interfaces/IResponseError";
import { StyledFontAwesomeIcon } from "../../HealerProfile/components";
import { Button } from "../MyOrganization/MyOrganization";
import ModalTemplatesServices from "./ModalTemplatesServices";

const AdminCenterServices = () => {

    const dispatch = useDispatch();
    const currentSuccess = useContext(ContextSuccess);

    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
    const userRoles = useSelector((state: RootState) => state.getUser.userRoles);
    const userPayment: IPaymentInfo = useSelector((state: RootState) => state.getUser.paymentInfo);
    const services: IHS[] = useSelector((state: RootState) => state.getModality.getHealerServices);

    const { modalitiesPages, reloadModalities } = useModalitiesSA({
        pageSizeProp: 1000,
        searchName: '',
    });
    const { benefitsPages, reloadBenefits } = useBenefitsSA({
        pageSizeProp: 1000,
        searchName: '',
    });
    const { taPages, reloadTherapeuticAreas } = useTherapeuticAreasSA({
        pageSizeProp: 1000,
        searchName: '',
    });

    const [page, setPage] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: '', value: '' }));
    const [searchPage, setSearchPage] = useState<string>('');
    const options = PAGE_SIZES.map((num) => ({
        value: `${num}`,
        label: `${num} results`,
    }));

    const [showModal, setShowModal] = useState(false);
    const [showModalTemplates, setShowModalTemplates] = useState<boolean>(false);

    const [filteredModality, setFilteredModality] = useState<string>('');
    const [modality, setModality] = useState<Modality>({ ...customModality });

    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<string>();
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [serviceEdit, setServiceEdit] = useState<IHS>();

    const getFiltered = (listName: any[]) => {
        return listName.filter((item: any) => {
            if (!filteredModality) return true;

            if (item.name && (
                item.name.toUpperCase().includes(filteredModality) ||
                item.name.toLowerCase().includes(filteredModality) ||
                item.name.includes(filteredModality)
            )) {
                return true;
            }

            return false;
        })
    }

    const getDeliveryOneName = (delivery: Delivery[]): string => {
        const inPerson = delivery.some(delivery => delivery.inPerson)
        const online = delivery.some(delivery => delivery.online)
        const mixed = inPerson && online
        if (mixed) return 'Online and In Person';
        if (inPerson) return 'In Person';
        if (online) return 'Online';
        return 'Not specified';
    }

    const handleModalityEdit = (open: boolean, mod?: Modality, service?: IHS) => {
        setShowModalEdit(open);
        setServiceEdit(service);
    }

    const handleUpdate = (service_is: string) => {
        const s = services.find(s => s.id === service_is);
        s ? setServiceEdit(s) : setServiceEdit({ ...customService });
        setShowModalEdit(true);
    }

    const handleModality = (open: boolean, mod?: Modality) => {
        setShowModalTemplates(false);
        
        if (!userPayment.hasActiveSubscription) {
            currentSuccess?.setModalPayInfo('Ops! To create new ones you need an active subscription.')
            return;
        }

        if (mod) setModality(mod);
        else setModality({ ...customModality })
        setShowModal(open);
    }

    const getNames = (data: string[], type: 'BE' | 'TA') => {
        const result: string[] = [];

        (type === 'BE' ? benefitsPages[0] : taPages[0]).forEach(b => {
            if (data.includes(b.id)) result.push(b.name)
        })

        return result.join(', ');
    }

    const onHideDelete = (type: onHideType, id: string) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);
                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        return dataSDK.sdk.Modalities.deleteHS(userInfo?.username || '', id, dataSDK.token);
                    })
                    .then((results) => {
                        if (results.statusCode === 201) {
                            dispatch(getHealerServices(userInfo?.username || ''));
                            currentSuccess?.setLoadingModal(false);
                            currentSuccess?.setModalsOff();
                        }
                        else {
                            console.log((results.body as IResponseError).error || (results.body as IResponseError).message)
                        }
                    })
                    .finally(() => {
                        setDeleteModal(false);
                        setDeleteLoading(false);
                        setDeleteId(undefined);
                    })
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                break;

            default:
                break;
        }
    }

    const handleDelete = (data: string) => {
        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => onHideDelete(type, data),
            'Are you sure you want to delete this service?'
        );
    }

    useEffect(() => {
        dispatch(getHealerServices(userInfo?.username || ''));
        setPage({
            ...page,
            value: options[0]
        })
    }, [])

    return (
        <ContentAdmin hasTemplates={false/*!userRoles.includes('SUPER')*/}>
            <Label>
                Manage Services
                <Button onClick={() => setShowModalTemplates(true)}>
                    Add New Service
                    <StyledFontAwesomeIcon
                        size='sm'
                        color='white'
                        icon='plus'
                    />
                </Button>
            </Label>

            {/*(!userRoles.includes('SUPER')) && (
                <Templates>
                    <LabelTemplates>Add new service</LabelTemplates>
                    <Area onClick={() => handleModality(true)}>
                        Add custom service
                        <IconPlus
                            icon={faPlus}
                            size='sm'
                            color={"black"}
                        />
                    </Area>
                    {sortAlphabeticallyOnProperty(modalitiesPages[0], 'name').map((item: Modality) => (
                        <Area onClick={() => handleModality(true, item)}>
                            {item.name}
                            <IconPlus
                                icon={faPlus}
                                size='sm'
                                color={"black"}
                            />
                        </Area>
                    ))}
                </Templates>
            )*/}

            <TableContainer>
                <WrapperRow>
                    <SearchInput
                        filteredItem={filteredModality}
                        setFilteredItem={setFilteredModality}
                        border={'1px solid var(--simple-gray)'}
                        placeholder={'Search Service'}
                    />
                    {/* <WrapperRow2>
                        <LabelLittle>Viewing</LabelLittle>
                        <Selector
                            options={options}
                            selected={page}
                            setSelected={setPage}
                            searchItem={searchPage}
                            setSearchItem={setSearchPage}
                            backgroundColor={'var(--t-blue)'}
                            border={'0px'}
                        />
                    </WrapperRow2> */}
                </WrapperRow>
                <Scroll>
                    <BlankTable
                        headerColored
                        border
                        maxWidth={'100px'}
                        backgroundColor={'white'}
                        headers={[
                            'Name',
                            'Description',
                            'Delivery',
                            'Benefits',
                            'Conditions Treated',
                            'Created at'
                        ]}
                        items={getFiltered(services).map((service) => ({
                            id: service.id,
                            labels: [
                                service.name,
                                service.description,
                                getDeliveryOneName(service.delivery || []),
                                getNames(service.BEIds || [], 'BE'),
                                getNames(service.TAIds || [], 'TA'),
                                unixToDateFormatted(service.createdAt || service.id)
                            ]
                        }))}
                        actions={['UPDATE', 'DELETE']}
                        actionsHandlers={{
                            handleDelete,
                            handleUpdate,
                        }}
                    />
                </Scroll>
            </TableContainer>

            <ModalServices
                showModal={showModal}
                onHide={handleModality}
                data={modality}
            />
            <ModalServices
                edit
                showModal={showModalEdit}
                onHide={handleModalityEdit}
                data={modality}
                serviceInfo={serviceEdit}
            />
            <ModalTemplatesServices
                templates={sortAlphabeticallyOnProperty(modalitiesPages[0], 'name')}
                setTemplate={handleModality}
                show={showModalTemplates}
                onHide={() => setShowModalTemplates(false)}
            />
        </ContentAdmin>
    )
}

const Scroll = styled.div`
    display: flex;
    width: 100%;
    overflow-y: scroll;
`;

const IconPlus = styled(FontAwesomeIcon)`
    cursor: pointer;
`;

const Area = styled.div`
    border-bottom: 1px solid var(--simple-gray);
    font-family: 'Inter';
    line-height: 18px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px 10px 10px;
    gap: 8px;
    align-items: center;
    text-align: left;
`

const Label = styled.div`
    color: var(--t-blue);
    font-family: 'Inter-bold';
    font-size: 24px;
    font-weight: bold;
    grid-area: 1 / 1 / 2 / 3;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    width: 100%;
`;

const LabelTemplates = styled.h1`
    color: var(--t-blue);
    font-family: 'Inter-bold';
    font-size: 16px;
    font-weight: bold;
`;

const LabelLittle = styled.h1`
    color: var(--t-blue);
    font-family: 'Inter';
    font-size: 16px;
    font-weight: normal;
`;

const ContentAdmin = styled.div`
    display: grid;
    grid-template-columns: ${({ hasTemplates }: { hasTemplates: boolean }) => hasTemplates ? '20% calc(80% - 20px)' : '100%'};
    grid-template-rows: 40px 1fr;
    grid-column-gap: ${({ hasTemplates }: { hasTemplates: boolean }) => hasTemplates ? '20px' : '0px'};
    grid-row-gap: 10px;
    width: 100%;
    height: 100%;
    padding: 30px;
    justify-items: flex-start;
`;

const Templates = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;
    flex-direction: column;
    background-color: var(--t-off-white);
    height: 100%;
    border-radius: 9px;
    align-items: flex-start;
    padding: 15px;
    overflow: scroll;
`;

const TableContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;
    flex-direction: column;
`;

const WrapperRow = styled.div`
    display: flex;
    width: 100%;
    max-width: 400px;
    gap: 200px;
    align-items: center;
    height: 40px;
`;

const WrapperRow2 = styled(WrapperRow)`
    gap: 10px;
    max-width: 300px;
`;

export default AdminCenterServices;