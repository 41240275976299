import { AnyAction } from 'redux';
import { LOGOUT_TOKENS, REFRESH_TOKEN_ERROR, REFRESH_TOKEN_SUCCESS, SAVE_TOKENS, START_REFRESH_TOKEN } from '../constants/tokenConstants';

interface tokenState {
  accessToken: string;
  IDToken: string;
  refreshToken: string;
  refreshing: boolean;
  refreshError?: any;
}

const initialState: tokenState = {
  accessToken: '',
  IDToken: '',
  refreshToken: '',
  refreshing: false,
  refreshError: undefined
};

export const getTokenReducer = (state = initialState, action: AnyAction): tokenState => {
  switch (action.type) {

    case LOGOUT_TOKENS:
      return initialState;
      
    case SAVE_TOKENS:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        IDToken: action.payload.IDToken,
        refreshToken: action.payload.refreshToken
      }

    case START_REFRESH_TOKEN:
      return {
        ...state,
        refreshing:true
      }

    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        refreshing:false,
        accessToken: action.payload.accessToken,
        IDToken: action.payload.IDToken,
        refreshToken: action.payload.refreshToken
      }

    case REFRESH_TOKEN_ERROR:
      return {
        ...state,
        refreshing:false,
        refreshError:action.payload
      }

    default:
      return state;
  }
};
