
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { IOrganizationExtended } from "@vibe/sdk/interfaces/Admin/Organization";
import { useContext, useEffect, useState } from "react";
import { ApproveOrganization, getOrgProfile } from "../../../store/actions/orgsActions";
import { RootState } from "../../../store/store";
import ButtonSpinner from "../../BaseComponents/ButtonSpinner";
import { ContextSuccess } from "../../../Context";
import { getUserInfoProfile } from "../../../store/actions/userActions";
import Breadcrumb, { CrumbInterface } from "../../BaseComponents/Breadcrumb";
import { BaseFieldState, getFieldState } from "../../BaseComponents/utils";
import { LogoInterface } from "../../signupsetuphealer/Introduction";
import HealerTables from "./HealerTables";
import HealerDescription from "./HealerDescription";
import { Loader } from "../../BaseComponents/Loader";

const HealersView = () => {
    const currentSuccess = useContext(ContextSuccess);
    const { id = '' } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loadingApproveOrg = useSelector((state: RootState) => state.getOrgs.loadingApproveOrg || false);
    const loadingOrgHealerProfile = useSelector((state: RootState) => state.getOrgs.loadingOrgHealerProfile || false);
    const orgProfile: IOrganizationExtended = useSelector((state: RootState) => state.getOrgs.orgHealerProfile);

    const [showTables, setShowTables] = useState<boolean>(false);
    const [logoOrg, setLogoOrg] = useState<BaseFieldState<LogoInterface>>(getFieldState({ file: '', fileLink: '' }));

    const reloadOrg = () => {
        dispatch(getOrgProfile(id));
    };

    const reloadUser = () => {
        dispatch(getUserInfoProfile(orgProfile.created_by, 'OA'));
    };

    const handleApprove = () => {
        dispatch<any>(ApproveOrganization(id))
            .then((results: string) => {
                if (results === 'Approved') currentSuccess?.setSuccessData(true, true, 'Healer approved!');
                else currentSuccess?.setSuccessData(false, true, 'Error approving Healer', typeof results === 'string' ? results : '');
            })
            .catch(console.log)
            .finally(() => reloadOrg());
    }

    const goTables = () => {
        setShowTables(true);
        navigate(`../../../../../superadmin/healers/${id}/view`);
    }

    const getCrumbs = (): CrumbInterface[] => {
        if(orgProfile.type !== 'INDEPENDENT') return [{
            navigate: () => navigate('../../../../../superadmin/healers'),
            label: 'Healers'
        }, {
            navigate: () => {},
            label: orgProfile?.name || 'View Healer'
        }];

        if(showTables) return [{
            navigate: () => navigate('../../../../../superadmin/healers'),
            label: 'Healers'
        },
        {
            navigate: () => {},
            label: 'Healer Organizations'
        }];

        return [{
            navigate: () => navigate('../../../../../superadmin/healers'),
            label: 'Healers'
        },
        {
            navigate: goTables,
            label: 'Healer Organizations'
        },
        {
            navigate: () => {},
            label: orgProfile?.name || 'View Healer'
        }];
    }

    useEffect(() => {
        reloadUser();
        setLogoOrg(getFieldState({
            file: orgProfile.logo || '',
            fileLink: orgProfile.logo || ''
        }));

        if(orgProfile.type === 'INDEPENDENT') setShowTables(true);
        else setShowTables(false);

    }, [orgProfile]);

    useEffect(() => {
        reloadOrg();
    }, [id]);

    return (
        <ContentFlexAdmin centered={loadingOrgHealerProfile}>
            {loadingOrgHealerProfile ? (<Loader color={'white'} />) : (
                <>
                    <HeaderAdmin>
                        <Breadcrumb
                            home={() => navigate('../../../../../superadmin')}
                            crumbs={getCrumbs()} />
                        {!showTables && <WrapperButtons>
                            {orgProfile.status != 'ACTIVE' ?
                                <ButtonSpinner
                                    loading={loadingApproveOrg}
                                    handleClick={handleApprove}
                                    text={'Approve'}
                                    hasIcon={false}
                                    color={'white'}
                                />
                                : <><span></span></>}
                            <Button onClick={() => navigate(orgProfile.type === 'INDEPENDENT' ? `../../../../../superadmin/seekers/${orgProfile.created_by}/update` : `../../../../../superadmin/healers/${id}/update`)}>Edit Info</Button>
                        </WrapperButtons>}
                    </HeaderAdmin>

                    {showTables ? (<HealerTables setShowTables={setShowTables}/>) : (<HealerDescription logoOrg={logoOrg} setLogoOrg={setLogoOrg} />)}
                </>
            )}
        </ContentFlexAdmin>
    )
};

export const WrapperInfo = styled.div`
    display: grid;
    width: 100%;

    color: #333;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;

    grid-template-columns: 0.5fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    justify-items: flex-start;
    align-items: center;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    align-items: flex-start;
`;

export const ColumnA = styled(Column)`
    grid-area: 2 / 1 / 3 / 2;
`;

export const ColumnB = styled(Column)`
    grid-area: 2 / 2 / 3 / 3;
`;

export const ContentAdmin = styled.div`
    display: grid;
    width: 100%;
    height: calc(100vh - 80px);

    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 0.1fr 1fr 0.1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 20px;

    padding: 50px;
`;

interface ContentFlexProps {
    centered?: boolean
    scroll?: boolean
    padding?: string
}

export const ContentFlexAdmin = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 80px);
    padding: ${({padding = ''}: ContentFlexProps) => padding ? padding : '25px 0px 10px 50px'};
    gap: 25px;

    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    text-align: left;

    align-items: ${({centered = false}: ContentFlexProps) => centered ? 'center' : 'flex-start'};
    overflow: ${({scroll = false}: ContentFlexProps) => scroll ? 'scroll' : 'hidden'};
`;

export const HeaderAdmin = styled.div`
    display: flex;
    justify-content: space-between;
    color: var(--t-blue);
    font-family: 'Inter-bold';
    font-size: 24px;
    font-weight: bold;
    align-items: center;

    width: 100%;
`;

export const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 200px;
    min-width: 100px;
    height: 100%;
    padding: 10px 20px;
    border-radius: 11px;
    background-color: var(--simple-blue-dark);
    border: 0;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: white;

    cursor: pointer;
`;

const WrapperButtons = styled.div`
  display: flex;
  height: 32px;
  gap: 15px;
  width: 230px;
`;

export default HealersView;
