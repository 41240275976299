import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { userForgotPassword } from "../../store/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import ButtonSpinner from "../BaseComponents/ButtonSpinner";
import { ContextSuccess } from "../../Context";
import { Imp, Row1 } from "../../containers/Website/Form";

const FormForgotPassword = ({
    onHide,
    goFormRestore,
}: {
    onHide: () => void
    goFormRestore?: () => void
}) => {

    const currentSuccess = useContext(ContextSuccess);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const loadingForgot = useSelector((state: any) => state.getUser.loadingForgot || false);

    const handleForgot = () => {
        if (username === '') {
            currentSuccess?.setSuccessData(false, true, 'Missing data!', 'Username is required.');
            return;
        }

        dispatch<any>(userForgotPassword(username))
            .then((results: any) => {
                if (results) {
                    if (goFormRestore) goFormRestore();
                    else navigate('/restore-password');

                    currentSuccess?.setSuccessData(true, true, 'Check your email!', 'We send you a code to reset your password.');
                }
                else {
                    currentSuccess?.setSuccessData(false, true, 'Error sending code.');
                }
            })
            .catch(console.log)
    };

    return (
        <Form>
            <TitleForm>Reset your password</TitleForm>
            <Inputs>
                <Row1>
                    <Label>Username<Imp>*</Imp></Label>
                    <Input
                        onChange={(e) => setUsername(e.currentTarget.value)}
                    />
                </Row1>
            </Inputs>
            <ButtonSpinner
                loading={loadingForgot}
                handleClick={handleForgot}
                text={'Send'}
                hasIcon={true}
                icon={'arrow-right'}
            />
            <ButtonCancel
                onClick={onHide}
            >
                Cancel
            </ButtonCancel>
        </Form>
    );
};

export default FormForgotPassword;

const Form = styled.div`
   width: 375px;
   margin-top: 50px;
`;

const TitleForm = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin: 0px 0 15px 0;
`;

export const Logo = styled.div`
    width: 100%;
    height: 50px;
    background-image: url(./logoVibe.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    top: 32px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: black;
    cursor: pointer;
    margin-left: 10px;
`;

const TextTerms = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%; 
    text-align: initial;
`

const NavLink = styled(Link)`
  text-decoration: underline;
`;

const StyledFontAwesomeIconSend = styled(FontAwesomeIcon)`
    color: white;
    margin-left: 15px;
    font-size: 16px;

    position: absolute;
    right: 15px;
`;

const ButtonSend = styled.div`
    width: 100%;
    height: 40px;
    padding: 10px 20px;
    border-radius: 11px;
    background-color: var(--simple-blue-dark-2);
    border: 0;
    color: white;
    font-weight: 700;
    margin-top: 25px;
    margin-right: 26px;
    position: relative;
    cursor: pointer;
    font-size: 24px;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
`
const ButtonCancel = styled.div`
    width: 100%;
    height: 40px;
    padding: 10px 20px;
    border-radius: 11px;
    background-color: white;
    border: 1px solid gray;
    color: black;
    font-weight: 700;
    margin-top: 25px;
    margin-right: 26px;
    position: relative;
    cursor: pointer;
    font-size: 24px;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
`


export const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
    margin: 7px 0;
`

const InputIcon = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    border-radius: 9px;
    align-items: center;
    padding-right: 15px;
    border: 1px solid var(--simple-gray);
    height: 40px;
    
    & ${Input}{
        border: 0;
        height: 38px;
    } 
`

export const InputPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0 0px 0 20px;
    font-size: 15px;
    font-family: 'Inter';
`;

const Select = styled.div`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Arial;
    border: 1px solid var(--simple-gray);
`

const Label = styled.div`    
    width: 100%;
    text-align: left;
    margin: 3px 0;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`
const Inputs = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
`

const TextForgot = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    padding: 12px 0 30px 0;
    text-decoration: underline;
`

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const LoginTitle = styled.p`
    font-size: 24px;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
`;