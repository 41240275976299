import styled from 'styled-components';
import { ClubCenterWrap } from '../ClubViewInformation';
import BlankTable from '../BaseComponents/BlankTable';
import SearchInput from '../BaseComponents/SearchInput';
import { useContext, useEffect, useState } from 'react';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { useParams } from 'react-router-dom';
import IResponseError from '@vibe/sdk/dist/interfaces/IResponseError';
import { IClubCode } from '@vibe/sdk/dist/interfaces/Club/clubCode';
import { formatLink } from '../../helpers/club/clubHelper';
import { ContextSuccess } from '../../Context';
import { onHideType } from '../BaseComponents/Modals/ModalUnsaved';
import { StyledFontAwesomeIcon } from '../HealerProfile/components';
import ModalMembers from '../signupsetuphealer/ModalMembers';
import { IFetchResponse } from '@vibe/sdk/dist/helpers/fetch.helper';
import { ICreateInviteResponse } from '@vibe/sdk/dist/interfaces/Invite/createInvite';
import { UserInClub } from '../signupsetuphealer/ModalSetup';
import { IClubExtended } from '@vibe/sdk/interfaces/Club/ClubInterface';
import { BaseFieldState, getFieldState } from '../BaseComponents/utils';

interface ClubLinksProps {
    infoClub: IClubExtended;
    reloadClub: any;
  }

const ClubLinks = (props: ClubLinksProps) => {
    const { id = "" } = useParams();
    const [valueFilter, setValueFilter] = useState<string>("");
    const [codes, setCodes] = useState<IClubCode[]>([]);
    const [showModalMembers, setShowModalMembers] = useState<boolean>(false);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const currentSuccess = useContext(ContextSuccess);
    const [teamIndividuallyMembers] = useState<BaseFieldState<UserInClub[]>>(getFieldState([]));
    const [teamRosters] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [clubCodes] = useState<Omit<IClubCode, 'club_id'>[]>([]);

    const reloadCodes = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Club.getCodesByClub(id, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) {
                    if (Array.isArray(results.body)) setCodes(results.body)
                    else setCodes([])
                    return;
                }
                console.log((results.body as IResponseError).error || (results.body as IResponseError).message);
            })
            .catch(console.error)
    }

    const handleCopy = (toCopy: string) => {
        navigator.clipboard.writeText(formatLink(toCopy));
        currentSuccess?.setSuccessData(true, true, 'Copied to clipboard');
    }

    const onHideDelete = (type: onHideType, id: string) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);
                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        return dataSDK.sdk.Club.deleteClubCode(id, dataSDK.token);
                    })
                    .then((results) => {
                        if (results.statusCode === 204) {
                            reloadCodes();
                            currentSuccess?.setModalsOff();
                            currentSuccess?.setLoadingModal(false);
                            currentSuccess?.setSuccessData(true, true, 'Invitation Link deleted successfully');
                            return;
                        }
                        currentSuccess?.setSuccessData(false, true, 'Error deleting club code');
                        console.log((results.body as IResponseError).error || (results.body as IResponseError).message)
                    })
                    .catch((error) => {
                        console.log('Error deleting club code', error);
                    })
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                break;

            default:
                break;
        }
    }

    const handleDelete = (data: string) => {
        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => onHideDelete(type, data),
            'Are you sure you want to delete this invitation link?'
        );
    }

    const getFiltered = (listName: any[]) => {
        return listName.filter((item: any) => {
            if (!valueFilter) return true;

            if (item.code && (
                item.code.toUpperCase().includes(valueFilter) ||
                item.code.toLowerCase().includes(valueFilter) ||
                item.code.includes(valueFilter)
            )) {
                return true;
            }

            if (formatLink(item.code) && (
                formatLink(item.code).toUpperCase().includes(valueFilter) ||
                formatLink(item.code).toLowerCase().includes(valueFilter) ||
                formatLink(item.code).includes(valueFilter)
            )) {
                return true;
            }

            return false;
        })
    }

    const handleOnClickInvite = (e: any) => {
        setShowModalMembers(true);
    };

    const onHideMembers = (
        type: 'CANCEL' | 'SUBMIT',
        individuals: UserInClub[],
        rosters: string[],
        codes?: Omit<IClubCode, 'club_id'>[],
        invites?: number
      ) => {
        if(type === 'CANCEL'){
          setShowModalMembers(false);
          return;
        }
    
        handleUpdateCommunity(individuals, rosters, codes ?? []);
      }
    
      const handleUpdateCommunity = (
        individuals: UserInClub[],
        rosters: string[],
        codes: Omit<IClubCode, 'club_id'>[]
      ) => {
        let dataSDK: getDataSDKResponse;
        const errors: string[] = [];
        if (!id) return;
    
        setLoadingSave(true);
    
        getDataSDK()
          .then((data: getDataSDKResponse) => {
            dataSDK = data;
    
            if ((individuals ?? []).length > 0) {
              return Promise.all([
                ...(individuals ?? [])
                  .map((inf: UserInClub) => dataSDK.sdk.Invite.createInvite({
                    email: inf.email,
                    org_id: props.infoClub.org_id,
                    club_id: id,
                    is_advisor: false,
                    firstName: inf.firstName,
                    lastName: inf.lastName,
                  }, dataSDK.token)),
                ] as Promise<(IFetchResponse<IResponseError | ICreateInviteResponse>)>[]);
            } else {
              return Promise.resolve([{
                body: {},
                statusCode: 201,
                headers: undefined,
              }] as IFetchResponse<IResponseError | ICreateInviteResponse>[]);
            }
          })
          .then((results: (IFetchResponse<IResponseError | ICreateInviteResponse>)[]) => {
    
            results.forEach((result) => {
              if (result.statusCode !== 201) {
                errors.push("Error adding individual members:", (result.body as IResponseError).error || (result.body as IResponseError).message);
                currentSuccess?.setSuccessData(false, true, "Error adding individual members", (result.body as IResponseError).error || (result.body as IResponseError).message);
              }
            })
    
            //Add code club link 
            if (codes.length > 0) {
              return Promise.all(codes.map((c) => dataSDK.sdk.Club.relateClubCodeToClub({
                code: c.code,
                club_id: id,
              }, dataSDK.token)))
            }
            else {
              return Promise.resolve([{
                statusCode: 201,
                headers: undefined,
              }] as IFetchResponse<IClubCode>[]);
            }
          })
          .then((results: IFetchResponse<IResponseError | IClubCode>[]) => {
    
            if (!results.every((r) => r.statusCode === 201)) {
              errors.push("Error adding club code:", (results[0].body as IResponseError).error || (results[0].body as IResponseError).message);
              currentSuccess?.setSuccessData(false, true, "Error adding club code", (results[0].body as IResponseError).error || (results[0].body as IResponseError).message);
            }
    
            //Invite rosters (if exist)
            if ((rosters || []).length > 0) {
              return Promise.all((rosters || [])
                .map((roster: string) => dataSDK.sdk.Invite.inviteRoster({
                  file: roster,
                  org_id: props.infoClub.org_id,
                  club_id: id,
                }, dataSDK.token)));
            }
            else {
              return Promise.resolve([{
                body: {},
                statusCode: 200,
                headers: undefined,
              }]);
            }
          })
          .then((results: IFetchResponse<IResponseError | undefined>[]) => {
    
            results.forEach((result) => {
              if (result.statusCode !== 200) {
                errors.push("Error adding rosters:", (result.body as IResponseError).error || (result.body as IResponseError).message);
                currentSuccess?.setSuccessData(false, true, "Error adding rosters", (result.body as IResponseError).error || (result.body as IResponseError).message);
              }
            })
            currentSuccess?.setSuccessData(true, true, "Invites sent and members added correctly.");
            setShowModalMembers(false);
            props.reloadClub();
            reloadCodes();
          })
          .catch((error) => {
            currentSuccess?.setSuccessData(false, true, "Error creating community", error);
            console.log("Error creating community", error);
          })
          .finally(() => {
            setLoadingSave(false);
          })
      }

    useEffect(() => {
        reloadCodes();
    }, [id])

    useEffect(() => {
        reloadCodes();
    }, [])

    return (
        <ClubCenterWrap>
            <Wrapper>
                <TitleMembers>Invitation Links</TitleMembers>
                <SearchInput
                    filteredItem={valueFilter}
                    setFilteredItem={setValueFilter}
                    backgroundColor={'var(--t-blue)'}
                    border={'0px'}
                    placeholder={`Search link`}
                />
                <Button onClick={handleOnClickInvite}>
                    <StyledFontAwesomeIcon
                        size='sm'
                        color='white'
                        icon='plus'
                    />
                    Create Link
                </Button>
            </Wrapper>
            <BlankTable
                headers={['Link', 'Maximum Invites', 'Invites Left', 'Expiration']}
                items={getFiltered(codes || []).map((code) => ({
                    id: code.code,
                    labels: [
                        formatLink(code.code),
                        `${code.max_members || '-'}`,
                        `${code.members_left || '-'}`,
                        code?.expirationTimestamp ? new Date(code.expirationTimestamp).toLocaleDateString() : ''
                    ]
                }))}
                actions={['COPY', 'DELETE']}
                actionsHandlers={{
                    handleDelete,
                    handleCopy,
                }}
                maxWidth={'150px'}
                onClickRow={handleCopy}
                cursor={'pointer'}
            />
            <ModalMembers
                showModal={showModalMembers}
                onHide={onHideMembers}
                individuals={teamIndividuallyMembers.value ?? []}
                rosters={teamRosters.value ?? []}
                codes={clubCodes ?? []}
                haveLoading
                loadingSave={loadingSave}
                tab={3}
            />
        </ClubCenterWrap>
    )
}

export default ClubLinks;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  padding: 10px 10px;
  border-radius: 11px;
  background-color: var(--simple-blue-dark);
  border: 0;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: white;
  gap: 10px;

  cursor: pointer;
`;

const WrapperInput = styled.div`
  width: 50%;
`;

const Wrapper = styled.div`
  height: fit-content;
  justify-content: space-between;
  align-items: center;

  display: grid;
  grid-template-columns: 35% calc(40% - 10px) calc(25% - 10px);
  grid-template-rows: 40px;
  grid-column-gap: 10px;
  width: 100%;
  gap: 10px;
`;

const TitleMembers = styled.div`
  font-family: 'Inter-bold';
  font-size: 22px;
  text-align: left;
  color: var(--t-blue);
`;