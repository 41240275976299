import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getUserInfoProfile } from "../../../store/actions/userActions";
import { RootState } from "../../../store/store";
import styled from "styled-components";
import { formatGender } from "../../../helpers/userHelper";
import { getRolesUsersView } from "../../../helpers/roleHelper";
import { capitalize } from "../../../helpers/letterHelper";
import { unixToDateFormatted } from "../../../helpers/dateHelper";

function AdminCenterMemberView() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id = "" } = useParams();

  const userProfile = useSelector((state: RootState) => state.getUser.userProfile);

  useEffect(() => {
    dispatch(getUserInfoProfile(id))
  }, []);

  useEffect(() => {
    dispatch(getUserInfoProfile(id))
  }, [id]);

  return (
    <ContentAdmin>

      <LabelTitle>
        View Member
        <ButtonMember onClick={() => navigate(`../../../../admin/members/${id}/update`)}>{t('button__update')}</ButtonMember>
      </LabelTitle>

      <ColumnA>
        <WrapperInfo>
          Name
          <PlaceInfo>{userProfile.firstname} {userProfile.lastname}</PlaceInfo>
        </WrapperInfo>
        <WrapperInfo>
          Email
          <PlaceInfo>{userProfile.email}</PlaceInfo>
        </WrapperInfo>
        <WrapperInfo>
          Gender
          <PlaceInfo>{formatGender(userProfile.gender)}</PlaceInfo>
        </WrapperInfo>
        <WrapperInfo>
          Phone Number
          <PlaceInfo>{userProfile.phoneNumber || ''}</PlaceInfo>
        </WrapperInfo>
        <WrapperInfo>
          Birthdate
          <PlaceInfo>{userProfile.birthdate || ''}</PlaceInfo>
        </WrapperInfo>
        <WrapperInfo>
          Roles
          <PlaceInfo>{getRolesUsersView(userProfile.roles).join(', ')}</PlaceInfo>
        </WrapperInfo>
        <WrapperInfo>
          Status
          <PlaceInfo>{capitalize(userProfile.status)}</PlaceInfo>
        </WrapperInfo>
        <WrapperInfo>
          Address
          <PlaceInfo>{userProfile.address || ''}</PlaceInfo>
        </WrapperInfo>
      </ColumnA>

      <ColumnB>
        <WrapperLogo>
          Avatar
          <LogoPlaceHolder src={userProfile.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'} />
        </WrapperLogo>
        <WrapperInfo>
          Country
          <PlaceInfo>{userProfile.country || ''}</PlaceInfo>
        </WrapperInfo>
        <WrapperInfo>
          State
          <PlaceInfo>{userProfile.state || ''}</PlaceInfo>
        </WrapperInfo>
        <WrapperInfo>
          City
          <PlaceInfo>{userProfile.city || ''}</PlaceInfo>
        </WrapperInfo>
        <WrapperInfo>
          Zipcode
          <PlaceInfo>{userProfile.zipcode || ''}</PlaceInfo>
        </WrapperInfo>
      </ColumnB>

      <LabelInfo>Registration Information</LabelInfo>

      <ColumnC>
        <WrapperInfo>
          Username
          <PlaceInfo>{userProfile.username || ''}</PlaceInfo>
        </WrapperInfo>
      </ColumnC>

      <ColumnD>
        <WrapperInfo>
          Creation Date
          <PlaceInfo>{unixToDateFormatted(userProfile.creationDate)}</PlaceInfo>
        </WrapperInfo>
      </ColumnD>

    </ContentAdmin>
  );
}

export const ButtonMember = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 200px;
  min-width: 100px;
  height: 100%;
  padding: 10px 20px;
  border-radius: 11px;
  background-color: var(--simple-blue-dark);
  border: 0;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: white;

  cursor: pointer;
`;

const PlaceInfo = styled.div`
  align-items: center;
  background-color: var(--t-blue);
  border-radius: 9px;
  display: flex;
  height: 39px;
  width: 100%;
  padding: 0 10px;

  color: white;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: normal;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  text-align: left;
`;

const WrapperInfo = styled.div`
  display: grid;
  width: 100%;

  color: var(--t-blue);
  font-family: 'Inter-bold';
  font-size: 16px;
  font-weight: bold;

  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  justify-items: flex-start;
  align-items: center;
`;

const WrapperLogo = styled(WrapperInfo)`
  align-items: flex-start;
`;

const LogoPlaceHolder = styled.img`
  width: 180px;
  height: 180px;
  min-width: 180px;
  min-height: 180px;
  max-width: 180px;
  max-height: 180px;
  border-radius: 8px;
  object-fit: cover;
`;

const ContentAdmin = styled.div`
  display: grid;
  width: 100%;
  height: calc(100vh - 80px);

  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 45px 1fr 22px 1fr;
  grid-column-gap: 25px;
  grid-row-gap: 35px;

  padding: 50px;
  justify-items: flex-start;
`;

const LabelTitle = styled.div`
  color: var(--t-blue);
  font-family: 'Inter-bold';
  font-size: 24px;
  font-weight: bold;
  grid-area: 1 / 1 / 2 / 3;

  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const LabelInfo = styled.h1`
  color: var(--t-blue);
  font-family: 'Inter-bold';
  font-size: 18px;
  font-weight: bold;
  grid-area: 3 / 1 / 4 / 3;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  align-items: flex-start;
`;

const ColumnA = styled(Column)`
  grid-area: 2 / 1 / 3 / 2;
`;

const ColumnB = styled(Column)`
  grid-area: 2 / 2 / 3 / 3;
`;

const ColumnC = styled(Column)`
  grid-area: 4 / 1 / 5 / 2;
`;

const ColumnD = styled(Column)`
  grid-area: 4 / 2 / 5 / 3;
`;

export default AdminCenterMemberView;
