import { useContext, useEffect, useMemo, useState } from "react";
import { render } from "react-dom";
import "./styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMyPendingClubs } from "../../store/actions/clubActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons";
import { WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";
import { IClubExtended } from "@vibe/sdk/interfaces/Club/ClubInterface";
import { RootState } from "../../store/store";
import ButtonSpinner from "../BaseComponents/ButtonSpinner";
import styled from "styled-components";
import IResponseError from "@vibe/sdk/dist/interfaces/IResponseError";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import { ContextSuccess } from "../../Context";

const HoverableDiv = ({
  handleMouseEnter,
  handleMouseLeave,
  infoClub
}: {
  handleMouseEnter: any
  handleMouseLeave: any
  infoClub: IClubExtended
}) => {
  const { t, i18n } = useTranslation();
  const { id = '' } = useParams();
  const userRoles = useSelector((state: RootState) => state.getUser.userRoles);

  return (
    <div className={`mainInfo ${!infoClub.is_setup && 'clubPendingSetup'}`}>
      <div
        className="clubrow-container"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >

        <img
          className="club-image"
          src={infoClub.clubLogo ?? infoClub.orgLogo ?? 'https://htmlcolorcodes.com/assets/images/colors/dark-gray-color-solid-background-1920x1080.png'}
          alt="club-image"
        />
        <div className="club-column">
          <h1 className={`${infoClub.id === id ? "robotocondensed-bold-white-16px" : "robotocondensed-bold-black-16px"} clubNameText`}>{infoClub.name}</h1>
          {(userRoles.includes(`SUPER`)) &&
            (<span className="roboto-bold-electric-violet-16px paddingRight15">{infoClub.orgName}</span>)}
          <div className={`club-school ${infoClub.id === id ? "robotocondensed-normal-white-16px" : "robotocondensed-normal-black-16px"}`}>
            {infoClub.members || 0} {t('comm__members')}
          </div>
        </div>
      </div>
    </div>
  );
};

const EmptyDiv = ({
  handleMouseEnter,
  handleMouseLeave,
  infoClub
}: {
  handleMouseEnter: any
  handleMouseLeave: any
  infoClub: IClubExtended
}) => {

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="clubrow-container-empty">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {(infoClub.is_setup) ? (
            <>
              <div className="row-hover">
                <FontAwesomeIcon
                  icon="list-alt"
                  size='lg'
                  color={WHITE}
                  className="iconMenu iconUsers  "
                />
                <div
                  style={{ marginLeft: "6px" }}
                  className="robotocondensed-normal-white-16px"
                >
                  {infoClub.posts || 0}
                </div>
              </div>
              <div className="row-hover">
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  size='lg'
                  color={WHITE}
                  className="iconMenu iconUsers"
                />
                <div
                  style={{ marginLeft: "6px" }}
                  className="robotocondensed-normal-white-16px"
                >
                  {infoClub.events || 0}
                </div>
              </div>
              <div className="row-hover">
                <FontAwesomeIcon
                  icon="user-group"
                  size='lg'
                  color={WHITE}
                  className="iconMenu iconUsers  "
                />
                <div
                  style={{ marginLeft: "6px" }}
                  className="robotocondensed-normal-white-16px"
                >
                  {infoClub.members || 0}
                </div>
              </div>
            </>
          ) : (
            <span className="HoveringClub-LeftPanel-Pending">Setup Pending</span>
          )}
        </div>
      </div>
    </div>
  );
};

function Index({
  infoClub,
  screen,
}: {
  infoClub: IClubExtended
  screen: 'Teams' | 'Communities' | 'Pending'
}) {

  const currentSuccess = useContext(ContextSuccess);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id = '' } = useParams();
  const { t, i18n } = useTranslation();

  const [isHovering, setIsHovering] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
  const organization = useSelector((state: RootState) => state.getOrgs.organizationInfo);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseEnterEmpty = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const deleteJoinRequest = () => {
    setLoadingDelete(true);
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Club.deleteJoinRequest(infoClub.id, userInfo?.username || '', dataSDK.token);
      })
      .then((results) => {
        if (results.statusCode == 200) {
          dispatch(getMyPendingClubs(userInfo?.username || ''));
        }
        else {
          const error = (results.body as IResponseError).error || (results.body as IResponseError).message;
          console.error(error);
          currentSuccess?.setSuccessData(false, true, 'Error deleting join request', error);
        }
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => setLoadingDelete(false));
  }

  return (
    <div style={{ width: "100%" }}>
      <div className="itemClubs" id={infoClub.id === id ? "color" : "transparent"}>
        {screen === "Pending" ? (
          <>
            <div className="clubrow-pending">

              <img
                className="club-image"
                src={infoClub.clubLogo ?? infoClub.orgLogo ?? 'https://htmlcolorcodes.com/assets/images/colors/dark-gray-color-solid-background-1920x1080.png'}
                alt="club-image"
              />

              <div className="club-column-2">
                <h1 className="robotocondensed-bold-black-16px clubNameText">{infoClub.name}</h1>
                <div className="club-school robotocondensed-normal-black-16px">
                  {infoClub.members || 0} {t('comm__members')}
                </div>
              </div>

              <Wrapper>
                <ButtonSpinner
                  loading={loadingDelete}
                  handleClick={deleteJoinRequest}
                  text={'Cancel'}
                  hasIcon={false}
                />
              </Wrapper>

            </div>
          </>
        ) : (
          <div onClick={() => navigate(`../../../../app/clubs/${infoClub.id}`)}>
            <HoverableDiv
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              infoClub={infoClub}
            />
            <EmptyDiv
              handleMouseEnter={handleMouseEnterEmpty}
              handleMouseLeave={handleMouseLeave}
              infoClub={infoClub}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const Wrapper = styled.div`
  width: 90px;
`;

export default Index;
