import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { getRelativeTime } from "../../../../helpers/chat/timeChatHelper";
import { ChatInterface } from "../../../../interfaces/chat/ChatInterface";
import { WHITE } from "../../../ClubIconsNavBar";
import { CircleStatusHeader } from "../../../Header";
import { ClickableIcon } from "../../../SuperCenter/SuperAdminComponents/basicForSACenter";
import { FromName, Group, Time } from "../../ChatCenter/components/components";

const ChatListOption = ({ chat }: { chat: ChatInterface }) => {
    const { id = '' } = useParams();
    const navigate = useNavigate();

    const [chatSelected, setChatSelected] = useState<boolean>(false);

    useEffect(() => {
        if (id === chat.from.username) {
            setChatSelected(true);
        }
        else {
            setChatSelected(false);
        }
    }, [id])

    return (
        <ChatListWrapper key={chat.from.username || ''} active={chatSelected} onClick={() => navigate(`/chat/conversation/${chat.from.username}`)}>
            <ChatList>
                <ImageContainer>
                    <CircleStatusHeader status={chat.from.status || 'available'} />
                    <Avatar src={chat.from.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} />
                </ImageContainer>
                <ColumnFlex>
                    <RowFlex>
                        <FromName active={chatSelected}>{chat.from.fullName || ''}</FromName>
                        <Time outgoing={chatSelected}>{(chat.lastMessage.timestamp !== 0) ?
                            getRelativeTime(chat.lastMessage.timestamp) : ''}
                        </Time>
                    </RowFlex>
                    <RowFlex>
                        {(chat.lastMessage.timestamp !== 0) ? (
                            <MessageContent active={chatSelected}>{chat.lastMessage.content || ''}</MessageContent>
                        ) : (
                            <Group outgoing={chatSelected}>{chat.lastMessage.content || ''}</Group>
                        )}
                        {(chat.muted) &&
                            <ClickableIcon
                                icon="volume-slash"
                                size='sm'
                                color={WHITE}
                            />}
                        {(chat.lastMessage.unread) && <PointUnread />}
                    </RowFlex>
                </ColumnFlex>
            </ChatList>
        </ChatListWrapper>
    );
}

export default ChatListOption;

const ChatList = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap:20px;
    margin-right: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const Avatar = styled.img`
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    object-fit: cover;
    border-radius: 50%;
`;

const ColumnFlex = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center !important;
    align-items: flex-start;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const RowFlex = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: center;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const MessageContent = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: ${({active = false}:{active?: boolean}) => active ? 'white' : 'black'};
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 85%;
`;

const PointUnread = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50px;
    background-color: var(--lavender);
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--onyx);
    border-radius: 36px;
    position: relative;
    margin-left: 8px;
`;

interface ChatListWrapperProps {
    active: boolean;
}

const ChatListWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    background: ${(props: ChatListWrapperProps) => props.active ? 'var(--t-blue)' : 'transparent'};
    border-radius: 60px 0px 0px 60px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    cursor: pointer;
`;