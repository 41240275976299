import { useEffect, useState } from "react";
import "./styles.css";
import ProfileInformation from "../ProfileInformation";
import ProfileClubs from "../ProfileClubs";
import ProfileConnections from "../ProfileConnections";
import { useParams } from "react-router-dom";
import Posts from "../../containers/Posts";
import ProfileSettings, { TabsProfile } from "../ProfileSettings/ProfileSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER, WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";
import PostsNew from "../../containers/Posts/PostsNew";

export const T_BLUE = '#27303B';
export const T_LIGHT_TAN = '#CEBEAD';

function Index({
  ChatServiceInstance
}:{
  ChatServiceInstance:ChatServiceInterface
}) {
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState("Information");
  const [screen, setScreen] = useState("Information");
  const { username } = useParams();

  useEffect(() => {
    setSelected("Information");
    setScreen("Information");
  }, []);

  useEffect(() => {
    setSelected("Information");
    setScreen("Information");
  }, [username]);

  const chooseView = (view: string) => {
    setSelected(view);
    setScreen(view);
  }

  function selectScreen(screen: string) {

    switch (screen) {
      case "Information":
        return <>
          <ProfileInformation
            isUserProfile={false}
            editVisible={false}
          />
          {username && <PostsNew postRequired={'CONNECTION'} username={username}/>}
        </>

      case "Clubs":
        return <ProfileClubs />;

      case "Connections":
        return <ProfileConnections />;

      default:
        return <></>;
        break;
    }
  }

  return (
    <>
      <TabsProfile>

        <div
          className={
            selected === "Information" ? "pointer icons-column-selected" : "pointer icons-column"
          }
          id="left-rad-up"
          onClick={() => chooseView('Information')}
        >
          <FontAwesomeIcon
            icon="home"
            size='sm'
            color={
              selected === "Information"
                ? T_BLUE
                : T_LIGHT_TAN
            }
          />
          <div className={
            selected === "Information"
              ? "icon-label robotocondensed-bold-t-blue-16px"
              : "icon-label robotocondensed-normal-lavender-16px"}
          >
            Vibes
          </div>
        </div>
        <div
          className={
            selected === "Clubs" ? "pointer icons-column-selected" : "pointer icons-column"
          }
          onClick={() => chooseView('Clubs')}
        >
          <FontAwesomeIcon
            icon="users"
            size='sm'
            color={
              selected === "Clubs"
                ? T_BLUE
                : T_LIGHT_TAN
            }
          />
          <div className={
            selected === "Clubs"
              ? "icon-label robotocondensed-bold-t-blue-16px"
              : "icon-label robotocondensed-normal-lavender-16px"}
          >
            {t('navbar__clubs')}
          </div>
        </div>

        <div
          className={
            selected === "Connections" ? "pointer icons-column-selected" : "pointer icons-column"
          }
          onClick={() => chooseView('Connections')}
        >
          <FontAwesomeIcon
            icon="user"
            size='sm'
            color={
              selected === "Connections"
                ? T_BLUE
                : T_LIGHT_TAN
            }
          />
          <div className={
            selected === "Connections"
              ? "icon-label robotocondensed-bold-t-blue-16px"
              : "icon-label robotocondensed-normal-lavender-16px"}
          >
            {t('navbar__connections')}
          </div>
        </div>

      </TabsProfile>

      {selectScreen(screen)}
    </>
  );
}

export default Index;
