import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Select, { ActionMeta, OnChangeValue, Options, StylesConfig } from "react-select";
import styled from "styled-components";
import Button from 'react-bootstrap/Button';
import CreatableSelect from "react-select/creatable";
import ErrorField from "../../../BaseComponents/ErrorField";

export const BROWN = 'var(--simple-brown)' ;
export const RED = '#FF4D82' ;
export const STRONG_BROWN = 'var(--violent-violet)' ;
export const WHITE = '#FFFFFF';
export const GRAY = '#969696';

export const MainWrapper = styled.div`
    width: 100%;
    z-index: 1;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    align-items: center;
    /* min-height: 944px; */
    background-color: var(--light-brown);
    padding-left: 174px;
    padding-right: 20px;
    height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
`;

export const ContentWrapper = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const TopColumn = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    padding: 0 20px;
    justify-content: space-between;
`;

export const Header = styled(TopColumn)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const Title = styled.h1`
    color: var(--t-blue);
    font-family: 'Inter-bold';
    font-size: 24px;
    font-weight: bold;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const RowText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 15px;
    gap: 5px;
`;

export const RowGap = styled(Row)`
    align-items: flex-start;
    gap: 10%;
`;

export const HeaderSubTitle = styled.h2`
    font-family: var(--font-family-roboto_condensed);
    font-weight: 700;
    color: var(--white);
    font-size: var(--font-size-m);
    letter-spacing: 0;
`;

export const HeaderText = styled.p`
    font-family: var(--font-family-roboto_condensed);
    font-weight: 400;
    color: var(--white);
    font-size: var(--font-size-m);
    letter-spacing: 0;
    text-align: left;
`;

export const PhantomElement = styled.div`
    width: 100%;
`;

export const CreateBtn = styled.button`
    align-items: center;
    background-color: var(--electric-yellow);
    border-radius: 12px;
    display: flex;
    height: 44px;
    padding: 20px;
    margin-left: 13px;
    border: none;
    cursor: pointer;
    color: var(--white);
    font-family: "Inter-bold";
    font-size: 16px;
    font-style: normal;
`;

export const ActionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

export const ClickableIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
`;

const BaseButton = styled(Button)`
    border: 1px solid var(--t-blue);
    border-radius: 12px;
    width: 150px;
    cursor: pointer;
`;

export const CancelButton = styled(BaseButton)`
    background: #FFFFFF;
    box-sizing: border-box;
    color: var(--t-blue);
`;

export const SaveButton = styled(BaseButton)`
    background: var(--t-blue);
    color: #ffffff;
    text-align: center;
    display: flex;
    justify-content: center;
`;

const TitleNestedWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
`;

interface TitleNestedProps {
    onClickBack: () => void
}

export const TitleNested: React.FC<TitleNestedProps> = ({ children, onClickBack }) => (
    <TitleNestedWrapper>
        <ClickableIcon
            icon="chevron-left"
            size="lg"
            color={BROWN}
            onClick={onClickBack}
        />
        <Title>{children}</Title>
    </TitleNestedWrapper>
);

interface BaseInputProps {
    placeholder: string;
    value: string;
    showError: boolean;
    error: string;
    onChangeValue: (event: React.FormEvent<HTMLInputElement>) => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: fit-content;
  gap: 5px;
`;

export const BaseInputWrapper = ({
    placeholder,
    value,
    showError,
    error,
    onChangeValue,
}: BaseInputProps) => {
    return (
        <Wrapper>
            <BaseInput
                placeholder={placeholder}
                value={value}
                onChange={onChangeValue}
            />
            {(showError) && (<ErrorField error={error} />)}
        </Wrapper>
    )
}

interface SelectWrapperProps {
    placeholder: string;
    value: any;
    options: Options<any>;
    components: any;
    styles: StylesConfig<string, false, any>;
    showError: boolean;
    error: string;
    onChangeValue: (newValue: OnChangeValue<any, any>, actionMeta: ActionMeta<any>) => void; //@todo I didn't found the correct values to the places where is any :c
}

export const SelectWrapper = ({
    placeholder,
    value,
    options,
    components,
    styles,
    showError,
    error,
    onChangeValue,
}: SelectWrapperProps) => {
    return (
        <div>
            <Select
                options={options}
                styles={styles}
                components={components}
                value={value}
                onChange={onChangeValue}
                placeholder={placeholder}
            />
            {(showError) && (<ErrorField error={error} />)}
        </div>
    )
}

interface CreatableSelectWrapperProps {
    placeholder: string;
    value: any[];
    options?: Options<any>;
    styles: StylesConfig<string, boolean, any>;
    showError: boolean;
    error: string;
    onChangeValue: (newValue: OnChangeValue<any, any>, actionMeta: ActionMeta<any>) => void; //@todo I didn't found the correct values to the places where is any :c
}

export const CreatableSelectWrapper = ({
    placeholder,
    value,
    options,
    styles,
    showError,
    error,
    onChangeValue,
}: CreatableSelectWrapperProps) => {
    return (
        <Wrapper>
            <CreatableSelect
                options={options}
                styles={styles}
                isMulti
                isClearable
                value={value}
                onChange={onChangeValue}
                placeholder={placeholder}
            />
            {(showError) && (<ErrorField error={error} />)}
        </Wrapper>
    )
}

export const BaseInput = styled.input`
    width: 100%;
    border: 1px solid var(--simple-gray);
    border-radius: 9px;
    margin: none;
    background-color: white;
    font-size: 16px;
    font-family: 'Inter';
    color: black;
    padding: 9px;
`;

interface BaseTextAreaProps {
    placeholder: string;
    value: string;
    showError: boolean;
    error: string;
    onChangeValue: (event: React.FormEvent<HTMLTextAreaElement>) => void;
}

export const BaseTextAreaWrapper = ({
    placeholder,
    value,
    showError,
    error,
    onChangeValue,
}: BaseTextAreaProps) => {
    return (
        <div>
            <BaseTextArea
                placeholder={placeholder}
                value={value}
                onChange={onChangeValue}
            />
            {(showError) && (<ErrorField error={error} />)}
        </div>
    )
}

export const BaseTextArea = styled.textarea`
    width: 100%;
    border: none;
    margin: none;
    outline: none;
    resize: none;
    background-color: #28115a;
    font-size: 16px;
    font-family: 'Inter';
    color: white;
    padding: 9px;

    &::placeholder {
        color: #FFFFFF77;
        font-size: 16px;
        font-family: 'Inter';
    }
`;