import React, { KeyboardEventHandler, useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { MultiValue, OnChangeValue, PropsValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Advisor, Member, Officer } from '../../containers/Club';
import parseClubToForm from '../../helpers/club/parseClubToForm';
import ClubForm from '../../interfaces/club/ClubForm';
import ClubInterface from '../../interfaces/ClubExpanded';
import { components, createOption, Option, skillsLearnedStyles } from './SkillsLearnedSelectConfig';
import "../ClubViewInformation/styles.scoped.css";
import { toast } from 'react-toastify';
import BooleanSelect from './BooleanSelect';
import { getOrganizationInfo } from "../../store/actions/orgsActions";
import { TimePicker } from 'antd';
import './styles.scoped.css';
import moment, { Moment } from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VIOLET, WHITE } from '../ClubIconsNavBar';
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from '../../helpers/backendHelper';
import { getUserRoles } from '../../helpers/roleHelper';
import useGender from '../../hooks/useGender';
import useGrades from '../../hooks/useGrades';
import MyTimePicker from "../../components/MyTimePicker"
import { ContextSuccess } from "../../Context";
import { ClubCenterWrap } from '../ClubViewInformation';
import TextEditor from '../BaseComponents/TextEditor';
import styled from 'styled-components';
import BlankTable from '../BaseComponents/BlankTable';
interface ClubEditInformationProps {
  club: ClubInterface,
  officers: Officer[],
  advisors: Advisor[],
  members: Member[],
  goToInformation: () => void;
  reloadOfficers: () => void;
  reloadAdvisors: () => void;
};

interface OfficerInfo {
  email: string;
  position: string;
};

const getTimeMoment = (time: any): Moment => {
  const time_parts = time.split("-");
  return moment(
    `${time_parts[0]}-${time_parts[1]}-${time_parts[2]} ${time_parts[3]}:${time_parts[4]
    }`
  );
};

const ClubEditInformation = ({
  club,
  officers,
  advisors,
  members,
  goToInformation,
  reloadOfficers,
  reloadAdvisors
}: ClubEditInformationProps) => {
  const { t, i18n } = useTranslation();
  const currentSuccess = useContext(ContextSuccess);
  const getMomentTime = (mom: Moment): string => {
    if (mom === null) return ""
    let hours: number | string = mom.hours()
    let minutes: number | string = mom.minutes()
    hours < 10 ? hours = `0${hours}` : hours = `${hours}`
    minutes < 10 ? minutes = `0${minutes}` : minutes = `${minutes}`
    return `${hours}:${minutes}`
  }

  const [clubForm, setClubForm] = useState<ClubForm>({});
  const [skillsLearned, setSkillsLearned] = useState<Option[]>([]);
  const [skillsLearnedInput, setSkillsLearnedInput] = useState<string>('');
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
  const [addAdvisor, setAddAdvisor] = useState<boolean>(false);
  const [advUsername, setAdvUsername] = useState<string>('');
  const [loadingAdvisor, setLoadingAdvisor] = useState<boolean>(false);

  const [addOfficer, setAddOfficer] = useState<boolean>(false);
  const [officerInfo, setOfficerInfo] = useState<OfficerInfo>({
    email: '',
    position: ''
  });
  const [loadingOfficer, setLoadingOfficer] = useState<boolean>(false);
  const organization = useSelector((state: any) => state.getOrgs.organizationInfo);

  const { genders, setCountry: setCountryGenders } = useGender();
  const { grades, setCountry: setCountryGrades } = useGrades();

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryGenders(organization.countryCode);
      setCountryGrades(organization.countryCode);
    }
  }, [])

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryGenders(organization.countryCode);
      setCountryGrades(organization.countryCode);
    }
  }, [organization])

  useEffect(() => {
    if (club) {
      setClubForm(parseClubToForm(club))
      setSkillsLearned((club.skills_learned || []).map((skill) => createOption(skill)));
    }
  }, [club]);

  const handleOnChangeDescription = (event: string) => {
    setClubForm({
      ...clubForm,
      description: event,
    });
  }

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClubForm({
      ...clubForm,
      name: event.target.value,
    });
  }

  const handleChangeLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClubForm({
      ...clubForm,
      meetingLocation: event.target.value,
    });
  }

  const handleOnChangeNumberMembers = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClubForm({
      ...clubForm,
      maximumNumberMembers: Number(event.target.value),
    });
  }

  const handleOnChangeSkills = (value: PropsValue<Option>) => {
    if (value) {
      const newValue = value as MultiValue<Option>;
      setSkillsLearned([...newValue]);
      setClubForm({
        ...clubForm,
        skills_learned: newValue.map((option) => option.label),
      });
    }
  };

  const handleOnChangeSkillsInput = (inputValue: string) => {
    setSkillsLearnedInput(inputValue);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!skillsLearnedInput) return;
    if (['Enter', 'Tab'].includes(event.key)) {
      setSkillsLearnedInput('');
      if (
        !skillsLearned
          .map((item) => item.value)
          .includes(skillsLearnedInput)
      ) {
        setSkillsLearned([...skillsLearned, createOption(skillsLearnedInput)]);
        setClubForm({
          ...clubForm,
          skills_learned: [
            ...(clubForm.skills_learned || []),
            skillsLearnedInput,
          ],
        });
      }
      event.preventDefault();
    }
  };

  const handleOnCancel = () => {
    goToInformation();
  }

  const handleOnChangeFees = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClubForm({
      ...clubForm,
      clubDues: Number(event.target.value),
    }
    )
  }

  const handleOnSave = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setLoadingUpdate(true);
    Backend(
      MicroServices.Club,
      `/clubs/${club.id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(clubForm),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          currentSuccess?.setSuccessData(true, true, t('toast_clubUpdated'));
          goToInformation();
        } else {
          // Handle error
        }
      })
      .catch((error) => {
        console.log(error);
        // Handle error
      })
      .finally(() => setLoadingUpdate(false))
  }

  const showAddAdvisor = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAddAdvisor(true);
  }

  const hideAddAdvisor = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAddAdvisor(false);
  }

  const createAdvisor = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (advUsername === '') return;
    setLoadingAdvisor(true);

    const advRoles = await getUserRoles(advUsername, organization.id);

    let canBeAdv = false;

    if (advRoles.length === 0) {
      currentSuccess?.setSuccessData(false, true, t('toast_emailNotRegistered'));
      setLoadingAdvisor(false);
      return;
    }
    else {

      if (advRoles.includes('STAFF')) {
        canBeAdv = true;
      }
      else {
        if (Array.isArray(members)) {
          canBeAdv = members.findIndex((member) => member.email === advUsername) !== -1;
        }
        else {
          currentSuccess?.setSuccessData(false, true, t('toast_clubDoesntHave'));
          setLoadingAdvisor(false);
          return;
        }
      }
    }

    if (canBeAdv) {
      Backend(
        MicroServices.Club,
        `/clubs/${club.id}/advisor?email=${advUsername}`,
        {
          method: 'POST',
        }
      )
        .then((response) => {
          if (response.status === 201) {
            currentSuccess?.setSuccessData(true, true, t('toast_advisorAdded'));
            reloadAdvisors();
            setAdvUsername('');
          }
          else {
            currentSuccess?.setSuccessData(false, true, t('toast_ThereWasError'));
          }
        })
        .catch((error) => {
          currentSuccess?.setSuccessData(false, true, t('toast_ThereWasError'), error);
          console.log('Error adding advisors:', error)
        })
        .finally(() => setLoadingAdvisor(false));
    } else {
      currentSuccess?.setSuccessData(false, true, t('toast_pleaseAddTheUser'));
      setLoadingAdvisor(false);
    }

  };

  const showAddOfficer = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAddOfficer(true);
  }

  const hideAddOfficer = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAddOfficer(false);
  }

  const createOfficer = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (officerInfo.email === '' || officerInfo.position === '') return;
    setLoadingOfficer(true);

    let membersList = [];

    if (Array.isArray(members)) {
      membersList = members;
    }
    else {
      currentSuccess?.setSuccessData(false, true, t('toast_clubDoesntHave'));
      setLoadingOfficer(false);
      return;
    }

    const isMember = membersList.findIndex((member) => member.email === officerInfo.email) !== -1;
    if (isMember) {
      Backend(
        MicroServices.Club,
        `/clubs/${club.id}/officer`,
        {
          method: 'POST',
          body: JSON.stringify(officerInfo)
        }
      )
        .then((response) => {
          if (response.status === 201) {
            currentSuccess?.setSuccessData(true, true, t('toast_officerAdded'));
            reloadOfficers();
            setOfficerInfo({
              email: '',
              position: ''
            });
          }
          else {
            currentSuccess?.setSuccessData(false, true, t('toast_ThereWasError'));
          }
        })
        .catch((error) => {
          currentSuccess?.setSuccessData(false, true, t('toast_ThereWasError'), error);
          console.log('Error adding officers:', error)
        })
        .finally(() => setLoadingOfficer(false));
    } else {
      currentSuccess?.setSuccessData(false, true, t('toast_pleaseAddTheUser'));
      setLoadingOfficer(false);
    }
  };

  const handleChangeBoolean = (value: boolean, key: keyof ClubForm) => {
    setClubForm({
      ...clubForm,
      [key]: value,
    })
  }

  const handleOnChangeGenders = (event: any) => {
    let updateList = [...(clubForm.gendersAllowed || [])];
    if (event.target.checked) {
      updateList = [...(clubForm.gendersAllowed || []), event.target.value];
    } else {
      updateList.splice((clubForm.gendersAllowed || []).indexOf(event.target.value), 1);
    }
    setClubForm({ ...clubForm, gendersAllowed: updateList });
  }

  const handleCheckGradesAllowed = (event: any) => {
    let updateList = [...(clubForm.gradesAllowed || [])];
    if (event.target.checked) {
      updateList = [...(clubForm.gradesAllowed || []), parseInt(event.target.value)];
    } else {
      updateList.splice((clubForm.gradesAllowed || []).indexOf(parseInt(event.target.value)), 1);
    }
    setClubForm({ ...clubForm, gradesAllowed: updateList });
  }

  const handleOnChangeDays = (event: any) => {
    let updateList = [...(clubForm.meetingDays || [])];
    if (event.target.checked) {
      updateList = [...(clubForm.meetingDays || []), event.target.value];
    } else {
      updateList.splice((clubForm.meetingDays || []).indexOf(event.target.value), 1);
    }
    setClubForm({ ...clubForm, meetingDays: updateList });
  }

  const handleOnStartChange = (event: any) => {
    setClubForm({ ...clubForm, meetingStartTime: getMomentTime(event) })
  }

  const handleOnEndChange = (event: any) => {
    setClubForm({ ...clubForm, meetingEndTime: getMomentTime(event) })
  }

  return (
    <ClubCenterWrap>
      <ClubInfo_Less>
        <h2 className="labelTitle">Club Name</h2>
        <div className="inputInfo">
          <input
            type="text"
            className='UpdateClub__clubName'
            value={clubForm.name}
            onChange={handleChangeName}
          />
        </div>

        <div className="labelTitle">{t('editInformation__description')}</div>
        <TextEditor
          value={clubForm.description || ''}
          setValue={handleOnChangeDescription}
          charLimit={2500}
          minHeight={150}
          placeholder='Describe your club!'
        />
      </ClubInfo_Less>

      <div className="labelInfo">{t('editInformation__additionalInformation')}</div>
      <div className='rowcol1'>
        <div className="col1">
          <div className="labelTitle">{t('editInformation__gendersAllowed')}</div>
          <div className='input-selectors'>
            <div className="containers-input">
              {genders.map(({ id: genderKey, label: genderLabel }) => (
                <div className="option">
                  <label className="content-input">
                    <input
                      type="checkbox"
                      name={`checkbox-${genderKey}`}
                      value={genderKey}
                      checked={clubForm.gendersAllowed?.includes(genderKey)}
                      onChange={handleOnChangeGenders}
                    />
                    <i></i>
                  </label>
                  <label htmlFor="students">{genderLabel}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col1">
          <div className="labelTitle">{t('editInformation__numberMembers')}</div>
          <div className='inputInfo'>
            <input
              type="number"
              className='UpdateClub__clubName'
              value={clubForm.maximumNumberMembers?.toString() || '0'}
              onChange={handleOnChangeNumberMembers}
            />
          </div>
        </div>
      </div>

      <div className="labelInfo">
        Advisors
        <button className="buttonAdd" onClick={showAddAdvisor}>Add Advisor</button>
      </div>
      <div className="inputInfo">
        {addAdvisor && (
          <div className="officer" >
            <div className='emailOfficerAdd'>
              {t('profile__email')}
              <div className='inputContainerOfficer'>
                <input type="text" value={advUsername} onChange={(e) => setAdvUsername(e.target.value)} />
              </div>
            </div>
            <div className='actionsOfficer'>
              <button className="buttonTrashOfficer" onClick={hideAddAdvisor}>
                <FontAwesomeIcon
                  icon="times"
                  size='sm'
                  color={'var(--t-blue)'}
                  className="trash  "
                />
              </button>
              <button className="buttonPenOfficer" onClick={createAdvisor} disabled={loadingAdvisor}>
                {loadingAdvisor ? (
                  <div className="icon-spinner-third buttonSendSignUp" />
                ) : (
                  <FontAwesomeIcon
                    icon="check"
                    size='sm'
                    color={WHITE}
                    className="trash  "
                  />
                )}
              </button>
            </div>
          </div>
        )}
        <BlankTable
          headers={['Guide Name', 'Email']}
          items={(advisors || []).map((adv) => ({
            id: adv.email,
            labels: [adv.fullname, adv.email]
          }))}
          maxWidth={'230px'}
        />
      </div>

      <div className="row">
        <div className='buttonsEditClub'>
          <button
            className='buttonCancelEditClub'
            onClick={handleOnCancel}>{t('button__cancel')}</button>
          <button
            className='buttonSaveEditClub'
            onClick={handleOnSave}
            disabled={loadingUpdate}
          >
            {loadingUpdate ? (
              <div className="icon-spinner-third buttonSendSignUp" />
            ) : `${t('button__save')}`}
          </button>
        </div>
      </div>

      {/* <div className="row">
          <div className="labelTitle">{t('editInformation__skills')}</div>
          <div className="inputInfo">
            <CreatableSelect
              components={components}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={handleOnChangeSkills}
              onInputChange={handleOnChangeSkillsInput}
              inputValue={skillsLearnedInput}
              onKeyDown={handleKeyDown}
              placeholder=""
              value={skillsLearned}
              styles={skillsLearnedStyles}
            />
          </div>
        </div> */}

      {/* <div className="row">
        <div className='rowcol3'>
            <BooleanSelect
              value={clubForm.applicationRequired || false}
              setValue={(val: boolean) => handleChangeBoolean(val, 'applicationRequired')}
              label={t('club__applicationRequired')}
            />
            <BooleanSelect
              value={clubForm.clubDuesRequired || false}
              setValue={(val: boolean) => handleChangeBoolean(val, 'clubDuesRequired')}
              label={t('club__duesRequired')}
            />
            <div className="col1">
              <div className="labelTitle">{t('club__duesFee')}</div>
              <div className='selectInformation'>
                <input
                  type="text"
                  className=''
                  value={clubForm.clubDues}
                  onChange={handleOnChangeFees}
                />
              </div>
            </div>
          </div>
          <div className='rowcol3'>
            <div className="col1">
              <div className="labelTitle">{t('editInformation__meetingLocation')}</div>
              <div className='selectInformation'>
                <input
                  type="text"
                  className=''
                  value={clubForm.meetingLocation}
                  onChange={handleChangeLocation}
                />
              </div>
            </div>
            <div className="col1">
              <div className="labelTitle">Start Meeting Time</div>
              <div className='selectInformation'>
                <MyTimePicker
                  handleClick={handleOnStartChange as any}
                  value={moment(clubForm.meetingStartTime || moment('2:00 PM', 'h:mm a'), 'h:mm a')}
                  className={'changeInfoDate'}
                />
              </div>
            </div>
            <div className="col1">
              <div className="labelTitle">End Meeting Time</div>
              <div className='selectInformation'>
                <MyTimePicker
                  handleClick={handleOnEndChange as any}
                  value={moment(clubForm.meetingEndTime || moment('2:00 PM', 'h:mm a'), 'h:mm a')}
                  className={'changeInfoDate'}
                />
              </div>
            </div>
          </div>
          <div className='rowcol1'>
            <div className="col1">
              <div className="labelTitle">{t('club__frecuency')}</div>
              <div className='input-selectors'>
                <div className="containers-input">
                  <div className="option">
                    <label className="content-input">
                      <input type="radio" name="principal-button" value=""
                        onClick={(e: any) => setClubForm({ ...clubForm, meetingFrequency: 'Weekly' })}
                        defaultChecked={clubForm.meetingFrequency ? clubForm.meetingFrequency === 'Weekly' : false} />
                      <i></i>
                    </label>
                    <label htmlFor="students">{t('editInformation__weekly')}</label>
                  </div>
                  <div className="option">
                    <label className="content-input">
                      <input type="radio" name="principal-button" value=""
                        onClick={(e: any) => setClubForm({ ...clubForm, meetingFrequency: 'Bi-weekly' })}
                        defaultChecked={clubForm.meetingFrequency ? clubForm.meetingFrequency === 'Bi-weekly' : false} />
                      <i></i>
                    </label>
                    <label htmlFor="students">{t('editInformation__biWeekly')}</label>
                  </div>
                  <div className="option">
                    <label className="content-input">
                      <input type="radio" name="principal-button" value=""
                        onClick={(e: any) => setClubForm({ ...clubForm, meetingFrequency: 'Monthly' })}
                        defaultChecked={clubForm.meetingFrequency ? clubForm.meetingFrequency === 'Monthly' : false} />
                      <i></i>
                    </label>
                    <label htmlFor="students">{t('editInformation__monthly')}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='rowcol1'>
            <div className="col1">
              <div className="labelTitle">{t('editInformation__meetingDays')}</div>
              <div className='input-selectors'>
                <div className="containers-input">
                  <div className="option">
                    <label className="content-input">
                      <input
                        type="checkbox"
                        name="principal-button"
                        value="Sunday"
                        checked={(clubForm.meetingDays || []).includes('Sunday')}
                        onChange={handleOnChangeDays}
                      />
                      <i></i>
                    </label>
                    <label htmlFor="students">SUN</label>
                  </div>
                  <div className="option">
                    <label className="content-input">
                      <input
                        type="checkbox"
                        name="principal-button"
                        value="Monday"
                        checked={(clubForm.meetingDays || []).includes('Monday')}
                        onChange={handleOnChangeDays}
                      />
                      <i></i>
                    </label>
                    <label htmlFor="students">MON</label>
                  </div>
                  <div className="option">
                    <label className="content-input">
                      <input
                        type="checkbox"
                        name="principal-button"
                        value="Tuesday"
                        checked={(clubForm.meetingDays || []).includes('Tuesday')}
                        onChange={handleOnChangeDays}
                      />
                      <i></i>
                    </label>
                    <label htmlFor="students">TUE</label>
                  </div>
                  <div className="option">
                    <label className="content-input">
                      <input
                        type="checkbox"
                        name="principal-button"
                        value="Wednesday"
                        checked={(clubForm.meetingDays || []).includes('Wednesday')}
                        onChange={handleOnChangeDays}
                      />
                      <i></i>
                    </label>
                    <label htmlFor="students">WED</label>
                  </div>
                  <div className="option">
                    <label className="content-input">
                      <input
                        type="checkbox"
                        name="principal-button"
                        value="Thursday"
                        checked={(clubForm.meetingDays || []).includes('Thursday')}
                        onChange={handleOnChangeDays}
                      />
                      <i></i>
                    </label>
                    <label htmlFor="students">THU</label>
                  </div>
                  <div className="option">
                    <label className="content-input">
                      <input
                        type="checkbox"
                        name="principal-button"
                        value="Friday"
                        checked={(clubForm.meetingDays || []).includes('Friday')}
                        onChange={handleOnChangeDays}
                      />
                      <i></i>
                    </label>
                    <label htmlFor="students">FRI</label>
                  </div>
                  <div className="option">
                    <label className="content-input">
                      <input
                        type="checkbox"
                        name="principal-button"
                        value="Saturday"
                        checked={(clubForm.meetingDays || []).includes('Saturday')}
                        onChange={handleOnChangeDays}
                      />
                      <i></i>
                    </label>
                    <label htmlFor="students">SAT</label>
                  </div>
                </div>
              </div>
            </div>
          </div><div className='rowcol3'>
            <BooleanSelect
              value={clubForm.participateRegionalCompetitions || false}
              setValue={(val: boolean) => handleChangeBoolean(val, 'participateRegionalCompetitions')}
              label={t('editInformation__regional')}
            />
            <BooleanSelect
              value={clubForm.participateNationalCompetitions || false}
              setValue={(val: boolean) => handleChangeBoolean(val, 'participateNationalCompetitions')}
              label={t('editInformation__nacional')}
            />
            <BooleanSelect
              value={clubForm.participateInternationalCompetitions || false}
              setValue={(val: boolean) => handleChangeBoolean(val, 'participateInternationalCompetitions')}
              label={t('editInformation__internacional')}
            />
          </div> 
      </div>*/}
    </ClubCenterWrap>
  );
};

export default ClubEditInformation;

const ClubInfo_Less = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;