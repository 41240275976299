import { useEffect, useMemo, useState } from "react";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import { IOrganizationResponse } from "@vibe/sdk/dist/interfaces/Admin/Organization";

const useProfessionalOrgsSA = (pageSizeProp: number) => {
    const [pageSize, setPageSize] = useState(pageSizeProp);
    const [professionalOrgs, setProfessionalOrgs] = useState<IOrganizationResponse[]>([]);
    const [bussiness, setBussiness] = useState<IOrganizationResponse[]>([]);

    const poPages: IOrganizationResponse[][] = useMemo(() => {
        const pages: IOrganizationResponse[][] = [];
        const all = [...professionalOrgs, ...bussiness];

        for (let i = 0; i < all.length; i+=pageSize) {
            pages.push(all.slice(i, i+pageSize));
        }

        if (pages.length === 0) {
            pages.push([]);
        }

        return pages;
    }, [pageSize, professionalOrgs, bussiness])

    const reloadProfessionalOrgs = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Admin.getOrganizations({
                    type: 'PROFESSIONAL',
                }, dataSDK?.token)
            })
            .then(({body}) => {
                if (!!body && Array.isArray(body)) {
                    setProfessionalOrgs(body);
                }
            })
    }

    const reloadBussiness = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Admin.getOrganizations({
                    type: 'BUSINESS',
                }, dataSDK?.token)
            })
            .then(({body}) => {
                if (!!body && Array.isArray(body)) {
                    setBussiness(body);
                }
            })
    }

    const reload = () => {
        reloadProfessionalOrgs();
        reloadBussiness();
    }
    
    useEffect(() => reload(), []);

    return {
        poPages,
        reload,
        setPageSize,
    }

}

export default useProfessionalOrgsSA;