import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { getActiveModalities, getAllModalities } from "../../../store/actions/modalityActions";
import Modality from "@vibe/sdk/interfaces/Modalities/modalities/Modality/Modality";
import { sortAlphabeticallyOnProperty } from "../../../containers/Signup/SignupHealerInd";

const useAllModalities = ({
    pageSizeProp,
    searchName,
}: {
    pageSizeProp: number
    searchName: string
}) => {
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState<number>(pageSizeProp);
    const [filteredMod, setFilteredMod] = useState<Modality[]>([]);

    const Modalities = useSelector((state: RootState) => state.getModality.getAllModalities);

    const searchTerm = useMemo(() => searchName.toLowerCase(), [searchName]);

    const modalitiesPages:Modality[][] = useMemo(() => {
        const pages:Modality[][] = [];

        for (let i = 0; i < Modalities.length; i+=pageSize) {
            pages.push(Modalities.slice(i, i+pageSize));
        }

        if (pages.length === 0) {
            pages.push([]);
        }

        return pages;
    }, [pageSize, Modalities])

    const reloadModalities = () => {
        dispatch(getAllModalities());
    }

    useEffect(() => {
        if (!searchTerm || searchTerm === '') {
            setFilteredMod(sortAlphabeticallyOnProperty(Modalities, 'name'));
            return;
        }
        setFilteredMod(
            (sortAlphabeticallyOnProperty(Modalities, 'name') as Modality[])
                .filter(i => i.name.toLowerCase().includes(searchTerm))
        );
    }, [searchTerm, Modalities]);
    
    useEffect(() => reloadModalities(), []);

    return {
        modalitiesPages,
        reloadModalities,
        setPageSize,
        filteredMod
    }

}

export default useAllModalities;