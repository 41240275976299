import React, { useEffect, useState, useContext } from 'react';
import DashboardsHeader, { tabsDashboardHealer } from '../../../components/Dashboards/DashboardsHeader';
import { isPaidHealer } from '../../../helpers/userHelper';
import { RootState } from '../../../store/store';
import IPaymentInfo from '../../../interfaces/user/IPaymentINfo';
import { useSelector } from 'react-redux';
import TableCommunities from '../../../components/Dashboards/TableCommunities';
import TableEvents from '../../../components/Dashboards/TableEvents';
import TableModalities from '../../../components/Dashboards/TableModalities';
import TableRevenue from '../../../components/Dashboards/TableRevenue';

const AdminCenterDashboard = () => {

    const [tabActive, setTabActive] = useState<tabsDashboardHealer>('BULLETIN'); //for Healer new dashboard on bulletin
    const userRoles = useSelector((state: RootState) => state.getUser.userRoles);
    const userPayment: IPaymentInfo = useSelector((state: RootState) => state.getUser.paymentInfo);

    return (
        <div>
            {(isPaidHealer(userRoles, userPayment.hasActiveSubscription)) && (
                <DashboardsHeader
                    tabActive={tabActive}
                    setTabActive={setTabActive}
                />
            )}
            {
                tabActive === 'PROFILE' ?
                    'Profile'
                    :
                    tabActive === 'REVENUE' ?
                        <TableRevenue />
                        :
                        tabActive === 'MODALITIES' ?
                            <TableModalities />
                            :
                            tabActive === 'COMMUNITIES' ?
                                <TableCommunities handleTab={setTabActive} />
                                :
                                tabActive === 'EVENTS' ?
                                    <TableEvents handleTab={setTabActive} />
                                    :
                                    <></>
            }
        </div>
    )
}


export default AdminCenterDashboard;