import styled from 'styled-components';
import { EventCardInterface, LabelCost, LabelFree, mockEventCard } from './EventCard';
import Modal from 'react-bootstrap/esm/Modal';
import { faTimes, faCopy, faChevronDown, faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { ClickableIcon } from '../../SuperCenter/SuperAdminComponents/basicForSACenter';
import { useContext, useEffect, useState } from 'react';
import { getDataSDK, getDataSDKResponse } from '../../../helpers/backendHelper';
import { EventsResponse, IGetEvent } from '@vibe/sdk/dist/interfaces/Events/getEvent';
import { ContextSuccess } from '../../../Context';
import IResponseError from '@vibe/sdk/dist/interfaces/IResponseError';
import { mapEventToCard } from '../../../hooks/Events/useEventsPanel';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { AvatarCreatorHome, AvatarHealerCardHome, CreatorNoAvatar, DividerLineHealerHome, NoAvatarHealerCardHome } from '../HomeCards/styles';
import { cleanHTML, cleanStyleHTML, getColorsInitials, getInitials } from '../../../helpers/letterHelper';
import { useNavigate } from 'react-router-dom';
import { formatCompactNumber } from '../../../helpers/numberHelper';
import { DividerLine } from '../../SuperCenter/Articles/ViewArticle';
import SelectorRSVP from '../../Events/SelectorRSVP';
import OnSale from './OnSale';
import EventStateLabel from './EventStateLabel';

const ModalEventDetail = ({
    event,
    show,
    setShow,
}: {
    event: EventCardInterface
    show: boolean
    setShow: (data: boolean) => void
}) => {
    const currentSuccess = useContext(ContextSuccess);
    const navigate = useNavigate();

    const username = useSelector((state: RootState) => state.getUser.userInfo?.username || '');

    const [eventLoaded, setEventLoaded] = useState<EventCardInterface>({ ...mockEventCard });

    const reloadEvent = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Events.getEvent({ id: event.id || '', username }, dataSDK.token);
            })
            .then((response) => {
                if (response.statusCode === 200) {
                    setEventLoaded(mapEventToCard(response.body as EventsResponse));
                }
                else {
                    currentSuccess?.setSuccessData(false, true, 'Get Error', 'Error getting event data.');
                    console.log((response.body as IResponseError).error || (response.body as IResponseError).message);
                }
            })
            .catch((error) => {
                currentSuccess?.setSuccessData(false, true, 'Get Error', 'Error getting event data.');
                console.log(error);
            })
    }

    const copyToClipboard = (toCopy: string) => {
        navigator.clipboard.writeText(toCopy);
        currentSuccess?.setSuccessData(true, true, 'Copied to clipboard');
    }

    const getDeliveryInPerson = () => {

        if (eventLoaded.delivery === 'online') return <></>;

        return (
            <RowFlex2>
                <FontAwesomeIcon
                    icon='location-dot'
                    size='sm'
                    color='var(--t-blue)'
                />
                <TitlePost
                    theme={{ main: '' }}
                    isEvent={true}
                    dangerouslySetInnerHTML={{ __html: cleanHTML(eventLoaded.locationComplete || '') }}
                />
                {eventLoaded.fee_person > 0 ? <LabelCost>${eventLoaded.fee_person}</LabelCost> : <LabelFree>Free</LabelFree>}
            </RowFlex2>
        );
    }

    const getDeliveryOnline = () => {

        if (eventLoaded.delivery === 'inPerson') return <></>;

        return (
            <RowFlex2>
                <FontAwesomeIcon
                    icon='video'
                    size='sm'
                    color='var(--t-blue)'
                />
                <TitlePost
                    theme={{ main: 'link', color: '#2A4CD3' }}
                    isEvent={false}
                >
                    <a href={`${eventLoaded.link}`} target="_blank" rel="noreferrer">
                        Link
                    </a>
                    <ClickableIcon
                        icon={faCopy}
                        size='sm'
                        color={'var(--t-blue)'}
                        onClick={() => copyToClipboard(`${eventLoaded.link}`)}
                    />
                </TitlePost>
                {eventLoaded.fee_online > 0 ? <LabelCost>${eventLoaded.fee_online}</LabelCost> : <LabelFree>Free</LabelFree>}
            </RowFlex2>
        )
    }

    const getHealerNoAvatar = () => {
        const num = [...eventLoaded.guide.name].reduce((acc, curr) => acc + curr.charCodeAt(0), 0);
        const colors = getColorsInitials(num);
        return <CreatorNoAvatar
            background_color={colors.background}
            text_color={colors.color}
        >
            {getInitials(eventLoaded.guide.name)}
        </CreatorNoAvatar>;
    }

    const showRSVPSelector = () : boolean => {
        const now = new Date();
        const startDate = new Date(`${eventLoaded.startDate} ${eventLoaded.startTime}`);
        return eventLoaded.creator !== username && now.getTime() < startDate.getTime();
    }

    useEffect(() => {
        if (event.name === '') reloadEvent();
        else setEventLoaded(event);
    }, [])

    return (
        <Modal className="modalEventDetail" show={show} onHide={() => setShow(false)}>
            <Modal.Header>
                <Modal.Title>{eventLoaded.name || ''}</Modal.Title>
                <ClickableIcon
                    icon={faTimes}
                    size='lg'
                    color={'var(--t-blue)'}
                    onClick={() => setShow(false)}
                />
            </Modal.Header>
            <Modal.Body>
                <Wrapper>
                    <EventStateLabel item={eventLoaded} isModalDetail/>

                    <TextRowPointer onClick={() => navigate(`../../../../../../h/${eventLoaded.guide.username || ''}`)}>
                        {eventLoaded.guide.avatar ? (
                            <AvatarCreatorHome src={eventLoaded.guide.avatar} />
                        ) : (
                            getHealerNoAvatar()
                        )}
                        {eventLoaded.guide.name}
                    </TextRowPointer>

                    <DateStyle>
                        {eventLoaded.startDate || ''} | {eventLoaded.startTime || ''}
                    </DateStyle>

                    <Counts>
                        <FontAwesomeIcon
                            icon={faUser}
                            size='sm'
                            color={'var(--t-blue)'}
                        />{formatCompactNumber(eventLoaded.interested || 0)} Interested | {formatCompactNumber(eventLoaded.attending || 0)} Attendees
                    </Counts>

                    {(eventLoaded.delivery !== 'inPerson') && <Delivery>
                        {getDeliveryOnline() || ''}
                    </Delivery>}

                    {(eventLoaded.delivery !== 'online') && <Delivery>
                        {getDeliveryInPerson() || ''}
                    </Delivery>}

                    {eventLoaded.eventBriteUrl && <OnSale url={eventLoaded.eventBriteUrl} />}

                    {showRSVPSelector() && <SelectorRSVP
                        reload={reloadEvent}
                        id={event.id}
                        delivery={eventLoaded.delivery}
                        rsvpSelector={eventLoaded.rsvp}
                        name={eventLoaded.name}
                        amountOnline={eventLoaded.fee_online}
                        amountInPerson={eventLoaded.fee_person}
                        isFullInPerson={eventLoaded.isFullInPerson}
                        isFullOnline={eventLoaded.isFullOnline}
                    />}

                    <DividerLineHealerHome />
                    <Description dangerouslySetInnerHTML={{ __html: cleanStyleHTML(eventLoaded.description) }} />
                </Wrapper>
            </Modal.Body>
        </Modal>
    )
}

export default ModalEventDetail;

const TextRow = styled.div`
    display: flex;
    gap: 5px;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    letter-spacing: 0.2px;
    color: black;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-items: center;

    & a{
        font-family: 'Inter';
        font-weight: 400;
        color: var(--absolute-zero);
        text-decoration: underline !important;
    }
`

const TextRowPointer = styled(TextRow)`
    cursor: pointer;
`;

const IconChevronRight = styled(FontAwesomeIcon)`
    position: absolute;
    right: 10px;
`;

const OptionsList = styled.div`
    position: absolute;
    background-color: white;
    width: calc(100% + 2px);
    z-index: 1;
    left: -1px;
    top: 33px;
    border: 1px solid #bfbfbf;
    padding: 7px 0px;
    border-radius: 0 0 9px 9px;
`
const OptionList = styled.div`
    font-family: 'Inter';
    padding: 8px 15px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    cursor: ${({ isFull = false }: { isFull?: boolean }) => isFull ? 'default' : 'pointer'};
    opacity: ${({ isFull = false }: { isFull?: boolean }) => isFull ? '0.5' : '1'};

    &:hover{
        background-color: ${({ isFull = false }: { isFull?: boolean }) => isFull ? 'transparent' : '#ebe8e6'};
    }

`

const ButtonSeeAll = styled.div`
    width: 100%;
    height: 40px;
    border: 1px solid var(--t-blue);
    border-radius: 8px;
    font-family: 'Inter-Bold';
    font-size: 16px; 
    color: var(--t-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
`

const DateStyle = styled.div`
    color: var(--default-dark);
    font-family: 'Inter';
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.1px;
    line-height: normal;
`
const Delivery = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
`

const RowFlex = styled.div`
    gap: 6px;
    display: flex;
    flex-direction: column;
    width: 100%;
`
const RowFlex2 = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
`

const TitlePost = styled.p.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    font-family: ${({ isEvent }: { isEvent: boolean }) => isEvent ? 'Inter' : 'Inter-bold'};
    font-style: normal;
    font-weight: ${({ isEvent }: { isEvent: boolean }) => isEvent ? '400' : '700'};
    font-size: 16px;
    text-align: left;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    color: ${props => props.theme.color || 'black'};

    &.noShowAll{
        -webkit-line-clamp: 1;
    }

    &.link{
        display: flex;
        align-items: center;
        gap: 8px;
    }
    &.link a{
        font-family: 'Inter';
        font-weight: 400;
        color: #2A4CD3;
    }
    
`

const Counts = styled.div`
    color: var(--default-dark);
    font-family: 'Inter';
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
    display: flex;
    gap: 5px;
    align-items: center;
`

const Description = styled.div`
    color: var(--default-dark);
    font-family: 'Inter';
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-align: left;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  gap: 16px;
  align-items: flex-start;
`;

const Name = styled.div`
    font-family: 'Inter-SemiBold';
    font-size: 18px;
    letter-spacing: 0.1px;
`;