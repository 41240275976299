import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled ,{ keyframes } from "styled-components";
import { LAVENDER } from "../../ClubIconsNavBar";
import Button from 'react-bootstrap/Button';
import { BROWN } from "../Modalities/Modals/components";

export const MainWrapper = styled.div`
    display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  justify-items: flex-start;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
    width: 100%;
    height: inherit;
    padding: 0 20px;
    overflow-y: scroll;
`;

export const TopColumn = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    padding: 0 20px;
    justify-content: space-between;
`;

export const Header = styled(TopColumn)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const Title = styled.h1`
    color: var(--t-blue);
    font-family: 'Inter-bold';
    font-size: 24px;
    font-weight: bold;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
`;

export const RowText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 15px;
    gap: 5px;
`;

export const RowGap = styled(Row)`
    align-items: flex-start;
    gap: 10%;
`;

export const HeaderSubTitle = styled.h2`
    font-family: var(--font-family-roboto_condensed);
    font-weight: 700;
    color: var(--white);
    font-size: var(--font-size-m);
    letter-spacing: 0;
`;

export const HeaderText = styled.p`
    font-family: var(--font-family-roboto_condensed);
    font-weight: 400;
    color: var(--white);
    font-size: var(--font-size-m);
    letter-spacing: 0;
    text-align: left;
`;

export const PhantomElement = styled.div`
    width: 100%;
`;

export const CreateBtn = styled.button`
    align-items: center;
    background-color: var(--t-blue);
    border-radius: 12px;
    display: flex;
    height: 44px;
    padding: 20px;
    margin-left: 13px;
    border: none;
    cursor: pointer;
    /* Find a better way */
    color: var(--white);
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
`;

export const ActionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
`;

export const ClickableIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
`;

const BaseButton = styled(Button)`
    border: 1px solid var(--t-blue);
    border-radius: 12px;
    padding: 14px 20px;
    width: 150px;
    cursor: pointer;
`;

export const CancelButton = styled(BaseButton)`
    background: #FFFFFF;
    box-sizing: border-box;
    color: var(--t-blue);
`;

export const SaveButton = styled(BaseButton)`
    background: var(--t-blue);
    color: #ffffff;
    text-align: center;
    display: flex;
    justify-content: center;
`;

const TitleNestedWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
`;

interface TitleNestedProps {
    onClickBack: () => void
}

export const TitleNested: React.FC<TitleNestedProps> = ({ children, onClickBack }) => (
    <TitleNestedWrapper>
        <ClickableIcon
            icon="chevron-left"
            size="lg"
            color={BROWN}
            onClick={onClickBack}
        />
        <Title>{children}</Title>
    </TitleNestedWrapper>
);

export const BaseInput = styled.input`
    width: 100%;
    border: none;
    margin: none;
    background-color: var(--t-blue);
    font-size: 16px;
    font-family: 'Inter';
    color: white;
    padding: 9px;
`;

export const BaseTextArea = styled.textarea`
    width: 100%;
    border: none;
    margin: none;
    outline: none;
    resize: none;
    background-color: #28115a;
    font-size: 16px;
    font-family: 'Inter';
    color: white;
    padding: 9px;

    &::placeholder {
        color: #FFFFFF77;
        font-size: 16px;
        font-family: 'Inter';
    }
`;