import React from 'react'
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/pro-regular-svg-icons';

const FiltersPanel = ({
  showFilters
}: {
  showFilters: (data: boolean) => void
}) => {
    return (
        <PanelFilters>
            <PanelFilter>
                <HeaderFilter>
                    Modalities
                    <FontAwesomeIcon
                        icon={faTimes}
                        size="lg"
                        onClick={() => showFilters(false)}
                    />
                </HeaderFilter>
                <TitleFilters> Filters </TitleFilters>
                <Filters>
                    <TitleFilter>Benefit</TitleFilter>
                    <List>
                        <Item theme={{ main: 'itemActive' }}>
                            <FontAwesomeIcon
                                icon={faCheck}
                                size="sm"
                            />
                            Decreased Stress & Anxiety
                        </Item>
                        <Item>
                            <FontAwesomeIcon
                                icon={faCheck}
                                size="sm"
                            />
                            Improved Emotional Resilience
                        </Item>
                    </List>
                </Filters>
                <Filters>
                    <TitleFilter>Delivery</TitleFilter>
                    <List>
                        <Item theme={{ main: 'itemActive' }}>
                            <FontAwesomeIcon
                                icon={faCheck}
                                size="sm"
                            />
                            Online
                        </Item>
                        <Item>
                            <FontAwesomeIcon
                                icon={faCheck}
                                size="sm"
                            />
                            In Person</Item>
                    </List>
                </Filters>
                <ButtonFilter>Apply Filters</ButtonFilter>
            </PanelFilter>
        </PanelFilters>
    )
}

const ButtonFilter = styled.div`
  border-radius: 8px;
  background: rgba(23, 27, 33, 0.30);
  position: absolute;
  bottom: 15px;
  margin: 0 16px;
  width: calc(100% - 32px);
  padding: 15px 0;
  color: white;
  font-family: 'Inter-SemiBold';
  font-size: 15px;
`

const Filters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-bottom: 12px;
`
const TitleFilter = styled.div`
  color: #282A2F;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.1px;
  text-align: left;
  padding: 6px 16px;
`
const List = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  padding: 0 16px;
`

const Item = styled.div.attrs(
  props => ({
    className: props.theme.main,
  })
)`
  border-radius: 8px;
  border: 1px solid var(--m-3-sys-light-outline, #79747E);
  background: #FFF;
  padding: 8px 12px;

  & svg{
    display: none;
  }

  &.itemActive{
    display: flex;
    background-color: linen;
    gap: 6px;
  }

  &.itemActive svg{
    display: block;
  }
`

const TitleFilters = styled.div`
  color: #282A2F;
  font-feature-settings: 'clig' off, 'liga' off;
  /* Strong/200 */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 177.778% */
  letter-spacing: 0.1px;
  text-align: left;
  padding: 6px 16px;
`

const HeaderFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #282A2F;
  font-feature-settings: 'clig' off, 'liga' off;
  /* Strong/200 */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 177.778% */
  letter-spacing: 0.1px;
  padding: 6px 16px;
  border-bottom: 1px solid #cfcfcf;
`

const PanelFilters = styled.div`
  position: absolute;
  background-color: #000000ab;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 100%;
  z-index: 11;
  display: none;

  @media only screen and (max-width: 770px) {
    display: block;
  }
`

const PanelFilter = styled.div`
  position: absolute;
  background-color: white;
  width: 100%;
  left: 0;
  bottom: 0;
  height: calc(100% - 65px);
  z-index: 11;
  border-radius: 12px 12px 0px 0px;
`

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export default FiltersPanel