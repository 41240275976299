import React from "react";
import { LinkPreview } from "@dhaiwat10/react-link-preview";
import styled from "styled-components";
import { Backend, MicroServices } from "../../helpers/backendHelper"

interface ILinkPreviewProps {
  url: string;
}

const MemoizedLinkPreview: React.FC<ILinkPreviewProps> = React.memo(({ url }) => (
  <LinkPreview
    className="input-link"
    url={url}
    width="400px"
    fallback={
      <>
        <PWarning>Unable to load link preview, please try again later or use another link.</PWarning>
      </>
    }
    fetcher={(): any =>
      new Promise((resolve, reject) => {
        Backend(MicroServices.Posts, `/post/preview-link-proxy?url=${url}`)
          .then((results) => results.json())
          .then((results) => resolve(results))
          .catch((error) => reject(error));
      })
    }
  />
));

const PWarning = styled.p`
  color: red;
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: 1rem;
  text-align: center;
  width: 100%;
`;

export default MemoizedLinkPreview;
