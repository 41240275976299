import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { userLogin } from "../../store/actions/userActions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { goCircleLoginUrl } from "../../AppConfig";
import styled, { css, keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { Row1, Imp } from "../Website/Form"
import ButtonSpinner from "../../components/BaseComponents/ButtonSpinner";
import { ContextSuccess } from "../../Context";
import { RootState } from '../../store/store';

toast.configure();

interface LoginContainerDispatch {
    dispatchUserLogin: (
        username: string,
        password: string,
        custom?: any,
        token?: string
    ) => Promise<boolean>;
    stayHere?: boolean;
    token?: string;
    loginUseInvite?: boolean;
}

interface LoginContainerProps extends LoginContainerDispatch { }

const Login = ({
    dispatchUserLogin,
    stayHere = false,
    token,
    loginUseInvite,
}: LoginContainerProps) => {
    console.log('callback on Login.tsx', token)
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const currentSuccess = useContext(ContextSuccess);
    const loginErrorMessage = useSelector((state: RootState) => state.getUser.loginErrorMessage);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        const { error, secret } = searchParamsObject;
        if (error) {
            currentSuccess?.setSuccessData(false, true, t('toast_errorCircle'));
        } else if (secret) {
            toast.info("You are being authenticated...");
            dispatchUserLogin(username.toLowerCase(), "", searchParamsObject);
        }
    }, []);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false)
    const [logGoCirle, setLogGoCirle] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleLogin = () => {
        setLoading(true);
        dispatchUserLogin(username.toLowerCase(), password, false, token)
            .then((result) => {
                if (result) {
                    if (!stayHere) navigate("../app");
                    setUsername("");
                    setPassword("");
                    setShowPassword(false);
                    currentSuccess?.setLoginModal(false, false);
                }
                else {
                    console.log("error on login");
                    console.log(loginErrorMessage)
                    currentSuccess?.setSuccessData(false, true, loginErrorMessage ?? 'Error logging in. Try again');
                }
            })
            .finally(() => setLoading(false));
    };

    const handleLoginGoCircle = () => {
        setLogGoCirle(true);
        window.location.href = goCircleLoginUrl;
    };

    const handleSignup = () => {
        currentSuccess?.setLoginModal(false, false);
        navigate('../../../../../../../../signup-healer');
    }

    const handleKeyPress = (e: string) => {
        if(username != '' && password != ''){
            handleLogin()
        }
    }

    return (
        <Form>
            <TitleForm>Please, Log in</TitleForm>
            <LabelSignup>
                {loginUseInvite
                    ? 'Log in to accept this invite'
                    : <>
                        {'Don\'t you have an account? '}
                        <RefSignup onClick={handleSignup}>Sign Up</RefSignup>
                    </>
                }
            </LabelSignup>
            <Inputs>
                <Row1>
                    <Label>Username<Imp>*</Imp></Label>
                    <Input
                        value={username.toLowerCase()}
                        onChange={(e) => setUsername(e.currentTarget.value)}
                        autoComplete={"unInput"}
                        onKeyPress={(event) => {
                            if (event.key === "Enter") {
                                handleKeyPress(event.currentTarget.value)
                            }
                        }}
                    />
                </Row1>
            </Inputs>
            <Inputs>
                <Row1>
                    <Label>Password<Imp>*</Imp></Label>
                    <InputIcon>
                        <Input
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => setPassword(e.currentTarget.value)}
                            autoComplete={"new-password"}
                            onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                    handleKeyPress(event.currentTarget.value)
                                }
                            }}
                        />
                        <StyledFontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            size='sm'
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </InputIcon>
                </Row1>
            </Inputs>
            <TextForgot
                onClick={() => {
                    navigate("../forgot-password")
                    currentSuccess?.setLoginModal(false, false)
                }}
            >
                Forgot password
            </TextForgot>
            <TextTerms>
                By clicking Log in, you agree to {'our '}
                <NavLink to="/terms" onClick={() => currentSuccess?.setLoginModal(false, false)}>
                    Terms of Service
                </NavLink>
                {' and '}
                <NavLink to="/privacy" onClick={() => currentSuccess?.setLoginModal(false, false)}>
                    Privacy Policy
                </NavLink>
            </TextTerms>
            <WrapperButton>
                <ButtonSpinner
                    loading={loading}
                    handleClick={handleLogin}
                    text={'Log in'}
                    hasIcon={true}
                    icon={'arrow-right'}
                />
            </WrapperButton>
        </Form>
    );
};

const LabelSignup = styled.div`
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: black;
    margin: 0px 0 15px 0;
`;

const RefSignup = styled.span`
    color: var(--absolute-zero);
    font-weight: 700;
    cursor: pointer;
    text-decoration: underline;
`;

const WrapperButton = styled.div`
    margin-top: 20px;
`;

const mapDispatchToProps = (dispatch: any) => ({
    dispatchUserLogin: (username: string, password: string, custom?: any, token?: string) =>
        dispatch(userLogin(username, password, custom, token)),
});

export default connect(
    null,
    mapDispatchToProps
)(Login);


const Form = styled.div`
   width: 375px;
   margin-top: 50px;

   @media only screen and (max-width: 500px) {
        width: 100%;
    }
`;

const TitleForm = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin: 0px 0 5px 0;
`;

export const Logo = styled.div`
    width: 100%;
    height: 50px;
    background-image: url(./logoVibe.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    top: 32px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: black;
    cursor: pointer;
    margin-left: 10px;
`;

const TextTerms = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%; 
    text-align: initial;
`

const NavLink = styled(Link)`
  text-decoration: underline;
`;

export const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
    margin: 7px 0;

`

const InputIcon = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    border-radius: 9px;
    align-items: center;
    padding-right: 15px;
    border: 1px solid var(--simple-gray);
    height: 40px;
    
    & ${Input}{
        border: 0;
        height: 38px;
    } 
`

export const InputPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0 0px 0 20px;
    font-size: 15px;
    font-family: 'Inter';
`;

const Label = styled.div`    
    width: 100%;
    text-align: left;
    margin: 3px 0;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`
const Inputs = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
`

const TextForgot = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    padding: 12px 0 30px 0;
    text-decoration: underline;
    cursor: pointer;
`