import { toast } from "react-toastify";
import i18n from '../i18n';
import { refreshTokens } from "../store/actions/tokenActions";
import jwt_decode from "jwt-decode";
import { store } from "../store/store";

// New ----------------------------------------------------------------

import { SDK } from '@vibe/sdk';
import { IEnvironment } from '@vibe/sdk/dist/mss/MSS';
import { projectEnvironment, BackendUrl } from "../AppConfig";
const sdk = new SDK({
    environment: projectEnvironment as IEnvironment,
    apiGateway: true
});

export interface getDataSDKResponse {
    sdk: SDK;
    token?: string;
}

export const getDataSDK = (): Promise<getDataSDKResponse> =>
    new Promise(async (resolve, reject) => {

        //Token Management
        let token = localStorage.getItem('IDToken')
        if (token) {
            const decodedToken = jwt_decode(token) as any
            if (`${decodedToken.exp}000` < new Date().valueOf().toString()) {
                //Token expired
                await store.dispatch<any>(refreshTokens())
                token = localStorage.getItem('IDToken')
            }
        }

        resolve({
            sdk,
            token: token === null ? undefined : token
        })
    })

// New ----------------------------------------------------------------

export enum MicroServices {
    AccessManager,
    Admin,
    Club,
    Connections,
    Events,
    Invite,
    Notification,
    Posts,
    Roster,
    Dashboard,
    Modalities,
    AHA
}

const { t } = i18n;

export const Backend = (MS: MicroServices, URL: string, INFO?: { method?: string, headers?: any, body?: any }, refreshToken = false): Promise<Response> =>
    new Promise(async (resolve, reject) => {

        //Token Management
        let token = localStorage.getItem('IDToken')
        if (token) {
            const decodedToken = jwt_decode(token) as any
            if (`${decodedToken.exp}000` < new Date().valueOf().toString()) {
                //Token expired
                await store.dispatch<any>(refreshTokens())
                token = localStorage.getItem('IDToken')
            }
        }

        if(refreshToken){
            await store.dispatch<any>(refreshTokens())
            token = localStorage.getItem('IDToken')
        }

        let GatewayURL;
        switch (MS) {
            case MicroServices.AccessManager:
                GatewayURL = `${BackendUrl}/access-manager${URL}`
                // GatewayURL = `${ACCESS_MANAGER_URL}${URL}`
                break
            case MicroServices.Admin:
                GatewayURL = `${BackendUrl}/admin${URL}`
                // GatewayURL = `${ADMIN_URL}${URL}`
                break
            case MicroServices.Club:
                GatewayURL = `${BackendUrl}/clubbing${URL}`
                // GatewayURL = `${CLUB_URL}${URL}`
                break
            case MicroServices.Connections:
                GatewayURL = `${BackendUrl}/connection${URL}`
                // GatewayURL = `${CONNECTIONS_URL}${URL}`
                break
            case MicroServices.Events:
                GatewayURL = `${BackendUrl}/eventing${URL}`
                // GatewayURL = `${EVENTS_URL}${URL}`
                break
            case MicroServices.Invite:
                GatewayURL = `${BackendUrl}/invite${URL}`
                // GatewayURL = `${INVITE_URL}${URL}`
                break
            case MicroServices.Notification:
                GatewayURL = `${BackendUrl}/notificating${URL}`
                // GatewayURL = `${NOTIFICATION_URL}${URL}`
                break
            case MicroServices.Posts:
                GatewayURL = `${BackendUrl}/posting${URL}`
                // GatewayURL = `${POSTS_URL}${URL}`
                break
            case MicroServices.Roster:
                GatewayURL = `${BackendUrl}/roster${URL}`
                // GatewayURL = `${ROSTER_URL}${URL}`
                break
            case MicroServices.Dashboard:
                GatewayURL = `${BackendUrl}/dashboarding${URL}`
                // GatewayURL = `${DASHBOARD_URL}${URL}`
                break
            case MicroServices.AHA:
                GatewayURL = `${BackendUrl}/aha${URL}`
                break
            case MicroServices.Modalities:
                GatewayURL = `${BackendUrl}/modalities${URL}`
                break
            default:
                GatewayURL = ''
                break
        }
        fetch(GatewayURL, { ...INFO, headers: { ...INFO?.headers, Authorization: `Bearer ${token}` } })
            //.then((response)=>resolve(response))
            .then(async (response) => {
                switch (response.status) {
                    // case 400:
                    //     throw new Error(`Guard Failed: ${(await response.json()).message || ''}`)
                    case 401:
                        const msj = (await response.json()).message || ''
                        //toast.error(msj + ' Please Reload.')
                        throw new Error(`Token Error: ${msj}`)
                    case 403:
                        //toast.error(t('toast_permissionDenied'))
                        throw new Error(`Forbiden: Token -> ${JSON.parse((await response.json()).body || {}).requester || {}}`)
                    default:
                        resolve(response)
                }
            })
            .catch((e) => {
                console.log(e)
                reject(e)
            })
    })