import React from 'react'
import styled from 'styled-components';
import { getColorsInitials, getInitials } from '../../../helpers/letterHelper';

const AvatarInitials = ({
    avatar,
    name,
    size
}: {
    avatar: string,
    name: string,
    size: number
}) => {

    const num = [...name].reduce((acc, curr) => acc + curr.charCodeAt(0), 0);
    const colors = getColorsInitials(num);

    return (
        <>
            {avatar !== "" ?
                <Avatar
                    theme={{
                        size: size
                    }}
                    src={avatar}
                />
                :
                <Initials
                    theme={{
                        background_color: colors.background,
                        color: colors.color,
                        size: size
                    }}
                >
                    {getInitials(name)}
                </Initials>
            }
        </>
    )
}

const Avatar = styled.img`
    width: ${props => props.theme.size}px;
    height: ${props => props.theme.size}px;
    min-width: ${props => props.theme.size}px;
    min-height: ${props => props.theme.size}px;
    max-width: ${props => props.theme.size}px;
    max-height: ${props => props.theme.size}px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #abb9bf;
`;

const Initials = styled.div`
    width: ${props => props.theme.size}px;
    height: ${props => props.theme.size}px;
    min-width: ${props => props.theme.size}px;
    min-height: ${props => props.theme.size}px;
    max-width: ${props => props.theme.size}px;
    max-height: ${props => props.theme.size}px;
    background-color: ${props => props.theme.background_color};
    color: ${props => props.theme.color};
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #abb9bf;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Inter-SemiBold';
    font-size: ${props => props.theme.size < 50 ? '10px' : '14px'};
`

export default AvatarInitials