import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useGrades from "../../../hooks/useGrades";
import useActiveCountry from "../../../hooks/Locations/useActiveCountry";
import styled, { css } from "styled-components";
import { cleanHTML, cleanStyleHTML } from "../../../helpers/letterHelper";
import UpdateMyOrganization from "./UpdateMyOrganization";
import OtherOrgs from "./OtherOrgs";

const MyOrganization = () => {
    const { t, i18n } = useTranslation();
    const [tab, setTab] = useState<'MY' | 'OTH'>('MY');
    const [isUpdate, setIsUpdate] = useState<boolean>(false);

    const { activeCountriesReactSelect } = useActiveCountry();

    const org = useSelector((state: any) => state.getOrgs.organizationInfo);

    const [seeMore, setSeeMore] = useState(false)

    useEffect(() => {
        if (org && org?.countryCode) {
            setCountry(org?.countryCode);
        }
    }, [org]);

    const { grades, setCountry } = useGrades();

    return (
        <ContentAdmin isAll={tab === 'OTH' || isUpdate}>
            <Tabs isAll={tab === 'OTH' || isUpdate}>
                <Tab theme={{ main: tab === 'MY' && 'tabActive' }} onClick={() => setTab('MY')} left>My Organization</Tab>
                <Tab theme={{ main: tab === 'OTH' && 'tabActive' }} onClick={() => setTab('OTH')} right>Other Organizations</Tab>
            </Tabs>
            {tab === 'OTH' ? (<OtherOrgs />) :
                isUpdate ? <UpdateMyOrganization goBack={() => setIsUpdate(false)} /> : (
                    <>
                        <HeaderAdmin>
                            <Button onClick={() => setIsUpdate(true)}>{t('button__update')}</Button>
                        </HeaderAdmin>
                        <ColumnA>
                            <WrapperLogo>
                                <LogoPlaceHolder src={org?.logo || "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_1280.png"} />
                                Organization Logo
                            </WrapperLogo>
                            <WrapperInfo>
                                <PlaceInfo>{org.name}</PlaceInfo>
                                Organization Name
                            </WrapperInfo>
                            <WrapperInfo>
                                <PlaceInfo>{org?.website || ''}</PlaceInfo>
                                Organization Website
                            </WrapperInfo>
                            <WrapperInfo>
                                <PlaceInfo>{`${org?.orgAdmin?.firstname} ${org?.orgAdmin?.lastname}`}</PlaceInfo>
                                Administrator
                            </WrapperInfo>
                            <WrapperInfo>
                                <PlaceInfo>{(activeCountriesReactSelect.find((country) => country.value === org?.countryCode)?.label) || ''}</PlaceInfo>
                                Country
                            </WrapperInfo>
                            <WrapperInfo>
                                <PlaceInfo>{org?.state || ''}</PlaceInfo>
                                State
                            </WrapperInfo>
                            <WrapperInfo>
                                <PlaceInfo>{org?.city || ''}</PlaceInfo>
                                City
                            </WrapperInfo>
                            <WrapperInfo>
                                <PlaceInfo>{org?.address || ''}</PlaceInfo>
                                Address
                            </WrapperInfo>
                            <WrapperInfo>
                                <PlaceInfo>{org?.zipcode || ''}</PlaceInfo>
                                Zipcode
                            </WrapperInfo>
                        </ColumnA>
                        <ColumnB>
                            <LabelTitle>About you</LabelTitle>
                            <WrapperInfo>
                                <DivOrgStatement>
                                    <PlaceInfoStatement
                                        theme={{ main: seeMore ? 'activeMore' : '' }}
                                        dangerouslySetInnerHTML={{ __html: cleanStyleHTML(org?.mission as string ?? '') }}
                                    />
                                    {org?.mission &&
                                        <ReadMore onClick={() => setSeeMore(!seeMore)}>{seeMore ? 'Read Less' : 'Read More'}</ReadMore>
                                    }
                                </DivOrgStatement>
                                Organization Statement
                            </WrapperInfo>
                            <WrapperInfo>
                                <PlaceInfo>{org?.primaryContact?.name}</PlaceInfo>
                                Primary Contact
                            </WrapperInfo>
                            <WrapperInfo>
                                <PlaceInfo>{org?.primaryContact?.phoneNumber}</PlaceInfo>
                                Primary Contact Phone
                            </WrapperInfo>
                            <WrapperInfo>
                                <PlaceInfo>{org?.primaryContact?.email}</PlaceInfo>
                                Primary Contact Email
                            </WrapperInfo>
                        </ColumnB>
                    </>
                )}
        </ContentAdmin>
    )
};

const DivOrgStatement = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    
`

export const ReadMore = styled.div`
    font-family: 'Inter-Bold';
    font-size: 14px;
    line-height: 17px;
    color: var(--absolute-zero);
    text-align: left;
    cursor: pointer;
`

export const LabelTitle = styled.span`
    color: #1A1A1A;

    font-feature-settings: 'clig' off, 'liga' off;
    /* Strong/400 */
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0.1px;
`;

export const LogoPlaceHolder = styled.img`
    width: 106px;
    height: 106px;
    min-width: 106px;
    min-height: 106px;
    max-width: 106px;
    max-height: 106px;
    border-radius: 8px;
    object-fit: cover;
`;

export const WrapperInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    color: #6B6B6B;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
    justify-items: flex-start;
    align-items: flex-start;
`;

export const WrapperLogo = styled(WrapperInfo)`
    align-items: flex-start;
`;

export const PlaceInfo = styled.div`
    align-items: center;
    width: 100%;

    color: var(--Text-Body-Text, #333);
    font-feature-settings: 'clig' off, 'liga' off;
    /* Medium/100 */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0.2px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    text-align: left;
`;

export const PlaceInfoStatement = styled(PlaceInfo).attrs(
    props => ({
        className: props.theme.main,
    })
)`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    height: 100%;
    min-height: 39px;
    max-height: 111px;

    overflow: scroll;
    white-space: normal;

    &.activeMore{
        min-height: auto;
        max-height: max-content;
        display: block;
    }

`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    align-items: flex-start;
`;

export const ColumnA = styled(Column)`
    grid-area: 3 / 1 / 4 / 2;
`;

export const ColumnB = styled(Column)`
    grid-area: 3 / 2 / 4 / 3;
`;

export const ContentAdmin = styled.div`
    display: ${({ isAll = false }: { isAll?: boolean }) => isAll ? 'flex' : ' grid'};
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 80px);

    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 0.1fr 0.1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 20px;

    padding: 30px 0px 10px 50px;
    overflow: scroll;
`;

export const HeaderAdmin = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    color: var(--t-blue);
    font-family: 'Inter-bold';
    font-size: 24px;
    font-weight: bold;

    grid-area: 2 / 2 / 3 / 3;
`;

export const Button = styled.button`
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;

    width: fit-content;
    min-width: 100px;
    height: 40px;
    padding: 10px 20px;
    border-radius: 11px;
    background-color: var(--simple-blue-dark);
    border: 0;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: white;

    cursor: pointer;
`;

const Tabs = styled.div`
    display: flex;
    justify-content: space-evenly;
    border-radius: 8px;
    overflow: hidden;
    width: ${({ isAll }: { isAll: boolean }) => isAll ? '50%' : '100%'};
    height: 40px;

    grid-area: 1 / 1 / 2 / 2;
`

const Tab = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    background-color: var(--simple-gray-2);
    width: 50%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: 'Inter';
    cursor: pointer;

    ${({ left }: { left?: boolean, right?: boolean }) => left ? css`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    ` : ''}

    ${({ right }: { right?: boolean }) => right ? css`
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    ` : ''}


    &.tabActive{
        background-color: #E89F4D;
        color: white;
        font-family: 'Inter-Bold';
    }
`

export default MyOrganization;
