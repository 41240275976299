import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import { getFiltered, sortAlphabeticallyOnProperty } from "../../../containers/Signup/SignupHealerInd"
import { useEffect, useState } from "react";
import useActiveCountry, { CountryOption } from "../../../hooks/Locations/useActiveCountry";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { BaseFieldState } from "../utils";
import OutsideClickHandler from "react-outside-click-handler";
import useCountriesSA from "../../../hooks/useCountriesSA";

const SelectorCountry = ({
    country,
    setCountry,
    searchCountry,
    setSearchCountry,
    backgroundColor = "white",
    border = "1px solid var(--simple-gray)",
    onlyActive = true,
}: {
    country: BaseFieldState<CountryOption>,
    setCountry: (data: BaseFieldState<CountryOption>) => void,
    searchCountry: string,
    setSearchCountry: (data: string) => void,
    backgroundColor?: string,
    border?: string,
    onlyActive?: boolean
}) => {
    const { activeCountriesReactSelect } = useActiveCountry();
    const { countriesReactSelect } = useCountriesSA({SearchTerm: ''});

    const [showOptionsCountry, setShowOptionsCountry] = useState<boolean>(false);

    const countryOptions = () : CountryOption[] => onlyActive ? activeCountriesReactSelect : countriesReactSelect;

    useEffect(() => {
        const itemFound = countryOptions().find((country) =>
            country.label &&
            (country.label.toLocaleLowerCase() === searchCountry ||
                country.label.toLocaleUpperCase() === searchCountry ||
                country.label === searchCountry)
        )
        if (itemFound) {
            setSearchCountry(itemFound.label)
            setShowOptionsCountry(false)
            setCountry({
                ...country,
                value: itemFound
            })
        }
    }, [searchCountry])

    useEffect(() => {
        if (country.value?.value) {
            setSearchCountry(country.value?.label)
        }
    }, [])

    useEffect(() => {
        if (country.value?.value) {
            setSearchCountry(country.value?.label)
        }
    }, [country])

    const getFilteredOptions = () => {
        if (searchCountry !== country.value?.label) {
            return getFiltered(countryOptions(), searchCountry)
        }
        return countryOptions();
    }

    return (
        <WrapperSelect>
            <Select_Input
                onClick={() => setShowOptionsCountry(true)}
                value={searchCountry}
                placeholder='Select a Country'
                onChange={(e) => setSearchCountry(e.currentTarget.value)}
                backgroundColor={backgroundColor}
                border={border}
                onFocus={() => setShowOptionsCountry(true)}
                autoComplete={"unInput"}
            />
            <IconDropdownInput
                icon={faChevronDown}
                size='sm'
                color={backgroundColor === 'white' ? 'black' : 'white'}
                onClick={() => setShowOptionsCountry(!showOptionsCountry)}
            />
            {showOptionsCountry &&
                <OutsideClickHandler onOutsideClick={() => setShowOptionsCountry(false)}>
                    <Items>
                        {sortAlphabeticallyOnProperty(getFilteredOptions(), 'label').map((activeCountry, index) => (
                            <Item onClick={() => {
                                setSearchCountry(activeCountry.label)
                                setShowOptionsCountry(false)
                                setCountry({
                                    ...country,
                                    value: activeCountry
                                })
                            }
                            }
                                key={index}>
                                {activeCountry.label}
                            </Item>
                        ))}
                    </Items>
                </OutsideClickHandler>
            }
        </WrapperSelect>
    )
}

const WrapperSelect = styled.div`
    position: relative;
    display: flex;
    width: 100%;
`;

interface Select_InputProps {
    backgroundColor: string
    border: string
}

const Select_Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Inter';
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;

    background-color: ${(props: Select_InputProps) => props.backgroundColor};
    border: ${(props: Select_InputProps) => props.border};
    color: ${(props: Select_InputProps) => props.backgroundColor === 'white' ? 'black' : 'white'};

    &::placeholder{
        color: var(--simple-gray);
        font-family: 'Inter';
    }
`;

const IconDropdownInput = styled(FontAwesomeIcon)`
    cursor: pointer;
    position: absolute;
    z-index: 0;
    top: 37%;
    right: 20px;
`;

const Item = styled.li`
    background-color: white;
    padding: 10px 20px;
    text-align: left;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;

    &:last-child {
        border-radius: 0 0 10px 10px;
        -webkit-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        -moz-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
    }

    &:hover {
        background-color: #e8e8e8;
    }
`;

const Items = styled.ul`
    width: 100%;
    position: absolute;
    top: 100%;
    z-index: 10;
    overflow-y: scroll;
    max-height: 200px;
    border-radius: 12px;
    border: 1px solid var(--simple-gray);
    left: 0px;
    box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
`;

export default SelectorCountry;