import { IPostExtended } from '@vibe/sdk/interfaces/Posts/IPost';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { mapPostsCard } from '../../helpers/postHelper';
import Modal from "react-bootstrap/esm/Modal"
import styled from 'styled-components';
import PostCard from '../BaseComponents/Posts/PostCard';

interface FeaturedPostsModalProps {
  show: boolean;
  onHide: () => void;
  postId?: string;
}

const FeaturedPostsModal = ({
  show,
  onHide,
  postId
}: FeaturedPostsModalProps) => {
  const healerFeaturedPosts: IPostExtended[] = useSelector((state: RootState) => state.getPost.getPostsFeaturedProfile);
  const featuredPosts = useMemo(() => mapPostsCard(healerFeaturedPosts), [healerFeaturedPosts]);

  useEffect(() => {
    if (!postId) return;
    const element = document.getElementById(postId);
    if (!element) return;
    setTimeout(()=>{ element.scrollIntoView({ behavior: 'smooth', block: 'start' }); }, 500);
  }, [postId])

  return (
    <Modal className='modalFeatured' show={show} onHide={onHide} >
      <WrapperPosts>
        {featuredPosts.map((post) => (
          <PostCard data={post} setShowSetup={() => { }} hideSave id={post.id} reloadPosts={() => {}}/>
        ))}
      </WrapperPosts>
    </Modal>
  );
}

const WrapperPosts = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    max-height: calc(100vh - 70px);
    /* margin-top: 140px; */
    padding-bottom: 10px;
    padding-bottom: 100px;
    gap: 10px;
    z-index: 1000000;
  margin-top: 30px;

`;

export default FeaturedPostsModal;