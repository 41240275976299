import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getMyEvents, getMyFriendsEvents, getPertinentEvents } from "../../store/actions/eventActions";
import { IGetEventSpecialFilters } from "@vibe/sdk/dist/interfaces/Events/getEvent";
import { EventsResponse } from "@vibe/sdk/dist/interfaces/Events/getEvent";
import { EventCardInterface, rsvp } from "../../components/BaseComponents/Events/EventCard";
import { getEventLocation } from "../../helpers/locationHelper";

type EventTabs = 'ALL' | 'MY' | 'FRIENDS' | 'ADMIN'

export const mapResponseRSVP = (res?: 'RSVPO' | 'RSVPP' | 'I' | 'NI' | 'CHECKIN'): rsvp => {
  if (res === 'RSVPP' || res === 'CHECKIN') return 'Going In Person';
  if (res === 'RSVPO') return 'Going Online';
  if (res === 'I') return 'Interested';
  if (res === 'NI') return 'Not Interested';
  return 'RSVP';
}

export const formatDateTime = (date: string): [string, string, Date] => {
  const parts = date.split('-');
  const dateTime = new Date(`${parts[0]}/${parts[1]}/${parts[2]}`);

  return [
    dateTime.toDateString(),
    `${parts[3]}:${parts[4]}`,
    dateTime
  ]
}

export const mapEventToCard = (event: EventsResponse): EventCardInterface => ({
  id: event.event_id,
  cover: event.event_cover,
  name: event.name,
  description: (event.description ?? '').replace(/style="(.*?)"/gm, ""),
  startDate: event.start ? formatDateTime(event.start)[0] : '',
  startTime: event.start ? formatDateTime(event.start)[1] : '',
  endDate: event.end ? formatDateTime(event.end)[0] : '',
  endTime: event.end ? formatDateTime(event.end)[1] : '',
  delivery: event.delivery,
  interested: event.interested,
  attending: event.goingInPerson + event.goingOnline,
  isFullOnline: event.max_event_capacity_online === event.goingOnline,
  isFullInPerson: event.max_event_capacity_inperson === event.goingInPerson,
  rsvp: mapResponseRSVP(event.userAttendance),
  link: event.link || '',
  eventBriteUrl: event.eventBriteUrl || '',
  address1: event.addressLine1 || '',
  address2: event.addressLine2 || '',
  fee_online: event.eventFeeOnline,
  fee_person: event.eventFeeInPerson,
  guide: {
    name: event.creatorName,
    avatar: event.creatorAvatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png',
    username: event.creator,
  },
  creator: event.creator,
  createdAt: event.createdAt,
  locationComplete: getEventLocation(
    event.addressLine1,
    event.addressLine2,
    event.location,
    event.zipcode,
    event.countryCode,
    event.country,
    event.city,
    event.state,
    event.stateCode
  ),
  modalities: event.modalities,

  typeID: event.eventType,
  modalitiesIDs: event.modalitiesIds || [],
  city_id: event.cityCode || '',
  country_id: event.countryCode || '',
  state_id: event.stateCode || '',
  start: event.start ? formatDateTime(event.start)[2] : new Date(),
})

interface EventFilter {
  modalityId?: string;
  delivery?: 'online' | 'mixed' | 'inPerson' | 'All';
  pickedDate?: Date;
}

const filterEvents = (event: EventsResponse, itemSearch: string, eventFilter: EventFilter) => {
  const filtersResponses = [false, false, false, false];
  if (!itemSearch) filtersResponses[0] = true;
  else if (event.name && (
    event.name.toUpperCase().includes(itemSearch) ||
    event.name.toLowerCase().includes(itemSearch) ||
    event.name.includes(itemSearch)
  )) {
    filtersResponses[0] = true;
  }

  if (!eventFilter.delivery || eventFilter.delivery === 'All') filtersResponses[1] = true;
  else if (event.delivery === eventFilter.delivery) {
    filtersResponses[1] = true;
  }

  if (!eventFilter.modalityId) filtersResponses[2] = true;
  else if (event.modalities.map(({id}) => id).includes(eventFilter.modalityId)) {
    filtersResponses[2] = true;
  }

  if (!eventFilter.pickedDate) filtersResponses[3] = true;
  else {
    const partsStart = event.start.split('-');
    const start = new Date(`${partsStart[0]}/${partsStart[1]}/${partsStart[2]}`);
    const partsEnd = event.end.split('-');
    const end = new Date(`${partsEnd[0]}/${partsEnd[1]}/${partsEnd[2]}`);
    end.setDate(end.getDate() + 1)
    console.log({start, end, 'pickedDate': eventFilter.pickedDate, 'start >= eventFilter.pickedDate && eventFilter.pickedDate <= end': start >= eventFilter.pickedDate && eventFilter.pickedDate <= end})
    if (start <= eventFilter.pickedDate && eventFilter.pickedDate <= end) {
      filtersResponses[3] = true;
    }
  }

  return filtersResponses.every((p) => p);
}

const useEventsPanel = ({
  searchItem
}: {
  searchItem: string
}) => {
  const dispatch = useDispatch();

  const [tab, setTab] = useState<EventTabs>('ALL');
  const [limit, setLimit] = useState<IGetEventSpecialFilters>()

  const username = useSelector((state: RootState) => state.getUser.userInfo?.username);

  const pertinentEvents = useSelector((state: RootState) => state.getEvent.getPertinentEvents ?? []);
  const myEvents = useSelector((state: RootState) => state.getEvent.getMyEvents ?? []);
  const friendsEvents = useSelector((state: RootState) => state.getEvent.getMyFriendsEvents ?? []);

  // Filters
  const [eventsFilter, setEventsFilter] = useState<EventFilter>({
    delivery: undefined,
    modalityId: undefined,
    pickedDate: undefined,
  })

  useEffect(() => console.log('pertinentEvents', pertinentEvents), [pertinentEvents])

  const cardPertinentEvents = useMemo(() => pertinentEvents.filter((event) => filterEvents(event, searchItem, eventsFilter)).map(mapEventToCard), [pertinentEvents, searchItem, eventsFilter]);
  const cardMyEvents = useMemo(() => myEvents.filter((event) => filterEvents(event, searchItem, eventsFilter)).map(mapEventToCard), [myEvents, searchItem, eventsFilter]);
  const cardFriendsEvents = useMemo(() => friendsEvents.filter((event) => filterEvents(event, searchItem, eventsFilter)).map(mapEventToCard), [friendsEvents, searchItem, eventsFilter]);

  const currentEvents = useMemo(() => {
    if (tab === 'ALL') return pertinentEvents;
    else if (tab === 'MY') return myEvents;
    else return friendsEvents;
  }, [pertinentEvents, myEvents, friendsEvents, tab])

  const modalities = useMemo(() => {
    return currentEvents.map(({modalities}) => modalities).flat().filter(({id}, idx, arr) => arr.findIndex(({id: otherId}) => otherId === id) === idx);
  }, [currentEvents])

  const currentCards = useMemo(() => {
    if (tab === 'ALL') return cardPertinentEvents;
    else if (tab === 'MY') return cardMyEvents;
    else return cardFriendsEvents;
  }, [cardPertinentEvents, cardMyEvents, cardFriendsEvents, tab])

  useEffect(() => {
    if (tab === 'ALL') reloadAllEvents();
    else if (tab === 'MY') reloadMyEvents();
    else reloadFriendEvents();
  }, [tab, username, limit]);

  const reloadAllEvents = () => {
    if (!username) return;

    dispatch(getPertinentEvents(username, limit));
  }

  const reloadMyEvents = () => {
    if (!username) return;

    dispatch(getMyEvents(username, limit));
  }

  const reloadFriendEvents = () => {
    if (!username) return;

    dispatch(getMyFriendsEvents(username, limit))
  }

  const reload = () => {
    reloadAllEvents();
    reloadMyEvents();
    reloadFriendEvents();
  }

  const filterChanges = (filter: EventFilter) => {
    setEventsFilter(filter);
  }

  const handleTabChange = (tab: EventTabs) => setTab(tab);

  const handleLimitChange = (lim?: IGetEventSpecialFilters) => setLimit(lim);

  const cleanLimit = () => setLimit(undefined);

  return {
    currentTab: tab,
    currentEvents,
    pertinentEvents,
    myEvents,
    friendsEvents,
    currentCards,
    cardPertinentEvents,
    cardMyEvents,
    cardFriendsEvents,
    modalities,
    handleTabChange,
    handleLimitChange,
    cleanLimit,
    reload,
    filterChanges,
  }
};

export default useEventsPanel;