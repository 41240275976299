import { AnyAction } from "@reduxjs/toolkit";
import {
    GET_INVITES_LIMIT_ERROR,
    GET_INVITES_LIMIT_SUCCESS,
    START_GET_INVITES_LIMIT,
    INVITE_BY_TOKEN_SUCCESS,
    START_GET_INVITE_BY_TOKEN,
    INVITE_BY_TOKEN_ERROR,
    START_GET_ORG_INVITES,
    GET_ORG_INVITES_SUCCESS,
    GET_ORG_INVITES_ERROR,
    DELETE_INVITE_ERROR,
    DELETE_INVITE_SUCCESS,
    START_DELETE_INVITE,
    RESEND_INVITE_ERROR,
    RESEND_INVITE_SUCCESS,
    START_RESEND_INVITE,
    START_VALIDATE_INVITE_ROSTER,
    VALIDATE_INVITE_ROSTER_ERROR,
    VALIDATE_INVITE_ROSTER_SUCCESS,
    START_CREATE_INVITE_ROSTER,
    CREATE_INVITE_ROSTER_ERROR,
    CREATE_INVITE_ROSTER_SUCCESS,
    START_CREATE_INVITE,
    CREATE_INVITE_SUCCESS,
    CREATE_INVITE_ERROR,
    START_GET_COMM_INVITES,
    GET_COMM_INVITES_SUCCESS,
    GET_COMM_INVITES_ERROR,
    LOGOUT_INVITES,
    GET_INVITES_SETUP_ERROR,
    GET_INVITES_SETUP_SUCCESS,
    START_GET_INVITES_SETUP,
    START_ACCEPT_INVITE,
    ACCEPT_INVITE_SUCCESS,
    ACCEPT_INVITE_ERROR,

} from "../constants/inviteConstants";
import InvitesStateInterface from "../../interfaces/store/inviteState";

const initialState: InvitesStateInterface = {
    invites: [],
    loadingInvites: false,
    invitesError: undefined,

    loadingGetInvites_setup: false,
    getInvites_setupError: undefined,
    getInvites_setup: [],

    invitesLimit: [],
    loadingInvitesLimit: false,
    invitesLimitError: undefined,
    lastEvaluatedkeys: [],

    loadingInviteTypes: false,
    inviteTypesError: undefined,
    inviteTypes: [],

    loadingInviteByToken: false,
    inviteByTokenError: undefined,
    inviteByToken: [],

    loadingDeleteInvite: false,
    deleteInviteError: undefined,
    deleteInvite: undefined,

    loadingResendInvite: false,
    resendInviteError: undefined,
    resendInvite: undefined,

    loadingValidateInviteRoster: false,
    validateInviteRosterError: undefined,
    validateInviteRoster: undefined,

    loadingCreateInviteRoster: false,
    createInviteRosterError: undefined,
    createInviteRoster: undefined,

    loadingOrgInvites: false,
    orgInvitesError: undefined,
    orgInvites: [],

    loadingCreateInvite: false,
    CreateInviteError: undefined,
    CreateInvite: [],

    loadingCommunityInvites: false,
    communityInvitesError: undefined,
    communityInvites: { staffUsers: [], invitesCount: {} },

    acceptInviteTry: false,
    loadingAcceptInvite: false,
    acceptInviteError: undefined,
};

export const getInvitesReducer = (state = initialState, action: AnyAction): InvitesStateInterface => {
    switch (action.type) {
        case START_GET_INVITES_LIMIT:
            return {
                ...state,
                loadingInvitesLimit: true,
            };
        case GET_INVITES_LIMIT_SUCCESS: {
            const newLastEvaluatedKey = [...state.lastEvaluatedkeys];
            newLastEvaluatedKey[action.payload.page + 1] = action.payload.lastEvaluatedKey;

            return {
                ...state,
                invitesLimit: action.payload.invites,
                loadingInvitesLimit: false,
                lastEvaluatedkeys: [...newLastEvaluatedKey],
            };
        }
        case GET_INVITES_LIMIT_ERROR:
            return {
                ...state,
                invitesLimitError: action.payload,
                loadingInvitesLimit: false,
            };
        case START_GET_INVITE_BY_TOKEN:
            return {
                ...state,
                loadingInviteByToken: true,
            };

        case INVITE_BY_TOKEN_SUCCESS: {
            return {
                ...state,
                inviteByToken: action.payload,
                loadingInviteByToken: false
            };
        }
        case INVITE_BY_TOKEN_ERROR:
            return {
                ...state,
                inviteByTokenError: action.payload,
                loadingInviteByToken: false,
            };

        case START_GET_ORG_INVITES:
            return {
                ...state,
                loadingOrgInvites: true,
            }
        case GET_ORG_INVITES_SUCCESS:
            return {
                ...state,
                loadingOrgInvites: false,
                orgInvites: action.payload,
            }
        case GET_ORG_INVITES_ERROR:
            return {
                ...state,
                loadingOrgInvites: false,
                orgInvitesError: action.payload,
            }

        case START_DELETE_INVITE:
            return {
                ...state,
                loadingDeleteInvite: true,
            };

        case DELETE_INVITE_SUCCESS: {
            return {
                ...state,
                deleteInvite: action.payload,
                loadingDeleteInvite: false
            };
        }
        case DELETE_INVITE_ERROR:
            return {
                ...state,
                deleteInviteError: action.payload,
                loadingDeleteInvite: false,
            };

        case START_RESEND_INVITE:
            return {
                ...state,
                loadingResendInvite: true,
            };

        case RESEND_INVITE_SUCCESS: {
            return {
                ...state,
                resendInvite: action.payload,
                loadingResendInvite: false
            };
        }
        case RESEND_INVITE_ERROR:
            return {
                ...state,
                resendInviteError: action.payload,
                loadingResendInvite: false,
            };

        case START_VALIDATE_INVITE_ROSTER:
            return {
                ...state,
                loadingValidateInviteRoster: true,
            };

        case VALIDATE_INVITE_ROSTER_SUCCESS: {
            return {
                ...state,
                validateInviteRoster: action.payload,
                loadingValidateInviteRoster: false
            };
        }
        case VALIDATE_INVITE_ROSTER_ERROR:
            return {
                ...state,
                validateInviteRosterError: action.payload,
                loadingValidateInviteRoster: false,
            };

        case START_CREATE_INVITE_ROSTER:
            return {
                ...state,
                loadingCreateInviteRoster: true,
            };

        case CREATE_INVITE_ROSTER_SUCCESS: {
            return {
                ...state,
                createInviteRoster: action.payload,
                loadingCreateInviteRoster: false
            };
        }
        case CREATE_INVITE_ROSTER_ERROR:
            return {
                ...state,
                createInviteRosterError: action.payload,
                loadingCreateInviteRoster: false,
            };

        case START_CREATE_INVITE:
            return {
                ...state,
                loadingCreateInvite: true,
            }
        case CREATE_INVITE_SUCCESS:
            return {
                ...state,
                loadingCreateInvite: false,
                CreateInvite: action.payload,
            }
        case CREATE_INVITE_ERROR:
            return {
                ...state,
                loadingCreateInvite: false,
                CreateInviteError: action.payload,
            }

        case START_GET_COMM_INVITES:
            return {
                ...state,
                loadingCommunityInvites: true,
            }
        case GET_COMM_INVITES_SUCCESS:
            return {
                ...state,
                loadingCommunityInvites: false,
                communityInvites: action.payload,
            }
        case GET_COMM_INVITES_ERROR:
            return {
                ...state,
                loadingCommunityInvites: false,
                communityInvitesError: action.payload,
            }

        case START_GET_INVITES_SETUP:
            return {
                ...state,
                loadingGetInvites_setup: true,
            };
        case GET_INVITES_SETUP_SUCCESS:
            return {
                ...state,
                loadingGetInvites_setup: false,
                getInvites_setup: action.payload,
            };
        case GET_INVITES_SETUP_ERROR:
            return {
                ...state,
                loadingGetInvites_setup: false,
                getInvites_setupError: action.payload,
            };

        case START_ACCEPT_INVITE:
            return {
                ...state,
                acceptInviteTry: true,
                loadingAcceptInvite: true,
            };

        case ACCEPT_INVITE_SUCCESS: {
            return {
                ...state,
                loadingAcceptInvite: false,
            };
        }

        case ACCEPT_INVITE_ERROR: {
            return {
                ...state,
                loadingAcceptInvite: false,
                acceptInviteError: action.payload,
            };
        }

        case LOGOUT_INVITES:
            return initialState;

        default:
            return state;
    }
};