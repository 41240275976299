import { useEffect, useState } from "react";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import SelectItem from "../../interfaces/SelectItem";

export interface CountryOption extends SelectItem { };

const useActiveCountry = () => {
  const [activeCountries, setActiveCountries] = useState<string[]>([]);
  const [activeCountriesReactSelect, setActiveCountriesReactSelect] = useState<CountryOption[]>([])
  
  useEffect(() => {
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Roster.getActiveCountries(dataSDK.token);
      })
      .then((results) => {
        if (!Array.isArray(results.body)) {
          return;
        }
        setActiveCountries(results.body.map((country) => country.name));
        setActiveCountriesReactSelect(
          results.body.map((country) => ({ 
            value: country.location.replaceAll('#','_'),
            label: country.name 
          }))
        )
      })
  }, []);

  return { 
    activeCountries, 
    activeCountriesReactSelect 
  };
}

export default useActiveCountry;