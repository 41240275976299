import { EventsResponse } from "@vibe/sdk/dist/interfaces/Events/getEvent";

export const sortEventsProperly = (events: EventsResponse[], order?: 'DESC' | 'ASC') => events.sort((a, b) => {
    const stringsA = a.start.split('-');
    const stringsB = b.start.split('-');

    const startTimeA = new Date(`${stringsA[0]}-${stringsA[1]}-${stringsA[2]}T${stringsA[3]}:${stringsA[4]}`).getTime();
    const startTimeB = new Date(`${stringsB[0]}-${stringsB[1]}-${stringsB[2]}T${stringsB[3]}:${stringsB[4]}`).getTime();

    return (order && order === 'ASC') ? startTimeB - startTimeA : startTimeA - startTimeB;
});
