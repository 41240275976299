import styled from "styled-components";
import { Title } from "../SuperAdminComponents/basicForSACenter";
import SearchInput from "../../BaseComponents/SearchInput";
import { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateInterestModal from "./Modals/CreateInterestModal";
import useIntListsSA from "../../../hooks/Interests/useIntListsSA";
import useInterestsSA from "../../../hooks/Interests/useInterestsSA";
import BlankTable from "../../BaseComponents/BlankTable";
import { onHideType } from "../../BaseComponents/Modals/ModalUnsaved";
import { ContextSuccess } from "../../../Context";
import CreateListIntModal from "./Modals/CreateListIntModal";
import AssignIntModal from "./Modals/AssignIntModal";
import { INTList, Interest } from "@vibe/sdk/dist/interfaces/Connections/newInterests";
import useCountry from "../../../hooks/useCountry";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import UpdateIntModal from "./Modals/UpdateIntModal";

const InterestsView = () => {
    const currentSuccess = useContext(ContextSuccess);

    const [SearchTerm, setSearchTerm] = useState("");
    const [showCreateList, setShowCreateList] = useState<boolean>(false);
    const [showCreateInterest, setShowCreateInterest] = useState<boolean>(false);
    const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [listSelected, setListSelected] = useState<INTList | '0'>('0');
    const [intSelected, setIntSelected] = useState<Interest>({
        id: '',
        name: '',
    });

    const {
        interFiltered,
        interByList,
        reloadInterests,
        intListInfo
    } = useInterestsSA({
        searchTerm: SearchTerm,
        listId: listSelected === '0' ? listSelected : listSelected.id,
    });

    const { intlists, reload } = useIntListsSA();
    const { countries, countriesReactSelect } = useCountry();

    const reloadBoth = () => {
        reload();
        reloadInterests();
    }

    const interestsListMapped = () => {
        return (interFiltered || []).map(i => {
            let asgName = '';
            let status = 'Not Assigned';
            const intList = interByList.find(int => int.id === i.id);
            if (intList) {
                asgName = intList.assignedName || intList.name || '';
                status = 'Assigned';
            }
            return {
                id: i.id,
                labels: [
                    i.name,
                    asgName,
                    status
                ]
            }
        })
    }

    const handleActivate = (data: string) => {
        setShowAssignModal(true);
        const interest = interFiltered.find(i => i.id === data);
        if (interest) setIntSelected(interest);
    }

    const onHideDeactivate = (type: onHideType, id: string) => {
        switch (type) {
            case 'save':
                if (listSelected === '0') return
                currentSuccess?.setLoadingModal(true);
                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        return dataSDK.sdk.Connections.deassignINTToINTList({
                            intId: id,
                        }, listSelected.id, dataSDK.token);
                    })
                    .then((response) => {
                        if (response.statusCode === 201) {
                            currentSuccess?.setSuccessData(true, true, 'Interest unassigned successfully');
                        } else {
                            currentSuccess?.setSuccessData(false, true, 'Error unassigning interest');
                        }
                    })
                    .finally(() => {
                        currentSuccess?.setModalsOff();
                        currentSuccess?.setLoadingModal(false);
                        reloadBoth();
                    })
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                break;

            default:
                break;
        }
    }

    const handleDeactivate = (data: string) => {
        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => onHideDeactivate(type, data),
            'Do you want to unassign this interest from the active list?',
            'Unassign'
        );
    }

    const handleUpdate = (data: string) => {
        setShowUpdateModal(true);
        const interest = interFiltered.find(i => i.id === data);
        if (interest) setIntSelected(interest);
    }

    const onHideDelete = (type: onHideType, id: string) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);
                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        return dataSDK.sdk.Connections.deleteINT(id, dataSDK.token);
                    })
                    .then((response) => {
                        if (response.statusCode === 201) {
                            reloadBoth();
                            currentSuccess?.setSuccessData(true, true, 'Interest deleted successfully');
                        } else {
                            currentSuccess?.setSuccessData(false, true, 'Error deleting interest');
                        }
                    })
                    .finally(() => {
                        currentSuccess?.setLoadingModal(false);
                        currentSuccess?.setModalsOff();
                    })
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                break;

            default:
                break;
        }
    }

    const handleDelete = (data: string) => {
        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => onHideDelete(type, data),
            'Do you want to delete this interest?'
        );
    }

    const getCountriesList = (): string => {
        const countryNames: string[] = [];

        (intListInfo.countries || []).forEach(c => {
            const countryInfo = countriesReactSelect.find(co => co.value === c);

            if (countryInfo) countryNames.push(countryInfo.label);
            else countryNames.push(c);
        })

        return countryNames.join(', ');
    }

    return (
        <Wrapper>
            <Title>Interests</Title>
            <WrapperRow>
                <WrapButtons>
                    <Button onClick={() => setShowCreateList(true)}>
                        <StyledFontAwesomeIcon
                            size='sm'
                            color='white'
                            icon='plus'
                        />
                        Create List
                    </Button>
                    <Button isInterest={true} onClick={() => setShowCreateInterest(true)}>
                        <StyledFontAwesomeIcon
                            size='sm'
                            color='white'
                            icon='plus'
                        />
                        Create Interest
                    </Button>
                </WrapButtons>
                <SearchInput
                    filteredItem={SearchTerm}
                    setFilteredItem={setSearchTerm}
                    border={'1px solid var(--simple-gray)'}
                    placeholder={`Search by name`}
                />
            </WrapperRow>
            <WrapContent>
                <Templates>
                    <LabelTemplates>Active Interests Lists</LabelTemplates>
                    <ScrollTemplate>
                        <Area active={listSelected === '0'} onClick={() => setListSelected('0')}>
                            <WrapRow>
                                All Interests
                                {(listSelected === '0') && (
                                    <IconPlus
                                        icon='arrow-right'
                                        size='sm'
                                        color="white"
                                    />
                                )}
                            </WrapRow>
                            <BottomLine active={listSelected === '0'} />
                        </Area>
                        {intlists.map(list => (
                            <Area
                                active={listSelected !== '0' && listSelected.id === list.id}
                                onClick={() => setListSelected(list)}
                            >
                                <WrapRow>
                                    {list.name}
                                    {(listSelected !== '0' && listSelected.id === list.id) && (
                                        <IconPlus
                                            icon='arrow-right'
                                            size='sm'
                                            color="white"
                                        />
                                    )}
                                </WrapRow>
                                <BottomLine active={listSelected !== '0' && listSelected.id === list.id} />
                            </Area>
                        ))}
                    </ScrollTemplate>
                </Templates>
                <ListInfo>
                    {(listSelected !== '0') && (
                        <Info>
                            <Row>
                                Name
                                <RowInfoLabel>{intListInfo.name}</RowInfoLabel>
                            </Row>
                            <Row>
                                Active in Country
                                <RowInfoLabel>{getCountriesList()}</RowInfoLabel>
                            </Row>
                        </Info>
                    )}
                    <ScrollInt>
                        {(listSelected === '0') ? (
                            <BlankTable
                                headerColored
                                border
                                headers={['Interest', 'Lists where has been assigned']}
                                items={interFiltered.map(i => ({
                                    id: i.id,
                                    labels: [
                                        i.name,
                                        `${i.INTLists?.length || 0}`
                                    ]
                                }))}
                                actions={['UPDATE', 'DELETE']}
                                actionsHandlers={{
                                    handleDelete,
                                    handleUpdate,
                                }}
                                backgroundColor={'white'}
                            />
                        ) : (
                            <BlankTable
                                headerColored
                                border
                                headers={['Interest', 'Assigned Name', 'Status']}
                                items={interestsListMapped()}
                                actions={['ACTIVATE', 'DEACTIVATE']}
                                actionsHandlers={{
                                    handleActivate,
                                    handleDeactivate,
                                }}
                                backgroundColor={'white'}
                            />
                        )}
                    </ScrollInt>
                </ListInfo>

            </WrapContent>
            <CreateListIntModal
                show={showCreateList}
                setShow={setShowCreateList}
                reloadList={reload}
            />
            <CreateInterestModal
                show={showCreateInterest}
                setShow={setShowCreateInterest}
                reloadList={reloadInterests}
            />
            <AssignIntModal
                show={showAssignModal}
                setShow={setShowAssignModal}
                reloadList={reloadBoth}
                intList={listSelected}
                interest={intSelected}
            />
            <UpdateIntModal
                show={showUpdateModal}
                setShow={setShowUpdateModal}
                reloadList={reloadBoth}
                interest={intSelected}
            />
        </Wrapper>
    )
}

const WrapRow = styled.div`
    display: flex;
    width: 100%;
    gap: 15px;
    justify-content: space-between;
`;

const IconPlus = styled(FontAwesomeIcon)`
    cursor: pointer;
`;

interface areaProps {
    active: boolean;
}

const BottomLine = styled.div`
    width: 100%;
    background-color: var(--simple-gray);
    height: 1px;
    border-radius: 8px;
    display: ${({ active }: areaProps) => active ? 'none' : 'flex'};
`;

const Area = styled.div`
    background-color: ${({ active }: areaProps) => active ? 'var(--t-blue)' : 'transparent'};
    color: ${({ active }: areaProps) => active ? 'white' : 'var(--t-blue)'};
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    font-family: 'Inter';
    line-height: 18px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: ${({ active }: areaProps) => active ? '20px 15px 20px 10px' : '10px 15px 0px 10px'};
    gap: 8px;
    align-items: center;
    text-align: left;
    flex-direction: column;
`;

const LabelTemplates = styled.h1`
    font-family: 'Inter-bold';
    font-size: 16px;
    font-weight: bold;
`;


const Templates = styled.div`
    display: flex;
    flex-direction: column;
    background-color: var(--t-off-white);
    border-radius: 9px;
    align-items: flex-start;
    padding: 15px 0px 15px 15px;
    height: 70vh;
`;

const ScrollTemplate = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: scroll;
    width: 100%;
    margin-top: 20px;
`;

const ScrollInt = styled.div`
    display: flex;
    overflow: scroll;
    width: 100%;
    height: fit-content;
    max-height: 100%;
`;

const ListInfo = styled.div`
    display: flex;
    overflow: hidden;
    width: 100%;
    flex-direction: column;
    height: 100%;
    gap: 15px;
`;

const Info = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 25px;
    overflow: hidden;
    width: 100%;
    border: 1px solid var(--simple-gray);
    border-radius: 8px;
    padding: 10px;
    height: 65px;
    align-items: center;
`;

const Row = styled.div`
    display: flex;
    overflow: hidden;
    gap: 15px;

    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
`;

const RowInfoLabel = styled.span`
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    color: black;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    height: 90vh;
    padding: 30px;
    flex-direction: column;
    gap: 25px;
    align-items: flex-start;
    overflow: hidden;
`;

const WrapperRow = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;
  align-items: flex-end;
`;

const WrapContent = styled.div`
  display: grid;
  grid-template-columns: 20% calc(80% - 25px);
  grid-column-gap: 25px;
  width: 100%;
  height: 100%;
  padding-bottom: 115px;
`;

const WrapButtons = styled.div`
  display: flex;
  gap: 10px;
  width: 60%;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-width: 100px;
  height: 100%;
  padding: 10px;
  border-radius: 11px;
  background-color: ${({ isInterest }: { isInterest?: boolean }) => isInterest ? 'var(--simple-blue-dark)' : 'var(--electric-yellow)'};
  border: 0;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: white;
  gap: 10px;

  cursor: pointer;
`;

export default InterestsView;