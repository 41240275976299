import { AnyAction } from "redux";
import { GET_BALANCE_ERROR, GET_BALANCE_SUCCESS, GET_TRANSACTIONS_ERROR, GET_TRANSACTIONS_SUCCESS, LOGOUT_GV, START_GET_BALANCE, START_GET_TRANSACTIONS, TOGGLE_BALANCE } from "../constants/GVConstants";
import { GVTransactionGet } from "@vibe/sdk/dist/interfaces/Admin/GoodVibes";

interface GVState {
  balanceVisible: boolean;

  loadingBalance: boolean;
  balance: number;
  balanceError?: any;

  loadingTransactions: boolean;
  credits: GVTransactionGet[];
  debits: GVTransactionGet[];
  transactionsError?: any;
}

const initialState : GVState = {
  balanceVisible: true,

  loadingBalance: false,
  balance: 0,
  balanceError: undefined,

  loadingTransactions: false,
  credits: [],
  debits: [],
  transactionsError: undefined,
};

export const getGVReducer = (state = initialState, action: AnyAction) : GVState => {
  switch (action.type) {
    case START_GET_BALANCE:
      return {
        ...state,
        loadingBalance: true,
      }
    case GET_BALANCE_SUCCESS:
      return {
        ...state,
        loadingBalance: false,
        balance: action.payload,
      }
    case GET_BALANCE_ERROR:
      return {
        ...state,
        loadingBalance: false,
        balanceError: action.payload,
      }
    case START_GET_TRANSACTIONS:
      return {
        ...state,
        loadingTransactions: true,
      }
    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loadingTransactions: false,
        credits: action.payload.asReceiver,
        debits: action.payload.asSender,
      }
    case GET_TRANSACTIONS_ERROR:
      return {
        ...state,
        loadingTransactions: false,
        transactionsError: action.payload,
      }

    case TOGGLE_BALANCE:
      return {
        ...state,
        balanceVisible: !state.balanceVisible,
      }
    
    case LOGOUT_GV:
      return initialState;

    default:
      return state;
  }
};
