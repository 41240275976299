import styled from "styled-components";
import {
    TitleContent, SubText
} from "../../components/signupsetup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-thin-svg-icons";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const StartRegister = ({
    handleTabActive
}: {
    handleTabActive: (value: number) => void
}) => {

    const user = useSelector((state: RootState | any) => state.getUser.user);
    return (
        <Container>
            <Info>
                <Logo />
                <Name>{user.firstname || 'Hey,'} {user.lastname || ''}</Name>
                <Desc>Your registration was a success!</Desc>
                <Text>To access Vibe please personalize your account</Text>
                <Button onClick={() => handleTabActive(1)}>
                    Set up my profile
                    <IconButtonFtr
                        icon={faArrowRight}
                        size='lg'
                        color={"var(--black)"}
                        style={{
                            fontSize: '16px'
                        }}
                    />
                </Button>
            </Info>
        </Container>
    )
}

const Container = styled.div`
    background-color: var(--dark-blue);
    height: 100vh;
    width: 100%;
    background-image: url(./SlideSetup.png);
`

const Logo = styled.div`
    width: 180px;
    height: 80px;
    background-image: url(./LogoWhite.png);
`

const Info = styled.div`
    display: flex;
    gap: 32px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: #CFBFAE;
`

const Name = styled.div`
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Inter';
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 64px;
    letter-spacing: 0.1px;
    color: #CFBFAE;
`

const Desc = styled.div`
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Inter';
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
`

const Text = styled.div`
    font-feature-settings: 'clig' off, 'liga' off;
    /* Strong/400 */
    font-family: 'Inter';
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 137.5% */
    letter-spacing: 0.1px;
    color: #FFF;
`

const Button = styled.div`
    color: var(--black);
    cursor: pointer;
    box-sizing: border-box;
    justify-content: center;
    padding: 8px 16px;
    width: 262px;
    height: 56px;
    background: var(--white);
    border: 1px solid var(--dark-blue);
    border-radius: 8px;
    font-family: 'Inter-Bold';
    font-size: 23px;
    display: flex;
    align-items: center;
`

const IconButtonFtr = styled(FontAwesomeIcon)`
    padding: 0 10px;
`;


export default StartRegister