import { useEffect, useState } from 'react';
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet, faThumbTack, faMagnifyingGlass, faArrowUpArrowDown, faCaretDown, faCalendar, faBrowser } from "@fortawesome/pro-regular-svg-icons";
import {
    ContainerTable, Filters, InputSearch, Input, SelectFilter, StyledTable, THead, TR, TD,
    TextHead, TBody, ViewUser, Avatar, TextBlue, Count, IconGeoUp, Price
} from "./Dashboards.styles"
import useDashboard from '../../hooks/Dashboard/useDashboard';
import { EventTable } from '@vibe/sdk/dist/interfaces/Dashboard/tables';
import TableLoader from './components';
import { Link } from 'react-router-dom';

const getTableRow = (
    event: EventTable,
    castBigNumbers: (n: number) => string,
    castMoneyNumbers: (n: number) => string,
    castDateTimestamp: (timestamp: string) => string,
    handleTab: (text: string) => void
) => {
    return (
        <TR>
            <TD>
                <Link onClick={() => handleTab('BULLETIN')} to={`./event/${event.id}`}><TextBlue>{event.name}</TextBlue></Link>
            </TD>
            <TD>
                {castBigNumbers(event.attendanceOnline)}
            </TD>
            <TD>
                {castBigNumbers(event.attendanceInPerson)}
            </TD>
            <TD>
                <Count>
                    <Price>
                        ${castMoneyNumbers(event.GV)}
                    </Price>
                    <IconGeoUp theme={{ color: '#0F9936' }} />
                </Count>
            </TD>
            <TD>
                {castDateTimestamp(event.createdAt)}
            </TD>
        </TR>
    )
}

const TableEvents = (props: any) => {
    const { eventsDashboardTable, castBigNumbers, castMoneyNumbers, castDateTimestamp, reloadDashboardTable, loadingGeneral, loadingEventsTable } = useDashboard()

    useEffect(() => {
        reloadDashboardTable('events')
    }, [])

    return (
        <ContainerTable>
            <Filters>
                <InputSearch>
                    <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        size="sm"
                        color={'black'}
                    />
                    <Input />
                </InputSearch>
                <SelectFilter>
                    <FontAwesomeIcon
                        icon={faWallet}
                        size="sm"
                        color={'black'}
                    />
                    Type
                    <FontAwesomeIcon
                        icon={faCaretDown}
                        size="sm"
                        color={'black'}
                    />
                </SelectFilter>
                <SelectFilter>
                    <FontAwesomeIcon
                        icon={faBrowser}
                        size="sm"
                        color={'black'}
                    />
                    Delivery
                    <FontAwesomeIcon
                        icon={faCaretDown}
                        size="sm"
                        color={'black'}
                    />
                </SelectFilter>
                <SelectFilter>
                    <FontAwesomeIcon
                        icon={faThumbTack}
                        size="sm"
                        color={'black'}
                    />
                    Benefits
                    <FontAwesomeIcon
                        icon={faCaretDown}
                        size="sm"
                        color={'black'}
                    />
                </SelectFilter>
            </Filters>
            <StyledTable>
                <THead>
                    <TR>
                        <TD>
                            <TextHead>
                                Event
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    size="sm"
                                    color={'black'}
                                />
                            </TextHead>

                        </TD>
                        <TD>
                            <TextHead>
                                Attended Online
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    size="sm"
                                    color={'black'}
                                />
                            </TextHead>
                        </TD>
                        <TD>
                            <TextHead>
                                Attended In Person
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    size="sm"
                                    color={'black'}
                                />
                            </TextHead>
                        </TD>
                        <TD>
                            <TextHead>
                                Revenue
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    size="sm"
                                    color={'black'}
                                />
                            </TextHead>
                        </TD>
                        <TD>
                            <TextHead>
                                Date created
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    size="sm"
                                    color={'black'}
                                />
                            </TextHead>
                        </TD>
                    </TR>
                </THead>
                <TBody>
                    {loadingGeneral || loadingEventsTable ? (
                        <TableLoader />
                    ) : eventsDashboardTable.map(event => getTableRow(event, castBigNumbers, castMoneyNumbers, castDateTimestamp, props.handleTab))}
                </TBody>
            </StyledTable>
        </ContainerTable>
    )
}

export default TableEvents;