import { AnyAction } from 'redux';
import Connection from '../../interfaces/user/Connection';
import Friend from '../../interfaces/user/Friend';
import FriendRequest from '../../interfaces/user/FriendRequest';
import UnregisteredUser from '../../interfaces/user/UnregisteredUser';
import {
  CANCEL_FRIEND_REQUEST_ERROR,
  CANCEL_FRIEND_REQUEST_SUCCESS,
  CREATE_EMAIL_REQ_ERROR,
  CREATE_EMAIL_REQ_SUCCESS,
  CREATE_FRIEND_REQUEST_ERROR,
  CREATE_FRIEND_REQUEST_SUCCESS,
  DELETE_FRIEND_ERROR,
  DELETE_FRIEND_SUCCESS,
  GET_CLUB_SUG_ERROR,
  GET_CLUB_SUG_SUCCESS,
  GET_CONNECTIONS_ERROR,
  GET_CONNECTIONS_SUCCESS,
  GET_C_SUG_ERROR,
  GET_C_SUG_SUCCESS,
  GET_INT_USER_ERROR,
  GET_INT_USER_SUCCESS,
  GET_I_SUG_ERROR,
  GET_I_SUG_SUCCESS,
  GET_UNREG_SUG_ERROR,
  GET_UNREG_SUG_SUCCESS,
  GET_USER_BIRTHDAY_CONNECTIONS_ERROR,
  GET_USER_BIRTHDAY_CONNECTIONS_SUCCESS,
  GET_USER_FRIENDS_ERROR,
  GET_USER_FRIENDS_SUCCESS,
  GET_USER_PENDING_REQUESTS_ERROR,
  GET_USER_PENDING_REQUESTS_SUCCESS,
  GET_USER_POSSIBLE_CONNECTIONS_ERROR,
  GET_USER_POSSIBLE_CONNECTIONS_SUCCESS,
  GET_USER_SENT_REQUESTS_ERROR,
  GET_USER_SENT_REQUESTS_SUCCESS,
  GET_U_SUG_ERROR,
  GET_U_SUG_SUCCESS,
  LOGOUT_CONNECTION,
  REJECT_FRIEND_REQUEST_ERROR,
  REJECT_FRIEND_REQUEST_SUCCESS,
  SAVE_I_ERROR,
  SAVE_I_SUCCESS,
  SAVE_NEW_FRIEND_ERROR,
  SAVE_NEW_FRIEND_SUCCESS,
  SEARCH_ENGINE_ERROR,
  SEARCH_ENGINE_SUCCESS,
  START_CANCEL_FRIEND_REQUEST,
  START_CREATE_EMAIL_REQ,
  START_CREATE_FRIEND_REQUEST,
  START_DELETE_FRIEND,
  START_GET_CLUB_SUG,
  START_GET_CONNECTIONS,
  START_GET_C_SUG,
  START_GET_INT_USER,
  START_GET_I_SUG,
  START_GET_UNREG_SUG,
  START_GET_USER_BIRTHDAY_CONNECTIONS,
  START_GET_USER_FRIENDS,
  START_GET_USER_PENDING_REQUESTS,
  START_GET_USER_POSSIBLE_CONNECTIONS,
  START_GET_USER_SENT_REQUESTS,
  START_GET_U_SUG,
  START_REJECT_FRIEND_REQUEST,
  START_SAVE_I,
  START_SAVE_NEW_FRIEND,
  START_SEARCH_ENGINE,
} from '../constants/connectionsConstants';
import { UserConnection } from '@vibe/sdk/dist/interfaces/Roster/getUserConnections';
import { Interest } from '@vibe/sdk/dist/interfaces/Connections/newInterests';
import { SearchEngineResponse } from '@vibe/sdk/dist/interfaces/Connections/SearchEngine';

interface ConnectionsState {
  loadingGetISug: boolean;
  GetISugError?: any;
  GetISug: Interest[];

  loadingUserConnections: boolean;
  userConnectionsError?: any;
  userConnections: UserConnection[];

  loadingGetInterestsUser: boolean;
  getInterestsUserError?: any;
  getInterestsUser: string[];

  loadingSaveI: boolean;
  SaveIError?: any;
  SaveI: any;

  loadingGetCSug: boolean;
  GetCSugError?: any;
  GetCSug: any[];

  loadingGetUSug: boolean;
  GetUSugError?: any;
  GetUSug: any[];

  loadingGetClubSug: boolean;
  GetClubSugError?: any;
  GetClubSug: any[];

  loadingUserFriends: boolean;
  userFriendsError?: any;
  userFriends: Friend[];

  loadingUserPossibleConnections: boolean;
  userPossibleConnectionsError?: any;
  userPossibleConnections: Connection[];

  loadingUserBirthdayConnections: boolean;
  userBirthdayConnectionsError?: any;
  userBirthdayConnections: any[];

  loadingUserPendingRequests: boolean;
  userPendingRequestsError?: any;
  userPendingRequests: FriendRequest[];

  loadingUserSentRequests: boolean;
  userSentRequestsError?: any;
  userSentRequests: any[];

  loadingSaveNewFriend: boolean;
  saveNewFriendError?: any;
  saveNewFriend: any;

  loadingCancelFriendRequest: boolean;
  cancelFriendRequestError?: any;
  cancelFriendRequest: any;

  loadingRejectFriendRequest: boolean;
  rejectFriendRequestError?: any;
  rejectFriendRequest: any;

  loadingCreateFriendRequest: boolean;
  createFriendRequestError?: any;
  createFriendRequest: any;

  loadingDeleteFriend: boolean;
  deleteFriendError?: any;
  deleteFriend: any;

  loadingGetUnregSug: boolean;
  GetUnregSugError?: any;
  GetUnregSug: UnregisteredUser[];

  loadingCreateEmailRequest: boolean;
  CreateEmailRequestError?: any;
  CreateEmailRequest: any;

  loadingSearchEngine: boolean;
  SearchEngineError?: any;
  SearchEngine: SearchEngineResponse;
}

const initialState: ConnectionsState = {
  loadingUserConnections: false,
  userConnectionsError: undefined,
  userConnections: [],

  loadingGetISug: false,
  GetISugError: undefined,
  GetISug: [],

  loadingGetInterestsUser: false,
  getInterestsUserError: undefined,
  getInterestsUser: [],

  loadingSaveI: false,
  SaveIError: undefined,
  SaveI: undefined,

  loadingGetCSug: false,
  GetCSugError: undefined,
  GetCSug: [],

  loadingGetUSug: false,
  GetUSugError: undefined,
  GetUSug: [],

  loadingGetClubSug: false,
  GetClubSugError: undefined,
  GetClubSug: [],

  loadingUserFriends: false,
  userFriendsError: undefined,
  userFriends: [],

  loadingUserPossibleConnections: false,
  userPossibleConnectionsError: undefined,
  userPossibleConnections: [],

  loadingUserBirthdayConnections: false,
  userBirthdayConnectionsError: undefined,
  userBirthdayConnections: [],

  loadingUserPendingRequests: false,
  userPendingRequestsError: undefined,
  userPendingRequests: [],

  loadingUserSentRequests: false,
  userSentRequestsError: undefined,
  userSentRequests: [],

  loadingSaveNewFriend: false,
  saveNewFriendError: undefined,
  saveNewFriend: undefined,

  loadingCancelFriendRequest: false,
  cancelFriendRequestError: undefined,
  cancelFriendRequest: undefined,

  loadingRejectFriendRequest: false,
  rejectFriendRequestError: undefined,
  rejectFriendRequest: undefined,

  loadingCreateFriendRequest: false,
  createFriendRequestError: undefined,
  createFriendRequest: undefined,

  loadingDeleteFriend: false,
  deleteFriendError: undefined,
  deleteFriend: undefined,

  loadingGetUnregSug: false,
  GetUnregSugError: undefined,
  GetUnregSug: [],

  loadingCreateEmailRequest: false,
  CreateEmailRequestError: undefined,
  CreateEmailRequest: undefined,

  loadingSearchEngine: false,
  SearchEngineError: undefined,
  SearchEngine: {
    communities: [],
    healers: [],
    services: [],
    modalities: [],
    benefits: [],
    treatedAreas: [],
    quotes: [],
    events: [],
    counts: {
      Total: 0,
      communities: 0,
      healers: 0,
      services: 0,
      modalities: 0,
      benefits: 0,
      treatedAreas: 0,
      quotes: 0,
      events: 0,
    }
  },
};

export const getConnectionReducer = (
  state = initialState,
  action: AnyAction,
): ConnectionsState => {
  switch (action.type) {

    case START_GET_CONNECTIONS:
      return {
        ...state,
        loadingUserConnections: true,
      };
    case GET_CONNECTIONS_SUCCESS:
      return {
        ...state,
        loadingUserConnections: false,
        userConnections: action.payload,
      };
    case GET_CONNECTIONS_ERROR:
      return {
        ...state,
        loadingUserConnections: false,
        userConnectionsError: action.payload,
      };

    case START_GET_I_SUG:
      return {
        ...state,
        loadingGetISug: true,
      };
    case GET_I_SUG_SUCCESS:
      return {
        ...state,
        loadingGetISug: false,
        GetISug: action.payload,
      };
    case GET_I_SUG_ERROR:
      return {
        ...state,
        loadingGetISug: false,
        GetISugError: action.payload,
      };

    case START_GET_INT_USER:
      return {
        ...state,
        loadingGetInterestsUser: true,
      };
    case GET_INT_USER_SUCCESS:
      return {
        ...state,
        loadingGetInterestsUser: false,
        getInterestsUser: action.payload,
      };
    case GET_INT_USER_ERROR:
      return {
        ...state,
        loadingGetInterestsUser: false,
        getInterestsUserError: action.payload,
      };

    case START_SAVE_I:
      return {
        ...state,
        loadingSaveI: true,
      };
    case SAVE_I_SUCCESS:
      return {
        ...state,
        loadingSaveI: false,
        SaveI: action.payload,
      };
    case SAVE_I_ERROR:
      return {
        ...state,
        loadingSaveI: false,
        SaveIError: action.payload,
      };

    case START_GET_C_SUG:
      return {
        ...state,
        loadingGetCSug: true,
      };
    case GET_C_SUG_SUCCESS:
      return {
        ...state,
        loadingGetCSug: false,
        GetCSug: action.payload,
      };
    case GET_C_SUG_ERROR:
      return {
        ...state,
        loadingGetCSug: false,
        GetCSugError: action.payload,
      };

    case START_GET_U_SUG:
      return {
        ...state,
        loadingGetUSug: true,
      };
    case GET_U_SUG_SUCCESS:
      return {
        ...state,
        loadingGetUSug: false,
        GetUSug: [...state.GetUSug, ...action.payload].filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.username === value.username
          ))),
      };
    case GET_U_SUG_ERROR:
      return {
        ...state,
        loadingGetUSug: false,
        GetUSugError: action.payload,
      };

    case START_GET_CLUB_SUG:
      return {
        ...state,
        loadingGetClubSug: true,
      }
    case GET_CLUB_SUG_SUCCESS:
      return {
        ...state,
        loadingGetClubSug: false,
        GetClubSug: [...state.GetClubSug, action.payload],
      }
    case GET_CLUB_SUG_ERROR:
      return {
        ...state,
        loadingGetClubSug: false,
        GetClubSugError: action.payload,
      }

    case START_GET_USER_FRIENDS:
      return {
        ...state,
        loadingUserFriends: true,
      }
    case GET_USER_FRIENDS_SUCCESS:
      return {
        ...state,
        loadingUserFriends: false,
        userFriends: action.payload,
      }
    case GET_USER_FRIENDS_ERROR:
      return {
        ...state,
        loadingUserFriends: false,
        userFriendsError: action.payload,
      }

    case START_GET_USER_POSSIBLE_CONNECTIONS:
      return {
        ...state,
        loadingUserPossibleConnections: true,
      }
    case GET_USER_POSSIBLE_CONNECTIONS_SUCCESS:
      return {
        ...state,
        loadingUserPossibleConnections: false,
        userPossibleConnections: action.payload,
      }
    case GET_USER_POSSIBLE_CONNECTIONS_ERROR:
      return {
        ...state,
        loadingUserPossibleConnections: false,
        userPossibleConnectionsError: action.payload,
      }

    case START_GET_USER_BIRTHDAY_CONNECTIONS:
      return {
        ...state,
        loadingUserBirthdayConnections: true,
      }
    case GET_USER_BIRTHDAY_CONNECTIONS_SUCCESS:
      return {
        ...state,
        loadingUserBirthdayConnections: false,
        userBirthdayConnections: action.payload,
      }
    case GET_USER_BIRTHDAY_CONNECTIONS_ERROR:
      return {
        ...state,
        loadingUserBirthdayConnections: false,
        userBirthdayConnectionsError: action.payload,
      }

    case START_GET_USER_PENDING_REQUESTS:
      return {
        ...state,
        loadingUserPendingRequests: true,
      }
    case GET_USER_PENDING_REQUESTS_SUCCESS:
      return {
        ...state,
        loadingUserPendingRequests: false,
        userPendingRequests: action.payload,
      }
    case GET_USER_PENDING_REQUESTS_ERROR:
      return {
        ...state,
        loadingUserPendingRequests: false,
        userPendingRequestsError: action.payload,
      }

    case START_GET_USER_SENT_REQUESTS:
      return {
        ...state,
        loadingUserSentRequests: true,
      }
    case GET_USER_SENT_REQUESTS_SUCCESS:
      return {
        ...state,
        loadingUserSentRequests: false,
        userSentRequests: action.payload,
      }
    case GET_USER_SENT_REQUESTS_ERROR:
      return {
        ...state,
        loadingUserSentRequests: false,
        userSentRequestsError: action.payload,
      }

    case START_SAVE_NEW_FRIEND:
      return {
        ...state,
        loadingSaveNewFriend: true,
      }
    case SAVE_NEW_FRIEND_SUCCESS:
      return {
        ...state,
        loadingSaveNewFriend: false,
        saveNewFriend: action.payload,
      }
    case SAVE_NEW_FRIEND_ERROR:
      return {
        ...state,
        loadingSaveNewFriend: false,
        saveNewFriendError: action.payload,
      }

    case START_CANCEL_FRIEND_REQUEST:
      return {
        ...state,
        loadingCancelFriendRequest: true,
      }
    case CANCEL_FRIEND_REQUEST_SUCCESS:
      return {
        ...state,
        loadingCancelFriendRequest: false,
        cancelFriendRequest: action.payload,
      }
    case CANCEL_FRIEND_REQUEST_ERROR:
      return {
        ...state,
        loadingCancelFriendRequest: false,
        cancelFriendRequestError: action.payload,
      }

    case START_REJECT_FRIEND_REQUEST:
      return {
        ...state,
        loadingRejectFriendRequest: true,
      }
    case REJECT_FRIEND_REQUEST_SUCCESS:
      return {
        ...state,
        loadingRejectFriendRequest: false,
        rejectFriendRequest: action.payload,
      }
    case REJECT_FRIEND_REQUEST_ERROR:
      return {
        ...state,
        loadingRejectFriendRequest: false,
        rejectFriendRequestError: action.payload,
      }

    case START_CREATE_FRIEND_REQUEST:
      return {
        ...state,
        loadingCreateFriendRequest: true,
      }
    case CREATE_FRIEND_REQUEST_SUCCESS:
      return {
        ...state,
        loadingCreateFriendRequest: false,
        createFriendRequest: action.payload,
      }
    case CREATE_FRIEND_REQUEST_ERROR:
      return {
        ...state,
        loadingCreateFriendRequest: false,
        createFriendRequestError: action.payload,
      }

    case START_DELETE_FRIEND:
      return {
        ...state,
        loadingDeleteFriend: true,
      }
    case DELETE_FRIEND_SUCCESS:
      return {
        ...state,
        loadingDeleteFriend: false,
        deleteFriend: action.payload,
      }
    case DELETE_FRIEND_ERROR:
      return {
        ...state,
        loadingDeleteFriend: false,
        deleteFriendError: action.payload,
      }

    case START_GET_UNREG_SUG:
      return {
        ...state,
        loadingGetUnregSug: true,
      }
    case GET_UNREG_SUG_SUCCESS:
      return {
        ...state,
        loadingGetUnregSug: false,
        GetUnregSug: action.payload,
      }
    case GET_UNREG_SUG_ERROR:
      return {
        ...state,
        loadingGetUnregSug: false,
        GetUnregSugError: action.payload,
      }

    case START_CREATE_EMAIL_REQ:
      return {
        ...state,
        loadingCreateEmailRequest: true,
      }
    case CREATE_EMAIL_REQ_SUCCESS:
      return {
        ...state,
        loadingCreateEmailRequest: false,
        CreateEmailRequest: action.payload,
      }
    case CREATE_EMAIL_REQ_ERROR:
      return {
        ...state,
        loadingCreateEmailRequest: false,
        CreateEmailRequestError: action.payload,
      }

    case START_SEARCH_ENGINE:
      return {
        ...state,
        loadingSearchEngine: true,
      }
    case SEARCH_ENGINE_SUCCESS:
      return {
        ...state,
        loadingSearchEngine: false,
        SearchEngine: action.payload,
      }
    case SEARCH_ENGINE_ERROR:
      return {
        ...state,
        loadingSearchEngine: false,
        SearchEngineError: action.payload,
      }

    case LOGOUT_CONNECTION:
      return initialState;

    default:
      return state;
  }
};
