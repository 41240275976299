import { PaymentMethod } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import { GET_TRANSACTIONS_ERROR, GET_TRANSACTIONS_SUCCESS } from "../../store/constants/GVConstants";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { PaymentMethodCard } from "../../components/EditProfile/PaymentMethod";

const usePaymentMethods = () => {

    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);

    const [methods, setMethods] = useState<PaymentMethodCard[]>([]);

    const reloadMethods = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Admin.getPaymentMethods({username: userInfo?.username || ''}, dataSDK.token)
            })
            .then((response) => {
                if (response.statusCode === 200) {
                    const results = response.body as {paymentMethod: PaymentMethod, default?: boolean}[];
                    setMethods(results.map((method) => ({
                        id: method.paymentMethod.id,
                        type: (method.paymentMethod.card?.funding || '').toLocaleLowerCase() || '',
                        name: method.paymentMethod.billing_details.name || '',
                        typeCard: (method.paymentMethod.card?.brand || '').toLocaleLowerCase() || '',
                        lastNumber: method.paymentMethod.card?.last4 || '',
                        default: method.default || false,
                    })));
                    return;
                }
                else{
                    setMethods([]);
                    console.error(response);
                }
            })
            .catch(console.error)
    }

    useEffect(() => reloadMethods(), [])

    return {
        methods,
        reloadMethods
    }
}

export default usePaymentMethods;