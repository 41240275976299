import { EventSchedule, EventScheduleUser } from "@vibe/sdk/dist/interfaces/Events/EventSchedule";
import { EventsResponse } from "@vibe/sdk/dist/interfaces/Events/getEvent";

export type reachType = 'ADVISOR' | 'HEALER' | 'SEEKER' | 'ADVISORY' | 'COMITEE' | 'STAFF';
export type clubReachType = 'MEMBER' | 'FOLLOWER' | 'OFFICER' | 'ADVISOR';
export type deliveryType = 'online' | 'mixed' | 'inPerson';
export type categoryType = 'ORG' | 'COMM' | 'CLUB';

export type clubReachTypeEvent = clubReachType | 'ALL' | 'ANY';
export type reachTypeEvent = reachType | 'ALL' | 'ANY';
export type deliveryTypeEvent = deliveryType | 'ANY';
export type categoryTypeEvent = categoryType | 'ANY';

export interface EventGeneralInfo {
    category: categoryTypeEvent
    clubIds?: string[]
    reach: reachTypeEvent[]
    clubReach?: clubReachTypeEvent[]

    eventType: string
    eventMS?: string[]

    cover: any // is type of files from computer :(
    name: string
    description: string

    timezone: string
    start: string
    end: string

    schedule: EventSchedule[]
}

export interface EventDateInfo {

    delivery: deliveryTypeEvent

    link: string;
    address_1?: string;
    address_2?: string;

    instructions_online: string
    instructions_inPerson: string

    maxCapacity_online: number
    maxCapacity_inPerson: number

    fee_online: string
    fee_inPerson: string

    createEventbrite?: boolean
    eventBriteOnline?: boolean
    eventBriteCapacity?: number
    eventBriteFee?: number

    zipcode?: string
    countryCode?: string
    country?: string
    city?: string
    cityCode?: string
    state?: string
    stateCode?: string
}

export const MockEventGeneralInfo: EventGeneralInfo = {
    category: 'ANY',
    reach: [],
    cover: '',
    name: '',
    description: '',
    timezone: '',
    start: '',
    end: '',
    schedule: [],
    eventType: '',
}

export const MockEventDateInfo: EventDateInfo = {
    delivery: 'online',
    link: '',
    address_1: '',
    address_2: '',
    instructions_inPerson: '',
    instructions_online: '',
    fee_online: '0',
    fee_inPerson: '0',
    maxCapacity_online: 0,
    maxCapacity_inPerson: 0,
    eventBriteOnline: true,
    createEventbrite: false,
    eventBriteFee: 0,
}

export const MockEventDetails: EventsResponse = {
    event_id: '',
    org_id: '',
    club_ids: [],
    name: '',
    start: '',
    end: '',
    description: '',
    max_event_capacity_online: 0,
    max_event_capacity_inperson: 0,
    creator: '',
    category: 'CLUB',
    reach: [],
    schedule: [],
    clubReach: [],
    eventFeeOnline: 0,
    eventFeeInPerson: 0,
    createdAt: '',
    timezone: '',
    event_cover: '',
    club_type: '',
    YO_id: '',
    modalitiesIds: [],
    benefitsIds: [],
    delivery: 'online',
    link: '',
    interested: 0,
    goingInPerson: 0,
    goingOnline: 0,
    creatorAvatar: '',
    creatorName: '',
    userAttendance: 'RSVPO',
    modalities: [],
    benefits: [],
    creatorExtended: {
        email: '',
        username: '',
        name: '',
    },
    eventType: '',
}

export const MockEventSession: EventSchedule = {
    name: "",
    startDate: "",
    description: "",
    facilitators: []
}

export const MockSessionFacilitator: EventScheduleUser = {
    firstName: "",
    lastName: "",
    email: "",
    bio: ""
}