import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { IUserGradeResponse } from "@vibe/sdk/interfaces/Admin/User";
import { useEffect, useMemo, useState } from "react";
import { getOrgEvents } from "../../store/actions/eventActions";
import { sortAlphabeticallyOnProperty } from "../../containers/Signup/SignupHealerInd";
import { EventsResponse } from "@vibe/sdk/dist/interfaces/Events/getEvent";

const useEventsAdminCenter = ({
  searchName
}:{
  searchName: string
}) => {
  const dispatch = useDispatch();
  const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);
  const orgEvents = useSelector((state: RootState) => state.getEvent.orgEvents ?? []);

  const [orgID, setOrgID] = useState<string>();
  const [allFilteredEvents, setAllFilteredEvents] = useState<EventsResponse[]>([]);

  const searchParsed = useMemo(() => searchName.toLowerCase(), [searchName]);

  useEffect(() => {
    const orgIdx = user.rolesByOrg.findIndex((orgRoles) => orgRoles.includes('ORGADMIN'));
    if (orgIdx === -1) setOrgID(undefined);
    else setOrgID(user.organizations[orgIdx])
  }, [user]);

  useEffect(() => {
    reloadOrgEvents();
  }, [orgID]);

  const reloadOrgEvents = () => {
    if (!orgID) return;

    dispatch(getOrgEvents(orgID, user.username));
  }

  const filterEvent = (event: EventsResponse): boolean => {
    let acceptedName = true;

    if(searchName && searchName !== ''){
      acceptedName = (event.name || '').toLowerCase().includes(searchParsed)
    }

    return acceptedName;
  }

  useEffect(() => {
    if (!searchName || searchName === '') {
      setAllFilteredEvents(orgEvents);
      return;
    }

    setAllFilteredEvents(
      (sortAlphabeticallyOnProperty(orgEvents, 'name') as EventsResponse[]).filter(filterEvent)
    )
  }, [searchName, orgEvents]);

  return {
    orgEvents,
    allFilteredEvents,
    reloadOrgEvents,
  }
}

export default useEventsAdminCenter;