import { useContext, useEffect, useState } from 'react'
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OutsideClickHandler from 'react-outside-click-handler';
import { ContextSuccess } from "../../Context";
import { onHideType } from "../../components/BaseComponents/Modals/ModalUnsaved";
import ModalAddPayment from './ModalAddPayment';
import usePaymentMethods from '../../hooks/Payment/usePaymentMethods';
import { userInfo } from 'os';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import ButtonSpinner from '../BaseComponents/ButtonSpinner';

export interface PaymentMethodCard {
    id: string
    type: string
    name: string
    typeCard: string
    lastNumber: string
    default: boolean
}

export const MockPaymentMethodCard: PaymentMethodCard = {
    id: '',
    type: '',
    name: '',
    typeCard: '',
    lastNumber: '',
    default: false,
}

const PaymentMethod = () => {

    const currentSuccess = useContext(ContextSuccess);

    const [cardActive, setCardActive] = useState<string>('');
    const [optionActive, setOptionActive] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [saveActive, setSaveActive] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const { methods, reloadMethods } = usePaymentMethods();

    const username = useSelector((state: RootState) => state.getUser.userInfo?.username || '');

    const handleCardActive = (card: string) => {
        setCardActive(card)
        setSaveActive(true);
    }

    const handleActiveOptions = (card: string) => {
        setOptionActive(card)
    }

    const getDefaultMethod = () => {
        const defaultMethod = methods.find((met) => met.default);

        if(defaultMethod) setCardActive(defaultMethod.id);
        else setCardActive('');
        
        // getDataSDK()
        //     .then((dataSDK: getDataSDKResponse) => {
        //         return dataSDK.sdk.Admin.getDefaultPaymentMethod({
        //             username,
        //         }, dataSDK.token)
        //     })
        //     .then((response) => {
        //         if (response.statusCode === 200) {
        //             const results = response.body as any;
        //             setCardActive(results.id);
        //             return;
        //         }
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //     })
    }

    const changeDefaultMethod = () => {
        if (!saveActive) return;

        setLoading(true);

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Admin.setDefaultPaymentMethod({
                    paymentMethodId: cardActive,
                    username,
                }, dataSDK.token)
            })
            .then((response) => {
                if (response.statusCode === 204) {
                    currentSuccess?.setSuccessData(true, true, 'Default payment method changed.');
                    setSaveActive(false);
                    return;
                }
                else {
                    currentSuccess?.setSuccessData(false, true, 'Error trying to change default payment method.');
                }
            })
            .catch((error) => {
                currentSuccess?.setSuccessData(false, true, 'Error trying to change default payment method.', error);
                console.error(error);
            })
            .finally(() => setLoading(false))
    }

    const handleDelete = (data: string) => {
        if (methods.length === 1) {
            currentSuccess?.setSuccessData(false, true, 'Ops!', 'You need at least one payment method registered.');
            return;
        }

        if(data === cardActive) {
            currentSuccess?.setSuccessData(false, true, 'Ops!', 'Please transfer your default payment method to other card first.');
            return;
        }

        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => onHideDelete(type, data),
            'Are you sure you want to delete this card?'
        );
    }

    const handleModal = () => {
        setShowModal(false)
    }

    const removeMethod = (id: string) => {
        currentSuccess?.setLoadingModal(true);

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Admin.deletePaymentMethod({
                    paymentMethodId: id,
                    username,
                }, dataSDK.token)
            })
            .then((response) => {
                if (response.statusCode === 204) {
                    reloadMethods();
                    currentSuccess?.setSuccessData(true, true, 'Payment method deleted.');
                    currentSuccess?.setModalsOff();
                    return;
                }
                else {
                    currentSuccess?.setSuccessData(false, true, 'Error trying to delete payment method.');
                }
            })
            .catch((error) => {
                currentSuccess?.setSuccessData(false, true, 'Error trying to delete payment method.', error);
                console.error(error);
            })
            .finally(() => currentSuccess?.setLoadingModal(false))
    }

    const onHideDelete = (type: onHideType, id: string) => {
        switch (type) {
            case 'save':
                removeMethod(id);
                break;
            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        getDefaultMethod()
    }, [methods]);

    return (
        <Container>
            <Header>
                <Text>
                    <Title>Your Cards</Title>
                    <Descp>Vibe doesn't store or have access to the full information of cards added.</Descp>
                </Text>
                <Buttons>
                    <ButtonAdd onClick={() => setShowModal(true)}>Add new Card +</ButtonAdd>
                    <ButtonSpinner
                        loading={loading}
                        handleClick={changeDefaultMethod}
                        text={'Save'}
                        hasIcon={false}
                        disabled={!saveActive}
                    />
                </Buttons>
            </Header>
            <Cards>
                {methods.map((item, index) =>
                    <Card theme={{ main: `${item.type}-${item.typeCard}` }}>
                        <HeaderCard>
                            <Check onClick={() => handleCardActive(item.id)}>
                                <BlankCircle theme={{ main: cardActive === item.id ? 'cardActive' : '' }}>
                                    {cardActive === item.id && <Point />}
                                </BlankCircle>
                                {item.type}
                            </Check>
                            <ButtonEllip onClick={() => handleActiveOptions(item.id)}>
                                <FontAwesomeIcon
                                    icon="ellipsis-v"
                                    size='lg'
                                    color={'white'}
                                    className="dots pointer"
                                />
                                {optionActive === item.id &&
                                    <Options>
                                        <OutsideClickHandler onOutsideClick={() => handleActiveOptions('')}>
                                            <Option onClick={() => handleDelete(item.id)}>
                                                Remove
                                            </Option>
                                        </OutsideClickHandler>
                                    </Options>
                                }
                            </ButtonEllip>
                        </HeaderCard>
                        <NumberCard>
                            <NumberHide>xxxx</NumberHide>
                            <NumberHide>xxxx</NumberHide>
                            <NumberHide>xxxx</NumberHide>
                            <LastNumbers>{item.lastNumber}</LastNumbers>
                        </NumberCard>
                        <NameCard>
                            <Name>{item.name}</Name>
                            <TypeCard theme={{ main: `card${item.typeCard}` }} />
                        </NameCard>
                    </Card>
                )}
            </Cards>
            <ModalAddPayment
                showModal={showModal}
                onHide={handleModal}
                reload={reloadMethods}
            />
        </Container >
    )
}

const TypeCard = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    width: 70px;
    height: 30px;
    display: flex;
    justify-content: center;
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;

    &.cardmastercard{ background-image: url(../Rounded.svg); }
    &.cardvisa{ background-image: url(../visa.svg); }
    &.cardamerican{ background-image: url(../american.svg); }
    &.cardamex{ background-image: url(../american.svg); }
    &.carddiners{ background-image: url(../dinersclubSolid.svg); }
`

const Name = styled.div`
    color: #FFF;
    text-shadow: 0px 1px 2px rgba(27, 36, 44, 0.12);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0.2px;
    text-align: left;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const NameCard = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    overflow: hidden;
`

const LastNumbers = styled.div``
const NumberHide = styled.div``

const NumberCard = styled.div`
    display: flex;
    gap: 12px;
    color: #FFF;
    text-shadow: 0px 1px 2px rgba(27, 36, 44, 0.12);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 450;
    line-height: 32px;
    letter-spacing: 0.2px;
    margin-top: 30px;
`

const Option = styled.div`
    padding: 15px;
    cursor: pointer;
    text-align: left;

    &:hover{
        background-color: #ededee;
    }

`
const Options = styled.div`
    position: absolute;
    background-color: white;
    top: 30px;
    right: 0;
    padding: 4px 0px;
    color: black;
    border-radius: 8px;
    width: 150px;
`

const ButtonEllip = styled.div`
    border-radius: 24px;
    background: rgba(16, 40, 72, 0.09);
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    cursor: pointer;
`

const BlankCircle = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    display: flex;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 2px solid #CFD6DD;
    background-color: #FFF;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.cardActive{
        border: 5px solid #3062D4;
    }
`;

const Point = styled.div`
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background-color: white;
`;

const Check = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    color: #FFF;
    text-shadow: 0px 1px 2px rgba(27, 36, 44, 0.12);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0.2px;
    cursor: pointer;
`
const HeaderCard = styled.div`
    display: flex;
    justify-content: space-between;
    height: 32px;
    align-items: center;
`

const Card = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    width: 280px;
    height: 164px;
    border-radius: 8px;
    color: white;
    padding: 16px;
    background: linear-gradient(180deg, #362100 0%, #ecae5e 100%);

    &.credit-mastercard{background: linear-gradient(357deg, #D9BF39 -0.16%, #806917 95.13%);}
    &.credit-visa{background: linear-gradient(180deg, #666 0%, #B2B2B2 100%);}
    &.credit-american{background: linear-gradient(180deg, #1A1A1A 0%, #4D4D4D 100%);}
    &.credit-amex{background: linear-gradient(180deg, #1A1A1A 0%, #4D4D4D 100%);}
    &.debit-mastercard{background: linear-gradient(180deg, #00AAF2 0%, #006B99 100%);}
    &.debit-visa{background: linear-gradient(180deg, #552c2c 0%, #b76767 100%);}
    &.debit-american{background: linear-gradient(180deg, #1a1a1a 0%, #6f8173 100%);}
    &.debit-amex{background: linear-gradient(180deg, #1a1a1a 0%, #6f8173 100%);}
`

const Cards = styled.div`
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
`

const ButtonSave = styled.div`
    width: 165px;
    height: 40px;
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background-color: var(--t-blue);
    opacity: ${({ saveActive }: { saveActive: boolean }) => saveActive ? '1' : '0.5'};
    cursor: ${({ saveActive }: { saveActive: boolean }) => saveActive ? 'pointer' : 'default'};
    color: #FFF;
    font-family: 'Inter-SemiBold';
    font-size: 16px;
`

const ButtonAdd = styled.div`
    padding: 4px 8px;
    border-radius: 8px;
    background: #E59B50;
    height: 40px;
    color: #FFF;
    text-align: center;
    font-family: 'Inter-SemiBold';
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 270px;
    cursor: pointer;
`;

const Buttons = styled.div`
    display: flex;
    gap: 16px;
`

const Descp = styled.div`
    color: #1A1A1A;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0.2px;
`

const Title = styled.div`
    color: #1A1A1A;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0.1px;
`
const Text = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
    height: 100vh;
    width: 100vw;
    padding: 30px;
    overflow-y: scroll;
    overflow-x: hidden;
`


export default PaymentMethod