import { useEffect, useState, useContext } from 'react'
import styled from "styled-components";
import {
    TitleContent
} from "../signupsetuphealer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-thin-svg-icons";
import ModalSetup from '../../components/signupsetuphealer/ModalSetup';
import { ProcessInterface } from '../../containers/SetupHealer/SetupHealer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import TClub from '@vibe/sdk/dist/interfaces/Club/TClubs/TClub/TClub';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { getCommunities } from '../../store/actions/clubActions';
import IResponseError from '@vibe/sdk/dist/interfaces/IResponseError';
import ModalDelete from '../BaseComponents/Modals/ModalDelete';
import { IClubExtended } from '@vibe/sdk/dist/interfaces/Club/ClubInterface';
import ModalComplete from '../BaseComponents/Modals/ModalComplete';
import { ContextSuccess } from "../../Context";
import { onHideType } from "../../components/BaseComponents/Modals/ModalUnsaved";
import { Scroll_Content_Signup } from './Introduction';
import { getEmailsSetup } from '../../store/actions/inviteActions';

export const customTComm: TClub = {
    id: '',
    name: 'custom',
    club_type: 'COMM',
    description: '',
    member_types: [
        'SEEKER',
        'STAFF',
        'ADVISORY',
        'COMMITTEE',
        'HEALER',
        'MIXED',
    ],
    role_assigned: ''
}

const InternalTeams = ({
    processData,
}: {
    processData: ProcessInterface
}) => {
    const dispatch = useDispatch();
    const currentSuccess = useContext(ContextSuccess);
    const organization = useSelector((state: RootState | any) => state.getOrgs.organizationInfo);

    const [showModal, setShowModal] = useState(false);
    const [templateSelected, setTemplateSelected] = useState<TClub>({ ...customTComm });
    const [deleteCommunity, setDeleteCommunity] = useState<{
        id: string,
        showModal: boolean,
    }>();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [editComm, setEditComm] = useState<IClubExtended>();

    const templateComms = useSelector((state: RootState) => state.getTClubs.TComm);
    const communities: IClubExtended[] = useSelector((state: RootState) => state.getClub.GetCommunities);

    const handleModal = (show: boolean, template?: TClub) => {
        if (template) setTemplateSelected(template);
        setShowModal(show);
    }

    const handleModalEdit = (show: boolean, _template?: TClub, club?: IClubExtended) => {
        setShowModalEdit(show);
        setEditComm(club);
    }

    useEffect(() => {
        if ((communities || []).length > 0) processData.setTabsProcessed([...processData.tabsProcessed, 2]);
    }, [communities])

    const onHideDelete = (type: onHideType, id: string) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);
                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        return dataSDK.sdk.Club.deleteClub(id, organization.status.startsWith('PE'), undefined, dataSDK.token);
                    })
                    .then((results) => {
                        if (results.statusCode === 204) {
                            dispatch(getCommunities(organization.id, organization.status.startsWith('PE')));
                            dispatch(getEmailsSetup(organization.id, organization.status.startsWith('PE')));
                            currentSuccess?.setLoadingModal(false);
                            currentSuccess?.setModalsOff();
                        }
                        else {
                            console.log((results.body as IResponseError).error || (results.body as IResponseError).message)
                        }
                    })
                    .finally(() => {
                        setDeleteCommunity(undefined);
                        setDeleteLoading(false);
                    })
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                break;

            default:
                break;
        }
    }

    const handleDelete = (data: string) => {
        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => onHideDelete(type, data),
            'Are you sure you want to delete this internal team?'
        );
    }

    return (
        <Scroll_Content_Signup>
            <TitleContent theme={{ align: 'center' }}>What internal teams would you like to create?</TitleContent>
            <ListAreas>
                {communities.map((comm) => (
                    <Area isTemplate={false} onClick={() => handleModalEdit(true, undefined, comm)}>
                        {comm.name}
                        <IconPlus
                            icon={'times'}
                            size='lg'
                            color={"black"}
                            onClick={() => handleDelete(comm.id)}
                        />
                    </Area>
                ))}
            </ListAreas>
            {(communities.length > 0) && <LineList />}
            <ListAreas>
                {(templateComms || []).map((template) => (
                    <Area theme={{ main: 'item' + template.name }} isTemplate={true} onClick={() => handleModal(true, template)}>
                        {template.name}
                        <IconPlus
                            icon={faPlus}
                            size='lg'
                            color={"black"}
                        />
                    </Area>
                ))}
                <Area theme={{ main: 'itemCustom' }} isTemplate={true} onClick={() => handleModal(true, { ...customTComm })}>
                    Custom Team
                    <IconPlus
                        icon={faPlus}
                        size='lg'
                        color={"black"}
                    />
                </Area>
            </ListAreas>
            <ModalSetup
                showModal={showModal}
                onHide={handleModal}
                data={templateSelected}
            />
            <ModalSetup
                showModal={showModalEdit}
                onHide={handleModalEdit}
                data={templateSelected}
                edit
                clubInfo={editComm}
            />
        </Scroll_Content_Signup>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    align-items: center;
`;

const LineList = styled.div`
    width: 100%;
    background-color: var(--simple-gray);
    height: 1px;
`;

interface PropsArea {
    isTemplate: boolean;
}

const Area = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    border: 1px solid darkgrey;
    width: fit-content;
    background-color: ${(props: PropsArea) => props.isTemplate ? "white" : "var(--lavender)"};
    padding: 7px 12px;
    border-radius: 5px;
    font-family: ${(props: PropsArea) => props.isTemplate ? "Inter-bold" : "Inter"};
    font-size: 14px;
    cursor: pointer;

    &:hover {
        border: 1px solid gray;
    }

    &.itemHealers{
        order: 1;
    }
    &.itemCustom{
        order: 1;
    }

`
const ListAreas = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
`

const IconPlus = styled(FontAwesomeIcon)`
    color: black;
    font-size: 14px;
    margin-left: 8px
`;

export default InternalTeams