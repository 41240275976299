import React, { useEffect, useMemo, useState } from "react";
import './styles.scoped.css';
import styled from "styled-components";
import {
  RobotocondensedRegularNormalWhite16,
} from "../../styledMixins";
import "./styles.css";
import "../../styles.css";
import Select, { SingleValue } from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import { getUsersRoles, getUsersStatus, getUsersWithLimit } from "../../store/actions/userActions";
import { getOrganizationNames } from "../../store/actions/orgsActions";
import { getActiveCities, getActiveCountries } from "../../store/actions/locationActions";
import Chart from "react-apexcharts";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { truncateDecimals } from "../../helpers/numberHelper";
import { RootState } from "../../store/store";
import { useTranslation } from "react-i18next";
import useGender from "../../hooks/useGender";
import { PAGE_SIZES, pageSize } from "../AdminCenter/MyEvents/EventAdmin";

function Users({ isSuper }: { isSuper: boolean }) {

  const [showInfo, setShowInfo] = useState(false)

  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<pageSize>(PAGE_SIZES[0]);

  const usersAll = useSelector((state: any) => state.getUser.users);
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.getUser.user);

  const token = useSelector((state: any) => state.getUser.token);
  const lastEvaluatedkeys = useSelector((state: any) => state.getOrgs.lastEvaluatedkeys);

  const userInfo = useSelector((state: any) => state.getUser.userInfo);

  useEffect(() => {
    //dispatch(getUsers());
    dispatch(getUsersStatus());
    dispatch(getOrganizationNames());
    dispatch(getUsersRoles());
    dispatch(getActiveCities());
    dispatch(getActiveCountries());

    if (isSuper) {
      dispatch(getUsersWithLimit({ 'token': token, 'limit': pageSize }));
    }
    else {
      dispatch(getUsersWithLimit({ 'token': token, 'limit': pageSize, org: user.organizations[0] }));
    }
  }, [])

  useEffect(() => {
    if (isSuper) {
      if (page === 0) {
        dispatch(getUsersWithLimit({ 'token': token, 'limit': pageSize, 'page': page }))
      } else {
        dispatch(getUsersWithLimit({ 'token': token, 'limit': pageSize, 'page': page, 'lastEvaluatedKey': lastEvaluatedkeys[page] }))
      }
    }
    else {
      if (page === 0) {
        dispatch(getUsersWithLimit({ 'token': token, 'limit': pageSize, 'page': page, org: user.organizations[0] }))
      } else {
        dispatch(getUsersWithLimit({ 'token': token, 'limit': pageSize, 'page': page, 'lastEvaluatedKey': lastEvaluatedkeys[page], org: user.organizations[0] }))
      }
    }

  }, [pageSize, page]);

  const [roleSelected, setRoleSelected] = useState('');

  const viewingOptions = PAGE_SIZES.map((item) => ({
    label: `${item} results`,
    value: item,
  }))

  const canGoBack = () => page !== 0;
  const canGoNext = () => true;

  const goBack = () => {
    setPage(page - 1);
  };

  const goNext = () => {
    setPage(page + 1);
  };

  const handleChangeRadio = (event: any) => {
    setRoleSelected(event.target.value);
  }

  const userRoles = useSelector((state: any) => state.getUser.userRoles)
  const organization = useSelector((state: any) => state.getOrgs.organizationInfo);

  const { chart: [genderChart, colorChart], setCountry: setCountryGender, genders } = useGender();

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryGender(organization.countryCode);
    }
  }, [])

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryGender(organization.countryCode);
    }
  }, [organization])

  const [dashboardInfo, setDashboardInfo] = useState<any>({});

  const GenderOptions = useMemo(() => ({
    labels: [...genderChart],
    colors: [...colorChart],
    dataLabels: {
      enabled: false,
    },
    chart: {
      foreColor: '#fff',
    },
    stroke: {
      show: false
    },
    legend: {
      show: true,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "80px",
          labels: {
            show: true,
            value: {
              show: false
            },
            total: {
              label: t('dashboard__genders'),
              show: true,
              showAlways: true,
              fontSize: '16px',
              color: '#fff'
            }
          }
        }
      }
    },
  }), [dashboardInfo, genderChart, colorChart]);

  const options2 = {
    labels: ['Data not provided'],
    colors: ['#311c4eb2',],
    chart: {
      foreColor: '#fff',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false
    },
    plotOptions: {
      pie: {
        donut: {
          size: "80px",
          labels: {
            show: true,
            value: {
              show: false
            },
            total: {
              label: [t('dashboard__economicStatus1'), t('dashboard__economicStatus2')] as any,
              show: true,
              showAlways: false,
              fontSize: '16px',
              color: '#fff',
            }
          }
        }
      }
    },
  };

  const options3 = {
    labels: ['Data not provided'],
    colors: ['#311c4eb2',],
    chart: {
      foreColor: '#fff',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false
    },
    plotOptions: {
      pie: {
        donut: {
          size: "80px",
          labels: {
            show: true,
            value: {
              show: false
            },
            total: {
              label: t('dashboard__ethnicity'),
              show: true,
              showAlways: false,
              fontSize: '16px',
              color: '#fff'
            }
          }
        }
      }
    },
  };


  const capitalizeString = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

  const optionsAhaChart = useMemo(() => ({
    labels: dashboardInfo && 'aHa' in dashboardInfo ? Object.keys(dashboardInfo.aha).map(capitalizeString) : [],
    markers: {
      size: 7,
    },
    title: {
      text: t('dashboard__totalParticipationStudents')
    },
    chart: {
      toolbar: {
        show: false
      },
      height: 350
    },
  }), [dashboardInfo]);

  const seriesAhaChart = useMemo(() => ([
    {
      name: `${t('dashboard__numberStudentsThat')} ${dashboardInfo?.engagement?.totalActivities} ${t('dashboard__activities')}`,
      data: (dashboardInfo && 'aha' in dashboardInfo ? Object.values(dashboardInfo.aha) : []) as number[],
    }
  ]), [dashboardInfo]);

  const GenderSeries = useMemo(() => (
    (dashboardInfo && 'social' in dashboardInfo && 'genders' in dashboardInfo.social
      ? Object.values(Object.entries(dashboardInfo.social.genders).reduce((acc: any, [key, value]) => {
        if (genders.map((g) => g.id).includes(key)) {
          acc[key] = value;
        }
        return acc;
      }, {}))
      : []
    ) as number[]
  ), [dashboardInfo, genders]);
  const series2 = [1];
  const series3 = [1];

  function onChange() {

  }


  useEffect(() => {
    const orgId = JSON.parse(userInfo.attributes['custom:organizations'])[0];
    Backend(
      MicroServices.Dashboard,
      `/dashboard/community${userRoles.includes('SUPER') ? '' : `?organizationId=${orgId}`}`)
      .then((response) => response.json())
      .then((results) => {
        setDashboardInfo(results)
      })
  }, [])

  const parseGrades = (grades: any) => {
    const { Total, ...rest } = grades;
    const gradesLength = Object.keys(rest).length;
    let gradesMissing: any = {}
    const minimumGrades = 3;
    if (gradesLength < minimumGrades) {
      const gradesMissingLength = minimumGrades - gradesLength;
      for (let i = 0; i < gradesMissingLength; i++) {
        gradesMissing[`missing${i}`] = { "enrolled": "", "active": "" }
      }
    }
    return { ...rest, ...gradesMissing, Total }
  }

  return (

    <ContentAdmin>
      <TopColumn>
        <ManageUsersCol>
          {showInfo ?
            <><div className="headerDashboard">
              <h1 className="titleDashboard">{t('dashboard__title')}</h1>

              {/*<div className="containerDates">
      <label htmlFor="">Start: </label>
      <DatePicker
        dateFormat="MM-dd-yyyy"
        onChange={() => onChange()}
        className='inputDashboard'
        placeholderText="MM/DD/YYYY"
      />
      <label htmlFor="">End: </label>
      <DatePicker
        dateFormat="MM-dd-yyyy"
        onChange={() => onChange()}
        className='inputDashboard'
        placeholderText="MM/DD/YYYY"
      />
    </div>*/}

            </div><div className="dashboardHeader">
                <div className="cardHeader active col1">
                  <div className="cardTitle">{t('dashboard__CommMembers')}</div>
                  <div className="descriptionCards">
                    <div className="countCard"><span>{t('dashboard__students')} </span>{`: ${dashboardInfo?.community?.students}`}</div>
                    <div className="countCard"><span>{t('dashboard__guardians')} </span>{`: ${dashboardInfo?.community?.guardians}`}</div>
                    <div className="countCard"><span>{t('dashboard__staff')} </span>{`: ${dashboardInfo?.community?.staff}`}</div>
                  </div>
                </div>
                <div className="cardHeader col1">
                  <div className="cardTitle">{t('dashboard__clubTeams')}</div>
                  <div className="descriptionCards">
                    <div className="countCard"><span>{t('dashboard__clubs')} </span>{`: ${dashboardInfo?.extracurricularActivities?.clubs}`}</div>
                    <div className="countCard"><span>{t('dashboard__teams')} </span>{`: ${dashboardInfo?.extracurricularActivities?.teams}`}</div>
                    {/*<div className="countCard"><span>Communities </span>{`: ${dashboardInfo?.extracurricularActivities?.communities}`}</div>*/}
                  </div>
                </div>
                <div className="cardHeader col1">
                  <div className="cardTitle">{t('dashboard__engagement')}</div>
                  <div className="descriptionCards">
                    <div className="countCard"><span>{t('dashboard__totalActivities')} </span>{`: ${dashboardInfo?.engagement?.totalActivities}`}</div>
                    <div className="countCard"><span>{t('dashboard__activitiesThisWeek')} </span>{`: ${dashboardInfo?.engagement?.activitiesThisWeek}`}</div>
                  </div>
                </div>
              </div><div className="row row-3">
                <div className="col1 cardDark">
                  {/*<div className="titleTable">Grades</div>*/}
                  <div id="table-wrapper">
                    <div id="table-scroll">
                      <table className="infoTable">
                        <thead>
                          <tr>
                            <th className="nmClass">
                              <span className="text-fixed-header">{t('dashboard__nameClass')}</span>
                            </th>
                            <th className="nmClass">
                            </th>
                            <th className="nmClass">
                              <span className="text-fixed-header">{t('dashboard__enrolled')}</span>
                            </th>
                            <th className="nmClass">
                              <span className="text-fixed-header">{t('dashboard__active')}</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {dashboardInfo && 'grades' in dashboardInfo && Object.entries(parseGrades(dashboardInfo.grades)).map(([key, value]) => {
                            const castedValue = (value as any);
                            return (

                              <tr>
                                <td className="nmClass">{key.includes("missing") ? "-" : key}</td>
                                <td></td>
                                <td>{castedValue.enrolled}</td>
                                <td>{castedValue.active}</td>
                              </tr>

                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col3 cardDark">
                  <div className="GrphPieMulti">
                    <div className="">
                      <Chart
                        options={GenderOptions}
                        series={GenderSeries}
                        height={350}
                        type="donut" />
                    </div>
                    <Chart
                      options={options2}
                      series={series2}
                      height={350}
                      type="donut" />
                    <Chart
                      options={options3}
                      series={series3}
                      height={350}
                      type="donut" />
                  </div>
                </div>
              </div><div className="row row-3">
                <div className="col1 cardLight">
                  <div className="titleTable">{t('dashboard__interesrs')}</div>
                  <table className="infoTable">
                    <tr>
                      <th className="nmClass">{t('dashboard__mostPopular')}</th>
                      <th className="nmClass">{t('dashboard__numberStudents')}</th>
                      <th className="nmClass">{t('dashboard__percentage')}</th>
                    </tr>
                    {dashboardInfo && 'interests' in dashboardInfo && Object.entries(dashboardInfo.interests).sort((a: any, b: any) => b[1].users - a[1].users).slice(0, 8).map(([key, value]) => {
                      const castedValue = (value as any);
                      return (
                        <tr>
                          <td>{key}</td>
                          <td>{castedValue.users}</td>
                          <td>+{truncateDecimals(castedValue.interested * 100, 2)}%</td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
                <div className="col1 cardLight">
                  <div>
                    <div className="titleTable">{t('dashboard__participacionTracked')}</div>
                    <Chart
                      type="line"
                      options={optionsAhaChart}
                      series={seriesAhaChart}
                      height={200} />
                    <div className="cardFooter">
                      <p>{t('dashboard__totalParticipacionTracked')}</p>
                      <span><i className="iconAhaPurple"></i>{dashboardInfo && 'aha' in dashboardInfo ? (Object.values(dashboardInfo.aha) as number[]).reduce((acc: number, curr: number) => acc + curr, 0).toLocaleString() : 0}</span>
                    </div>
                  </div>
                </div>
                <div className="col1 cardLight">
                  <div>
                    <div className="titleTable titleViewAll">
                      {t('dashboard__alerts')}
                      <button className="linkViewAll">{t('dashboard__viewAll')}</button>
                    </div>
                    <div className="alerts">
                      <div className="cardAlert">
                        <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" alt="" />
                        <div className="descCard">
                          <p>Quit the Club</p>
                          <span>Approve or ignore request</span>
                        </div>
                      </div>
                      <p className="dateTime">Today</p>
                    </div>
                    <div className="alerts">
                      <div className="cardAlert">
                        <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" alt="" />
                        <div className="descCard">
                          <p>Jorge Alonso Didn't attend to the event</p>
                          <span>Basketball in the park</span>
                        </div>
                      </div>
                      <p className="dateTime">Yesterday</p>
                    </div>
                    <div className="alerts">
                      <div className="cardAlert">
                        <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" alt="" />
                        <div className="descCard">
                          <p>Upcoming event</p>
                          <span>The millenial's club</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div></>
            :
            'The Dashboard is Coming Soon.'
          }
        </ManageUsersCol>

      </TopColumn>

    </ContentAdmin>
  );
}

const ContentAdmin = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  justify-items: flex-start;
  flex-direction: column;
`;

const ContentUsers = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  width: 100%;
  z-index: 1;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  align-items: center;
  min-height: 944px;
  background-color: var(--light-brown);
  padding-left: 152px;
  padding-right: 20px;
`;

const TopColumn = styled.div`
  // height: 154px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
  justify-content: space-between;
`;

// const ManageUsersCol = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
// `;

const ManageUsersCol = styled.div`
    font-family: 'Inter';
    font-size: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 68vh;
    color: var(--t-blue);
    width: 400px;
    margin: 0 auto;
`

const Title = styled.h1`
  min-height: 28px;
  font-family: var(--font-family-roboto_condensed);
  font-weight: 700;
  color: var(--lavender);
  font-size: var(--font-size-xxxl);
  letter-spacing: 0;
  padding-bottom: 25px;
`;

const ShowingItems = styled.p`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  align-self: flex-start;
  margin-top: 5px;
  letter-spacing: 0;
  align-self: center;
  text-align: center;
`;

const Viewing = styled.div`
  margin-left: 3px;
  display: flex;
  align-items: center;
  min-width: 197px;
  align-self: flex-end;
  font-family: var(--font-family-roboto_condensed);
`;

const Viewing1 = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  min-width: 50px;
  letter-spacing: 0;
`;

const Box = styled.div`
  height: 40px;
  margin-left: 17px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  min-width: 130px;
  background-color: var(--violent-violet);
`;

/* const PgNumber = styled.div`
  margin-top: 438px;
  display: flex;  
  padding: 0 30px;
  align-items: center;
  min-width: 360px;
`; */

export default Users;
