import { TitleHome } from "../SearchHome/styles";
import { AdvContainerHome, AdvListRow, ContentComingSoon, TitleHomeAdvs } from "./styles";
import AdvisorCard, { AdvisorHomeInfo } from "./AdvisorCard";
import SliderAdvHome from "./SliderAdvHome";

const AdvisorsHome = () => {
    return (
        <AdvContainerHome>
            <TitleHomeAdvs>
                Dedicated Healers & Seekers Raising the Vibration of the Planet
            </TitleHomeAdvs>

            <AdvListRow>Vibe Leadership</AdvListRow>

            <ContentComingSoon>
                Our team is growing, and this page will be updated soon.
            </ContentComingSoon>
            {/*<AdvListRow>
                Vibe Leadership
                <SliderAdvHome
                    defaultCard={0}
                    elements={listLeaderHome}
                    renderCard={(user) => (
                        <AdvisorCard adv={user}/>
                    )}
                />
            </AdvListRow>
            <AdvListRow>
                Vibe Advisors
                <SliderAdvHome
                    defaultCard={0}
                    elements={listAdvHome}
                    renderCard={(user) => (
                        <AdvisorCard adv={user}/>
                    )}
                />
            </AdvListRow>*/}
        </AdvContainerHome>
    )
}

export default AdvisorsHome;

const listAdvHome: AdvisorHomeInfo[] = [{
    name: "James Sisneros",
    position: "CO - Founder",
    city: "Miami",
    state: "Florida",
    tags: ['Vibe', 'Zaahah & the aHa token']
},
{
    name: "James Sisneros",
    position: "CO - Founder",
    city: "Miami",
    state: "Florida",
    tags: ['Vibe', 'Zaahah & the aHa token']
},
{
    name: "James Sisneros",
    position: "CO - Founder",
    city: "Miami",
    state: "Florida",
    tags: ['Vibe', 'Zaahah & the aHa token']
}]

const listLeaderHome: AdvisorHomeInfo[] = [{
    name: "James Sisneros",
    position: "CO - Founder",
    city: "Miami",
    state: "Florida",
    tags: ['Vibe', 'Zaahah & the aHa token']
},
{
    name: "James Sisneros",
    position: "CO - Founder",
    city: "Miami",
    state: "Florida",
    tags: ['Vibe', 'Zaahah & the aHa token']
},
{
    name: "James Sisneros",
    position: "CO - Founder",
    city: "Miami",
    state: "Florida",
    tags: ['Vibe', 'Zaahah & the aHa token']
}]