import { useEffect, useState } from 'react';
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faArrowUpArrowDown, faGlobe, faLock, faCaretDown } from "@fortawesome/pro-regular-svg-icons";
import {
    ContainerTable, Filters, InputSearch, Input, SelectFilter, StyledTable, THead, TR, TD,
    TextHead, TBody, ViewUser, Avatar, TextBlue
} from "./Dashboards.styles"
import { CommunityTable } from '@vibe/sdk/dist/interfaces/Dashboard/tables';
import useDashboard from '../../hooks/Dashboard/useDashboard';
import TableLoader from './components';
import { Link } from 'react-router-dom';

const getTableRow = (
    community: CommunityTable,
    castBigNumbers: (n: number) => string,
    castMoneyNumbers: (n: number) => string,
    castDateTimestamp: (timestamp: string) => string,
    handleTab: (text: string) => void
) => {
    return (
        <TR>
            <TD>
                <Link onClick={() => handleTab('BULLETIN')} to={`./clubs/${community.id}`}><TextBlue>{community.name}</TextBlue></Link>
            </TD>
            <TD>
                {community.guides.map(i => (
                    <ViewUser>
                        <Avatar src={i.avatar ?? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} />
                    </ViewUser>))}
            </TD>
            <TD>
                {castBigNumbers(community.members)}
            </TD>
            <TD>
                {castBigNumbers(community.followers)}
            </TD>
            <TD>
                {castDateTimestamp(community.createdAt)}
            </TD>
        </TR>
    )
}

const TableCommunities = (props: any) => {
    const { clubsDashboardTable, castBigNumbers, castMoneyNumbers, castDateTimestamp, reloadDashboardTable, loadingGeneral, loadingClubsTable } = useDashboard()

    useEffect(() => {
        reloadDashboardTable('clubs')
    }, [])

    return (
        <ContainerTable>
            <Filters>
                <InputSearch>
                    <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        size="sm"
                        color={'black'}
                    />
                    <Input />
                </InputSearch>
                <SelectFilter>
                    <FontAwesomeIcon
                        icon={faGlobe}
                        size="sm"
                        color={'black'}
                    />
                    Public
                    <FontAwesomeIcon
                        icon={faCaretDown}
                        size="sm"
                        color={'black'}
                    />
                </SelectFilter>
                <SelectFilter>
                    <FontAwesomeIcon
                        icon={faLock}
                        size="sm"
                        color={'black'}
                    />
                    Private
                    <FontAwesomeIcon
                        icon={faCaretDown}
                        size="sm"
                        color={'black'}
                    />
                </SelectFilter>
            </Filters>
            <StyledTable>
                <THead>
                    <TR>
                        <TD>
                            <TextHead>
                                Community
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    size="sm"
                                    color={'black'}
                                />
                            </TextHead>

                        </TD>
                        <TD>
                            <TextHead>
                                Guides
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    size="sm"
                                    color={'black'}
                                />
                            </TextHead>
                        </TD>
                        <TD>
                            <TextHead>
                                Members
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    size="sm"
                                    color={'black'}
                                />
                            </TextHead>
                        </TD>
                        <TD>
                            <TextHead>
                                Followers
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    size="sm"
                                    color={'black'}
                                />
                            </TextHead>
                        </TD>
                        <TD>
                            <TextHead>
                                Date created
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    size="sm"
                                    color={'black'}
                                />
                            </TextHead>
                        </TD>
                    </TR>
                </THead>
                <TBody>
                    {loadingGeneral || loadingClubsTable ? (
                        <TableLoader />
                    ) : clubsDashboardTable.map(community => getTableRow(community, castBigNumbers, castMoneyNumbers, castDateTimestamp, props.handleTab))}
                </TBody>
            </StyledTable>
        </ContainerTable>
    )
}

export default TableCommunities