import styled from "styled-components";

export const HomeContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 120px 1fr;
    overflow: hidden;
    position: relative;
    height: 100vh;

    a {
        display: contents;
        text-decoration: none;
    }

    @media only screen and (max-width: 770px) {
        display: flex;
        flex-direction: column;
    }
`;

export const ContentHome = styled.div`
    display: flex;
    flex-direction: column;
    gap: 45px;

    align-items: center;
    justify-content: center;
    padding: 0px;

    background-image: url(./background_home_vibe.png);
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    
    @media only screen and (max-width: 770px) {
        margin-top: 60px;
    }
`;