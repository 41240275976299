import { ChatResourcesListInterface } from "../../interfaces/chat/ChatResourcesListInterface"
import { Conversation } from "../../interfaces/chat/Conversation"
import { UserChat } from "../../interfaces/chat/UserChat"
import IConversationGroupMediaOutput from "../../interfaces/chat/IConversationGroupMediaOutput";

export const defaultConversation = (): Conversation => {
    return {
        from: {
            fullName: '',
            username: '',
            avatar: '',
            status: 'available'
        },
        to: {
            fullName: '',
            username: '',
            avatar: '',
            status: 'available'
        },
        messages: [],
        id: '',
        muted: false,
        type: 'USER'
    }
}

export const defaultConnectUser = (): UserChat => {
    return {
        username: '',
        fullName: '',
        avatar: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png',
        status: 'available'
    }
}

export const defaultResources = (idGroup?: string, id?: string) : ChatResourcesListInterface => {
    return {
        conversation_id: idGroup ? (idGroup || '') : (id || ''),
        images: [],
        files: [],
        links: [],
    }
}

export const defaultConversationMedia = (): IConversationGroupMediaOutput => {
    return {
        media: '',
        type: 'FILE',
        author: '',
        createdAt: 0,
        messageId: ''
    }
}