import styled from "styled-components";
import {
    TitleContent, Input
} from "."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-thin-svg-icons";
import { faSearch, faPen, faTrash, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { ProcessInterface } from '../../containers/SetupHealer/SetupHealer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import useModalitiesSA from '../../hooks/Superadmin/Modalities/useModalitiesSA';
import { sortAlphabeticallyOnProperty } from '../../containers/Signup/SignupHealerInd';
import Modality from '@vibe/sdk/interfaces/Modalities/modalities/Modality/Modality';
import { useState, useEffect, useContext } from "react";
import IClub, { IClubExtended } from "@vibe/sdk/dist/interfaces/Club/ClubInterface";
import { customModality, customService } from "../signupsetuphealer/Services";
import ModalCommunities from "../signupsetuphealer/ModalCommunities";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import { getClubs } from "../../store/actions/clubActions";
import IResponseError from "@vibe/sdk/dist/interfaces/IResponseError";
import ModalDelete from "../BaseComponents/Modals/ModalDelete";
import IHS from "@vibe/sdk/dist/interfaces/Modalities/hservices/HS";
import IPaymentInfo from "../../interfaces/user/IPaymentINfo";
import ContentPayment from "../BaseComponents/Payment/ContentPayment";
import { onHideType } from "../../components/BaseComponents/Modals/ModalUnsaved";
import { ContextSuccess } from "../../Context";
import BlankTable from "../BaseComponents/BlankTable";
import { Scroll_Content_Signup } from "../signupsetuphealer/Introduction";
import { Content_Signup_Columns } from "./MyServices";

const Communities = ({
    processData,
}: {
    processData: ProcessInterface
}) => {

    const dispatch = useDispatch();
    const currentSuccess = useContext(ContextSuccess);
    const { modalitiesPages, reloadModalities } = useModalitiesSA({
        pageSizeProp: 1000,
        searchName: '',
    });
    const user = useSelector((state: RootState) => state.getUser.user);
    const userPayment: IPaymentInfo = useSelector((state: RootState) => state.getUser.paymentInfo);

    const organization = useSelector((state: RootState) => state.getOrgs.organizationInfo);
    const communities = useSelector((state: RootState) => state.getClub.getClubsOrg);
    const services = useSelector((state: RootState) => state.getModality.getHealerServices);

    const [showModal, setShowModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<string>();
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [editComm, setEditComm] = useState<IClubExtended>();
    const [modalities, setModalities] = useState<Modality[]>([]);

    const [filteredModality, setFilteredModality] = useState<string>('');
    const [community, setCommunity] = useState<IHS>({ ...customService });

    const getFiltered = (listName: any[]) => {
        return listName.filter((item: any) => {
            if (!filteredModality) return true;

            if (item.name && (
                item.name.toUpperCase().includes(filteredModality) ||
                item.name.toLowerCase().includes(filteredModality) ||
                item.name.includes(filteredModality)
            )) {
                return true;
            }

            return false;
        })
    }

    const handleModality = (open: boolean, mod?: IHS) => {
        if (mod) {
            setCommunity(mod);
            const mods = (mod.modalityIds || [])
                .map((modIdx) =>
                    modalitiesPages[0]
                        .find((mod) => mod.id === modIdx)
                )
                .filter((m): m is Modality => !!m);
            setModalities(mods);
        } else {
            setCommunity({ ...customService });
            setModalities([]);
        }
        setShowModal(open);
    }

    const handleModalEdit = (show: boolean, _template?: Modality, club?: IClubExtended) => {
        setShowModalEdit(show);
        setEditComm(club);
    }

    const handleUpdate = (service_is: string) => {
        const s = communities.find( s => s.id === service_is);
        handleModalEdit(true, undefined, s);
    }

    useEffect(() => {
        if ((communities || []).length > 0) processData.setTabsProcessed([...processData.tabsProcessed, 5]);
    }, [communities])

    const getContentOfTab = () => {
        if (user.organizations.length === 1 || userPayment.hasActiveSubscription) {
            return (
                <Scroll_Content_Signup>
                    <TitleContent theme={{ align: 'center' }}>
                        Build communities aligned with modalities and services you provide to support and grow your community
                    </TitleContent>
                    <Content_Signup_Columns>
                        <LeftColumn>
                            <InputIcon>
                                <SearchIcon
                                    icon={faSearch}
                                    size='lg'
                                />
                                <InputPlaceholder
                                    placeholder="Search"
                                    value={filteredModality}
                                    onChange={(e) => setFilteredModality(e.currentTarget.value)}
                                />
                            </InputIcon>
                            <BaseAreas>
                                <TitleAreas>Your Services</TitleAreas>
                                <ListAreas>
                                    {sortAlphabeticallyOnProperty(getFiltered(services), 'name').map((item: IHS) => (
                                        <Area onClick={() => handleModality(true, item)}>
                                            <TextServices>{item.name}</TextServices>
                                            <IconPlus
                                                icon={faPlus}
                                                size='lg'
                                                color={"black"}
                                            />
                                        </Area>
                                    ))}
                                </ListAreas>
                            </BaseAreas>
                            <Button onClick={() => handleModality(true)}>
                                Add custom community
                                <IconPlus
                                    icon={faPlus}
                                    size='lg'
                                    color={"var(--white)"}
                                />
                            </Button>
                        </LeftColumn>
                        {(communities || []).length === 0 ?
                            <ListServicesEmp>
                                <Header>
                                    <Title>No communities yet.</Title>
                                    <SubTitle>Here you will see the communities you upload</SubTitle>
                                </Header>
                            </ListServicesEmp>
                            :
                            <ListServices>
                                 <BlankTable
                                    headers={['Name', 'Description']}
                                    items={sortAlphabeticallyOnProperty(getFiltered(communities || []), 'name').map((service: IClubExtended) => ({
                                        id: service.id,
                                        labels: [
                                            service.name,
                                            service.description || ''
                                        ],
                                    }))}
                                    actions={['UPDATE', 'DELETE']}
                                    actionsHandlers={{
                                        handleUpdate,
                                        handleDelete,
                                    }}
                                    maxWidth={'200px'}
                                />
                            </ListServices>
                        }
                    </Content_Signup_Columns>
                </Scroll_Content_Signup>
            )
        } else {
            return <ContentPayment isCommunities/>;
        }
    }

    const onHideDelete = (type: onHideType, id: string) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);
                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        return dataSDK.sdk.Club.deleteClub(id, organization.status.startsWith('PE'), undefined, dataSDK.token);
                    })
                    .then((results) => {
                        if (results.statusCode === 204) {
                            dispatch(getClubs({
                                pending: organization.status.startsWith('PE'),
                                org_id: organization.id,
                                club_type: 'CLUB',
                            }));
                            currentSuccess?.setLoadingModal(false);
                            currentSuccess?.setModalsOff();
                        }
                        else {
                            console.log((results.body as IResponseError).error || (results.body as IResponseError).message)
                        }
                    })
                    .finally(() => {
                        setDeleteModal(false);
                        setDeleteLoading(false);
                        setDeleteId(undefined);
                    })
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                break;

            default:
                break;
        }
    }

    const handleDelete = (data: string) => {
        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => onHideDelete(type, data),
            'Are you sure you want to delete this community?'
        );
    }

    return (
        <>
            {getContentOfTab()}
            <ModalCommunities
                showModal={showModal}
                onHide={setShowModal}
                data={community}
                modalities={modalities}
            />
            <ModalCommunities
                showModal={showModalEdit}
                onHide={handleModalEdit}
                data={community}
                edit
                clubInfo={editComm}
            />
        </>
    )
}

const TextServices = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

const ContentServices = styled.div`
    display: grid;
    grid-template-columns: 30% calc(70% - 20px);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    height: 100%;
`

const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Area = styled.div`
    border-bottom: 1px solid darkgrey;
    background-color: white;
    font-family: 'Inter';
    font-size: 14px;
    cursor: pointer;
    width: 100%;
    padding: 13px 9px;
    margin: 0;
    display: flex;
    justify-content: space-between;
`;

const ListAreas = styled.div`
    width: 100%;
    padding: 7px 9px;
    background-color: white;
    height: calc(100vh - 410px);
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 5px;
        display: block !important;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--t-blue);
    }
`

const BaseAreas = styled.div`
    width: 100%;
    border-radius: 9px;
    background-color: white;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
`

const TitleAreas = styled.span`
    font-family: 'Inter-Bold';
    font-size: 16px;
    color: black;
    margin: 15px 0px 0px 10px;
`;

const WrapperList = styled.p`
    overflow: scroll;
    max-height: 300px;
    display: flex;
    width: 100%;
`;

const WrapperText = styled.p`
    overflow: hidden;
    max-height: 15px;
`;

const WrapperEmpty = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const TableIcon = styled(FontAwesomeIcon)`
    font-size: 18px;
    cursor: pointer;
`;

const TableList = styled.table`
    width: 100%;
    background-color: white;
    border-radius: 8px;
    max-height: 100px;
    overflow: scroll;
`

const Tr = styled.tr`
    & td:last-child,
    & th:last-child {
        width: 85px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

const Th = styled.th`
    vertical-align: middle;
    height: 40px;
    border-bottom: 1px solid var(--simple-gray-2);
    font-family: 'Inter-Bold';
    font-size: 14px;
    padding: 0 15px;
    text-align: left;
`

const Td = styled.td`
    vertical-align: middle;
    height: 40px;
    border-bottom: 1px solid var(--simple-gray-2);
    font-family: 'Inter';
    font-size: 14px; 
    padding: 0 15px; 
    text-align: left;
`

const SearchIcon = styled(FontAwesomeIcon)`
    font-size: 12px;
    margin-right: 20px;
    color: var(--black);
    margin-left: 20px;
`;

const InputIcon = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-radius: 8px;
    align-items: center;
    border: 1px solid var(--simple-gray);
    height: 40px;
    width: 100%;
    
    & ${Input}{
        border: 0;
        height: 38px;
    } 
`

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background: var(--electric-yellow-2);
    border-radius: 8px;
    color: var(--white);
    font-family: 'Inter-Bold';
    font-size: 16px;
    padding: 10px;

    cursor: pointer;
`

const Title = styled.p`
    font-family: 'Inter-Bold';
    font-size: 16px;
    padding: 13px 0;
`
const SubTitle = styled.span`
    font-family: 'Inter';
    font-size: 14px;
`

const ListServicesEmp = styled.div`
    width: 100%;
    height: 100%;
    background-color: white;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-image: url(./NoServices.png);
    border-radius: 9px;
    padding: 20px 0;
`

const ListServices = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    overflow: scroll;
`

const IconPlus = styled(FontAwesomeIcon)`
    font-size: 14px;
    margin-left: 8px
`;

const InputPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0;
    font-size: 15px;
    font-family: 'Inter';
    background-color: transparent;
`;

export default Communities;