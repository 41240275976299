import { useState, useContext, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { BaseInput, ClickableIcon } from "./components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ContextSuccess } from "../../../../Context";
import { faTimes, faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import ModalDiscardChanges from "../../../SetupSetup/ModalDiscardChanges";
import ErrorField from '../../../BaseComponents/ErrorField';
import { toast } from "react-toastify";
import { getDataSDK, getDataSDKResponse } from "../../../../helpers/backendHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFiltered } from '../../../../containers/Signup/SignupHealer';
import useActiveCountry, { CountryOption } from '../../../../hooks/Locations/useActiveCountry';
import useActiveState, { StateOption } from '../../../../hooks/Locations/useActiveState';
import { ISignUpUnregisteredOrganizationRequest } from '@vibe/sdk/dist/interfaces/AccessManager/unregisteredOrg';
import { BaseFieldState, getFieldState } from '../../../BaseComponents/utils';
import { sortAlphabeticallyOnProperty } from '../../../../containers/Signup/SignupHealerInd';
import { onHideType } from '../../../BaseComponents/Modals/ModalUnsaved';
import { Imp } from '../../../signupsetuphealerind';
import SelectorCountry from '../../../BaseComponents/Location/SelectorCountry';
import SelectorState from '../../../BaseComponents/Location/SelectorState';
import ButtonSpinner from '../../../BaseComponents/ButtonSpinner';
import Button from 'react-bootstrap/esm/Button';

interface CreateListModalProps {
    show: boolean;
    onHide: (type: 'discard' | 'save', form?: ISignUpUnregisteredOrganizationRequest) => void;
}

const CreateListModal = ({
    show,
    onHide,
}: CreateListModalProps) => {

    const { t } = useTranslation();
    const currentSuccess = useContext(ContextSuccess);
    const [loadingAssign, setLoadingAssign] = useState<boolean>(false);

    const [listName, setListName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [website, setWebsite] = useState<BaseFieldState<string>>(getFieldState(''));
    const [country, setCountry] = useState<BaseFieldState<CountryOption>>(getFieldState({ label: '', value: '' }));
    const [state, setState] = useState<BaseFieldState<StateOption>>(getFieldState({ label: '', value: '' }));
    const [zipcode, setZipcode] = useState<BaseFieldState<string>>(getFieldState(''));

    const [showOptionsCountry, setShowOptionsCountry] = useState<boolean>(false)
    const [searchCountry, setSearchCountry] = useState<string>('');
    const { activeCountriesReactSelect } = useActiveCountry();
    const [showOptionsState, setShowOptionsState] = useState<boolean>(false)
    const [searchState, setSearchState] = useState<string>('');
    const { activeStatesReactSelect } = useActiveState();

    const [existingPO, setExistingPO] = useState<string | undefined>('');

    const cleanWebsite = (web: string) => {
        const website = web.replace(/\s+/g, '').toLowerCase();
        const URL_PROTOCOL = /http(s)?:\/\/.*/;
        if (URL_PROTOCOL.exec(website)) {
            return website;
        }
        return `https://${website}`;
    }

    const onCreate = () => {
        if (errorsInForm()) {
            currentSuccess?.setSuccessData(false, true, t('toast_errorMissingInformation'));
            return;
        }

        onHide('save', {
            organization: {
                organizationName: listName.value!,
                organizationType: 'PROFESSIONAL',
                website: cleanWebsite(website.value!),
                state: state.value!.value,
                zipcode: zipcode.value || "00000",
                countryCode: country.value?.value || 'USA',
            }
        })

    }

    const errorsInForm = () => {
        let errors = false;

        if (!listName.value || listName.value.length <= 0) {
            errors = true;
            setListName({
                ...listName,
                showError: true,
                error: 'Name is required',
            })
        } else {
            setListName(getFieldState(listName.value));
        }

        if (!website.value || website.value.length <= 0) {
            errors = true;
            setWebsite({
                ...website,
                showError: true,
                error: 'Website is required',
            })
        } else {
            setWebsite(getFieldState(website.value));
        }

        if (
            !country.value?.value || country.value?.value.length === 0 ||
            !country.value?.label || country.value?.label.length === 0
        ) {
            errors = true;
            setCountry({
                ...country,
                showError: true,
                error: 'Country is required',
            })
        } else {
            setCountry(getFieldState(country.value));
        }

        if (
            !state.value?.value || state.value?.value.length === 0 ||
            !state.value?.label || state.value?.label.length === 0
        ) {
            errors = true;
            setState({
                ...state,
                showError: true,
                error: 'State / Region is required',
            })
        } else {
            setState(getFieldState(state.value));
        }

        if (!zipcode.value || zipcode.value.length <= 0) {
            // errors = true;
            // setZipcode({
            //     ...zipcode,
            //     showError: true,
            //     error: 'Zipcode is required',
            // })
            setZipcode(getFieldState('00000'));
        } else {
            setZipcode(getFieldState(zipcode.value));
        }

        return errors;
    }

    const onChangeListName = (event: React.FormEvent<HTMLInputElement>) => {
        if (event) {
            setListName({
                ...listName,
                value: event?.currentTarget.value
            });
        }
    }

    const onChangeWebsite = (event: React.FormEvent<HTMLInputElement>) => {
        if (event) {
            setWebsite({
                ...website,
                value: event?.currentTarget.value
            });
        }
    }

    const onChangeZipcode = (event: React.FormEvent<HTMLInputElement>) => {
        if (event) {
            setZipcode({
                ...zipcode,
                value: event?.currentTarget.value
            });
        }
    }

    const onHideUnsaved = (type: onHideType) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);
                currentSuccess?.setModalsOff();
                onCreate();
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                currentSuccess?.setLoadingModal(false);
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                currentSuccess?.setLoadingModal(false);
                onHide('discard');
                cleanForm();
                break;

            default:
                break;
        }
    }

    const onCancel = () => {
        currentSuccess?.setModalWarning(
            'UNSAVED',
            (type) => onHideUnsaved(type),
            'Do you want to discard changes?'
        );
    }

    const cleanForm = () => {
        setListName(getFieldState(''));
        setWebsite(getFieldState(''));
        setCountry(getFieldState({ label: '', value: '' }));
        setState(getFieldState({ label: '', value: '' }));
        setZipcode(getFieldState(''));
    }

    useEffect(() => {
        setListName(getFieldState(''));
        setWebsite(getFieldState(''));
        setCountry(getFieldState({ label: '', value: '' }));
        setState(getFieldState({ label: '', value: '' }));
        setZipcode(getFieldState(''));
        setSearchCountry('')
        setSearchState('')
    }, [show])

    return (
        <>
            <Modal className="modalModalitiesCreate" show={show} onHide={onCancel}>
                <Modal.Header>
                    <Modal.Title>
                        Create New Professional Organization
                    </Modal.Title>
                    <ClickableIcon
                        icon={faTimes}
                        size='xl'
                        color={'var(--black'}
                        className="icon-times"
                        onClick={onCancel}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Label>Name<Imp>*</Imp></Label>
                    <BaseInputList
                        onChange={onChangeListName}
                        value={listName.value}
                    />
                    {(listName.showError) && (<ErrorField error={listName.error} />)}

                    <Label>Website<Imp>*</Imp></Label>
                    <BaseInputList
                        onChange={onChangeWebsite}
                        value={website.value}
                    />
                    {(website.showError) && (<ErrorField error={website.error} />)}

                    <Label>Country<Imp>*</Imp></Label>
                    <SelectorCountry
                        country={country}
                        setCountry={setCountry}
                        searchCountry={searchCountry}
                        setSearchCountry={setSearchCountry}
                    />
                    {(country.showError) && (<ErrorField error={country.error} />)}

                    <Label>State / Region<Imp>*</Imp></Label>
                    <SelectorState
                        country={country.value?.value ?? ''}
                        state={state}
                        setState={setState}
                        searchState={searchState}
                        setSearchState={setSearchState}
                    />
                    {(state.showError) && (<ErrorField error={state.error} />)}

                    <Label>Zipcode</Label>
                    <BaseInputList
                        onChange={onChangeZipcode}
                        value={zipcode.value}
                    />
                    {(zipcode.showError) && (<ErrorField error={zipcode.error} />)}
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton onClick={onCancel}>
                        {t('button__cancel')}
                    </CancelButton>
                    <ButtonSpinner
                        loading={loadingAssign}
                        handleClick={onCreate}
                        text={t('button__save')}
                        hasIcon={false}
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateListModal;

const CancelButton = styled(Button)`
    border: 1px solid var(--t-blue);
    border-radius: 12px;
    width: 100%;
    cursor: pointer;
    background: #FFFFFF;
    box-sizing: border-box;
    color: var(--t-blue);
`;

const Label = styled.div`    
    width: 100%;
    text-align: left;
    margin: 3px 0;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`;

const BaseInputList = styled(BaseInput)`
    background-color: white;
    border: 1px solid var(--simple-gray);
    color: black;

    ::placeholder{
        color: var(--simple-gray);
    }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: var(--white);
    cursor: pointer;
    margin-left: 10px;
`;

const IconDropdownInput = styled(StyledFontAwesomeIcon)`
    position: absolute;
    z-index: 2;
    right: 10px;
`;

const Items = styled.ul`
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 33px;
`

const Item = styled.li`
    background-color: var(--t-blue);
    color: var(--white);
    padding: 10px 20px;
    text-align: left;
    font-family: Arial;
    cursor: pointer;

    &:hover {
        background-color: var(--black);
    }
`;