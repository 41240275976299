import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { ContextSuccess } from "../../Context";
import { RootState } from "../../store/store";
import Modal from "react-bootstrap/esm/Modal";
import styled from "styled-components";

const ModalSearchWarning = ({
    showModal,
    setShowModal,
}: {
    showModal: boolean
    setShowModal: (data: boolean) => void
}) => {

    const currentSuccess = useContext(ContextSuccess);

    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);


    const onHideClose = () => {
        setShowModal(false);
    }


    const onHideDiscard = () => {
        currentSuccess?.setModalsOff();
        onHideClose();
    };


    const [ip, setIp] = useState<string>('');

    useEffect(() => {
        fetch('https://api.ipify.org/?format=json')
            .then(res => res.json())
            .then(({ip}) => {
                setIp(ip);
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    const [disableButton, setDisableButton] = useState<boolean>(false)

    const handleAgree = () => {
        setDisableButton(true)
        getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
            return dataSDK.sdk.Connections.acceptDisclaimer(ip, userInfo?.username);
        })
        .then(({statusCode}) => {
            if (statusCode === 201) {
                onHideClose();
                console.log("Disclaimer accepted");
            }
        })
        .catch((error) => {
           console.log("Error accepting disclaimer", error);
        })
    }

    return (
        <Modal className="modalSearchWarning" show={showModal}>
            <Modal.Header>
            </Modal.Header>
            <Modal.Body>
            <ColumnRow>
                <Row1>
                    <ItemCheckbox>The information provided on this platform is for informational purposes only. By using this information, you acknowledge and accept that you are assuming all risks associated with its use. This platform and its operators cannot be held responsible for any consequences resulting from reliance on this information. It is strongly recommended to consult with a qualified professional.</ItemCheckbox>
                </Row1>
            </ColumnRow>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => onHideDiscard()}>
                    Leave
                </Button>
                <Button
                    theme={{ main: disableButton ? 'buttonDisabled': 'buttonActive' }}
                    onClick={handleAgree}>
                    Agree
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalSearchWarning;

export const ContentTab_Event = styled.div`
    gap: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
`

const Button = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    width: 100%;
    border-radius: 8px;
    border: 1px solid #999796;
    background: #FFF;
    font-family: 'Inter-Bold';
    font-size: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    color: #6B6B6B;

    &.buttonDisabled{
        background: #EEE; 
    }
    &.buttonActive{
        background: var(--t-blue);
        color: white;
    }

    &.buttonDisabled:hover .tooltip{
        display: block;
    }
`

const Row1 = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 62px;
    min-height: 62px;
`;

const ColumnRow = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    gap: 10px;
    flex-direction: column;
`;


const ItemCheckbox = styled.div`
    display: flex;
    justify-content: start;
    padding: 3px 0px 3px 0;
    align-items: center;
    font-family: 'Inter';
    font-size: 14px;
    gap: 8px;
    cursor: pointer;
    line-height: 1.2;
`;