import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { WHITE, LAVENDER } from "../../ClubIconsNavBar";
import { BROWN } from "../../SuperCenter/Modalities/Modals/components";
import styled from "styled-components";

export type onHideType = 'close' | 'discard' | 'save';

function ModalUnsaved({
  show,
  onHide,
  content,
  deleteModal
} : {
  show: boolean,
  onHide: (type: onHideType) => void,
  content: string,
  deleteModal?: boolean
}) {
  return (
    <>
      <Modal
        show={show}
        onHide={() => onHide('close')}
      >
        <Modal.Header>
          <Modal.Title></Modal.Title>
          <FontAwesomeIcon
            icon={faTimes}
            size='xl'
            color={WHITE}
            className="icon-times"
            onClick={() => onHide('close')}
          />
        </Modal.Header>
        <Modal.Body>
          <FontAwesomeIcon
            icon="circle-exclamation"
            size='4x'
            color={'var(--simple--blue-dark)'}
          />
          <Content>{content}</Content>
        </Modal.Body>
        <Modal.Footer>
          <DisplayButton>
            <ButtonBase save={false} onClick={() => onHide('discard')}>
              Discard
            </ButtonBase>
            <ButtonBase save={true} onClick={() => onHide('save')}>
              {deleteModal ? 'Delete' : 'Save'}
            </ButtonBase>
          </DisplayButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const Content = styled.span`
  display: block;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: var(--simple-blue-dark);
  margin-top: 25px;
`;

const DisplayButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  justify-items: space-between;
`;

interface PropsButton {
  save: boolean
}

const ButtonBase = styled.button`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;

  color: ${(props: PropsButton) => !props.save ? 'var(--simple-blue-dark)' : 'var(--white)'};
  background-color: ${(props: PropsButton) => props.save ? 'var(--simple-blue-dark)' : 'var(--white)'};
  border: 2px solid var(--simple-blue-dark);
`;

export default ModalUnsaved;