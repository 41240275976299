import { AnyAction } from "@reduxjs/toolkit";
import {
  GET_ORGS_ERROR,
  GET_ORGS_LIMIT_ERROR,
  GET_ORGS_LIMIT_SUCCESS,
  GET_ORGS_SUCCESS,
  GET_ORG_ERROR,
  GET_ORG_SUCCESS,
  ORG_NAMES_ERROR,
  ORG_NAMES_SUCCESS,
  ORG_STATUS_ERROR,
  ORG_STATUS_SUCCESS,
  ORG_TYPE_ERROR,
  ORG_TYPE_SUCCESS,
  START_GET_ORG,
  START_GET_ORGS,
  START_GET_ORGS_LIMIT,
  START_ORG_NAMES,
  START_ORG_STATUS,
  START_ORG_TYPE,
  START_UPDATE_ORG,
  UPDATE_ORG_ERROR,
  UPDATE_ORG_SUCCESS,
  START_CLUB_NAMES,
  CLUB_NAMES_SUCCESS,
  CLUB_NAMES_ERROR,
  START_APPROVE_ORG,
  APPROVE_ORG_SUCCESS,
  APPROVE_ORG_ERROR,
  START_UPLOAD_LOGO_ORG,
  UPLOAD_LOGO_ORG_SUCCESS,
  UPLOAD_LOGO_ORG_ERROR,
  START_ORG_TITLE,
  ORG_TITLE_SUCCESS,
  ORG_TITLE_ERROR,
  LOGOUT_ORG,
  START_GET_STAFF_USERS,
  GET_STAFF_USERS_SUCCESS,
  GET_STAFF_USERS_ERROR,
  START_YOUTH_SCHOOLS,
  YOUTH_SCHOOLS_ERROR,
  YOUTH_SCHOOLS_SUCCESS,
  START_GET_ORG_HEALER_PROFILE,
  GET_ORG_HEALER_PROFILE_ERROR,
  GET_ORG_HEALER_PROFILE_SUCCESS,
  GET_ORG_HEALERS_ERROR,
  GET_ORG_HEALERS_SUCCESS,
  START_GET_ORG_HEALERS,
} from "../constants/orgsConstants";
import OrgsStateInterface from "../../interfaces/store/orgsState";
import { MockOrg } from "../../interfaces/organization/Organization";

const initialState: OrgsStateInterface = {
  organizations: [],
  loadingOrganizations: false,
  organizationsError: undefined,

  organizationsLimit: [],
  loadingOrganizationsLimit: false,
  organizationsLimitError: undefined,
  lastEvaluatedkeys: [],

  loadingOrganizationStatus: false,
  organizationStatus: [],
  organizationStatusError: undefined,

  loadingOrganizationTypes: false,
  organizationTypesError: undefined,
  organizationTypes: [],

  loadingOrganizationTitles: false,
  organizationTitlesError: undefined,
  organizationTitles: [],

  loadingOrganizationNames: false,
  organizationNamesError: undefined,
  organizationNames: [],

  loadingOrganizationInfo: false,
  organizationInfoError: undefined,
  organizationInfo: {...MockOrg},

  loadingOrgHealerProfile: false,
  orgHealerProfileError: undefined,
  orgHealerProfile: {...MockOrg},

  loadingOrgHealers: false,
  orgHealersError: undefined,
  orgHealers: [],

  loadingUpdateOrg: false,
  updateOrgError: undefined,
  updateOrg: [],

  loadingClubNames: false,
  clubNamesError: undefined,
  clubNames: [],

  loadingUploadLogoOrg: false,
  UploadLogoOrgError: undefined,
  uploadLogoOrg: [],

  loadingStaffUsers: false,
  staffUsersError: undefined,
  staffUsers: [],

  loadingSchoolsYouth: false,
  schoolsYouthError: undefined,
  schoolsYouth: [],

  loadingApproveOrg: false,
  approveOrg: undefined,
  approveOrgError: undefined,
};

export const getOrgsReducer = (state = initialState, action: AnyAction):OrgsStateInterface => {
  switch (action.type) {
    case START_GET_ORGS:
      return {
        ...state,
        loadingOrganizations: true,
      };
    case GET_ORGS_SUCCESS:
      return {
        ...state,
        organizations: action.payload,
        loadingOrganizations: false,
      };
    case GET_ORGS_ERROR:
      return {
        ...state,
        organizationsError: action.payload,
        loadingOrganizations: false,
      };

    case START_YOUTH_SCHOOLS:
      return {
        ...state,
        loadingSchoolsYouth: true,
      };
    case YOUTH_SCHOOLS_SUCCESS:
      return {
        ...state,
        schoolsYouth: action.payload,
        loadingSchoolsYouth: false,
      };
    case YOUTH_SCHOOLS_ERROR:
      return {
        ...state,
        schoolsYouthError: action.payload,
        loadingSchoolsYouth: false,
      };

    case START_GET_ORGS_LIMIT:
      return {
        ...state,
        loadingOrganizationsLimit: true,
      };
    case GET_ORGS_LIMIT_SUCCESS: {
      const newLastEvaluatedKey = [...state.lastEvaluatedkeys];
      newLastEvaluatedKey[action.payload.page + 1] =
        action.payload.lastEvaluatedKey;

      return {
        ...state,
        organizationsLimit: action.payload.organizations,
        loadingOrganizationsLimit: false,
        lastEvaluatedkeys: [...newLastEvaluatedKey],
      };
    }
    case GET_ORGS_LIMIT_ERROR:
      return {
        ...state,
        organizationsLimitError: action.payload,
        loadingOrganizationsLimit: false,
      };

    case START_ORG_STATUS:
      return {
        ...state,
        loadingOrganizationStatus: true,
      };
    case ORG_STATUS_SUCCESS:
      return {
        ...state,
        organizationStatus: action.payload,
        loadingOrganizationStatus: false,
      };
    case ORG_STATUS_ERROR:
      return {
        ...state,
        organizationStatusError: action.payload,
        loadingOrganizationStatus: false,
      };

    case START_GET_ORG_HEALERS:
      return {
        ...state,
        loadingOrgHealers: true,
      };
    case GET_ORG_HEALERS_SUCCESS:
      return {
        ...state,
        orgHealers: action.payload,
        loadingOrgHealers: false,
      };
    case GET_ORG_HEALERS_ERROR:
      return {
        ...state,
        orgHealersError: action.payload,
        loadingOrgHealers: false,
      };

    case START_ORG_TYPE:
      return {
        ...state,
        loadingOrganizationTypes: true,
      };
    case ORG_TYPE_SUCCESS:
      return {
        ...state,
        organizationTypes: action.payload,
        loadingOrganizationTypes: false,
      };
    case ORG_TYPE_ERROR:
      return {
        ...state,
        organizationTypesError: action.payload,
        loadingOrganizationTypes: false,
      };

    case START_ORG_TITLE:
      return {
        ...state,
        loadingOrganizationTitles: true,
      };
    case ORG_TITLE_SUCCESS:
      return {
        ...state,
        organizationTitles: action.payload,
        loadingOrganizationTitles: false,
      };
    case ORG_TITLE_ERROR:
      return {
        ...state,
        organizationTitlesError: action.payload,
        loadingOrganizationTitles: false,
      };

    case START_ORG_NAMES:
      return {
        ...state,
        loadingOrganizationNames: true,
      };
    case ORG_NAMES_SUCCESS:
      return {
        ...state,
        organizationNames: action.payload,
        loadingOrganizationNames: false,
      };
    case ORG_NAMES_ERROR:
      return {
        ...state,
        organizationNamesError: action.payload,
        loadingOrganizationNames: false,
      };

    case START_GET_ORG:
      return {
        ...state,
        loadingOrganizationInfo: true,
      };
    case GET_ORG_SUCCESS:
      return {
        ...state,
        loadingOrganizationInfo: false,
        organizationInfo: action.payload,
      };
    case GET_ORG_ERROR:
      return {
        ...state,
        loadingOrganizationInfo: false,
        organizationInfoError: action.payload,
      };

    case START_GET_ORG_HEALER_PROFILE:
      return {
        ...state,
        loadingOrgHealerProfile: true,
      };
    case GET_ORG_HEALER_PROFILE_SUCCESS:
      return {
        ...state,
        loadingOrgHealerProfile: false,
        orgHealerProfile: action.payload,
      };
    case GET_ORG_HEALER_PROFILE_ERROR:
      return {
        ...state,
        loadingOrgHealerProfile: false,
        orgHealerProfileError: action.payload,
      };

    case START_UPDATE_ORG:
      return {
        ...state,
        loadingUpdateOrg: true,
      };
    case UPDATE_ORG_SUCCESS:
      return {
        ...state,
        loadingUpdateOrg: false,
        updateOrg: action.payload,
      };
    case UPDATE_ORG_ERROR:
      return {
        ...state,
        loadingUpdateOrg: false,
        updateOrgError: action.payload,
      };
    case START_CLUB_NAMES:
      return {
        ...state,
        loadingClubNames: true,
      };
    case CLUB_NAMES_SUCCESS:
      return {
        ...state,
        clubNames: action.payload,
        loadingClubNames: false,
      };
    case CLUB_NAMES_ERROR:
      return {
        ...state,
        clubNamesError: action.payload,
        loadingClubNames: false,
      };

    case START_APPROVE_ORG:
      return {
        ...state,
        loadingApproveOrg: true,
      };
    case APPROVE_ORG_SUCCESS:
      return {
        ...state,
        loadingApproveOrg: false,
        approveOrg: action.payload,
      };
    case APPROVE_ORG_ERROR:
      return {
        ...state,
        loadingApproveOrg: false,
        approveOrgError: action.payload,
      };
    case START_UPLOAD_LOGO_ORG:
      return {
        ...state,
        loadingUploadLogoOrg: true,
      };
    case UPLOAD_LOGO_ORG_SUCCESS:
      return {
        ...state,
        loadingUploadLogoOrg: false,
        uploadLogoOrg: action.payload,
      };
    case UPLOAD_LOGO_ORG_ERROR:
      return {
        ...state,
        loadingUploadLogoOrg: false,
        UploadLogoOrgError: action.payload,
      };

    case LOGOUT_ORG:
      return initialState;

    case START_GET_STAFF_USERS:
      return {
        ...state,
        loadingStaffUsers: true,
      };

    case GET_STAFF_USERS_SUCCESS:
      return {
        ...state,
        loadingStaffUsers: false,
        staffUsers: action.payload,
      };

    case GET_STAFF_USERS_ERROR:
      return {
        ...state,
        loadingStaffUsers: false,
        staffUsersError: action.payload,
      };

    default:
      return state;
  }
};
