import React, { useState } from 'react'
import '../Styles/styles.scoped.css';
import styled from 'styled-components';
import ContainerVideo from './ContainerVideo';
import { IMedia } from '@vibe/sdk/interfaces/Admin/utils';

const GridImages = ({
    images,
    type
}: {
    images: any[],
    type: string
}) => {

    const isImage = (request: any): boolean => {
        const imageTypes: any = ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'tiff', 'webp', 'svg'];
        const finalType: string = (request?.type || '').split('/'); //Because some of the files has only the extension and not the contentType complete
        return imageTypes.includes(finalType[finalType.length-1] || '');
    }

    const correctContainer = (request: any) => {
        return type === 'create' ? (
            <img className=""
                src={URL.createObjectURL(request)}
            />
        ) : (
            isImage(request) ? (
                <img className=""
                    src={request.link}
                />
            ) : (
                <ContainerVideo file={request as IMedia} />
            )
        )
    }

    return (
        <WrapperImages>
            {images.length === 1 &&
                <div className='oneImages'>
                    {images.map((request: any, index: number) => (
                        <div className='column'>
                            <div key={index} className='contImages'>
                                {correctContainer(request)}
                            </div>
                        </div>
                    ))}
                </div>
            }
            {images.length === 2 ?
                <div className='twoImages'>
                    {images.map((request: any, index: number) => (
                        <div className='column'>
                            <div key={index} className='contImages'>
                                {correctContainer(request)}
                            </div>
                        </div>
                    ))}
                </div>
                :
                (images.length >= 3 && images.length < 5) ?
                    <div className='threeImages'>
                        <div className='column'>
                            <div key={0} className='contImages'>
                                {correctContainer(images[0])}
                            </div>
                        </div>
                        <div className='column col2'>
                            {images.map((request: any, index: number) => (
                                index !== 0 &&
                                <div key={index} className='contImages'>
                                    {correctContainer(request)}
                                </div>
                            ))}
                        </div>

                    </div>
                    : images.length >= 5 ?
                        <div className='moreImages'>
                            <div className='column'>
                                <div key={0} className='contImages'>
                                    {correctContainer(images[0])}
                                </div>
                                <div key={1} className='contImages'>
                                    {correctContainer(images[1])}
                                </div>
                            </div>
                            <div className='column col2'>
                                <div key={2} className='contImages'>
                                    {correctContainer(images[2])}
                                </div>
                                <div key={3} className='contImages'>
                                    {correctContainer(images[3])}
                                </div>
                                <div key={4} className='contImages lastImage'>
                                    {images.length > 5 &&
                                        <p>+{images.length - 5}</p>
                                    }
                                    {correctContainer(images[4])}
                                </div>
                            </div>
                        </div>
                        :
                        ''
            }
        </WrapperImages>
    )
}

const WrapperImages = styled.div`
    width: 100%;
    max-height: 400px;
    overflow: hidden;
    border-radius: 9px;
`;

export default GridImages;