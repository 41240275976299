import { useContext, useEffect, useState } from 'react'
import styled from "styled-components";
import {
    Input, Imp
} from "../signupsetuphealerind"
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faSquareCheck, faCirclePlus, faUserPlus, faClone } from '@fortawesome/pro-regular-svg-icons';
import ModalModalities from '../../components/signupsetuphealer/ModalModalities';
import ModalTherapeutic from '../../components/signupsetuphealer/ModalTherapeutic';
import ModalBenefit from '../../components/signupsetuphealer/ModalBenefit';
import Modality from '@vibe/sdk/interfaces/Modalities/modalities/Modality/Modality';
import ModalUnsaved from '../BaseComponents/Modals/ModalUnsaved';
import ButtonSpinner from '../BaseComponents/ButtonSpinner';
import useModalitiesSA from '../../hooks/Superadmin/Modalities/useModalitiesSA';
import useBenefitsSA from '../../hooks/Superadmin/Modalities/useBESA';
import useTherapeuticAreasSA from '../../hooks/Superadmin/Modalities/useTASA';
import Delivery from '@vibe/sdk/interfaces/Modalities/hservices/Delivery';
import { capitalize } from '../../helpers/letterHelper';
import ErrorField from '../BaseComponents/ErrorField';
import IClub, { IClubExtended } from '@vibe/sdk/dist/interfaces/Club/ClubInterface';
import IResponseError from '@vibe/sdk/dist/interfaces/IResponseError';
import { useDispatch, useSelector } from 'react-redux';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { getHealerServices } from '../../store/actions/modalityActions';
import { RootState } from '../../store/store';
import IHS from '@vibe/sdk/dist/interfaces/Modalities/hservices/HS';
import { UserInClub } from './ModalSetup';
import TClub from '@vibe/sdk/dist/interfaces/Club/TClubs/TClub/TClub';
import { genderType } from '@vibe/sdk/dist/interfaces/AccessManager/signUpUser';
import { getClubs } from '../../store/actions/clubActions';
import useGender from '../../hooks/useGender';
import ModalGuide from './ModalGuide';
import ModalMembers from './ModalMembers';
import { ClickableIcon } from '../SuperCenter/Modalities/Modals/components';
import { IFetchResponse } from '@vibe/sdk/dist/helpers/fetch.helper';
import { ICreateInviteResponse } from '@vibe/sdk/dist/interfaces/Invite/createInvite';
import { IClubCode } from '@vibe/sdk/dist/interfaces/Club/clubCode';
import { ContextSuccess } from '../../Context';
import ITherapeuticArea from '@vibe/sdk/dist/interfaces/Modalities/modalities/TA/TA';
import IBenefit from '@vibe/sdk/dist/interfaces/Modalities/modalities/BE/BE';
import { formatLink } from '../../helpers/club/clubHelper';
import { frontendUrl } from '../../AppConfig';
import { IUserGradeResponse } from '@vibe/sdk/dist/interfaces/Admin/User';
import ModalCommServices from './ModalCommServices';
import BlankTable from '../BaseComponents/BlankTable';
import TextEditor from '../BaseComponents/TextEditor';
import { BaseFieldState, getFieldState } from '../BaseComponents/utils';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import IconInput from '../FormComponents/IconInput';
import { onHideType } from "../../components/BaseComponents/Modals/ModalUnsaved";

const ModalCommunities = ({
    showModal,
    onHide,
    data,
    edit,
    clubInfo,
    modalities,
    service,
    reloadClubs,
}: {
    showModal: boolean,
    onHide: (show: boolean, value?: Modality) => void,
    data: IHS,
    modalities?: Modality[],
    edit?: boolean,
    clubInfo?: IClubExtended,
    service?: IHS,
    reloadClubs?: () => void;
}) => {

    const currentSuccess = useContext(ContextSuccess);

    const dispatch = useDispatch();

    const userInfo = useSelector((state: RootState | any) => state.getUser.userInfo);
    const user: IUserGradeResponse = useSelector((state: RootState | any) => state.getUser.user);
    const organization = useSelector((state: RootState | any) => state.getOrgs.organizationInfo);
    const services: IHS[] = useSelector((state: RootState) => state.getModality.getHealerServices);

    const { modalitiesPages, reloadModalities } = useModalitiesSA({
        pageSizeProp: 1000,
        searchName: '',
    });
    const { benefitsPages, reloadBenefits } = useBenefitsSA({
        pageSizeProp: 100,
        searchName: '',
    });
    const { taPages, reloadTherapeuticAreas } = useTherapeuticAreasSA({
        pageSizeProp: 100,
        searchName: '',
    });
    const { genders, setCountry: setCountryGenders } = useGender();

    const [tabActive, setTabActive] = useState<number>(1)

    const [showModalModalities, setShowModalModalities] = useState(false);
    const [showModalTherapeutic, setShowModalTherapeutic] = useState(false);
    const [showModalBenefit, setShowModalBenefit] = useState(false);
    const [showModalGuide, setShowModalGuide] = useState(false);
    const [showModalMembers, setShowModalMembers] = useState(false);
    const [loading, setLoading] = useState(false);

    const [selectAllMod, setSelectAllMod] = useState(false);
    const [notApplyMod, setNotApplyMod] = useState(false);
    const [selectAllTA, setSelectAllTA] = useState(false);
    const [notApplyTA, setNotApplyTA] = useState(false);
    const [selectAllBE, setSelectAllBE] = useState(false);
    const [notApplyBE, setNotApplyBE] = useState(false);
    const [selectAllGenders, setSelectAllGenders] = useState(false);

    const [modName, setModName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [clubTag, setClubTag] = useState<BaseFieldState<string>>(getFieldState(''));
    const [modDescription, setModDescription] = useState<BaseFieldState<string>>(getFieldState(''));
    const [modModalities, setModModalities] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [modServices, setModServices] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [modTA, setModTA] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [modBE, setModBE] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [modGenders, setModGenders] = useState<BaseFieldState<genderType[]>>(getFieldState([]));
    const [modTACreate, setModTACreate] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [modBECreate, setModBECreate] = useState<BaseFieldState<string[]>>(getFieldState([]));

    const [clubTagLoading, setClubTagLoading] = useState(false);
    const [clubTagTaken, setClubTagTaken] = useState(false);

    //Invites
    const [teamAdvisors, setTeamAdvisors] = useState<BaseFieldState<UserInClub[]>>(getFieldState([]));

    const [teamIndividuallyMembers, setTeamIndividuallyMembers] = useState<BaseFieldState<UserInClub[]>>(getFieldState([]));
    const [teamRosters, setTeamRosters] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [teamMembers, setTeamMembers] = useState<BaseFieldState<string>>(getFieldState(''));
    const [clubCodes, setClubCodes] = useState<Omit<IClubCode, 'club_id'>[]>([]);
    const [oldClubCodes, setOldClubCodes] = useState<Omit<IClubCode, 'club_id'>[]>([]);
    const [invitesRosters, setInvitesRosters] = useState<number>(0);
    const [invitesIndividual, setInvitesIndividual] = useState<number>(0);

    const cleanFields = () => {
        setModName(getFieldState(''));
        setModDescription(getFieldState(''));
        setModModalities(getFieldState([]));
        setModServices(getFieldState([]));
        setModTA(getFieldState([]));
        setModBE(getFieldState([]));
        setTeamIndividuallyMembers(getFieldState([]));
        setTeamRosters(getFieldState([]));
        setTeamMembers(getFieldState(''));
        setModGenders(getFieldState([]));
        setTeamAdvisors(getFieldState([]));
        setModTACreate(getFieldState([]));
        setModBECreate(getFieldState([]));
        setClubTag(getFieldState(''));
        setInvitesRosters(0);
        setInvitesIndividual(0);

        setTabActive(1);
        setSelectAllMod(false);
        setSelectAllTA(false);
        setSelectAllBE(false);
        setSelectAllGenders(false);
        setNotApplyMod(false);
        setNotApplyTA(false);
        setNotApplyBE(false);
        setShowModalGuide(false);
        setShowModalMembers(false);
        setClubCodes([]);
    }

    useEffect(() => console.log('modName', modName), [modName])

    const updateFields = () => {
        cleanFields();

        setTeamAdvisors({
            ...teamAdvisors,
            value: [{
                firstName: user.firstname || '',
                lastName: user.lastname || '',
                email: user.email || '',
            }]
        })

        if (data.name === 'custom') return;

        setModServices({
            ...modServices,
            value: [data.id]
        })

        setModName({
            ...modName,
            value: data.name
        });

        if (data.description) {
            setModDescription({
                ...modDescription,
                value: data.description
            });
        }

        if (modalities && modalities.length > 0) {
            const TAAux = modalities.map((m) => m.therapeuticAreas).filter((t): t is ITherapeuticArea[] => !!t && t.length > 0).flat();
            if (TAAux.length > 0) {
                setModTA({
                    ...modTA,
                    value: TAAux.map((mod) => mod.id)
                });
            }

            const BEAux = modalities.map((m) => m.benefits).filter((t): t is IBenefit[] => !!t && t.length > 0).flat();
            if (BEAux.length > 0) {
                setModBE({
                    ...modBE,
                    value: BEAux.map((mod) => mod.id)
                });
            }
            setModModalities({
                ...modModalities,
                value: [...modalities.map(({ id }) => id)]
            });

        }

        if (data.BEIds) {
            setModBE({
                ...modBE,
                value: [...(modBE.value || []), ...data.BEIds]
            });
        }

        if (data.TAIds) {
            setModTA({
                ...modTA,
                value: [...(modTA.value || []), ...data.TAIds]
            });
        }
    }

    const getClubCodes = (id: string) => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Club.getCodesByClub(id, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) {
                    setClubCodes(results.body as IClubCode[]);
                    setOldClubCodes(results.body as IClubCode[]);
                }
            })
    }

    const loadData = () => {
        if (edit && clubInfo) {
            setModName({
                ...modName,
                value: clubInfo.name,
            });
            setModDescription({
                ...modDescription,
                value: clubInfo.description,
            })
            setModModalities({
                ...modModalities,
                value: clubInfo.modalitiesIds,
            })
            setModServices({
                ...modServices,
                value: clubInfo.servicesIds,
            })
            setModGenders({
                ...modGenders,
                value: clubInfo.gendersAllowed as genderType[],
            })
            setTeamAdvisors({
                ...teamAdvisors,
                value: (clubInfo.advInvites || []).map((invite) => ({
                    firstName: invite.name.split(' ')[0],
                    lastName: invite.name.split(' ')[1],
                    email: invite.email,
                }))
            })
            setTeamIndividuallyMembers({
                ...teamIndividuallyMembers,
                value: (clubInfo.invites || []).map((invite) => ({
                    firstName: invite.firstName,
                    lastName: invite.lastName,
                    email: invite.email,
                }))
            })
            getClubCodes(clubInfo.id);
            setClubTag({
                ...clubTag,
                value: clubInfo.clubTag,
            })
            setInvitesRosters(clubInfo.invitesCount.roster);
            setInvitesIndividual(clubInfo.invitesCount.individual);
        }
    }

    const checkMembers = () => {
        let hasInd = false;
        let hasRoster = false;
        let hasLink = false;

        if (teamIndividuallyMembers.value && teamIndividuallyMembers.value?.length > 0) {
            hasInd = true;
        }
        if (teamRosters.value && teamRosters.value?.length > 0) {
            hasRoster = true;
        }
        if (clubCodes && clubCodes.length > 0) {
            hasLink = true;
        }

        return hasInd || hasRoster || hasLink
    }

    const checkData = (): boolean => {
        let hasErrors = false;

        if (!modName.value || modName.value?.length === 0) {
            hasErrors = true;
            setModName({
                ...modName,
                showError: true,
                error: 'Modality name is required'
            })
        }
        else {
            setModName({
                ...modName,
                showError: false,
                error: ''
            })
        }

        if (!modDescription.value || modDescription.value?.length === 0) {
            hasErrors = true;
            setModDescription({
                ...modDescription,
                showError: true,
                error: 'Modality description is required'
            })
        } else {
            setModDescription({
                ...modDescription,
                showError: false,
                error: ''
            })
        }

        if (!modGenders.value || modGenders.value?.length === 0) {
            hasErrors = true;
            setModGenders({
                ...modGenders,
                showError: true,
                error: 'At least one gender is required'
            })
        } else {
            setModGenders({
                ...modGenders,
                showError: false,
                error: ''
            })
        }

        if (!teamAdvisors.value || teamAdvisors.value?.length === 0) {
            hasErrors = true;
            setTeamAdvisors({
                ...teamAdvisors,
                showError: true,
                error: 'At least one advisor is required'
            })
        } else {
            setTeamAdvisors({
                ...teamAdvisors,
                showError: false,
                error: ''
            })
        }

        return hasErrors;
    }

    const checkDataFirst = (): boolean => {
        let hasErrors = false;

        if (!modName.value || modName.value?.length === 0) {
            hasErrors = true;
            setModName({
                ...modName,
                showError: true,
                error: 'Modality name is required'
            })
        }
        else {
            setModName({
                ...modName,
                showError: false,
                error: ''
            })
        }

        if (!modDescription.value || modDescription.value?.length === 0) {
            hasErrors = true;
            setModDescription({
                ...modDescription,
                showError: true,
                error: 'Modality description is required'
            })
        } else {
            setModDescription({
                ...modDescription,
                showError: false,
                error: ''
            })
        }

        if (!modGenders.value || modGenders.value?.length === 0) {
            hasErrors = true;
            setModGenders({
                ...modGenders,
                showError: true,
                error: 'At least one gender is required'
            })
        } else {
            setModGenders({
                ...modGenders,
                showError: false,
                error: ''
            })
        }

        return hasErrors;
    }

    const handleOnSaveCommunity = () => {
        setLoading(true);
        if (checkData()) {
            setLoading(false);
            return;
        }

        const toSend: Omit<IClub, 'id'> = {
            org_id: organization.id || '',
            status: 'PENDING',
            name: modName.value || '',
            member_type: [
                'SEEKER',
                'STAFF',
                'ADVISORY',
                'COMMITTEE',
                'HEALER',
                'MIXED',
            ],
            club_type: 'CLUB',
            role_type: 'SEEKER',
            description: modDescription.value || '',
            gendersAllowed: modGenders.value || [],
            modalitiesIds: modModalities.value || [],
            servicesIds: modServices.value || [],
            therapeuticAreasIds: modTA.value || [],
            benefitsIds: modBE.value || [],
            benefitsCreate: (modBECreate.value || []).map((BE) => ({
                type: 'NEW',
                name: BE,
            })),
            therapeuticAreasCreate: (modTACreate.value || []).map((TA) => ({
                type: 'NEW',
                name: TA,
            })),
            clubTag: clubTag?.value ?? `${organization.name} ${modName.value}`,
            orgName: organization.name || '',
            orgLogo: organization.logo,
            createdAt: `${new Date().valueOf()}`,
        }

        if (edit && clubInfo?.id) handleUpdateCommunity(toSend)
        else handleCreateCommunity(toSend)
    }

    const handleCreateCommunity = (toSend: Omit<IClub, 'id'>) => {

        let dataSDK: getDataSDKResponse;
        let id: string = "";
        const errors: string[] = [];

        getDataSDK()
            .then((data: getDataSDKResponse) => {
                dataSDK = data;
                return dataSDK.sdk.Club.createClub(toSend, dataSDK.token);
            })
            .then((results: IFetchResponse<{ id: string } | IResponseError>) => {
                if (results.statusCode === 201) {
                    id = (results.body as { id: string }).id;
                    // currentSuccess?.setSuccessData(true, true, 'Community created successfully.');
                    //Add new guides
                    return Promise.all((teamAdvisors.value || [])
                        .map((advisor: UserInClub) => dataSDK.sdk.Invite.createInvite({
                            email: advisor.email,
                            org_id: organization.id,
                            club_id: id,
                            is_advisor: true,
                            firstName: advisor.firstName,
                            lastName: advisor.lastName,
                        }, dataSDK.token)));

                }
                else {
                    errors.push("Error creating community:", (results.body as IResponseError).error || (results.body as IResponseError).message);
                    currentSuccess?.setSuccessData(false, true, "Error creating community", (results.body as IResponseError).error || (results.body as IResponseError).message);
                    throw new Error('Failed to create community');
                }
            })
            .then((results: IFetchResponse<IResponseError | ICreateInviteResponse>[]) => {

                results.forEach((result) => {
                    if (result.statusCode !== 201) {
                        currentSuccess?.setSuccessData(false, true, "Error adding guides", (result.body as IResponseError).error || (result.body as IResponseError).message);
                        errors.push("Error adding guides:", (result.body as IResponseError).error || (result.body as IResponseError).message);
                    }
                })

                //Add new individual members
                if ((teamIndividuallyMembers.value || []).length > 0) {
                    return Promise.all((teamIndividuallyMembers.value || [])
                        .map((member: UserInClub) => dataSDK.sdk.Invite.createInvite({
                            email: member.email,
                            org_id: organization.id,
                            club_id: id,
                            is_advisor: false,
                            firstName: member.firstName,
                            lastName: member.lastName,
                        }, dataSDK.token)));
                }
                else {
                    return Promise.resolve([{
                        body: {},
                        statusCode: 201,
                        headers: undefined,
                    }]);
                }
            })
            .then((results: IFetchResponse<IResponseError | ICreateInviteResponse>[]) => {

                results.forEach((result) => {
                    if (result.statusCode !== 201) {
                        currentSuccess?.setSuccessData(false, true, "Error adding individual members", (result.body as IResponseError).error || (result.body as IResponseError).message);
                        errors.push("Error adding individual members:", (result.body as IResponseError).error || (result.body as IResponseError).message);
                    }
                })

                //Add code club link 
                if (clubCodes.length > 0) {
                    return Promise.all(clubCodes.map((c) => dataSDK.sdk.Club.relateClubCodeToClub({
                        code: c.code,
                        club_id: id,
                    }, dataSDK.token)))
                }
                else {
                    return Promise.resolve([{
                        body: {},
                        statusCode: 201,
                        headers: undefined,
                    }]);
                }
            })
            .then((results: IFetchResponse<IResponseError | IClubCode>[]) => {

                if (!results.every((r) => r.statusCode === 201)) {
                    currentSuccess?.setSuccessData(false, true, "Error adding club code", (results[0].body as IResponseError).error || (results[0].body as IResponseError).message);
                    errors.push("Error adding club code:", (results[0].body as IResponseError).error || (results[0].body as IResponseError).message);
                }

                //Invite rosters (if exist)
                if ((teamRosters.value || []).length > 0) {
                    return Promise.all((teamRosters.value || [])
                        .map((roster: string) => dataSDK.sdk.Invite.inviteRoster({
                            file: roster,
                            org_id: organization.id,
                            club_id: id
                        }, dataSDK.token)));
                }
                else {
                    return Promise.resolve([{
                        body: {},
                        statusCode: 200,
                        headers: undefined,
                    }]);
                }
            })
            .then((results: IFetchResponse<IResponseError | undefined>[]) => {

                results.forEach((result) => {
                    if (result.statusCode !== 200) {
                        errors.push("Error adding rosters:", (result.body as IResponseError).error || (result.body as IResponseError).message);
                        currentSuccess?.setSuccessData(false, true, "Error adding rosters", (result.body as IResponseError).error || (result.body as IResponseError).message);
                    }
                })

                //Invite rosters (if exist)
                dispatch(getClubs({
                    pending: organization.status.startsWith('PE'),
                    org_id: organization.id,
                    club_type: 'CLUB',
                }));
                if(reloadClubs) reloadClubs();
                cleanFields();
                onHide(false);
            })
            .catch((error) => {
                currentSuccess?.setSuccessData(false, true, "Error to community");
                console.log("Error creating community", error);
            })
            .finally(() => {
                if (errors.length > 0) {
                    currentSuccess?.setSuccessData(false, true, "Error creating community");
                    console.group('Errors creating community');
                    errors.forEach((error) => console.log(error));
                    console.groupEnd();
                }
                else { currentSuccess?.setSuccessData(true, true, "Info Saved!", "Your community has been created."); ; }
                setLoading(false);
                reloadBenefits();
                reloadTherapeuticAreas();
            })
    }

    const handleUpdateCommunity = (toSend: Partial<IClub>) => {
        let dataSDK: getDataSDKResponse;
        const errors: string[] = [];

        getDataSDK()
            .then((data: getDataSDKResponse) => {
                dataSDK = data;
                return dataSDK.sdk.Club.updateClub(clubInfo!.id, toSend, dataSDK.token)
            })
            .then((results: IFetchResponse<IClub | IResponseError>) => {
                if (results.statusCode !== 200) {
                    errors.push("Error creating community:", (results.body as IResponseError).error || (results.body as IResponseError).message);
                    currentSuccess?.setSuccessData(false, true, "Error creating community", (results.body as IResponseError).error || (results.body as IResponseError).message);
                    throw new Error('Failed to update community');
                }

                const newGuides = (teamAdvisors.value ?? [])
                    .filter((adv) =>
                        (clubInfo!.advInvites ?? [])
                            .findIndex((advInv) => advInv.email === adv.email) === -1
                    );

                const toDeleteGuidesEmails = (clubInfo!.advInvites ?? [])
                    .filter((adv) =>
                        (teamAdvisors.value ?? [])
                            .findIndex((advInv) => advInv.email === adv.email) === -1
                    )
                    .map(({ email }) => email as string);

                if (newGuides.length > 0 || toDeleteGuidesEmails.length > 0) {
                    return Promise.all([
                        ...(newGuides || [])
                            .map((advisor: UserInClub) => dataSDK.sdk.Invite.createInvite({
                                email: advisor.email,
                                org_id: organization.id,
                                club_id: clubInfo!.id,
                                is_advisor: true,
                                firstName: advisor.firstName,
                                lastName: advisor.lastName,
                            }, dataSDK.token)),
                        ...(toDeleteGuidesEmails || [])
                            .map((email: string) => dataSDK.sdk.Invite.hardDeleteInvite({
                                club_id: clubInfo!.id,
                                email,
                                invite_type: 'Advisor',
                            }, dataSDK.token))
                    ] as Promise<(IFetchResponse<IResponseError | ICreateInviteResponse | undefined>)>[]);
                } else {
                    return Promise.resolve([{
                        statusCode: 201,
                        headers: undefined,
                    }] as IFetchResponse<IResponseError | undefined>[]);
                }
            })
            .then((results: (IFetchResponse<IResponseError | ICreateInviteResponse | undefined>)[]): Promise<(IFetchResponse<IResponseError | ICreateInviteResponse> | IFetchResponse<IResponseError | undefined>)[]> => {
                results.forEach((result) => {
                    if (result.statusCode !== 201) {
                        errors.push("Error updating guides:", (result.body as IResponseError).error || (result.body as IResponseError).message);
                        currentSuccess?.setSuccessData(false, true, "Error updating guides", (result.body as IResponseError).error || (result.body as IResponseError).message);
                        throw new Error('Failed to update community');
                    }
                })

                const newInvites = (teamIndividuallyMembers.value ?? [])
                    .filter((ind) =>
                        (clubInfo!.invites ?? [])
                            .findIndex((newInv) => newInv.email === ind.email) === -1
                    )

                const toDeleteInvites = (clubInfo!.invites ?? [])
                    .filter((ind) =>
                        (clubInfo!.invites ?? [])
                            .findIndex((newInv) => newInv.email === ind.email) === -1
                    )

                if (newInvites.length > 0 || toDeleteInvites.length > 0) {
                    return Promise.all([
                        ...(newInvites || [])
                            .map((advisor: UserInClub) => dataSDK.sdk.Invite.createInvite({
                                email: advisor.email,
                                org_id: organization.id,
                                club_id: clubInfo!.id,
                                is_advisor: true,
                                firstName: advisor.firstName,
                                lastName: advisor.lastName,
                            }, dataSDK.token)),
                        ...(toDeleteInvites || [])
                            .map((invite) => dataSDK.sdk.Invite.hardDeleteInvite({
                                club_id: clubInfo!.id,
                                email: invite.email,
                                invite_type: 'End_User',
                            }, dataSDK.token))
                    ] as Promise<(IFetchResponse<IResponseError | ICreateInviteResponse> | IFetchResponse<IResponseError | undefined>)>[]);
                } else {
                    return Promise.resolve([{
                        body: {},
                        statusCode: 201,
                        headers: undefined,
                    }] as IFetchResponse<IResponseError | undefined>[]);
                }
            })
            .then((results: (IFetchResponse<IResponseError | ICreateInviteResponse> | IFetchResponse<IResponseError | undefined>)[]) => {

                results.forEach((result) => {
                    if (result.statusCode !== 201) {
                        errors.push("Error adding individual members:", (result.body as IResponseError).error || (result.body as IResponseError).message);
                        currentSuccess?.setSuccessData(false, true, "Error adding individual members", (result.body as IResponseError).error || (result.body as IResponseError).message);
                    }
                })

                //Add code club link 
                const newClubCodes = clubCodes.filter(({code}) => oldClubCodes.findIndex(({code: oldCode}) => oldCode === code) === -1)
                if (newClubCodes.length > 0) {
                    return Promise.all(newClubCodes.map((c) => dataSDK.sdk.Club.relateClubCodeToClub({
                        code: c.code,
                        club_id: clubInfo!.id,
                    }, dataSDK.token)))
                }
                else {
                    return Promise.resolve([{
                        statusCode: 201,
                        headers: undefined,
                    }] as IFetchResponse<IClubCode>[]);
                }
            })
            .then((results: IFetchResponse<IResponseError | IClubCode>[]) => {

                if (!results.every((r) => r.statusCode === 201)) {
                    errors.push("Error adding club code:", (results[0].body as IResponseError).error || (results[0].body as IResponseError).message);
                    currentSuccess?.setSuccessData(false, true, "Error adding club code", (results[0].body as IResponseError).error || (results[0].body as IResponseError).message);
                }

                //Invite rosters (if exist)
                if ((teamRosters.value || []).length > 0) {
                    return Promise.all((teamRosters.value || [])
                        .map((roster: string) => dataSDK.sdk.Invite.inviteRoster({
                            file: roster,
                            org_id: organization.id,
                            club_id: clubInfo!.id
                        }, dataSDK.token)));
                }
                else {
                    return Promise.resolve([{
                        body: {},
                        statusCode: 200,
                        headers: undefined,
                    }]);
                }
            })
            .then((results: IFetchResponse<IResponseError | undefined>[]) => {

                results.forEach((result) => {
                    if (result.statusCode !== 200) {
                        errors.push("Error adding rosters:", (result.body as IResponseError).error || (result.body as IResponseError).message);
                        currentSuccess?.setSuccessData(false, true, "Error adding rosters", (result.body as IResponseError).error || (result.body as IResponseError).message);
                    }
                })

                dispatch(getClubs({
                    pending: organization.status.startsWith('PE'),
                    org_id: organization.id,
                    club_type: 'CLUB',
                }));
                if(reloadClubs) reloadClubs();
                cleanFields();
                onHide(false);
            })
            .catch((error) => {
                currentSuccess?.setSuccessData(false, true, "Error creating community", error);
                console.log("Error creating community", error);
            })
            .finally(() => {
                setLoading(false)
                reloadBenefits();
                reloadTherapeuticAreas();
                cleanFields();
            })

        //Add new guides
        //Invite Members 

        //cleanFields();
        //onHide(false); 
    }

    const onHideUnsaved = (type: onHideType) => {
        switch (type) {
            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                onHide(false);
                cleanFields();
                break;
            case 'save':
                handleOnSaveCommunity();
                break;
            default:
                currentSuccess?.setModalsOff();
                break;
        }
    }

    const handleUnsaved = () => {
        currentSuccess?.setModalWarning(
            'UNSAVED',
            (type) => onHideUnsaved(type),
            'Do you want to save your changes?'
        );
    }

    const handleNext = () => {
        if (tabActive === 1) {
            if (checkDataFirst()) return;
        }

        setTabActive(tabActive + 1)
    }

    const handleTab = (tab: number) => {
        if (tabActive === 1) {
            if (checkData()) return;
        }

        setTabActive(tab)
    }

    const handleSelectAllMod = (value: boolean) => {
        if (!value) {
            setModServices({
                ...modServices,
                value: []
            })
        }
        else {
            setModServices({
                ...modServices,
                value: services.map((mod) => mod.id)
            })
        }

        setSelectAllMod(value);
    }

    const handleSelectAllBE = (value: boolean) => {
        if (!value) {
            setModBE({
                ...modBE,
                value: []
            })
        }
        else {
            setModBE({
                ...modBE,
                value: benefitsPages[0].map((mod) => mod.id)
            })
        }
        setSelectAllBE(value);
    }

    const handleSelectAllTA = (value: boolean) => {
        if (!value) {
            setModTA({
                ...modTA,
                value: []
            })
        }
        else {
            setModTA({
                ...modTA,
                value: taPages[0].map((mod) => mod.id)
            })
        }

        setSelectAllTA(value);
    }

    const handleSelectAllGenders = (value: boolean) => {
        if (!value) {
            setModGenders({
                ...modGenders,
                value: []
            })
        }
        else {
            setModGenders({
                ...modGenders,
                value: genders.map((mod) => mod.id as genderType)
            })
        }

        setSelectAllGenders(value);
    }

    const getTenMod = () => {
        return modalitiesPages[0].slice(0, 11);
    }

    const handleModalities = (id: string) => {
        // if (modModalities.value?.includes(id)) {
        //     setModModalities({
        //         ...modModalities,
        //         value: (modModalities.value || []).filter(comm => comm !== id)
        //     })
        //     return;
        // }

        // setModModalities({
        //     ...modModalities,
        //     value: [...(modModalities.value || []), id]
        // })

        if (modServices.value?.includes(id)) {
            setModServices({
                ...modServices,
                value: (modServices.value || []).filter(comm => comm !== id)
            })
            return;
        }

        setModServices({
            ...modServices,
            value: [...(modServices.value || []), id]
        })
    }

    const handleTA = (id: string) => {
        if (modTA.value?.includes(id)) {
            setModTA({
                ...modTA,
                value: (modTA.value || []).filter(comm => comm !== id)
            })
            return;
        }

        setModTA({
            ...modTA,
            value: [...(modTA.value || []), id]
        })
    }

    const handleBE = (id: string) => {
        if (modBE.value?.includes(id)) {
            setModBE({
                ...modBE,
                value: (modBE.value || []).filter(comm => comm !== id)
            })
            return;
        }

        setModBE({
            ...modBE,
            value: [...(modBE.value || []), id]
        })
    }

    const handleGender = (id: genderType) => {
        if (modGenders.value?.includes(id)) {
            setModGenders({
                ...modGenders,
                value: (modGenders.value || []).filter(comm => comm !== id)
            })
            return;
        }

        setModGenders({
            ...modGenders,
            value: [...(modGenders.value || []), id]
        })
    }

    const onHideServices = (services: string[]) => {
        setModServices({
            ...modServices,
            value: services
        })
        setShowModalModalities(false);
    }

    const onHideGuide = (Advisors: UserInClub[]) => {
        setTeamAdvisors({
            ...teamAdvisors,
            value: Advisors
        })
        setShowModalGuide(false);
    }

    const onHideMembers = (type: 'CANCEL' | 'SUBMIT', individuals: UserInClub[], rosters: string[], codes?: Omit<IClubCode, 'club_id'>[], invites?: number) => {
        if (invites) {
            setInvitesRosters(invites)
        }

        if(type === 'SUBMIT') setInvitesIndividual(individuals.length);
        
        setTeamIndividuallyMembers({
            ...teamIndividuallyMembers,
            value: individuals
        })
        setTeamRosters({
            ...teamRosters,
            value: rosters
        })
        setClubCodes(codes ?? []);
        setShowModalMembers(false);
    }


    const onHideTherapeutic = (newName?: string) => {
        console.log(newName);
        setShowModalTherapeutic(false);
        if (!newName) return;
        setModTACreate({
            ...modTACreate,
            value: [...(modTACreate.value || []), newName]
        })
    }

    const handleDeleteTA = (TA: string) => {
        setModTACreate({
            ...modTACreate,
            value: (modTACreate.value || []).filter((t) => t !== TA)
        })
    }

    const onHideBenefit = (newName?: string) => {
        setShowModalBenefit(false);
        if (!newName) return;
        setModBECreate({
            ...modBECreate,
            value: [...(modBECreate.value || []), newName]
        })
    }

    const handleDeleteBE = (BE: string) => {
        setModBECreate({
            ...modBECreate,
            value: (modBECreate.value || []).filter((t) => t !== BE)
        })
    }

    const copyToClipboard = (toCopy: string) => {
        //Special Code
        navigator.clipboard.writeText(formatLink(toCopy));
        currentSuccess?.setSuccessData(true, true, 'Copied to clipboard');
    }

    useEffect(() => {
        if (edit) loadData();
        else updateFields();
    }, [data, clubInfo, edit, showModal])

    const handleOnBlurDescription = (e: string) => {
        setModDescription({
            ...modDescription,
            value: e
        })
    }

    const onChangeClubTag = (value: string) => {
        setClubTag({
            ...clubTag,
            value: value
        })
        if (!clubTag.value || clubTag.value.length === 0) return;
        setClubTagLoading(true);
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Club.clubTag(value)
            })
            .then((response) => {
                setClubTagTaken(response.statusCode === 200);
            })
            .finally(() => setClubTagLoading(false))
    }

    return (
        <>
            <Modal className="modalSetup modalSetupCommunities" show={showModal}>
                <Modal.Header>
                    <Modal.Title>
                        {(modName.value === '') &&
                            <Tooltip>Start by giving this a name</Tooltip>
                        }
                        <ClubTagWrapper>
                            <InputOnlyPlaceholder
                                value={modName.value}
                                onChange={(e) => setModName({
                                    ...modName,
                                    value: e.currentTarget.value
                                })}
                                placeholder="Give it a name" />
                            {(modName.showError) && (<ErrorField error={modName.error} />)}

                        </ClubTagWrapper>

                        {modName.value !== '' && (<ClubTagWrapper>
                            <IconInput
                                icon={'at'}
                                value={clubTag.value}
                                onChange={(e) => onChangeClubTag(e.currentTarget.value)}
                                placeholder='Give it a tag'
                                loading={clubTagLoading}
                            />
                            {clubTagTaken && <ErrorField error='This tag is already taken' />}
                        </ClubTagWrapper>)}
                        <IconClicker
                            onClick={handleUnsaved}
                            icon="times"
                            size='xl'
                            color={'black'}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs>
                        <Tab theme={{ main: tabActive === 1 && 'tabActive' }} onClick={() => { handleTab(1) }}>About</Tab>
                        <Tab theme={{ main: tabActive === 2 && 'tabActive' }} onClick={() => { handleTab(2) }}>Conditions Treated</Tab>
                        <Tab theme={{ main: tabActive === 3 && 'tabActive' }} onClick={() => { handleTab(3) }}>Benefits</Tab>
                        <Tab theme={{ main: tabActive === 4 && 'tabActive' }} onClick={() => { handleTab(4) }}>Members</Tab>
                    </Tabs>
                    {tabActive === 1 ?
                        <ContainerTabs>
                            <Label>Description<Imp>*</Imp></Label>
                            <TextEditor
                                value={modDescription.value || ''}
                                setValue={handleOnBlurDescription}
                                charLimit={2500}
                                minHeight={120}
                                placeholder={'Please describe the community you are creating..'}
                                showError={modDescription.showError}
                            />
                            <Label>Select additional services</Label>
                            <ListCheckbox>
                                <ItemList onClick={() => handleSelectAllMod(!selectAllMod)}>
                                    <RadioIcon
                                        icon={selectAllMod ? faSquareCheck : faSquare}
                                        size='lg'
                                        color={"black"}
                                    />
                                    Select All
                                </ItemList>
                                <ItemList onClick={() => setNotApplyMod(!notApplyMod)}>
                                    <RadioIcon
                                        icon={notApplyMod ? faSquareCheck : faSquare}
                                        size='lg'
                                        color={"black"}
                                    />
                                    Doesn't apply
                                </ItemList>
                            </ListCheckbox>
                            <ListAreas>
                                {services.map((comm) => (
                                    <Area
                                        isSelected={modServices.value?.includes(comm.id) || false}
                                        onClick={() => handleModalities(comm.id)}>
                                        {comm.name}
                                    </Area>
                                ))}
                            </ListAreas>
                            <SeeAllModalities onClick={() => setShowModalModalities(true)}>See all services</SeeAllModalities>
                            <Label>Genders Allowed<Imp>*</Imp></Label>
                            <ListCheckbox>
                                <ItemList onClick={() => handleSelectAllGenders(!selectAllGenders)}>
                                    <RadioIcon
                                        icon={selectAllGenders ? faSquareCheck : faSquare}
                                        size='lg'
                                        color={"black"}
                                    />
                                    Select All
                                </ItemList>
                                {genders.map((gender) => (
                                    <ItemList>
                                        <RadioIcon
                                            icon={(modGenders.value || []).includes(gender.id as genderType) ? faSquareCheck : faSquare}
                                            size='lg'
                                            color={"black"}
                                            onClick={() => handleGender(gender.id as genderType)}
                                        />
                                        {gender.label}
                                    </ItemList>
                                ))}
                            </ListCheckbox>
                            <WrapperError>
                                {(modGenders.showError) && (<ErrorField error={modGenders.error} />)}
                            </WrapperError>
                        </ContainerTabs>
                        :
                        tabActive === 2 ?
                            <>
                                <ContainerTabs>
                                    <Label>Please, select the conditions your community treats</Label>
                                    <ListCheckbox>
                                        <ItemList onClick={() => handleSelectAllTA(!selectAllTA)}>
                                            <RadioIcon
                                                icon={selectAllTA ? faSquareCheck : faSquare}
                                                size='lg'
                                                color={"black"}
                                            />
                                            Select All
                                        </ItemList>
                                        <ItemList onClick={() => setNotApplyTA(!notApplyTA)}>
                                            <RadioIcon
                                                icon={notApplyTA ? faSquareCheck : faSquare}
                                                size='lg'
                                                color={"black"}
                                            />
                                            Doesn't apply
                                        </ItemList>
                                    </ListCheckbox>
                                    <ListAreas>
                                        {taPages[0].map((comm) => (
                                            <Area
                                                isSelected={modTA.value?.includes(comm.id) || false}
                                                onClick={() => handleTA(comm.id)}>
                                                {comm.name}
                                            </Area>
                                        ))}
                                        {(modTACreate.value || []).map((TA) => (
                                            <AreaDeleteable isSelected={true}>
                                                {TA}
                                                <ClickableIcon
                                                    icon={'times'}
                                                    size='sm'
                                                    color={'black'}
                                                    onClick={() => handleDeleteTA(TA)}
                                                />
                                            </AreaDeleteable>
                                        ))}
                                    </ListAreas>
                                    <SeeAllModalities onClick={() => setShowModalTherapeutic(true)}>
                                        <PlusIcon
                                            icon={faCirclePlus}
                                            size='lg'
                                            color={"var(--absolute-zero)"}
                                        />
                                        Add another area
                                    </SeeAllModalities>
                                </ContainerTabs>
                            </>
                            :
                            tabActive === 3 ?
                                <>
                                    <ContainerTabs>
                                        <Label>What are the benefits?</Label>
                                        <ListCheckbox>
                                            <ItemList onClick={() => handleSelectAllBE(!selectAllBE)}>
                                                <RadioIcon
                                                    icon={selectAllBE ? faSquareCheck : faSquare}
                                                    size='lg'
                                                    color={"black"}
                                                />
                                                Select All
                                            </ItemList>
                                            <ItemList onClick={() => setNotApplyBE(!notApplyBE)}>
                                                <RadioIcon
                                                    icon={notApplyBE ? faSquareCheck : faSquare}
                                                    size='lg'
                                                    color={"black"}
                                                />
                                                Doesn't apply
                                            </ItemList>
                                        </ListCheckbox>
                                        <ListAreas>
                                            {benefitsPages[0].map((comm) => (
                                                <Area
                                                    isSelected={modBE.value?.includes(comm.id) || false}
                                                    onClick={() => handleBE(comm.id)}>
                                                    {comm.name}
                                                </Area>
                                            ))}
                                            {(modBECreate.value || []).map((BE) => (
                                                <AreaDeleteable isSelected={true}>
                                                    {BE}
                                                    <ClickableIcon
                                                        icon={'times'}
                                                        size='sm'
                                                        color={'black'}
                                                        onClick={() => handleDeleteBE(BE)}
                                                    />
                                                </AreaDeleteable>
                                            ))}
                                        </ListAreas>
                                        <SeeAllModalities theme={{ border: '0' }} onClick={() => setShowModalBenefit(true)}>
                                            <PlusIcon
                                                icon={faCirclePlus}
                                                size='lg'
                                                color={"var(--absolute-zero)"}
                                            />
                                            Add another benefit
                                        </SeeAllModalities>
                                    </ContainerTabs>
                                </>
                                :
                                <>
                                    <ContainerTabs>
                                        <LabelIcon theme={{ border: '0' }} onClick={() => setShowModalGuide(true)}>
                                            <IconAdd
                                                icon={faUserPlus}
                                                size='lg'
                                                color={"black"}
                                            />
                                            Add guide<Imp>*</Imp>
                                            <WrapperError>
                                                {(teamAdvisors.showError) && (<ErrorField error={teamAdvisors.error} />)}
                                            </WrapperError>
                                        </LabelIcon>
                                        <WrapperAdvisors>
                                            <BlankTable
                                                headers={['Guide Name', 'Email']}
                                                items={(teamAdvisors.value || []).map((adv) => ({
                                                    id: adv.email,
                                                    labels: [`${adv.firstName} ${adv.lastName}`, adv.email]
                                                }))}
                                                maxWidth={'230px'}
                                            />
                                        </WrapperAdvisors>
                                        <LabelIcon theme={{ border: '0px' }} onClick={() => setShowModalMembers(true)}>
                                            <IconAdd
                                                icon={faUserPlus}
                                                size='lg'
                                                color={"black"}
                                            />
                                            Invite members
                                        </LabelIcon>
                                        {(teamMembers.showError) && (
                                            <WrapperError>
                                                <ErrorField error={teamMembers.error} />
                                            </WrapperError>
                                        )}

                                        {checkMembers() && (
                                            <WrapperMembers>
                                                <LabelInfo>{`Individual members invited: ${invitesIndividual}`}</LabelInfo>
                                                <LabelInfo>{`Members invited by roster: ${invitesRosters}`}</LabelInfo>
                                            </WrapperMembers>
                                        )}
                                        {(clubCodes.length > 0) &&
                                            <>
                                                <LabelInfoBold>Invitation Links</LabelInfoBold>
                                                <BlankTable
                                                    headers={['Link', 'Invites', 'Expiration']}
                                                    items={(clubCodes || []).map((adv) => ({
                                                        id: adv.code,
                                                        labels: [
                                                            formatLink(adv.code),
                                                            `${adv?.max_members || undefined}`,
                                                            adv?.expirationTimestamp ? new Date(adv.expirationTimestamp).toLocaleDateString() : ''
                                                        ]
                                                    }))}
                                                    maxWidth={'230px'}
                                                    actions={['COPY']}
                                                    actionsHandlers={{
                                                        handleCopy: copyToClipboard,
                                                    }}
                                                />
                                            </>
                                        }
                                    </ContainerTabs>
                                </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <ButtonCancel onClick={handleUnsaved}>Cancel</ButtonCancel>
                    {tabActive === 4 ?
                        <WrapperButton>
                            <ButtonSpinner
                                loading={loading}
                                handleClick={handleOnSaveCommunity}
                                text={'Save'}
                                hasIcon={false}
                            />
                        </WrapperButton>
                        :
                        <ButtonSave onClick={handleNext}>
                            Next
                        </ButtonSave>
                    }
                </Modal.Footer>
            </Modal>
            <ModalCommServices
                showModal={showModalModalities}
                onHide={onHideServices}
                services={modServices.value || []}
                selectAll={selectAllMod}
                setSelectAll={setSelectAllMod} />
            <ModalTherapeutic showModal={showModalTherapeutic} onHide={onHideTherapeutic} />
            <ModalBenefit showModal={showModalBenefit} onHide={onHideBenefit} />
            <ModalGuide
                showModal={showModalGuide}
                onHide={onHideGuide}
                advisors={teamAdvisors.value || []} />
            <ModalMembers
                showModal={showModalMembers}
                onHide={onHideMembers}
                individuals={teamIndividuallyMembers.value || []}
                rosters={teamRosters.value || []}
                codes={clubCodes || []} />
            {/* <ModalUnsaved
                show={showUnsavedData}
                onHide={onHideUnsaved}
                content={"Do you want to save your changes?"}
            /> */}
        </>
    )
}

const TitleWrapper = styled.div``

const ClubTagWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    margin-right: 5px;
`;

const LabelName = styled.div`
    font-family: 'Inter';
    font-size: 14px;
    text-align: left;
    padding: 15px 0;
    width: 100%;
    display: flex;
    color: black;

    opacity: 0;
    position: absolute;
    transition: ease-in-out all 0.5s;
`

const Avatar = styled.img`
    width: 35px;
    height: 35px;
    min-width: 35px;
  min-height: 35px;
  max-width: 35px;
  max-height: 35px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: -10px;
    border: 1px solid white;
`;

const HoverAdvisor = styled.div`
    background-color: transparent;
    color: black;
    width: 0px;
    padding: 0px 15px;
    transition: ease-in-out all 0.5s;
    display: flex;

    :hover{
        width: 300px;
    }

    :hover ${LabelName}{
        opacity: 1;
        padding-left: 35px;
        padding-top: 12px;
    }
`;

const SearchIcon = styled(FontAwesomeIcon)`
    font-size: 12px;
    margin-right: 20px;
    color: var(--black);
    margin-left: 20px;
`;

const ListServices = styled.div`
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: end;
`

const TableList = styled.table`
    width: 100%;
    background-color: white;
    border-radius: 8px;
`

const TrNew = styled.tr`
    & td:last-child,
    & th:last-child {
        width: 85px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

const ThNew = styled.th`
    vertical-align: middle;
    height: 40px;
    border-bottom: 1px solid var(--simple-gray-2);
    font-family: 'Inter-Bold';
    font-size: 14px;
    padding: 0 15px;
    text-align: left;
`

const TdNew = styled.td`
    vertical-align: middle;
    height: 40px;
    border-bottom: 1px solid var(--simple-gray-2);
    font-family: 'Inter';
    font-size: 14px; 
    padding: 0 15px; 
    text-align: left;
`

const RowCenter = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const Div_Link = styled.div`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
`

const CopyIcon = styled(SearchIcon)`
    font-size: 16px;
    cursor: pointer;
`

const InputIcon = styled.div`
    display: flex;
    justify-content: space-between;
    width: 567px;
    background-color: white;
    border-radius: 20px;
    align-items: center;
    border: 1px solid var(--simple-gray);
    height: 40px;
    margin: 15px; 
    
    & ${Input}{
        border: 0;
        height: 38px;
    } 
`

const Labelinput = styled.div`
    font-family: 'Inter';
    font-size: 14px;
    text-align: left;
    padding: 15px 0;
    width: 100%;
    display: flex;
`

const LabelInfo = styled(Labelinput)`
    font-weight: 100;
    font-size: 16px;
    margin-left: 20px;
    padding: 0px;
    margin-top: 10px;
`;

const LabelInfoBold = styled(LabelInfo)`
    font-weight: 700 ;
    margin-bottom: 10px;
`;

const WrapperAdvisors = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid var(--simple-gray);
    padding-bottom: 10px;
    margin-top: -2px;
`;

const WrapperMembers = styled.div`
    display: flex;
    width: 100%;
    padding-bottom: 15px;
    margin-top: -2px;
    flex-direction: column;
`;

const IconClicker = styled(FontAwesomeIcon)`
    cursor: pointer;
    margin-left: 10px !important;
    margin-top: 8px;
`;

const WrapperError = styled.div`
    padding: 10px;
`;

const WrapperButton = styled.div`
    width: 50%;
`;

const Table = styled.table`
    width: 100%;
    border: 1px solid black;
    margin: 20px 0px;
`

const Th = styled.th`
    vertical-align: middle;
    height: 40px;
    border: 1px solid;
    font-family: 'Inter';
    font-size: 12px;
`

const Tr = styled.tr`
    &:nth-child(even) {
        background-color: #EEEEEE;
    }

    & td:first-child {
        font-family: 'Inter';
        font-size: 12px;
        color: var(--default-dark);
        text-align: left;
        padding-left: 10px;
    }
`

const Td = styled.td`
    vertical-align: middle;
    height: 40px;
    border: 1px solid;
`

const SeeAllModalities = styled.div`
    font-family: 'Inter-Bold';
    font-size: 14px;
    line-height: 17px;
    color: var(--absolute-zero);
    text-align: left;
    margin: 0 15px;
    border-bottom: ${props => props.theme.border || '1'}px solid gray;
    padding-bottom: 13px;
    width: calc(100% - 33px);
    cursor: pointer;
`

const Tooltip = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    width: 400px;
    background-color: var(--simple-blue-dark-2);
    padding: 8px 0;
    border-radius: 8px;
    color: white;
    margin-right: 12px;
    position: relative;

    &::before{
        content: "";
        width: 0;
        height: 0;
        border-left: 6px solid var(--simple-blue-dark-2);
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        position: absolute;
        right: -6px;
        top: 10px;
    }

`

const ButtonCancel = styled.div`
    width: 49%;
    text-align: center;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 1px solid var(--dark-blue);
    cursor: pointer;
`

const ButtonSave = styled.div`
    width: 49%;
    text-align: center;
    background-color: var(--dark-blue);
    color: white;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 0px;
    cursor: pointer;
`

const ContainerTabs = styled.div`
    overflow: auto;
    height: 75vh;
    padding-bottom: 250px !important;
    padding: 0 24px;
`

const ItemList = styled.div`
    display: flex;
    justify-content: start;
    padding: 3px 0px 3px 0;
    align-items: center;
    font-family: 'Inter';
    font-size: 14px;
    margin-right: 15px;
`

const Label = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    padding: 15px;
    display: flex;
`

const Textarea = styled.div`
    border: 1px solid #747474;
    padding: 0 15px;
    border-radius: 8px;
    font-family: 'Inter';
    margin: 0 15px;
`

const Tabs = styled.div`
    display: flex;
    justify-content: space-evenly;
    border-radius: 8px;
    overflow: hidden;
    padding: 0 24px;
`

const Tab = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    background-color: var(--simple-gray-2);
    width: 33%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: 'Inter';
    cursor: pointer;

    &.tabActive {
        background-color: #E89F4D;
        color: white;
        font-family: 'Inter-Bold';
    }
`

const RadioIcon = styled(FontAwesomeIcon)`
    font-size: 24px;
    margin-right: 15px;
    color: var(--black);
    cursor: pointer;
`;

const CheckIcon = styled(FontAwesomeIcon)`
    font-size: 18px;
    color: var(--absolute-zero);
`;

const PlusIcon = styled(FontAwesomeIcon)`
    font-size: 24px;
    margin-right: 10px;
    
`;



export const InputPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0;
    font-size: 15px;
    font-family: 'Inter';
    background-color: transparent;
`;

export const InputOnlyPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--black);
    padding-left: 20px;
    margin-right: 10px;

    &::placeholder{
        color: var(--black);
        font-family: 'Inter-Bold'
    }
`;

interface PropsArea {
    isSelected: boolean;
}

const Area = styled.div`
    border: 1px solid darkgrey;
    width: fit-content;
    background-color: ${(props: PropsArea) => !props.isSelected ? "white" : "var(--lavender)"};
    padding: 7px 12px;
    border-radius: 5px;
    font-family: ${(props: PropsArea) => !props.isSelected ? "Inter-bold" : "Inter"};
    font-size: 14px;
    cursor: pointer;

    &:hover {
        border: 1px solid gray;
    }
`

const AreaDeleteable = styled.div`
    border: 1px solid darkgrey;
    width: fit-content;
    background-color: ${(props: PropsArea) => !props.isSelected ? "white" : "var(--lavender)"};
    padding: 7px 12px;
    border-radius: 5px;
    font-family: ${(props: PropsArea) => !props.isSelected ? "Inter-bold" : "Inter"};
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;

    &:hover {
        border: 1px solid gray;
    }
`

const ListAreas = styled.div`
    width: 100%;
    margin: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
`

const ListCheckbox = styled.div`
    display: flex;
    margin-left: 15px;
`

const LabelIcon = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    padding: 15px;
    display: flex;
    align-items: center;
    border-bottom: ${props => props.theme.border || '1'}px solid var(--simple-gray-2);
    cursor: pointer;
`

const IconAdd = styled(FontAwesomeIcon)`
    font-size: 14px;
    margin-right: 15px;
    color: black;
`;

export default ModalCommunities;