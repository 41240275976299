import styled from "styled-components";

export const ContainerTab = styled.div`
    padding: 0px 136px;
    width: 100%;
    overflow: auto;
    display: flex;
    display: flex;
    justify-content: center;
`

export const Tabs = styled.div`
    display: flex;
    margin-top: 90px;
    width: 100%;
    justify-content: center;
    gap: 3px;
    padding: 0px 136px;
`
export const Tab = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    background-color: #eeeeee;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 6px 0;
    color: #333;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0.2px;
    cursor: pointer;

    :first-child {
        border-radius: 8px 0px 0px 8px;
    }
    :last-child {
        border-radius: 0px 8px 8px 0px;
    }

    &.tabActive{
        background-color: #E89F4D;
        font-family: Inter-Bold;
        color: white;
    }
`

export const PrincipalContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    background-color: white;

    a {
        display: contents;
        text-decoration: none;
    }
`;