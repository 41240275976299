import { AnyAction } from "redux";
import {
  GET_YO_REP_ERROR,
  GET_YO_REP_SUCCESS,
  GET_YO_TYPES_ERROR,
  GET_YO_TYPES_SUCCESS,
  START_GET_YO_REP,
  START_GET_YO_TYPES,
} from "../constants/yoconstants";

export interface YOBaseInformation {
  code: string;
  title: string;
}

export interface YOType extends YOBaseInformation {}
export interface YORep extends YOBaseInformation {
  index: number,
}

interface YOState {
  loadingYOTypes: boolean;
  YOTypesError?: any;
  YOTypes: YOType[];

  loadingYORep: boolean;
  YORepError?: any;
  YORep: YORep[];
}

const initialState: YOState = {
  loadingYOTypes: false,
  YOTypesError: undefined,
  YOTypes: [],

  loadingYORep: false,
  YORepError: undefined,
  YORep: [],
};

export const getYOReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_GET_YO_TYPES:
      return {
        ...state,
        loadingYOTypes: true,
      };
    case GET_YO_TYPES_SUCCESS:
      return {
        ...state,
        loadingYOTypes: false,
        YOTypes: action.payload,
      };
    case GET_YO_TYPES_ERROR:
      return {
        ...state,
        loadingYOTypes: false,
        YOTypesError: action.payload,
      };

    case START_GET_YO_REP:
      return {
        ...state,
        loadingYORep: true,
      };
    case GET_YO_REP_SUCCESS:
      return {
        ...state,
        loadingYORep: false,
        YORep: action.payload,
      };
    case GET_YO_REP_ERROR:
      return {
        ...state,
        loadingYORep: false,
        YORepError: action.payload,
      };
    
    default:
      return state;
  }
};
