import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const WrapperMenuHome = styled.div.attrs(
  props => ({
      className: props.theme.main,
  })
)`
  display: flex;
  width: 100vw;
  height: 0vh;
  gap: 40px;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: var(--default-menu);
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;

  -webkit-transition: all 0.5s ease 0.5s;
  -moz-transition: all 0.5s ease 0.5s;
  -o-transition: all 0.5s ease 0.5s;
  -ms-transition: all 0.5s ease 0.5s;
  transition: all 0.5s ease 0.5s;

  &.menuActive{
    height: ${({ active }: { active: boolean }) => active ? "100vh" : "0vh"};
  }
  
`;

const logoVibe = require("../../../assets/img/logoVibe_white.png");
export const LogoHeaderMenuHome = styled.div`
  width: 126px;
  height: 56px;
  background-image: url("${logoVibe}");
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const HeaderMenuHome = styled.div`
  display: flex;
  width: 100%;
  height: 120px;
  padding: 56px 136px 16px 136px;
  align-items: center;
  gap: 16px;
  border-bottom: 0px;
  justify-content: space-between;
  background-color: transparent;

  @media only screen and (max-width: 770px) {
    padding: 15px;
    height: 70px;

    & ${LogoHeaderMenuHome}{
      height: 40px;
      width: 95px;
    }
  }
`;



export const OptionsMenuHome = styled.div`
  display: flex;
  padding: 0px 0px 0px 234px;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  gap: 40px;
  overflow: scroll;
  width: 100%;

  color: var(--content-tertiary);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Inter';
  font-size: 64px;
  font-style: normal;
  font-weight: 300;
  line-height: 64px;
  letter-spacing: 0.1px;

  @media only screen and (max-width: 770px) {
    padding: 0px;
    gap: 10px;
  }
`;

export const IconArrow = styled(FontAwesomeIcon)`
cursor: pointer;
`;

export const OptionMenuHome = styled.div`
  display: flex;
  padding: 6px 24px;
  align-items: center;
  text-align: left;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  font-size: 58px;
  background: 
    radial-gradient(
     200px at 10px 10px, #3498db, #000
    );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  -webkit-transition: all 0.2s ease 0.2s;
  -moz-transition: all 0.2s ease 0.2s;
  -o-transition: all 0.2s ease 0.2s;
  -ms-transition: all 0.2s ease 0.2s;
  transition: all 0.2s ease 0.2s;

  & svg{
    -webkit-transition: all 0.2s ease 0.2s;
    -moz-transition: all 0.2s ease 0.2s;
    -o-transition: all 0.2s ease 0.2s;
    -ms-transition: all 0.2s ease 0.2s;
    transition: all 0.2s ease 0.2s;
    width: 0px;
    height: 40px;
    opacity: 0;
    font-size: 0px;
  }

  &:hover{
    font-family: 'Inter-Bold';
  }

  &:hover svg{
    opacity: 1;
    width: 40px;
    margin-right: 40px;
  }

  @media only screen and (max-width: 770px) {
    padding: 0px 24px;
  }
`;

export const FooterMenu = styled.div`
  flex-direction: column;
  width: 100%;
  width: calc(100% - 30px);
  margin: 0 auto;
  padding: 13px 0;
  border-top: 1px solid #5d6875;
  gap: 8px;
  display: none;
  @media only screen and (max-width: 770px) {
    display: flex;
  }
`

export const SocialMedia = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
`

export const IconMedia = styled.img`
  width: 24px;
  color: white;
  filter: invert(1);
`
export const LinksPPTC = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  gap: 10px;
  color: #7E8B99;
`
export const LinkP = styled.div`
  color: #7E8B99;
  font-family: Inter;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
`
export const TextC = styled.div`
  color: #7E8B99;
  font-family: Inter;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.2px;
  `
