import { IconName } from "@fortawesome/fontawesome-svg-core";
import { IModalityCard } from "../../components/BaseComponents/HomeCards/ModalityCard";
import { ICommunityHomeCard } from "../../components/BaseComponents/HomeCards/CommunityHomeCard";
import { EventCardInterface } from "../../components/BaseComponents/Events/EventCard";
import { IHealerCard } from "../../components/BaseComponents/HomeCards/HealerCard";
import { GuideInfoExtended } from "@vibe/sdk/interfaces/Club/ClubInterface";

export type ItemFilterType = 'BEN' | 'DEL' | 'LOC' | 'DATE' | 'FEE';
export interface SelectedItemHome {
    value: string;
    label: string;
}
export default interface ItemFilterHome {
    id: number;
    icon: IconName;
    dropdown: boolean;
    label: string;
}

export interface IPanelRightHome {
    title: string
    type: ActivePanelType
    healers: number // Can be interested and followers for Healer and Event 
    seekers: number // Can be attending and members for Healer and Event 
    aliases: string[]
    description: string
    route: string

    avatar?: string
    guide?: GuideInfoExtended
    posts?: number
    lastPost?: Date
}

export const mockPanelRightData: IPanelRightHome = {
    title: '',
    type: 'MOD',
    healers: 0,
    seekers: 0,
    aliases: [],
    description: '',
    route: '',
}

export interface ItemFilterHook extends ItemFilterHome {
    options: SelectedItemHome[];
}

export type ActivePanelDataType = IModalityCard | ICommunityHomeCard | EventCardInterface | IHealerCard;
export type ActivePanelType = 'MOD' | 'COMM' | 'TP' | 'PO' | 'EVENT';