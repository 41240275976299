import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import Introduction from '../../components/signupsetuphealer/Introduction';
import InternalTeams from '../../components/signupsetuphealer/InternalTeams';
import Communities from '../../components/signupsetuphealer/Communities';
import Summary from '../../components/signupsetuphealer/Summary';
import Column2 from "../../components/signupsetuphealer/Column2"
import Footer from "../../components/signupsetuphealer/Footer"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalUnsaved from '../../components/BaseComponents/Modals/ModalUnsaved';
import { getTCommsByCountry } from '../../store/actions/TClubsActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { getClubs, getCommunities } from '../../store/actions/clubActions';
import Services from '../../components/signupsetuphealer/Services';
import { getHealerServices } from '../../store/actions/modalityActions';
import IPaymentInfo from '../../interfaces/user/IPaymentINfo';
import PaymentTab from '../../components/signupsetuphealerind/Payment';
import { Container_Signup, Logo } from '../Signup/WrapBusiness';
import { getEmailsSetup } from '../../store/actions/inviteActions';
import HeaderSetup from '../SetupSeeker/HeaderSetup';
import { ContainerSetup_v2, Tabs_Setup } from '../SetupSeeker/SetupSeeker';
import Tab_Setup from '../SetupSeeker/Tab_Setup';
import { ChatServiceInterface } from '../../interfaces/chat/ChatServiceInterface';
import { IUserGradeResponse } from '@vibe/sdk/interfaces/Admin/User';

export type ProcessType = "off" | "loading" | "continue";
export interface ProcessInterface {
    process: ProcessType;
    setProcess: (value: ProcessType) => void;
    step: number;
    setTabsProcessed: (value: number[]) => void;
    tabsProcessed: number[];
    setNewData: (value: boolean) => void;
}

export interface TabsInterface {
    id: number;
    label: string;
}

const tabsHealer: TabsInterface[] = [
    {
        id: 1,
        label: 'Introduction',
    },
    {
        id: 2,
        label: 'Internal Teams',
    },
    {
        id: 3,
        label: 'Services',
    },
    {
        id: 4,
        label: 'Communities',
    },
    {
        id: 5,
        label: 'Summary',
    },
]

const tabsHealerUpdate: TabsInterface[] = [
    {
        id: 1,
        label: 'Introduction',
    },
]

const SetupHealer = ({
    setShowSetupUser,
    setShowSetupOrg,
    isUpdate = false,
    ChatServiceInstance,
}: {
    setShowSetupUser: (value: boolean) => void
    setShowSetupOrg: (value: boolean) => void
    isUpdate?: boolean
    ChatServiceInstance?: ChatServiceInterface
}) => {

    const dispatch = useDispatch();

    const organization = useSelector((state: RootState) => state.getOrgs.organizationInfo);
    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
    const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);
    const userPayment: IPaymentInfo = useSelector((state: RootState) => state.getUser.paymentInfo);

    const [tabActive, setTabActive] = useState<number>(1);
    const [process, setProcess] = useState<ProcessType>('off');
    const [tabsProcessed, setTabsProcessed] = useState<number[]>([]);
    const [newData, setNewData] = useState<boolean>(false);
    const [showUnsavedData, setShowUnsavedData] = useState<boolean>(false);
    const [tabClicked, setTabClicked] = useState<number>(1);

    useEffect(() => {
        if (
            user && userPayment && tabsHealer.length === 5 &&
            user.organizations.length === 1 && !userPayment.hasActiveSubscription
        ) {
            tabsHealer.push({
                id: 6,
                label: 'Payment'
            })
        }
    }, [user, userPayment])

    const getProcessData = (): ProcessInterface => {
        return {
            process,
            setProcess,
            step: tabActive,
            tabsProcessed,
            setTabsProcessed,
            setNewData
        }
    }

    const getClassNameTab = (tab: number) => {
        if (tabActive === tab) return 'tabActive';
        if (tabsProcessed.includes(tab)) return 'processed';
        return '';
    }

    const handleTab = (tab: number) => {
        if (newData) {
            setShowUnsavedData(true);
            setTabClicked(tab)
            return;
        }
        setTabActive(tab);
    }

    const onHideUnsaved = (type = 'close') => {
        switch (type) {
            case 'close':
                setShowUnsavedData(false);
                break;

            case 'discard':
                setShowUnsavedData(false);
                setNewData(false);
                setTabActive(tabClicked);
                break;

            case 'save':
                setShowUnsavedData(false);
                setProcess('loading');
                break;

            default:
                setShowUnsavedData(false);
                break;
        }
    }

    useEffect(() => {
        setProcess('off');
    }, [tabActive])

    useEffect(() => {
        if (process === 'continue') setNewData(false);
    }, [process])

    useEffect(() => {
        if (organization) {
            dispatch(getTCommsByCountry(organization?.countryCode));
            dispatch(getCommunities(organization.id, organization.status.startsWith('PE')));
            dispatch(getHealerServices(userInfo?.username || ''));
            dispatch(getClubs({
                pending: organization.status.startsWith('PE'),
                org_id: organization.id,
                club_type: 'CLUB',
            }));
            dispatch(getEmailsSetup(organization.id, organization.status.startsWith('PE')));
        }
    }, [organization])

    return (
        <ContainerSetup_v2>
            <HeaderSetup ChatServiceInstance={ChatServiceInstance} />
            <Container_Signup>
                <Column1_Setup>
                    <Content_Signup>
                        <Tabs_Setup>
                            {(isUpdate ? tabsHealerUpdate : tabsHealer).map((tab) => (
                                <Tab_Setup
                                    onClick={() => handleTab(tab.id)}
                                    text={tab.label}
                                    active={getClassNameTab(tab.id) === 'tabActive'}
                                    processed={getClassNameTab(tab.id) === 'processed'}
                                />
                            ))}
                        </Tabs_Setup>

                        {tabActive === 1 ?
                            <Introduction processData={getProcessData()} isUpdate={isUpdate} />
                            :
                            tabActive === 2 ?
                                <InternalTeams processData={getProcessData()} />
                                :
                                tabActive === 3 ?
                                    <Services processData={getProcessData()} />
                                    :
                                    tabActive === 4 ?
                                        <Communities processData={getProcessData()} />
                                        : tabActive === 5 ?
                                            <Summary
                                                handleTabActive={setTabActive}
                                                processData={getProcessData()}
                                            />
                                            :
                                            <PaymentTab
                                                processData={getProcessData()}
                                                handleTabActive={setTabActive}
                                            />
                        }
                    </Content_Signup>
                    <Footer
                        tabActive={tabActive}
                        handleTabActive={setTabActive}
                        setShowSetupUser={setShowSetupUser}
                        setShowSetupOrg={setShowSetupOrg}
                        setProcess={setProcess}
                        process={process}
                        isUpdate={isUpdate}
                    />
                    <ModalUnsaved
                        show={showUnsavedData}
                        onHide={onHideUnsaved}
                        content={"Do you want to save your changes?"}
                    />
                </Column1_Setup>

                <Column2 tabActive={tabActive} isUpdate={isUpdate} />
            </Container_Signup>
        </ContainerSetup_v2>
    )
}

export const Column1_Setup = styled.div`
    width: 100%;
    height: calc(100vh - 94px);
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 56px;
    grid-row-gap: 10px;
`

export const Content_Signup = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  padding: 0px 16px 0px 16px;
`;

export default SetupHealer