export const START_GET_TCLUBS = 'START_GET_TCLUBS';
export const GET_TCLUBS_SUCCESS = 'GET_TCLUBS_SUCCESS';
export const GET_TCLUBS_ERROR = 'GET_TCLUBS_ERROR';

export const START_GET_TCOMM = 'START_GET_TCOMM';
export const GET_TCOMM_SUCCESS = 'GET_TCOMM_SUCCESS';
export const GET_TCOMM_ERROR = 'GET_TCOMM_ERROR';

export const START_GET_TTEAM = 'START_GET_TTEAM';
export const GET_TTEAM_SUCCESS = 'GET_TTEAM_SUCCESS';
export const GET_TTEAM_ERROR = 'GET_TTEAM_ERROR';


export const LOGOUT_TCLUBS = 'LOGOUT_TCLUBS';
