import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from "styled-components";
import {
    Card, InfoProfile, TitleCard, ButtonSeeAll
} from '../HealerProfile/components'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import EventCard, { EventCardInterface } from '../BaseComponents/Events/EventCard';
import { EventsResponse } from "@vibe/sdk/dist/interfaces/Events/getEvent";
import FiltersEvents from '../BaseComponents/Events/FiltersEvents';
import ModalComingSoon from '../BaseComponents/Modals/ModalComingSoon';
import { mapEventToCard } from '../../hooks/Events/useEventsPanel';
import { getEventLimitTime, getEvents, getHealerIndEvents, getHealerOrgEvents } from '../../store/actions/eventActions';
import Filter, { IEventFilter, MockEventFilter } from '../BaseComponents/Filter';

const index = ({ showInfo }: { showInfo: boolean }) => {
    const dispatch = useDispatch();

    const events: EventsResponse[] = useSelector((state: RootState) => state.getEvent.events);
    const userProfile = useSelector((state: RootState) => state.getUser.userProfile);
    const username = useSelector((state: RootState) => state.getUser.userInfo?.username || '');
    const healerOrgEvents = useSelector((state: RootState) => state.getEvent.healerOrgEvents ?? []);
    const healerIndEvents = useSelector((state: RootState) => state.getEvent.healerIndEvents ?? []);
    const healerEvents = useMemo(() => userProfile?.roles?.includes('INDIVIDUAL') ? healerIndEvents : healerOrgEvents, [userProfile, healerIndEvents, healerOrgEvents]);

    const [tabActive, setTabActive] = useState<number>(1);
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [filteredModality, setFilteredModality] = useState<string>('');
    const [delivery, setDelivery] = useState<'online' | 'mixed' | 'inPerson' | 'All'>('All');
    const [showComing, setShowComing] = useState<boolean>(false);
    const [showAllEvent, setShowAllEvent] = useState<boolean>(false)

    const [eventFilter, setEventFilter] = useState<IEventFilter>({ ...MockEventFilter });

    const eventsFiltered: EventCardInterface[] = useMemo(() => {
        return healerEvents.map(mapEventToCard).filter(
            (ev: EventCardInterface) =>
              (!eventFilter.cityID || ev.city_id === eventFilter.cityID) &&
              (!eventFilter.countryID || ev.country_id === eventFilter.countryID) &&
              (!eventFilter.stateID || ev.state_id === eventFilter.stateID) &&
              (!eventFilter.typeID || ev.typeID === eventFilter.typeID) &&
              (!eventFilter.start_date || ev.start.getTime() >= eventFilter.start_date.getTime()) &&
              (!eventFilter.delivery || ev.delivery === eventFilter.delivery) &&
              (!eventFilter.modID || (ev.modalitiesIDs || []).includes(eventFilter.modID)))
    }, [healerEvents, eventFilter]);

    const getEventsList = (e: boolean) => {
        const result: any = [];

        // console.log(showAllEvent)
        for (let i = 0; i < eventsFiltered.length; i++) {
            if (i === 3 && !e) {
                result.push(
                    <ButtonSeeAll onClick={() => { setShowAllEvent(true) }}>
                        See all
                    </ButtonSeeAll>
                );
                break;
            }
            else {
                result.push(
                    <EventCard data={eventsFiltered[i]} reload={reload} />
                );
            }
        }

        return result;
    }

    // const reload = () => {
    //     if (!startDate)
    //         dispatch(getEvents({
    //             org_id: userProfile.organizations[0],
    //             creator: userProfile.username,
    //             modality: filteredModality,
    //         }, username, {
    //             ignoreLimitTime: 'true',
    //         }));
    //     else
    //         dispatch(getEvents({
    //             org_id: userProfile.organizations[0],
    //             creator: userProfile.username,
    //             modality: filteredModality,
    //         }, username, {
    //             limitTime: getEventLimitTime(startDate),
    //         }));
    // }

    const reload = () => {
        if (userProfile.roles.includes('INDIVIDUAL')) {
            dispatch(getHealerIndEvents(userProfile.username, username, startDate ? {
                limitTime: getEventLimitTime(startDate),
            } : undefined));
        } else {
            dispatch(getHealerOrgEvents(userProfile.organizations[0], username, startDate ? {
                limitTime: getEventLimitTime(startDate),
            } : undefined));
        }
    }

    useEffect(() => {
        reload();
    }, [startDate, filteredModality]);

    return (
        <ColumnRight theme={{ main: showInfo ? 'flex' : 'none' }}>
            <WrapperCard
                onMouseEnter={() => setShowComing(true)}
                onMouseLeave={() => setShowComing(false)}
            >
                <TitleCard>
                    Events
                    {process.env.REACT_APP_SHOW_EVENT === 'true' ? (
                        // <FiltersEvents
                        //     filterActive={tabActive}
                        //     setFilterActive={setTabActive}
                        //     startDate={startDate}
                        //     setStartDate={setStartDate}
                        //     setFilteredModalityID={setFilteredModality}
                        //     setFilterDelivery={setDelivery}
                        //     modalities={modalities}
                        // />
                        <Filter from={'PROFILE'} type={'EV'} eventFilter={eventFilter} setEventFilter={setEventFilter} />
                    ) : (
                        <ComSon>Coming soon</ComSon>
                    )}
                </TitleCard>

                {process.env.REACT_APP_SHOW_EVENT === 'true' && (
                    <WrapperEvents>
                        {getEventsList(showAllEvent)}
                    </WrapperEvents>
                )}
            </WrapperCard>
        </ColumnRight>
    )
}

const WrapperCard = styled(Card)`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
`;

const ComSon = styled.div`
    font-family: 'Inter';
    font-size: 10px;
    width: 70px;
    height: 18px;
    border: 1px solid var(--electric-yellow-2);
    border-radius: 5px;
    padding: 0px 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

const WrapperEvents = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: scroll;
    max-height: calc(100vh - 160px);
`;

const ColumnRight = styled.div`
    width: 25%;

    & ${InfoProfile}{
        margin-top: 20px
    }

    @media only screen and (max-width: 900px) {
        width: 100%;
        display: ${props => props.theme.main || 'none'};
    }
    
`

export default index;

const e = {
    attending: 1,
    cover: "https://roster-v1-roster-images-vibe-development.s3.amazonaws.com/event_cover/1692973221099/event_coversofiak_po.undefined",
    delivery: "mixed",
    description: "Come with your puppy and grow up together with pilates.",
    id: "1692973223911",
    interested: 0,
    link: "https://theonevalley.zoom.us/j/2208443939?_x_zm_rtaid=vs6lUAuUTuaBnotRyZh_pA.1683668017765.83b0bb7f8b304da92ef921ef3bf37bef&_x_zm_rhtaid=417#success",
    location: "Calle 116 #50-23 - online guided session",
    name: "Cat Pilates",
    rsvp: "Going",
    startDate: "Fri Aug 25 2023",
    startTime: "09:30",
    guide: {
        username: 'sofiak_po',
        name: 'Sofia Kappa',
        avatar: 'https://images.pexels.com/photos/3992656/pexels-photo-3992656.png?auto=compress&cs=tinysrgb&w=800'
    }
}