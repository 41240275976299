import styled from "styled-components";

export const WrapperModality = styled.div`
    height: calc(100vh - 120px);
    background-color: var(--background-default);
    // display: grid;
    // grid-template-columns: 1fr 322px;
    // grid-template-rows: 1fr;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100vw;
    padding: 12px 136px;
    justify-items: flex-start;

    color: var(--default-dark);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;

    @media screen and (max-width: 900px){
        margin-top: 60px;
        padding: 10px 20px;
        height: calc(100vh - 60px);
    }
`;

export const InfoModality = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin-top: 30px;
    width: 100%;

    @media screen and (max-width: 900px){
        display: flex;
    }
`
export const InfoDetail = styled.div`
    border-right: 1px solid var(--border-default);
    width: 100%;
    
`
export const TabsMembers = styled.div`
    padding: 0px 2px;
    @media screen and (max-width: 900px){
        display: none;
    }
`;

export const Header = styled.div`
    color: var(--default-dark);
    font-family: Inter-SemiBold;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.1px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
`
export const Title = styled.div``

export const ToolTip = styled.div`
  position: absolute;
  font-family: 'Inter';
  font-size: 10px;
  width: 77px;
  height: 21px;
  border: 1px solid var(--electric-yellow-2);
  border-radius: 3px;
  padding: 0px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  bottom: 100%;
  left: 0%;
  z-index: 5;
`

export const Tabs = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    display: flex;
    gap: 16px;
    border-bottom: 1px solid var(--border-default);

    @media screen and (max-width: 900px){
        display: none;
    }
`;

export const TabsEvents = styled(Tabs)`
    padding-left: 10px;
`;

export const TabsMobile = styled(Tabs)`
    display: none;

    @media screen and (max-width: 900px){
        display: flex;
        overflow-x: scroll;
        overflow-y: visible;
        width: 100%;
    }


`;

export const Tab = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    
    padding: 2px 0; 
    font-size: 16px;  
    color: black;
    cursor: pointer;
    position: relative;
    width: fit-content;
    white-space: nowrap;
    overflow: visible;
  
    &.active{
      font-family: 'Inter-SemiBold';
      border-bottom: 2px solid orange;
      color: black;
    }
  
    &.inactive{
        font-family: 'Inter';
        border-bottom: 0px solid orange;
    }

    @media screen and (max-width: 900px){
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        & ${ToolTip}{
            position: static;
        }
    }

    
        
`

export const InfoTabActive = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: left;
    margin: 16px 0;
    overflow: auto;
    height: calc(100vh - 300px);
    padding-right: ${({isUpdate = false}:{isUpdate?: boolean}) => isUpdate ? '10px': '0px'};

    @media screen and (max-width: 900px){
        height: calc(100vh - 200px);
    }
`

export const InfoComm = styled.div`
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 200px);
    overflow: scroll;
    padding-top: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fit,
      minmax(315px, 350px));
    grid-template-rows: repeat(auto-fit, minmax(180px, 350px));
    overflow: scroll;
    grid-row-gap: 15px;
    grid-column-gap: 15px;

    @media only screen and (max-width: 500px) {
        grid-template-columns: 1fr;
    }
`;

export const Descp = styled.div`
    letter-spacing: 0.1px;
`

export const BtnReadMore = styled.div`
    font-family: 'Inter-SemiBold';
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
`

export const List = styled.div`
    font-size: 14px;
    line-height: 24px;
`

export const Tags = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    display: flex;
    flex-direction: column;
    gap: 12px;

    &.tgAlianses ${List}{
        border-right: 1px solid black;
        padding-right: 10px;
    }

    &.tgPractices ${List}{
        background-color: #e2e2e5;
        text-align: center;
        padding: 0 5px;
        border-radius: 5px;
    }

    &.tgBenefits ${List}{
        background-color: var(--simple-green-light);
        text-align: center;
        padding: 0 5px;
        border-radius: 5px;
    }

    &.tgAreas ${List}{
        background-color: #f5e5c4;
        text-align: center;
        padding: 0 5px;
        border-radius: 5px;
    }

`

export const TitleTag = styled.div`
    font-size: 16px;
    font-family: 'Inter-SemiBold';
    letter-spacing: 0.1px;
`

export const ListTags = styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
`

export const TabsM = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    gap: 10px;
    padding: 0px 2px;
`

export const CardMembers = styled.div`padding: 6px 12px`

export const Card = styled.div`
    border: 1px solid #cdcdcd;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: ${props => props.theme.width || '100%'};
`
export const HeaderCard = styled.div`
    width: 100%;
    display: flex;
    gap: 12px;
    align-items: center;
    text-align: left;
`
export const Avatar = styled.img`
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    max-width: 60px;
    max-height: 60px;
    border-radius: 50%;
    object-fit: cover;
`
export const InfoMember = styled.div`
    color: var(--simple-brown-dark);
    font-family: Inter-Bold;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.1px;
`
export const Location = styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
    font-family: 'Inter';
    font-size: 14px;
`
export const SocialMedia = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`
export const IconMedia = styled.img`
    width: 17px;
    height: 17px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    cursor: pointer;
`
export const TagsM = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: left;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--mountain-mist);
`
export const Descrpt = styled.div`
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
`
export const CountMedia = styled.div`
    display: flex;
    gap: 12px;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--mountain-mist);
`
export const CountS = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

export const IconUser = styled.img`
    width: 18px;
    height: 18px;
    object-fit: contain;
`;

export const HeaderComm = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const TitleGuide = styled.div`
    margin-right: 15px;
`

export const ListGuide = styled.div`
    color: var(--simple-brown-dark);
    text-align: center;
    font-family: Inter-SemiBold;
    font-size: 16px;
    line-height: 28px;
    -webkit-letter-spacing: 0.1px;
    -moz-letter-spacing: 0.1px;
    -ms-letter-spacing: 0.1px;
    letter-spacing: 0.1px;
    display: flex;
    align-items: center;
`
export const TitleComm = styled.div`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
            line-clamp: 1; 
    -webkit-box-orient: vertical;
    color: var(--absolute-zero);
    font-family: Inter-Bold;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.1px;
`
export const Guide = styled.div`
    width: 32px;
    height: 32px;
    margin-left: -8px;
    border-radius: 50%;
    background-color: ${props => props.theme.bgcolor || 'black'};
    color: ${props => props.theme.color || 'white'};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
`

export const GuideI = styled.img`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: contain;
    margin-left: -8px;
`

export const Counts = styled.div`
    display: flex;
    gap: 12px;
`
export const User = styled.div`
    color: var(--simple-brown-dark);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
    gap: 10px;
`
export const AvatarU = styled.img`
    width: ${props => props.theme.width || '20px'};
    height: ${props => props.theme.height || '20px'};
    border-radius: 50%;
    object-fit: contain;
`
