
import { AnyAction } from 'redux';
import { ACTIVE_CITIES_ERROR, ACTIVE_CITIES_SUCCESS, ACTIVE_COUNTRIES_ERROR, ACTIVE_COUNTRIES_SUCCESS, CITY_ERROR, CITY_SUCCESS, COUNTRY_ERROR, COUNTRY_SUCCESS, LOCATION_ERROR, LOCATION_SUCCESS, LOGOUT_LOCATION, START_ACTIVE_CITIES, START_ACTIVE_COUNTRIES, START_CITY, START_COUNTRY, START_LOCATION, START_TIMEZONE, TIMEZONE_ERROR, TIMEZONE_SUCCESS } from '../constants/locationConstants';
import LocationStateInterface from '../../interfaces/store/locationState';

const initialState: LocationStateInterface = {
    loadingActiveCountries: false,
    activeCountries: [],
    loadingActiveCities: false,
    activeCities: [],
    loadingLocation: false,
    loadingTimezones: false,
    timezones: [],
    loadingCity: false,
    loadingCountry: false
};

export const getLocationReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case START_ACTIVE_CITIES:
            return {
                ...state,
                loadingActiveCities: true,
            }
        case ACTIVE_CITIES_SUCCESS:
            return {
                ...state,
                loadingActiveCities: false,
                activeCities: action.payload,
            }

        case ACTIVE_CITIES_ERROR:
            return {
                ...state,
                loadingActiveCities: false,
                activeCountriesError: action.payload,
            }

        case START_ACTIVE_COUNTRIES:
            return {
                ...state,
                loadingActiveCountries: true,
            }
        case ACTIVE_COUNTRIES_SUCCESS:
            return {
                ...state,
                loadingActiveCountries: false,
                activeCountries: action.payload,
            }

        case ACTIVE_COUNTRIES_ERROR:
            return {
                ...state,
                loadingActiveCountries: false,
                activeCountriesError: action.payload,
            }

        case START_LOCATION:
            return {
                ...state,
                loadingLocation: true,
            }

        case LOCATION_SUCCESS:
            return {
                ...state,
                loadingLocation: false,
                location: action.payload,
            }

        case LOCATION_ERROR:
            return {
                ...state,
                loadingLocation: false,
                locationError: action.payload,
            }

        case START_TIMEZONE:
            return {
                ...state,
                loadingTimezones: true,
            }

        case TIMEZONE_SUCCESS:
            return {
                ...state,
                loadingTimezones: false,
                timezones: action.payload,
            }

        case TIMEZONE_ERROR:
            return {
                ...state,
                loadingTimezones: false,
                timezonesError: action.payload,
            }

        case START_CITY:
            return {
                ...state,
                loadingCity: true,
            }

        case CITY_SUCCESS:
            return {
                ...state,
                loadingCity: false,
                cities: action.payload,
            }

        case CITY_ERROR:
            return {
                ...state,
                loadingCity: false,
                cityError: action.payload,
            }

        case START_COUNTRY:
            return {
                ...state,
                loadingCountry: true,
            }

        case COUNTRY_SUCCESS:
            return {
                ...state,
                loadingCountry: false,
                countries: action.payload,
            }

        case COUNTRY_ERROR:
            return {
                ...state,
                loadingCountry: false,
                countryError: action.payload,
            }

         case LOGOUT_LOCATION:
            return initialState;

        default:
            return state;
    }
};
