import React, { useEffect, useState, useContext, useRef } from 'react'
import styled, { keyframes } from "styled-components";
import OutsideClickHandler from "react-outside-click-handler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronDown, faCalendar, faEye, faEyeSlash, faArrowLeft, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import { Row1, Row2, Imp } from "../Website/Form"
import { BaseFieldState, getFieldState } from '../../components/BaseComponents/utils';
import useActiveCountry, { CountryOption } from '../../hooks/Locations/useActiveCountry';
import { ISignUpOrganizationBody } from '@vibe/sdk/dist/interfaces/AccessManager/signUpOrg';
import { orgSignup } from '../../store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { orgType } from '@vibe/sdk/dist/interfaces/Admin/getOrganizations';
import { genderType } from '@vibe/sdk/dist/interfaces/AccessManager/signUpUser';
import useActiveState, { StateOption } from '../../hooks/Locations/useActiveState';
import useGenders, { GenderOption } from '../../hooks/users/useGenders';
import ErrorField from '../../components/BaseComponents/ErrorField';
import { isValidBirthdate, isValidUsername, validatePassword, isValidZipCode } from '../../helpers/userHelper';
import PasswordToolTip from '../../components/Website/PasswordToolTip';
import EmailSent from '../../components/Website/EmailSent';
import SelectItem from '../../interfaces/SelectItem';
import SignupHealerInd from './SignupHealerInd';
import ButtonSpinner from '../../components/BaseComponents/ButtonSpinner';
import DatePicker from "react-datepicker";
import SelectorCountry from '../../components/BaseComponents/Location/SelectorCountry';
import SelectorState from '../../components/BaseComponents/Location/SelectorState';
import ModalSeeWhat from '../../components/Home/Pricing/ModalSeeWhat';
import { ContextSuccess } from "../../Context";
import { RootState } from '../../store/store';
import { checkOrgWebsite } from '../../helpers/signupHelper';
import { PostalCodeClean } from '@vibe/sdk/dist/interfaces/Roster/postalCodes';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';

export const getFiltered = (listName: SelectItem[], itemSearch: string) => {
    return listName.filter((item: SelectItem) => {
        if (!itemSearch) return true;

        if (item.label && (
            item.label.toUpperCase().includes(itemSearch) ||
            item.label.toLowerCase().includes(itemSearch) ||
            item.label.includes(itemSearch)
        )) {
            return true;
        }

        return false;
    })
}

const SignupHealer = () => {
    const dispatch = useDispatch();
    const currentSuccess = useContext(ContextSuccess);
    const datepickerRef = useRef<any>(null);

    const createOrgError = useSelector((state: RootState) => state.getUser.orgCreatedError);

    const { activeCountriesReactSelect } = useActiveCountry();
    const { activeStatesReactSelect } = useActiveState();
    const { GendersReactSelect } = useGenders();

    const [selectOption, setSelectOption] = useState('')
    const [step, setStep] = useState(0)

    const [emailSent, setEmailSent] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [showModalSeeMore, setShowModalSeeMore] = useState(false)
    const [showOptionsCountry, setShowOptionsCountry] = useState(false)
    const [showOptionsState, setShowOptionsState] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);
    const [loadingSignup, setLoadingSignup] = useState(false);

    const [searchCountry, setSearchCountry] = useState('');
    const [searchState, setSearchState] = useState('');

    const [orgType, setOrgType] = useState<BaseFieldState<orgType>>(getFieldState('INDEPENDENT'));
    const [orgName, setOrgName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [orgWebsite, setOrgWebsite] = useState<BaseFieldState<string>>(getFieldState(''));
    const [orgCountry, setOrgCountry] = useState<BaseFieldState<CountryOption>>(getFieldState({ label: '', value: '' }));
    const [orgState, setOrgState] = useState<BaseFieldState<StateOption>>(getFieldState({ label: '', value: '' }));
    const [orgZip, setOrgZip] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userFirstName, setUserFirstName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userLastName, setUserLastName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userEmail, setUserEmail] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userBirthday, setUserBirthday] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userGender, setUserGender] = useState<BaseFieldState<GenderOption>>(getFieldState({ label: 'Select a gender', value: 'P' }));
    const [username, setUsername] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userPassword, setUserPassword] = useState<BaseFieldState<string>>(getFieldState(''));
    const [startDate, setStartDate] = useState(new Date());

    const [zipcodeLengths, setZipcodeLengths] = useState<PostalCodeClean>({ maxLength: 5, minLength: 5 })

    const clickCalendar = () => {
        if (datepickerRef) {
            datepickerRef.current.setFocus(true)
        }
    }

    const cleanFields = () => {
        setOrgType(getFieldState('INDEPENDENT'));
        setOrgName(getFieldState(''));
        setOrgWebsite(getFieldState(''));
        setOrgCountry(getFieldState({ label: '', value: '' }));
        setOrgState(getFieldState({ label: '', value: '' }));
        setOrgZip(getFieldState(''));

        setUserFirstName(getFieldState(''));
        setUserLastName(getFieldState(''));
        setUserEmail(getFieldState(''));
        setUserBirthday(getFieldState(''));
        setUserGender(getFieldState({ label: 'Select a gender', value: 'P' }));
        setUsername(getFieldState(''));
        setUserPassword(getFieldState(''));
    }

    const setHealerType = (id: string) => {
        setSelectOption(id);
        if (id === "1") {
            setOrgType(getFieldState('PROFESSIONAL'));
            return;
        }
        if (id === "2") {
            setOrgType(getFieldState('BUSINESS'));
            return;
        }
        if (id === "3") {
            setOrgType(getFieldState('INDEPENDENT'));
            return;
        }
    }

    const checkStep = async () => {
        if (step === 0) {
            setStep(step + 1);
            return;
        }

        if (await checkDataFirst()) return;
        setStep(step + 1);
    }

    const checkDataFirst = async () => {
        let hasErrors = false;

        if (!orgName.value || orgName.value?.length === 0) {
            hasErrors = true;
            setOrgName({
                ...orgName,
                showError: true,
                error: 'Organization name is required'
            })
        } else {
            setOrgName({
                ...orgName,
                showError: false,
                error: ''
            })
        }

        if (
            !orgCountry.value?.value || orgCountry.value?.value.length === 0 ||
            !orgCountry.value?.label || orgCountry.value?.label.length === 0
        ) {
            hasErrors = true;
            setOrgCountry({
                ...orgCountry,
                showError: true,
                error: 'Organization Country is required'
            })
        } else {
            setOrgCountry({
                ...orgCountry,
                showError: false,
                error: ''
            })
        }

        if (
            !orgState.value?.value || orgState.value?.value.length === 0 ||
            !orgState.value?.label || orgState.value?.label.length === 0
        ) {
            hasErrors = true;
            setOrgState({
                ...orgState,
                showError: true,
                error: 'Organization State is required'
            })
        } else {
            setOrgState({
                ...orgState,
                showError: false,
                error: ''
            })
        }

        const errorZipCode = isValidZipCode(orgZip.value || '', zipcodeLengths, orgCountry.value?.label);
        if (errorZipCode !== '') {
            hasErrors = true;
            setOrgZip({
                ...orgZip,
                showError: true,
                error: errorZipCode
            })
        } else {
            setOrgZip({
                ...orgZip,
                showError: false,
                error: ''
            })
        }

        setLoadingSignup(true);
        const available = await checkOrgWebsite(orgWebsite.value || '');
        if (selectOption !== '3' && (!orgWebsite.value || orgWebsite.value?.length === 0)) {
            hasErrors = true;
            setOrgWebsite({
                ...orgWebsite,
                showError: true,
                error: 'Organization website is required'
            })
        }
        else if (selectOption !== '3' && !available) {
            hasErrors = true;
            setOrgWebsite({
                ...orgWebsite,
                showError: true,
                error: 'Organization website is already used. If you have subdomain please include it.'
            })
        }
        else {
            setOrgWebsite({
                ...orgWebsite,
                showError: false,
                error: ''
            })
        }
        setLoadingSignup(false);
        return hasErrors;
    }

    const checkDataSecond = (): boolean => {
        let hasErrors = false;

        if (!userFirstName.value || userFirstName.value?.length === 0) {
            hasErrors = true;
            setUserFirstName({
                ...userFirstName,
                showError: true,
                error: 'First name is required'
            })
        } else {
            setUserFirstName({
                ...userFirstName,
                showError: false,
                error: ''
            })
        }

        if (!userLastName.value || userLastName.value?.length === 0) {
            hasErrors = true;
            setUserLastName({
                ...userLastName,
                showError: true,
                error: 'Last name is required'
            })
        } else {
            setUserLastName({
                ...userLastName,
                showError: false,
                error: ''
            })
        }

        if (!userEmail.value || userEmail.value?.length === 0) {
            hasErrors = true;
            setUserEmail({
                ...userEmail,
                showError: true,
                error: 'Email is required'
            })
        } else {
            setUserEmail({
                ...userEmail,
                showError: false,
                error: ''
            })
        }

        if (!userBirthday.value ||
            userBirthday.value?.length === 0
        ) {
            hasErrors = true;
            setUserBirthday({
                ...userBirthday,
                showError: true,
                error: 'Date of Birth is required'
            })
        } else {
            setUserBirthday({
                ...userBirthday,
                showError: false,
                error: ''
            })
        }

        if (!isValidBirthdate(userBirthday.value)) {
            hasErrors = true;
            setUserBirthday({
                ...userBirthday,
                showError: true,
                error: 'You must be at least 18 years old'
            })
        } else {
            setUserBirthday({
                ...userBirthday,
                showError: false,
                error: ''
            })
        }

        if (
            !userGender.value?.label || userGender.value?.label.length === 0 ||
            !userGender.value?.value || userGender.value?.value.length === 0
        ) {
            hasErrors = true;
            setUserGender({
                ...userGender,
                showError: true,
                error: 'Gender is required'
            })
        } else {
            setUserGender({
                ...userGender,
                showError: false,
                error: ''
            })
        }

        const errorUsername = isValidUsername(username.value || '');
        if (errorUsername !== '') {
            hasErrors = true;
            setUsername({
                ...username,
                showError: true,
                error: errorUsername
            })
        } else {
            setUsername({
                ...username,
                showError: false,
                error: ''
            })
        }

        const errorPassword = validatePassword(userPassword.value || '');
        if (errorPassword.lastError !== '') {
            hasErrors = true;
            setUserPassword({
                ...userPassword,
                showError: true,
                error: errorPassword.lastError === 'Password is required' ?
                    errorPassword.lastError : `Password needs ${errorPassword.lastError.toLowerCase()}`
            })
        } else {
            setUserPassword({
                ...userPassword,
                showError: false,
                error: ''
            })
        }

        return hasErrors;
    }

    const cleanWebsite = (web: string) => {
        const website = web.replace(/\s+/g, '').toLowerCase();
        const URL_PROTOCOL = /http(s)?:\/\/.*/;
        if (URL_PROTOCOL.exec(website)) {
            return website;
        }
        return `https://${website}`;
    }

    const handleSignup = async () => {
        if (await checkDataFirst() || checkDataSecond()) {
            setLoadingSignup(false);
            return;
        }
        setLoadingSignup(true);
        const toSend: ISignUpOrganizationBody = {
            organization: {
                organizationName: orgName.value || '',
                organizationType: orgType.value || 'INDEPENDENT',
                zipcode: orgZip.value || '',
                countryCode: orgCountry.value?.value || '',
                country: orgCountry.value?.label || '',
                state: orgState.value?.value || '',
                website: cleanWebsite(orgWebsite.value || ''),
            },
            user: {
                email: userEmail.value || '',
                givenName: userFirstName.value || '',
                familyName: userLastName.value || '',
                birthdate: userBirthday.value || '',
                gender: userGender.value?.value as genderType || 'P',
                userName: username.value || '',
                password: userPassword.value || '',
                zipcode: orgZip.value || '',
                countryCode: orgCountry.value?.value || '',
                organizations: [orgName.value || ''],
                roles: [['ORGADMIN']],
                preferredLanguage: 'english',
                clubs: [],
                clubsAdvisor: [],
                clubsFollower: [],
                phoneNumber: 30000000000,
            },
        }

        console.log('To Send - Signup Healer', toSend);

        dispatch<any>(orgSignup(toSend, true))
            .then((results: string) => {
                if (!results.includes('error')) {
                    cleanFields();
                    setEmailSent(true);
                    setLoadingSignup(false);
                }
                else {
                    setLoadingSignup(false);
                    currentSuccess?.setSuccessData(false, true, results.split('/')[0], results.split('/')[1])
                }
            })
            .catch(console.error)
            .finally(() => setLoadingSignup(false));
    }

    const getMaxDate = () => {
        const date = new Date();
        date.setFullYear(date.getFullYear() - 18);
        return date;
    }

    const onHideSeeModal = () => {
        setShowModalSeeMore(false);
    }

    const handleOnBlur = (e: any) => {
        const date = new Date(e.target.value);
        const date2 = new Date(userBirthday?.value || '');

        if (date.toDateString() !== date2.toDateString()) {
            currentSuccess?.setSuccessData(false, true, 'You must be 18 years old or higher');
        }
    };

    const selectHealerType = (type: string) => {
        setHealerType(type);
        setStep(step + 1);
    }

    useEffect(() => {
        if (orgCountry.value?.value) {
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Roster.postalCodes(orgCountry.value?.value, dataSDK.token)
                })
                .then((results) => {
                    if (results.body && 'maxLength' in results.body) {
                        setZipcodeLengths(results.body)
                    }
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }, [orgCountry])

    return (
        <>
            {!emailSent ? (
                (selectOption === '3') ? (
                    <SignupHealerInd emailSent={emailSent} setEmailSent={setEmailSent} isIndividual={true} />
                ) : (
                    (step === 2 ? (
                        <Form_signup>
                            <TitleForm>Please create your account.</TitleForm>
                            <Inputs>
                                <Row2>
                                    <Label>First name<Imp>*</Imp></Label>
                                    <Input
                                        value={userFirstName.value}
                                        onChange={(e) => setUserFirstName({ ...userFirstName, value: e.currentTarget.value })}
                                    />
                                    {(userFirstName.showError) && (<ErrorField error={userFirstName.error} />)}
                                </Row2>
                                <Row2>
                                    <Label>Last name<Imp>*</Imp></Label>
                                    <Input
                                        value={userLastName.value}
                                        onChange={(e) => setUserLastName({ ...userLastName, value: e.currentTarget.value })}
                                    />
                                    {(userLastName.showError) && (<ErrorField error={userLastName.error} />)}
                                </Row2>
                            </Inputs>
                            <Inputs>
                                <Row1>
                                    <Label>Email<Imp>*</Imp></Label>
                                    <Input
                                        value={userEmail.value}
                                        onChange={(e) => setUserEmail({ ...userEmail, value: e.currentTarget.value })}
                                    />
                                    {(userEmail.showError) && (<ErrorField error={userEmail.error} />)}
                                </Row1>
                            </Inputs>
                            <Inputs>
                                <Row2>
                                    <Label>Date of Birth<Imp>*</Imp></Label>
                                    <InputIcon theme={{ main: 'datePickerYearMonth' }}>
                                        <DatePicker
                                            ref={datepickerRef}
                                            placeholderText={'MM  /  DD  /  YYYY'}
                                            selected={userBirthday.value ? new Date(userBirthday.value || '') : null}
                                            onChange={(date: Date) => {
                                                setUserBirthday({
                                                    ...userBirthday,
                                                    value: date.toLocaleDateString()
                                                })
                                            }}
                                            onBlur={handleOnBlur}
                                            maxDate={getMaxDate()}
                                            className="inputDatePicker"
                                            showYearDropdown
                                            showMonthDropdown
                                            yearDropdownItemNumber={100}
                                        />
                                        <StyledFontAwesomeIcon
                                            icon={faCalendar}
                                            size='lg'
                                            onClick={clickCalendar}
                                        />
                                    </InputIcon>
                                    {(userBirthday.showError) && (<ErrorField error={userBirthday.error} />)}
                                </Row2>
                                <Row2>
                                    <Label>Gender<Imp>*</Imp></Label>
                                    <Select onClick={() => setShowOptions(!showOptions)}>
                                        <Labelselect>{userGender.value?.label !== '' ? userGender.value?.label : 'Select an option'}</Labelselect>
                                        <StyledFontAwesomeIcon
                                            icon={faChevronDown}
                                            size='sm'
                                        />
                                    </Select>
                                    {showOptions &&
                                        <Items>
                                            {GendersReactSelect.map((gender, index) => (
                                                <Item onClick={() => {
                                                    setShowOptions(!showOptions)
                                                    setUserGender({
                                                        ...userGender,
                                                        value: gender
                                                    })
                                                }
                                                }
                                                    key={index}>
                                                    {gender.label}
                                                </Item>
                                            ))}
                                        </Items>
                                    }
                                    {(userGender.showError) && (<ErrorField error={userGender.error} />)}
                                </Row2>
                            </Inputs>
                            <Inputs>
                                <Row2>
                                    <Label>Username<Imp>*</Imp></Label>
                                    <Input
                                        value={username.value?.toLowerCase()}
                                        onChange={(e) => setUsername({ ...username, value: e.currentTarget.value })}
                                        autoComplete={"unInput"}
                                    />
                                    {(username.showError) && (<ErrorField error={username.error} />)}
                                </Row2>
                                <Row2>
                                    <Label>Password<Imp>*</Imp></Label>
                                    <InputIcon_Healer
                                        onClick={() => setShowPasswordTooltip(true)}
                                    >
                                        <Input
                                            type={showPassword ? 'text' : 'password'}
                                            value={userPassword.value}
                                            onChange={(e) => setUserPassword({ ...userPassword, value: e.currentTarget.value })}
                                            autoComplete="new-password"
                                        />
                                        <StyledFontAwesomeIcon
                                            icon={showPassword ? faEyeSlash : faEye}
                                            size='sm'
                                            onClick={() => setShowPassword(!showPassword)}
                                        />
                                    </InputIcon_Healer>
                                    {(showPasswordTooltip) && (<PasswordToolTip password={userPassword.value || ''} setShowModal={setShowPasswordTooltip} />)}
                                    {(userPassword.showError) && (<ErrorField error={userPassword.error} />)}
                                </Row2>
                            </Inputs>
                            <TextTerms>
                                By clicking Sign up, you agree to our <NavLink to="/terms">Terms of Service</NavLink> and <NavLink to="/privacy">Privacy Policy</NavLink>
                            </TextTerms>
                            <WrapperButton>
                                <ButtonSpinner
                                    loading={loadingSignup}
                                    handleClick={handleSignup}
                                    text={'Sign Up'}
                                    hasIcon={true}
                                    icon={'arrow-right'}
                                />
                            </WrapperButton>
                        </Form_signup>
                    ) : (
                        <GridBaseArea footerActive={step !== 2 && step !== 0}>
                            <Content>
                                {step === 0 ?
                                    <WrapperGrid>

                                        <ColumnInfo_Signup>
                                            <HeaderCol>
                                                <IconHealer />
                                                <Info>
                                                    <TextLine>${process.env.REACT_APP_SUBSCRIPTION_COST || '0'} Annually</TextLine>
                                                    <TextPrice>${process.env.REACT_APP_SUBSCRIPTION_DISCOUNT || '0'} for 1st 100 Healers</TextPrice>
                                                </Info>
                                                <ReadMore onClick={() => setShowModalSeeMore(true)}>See what's included</ReadMore>
                                            </HeaderCol>
                                            <InfoContainer>
                                                <TextHeader>
                                                    Support, Grow and Monetize Communities and Followers
                                                </TextHeader>
                                                <ItemPricing theme={{ size: '14px' }}>
                                                    <FontAwesomeIcon icon={faCheck} size='sm' />
                                                    Grow unlimited internal & external communities & groups.
                                                </ItemPricing>
                                                <ItemPricing theme={{ size: '14px' }}>
                                                    <FontAwesomeIcon icon={faCheck} size='sm' />
                                                    Message individuals or groups.
                                                </ItemPricing>
                                                <ItemPricing theme={{ size: '14px' }}>
                                                    <FontAwesomeIcon icon={faCheck} size='sm' />
                                                    Schedule events.
                                                </ItemPricing>
                                                <ItemPricing theme={{ size: '14px' }}>
                                                    <FontAwesomeIcon icon={faCheck} size='sm' />
                                                    Promote events, news & updates to communities.
                                                </ItemPricing>
                                                <ItemPricing theme={{ size: '14px' }}>
                                                    <FontAwesomeIcon icon={faCheck} size='sm' />
                                                    Retain names and emails of new community members.
                                                </ItemPricing>
                                                <ItemPricing theme={{ size: '14px' }}>
                                                    <FontAwesomeIcon icon={faCheck} size='sm' />
                                                    Zero algorithm content bias; content always appears in followers and members feeds
                                                </ItemPricing>
                                                <ItemPricing theme={{ size: '14px' }}>
                                                    <FontAwesomeIcon icon={faCheck} size='sm' />
                                                    SEO optimized directory listing.
                                                </ItemPricing>

                                                <TextHeader>
                                                    Generate Revenue
                                                </TextHeader>

                                                <ItemPricing theme={{ size: '14px' }}>
                                                    <FontAwesomeIcon icon={faCheck} size='sm' />
                                                    Receive referrals of seekers.
                                                </ItemPricing>
                                                <ItemPricing theme={{ size: '14px' }}>
                                                    <FontAwesomeIcon icon={faCheck} size='sm' />
                                                    Create VIP communities with premium content. *
                                                </ItemPricing>
                                                <ItemPricing theme={{ size: '14px' }}>
                                                    <FontAwesomeIcon icon={faCheck} size='sm' />
                                                    Deliver group or individual services online (live classes, memberships, online courses). *
                                                </ItemPricing>
                                                <ItemPricing theme={{ size: '14px' }}>
                                                    <FontAwesomeIcon icon={faCheck} size='sm' />
                                                    Receive tips from Seekers and Healers.*
                                                </ItemPricing>
                                            </InfoContainer>
                                        </ColumnInfo_Signup>

                                        <ColumnInfo_Signup>
                                            <TitleForm>What type of healer are you?</TitleForm>
                                            <Selectors>
                                                <ItemSelc
                                                    theme={{ main: selectOption === '1' ? 'active' : '' }}
                                                    onClick={() => selectHealerType('1')}
                                                >
                                                    <TitleSelc>Administrator of a Professional Organization</TitleSelc>
                                                    <TextSelc>Supports and/or certifies healers.</TextSelc>
                                                </ItemSelc >
                                                <ItemSelc
                                                    theme={{ main: selectOption === '2' ? 'active' : '' }}
                                                    onClick={() => selectHealerType('2')}
                                                >
                                                    <TitleSelc>Business Administrator of a Healer Entity</TitleSelc>
                                                    <TextSelc>Clinic or center that employs or contracts with healers to provide services.</TextSelc>
                                                </ItemSelc>
                                                <ItemSelc
                                                    theme={{ main: selectOption === '3' ? 'active' : '' }}
                                                    onClick={() => selectHealerType('3')}
                                                >
                                                    <TitleSelc>Independent Healer</TitleSelc>
                                                    <TextSelc>I independently provide services to support my clients' wellness and transformative journeys.</TextSelc>
                                                </ItemSelc>
                                            </Selectors>
                                        </ColumnInfo_Signup>
                                    </WrapperGrid>
                                    : (
                                        <Form_signup isCompanyPO={true}>
                                            <TitleForm>Please Signup your organization, business or independent practice.</TitleForm>
                                            <Inputs>
                                                <Row1>
                                                    <Label>Company / Organization name<Imp>*</Imp></Label>
                                                    <Input
                                                        value={orgName.value}
                                                        onChange={(e) => setOrgName({ ...orgName, value: e.currentTarget.value })}
                                                    />
                                                    {(orgName.showError) && (<ErrorField error={orgName.error} />)}
                                                </Row1>
                                            </Inputs>
                                            <Inputs>
                                                <Row1>
                                                    <Label>Website<Imp>*</Imp></Label>
                                                    <Input
                                                        placeholder="https://"
                                                        value={orgWebsite.value}
                                                        onChange={(e) => setOrgWebsite({ ...orgWebsite, value: e.currentTarget.value })}
                                                    />
                                                    {(orgWebsite.showError) && (<ErrorField error={orgWebsite.error} />)}
                                                </Row1>
                                            </Inputs>
                                            <Inputs>
                                                <Row1>
                                                    <Label>Country<Imp>*</Imp></Label>
                                                    <SelectorCountry
                                                        country={orgCountry}
                                                        setCountry={setOrgCountry}
                                                        searchCountry={searchCountry}
                                                        setSearchCountry={setSearchCountry}
                                                    />
                                                    {(orgCountry.showError) && (<ErrorField error={orgCountry.error} />)}
                                                </Row1>
                                            </Inputs>
                                            <Inputs>
                                                <Row1>
                                                    <Label>State / Region<Imp>*</Imp></Label>
                                                    <SelectorState
                                                        country={orgCountry.value?.value ?? ''}
                                                        state={orgState}
                                                        setState={setOrgState}
                                                        searchState={searchState}
                                                        setSearchState={setSearchState}
                                                    />
                                                    {(orgState.showError) && (<ErrorField error={orgState.error} />)}
                                                </Row1>
                                            </Inputs>
                                            <Inputs>
                                                <Row1>
                                                    <Label>Zip / Postal Code<Imp>*</Imp></Label>
                                                    <Input
                                                        value={orgZip.value}
                                                        onChange={(e) => setOrgZip({ ...orgZip, value: e.currentTarget.value })}
                                                    />
                                                    {(orgZip.showError) && (<ErrorField error={orgZip.error} />)}
                                                </Row1>
                                            </Inputs>

                                        </Form_signup>
                                    )
                                }
                            </Content>

                            {/* Footer */}
                            {(step !== 2 && step !== 0) && (
                                (step === 1 && selectOption === '3') ? '' :
                                    <FooterCon theme={{ main: step === 0 ? 'end' : 'space-between' }}>
                                        {step !== 0 &&
                                            <ButtonBack onClick={() => setStep(step - 1)}>
                                                <IconButtonFtr
                                                    icon={faArrowLeft}
                                                    size='lg'
                                                    color={"black"}
                                                    style={{
                                                        fontSize: '16px'
                                                    }}
                                                />
                                                Back
                                            </ButtonBack>
                                        }
                                        <WrapperButtonNext>
                                            <ButtonSpinner
                                                loading={loadingSignup}
                                                handleClick={checkStep}
                                                text={'Next'}
                                                hasIcon={true}
                                                icon={'arrow-right'}
                                                disabled={selectOption === ''}
                                            />
                                        </WrapperButtonNext>
                                    </FooterCon>
                            )}
                        </GridBaseArea>
                    ))
                )) : (
                <EmailSent type={"healer"} from={"individual"} />
            )}
            <ModalSeeWhat
                show={showModalSeeMore}
                onHide={onHideSeeModal}
            />
        </>
    )
}

const Labelselect = styled.div`
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 10px;
    text-overflow: ellipsis;
    text-align: left;
`

const WrapperButtonNext = styled.div`
    width: fit-content;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  gap: 16px;
  padding-right: 16px;

  @media only screen and (max-width: 770px) {
    padding: 0px 16px;
  }
`;

const ColumnInfo_Signup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const GridBaseArea = styled.div`
    overflow: hidden;
    width: 100%;
    height: calc(100vh - 134px); //Header height + padding
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: ${({ footerActive }: { footerActive: boolean }) => footerActive ? '1fr 56px' : '1fr 5px'};
    grid-row-gap: 16px;

    @media only screen and (max-width: 770px) {
        height: calc(100vh - 92px);
    }
`;

const ReadMore = styled.div`
    font-family: 'Inter-Bold';
    font-size: 14px;
    line-height: 17px;
    color: var(--absolute-zero);
    text-align: left;
    cursor: pointer;
    display: none;

    @media only screen and (max-width: 770px) {
        display: flex;
        grid-area: 2 / 1 / 3 / 3;
   }
`

const ItemPricing = styled.div`
  color: var(--default-dark);
  font-size: ${props => props.theme.size || '16px'};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align:left;
  display: flex;
  align-items: center;
  gap: 10px;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10%;
  height: 100%;
`;

const TextHeader = styled.div`
    color: #000;
    font-size: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    padding-bottom: 5px;
`;

const InfoContainer = styled.div`
    padding: 10px 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: white;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    height: 100%;
    min-height: 413px;
    border: 1px solid var(--simple-gray);
    border-top: 0px;
    width: 100%;

    @media only screen and (max-width: 770px) {
        display: none;
    }
`;

const ItemInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5%;
    height: 100%;
`;

const TextPrice = styled.div`
  color: var(--default-dark);
  font-size: 18px;
  font-family: 'Inter-bold';
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: right;
`
const TextLine = styled.div`
  color: #808080;
  text-align: right;
  font-size: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: line-through;
`

const Info = styled.div`
    @media only screen and (max-width: 770px) {
        grid-area: 1 / 2 / 2 / 3;
   }
`;

const IconHealer = styled.div`
  width: 40px;
  height: 40px;
    //background-color: #DBB467;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: relative;
  background-image: url(../icon_healer.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 70% auto;
  border: 1px solid var(--simple-blue-dark);

  @media only screen and (max-width: 770px) {
        grid-area: 1 / 1 / 2 / 2;
   }
`

const HeaderCol = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    padding: 10px 24px;
    border-radius: 8px 8px 0px 0px;
    border: 1px solid var(--simple-gray);
    background-color: #FFF;

  @media only screen and (max-width: 770px) {
        border-radius: 8px;
        display: grid;
        grid-template-columns: 15% 80%;
        grid-template-rows: 80% 15%;
        grid-column-gap: 15px;
        grid-row-gap: 5px;
   }
`

const WrapperGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    grid-template-rows: minmax(200px, 1fr);
    grid-column-gap: 16px;
    overflow: scroll;
    height: 100%;
    width: 100%;

    @media only screen and (max-width: 770px) {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
   }
`;

const WrapperButton = styled.div`
    margin-top: 10px;
    width: 100%;
    @media only screen and (max-width: 770px) {
        margin-bottom: 20px;
        width: 100%;
   }
`;

const Selectors = styled.div`
    display: grid;
    width: 100%;
    height:100%;
    grid-template-rows: repeat(
        auto-fit,
        minmax(
            138px,
            1fr
        )
    );
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
    justify-content: flex-start;
    padding-top: 16px;

    @media only screen and (max-width: 770px) {
        padding-top: 15px;
    }
`
const ItemSelc = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    display: flex;
    background-color: white;
    padding: 16px;
    gap: 12px;
    min-height: 138px;
    flex-direction: column;
    border: 1px solid var(--simple-gray);
    border-radius: 8px;
    text-align: left;
    cursor: pointer;

    &:hover{
        border: 1px solid gray;
    }
    &.active{
        border: 2px solid var(--electric-yellow-2);
    }

    @media only screen and (max-width: 770px) {
        width: 100%;
    }

`
const TitleSelc = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
`
const TextSelc = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
`

export const Form_signup = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: ${({ isCompanyPO = false }: { isCompanyPO?: boolean }) => isCompanyPO ? '33px 0px 0px 16px' : '33px 16px 0px 16px'};
    overflow: scroll;
    height: ${({ isCompanyPO = false }: { isCompanyPO?: boolean }) => isCompanyPO ? '100%' : 'calc(100vh - 134px)'};

   @media only screen and (max-width: 770px) {
        padding: 16px;
        height: 100%;
        overflow: scroll;
        margin-bottom: 60px;
   }
`;

const TitleForm = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    padding-bottom: 10px;

    @media only screen and (max-width: 770px) {
        grid-area: 2 / 1 / 3 / 2;
        justify-self: center;
        align-self: center;
    }
`;

export const Logo = styled.div`
    width: 110px;
    height: 50px;
    background-image: url("./logoVibe.png");
    background-size: contain;
    background-repeat: no-repeat;
    // position: fixed;
    // top: 32px;
    // left: 37px;
    @media only screen and (max-width: 770px) {
        margin: 20px;
    }
    
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: black;
    cursor: pointer;
`;

const TextTerms = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%; 
    text-align: initial;
`

const NavLink = styled(Link)`
  text-decoration: underline;
`;

export const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
`

const InputIcon = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    border-radius: 9px;
    align-items: center;
    padding-right: 15px;
    border: 1px solid var(--simple-gray);
    height: 40px;
    
    & ${Input}{
        border: 0;
        height: 38px;
    } 
`

export const InputPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 0px 0 20px;
    font-size: 15px;
    font-family: 'Inter';
`;

const Select = styled.div`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
`

const Label = styled.div`    
    width: 100%;
    text-align: left;
    margin: 3px 0;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`
const Inputs = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 770px) {
        padding: 0;
        gap: 20px;
    }
`

const Items = styled.ul`
    width: 100%;
    position: absolute;
    top: 65px;
    z-index: 1;
    overflow-y: scroll;
    max-height: 200px;
    border-radius: 12px;
`

const Item = styled.li`
    background-color: white;
    padding: 10px 20px;
    text-align: left;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;

    &:last-child {
        border-radius: 0 0 10px 10px;
        -webkit-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        -moz-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
    }

    &:hover {
        background-color: #e8e8e8;
    }
`

const ButtonNext = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    background-color: var(--simple-blue-dark-2);
    padding: 12px 4px 12px 16px;
    border-radius: 8px;
    color: white;
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &.btnActive{
        
    }
    &.btnInactive{
        pointer-events: none;
        opacity: 0.7;
    }

`

const ButtonBack = styled.div`
    background-color: white;
    border: 1px solid var(--simple-blue-dark-2);
    padding: 12px 16px 12px 4px;
    border-radius: 8px;
    color: black;
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`

const FooterCon = styled.div`
    background-color: white;
    width: 100%;
    padding: 10px 16px;
    height: 56px;
    -webkit-box-shadow: 0px -4px 4px 0px rgba(40, 47, 58, 0.05);
    -moz-box-shadow: 0px -4px 4px 0px rgba(40, 47, 58, 0.05);
    box-shadow: 0px -4px 4px 0px rgba(40, 47, 58, 0.05);
    display: flex;
    justify-content: ${props => props.theme.main};
    @media only screen and (max-width: 770px) {
        position: sticky;
    }
`

const InputIcon_Healer = styled(InputIcon)`
    position: relative;
`;

const IconButtonFtr = styled(FontAwesomeIcon)`
    padding: 0 10px
`;


const LoginTitle = styled.p`
    font-size: 24px;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
`;

export default SignupHealer;