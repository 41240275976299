import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import { useEffect, useState } from "react";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { getFiltered, sortAlphabeticallyOnProperty } from "../../containers/Signup/SignupHealerInd";
import { BaseFieldState } from "./utils";
import SelectItem from "../../interfaces/SelectItem";
import OutsideClickHandler from "react-outside-click-handler";

const Selector = ({
    options,
    selected,
    setSelected,
    searchItem,
    setSearchItem,
    backgroundColor = "white",
    border = "1px solid var(--simple-gray)",
    placeholder = "Select an item",
    notOrganized = false,
    hideIcon = false,
    showOptionsClick = 0,
    className = ''
}: {
    options: SelectItem[],
    selected: BaseFieldState<SelectItem>,
    setSelected: (data: BaseFieldState<SelectItem>) => void,
    searchItem: string,
    setSearchItem: (data: string) => void,
    backgroundColor?: string,
    border?: string,
    placeholder?: string,
    notOrganized?: boolean,
    hideIcon?: boolean,
    showOptionsClick?: number,
    className?: string 
}) => {

    const [showOptions, setShowOptions] = useState<boolean>(false);

    useEffect(() => {
        const itemFound = options.find((Item) =>
            Item.label &&
            (Item.label.toLocaleLowerCase() === searchItem ||
                Item.label.toLocaleUpperCase() === searchItem ||
                Item.label === searchItem)
        )
        if (itemFound) {
            setSearchItem(itemFound.label);
            setShowOptions(false);
            setSelected({
                ...selected,
                value: itemFound
            });
        }
    }, [searchItem])

    useEffect(() => {
        if (selected.value?.value) {
            setSearchItem(selected.value?.label)
        }
    }, [])

    useEffect(() => {
        if(showOptionsClick){
            setShowOptions(true)
        }
    },[showOptionsClick])

    useEffect(() => {
        if (selected.value?.value) {
            setSearchItem(selected.value?.label)
        }
    }, [selected])

    const getFilteredOptions = () => {
        if (searchItem !== selected.value?.label) {
            return getFiltered(options, searchItem)
        }
        return options;
    }

    const getOptions = () => {
        return notOrganized ?
            getFilteredOptions() :
            (sortAlphabeticallyOnProperty(getFilteredOptions(), 'label') as SelectItem[]);
    }

    return (
        <WrapperSelect theme={{ main: className }}>
            <Select_Input
                onClick={() => setShowOptions(true)}
                value={searchItem}
                placeholder={placeholder}
                onChange={(e) => setSearchItem(e.currentTarget.value)}
                backgroundColor={backgroundColor}
                border={border}
                onFocus={() => setShowOptions(true)}
            />
            {!hideIcon &&
                <IconDropdownInput
                    icon={faChevronDown}
                    size='sm'
                    color={backgroundColor === 'white' ? 'black' : 'white'}
                    onClick={() => setShowOptions(!showOptions)}
                />
            }
            {showOptions &&
                <OutsideClickHandler onOutsideClick={() => setShowOptions(false)}>
                    <Items>
                        {getOptions().map((active, index) => (
                            <Item onClick={() => {
                                setSearchItem(active.label)
                                setShowOptions(false)
                                setSelected({
                                    ...selected,
                                    value: active
                                })
                            }
                            }
                                key={index}>
                                {active.label}
                            </Item>
                        ))}
                    </Items>
                </OutsideClickHandler>
            }
        </WrapperSelect>
    )
}

const WrapperSelect = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
`;

interface Select_InputProps {
    backgroundColor: string
    border: string
}

const Select_Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Inter';
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;

    background-color: ${(props: Select_InputProps) => props.backgroundColor};
    border: ${(props: Select_InputProps) => props.border};
    color: ${(props: Select_InputProps) => props.backgroundColor === 'white' ? 'black' : 'white'};

    &::placeholder{
        color: var(--simple-gray);
        font-family: 'Inter';
    }
`;

const IconDropdownInput = styled(FontAwesomeIcon)`
    cursor: pointer;
    position: absolute;
    z-index: 0;
    top: 37%;
    right: 20px;
`;

const Item = styled.li`
    background-color: white;
    padding: 10px 20px;
    text-align: left;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;

    &:last-child {
        border-radius: 0 0 10px 10px;
        -webkit-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        -moz-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
    }

    &:hover {
        background-color: #e8e8e8;
    }
`;

const Items = styled.ul`
    width: 100%;
    position: absolute;
    top: 100%;
    z-index: 10;
    overflow-y: scroll;
    max-height: 200px;
    border-radius: 12px;
    left: 0px;
    box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
`;

export default Selector;