import React, { useState } from 'react'
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faTimes, faDownload, faChevronUp, faCheck, faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { Header, PaymentDetails, Items, TextPayment, Title, List } from './index'

const Payment = (props: any) => {
    return (
        <>
            <Header>
                <TextHeader> </TextHeader>
                <FontAwesomeIcon
                    icon={faTimes}
                    size="sm"
                    onClick={() => props.purchaseActive(false)}
                />
            </Header>
            <ContainerSuccessful>
                <Icon>
                    <FontAwesomeIcon
                        icon={faCheck}
                        size="sm"
                    />
                </Icon>
                <TextSuccessful>Payment Successful</TextSuccessful>
                <DescScc>
                    We've processed your charge for 800.00 US$
                </DescScc>
            </ContainerSuccessful>
            <PaymentDetails>
                <Title>
                    Summary
                </Title>
                <List>
                    <Items>
                        <TextPayment>ID</TextPayment>
                        <TextPayment>cac1c345456-99</TextPayment>
                    </Items>
                    <Items>
                        <TextPayment>Date</TextPayment>
                        <TextPayment>Aug 04 - 2023</TextPayment>
                    </Items>
                    <Items>
                        <TextPayment>Payment method</TextPayment>
                        <TextPayment>MasterCard ***1245</TextPayment>
                    </Items>
                </List>
            </PaymentDetails>
            <PaymentDetails>
                <Title>
                    Payment Details
                </Title>
                <List>
                    <Items>
                        <TextPayment>Amount</TextPayment>
                        <TextPayment>800.00 US$</TextPayment>
                    </Items>
                    <Items>
                        <TextPayment>Transaction Fee</TextPayment>
                        <TextPayment>21.81 US$</TextPayment>
                    </Items>
                    <Items>
                        <TextPayment>Net</TextPayment>
                        <TextPayment>720.00 US$</TextPayment>
                    </Items>
                </List>
            </PaymentDetails>
            <ButtonDownload>
                Download Invoice
                <FontAwesomeIcon
                    icon={faDownload}
                    size="lg"
                />
            </ButtonDownload>
        </>
    )
}

const ButtonDownload = styled.div`
    display: flex;
    gap: 10px;
    color: var(--dark-blue);
    font-family: "Inter-Bold";
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.1px;
    border-radius: 8px;
    border: 1px solid #BEBEBE;
    background: #FFF;
    width: fit-content;
    padding: 6px 18px;
    align-items: center;
    margin: 0 auto;
`

const ContainerSuccessful = styled.div`
width: 50%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin: 0 auto;
gap: 13px;
padding: 16px 0;
`
const TextSuccessful = styled.div`
    color: var(--default-dark);
    text-align: center;
    font-family: "Inter-Bold";
    font-size: 24px;
    letter-spacing: 0.1px;
`

const Icon = styled.div`
    background-color: #A3CC52;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
`
const DescScc = styled.div`
    color: var(--default-dark);
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.2px;
`

const TextHeader = styled.div`
    display: flex;
    gap: 15px;
`

export default Payment