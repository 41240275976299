import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row1, Imp } from "../Website/Form"
import { userForgotPassword } from "../../store/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import ButtonSpinner from "../../components/BaseComponents/ButtonSpinner";
import { ContextSuccess } from "../../Context";
import FormForgotPassword from "../../components/ForgotPassword/FormForgotPassword";

const ForgotPassword = () => {
    const currentSuccess = useContext(ContextSuccess);
    const navigate = useNavigate();

    const goBack = () => {
        navigate("../?onlyLogin=true");
        currentSuccess?.setLoginModal(true, false);
    }

    return (
        <Container>
            <ContainerForm>
                <Logo onClick={goBack}/>

                <FormForgotPassword onHide={goBack} />

            </ContainerForm>
            <ContainerInfo>
                {/*<Title>Congratulations!</Title>
                <Subtitle>You've been invited to raise your vibration through Vibe.</Subtitle>
                <Text>Please complete your registration to begin your journey.<br />Your personal information will not be shared with anyone, but online communities are more positive and safer when people register with their real identity.</Text>
                */}
            </ContainerInfo>
        </Container>
    );
};

export default ForgotPassword;

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
`;

const ContainerForm = styled.div`
    width: calc(100% - 410px);
    background-color: var(--background-light);
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 0 135px;
    align-items: center;
    overflow-y: scroll;
`;

const ContainerInfo = styled.div`
    width: 410px;
    background-color: var(--simple-brown);
    height: 100vh;
    text-align: left;
    color: black;
    padding: 33px 135px 33px 33px;
    background-image: url(./slideSignUp.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;
`;

export const Logo = styled.div`
    width: 100%;
    height: 50px;
    background-image: url(./logoVibe.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    top: 32px;
`;

export const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
    margin: 7px 0;
`

export const InputPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0 0px 0 20px;
    font-size: 15px;
    font-family: 'Inter';
`;