import { useContext, useEffect, useMemo, useState } from 'react'
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IClubExtended } from '@vibe/sdk/dist/interfaces/Club/ClubInterface';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import CommunityCard, { CardInterface, isConnectionsInterface } from './CommunityCard';
import { ClubDigestExtra } from '@vibe/sdk/dist/interfaces/Roster/getUserClubs';
import { getDataSDK, getDataSDKResponse } from '../../../helpers/backendHelper';
import { ContextSuccess } from '../../../Context';
import { getMyClubs } from '../../../store/actions/clubActions';
import { IUserGradeResponse } from '@vibe/sdk/dist/interfaces/Admin/User';
import { ModalityMappedInterface } from '../../../containers/ModalitiesServices/ModalityDescription';
import { getConnection } from '../../../helpers/modalities/MList';
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import Filter, { IComFilter, MockComFilter } from '../Filter';

const Communities = ({
    from,
    data,
    otherHandler,
    commData,
    onClickMS,
    commsFiltered = '',
}: {
    from: 'H-Profile' | 'Modality-Table'
    commsFiltered?: string
    data?: ModalityMappedInterface
    otherHandler?: (data?: any) => any
    commData?: CardInterface[]
    onClickMS: (data: boolean) => void
}) => {

    const dispatch = useDispatch();

    const currentSuccess = useContext(ContextSuccess);

    // Info of who is seeing the healer profile
    const isLoggedIn = useSelector((state: RootState) => state.getUser.isLoggedIn);
    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
    const listClubs: ClubDigestExtra[] = useSelector((state: RootState) => state.getClub.getMyClubs.filter((c) => c.clubType && c.clubType === 'CLUB')); // Clubs of who is seeing the healer profile

    // Info Healer
    const communitiesOrgGuide: IClubExtended[] = useSelector((state: RootState) => state.getClub.getClubsOrg);
    const clubsHealerIsGuide: IClubExtended[] = useSelector((state: RootState) => state.getClub.clubsImAdv.filter((c) => c.club_type === 'CLUB'));
    const healerAllClubs: IClubExtended[] = useSelector((state: RootState) => state.getClub.getMyClubsExtended);

    // Memos Healer Communities
    const guideClubsIDs: string[] = useMemo(() => clubsHealerIsGuide.map(c => c.id), [clubsHealerIsGuide]);
    const orgClubsIDs: string[] = useMemo(() => communitiesOrgGuide.map(c => c.id), [communitiesOrgGuide]);
    const healerBelongClubs: IClubExtended[] = useMemo(() =>
        healerAllClubs.filter((c) => c.club_type === 'CLUB' && !guideClubsIDs.includes(c.id) && !orgClubsIDs.includes(c.id)),
        [
            healerAllClubs,
            guideClubsIDs,
            orgClubsIDs
        ]);
    const healerIsGuide: IClubExtended[] = useMemo(() =>
        clubsHealerIsGuide.filter((c) => c.club_type === 'CLUB' && !orgClubsIDs.includes(c.id)),
        [
            clubsHealerIsGuide,
            orgClubsIDs
        ]);

    const [cardsActive, setCardsActive] = useState<string[]>([]);
    const [loadingButtons, setLoadingButtons] = useState<boolean>(false);

    const [actonFinished, setActionFinished] = useState<boolean>(false);

    const [comFilter, setComFilter] = useState<IComFilter>({ ...MockComFilter });
    const [comsFiltered, setComsFiltered] = useState<CardInterface[]>([]);

    const handleCardActive = (e: string, action: boolean) => {
        if (action) {
            setCardsActive([...cardsActive, e])
        }
        else {
            setCardsActive(cardsActive.filter(c => c !== e))
        }
    }

    useEffect(() =>
        setComsFiltered(mapCommunities().filter(
            (com: CardInterface) =>
                (!comFilter.orgID || com.orgId === comFilter.orgID) &&
                (!comFilter.modID || (com.modalitiesIds || []).includes(comFilter.modID))))
        , [communitiesOrgGuide, healerIsGuide, healerBelongClubs, listClubs, comFilter])

    const mapCommunities = (): CardInterface[] => {
        if (from === 'Modality-Table') {
            return data?.communities || [];
        }

        let healerClubs: IClubExtended[] = [...communitiesOrgGuide, ...healerIsGuide, ...healerBelongClubs];
        let clubsMapped: IClubExtended[] = [];

        if (commsFiltered.length > 0) clubsMapped = healerClubs.filter((club) => (club.servicesIds || []).includes(commsFiltered));
        else clubsMapped = healerClubs;

        return (clubsMapped || []).map((comm) => {
            const connection = getConnection(comm.id || '', listClubs);
            return {
                id: comm.id || '',
                name: comm.name || '',
                description: comm.description || '',
                joined: connection.joined,
                followed: connection.followed,
                guides: [...(comm.advisorsExtended ?? []), ...(comm.advInvites ?? []).map((adv) => ({
                    name: adv.name,
                }))],
                members: comm.members || 0,
                followers: comm.followers ?? 0,
                orgId: comm.org_id,
                modalitiesIds: comm.modalitiesIds || [],
            }
        })
    }

    const followCommunity = (club_id: string) => {
        if (isLoggedIn) {
            setActionFinished(false);
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Club.userAsFollower(club_id, userInfo?.username || '', dataSDK.token);
                })
                .then((results) => {
                    if (results.statusCode === 201) {
                        dispatch(getMyClubs(userInfo?.username || ''));
                        setActionFinished(true);
                        if (otherHandler) otherHandler();
                    }
                    else {
                        currentSuccess?.setSuccessData(false, true, "Error trying to follow", `Please, try later to follow the community.`);
                    }
                })
                .catch((error) => {
                    currentSuccess?.setSuccessData(false, true, "Error trying to follow", error);
                })
        }
        else {
            currentSuccess?.setLoginModal(true, false);
        }

    };

    const joinCommunity = (club_id: string) => {
        if (isLoggedIn) {
            setActionFinished(false);
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Club.userAsJoinRequest(club_id, userInfo?.username || '', dataSDK.token);
                })
                .then((results) => {
                    if (results.statusCode === 201) {
                        dispatch(getMyClubs(userInfo?.username || ''));
                        setActionFinished(true);
                        if (otherHandler) otherHandler();
                    }
                    else {
                        currentSuccess?.setSuccessData(false, true, "Error trying to join", `Please, try later to join the community.`);
                    }
                })
                .catch((error) => {
                    currentSuccess?.setSuccessData(false, true, "Error trying to join", error);
                })
        }
        else {
            currentSuccess?.setLoginModal(true, false);
        }

    };

    const handleOnClick = (type: 'join' | 'follow', data: string, action: boolean) => {
        //@todo function on action, on click when already requested join or follow
        switch (type) {
            case 'follow':
                followCommunity(data);
                break;
            case 'join':
                joinCommunity(data);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (isLoggedIn) setLoadingButtons(true);
        else setLoadingButtons(false);
    }, [isLoggedIn])

    return (
        <>
            <Titleh1>
                {from !== 'H-Profile' &&
                    <FontAwesomeIcon
                        onClick={() => onClickMS(false)}
                        icon={faArrowLeft}
                        size='sm'
                        color={"var(--absolute-zero)"}
                        className="arrowMS"
                    />
                }

                {data?.name}
            </Titleh1>

            <Row>
                <Filter from={from === 'H-Profile' ? 'PROFILE' : 'MODALITY'} type={'COM'} comFilter={comFilter} setComFilter={setComFilter} />
            </Row>

            <ListComm>
                {comsFiltered.map((area) => (
                    <CommunityCard
                        key={area.id}
                        active={cardsActive.includes(area.id)}
                        data={area}
                        onClickButton={handleOnClick}
                        onActivation={handleCardActive}
                        loadingButtons={loadingButtons}
                        actionFinished={actonFinished}
                    />
                ))}
            </ListComm>
        </>

    )
}

const Row = styled.div`
  display: flex;
  width: 100%;
  height: 35px;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
`;

const Titleh1 = styled.div`
  display: none;
  font-family: 'Inter-Bold';
  font-size: 24px;
  color: black;
  margin-bottom: 12px;
  @media only screen and (max-width: 700px) {
    display: flex;
    gap: 10px;
    align-items: center;

    & .arrowMS{
      font-size: 14px;
    }
  }
`

const TitleHealer = styled.div`
  display: flex;
  font-family: 'Inter-Bold';
  font-size: 24px;
  color: black;
  margin-bottom: 12px;
`

const ListComm = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit,
      minmax(175px,
        195px));
    grid-template-rows: repeat(auto-fit,
      minmax(225px,
        250px));
    overflow: scroll;
    grid-row-gap: 15px;
    grid-column-gap: 15px;

    @media only screen and (max-width: 500px) {
        grid-template-columns: 1fr;
    }
`

const CardItem = styled.div`
    width: 203px;
    border: 1px solid var(--mountain-mist);
    background-color: white;
    border-radius: 10px;
    margin: 0 12px 14px 12px;
    padding: 17px;
    color: black;
    text-align: center;
    position: relative;
    transform: perspective(500px) rotateY(0deg)
`

const CardBack = styled.div`
    border: 1px solid var(--mountain-mist);
    background-color: white;
    border-radius: 10px;
    margin: 0 12px 14px 12px;
    padding: 17px;
    color: black;
    transform: perspective(500px) rotateY(180deg)
`

const Card = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    position: relative;
    width: 228px;
    height: 263px;
    
    & > div{
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        backface-visibility: hidden;
        transition: 1s;
        flex-direction: column;
    }

    &.active ${CardItem}{
        transform: perspective(500px) rotateY(180deg)
    }

    &.active ${CardBack}{
        transform: perspective(500px) rotateY(360deg)
    }

`

const Area = styled.div`
    border: 1px solid darkgrey;
    width: fit-content;
    background-color: white;
    padding: 7px 12px;
    border-radius: 17px;
    margin-right: 6px;
    margin-bottom: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    &:hover {
        background-color: #FAF0E6
    }
`
const ListAreas = styled.div`
    width: 685px;
    margin: 24px auto;
    display: flex;
    flex-wrap: wrap;
`

const TypeCard = styled.div`
    margin-top: 30px;
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    & span{
        color: black;
        font-weight: bold;
        font-family: 'Inter-Bold';
    }
`

const IconButtonFtr = styled(FontAwesomeIcon)`
    padding: 0 10px
`;


const IconRotate = styled(FontAwesomeIcon)`
    position: absolute;
    top: 11px;
    font-size: 15px;
    right: 11px;
    background-color: var(--simple-gray-2);
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
`;

export default Communities