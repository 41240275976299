import { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalBaseProps from "../../../../interfaces/modals/ModalBaseProps";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ModalDiscardChanges from "../../../SetupSetup/ModalDiscardChanges";
import { getDataSDK, getDataSDKResponse } from "../../../../helpers/backendHelper";
import { ContextSuccess } from "../../../../Context";
import { BaseFieldState, getFieldState } from '../../../BaseComponents/utils';
import ButtonSpinner from '../../../BaseComponents/ButtonSpinner';
import { Imp } from '../../../signupsetuphealerind';
import ErrorField from '../../../BaseComponents/ErrorField';
import { onHideType } from '../../../BaseComponents/Modals/ModalUnsaved';

interface CreateListModalProps extends ModalBaseProps {
}

const CreateListModal = ({
    show,
    onHide,
}: CreateListModalProps) => {

    const { t } = useTranslation();
    const currentSuccess = useContext(ContextSuccess);
    const [loadingAssign, setLoadingAssign] = useState<boolean>(false);
    const [listName, setListName] = useState<BaseFieldState<string>>(getFieldState(''));

    const onCreate = () => {
        if (errorsInForm()) return;

        setLoadingAssign(true);
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.createTA({ name: listName.value!, createdAt: `${new Date().valueOf()}` })
            })
            .then((response) => {
                if (response.statusCode === 201) {
                    currentSuccess?.setSuccessData(true, true, t('toast_TACreated'));
                    onHide('reload');
                    cleanForm();
                }
                else {
                    currentSuccess?.setSuccessData(false, true, 'Error creating condition treated.');
                }
            })
            .finally(() => setLoadingAssign(false))
    }

    const errorsInForm = () => {
        let errors = false;

        if (!listName.value || listName.value.length <= 0) {
            errors = true;
            setListName({
                ...listName,
                showError: true,
                error: 'Name is required',
            })
        } else {
            setListName(getFieldState(listName.value));
        }

        return errors;
    }

    const onChangeListName = (event: React.FormEvent<HTMLInputElement>) => {
        if (event) {
            setListName({
                ...listName,
                value: event?.currentTarget.value
            });
        }
    }

    const cleanForm = () => {
        setListName(getFieldState(''));
    }

    const onHideDiscard = (type: onHideType) => {
        switch (type) {
            case 'save':
                onCreate();
                currentSuccess?.setModalsOff();
                break;
            case 'close':
                currentSuccess?.setModalsOff();
                break;
            case 'discard':
                cleanForm();
                currentSuccess?.setModalsOff();
                onHide();
                break;
            default:
                break;
        }
    }

    const discard = () => {
        if (listName.value === '') {
            onHide();
            return;
        }
        currentSuccess?.setModalWarning('UNSAVED', onHideDiscard, 'Do you want to discard changes?')
    }

    return (
        <>
            <Modal show={show} onHide={discard}>
                <Modal.Header>
                    <Modal.Title>
                        <Content>Create New condition treated</Content>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Label>Name<Imp>*</Imp></Label>
                        <Input
                            value={listName.value}
                            onChange={onChangeListName}
                        />
                        {(listName.showError) && (<ErrorField error={listName.error} />)}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <WrapButtons>
                        <Button onClick={discard}>Cancel</Button>
                        <WrapSpinner>
                            <ButtonSpinner
                                loading={loadingAssign}
                                handleClick={onCreate}
                                text={'Create'}
                                hasIcon={false}
                            />
                        </WrapSpinner>
                    </WrapButtons>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateListModal;

const Content = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  text-align: left;
  color: var(--simple-blue-dark);
  margin-top: 30px;
  margin-left: 10px;
`;

const WrapSpinner = styled.div`
    width: 75%;
`;

const WrapButtons = styled.span`
    width: 100%;
    display: flex;
    gap: 15px;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 200px;
  min-width: 100px;
  height: 100%;
  padding: 10px 20px;
  border-radius: 11px;
  background-color: white;
  border: 1px solid var(--t-blue);

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: var(--t-blue);
  gap: 10px;

  cursor: pointer;
`;

const Label = styled.div`    
    width: 100%;
    text-align: left;
    margin: 3px 0;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`;

const Row = styled.div`
    width: '100%';
    display: flex;
    flex-direction: column;
`;

const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
    margin-bottom: 10px;
`