import "./styles.css"
import React from "react";

function Index(props: any) {
    const handleCloseClick = () => {
        props.onClose();
    }
    console.log(`here: ${JSON.stringify(props)}`)
    return (
        <div className="container-post-embed-preview">
            {props.showX && (<span className="close" onClick={handleCloseClick}>&times;</span>)}
            <div className="embed-link" dangerouslySetInnerHTML={{ __html: props.embedLink }}></div>
        </div>
    )
}

export default Index;