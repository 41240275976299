import { useDispatch, useSelector } from "react-redux";
import { getOrganizationInfo } from "../../../store/actions/orgsActions";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { updateOrg, updateLogoOrg, uploadImage } from "../../../store/actions/orgsActions";
import { useTranslation } from "react-i18next";
import { CountryOption } from "../../../hooks/Locations/useActiveCountry";
import { HeaderAdmin, ContentAdmin, ColumnA, ColumnB, LabelTitle, LogoPlaceHolder, PlaceInfoStatement, WrapperInfo, WrapperLogo } from "./MyOrganization";
import ButtonSpinner from "../../BaseComponents/ButtonSpinner";
import styled from "styled-components";
import { IUserGradeResponse } from "@vibe/sdk/dist/interfaces/Admin/User";
import { RootState } from "../../../store/store";
import AddProfilePhoto from "../../BaseComponents/ProfilePhoto/AddProfilePhoto";
import { LogoInterface } from "../../signupsetuphealer/Introduction";
import { ContextSuccess } from "../../../Context";
import SelectorCountry from "../../BaseComponents/Location/SelectorCountry";
import { StateOption } from "../../../hooks/Locations/useActiveState";
import SelectorState from "../../BaseComponents/Location/SelectorState";
import { getIdOrgUser } from "../../../helpers/userHelper";
import TextEditor from "../../BaseComponents/TextEditor";
import { BaseFieldState, getFieldState } from "../../BaseComponents/utils";
import SelectorCity from "../../BaseComponents/Location/SelectorCity";
import { CityOption } from "../../../hooks/Locations/useStateCities";
import ModalImageEditorCrop from "../../ModalImageEditorCrop";
import { onHideType } from "../../BaseComponents/Modals/ModalUnsaved";

const UpdateMyOrganization = ({
    goBack,
}: {
    goBack: () => void
}) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const currentSuccess = useContext(ContextSuccess);

    const userRoles = useSelector((state: RootState) => state.getUser.userRoles);
    const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);
    const org = useSelector((state: RootState) => state.getOrgs.organizationInfo);
    const loadingUploadLogoOrg = useSelector((state: RootState) => state.getOrgs.loadingUploadLogoOrg || false);
    const loadingUpdateOrg = useSelector((state: RootState) => state.getOrgs.loadingUpdateOrg || false);

    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [newLogo, setNewLogo] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [activeCrop, setActiveCrop] = useState(false);
    const [searchCountry, setSearchCountry] = useState('');
    const [searchState, setSearchState] = useState('');
    const [searchCity, setSearchCity] = useState('');

    const [logoOrg, setLogoOrg] = useState<BaseFieldState<LogoInterface>>(getFieldState({ file: '', fileLink: '' }));
    const [schoolName, setSchoolName] = useState<string>('');
    const [schoolWebsite, setSchoolWebsite] = useState<string>('');
    const [mission, setMission] = useState<string>('');
    const [postalCode, setPostalCode] = useState<string>('');
    const [primaryContact, setPrimaryContact] = useState({
        name: '',
        email: '',
        phoneNumber: 3000000000
    });
    const [orgCountry, setOrgCountry] = useState<BaseFieldState<CountryOption>>(getFieldState({ label: '', value: '' }));
    const [orgState, setOrgState] = useState<BaseFieldState<StateOption>>(getFieldState({ label: '', value: '' }));
    const [orgCity, setOrgCity] = useState<BaseFieldState<CityOption>>(getFieldState({ label: '', value: '' }));

    const [address, setAddress] = useState<string>('');

    const loadData = () => {
        setLogoOrg(getFieldState({
            file: org.logo || '',
            fileLink: org.logo || ''
        }));
        setSchoolName(org.name || '');
        setSchoolWebsite(org.website || '');
        setOrgCountry({
            ...orgCountry,
            value: {
                label: org.country || '',
                value: org.countryCode || ''
            }
        });
        setOrgState({
            ...orgState,
            value: {
                label: org.state || '',
                value: !org.stateCode ? '' : (org.stateCode.length > 3) ? org.stateCode.split('_')[org.stateCode.split('_').length - 1] : org.stateCode,
            }
        });
        setOrgCity({
            ...orgCity,
            value: {
                label: org.city || '',
                value: org.city || ''
            }
        });
        setPostalCode(org.zipcode || '');
        setPrimaryContact({
            name: (org.primaryContact || {}).name || '',
            email: (org.primaryContact || {}).email || '',
            phoneNumber: (org.primaryContact || {}).phoneNumber || 3000000000
        });
        setAddress(org.address || '');
        setMission(org.mission || '');
    }

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSchoolName(event.target.value);
    }

    const handleChangeWebsite = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSchoolWebsite(event.target.value);
    }

    const handleChangePostalCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPostalCode(event.target.value);
    }

    const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(event.target.value);
    }

    const handleOnChangePrimary = (
        e: React.ChangeEvent<HTMLInputElement>,
        inputName: string,
    ) => {

        if (inputName === 'name') {
            setPrimaryContact({
                ...primaryContact,
                name: e.target.value,
            });
        }

        if (inputName === 'email') {
            setPrimaryContact({
                ...primaryContact,
                email: e.target.value,
            });
        }

        if (inputName === 'phoneNumber') {
            setPrimaryContact({
                ...primaryContact,
                phoneNumber: Number(e.target.value),
            });
        }
    };

    const handleSaveImage = () => new Promise((resolve, reject) => {
        const dataFile = URL.createObjectURL(logoOrg.value?.file as any);
        let contentType: any;
        let file: any;
        fetch(dataFile)
            .then(res => res.blob())
            .then(blob => {
                contentType = dataFile.split(';')[0].split(':')[1];
                const extension = contentType.split('/')[1]
                file = new File([blob], 'Logo' + org?.id, { type: contentType });

                if (file) {
                    return dispatch<any>(updateLogoOrg(
                        file.type,
                        file.name + '.' + extension,
                        "school_logo"
                    ))
                }
                else {
                    return Promise.reject(new Error("File not found"));
                }
            })
            .then((results: any) => {
                if (results.body) {

                    dispatch<any>(uploadImage(
                        results.body,
                        contentType,
                        file,
                    ))
                        .then((result: any) => {
                            if (result.status === 200) {
                                const urlImage = results.body.split('?')[0];
                                setLogoOrg({
                                    ...logoOrg,
                                    value: {
                                        ...logoOrg.value as LogoInterface,
                                        fileLink: urlImage,
                                        file: urlImage
                                    }
                                })
                                resolve(urlImage);
                            }
                            else {
                                console.log(result)
                                reject();
                            }
                        })
                        .catch((error: any) => {
                            console.log(error)
                            reject();
                        })

                }
            })
    });

    const checkForm = () => {
        if (schoolWebsite === "") {
            currentSuccess?.setSuccessData(false, true, "Website is mandatory", "");
            return false;
        }

        return true;
    }

    const handleSave = async () => {

        let logoLink: string = org?.logo || '';

        if (newLogo && logoOrg.value?.file !== '') {
            logoLink = await handleSaveImage() as string;
        }

        if (!checkForm()) {
            return
        }

        dispatch<any>(updateOrg(
            {
                name: schoolName,
                website: schoolWebsite,
                lowGrade: 12,
                highGrade: 12,
                countryCode: orgCountry.value?.value || '',
                city: orgCity.value?.value || '',
                zipcode: postalCode,
                logo: logoLink || '',
                yearbook: '',
                newspaper: '',
                primaryContact: primaryContact,
                lowAge: 18,
                highAge: 18,
                stateCode: orgState.value?.value || '',
                address: address,
                mission: mission,
            },
            org.id,
            org.status === 'ACTIVE' ? false : true,
        ))
            .then((results: any) => {
                dispatch(getOrganizationInfo(getIdOrgUser(user.rolesByOrg, user.organizations)));
                currentSuccess?.setSuccessData(true, true, "Organization data updated");
                goBack();
            })
            .catch((error: any) => {
                console.log(error)
                currentSuccess?.setSuccessData(false, true, "Error updating org", error);
            })
    }

    function handleCloseModal(e: any, type: any) {
        setShowModal(() => false);
    };

    function handleCropImage(e: any) {

        const dataFile = URL.createObjectURL(logoOrg.value?.file as any);
        let contentType: any;
        let file: any;

        fetch(e)
            .then(res => res.blob())
            .then(blob => {

                // contentType = logo?.value?.file.type;
                contentType = dataFile.split(';')[0].split(':')[1];
                const extension = contentType.split('/')[1]
                file = new File([blob], 'LogoOrg ' + org?.id, { type: contentType });

                setActiveCrop(true)
                setLogoOrg({
                    ...logoOrg,
                    value: {
                        fileLink: '',
                        file: file,
                    }
                })
            })
    };

    const showModalCrop = (e: BaseFieldState<LogoInterface>, isDelete = false) => {
        setLogoOrg(e);
        if (!isDelete) setShowModal(true);
        setActiveCrop(false);
    }

    const onHideDiscard = (type: onHideType) => {
        switch (type) {
            case 'save':
                handleSave();
                currentSuccess?.setModalsOff();
                break;
            case 'close':
                currentSuccess?.setModalsOff();
                break;
            case 'discard':
                currentSuccess?.setModalsOff();
                goBack();
                break;
            default:
                break;
        }
    }

    const handleCancel = () => {
        currentSuccess?.setModalWarning('UNSAVED', onHideDiscard, 'Do you want to discard changes?')
    }

    useEffect(() => {
        if (org) loadData();
    }, [org]);

    useEffect(() => {
        if (org) loadData();
    }, []);

    return (
        <ContentAdminUpdate>
            <HeaderAdmin>
                <WrapperButtons>
                    <ButtonCancel onClick={handleCancel}>Cancel</ButtonCancel>
                    <WrapperButton>
                        <ButtonSpinner
                            loading={loadingUpdateOrg || loadingUpdate}
                            handleClick={handleSave}
                            text={'Update'}
                            hasIcon={false}
                        />
                    </WrapperButton>
                </WrapperButtons>
            </HeaderAdmin>
            <ColumnA>
                <WrapperLogo>
                    Logo
                    <ModalImageEditorCrop
                        preview={logoOrg}
                        handleSaveImage={handleCropImage}
                        show={showModal}
                        onHide={handleCloseModal}
                        isProfilePhoto={true}
                    />
                    <AddProfilePhoto
                        title={'Add organization logo'}
                        logo={logoOrg}
                        setLogo={(e: BaseFieldState<LogoInterface>, isDelete?: boolean) => showModalCrop(e, isDelete)}
                        setNewLogo={setNewLogo}
                        notSetup={true}
                    />
                </WrapperLogo>
                <WrapperInfo>
                    <PlaceInfo
                        type="text"
                        value={schoolName || ''}
                        onChange={handleChangeName}
                    />
                    Organization Name
                </WrapperInfo>
                <WrapperInfo>
                    <PlaceInfo
                        type="text"
                        value={schoolWebsite || ''}
                        onChange={handleChangeWebsite}
                    />
                    Organization Website
                </WrapperInfo>
                <WrapperInfo>
                    <PlaceInfoNoInput>{`${org?.orgAdmin?.firstname} ${org?.orgAdmin?.lastname}`}</PlaceInfoNoInput>
                    Administrator
                </WrapperInfo>
                <WrapperInfo>
                    <SelectorCountry
                        country={orgCountry}
                        setCountry={setOrgCountry}
                        searchCountry={searchCountry}
                        setSearchCountry={setSearchCountry}
                    />
                    Country
                </WrapperInfo>
                <WrapperInfo>
                    <SelectorState
                        country={orgCountry.value?.value ?? ''}
                        state={orgState}
                        setState={setOrgState}
                        searchState={searchState}
                        setSearchState={setSearchState}
                    />
                    State
                </WrapperInfo>
                <WrapperInfo>
                    <SelectorCity
                        state={orgState.value?.value || ''}
                        city={orgCity}
                        setCity={setOrgCity}
                        searchCity={searchCity}
                        setSearchCity={setSearchCity}
                    />
                    City
                </WrapperInfo>
                <WrapperInfo>
                    <PlaceInfo
                        type="text"
                        value={address || ''}
                        onChange={handleChangeAddress}
                    />
                    Address
                </WrapperInfo>
                <WrapperInfo>
                    <PlaceInfo
                        type="text"
                        value={postalCode || ''}
                        onChange={handleChangePostalCode}
                    />
                    Zipcode
                </WrapperInfo>
            </ColumnA>
            <ColumnB>
                <LabelTitle>About you</LabelTitle>
                <WrapperLogo>
                    <TextEditor
                        value={mission || ''}
                        setValue={setMission}
                        charLimit={1000}
                        maxHeight={'300px'}
                        placeholder='Please describe your organization...'
                    />
                    Organization Statement
                </WrapperLogo>
                <WrapperInfo>
                    <PlaceInfo
                        type="text"
                        value={primaryContact.name || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleOnChangePrimary(e, 'name')
                        }
                    />
                    Primary Contact
                </WrapperInfo>
                <WrapperInfo>
                    <PlaceInfo
                        type="text"
                        value={primaryContact.phoneNumber || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleOnChangePrimary(e, 'phoneNumber')
                        }
                    />
                    Primary Contact Phone
                </WrapperInfo>
                <WrapperInfo>
                    <PlaceInfo
                        type="text"
                        value={primaryContact.email || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleOnChangePrimary(e, 'email')
                        }
                    />
                    Primary Contact Email
                </WrapperInfo>
            </ColumnB>
        </ContentAdminUpdate>
    )
};

export const ContentAdminUpdate = styled.div`
    display:  grid;
    width: 100%;
    height: 100%;

    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 0.1fr repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 20px;

    overflow: scroll;
`;

const TextArea = styled.div`
    width: 100%;
    border-radius: 12px;
    background-color: white;
    border: 1px solid var(--simple-gray);

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: var(--onyx);
    padding: 10px;
    height: 150px;
`;

const Button = styled.button`
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;

    width: fit-content;
    min-width: 100px;
    height: 40px;
    padding: 10px 20px;
    border-radius: 11px;
    background-color: var(--simple-blue-dark);
    border: 0;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: white;

    width: 100%;

    cursor: pointer;
`;

const ButtonCancel = styled(Button)`
    background-color: white;
    border: 1px solid var(--t-blue);
    color: var(--t-blue);
    height: 100%;
`;

const WrapperButton = styled.div`
    width: 100%;
    min-width: 100px;
    display: flex;
    height: 100%;
`;

const WrapperButtons = styled.div`
    gap: 20px;
    display: flex;
`;

const PlaceInfo = styled.input`
    align-items: center;
    background-color: white;
    border-radius: 9px;
    display: flex;
    height: 39px;
    width: 100%;
    padding: 0 10px;

    color: black;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: normal;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    text-align: left;

    outline: none;
    border: 1px solid var(--simple-gray);
`;

const PlaceInfoNoInput = styled.div`
    align-items: center;
    background-color: var(--t-blue);
    border-radius: 9px;
    display: flex;
    height: 39px;
    width: 100%;
    padding: 0 10px;

    color: white;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: normal;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    text-align: left;
    opacity: 0.7;
`;

export default UpdateMyOrganization;
