import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Menu from '../Menu'
import { RootState } from '../../store/store';
import styled from 'styled-components';
import { getStatusColor } from '../../helpers/chat/statusHelper';
import { ChatServiceInterface } from '../../interfaces/chat/ChatServiceInterface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons";
import { ContextSuccess } from '../../Context';
import { IconClicker } from '../Home/HeaderHome/styles';
import { IUserGradeResponse } from '@vibe/sdk/interfaces/Admin/User';
import ModalEventBrite from "./ModalEventBrite"
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { getUser } from '../../store/actions/userActions';
import IResponseError from '@vibe/sdk/dist/interfaces/IResponseError';
import IPaymentInfo from "../../interfaces/user/IPaymentINfo";

interface HeaderProps {
  //todo -> put types
  handleActiveNotification?: any;
  ChatServiceInstance?: ChatServiceInterface;
  isHealerProfile?: boolean;
  isHealerBulletin?: boolean;
  isModalityTable?: boolean;
  isGoodVibes?: boolean;
  isPricing?: boolean;
  tabActive?: any,
}

const Index = (props: HeaderProps) => {

  const currentSuccess = useContext(ContextSuccess);
  const userPayment: IPaymentInfo = useSelector((state: RootState) => state.getUser.paymentInfo);
  const isLoggedIn = useSelector((state: RootState) => state.getUser.isLoggedIn);
  const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);
  const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
  const userRoles = useSelector((state: RootState) => state.getUser.userRoles);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isActive, setActive] = useState(false);
  const [activeChat, setActiveChat] = useState(false);
  const [hideBanner, setHideBanner] = useState(false);
  const [showModalEventBrite, setShowModalEventBrite] = useState(false);

  const handleToggle = () => {
    setActive(false);
  };

  const handleImage = () => {
    if (isLoggedIn) {
      setActive(true);
    }
  };

  const handleLogo = () => {
    if (isLoggedIn) {
      navigate('../app');
      props.tabActive('BULLETIN');
    }
    else {
      navigate('../');
    }
  }

  const activeNotification = () => {
    props.handleActiveNotification(true)
  }

  function handleCloseModalEventBrite() {
    setShowModalEventBrite(false);
  };

  const handleRemind = () => {
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Events.eventBriteRemind(userInfo?.username || '', dataSDK.token);
      })
      .then((results) => {
        if (results.statusCode === 200) {
          dispatch(getUser(userInfo?.username || ''));
          setHideBanner(true);
        }
        else {
          console.log((results.body as IResponseError).error || (results.body as IResponseError).message)
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const showEventBriteBanner = (): boolean => (
    isLoggedIn && (userRoles.includes('HEALER') || userRoles.includes('ORGADMIN'))
  ) && !hideBanner && (user.eventbriteBanner === undefined || user.eventbriteBanner) && userPayment.hasActiveSubscription;

  console.log(userPayment)

  useEffect(() => {
    if (location.pathname.includes('chat')) {
      setActiveChat(true);
    }
  }, [])

  useEffect(() => {
    if (!activeChat && props.ChatServiceInstance?.chatConnected) props.ChatServiceInstance.getChatList();
  }, [activeChat])

  return (
    <Header isHealerProfile={props.isHealerProfile ?? false} isHealerBulletin={props.isHealerBulletin ?? false} isGoodVibes={props.isGoodVibes ?? false}>
      <Logo onClick={handleLogo} />

      {showEventBriteBanner() &&
        <>
          <Eventbrite>
            <LogoEventB />
            <TextEventB>
              We are integrated with Eventbrite to simplify your events planning
            </TextEventB>
            <ButtonAsync onClick={() => setShowModalEventBrite(true)}>
              Sync account
              <FontAwesomeIcon
                icon={faArrowsRotate}
                size='sm'
                color={'var(--white)'}
              />
            </ButtonAsync>
            <TextSub onClick={handleRemind}>Remind me later</TextSub>
          </Eventbrite>
          <ModalEventBrite show={showModalEventBrite} onHide={handleCloseModalEventBrite} />
        </>

      }

      <HeaderOptions>

        {(!isLoggedIn) ? (
          <>
            {(props.isModalityTable ?? false) && (
              <>
                <LoginLabel onClick={() => navigate('/#about-vibe')}>
                  About Vibe
                </LoginLabel>
                {/*<LoginLabel onClick={() => navigate('../')}>
                  Advisory Body
                </LoginLabel>*/}
                <LoginLabel onClick={() => navigate('/#request-info')}>
                  Contact Us
                </LoginLabel>
                <LoginLabel onClick={() => navigate('../../../pricing')}>
                  Pricing
                </LoginLabel>
              </>
            )}
            <LoginLabel onClick={() => currentSuccess?.setLoginModal(true, false)}>
              Log in
            </LoginLabel>
          </>
        ) : process.env.REACT_APP_SHOW_CHAT === 'true' && (
          <IconClicker
            icon="message"
            color={activeChat ? "var(--lavender)" : "black"}
            size="lg"
            onClick={() => navigate('/chat')}
          />
        )}

        {(!activeChat && props.ChatServiceInstance?.newMessages !== 0 && isLoggedIn) &&
          <NotificationChatCircle key={props.ChatServiceInstance?.newMessages || 0}
            width={(props.ChatServiceInstance?.newMessages || 0) > 99 ? '25px' : (props.ChatServiceInstance?.newMessages || 0) > 10 ? '20px' : '17px'}
          >
            {(props.ChatServiceInstance?.newMessages || 0) > 99 ? '99+' : props.ChatServiceInstance?.newMessages}
          </NotificationChatCircle>
        }

        {(isLoggedIn) &&
          <ImageContainerHeader onClick={handleImage}>
            <CircleStatusHeader status={props.ChatServiceInstance?.connectedUser.status || 'available'} />
            <AvatarHeader
              src={user.avatar ? user.avatar : `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png`}
              alt="profile-pic"
            />
          </ImageContainerHeader>
        }

        {(!isLoggedIn) && <IconHealer onClick={() => navigate('/modalities-services')} />}

        {(isLoggedIn) &&
          <Menu
            show={isActive}
            onHide={handleToggle}
            activeNotification={activeNotification}
            ChatServiceInstance={props.ChatServiceInstance}
          />
        }
      </HeaderOptions>
    </Header>
  )
};

export default Index;

const TextSub = styled.div`
  color: #333;
  font-family: 'Inter-SemiBold';
  font-size: 14px;
  font-style: normal;
  text-decoration-line: underline;
  cursor: pointer;
`

const ButtonAsync = styled.div`
  border-radius: 8px;
  background: #29303B;
  color: white;
  display: flex;
  gap: 8px;
  padding: 8px 13px;
  color: #FFF;
  font-family: 'Inter-Bold';
  font-size: 16px;
  cursor: pointer;
`

const TextEventB = styled.div`
  color: #333;
  font-family: 'Inter-Bold';
  letter-spacing: 0.1px;
  font-size: 14px;
`

const Eventbrite = styled.div`
  width: fit-content;
  padding: 5px 16px;
  background-color: #dff7f3;
  border-radius: 12px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(217,217,217,1);
  -moz-box-shadow: 2px 2px 5px 0px rgba(217,217,217,1);
  box-shadow: 2px 2px 5px 0px rgba(217,217,217,1);
`

const CoverImg = styled.img`
  width: 500px;
`

const IconHealer = styled.div`
  width: 40px;
  height: 40px;
  background-color: var(--electric-yellow-2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: relative;
  background-image: url(../icon_healer.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80% auto;
  border: 2px solid var(--simple-blue-dark);
  cursor: pointer;
`

interface CircleStatusProps {
  status: string;
}

export const AvatarHeader = styled.img`
  width: 38px;
  height: 38px;
  min-width: 38px;
  min-height: 38px;
  max-width: 38px;
  max-height: 38px;
  border-radius: 100%;
  object-fit: cover;
  border: 1px solid var(--simple-blue-dark);
`;

const LoginLabel = styled.span`
  color: var(--simple-blue-dark);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 16px;
  cursor: pointer;
`;

const logoVibe = require("../../assets/img/logoVibe.png");
const Logo = styled.div`
    width: 110px;
    height: 50px;
    background-image: url("${logoVibe}");
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
`;

const logoEventBrite = require("../../assets/img/eventbrite.png");
const LogoEventB = styled.div`
    width: 24px;
    height: 24px;
    background-image: url("${logoEventBrite}");
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
`;

export const CircleStatusHeader = styled.div`
  padding: 6px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  background-color: ${(props: CircleStatusProps) => getStatusColor(props.status.toLocaleLowerCase())};
`;

interface NotificationChatProps {
  width: string;
}

export const NotificationChatCircle = styled.div`
    background-color: var(--simple-gray);
    border-radius: 50px;
    width: ${(props: NotificationChatProps) => props.width};
    height: 17px;
    position: absolute;
    font-size: 15px;
    font-family: 'Inter';
    color: black;
`;

interface HeaderStyleProps {
  isHealerBulletin: boolean
  isHealerProfile: boolean
  isGoodVibes: boolean
}

const Header = styled.div`
  position: fixed;
  width: 100%;
  height: 80px;
  top: 0;
  left: 0;
  z-index: 3;
  display: block;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: var(--border-gray);
  /* background-color: ${(props: HeaderStyleProps) => (props.isHealerProfile || props.isHealerBulletin || props.isGoodVibes) ? "white" : "var(--t-off-white)"}; */
  background-color: white;
  padding: ${(props: HeaderStyleProps) => (props.isHealerProfile || props.isHealerBulletin) ? "0px 136px" : "0px 30px"};
    /* Tara new Colors */

  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

const HeaderOptions = styled.div`
  // width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;

export const ImageContainerHeader = styled.div`
  height: 41px;
  width: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--onyx);
  border-radius: 36px;
  position: relative;
  cursor: pointer;
`;

const WrapperImageProfile = styled.div`
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto; 
`;