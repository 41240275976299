import styled from "styled-components";
import { ORGSTATUS } from "../../SuperCenter/Healers/Healers";

export type StatusType = 'ACTIVE' | 'INACTIVE' | 'PENDING_CONFIRMATION' | 'PENDING_APPROVE' | 'PENDING_APPROVAL' | 'DELETED';

const StatusBudged = ({
    status
}: {
    status: StatusType
}) => {
    const getColor = (): string => {
        if(status === 'ACTIVE') return '#C6F1DA';
        if(status === 'INACTIVE') return '#D7E4FF';
        if(status === 'PENDING_CONFIRMATION') return '#ffd5d5';
        if(status === 'PENDING_APPROVE' || status === 'PENDING_APPROVAL') return '#feede2';
        if(status === 'DELETED') return '#CCE7FF';
        return '#DEE3E7';
    }

    return (
        <WrapperStatus color={getColor()}>
            {ORGSTATUS[((status || '') + '') || '']}
        </WrapperStatus>
    )
}

export default StatusBudged;

const WrapperStatus = styled.div`
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    color: #333;
    background-color: ${({ color }: { color: string }) => color};
    font-family: 'Inter';
    font-size: 14px;
`;