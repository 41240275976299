import React, { useState } from 'react'
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronDown, faWarning } from "@fortawesome/pro-regular-svg-icons";
import OutsideClickHandler from "react-outside-click-handler";
import 'react-phone-number-input/style.css'
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input'
import { Backend, MicroServices } from '../../helpers/backendHelper';
import ButtonSpinner from '../../components/BaseComponents/ButtonSpinner';

const InterestMap: Record<string, string> = {
    'IND': 'Individual seeker',
    'HEA': 'Healer',
    'INV': 'Investor',
    'PAR': 'Partner',
    'PRE': 'Press',
    'OTH': 'Other',
}

const AboutVibe = () => {

    const [showOptions, setShowOptions] = useState(false)
    const [interest, setInterest] = useState<string>();
    const [interestError, setInterestError] = useState<[boolean, string]>();
    const [firstName, setFirstName] = useState<string>();
    const [firstNameError, setFirstNameError] = useState<[boolean, string]>();
    const [lastName, setLastName] = useState<string>();
    const [lastNameError, setLastNameError] = useState<[boolean, string]>();
    const [email, setEmail] = useState<string>();
    const [emailError, setEmailError] = useState<[boolean, string]>();
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [tellMore, setTellMore] = useState('');


    const handleOption = (option: string) => {
        setShowOptions(false);
        setInterest(option);
    }

    const checkForm = (): boolean => {
        let errors = false;
        if (!interest || interest.length === 0) {
            setInterestError([true, 'Please select an option.']);
            errors = true;
        } else {
            setInterestError([false, '']);
        }
        if (!firstName || firstName.length === 0) {
            setFirstNameError([true, 'First Name is required']);
            errors = true;
        } else {
            setFirstNameError([false, '']);
        }
        if (!lastName || lastName.length === 0) {
            setLastNameError([true, 'Last Name is required']);
            errors = true;
        } else {
            setLastNameError([false, '']);
        }
        if (!email || email.length === 0) {
            setEmailError([true, 'Email is required']);
            errors = true;
        } else if (!email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
            setEmailError([true, 'Email is not in the right format']);
            errors = true;
        } else {
            setEmailError([false, '']);
        }
        return !errors;
    }

    const handleSend = (event: any) => {
        event.preventDefault();
        if (!checkForm()) {
            return;
        }
        setLoading(true);
        Backend(MicroServices.AccessManager, '/contact-us', {
            method: 'POST',
            body: JSON.stringify({
                interest,
                firstName,
                lastName,
                email,
                phoneNumber,
                tellMore
            })
        })
            .then(() => {
                setInterest(undefined);
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhoneNumber('');
                setTellMore('');
            })
            .finally(() => setLoading(false))
    }

    return (
        <ContainerB id="request-info">
            <ContentWrap>
                {/* <Title>
                    Better Vibes Have Arrived
                </Title> */}
                <TextTellUs>
                    Tell us more about your interest in Vibe...
                </TextTellUs>
                <ContainerForm>
                    <Inputs>
                        <Row1>
                            <Label>What is your interest in Vibe <Imp>*</Imp></Label>
                            <OutsideClickHandler
                                onOutsideClick={() => {
                                    setShowOptions(false);
                                }}
                                display="flex"
                            >
                                <Select onClick={() => setShowOptions(!showOptions)}>
                                    {interest ? InterestMap[interest] : 'Select an option'}
                                    <StyledFontAwesomeIcon
                                        icon={faChevronDown}
                                        size='sm'
                                    />
                                </Select>
                                {showOptions &&
                                    <Items>
                                        <Item onClick={() => handleOption('IND')}>
                                            Individual seeker
                                        </Item>
                                        <Item onClick={() => handleOption('HEA')}>
                                            Healer
                                        </Item>
                                        <Item onClick={() => handleOption('INV')}>
                                            Investor
                                        </Item>
                                        <Item onClick={() => handleOption('PAR')}>Partner</Item>
                                        <Item onClick={() => handleOption('PRE')}>Press</Item>
                                        <Item onClick={() => handleOption('OTH')}>
                                            Other
                                        </Item>
                                    </Items>
                                }
                            </OutsideClickHandler>
                        </Row1>
                    </Inputs>
                    <Row2>
                        {interestError?.[0] && (<ContainerError>
                            <FontAwesomeIcon
                                icon={faWarning}
                                color='#d92b60'
                                style={{
                                    marginRight: '10px'
                                }}
                            />
                            {interestError?.[1]}
                        </ContainerError>)}
                    </Row2>
                    <Inputs>
                        <Row2>
                            <Label>First name <Imp>*</Imp></Label>
                            <Input onChange={(event) => setFirstName(event.currentTarget.value)} value={firstName}></Input>
                            {firstNameError?.[0] && (<ContainerError>
                                <FontAwesomeIcon
                                    icon={faWarning}
                                    color='#d92b60'
                                    style={{
                                        marginRight: '10px'
                                    }}
                                />
                                {firstNameError?.[1]}
                            </ContainerError>)}
                        </Row2>
                        <Row2>
                            <Label>Last name <Imp>*</Imp></Label>
                            <Input onChange={(event) => setLastName(event.currentTarget.value)} value={lastName}></Input>
                            {lastNameError?.[0] && (<ContainerError>
                                <FontAwesomeIcon
                                    icon={faWarning}
                                    color='#d92b60'
                                    style={{
                                        marginRight: '10px'
                                    }}
                                />
                                {lastNameError?.[1]}
                            </ContainerError>)}
                        </Row2>
                    </Inputs>
                    <Inputs>
                        <Row2>
                            <Label>Email<Imp>*</Imp></Label>
                            <Input onChange={(event) => setEmail(event.currentTarget.value)} value={email}></Input>
                            {emailError?.[0] && (<ContainerError>
                                <FontAwesomeIcon
                                    icon={faWarning}
                                    color='#d92b60'
                                    style={{
                                        marginRight: '10px'
                                    }}
                                />
                                {emailError?.[1]}
                            </ContainerError>)}
                        </Row2>
                        <Row2>
                            <Label>Phone number</Label>
                            <PhoneInput
                                placeholder=""
                                value={phoneNumber}
                                defaultCountry='US'
                                style={{ width: '100%' }}
                                flagComponent={(flagProps: any) => <CodeOption>+{getCountryCallingCode(flagProps.country)}</CodeOption>}
                                onChange={setPhoneNumber} />
                        </Row2>
                    </Inputs>
                    <Inputs>
                        <Row1>
                            <Label>Tell us more... </Label>
                            <TextArea onChange={(event) => setTellMore(event.currentTarget.value)} value={tellMore}></TextArea>
                        </Row1>
                    </Inputs>
                    <WrapperButton>
                        <ButtonSpinner
                            loading={loading}
                            handleClick={handleSend}
                            text={'Vibe with us'}
                            hasIcon={true}
                            icon={'arrow-right'}
                        />
                    </WrapperButton>
                </ContainerForm>
            </ContentWrap>
        </ContainerB>
    )
}

const WrapperButton = styled.div`
    margin-top: 32px;
`;

const ContainerError = styled.div`
    padding: 5px;
    padding-left: 10px;
    border-radius: 5px;
    background-color: #f5e6eb;
    display: inline-block;
    /* display: flex;
    flex-direction: row; */
    margin-top: 10px;
    color: #d92b60;
    font-family: Inter-Bold;
`;

const ContainerB = styled.div`
    width: 100%;
    height: 100vh;
    background-color: var(--simple-brown-gray);
    display: flex;
    justify-content: center;
    // padding: 120px 0 100px 0;
    background-image: url(./slide3.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;
    align-items: center;
    @media only screen and (max-width: 500px) {
        padding: 24px;
        min-height: 500px;
        height: auto;
    }
`;

const ContentWrap = styled.div`
    width: 1250px;
    margin-bottom: 2px;
    display: flex;
    flex-direction: column;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: black;
    cursor: pointer;
    margin-left: 10px;
`;

const Title = styled.div`
    text-align: initial;
    font-size: 65px;
    font-family: cursive;
    width: 100%;
`

const TextTellUs = styled.div`
    text-align: initial;
    font-size: 50px;
    width: 60%;
    margin: 0px 0px 40px 0px;
    font-family: 'Inter';
    word-wrap: break-word;

    @media only screen and (max-width: 500px) {
        font-size: 32px;
        line-height: 130%;
        width: 100%;
    }
`
const ContainerForm = styled.div`
    width: 60%;
    @media only screen and (max-width: 500px) {
        width: 100%;
    }
`

export const Inputs = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    @media only screen and (max-width: 500px) {
        flex-direction: column;
    }
`
export const Row1 = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
`
export const Row2 = styled.div`
    width: 48%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;

    @media only screen and (max-width: 500px) {
        width: 48%;
    }
`

export const Label = styled.div`    
    width: 100%;
    text-align: left;
    margin: 10px 0;
    font-family: 'Inter';
    display: flex;
    font-size: 16px;
`
export const Input = styled.input`
    outline: none;
    width: 100%;
    height: 50px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-family: 16px;
    font-family: 'Inter';
`

export const TextArea = styled.textarea`
    outline: none;
    width: 100%;
    height: 80px;
    border-radius: 9px;
    border: 0;
    padding: 15px 20px;
    font-family: 16px;
    font-family: 'Inter';
`

export const Select = styled.div`
    outline: none;
    width: 100%;
    height: 50px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Arial;
`
export const Items = styled.div`
    width: 100%;
    position: absolute;
    top: 80px;
    z-index: 1;
`
export const Item = styled.div`
    background-color: white;
    padding: 10px 20px;
    text-align: left;
    font-family: Arial;
    cursor: pointer;

    &:last-child {
        border-radius: 0 0 10px 10px;
        -webkit-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        -moz-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
    }

    &:hover {
        background-color: #e8e8e8;
    }
`

const CodeOption = styled.span`
    color: black;
    font-size: 15px;
    font-family: Arial;
`

export const ButtonSend = styled.div`
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    border-radius: 11px;
    background-color: var(--simple-blue-dark-2);
    border: 0;
    color: white;
    font-weight: 700;
    margin-top: 25px;
    margin-right: 26px;
    position: relative;
    cursor: pointer;
    font-size: 24px;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
`


const StyledFontAwesomeIconSend = styled(FontAwesomeIcon)`
    color: white;
    position: absolute;
    top: 15px;
    right: 17px;
`;

export const Imp = styled.p`
    color: red;
    margin-left: 5px;
`

export default AboutVibe