import styled from "styled-components";
import '../Styles/styles.css';
import {LinkPreview} from "@dhaiwat10/react-link-preview";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import { IPreviewLinkProxyResponse } from "@vibe/sdk/dist/interfaces/Posts/previewLinkProxy";
import { cleanHTML } from "../../../helpers/letterHelper";

const PostPreview = ({
    type,
    link,
}: {
    type: string,
    link: string,
}) => {

    const handleLink = (): any => new Promise((resolve, reject) => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Posts.previewLinkProxy(link, dataSDK.token);
            })
            .then(results => {
                if(results.statusCode === 200){
                    resolve((results.body as IPreviewLinkProxyResponse))
                }
                else{
                    console.log('Error getting link preview', results)
                }
            })
            .catch(error => reject(error))
    })

    return (
        <PreviewWrapper>
            {type.toLowerCase() === "embed" ? (
                <EmbedPreview dangerouslySetInnerHTML={{ __html: link}}></EmbedPreview>
            ) : (
                <LinkPreview
                    className="input-link_PostPreview_Link" 
                    url={link}
                    fetcher={handleLink}
                />
            )}
        </PreviewWrapper>
    )
}

const PreviewWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    font-family: 'Inter';
`;

const EmbedPreview = styled.div`
    width: 100% !important;
    justify-content: center;
    display: flex;
`;

export default PostPreview;