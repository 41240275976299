import {Animation, Background, ContainerContentInspiration, InspRows, InspirationHomeContainer, InspirationRow, LogoInspirations, ScrollInspiration } from '../../Home/ResultsHome/CarrouselInspiration/styles';
import Content, { IInspiration } from '../../Home/ResultsHome/CarrouselInspiration/Content';
import { IconClicker } from '../../Home/HeaderHome/styles';
import { useState } from 'react';
import { getInspirationalColor } from '../../../helpers/letterHelper';
import { faLoveseat } from '@fortawesome/pro-solid-svg-icons';

export type InspirationalColors = "#D6D9CA" | "#D9CEC3" | "#E5DBDC" | "#E7D9B9";

const Quotes = ({
    quotes,
    height = '526px',
    width = "220px",
    isBulletin = false,
}: {
    quotes: IInspiration[]
    height?: string
    width?: string
    isBulletin?: boolean
}) => {

    const [cardActive, setCardActive] = useState<number>(0)
    const [color, setColor] = useState<InspirationalColors>("#D6D9CA");
    const [colorButton, setColorButton] = useState<InspirationalColors>("#D9CEC3");
    const [colorNumber, setColorNumber] = useState<number>(0); //Only 4 colors
    const [anim, setAnim] = useState<boolean>(false)

    const goRight = (cardActive: number) => {
        if (cardActive === (quotes.length - 1)) {
            setCardActive(0)
        }
        else {
            setCardActive(cardActive + 1)
        }

        const colors = getInspirationalColor(colorNumber);
        setColor(colors.base);
        setColorButton(colors.button);
        setColorNumber(colors.id);
    }

    const handleAnim = () => {
        setAnim(true);
        const timerAct = setTimeout(() => {
            goRight(cardActive);
        }, 1000);
        const timer = setTimeout(() => {
            setAnim(false);
        }, 1500);
    } 

    return (
        <InspirationHomeContainer color={color} height={height} width={`calc(${width} + 47px)`}>
            <ScrollInspiration>
                <ContainerContentInspiration>
                    <Content width={width} inspiration={quotes.length > 0 ? quotes[cardActive] : { ...mockQuote }} />
                </ContainerContentInspiration>
            </ScrollInspiration>
            <Animation color={color} theme={{ main: anim ? 'animAct': 'animdes' }}></Animation>
            <InspRows color={colorButton}>
                <IconClicker
                    icon="chevron-right"
                    size="lg"
                    color="#28303A"
                    onClick={() => {  handleAnim();  }}
                />
            </InspRows>
            <Background
                width={isBulletin ? `calc(${width} + 60%)` : `calc(${width} + 100px)`}
                height={isBulletin ? `calc(${height} + 60%)` : `calc(${height} + 100px)`}
                isBulletin={isBulletin}
            />
        </InspirationHomeContainer>
    )
}

export default Quotes;

const mockQuote = {
    from: '',
    quote: '',
}