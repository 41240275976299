import { format, formatDistance, formatRelative, subDays } from 'date-fns'

//@todo correct labeling on Dates on Chat

/*Limits inside a chat
    Messages:
        Less than a minute sent -> Display "Now"
        Less than an hour sent -> Display minutes (23m)
        More than an hour sent -> Display time (12:48 p.m.)
    Day division: (through chat)
        Messages today -> Display "Today"
        Messages yesterday -> Display "Yesterday"
        In the present year:
            Day division with weekday -> (Friday, Feb 22)
        In past years:
            Day division with year and without weekday -> (July 11, 2022)
*/

/*Limits in left panel chat list
    Less than a minute sent -> Display "Now"
    Less than an hour sent -> Display minutes (23m)
    Less than a day sent -> Display time (12:48 p.m.)
    If sent yesterday -> Display "Yesterday"
    If sent in this year -> Display date with out year (Jan 23)
    Else -> Display date (Aug 11, 2022)
*/

export const getChatDivisions = (timestampList: string[]): string[] => {

    const result: string[] = [];
    const dates = timestampList.map((timestamp: string) => new Date(timestamp));

    dates.forEach((date: any) => {
        //@todo complete
    });

    return result;
}

export const getRelativeTime = (timestamp: number): string => { //@todo complete
    return formatRelative(new Date(timestamp), new Date());
}