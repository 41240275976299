import { useSelector } from "react-redux";
import styled from "styled-components";
import ClubListOption from "./ClubListOption";
import { ClubList } from "../../../../interfaces/club/ClubList";
import { ClickableIcon } from "../../../SuperCenter/SuperAdminComponents/basicForSACenter";
import { LAVENDER } from "../../../ClubIconsNavBar";
import {  useRef} from "react";
import { BROWN } from "../../../SuperCenter/Modalities/Modals/components";

const ClubsChatLeftPanel = ({chatSearched} :{ chatSearched: string}) => {
    const listClubs = useSelector((state: any) => state.getClub.getMyClubs);

    const clubsRef: any = useRef(null);

    const scrollButton = (scrollOffset: number) => {
        clubsRef.current.scrollLeft += scrollOffset;
    };

    const getFilteredClubs = (listName: ClubList[]) => { 
        return listName.filter((item: any) => {
            if (!chatSearched) return true;
            if (item.name.toUpperCase().includes(chatSearched) ||
                item.name.toLowerCase().includes(chatSearched) ||
                item.name.includes(chatSearched)
            ) {
                return true;
            }
            return false;
        })
    }

    return (
        <ClubListPanel>
            <ClickableIcon
                icon="chevron-left"
                size='xl'
                color={"var(--t-blue"}
                onClick={() => scrollButton(-80)}
            />
            <ClubScroll ref={clubsRef}>
                {getFilteredClubs(listClubs).map((club: ClubList) => (
                    <ClubListOption club={club} />
                ))}
            </ClubScroll>
            <ClickableIcon
                icon="chevron-right"
                size='xl'
                color={"var(--t-blue"}
                onClick={() => scrollButton(80)}
            />
        </ClubListPanel>
    );
}

export default ClubsChatLeftPanel;

const ClubListPanel = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    height: 100%;
    max-height: 78px;
    gap: 5px;
    align-items: center;
`;

const ClubScroll = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    gap:5px;
    overflow-y: hidden;
    overflow-x: scroll;
    scroll-behavior: smooth;
`;