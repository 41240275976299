import { faMessageDots, faShareNodes, faBookmark, faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons"
import { faBookmark as faBookmarkSolid } from "@fortawesome/pro-solid-svg-icons"
import styled from "styled-components"
import { InfoCount, Interested, Attend, StyledFontAwesomeIcon } from "../../HealerProfile/components"
import GridImages from "./GridImages";
import { IMedia } from "@vibe/sdk/dist/interfaces/Admin/utils";
import { timeDelay } from "../../../helpers/dateHelper";
import PostPreview from "./PostPreview";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useContext, useState, useRef, useEffect } from "react";
import { ContextSuccess } from "../../../Context";
import { Comment } from "@vibe/sdk/interfaces/Posts/IPost"
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import { ICommentPostBody } from "@vibe/sdk/dist/interfaces/Posts/commentPost";
import TextEditor from "../TextEditor";
import { Backend, MicroServices } from '../../../helpers/backendHelper';
import { useTranslation } from "react-i18next";
import { getPostsHealer, getPostsHealerFeatured, getPostsOrg, reloadPostsSaved } from "../../../store/actions/postActions"
import { IReactionPostBody } from "@vibe/sdk/dist/interfaces/Posts/reactionPost"
import { IDeleteReactionBody } from "@vibe/sdk/dist/interfaces/Posts/deleteReaction"
import { getRelativeTime } from "../../../helpers/chat/timeChatHelper"
import ModalEventDetail from "../Events/ModalEventDetail"
import { deliveryEvent, mockEventCard } from "../Events/EventCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ModalShareVibes from './ModalShareVibes'
import { cleanHTML, cleanStyleHTML } from "../../../helpers/letterHelper";
import { Loader } from "../Loader";
import { useNavigate, useParams } from "react-router-dom";
import { IconClicker } from "../../Home/HeaderHome/styles";
import OutsideClickHandler from "react-outside-click-handler";
import { ActionsLabel } from "../BlankTable";
import { IFlagPostBody } from "@vibe/sdk/dist/interfaces/Posts/flagPost";
import { onHideType } from "../Modals/ModalUnsaved";
export interface EventPostInterface {
    event_id: string;
    name: string;
    cover: string;
    start_date: string;
    start_time: string;
    delivery: deliveryEvent;
    location: string;
    link_online_event: string;
}
export interface PostCardInterface {
    avatar?: string;
    files?: IMedia[];
    link?: string;
    comments: Comment[];
    shared: number;
    likes: number;
    content: string; //Here is the event description if type = NEWEVENT
    author_name: string;
    timestamp: string;
    author_username: string;
    reachName: string;
    reachID: string;
    type: string;
    id: string;
    is_tip: boolean;
    tip_author?: string;
    event: EventPostInterface;
}

const PostCard = ({
    data,
    setShowSetup,
    hideSave,
    id,
    reloadPosts
}: {
    data: PostCardInterface,
    setShowSetup: (value: string, type: boolean) => void,
    hideSave?: boolean
    id?: string,
    reloadPosts: () => void
}) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const currentSuccess = useContext(ContextSuccess);
    const dispatch = useDispatch();

    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
    const userProfile = useSelector((state: RootState) => state.getUser.userProfile);
    const isLoggedIn = useSelector((state: RootState) => state.getUser.isLoggedIn);
    const postsSave = useSelector((state: RootState) => state.getPost.getPostsSave ?? []);

    const [showComments, setShowComments] = useState<boolean>(false);
    const [comment, setComment] = useState<string>('');
    const [flagReason, setFlagReason] = useState<string>('');
    const [sendingComment, setSendingComment] = useState<boolean>(false);
    const [savePost, setSavePost] = useState<boolean>(false);
    const [liked, setLiked] = useState<boolean>(false);
    const [sendingLike, setSendingLike] = useState<boolean>(false);
    const [showEvent, setShowEvent] = useState<boolean>(false);
    const [showModalShareVibes, setShowModalShareVibes] = useState<boolean>(false);
    const [reloadPostOnly, setReloadPostOnly] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSave, setLoadingSave] = useState<boolean>(false)
    const [showOptions, setShowOptions] = useState<boolean>(false)

    const getCommAndOrg = () => {
        if (userProfile.roles.includes('INDIVIDUAL')) {
            dispatch(getPostsHealer(userProfile.username));
        }
        else {
            dispatch(getPostsOrg(userProfile.username));
        }
        dispatch(getPostsHealerFeatured(userProfile.username));
    }

    const handleComment = () => {
        if (isLoggedIn && comment !== '') {
            setSendingComment(true);

            const toSend: ICommentPostBody = {
                author: data.author_username,
                username: userInfo?.username || '',
                post_id: data.id,
                content: comment,
            }
            setLoading(true)

            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Posts.commentPost(toSend, dataSDK.token);
                })
                .then((results) => {
                    if (results.statusCode === 201) {
                        setComment('')
                        reloadPost(data.id)
                    } else {
                        currentSuccess?.setSuccessData(false, true, 'Error commenting post.',)
                    }
                })
                .catch((error) => {
                    console.log('Error commenting post', error)
                    currentSuccess?.setSuccessData(false, true, 'Error commenting post.', error)
                    setLoading(false)
                })
                .finally(() => setSendingComment(false))
        }
        else {
            currentSuccess?.setLoginModal(true, false);
        }
    }

    const sharePost = () => {
        if (isLoggedIn) {
            //@todo share a post
        }
        else {
            currentSuccess?.setLoginModal(true, false);
        }
    }

    const likePost = () => {
        if (isLoggedIn) {
            setSendingLike(true);

            if (!liked) {
                setLiked(true);
                data.likes += 1;

                const toSend: IReactionPostBody = {
                    username: userInfo?.username || '',
                    post_id: data.id,
                    reaction: 'like',
                }

                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        return dataSDK.sdk.Posts.reactionPost(toSend, dataSDK.token);
                    })
                    .then(() => { /* Nothing */ })
                    .catch(console.log)
                    .finally(() => setSendingLike(false))
            }
            else {
                setLiked(false);
                data.likes -= 1;

                const toSend: IDeleteReactionBody = {
                    username: userInfo?.username || '',
                    post_id: data.id,
                }

                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        return dataSDK.sdk.Posts.deleteReaction(toSend, dataSDK.token);
                    })
                    .then(() => { /* Nothing */ })
                    .catch(console.log)
                    .finally(() => setSendingLike(false))
            }
        }
        else {
            currentSuccess?.setLoginModal(true, false);
        }
    }

    const commentPost = () => {
        setShowComments(!showComments);
    }

    const ref = useRef(null);
    const [showLink, setShowLink] = useState<boolean>(false);
    const [showText, setShowText] = useState<boolean>(false);
    const count = (ref: any) => {
        if (ref.current) {
            if (ref.current.clientHeight + 3 < ref.current.scrollHeight) {
                setShowLink(true)
            }
        }
    }

    function savePosts(postInfo: any) {
        if (isLoggedIn && postInfo.author_username === userInfo?.username) {
            setLoadingSave(true);

            if (savePost) {
                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        return dataSDK.sdk.Posts.unSavePost(postInfo.id, userInfo?.username || '', dataSDK.token)
                    })
                    .then((response) => {
                        if (response.statusCode === 201) {
                            currentSuccess?.setSuccessData(true, true, "Post unpinned.");
                            setSavePost(false);
                            dispatch(reloadPostsSaved(userInfo?.username || ''));
                            dispatch(getPostsHealerFeatured(userInfo?.username || ''));
                        }
                        else {
                            currentSuccess?.setSuccessData(false, true, 'Error unpinning post.');
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        currentSuccess?.setSuccessData(false, true, 'Error unpinning post.');
                    })
                    .finally(() => setLoadingSave(false));
                return;
            }

            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Posts.savePost({
                        postId: postInfo.id,
                        usernamePoster: postInfo.author_username,
                        usernameSaver: userInfo?.username || ''
                    }, dataSDK.token)
                })
                .then((response) => {
                    if (response.statusCode === 201) {
                        currentSuccess?.setSuccessData(true, true, t('toast_postSaved'));
                        setSavePost(true);
                        dispatch(reloadPostsSaved(userInfo?.username || ''));
                        dispatch(getPostsHealerFeatured(userInfo?.username || ''));
                    }
                    else {
                        currentSuccess?.setSuccessData(false, true, 'Error pinning post.');
                    }
                })
                .catch((error) => {
                    console.log(error);
                    currentSuccess?.setSuccessData(false, true, 'Error pinning post.');
                })
                .finally(() => setLoadingSave(false));
        }
    }

    const getDelivery = () => {
        switch (data.event.delivery) {
            case 'online':
                return (
                    <RowFlex2>
                        <FontAwesomeIcon
                            icon='video'
                            size='sm'
                            color='var(--t-blue)'
                        />
                        <TitlePost
                            theme={{ main: '' }}
                            isEvent={true}
                        >
                            <a href={`${data.event.link_online_event}`} target="_blank" rel="noreferrer">
                                Link
                            </a>
                        </TitlePost>
                    </RowFlex2>
                );

            case 'mixed':
                return (
                    <RowFlexMixed>
                        <RowFlex2>
                            <FontAwesomeIcon
                                icon='video'
                                size='sm'
                                color='var(--t-blue)'
                            />
                            <TitlePost
                                theme={{ main: '' }}
                                isEvent={true}
                            >
                                <a href={data.event.link_online_event} target="_blank" rel="noreferrer">
                                    Link
                                </a>
                            </TitlePost>
                        </RowFlex2>

                        <RowFlexLocation>
                            <FontAwesomeIcon
                                icon='location-dot'
                                size='sm'
                                color='var(--t-blue)'
                            />
                            <TitlePost
                                theme={{ main: '' }}
                                isEvent={true}
                            >
                                {data.event.location}
                            </TitlePost>
                        </RowFlexLocation>
                    </RowFlexMixed>
                );

            case 'inPerson':
                return (
                    <RowFlexLocation>
                        <FontAwesomeIcon
                            icon='location-dot'
                            size='sm'
                            color='var(--t-blue)'
                        />
                        <TitlePost
                            theme={{ main: '' }}
                            isEvent={true}
                        >
                            {data.event.location}
                        </TitlePost>
                    </RowFlexLocation>
                );

            default:
                break;
        }
    }

    const handleSavePost = () => {
        setShowSetup('Featured', true);
        savePosts(data)
    }

    const handleSendVibes = () => {
        if (isLoggedIn) {
            setShowModalShareVibes(true);
        }
        else {
            currentSuccess?.setLoginModal(true, false);
        }
    }

    useEffect(() => {
        if (data.id && userInfo?.username) {
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Posts.getUserReaction(data.id, userInfo?.username || '', dataSDK.token)
                })
                .then((response) => {
                    if (response.statusCode === 200 && response.body && 'type' in response.body) {
                        setLiked(true)
                    }
                })
                .catch(console.log);
        }

        if (data.id && postsSave.length > 0) {
            for (let i = 0; i < postsSave.length; i++) {
                if (postsSave[i].post_id === data.id) {
                    setSavePost(true);
                    break;
                }
            }
        }
    }, [data.id])

    const reloadPost = (id: string) => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Posts.getPost(id);
            })
            .then((results) => {
                if (results.statusCode === 200 && results.body && 'type' in results.body) {
                    setReloadPostOnly(results.body)
                    setLoading(false)
                }
            })
            .catch(console.error)
    }

    const deletePost = (id: string) => {
        if (isLoggedIn) {
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Posts.deletePost(id, dataSDK.token);
                })
                .then((response) => {

                    reloadPosts();

                    if (response.statusCode === 204) {
                        setShowOptions(false);
                        currentSuccess?.setLoadingModal(false);
                        currentSuccess?.setModalsOff();
                        currentSuccess?.setSuccessData(true, true, 'Post deleted')
                    }
                    else {
                        currentSuccess?.setSuccessData(false, true, 'There was an error!')
                        console.log(response);
                    }
                })
                .catch(console.log);
        } else {
            currentSuccess?.setLoginModal(true, true);
        }
    }

    const flagPost = (id: string) => {
        if (isLoggedIn) {
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    //@todo flagReason
                    const toSend: IFlagPostBody = {
                        usernamePoster: data.author_username,
                        postId: data.id,
                        usernameFlagger: userInfo?.username || '',
                        reach: data.reachName,
                        reachId: data.reachID,
                        reason: flagReason || 'Other',
                    }
                    return dataSDK.sdk.Posts.flagPost(toSend, dataSDK.token);
                })
                .then((response) => {

                    reloadPosts();

                    if (response.statusCode === 201) {
                        setShowOptions(false);
                        currentSuccess?.setSuccessData(true, true, 'Post flagged', "We'll review the content.")
                    }
                    else {
                        currentSuccess?.setSuccessData(false, true, 'There was an error!')
                        console.log(response);
                    }
                })
                .catch(console.log);
        } else {
            currentSuccess?.setLoginModal(true, true);
        }
    }

    const onHideDeletePost = (type: onHideType, id: string) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);
                deletePost(id);
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                break;

            default:
                break;
        }
    }

    const handleDeletePost = (data: string) => {
        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => onHideDeletePost(type, data),
            'Do you want to delete this post?'
        );
    }

    const getActions = (): ActionsLabel[] => {
        const actions: ActionsLabel[] = [];

        if (data.author_username === userInfo?.username) {
            actions.push({
                enabled: true,
                onClick: handleDeletePost,
                label: 'Delete Post',
            })
        }

        // if (data.author_username !== userInfo?.username) {
        //     actions.push({
        //         enabled: true,
        //         onClick: flagPost, //@todo
        //         label: 'Flag Post',
        //     })
        // }

        return actions;
    }

    useEffect(() => {
        setShowLink(false)
        count(ref)
    }, [ref])

    return (
        <Post id={id}>

            <Header>
                <InfoUser>
                    <Avatar src={data.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'} />
                    <AuthorInfo>
                        <Name>{data.author_name}</Name>
                        <Reach>{data.reachName} - {!data.timestamp.includes('-') ? getRelativeTime(parseInt(data.timestamp)) : ''}</Reach>
                    </AuthorInfo>
                </InfoUser>
                {(data.type !== 'NEWEVENT' && getActions().length > 0) && <IconClicker
                    icon={faEllipsisVertical}
                    size='lg'
                    color='var(--t-blue)'
                    onClick={() => setShowOptions(true)}
                />}
            </Header>

            {(data.content) && (
                <>
                    {(data.type === 'NEWEVENT') && (
                        <TitlePost
                            theme={{ main: 'lineLink' }}
                            isEvent={false}
                            onClick={() => { navigate(`../../../../app/event/${data.event.event_id}`); }}
                        >
                            {data.event.name}
                        </TitlePost>
                    )}

                    <TitlePost
                        theme={{ main: !showText ? 'noShowAll' : '' }}
                        isEvent={data.type === 'NEWEVENT'}
                        ref={ref}
                        dangerouslySetInnerHTML={{
                            __html: cleanHTML(data.is_tip ? `"${data.content}"` : data.content)
                        }} />

                    {((showLink && data.type !== 'NEWEVENT') && <ReadMore onClick={() => setShowText(!showText)}>{showText ? 'Read less' : 'Read more'}</ReadMore>)}

                    {(data.type === 'NEWEVENT') && (
                        <ViewDetails onClick={() => setShowEvent(true)}>
                            View Details
                        </ViewDetails>
                    )}
                </>
            )}

            {data.is_tip && <Author>- {data.tip_author || ''}</Author>}
            {(data.type === 'NEWEVENT') && (
                <RowFlexEventInfo>
                    <RowFlexCalendar>
                        <FontAwesomeIcon
                            icon='calendar'
                            size='sm'
                            color='var(--t-blue)'
                        />
                        <TitlePost
                            theme={{ main: '' }}
                            isEvent={true}
                        >
                            {data.event.start_date}
                        </TitlePost>
                    </RowFlexCalendar>

                    <RowFlex2>
                        <FontAwesomeIcon
                            icon='clock'
                            size='sm'
                            color='var(--t-blue)'
                        />
                        <TitlePost
                            theme={{ main: '' }}
                            isEvent={true}
                        >
                            {data.event.start_time}
                        </TitlePost>
                    </RowFlex2>

                    {getDelivery()}

                </RowFlexEventInfo>
            )}

            {(data.files && data.files.length > 0) && <GridImages images={data.files || []} type={'detail'} />}
            {(data.type === 'NEWEVENT' && data.event.cover !== '') && <ImageEvent src={data.event.cover} />}
            {(data.type.toLowerCase() === 'link' || data.type.toLowerCase() === 'embed') &&
                <PostPreview type={data.type} link={data.link || ''} />
            }

            <InfoCount>
                <Interested>{`${data.comments.length}`} comments</Interested>
                {process.env.REACT_APP_SHOW_SHARE === 'true' && (
                    <Attend>{`${data.shared}`} shared</Attend>
                )}
                <Attend>{`${data.likes}`} Good Vibes</Attend>
            </InfoCount>

            <Actions>
                <ActionsWrap>
                    <Share
                        onClick={commentPost}
                    >
                        Comment
                        <StyledFontAwesomeIcon
                            icon={faMessageDots}
                            size='lg'
                            color={"black"}
                            style={{
                                fontSize: '16px'
                            }}
                        />
                    </Share>
                    {process.env.REACT_APP_SHOW_SHARE === 'true' && (
                        <Share
                            onClick={sharePost}
                        >
                            Share
                            <StyledFontAwesomeIcon
                                icon={faShareNodes}
                                size='lg'
                                color={"black"}
                                style={{
                                    fontSize: '16px'
                                }}
                            />
                        </Share>
                    )}
                    <Share
                        onClick={likePost}
                    >
                        {!liked ? 'Good Vibes' : 'Vibing!'}
                        {!liked ? <VibeLogo_Light /> : <VibeLogo />}
                    </Share>
                </ActionsWrap>

                <ActionsWrap>
                    {loadingSave ? <Loader color={"white"} /> :
                        (/*!hideSave && */ isLoggedIn && data.author_username === userInfo?.username) && (
                            <IconSave
                                icon={savePost ? faBookmarkSolid : faBookmark}
                                size='lg'
                                color={"black"}
                                style={{
                                    fontSize: '16px'
                                }}
                                onClick={handleSavePost}
                            />
                        )}
                    {(data.author_username !== userInfo?.username) && (
                        <IconVibes onClick={handleSendVibes} />
                    )}
                </ActionsWrap>
            </Actions>
            {showComments && (
                <CommentsContainer>
                    <CommentsScroll>
                        {reloadPostOnly?.comments.length > 0 ?
                            reloadPostOnly.comments.sort(function (x: any, y: any) {
                                return parseInt(x.created_at) - parseInt(y.created_at);
                            }).map((comm: any) => (
                                <CommentContainer>
                                    <CommentInfoWrap>
                                        <CommentInfo>{comm.author}</CommentInfo>
                                        <Point />
                                        <CommentInfo>
                                            {comm.created_at &&
                                                timeDelay(comm.created_at)
                                            }
                                        </CommentInfo>
                                    </CommentInfoWrap>
                                    <CommentContent dangerouslySetInnerHTML={{ __html: cleanStyleHTML(comm.content) }} />
                                </CommentContainer>
                            )) :
                            data.comments.map(comm => (
                                <CommentContainer>
                                    <CommentInfoWrap>
                                        <CommentInfo>{comm.author}</CommentInfo>
                                        <Point />
                                        <CommentInfo>
                                            {comm.created_at &&
                                                timeDelay(comm.created_at)
                                            }
                                        </CommentInfo>
                                    </CommentInfoWrap>
                                    <CommentContent dangerouslySetInnerHTML={{ __html: cleanStyleHTML(comm.content) }} />
                                </CommentContainer>
                            ))
                        }
                    </CommentsScroll>
                    <InputCommentWrap>
                        <WrapperTextArea>
                            <TextEditor
                                value={comment}
                                setValue={setComment}
                                charLimit={1000}
                                minHeight={100}
                                placeholder='Write something to comment...'
                                border={'0px'}
                            />
                        </WrapperTextArea>
                        {loading ? <Loader color={"white"} /> :
                            <StyledFontAwesomeIcon
                                icon={'paper-plane-top'}
                                size='xl'
                                color={"black"}
                                style={{
                                    fontSize: '18px'
                                }}
                                onClick={() => handleComment()}
                            />
                        }

                    </InputCommentWrap>
                </CommentsContainer>
            )}
            {(data.type === 'NEWEVENT' && data.event.event_id !== '' && showEvent) && (
                <ModalEventDetail
                    event={{
                        ...mockEventCard,
                        id: data.event.event_id || '',
                    }}
                    show={showEvent}
                    setShow={setShowEvent}
                />
            )}
            <ModalShareVibes
                receiver={{ username: data.author_username, name: data.author_name, avatar: data.avatar ?? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png' }}
                postId={data.id}
                show={showModalShareVibes}
                setShow={setShowModalShareVibes}
            />
            {showOptions &&
                <OutsideClickHandler onOutsideClick={() => setShowOptions(false)} key={data.id}>
                    <Items>
                        {getActions().map((action: ActionsLabel, index) => (
                            <Item
                                onClick={() => action.onClick(data.id)}
                                key={index}
                                enabled={action.enabled}
                            >
                                {action.label}
                            </Item>
                        ))}
                    </Items>
                </OutsideClickHandler>
            }
        </Post>
    )
}

const IconSave = styled(FontAwesomeIcon)`
    cursor: pointer;
`;

const Header = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
`;

const ActionsWrap = styled.div`
    display: flex;
    font-family: 'Inter-Bold';
    gap: 10px;
    font-size: 14px;
    align-items: center;
`;

const IconVibes = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: relative;
  background-image: url(../goodVibes.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80% auto;
  cursor: pointer;
`

const RowFlex = styled.div`
  display: flex;
  height: fit-content;
  gap: 15px;
`;

const RowFlex2 = styled(RowFlex)`
    gap: 5px;
    width: fit-content;
`;

const RowFlexCalendar = styled(RowFlex)`
    gap: 5px;
    width: fit-content;
    max-width: fit-content;
    min-width: fit-content;
`;

const RowFlexLocation = styled(RowFlex)`
    gap: 5px;
    width: fit-content;
    overflow: hidden;
    white-space: nowrap;
`;

const RowFlexMixed = styled(RowFlex)`
    gap: 10px;
    width: fit-content;
`;

const RowFlexEventInfo = styled(RowFlex)`
    gap: 10px;
    overflow: hidden;
`;

const ImageEvent = styled.img`
    width: 100%;
    height: 400px;
    overflow: hidden;
    object-fit: cover;
    border-radius: 9px;
`;

const InfoUser = styled.div`
  display: grid;
  grid-template-columns: 40px calc(100% - 50px);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  width: 100%;
  height: fit-content;
  text-align: left;
  align-items: center;
`;

const Avatar = styled.img`
    object-fit: cover;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    border-radius: 50%;
    border: 1px solid var(--simple-gray);
`;

const AuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  gap: 5px;
`;

const Name = styled.div`
    font-family: 'Inter-Bold';
    font-size: 14px;
    color: var(--t-blue);
`;

const Reach = styled(Name)`
    font-family: 'Inter';
`;

const VibeLogo = styled.img`
    content: url(../../../vibeLogo.svg);
    width: 20px;
    height: 20px;
`;

const VibeLogo_Light = styled(VibeLogo)`
    content: url(../../../vibeLogo_light.svg);
`;

const ReadMore = styled.div`
    color: #666;
    font-family: Inter-Bold;
    font-size: 14px;
    text-align: left;
    cursor:pointer;
`

const ViewDetails = styled(ReadMore)`
    color: var(--absolute-zero);
`

const TitlePost = styled.p.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    font-family: ${({ isEvent }: { isEvent: boolean }) => isEvent ? 'Inter' : 'Inter-bold'};
    font-style: normal;
    font-weight: ${({ isEvent }: { isEvent: boolean }) => isEvent ? '400' : '700'};
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    width: calc(100%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;

    &.noShowAll{
        -webkit-line-clamp: 1;
    }

    & a{
        font-family: 'Inter';
        font-weight: 400;
        color: var(--absolute-zero);
        text-decoration: underline !important;
    }
`

const Author = styled.div`
    font-family: 'Inter';
    font-style: italic;
    font-size: 14px;
    text-align: left;
    overflow: hidden;
    color: var(--simple-blue-dark);
    width: 100%;
`;

const WrapperTextArea = styled.div`
    width: 95%;
`;

const InputComment = styled.input`
    font-family: 'Inter';
    font-size: 14px;
    color: var(--simple-blue-dark);
    outline: none;
    padding: 2px;
    border: none;
    width: 100%;

    ::placeholder{
        font-family: 'Inter';
        font-size: 14px;
        color: var(--simple-gray);
    }
`;

const InputCommentWrap = styled.div`
    border-top: 1px solid var(--simple-gray);
    display: flex;
    padding-right: 10px;
    width: 100%;
    align-items: center;
`;

const Point = styled.div`
    background-color: var(--simple-blue-dark);
    width: 3px;
    height: 3px;
    border-radius: 100%;
`;

const CommentContent = styled.div`
    font-family: 'Inter';
    font-size: 14px;
    color: var(--simple-blue-dark);
    color: var(--simple-blue-dark);
    width: 100%;
    word-break: break-word;
    padding-bottom: 10px;
`;

const CommentInfo = styled.span`
    font-family: 'Inter-bold';
    font-size: 12px;
    color: var(--simple-blue-dark);
`;

const CommentContainer = styled.div`
    width: 100%;
    border-bottom: 0.5px solid var(--simple-gray);
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const CommentsScroll = styled.div`
    width: 100%;
    display: flex;
    max-height: 150px;
    min-height: 50px;
    flex-direction: column;
    gap: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px;
    text-align: left;
`;

const CommentInfoWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
`;

const CommentsContainer = styled.div`
    width: 100%;
    height: fit-content;
    border: 0.5px solid var(--simple-gray);
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 8px;
`;

const DivisionLine = styled.div`
    background-color: var(--simple-gray);
    height: 1px;
    width: 100%;
`;

const Share = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
`
const Actions = styled.div`
    display: flex;
    font-family: 'Inter-Bold';
    gap: 10px;
    font-size: 14px;
    justify-content: space-between;
    position: relative;
`
const Image = styled.img`
    width: 100%;
    height: 350px;
    border-radius: 8px;
`
const Post = styled.div`
    gap: 13px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    border: 1px solid var(--simple-gray);
    padding: 17px;
    position: relative;
`

const Item = styled.li`
    background-color: white;
    padding: 10px 20px;
    text-align: left;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    opacity: ${({ enabled }: { enabled: boolean }) => enabled ? '1' : '0.5'};
    cursor: ${({ enabled }: { enabled: boolean }) => enabled ? 'pointer' : 'default'};

    &:last-child {
        border-radius: 0 0 10px 10px;
        -webkit-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        -moz-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
    }

    &:hover {
        background-color: ${({ enabled }: { enabled: boolean }) => enabled ? '#e8e8e8' : 'white'};
    }
`;

const Items = styled.ul`
    width: 235px;
    position: absolute;
    top: 55px;
    z-index: 10;
    overflow-y: scroll;
    max-height: 200px;
    border-radius: 12px;
    right: 10px;
    box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
    background-color: white;
`;

export default PostCard;