import { useState, useContext } from "react";
import {
    Title,
} from "../SuperAdminComponents/basicForSACenter";
import { useTranslation } from "react-i18next";
import { ContextSuccess } from "../../../Context";
import styled from "styled-components";
import SearchInput from "../../BaseComponents/SearchInput";
import BlankTable from "../../BaseComponents/BlankTable";
import useReferralsSA, { HealerReferred } from "../../../hooks/Superadmin/Referrals/useReferralsSA";

const Referrals = () => {
    const { t, i18n } = useTranslation();
    const currentSuccess = useContext(ContextSuccess);

    const [SearchTerm, setSearchTerm] = useState("");

    const {
        ReferralsFiltered,
        reloadReferrals
    } = useReferralsSA({
        SearchTerm,
    });

    return (
        <ContentOrganizations>
            <Title>Referrals</Title>
            <WrapperRow>
                <SearchInput
                    filteredItem={SearchTerm}
                    setFilteredItem={setSearchTerm}
                    border={'1px solid var(--simple-gray)'}
                    placeholder={`Search by first name, last name or email`}
                />
            </WrapperRow>
            <Scroll>
                <BlankTable
                    headerColored
                    border
                    headers={['Name', 'Email', 'Modalities', 'Location', 'Message']}
                    items={ReferralsFiltered.map(org => ({
                        id: org.healerEmail,
                        labels: [
                            `${org.healerFirstName} ${org.healerLastName}`,
                            org.healerEmail,
                            org.modality.map(m => m.name).join(', '),
                            `${org.zipCode} - ${org.cityName}, ${org.stateName}. ${org.countryName}.`,
                            org.message || '',
                        ],
                    }))}
                    backgroundColor={'white'}
                />
            </Scroll>
        </ContentOrganizations>
    );
};

export default Referrals;

const ContentOrganizations = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  align-items: flex-start;
  gap: 15px;
  flex-direction: column;
`;

const WrapperRow = styled.div`
  display: flex;
  width: 40%;
  gap: 25px;
  align-items: flex-end;
`;

const Scroll = styled.div`
  display: flex;
  width: 100%;
  overflow-y: scroll;
`;
