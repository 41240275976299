import { useState, useContext } from "react";
import {
    Title,
} from "../SuperAdminComponents/basicForSACenter";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import { useTranslation } from "react-i18next";
import useLanguagesSA from "../../../hooks/useLanguagesSA";
import { ContextSuccess } from "../../../Context";
import styled from "styled-components";
import SearchInput from "../../BaseComponents/SearchInput";
import BlankTable from "../../BaseComponents/BlankTable";

const LangTable = () => {
    const { t, i18n } = useTranslation();
    const currentSuccess = useContext(ContextSuccess);

    const [SearchTerm, setSearchTerm] = useState("");

    const { langFiltered, reloadLanguages } = useLanguagesSA({
        SearchTerm,
    });

    const handleActivate = (langCode: string) => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Admin.activateLanguage(langCode, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 201) {
                    currentSuccess?.setSuccessData(true, true, t('toast_countryActivated'));
                    reloadLanguages();
                } else {
                    currentSuccess?.setSuccessData(false, true, 'There was an error');
                }
            })
    };

    const handleDeactivate = (langCode: string) => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Admin.deactivateLanguage(langCode, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 201) {
                    currentSuccess?.setSuccessData(true, true, t('toast_countryDesactivated'));
                    reloadLanguages();
                } else {
                    currentSuccess?.setSuccessData(false, true, 'There was an error');
                }
            });
    };

    return (
        <ContentOrganizations>
            <Title>Languages</Title>
            <WrapperRow>
                <SearchInput
                    filteredItem={SearchTerm}
                    setFilteredItem={setSearchTerm}
                    border={'1px solid var(--simple-gray)'}
                    placeholder={`Search by name`}
                />
            </WrapperRow>
            <Scroll>
                <BlankTable
                    headerColored
                    border
                    headers={['Language', 'Status']}
                    items={langFiltered.map(org => ({
                        id: org.code,
                        labels: [
                            org.engName,
                            org.active ? 'Active' : 'Disabled',
                        ],
                    }))}
                    actions={['ACTIVATE', 'DEACTIVATE']}
                    actionsHandlers={{
                        handleActivate,
                        handleDeactivate,
                    }}
                    backgroundColor={'white'}
                />
            </Scroll>
        </ContentOrganizations>
    );
};

export default LangTable;

const ContentOrganizations = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  align-items: flex-start;
  gap: 15px;
  flex-direction: column;
`;

const WrapperRow = styled.div`
  display: flex;
  width: 40%;
  gap: 25px;
  align-items: flex-end;
`;

const Scroll = styled.div`
  display: flex;
  width: 100%;
  overflow-y: scroll;
`;
