import { isDate } from "../guards/isDate";
import { padNumber } from "./numberHelper";

const MONTHS_ABBR = [
    'Jan.',
    'Feb.',
    'Mar.',
    'Apr.',
    'May',
    'Jun.',
    'Jul.',
    'Aug.',
    'Sept.',
    'Oct.',
    'Nov.',
    'Dec.',
];

const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const getMonth = (month: number, abbr = true) => abbr ? MONTHS_ABBR[month] : MONTHS[month];

export const unixToDate = (dateUnix: string): Date => new Date(parseInt(dateUnix))

export const dateToFormat = (date: Date): string =>
    isDate(date)
        ? `${getMonth(date.getMonth())} ${date.getDate()}, ${date.getFullYear()}, ${padNumber(date.getHours() % 12, 2)}:${padNumber(date.getMinutes(), 2)}:${padNumber(date.getSeconds(), 2)} ${date.getHours() > 12 ? 'PM' : 'AM'}`
        : '';

export const dateToFormat2 = (date: Date): string =>
    isDate(date)
        ? `${padNumber(date.getMonth() + 1, 2)}/${padNumber(date.getDate(), 2)}/${date.getFullYear()} ${padNumber(date.getHours() % 12, 2)}:${padNumber(date.getMinutes(), 2)} ${date.getHours() > 12 ? 'PM' : 'AM'}`
        : '';

export const dateToFormat3 = (date: Date): string =>
    isDate(date)
        ? `${padNumber(date.getMonth() + 1, 2)}/${padNumber(date.getDate(), 2)}/${date.getFullYear()}`
        : '';

export const unixToDateFormatted = (dateUnix: string, formatter = dateToFormat): string => !dateUnix || dateUnix === '' ? '' : formatter(unixToDate(dateUnix));

export const dateToUpdate = (date: Date): string =>
    isDate(date)
        ? `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`
        : '';

export const stringToDate = (dateFormat: string): Date => new Date(dateFormat);

export const checkBirth = (birth: string): boolean => {

    const birthday = (new Date(birth)).getTime();
    const now = Date.now();
    const gap = new Date(now - birthday).getFullYear() - 1970;

    if (gap < 13) {
        return false;
    }
    return true;
};

//TIME HELPER
const bisiesto = (year: number): boolean => (year % 4 == 0 && (year % 100 != 0 || year % 400 == 0))

const lastDay = (year: number, month: number): number | undefined => {
    if ([1, 3, 5, 7, 8, 10, 12].includes(month)) return 31

    if ([4, 6, 9, 11].includes(month)) return 30

    if (month === 2) return bisiesto(year) ? 29 : 28

    return undefined
}

const mod = (n: number, m: number): number => ((n % m) + m) % m;

export const applyTimeOffsetForLocal = (time_parts: string[], offsets: string[]): string[] | undefined => {
    const local_offset = new Date().getTimezoneOffset();
    const local_offsets = [-Math.floor(local_offset / 60), -local_offset % 60]
    const global_offsets = [parseInt(offsets[0].replace(/−/g, '-')) - local_offsets[0], parseInt(offsets[1].replace(/−/g, '-')) - local_offsets[1]]
    const time = applyTimeOffset(time_parts.map((item) => parseInt(item)), [-global_offsets[0], -global_offsets[1]])
    return time ? [
        `${time[0]}`,
        time[1] < 10 ? `0${time[1]}` : `${time[1]}`,
        time[2] < 10 ? `0${time[2]}` : `${time[2]}`,
        time[3] < 10 ? `0${time[3]}` : `${time[3]}`,
        time[4] < 10 ? `0${time[4]}` : `${time[4]}`,
    ] : time
}

export const applyTimeOffset = (time_parts: number[], offsets: number[]): number[] | undefined => {
    //0 -> year
    //1 -> month
    //2 -> day
    //3 -> hour
    //4 -> minute

    //Offset minutes
    const changes = [false, false, false]
    let change_factor = (offsets[1] < 0) ? -1 : 1
    let prev = time_parts[4]
    time_parts[4] += offsets[1]
    time_parts[4] = mod(time_parts[4], 60)
    if (change_factor === -1 && prev < time_parts[4]) {
        time_parts[3] -= 1
        if (time_parts[3] < 0) {
            time_parts[3] = 23
            time_parts[2] -= 1
            changes[2] = true
            if (time_parts[2] < 1) {
                const last = lastDay((time_parts[1] === 1) ? time_parts[0] - 1 : time_parts[0], (time_parts[1] === 1) ? 12 : time_parts[1] - 1)
                if (!last) return undefined
                time_parts[2] = last
                time_parts[1] -= 1
                changes[1] = true
                if (time_parts[1] < 1) {
                    time_parts[1] = 12
                    time_parts[0] -= 1
                    changes[0] = true
                }
            }
        }
    } else if (change_factor === 1 && prev > time_parts[4]) {
        time_parts[3] += 1
        if (time_parts[3] > 24) {
            time_parts[3] = 0
            time_parts[2] += 1
            changes[2] = true
            const last = lastDay(time_parts[0], time_parts[1])
            if (!last) return undefined
            if (time_parts[2] > last) {
                time_parts[2] = 1
                time_parts[1] += 1
                changes[1] = true
                if (time_parts[1] > 12) {
                    time_parts[1] = 1
                    time_parts[0] += 1
                    changes[0] = true
                }
            }
        }
    }

    //Offset hours
    change_factor = (offsets[0] < 0) ? -1 : 1
    prev = time_parts[3]
    time_parts[3] += offsets[0]
    time_parts[3] = mod(time_parts[3], 24)
    if (change_factor === -1 && prev < time_parts[3]) {
        if (!changes[2]) time_parts[2] -= 1
        if (time_parts[2] < 1) {
            const last = lastDay((time_parts[1] === 1) ? time_parts[0] - 1 : time_parts[0], (time_parts[1] === 1) ? 12 : time_parts[1] - 1)
            if (!last) return undefined
            time_parts[2] = last
            if (!changes[1]) time_parts[1] -= 1
            if (time_parts[1] < 1) {
                time_parts[1] = 12
                if (!changes[0]) time_parts[0] -= 1
            }
        }
    } else if (change_factor === 1 && prev > time_parts[3]) {
        if (!changes[2]) time_parts[2] += 1
        const last = lastDay(time_parts[0], time_parts[1])
        if (!last) return undefined
        if (time_parts[2] > last) {
            time_parts[2] = 1
            if (!changes[1]) time_parts[1] += 1
            if (time_parts[1] > 12) {
                time_parts[1] = 1
                if (!changes[0]) time_parts[0] += 1
            }
        }
    }

    return time_parts
}

export const timeBefore = (time1: number[], time2: number[]): boolean | undefined => {
    if (time1[0] < time2[0]) {
        return true
    } else if (time1[0] > time2[0]) {
        return false
    } else {
        if (time1[1] < time2[1]) {
            return true
        } else if (time1[1] > time2[1]) {
            return false
        } else {
            if (time1[2] < time2[2]) {
                return true
            } else if (time1[2] > time2[2]) {
                return false
            } else {
                if (time1[3] < time2[3]) {
                    return true
                } else if (time1[3] > time2[3]) {
                    return false
                } else {
                    if (time1[4] < time2[4]) {
                        return true
                    } else if (time1[4] > time2[4]) {
                        return false
                    } else {
                        //Same time
                        return undefined
                    }
                }
            }
        }
    }
}

export const dateToLimitForEvents = (date: Date): string => {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    let offset = date.getTimezoneOffset() / 60.0
    const offset_part = offset > 0 ? 'm' : 'p'
    offset = Math.abs(offset)
    const offset_info = offset.toString().split('.')
    const off_hours = parseInt(offset_info[0]) < 10 ? `0${offset_info[0]}` : offset_info[0]
    const off_minutes = offset_info[1] ? '30' : '00'
    return `${year}-${month}-${day}-${offset_part}-${off_hours}-${off_minutes}`
}

export const timeDelay = (timestamp: string): string => {
    const actual_time = new Date().valueOf()
    const time = parseInt(timestamp)
    let difference = actual_time - time

    const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    difference -= daysDifference * 1000 * 60 * 60 * 24

    const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60

    const minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60

    const secondsDifference = Math.floor(difference / 1000);
    difference -= secondsDifference * 1000

    let time_str = ''
    if(daysDifference > 0){
        time_str = unixToDateFormatted(timestamp)
    }else{
        time_str = `${daysDifference !== 0 ? `${daysDifference}d` : ''} ${hoursDifference !== 0 ? `${hoursDifference}h` : ''} ${minutesDifference !== 0 ? `${minutesDifference}min` : ''}`
    }

    return time_str.length !== 0 ? time_str : `${secondsDifference}sec`
}

export const getBaseYear = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 13);
    return date;
}