import { AnyAction } from 'redux';
import {
  GET_AHA_SETUP_ERROR, GET_AHA_SETUP_SUCCESS, LOGOUT_AHA, START_GET_AHA_SETUP,
  GET_AHA_EVENTS_ERROR, GET_AHA_EVENTS_SUCCESS, START_GET_AHA_EVENTS,
} from '../constants/ahaConstants';

interface ahaState {
  loadingGetAhaSetup: boolean;
  GetAhaSetupError?: any;
  GetAhaSetup: string;

  loadingGetAhaEvents: boolean;
  GetAhaEventsError?: any;
  GetAhaEvents: { school: { label: string, active: boolean }[], club: { label: string, active: boolean }[], team: { label: string, active: boolean }[] };
}

const initialState: ahaState = {
  loadingGetAhaSetup: false,
  GetAhaSetupError: undefined,
  GetAhaSetup: 'some',

  loadingGetAhaEvents: false,
  GetAhaEventsError: undefined,
  GetAhaEvents: {
    school: [],
    club: [],
    team: []
  }
};

export const getAhaReducer = (state = initialState, action: AnyAction): ahaState => {
  switch (action.type) {
    case LOGOUT_AHA:
      return initialState;

    case START_GET_AHA_SETUP:
      return {
        ...state,
        loadingGetAhaSetup: true,
      }
    case GET_AHA_SETUP_SUCCESS:
      return {
        ...state,
        loadingGetAhaSetup: false,
        GetAhaSetup: action.payload,
      }
    case GET_AHA_SETUP_ERROR:
      return {
        ...state,
        loadingGetAhaSetup: false,
        GetAhaSetupError: action.payload,
      }

    case START_GET_AHA_EVENTS:
      return {
        ...state,
        loadingGetAhaEvents: true,
      }
    case GET_AHA_EVENTS_SUCCESS:
      return {
        ...state,
        loadingGetAhaEvents: false,
        GetAhaEvents: action.payload,
      }
    case GET_AHA_EVENTS_ERROR:
      return {
        ...state,
        loadingGetAhaEvents: false,
        GetAhaEventsError: action.payload,
      }


    default:
      return state;
  }
};
