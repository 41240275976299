export interface BaseFieldState<T> {
    value?: T;
    showError: boolean;
    error: string;
}

export const getFieldState = <T extends unknown>(value?: T): BaseFieldState<T> => ({
    value,
    showError: false,
    error: ''
})