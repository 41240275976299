import { useEffect, useMemo, useState } from "react";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import { IOrganizationResponse } from "@vibe/sdk/dist/interfaces/Admin/Organization";

const useTopPractitionersSA = (pageSizeProp: number) => {
    const [pageSize, setPageSize] = useState(pageSizeProp);
    const [TopPractitioners, setTopPractitioners] = useState<IOrganizationResponse[]>([]);

    const tpPages:IOrganizationResponse[][] = useMemo(() => {
        const pages:IOrganizationResponse[][] = [];

        for (let i = 0; i < TopPractitioners.length; i+=pageSize) {
            pages.push(TopPractitioners.slice(i, i+pageSize));
        }

        if (pages.length === 0) {
            pages.push([]);
        }

        return pages;
    }, [pageSize, TopPractitioners])

    const reloadTopPractitioners = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Admin.getOrganizations({
                    type: 'PROFESSIONAL',
                }, dataSDK?.token)
            })
            .then(({body}) => {
                if (!!body && Array.isArray(body)) {
                    setTopPractitioners(body);
                }
            })
    }
    
    useEffect(() => reloadTopPractitioners(), []);

    return {
        tpPages,
        reloadTopPractitioners,
        setPageSize,
    }

}

export default useTopPractitionersSA;