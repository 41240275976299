import "./styles.css";
import React, {
  useState,
  useEffect,
  useContext
} from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser, updateProfilePhoto, uploadAsset, uploadImage, userLogout } from "../../store/actions/userActions";
import { getNameUser } from "../../helpers/userHelper";
import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalImageEditor from '../ModalImageEditor';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
import { useTranslation } from "react-i18next";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";
import styled from "styled-components";
import { ContextSuccess } from "../../Context";
toast.configure();

const Menu = ({
  show,
  onHide,
  ChatServiceInstance,
  activeNotification,
}: {
  show: boolean,
  onHide: (data: boolean) => void,
  ChatServiceInstance?: ChatServiceInterface
  activeNotification: () => void,
}) => {
  const { t, i18n } = useTranslation();
  const currentSuccess = useContext(ContextSuccess);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const user = useSelector((state: any) => state.getUser.user);
  const userRoles = useSelector((state: any) => state.getUser.userRoles);

  const [showModal, setShowModal] = useState(false);
  const [userProfile, setUserProfile] = useState('');
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    setUserProfile(user.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png')
  }, [user])

  function handleOnClickOk() {
    onHide(false);
  }

  const handleLogOut = () => {
    dispatch(userLogout());
    if (ChatServiceInstance) ChatServiceInstance.disconnect();
    navigate("/");
  };

  const showNotification = () => {
    if (location.pathname.includes('admin') || location.pathname.includes('chat')) {
      navigate(`/app?notifications=true`);
    }
    activeNotification();
    onHide(false);
  }

  const viewProfile = () => {
    if (userRoles.includes('HEALER')) {
      navigate(`../../../h/${userInfo.username}`);
    }
    else {
      if (location.pathname.includes('admin') || location.pathname.includes('chat')) {
        navigate(`../../../app/userProfile/${userInfo.username}`);
      }
      else {
        navigate(`../../../app/userProfile/${userInfo.username}`);
      }
    }
    handleOnClickOk();
  };

  const viewEditProfileHealer = () => {
    if (userRoles.includes('INDIVIDUAL') || userRoles.includes('HEALER')) {
      navigate(`../../../edit-healer-ind-profile/${userInfo.username}`);
    }
    else {
      navigate(`../../../edit-userProfile/${userInfo.username}`);
    }
    setShowMenu(false)
    handleOnClickOk();
  };

  function handleSaveImage(e: any) {

    fetch(e)
      .then(res => res.blob())
      .then(blob => {
        const contentType = e.split(';')[0].split(':')[1];
        const extension = contentType.split('/')[1]
        const file = new File([blob], 'profile' + userInfo.username, { type: contentType });

        if (file) {
          dispatch<any>(uploadAsset(
            file.type,
            file.name + '.' + extension,
            "profile_photo"
          ))
            .then((results: any) => {
              if (results.body) {
                dispatch<any>(uploadImage(
                  results.body,
                  contentType,
                  file,
                ))
                  .then((result: any) => {
                    if (result.status === 200) {
                      const urlImage = results.body.split('?')[0];
                      setUserProfile(urlImage);
                      dispatch<any>(updateProfilePhoto(
                        urlImage,
                        userInfo.username,
                      ))
                        .then((result: any) => {
                          if (result.status === 200) {
                            setUserProfile(urlImage);
                            setShowModal(false);
                            dispatch<any>(getUser(userInfo.username));
                          }
                          else {
                            setShowModal(false);
                          }
                        })
                        .catch((error: any) => {
                          console.log(error)
                        })
                    }
                  })
                  .catch((error: any) => {
                    console.log(error)
                  })

              }
              else {
                currentSuccess?.setSuccessData(false, true, t('toast_errorImageUrl'));
              }
            })
            .catch((error: any) => {
              console.log(error)
            })
        }
        else {
          currentSuccess?.setSuccessData(false, true, t('toast_addFile'));
        }

      })
  };

  function handleCloseModal(e: any, type: any) {
    setShowModal(() => false);
    console.log('close modal');
  };

  return (
    <>
      <div
        onClick={handleOnClickOk}
        className={`overlap ${show ? "active" : ""}`}
        id="activate-menu"
      ></div>
      <div id={`sidebar`} className={`${show ? "active" : ""}`}>
        <button className="buttonCloseMenu" onClick={handleOnClickOk}>
          <FontAwesomeIcon
            icon="times"
            size='lg'
            color={'var(--t-blue)'}
            className=" "
          />
        </button>
        <div className="photoProfile">
          <div className="containerImagProfile">
            <ModalImageEditor handleSaveImage={handleSaveImage} show={showModal} onHide={handleCloseModal} isProfilePhoto={true} />
            <img
              src={userProfile !== '' ? userProfile : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'}
              onClick={() => setShowModal(true)}
              alt=""
            />
          </div>

          <span>{getNameUser(user, userInfo, userRoles)}</span>

        </div>
        <ul className="listMenu">
          {userRoles.includes("SUPER") && (
            <>
              <ItemMenuLi onClick={() => navigate("/superadmin")}>
                <div className="letterMenuL letterA"> A </div>
                Superadmin center
              </ItemMenuLi>
            </>
          )}

          {userRoles.includes("ORGADMIN") && (
            <>
              <ItemMenuLi onClick={() => navigate("/admin")}>
                <div className="letterMenuL letterA"> A </div>
                {t('menu__adminCenter')}
              </ItemMenuLi>
            </>
          )}

          {(!userRoles.includes("SUPER")) && (
            <>
              <ItemMenuLiSub onClick={() => setShowMenu(!showMenu)}>
                <div>
                  <FontAwesomeIcon
                    icon="user"
                    size='lg'
                    color={'var(--t-blue)'}
                    className="iconMenuL  "

                  />
                  Account
                </div>
                <FontAwesomeIcon
                  icon={showMenu ? 'chevron-up' : 'chevron-down'}
                  size='sm'
                  color={'var(--t-blue)'}
                  className="iconMenuL  "
                />
              </ItemMenuLiSub>
              <ItemsSubMenu theme={{ main: showMenu ? 'activeMenu' : '' }}>
                <ItemSubMenu onClick={viewProfile}>View Profile</ItemSubMenu>
                <ItemSubMenu onClick={viewEditProfileHealer}>Edit Profile</ItemSubMenu>
              </ItemsSubMenu>
            </>
          )}

          <ItemMenuLi onClick={() => navigate("../../../modalities-services")}>
            <IconHealer />
            Modalities and Services
          </ItemMenuLi>

          <ItemMenuLi onClick={() => showNotification()}>
            <FontAwesomeIcon
              icon="bell"
              size='lg'
              color={'var(--t-blue)'}
              className="iconMenuL"
            />
            {t('menu__notifications')}
          </ItemMenuLi>

          {/* <ItemMenuLi>
            <FontAwesomeIcon
              icon="memo"
              size='lg'
              color={'var(--t-blue)'}
              className="iconMenuL  "

            />
            <a className="linkTermsPrivacy" href="/terms">{t('label_terms')}</a>
            &
            <a className="linkTermsPrivacy" href="/privacy">{t('label_privacy')}</a>
          </ItemMenuLi> */}
          <ItemMenuLi>
            <FontAwesomeIcon
              icon="memo"
              size='lg'
              color={'var(--t-blue)'}
              className="iconMenuL  "

            />
            <NavLink to="/terms">
              {t('label_terms')}
            </NavLink>
            {' & '}
            <NavLink to="/privacy">
              {t('label_privacy')}
            </NavLink>
          </ItemMenuLi>

          <ItemMenuLi onClick={() => handleLogOut()} id="logout-btn">
            <FontAwesomeIcon
              icon="sign-out-alt"
              size='lg'
              color={'var(--t-blue)'}
              className="iconMenuL  "

            />
            {t('menu__logOut')}
          </ItemMenuLi>

        </ul>
      </div>
    </>
  );
};

const NavLink = styled(Link)`
  text-decoration: underline;
`;
const iconHealerSvg = require("../../assets/img/icon_healer.svg");
const IconHealer = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  background-image: url(../../../icon_healer.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  margin-left: 20px;
  margin-right: 20px;
`
const ItemsSubMenu = styled.div.attrs(
  props => ({
    className: props.theme.main,
  })
)`
  height: 0;
  overflow: hidden;
  -webkit-transition: all 0.2s ease 0.2s;
  -moz-transition: all 0.2s ease 0.2s;
  -o-transition: all 0.2s ease 0.2s;
  -ms-transition: all 0.2s ease 0.2s;
  transition: all 0.2s ease 0.2s;

  &.activeMenu{
    height: 107px;
  }
`

const ItemSubMenu = styled.div`
  background-color: #eeeeee;
  text-align: left;
  padding: 19px 86px;
  width: auto;
  margin: 0 15px;
  cursor: pointer;

  &:hover{
    background-color: #e6e6e6;
  }
`

const ItemMenuLi = styled.li`
    align-items: center;
    display: flex;
    color: var(--t-blue);
    font-size: 16px;
    font-family: 'Inter';
    margin: 0px 15px !important;
    cursor: pointer;
    padding: 20px 0;
    border-radius: 11px;
    -webkit-transition: all 0.2s ease 0.2s;
    -moz-transition: all 0.2s ease 0.2s;
    -o-transition: all 0.2s ease 0.2s;
    -ms-transition: all 0.2s ease 0.2s;
    transition: all 0.2s ease 0.2s;

    &:hover{
      background-color: #e6e6e6;
    }
`

const ItemMenuLiSub = styled(ItemMenuLi)`
  justify-content: space-between;
`

export default Menu;
