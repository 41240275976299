import { IUserGradeResponse, OrgJoined } from "@vibe/sdk/interfaces/Admin/User";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../../store/store";
import { useEffect, useState } from "react";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import { IOrganizationExtended } from "@vibe/sdk/interfaces/Admin/Organization";
import { getRolesUsersView } from "../../../helpers/roleHelper";
import OrgCard from "./OrgCard";

export interface OrgJoinedExtended extends OrgJoined {
    logo?: string
    city: string
    state: string
    country: string
    seekers: number
    admin: {
        name: string
        email: string
        username: string
    }
    roles: string
    website?: string
}

const OtherOrgs = () => {

    const [orgs, setOrgs] = useState<OrgJoinedExtended[]>([]);
    const [cardsActive, setCardsActive] = useState<string[]>([]);

    const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);
    const healerOrg = useSelector((state: RootState) => state.getOrgs.organizationInfo);

    const handleCardActive = (e: string, action: boolean) => {
        if (action) {
            setCardsActive([...cardsActive, e])
        }
        else {
            setCardsActive(cardsActive.filter(c => c !== e))
        }
    }

    const getRolesOrg = (orgId: string): string => {
        const orgIndex = user.organizations.findIndex((o) => o === orgId);

        if (orgIndex > -1 && user.rolesByOrg.length >= orgIndex) {
            return getRolesUsersView(user.rolesByOrg[orgIndex]).join(', ');
        }

        return '';
    };

    const getOrgs = () => {
        if (user.joined) {
            const orgsToSearch = user.joined.filter((org) => org.orgId !== healerOrg.id);

            Promise.all(orgsToSearch.map((org) => getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return Promise.all([
                        dataSDK.sdk.Admin.getOrganization(org.orgId, true, dataSDK.token),
                        dataSDK.sdk.Admin.getOrganization(org.orgId, false, dataSDK.token),
                    ]);
                })))
                .then((resultsArray) => {
                    const orgsResult: OrgJoinedExtended[] = [];

                    resultsArray.forEach(([pending, notPending], idx) => {
                        if (notPending.statusCode === 200) {
                            const org = notPending.body as IOrganizationExtended;
                            orgsResult.push({
                                orgId: org.id,
                                orgName: org.name,
                                dateJoined: (user.joined || [])[idx].dateJoined,
                                logo: org.logo || '',
                                city: org.country || '',
                                state: org.state || '',
                                country: org.country || '',
                                seekers: org.seekers || 0,
                                admin:{
                                    name: `${org.orgAdmin?.firstname} ${org.orgAdmin?.lastname}`,
                                    email: org.orgAdmin?.email || '',
                                    username: org.created_by,
                                },
                                roles: getRolesOrg(org.id),
                                website: org.website || '',
                            });
                        }
                        else if (pending.statusCode === 200) {
                            const org = notPending.body as IOrganizationExtended;
                            orgsResult.push({
                                orgId: org.id,
                                orgName: org.name,
                                dateJoined: (user.joined || [])[idx].dateJoined,
                                logo: org.logo || '',
                                city: org.country || '',
                                state: org.state || '',
                                country: org.country || '',
                                seekers: org.seekers || 0,
                                admin:{
                                    name: `${org.orgAdmin?.firstname} ${org.orgAdmin?.lastname}`,
                                    email: org.orgAdmin?.email || '',
                                    username: org.created_by,
                                },
                                roles: getRolesOrg(org.id),
                                website: org.website || '',
                            });
                        }
                    })

                    setOrgs(orgsResult);
                })
                .catch((error) => {
                    console.log(error, 'Error getting orgs');
                    setOrgs([]);
                })
        }
    }

    useEffect(() => {
        if (user && user.joined) {
            getOrgs();
        }
        else {
            setOrgs([]);
        }
    }, [user]);

    return (
        <ContentAdminUpdate>
            <LabelTitle>These are the organizations that you have been invited to join.</LabelTitle>
            <WrapperCards>
                {orgs.map((org) => <OrgCard 
                    active={cardsActive.includes(org.orgId)} 
                    data={org} 
                    onActivation={handleCardActive} 
                />)}
            </WrapperCards>
        </ContentAdminUpdate>
    )
}

const LabelTitle = styled.div`
    width: 100%;
    color: #1A1A1A;

    font-feature-settings: 'clig' off, 'liga' off;
    /* Medium/100 */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 28px; /* 175% */
    letter-spacing: 0.2px;
`;

const ContentAdminUpdate = styled.div`
    display:  flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 15px;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;

    overflow: scroll;
`;

const WrapperCards = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, 280px);
    grid-template-rows: 176px;
    overflow: scroll;
    grid-row-gap: 15px;
    grid-column-gap: 15px;
`;

export default OtherOrgs;