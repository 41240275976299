import styled from "styled-components";

export const PrincipalContainer = styled.div`
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const ContainerDash = styled.div`
    width: 100%;
    display: flex;
    position: absolute;
    top: 80px;
    align-items: center;
`

export const ContainerTable = styled.div`
  background-color: white;
  margin: 0 20px;
  border-radius: 8px;
  padding: 12px;
  width: calc(100% - 300px);
`

export const StyledTable = styled.table`
    width: 100%;
    padding: 0 20px;
`;

export const TD = styled.td``;

export const TR = styled.tr`
  &:nth-child(even) {
    background-color: #F7F5F4;
  }

  & ${TD}{
    color: #333;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Strong/75 */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.1px;
    padding: 13px 10px;
    text-align: left;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
  }
`;

export const THead = styled.thead`
  & ${TD}{
    background-color: #F0F3F5;
  }
`;

export const TBody = styled.tbody`
    & ${TD}{
    padding: 8px 10px;
    }
`;

export const Filters = styled.div`
display: flex;
align-items: center;
gap: 12px;
margin-bottom: 12px;
`
export const InputSearch = styled.div`
border-radius: 9px;
border: 1px solid #ced6dd;
padding: 6px 10px;
width: 300px;
height: 37px;
display: flex;
align-items: center;
gap: 10px;
`
export const Input = styled.div``
export const SelectFilter = styled.div`
display: flex;
gap: 11px;
border-radius: 9px;
border: 1px solid black;
width: fit-content;
padding: 6px 10px;
height: 37px;
    align-items: center;
`

export const ViewUser = styled.div`
display: flex;
align-items: center;
gap: 12px;
`

export const Avatar = styled.img`
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    max-width: 32px;
    max-height: 32px;
    border-radius: 50%;
    object-fit: cover;
`

export const TextBlue = styled.div`
color: #2A4CD3;
font-feature-settings: 'clig' off, 'liga' off;
text-overflow: ellipsis;
/* Strong/75 */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: 0.1px;
overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const TextHead = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
export const Count = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
`

export const Price = styled.div`
color: #0F9936;
font-feature-settings: 'clig' off, 'liga' off;
/* Strong/200 */
font-family: Inter;
font-size: ${props => props.theme.size || '14px'};
font-style: normal;
font-weight: 600;
line-height: 32px; /* 177.778% */
letter-spacing: 0.1px;
`

export const IconGeoUp = styled.div`
  width: 0;
  height: 0;
  position: relative;
  top: -2px;
  border-right: 4px solid transparent;
  border-top: 4px solid transparent;
  border-left: 4px solid transparent;
  border-bottom: 4px solid ${props => props.theme.color || 'black'};
`