import IHS from "@vibe/sdk/interfaces/Modalities/hservices/HS";
import { useEffect, useState } from "react";
import { getDataSDK } from "../../../helpers/backendHelper";
import { IUserGradeResponse } from "@vibe/sdk/interfaces/Admin/User";
import styled from "styled-components";
import OutsideClickHandler from "react-outside-click-handler";
import { AvatarHealerCardHome, IconUser, NoAvatarHealerCardHome } from "../HomeCards/styles";
import { getColorsInitials, getInitials } from "../../../helpers/letterHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { formatCompactNumber } from "../../../helpers/numberHelper";
import { IconClicker } from "../../Home/HeaderHome/styles";
import { faInstagram, faFacebook, faYoutube, faTwitter, faLinkedin, faPinterest, faSnapchat, faTiktok } from "@fortawesome/free-brands-svg-icons";
import { PlatformType, PlatformTypeIcon } from "../HomeCards/HealerCard";
import { IMedia } from "@vibe/sdk/interfaces/Admin/utils";

export interface IGuideSummary {
    username?: string
    email?: string
    name?: string
    avatar?: string
    roles?: string[]
    services?: IHS[]
    socialMedia?: IMedia[]
    seekers?: number
}

export const MockGuideSummary: IGuideSummary = {
    username: '',
    email: '',
    name: '',
    avatar: '',
    roles: [],
    services: [],
    socialMedia: [],
    seekers: 0,
}

const SummaryGuide = ({
    isOpen,
    close,
    username = '',
    name = '',
    avatar = '',
    email = '',
}: {
    isOpen: boolean
    close: () => void
    username?: string
    name?: string
    avatar?: string
    email?: string
}) => {
    const [guide, setGuide] = useState<IGuideSummary>({ ...MockGuideSummary });

    const getHealerNoAvatar = () => {
        const num = [...guide.name || ''].reduce((acc, curr) => acc + curr.charCodeAt(0), 0);
        const colors = getColorsInitials(num);
        return <NoAvatarHealerCardHome
            background_color={colors.background}
            text_color={colors.color}
        >
            {getInitials(guide.name || '')}
        </NoAvatarHealerCardHome>;
    }

    const getIcon = (platform: PlatformType) => {
        if (platform === 'Substack') {
            return <>
                <svg width="18" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.9991 5.40625H0V8.24275H20.9991V5.40625Z" fill="var(--default-dark)" />
                    <path d="M0 10.8125V24.0004L10.4991 18.1107L21 24.0004V10.8125H0Z" fill="var(--default-dark)" />
                    <path d="M20.9991 0H0V2.83603H20.9991V0Z" fill="var(--default-dark)" />
                </svg>
            </>; //@todo
        }

        if (platform === 'Zaahah') {
            return <></>; //@todo
        }

        if (platform === 'Personal') {
            return <IconClicker
                icon="globe"
                size="lg"
                color="var(--default-dark)"
            />;
        }

        const platforms: Record<PlatformTypeIcon, IconDefinition> = {
            "Instagram": faInstagram,
            'Facebook': faFacebook,
            'YouTube': faYoutube,
            'Twitter': faTwitter,
            'LinkedIn': faLinkedin,
            'Pinterest': faPinterest,
            'Snapchat': faSnapchat,
            'TikTok': faTiktok,
        }

        return <IconClicker
            icon={platforms[platform]}
            size="lg"
            color="var(--default-dark)"
        />
    }

    const getSocialMedia = () => {
        return <RowSocialMedia>
            {(guide.socialMedia || []).map(s => (
                <a href={s.link} target="_blank" rel="noopener noreferrer">
                    {getIcon(s.type as PlatformType)}
                </a>
            ))}
        </RowSocialMedia>
    }

    useEffect(() => {
        setGuide({
            username,
            email,
            name,
            avatar,
        })

        if (username !== '') {
            getDataSDK().then((dataSDK) => {
                return dataSDK.sdk.Admin.getUser(username, 'OA', dataSDK.token);
            })
                .then((results) => {
                    if (results.statusCode === 200) {
                        const response = results.body as IUserGradeResponse;
                        setGuide({
                            username,
                            email: email || response.email || '',
                            name: name || `${response.firstname || ''} ${response.lastname || ''}`,
                            avatar: avatar || response.avatar || '',
                            roles: response.roles,
                            services: response.services || [],
                            socialMedia: response.socialMedia || [],
                            seekers: response.seekers || 0,
                        })
                    }
                    else {
                        console.error('Error getting guide', results);
                        setGuide({ ...MockGuideSummary });
                    }
                })
                .catch((error) => {
                    console.error('Error getting guide', error);
                    setGuide({ ...MockGuideSummary });
                });
        }

    }, [username])

    return (
        <FloatingCard isOpen={isOpen}>
            <OutsideClickHandler onOutsideClick={close}>
                <ColumnFlex>
                    <RowFlex>
                        <IconClickerAbsolute
                            icon={faTimes}
                            size='sm'
                            onClick={close}
                        />

                        {guide.avatar ? (
                            <AvatarHealerCardHome src={guide.avatar} />
                        ) : (
                            getHealerNoAvatar()
                        )}
                        <ColumnFlex>
                            <Title>{guide.name}</Title>
                            {guide.email}
                            {getSocialMedia()}
                        </ColumnFlex>
                    </RowFlex>

                    {(guide.seekers || 0) > 0 &&
                        <RowFlex>
                            <IconUser src={'./icon_seeker.png'} />
                            {formatCompactNumber(guide.seekers || 0)} Seekers
                        </RowFlex>
                    }
                    <ItemsA>
                        {(guide.services || []).slice(0, 3).map((alias) => (
                            <ItemA>{alias.name}</ItemA>
                        ))}
                        {(guide.services || []).length > 3 && <ItemA>+{(guide.services || []).length - 3}</ItemA>}
                    </ItemsA>
                </ColumnFlex>
            </OutsideClickHandler>
        </FloatingCard>
    )
}

export default SummaryGuide;

const RowSocialMedia = styled.div`  
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

const ItemsA = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 5px;
`;

const ItemA = styled.div`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  color: black;
  border-right: 1px solid black;
  padding-right: 12px;
`

const IconClickerAbsolute = styled(FontAwesomeIcon)`
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
`;

const FloatingCard = styled.div`
    display: ${({ isOpen }: { isOpen: boolean }) => isOpen ? 'flex' : 'none'};

    flex-direction: column;
    gap: 10px;
    padding: 10px;
    background-color: white;
    border: 1px solid var(--simple-gray);
    border-radius: 4px;
    position: absolute;
    z-index: 1000;
    top: 10px;
    left: 10px;

    color: #1A1A1A;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    justify-items: flex-start;
    align-items: flex-start;

    height: fit-content !important;
    max-height: fit-content !important;
    width: fit-content !important;
    max-width: fit-content !important;
    min-width: fit-content !important;

    -webkit-box-shadow: 0px 6px 8px 0px rgb(0 0 0 / 24%);
    -moz-box-shadow: 0px 6px 8px 0px rgb(0 0 0 / 24%);
    box-shadow: 0px 6px 8px 0px rgb(0 0 0 / 24%);
`;

const Title = styled.div`
    font-family: 'Inter-bold';
    font-size: 16px;
    font-weight: 600;
`;

const ColumnFlex = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
`;

const RowFlex = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
`;