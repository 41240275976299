import { AnyAction } from "@reduxjs/toolkit";
import React, { useContext } from "react";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import i18n from '../../i18n';
import { ContextSuccess } from "../../Context";
import {
    GET_CLUBS_ORG_ERROR, GET_CLUBS_ORG_SUCCESS, START_GET_CLUBS_ORG,
    START_GET_MY_CLUBS, GET_MY_CLUBS_SUCCESS, GET_MY_CLUBS_ERROR,
    START_POST_USER_AS_MEMBER, POST_USER_AS_MEMBER_SUCCESS,
    POST_USER_AS_MEMBER_ERROR,
    START_POST_USER_AS_ADVISOR,
    POST_USER_AS_ADVISOR_SUCCESS,
    POST_USER_AS_ADVISOR_ERROR,
    START_POST_USER_AS_FAN,
    POST_USER_AS_FAN_SUCCESS,
    POST_USER_AS_FAN_ERROR,
    START_POST_USER_AS_FOLLOWER,
    POST_USER_AS_FOLLOWER_SUCCESS,
    POST_USER_AS_FOLLOWER_ERROR,
    START_POST_USER_AS_JOIN_REQUESTED,
    POST_USER_AS_JOIN_REQUESTED_SUCCESS,
    POST_USER_AS_JOIN_REQUESTED_ERROR,
    START_POST_USER_AS_QUIT_REQUESTED,
    POST_USER_AS_QUIT_REQUESTED_SUCCESS,
    POST_USER_AS_QUIT_REQUESTED_ERROR,
    START_GET_USER_PENDING_CLUBS,
    GET_USER_PENDING_CLUBS_SUCCESS,
    GET_USER_PENDING_CLUBS_ERROR,
    START_DELETE_JOIN,
    DELETE_JOIN_SUCCESS,
    DELETE_JOIN_ERROR,
    START_GET_COMMUNITIES,
    GET_COMMUNITIES_SUCCESS,
    GET_COMMUNITIES_ERROR,
    START_GET_CLUB,
    GET_CLUB_SUCCESS,
    GET_CLUB_ERROR,
    ORGADMIN_ADVISOR_ERROR,
    ORGADMIN_ADVISOR_SUCCESS,
    START_ORGADMIN_ADVISOR,
    START_CLUBS_IM_ADV,
    CLUBS_IM_ADV_SUCCESS,
    CLUBS_IM_ADV_ERROR,
    START_GET_COMM,
    GET_COMM_SUCCESS,
    GET_COMM_ERROR,
    START_GET_CLUB_MEMBERS,
    GET_CLUB_MEMBERS_ERROR,
    GET_CLUB_MEMBERS_SUCCESS,
    START_EDIT_CLUB,
    EDIT_CLUB_SUCCESS,
    EDIT_CLUB_ERROR,
    GET_COMM_ORG_SETUP_ERROR,
    GET_COMM_ORG_SETUP_SUCCESS,
    START_GET_COMM_ORG_SETUP,
    START_GET_MY_CLUBS_EXTENDED,
    GET_MY_CLUBS_EXTENDED_SUCCESS,
    GET_MY_CLUBS_EXTENDED_ERROR
} from "../constants/clubConstants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backend, MicroServices, getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import { IClubExtended, IClubFilter } from "@vibe/sdk/dist/interfaces/Club/ClubInterface";
import IResponseError from "@vibe/sdk/dist/interfaces/IResponseError";
import { ClubDigestExtra } from "@vibe/sdk/dist/interfaces/Roster/getUserClubs";

toast.configure();

export const getOrgCommunities = (org_id: string, pending = false): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_COMM });
        Backend(
            MicroServices.Club,
            `/clubs?pending=${pending}&org_id=${org_id}&club_type=COMM`)
            .then((response) => response.json())
            .then(({ clubs }) => {
                dispatch({
                    type: GET_COMM_SUCCESS,
                    payload: clubs,
                })
                resolve(clubs);
            })
            .catch((error) => {
                dispatch({
                    type: GET_COMM_ERROR,
                    payload: error,
                });
            })
    });

export const getOrgCommSetup = (org_id: string, pending = false): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_COMM_ORG_SETUP });
        Backend(
            MicroServices.Club,
            `/clubs?pending=${pending}&org_id=${org_id}&club_type=COMM&school_setup=true`)
            .then((response) => response.json())
            .then(({ clubs }) => {
                dispatch({
                    type: GET_COMM_ORG_SETUP_SUCCESS,
                    payload: clubs,
                })
                resolve(clubs);
            })
            .catch((error) => {
                dispatch({
                    type: GET_COMM_ORG_SETUP_ERROR,
                    payload: error,
                });
            })
    });

interface getClubsProps {
    pending?: boolean,
    deleted?: boolean,
    youthOrg?: boolean,
    org_id?: string,
    club_type?: string,
    name?: string,
    type?: string,
    member_roles?: string,
    city?: string,
    country?: string,
    limit?: number,
    lastEvaluatedKey?: string,
    org_ids?: string[],
}

export const getClubs = ({
    pending = false,
    deleted = false,
    youthOrg = false,
    org_id = '',
    club_type = '',
    name = '',
    type = '',
    member_roles = '',
    city = '',
    country = '',
    limit = 0,
    lastEvaluatedKey = '',
    org_ids = [],
}: getClubsProps): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_CLUBS_ORG });

        const filters: IClubFilter = {
            pending,
            deleted,
        }

        if (youthOrg) filters.youthOrg = youthOrg;
        if (org_id) filters.org_id = org_id;
        if (club_type) filters.club_type = club_type;
        if (name) filters.name = name;
        if (type) filters.type = type;
        if (member_roles) filters.member_roles = member_roles;
        if (city) filters.city = city;
        if (country) filters.country = country;
        if (limit) filters.limit = limit;
        if (lastEvaluatedKey) filters.lastEvaluatedKey = lastEvaluatedKey;

        console.log('org_ids', org_ids)

        if (org_ids.length <= 1) {
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Club.getClubs(filters, dataSDK.token);
                })
                .then((results) => {
                    if (results.statusCode === 200) {
                        const data: any = results.body;
                        let resultClubs: IClubExtended[] = []

                        if (!pending) {
                            //@todo correct it in back :'v
                            resultClubs = (data.clubs as IClubExtended[]).filter(c => c.status === 'ACTIVE')
                        }
                        else {
                            resultClubs = data.clubs as IClubExtended[];
                        }

                        dispatch({
                            type: GET_CLUBS_ORG_SUCCESS,
                            payload: resultClubs,
                        })
                    }
                    else {
                        dispatch({
                            type: GET_CLUBS_ORG_ERROR,
                            payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
                        });
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: GET_COMMUNITIES_ERROR,
                        payload: error,
                    });
                })
        } else {
            Promise.all(org_ids.map((id) => new Promise<IClubExtended[]>((res, rej) => {
                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        return dataSDK.sdk.Club.getClubs({ ...filters, org_id: id }, dataSDK.token);
                    })
                    .then((results) => {
                        if (results.statusCode === 200) {
                            const data: any = results.body;
                            let resultClubs: IClubExtended[] = []

                            if (!pending) {
                                //@todo correct it in back :'v
                                resultClubs = (data.clubs as IClubExtended[]).filter(c => c.status === 'ACTIVE')
                            }
                            else {
                                resultClubs = data.clubs as IClubExtended[];
                            }

                            res(resultClubs)
                        }
                        else {
                            res([])
                        }
                    })
                    .catch((error) => {
                        res([])
                    })
            })))
                .then((res) => {
                    const clubs = res.flat().filter((c, idx, arr) => idx === arr.findIndex((c2) => c.id === c2.id))
                    dispatch({
                        type: GET_CLUBS_ORG_SUCCESS,
                        payload: clubs,
                    })
                })
                .catch((error) => {
                    dispatch({
                        type: GET_COMMUNITIES_ERROR,
                        payload: error,
                    });
                })
        }
    });

export const getCommunities = (org_id: string, pending = false): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_COMMUNITIES });

        const filters: IClubFilter = {
            pending,
            club_type: "COMM",
            org_id: org_id,
        }

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Club.getClubs(filters, dataSDK.token);
            })
            .then((results) => {

                if (results.statusCode === 200) {
                    const data: any = results.body;
                    dispatch({
                        type: GET_COMMUNITIES_SUCCESS,
                        payload: data.clubs,
                    })
                }
                else {
                    dispatch({
                        type: GET_COMMUNITIES_ERROR,
                        payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_COMMUNITIES_ERROR,
                    payload: error,
                });
            })
    })

export const getClub = (club_id: string, pending = false): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_CLUB });
        if (!club_id) {
            dispatch({
                type: GET_CLUB_ERROR,
                payload: 'Undefined club_id',
            });
            return
        }

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Club.getClub(club_id, pending, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) {
                    const response = results.body as IClubExtended;
                    dispatch({
                        type: GET_CLUB_SUCCESS,
                        payload: response,
                    })
                }
                else {
                    const error = (results.body as IResponseError).error || (results.body as IResponseError).message
                    dispatch({
                        type: GET_CLUB_ERROR,
                        payload: error,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_CLUB_ERROR,
                    payload: error,
                });
            })
    });

export const getClubsImAdv = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_CLUBS_IM_ADV });

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Club.getClubsOfAdvisor(username, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) {
                    dispatch({
                        type: CLUBS_IM_ADV_SUCCESS,
                        payload: (results.body as IClubExtended[]),
                    })
                }
                else {
                    dispatch({
                        type: CLUBS_IM_ADV_ERROR,
                        payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: CLUBS_IM_ADV_ERROR,
                    payload: error,
                });
            })
    });

export const getClubNoIdeaPending = (club_id: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_CLUB });

        if (!club_id) {
            dispatch({
                type: GET_CLUB_ERROR,
                payload: 'Undefined club_id',
            });
            return
        }

        Backend(
            MicroServices.Club,
            `/clubs/${club_id}?pending=${false}`)
            .then(async (response) => {
                if (response.status === 404) {
                    const responseNotPending = await Backend(
                        MicroServices.Club,
                        `/clubs/${club_id}?pending=${true}`)
                    return responseNotPending.json();
                }
                else {
                    return response.json();
                }
            })
            .then((club) => {
                dispatch({
                    type: GET_CLUB_SUCCESS,
                    payload: club,
                })
            })
            .catch((error) => {
                dispatch({
                    type: GET_CLUB_ERROR,
                    payload: error,
                });
            })
    });

export const getMyClubs = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_MY_CLUBS });

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Roster.getUserClubs({ username }, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) {
                    dispatch({
                        type: GET_MY_CLUBS_SUCCESS,
                        payload: results.body as ClubDigestExtra[],
                    })
                }
                else {
                    dispatch({
                        type: GET_MY_CLUBS_ERROR,
                        payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_MY_CLUBS_ERROR,
                    payload: error,
                });
            })
    });

export const getMyClubsExtended = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_MY_CLUBS_EXTENDED });

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Roster.getUserClubsExtended({ username }, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) {
                    dispatch({
                        type: GET_MY_CLUBS_EXTENDED_SUCCESS,
                        payload: results.body as IClubExtended[],
                    })
                }
                else {
                    dispatch({
                        type: GET_MY_CLUBS_EXTENDED_ERROR,
                        payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_MY_CLUBS_EXTENDED_ERROR,
                    payload: error,
                });
            })
    });

export const userAsMember = (clubId: string, username: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_POST_USER_AS_MEMBER });
        const { t } = i18n;
        Backend(
            MicroServices.Club,
            `/clubs/${clubId}/member?username=${username}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then((response) => {

                if (response.status === 201) {
                    dispatch({
                        type: POST_USER_AS_MEMBER_SUCCESS,
                    });
                    resolve(true);
                    return;
                }
                else if (response.status === 400) {
                    dispatch({
                        type: POST_USER_AS_MEMBER_ERROR,
                    });
                    console.error('Guard failed');
                    //toast.error(t('toast_ThereWasErrorTry'));
                    return Promise.reject();
                }
                else if (response.status === 404) {
                    return response.json();
                }

                //toast.error(t('toast_ThereWasErrorTry'));
                console.error(response);
                return Promise.reject();
            })
            .then((response) => {
                console.error(response.message);
                dispatch({
                    type: POST_USER_AS_MEMBER_ERROR,
                    payload: response.message,
                });
                //toast.error(`${t('toast_ThereWasError')} ${response.message}`);
                return Promise.reject();
            })
            .catch((error) => {
                dispatch({
                    type: POST_USER_AS_MEMBER_ERROR,
                    payload: error,
                });
            })
    });

export const userAsAdvisor = (clubId: string, username: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_POST_USER_AS_ADVISOR });
        const { t } = i18n;
        Backend(
            MicroServices.Club,
            `/clubs/${clubId}/advisor?username=${username}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then((response) => {

                if (response.status === 201) {
                    dispatch({
                        type: POST_USER_AS_ADVISOR_SUCCESS,
                    });
                    resolve(true);
                    return;
                }
                else if (response.status === 400) {
                    dispatch({
                        type: POST_USER_AS_ADVISOR_ERROR,
                    });
                    console.error('Guard failed');
                    //toast.error(t('toast_ThereWasErrorTry'));
                    return Promise.reject();
                }
                else if (response.status === 404) {
                    return response.json();
                }

                //toast.error(t('toast_ThereWasErrorTry'));
                console.error(response);
                return Promise.reject();
            })
            .then((response) => {
                console.error(response.message);
                dispatch({
                    type: POST_USER_AS_ADVISOR_ERROR,
                    payload: response.message,
                });
                //toast.error(`${t('toast_ThereWasError')} ${response.message}`);
                return Promise.reject();
            })
            .catch((error) => {
                dispatch({
                    type: POST_USER_AS_ADVISOR_ERROR,
                    payload: error,
                });
            })
    });

export const userAsFan = (clubId: string, username: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_POST_USER_AS_FAN });
        const { t } = i18n;
        Backend(
            MicroServices.Club,
            `/clubs/${clubId}/fan?username=${username}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then((response) => {

                if (response.status === 201) {
                    dispatch({
                        type: POST_USER_AS_FAN_SUCCESS,
                    });
                    resolve(true);
                    return;
                }
                else if (response.status === 400) {
                    dispatch({
                        type: POST_USER_AS_FAN_ERROR,
                    });
                    console.error('Guard failed');
                    //toast.error(t('toast_ThereWasErrorTry'));
                    return Promise.reject();
                }
                else if (response.status === 404) {
                    return response.json();
                }

                //toast.error(t('toast_ThereWasErrorTry'));
                console.error(response);
                return Promise.reject();
            })
            .then((response) => {
                console.error(response.message);
                dispatch({
                    type: POST_USER_AS_FAN_ERROR,
                    payload: response.message,
                });
                toast.error(`${t('toast_ThereWasError')} ${response.message}`);
                return Promise.reject();
            })
            .catch((error) => {
                dispatch({
                    type: POST_USER_AS_FAN_ERROR,
                    payload: error,
                });
            })
    });

export const userAsFollower = (clubId: string, username: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_POST_USER_AS_FOLLOWER });
        const { t } = i18n;
        Backend(
            MicroServices.Club,
            `/clubs/${clubId}/follower?username=${username}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then((response) => {

                if (response.status === 201) {
                    dispatch({
                        type: POST_USER_AS_FOLLOWER_SUCCESS,
                    });
                    resolve(true);
                    return;
                }
                else if (response.status === 400) {
                    dispatch({
                        type: POST_USER_AS_FOLLOWER_ERROR,
                    });
                    console.error('Guard failed');
                    //toast.error(t('toast_ThereWasErrorTry'));
                    return Promise.reject();
                }
                else if (response.status === 404) {
                    return response.json();
                }

                //toast.error(t('toast_ThereWasErrorTry'));
                console.error(response);
                return Promise.reject();
            })
            .then((response) => {
                console.error(response.message);
                dispatch({
                    type: POST_USER_AS_FOLLOWER_ERROR,
                    payload: response.message,
                });
                //toast.error(`${t('toast_ThereWasError')} ${response.message}`);
                return Promise.reject();
            })
            .catch((error) => {
                dispatch({
                    type: POST_USER_AS_FOLLOWER_ERROR,
                    payload: error,
                });
            })
    });

export const userAsJoinRequested = (clubId: string, username: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_POST_USER_AS_JOIN_REQUESTED });
        const { t } = i18n;
        Backend(
            MicroServices.Club,
            `/clubs/${clubId}/join?username=${username}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then((response) => {

                if (response.status === 201) {
                    dispatch({
                        type: POST_USER_AS_JOIN_REQUESTED_SUCCESS,
                    });
                    resolve(true);
                    return;
                }
                else if (response.status === 400 || response.status === 404) {

                    return response.json();
                }

                //toast.error(t('toast_ThereWasErrorTry'));
                console.error(response);
                return Promise.reject();
            })
            .then((response) => {
                console.error(response.message);
                dispatch({
                    type: POST_USER_AS_JOIN_REQUESTED_ERROR,
                    payload: response.message,
                });
                //toast.error(`${t('toast_ThereWasError')} ${response.message}`);
                return Promise.reject();
            })
            .catch((error) => {
                dispatch({
                    type: POST_USER_AS_JOIN_REQUESTED_ERROR,
                    payload: error,
                });
            })
    });

export const userAsQuitRequested = (clubId: string, username: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_POST_USER_AS_QUIT_REQUESTED });
        const { t } = i18n;
        Backend(
            MicroServices.Club,
            `/clubs/${clubId}/join?username=${username}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then((response) => {

                if (response.status === 201) {
                    dispatch({
                        type: POST_USER_AS_QUIT_REQUESTED_SUCCESS,
                    });
                    resolve(true);
                    return;
                }
                else if (response.status === 400 || response.status === 404) {

                    return response.json();
                }

                //toast.error(t('toast_ThereWasErrorTry'));
                console.error(response);
                return Promise.reject();
            })
            .then((response) => {
                console.error(response.message);
                dispatch({
                    type: POST_USER_AS_QUIT_REQUESTED_ERROR,
                    payload: response.message,
                });
                //toast.error(`${t('toast_ThereWasError')} ${response.message}`);
                return Promise.reject();
            })
            .catch((error) => {
                dispatch({
                    type: POST_USER_AS_QUIT_REQUESTED_ERROR,
                    payload: error,
                });
            })
    });

export const getMyPendingClubs = (username: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_USER_PENDING_CLUBS });

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Club.getPendingRequestClubs(username, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode == 200) {
                    dispatch({
                        type: GET_USER_PENDING_CLUBS_SUCCESS,
                        payload: results.body,
                    })
                }
                else if (results.statusCode == 404) {
                    dispatch({
                        type: GET_USER_PENDING_CLUBS_SUCCESS,
                        payload: [],
                    })
                }
                else {
                    dispatch({
                        type: GET_USER_PENDING_CLUBS_ERROR,
                        payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_USER_PENDING_CLUBS_ERROR,
                    payload: error,
                });
            })
    });

export const addOrgAdminAsAdvisor = (club_id: string, username: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_ORGADMIN_ADVISOR });

        Backend(
            MicroServices.Club,
            `/clubs/${club_id}/advisor?username=${username}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then((response) => response.json())
            .then((results) => {
                dispatch({
                    type: ORGADMIN_ADVISOR_SUCCESS,
                })
            })
            .catch((error) => {
                dispatch({
                    type: ORGADMIN_ADVISOR_ERROR,
                    payload: error,
                });
            })
    });

export const getClubMembers = (club_id: string, username: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_CLUB_MEMBERS });

        Backend(
            MicroServices.Club,
            `/clubs/${club_id}/members?username=${username}`)
            .then((response) => response.json())
            .then((results) => {
                if (Array.isArray(results)) {
                    resolve(results);
                    dispatch({
                        type: GET_CLUB_MEMBERS_SUCCESS,
                        payload: results,
                    })
                } else {
                    resolve([]);
                    dispatch({
                        type: GET_CLUB_MEMBERS_SUCCESS,
                        payload: [],
                    })
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_CLUB_MEMBERS_ERROR,
                    payload: error,
                });
            });
    });

export const editClub = (club: any): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_EDIT_CLUB });

        Backend(
            MicroServices.Club,
            `/clubs/${club.id}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(club),
            })
            .then((response) => response.json())
            .then((results) => {
                dispatch({
                    type: EDIT_CLUB_SUCCESS,
                })
                resolve(true)
            })
            .catch((error) => {
                dispatch({
                    type: EDIT_CLUB_ERROR,
                    payload: error,
                });
                resolve(false)
            })
    });