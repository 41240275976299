import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import { useEffect, useState } from "react";
import { BaseFieldState } from "./utils";
import SelectItem from "../../interfaces/SelectItem";
import OutsideClickHandler from "react-outside-click-handler";
import { SetupInvites } from "../../interfaces/store/inviteState";

const SelectorEmails = ({
    options,
    selected,
    setSelected,
    searchItem,
    setSearchItem,
    backgroundColor = "white",
    border = "1px solid var(--simple-gray)",
    placeholder = "Select an item",
}: {
    options: SetupInvites[]
    selected: BaseFieldState<SetupInvites>
    setSelected: (data: BaseFieldState<SetupInvites>) => void
    searchItem: string,
    setSearchItem: (data: string) => void,
    backgroundColor?: string
    border?: string
    placeholder?: string
}) => {

    const [showOptions, setShowOptions] = useState<boolean>(false);

    useEffect(() => {
        const itemFound = options.find((Item) =>
            Item.email &&
            (Item.email.toLocaleLowerCase() === searchItem ||
                Item.email.toLocaleUpperCase() === searchItem ||
                Item.email === searchItem)
        )

        if (itemFound) {
            setSearchItem(itemFound.email.toLocaleLowerCase());
            setShowOptions(false);
            setSelected({
                ...selected,
                value: itemFound
            });
        }
        else{
            setSelected({
                ...selected,
                value: {
                    email: searchItem.toLocaleLowerCase(),
                    lastName: '',
                    firstName: '',
                }
            });
        }
    }, [searchItem])

    const getFiltered = (listName: SetupInvites[]) => {
        return listName.filter((item: SetupInvites) => {
            if (!searchItem) return true;
    
            if (item.email && (
                item.email.toUpperCase().includes(searchItem) ||
                item.email.toLowerCase().includes(searchItem) ||
                item.email.includes(searchItem)
            )) {
                return true;
            }
    
            return false;
        })
    }

    return (
        <WrapperSelect>
            <Select_Input
                onClick={() => setShowOptions(true)}
                value={searchItem.toLocaleLowerCase()}
                placeholder={placeholder}
                onChange={(e) => setSearchItem(e.currentTarget.value.toLocaleLowerCase())}
                backgroundColor={backgroundColor}
                border={border}
                onFocus={() => setShowOptions(true)}
            />
            {showOptions &&
                <OutsideClickHandler onOutsideClick={() => setShowOptions(false)}>
                    <Items>
                        {getFiltered(options).map((active, index) => (
                            <Item onClick={() => {
                                setSearchItem(active?.email || '')
                                setShowOptions(false)
                            }}
                                key={index}>
                                {active.email}
                            </Item>
                        ))}
                    </Items>
                </OutsideClickHandler>
            }
        </WrapperSelect>
    )
}

const WrapperSelect = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
`;

interface Select_InputProps {
    backgroundColor: string
    border: string
}

const Select_Input = styled.input`
    outline: none;
    width: 100%;
    height: 100%;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Inter';
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;

    background-color: ${(props: Select_InputProps) => props.backgroundColor};
    border: ${(props: Select_InputProps) => props.border};
    color: ${(props: Select_InputProps) => props.backgroundColor === 'white' ? 'black' : 'white'};

    &::placeholder{
        color: var(--simple-gray);
        font-family: 'Inter';
    }
`;

const IconDropdownInput = styled(FontAwesomeIcon)`
    cursor: pointer;
    position: absolute;
    z-index: 0;
    top: 37%;
    left: 90%;
`;

const Item = styled.li`
    background-color: white;
    padding: 10px 20px;
    text-align: left;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;

    &:last-child {
        border-radius: 0 0 10px 10px;
        -webkit-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        -moz-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
    }

    &:hover {
        background-color: #e8e8e8;
    }
`;

const Items = styled.ul`
    width: 100%;
    position: absolute;
    top: 100%;
    z-index: 10;
    overflow-y: scroll;
    max-height: 200px;
    border-radius: 12px;
    left: 0px;
    box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
`;

export default SelectorEmails;