import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Card = styled.div`
    width: 100%;
    background-color: white;
    border-radius: 8px;
    border: 1px solid var(--simple-gray);
    padding: 17px;
    position: relative;
`

export const InfoProfile = styled.div`
    display: flex;
    gap: 12px;
`

export const Avatar = styled.img`
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    max-width: 80px;
    max-height: 80px;
    border-radius: 8px;
    object-fit: cover;
`

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    margin-right: 7px;
    cursor: pointer;
`;

export const IconSave = styled(FontAwesomeIcon)`
    cursor: pointer;
    position: absolute;
    right: 0px;
`;

export const TitleCard = styled.div`
    font-family: 'Inter-Bold';
    font-size: 16px;
    line-height: 130%;
    color: #2E2623;
    text-align: left;
    display: flex;
    justify-content: space-between;
`

export const Tabs = styled.div`
    display: flex;
    padding: 10px 0;
    gap: 12px;
`

export const Tab = styled.div.attrs(props => ({
    className: props.theme.main
}))`
    
    padding: 2px 0; 
    font-size: 14px;  
    color: #7A7978;
    cursor: pointer;
    position: relative;

    &.active{
        font-family: 'Inter-Bold';
        border-bottom: 3px solid orange;
        color: black;
    }

    &.inactive{
        font-family: 'Inter';
        border-bottom: 0px solid orange;
    }
`


export const Location = styled.div`
    font-family: 'Inter';
    font-size: 14px;
    text-align: left;
    margin-top: ${props => props.theme.top || '0px'};
`

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    text-align: left;
    width: 100%;
`

export const Name = styled.div`
    font-family: 'Inter-Bold';
    font-size: 14px;
    line-height: 28px;
`


export const ReadMore = styled.div`
    font-family: 'Inter-Bold';
    font-size: 14px;
    line-height: 17px;
    color: var(--absolute-zero);
    text-align: left;
    cursor: pointer;
`

export const ButtonSeeAll = styled.div`
    padding: 4px 24px;
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #665D5A;
    border-radius: 8px;
    font-family: 'Inter-Bold';
    font-size: 16px; 
    color: #665D5A;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
`

export const Options = styled.div`
    position: absolute;
    background-color: white;
    padding: 7px 15px 15px 15px;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    top: 44px;
    -webkit-box-shadow: 0px 22px 13px -19px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 22px 13px -19px rgba(0,0,0,0.75);
    box-shadow: 0px 22px 13px -19px rgba(0,0,0,0.75);
    z-index: 1;
`
export const Option = styled.div`
    padding: 9px 11px;
    border: 1px solid #bfbfbf;
    border-radius: 8px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`


export const OptionsFilter = styled.div`
    position: absolute;
    background-color: white;
    width: calc(100% + 2px);
    z-index: 1;
    left: -1px;
    top: 33px;
    border: 1px solid #bfbfbf;
    padding: 7px 0px;
    border-radius: 0 0 9px 9px;
`
export const OptionFilter = styled.div`
    font-family: 'Inter';
    padding: 8px 15px;
    cursor: pointer;
    &:hover{
        background-color: #ebe8e6;
    }
`

export const Interested = styled.div`
    display: flex;
    align-items: center;
`
export const Attend = styled.div`
    padding-left: 9px;
    border-left: 1px solid black;
    
`

export const InfoCount = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #2E2623;
    gap: 10px;
`

export const ButtonsFilter = styled.div`
    display: flex;
    gap: 5px;
    
`
export const ButtonFilter = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    font-family: 'Inter';
    font-size: 16px;
    padding: 8px 15px;
    border: 1px solid #BEBEBE;
    border-radius: 20px;
    background-color: white;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    &.active{
        background-color: #E5DCD0;
        font-family: 'Inter-Bold';
    }
`