import { useEffect, useState } from "react"
import { Backend, MicroServices } from "../helpers/backendHelper";
import { GradeClean } from "../interfaces/personalization/grades";
import SelectItem from "../interfaces/SelectItem";

const useGrades = () => {
  const [grades, setGrades] = useState<GradeClean[]>([]);
  const [gradesSelect, setGradesSelect] = useState<SelectItem[]>([])

  // useEffect(() => {
  //   Backend(
  //     MicroServices.Roster,
  //     '/p13n/grades'
  //   )
  //     .then((response) => response.json())
  //     .then((grades) => setGrades(grades))
  // }, [])

  useEffect(() => {
    setGradesSelect(
      grades
        .map((grade) => ({
          label: grade.listLabel,
          value: grade.id.toString()
        }))
        .sort((a, b) => Number(a.value) - Number(b.value))
    )
  }, [JSON.stringify(grades)])
  
  const setCountry = (country: string) => {
    if (!country) return;
    Backend(
      MicroServices.Roster,
      `/p13n/grades?country=${country}`
    )
      .then((response) => response.json())
      .then((grades) => setGrades(grades))
  }

  return {
    grades,
    gradesSelect,
    setCountry,
  }
}

export default useGrades;