import { useEffect, useMemo, useState, useContext } from "react";
import TwoColumns from "../../../TwoColumns";
import {
    CancelButton,
    ContentWrapper,
    CreatableSelectWrapper,
    Header,
    Row,
    SelectWrapper,
    SaveButton,
    Title,
    BaseInputWrapper,
    CreateBtn,
} from "../Modals/components";
import { customStylesRSMulti, DropdownIndicator, customStylesRSLeft, customStylesRSMultiNoDropdown, customStylesRSMultiNoDropdownWhite, customStylesRSMultiWhite } from "../../../../helpers/selectHelper";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ModalDiscardChanges from "../../../SetupSetup/ModalDiscardChanges";
import { useNavigate, useParams } from "react-router-dom";
import { Backend, MicroServices, getDataSDK, getDataSDKResponse } from "../../../../helpers/backendHelper";
import { toast } from "react-toastify";
import useBenefitsSA from "../../../../hooks/Superadmin/Modalities/useBESA";
import useProfessionalOrgsSA from "../../../../hooks/Superadmin/Modalities/usePOSA";
import useTherapeuticAreasSA from "../../../../hooks/Superadmin/Modalities/useTASA";
import useTopPractitionersSA from "../../../../hooks/Superadmin/Modalities/useTPSA";
import useModalitiesSA from "../../../../hooks/Superadmin/Modalities/useModalitiesSA";
import { ItemCreate, ItemCreateExisting, ItemCreateNew, ItemWebsiteCreateExisting, ItemWebsiteCreateNew } from "../../../../interfaces/Superadmin/modalities/Modality/Modality";
import ModalInputWebsite from "../Create/components/ModalInputWebsite";
import { BaseTextAreaWrapper, ClickableIcon } from "../Modals/components";
import Modality from '@vibe/sdk/interfaces/Modalities/modalities/Modality/Modality';
import { CustomOption, ListItem, ListWrapper } from "../Create";
import { useSelector } from "react-redux";
import { ContextSuccess } from "../../../../Context";
import { BaseFieldState, getFieldState } from "../../../BaseComponents/utils";
import { SelectMultiWrapper } from "../../../BaseComponents/SelectMultiWrapper";
import ButtonSpinner from "../../../BaseComponents/ButtonSpinner";
import CreatePOModal from "../Modals/CreatePOModal";
import CreateTPModal from "../Modals/CreateTPModal";
import BlankTable from "../../../BaseComponents/BlankTable";
import { onHideType } from "../../../BaseComponents/Modals/ModalUnsaved";
import { Imp } from "../../../signupsetuphealerind";
import TextEditor from "../../../BaseComponents/TextEditor";
import { Loader } from "../../../BaseComponents/Loader";
import { ISignUpUnregisteredOrganizationRequest } from "@vibe/sdk/interfaces/AccessManager/unregisteredOrg";
import { IOrganizationExtended } from "@vibe/sdk/interfaces/Admin/Organization";
import { ISignUpUnregisteredIndependentRequest } from "@vibe/sdk/interfaces/AccessManager/unregisteredIndependent";
import useActiveCountry from "../../../../hooks/Locations/useActiveCountry";
import { ItemCheckbox, SquareIcon } from "../../../Events/TabDateType";
import { faSquare, faSquareCheck } from "@fortawesome/pro-regular-svg-icons";
import Contributor from "@vibe/sdk/interfaces/Modalities/contributors/Contributor";
import { unixToDateFormatted } from "../../../../helpers/dateHelper";
import ModalContributorSA from "../../Contributors/ModalContributorSA";
import { cleanHTML } from "../../../../helpers/letterHelper";

export interface ModalityOptions<T> {
    value: T;
    label: string;
    __isNew__: boolean;
}

export interface valueWebsite {
    id: string;
    website: string;
}

const UpdateModality = () => {
    const { t } = useTranslation();
    const currentSuccess = useContext(ContextSuccess);
    const navigate = useNavigate();
    const { id } = useParams();
    const username = useSelector((state: any) => state.getUser.userInfo.username);

    const { activeCountriesReactSelect } = useActiveCountry();

    const { benefitsPages, reloadBenefits } = useBenefitsSA({
        pageSizeProp: 100,
        searchName: '',
    });
    const { poPages } = useProfessionalOrgsSA(100);
    const { taPages, reloadTherapeuticAreas } = useTherapeuticAreasSA({
        pageSizeProp: 100,
        searchName: '',
    });
    const { tpPages, reloadTopPractitioners } = useTopPractitionersSA(100);
    const { modalitiesPages, reloadModalities } = useModalitiesSA({
        pageSizeProp: 1000,
        searchName: '',
    });

    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
    const [loadingData, setLoadingData] = useState<boolean>(false);

    const [Modality, setModality] = useState<Modality>();

    const [ModalityName, setModalityName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [ModalityDescription, setModalityDescription] = useState<BaseFieldState<string>>(getFieldState(''));
    const [ModalityWhat, setModalityWhat] = useState<BaseFieldState<string>>(getFieldState(''));
    const [ModalityParentsID, setModalityParentsID] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [ModalityAliases, setModalityAliases] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [ModalityTA, setModalityTA] = useState<BaseFieldState<(ItemCreateExisting | ItemCreateNew)[]>>(getFieldState([]));
    const [ModalityBE, setModalityBE] = useState<BaseFieldState<(ItemCreateExisting | ItemCreateNew)[]>>(getFieldState([]));
    const [ModalityPrivate, setModalityPrivate] = useState<BaseFieldState<boolean>>(getFieldState(false));

    const [ModalitySelectedTA, setModalitySelectedTA] = useState<ModalityOptions<string>[]>([]);
    const [ModalitySelectedBE, setModalitySelectedBE] = useState<ModalityOptions<string>[]>([]);
    const [ModalitySelectedParent, setModalitySelectedParent] = useState<ModalityOptions<string>[]>();
    const [ModalitySelectedAliases, setModalitySelectedAliases] = useState<ModalityOptions<string>[]>([]);

    const [ModalityOptionsTA, setModalityOptionsTA] = useState<ModalityOptions<string>[]>([]);
    const [ModalityOptionsBE, setModalityOptionsBE] = useState<ModalityOptions<string>[]>([]);
    const [ModalityOptionsPO, setModalityOptionsPO] = useState<ModalityOptions<valueWebsite>[]>([]);
    const [ModalityOptionsTP, setModalityOptionsTP] = useState<ModalityOptions<valueWebsite>[]>([]);
    const [ModalityOptionsParent, setModalityOptionsParent] = useState<ModalityOptions<string>[]>([]);

    const [ModalityContributors, setModalityContributors] = useState<Contributor[]>([]);
    const [ModalityPOs, setModalityPOs] = useState<(ISignUpUnregisteredOrganizationRequest | IOrganizationExtended)[]>([]);
    const [ModalityTPs, setModalityTPs] = useState<(ISignUpUnregisteredIndependentRequest | IOrganizationExtended)[]>([]);

    const [showCreate, setShowCreate] = useState<boolean>(false);
    const [showCreateTP, setShowCreateTP] = useState<boolean>(false);
    const [showAssignContributor, setShowAssignContributor] = useState<boolean>(false);

    useEffect(() => {
        getModality()
    }, []);

    useEffect(() => {
        getModality()
    }, [id]);

    useEffect(() => {
        setFields()
    }, [Modality]);

    useEffect(() => {
        setModalityOptionsBE(benefitsPages[0].map((b) => ({
            label: b.name,
            value: b.id,
            __isNew__: false,
        })))
    }, [benefitsPages])

    useEffect(() => {
        setModalityOptionsPO(poPages[0].map((b) => ({
            label: b.name,
            value: {
                id: b.id,
                website: b.website,
            },
            __isNew__: false,
        })))
    }, [poPages])

    useEffect(() => {
        setModalityOptionsTA(taPages[0].map((b) => ({
            label: b.name,
            value: b.id,
            __isNew__: false,
        })))
    }, [taPages])

    useEffect(() => {
        setModalityOptionsTP(tpPages[0].map((b) => ({
            label: b.name,
            value: {
                id: b.id,
                website: b.website,
            },
            __isNew__: false,
        })))
    }, [tpPages])

    useEffect(() => {
        setModalityOptionsParent(modalitiesPages[0].map((b) => ({
            label: b.name,
            value: b.id,
            __isNew__: false,
        })))
    }, [modalitiesPages])

    const cleanForm = () => {
        setModalityName(getFieldState(''));
        setModalityDescription(getFieldState(''));
        setModalityWhat(getFieldState(''));
        setModalityParentsID(getFieldState([]));
        setModalityTA(getFieldState([]));
        setModalityBE(getFieldState([]));
        setModalityAliases(getFieldState([]));
        setModalityPrivate(getFieldState(false));

        setLoadingUpdate(false);

        setModalitySelectedTA([]);
        setModalitySelectedBE([]);
        setModalitySelectedParent([]);
        setModalitySelectedAliases([]);
    }

    const getModality = () => {
        setLoadingData(true);

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.getModality(id || '', false, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode !== 200) {
                    currentSuccess?.setSuccessData(false, true, t('toast_errorGettingModality'));
                    console.error(results);
                    return;
                }
                setModality(results.body as Modality);
            })
            .catch((error) => {
                currentSuccess?.setSuccessData(false, true, t('toast_errorGettingModality'), error);
                console.log(error);
            })
            .finally(() => setLoadingData(false));
    }

    const setFields = () => {
        setModalityName({
            showError: false,
            error: '',
            value: Modality?.name,
        });

        console.log('Modality?.description', Modality?.description)

        setModalityDescription({
            showError: false,
            error: '',
            value: Modality?.description,
        });

        setModalityAliases({
            showError: false,
            error: '',
            value: Modality?.aliases,
        });

        setModalitySelectedAliases((Modality?.aliases || []).map((a) => ({
            value: a,
            label: a,
            __isNew__: false,
        })))

        setModalityTA({
            showError: false,
            error: '',
            value: (Modality?.therapeuticAreas || []).map((item) => ({
                type: 'EXISTING',
                id: item.id
            })),
        });

        setModalitySelectedTA((Modality?.therapeuticAreas || []).map((b) => ({
            label: b.name,
            value: b.id,
            __isNew__: false,
        })))

        setModalityBE({
            showError: false,
            error: '',
            value: (Modality?.benefits || []).map((item) => ({
                type: 'EXISTING',
                id: item.id
            }))
        });

        setModalitySelectedBE((Modality?.benefits || []).map((b) => ({
            label: b.name,
            value: b.id,
            __isNew__: false,
        })))

        setModalityWhat({
            showError: false,
            error: '',
            value: Modality?.expect,
        })

        setModalityPOs(Modality?.professionalOrg || []);
        setModalityTPs(Modality?.topPractitioners || []);
        setModalityContributors(Modality?.contributors || []);

        if (Modality?.parentMSs) {
            setModalitySelectedParent(Modality?.parentMSs?.map((parentMS) => ({
                label: parentMS.name,
                value: parentMS.id,
                __isNew__: false,
            })))
        }
        setModalityPrivate(getFieldState(!!Modality?.private));
    }

    const getItemCreate = (updatedList: ModalityOptions<string>[]): (ItemCreateExisting | ItemCreateNew)[] => {
        return updatedList.map(item => {
            if (item.__isNew__) {
                return {
                    type: 'NEW',
                    name: item.label,
                }
            }
            return {
                type: 'EXISTING',
                id: item.value
            }
        })
    }

    const onChangeModalityName = (event: React.FormEvent<HTMLInputElement>) => {
        if (event) {
            setModalityName({
                ...ModalityName,
                value: event?.currentTarget.value
            });
        }
    }

    const onChangeModalityDescription = (event: string) => {
        if (event) {
            setModalityDescription({
                ...ModalityDescription,
                value: event
            });
        }
    }

    const onChangeModalityWhat = (event: string) => {
        if (event) {
            setModalityWhat({
                ...ModalityWhat,
                value: cleanHTML(event)
            });
        }
    }

    const onChangeModalityTA = (updatedList: ModalityOptions<string>[]) => {
        setModalityTA({
            ...ModalityTA,
            value: getItemCreate(updatedList),
        });
        setModalitySelectedTA(updatedList);
    }

    const onChangeModalityBE = (updatedList: ModalityOptions<string>[]) => {
        setModalityBE({
            ...ModalityBE,
            value: getItemCreate(updatedList),
        });
        setModalitySelectedBE(updatedList);
    }

    const onChangeModalityAliases = (updatedList: ModalityOptions<string>[]) => {
        setModalityAliases({
            ...ModalityAliases,
            value: updatedList.map(item => item.value),
        });
        setModalitySelectedAliases(updatedList);
    }

    const onChangeModalityParents = (updatedList: ModalityOptions<string>[]) => {
        setModalityParentsID({
            ...ModalityParentsID,
            value: updatedList.map(item => item.value),
        })
        setModalitySelectedParent(updatedList);
    }

    const errorsInForm = () => {
        let hasErrors = false;

        if (!ModalityName.value || ModalityName.value.length <= 0) {
            hasErrors = true;
            setModalityName({
                ...ModalityName,
                showError: true,
                error: 'Name is required',
            })
            console.log('Name is required',)
        } else {
            setModalityName(getFieldState(ModalityName.value));
        }

        return hasErrors;
    }

    const onHideUnsaved = (type: onHideType) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);
                onUpdate();
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                currentSuccess?.setLoadingModal(false);
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                currentSuccess?.setLoadingModal(false);
                cleanForm();
                navigate(-1);
                break;

            default:
                break;
        }
    }

    const onCancel = () => {
        currentSuccess?.setModalWarning(
            'UNSAVED',
            (type) => onHideUnsaved(type),
            'Do you want to discard changes?'
        );
    }

    const onUpdate = () => {
        if (errorsInForm()) return;

        const toSend: Partial<Modality> = {
            name: ModalityName.value,
            description: ModalityDescription.value,
            parentMSIds: ModalityParentsID.value,
            aliases: ModalityAliases.value,
            therapeuticAreasCreate: ModalityTA.value || [],
            topPractitionersCreate: ModalityTPs as ISignUpUnregisteredIndependentRequest[] || [],
            professionalOrgCreate: ModalityPOs as ISignUpUnregisteredOrganizationRequest[] || [],
            benefitsCreate: ModalityBE.value,
            updatedBy: username,
            private: ModalityPrivate.value,
            expect: ModalityWhat.value,
        }

        setLoadingUpdate(true);
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.updateModality(id || Modality?.id || '', toSend, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 201) {
                    currentSuccess?.setSuccessData(true, true, t('toast_modalityUpdated'));
                    cleanForm();
                    navigate(-1);
                    return;
                }
                currentSuccess?.setSuccessData(false, true, t('toast_errorUpdatingModality'));
            })
            .catch(() => currentSuccess?.setSuccessData(false, true, t('toast_errorUpdatingModality')))
            .finally(() => setLoadingUpdate(false));
    }

    function handleCloseModalDelete(type: onHideType, id: string) {
        switch (type) {
            case 'discard':
                currentSuccess?.setModalsOff();
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'save':
                currentSuccess?.setLoadingModal(true);
                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        return dataSDK.sdk.Modalities.deleteContributor(id, dataSDK.token);
                    })
                    .then((results) => {
                        if (results.statusCode === 204) {
                            setModalityContributors((mc) => mc.filter(c => c.username !== id))
                            currentSuccess?.setModalsOff();
                            currentSuccess?.setSuccessData(true, true, "Contributor unassigned");
                        } else {
                            currentSuccess?.setSuccessData(false, true, "There was an error");
                        }
                    })
                    .catch((error) => currentSuccess?.setSuccessData(false, true, "There was an error", error))
                    .finally(() => {
                        currentSuccess?.setLoadingModal(false);
                    })
                break;

            default:
                break;
        }
    };

    const handleUnassign = (id: string) => {
        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => handleCloseModalDelete(type, id),
            'Do you want to unassign this healer as a contributor?',
            'Unassign'
        );
    }

    const onHideDeletePO = (type: onHideType, id: string) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);

                setModalityPOs((pos) => {
                    const idx = pos.findIndex((po) => ('organization' in po ? po.organization.website || '' : po.website || '') === id);
                    if (idx !== -1) pos.splice(idx, 1);
                    return pos

                })

                currentSuccess?.setModalsOff();
                currentSuccess?.setLoadingModal(false);
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                currentSuccess?.setLoadingModal(false);
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                currentSuccess?.setLoadingModal(false);
                break;

            default:
                break;
        }
    }

    const handleDeletePO = (data: string) => {
        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => onHideDeletePO(type, data),
            'Are you sure you want to delete this Professional Organization?'
        );
    }

    const onHideDeleteTP = (type: onHideType, id: string) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);
                setModalityTPs((pos) => {
                    const idx = pos.findIndex((po) => ('organization' in po ? po.organization.website || '' : po.website || '') === id);
                    if (idx !== -1) pos.splice(idx, 1);
                    return pos

                })

                currentSuccess?.setModalsOff();
                currentSuccess?.setLoadingModal(false);
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                currentSuccess?.setLoadingModal(false);
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                currentSuccess?.setLoadingModal(false);
                break;

            default:
                break;
        }
    }

    const handleDeleteTP = (data: string) => {
        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => onHideDeleteTP(type, data),
            'Are you sure you want to delete this Top Healer?'
        );
    }

    const onHideCreateModalPO = (type: 'discard' | 'save', form?: ISignUpUnregisteredOrganizationRequest) => {
        if (type == 'save' && form) {
            setModalityPOs([...ModalityPOs, form]);
        }
        setShowCreate(false);
    }

    const onHideCreateModalTP = (type: 'discard' | 'save', form?: ISignUpUnregisteredIndependentRequest) => {
        if (type == 'save' && form) {
            setModalityTPs([...ModalityTPs, form]);
        }
        setShowCreateTP(false);
    }

    const handleCreatePrivate = () => {
        setModalityPrivate((old) => ({
            ...old,
            value: !old.value,
        }));
    }

    const partiallyReloadContributors = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.getContributorsByMod(id || '', dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) {
                    setModalityContributors(results.body as Contributor[] || []);
                }
            })
            .catch((error) => currentSuccess?.setSuccessData(false, true, "There was an error", error))
            .finally(() => {
                currentSuccess?.setLoadingModal(false);
            })
    }

    return (
        <>
            <ContentAdmin>
                <Title>Update Modality and Service</Title>
                {loadingData && <Loader color={"white"} />}
                <ContentWrapper>
                    <TwoColumns
                        left={<FormWrapper>
                            <Label>Name<Imp>*</Imp></Label>
                            <BaseInputWrapper
                                placeholder=""
                                value={ModalityName.value || ''}
                                onChangeValue={onChangeModalityName}
                                showError={ModalityName.showError || false}
                                error={ModalityName.error || ''}
                            />
                            <Label>Aliases</Label>
                            <CreatableSelectWrapper
                                options={undefined}
                                styles={customStylesRSMultiNoDropdownWhite()}
                                value={ModalitySelectedAliases || []}
                                onChangeValue={onChangeModalityAliases}
                                placeholder="Type and press enter"
                                showError={ModalityBE.showError || false}
                                error={ModalityBE.error || ''}
                            />
                            <Label>Description</Label>
                            <TextEditor
                                value={ModalityDescription.value || ''}
                                setValue={onChangeModalityDescription}
                                charLimit={2500}
                                placeholder='Please describe the modality...'
                                showError={ModalityDescription.showError || false}
                            />
                            <Label>What to Expect</Label>
                            <TextEditor
                                value={ModalityWhat.value || ''}
                                setValue={onChangeModalityWhat}
                                charLimit={2500}
                                placeholder='Please describe what to expect about modality...'
                                showError={ModalityWhat.showError || false}
                            />
                        </FormWrapper>}

                        right={<FormWrapper>
                            <Label>Associated Modalities</Label>
                            <SelectMultiWrapper
                                options={ModalityOptionsParent}
                                styles={customStylesRSMultiWhite()}
                                components={{
                                    DropdownIndicator
                                }}
                                value={ModalitySelectedParent || []}
                                onChangeValue={onChangeModalityParents}
                                placeholder="Select similar modalities"
                                showError={ModalityParentsID.showError || false}
                                error={ModalityParentsID.error || ''}
                            />
                            <Label>Benefits</Label>
                            <CreatableSelectWrapper
                                options={ModalityOptionsBE}
                                styles={customStylesRSMultiNoDropdownWhite()}
                                value={ModalitySelectedBE || []}
                                onChangeValue={onChangeModalityBE}
                                placeholder="Select or create one by pressing enter"
                                showError={ModalityBE.showError || false}
                                error={ModalityBE.error || ''}
                            />
                            <Label>Conditions Treated</Label>
                            <CreatableSelectWrapper
                                options={ModalityOptionsTA}
                                styles={customStylesRSMultiNoDropdownWhite()}
                                value={ModalitySelectedTA || []}
                                onChangeValue={onChangeModalityTA}
                                placeholder="Select or create one by pressing enter"
                                showError={ModalityTA.showError || false}
                                error={ModalityTA.error || ''}
                            />
                            <ItemCheckbox onClick={handleCreatePrivate}>
                                <SquareIcon
                                    icon={ModalityPrivate?.value ? faSquareCheck : faSquare}
                                    size='xl'
                                    color={ModalityPrivate?.value ? 'black' : "white"}
                                    active={ModalityPrivate?.value ?? false}
                                />
                                Private Modality
                            </ItemCheckbox>
                        </FormWrapper>}
                    />

                    <Row>
                        <h1>Contributors</h1>
                        <CreateBtn onClick={() => { setShowAssignContributor(true); }}>
                            + Assign Contributors
                        </CreateBtn>
                    </Row>
                    <BlankTable
                        headers={['Name', 'Assignment date']}
                        items={ModalityContributors.map((p: Contributor) => ({
                            id: p.username,
                            labels: [
                                p.fullname || '',
                                unixToDateFormatted(p.createdAt),
                            ],
                        }))}
                        actions={['DELETE']}
                        actionsHandlers={{
                            handleDelete: handleUnassign,
                        }}
                        maxWidth={'200px'}
                    />

                    <Row>
                        <h1>Professional Organizations</h1>
                        <CreateBtn onClick={() => { setShowCreate(true); }}>
                            + Assign Professional Organization
                        </CreateBtn>
                    </Row>
                    <BlankTable
                        headers={['Name', 'Website', 'State', 'Country', 'Zipcode']}
                        items={ModalityPOs.map((p: ISignUpUnregisteredOrganizationRequest | IOrganizationExtended) => ({
                            id: 'organization' in p ? p.organization.website || '' : p.website || '',
                            labels: 'organization' in p ? [
                                p.organization.organizationName || '',
                                p.organization.website || '',
                                p.organization.state || '',
                                p.organization.country || (activeCountriesReactSelect.find((country) => country.value === p.organization.countryCode)?.label) || '',
                                p.organization.zipcode || '',
                            ] : [
                                p.name || '',
                                p.website || '',
                                p.state || '',
                                p.country || (activeCountriesReactSelect.find((country) => country.value === p.countryCode)?.label) || '',
                                p.zipcode || '',
                            ],
                        }))}
                        actions={['DELETE']}
                        actionsHandlers={{
                            handleDelete: handleDeletePO,
                        }}
                        maxWidth={'200px'}
                    />

                    <Row>
                        <h1>Top Healers</h1>
                        <CreateBtn onClick={() => { setShowCreateTP(true); }}>
                            + Assign Top Healer
                        </CreateBtn>
                    </Row>
                    <BlankTable
                        headers={['Name', 'Website', 'State', 'Country', 'Zipcode']}
                        items={ModalityTPs.map((p: ISignUpUnregisteredIndependentRequest | IOrganizationExtended) => ({
                            id: 'organization' in p ? p.organization.website || '' : p.website || '',
                            labels: 'organization' in p ? [
                                p.organization.organizationName || '',
                                p.organization.website || '',
                                p.organization.state || '',
                                p.organization.country || (activeCountriesReactSelect.find((country) => country.value === p.organization.countryCode)?.label) || '',
                                p.organization.zipcode || '',
                            ] : [
                                p.name || '',
                                p.website || '',
                                p.state || '',
                                p.country || (activeCountriesReactSelect.find((country) => country.value === p.countryCode)?.label) || '',
                                p.zipcode || '',
                            ],
                        }))}
                        actions={['DELETE']}
                        actionsHandlers={{
                            handleDelete: handleDeleteTP,
                        }}
                        maxWidth={'200px'}
                    />

                    <ActionWrapper>
                        <CancelButton onClick={onCancel}>
                            {t('button__cancel')}
                        </CancelButton>
                        <WrapperButton>
                            <ButtonSpinner
                                loading={loadingUpdate}
                                handleClick={onUpdate}
                                text={t('button__save')}
                                hasIcon={false}
                            />
                        </WrapperButton>
                    </ActionWrapper>
                </ContentWrapper>
            </ContentAdmin>
            <CreatePOModal
                show={showCreate}
                onHide={onHideCreateModalPO}
            />
            <CreateTPModal
                show={showCreateTP}
                onHide={onHideCreateModalTP}
            />
            <ModalContributorSA
                showModal={showAssignContributor}
                onHide={() => setShowAssignContributor(false)}
                modalityID={id || ''}
                reload={partiallyReloadContributors}
            />
        </>
    )
}

const WrapperButton = styled.div`
  width: 150px;
  height: 100%;
`;

const Label = styled.div`    
    width: 100%;
    text-align: left;
    margin: 3px 0;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`;

const FormWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const ActionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 15px;
    justify-content: flex-end;
`;

export const AddYOBtn = styled.button`
    align-items: center;
    background-color: var(--electric-yellow);
    border-radius: 12px;
    display: flex;
    height: 33px;
    padding: 18px;
    margin-left: 13px;
    border: none;
    cursor: pointer;
    /* Find a better way */
    color: var(--white);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
`;

const RowLeft = styled(Row)`
    justify-content: flex-end;
`;

const Scroll = styled.div`
    display: flex;
    width: 100%;
    overflow-y: scroll;
`;

const ContentAdmin = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 80px);
  padding: 30px;
  justify-items: flex-start;
  align-items: flex-start;
  flex-direction: column;
  overflow-y: scroll;
  margin-bottom: 30px;
  gap: 10px;
`;

export default UpdateModality;