export const cityIdFormat = (locationId : string) => {
    //City id formated to send on roster endpoind to get city name
    const loc = locationId.split("#");
    return loc[0]+'_'+ loc[1]+'_'+ loc[2]+'_'+ loc[3]+'_'+ loc[4]
}

export const countryIdFormat = (locationId : string) => {
    //Country id formated to send on roster endpoind to get city name
    const loc = locationId.split("#");
    return loc[0]+'_'+ loc[1]+'_'+ loc[2]
}

export const getEventLocation = (
    addressLine1?: string,
    addressLine2?: string,
    location?: string,
    zipcode?: string,
    countryCode?: string,
    country?: string,
    city?: string,
    state?: string,
    stateCode?: string,
): string => {
    const loc = [];

    if(addressLine1) loc.push(addressLine1);
    if(addressLine2) loc.push(addressLine2);

    if(country) loc.push(country);
    else if(countryCode) loc.push(countryCode);

    if(state) loc.push(state);
    else if(stateCode) loc.push(stateCode);

    if(city) loc.push(city);
    if(zipcode) loc.push(zipcode);

    return loc.join(', ') + '.';
}