import styled from "styled-components";

export const QuestionsHome = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 24px 136px;
  width: 100%;
  height: calc(100vh - 120px); // Header
  overflow: scroll;
  
  @media only screen and (max-width: 500px) {
    padding: 24px 16px;
  }
`;

export const QuestionsContainerHome = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: scroll;
`;

export const QuestionHome = styled.div`
    display: flex;
    height: 64px;
    padding: 12px 32px;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    border-radius: 12px;
    background: var(--background-default);
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.10);
    -webkit-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.10);

    color: black;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 450;
    line-height: 32px;
    letter-spacing: 0.2px;
`;