import Select, { ActionMeta, OnChangeValue, Options, StylesConfig } from "react-select";
import styled from "styled-components";
import ErrorField from "./ErrorField";

const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-content: flex-start;
    gap: 10px;
`;

interface SelectMultiWrapperProps {
    placeholder: string;
    value: any[];
    options: Options<any>;
    components: any;
    styles: StylesConfig<string, boolean, any>;
    showError: boolean;
    error: string;
    onChangeValue: (newValue: OnChangeValue<any, any>, actionMeta: ActionMeta<any>) => void; //@todo I didn't found the correct values to the places where is any :c
}

export const SelectMultiWrapper = ({
    placeholder,
    value,
    options,
    components,
    styles,
    showError,
    error,
    onChangeValue,
}: SelectMultiWrapperProps) => {
    return (
        <Column>
            <Select
                options={options}
                styles={styles}
                components={components}
                value={value}
                onChange={onChangeValue}
                placeholder={placeholder}
                isMulti
                maxMenuHeight={160}
            />
            {(showError) && (<ErrorField error={error} />)}
        </Column>
    )
}