import { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/esm/Modal"
import styled from "styled-components";
import NewCardHealer from "../BaseComponents/Healers/NewCardHealer";
import IHealer from "@vibe/sdk/interfaces/Admin/IHealer";

export type tabsHealer = 'HEALERS' | 'PROF_ORG';

const HealersModal = ({
    showModal,
    setShowModal,
    healers
}: {
    showModal: boolean,
    setShowModal: (data: boolean) => void,
    healers: IHealer[]
}) => {


    const [tabActive, setTabactive] = useState<tabsHealer>('HEALERS')
    console.log(healers)

    return (
        <Modal className="modalHealers" show={showModal} onHide={() => setShowModal(false)} >
            <IconClicker
                onClick={() => setShowModal(false)}
                icon="times"
                size='xl'
                color={'black'}
            />
            <Modal.Header>
                <FlexHeader>
                    <Title>Healers</Title>
                </FlexHeader>
            </Modal.Header>
            <Modal.Body>
                <Tabs>
                    <Tab theme={{ main: tabActive === 'HEALERS' ? 'tabActive' : '' }} onClick={() => setTabactive('HEALERS')}> Healers </Tab>
                    <Tab theme={{ main: tabActive === 'PROF_ORG' ? 'tabActive' : '' }} onClick={() => setTabactive('PROF_ORG')}> Prof. Organizations </Tab>
                </Tabs>
                <TabContent>
                    {tabActive === 'HEALERS' ?
                        healers.filter((c: any) => c?.roles.includes('INDIVIDUAL')).map(healer => (
                            <NewCardHealer
                                data={healer}
                                setShowModal={setShowModal}
                                sizeAvatar={32}
                            />
                        ))
                        :
                        healers.filter((c: any) => !c?.roles.includes('INDIVIDUAL')).map(healer => (
                            <NewCardHealer
                                data={healer}
                                setShowModal={setShowModal}
                                sizeAvatar={32}
                            />
                        ))
                    }
                </TabContent>
            </Modal.Body>
        </Modal>
    )
}

const TabContent = styled.div`

`

const Tab = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    width: 50%;
    text-align: center;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.tabActive{
        border-bottom: 2px solid var(--electric-yellow-2);
    }
`

const Tabs = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const FlexHeader = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 15px;
`;

const IconHealer = styled.div`
    width: 25px;
    height: 25px;
    background-color: var(--electric-yellow-2);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: relative;
    background-image: url(../icon_healer.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 70% auto;
`

const Title = styled.span`
    font-family: 'Inter-Bold';
    color: black;
    font-size: 16px;
`;

const IconClicker = styled(FontAwesomeIcon)`
    cursor: pointer;
    position: absolute;
    z-index: 9999;
    right: 17px;
    top: 17px;
`;

export default HealersModal;