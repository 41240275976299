import styled from "styled-components";
import { TitleContent, listConnections } from "../../components/signupsetup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProcessInterface } from "../../containers/SetupHealer/SetupHealer";
import ConnectionCard, { CardConnectionsInterface, relationTypeCard } from "../BaseComponents/Connections/ConnectionCard";
import { useDispatch, useSelector } from "react-redux";
import { IUserResponse } from "@vibe/sdk/dist/interfaces/Admin/User";
import { useContext, useEffect, useState } from "react";
import { ContextSuccess } from "../../Context";
import { RootState } from "../../store/store";
import { rolesType } from "@vibe/sdk/dist/interfaces/AccessManager/signUpUser";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import { ISaveFriendRequestQuery } from "@vibe/sdk/dist/interfaces/Connections/friends";
import IResponseError from "@vibe/sdk/dist/interfaces/IResponseError";
import { getMyConnections } from "../../store/actions/connectionActions";
import { UserConnection } from "@vibe/sdk/dist/interfaces/Roster/getUserConnections";
import { isConnectionActive } from "../../helpers/Connections/getConnections";

const Connections = ({
    processData,
    isSummary,
}:{
    processData: ProcessInterface,
    isSummary?: boolean
}) => {

    const dispatch = useDispatch();

    const currentSuccess = useContext(ContextSuccess);

    const suggestions: IUserResponse[] = useSelector((state: RootState) => state.getUser.usersFiltered);
    const connections: UserConnection[] = useSelector((state: RootState) => state.getConnection.userConnections);
    const username = useSelector((state: RootState | any) => state.getUser.userInfo.username);

    const [conn, setConn] = useState<CardConnectionsInterface[]>([]);

    const mapConnections = () => {
        if (isSummary) {
            const myClubs: CardConnectionsInterface[] = [];
            (suggestions || []).forEach((comm) => {
                const connection = isConnectionActive(comm.username || '', connections);
                if(connection.relation !== 'ANY'){
                    myClubs.push({
                        id: comm.username || '',
                        avatar: comm.avatar || '',
                        name: `${comm.firstname || ''} ${comm.lastname || ''}`,
                        roles: comm.roles as rolesType[] || [],
                        zipcode: comm.zipcode || '',
                        country: comm.country || '',
                        createdOn: comm.creationDate || '',
                        interests: comm.interests || [],
                        connections: comm.connections || [],
                        typeConnection: connection.relation,
                        city: comm.city || '',
                    });
                }
            })

            setConn(myClubs);
        }
        else {
            setConn((suggestions.filter(({username: us}) => us !== username) || []).map((comm) => {
                const connection = isConnectionActive(comm.username|| '', connections);
                return {
                    id: comm.username || '',
                    avatar: comm.avatar || '',
                    name: `${comm.firstname || ''} ${comm.lastname || ''}`,
                    roles: comm.roles as rolesType[] || [],
                    zipcode: comm.zipcode || '',
                    country: comm.country || '',
                    createdOn: comm.creationDate || '',
                    interests: comm.interests || [],
                    connections: comm.connections || [],
                    typeConnection: connection.relation,
                    city: comm.city || '',
                }
            }))
        }
    }

    const handleOnClick = (data: CardConnectionsInterface) => {
        const toSend: ISaveFriendRequestQuery = {
            usernameFrom: username || '',
            usernameTo: data.id || '',
        }

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Connections.saveFriendRequest(toSend, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 201) {
                    dispatch(getMyConnections(username));
                }
                else {
                    currentSuccess?.setSuccessData(false, true, "Error sending friend request", (results.body as IResponseError).error || (results.body as IResponseError).message);
                }
            })
            .catch((error) => {
                currentSuccess?.setSuccessData(false, true, "Error sending friend request", error);
            })
    }

    useEffect(() => {
        if ((connections || []).length > 0) processData.setTabsProcessed([...processData.tabsProcessed, 4]);
        mapConnections();
    }, [suggestions, connections, isSummary])
    
    return (
        <>
            {!isSummary && <TitleContent>Meaningful connections to elevate your life.</TitleContent>}
            <ListComm isSummary={isSummary || false}>
                {conn.map((user) => (
                    <ConnectionCard
                        data={user}
                        onClickButton={handleOnClick}
                        isSummary={isSummary || false}
                        isSeeker={true}
                    />
                ))}
            </ListComm>
        </>
    )
}

interface ListProps{
    isSummary: boolean;
}

const ListComm = styled.div`
    display: ${(props: ListProps) => props.isSummary? 'flex': 'grid'};
    grid-template-columns: repeat( auto-fit, minmax( 185px, 235px ) );
    width: 100%;
    height: 100%;
    margin-top: ${(props: ListProps) => props.isSummary? '15px': '0px'};
    overflow: scroll;
    gap: 10px;

    @media only screen and (max-width: 500px) {
        grid-template-columns: 1fr;
    }
`;

const IconButtonFtr = styled(FontAwesomeIcon)`
    padding: 0 10px;
`;

export default Connections;
