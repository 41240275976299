import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import './styles.css'

interface CreatePostInspirationProps {
  text: string;
  setText: (p: string) => void;
  author: string;
  setAuthor: (p: string) => void;
}

function CreatePostInspiration({
  text,
  setText,
  author,
  setAuthor,
}: CreatePostInspirationProps) {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="containerPostCreateImage Ins">

        <div className="containerPostCreateLink">
          <textarea
            className="containerPostCreateLink-inputText HeightAuto"
            placeholder={t('label_writeSomething')}
            value={text}
            maxLength={2200}
            onChange={(e) => {
              const { value } = e.target
              setText(value)
            }}
          />
          {text.length > 0 && <div className="CharCounter__wrapper">
            <span className="CharCounter__text">{text.length}/2200</span>
          </div>}
        </div>

        <div className="containerPostCreateLink">
          <textarea
            className="containerPostCreateLink-inputText"
            placeholder={t('label_inspAuthor')}
            value={author}
            maxLength={2200}
            onChange={(e) => {
              const { value } = e.target
              setAuthor(value)
            }}
          />
          {author.length > 0 && <div className="CharCounter__wrapper">
            <span className="CharCounter__text">{author.length}/2200</span>
          </div>}
        </div>
      </div>
    </>
  );
}

export default CreatePostInspiration;