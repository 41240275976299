import { useEffect, useState, useContext } from 'react';
import styled from "styled-components";
import {
    TitleContent, Input
} from "../signupsetuphealer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-thin-svg-icons";
import { faSearch, faPen, faTrash } from '@fortawesome/pro-regular-svg-icons';
import ModalCertifications from '../../components/signupsetuphealerind/ModalCertifications';
import { ProcessInterface } from '../../containers/SetupHealer/SetupHealer';
import { ICertification } from '@vibe/sdk/dist/interfaces/Modalities/certifications/Certification';
import { sortAlphabeticallyOnProperty } from '../../containers/Signup/SignupHealerInd';
import useModalitiesSA from '../../hooks/Superadmin/Modalities/useModalitiesSA';
import list from 'antd/lib/list';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { customModality } from '../signupsetuphealer/Services';
import ModalDelete from '../BaseComponents/Modals/ModalDelete';
import IResponseError from '@vibe/sdk/dist/interfaces/IResponseError';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { getCertifications } from '../../store/actions/modalityActions';
import Modality from '@vibe/sdk/interfaces/Modalities/modalities/Modality/Modality';
import { ContextSuccess } from "../../Context";
import { onHideType } from "../../components/BaseComponents/Modals/ModalUnsaved";
import BlankTable from '../BaseComponents/BlankTable';
import { Scroll_Content_Signup } from '../signupsetuphealer/Introduction';
import { Content_Signup_Columns } from './MyServices';
import { Link } from 'react-router-dom';

const Certifications = ({
    processData,
}: {
    processData: ProcessInterface
}) => {

    const dispatch = useDispatch();
    const { modalitiesPages, reloadModalities } = useModalitiesSA({
        pageSizeProp: 1000,
        searchName: '',
    });
    const currentSuccess = useContext(ContextSuccess);
    const certifications: ICertification[] = useSelector((state: RootState) => state.getModality.getCertifications);
    const username = useSelector((state: RootState | any) => state.getUser.userInfo.username);

    const [showModal, setShowModal] = useState(false)
    const [showModalEdit, setShowModalEdit] = useState(false)
    const [certificationEdit, setCertificationEdit] = useState<ICertification>()
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<string>();

    const [filteredCertification, setFilteredCertification] = useState<string>('')
    const [ICertification, setCertification] = useState<Modality>({ ...customModality })

    const handleCertification = (open: boolean, mod?: Modality) => {
        if (mod) setCertification(mod);
        else setCertification({ ...customModality })
        setShowModal(open);
    }

    const getFiltered = (listName: any[]) => {
        return listName.filter((item: any) => {
            if (!filteredCertification) return true;

            if (item.name && (
                item.name.toUpperCase().includes(filteredCertification) ||
                item.name.toLowerCase().includes(filteredCertification) ||
                item.name.includes(filteredCertification)
            )) {
                return true;
            }

            return false;
        })
    }

    const handleModalEdit = (show: boolean, _template?: Modality, ICertification?: ICertification) => {
        setShowModalEdit(show);
        setCertificationEdit(ICertification);
        console.log(ICertification)
    }

    useEffect(() => {
        if ((certifications || []).length > 0) processData.setTabsProcessed([...processData.tabsProcessed, 2]);
    }, [certifications])

    const onHideDelete = (type: onHideType, id: string) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);
                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        return dataSDK.sdk.Modalities.deleteCertification(id, dataSDK.token);
                    })
                    .then((results: any) => {
                        if (results.statusCode === 204) {
                            currentSuccess?.setSuccessData(true, true, 'Certification deleted succesfully');
                            dispatch(getCertifications(username));
                            currentSuccess?.setModalsOff();
                        }
                        else {
                            currentSuccess?.setSuccessData(false, true, (results.body as IResponseError).error || (results.body as IResponseError).message);
                            console.log((results.body as IResponseError).error || (results.body as IResponseError).message)
                        }
                    })
                    .finally(() => {
                        setDeleteModal(false);
                        setDeleteLoading(false);
                        setDeleteId(undefined);
                    })
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                break;

            default:
                break;
        }
    }

    const handleDelete = (data: string) => {
        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => onHideDelete(type, data),
            'Are you sure you want to delete this ICertification?'
        );
    }

    const handleUpdate = (service_is: string) => {
        const s = certifications.find(s => s.id === service_is);
        handleModalEdit(true, undefined, s);
    }

    const handleGoWebsite = (id: string, link?: string) => {
        if (link) window.open(link, "_blank");
    }

    const handleDownloadPdf = (name: string, link?: string) => {
        if (link) {
            fetch(link)
                .then(response => response.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${name}_Certification.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                })
                .catch(error => {
                    console.log(error)
                    currentSuccess?.setSuccessData(false, true, 'Error downloading the file!');
                });
        }
    }

    return (
        <Scroll_Content_Signup>
            <TitleContent theme={{ align: 'center' }}>
                Please select for each service one or more certifications
            </TitleContent>

            <Content_Signup_Columns>
                <LeftColumn>
                    <InputIcon>
                        <SearchIcon
                            icon={faSearch}
                            size='lg'
                        />
                        <InputPlaceholder
                            placeholder="Search"
                            value={filteredCertification}
                            onChange={(e) => setFilteredCertification(e.currentTarget.value)}
                        />
                    </InputIcon>
                    <BaseAreas>
                        <TitleAreas>Popular Certifications</TitleAreas>
                        <ListAreas>
                            {sortAlphabeticallyOnProperty(getFiltered(modalitiesPages[0]), 'name').map((item: Modality) => (
                                <Area onClick={() => handleCertification(true, item)}>
                                    <TextServices>{item.name}</TextServices>
                                    <IconPlus
                                        icon={faPlus}
                                        size='lg'
                                        color={"black"}
                                    />
                                </Area>
                            ))}
                        </ListAreas>
                    </BaseAreas>
                    <Button onClick={() => handleCertification(true)}>
                        Add custom certification
                        <IconPlus
                            icon={faPlus}
                            size='lg'
                            color={"var(--white)"}
                        />
                    </Button>
                </LeftColumn>
                {(certifications || []).length === 0 ?
                    <ListServicesEmp>
                        <Header>
                            <Title>No certifications yet.</Title>
                            <SubTitle>Here you will see the certifications you upload</SubTitle>
                        </Header>
                    </ListServicesEmp>
                    :
                    <ListServices>
                        <BlankTable
                            headers={['Name', 'Granted organization', 'Experience']}
                            items={sortAlphabeticallyOnProperty(getFiltered(certifications || []), 'name').map((service: ICertification) => ({
                                id: service.id,
                                labels: [
                                    `${service.name}`,
                                    'grantedOrgWebsite' in service ? service?.grantedOrgName : '',
                                    'yearsOfExperience' in service ? `${service?.yearsOfExperience} Years` : '',
                                ],
                                link: 'grantedOrgWebsite' in service ? service?.grantedOrgWebsite : '',
                                linkPdf: 'pdfLink' in service ? service?.pdfLink : '',
                                noShowIcon: !('grantedOrgWebsite' in service),
                                showIconIndex: ('pdfLink' in service),
                            }))}
                            actions={['UPDATE', 'DELETE']}
                            actionsHandlers={{
                                handleUpdate,
                                handleDelete,
                            }}
                            maxWidth={'200px'}
                            specialColumnFirst={{
                                handle: handleDownloadPdf,
                                idx: 0,
                            }}
                            specialColumn={{
                                handle: handleGoWebsite,
                                idx: 1,
                            }}
                        />
                    </ListServices>
                }
            </Content_Signup_Columns>
            <ModalCertifications
                showModal={showModal}
                onHide={setShowModal}
                data={ICertification}
            />
            <ModalCertifications
                edit
                showModal={showModalEdit}
                onHide={setShowModalEdit}
                certificationInfo={certificationEdit}
                data={ICertification}
            />
        </Scroll_Content_Signup>
    )
}

const TextServices = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

const IconDrop = styled.img`
    width: 40px;
    cursor: pointer;
`;

const WrapperText = styled.p`
    overflow: hidden;
    max-height: 15px;
`;

const WrapperEmpty = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const TableIcon = styled(FontAwesomeIcon)`
    font-size: 18px;
    cursor: pointer;
`;

const TableList = styled.table`
    width: 100%;
    background-color: white;
    border-radius: 8px;
`

const Tr = styled.tr`
    & td:last-child,
    & th:last-child {
        width: 85px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

const Th = styled.th`
    vertical-align: middle;
    height: 40px;
    border-bottom: 1px solid var(--simple-gray-2);
    font-family: 'Inter-Bold';
    font-size: 14px;
    padding: 0 15px;
    text-align: left;
`

const Td = styled.td`
    vertical-align: middle;
    height: 40px;
    border-bottom: 1px solid var(--simple-gray-2);
    font-family: 'Inter';
    font-size: 14px; 
    padding: 0 15px; 
    text-align: left;
`

const SearchIcon = styled(FontAwesomeIcon)`
    font-size: 12px;
    margin-right: 20px;
    color: var(--black);
    margin-left: 20px;
`;

const InputIcon = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-radius: 8px;
    align-items: center;
    border: 1px solid var(--simple-gray);
    height: 40px;
    width: 100%;
    
    & ${Input}{
        border: 0;
        height: 38px;
    } 
`

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background: var(--electric-yellow-2);
    border-radius: 8px;
    color: var(--white);
    font-family: 'Inter-Bold';
    font-size: 16px;
    padding: 10px;

    cursor: pointer;
`

const Title = styled.p`
    font-family: 'Inter-Bold';
    font-size: 16px;
    padding: 13px 0;
`
const SubTitle = styled.span`
    font-family: 'Inter';
    font-size: 14px;
`

const ListServicesEmp = styled.div`
    width: 100%;
    height: 100%;
    background-color: white;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-image: url(./NoServices.png);
    border-radius: 9px;
    padding: 20px 0;
`

const ListServices = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    overflow: scroll;
`

const ContentServices = styled.div`
    display: grid;
    grid-template-columns: 30% calc(70% - 20px);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
`

const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Area = styled.div`
    border-bottom: 1px solid darkgrey;
    background-color: white;
    font-family: 'Inter';
    font-size: 14px;
    cursor: pointer;
    width: 100%;
    padding: 13px 9px;
    margin: 0;
    display: flex;
    justify-content: space-between;
`;

const ListAreas = styled.div`
    width: 100%;
    padding: 7px 9px;
    background-color: white;
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 385px);

    &::-webkit-scrollbar {
        width: 5px;
        display: block !important;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--t-blue);
    }
`

const BaseAreas = styled.div`
    width: 100%;
    border-radius: 9px;
    background-color: white;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;
`

const TitleAreas = styled.span`
    font-family: 'Inter-Bold';
    font-size: 16px;
    color: black;
    margin: 15px 0px 0px 10px;
`;

const IconPlus = styled(FontAwesomeIcon)`
    font-size: 14px;
    margin-left: 8px
`;

const InputPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0;
    font-size: 15px;
    font-family: 'Inter';
    background-color: transparent;
`;

const IconClicker = styled(FontAwesomeIcon)`
    cursor: pointer;
`;

const IconClickerTable = styled(IconClicker)`
    margin-right: 10px;
`;

export default Certifications;