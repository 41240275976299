import { useEffect, useState } from 'react';
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbTack, faMagnifyingGlass, faArrowUpArrowDown, faBrowser, faCaretDown, faWallet } from "@fortawesome/pro-regular-svg-icons";
import {
    ContainerTable, Filters, InputSearch, Input, SelectFilter, StyledTable, THead, TR, TD,
    TextHead, TBody, TextBlue, Count, Price, IconGeoUp
} from "./Dashboards.styles"
import useDashboard from '../../hooks/Dashboard/useDashboard';
import { ServiceTable } from '@vibe/sdk/dist/interfaces/Dashboard/tables';
import { Loader } from '../BaseComponents/Loader';
import TableLoader from './components';

const getTableRow = (
    service: ServiceTable,
    castBigNumbers: (n: number) => string,
    castMoneyNumbers: (n: number) => string,
    castDateTimestamp: (timestamp: string) => string
) => {
    return (
        <TR>
            <TD>
                <TextBlue>{service.name}</TextBlue>
            </TD>
            <TD>
                {service.events.map(i => (<TextBlue>{i.name}</TextBlue>))}
            </TD>
            <TD>
                {castBigNumbers(service.members)}
            </TD>
            <TD>
                {castBigNumbers(service.followers)}
            </TD>
            <TD>
                <Count>
                    <Price>
                        ${castMoneyNumbers(service.GV)}
                    </Price>
                    <IconGeoUp theme={{ color: '#0F9936' }} />
                </Count>
            </TD>
            <TD>
                {castDateTimestamp(service.createdAt)}
            </TD>
        </TR>
    )
}

const TableModalities = () => {
    const { servicesDashboardTable, castBigNumbers, castMoneyNumbers, castDateTimestamp, reloadDashboardTable, loadingGeneral, loadingServicesTable } = useDashboard()

    useEffect(() => {
        reloadDashboardTable('services')
    }, [])

    useEffect(() => console.log({loadingGeneral, loadingServicesTable}), [loadingGeneral, loadingServicesTable])

    return (
        <ContainerTable>
            <Filters>
                <InputSearch>
                    <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        size="sm"
                        color={'black'}
                    />
                    <Input />
                </InputSearch>
                <SelectFilter>
                    <FontAwesomeIcon
                        icon={faWallet}
                        size="sm"
                        color={'black'}
                    />
                    Type
                    <FontAwesomeIcon
                        icon={faCaretDown}
                        size="sm"
                        color={'black'}
                    />
                </SelectFilter>
                <SelectFilter>
                    <FontAwesomeIcon
                        icon={faBrowser}
                        size="sm"
                        color={'black'}
                    />
                    Delivery
                    <FontAwesomeIcon
                        icon={faCaretDown}
                        size="sm"
                        color={'black'}
                    />
                </SelectFilter>
                <SelectFilter>
                    <FontAwesomeIcon
                        icon={faThumbTack}
                        size="sm"
                        color={'black'}
                    />
                    Benefits
                    <FontAwesomeIcon
                        icon={faCaretDown}
                        size="sm"
                        color={'black'}
                    />
                </SelectFilter>
            </Filters>
            <StyledTable>
                <THead>
                    <TR>
                        <TD>
                            <TextHead>
                                Modality/Service
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    size="sm"
                                    color={'black'}
                                />
                            </TextHead>

                        </TD>
                        <TD>
                            <TextHead>
                                Events
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    size="sm"
                                    color={'black'}
                                />
                            </TextHead>
                        </TD>
                        <TD>
                            <TextHead>
                                Members
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    size="sm"
                                    color={'black'}
                                />
                            </TextHead>
                        </TD>
                        <TD>
                            <TextHead>
                                Followers
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    size="sm"
                                    color={'black'}
                                />
                            </TextHead>
                        </TD>
                        <TD>
                            <TextHead>
                                Revenue
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    size="sm"
                                    color={'black'}
                                />
                            </TextHead>
                        </TD>
                        <TD>
                            <TextHead>
                                Date created
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    size="sm"
                                    color={'black'}
                                />
                            </TextHead>
                        </TD>
                    </TR>
                </THead>
                <TBody>
                    {loadingGeneral || loadingServicesTable ? (
                        <TableLoader />
                    ) : servicesDashboardTable.map(service => getTableRow(service, castBigNumbers, castMoneyNumbers, castDateTimestamp))}
                </TBody>
            </StyledTable>
        </ContainerTable>
    )
}



export default TableModalities