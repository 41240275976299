export const START_GET_GENERALDASH = 'START_GET_GENERALDASH';
export const GET_GENERALDASH_SUCCESS = 'GET_GENERALDASH_SUCCESS';
export const GET_GENERALDASH_ERROR = 'GET_GENERALDASH_ERROR';

export const START_GET_HSTABLE = 'START_GET_HSTABLE';
export const GET_HSTABLE_SUCCESS = 'GET_HSTABLE_SUCCESS';
export const GET_HSTABLE_ERROR = 'GET_HSTABLE_ERROR';

export const START_GET_COMMTABLE = 'START_GET_COMMTABLE';
export const GET_COMMTABLE_SUCCESS = 'GET_COMMTABLE_SUCCESS';
export const GET_COMMTABLE_ERROR = 'GET_COMMTABLE_ERROR';

export const START_GET_EVTABLE = 'START_GET_EVTABLE';
export const GET_EVTABLE_SUCCESS = 'GET_EVTABLE_SUCCESS';
export const GET_EVTABLE_ERROR = 'GET_EVTABLE_ERROR';

export const LOGOUT_DASHBOARD = 'LOGOUT_DASHBOARD';