import { ModCommunity, ModalityExtended } from "@vibe/sdk/interfaces/Modalities/modalities/Modality/Modality";
import { isMList } from "../../guards/modalities/TCList";
import MList from "../../interfaces/Superadmin/modalities/MList/MList";
import { Backend, MicroServices } from "../backendHelper";
import { ModalityMappedInterface } from "../../containers/ModalitiesServices/ModalityDescription";
import { CardInterface, JoinedType, isConnectionsInterface } from "../../components/BaseComponents/Communities/CommunityCard";
import { ClubDigestExtra } from "@vibe/sdk/dist/interfaces/Roster/getUserClubs";

export const NOT_FOUND = 'NOT_FOUND';

export const getMList = (id: string): Promise<MList> =>
    new Promise((resolve, reject) => {
        Backend(
            MicroServices.Modalities,
            `/modalities/mlists/${id}`
        )
            .then((response) => {
                if (response.status === 404) {
                    return Promise.reject(NOT_FOUND)
                }
                return response.json();
            })
            .then((mlist: MList) => {
                if (!isMList(mlist)) {
                    reject()
                    return;
                }
                resolve(mlist)
            })
            .catch(reject);
    })

export const getConnection = (id: string, myClubs: ClubDigestExtra[]): isConnectionsInterface => {
    const result = {
        joined: 'NO' as JoinedType,
        followed: false
    }

    const club = (myClubs || []).find(cl => cl.club_id === id);

    if (club) {
        const roles = club.roles || [];
        result.joined = (
            roles.includes('MEMBER') || 
            roles.includes('ADVISOR') || 
            roles.includes('OFFICER')
        )? 'MEMBER' as JoinedType: (
            roles.includes('JOIN') || roles.includes('PENDING')
            )? 'PENDING' as JoinedType : 'NO' as JoinedType;
        result.followed = roles.includes('FOLLOWER') || roles.includes('FAN');
    }

    return result;
}

export const mapCommMod = (
    comms: ModCommunity[],
    myClubs: ClubDigestExtra[]
) => comms.map((comm): CardInterface => {
    const connection = getConnection(comm.id || '', myClubs);
    return {
        id: comm.id,
        name: comm.name,
        description: comm.description,
        joined: connection.joined,
        followed: connection.followed,
        guides: comm.guides,
        members: comm.members,
        followers: comm.followers,
        orgId: comm.org_id || '',
        modalitiesIds: comm.modalitiesIDs || [],
    }
})

export const mapModalities = (
    mods: ModalityExtended[],
    myClubs: ClubDigestExtra[]
): ModalityMappedInterface[] => mods.map((mod) => ({
    id: mod.id,
    name: mod.name,
    description: mod.description || '',

    aliases: mod.aliases || [],
    benefits: (mod.benefits || []).map((b) => b.name),
    t_areas: (mod.therapeuticAreas || []).map((b) => b.name),

    communities: mapCommMod(mod.comms, myClubs),
    healers: mod.healersCount,
    followers: mod.followers,
    seekers: mod.seekers,

    topPractitioners: (mod.topPractitioners ?? []).map((org) => ({
        name: `${org.orgAdmin?.firstname ?? ''} ${org.orgAdmin?.lastname ?? ''}`,
        services: [],
        zipcode: org.zipcode,
        city: org.city,
        country: org.country,
        username: org.orgAdmin?.username ?? '',
        roles: [] // @audit
    })),
    profOrganizations: (mod.professionalOrg ?? []).map((org) => ({
        name: org.name,
        description: org.mission ?? '',
        healerOrg: org.id,
        logo: ''
    }))
}))