import styled from "styled-components";
import {
    TitleContent, Input
} from "."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-thin-svg-icons";
import { faSearch, faPen, faTrash, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { ProcessInterface } from '../../containers/SetupHealer/SetupHealer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import useModalitiesSA from '../../hooks/Superadmin/Modalities/useModalitiesSA';
import Modality from '@vibe/sdk/interfaces/Modalities/modalities/Modality/Modality';
import { useState, useEffect, useContext } from "react";
import { ContextSuccess } from "../../Context";
import ModalCommunities from "./ModalCommunities";
import IHS from "@vibe/sdk/dist/interfaces/Modalities/hservices/HS";
import { customModality, customService } from "./Services";
import { IClubExtended } from "@vibe/sdk/dist/interfaces/Club/ClubInterface";
import ModalDelete from "../BaseComponents/Modals/ModalDelete";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import { getClubs } from "../../store/actions/clubActions";
import IResponseError from "@vibe/sdk/dist/interfaces/IResponseError";
import IPaymentInfo from "../../interfaces/user/IPaymentINfo";
import ContentPayment from "../BaseComponents/Payment/ContentPayment";
import { IUserGradeResponse } from "@vibe/sdk/interfaces/Admin/User";
import { onHideType } from "../../components/BaseComponents/Modals/ModalUnsaved";
import { sortAlphabeticallyOnProperty } from "../../containers/Signup/SignupHealerInd";
import BlankTable from "../BaseComponents/BlankTable";
import { Scroll_Content_Signup } from "./Introduction";
import { Content_Signup_Columns } from "../signupsetuphealerind/MyServices";

const Communities = ({
    processData,
}: {
    processData: ProcessInterface
}) => {
    const dispatch = useDispatch();
    const currentSuccess = useContext(ContextSuccess);
    const { modalitiesPages, reloadModalities } = useModalitiesSA({
        pageSizeProp: 1000,
        searchName: '',
    });
    const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);
    const userPayment: IPaymentInfo = useSelector((state: RootState) => state.getUser.paymentInfo);
    const services = useSelector((state: RootState) => state.getModality.getHealerServices);

    const organization = useSelector((state: RootState) => state.getOrgs.organizationInfo);
    const communities = useSelector((state: RootState) => state.getClub.getClubsOrg);
    const [showModal, setShowModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<string>();
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [editComm, setEditComm] = useState<IClubExtended>();
    const [modalities, setModalities] = useState<Modality[]>([]);
    const [service, setService] = useState<IHS>();

    const [filteredModality, setFilteredModality] = useState<string>('');
    const [community, setCommunity] = useState<IHS>({ ...customService });

    const getFiltered = (listName: any[]) => {
        return listName.filter((item: any) => {
            if (!filteredModality) return true;

            if (item.name && (
                item.name.toUpperCase().includes(filteredModality) ||
                item.name.toLowerCase().includes(filteredModality) ||
                item.name.includes(filteredModality)
            )) {
                return true;
            }

            return false;
        })
    }

    const handleModality = (open: boolean, mod?: IHS) => {
        if (mod) {
            setCommunity(mod);
            const mods = (mod.modalityIds || [])
                .map((modIdx) =>
                    modalitiesPages[0]
                        .find((mod) => mod.id === modIdx)
                )
                .filter((m): m is Modality => !!m);
            setModalities(mods);
            setService(mod);
        } else {
            setCommunity({ ...customService });
            setModalities([]);
            setService(undefined);
        }
        setShowModal(open);
    }

    const handleModalEdit = (show: boolean, _template?: Modality, club?: IClubExtended) => {
        setShowModalEdit(show);
        setEditComm(club);
    }

    const handleUpdate = (service_is: string) => {
        const s = communities.find( s => s.id === service_is);
        handleModalEdit(true, undefined, s);
    }

    useEffect(() => {
        if ((communities || []).length > 0) processData.setTabsProcessed([...processData.tabsProcessed, 4]);
    }, [communities])

    const onHideDeleteComm = (type: onHideType, id: string) => {
        console.log(type)
        switch (type) {
            case 'save':
                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        return dataSDK.sdk.Club.deleteClub(id, organization.status.startsWith('PE'), undefined, dataSDK.token);
                    })
                    .then((results) => {
                        if (results.statusCode === 204) {
                            dispatch(getClubs({
                                pending: organization.status.startsWith('PE'),
                                org_id: organization.id,
                                club_type: 'CLUB',
                            }));
                            currentSuccess?.setModalsOff();
                        }
                        else {
                            console.log((results.body as IResponseError).error || (results.body as IResponseError).message)
                        }
                    })
                    .finally(() => {
                        setDeleteModal(false);
                        setDeleteLoading(false);
                        setDeleteId(undefined);
                    })
                break;
            case 'close':
                currentSuccess?.setModalsOff();
                break;
            case 'discard':
                currentSuccess?.setModalsOff();
                break;
            default:
                break;
        }
    }

    const handleDelete = (data: string) => {
        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => onHideDeleteComm(type, data),
            'Are you sure you want to delete this community?'
        );
    }

    const getContentOfTab = () => {
        if (user.organizations.length === 1 || userPayment.hasActiveSubscription) {
            return (
                <Scroll_Content_Signup>
                    <TitleContent theme={{ align: 'center' }}>
                        Create communities aligned with the modalities and services you offer.
                    </TitleContent>
                    <Content_Signup_Columns>
                        <LeftColumn>
                            <InputIcon>
                                <SearchIcon
                                    icon={faSearch}
                                    size='lg'
                                />
                                <InputPlaceholder
                                    placeholder="Search"
                                    value={filteredModality}
                                    onChange={(e) => setFilteredModality(e.currentTarget.value)}
                                />
                            </InputIcon>
                            <BaseAreas>
                                <TitleAreas>Your Services</TitleAreas>
                                <ListAreas>
                                    {sortAlphabeticallyOnProperty(getFiltered(services), 'name').map((item: IHS) => (
                                        <Area onClick={() => handleModality(true, item)}>
                                            <TextServices>{item.name}</TextServices>
                                            <IconPlus
                                                icon={faPlus}
                                                size='lg'
                                                color={"black"}
                                            />
                                        </Area>
                                    ))}
                                </ListAreas>
                            </BaseAreas>
                            <Button onClick={() => handleModality(true)}>
                                Add custom community
                                <IconPlus
                                    icon={faPlus}
                                    size='lg'
                                    color={"var(--white)"}
                                />
                            </Button>
                        </LeftColumn>
                        {(communities || []).length === 0 ?
                            <ListServicesEmp>
                                <Header>
                                    <Title>No communities yet.</Title>
                                    <SubTitle>Here you will see the communities you upload</SubTitle>
                                </Header>
                            </ListServicesEmp>
                            :
                            <ListServices>
                                <BlankTable
                                    headers={['Name', 'Description']}
                                    items={sortAlphabeticallyOnProperty(getFiltered(communities || []), 'name').map((service: IClubExtended) => ({
                                        id: service.id,
                                        labels: [
                                            service.name,
                                            service.description || ''
                                        ],
                                    }))}
                                    actions={['UPDATE', 'DELETE']}
                                    actionsHandlers={{
                                        handleUpdate,
                                        handleDelete,
                                    }}
                                    maxWidth={'200px'}
                                />
                            </ListServices>
                        }
                    </Content_Signup_Columns>
                </Scroll_Content_Signup>
            )
        } else {
            return <ContentPayment />;
        }
    }

    return (
        <>
            {getContentOfTab()}
            <ModalCommunities
                showModal={showModal}
                onHide={setShowModal}
                data={community}
                modalities={modalities}
                service={service}
            />
            <ModalCommunities
                showModal={showModalEdit}
                onHide={handleModalEdit}
                data={community}
                edit
                clubInfo={editComm}
            />
        </>
    )
}

const TextServices = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

const ContentServices = styled.div`
    display: grid;
    grid-template-columns: 30% calc(70% - 20px);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
`

const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Area = styled.div`
    border-bottom: 1px solid darkgrey;
    background-color: white;
    font-family: 'Inter';
    font-size: 14px;
    cursor: pointer;
    width: 100%;
    padding: 13px 9px;
    margin: 0;
    display: flex;
    justify-content: space-between;
`;

const ListAreas = styled.div`
    width: 100%;
    padding: 7px 9px;
    background-color: white;
    height: calc(100vh - 385px);
    overflow-y: scroll;
    overflow-x: hidden;
    text-overflow: ellipsis;


    &::-webkit-scrollbar {
        width: 5px;
        display: block !important;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--t-blue);
    }
`

const BaseAreas = styled.div`
    width: 100%;
    border-radius: 9px;
    background-color: white;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;
`

const TitleAreas = styled.span`
    font-family: 'Inter-Bold';
    font-size: 16px;
    color: black;
    margin: 15px 0px 0px 10px;
`;

export const Column = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
  
    border-radius: 8px;
    border: 1px solid #D3DCE5;
    background: #F7F5F4;
  
    .column1 { grid-area: 1 / 1 / 2 / 3; }
    .column2 { grid-area: 1 / 3 / 2 / 5; }
  `

const ItemInfo = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
`;

const IconHealer = styled.div`
  width: 40px;
  height: 40px;
  background-color: #DBB467;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: relative;
  background-image: url(../icon_healer.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80% auto;
  border: 2px solid var(--simple-blue-dark);
`

const HeaderCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.theme.justify || 'space-between'};
  padding: 24px;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid #D3DCE5;
  background-color: #FFF;
  height: 88px;
  gap: 15px;
`

const TextPrice = styled.div`
  color: var(--default-dark);
  font-size: 24px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`
const TextLine = styled.div`
  color: #808080;
  text-align: right;
  font-size: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: line-through;
`
const InfoContainer = styled.div`
    padding: 24px;
`
const TitleBold = styled.div`
    color: var(--default-dark);
    font-size: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
`

const List = styled.div`
  margin: 8px 0;
`

const ButtonLetsVibe = styled.button` 
width: 100%;
border: none;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--dark-blue);
  color: #FFF;
  display: flex;
  font-size: 16px;
  font-family: Inter-Bold;
  margin-top: 24px;
  cursor: pointer;
  justify-content: flex-end;
  gap: 40%;
  padding-right: 10px;
`

const WrapperText = styled.p`
    overflow: hidden;
    max-height: 15px;
`;

const WrapperEmpty = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const TableIcon = styled(FontAwesomeIcon)`
    font-size: 18px;
    cursor: pointer;
`;

const TableList = styled.table`
    width: 100%;
    background-color: white;
    border-radius: 8px;
`

const Tr = styled.tr`
    & td:last-child,
    & th:last-child {
        width: 85px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

const Th = styled.th`
    vertical-align: middle;
    height: 40px;
    border-bottom: 1px solid var(--simple-gray-2);
    font-family: 'Inter-Bold';
    font-size: 14px;
    padding: 0 15px;
    text-align: left;
`

const Td = styled.td`
    vertical-align: middle;
    height: 40px;
    border-bottom: 1px solid var(--simple-gray-2);
    font-family: 'Inter';
    font-size: 14px; 
    padding: 0 15px; 
    text-align: left;
`

const SearchIcon = styled(FontAwesomeIcon)`
    font-size: 12px;
    margin-right: 20px;
    color: var(--black);
    margin-left: 20px;
`;

const InputIcon = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-radius: 8px;
    align-items: center;
    border: 1px solid var(--simple-gray);
    height: 40px;
    width: 100%;
    
    & ${Input}{
        border: 0;
        height: 38px;
    } 
`

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background: var(--electric-yellow-2);
    border-radius: 8px;
    color: var(--white);
    font-family: 'Inter-Bold';
    font-size: 16px;
    padding: 10px;

    cursor: pointer;
`

const Title = styled.p`
    font-family: 'Inter-Bold';
    font-size: 16px;
    padding: 13px 0;
`
const SubTitle = styled.span`
    font-family: 'Inter';
    font-size: 14px;
`

const ListServicesEmp = styled.div`
    width: 100%;
    height: 100%;
    background-color: white;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-image: url(./NoServices.png);
    border-radius: 9px;
    padding: 20px 0;
`

const ListServices = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    overflow: scroll;
`

const IconPlus = styled(FontAwesomeIcon)`
    font-size: 14px;
    margin-left: 8px;
`;

const InputPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0;
    font-size: 15px;
    font-family: 'Inter';
    background-color: transparent;
`;

export default Communities;