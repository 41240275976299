export const START_GET_CONNECTIONS = 'START_GET_CONNECTIONS';
export const GET_CONNECTIONS_SUCCESS = 'GET_CONNECTIONS_SUCCESS';
export const GET_CONNECTIONS_ERROR = 'GET_CONNECTIONS_ERROR';

export const START_GET_I_SUG = 'START_GET_I_SUG';
export const GET_I_SUG_SUCCESS = 'GET_I_SUG_SUCCESS';
export const GET_I_SUG_ERROR = 'GET_I_SUG_ERROR';

export const START_GET_INT_USER = 'START_GET_INT_USER';
export const GET_INT_USER_SUCCESS = 'GET_INT_USER_SUCCESS';
export const GET_INT_USER_ERROR = 'GET_INT_USER_ERROR';

export const START_SAVE_I = 'START_SAVE_I';
export const SAVE_I_SUCCESS = 'SAVE_I_SUCCESS';
export const SAVE_I_ERROR = 'SAVE_I_ERROR';

export const START_GET_C_SUG = 'START_GET_C_SUG';
export const GET_C_SUG_SUCCESS = 'GET_C_SUG_SUCCESS';
export const GET_C_SUG_ERROR = 'GET_C_SUG_ERROR';

export const START_GET_U_SUG = 'START_GET_U_SUG';
export const GET_U_SUG_SUCCESS = 'GET_U_SUG_SUCCESS';
export const GET_U_SUG_ERROR = 'GET_U_SUG_ERROR';

export const START_GET_CLUB_SUG = 'START_GET_CLUB_SUG';
export const GET_CLUB_SUG_SUCCESS = 'GET_CLUB_SUG_SUCCESS';
export const GET_CLUB_SUG_ERROR = 'GET_CLUB_SUG_ERROR';

//Get connections

export const START_GET_USER_FRIENDS = 'START_GET_USER_FRIENDS';
export const GET_USER_FRIENDS_SUCCESS = 'GET_USER_FRIENDS_SUCCESS';
export const GET_USER_FRIENDS_ERROR = 'GET_USER_FRIENDS_ERROR';

export const START_GET_USER_POSSIBLE_CONNECTIONS = 'START_GET_USER_POSSIBLE_CONNECTIONS';
export const GET_USER_POSSIBLE_CONNECTIONS_SUCCESS = 'GET_USER_POSSIBLE_CONNECTIONS_SUCCESS';
export const GET_USER_POSSIBLE_CONNECTIONS_ERROR = 'GET_USER_POSSIBLE_CONNECTIONS_ERROR';

export const START_GET_USER_BIRTHDAY_CONNECTIONS = 'START_GET_USER_BIRTHDAY_CONNECTIONS';
export const GET_USER_BIRTHDAY_CONNECTIONS_SUCCESS = 'GET_USER_BIRTHDAY_CONNECTIONS_SUCCESS';
export const GET_USER_BIRTHDAY_CONNECTIONS_ERROR = 'GET_USER_BIRTHDAY_CONNECTIONS_ERROR';

export const START_GET_USER_PENDING_REQUESTS = 'START_GET_USER_PENDING_REQUESTS';
export const GET_USER_PENDING_REQUESTS_SUCCESS = 'GET_USER_PENDING_REQUESTS_SUCCESS';
export const GET_USER_PENDING_REQUESTS_ERROR = 'GET_USER_PENDING_REQUESTS_ERROR';

export const START_GET_USER_SENT_REQUESTS = 'START_GET_USER_SENT_REQUESTS';
export const GET_USER_SENT_REQUESTS_SUCCESS = 'GET_USER_SENT_REQUESTS_SUCCESS';
export const GET_USER_SENT_REQUESTS_ERROR = 'GET_USER_SENT_REQUESTS_ERROR';

export const START_GET_UNREG_SUG = 'START_GET_UNREG_SUG';
export const GET_UNREG_SUG_SUCCESS = 'GET_UNREG_SUG_SUCCESS';
export const GET_UNREG_SUG_ERROR = 'GET_UNREG_SUG_ERROR';

//CRUD Connections

export const START_SAVE_NEW_FRIEND = 'START_SAVE_NEW_FRIEND';
export const SAVE_NEW_FRIEND_SUCCESS = 'SAVE_NEW_FRIEND_SUCCESS';
export const SAVE_NEW_FRIEND_ERROR = 'SAVE_NEW_FRIEND_ERROR';

export const START_CANCEL_FRIEND_REQUEST = 'START_CANCEL_FRIEND_REQUEST';
export const CANCEL_FRIEND_REQUEST_SUCCESS = 'CANCEL_FRIEND_REQUEST_SUCCESS';
export const CANCEL_FRIEND_REQUEST_ERROR = 'CANCEL_FRIEND_REQUEST_ERROR';

export const START_REJECT_FRIEND_REQUEST = 'START_REJECT_FRIEND_REQUEST';
export const REJECT_FRIEND_REQUEST_SUCCESS = 'REJECT_FRIEND_REQUEST_SUCCESS';
export const REJECT_FRIEND_REQUEST_ERROR = 'REJECT_FRIEND_REQUEST_ERROR';

export const START_CREATE_FRIEND_REQUEST = 'START_CREATE_FRIEND_REQUEST';
export const CREATE_FRIEND_REQUEST_SUCCESS = 'CREATE_FRIEND_REQUEST_SUCCESS';
export const CREATE_FRIEND_REQUEST_ERROR = 'CREATE_FRIEND_REQUEST_ERROR';

export const START_DELETE_FRIEND = 'START_DELETE_FRIEND';
export const DELETE_FRIEND_SUCCESS = 'DELETE_FRIEND_SUCCESS';
export const DELETE_FRIEND_ERROR = 'DELETE_FRIEND_ERROR';

export const START_CREATE_EMAIL_REQ = 'START_CREATE_EMAIL_REQ';
export const CREATE_EMAIL_REQ_SUCCESS = 'CREATE_EMAIL_REQ_SUCCESS';
export const CREATE_EMAIL_REQ_ERROR = 'CREATE_EMAIL_REQ_ERROR';

export const START_SEARCH_ENGINE = 'START_SEARCH_ENGINE';
export const SEARCH_ENGINE_SUCCESS = 'SEARCH_ENGINE_SUCCESS';
export const SEARCH_ENGINE_ERROR = 'SEARCH_ENGINE_ERROR';

export const LOGOUT_CONNECTION = 'LOGOUT_CONNECTION';