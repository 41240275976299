import { useNavigate } from "react-router-dom";
import { FooterColumnHome, FooterResultsHome, FooterRowHome, LogoHomeResults, SocialMediaFooter } from "./styles";
import { TextClicker } from "../HeaderHome/styles";

const FooterHome = () => {
    const navigate = useNavigate();

    return (
        <FooterResultsHome>
            <LogoHomeResults onClick={() => navigate('../../../../../')} />

            <FooterColumnHome>
                <TextClicker onClick={() => navigate('../../../../../about')}>
                    About Vibe
                </TextClicker>
                <TextClicker onClick={() => navigate('../../../../../advisors')}>
                    Advisory Body
                </TextClicker>
                <TextClicker>
                    <a href="mailto:hello@vibe.love" target="_blank" rel="noopener noreferrer">
                        hello@vibe.love
                    </a>
                </TextClicker>
            </FooterColumnHome>

            <FooterColumnHome>
                <TextClicker>
                    <a href="" target="_blank" rel="noopener noreferrer">
                        Policy & Privacy
                    </a>
                </TextClicker>
                <TextClicker>
                    <a href="" target="_blank" rel="noopener noreferrer">
                        Terms & Conditions
                    </a>
                </TextClicker>
                <TextClicker onClick={() => navigate('../../../../../faq')}>
                    FAQS
                </TextClicker>
            </FooterColumnHome>

            <FooterColumnHome>
                <FooterRowHome>
                    <a href="https://www.instagram.com/wevibe.love/" target="_blank" rel="noopener noreferrer">
                        <SocialMediaFooter src='./instagram_white.png' />
                    </a>
                    <a href="https://www.tiktok.com/@wevibe.love" target="_blank" rel="noopener noreferrer">
                        <SocialMediaFooter src='./tiktok_white.png' />
                    </a>
                </FooterRowHome>
                <div>Copyright 2023, Vibe</div>
            </FooterColumnHome>
        </FooterResultsHome>
    )
}

export default FooterHome;