import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-thin-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { getUser } from '../../store/actions/userActions';
import { RootState } from '../../store/store';
import ButtonSpinner from '../BaseComponents/ButtonSpinner';
import { ProcessType } from '../../containers/SetupHealer/SetupHealer';

const Footer = ({
    handleTabActive,
    tabActive,
    setShowSetup,
    setProcess,
    process,
}: {
    handleTabActive: (value: number) => void,
    tabActive: number,
    setShowSetup: (value: boolean) => void,
    setProcess: (value: ProcessType) => void,
    process: ProcessType
}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userInfo = useSelector((state: RootState | any) => state.getUser.userInfo);

    const [loading, setLoading] = useState<boolean>(false);

    const setupComplete = () => {
        setLoading(true);
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.AccessManager.setupCompleteUser(userInfo.username, dataSDK.token);
            })
            .then((results) => {
                dispatch<any>(getUser(userInfo.username))
                    .then(() => {
                        setLoading(false);
                        setShowSetup(false);
                        navigate("/app");
                    })
            })
    }

    const movePage = (tab: number, type: string) => {
        if (tab >= 1 && type === 'next' && tab < 5) {
            handleTabActive(tab + 1)
        }
        setLoading(false);
    }

    function handleTab(tab: number, type: string) {

        if(tab !== 1 && type === 'back'){
            handleTabActive(tab - 1);
            return;
        }

        if(tab !== 1 && tab !== 2){
            setProcess('continue');
        }
        else{
            setLoading(true);
            setProcess('loading');
        }

        if (type === 'complete') {
            setupComplete();
            return;
        }
    }

    useEffect(() => {
        if(process === 'continue' && tabActive !== 5) movePage(tabActive, "next");
        if(process === 'off') setLoading(false);
        if(process === 'loading') setLoading(true);
    },[process])

    return (
        <FooterCon_Setup isFirstTab={tabActive === 1}>

            {tabActive !== 1 && (
                <ButtonBack onClick={() => handleTab(tabActive, 'back')}>
                    <IconButtonFtr
                        icon={faArrowLeft}
                        size='lg'
                        color={"black"}
                        style={{
                            fontSize: '16px'
                        }}
                    />
                    Back
                </ButtonBack>
            )}
            
            {tabActive === 5 ? (
                <WrapperButton>
                    <ButtonSpinner
                        loading={loading}
                        handleClick={() => handleTab(tabActive, 'complete')}
                        text={'Submit'}
                        hasIcon={true}
                        icon={'arrow-right'}
                    />
                </WrapperButton>
            ) : (
                <WrapperButtonNext>
                    <ButtonSpinner
                        loading={loading}
                        handleClick={() => handleTab(tabActive, 'next')}
                        text={'Next'}
                        hasIcon={true}
                        icon={'arrow-right'}
                    />
                </WrapperButtonNext>
            )}

        </FooterCon_Setup>
    )
}

const WrapperButton = styled.div`
    width: 25%;

    @media only screen and (max-width: 500px) {
        width: auto;
    }
`;

const WrapperButtonNext = styled.div`
    width: 15%;

    @media only screen and (max-width: 500px) {
        width: auto;
    }
`;

const ButtonBack = styled.div`
    background-color: white;
    border: 1px solid var(--simple-blue-dark-2);
    padding: 12px 16px 12px 4px;
    border-radius: 8px;
    color: black;
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`

export const FooterCon_Setup = styled.div`
    background-color: white;
    width: 100%;
    padding: 10px 16px;
    height: 56px;
    -webkit-box-shadow: 0px -4px 4px 0px rgba(40, 47, 58, 0.05);
    -moz-box-shadow: 0px -4px 4px 0px rgba(40, 47, 58, 0.05);
    box-shadow: 0px -4px 4px 0px rgba(40, 47, 58, 0.05);
    display: flex;
    justify-content: ${({isFirstTab}:{isFirstTab: boolean}) => isFirstTab ? "flex-end": "space-between"};

    @media only screen and (max-width: 500px) {
        position: fixed;
        min-height: 62px;
        width: 100%;
        bottom: 0;
    }
`

const IconButtonFtr = styled(FontAwesomeIcon)`
    padding: 0 10px;
`;

export default Footer