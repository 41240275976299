import styled from 'styled-components';
import Modal from 'react-bootstrap/esm/Modal';
import { faTimes, faChevronDown, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { ClickableIcon } from '../../SuperCenter/SuperAdminComponents/basicForSACenter';
import { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContainerBalance from "../../ContainerBalance";
import OutsideClickHandler from "react-outside-click-handler";
import { ContextSuccess } from '../../../Context';
import { userInfo } from 'os';
import { getDataSDK, getDataSDKResponse } from '../../../helpers/backendHelper';
import { getMyClubs } from '../../../store/actions/clubActions';
import { Loader } from '../Loader';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { getBalance } from '../../../store/actions/userActions';
import useBalance from '../../../hooks/GV/useBalance';
import IResponseError from '@vibe/sdk/dist/interfaces/IResponseError';

const ModalShareVibes = ({
    receiver,
    postId,
    show,
    setShow,
}: {
    receiver: { username: string, name: string, avatar: string }
    postId: string
    show: boolean
    setShow: (data: boolean) => void
}) => {

    const userInfo = useSelector((state: RootState) => state.getUser.userInfo)
    const currentSuccess = useContext(ContextSuccess);
    const dispatch = useDispatch()
    const balance = useBalance()
    const username = useSelector((state: RootState) => state.getUser.userInfo?.username);

    const [amount, setAmount] = useState<number>(0);

    const handleAmountChips = (amount: number) => {
        setAmount(Math.min(amount, balance.balance))
        checkAmount(amount.toString())
    };

    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [gvReason, setGVReason] = useState<string | undefined>(undefined)
    const [gvCustomReason, setGVCustomReason] = useState<string | undefined>(undefined)
    const [transactionId, setTransactionId] = useState<string>('')

    const [amountS, setAmountS] = useState<string | number>('0');
    const [blockSend, setBlockSend] = useState<boolean>(false)
    const [showMessageError, setShowMessageError] = useState<boolean>(false)

    const [step, setStep] = useState<number>(1);
    const [sending, setSending] = useState<boolean>(false)

    const resetInfo = () => {
        setAmount(0);
        setShowOptions(false);
        setGVReason(undefined);
        setGVCustomReason(undefined);
        setTransactionId('');
        setStep(1);
        setSending(false);
    }

    function handleItemClick(option: string) {
        setGVReason(option);
        setShowOptions(false);
    }

    const sendGoodVibes = () => {
        setSending(true)
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Admin.createTransaction({
                    receiverUser: receiver.username,
                    senderUser: userInfo?.username ?? '',
                    description: `Tip received. Reason: ${gvReason ?? ''} - ${gvCustomReason ?? ''}`,
                    type: 'Tip',
                    GV: amount
                }, {
                    postId
                }, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 201) {
                    balance.reloadBalance()
                    setTransactionId((results.body as {
                        transactionId: string;
                    }).transactionId)
                    setStep(3);
                    dispatch(getBalance(username!))
                }
                else {
                    currentSuccess?.setSuccessData(false, true, "Error sending Good Vibes", (results?.body as IResponseError).message);
                }
            })
            .catch((error) => {
                currentSuccess?.setSuccessData(false, true, "Error sending Good Vibes", error);
            })
            .finally(() => setSending(false))
    };

    const checkAmount = (e: string) => {
        const text = e;
        const input = Number(text)
        if (text === '' || isNaN(input)) {
            if (text === '') {
                setAmountS('')
                setBlockSend(true)
            } else {
                setAmountS(amountS)
            }
        } else {
            setAmountS(text)

            if (input === 0) {
                setBlockSend(true)
                return
            }

            if (Number(text) > balance.balance) {
                setShowMessageError(true)
                setBlockSend(true)
                setAmount(0)
            } else {
                setShowMessageError(false)
                setAmountS(text)
                setBlockSend(false)
                setAmount(Math.min(Number(e), balance.balance))
            }
        }
    }

    return (
        <Modal className="modalShareVibes" show={show} onHide={() => { setShow(false); resetInfo() }}>
            <Modal.Header>
                <Modal.Title>Share Good Vibes</Modal.Title>
                <ClickableIcon
                    icon={faTimes}
                    size='lg'
                    color={'var(--t-blue)'}
                    onClick={() => { setShow(false); resetInfo() }}
                />
            </Modal.Header>
            <Modal.Body>
                <Wrapper>
                    {step === 1 ?
                        <>
                            <Balance>
                                <ContainerBalance showDetails={true} />
                            </Balance>
                            <SetAmount>
                                Set Amount
                                <InputAmount
                                    theme={{ borderBottomColor: blockSend ? 'red' : 'black' }}
                                    type='text'
                                    value={amountS}
                                    onChange={(e) => {
                                        checkAmount(e.currentTarget.value)
                                        // setAmount(Math.min(Number(e.currentTarget.value), balance.balance))
                                    }}
                                />
                                {showMessageError &&
                                    <TextError>
                                        * You don't have enough Good Vibes to send this amount, please purchase more
                                    </TextError>
                                }
                                <OptionsAmount>
                                    <Option onClick={() => handleAmountChips(5)}>5</Option>
                                    <Option onClick={() => handleAmountChips(10)}>10</Option>
                                    <Option onClick={() => handleAmountChips(20)}>20</Option>
                                    <Option onClick={() => handleAmountChips(50)}>50</Option>
                                    <Option onClick={() => handleAmountChips(100)}>100</Option>
                                    <Option onClick={() => handleAmountChips(200)}>200</Option>
                                    <Option onClick={() => handleAmountChips(800)}>800</Option>
                                </OptionsAmount>
                            </SetAmount>
                            <InputSelect>
                                <Label>Categorize your Good vibes</Label>
                                <WrapperSelect>

                                    <Select_Input
                                        onClick={() => setShowOptions(true)}
                                        value={gvReason}
                                        placeholder='Select'
                                        backgroundColor={'white'}
                                        border={"1px solid var(--simple-gray)"}
                                    />
                                    <IconDropdownInput
                                        icon={faChevronDown}
                                        size='sm'
                                        color={'white'}
                                        onClick={() => setShowOptions(!showOptions)}
                                    />
                                    {showOptions &&
                                        <OutsideClickHandler onOutsideClick={() => setShowOptions(false)}>
                                            <Items>
                                                <Item onClick={() => handleItemClick('Positive Content')}>Positive Content​</Item>
                                                <Item onClick={() => handleItemClick('Inspirational Share')}>Inspirational Share</Item>
                                                <Item onClick={() => handleItemClick('Raising Vibrations')}>Raising Vibrations​</Item>
                                                <Item onClick={() => handleItemClick('Other')}>Other</Item>
                                            </Items>
                                        </OutsideClickHandler>
                                    }
                                </WrapperSelect>
                                {gvReason === 'Other' && (
                                    <Input
                                        value={gvCustomReason}
                                        placeholder='Type your own reason here'
                                        onChange={(e) => setGVCustomReason(e.target.value)}
                                    />
                                )}
                            </InputSelect>
                            <ButtonContinue theme={{ opacity: blockSend || amountS === '0' ? 0.6 : 1, pointerEvents: blockSend || amountS === '0' ? 'none' : 'auto' }} onClick={() => setStep(2)}>
                                Continue
                            </ButtonContinue>
                        </>
                        :
                        step === 2 ?
                            <>
                                <TextVerify> Verify your sent</TextVerify>
                                <CountVibes>
                                    <Vibes>
                                        Good Vibes to sent
                                        <Count>
                                            {amount}
                                            <IconVibes />
                                        </Count>
                                    </Vibes>
                                    <ButtonChange onClick={() => setStep(1)}>
                                        Change
                                    </ButtonChange>
                                </CountVibes>
                                <Recipent>
                                    Recipent
                                    <NameUser>
                                        <Avatar src={receiver.avatar} />
                                        {receiver.name}
                                    </NameUser>
                                </Recipent>
                                <Reason>
                                    <TextReason>Reason</TextReason>
                                    <TextReason>
                                        {gvReason !== undefined ?
                                            (gvReason === 'Other' ?
                                                (gvCustomReason !== undefined ?
                                                    gvCustomReason : gvReason) : gvReason) : 'No reason'}
                                    </TextReason>
                                </Reason>
                                {sending ? (<Loader color={"var(--simple-blue-dark-2)"} />) : (
                                    <ButtonContinue onClick={() => sendGoodVibes()}>
                                        Send Good Vibes
                                    </ButtonContinue>
                                )}
                            </>
                            :
                            <>
                                <TextSuccess>
                                    <SuccessIcon
                                        icon={faCheck}
                                        color={'var(--t-blue)'}
                                    />
                                    Successfully sent!
                                </TextSuccess>
                                <CountVibes>
                                    <Vibes>
                                        Good Vibes sent
                                        <Count>
                                            {amount}
                                            <IconVibes />
                                        </Count>
                                    </Vibes>
                                </CountVibes>
                                <Recipent>
                                    Recipent
                                    <NameUser>
                                        <Avatar src={receiver.avatar} />
                                        {receiver.name}
                                    </NameUser>
                                </Recipent>
                                <Reason>
                                    <TextReason>Transaction Identifier:</TextReason>
                                    <TextReason>{transactionId.split(':')[1]}</TextReason>
                                </Reason>
                                <ButtonClose onClick={() => { setShow(false); resetInfo(); }}>
                                    Close
                                </ButtonClose>
                            </>
                    }

                </Wrapper>
            </Modal.Body>
        </Modal>
    )
}

export default ModalShareVibes;

const TextError = styled.div`
    font-size: 11px;
    text-align: left;
    color: red;
    margin-top: -10px;
`

const ButtonClose = styled.div`
    position: relative;
    width: calc(100% + 48px);
    right: 24px;
    padding: 10px 24px;
    top: 15px;
    border-top: 1px solid #BFBFBF;
    color: #2A4CD3;
    text-align: right;
    font-family: 'Inter-Bold';
    font-size: 16px;
    letter-spacing: 0.1px;
`

const TextSuccess = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    color: var(--default-dark);
    text-align: center;
    font-family: 'Inter-Bold';
    font-size: 18px;
    letter-spacing: 0.1px;
`
const SuccessIcon = styled(FontAwesomeIcon)`
    font-size: 10px;
    background-color: #A3CC52;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 9px;
`;

const IconVibes = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: relative;
  background-image: url(../goodVibes.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80% auto;
`

const Reason = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
`
const TextReason = styled.div`
    color: #181818;
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    letter-spacing: 0.2px;
`

const Recipent = styled.div`
    color: #181818;
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    letter-spacing: 0.2px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
`
const NameUser = styled.div`
    color: #181818;
    text-align: center;
    font-family: 'Inter-Bold';
    font-size: 16px;
    letter-spacing: 0.1px;
    display: flex;
    align-items: center;
    gap: 8px;
`
const Icon = styled.div`
    width: 32px;
    height: 32px;
    background-color: #27303B;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;
`

const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
    margin: 7px 0;
`

const Avatar = styled.img`
    object-fit: cover;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    border-radius: 50%;
    border: 1px solid var(--simple-gray);
`;

const TextVerify = styled.div`
    color: #282A2F;
    font-family: 'Inter-Bold';
    font-size: 24px;
    letter-spacing: 0.1px;
`
const CountVibes = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const Vibes = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    font-family: 'Inter';
    font-size: 16px;
    letter-spacing: 0.2px;
`
const Count = styled.div`
    color: var(#181818);
    text-align: center;
    font-family: 'Inter-Bold';
    font-size: 24px;
    letter-spacing: 0.1px;
    display: flex;
    gap: 8px;
    align-items: center;
`
const ButtonChange = styled.div`
    color: #2A4CD3;
    font-family: 'Inter-Bold';
    font-size: 16px;
    letter-spacing: 0.1px;
`

const InputSelect = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
`

const Label = styled.p`
    color: var(--default-dark);
    font-family: 'Inter';
    font-size: 16px;
    letter-spacing: 0.2px;
`

const ButtonContinue = styled.div`
    width: 100%;
    display: flex;
    height: 48px;
    justify-content: center;
    align-items: center;
    background-color: var(--dark-blue);
    color: white;
    font-family: "Inter-Bold";
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;

    opacity: ${props => props.theme.opacity || '1'};
    pointerEvents: ${props => props.theme.pointerEvents || 'auto'};
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  gap:24px;
  align-items: flex-start;
`;

const WrapperSelect = styled.div`
    position: relative;
    display: flex;
    width: 100%;
`;

interface Select_InputProps {
    backgroundColor: string
    border: string
}

const Select_Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Inter';
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;

    background-color: ${(props: Select_InputProps) => props.backgroundColor};
    border: ${(props: Select_InputProps) => props.border};
    color: ${(props: Select_InputProps) => props.backgroundColor === 'white' ? 'black' : 'white'};

    &::placeholder{
        color: var(--simple-gray);
        font-family: 'Inter';
    }
`;

const IconDropdownInput = styled(FontAwesomeIcon)`
    cursor: pointer;
    position: absolute;
    z-index: 2;
    color: black;
    right: 17px;
    top: 14px;
`;

const Item = styled.li`
    background-color: white;
    padding: 10px 20px;
    text-align: left;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;

    &:last-child {
        border-radius: 0 0 10px 10px;
        -webkit-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        -moz-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
    }

    &:hover {
        background-color: #e8e8e8;
    }
`;

const Items = styled.ul`
    width: 100%;
    position: absolute;
    top: 100%;
    z-index: 1;
    overflow-y: scroll;
    max-height: 200px;
    border-radius: 12px;
    border: 1px solid var(--simple-gray);
    left: 0px;
    box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
`;

const Balance = styled.div`
    width: 100%;
`

const SetAmount = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Inter';
    font-size: 16px;
    gap: 15px;
`
const InputAmount = styled.input`
    height: 50px;
    border-bottom: 1px solid black;
    width: 100%;
    font-size: 24px;
    text-align: center;

    border-bottom: 1px solid ${props => props.theme.borderBottomColor || 'black'};
`

const ContainerOptions = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    height: 0px;
    overflow:hidden;
    -webkit-transition: height 1s ease-in-out;
    -moz-transition: height 1s ease-in-out;
    -o-transition: height 1s ease-in-out;
    transition: height 1s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 25px;

    &.active{
        height:160px;
    }
    &.inactive{
        height:0px;
    }
`

const OptionsAmount = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: center;
    padding-bottom: 16px;
    border-bottom: 1px solid #F2F2F2;
`

const Option = styled.div`
    border: 1px solid #999796;
    min-width: 40px;
    justify-content: center;
    display: flex;
    height: 32px;
    padding: 4px 12px;
    align-items: center;
    border-radius: 16px;
    cursor: pointer;
`