import styled from 'styled-components';
import { IconClicker } from '../../Home/HeaderHome/styles';
import Modal from 'react-bootstrap/esm/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

const ModalTemplates = ({
    templates,
    setTemplate,
    show,
    onHide,
    clubTypeSingle,
}: {
    templates: any[]
    setTemplate: (data: any) => void
    show: boolean
    onHide: () => void
    clubTypeSingle: string
}) => {
    return (
        <Modal className="modalTemplates" show={show} onHide={onHide}>
            <Modal.Header>
                <LabelTemplates>Select one of the following templates to create your new {clubTypeSingle}</LabelTemplates>
                <IconClicker
                    onClick={onHide}
                    icon="times"
                    size='xl'
                    color={'black'}
                />
            </Modal.Header>

            <Templates>
                <Area onClick={() => setTemplate('custom')}>
                    Custom {clubTypeSingle}
                    <IconPlus
                        icon={faPlus}
                        size='sm'
                        color={"black"}
                    />
                </Area>
                {templates.map(template => (
                    <Area onClick={() => setTemplate(template)}>
                        {template.name} {clubTypeSingle}
                        <IconPlus
                            icon={faPlus}
                            size='sm'
                            color={"black"}
                        />
                    </Area>
                ))}
            </Templates>
        </Modal>
    )
}

export default ModalTemplates;

const IconPlus = styled(FontAwesomeIcon)`
    cursor: pointer;
`;

const Area = styled.div`
    border-bottom: 1px solid var(--simple-gray);
    font-family: 'Inter';
    line-height: 18px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px 10px 10px;
    gap: 8px;
    align-items: center;
    text-align: left;
`

const Templates = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;
    flex-direction: column;
    height: 100%;
    border-radius: 9px;
    align-items: flex-start;
    padding: 15px;
    margin-top: 20px;
    max-height: 80vh;
    overflow: scroll;
`;

const LabelTemplates = styled.h1`
    color: var(--t-blue);
    font-family: 'Inter-bold';
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    width: 400px;
    padding-right: 50px; 
    height: fit-content;
    text-align: left;
`;