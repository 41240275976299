import { useContext, useState } from "react";
import CreateModal from "./Modals/CreateModal";
import EditModal from "./Modals/EditModal";
import styled from "styled-components";
import SearchInput from "../../BaseComponents/SearchInput";
import BlankTable from "../../BaseComponents/BlankTable";
import { onHideType } from "../../BaseComponents/Modals/ModalUnsaved";
import { ContextSuccess } from "../../../Context";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Title } from "../SuperAdminComponents/basicForSACenter";
import useEventTypesSA from "../../../hooks/Superadmin/EventTypes/useEventTypesSA";


const EventTypesView = () => {
    const currentSuccess = useContext(ContextSuccess);

    const [searchName, setSearchName] = useState<string>('');
    const [showCreate, setShowCreate] = useState<boolean>(false);
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [idSelected, setIdSelected] = useState<string>('');

    const { 
        eventTypesPages, 
        reloadEventTypes,
        filteredEventTypes
    } = useEventTypesSA({
        pageSizeProp: 1000,
        searchName,
    });

    const onHideCreateModal = (e: string | undefined) => {
        setShowCreate(false);
        setShowEdit(false);
        e === 'reload' && reloadEventTypes();
    }

    const handleUpdate = (data: string) => {
        setIdSelected(data);
        setShowEdit(true);
    }

    const onHideDelete = (type: onHideType, id: string) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);
                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        // TODO:
                        return dataSDK.sdk.Modalities.deleteBE(id)
                    })
                    .then((response) => {
                        if (response.statusCode === 204) {
                            reloadEventTypes();
                            currentSuccess?.setSuccessData(true, true, 'Event Type Successfully Deleted');
                            currentSuccess?.setModalsOff();
                        } else {
                            currentSuccess?.setSuccessData(false, true, 'Error deleting Event Type');
                        }
                    })
                    .finally(() => currentSuccess?.setLoadingModal(false))
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                break;

            default:
                break;
        }
    }

    const handleDelete = (data: string) => {
        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => onHideDelete(type, data),
            'Do you want to delete this Event Type?'
        );
    }

    return (
        <ContentOrganizations>
            <Title>Event Types</Title>
            <WrapperRow>
                <Button onClick={() => setShowCreate(true)}>
                    <StyledFontAwesomeIcon
                        size='sm'
                        color='white'
                        icon='plus'
                    />
                    Create Type
                </Button>
                <WrapInput>
                    <SearchInput
                        filteredItem={searchName}
                        setFilteredItem={setSearchName}
                        backgroundColor={'var(--t-blue)'}
                        border={'0px'}
                        placeholder={`Search by name`}
                    />
                </WrapInput>
            </WrapperRow>
            <Scroll>
                <BlankTable
                    headers={['ID', 'Name']}
                    items={filteredEventTypes.map(org => ({
                        id: org.id,
                        labels: [
                            org.id,
                            org.name,
                        ],
                    }))}
                    // actions={['UPDATE', 'DELETE']}
                    actionsHandlers={{
                        handleUpdate,
                        handleDelete,
                    }}
                    backgroundColor={'white'}
                />
            </Scroll>
            <CreateModal
                show={showCreate}
                onHide={onHideCreateModal}
            />
            <EditModal
                id={idSelected}
                show={showEdit}
                onHide={onHideCreateModal}
            />
        </ContentOrganizations>
    );
}

export default EventTypesView;

const WrapInput = styled.div`
    width: 50%;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const ContentOrganizations = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  align-items: flex-start;
  gap: 15px;
  flex-direction: column;
`;

const WrapperRow = styled.div`
  display: flex;
  width: 100%;
  gap: 25px;
  align-items: flex-end;
  justify-content: space-between;
`;

const Scroll = styled.div`
  display: flex;
  width: 100%;
  overflow-y: scroll;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 185px;
  min-width: 100px;
  height: 100%;
  padding: 10px 20px;
  border-radius: 11px;
  background-color: var(--simple-blue-dark);
  border: 0;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: white;
  gap: 10px;

  cursor: pointer;
`;