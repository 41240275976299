import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useEffect, useMemo, useState } from "react";
import { getPostsHealer, getPostsHealerFeatured, getPostsOrg, reloadPostsClub, reloadPostsEvent, reloadPostsFriends, reloadPostsOrg, reloadPostsSaved, reloadPostsWall } from "../../store/actions/postActions";
import { mapPostsCard } from "../../helpers/postHelper";
import { IPostExtended } from "@vibe/sdk/dist/interfaces/Posts/IPost";
import { PostCardInterface } from "../../components/BaseComponents/Posts/PostCard";
import { sortAlphabeticallyOnProperty } from "../../containers/Signup/SignupHealerInd";

interface ReloadAllForm {
  event_id?: string;
  club_id?: string;
  org_id?: string;
  user_name?: string;
  username_wall?: string;
}

export const sortAlphabeticallyOnPropertyPosts = (list: any[], property: string, ordSelected: 'DESC' | 'ASC' = 'ASC') => {
  if (ordSelected === 'DESC') {
      return list.sort((a, b) => a[property] < b[property] ? 1 : a[property] > b[property] ? -1 : 0)
  }
  return list.sort((b, a) => a[property] < b[property] ? 1 : a[property] > b[property] ? -1 : 0)
}

const mapAndSortPosts = (posts: IPostExtended[], order: 'DESC' | 'ASC'): PostCardInterface[] =>
  sortAlphabeticallyOnPropertyPosts(mapPostsCard(posts.filter(p => p && !p.is_tip)), 'timestamp', order);

const usePosts = () => {
  const dispatch = useDispatch();

  const [order, setOrder] = useState<'DESC' | 'ASC'>('DESC');

  const [eventId, setEventId]             = useState<string>();
  const [clubId, setClubId]               = useState<string>();
  const [orgId, setOrgId]                 = useState<string>();
  const [username, setUsername]           = useState<string>();
  const [usernameWall, setUsernameWall]   = useState<string>();

  const postsBulletin         = useSelector((state: RootState) => state.getPost.getPostsBulletin);
  const postsOrg              = useSelector((state: RootState) => state.getPost.getPostsOrg ?? []);
  const postsFriends          = useSelector((state: RootState) => state.getPost.getPostsFriends ?? []);
  const postsSave             = useSelector((state: RootState) => state.getPost.getPostsSave ?? []);
  const postsFeedMyProfile    = useSelector((state: RootState) => state.getPost.getPostsFeedProfile ?? []);
  const postsFeedConnection   = useSelector((state: RootState) => state.getPost.getPostsFeedConnections ?? []);
  const postsEvent            = useSelector((state: RootState) => state.getPost.getPostsEvent ?? []);
  const postsClub             = useSelector((state: RootState) => state.getPost.getPostsClub ?? []);

  const loadingBulletin         = useSelector((state: RootState) => !!state.getPost.loadingGetPostsBulletin);
  const loadingOrg              = useSelector((state: RootState) => !!state.getPost.loadingGetPostsOrg);
  const loadingFriends          = useSelector((state: RootState) => !!state.getPost.loadingGetPostsFriends);
  const loadingSave             = useSelector((state: RootState) => !!state.getPost.loadingGetPostsSave);
  const loadingFeedMyProfile    = useSelector((state: RootState) => !!state.getPost.loadingGetPostsFeedProfile);
  const loadingFeedConnection   = useSelector((state: RootState) => !!state.getPost.loadingGetPostsFeedConnections);
  const loadingEvent            = useSelector((state: RootState) => !!state.getPost.loadingGetPostsEvent);
  const loadingClub             = useSelector((state: RootState) => !!state.getPost.loadingGetPostsClub);

  const cardsBulletin         = useMemo(() => mapAndSortPosts(postsBulletin, order),       [postsBulletin, order]);
  const cardsOrg              = useMemo(() => mapAndSortPosts(postsOrg, order),            [postsOrg, order]);
  const cardsFriends          = useMemo(() => mapAndSortPosts(postsFriends, order),        [postsFriends, order]);
  const cardsSave             = useMemo(() => mapAndSortPosts(postsSave, order),           [postsSave, order]);
  const cardsFeedMyProfile    = useMemo(() => mapAndSortPosts(postsFeedMyProfile, order),  [postsFeedMyProfile, order]);
  const cardsFeedConnection   = useMemo(() => mapAndSortPosts(postsFeedConnection, order), [postsFeedConnection, order]);
  const cardsEvent            = useMemo(() => mapAndSortPosts(postsEvent, order),          [postsEvent, order]);
  const cardsClub             = useMemo(() => mapAndSortPosts(postsClub, order),           [postsClub, order]);
  
  useEffect(() => {
    forceReloadEventId();
  }, [eventId])

  useEffect(() => {
    forceReloadClubId();
  }, [clubId])

  useEffect(() => {
    forceReloadOrgId();
  }, [orgId])

  useEffect(() => {
    forceReloadUsername();
  }, [username])

  useEffect(() => {
    forceReloadUsernameWall();
  }, [usernameWall])

  const forceReloadEventId = () => {
    eventId && dispatch(reloadPostsEvent(eventId))
  };

  const forceReloadClubId = () => {
    clubId && dispatch(reloadPostsClub(clubId))
  };

  const forceReloadOrgId = () => {
    orgId && dispatch(reloadPostsOrg(orgId))
  };

  const forceReloadUsername = () => {
    if (!username) return;
    dispatch(reloadPostsSaved(username));
    dispatch(reloadPostsFriends(username));
    dispatch(getPostsOrg(username));
    dispatch(getPostsHealer(username));
    dispatch(getPostsHealerFeatured(username));
  };

  const forceReloadUsernameWall = () => {
    usernameWall && dispatch(reloadPostsWall(usernameWall))
  };

  const reloadEventPosts = (event_id: string) => {
    if (event_id && event_id.length > 0) {
      setEventId(event_id);
    }
  }

  const reloadClubPosts = (club_id: string) => {
    if (club_id && club_id.length > 0) {
      setClubId(club_id);
    }
  }

  const reloadOrgPosts = (org_id: string) => {
    if (org_id && org_id.length > 0) {
      setOrgId(org_id);
    }
  }

  const reloadUserPosts = (user_name: string) => {
    if (user_name && user_name.length > 0) {
      setUsername(user_name);
    }
  }

  const reloadWallPosts = (username_wall: string) => {
    if (username_wall && username_wall.length > 0) {
      setUsernameWall(username_wall);
    }
  }

  const reloadAll = (form: ReloadAllForm) => {
    if (form?.event_id) reloadEventPosts(form.event_id);
    if (form?.club_id) reloadClubPosts(form.club_id);
    if (form?.org_id) reloadOrgPosts(form.org_id);
    if (form?.user_name) reloadUserPosts(form.user_name);
    if (form?.username_wall) reloadWallPosts(form.username_wall);
  }

  const toggleOrder = () => {
    setOrder((oldOrder) => oldOrder === 'ASC' ? 'DESC' : 'ASC');
  }

  return {
    order,
    toggleOrder,
    postsBulletin,
    postsOrg,
    postsFriends,
    postsSave,
    postsFeedMyProfile,
    postsFeedConnection,
    postsEvent,
    postsClub,
    loadingBulletin,
    loadingOrg,
    loadingFriends,
    loadingSave,
    loadingFeedMyProfile,
    loadingFeedConnection,
    loadingEvent,
    loadingClub,
    cardsBulletin,
    cardsOrg,
    cardsFriends,
    cardsSave,
    cardsFeedMyProfile,
    cardsFeedConnection,
    cardsEvent,
    cardsClub,
    reloadEventPosts,
    reloadClubPosts,
    reloadOrgPosts,
    reloadUserPosts,
    reloadWallPosts,
    reloadAll,
    forceReloadEventId,
    forceReloadClubId,
    forceReloadOrgId,
    forceReloadUsername,
    forceReloadUsernameWall,
  }
}

export default usePosts;