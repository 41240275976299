import styled from "styled-components";
import usePaymentMethods from "../../../../hooks/Payment/usePaymentMethods";
import { useEffect, useState } from "react";
import SavedCard from "./SavedCard";
import { IconClicker } from "../../../Home/HeaderHome/styles";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { MockPaymentMethodCard, PaymentMethodCard } from "../../../EditProfile/PaymentMethod";
import OutsideClickHandler from "react-outside-click-handler";

const CardsPayment = ({
    setCardActive,
    cardActive,
    setNewActive,
    newActive,
}: {
    setCardActive: (card: PaymentMethodCard) => void
    cardActive: PaymentMethodCard
    setNewActive: (isActive: boolean) => void
    newActive: boolean
}) => {
    const { methods } = usePaymentMethods();

    const [open, setOpen] = useState<boolean>(false);

    const getDefaultMethod = () => {
        const defaultMethod = methods.find((met) => met.default);

        if (defaultMethod) setCardActive(defaultMethod);
        else setCardActive({ ...MockPaymentMethodCard });

        // getDataSDK()
        //     .then((dataSDK: getDataSDKResponse) => {
        //         return dataSDK.sdk.Admin.getDefaultPaymentMethod({
        //             username,
        //         }, dataSDK.token)
        //     })
        //     .then((response) => {
        //         if (response.statusCode === 200) {
        //             const results = response.body as any;
        //             setCardActive(results.id);
        //             return;
        //         }
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //     })
    }

    useEffect(() => {
        getDefaultMethod();
        if (methods.length === 0) setNewActive(true);
        else setNewActive(false);
    }, [methods]);

    useEffect(() => {
        if (newActive) setCardActive({ ...MockPaymentMethodCard });
        else getDefaultMethod();
    }, [newActive]);

    return (
        <ColumnContainer all={methods.length > 0 && !newActive}>
            {(methods.length > 0 && !newActive) &&
                <WrapperSelect>
                    {cardActive.id ? <SavedCard card={cardActive} handleCardActive={() => setOpen(!open)} isView/> : (
                        <Placeholder onClick={() => setOpen(!open)}>Select payment method</Placeholder>
                    )}
                    <IconClicker
                        icon={open ? faChevronUp : faChevronDown}
                        size='sm'
                        onClick={() => setOpen(!open)}
                    />
                    {open && <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
                        <ColumnFlex>
                            {methods.map(met => (
                                <SavedCard card={met} handleCardActive={() => {
                                    setCardActive(met);
                                    setOpen(false);
                                }} />
                            ))}
                        </ColumnFlex>
                    </OutsideClickHandler>
                    }
                </WrapperSelect>}

            {methods.length > 0 && <RowContent onClick={() => setNewActive(!newActive)}>
                Add new card
                <IconClicker
                    icon={newActive ? faChevronUp : faChevronDown}
                    size='sm'
                />
            </RowContent>}
        </ColumnContainer>
    )
}

export default CardsPayment;

const Placeholder = styled.div`
    color: var(--simple-gray);
    width: 100%;
    text-align: left;
`;

const WrapperSelect = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 45px;
    min-height: 45px;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    border-radius: 8px;
    border: 1px solid var(--simple-gray);
    cursor: pointer;
`;

const RowContent = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 5px;

    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
    overflow: hidden;
    height: ${({ all }: { all: boolean }) => all ? '140px' : '20px'};
    min-height: ${({ all }: { all: boolean }) => all ? '140px' : '20px'};
    max-height: ${({ all }: { all: boolean }) => all ? '140px' : '20px'};
`;

const ColumnFlex = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    max-height: 68px;
    min-height: 68px;

    width: 100%;
    position: absolute;
    top: 100%;
    z-index: 10000;
    border-radius: 8px;
    border: 1px solid var(--simple-gray);
    background-color: white;
    left: 0px;
    box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);

    padding: 5px 20px;

    :hover::-webkit-scrollbar{
        display: block;
    }

    ::-webkit-scrollbar {
        width: 5px;
        display: none;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--lavender); 
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: var(--light-brown); 
    }
`;