export const START_GET_TCATEGORIES = 'START_GET_TCATEGORIES';
export const GET_TCATEGORIES_SUCCESS = 'GET_TCATEGORIES_SUCCESS';
export const GET_TCATEGORIES_ERROR = 'GET_TCATEGORIES_ERROR';

export const START_GET_CATEGORY = 'START_GET_CATEGORY';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_ERROR = 'GET_CATEGORY_ERROR';

export const START_UPDATE_CATEGORY = 'START_UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR';

export const START_DELETE_CATEGORY = 'START_DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';

export const LOGOUT_TEMPLATE_CATEGORY = 'LOGOUT_TEMPLATE_CATEGORY';