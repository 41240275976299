import { AnyAction } from 'redux';
import React from "react";
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ContextSuccess } from "../../Context";
import i18n from '../../i18n';
import { CognitoUser, ICognitoUserAttributeData } from 'amazon-cognito-identity-js';
import {
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  EDIT_USER_ERROR,
  EDIT_USER_SUCCESS,
  FORGOT_ERROR,
  FORGOT_SUCCESS,
  GET_AHA_BALANCE_ERROR,
  GET_AHA_BALANCE_SUCCESS,
  GET_PROFILE_ERROR,
  GET_PROFILE_SUCCESS,
  GET_USERS_ERROR,
  GET_USERS_FILTERED_ERROR,
  GET_USERS_FILTERED_SUCCESS,
  GET_USERS_LIMIT_ERROR,
  GET_USERS_LIMIT_SUCCESS,
  GET_USERS_ORG_ERROR,
  GET_USERS_ORG_SUCCESS,
  GET_USERS_SUCCESS,
  GET_USER_EDUCATION_ERROR,
  GET_USER_EDUCATION_SUCCESS,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  RELOAD_COGNITO_USER_ERROR,
  RELOAD_COGNITO_USER_SUCCESS,
  RESTORE_ERROR,
  RESTORE_SUCCESS,
  SAVE_DEPARTMENTS_ERROR,
  SAVE_DEPARTMENTS_SUCCESS,
  SIGNUP_ERROR,
  SIGNUP_RESTORE,
  SIGNUP_SUCCESS,
  START_CHANGE_PASSWORD,
  START_EDIT_USER,
  START_FORGOT,
  START_GET_AHA_BALANCE,
  START_GET_PROFILE,
  START_GET_USERS,
  START_GET_USERS_FILTERED,
  START_GET_USERS_LIMIT,
  START_GET_USERS_ORG,
  START_GET_USER_EDUCATION,
  START_LOGIN,
  START_RELOAD_COGNITO_USER,
  START_RESTORE,
  START_SAVE_DEPARTMENTS,
  START_SIGNUP,
  START_UPDATE_PROFILE_PHOTO,
  START_UPDATE_USER,
  START_UPDATE_USER_BIO,
  START_UPLOAD_ASSET_PROFILE_PHOTO,
  START_UPLOAD_PROFILE_PHOTO,
  START_USER,
  START_USER_INFO,
  START_USER_ROLES,
  START_USER_STATUS,
  UPDATE_PROFILE_PHOTO_ERROR,
  UPDATE_PROFILE_PHOTO_SUCCESS,
  UPDATE_USER_BIO_ERROR,
  UPDATE_USER_BIO_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  UPLOAD_ASSET_PROFILE_PHOTO_ERROR,
  UPLOAD_ASSET_PROFILE_PHOTO_SUCCESS,
  UPLOAD_PROFILE_PHOTO_ERROR,
  UPLOAD_PROFILE_PHOTO_SUCCESS,
  USER_ERROR,
  USER_INFO_ERROR,
  USER_INFO_SUCCESS,
  USER_ROLES_ERROR,
  USER_ROLES_SUCCESS,
  USER_STATUS_ERROR,
  USER_STATUS_SUCCESS,
  USER_SUCCESS
} from '../constants/userConstants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Auth } from 'aws-amplify';
import { ISignUpOrganizationRequest } from '../../interfaces/Signup';
import { UserResponse } from '../../interfaces/user/UserResponse';
import getUserParams from '../../interfaces/user/getUserParams';
import { getOrganizationInfo } from './orgsActions';
import { LOGOUT_ORG } from '../constants/orgsConstants';
import { LOGOUT_AHA } from '../constants/ahaConstants';
import { LOGOUT_CLUB } from '../constants/clubConstants';
import { LOGOUT_CONNECTION } from '../constants/connectionsConstants';
import { LOGOUT_EVENT } from '../constants/eventConstants';
import { LOGOUT_INVITES } from '../constants/inviteConstants';
import { LOGOUT_LOCATION } from '../constants/locationConstants';
import { LOGOUT_NOTIFICATION } from '../constants/notificationConstants';
import { LOGOUT_POST } from '../constants/postConstants';
import { LOGOUT_PROFILE } from '../constants/profileConstants';
import { LOGOUT_TEMPLATE_CATEGORY } from '../constants/templateCategoryConstants';
import { LOGOUT_TEMPLATE_CLUB } from '../constants/templateClubConstants';
import { refreshTokens, saveTokens } from './tokenActions';
import { LOGOUT_TOKENS } from '../constants/tokenConstants';
import { Backend, MicroServices, getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { getSignInFunction } from "../../helpers/amplifyHelper";
import { isEmail } from "../../guards/SignupOrg";
import { LOGOUT_CHAT } from '../constants/chatConstants';
import { ISignUpOrganizationBody } from '@vibe/sdk/dist/interfaces/AccessManager/signUpOrg';
import IResponseError from '@vibe/sdk/dist/interfaces/IResponseError';
import { IUpdateUserBody } from '@vibe/sdk/dist/interfaces/Admin/updateUser';
import { IUserGradeResponse, IUserResponse, getUsersResponse } from '@vibe/sdk/dist/interfaces/Admin/User';
import { LOGOUT_MODALITY } from '../constants/healerConstants';
import { IGetUserEducationResponse } from '@vibe/sdk/dist/interfaces/Roster/getUserEducation';
import { IEducation } from '@vibe/sdk/dist/interfaces/Roster/UserInfo';
import { getUsersFilter } from '@vibe/sdk/dist/interfaces/Admin/getUsers';
import { LOGOUT_GV } from '../constants/GVConstants';
import { LOGOUT_DASHBOARD } from '../constants/DashboardConstants';

toast.configure();

interface Error {
  code: string;
  name: string;
  message: string;
}

export const userLogin =
  (
    username: string,
    password: string,
    custom: any = false,
    token?: string,
  ): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<boolean> => new Promise((resolve, reject) => {
      dispatch({ type: START_LOGIN });
      console.log('callback on userActions.ts', token)


      getSignInFunction({ username, password, custom })
        .then((signInFunc: any) => {
          return signInFunc()
        })
        .then(async (res: any) => {
          const {
            idToken: IDToken,
            accessToken,
            refreshToken,
            user: response,
            expiration,
          } = res

          let orgId = JSON.parse(response.attributes['custom:organizations'])[0];
          const userRoles = JSON.parse(response.attributes["custom:roles"] || []);
          // console.log({orgId, userRoles})

          // console.log(response);

          // if (isEmail(response.username)) {
          //   try {
          //     response.username = response.getSignInUserSession()?.getIdToken()?.decodePayload()["cognito:username"];
          //   } catch (e) {
          //     console.log("unable to get username from cognitoUser object", e)
          //   }
          // }

          if (!IDToken) {
            // toast.error(
            //   response.message || 'Error: Please check the information'
            // );
            dispatch({
              type: LOGIN_ERROR,
              payload: {
                message: response.message || 'Error: Please check the information',
                obj: response,
              },
            });
            resolve(false);
          }

          let cont = true;
          console.log('Before accepting, checking callback', token)
          if (token && token.length > 0) {
            cont = false;
            console.log('Accepting invite')
            try {
              const dataSDK: getDataSDKResponse = await getDataSDK();
              const response = await dataSDK.sdk.Invite.acceptInvite(token, undefined, undefined, username, true)
              if (response.statusCode === 201) {
                cont = true
              } else {
                toast.error('Error: Please try later');
              }
            } catch (error) {
              cont = false
            }
          }

          if (cont === false) {
            return;
          }

          // const accessToken = response.getSignInUserSession()?.getAccessToken().getJwtToken() || ''
          // const IDToken = response.getSignInUserSession()?.getIdToken().getJwtToken() || ''
          // const refreshToken = response.getSignInUserSession().getRefreshToken().getToken() || ''

          dispatch(saveTokens(accessToken, IDToken, refreshToken))


          localStorage.setItem('user', JSON.stringify(response));

          dispatch(refreshTokens())
          dispatch(reloadUserRoles())

          // Do not comment this! -------------------------------------
          const orgsRelated = JSON.parse(
            response.attributes["custom:organizations"] || []
          ).map((org: string, idx: number) => {

            if (userRoles[idx].includes('ORGADMIN')) orgId = org;
            return [
              org,
              userRoles[idx] || [],
            ];
          })

          console.log('orgId login', orgId)

          dispatch(getOrganizationInfo(orgId));
          // -----------------------------------------------------------

          const user = {
            ...response,
            auth_key:
              accessToken,
          }
          // dispatch(getBalance(user.username))
          dispatch({
            type: LOGIN_SUCCESS,
            payload: user,
          });
          resolve(true);
        })
        .catch((error: Error) => {
          console.log('here', error)
          // toast.error(error.message || 'Error: Please try later');
          dispatch({
            type: LOGIN_ERROR,
            payload: {
              message: error.message || 'Error: Please try later',
              obj: error,
            },
          });
          resolve(false);
        });
    }
    );

export const getBalance = (username: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
    new Promise((resolve, reject) => {
      dispatch({ type: START_GET_AHA_BALANCE })
      Backend(
        MicroServices.AHA,
        `/person/balance?username=${username}`
      )
        .then((r) => {
          if (r.status === 200) {
            return r.json()
          } else {
            return Promise.reject(r)
          }
        })
        .then((results) => {
          if (results) {
            dispatch({ type: GET_AHA_BALANCE_SUCCESS, payload: results.aHa })
          }
        })
        .catch((error) => {
          console.log('Error catched aHa Balance', error);
          dispatch({ type: GET_AHA_BALANCE_ERROR, payload: error })
        })
    })

export const orgSignup =
  (
    body: ISignUpOrganizationBody,
    newUser = false,
  ): ThunkAction<Promise<string>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {

        dispatch({ type: START_SIGNUP });
        const { t } = i18n;

        getDataSDK()
          .then((dataSDK: getDataSDKResponse) => {
            return dataSDK.sdk.AccessManager.signUpOrganization(body, dataSDK.token, newUser);
          })
          .then((r) => {
            if (r.statusCode === 201) {
              dispatch({ type: SIGNUP_SUCCESS });
              resolve('Professional Organization Created');
            }
            else {
              const body = r.body as IResponseError;
              dispatch({ type: SIGNUP_ERROR, payload: body });
              resolve('There was an error./' + (body.error?.message || ''));
            }
          })
          .catch((error) => {
            dispatch({ type: SIGNUP_ERROR, payload: error });
          });
      });

export const restoreSignup =
  (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      dispatch({ type: SIGNUP_RESTORE });
    };

export const reloadSession =
  (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise(async (resolve, reject) => {

      try {
        const userStr = localStorage.getItem('user');
        if (typeof userStr === 'string') {
          const user = JSON.parse(userStr);

          // dispatch(getBalance(user.username))
          dispatch({
            type: LOGIN_SUCCESS,
            payload: user,
          });

          let orgId = JSON.parse(user.attributes['custom:organizations'])[0];
          const userRoles = JSON.parse(user.attributes["custom:roles"] || []);

          await dispatch(refreshTokens())
          await dispatch(reloadUserRoles())

          const orgsRelated = JSON.parse(
            user.attributes["custom:organizations"] || []
          ).map((org: string, idx: number) => {

            if (userRoles[idx].includes('ORGADMIN')) orgId = org;
            return [
              org,
              userRoles[idx] || [],
            ];
          })

          dispatch(getOrganizationInfo(orgId));
          resolve();
          return;
        }

        console.log('Not user logged')
        dispatch(userLogout());
        resolve();
        return;

      } catch (error) {
        console.log('Reload session error')
        dispatch(userLogout());
      }
    });

export const userLogout =
  (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      localStorage.clear();
      dispatch({
        type: LOGOUT
      });

      dispatch({
        type: LOGOUT_ORG,
      });

      dispatch({
        type: LOGOUT_CONNECTION,
      });

      dispatch({
        type: LOGOUT_CLUB,
      });

      dispatch({
        type: LOGOUT_EVENT,
      });

      dispatch({
        type: LOGOUT_POST,
      });

      dispatch({
        type: LOGOUT_NOTIFICATION,
      });

      dispatch({
        type: LOGOUT_AHA,
      });

      dispatch({
        type: LOGOUT_PROFILE,
      });

      dispatch({
        type: LOGOUT_LOCATION,
      });

      dispatch({
        type: LOGOUT_INVITES,
      });

      dispatch({
        type: LOGOUT_TEMPLATE_CLUB,
      });

      dispatch({
        type: LOGOUT_TEMPLATE_CATEGORY,
      });

      dispatch({
        type: LOGOUT_TOKENS,
      })

      dispatch({
        type: LOGOUT_CHAT,
      })

      dispatch({
        type: LOGOUT_MODALITY,
      })

      dispatch({
        type: LOGOUT_GV,
      })

      dispatch({
        type: LOGOUT_DASHBOARD,
      })
    };

export const userForgotPassword =
  (username: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve) => {
      dispatch({ type: START_FORGOT });
      const { t } = i18n;
      Auth.forgotPassword(username)
        .then((response) => {
          dispatch({ type: FORGOT_SUCCESS });
          resolve(true)
        })
        .catch((error) => {
          dispatch({
            type: FORGOT_ERROR,
            payload: error
          });
          resolve(false)
        });
    });

export const userRestorePassword =
  (
    username: string,
    code: string,
    password: string
  ): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve) => {
      dispatch({ type: START_RESTORE });
      const { t } = i18n;
      Auth.forgotPasswordSubmit(username, code, password)
        .then((response) => {
          dispatch({ type: RESTORE_SUCCESS });
          resolve(true);
        })
        .catch((error) => {
          console.error('RESTORE error', error);
          dispatch({ type: RESTORE_ERROR });
          resolve(false);
        });
    });

export const reloadUserRoles =
  (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve) => {
      dispatch({ type: START_RELOAD_COGNITO_USER });
      const { t } = i18n;
      Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {

          user.getUserAttributes((error, attributes) => {

            if (error) {
              dispatch({ type: RELOAD_COGNITO_USER_ERROR });
              //toast.error(t('toast_errorReloading'));
              console.log(error);
              resolve()
              return;
            }

            const rolesAttribute: ICognitoUserAttributeData | undefined = (attributes || []).find((attribute: ICognitoUserAttributeData) => attribute.Name === "custom:roles");

            if (rolesAttribute === undefined) {
              dispatch({ type: RELOAD_COGNITO_USER_ERROR });
              //toast.error(t('toast_errorReloadingRol'));
              console.log('User roles are not on cognito user.');
              resolve()
              return;
            }

            const oldUser = JSON.parse(localStorage.getItem('user') as string);
            oldUser.attributes["custom:roles"] = rolesAttribute.Value;
            localStorage.setItem('user', JSON.stringify(oldUser));

            dispatch({
              type: RELOAD_COGNITO_USER_SUCCESS,
              payload: (JSON.parse(rolesAttribute.Value) || []).flat(),
            });

            resolve()

          })
        })
        .catch(error => {
          //toast.error(t('toast_errorReloadingUser') + error);
          dispatch({ type: RELOAD_COGNITO_USER_ERROR });
        });
    });

export const changePassword =
  (
    oldPassword: string,
    newPassword: string
  ): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve) => {
      dispatch({ type: START_CHANGE_PASSWORD });
      const { t } = i18n;
      Auth.currentAuthenticatedUser()
        .then(user => Auth.changePassword(user, oldPassword, newPassword))
        .then(data => {
          //toast.success(t('toast_passwordChanged'));
          dispatch({ type: CHANGE_PASSWORD_SUCCESS });
          resolve();
        })
        .catch(error => {
          //toast.error(t('toast_errorCheckCurrent'));
          dispatch({ type: CHANGE_PASSWORD_ERROR });
        });
    });

export const getUsers =
  (_token?: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_GET_USERS });

        Backend(
          MicroServices.Admin,
          `/users`)
          .then((response) => response.json())
          .then((results) => {
            dispatch({
              type: GET_USERS_SUCCESS,
              payload: results.users,
            });
          })
          .catch((error) => {
            dispatch({
              type: GET_USERS_ERROR,
              payload: error,
            });
          });
      });

export const getUsersStatus =
  (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_USER_STATUS });
        Backend(
          MicroServices.Roster,
          `/users/status`)
          .then((response) => response.json())
          .then((results) => {
            dispatch({
              type: USER_STATUS_SUCCESS,
              payload: results.userStatus,
            });
          })
          .catch((error) => {
            dispatch({
              type: USER_STATUS_ERROR,
              payload: error,
            });
          });
      });

export const getUsersRoles =
  (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_USER_ROLES });
        Backend(
          MicroServices.Roster,
          `/users/roles`)
          .then((response) => response.json())
          .then((results) => {
            dispatch({
              type: USER_ROLES_SUCCESS,
              payload: results.userRoles,
            });
          })
          .catch((error) => {
            dispatch({
              type: USER_ROLES_ERROR,
              payload: error,
            });
          });
      });

export const getUsersWithLimit =
  ({
    page = 0,
    id = '',
    role = '',
    org = '',
    country = '',
    city = '',
    status = '',
    date = 'ASC',
    roleRequest = 'SA',
    lastEvaluatedKey = '',
    firstname = '',
    lastname = '',
    email = '',
    limit = 10000,
  }: getUserParams): ThunkAction<Promise<IUserResponse[]>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_GET_USERS_LIMIT });

        const toSend: getUsersFilter = {}

        if (id !== '') toSend.id = id;
        if (firstname !== '') toSend.firstname = firstname;
        if (lastname !== '') toSend.lastname = lastname;
        if (email !== '') toSend.email = email;
        if (role !== '') toSend.role = role;
        if (org !== '') toSend.org = org;
        if (country !== '') toSend.country = country;
        if (city !== '') toSend.city = city;
        if (status !== '') toSend.status = status;
        if (date !== '') toSend.date = date;
        if (limit !== 0) toSend.limit = `${limit}`;
        if (roleRequest !== '') toSend.roleRequest = roleRequest;

        getDataSDK()
          .then((dataSDK: getDataSDKResponse) => {
            return dataSDK.sdk.Admin.getUsers(toSend, dataSDK.token);
          })
          .then((results) => {
            if (results.statusCode === 200) {
              const result = (results.body as getUsersResponse);
              dispatch({
                type: GET_USERS_LIMIT_SUCCESS,
                payload: {
                  users: result.users,
                  lastEvaluatedKey: '',// result.lastEvaluatedKey,
                  page,
                },
              });
              resolve(result.users)
            }
            else {
              dispatch({
                type: GET_USERS_LIMIT_ERROR,
                payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
              });
            }
          })
          .catch((error) => {
            dispatch({
              type: GET_USERS_LIMIT_ERROR,
              payload: error,
            });
          });
      });

export const getUsersFiltered =
  ({
    id = '',
    role = '',
    org = '',
    country = '',
    city = '',
    status = '',
    date = 'ASC',
    roleRequest = '',
    firstname = '',
    lastname = '',
    email = '',
    limit = 0,
    lastEvaluatedKey = '',
    page = 0,
  }: getUserParams): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_GET_USERS_FILTERED });

        const filters: getUsersFilter = {}

        if (id) filters.id = id;
        if (org) filters.org = org;
        if (role) filters.role = role;
        if (country) filters.country = country;
        if (city) filters.city = city;
        if (status) filters.status = status;
        if (firstname) filters.firstname = firstname;
        if (lastname) filters.lastname = lastname;
        if (email) filters.email = email;
        if (date) filters.date = date;
        if (roleRequest) filters.roleRequest = roleRequest;

        getDataSDK()
          .then((dataSDK: getDataSDKResponse) => {
            return dataSDK.sdk.Admin.getUsers(filters, dataSDK.token);
          })
          .then((results) => {
            if (results.statusCode === 200) {
              const response = (results.body as any).users as IUserResponse[];
              dispatch({
                type: GET_USERS_FILTERED_SUCCESS,
                payload: response,
              });
            }
            else {
              dispatch({
                type: GET_USERS_FILTERED_ERROR,
                payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
              });
            }
          })
          .catch((error) => {
            dispatch({
              type: GET_USERS_FILTERED_ERROR,
              payload: error,
            });
          });
      });

export const getUsersOrg = (orgId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
    new Promise((resolve, reject) => {
      dispatch({ type: START_GET_USERS_ORG });

      getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
          return dataSDK.sdk.Admin.getUsers({
            org: orgId,
            roleRequest: 'OA'
          }, dataSDK.token);
        })
        .then((results) => {
          if (results.statusCode === 200) {
            const result = (results.body as getUsersResponse);
            dispatch({
              type: GET_USERS_ORG_SUCCESS,
              payload: result.users,
            });
          }
          else {
            dispatch({
              type: GET_USERS_ORG_ERROR,
              payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: GET_USERS_ORG_ERROR,
            payload: error,
          });
        });
    });

export const getUser = (
  id: string,
  roleRequest: 'SA' | 'OA' = 'OA'
): ThunkAction<Promise<UserResponse | IUserGradeResponse | undefined>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
    new Promise((resolve, reject) => {
      dispatch({ type: START_USER });

      getDataSDK().then((dataSDK) => {
        return dataSDK.sdk.Admin.getUser(id, roleRequest, dataSDK.token);
      })
        .then((results) => {
          if (results.statusCode === 200) {
            dispatch({
              type: USER_SUCCESS,
              payload: results.body,
            });
            resolve(results.body as IUserGradeResponse);
          }
          else {
            dispatch({
              type: USER_ERROR,
              payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: USER_ERROR,
            payload: error,
          });
        });
    });

export const getUserInfoProfile = (
  id: string,
  roleRequest: 'SA' | 'OA' = 'OA'
): ThunkAction<Promise<UserResponse | IUserGradeResponse | undefined>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
    new Promise((resolve, reject) => {
      dispatch({ type: START_GET_PROFILE });

      getDataSDK().then((dataSDK) => {
        return dataSDK.sdk.Admin.getUser(id, roleRequest, dataSDK.token);
      })
        .then((results) => {
          if (results.statusCode === 200) {
            dispatch({
              type: GET_PROFILE_SUCCESS,
              payload: results.body,
            });
            resolve(results.body as IUserGradeResponse);
          }
          else {
            dispatch({
              type: GET_PROFILE_ERROR,
              payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: GET_PROFILE_ERROR,
            payload: error,
          });
        });
    });

export const updateUserBio = (
  username: string,
  bio: string,
): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
    new Promise((resolve, reject) => {
      dispatch({ type: START_UPDATE_USER_BIO });

      getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
          return dataSDK.sdk.Roster.updateUserBio(username, bio, dataSDK.token);
        })
        .then((results) => {
          if (results.statusCode == 201) {
            dispatch({
              type: UPDATE_USER_BIO_SUCCESS,
              payload: results.body,
            });
            resolve(true);
          }
          else {
            dispatch({
              type: UPDATE_USER_BIO_ERROR,
              payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
            });
            resolve(false);
          }
        })
        .catch((error) => {
          dispatch({
            type: UPDATE_USER_BIO_ERROR,
            payload: error,
          });
        });
    });

export const updateUser = (
  id: string,
  form: IUpdateUserBody,
  roleRequest: 'SA' | 'OA' = 'SA'
): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
    new Promise((resolve, reject) => {
      dispatch({ type: START_UPDATE_USER });

      getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
          return dataSDK.sdk.Admin.editUser(id, form, roleRequest, dataSDK.token);
        })
        .then((results) => {
          if (results.statusCode == 200) {
            dispatch({
              type: UPDATE_USER_SUCCESS,
              payload: results.body,
            });
            resolve(true);
          }
          else {
            dispatch({
              type: UPDATE_USER_ERROR,
              payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
            });
            resolve(false);
          }
        })
        .catch((error) => {
          dispatch({
            type: UPDATE_USER_ERROR,
            payload: error,
          });
        });
    });

export const uploadAsset = (contentType: string, key: string, type: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_UPLOAD_ASSET_PROFILE_PHOTO });
    Backend(
      MicroServices.Roster,
      `/media/upload-asset`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "ContentType": contentType,
          "Key": key,
          "Type": type
        }),
      },
    )
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: UPLOAD_ASSET_PROFILE_PHOTO_SUCCESS,
          payload: results,
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
          type: UPLOAD_ASSET_PROFILE_PHOTO_ERROR,
          payload: error,
        });
        reject(error)
      })
  });

export const uploadImage = (url: string, contentType: string, image: any): ThunkAction<Promise<Response>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_UPLOAD_PROFILE_PHOTO });
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': decodeURIComponent(contentType)
      },
      body: image
    })
      .then((results) => {
        dispatch({
          type: UPLOAD_PROFILE_PHOTO_SUCCESS,
          payload: results,
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
          type: UPLOAD_PROFILE_PHOTO_ERROR,
          payload: error,
        });
        reject(error)
      })
  });

export const updateProfilePhoto = (image: string, username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_UPDATE_PROFILE_PHOTO });
    Backend(
      MicroServices.Admin,
      `/users/${username}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          "avatar": image,
        }),
      },
    )
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: UPDATE_PROFILE_PHOTO_SUCCESS,
          payload: results,
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_PROFILE_PHOTO_ERROR,
          payload: error,
        });
        reject(error)
      })
  });

export const saveUserDepartments = (username: string, departments: string[]): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_SAVE_DEPARTMENTS });
    Backend(
      MicroServices.Connections,
      `/profile/departments`,
      {
        method: 'POST',
        body: JSON.stringify({
          username: username,
          departments: departments
        }),
      },
    )
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: SAVE_DEPARTMENTS_SUCCESS,
          payload: departments,
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
          type: SAVE_DEPARTMENTS_ERROR,
          payload: error,
        });
        reject(error)
      })
  });

export const editUser = (username: string, roleRequest: 'OA' | 'SA', edits: any): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_EDIT_USER });
    Backend(
      MicroServices.Admin,
      `/users/${username}?roleRequest=${roleRequest}`,
      {
        method: 'PUT',
        body: JSON.stringify(edits),
      },
    )
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: EDIT_USER_SUCCESS
        })
      })
      .catch((error) => {
        dispatch({
          type: EDIT_USER_ERROR,
          payload: error,
        });
        reject(error)
      })
  });

export const getUserEducation = (
  username: string,
): ThunkAction<Promise<IEducation[] | IResponseError | undefined>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
    new Promise((resolve, reject) => {
      dispatch({ type: START_GET_USER_EDUCATION });

      getDataSDK().then((dataSDK) => {
        return dataSDK.sdk.Roster.getUserEducation(username, dataSDK.token);
      })
        .then((results) => {
          if (results.statusCode === 200) {
            dispatch({
              type: GET_USER_EDUCATION_SUCCESS,
              payload: (results.body as IGetUserEducationResponse).educationList,
            });
            resolve((results.body as IGetUserEducationResponse).educationList);
          }
          else {
            dispatch({
              type: GET_USER_EDUCATION_ERROR,
              payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: GET_USER_EDUCATION_ERROR,
            payload: error,
          });
        });
    });