import { IconProp } from "@fortawesome/fontawesome-svg-core";
import styled from "styled-components";
import { WHITE } from "../../../ClubIconsNavBar";
import { ClickableIcon } from "../../../SuperCenter/SuperAdminComponents/basicForSACenter";
import ReadMore from "./ReadMore";
import { useTranslation } from "react-i18next";

export const Title = styled.p`
    color: white;
    font-family: 'Inter';
    font-weight: 700;
    font-size: 22px;
`;

export const ChatClickerModal = styled.div`
    position: absolute;
    right: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;
    text-align: left;
    background: var(--t-blue);
    border-radius: 3px;
    color: white;
    height: auto;
    width: 320px;
    font-family: 'Inter';
`;

const ListOption = styled.li`
    display: flex;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
    margin-right: 30px;
    gap: 25px;
`;

const ListContent = styled(Title)`
    font-weight: 400;
    font-size: 18px;
`;

interface ListModalClickerProps {
    iconName: IconProp;
    content: string;
    onClickMethod?: (e: any) => void;
}

export const ListModalClicker = ({
    iconName,
    content,
    onClickMethod
}: ListModalClickerProps) => {
    return (
        <ListOption onClick={onClickMethod}>
            <ClickableIcon
                icon={iconName}
                size='lg'
                color={WHITE}
            />
            <ListContent>
                {content}
            </ListContent>
        </ListOption>
    )
}

//ChatUser

export const ChatUserWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 5px 10px 0px 10px;
`;

export const NoMessagesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 200px;
    align-items: center;
`;

export const AvatarNoMessages = styled.img`
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
    max-width: 70px;
    max-height: 70px;
    object-fit: cover;
    border-radius: 50%;
`;

const IncomingMessageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    justify-content: flex-start;
`;

const IncomingMessageWrapperRight = styled(IncomingMessageWrapper)`
    justify-content: flex-end;
`;

const IncomingMessageAvatar = styled.img`
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    object-fit: cover;
    border-radius: 50%;
`;

const MessageSquare = styled.div`
    width: auto;
    min-width: 200px;
    max-width: 70%;
    height: auto;
    max-height: 500px;
    background: ${({outgoing}:{outgoing: boolean}) => outgoing ? 'var(--t-blue)' : 'white'};
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding: 13px 15px 10px 15px;
    align-items: flex-start;
    overflow: hidden;
`;

const MessageSquareImage = styled.div`
    width: auto;
    min-width: 200px;
    max-width: 70%;
    height: auto;
    background: ${({outgoing}:{outgoing: boolean}) => outgoing ? 'var(--t-blue)' : 'white'};
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 13px 15px 10px 15px;
    align-items: flex-start;
    overflow: hidden;
`;

const RowFlex = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: space-between !important;
    align-items: center;
    width: 100%;
    min-width: 200px;
    height: auto;
    max-height: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const FromName = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: ${({active = false}:{active?: boolean}) => active ? 'white' : 'var(--t-blue)'};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const Time = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: ${({outgoing = false}:{outgoing?: boolean}) => outgoing ? 'var(--simple-gray)' : 'var(--t-blue)'};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const Group = styled(Time)`
    font-size: 16px;
    line-height: 18px;
`;

const MessageContent = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: ${({outgoing = false}:{outgoing?: boolean}) => outgoing ? 'white' : 'black'};
    text-align: left;
    text-overflow: ellipsis;
    max-height: 93%;
    overflow: hidden;
`;

const RowFlexTimeOutgoing = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    min-width: 200px;
    height: auto;
    max-height: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const AhaLogo = styled.img`
    width: 47px;
    height: 47px;
`;

const AhaWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--light-brown);
    width: 100%;
    border-radius: 8px;
    margin: 5px 0px 5px 0px;
`;

const AhaSent = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const ImageSent = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const CoverImg1 = styled.img`
  width: 100%;
  object-fit: cover;
`

const CoverImg2 = styled.img`
  width: 50%;
  object-fit: cover;
`

const CoverImg3 = styled.img`
  width: 33%;
  object-fit: cover;
`

interface IncomingMessageProps {
    avatar: string;
    fullName: string;
    timeName: string;
    textContent: string;
    readMore: boolean;
}

export const IncomingMessage = ({
    avatar,
    fullName,
    timeName,
    textContent,
    readMore,
}: IncomingMessageProps) => {
    return (
        <IncomingMessageWrapper>
            <IncomingMessageAvatar src={avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} />
            <MessageSquare outgoing={false}>
                <RowFlex>
                    <FromName>{fullName}</FromName>
                    <Time outgoing={false}>{timeName}</Time>
                </RowFlex>
                {readMore ? (
                    <ReadMore content={textContent} outgoing={false}/>
                ) : (
                    <MessageContent outgoing={false}>{textContent}</MessageContent>
                )}
            </MessageSquare>
        </IncomingMessageWrapper>
    )
}

interface AhaIncomingMessageProps extends IncomingMessageProps {
    ahaValue: number;
}

export const AhaIncomingMessage = ({
    avatar,
    fullName,
    timeName,
    textContent,
    readMore,
    ahaValue
}: AhaIncomingMessageProps) => {
    const { t, i18n } = useTranslation();
    return (
        <IncomingMessageWrapper>
            <IncomingMessageAvatar src={avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} />
            <MessageSquare outgoing={false}>
                <RowFlex>
                    <FromName>{fullName}</FromName>
                    <Time outgoing={false}>{timeName}</Time>
                </RowFlex>
                {readMore ? (
                    <ReadMore content={textContent} outgoing={false}/>
                ) : (
                    <MessageContent outgoing={false}>{textContent}</MessageContent>
                )}
                <AhaWrapper>
                    <AhaLogo src={"/aHa-logo_w.png"} />
                    <AhaSent>{`${fullName.split(' ')[0]} ${t('chat_otherSentAha')} ${ahaValue} aHa`}</AhaSent>
                </AhaWrapper>
            </MessageSquare>
        </IncomingMessageWrapper>
    )
}

interface OutgoingMessageProps {
    timeName: string;
    textContent: string;
    readMore: boolean;
}

export const OutgoingMessage = ({
    timeName,
    textContent,
    readMore,
}: OutgoingMessageProps) => {
    return (
        <IncomingMessageWrapperRight>
            <MessageSquare outgoing={true}>
                {readMore ? (
                    <ReadMore content={textContent} outgoing={true}/>
                ) : (
                    <MessageContent outgoing={true}>{textContent}</MessageContent>
                )}
                <RowFlexTimeOutgoing>
                    <Time outgoing={true}>{timeName}</Time>
                </RowFlexTimeOutgoing>
            </MessageSquare>
        </IncomingMessageWrapperRight>
    )
}

interface AhaOutgoingMessageProps extends OutgoingMessageProps {
    ahaValue: number;
}

export const AhaOutgoingMessage = ({
    timeName,
    textContent,
    readMore,
    ahaValue
}: AhaOutgoingMessageProps) => {
    const { t, i18n } = useTranslation();
    return (
        <IncomingMessageWrapperRight>
            <MessageSquare outgoing={true}>
                {readMore ? (
                    <ReadMore content={textContent} outgoing={true}/>
                ) : (
                    <MessageContent outgoing={true}>{textContent}</MessageContent>
                )}
                <AhaWrapper>
                    <AhaLogo src={"/aHa-logo_w.png"} />
                    <AhaSent>{`${t('chat_youSentAha')} ${ahaValue} aHa`}</AhaSent>
                </AhaWrapper>
                <RowFlexTimeOutgoing>
                    <Time outgoing={true}>{timeName}</Time>
                </RowFlexTimeOutgoing>
            </MessageSquare>
        </IncomingMessageWrapperRight>
    )
}

interface ImageOutgoingMessageProps {
    timeName: string;
    textContent: string;
    readMore: boolean;
    media: string[];
}

export const ImageOutgoingMessage = ({
    timeName,
    textContent,
    readMore,
    media
}: ImageOutgoingMessageProps) => {
    return (
        <IncomingMessageWrapperRight>
            <MessageSquareImage outgoing={true}>
                <ImageSent>
                    {
                        media.length > 0 &&
                            media.length === 1 ?
                            Object.keys(media).map(function (key: any) {
                                return <CoverImg1 src={media[key]} />;
                            })
                            : media.length === 2 ? Object.keys(media).map(function (key: any) {
                                return <CoverImg2 src={media[key]} />;
                            }) :
                                Object.keys(media).map(function (key: any) {
                                    return <CoverImg3 src={media[key]} />;
                                })
                    }
                </ImageSent>
                {readMore ? (
                    <ReadMore content={textContent} outgoing={true}/>
                ) : (
                    textContent &&
                    <MessageContent outgoing={true}>{textContent}</MessageContent>
                )}
                <RowFlexTimeOutgoing>
                    <Time outgoing={true}>{timeName}</Time>
                </RowFlexTimeOutgoing>
            </MessageSquareImage>
        </IncomingMessageWrapperRight>
    )
}

interface ImageIncomingMessageProps {
    avatar: string;
    fullName: string;
    timeName: string;
    textContent: string;
    readMore: boolean;
    media: string[];
}

export const ImageIncomingMessage = ({
    avatar,
    fullName,
    timeName,
    textContent,
    readMore,
    media
}: ImageIncomingMessageProps) => {
    return (
        <IncomingMessageWrapper>
            <IncomingMessageAvatar src={avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} />
            <MessageSquare outgoing={false}>
                <RowFlex>
                    <FromName>{fullName}</FromName>
                    <Time outgoing={false}>{timeName}</Time>
                </RowFlex>
                <ImageSent>
                    {
                        media.length > 0 &&
                            media.length === 1 ?
                            Object.keys(media).map(function (key: any) {
                                return <CoverImg1 src={media[key]} />;
                            })
                            : media.length === 2 ? Object.keys(media).map(function (key: any) {
                                return <CoverImg2 src={media[key]} />;
                            }) :
                                Object.keys(media).map(function (key: any) {
                                    return <CoverImg3 src={media[key]} />;
                                })
                    }
                </ImageSent>
                {readMore ? (
                    <ReadMore content={textContent} outgoing={false}/>
                ) : (
                    <MessageContent outgoing={false}>{textContent}</MessageContent>
                )}
            </MessageSquare>
        </IncomingMessageWrapper>
    )
}

//GroupChat

const RowFlexCheckBoxes = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: space-between;
`;

const OptionCheckBox = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
`;

interface CheckboxProps {
    active: boolean;
}

const CheckBox = styled.input`
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 2px solid var(--t-blue);
    width: 20px;
    height: 20px;
    opacity: ${(props: CheckboxProps) => props.active ? "1" : "0.5"};

    &:hover {
        background: rgba(85, 51, 120, 0.68);
    }

    &:checked {
        background-clip: content-box;
        background: var(--t-blue);
        box-shadow: inset 0 0 0 4px var(--light-brown);
    }
`;

const CheckboxName = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: var(--t-blue);
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    opacity: ${(props: CheckboxProps) => props.active ? "1" : "0.5"};
`;

interface CheckBoxesProps {
    options: Record<string, string>;
    selected: Record<string, string>;
    disabled: Record<string, string>;
    onChangeMethod?: (e: any) => void;
}

export const CheckBoxes = ({
    options,
    selected,
    disabled,
    onChangeMethod
}: CheckBoxesProps) => {
    return (
        <RowFlexCheckBoxes>
            {Object.keys(options).map((keyOption) => (
                <OptionCheckBox>
                    <CheckBox
                        active={!Object.keys(disabled).includes(keyOption)}
                        type="checkbox"
                        value={keyOption}
                        checked={Object.keys(selected).includes(keyOption)}
                        onChange={onChangeMethod}
                        disabled={Object.keys(disabled).includes(keyOption)}
                    />
                    <CheckboxName
                        active={!Object.keys(disabled).includes(keyOption)}
                    >
                        {keyOption}
                    </CheckboxName>
                </OptionCheckBox>
            ))}
        </RowFlexCheckBoxes>
    )
}