import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Outlet } from 'react-router-dom';
import styled from "styled-components";
import { ChatServiceInterface } from "../../../interfaces/chat/ChatServiceInterface";
import ChatFooter from "./components/ChatFooter";
import ChatHeader from "./components/ChatHeader";

const ChatCenter = ({ ChatServiceInstance }: { ChatServiceInstance: ChatServiceInterface }) => {
    const location = useLocation();
    const { id } = useParams();

    const [chatSelected, setChatSelected] = useState<boolean>(false);
    const [isMuted, setIsMuted] = useState<boolean>(false);

    useEffect(() => {
        if (location.pathname.includes('conversation')) {
            setChatSelected(true);
        }
        else{
            setChatSelected(false);
        }
    }, [])

    useEffect(() => {
        if (location.pathname.includes('conversation')) {
            setChatSelected(true);
        }
        else{
            setChatSelected(false);
        }
    }, [location.pathname])

    useEffect(() => {
        setIsMuted(ChatServiceInstance.activeConversation.muted);
    }, [ChatServiceInstance.activeConversation])

    useEffect(() => {
        if (location.pathname.includes('conversation')) {
            setChatSelected(true);
        }
        else{
            setChatSelected(false);
        }
        
        if (id && id !== '' && ChatServiceInstance.chatConnected) {
            ChatServiceInstance.getConversation(id)
            ChatServiceInstance.getConversationMedia(id)
        }
    }, [id, ChatServiceInstance.chatConnected])

    return (
        <>
            {(chatSelected) ? (
                <ChatGrid>
                    <ChatHeader
                        key={JSON.stringify(ChatServiceInstance.activeConversation.from)}
                        from={ChatServiceInstance.activeConversation.from}
                        muted={isMuted}
                        type={ChatServiceInstance.activeConversation.type}
                        ChatServiceInstance={ChatServiceInstance}
                    />
                    <ChatOutletWrapper>
                        <Outlet />
                    </ChatOutletWrapper>
                    <ChatFooter ChatServiceInstance={ChatServiceInstance} />
                </ChatGrid>
            ) : (
                <ChatWrapper>
                    <Outlet />
                </ChatWrapper>
            )}
        </>
    );
}

export default ChatCenter;

const ChatGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 70px calc(100vh - 210px) 40px;
    height: 100%;
    width: 100%;
`;

const ChatWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 80px);
    width: 100%;
`;

const ChatOutletWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto; 
    overflow-x: hidden;

    :hover::-webkit-scrollbar{
        display: block;
    }

    ::-webkit-scrollbar {
        width: 5px;
        display: none;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--lavender); 
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: var(--light-brown); 
    }
`;