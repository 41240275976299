import { useEffect, useState, useContext } from 'react'
import styled, { css } from "styled-components";
import {
    TitleContent, SubText, Imp, Accordion, ContainerMedia, MediaHeader, ContainerInputs, Inputs,
    Row2, Label, Input, Row1, TextArea, MediaFooter, ButtonSave, Row2_70, Row2_avatar, MediaOption
} from "../../components/signupsetuphealerind"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPen, faPlus, faPlusCircle, faImage } from "@fortawesome/pro-thin-svg-icons";
import { Link } from 'react-scroll'
import { ProcessInterface } from '../../containers/SetupHealer/SetupHealer';
import { IUpdateUserBody } from '@vibe/sdk/dist/interfaces/Admin/updateUser';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizationInfo, updateOrg, uploadImage } from '../../store/actions/orgsActions';
import { uploadAsset, updateUser, getUser, updateUserBio } from '../../store/actions/userActions';
import { RootState } from '../../store/store';
import { Line, LogoInterface, Scroll_Content_Signup } from '../signupsetuphealer/Introduction';
import ErrorField from '../BaseComponents/ErrorField';
import AddProfilePhoto from '../BaseComponents/ProfilePhoto/AddProfilePhoto';
import ModalMedia, { ModalMediaTabsType } from './ModalMedia';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import UserMedia from '@vibe/sdk/dist/interfaces/Roster/userMedia';
import UserPodcast from '@vibe/sdk/dist/interfaces/Roster/userPodcast';
import { BROWN, ClickableIcon, RED } from '../SuperCenter/Modalities/Modals/components';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { faLink, faPen } from '@fortawesome/pro-regular-svg-icons';
import { faSpotify, faApple } from '@fortawesome/free-brands-svg-icons';
import UserSocialMedia from '@vibe/sdk/dist/interfaces/Roster/userSocialMedia';
import ModalUnsaved from '../BaseComponents/Modals/ModalUnsaved';
import { toast } from 'react-toastify';
import { Header, IconPlus, ListServicesEmp, SubTitle, Title } from './MyServices';
import { Button } from 'antd';
import { ContextSuccess } from "../../Context";
import { IUpdateOrganizationBody } from '@vibe/sdk/dist/interfaces/Admin/updateOrganization';
import { getIdOrgUser } from '../../helpers/userHelper';
import TextEditor from '../BaseComponents/TextEditor';
import BlankTable from '../BaseComponents/BlankTable';
import { BaseFieldState, getFieldState } from '../BaseComponents/utils';
import ModalImageEditorCrop from "../ModalImageEditorCrop";

const Introduction = ({
    processData,
}: {
    processData: ProcessInterface
}) => {

    const dispatch = useDispatch();
    const currentSuccess = useContext(ContextSuccess);
    const user = useSelector((state: RootState) => state.getUser.user);
    const userInfo = useSelector((state: any) => state.getUser.userInfo);
    const username = useSelector((state: any) => state.getUser.userInfo.username);
    const organization = useSelector((state: RootState) => state.getOrgs.organizationInfo);

    const [newLogo, setNewLogo] = useState<boolean>(false);
    const [companyStatement, setCompanyStatement] = useState<BaseFieldState<string>>(getFieldState(''));
    const [logo, setLogo] = useState<BaseFieldState<LogoInterface>>(getFieldState({ file: '', fileLink: '' }));
    const [showModalMedia, setShowModalMedia] = useState(false)
    const [showModalMediaEdit, setShowModalMediaEdit] = useState(false)
    const [userPublicationEdit, setUserPublicationEdit] = useState<UserMedia>()
    const [userPodcastEdit, setUserPodcastEdit] = useState<UserPodcast>()
    const [tabActive, setTabActive] = useState(1)
    const [modalMediaDefaultTab, setModalMediaDefaultTab] = useState<ModalMediaTabsType>(1);
    const [userPublications, setUserPublications] = useState<UserMedia[]>([])
    const [userPodcasts, setUserPodcasts] = useState<UserPodcast[]>([])
    const [userSocialMedia, setUserSocialMedia] = useState<UserSocialMedia[]>([])
    const [deleteModal, setdeleteModal] = useState(false);
    const [deleteType, setDeleteType] = useState<'pub' | 'pod' | 'sm'>();
    const [deleteId, setDeleteId] = useState<string>();
    const [showModalImage, setShowModalImage] = useState(false);
    const [activeCrop, setActiveCrop] = useState(false);

    const reloadUserPublications = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Roster.getUserMediaByUsername(username!, dataSDK.token)
            })
            .then((response) => {
                if (response.statusCode === 200 && Array.isArray(response.body)) {
                    setUserPublications(response.body);
                }
            })
    }

    const reloadUserPodcasts = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Roster.getUserPodcastByUsername(username!, dataSDK.token)
            })
            .then((response) => {
                if (response.statusCode === 200 && Array.isArray(response.body)) {
                    setUserPodcasts(response.body);
                }
            })
    }

    const reloadUserSocial = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Roster.getUserSocialMedia(username!, dataSDK.token)
            })
            .then((response) => {
                if (response.statusCode === 200 && Array.isArray(response.body)) {
                    setUserSocialMedia(response.body);
                }
            })
    }

    const checkProcessed = (): boolean => {
        let complete = true;

        if (!user.biography && companyStatement.value?.length === 0) {
            complete = false;
        }

        return complete;
    }

    const CheckDataStep = (): boolean => {
        let hasErrors = false;

        if (!companyStatement.value || companyStatement.value?.length === 0) {
            hasErrors = true;
            setCompanyStatement({
                ...companyStatement,
                showError: true,
                error: 'Company statement is required'
            })
        } else {
            setCompanyStatement({
                ...companyStatement,
                showError: false,
                error: ''
            })
        }

        return hasErrors;
    }

    const handleSaveImage = () => new Promise((resolve, reject) => {
        const dataFile = URL.createObjectURL(logo.value?.file as any);
        let contentType: any;
        let file: any;
        fetch(dataFile)
            .then(res => res.blob())
            .then(blob => {
                contentType = dataFile.split(';')[0].split(':')[1];
                const extension = contentType.split('/')[1]
                file = new File([blob], 'profile' + userInfo?.username, { type: contentType });

                if (file) {
                    return dispatch<any>(uploadAsset(
                        file.type,
                        file.name + '.' + extension,
                        "profile_photo"
                    ))
                }
                else {
                    return Promise.reject(new Error("File not found"));
                }
            })
            .then((results: any) => {
                if (results.body) {

                    dispatch<any>(uploadImage(
                        results.body,
                        contentType,
                        file,
                    ))
                        .then((result: any) => {
                            if (result.status === 200) {
                                const urlImage = results.body.split('?')[0];
                                setLogo({
                                    ...logo,
                                    value: {
                                        ...logo.value as LogoInterface,
                                        fileLink: urlImage,
                                    }
                                })
                                resolve(urlImage);
                            }
                            else {
                                console.log(result)
                                processData.setProcess("off");
                            }
                        })
                        .catch((error: any) => {
                            console.log(error)
                            processData.setProcess("off");
                        })

                }
            })
    });

    const handleClick = async () => {

        if (CheckDataStep()) {
            processData.setProcess("off");
            return;
        }

        const updateData: IUpdateUserBody = {}
        const updateDataOrg: IUpdateOrganizationBody = {}

        let logoLink: string = '';

        if (newLogo && logo.value?.file !== '') {
            logoLink = await handleSaveImage() as string;
        }

        const promises: Promise<any>[] = [
            dispatch<any>(updateUser(
                userInfo.username,
                updateData,
                'OA',
            )),
            dispatch<any>(updateUserBio(
                userInfo.username,
                companyStatement.value || '',
            )),
        ];

        if (newLogo) {
            updateData.avatar = logoLink || '';
            updateDataOrg.logo = logoLink || '';
            promises.push(dispatch<any>(updateOrg(
                updateDataOrg,
                organization.id,
                organization.status === 'ACTIVE' ? false : true,
            )))
        }

        Promise.all(promises)
            .then((results: any) => {
                Promise.all([
                    dispatch(getUser(userInfo.username)),
                    dispatch(getOrganizationInfo(getIdOrgUser(user.rolesByOrg, user.organizations)))
                ])
            })
            .catch((error: any) => {
                currentSuccess?.setSuccessData(false, true, 'Error updating org', error)
            })
            .finally(() => {
                processData.setNewData(false);
                processData.setProcess("continue");
                processData.setTabsProcessed([...processData.tabsProcessed, 1])
            })
    }

    const handleDelete = (type: 'pub' | 'pod' | 'sm', id: string) => {
        setDeleteType(type);
        setDeleteId(id);
        setdeleteModal(true);
    }

    const onHideDelete = (type?: string) => {
        if (!type || type === 'discard' || !deleteType || !deleteId) {
            setDeleteType(undefined);
            setDeleteId(undefined);
            setdeleteModal(false);
            return;
        }
        if (deleteType === 'pub') {
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Roster.deleteUserMedia(deleteId, dataSDK.token);
                })
                .then((results) => {
                    if (Object.keys(results?.body || {}).length === 0) {
                        currentSuccess?.setSuccessData(true, true, 'Publication deleted succesfully');
                        setDeleteType(undefined);
                        setDeleteId(undefined);
                        setdeleteModal(false);
                        dispatch(getUser(userInfo.username));
                        reloadUserPublications();
                        return;
                    }
                    currentSuccess?.setSuccessData(false, true, 'Error deleting publication');
                    setDeleteType(undefined);
                    setDeleteId(undefined);
                    setdeleteModal(false);
                    return;
                })
        }
        if (deleteType === 'pod') {
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Roster.deleteUserPodcast(deleteId, dataSDK.token);
                })
                .then((results) => {
                    if (Object.keys(results?.body || {}).length === 0) {
                        currentSuccess?.setSuccessData(true, true, 'Podcast deleted succesfully');
                        setDeleteType(undefined);
                        setDeleteId(undefined);
                        setdeleteModal(false);
                        dispatch(getUser(userInfo.username));
                        reloadUserPodcasts();
                        return;
                    }
                    currentSuccess?.setSuccessData(false, true, 'Error deleting podcast');
                    setDeleteType(undefined);
                    setDeleteId(undefined);
                    setdeleteModal(false);
                    return;
                })
        }
        if (deleteType === 'sm') {
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Roster.deleteUserSocialMedia(username, deleteId as 'Facebook' | 'YouTube' | 'Instagram' | 'Twitter' | 'LinkedIn' | 'Pinterest' | 'Snapchat' | 'TikTok' | 'Zaahah', dataSDK.token)
                })
                .then((results) => {
                    if (Object.keys(results?.body || {}).length === 0) {
                        currentSuccess?.setSuccessData(true, true, 'Social media deleted succesfully');
                        setDeleteType(undefined);
                        setDeleteId(undefined);
                        setdeleteModal(false);
                        dispatch(getUser(userInfo.username));
                        reloadUserSocial();
                        return;
                    }
                    currentSuccess?.setSuccessData(false, true, 'Error deleting social media');
                    setDeleteType(undefined);
                    setDeleteId(undefined);
                    setdeleteModal(false);
                    return;
                })
        }
    }

    function handleCloseModal(e: any, type: any) {
        setShowModalImage(() => false);
    };

    function handleCropImage(e: any) {

        const dataFile = URL.createObjectURL(logo.value?.file as any);
        let contentType: any;
        let file: any;

        fetch(e)
            .then(res => res.blob())
            .then(blob => {

                // contentType = logo?.value?.file.type;
                contentType = dataFile.split(';')[0].split(':')[1];
                const extension = contentType.split('/')[1]
                file = new File([blob], 'Logo' + organization?.id, { type: contentType });

                setActiveCrop(true)
                setLogo({
                    ...logo,
                    value: {
                        fileLink: '',
                        file: file,
                    }
                })
            })
    };

    const showModalCrop = (e: BaseFieldState<LogoInterface>, isDelete = false) => {
        setLogo(e); 
        if(!isDelete) setShowModalImage(true); 
        setActiveCrop(false);
    }

    useEffect(() => {
        if (processData.process === 'loading' && processData.step === 1) handleClick();
    }, [processData.process])

    useEffect(() => {
        if (user.biography) setCompanyStatement(getFieldState(user.biography));
        if (user.avatar) setLogo(getFieldState({
            file: user.avatar,
            fileLink: user.avatar
        }));
        if (checkProcessed()) processData.setTabsProcessed([...processData.tabsProcessed, 1]);
    }, [user])

    useEffect(() => {
        if (username) {
            reloadUserPodcasts();
            reloadUserPublications();
            reloadUserSocial();
        }
    }, [username])

    const showModal = (tab: ModalMediaTabsType) => {
        setModalMediaDefaultTab(tab);
        setShowModalMedia(true);
    }

    const showEdit = (tab: ModalMediaTabsType) => {
        setModalMediaDefaultTab(tab);
        setShowModalMediaEdit(true);
    }

    const showEditPublication = (userPublicationE: UserMedia) => {
        setUserPublicationEdit(userPublicationE);
        setUserPodcastEdit(undefined);
        showEdit(1);
    }

    const showEditPodcast = (userPodcastE: UserPodcast) => {
        setUserPublicationEdit(undefined);
        setUserPodcastEdit(userPodcastE);
        showEdit(2);
    }

    const showEditSocialMedia = () => {
        setUserPublicationEdit(undefined);
        setUserPodcastEdit(undefined);
        showEdit(3);
    }

    const hideModal = (tab?: number) => {
        setShowModalMedia(false);
        setShowModalMediaEdit(false);
        if (tab) {
            setTabActive(tab)
        }
        if (username) {
            reloadUserPodcasts();
            reloadUserPublications();
            reloadUserSocial();
        }
    }

    const handleOnBlurDescription = (e: string) => {
        setCompanyStatement({
            ...companyStatement,
            value: e
        })
    }

    return (
        <Scroll_Content_Signup>
            <TitleContent theme={{ align: 'center' }}>
                Please share your healer credentials
            </TitleContent>
            <ContainerPhoto>
                <ModalImageEditorCrop preview={logo} handleSaveImage={handleCropImage} show={showModalImage} onHide={handleCloseModal} isProfilePhoto={true} />
                <AddProfilePhoto
                    title={'Add an avatar'}
                    logo={logo}
                    setLogo={(e: BaseFieldState<LogoInterface>, isDelete?: boolean) => showModalCrop(e, isDelete)}
                    setNewLogo={setNewLogo}
                    processData={processData}
                />
                <Row2>
                    <SubText>Personal statement<Imp>*</Imp></SubText>
                    <TextEditor
                        value={companyStatement.value || ''}
                        setValue={handleOnBlurDescription}
                        charLimit={1000}
                        minHeight={153}
                        showError={companyStatement.showError}
                        placeholder='Please tell us about the transforming healing you bring to your members'
                    />
                </Row2>
            </ContainerPhoto>
            <Line />

            <ContainerMediaIntro>
                <AddMedia>
                    <MediaOption onClick={() => showModal(1)}>
                        Add Publication
                        <IconDrop
                            icon={faPlus}
                            size='lg'
                            color={"black"}
                            style={{
                                fontSize: '20px'
                            }}
                        />
                    </MediaOption>
                    <MediaOption onClick={() => showModal(2)}>
                        Add Podcast
                        <IconDrop
                            icon={faPlus}
                            size='lg'
                            color={"black"}
                            style={{
                                fontSize: '20px'
                            }}
                        />
                    </MediaOption>
                    <MediaOption onClick={() => showModal(3)}>
                        Add Social Media
                        <IconDrop
                            icon={faPlus}
                            size='lg'
                            color={"black"}
                            style={{
                                fontSize: '20px'
                            }}
                        />
                    </MediaOption>
                </AddMedia>
                <Row2_70>
                    <TabsMedia>
                        <Tab theme={{ active: tabActive === 1 && 'active' }} onClick={() => setTabActive(1)} left>Publications</Tab>
                        <Tab theme={{ active: tabActive === 2 && 'active' }} onClick={() => setTabActive(2)}>Podcasts</Tab>
                        <Tab theme={{ active: tabActive === 3 && 'active' }} onClick={() => setTabActive(3)} right>Social media</Tab>
                    </TabsMedia>
                    <TabsContent>
                        {tabActive === 1 ?
                            userPublications && userPublications.length > 0 ? (
                                <TableMedia>
                                    <TableMediaRow>
                                        <TableMediaNameCell><CellTitle>Name</CellTitle></TableMediaNameCell>
                                        <TableMediaActionsCell><CellTitle>Actions</CellTitle></TableMediaActionsCell>
                                    </TableMediaRow>
                                    {userPublications.map((p) => (
                                        <TableMediaRow>
                                            <TableMediaNameCell>
                                                <CellLink href={p.link} target="_blank" rel="noopener noreferrer">{p.name}</CellLink>
                                            </TableMediaNameCell>
                                            <TableMediaActionsCell>
                                                <ClickableIcon
                                                    icon={faPen}
                                                    onClick={() => showEditPublication(p)}
                                                />
                                                <ClickableIcon
                                                    icon={faTrash}
                                                    color={RED}
                                                    onClick={() => handleDelete('pub', p.id)}
                                                />
                                            </TableMediaActionsCell>
                                        </TableMediaRow>
                                    ))}
                                </TableMedia>
                            ) : (
                                <ListServicesEmp>
                                    <Header>
                                        <Title>No info yet.</Title>
                                        <SubTitle>Here you will see the titles you upload</SubTitle>
                                    </Header>
                                </ListServicesEmp>
                            )

                            : tabActive === 2 ?
                                userPodcasts && userPodcasts.length > 0 ? (
                                    <TableMedia>
                                        <TableMediaRow>
                                            <TableMediaNameCell><CellTitle>Name</CellTitle></TableMediaNameCell>
                                            <TableMediaNameCell><CellTitle>Links</CellTitle></TableMediaNameCell>
                                            <TableMediaActionsCell><CellTitle>Actions</CellTitle></TableMediaActionsCell>
                                        </TableMediaRow>
                                        {userPodcasts.map((p) => (
                                            <TableMediaRow>
                                                <TableMediaLinkCell>
                                                    <CellName>{p.name}</CellName>
                                                </TableMediaLinkCell>
                                                <TableMediaLinkCellIcons>
                                                    {p.spotifyLink && (
                                                        <ClickableIcon
                                                            color="#000"
                                                            icon={faSpotify}
                                                            onClick={() => window.open(p.spotifyLink, '_blank', 'rel=noopener noreferrer')}
                                                        />
                                                    )}
                                                    {p.appleLink && (
                                                        <ClickableIcon
                                                            color="#000"
                                                            icon={faApple}
                                                            onClick={() => window.open(p.appleLink, '_blank', 'rel=noopener noreferrer')}
                                                        />
                                                    )}
                                                    {p.otherLink && (
                                                        <ClickableIcon
                                                            color="#000"
                                                            icon={faLink}
                                                            onClick={() => window.open(p.otherLink, '_blank', 'rel=noopener noreferrer')}
                                                        />
                                                    )}
                                                </TableMediaLinkCellIcons>
                                                <TableMediaActionsCell>
                                                    <ClickableIcon
                                                        icon={faPen}
                                                        onClick={() => showEditPodcast(p)}
                                                    />
                                                    <ClickableIcon
                                                        icon={faTrash}
                                                        color={RED}
                                                        onClick={() => handleDelete('pod', p.id)}
                                                    />
                                                </TableMediaActionsCell>
                                            </TableMediaRow>
                                        ))}
                                    </TableMedia>
                                ) : (
                                    <ListServicesEmp>
                                        <Header>
                                            <Title>No info yet.</Title>
                                            <SubTitle>Here you will see the podcasts you upload</SubTitle>
                                        </Header>
                                    </ListServicesEmp>
                                )
                                :
                                (userSocialMedia.length > 0) ? (
                                    <TableMedia>
                                        <TableMediaRow>
                                            <TableMediaNameCell><CellTitle>Social Network</CellTitle></TableMediaNameCell>
                                            <TableMediaActionsCell><CellTitle>Actions</CellTitle></TableMediaActionsCell>
                                        </TableMediaRow>
                                        {userSocialMedia.map((p) => (
                                            <TableMediaRow>
                                                <TableMediaLinkCell>
                                                    <CellLink href={p.link} target="_blank" rel="noopener noreferrer">{p.platform !== 'Personal' ? p.platform : 'Website'}</CellLink>
                                                </TableMediaLinkCell>
                                                <TableMediaActionsCell>
                                                    <ClickableIcon
                                                        icon={faPen}
                                                        onClick={showEditSocialMedia}
                                                    />
                                                    <ClickableIcon
                                                        icon={faTrash}
                                                        color={RED}
                                                        onClick={() => handleDelete('sm', p.platform)}
                                                    />
                                                </TableMediaActionsCell>
                                            </TableMediaRow>
                                        ))}
                                    </TableMedia>
                                )
                                    :
                                    (
                                        <ListServicesEmp>
                                            <Header>
                                                <Title>No info yet.</Title>
                                                <SubTitle>Here you will see your social media links</SubTitle>
                                            </Header>
                                        </ListServicesEmp>
                                    )
                        }

                    </TabsContent>
                </Row2_70>
            </ContainerMediaIntro>

            <ModalMedia
                showModal={showModalMedia}
                onHide={hideModal}
                defaultTab={modalMediaDefaultTab}
            />
            <ModalMedia
                showModal={showModalMediaEdit}
                onHide={hideModal}
                defaultTab={modalMediaDefaultTab}
                edit
                userPublication={userPublicationEdit}
                userPodcast={userPodcastEdit}
                userSocialMedia={userSocialMedia}
            />
            <ModalUnsaved show={deleteModal} onHide={onHideDelete} content="Do you want to delete?" deleteModal />
        </Scroll_Content_Signup>
    )
}

export const TableMedia = styled.div`
    width: 100%;
    overflow: scroll;
    text-align: left;
`;

export const TableMediaRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--simple-gray);
    padding: 10px 15px;
`;

export const TableMediaNameCell = styled.div`
    width: 100%;
`;

export const TableMediaLinkCell = styled.div`
    width: 100%;
`;
export const TableMediaLinkCellIcons = styled(TableMediaLinkCell)`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

export const TableMediaActionsCell = styled.div`
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
`;

export const CellName = styled.span`
    color: var(--black);
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 300;
`;

export const CellLink = styled.a`
    color: blue;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 300;
`;

export const CellTitle = styled.span`
    color: var(--black);
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
`;

export const TabsMedia = styled.div`
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 14px;
    border-radius: 7px 7px 0px 0px;
`

export const Tab = styled.div.attrs(props => ({
    className: props.theme.active,
}))`
    height: 40px;
    background-color: var(--simple-gray-2);
    width: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--black);
    font-family: 'Inter';
    cursor:pointer;

    ${({ left }: { left?: boolean, right?: boolean }) => left ? css`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    ` : ''}

    ${({ right }: { right?: boolean }) => right ? css`
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    ` : ''}

    &.active{
        background-color: var(--electric-yellow-2); 
        font-family: Inter-Bold;  
        color: white;
    }
`

export const TabsContent = styled.div`
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 0px 14px 14px 14px;
    border-radius: 0px 0px 7px 7px;
    height: calc(100% - 68px);
`

export const AddMedia = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: white;
    margin-bottom: 15px;
    padding: 10px;
    /* border: 1px solid var(--simple-gray); */
    cursor: pointer;
`

export const IconAvatar = styled.div`
    background-image: url(${props => props.theme.src});
    width: 48px;
    height: 48px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
`

export const Row2Icon = styled.div`
    width: 49%;
    display: flex;
    align-items: center;
`
export const LogoImg = styled.img`
    width: 100%;
    border: 1px solid var(--simple-gray);
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
`;

export const ButtonAdd = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: var(--absolute-zero);
    width: 130px;
    display: flex;
    justify-content: space-between;
    align-items: center; 
`

export const ContainerPhoto = styled.div`
    display: grid;
    grid-template-columns: 30% calc(70% - 20px);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
`

export const ContainerMediaIntro = styled.div`
    display: grid;
    grid-template-columns: 30% calc(70% - 20px);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    height: 100%;
`

interface DropPhotoProps {
    padding: string
}

export const DropPhoto = styled.div`
    width: 100%;
    margin: 10px 0 0px 0;
    text-align: initial;
    border: 1px dashed var(--simple-gray);
    border-radius: 12px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${(props: DropPhotoProps) => props.padding};

    cursor: pointer;
`

export const ContIconDrop = styled.div`
    width: 120px;
    height: 120px;
    border-radius: 12px;
    background-color: #F2F5FF;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const TextDrop = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding-left: 16px;
    width: calc(100% - 120px);
    color: var(--simple-brown-dark);
`

export const SubtextDrop = styled.span`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: var(--onyx);
    margin-top: 4px;
`

export const IconDrop = styled(FontAwesomeIcon)`
    color: black;
    font-size: 18px;
`;

export const TextBlue = styled.span`
    color: blue
`

export const IconMedia = styled.div`
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: relative;
    margin-right: 10px;
    background-image: url(./${props => props.theme.main}.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
`

export const Textarea = styled.div`
    border: 1px solid var(--simple-gray);
    font-family: 'Inter';
    background-color: var(--white);
    padding: 0 15px;
    border-radius: 8px;
    font-family: 'Inter';
    margin: 10px 0;
    font-size: 12px;
`

export default Introduction