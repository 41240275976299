import ClubViewNavBar from "../../components/ClubViewNavBar";
import ClubViewCover from "../../components/ClubViewCover";

import "./styles.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Backend, MicroServices, getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import ClubByCode from "../../interfaces/club/ClubByCode";
import { ClubCode } from "../../interfaces/club/ClubCode";
import { getClubsImAdv } from "../../store/actions/clubActions";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";
import { RootState } from "../../store/store";

const getFetchPromises = (urls: string[]) => urls.map((url) =>
  Backend(
    MicroServices.Club,
    url, 
    undefined, 
    true)
    .then((response) => response.json())
)

export interface UserRelatedToClub {
  username?: string;
  avatar?: string;
  fullname: string;
  email: string;
}

export interface Officer extends UserRelatedToClub {
  position: string;
}

export interface Advisor extends UserRelatedToClub {
  userType?: string;
  unregistered?: boolean;
};

export interface Member extends UserRelatedToClub { };

const Club = ({ ChatServiceInstance }: { ChatServiceInstance: ChatServiceInterface }) => {
  const [club, setClub] = useState();
  const [officers, setOfficers] = useState<Officer[]>([]);
  const [members, setMembers] = useState<Member[]>([]);
  const [advisors, setAdvisors] = useState<Advisor[]>([]);
  const [clubCode, setClubCode] = useState<ClubCode>();
  let { id } = useParams();

  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const userRoles = useSelector((state: any) => state.getUser.userRoles);
  const clubsImAdv = useSelector((state: RootState) => state.getClub.clubsImAdv);

  const dispatch = useDispatch();

  const organization = useSelector(
    (state: any) => state.getOrgs.organizationInfo
  );

  const setClubInfo = (isUserAdvisor: boolean) => {
    if (organization && organization.status?.startsWith('PEND')) {
      Promise.all(getFetchPromises([
        `/clubs/${id}?pending=true`,
        `/clubs/${id}/officers?username=${userInfo.username}`,
        `/clubs/${id}/members?username=${userInfo.username}`,
        `/clubs/${id}/advisors?username=${userInfo.username}`,
        ...(isUserAdvisor ? [`/clubs/${id}/code`] : [])
      ]))
        .then(([clubResults, officersResults, membersResults, advResults, codeResults]) => {
          setClub(clubResults);
          setOfficers(officersResults);
          setMembers(membersResults);
          const advisorsList = advResults.filter((adv: any) => adv.fullname !== undefined);
          const registeredAdvisors: any[] = advisorsList.filter((adv: any) => !('unregistered' in adv));
          const unregisteredAdvisors: any[] = advisorsList.filter((adv: any) => adv?.unregistered);
          const fullAdv = [...registeredAdvisors, ...(unregisteredAdvisors.filter(({email}: any) => registeredAdvisors.findIndex(({email: emailRegistered}: any) => emailRegistered === email) === -1))]
          setAdvisors(fullAdv);
          setClubCode('code' in codeResults ? codeResults : null);
        })

    }
    else {
      Promise.all(getFetchPromises([
        `/clubs/${id}`,
        `/clubs/${id}/officers?username=${userInfo.username}`,
        `/clubs/${id}/members?username=${userInfo.username}`,
        `/clubs/${id}/advisors?username=${userInfo.username}`,
        ...(isUserAdvisor ? [`/clubs/${id}/code`] : [])
      ]))
        .then(([clubResults, officersResults, membersResults, advResults, codeResults]) => {
          setClub(clubResults);
          setOfficers(officersResults);
          setMembers(membersResults);
          const advisorsList = advResults.filter((adv: any) => adv.fullname !== undefined && !adv.fullname.includes('undefined'));
          const registeredAdvisors: any[] = advisorsList.filter((adv: any) => !('unregistered' in adv));
          const unregisteredAdvisors: any[] = advisorsList.filter((adv: any) => adv?.unregistered);
          const fullAdv = [...registeredAdvisors, ...(unregisteredAdvisors.filter(({email}: any) => registeredAdvisors.findIndex(({email: emailRegistered}: any) => emailRegistered === email) === -1))]
          console.log(fullAdv);
          setAdvisors(fullAdv);
          setClubCode('code' in codeResults ? codeResults : null);
        })
    }
  }

  const reloadClub = () => {
    setClub(undefined);

    if (!id) return

    if (userRoles.includes("ORGADMIN")) {
      setClubInfo(true)
    } else {
      for (let club of clubsImAdv) {
        if (club.id === id) {
          setClubInfo(true)
          return;
        }
      }
      setClubInfo(false)
      return;
    }
  }

  const reloadMembers = () => {
    Backend(
      MicroServices.Club,
      `/clubs/${id}/members?username=${userInfo.username}`)
      .then((response) => response.json())
      .then((results: any[]) => {
        setMembers(results);
      })
      .catch(console.error)
  }

  const reloadOfficers = () => {
    Backend(
      MicroServices.Club,
      `/clubs/${id}/officers?username=${userInfo.username}`)
      .then((response) => response.json())
      .then((results: any[]) => {
        setOfficers(results);
      })
      .catch(console.error)
  }

  const reloadAdvisors = () => {
    Backend(
      MicroServices.Club,
      `/clubs/${id}/advisors?username=${userInfo.username}`)
      .then((response) => response.json())
      .then((results: any[]) => {
        setAdvisors(results.filter((adv: any) => adv.fullname !== undefined && !adv.fullname.includes('undefined')));
      })
      .catch(console.error)
  }

  useEffect(() => {
    if (id) {
      reloadClub();
    }
  }, [id]);

  return (
    <>
      <ClubViewNavBar
        club={club}
        officers={officers}
        members={members}
        advisors={advisors}
        clubCode={clubCode}
        reloadClub={reloadClub}
        reloadMembers={reloadMembers}
        reloadOfficers={reloadOfficers}
        reloadAdvisors={reloadAdvisors}
        ChatServiceInstance={ChatServiceInstance}
      />
    </>
  );
}

export default Club;
