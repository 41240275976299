import { Auth } from "aws-amplify";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { REFRESH_TOKEN_ERROR, REFRESH_TOKEN_SUCCESS, SAVE_TOKENS, START_REFRESH_TOKEN } from "../constants/tokenConstants";
import { userLogout } from "./userActions";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";

export const saveTokens = (accessToken: string, IDToken: string, refreshToken: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({
      type: SAVE_TOKENS,
      payload: {
        accessToken,
        IDToken,
        refreshToken
      }
    });

    localStorage.setItem(
      'token',
      accessToken
    );
    localStorage.setItem(
      'IDToken',
      IDToken
    );
    localStorage.setItem(
      'refreshToken',
      refreshToken
    );

  });

export const refreshTokens = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_REFRESH_TOKEN })
    Auth.currentSession().then((session) => {
      if (session.isValid()) {

        const accessToken = session.getAccessToken().getJwtToken()
        const IDToken = session.getIdToken().getJwtToken()
        const refreshToken = session.getRefreshToken().getToken()

        dispatch({
          type: REFRESH_TOKEN_SUCCESS,
          payload: {
            accessToken,
            IDToken,
            refreshToken
          }
        });

        localStorage.setItem(
          'token',
          accessToken
        );
        localStorage.setItem(
          'IDToken',
          IDToken
        );
        localStorage.setItem(
          'refreshToken',
          refreshToken
        );

        getDataSDK()
          .then((dataSDK: getDataSDKResponse) => {
            return dataSDK.sdk.AccessManager.refresh(refreshToken, dataSDK.token);
          })
          .then((results) => {
            const response = results.body as any;
            if (results.statusCode === 200) {
              dispatch(saveTokens(response.accessToken, response.idToken, response.refreshToken))
              localStorage.setItem(
                'token',
                response.accessToken
              );
              localStorage.setItem(
                'IDToken',
                response.idToken
              );
              localStorage.setItem(
                'refreshToken',
                response.refreshToken
              );
              resolve()
            }
            else {
              console.log('Error getting refresh token', response)
              resolve()
            }
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        dispatch({
          type: REFRESH_TOKEN_ERROR,
          payload: 'Session is no longer valid.'
        })
        dispatch(userLogout())
        resolve()
      }
    })
      .catch((e) => {
        dispatch({
          type: REFRESH_TOKEN_ERROR,
          payload: e
        })
        dispatch(userLogout())
        resolve()
      })
  });
