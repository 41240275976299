import { useEffect, useState, useContext } from 'react'
import styled, { css } from "styled-components";
import {
    Imp,
    Input, listAreasModal
} from "../signupsetuphealerind"
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faSquareCheck, faSearch } from '@fortawesome/pro-regular-svg-icons';
import ErrorField from '../BaseComponents/ErrorField';
import { RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { Loader } from '../BaseComponents/Loader';
import { ContextSuccess } from "../../Context";
import { castFBLink, castFBUsername, castIGLink, castIGUsername, castPLLink, castPLUsername, castSTLink, castSTUsername, castTTLink, castTTUsername, castTWLink, castTWUsername, castYTLink, castYTUsername } from '../../helpers/socialMedia/socialMediaHelper';
import UserPodcast from '@vibe/sdk/dist/interfaces/Roster/userPodcast';
import TextEditor from '../BaseComponents/TextEditor';
import UserMedia from '@vibe/sdk/dist/interfaces/Roster/userMedia';
import UserSocialMedia from '@vibe/sdk/interfaces/Roster/userSocialMedia';
import { onHideType } from '../BaseComponents/Modals/ModalUnsaved';
import { getUser } from '../../store/actions/userActions';

export type ModalMediaTabsType = 1 | 2 | 3;

interface ModalMediaProps {
    defaultTab?: ModalMediaTabsType
    showModal: boolean
    onHide: (tab?: number) => void
    edit?: boolean
    userPublication?: UserMedia,
    userPodcast?: UserPodcast,
    userSocialMedia?: UserSocialMedia[],
}

const ModalMedia = ({
    defaultTab,
    showModal,
    onHide,
    edit,
    userPublication,
    userPodcast,
    userSocialMedia,
}: ModalMediaProps) => {
    const username = useSelector((state: RootState) => state.getUser.userInfo?.username || '');
    const currentSuccess = useContext(ContextSuccess);
    const dispatch = useDispatch();
    
    const [tabActive, setTabActive] = useState<ModalMediaTabsType>(defaultTab ?? 1)
    const [title, setTitle] = useState<string>('');
    const [link, setLink] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [IG, setIG] = useState<string>('');
    const [FB, setFB] = useState<string>('');
    const [YT, setYT] = useState<string>('');
    const [TT, setTT] = useState<string>('');
    const [TW, setTW] = useState<string>('');
    const [ST, setST] = useState<string>('');
    const [PL, setPL] = useState<string>('');
    const [titleError, setTitleError] = useState<boolean>(false);
    const [linkError, setLinkError] = useState<boolean>(false);
    const [linkFormatError, setLinkFormatError] = useState<boolean>(false);
    const [descriptionError, setDescriptionError] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const [linkSp, setLinkSp] = useState<string>('');
    const [linkSpFormatError, setLinkSpFormatError] = useState<boolean>(false);

    const [linkAp, setLinkAp] = useState<string>('');
    const [linkApFormatError, setLinkApFormatError] = useState<boolean>(false);

    const [idEdit, setIdEdit] = useState<string>();

    const loadData = () => {
        if (!edit) return;
        if (defaultTab === 1 && userPublication) {
            setIdEdit(userPublication.id);
            setTitle(userPublication.name);
            setLink(userPublication.link);
            setDescription(userPublication.description ?? '');
        } else if (defaultTab === 2 && userPodcast) {
            setIdEdit(userPodcast.id);
            setTitle(userPodcast.name);
            setLinkSp(userPodcast.spotifyLink ?? '');
            setLinkAp(userPodcast.appleLink ?? '');
            setLink(userPodcast.otherLink ?? '');
            setDescription(userPodcast.description ?? '');
        } else if (defaultTab === 3 && userSocialMedia) {
            userSocialMedia.forEach((usm) => {
                if (usm.platform === 'Instagram') {
                    setIG(castIGUsername(usm.link));
                }
                else if (usm.platform === 'Facebook') {
                    setFB(castFBUsername(usm.link));
                }
                else if (usm.platform === 'YouTube') {
                    setYT(castYTUsername(usm.link));
                }
                else if (usm.platform === 'TikTok') {
                    setTT(castTTUsername(usm.link));
                }
                else if (usm.platform === 'Twitter') {
                    setTW(castTWUsername(usm.link));
                }
                else if (usm.platform === 'Substack') {
                    setST(castSTUsername(usm.link));
                }
                else if (usm.platform === 'Personal') {
                    setPL(castPLUsername(usm.link))
                }
            })
        }
    }

    useEffect(() => defaultTab && setTabActive(defaultTab), [defaultTab]);

    useEffect(() => {
        edit && loadData();
    }, [edit, userPublication, userPodcast, userSocialMedia]);

    const checkLinks = (): boolean => {
        let hasErrors = false;

        if (tabActive === 2) {
            if (link.length === 0 && linkSp.length === 0 && linkAp.length === 0) {
                setLinkError(true);
                hasErrors = true;
            }
            else if (link && !link.match(
                /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?\??([^\s=&]*=[^\s]*)*$/
            )) {
                setLinkError(false);
                setLinkFormatError(true);
                hasErrors = true;
            }
            else if (linkSp && !linkSp.match(
                /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?\??([^\s=&]*=[^\s]*)*$/
            )) {
                setLinkError(false);
                setLinkSpFormatError(true);
                hasErrors = true;
            }
            else if (linkAp && !linkAp.match(
                /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?\??([^\s=&]*=[^\s]*)*$/
            )) {
                setLinkError(false);
                setLinkApFormatError(true);
                hasErrors = true;
            }
            else {
                setLinkError(false);
                setLinkFormatError(false);
                setLinkSpFormatError(false);
                setLinkApFormatError(false);
            }
        }
        else if (tabActive === 1) {
            if (link.length === 0) {
                setLinkError(true);
                hasErrors = true;
            }
            else if (!link.match(
                /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?\??([^\s=&]*=[^\s]*)*$/
            )) {
                setLinkError(false);
                setLinkFormatError(true);
                hasErrors = true;
            } else {
                setLinkError(false);
                setLinkFormatError(false);
            }
        }

        return hasErrors;
    }

    const formHasErrors = () => {
        let hasErrors = false;

        setTitleError(false);
        setDescriptionError(false);
        setLinkError(false);
        setLinkFormatError(false);
        setLinkSpFormatError(false);
        setLinkApFormatError(false);

        if (tabActive === 1 || tabActive === 2) {

            if (title.length === 0) {
                setTitleError(true);
                hasErrors = true;
            } else {
                setTitleError(false);
            }

            if (description.length === 0) {
                setDescriptionError(true);
                hasErrors = true;
            } else {
                setDescriptionError(false);
            }

            hasErrors = checkLinks();
        }

        return hasErrors;
    }

    const onHideInner = (tab?: number) => {
        cleanForm();
        onHide(tab);
        dispatch(getUser(username));
    }

    const cleanForm = () => {
        setTitle('');
        setLink('');
        setDescription('');
        setIG('');
        setFB('');
        setYT('');
        setTT('');
        setTW('');
        setST('');
        setPL('');

        setTitleError(false);
        setDescriptionError(false);
        setLinkError(false);
        setLinkFormatError(false);

        setLinkSp('');
        setLinkSpFormatError(false);

        setLinkAp('');
        setLinkApFormatError(false);
    }

    const handleCreatePublication = () => getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
            return dataSDK.sdk.Roster.createUserMedia({
                link,
                name: title,
                username,
                description,
            })
        })
        .then((response) => {
            setLoading(false);
            if (response?.body && 'id' in response?.body) {
                currentSuccess?.setSuccessData(true, true, 'Publication created successfully');
                onHideInner(tabActive)
                return;
            }
            currentSuccess?.setSuccessData(false, true, 'Error creating publication');
        })

    const handleUpdatePublication = () => getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
            return dataSDK.sdk.Roster.updateUserMedia(idEdit ?? '', {
                link,
                name: title,
                username,
                description,
            })
        })
        .then((response) => {
            setLoading(false);
            if (response.statusCode === 201) {
                currentSuccess?.setSuccessData(true, true, 'Publication updated successfully');
                onHideInner(tabActive)
                return;
            }
            currentSuccess?.setSuccessData(false, true, 'Error updating publication');
        });
    
    const handleCreatePodcast = () => getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
            const toSend: Omit<UserPodcast, "id"> = {
                name: title,
                username,
                description,
                spotifyLink: linkSp,
                appleLink: linkAp,
                otherLink: link,
                createdAt: `${new Date().valueOf()}`
            }
            return dataSDK.sdk.Roster.createUserPodcast(toSend);
        })
        .then((response) => {
            setLoading(false);
            if (response?.body && 'id' in response?.body) {
                currentSuccess?.setSuccessData(true, true, 'Podcast created successfully');
                onHideInner(tabActive)
                return;
            }
            currentSuccess?.setSuccessData(false, true, 'Error creating podcast');
        });
    
    const handleUpdatePodcast = () => getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
            const toSend: Omit<UserPodcast, "id"> = {
                name: title,
                username,
                description,
                spotifyLink: linkSp,
                appleLink: linkAp,
                otherLink: link,
                createdAt: `${new Date().valueOf()}`
            }
            return dataSDK.sdk.Roster.updateUserPodcast(idEdit ?? '', toSend);
        })
        .then((response) => {
            setLoading(false);
            if (response.statusCode === 201) {
                currentSuccess?.setSuccessData(true, true, 'Podcast updated successfully');
                onHideInner(tabActive)
                return;
            }
            currentSuccess?.setSuccessData(false, true, 'Error updating podcast');
        });

    const handleSave = () => {
        if (formHasErrors()) return;
        setLoading(true);
        if (tabActive === 1) {
            if (edit) return handleUpdatePublication();
            else return handleCreatePublication();
        } else if (tabActive === 2) {
            if (edit) return handleUpdatePodcast();
            else return handleCreatePodcast();
        } else {
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    const promises = [];
                    if (IG.length > 0) {
                        promises.push(() => dataSDK.sdk.Roster.setUserSocialMedia(username, {
                            platform: 'Instagram',
                            link: castIGLink(IG),
                            createdAt: `${new Date().valueOf()}`,
                        }, dataSDK.token))
                    }
                    if (FB.length > 0) {
                        promises.push(() => dataSDK.sdk.Roster.setUserSocialMedia(username, {
                            platform: 'Facebook',
                            link: castFBLink(FB),
                            createdAt: `${new Date().valueOf()}`,
                        }, dataSDK.token))
                    }
                    if (YT.length > 0) {
                        promises.push(() => dataSDK.sdk.Roster.setUserSocialMedia(username, {
                            platform: 'YouTube',
                            link: castYTLink(YT),
                            createdAt: `${new Date().valueOf()}`,
                        }, dataSDK.token))
                    }
                    if (TT.length > 0) {
                        promises.push(() => dataSDK.sdk.Roster.setUserSocialMedia(username, {
                            platform: 'TikTok',
                            link: castTTLink(TT),
                            createdAt: `${new Date().valueOf()}`,
                        }, dataSDK.token))
                    }
                    if (TW.length > 0) {
                        promises.push(() => dataSDK.sdk.Roster.setUserSocialMedia(username, {
                            platform: 'Twitter',
                            link: castTWLink(TW),
                            createdAt: `${new Date().valueOf()}`,
                        }, dataSDK.token))
                    }
                    if (ST.length > 0) {
                        promises.push(() => dataSDK.sdk.Roster.setUserSocialMedia(username, {
                            platform: 'Substack',
                            link: castSTLink(ST),
                            createdAt: `${new Date().valueOf()}`,
                        }, dataSDK.token))
                    }
                    if (PL.length > 0) {
                        promises.push(() => dataSDK.sdk.Roster.setUserSocialMedia(username, {
                            platform: 'Personal',
                            link: castPLLink(PL),
                            createdAt: `${new Date().valueOf()}`,
                        }, dataSDK.token))
                    }
                    return Promise.all(promises.map((p) => p()));
                })
                .then((response) => {
                    setLoading(false);
                    if (response.map((p) => !p.body)) {
                        currentSuccess?.setSuccessData(true, true, 'Social media created successfully');
                        onHideInner(tabActive)
                        return;
                    }
                    currentSuccess?.setSuccessData(false, true, 'Error creating podcast');
                })
        }
    }

    const getTitle = (): string => {
        if (!edit) return "Add Publications, Podcast or Social Media";
        if (tabActive === 1) return "Update Publication";
        if (tabActive === 2) return "Update Podcast";
        return "Update Social Media";
    }

    const onHideUnsaved = (type: onHideType) => {
        switch (type) {
            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                onHideInner();
                break;

            case 'save':
                currentSuccess?.setModalsOff();
                handleSave();
                break;

            default:
                currentSuccess?.setModalsOff();
                break;
        }
    }

    const handleUnsaved = () => {
        currentSuccess?.setModalWarning(
            'UNSAVED',
            (type) => onHideUnsaved(type),
            'Do you want to save your changes?'
        );
    }

    return (
        <>
            <Modal className="modalSetup modalMedia" show={showModal}>
                <Modal.Header>
                    <Modal.Title>
                        <Title>{getTitle()}</Title>
                        <FontAwesomeIcon
                            onClick={handleUnsaved}
                            icon="times"
                            size='xl'
                            color={'black'}
                            className=""
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!edit && (
                        <Tabs>
                            <Tab theme={{ main: tabActive === 1 && 'tabActive' }} onClick={() => { setTabActive(1) }} left>Publications</Tab>
                            <Tab theme={{ main: tabActive === 2 && 'tabActive' }} onClick={() => { setTabActive(2) }}>Podcasts</Tab>
                            <Tab theme={{ main: tabActive === 3 && 'tabActive' }} onClick={() => { setTabActive(3) }} right>Social Media</Tab>
                        </Tabs>
                    )}
                    {tabActive === 1 ?
                        <ContainerTabs>
                            <Column>
                                <Label>Title<Imp>*</Imp></Label>
                                <Input value={title} onChange={(e) => setTitle(e.currentTarget.value)} />
                                {titleError && <ErrorField error="Title is required" />}
                            </Column>
                            <Column>
                                <Label>Link<Imp>*</Imp></Label>
                                <Input placeholder='https://' value={link} onChange={(e) => setLink(e.currentTarget.value)} />
                                {linkError && <ErrorField error="Link is required" />}
                                {linkFormatError && <ErrorField error="Link is not in the right format" />}
                            </Column>
                            <Column>
                                <Label>Description<Imp>*</Imp></Label>
                                <TextEditor
                                    value={description}
                                    setValue={setDescription}
                                    charLimit={1000}
                                    showError={descriptionError}
                                    placeholder='Please write a brief description of your title'
                                />
                            </Column>
                        </ContainerTabs>
                        :
                        tabActive === 2 ?
                            <ContainerTabs>
                                <Column>
                                    <Label>Title<Imp>*</Imp></Label>
                                    <Input value={title} onChange={(e) => setTitle(e.currentTarget.value)} />
                                    {titleError && <ErrorField error="Title is required" />}
                                </Column>
                                <ColumnLink>
                                    <Label>Links<Imp>*</Imp></Label>
                                    <Row>
                                        Spotify
                                        <Column>
                                            <Input placeholder='https://' value={linkSp} onChange={(e) => setLinkSp(e.currentTarget.value)} />
                                            {linkSpFormatError && <ErrorField error="Link is not in the right format" />}
                                        </Column>
                                    </Row>
                                    <Row>
                                        Apple
                                        <Column>
                                            <Input placeholder='https://' value={linkAp} onChange={(e) => setLinkAp(e.currentTarget.value)} />
                                            {linkApFormatError && <ErrorField error="Link is not in the right format" />}
                                        </Column>
                                    </Row>
                                    <Row>
                                        Other
                                        <Column>
                                            <Input placeholder='https://' value={link} onChange={(e) => setLink(e.currentTarget.value)} />
                                            {linkFormatError && <ErrorField error="Link is not in the right format" />}
                                        </Column>
                                    </Row>
                                    {linkError && <ErrorField error="At least one link is required" />}
                                </ColumnLink>
                                <Column>
                                    <Label>Description<Imp>*</Imp></Label>
                                    <TextEditor
                                        value={description}
                                        setValue={setDescription}
                                        charLimit={1000}
                                        minHeight={150}
                                        showError={descriptionError}
                                        placeholder='Please write a brief description of your podcast'
                                    />
                                </Column>
                            </ContainerTabs>
                            :
                            <ContainerTabs>
                                <TwoColumns>
                                    <Column>
                                        <ColumnSocialMedia>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.0027 5.8467C8.59743 5.8467 5.85075 8.594 5.85075 12C5.85075 15.406 8.59743 18.1533 12.0027 18.1533C15.4079 18.1533 18.1546 15.406 18.1546 12C18.1546 8.594 15.4079 5.8467 12.0027 5.8467ZM12.0027 16.0004C9.80212 16.0004 8.00312 14.2064 8.00312 12C8.00312 9.7936 9.79677 7.99955 12.0027 7.99955C14.2086 7.99955 16.0022 9.7936 16.0022 12C16.0022 14.2064 14.2032 16.0004 12.0027 16.0004ZM19.8412 5.595C19.8412 6.39295 19.1987 7.03024 18.4062 7.03024C17.6085 7.03024 16.9713 6.38759 16.9713 5.595C16.9713 4.80241 17.6138 4.15977 18.4062 4.15977C19.1987 4.15977 19.8412 4.80241 19.8412 5.595ZM23.9157 7.05166C23.8247 5.12909 23.3856 3.42608 21.9775 2.02298C20.5747 0.619882 18.8721 0.180743 16.9499 0.0843468C14.9689 -0.0281156 9.03112 -0.0281156 7.05008 0.0843468C5.1333 0.175388 3.43068 0.614526 2.02253 2.01763C0.614389 3.42073 0.180703 5.12373 0.0843279 7.0463C-0.0281093 9.02778 -0.0281093 14.9669 0.0843279 16.9483C0.175349 18.8709 0.614389 20.5739 2.02253 21.977C3.43068 23.3801 5.12794 23.8193 7.05008 23.9157C9.03112 24.0281 14.9689 24.0281 16.9499 23.9157C18.8721 23.8246 20.5747 23.3855 21.9775 21.977C23.3803 20.5739 23.8193 18.8709 23.9157 16.9483C24.0281 14.9669 24.0281 9.03314 23.9157 7.05166ZM21.3564 19.0744C20.9388 20.1241 20.1303 20.9327 19.0755 21.3558C17.496 21.9824 13.7481 21.8378 12.0027 21.8378C10.2572 21.8378 6.50396 21.977 4.92984 21.3558C3.88042 20.9381 3.07195 20.1294 2.64897 19.0744C2.02253 17.4946 2.16709 13.7458 2.16709 12C2.16709 10.2542 2.02789 6.50006 2.64897 4.92558C3.06659 3.87593 3.87507 3.06728 4.92984 2.6442C6.50931 2.01763 10.2572 2.16222 12.0027 2.16222C13.7481 2.16222 17.5014 2.02298 19.0755 2.6442C20.1249 3.06192 20.9334 3.87058 21.3564 4.92558C21.9828 6.50541 21.8383 10.2542 21.8383 12C21.8383 13.7458 21.9828 17.4999 21.3564 19.0744Z" fill="var(--default-dark)" />
                                            </svg>
                                            <Input placeholder='@' value={IG} onChange={(e) => setIG(e.currentTarget.value)} />
                                        </ColumnSocialMedia>
                                        <ColumnSocialMedia>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.2136 13.375L17.8359 9.39352H13.9452V6.8098C13.9452 5.72055 14.4887 4.65879 16.2312 4.65879H18V1.26898C18 1.26898 16.3949 1 14.8602 1C11.656 1 9.56167 2.90695 9.56167 6.35906V9.39352H6V13.375H9.56167V23H13.9452V13.375H17.2136Z" fill="var(--default-dark)" />
                                            </svg>
                                            <Input placeholder='Link' value={FB} onChange={(e) => setFB(e.currentTarget.value)} />
                                        </ColumnSocialMedia>
                                        <ColumnSocialMedia>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M22.5403 6.50346C22.2873 5.51804 21.5418 4.74196 20.5953 4.47858C18.8796 4 12 4 12 4C12 4 5.12044 4 3.40474 4.47858C2.45821 4.742 1.71273 5.51804 1.45971 6.50346C1 8.28958 1 12.0162 1 12.0162C1 12.0162 1 15.7428 1.45971 17.5289C1.71273 18.5143 2.45821 19.258 3.40474 19.5214C5.12044 20 12 20 12 20C12 20 18.8796 20 20.5953 19.5214C21.5418 19.258 22.2873 18.5143 22.5403 17.5289C23 15.7428 23 12.0162 23 12.0162C23 12.0162 23 8.28958 22.5403 6.50346ZM9.74998 15.3996V8.63271L15.5 12.0163L9.74998 15.3996Z" fill="var(--default-dark)" />
                                            </svg>
                                            <Input placeholder='@' value={YT} onChange={(e) => setYT(e.currentTarget.value)} />
                                        </ColumnSocialMedia>
                                        <ColumnSocialMedia>
                                            <FontAwesomeIcon width="24" height="24" icon="globe" color="var(--default-dark)"/>
                                            <Input placeholder='Link' value={PL} onChange={(e) => setPL(e.currentTarget.value)} />
                                        </ColumnSocialMedia>
                                    </Column>
                                    <Column>
                                        <ColumnSocialMedia>
                                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.321 5.1344C16.5879 4.29394 16.1472 3.19805 16.1472 2H15.2285C15.4651 3.3095 16.2446 4.43326 17.321 5.1344Z" fill="var(--default-dark)" />
                                                <path d="M8.82144 11.9049C7.23002 11.9049 5.93555 13.2005 5.93555 14.7932C5.93555 15.9031 6.56655 16.8689 7.4852 17.3518C7.14187 16.8782 6.93772 16.2978 6.93772 15.6662C6.93772 14.0735 8.23219 12.7779 9.82364 12.7779C10.1206 12.7779 10.4082 12.8289 10.6773 12.9125V9.40198C10.3989 9.36479 10.1159 9.34155 9.82364 9.34155C9.77258 9.34155 9.72618 9.3462 9.67514 9.3462V12.0395C9.4014 11.9559 9.11837 11.9049 8.82144 11.9049Z" fill="var(--default-dark)" />
                                                <path d="M19.9236 6.67603V9.34609C18.142 9.34609 16.4903 8.77496 15.1447 7.80906V14.7976C15.1447 18.285 12.3099 21.1269 8.82087 21.1269C7.47536 21.1269 6.22265 20.6997 5.19727 19.9799C6.35255 21.2197 7.99964 21.9999 9.82307 21.9999C13.3074 21.9999 16.1469 19.1626 16.1469 15.6706V8.68205C17.4924 9.64795 19.1441 10.219 20.9258 10.219V6.78288C20.5778 6.78288 20.2437 6.74569 19.9236 6.67603Z" fill="var(--default-dark)" />
                                                <path d="M15.1456 14.7977V7.80911C16.4912 8.77501 18.1429 9.34614 19.9245 9.34614V6.67608C18.8945 6.45788 17.9899 5.90063 17.3218 5.1344C16.2454 4.43326 15.4704 3.3095 15.2245 2H12.7098L12.7051 15.7775C12.6495 17.3192 11.3782 18.5591 9.82393 18.5591C8.85884 18.5591 8.00977 18.0808 7.48085 17.3564C6.56219 16.8688 5.9312 15.9076 5.9312 14.7977C5.9312 13.205 7.22567 11.9094 8.81708 11.9094C9.11402 11.9094 9.40168 11.9605 9.67079 12.0441V9.35079C6.25598 9.42509 3.5 12.2298 3.5 15.6707C3.5 17.3331 4.14492 18.847 5.19812 19.98C6.2235 20.6998 7.47621 21.127 8.82172 21.127C12.3061 21.127 15.1456 18.2851 15.1456 14.7977Z" fill="var(--default-dark)" />
                                            </svg>
                                            <Input placeholder='@' value={TT} onChange={(e) => setTT(e.currentTarget.value)} />
                                        </ColumnSocialMedia>
                                        <ColumnSocialMedia>
                                            <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M22.033 4.48592C22.0482 4.68279 22.0482 4.87969 22.0482 5.07656C22.0482 11.0812 17.099 18 8.0533 18C5.26648 18 2.67767 17.2547 0.5 15.961C0.895953 16.0031 1.27663 16.0172 1.68781 16.0172C3.98727 16.0172 6.10405 15.3 7.79441 14.0766C5.63197 14.0344 3.81978 12.7266 3.19541 10.9266C3.5 10.9687 3.80455 10.9969 4.12438 10.9969C4.56598 10.9969 5.00764 10.9406 5.41878 10.8422C3.16498 10.4203 1.47458 8.59218 1.47458 6.38437V6.32814C2.12938 6.66564 2.89086 6.87657 3.69791 6.90467C2.37303 6.08902 1.50505 4.69685 1.50505 3.12184C1.50505 2.27811 1.74866 1.50467 2.17508 0.829666C4.59641 3.58591 8.23602 5.38588 12.3172 5.58279C12.2411 5.24529 12.1954 4.89376 12.1954 4.54219C12.1954 2.03904 14.3883 0 17.1141 0C18.5304 0 19.8095 0.548436 20.708 1.43437C21.8197 1.23751 22.8857 0.857802 23.8299 0.337502C23.4643 1.39221 22.6877 2.27815 21.6674 2.84061C22.6573 2.74222 23.6167 2.48904 24.4999 2.13752C23.83 3.03748 22.9924 3.83901 22.033 4.48592Z" fill="var(--default-dark)" />
                                            </svg>
                                            <Input placeholder='@' value={TW} onChange={(e) => setTW(e.currentTarget.value)} />
                                        </ColumnSocialMedia>
                                        <ColumnSocialMedia>
                                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M20.9991 5.40625H0V8.24275H20.9991V5.40625Z" fill="var(--default-dark)" />
                                                <path d="M0 10.8125V24.0004L10.4991 18.1107L21 24.0004V10.8125H0Z" fill="var(--default-dark)" />
                                                <path d="M20.9991 0H0V2.83603H20.9991V0Z" fill="var(--default-dark)" />
                                            </svg>
                                            <Input placeholder='@' value={ST} onChange={(e) => setST(e.currentTarget.value)} />
                                        </ColumnSocialMedia>
                                    </Column>
                                </TwoColumns>
                            </ContainerTabs>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <ButtonCancel onClick={handleUnsaved}>Cancel</ButtonCancel>
                    <ButtonSave onClick={handleSave}>
                        {loading ? <Loader /> : 'Save'}
                    </ButtonSave>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const TwoColumns = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 20px 24px;
    padding-bottom: 10px;
    gap: 20px;
`;

const Column = styled.div`
    width: 100%;
`;

const ColumnLink = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const ColumnSocialMedia = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding-bottom: 10px;
`;

const Row = styled.div`
    gap: 10px;
    align-items: center;
    width: 100%;
    display: grid;
    grid-template-columns: 0.2fr 1fr;
    grid-row-gap: 3px;
    justify-items: flex-start;
    padding-left: 20px;
`;

const Title = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    width: 100%;
    text-align: left;
`

const ButtonCancel = styled.div`
    width: 49%;
    text-align: center;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 1px solid var(--dark-blue);
    cursor: pointer;
`
const ButtonSave = styled.div`
    width: 49%;
    text-align: center;
    background-color: var(--dark-blue);
    color: white;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`

const ContainerTabs = styled.div`
    overflow: auto;
    height: 56vh;
    display: flex;
    flex-direction: column;
    padding: 25px;
    gap: 15px;
`

const Search = styled.div`
`

const GroupList = styled.div`
`

const ItemList = styled.div`
    display: flex;
    justify-content: start;
    padding: 3px 0px 3px 0;
    align-items: center;
    font-family: 'Inter';
    font-size: 14px;
`

const List = styled.div`
    padding: 15px;
`

const TitleId = styled.div`
    background-color: var(--simple-gray-2);
    width: 600px;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    font-family: 'Inter-Bold';
`

const Label = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    text-align: left;
    padding-bottom: 10px;
    display: flex;
`

const Textarea = styled.div`
    border: 1px solid var(--simple-gray);
    padding: 0 15px;
    border-radius: 8px;
    font-family: 'Inter';
`

const Tabs = styled.div`
    display: flex;
    justify-content: space-evenly;
    border-radius: 8px;
    overflow: hidden;
    padding: 0 24px;
`

const Tab = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    background-color: var(--simple-gray-2);
    width: 33%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: 'Inter';
    cursor: pointer;

    ${({ left }: { left?: boolean, right?: boolean }) => left ? css`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    ` : ''}

    ${({ right }: { right?: boolean }) => right ? css`
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    ` : ''}


    &.tabActive{
        background-color: #E89F4D;
        color: white;
        font-family: 'Inter-Bold';
    }
`

const RadioIcon = styled(FontAwesomeIcon)`
    font-size: 19px;
    margin-right: 20px;
    color: #1414e6;
`;

const SearchIcon = styled(FontAwesomeIcon)`
    font-size: 12px;
    margin-right: 20px;
    color: var(--black);
    margin-left: 20px;
`;

const InputIcon = styled.div`
    display: flex;
    justify-content: space-between;
    width: 567px;
    background-color: white;
    border-radius: 20px;
    align-items: center;
    border: 1px solid var(--simple-gray);
    height: 40px;
    margin: 15px; 
    
    & ${Input}{
        border: 0;
        height: 38px;
    } 
`

export const InputPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0;
    font-size: 15px;
    font-family: 'Inter';
    background-color: transparent;
`;

export const InputOnlyPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--black);
    padding-left: 20px;

    &::placeholder{
        color: var(--black);
        font-family: 'Inter-Bold'
        
    }
`;



export default ModalMedia;