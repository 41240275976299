import { Component } from "react";
import styled from "styled-components";

export const LeftPanel = styled.div`
    width: 100%;
    height: 100%;
    z-index: 2;

    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white;

    // overflow-y: auto;
    // overflow-x: hidden;

    overflow: hidden;

    border-top-right-radius: ${({isHealerBulletin = false}:{isHealerBulletin?: boolean}) => isHealerBulletin ? '8px' : '0px'};
    border-top-left-radius: ${({isHealerBulletin = false}:{isHealerBulletin?: boolean}) => isHealerBulletin ? '8px' : '0px'};

    padding: 15px 5px;
    position: relative;
`;

export const CentralBase = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    align-items: center;
    justify-content: center;
    position: relative;

    width: 100%;
    height: 100%;
    padding: ${({isHealerBulletin = false}:{isHealerBulletin?: boolean}) => isHealerBulletin ? '0px 25px 0px 25px' : '15px 25px 0px 25px'};
    overflow: hidden;

`;

export const CentralBaseChat = styled(CentralBase)`
    padding: 0px;
`;

export const RightPanel = styled.div`
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;

    align-items: center;
    //background-color: ${({isHealerBulletin = false}:{isHealerBulletin?: boolean}) => isHealerBulletin ? 'transparent': 'var(--t-off-white)'};

    height: 100%;
    width: 100%;
    overflow-y: auto;
    padding: ${({isHealerBulletin = false}:{isHealerBulletin?: boolean}) => isHealerBulletin ? '0px': '15px 15px 15px 0px'};
`;

export const PrincipalContainer = styled.div`
    display: grid;
    grid-template-columns: 25% 50% 25%;
    // grid-template-rows: ${({isHealerBulletin = false}:{isHealerBulletin?: boolean}) => isHealerBulletin ? 'calc(100vh - 260px)': 'calc(100vh - 80px)'};
    margin-top: ${({isHealerBulletin = false}:{isHealerBulletin?: boolean}) => isHealerBulletin ? '100px': '80px'};
    overflow: ${({isHealerBulletin = false}:{isHealerBulletin?: boolean}) => isHealerBulletin ? 'scroll': 'hidden'};
    padding: ${({isHealerBulletin = false}:{isHealerBulletin?: boolean}) => isHealerBulletin ? '0px 136px': '0px'};

    height: ${({isHealerBulletin = false}:{isHealerBulletin?: boolean}) => isHealerBulletin ? 'calc(100% - 80px)': 'calc(100% - 80px)'};
    a {
        display: contents;
        text-decoration: none;
    }
`;

export const ContainerAppLayout = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
`;