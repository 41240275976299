import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContextSuccess } from '../../../Context';
import { RootState } from '../../../store/store';
import { AvatarHeaderHome, ButtonSignupHome, ButtonsHeaderHome, CircleStatusHeaderHome, HeaderHomeContainer, IconClicker, ImageContainerHeaderHome, LogoHeaderHome, MenuButtonWrapper, TextClicker } from './styles';
import MenuHome from '../MenuHome';
import { NotificationChatCircle } from '../../Header';
import Menu from '../../Menu';
import { IUserGradeResponse } from '@vibe/sdk/interfaces/Admin/User';
import { ChatServiceInterface } from '../../../interfaces/chat/ChatServiceInterface';
import { contactUs } from '../../../AppConfig';

interface HeaderHomeProps {
    ChatServiceInstance?: ChatServiceInterface;
}


const HeaderHome = (props: HeaderHomeProps) => {

    const currentSuccess = useContext(ContextSuccess);
    const navigate = useNavigate();
    const location = useLocation();

    const isLoggedIn = useSelector((state: RootState) => state.getUser.isLoggedIn);
    const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);

    const [activeSignup, setActiveSignup] = useState<boolean>(false);
    const [activeSearchResults, setActiveSearchResults] = useState<boolean>(false);
    const [activeMenu, setActiveMenu] = useState<boolean>(false);
    const [activeChat, setActiveChat] = useState(false);
    const [activeMenuLogged, setActiveMenuLogged] = useState(false);

    const handleToggle = () => {
        setActiveMenuLogged(false);
    };

    const handleNotification = () => {
        navigate('../../../../../app?notifications=true');
    };

    const handleImage = () => {
        if (isLoggedIn) {
            setActiveMenuLogged(true);
        }
    };

    useEffect(() => {
        if (location.pathname.includes('signup')) {
            setActiveSignup(true);
        }
        else {
            setActiveSignup(false);
        }

        if (location.pathname.includes('modalities-services') || location.pathname.includes('modality')) {
            setActiveSearchResults(true);
        }
        else {
            setActiveSearchResults(false);
        }

        if (location.pathname.includes('chat')) {
            setActiveChat(true);
        }
    }, [location.pathname])

    return (
        <HeaderHomeContainer active={activeSearchResults}>
            <LogoHeaderHome onClick={() => navigate('../../../../../')} />

            <ButtonsHeaderHome>
                {!isLoggedIn ? (
                    <>
                        <TextClicker
                            onClick={() => contactUs ? /* navigate('/contact') Open login even if contact is true */ currentSuccess?.setLoginModal(true, true) : currentSuccess?.setLoginModal(true, true)}
                        >
                            Log in
                        </TextClicker>

                        <ButtonSignupHome
                            active={activeSignup}
                            onClick={() => navigate(contactUs ? '/contact' : '../../../../../signup-healer')}
                        >
                            Sign up
                        </ButtonSignupHome>

                        <MenuButtonWrapper onClick={() => setActiveMenu(true)}>
                            <TextClicker>Menu</TextClicker>
                            <IconClicker
                                icon="bars"
                                size="lg"
                                color="black"
                            />
                        </MenuButtonWrapper>
                        <MenuHome show={activeMenu} hide={() => setActiveMenu(false)} />
                    </>
                ) : (
                    process.env.REACT_APP_SHOW_CHAT === 'true' &&
                    <IconClicker
                        icon="message"
                        color={activeChat ? "var(--lavender)" : "black"}
                        size="xl"
                        onClick={() => navigate('/chat')}
                    />
                )}

                {(!activeChat && props.ChatServiceInstance?.newMessages !== 0 && isLoggedIn) &&
                    <NotificationChatCircle key={props.ChatServiceInstance?.newMessages || 0}
                        width={(props.ChatServiceInstance?.newMessages || 0) > 99 ? '25px' : (props.ChatServiceInstance?.newMessages || 0) > 10 ? '20px' : '17px'}
                    >
                        {(props.ChatServiceInstance?.newMessages || 0) > 99 ? '99+' : props.ChatServiceInstance?.newMessages}
                    </NotificationChatCircle>
                }

                {(isLoggedIn) &&
                    <ImageContainerHeaderHome onClick={handleImage}>
                        <CircleStatusHeaderHome status={props.ChatServiceInstance?.connectedUser.status || 'available'} />
                        <AvatarHeaderHome
                            src={user.avatar ? user.avatar : `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png`}
                            alt="profile-pic"
                        />
                    </ImageContainerHeaderHome>
                }

                {(isLoggedIn) &&
                    <Menu
                        show={activeMenuLogged}
                        onHide={handleToggle}
                        activeNotification={handleNotification}
                        ChatServiceInstance={props.ChatServiceInstance}
                    />
                }
            </ButtonsHeaderHome>
        </HeaderHomeContainer>
    )
}

export default HeaderHome;