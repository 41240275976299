import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getNameUser } from "../../helpers/userHelper";
import Property1AdminProperty2NoProperty3W from "../Property1AdminProperty2NoProperty3W";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { tabInterface } from "../FeedCenterSection";
import { useContext, useEffect, useState } from "react";
import { IconName } from "@fortawesome/pro-regular-svg-icons";
import { RootState } from "../../store/store";
import ModalComingSoon from "../BaseComponents/Modals/ModalComingSoon";
import IPaymentInfo from "../../interfaces/user/IPaymentINfo";
import { ContextSuccess } from "../../Context";
import Contributor from "@vibe/sdk/interfaces/Modalities/contributors/Contributor";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";

interface LeftMenuProps {
  screen: string;
}

export interface tabInterfaceAdmin extends tabInterface {
  needPay: boolean;
  icon?: IconName;
  route: string;
}

const AdminLeftMenu = ({ screen }: LeftMenuProps) => {
  const { t, i18n } = useTranslation();

  const currentSuccess = useContext(ContextSuccess);

  const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
  const user = useSelector((state: RootState) => state.getUser.user);
  const userRoles = useSelector((state: RootState) => state.getUser.userRoles);
  const organization = useSelector((state: RootState) => state.getOrgs.organizationInfo);
  const userPayment: IPaymentInfo = useSelector((state: RootState) => state.getUser.paymentInfo);

  const navigate = useNavigate();

  const [tabActive, setTabActive] = useState<number>(1);
  const [tabComing, setTabComing] = useState<number>(3);
  const [showComing, setShowComing] = useState<boolean>(false);
  // const [isContributor, setIsContributor] = useState<boolean>(false);
  const [tabs, setTabs] = useState<tabInterfaceAdmin[]>([]);

  const tabsAdmin: tabInterfaceAdmin[] = [
    {
      id: 8,
      label: 'Dashboard',
      comingSoon: false,
      needPay: false,
      icon: 'building',
      route: '../../admin/dashboard'
    },
    {
      id: 1,
      label: 'My Organizations',
      comingSoon: false,
      needPay: false,
      icon: 'building',
      route: '../../admin'
    },
    {
      id: 6,
      label: 'My Services',
      comingSoon: false,
      needPay: false,
      icon: 'user-shield',
      route: '../../admin/services'
    },
    {
      id: 2,
      label: 'My Teams',
      comingSoon: false,
      needPay: false,
      icon: 'users',
      route: '../../admin/teams'
    },
    {
      id: 3,
      label: 'My Communities',
      comingSoon: false,
      needPay: false,
      icon: 'users-rectangle',
      route: '../../admin/communities'
    },
    // {
    //   id: 7,
    //   label: 'My Articles',
    //   comingSoon: false,
    //   needPay: true,
    //   icon: 'newspaper',
    //   route: '../../admin/articles'
    // },
    {
      id: 4,
      label: 'My Members',
      comingSoon: false,
      needPay: true,
      icon: 'user',
      route: '../../admin/members'
    },
    {
      id: 5,
      label: 'My Events',
      comingSoon: process.env.REACT_APP_SHOW_EVENT === 'true' ? false : true,
      needPay: false,
      icon: 'calendar',
      route: '../../admin/events'
    },
  ]

  const tabsSuper: tabInterfaceAdmin[] = [
    {
      id: 1,
      label: 'Dashboard',
      comingSoon: false,
      needPay: false,
      route: '../../superadmin'
    },
    {
      id: 2,
      label: 'Seekers',
      comingSoon: false,
      needPay: false,
      route: '../../superadmin/seekers'
    },
    {
      id: 4,
      label: 'Healers',
      comingSoon: false,
      needPay: false,
      route: '../../superadmin/healers'
    },
    {
      id: 14,
      label: 'Referrals',
      comingSoon: false,
      needPay: false,
      route: '../../superadmin/referrals'
    },
    // {
    //   id: 15,
    //   label: 'Contributors',
    //   comingSoon: false,
    //   needPay: false,
    //   route: '../../superadmin/contributors'
    // },
    // {
    //   id: 16,
    //   label: 'Articles',
    //   comingSoon: false,
    //   needPay: false,
    //   route: '../../superadmin/articles'
    // },
    {
      id: 3,
      label: 'Posts',
      comingSoon: true,
      needPay: false,
      route: ''
    },
    {
      id: 5,
      label: 'Internal Teams',
      comingSoon: false,
      needPay: false,
      route: '../../superadmin/internal-teams'
    },
    {
      id: 6,
      label: 'Communities',
      comingSoon: false,
      needPay: false,
      route: '../../superadmin/communities'
    },
    {
      id: 7,
      label: 'Template Teams',
      comingSoon: false,
      needPay: false,
      route: '../../superadmin/template-teams'
    },
    {
      id: 8,
      label: 'Modalities & Services',
      comingSoon: false,
      needPay: false,
      route: '../../superadmin/modalities'
    },
    {
      id: 9,
      label: 'Benefits',
      comingSoon: false,
      needPay: false,
      route: '../../superadmin/benefits'
    },
    {
      id: 10,
      label: 'Conditions Treated',
      comingSoon: false,
      needPay: false,
      route: '../../superadmin/therapeutic-areas'
    },
    {
      id: 11,
      label: 'Interests',
      comingSoon: false,
      needPay: false,
      route: '../../superadmin/interests'
    },
    {
      id: 12,
      label: 'Event Types',
      comingSoon: false,
      needPay: false,
      route: '../../superadmin/event-types'
    },
    {
      id: 13,
      label: 'Countries',
      comingSoon: false,
      needPay: false,
      route: '../../superadmin/countries'
    },
    {
      id: 14,
      label: 'Languages',
      comingSoon: false,
      needPay: false,
      route: '../../superadmin/lang'
    },
  ]

  const handleTab = (tab: tabInterfaceAdmin) => {
    if (tab.comingSoon) {
      setShowComing(true);
      setTabComing(tab.id);
    }
    else if (!showTabPay(tab)) currentSuccess?.setModalPayInfo('Ops! To see this feature you need to have an active subscription.');
    else {
      setTabActive(tab.id);
      navigate(tab.route);
    }
  }

  const showTabPay = (tab: tabInterfaceAdmin) => {
    let show = true;
    if (tab.needPay && !userPayment.hasActiveSubscription) show = false;
    return show;
  }

  const getSize = (type: 'bottom' | 'left'): string => {
    if (5 === tabComing) {
      //Events in admin Center
      return type === 'bottom' ? '30vh' : '100px';
    }
    //Posts in Super admin Center
    return type === 'bottom' ? '30vh' : '100px';
  }

  // const checkContributor = () => {
  //   getDataSDK()
  //     .then((dataSDK: getDataSDKResponse) => {
  //       return dataSDK.sdk.Modalities.getContributor(userInfo?.username || '', dataSDK.token);
  //     })
  //     .then((results) => {
  //       if (results.statusCode === 200) setIsContributor(true)
  //       else setIsContributor(false)
  //     })
  //     .catch((error) => {
  //       console.error(error)
  //       setIsContributor(false);
  //     });
  // }

  useEffect(() => {
    //checkContributor();
    if (userRoles.includes('SUPER')) setTabs(tabsSuper);
    // else {
    //   if (isContributor) setTabs(tabsAdmin);
    //   else setTabs(tabsAdmin.filter(t => t.id !== 7));
    // }
    else setTabs(tabsAdmin);
  }, []);

  useEffect(() => {
    if (userRoles.includes('SUPER')) setTabs(tabsSuper);
    // else {
    //   if (isContributor) setTabs(tabsAdmin);
    //   else setTabs(tabsAdmin.filter(t => t.id !== 7));
    // }
    else setTabs(tabsAdmin);
  }, [userRoles/*, isContributor*/]);

  return (
    <SideBarAdmin>
      <ProfileInfoAdmin>
        <Property1AdminProperty2NoProperty3W
          logo={organization.logo}
        />
        <LabelNameAdmin>{getNameUser(user, userInfo, userRoles, organization)}</LabelNameAdmin>
      </ProfileInfoAdmin>

      {(showComing) &&
        <ModalComingSoon
          title={'Coming Soon!'}
          onHide={setShowComing}
          bottom={getSize('bottom')}
          left={getSize('left')}
        />}

      <ButtonsAdmin>
        {tabs.map(tab => (
          <TabAdmin
            active={tabActive === tab.id}
            onClick={() => handleTab(tab)}
          >
            {tab.icon && (
              <FontAwesomeIcon
                icon={tab.icon}
                size='lg'
                color={tabActive === tab.id ? "var(--t-off-white)" : "var(--t-blue)"}
              />
            )}

            {tab.label}
          </TabAdmin>
        ))}
      </ButtonsAdmin>
    </SideBarAdmin >
  );
};

interface TabAdminProps {
  active: boolean;
}

const TabAdmin = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 25px;
  border-radius: 60px 0px 0px 60px;
  cursor: pointer;

  gap: 10px;

  color: ${(props: TabAdminProps) => props.active ? 'white' : 'var(--t-blue)'};
  font-family: 'Inter';
  font-size: 14px;
  font-weight: normal;
  background-color: ${(props: TabAdminProps) => props.active ? 'var(--t-blue)' : 'transparent'};
  position: relative;
`;

const LabelNameAdmin = styled.div`
  color: var(--t-blue);
  font-family: 'Inter-bold';
  font-size: 14px;
  font-weight: bold;
`;

const ProfileInfoAdmin = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 100%;
`;

const ButtonsAdmin = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  width: 100%;
  min-height: calc(100vh - 200px);
  gap: 10px;
  padding-bottom: 55px;
`;

const SideBarAdmin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  padding-top: 25px;
  padding-bottom: 110px;
  height: 100%;
  width: 130px;
  min-width: 130px;
  gap: 15px;
`;

export default AdminLeftMenu;
