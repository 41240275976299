import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import './styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useEffect, useMemo } from "react";
import useGrades from "../../hooks/useGrades";
import useGender from "../../hooks/useGender";

function Index(props: any) {
  const { t, i18n } = useTranslation();

  const { grades, setCountry: setCountryGrades } = useGrades();
  const { genders, setCountry: setCountryGenders } = useGender();

  /*const genderList: any = {
    'M': 'Male',
    'F': 'Female'
  };*/ //@audit-issue change to avoid O and P gender @comment Dismissed since this is just for formatting

  const countryCode = useSelector((state: RootState) => state.getOrgs.organizationInfo?.countryCode);

  useEffect(() => {
    if (countryCode) {
      setCountryGrades(countryCode);
      setCountryGenders(countryCode);
    }
  }, [countryCode]);

  const gradesParsed = useMemo(() =>
    (props && props?.informationClub && props?.informationClub?.gradesAllowed && grades)
      ? props.informationClub.gradesAllowed.map((grade: number) =>
        grades.find(({ id }) => id === Number(grade))?.listLabel || grade
      ).join(', ')
      : '',
    [props, grades]
  )

  const gendersParsed = useMemo(() =>
    (props && props?.informationClub && props?.informationClub?.gendersAllowed && genders)
      ? props?.informationClub?.gendersAllowed.map((gender: string) =>
        genders.find(({ id }) => id === gender)?.label || undefined
      ).filter((g: any) => g).join(', ')
      : '',
    [props, genders]
  )

  const getClubGenders = (): string[] => {
    let genders: string[] = [];
    if (props && props?.informationClub && props?.informationClub?.gendersAllowed && genders) {
      if (props.informationClub.gendersAllowed?.includes('M')) genders = genders.concat([t('setupSchool__male')])
      if (props.informationClub.gendersAllowed?.includes('F')) genders = genders.concat([t('setupSchool__female')])
      if (props.informationClub.gendersAllowed?.includes('P')) genders = genders.concat([t('setupSchool__preferNot')])
      if (props.informationClub.gendersAllowed?.includes('O')) genders = genders.concat([t('label_otherGender')])
    }

    return genders
  }

  return (
    <div className="slide-holder">
      <div className="text-container">
        <h2>{t('club__generalInformation')}</h2>
        <table>
          {props.informationClub?.gradesAllowed &&
            <tr>
              <td>{t('club__gradesAllowed')}</td>
              <td>{gradesParsed}</td>
            </tr>
          }
          <tr>
            <td>{t('club__gnedersAllowed')}</td>
            <td>
              {(getClubGenders() || []).join(', ')}
            </td>
          </tr>
          <tr>
            <td>{t('club__maximunMembers')}</td>
            <td>{(props.informationClub?.maximumNumberMembers === 0 || props.informationClub?.maximumNumberMembers === undefined) ? 'No Limit' : props.informationClub?.maximumNumberMembers}</td>
          </tr>
        </table>
      </div>
    </div>
  )
  /*return (
    <div className="carousel-container">
      <Carousel
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        infiniteLoop
        useKeyboardArrows
        transitionTime={1000}
      >
        <div className="slide-holder">
          <div className="text-container">
            <h2>{t('club__generalInformation')}</h2>
            <table>
              {props.informationClub?.gradesAllowed &&
                <tr>
                  <td>{t('club__gradesAllowed')}</td>
                  <td>{gradesParsed}</td>
                </tr>
              }
              <tr>
                <td>{t('club__gnedersAllowed')}</td>
                <td>
                  {gendersParsed}
                  {(getClubGenders() || []).join(', ')}
                </td>
              </tr>
              <tr>
                <td>{t('club__maximunMembers')}</td>
                <td>{(props.informationClub?.maximumNumberMembers === 0 || props.informationClub?.maximumNumberMembers === undefined) ? 'No Limit' : props.informationClub?.maximumNumberMembers}</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="slide-holder">
          <div className="text-container">
            <h2>{t('club__clubRequirements')}</h2>
            <table>
              <tr>
                <td>{t('club__applicationRequired')}</td>
                <td>{props.informationClub?.applicationRequired ? 'Yes' : 'No'}</td>
              </tr>

              <tr>
                <td>{t('club__duesRequired')}</td>
                <td>{props.informationClub?.clubDuesRequired ? 'Yes' : 'No'}</td>
              </tr>
              <tr>
                <td>{t('club__duesFee')}</td>
                <td>{props.informationClub?.clubDues} </td>
              </tr>

            </table>
          </div>
        </div>

        <div className="slide-holder">
          <div className="text-container">
            <h2>{t('club__meetingInformation')}</h2>
            <table>
              <tr>
                <td>{t('club__location')}</td>
                <td>{props.informationClub?.meetingLocation}</td>
              </tr>
              {props.informationClub?.meetingDays &&
                <tr>
                  <td>{t('club__days')}</td>
                  <td>{props.informationClub.meetingDays.join(', ')}</td>
                </tr>
              }
              <tr>
                <td>{t('club__time')}</td>
                <td>{props.informationClub?.meetingStartTime} - {props.informationClub?.meetingEndTime}</td>
              </tr>
              <tr>
                <td>{t('club__frecuency')}</td>
                <td>{props.informationClub?.meetingFrequency}</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="slide-holder">
          <div className="text-container">
            <h2>{t('club__competitionsInformation')}</h2>
            <table>
              <tr>
                <td>{t('club__regional')}</td>
                <td>{props.informationClub?.participateRegionalCompetitions ? 'Yes' : 'No'}</td>
              </tr>
              <tr>
                <td>{t('club__nacional')}</td>
                <td>{props.informationClub?.participateNationalCompetitions ? 'Yes' : 'No'}</td>
              </tr>
              <tr>
                <td>{t('club__international')}</td>
                <td>{props.informationClub?.participateInternationalCompetitions ? 'Yes' : 'No'}</td>
              </tr>
            </table>
          </div>
        </div>

      </Carousel>
    </div>
  )*/
}

export default Index;
