import { useEffect, useMemo, useState } from "react";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import { sortAlphabeticallyOnProperty } from "../../../containers/Signup/SignupHealerInd";
import ReferHealerRequest from "@vibe/sdk/dist/interfaces/AccessManager/referHealer";
import useCountriesSA from "../../useCountriesSA";
import useActiveState from "../../Locations/useActiveState";
import useStateCities from "../../Locations/useStateCities";

export interface HealerReferred extends ReferHealerRequest {
    countryName: string;
    stateName: string;
    cityName: string;
}

const useReferralsSA = ({
    SearchTerm
}: {
    SearchTerm: string
}) => {

    const {
        countriesFiltered,
        reloadCountries
    } = useCountriesSA({
        SearchTerm: '',
    });

    const {
        activeStates,
        activeStatesReactSelect
    } = useActiveState();
    const {
        cities,
        citiesReactSelect,
        reloadState
    } = useStateCities();

    const searchParsed = useMemo(() => SearchTerm.toLowerCase(), [SearchTerm]);

    const [Referrals, setReferrals] = useState<ReferHealerRequest[]>([]);
    const [ReferralsFiltered, setReferralsFiltered] = useState<HealerReferred[]>([]);

    const reloadReferrals = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.AccessManager.getReferrals(dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) {
                    setReferrals(results.body as ReferHealerRequest[]);
                }
                else {
                    setReferrals([]);
                }
            });
    };

    const getReferralsComplete = (): HealerReferred[] => {
        return (sortAlphabeticallyOnProperty(Referrals, 'name') as ReferHealerRequest[]).map(l => { 
            const state = activeStatesReactSelect.find(c => c.value === l.stateCode)?.label || '';
            reloadState(state);
            return{
            ...l,
            countryName: (countriesFiltered.find(c => c.location === l.countryCode)?.name || ''),
            stateName: state,
            cityName: citiesReactSelect.find(c => c.value === l.cityCode)?.label || '',
        }})
    };

    useEffect(() => {
        if (!SearchTerm || SearchTerm === '') {
            setReferralsFiltered(getReferralsComplete());
            return;
        }
        setReferralsFiltered(getReferralsComplete()
            .filter(l => (l.healerFirstName || '').toLowerCase().includes(searchParsed) ||
                (l.healerLastName || '').toLowerCase().includes(searchParsed) ||
                (l.healerEmail || '').toLowerCase().includes(searchParsed))
        );
    }, [SearchTerm, Referrals]);

    useEffect(() => {
        reloadReferrals();
    }, []);

    return {
        ReferralsFiltered,
        reloadReferrals
    };
};

export default useReferralsSA;
