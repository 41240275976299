import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import PostCard, { PostCardInterface } from "../../components/BaseComponents/Posts/PostCard";
import FeaturedPostsModal from "../../components/FeedCenterSection/FeaturedPostsModal";
import { reloadPostsAll } from "../../store/actions/postActions";
import { RootState } from "../../store/store";
import PostModal from "../../components/PostInputLined/PostModal";
import usePosts from "../../hooks/Posts/usePosts";
import { Loader } from "../../components/BaseComponents/Loader";
import { getClub, getClubsImAdv, getMyClubsExtended } from "../../store/actions/clubActions";
import { IClubExtended } from "@vibe/sdk/interfaces/Club/ClubInterface";
import IResponseError from "@vibe/sdk/dist/interfaces/IResponseError";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import { GET_CLUB_SUCCESS, GET_CLUB_ERROR } from "../../store/constants/clubConstants";
import { IOrganizationExtended } from "@vibe/sdk/interfaces/Admin/Organization";
import { useParams } from "react-router-dom";

export type postTypeView = 'VIBES' | 'JOURNEY' | 'FRIENDS' | 'SAVED' | 'CLUB' | 'EVENT' | 'MY_PROFILE' | 'CONNECTION';

export type postTypeNoParam = 'VIBES' | 'JOURNEY' | 'FRIENDS' | 'SAVED' | 'MY_PROFILE';

type PostsNewProps = {
    postRequired: 'VIBES' | 'JOURNEY' | 'FRIENDS' | 'SAVED' | 'MY_PROFILE';
} | {
    postRequired: 'CLUB';
    clubId: string;
} | {
    postRequired: 'EVENT';
    eventId: string;
} | {
    postRequired: 'CONNECTION';
    username: string;
};

function PostsNew({
    postRequired,
    ...props
}: PostsNewProps) {

    const dispatch = useDispatch();

    const { id, username } = useParams();

    const [showCreate, setShowCreate] = useState<boolean>(false);

    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
    const clubsAdvisor: IClubExtended[] = useSelector((state: RootState) => state.getClub.clubsImAdv);
    const listClubsDigest = useSelector((state: RootState) => state.getClub.getMyClubs || []);
    const organization: IOrganizationExtended = useSelector((state: RootState) => state.getOrgs.organizationInfo);

    useEffect(() => {
        if (userInfo?.username) {
            reloadUserPosts(userInfo.username);
            dispatch(getMyClubsExtended(userInfo?.username || ''));
            dispatch(getClubsImAdv(userInfo?.username || ''));
        }
    }, [userInfo])

    const {
        cardsBulletin,
        cardsOrg,
        cardsFriends,
        cardsSave,
        cardsFeedMyProfile,
        cardsFeedConnection,
        cardsEvent,
        cardsClub,
        loadingBulletin,
        loadingOrg,
        loadingFriends,
        loadingSave,
        loadingFeedMyProfile,
        loadingFeedConnection,
        loadingEvent,
        loadingClub,
        reloadEventPosts,
        reloadClubPosts,
        reloadUserPosts,
        reloadWallPosts,
        forceReloadEventId,
        forceReloadClubId,
        forceReloadUsername,
        forceReloadUsernameWall,
    } = usePosts();

    const posts = useMemo(() => {
        if (postRequired === 'VIBES') {
            return cardsBulletin;
        }
        if (postRequired === 'JOURNEY') {
            return cardsOrg;
        }
        if (postRequired === 'FRIENDS') {
            return cardsFriends;
        }
        if (postRequired === 'SAVED') {
            return cardsSave;
        }
        if (postRequired === 'CLUB' && 'clubId' in props) {
            return cardsClub;
        }
        if (postRequired === 'EVENT' && 'eventId' in props) {
            return cardsEvent;
        }
        if (postRequired === 'MY_PROFILE') {
            return cardsFeedMyProfile;
        }
        if (postRequired === 'CONNECTION' && 'username' in props) {
            return cardsFeedConnection;
        }
        return [];
    }, [
        postRequired,
        cardsBulletin,
        cardsOrg,
        cardsFriends,
        cardsSave,
        cardsFeedMyProfile,
        cardsFeedConnection,
        cardsEvent,
        cardsClub,
    ]);

    const loading = useMemo(() => {
        if (postRequired === 'VIBES') {
            return loadingBulletin;
        }
        if (postRequired === 'JOURNEY') {
            return loadingOrg;
        }
        if (postRequired === 'FRIENDS') {
            return loadingFriends;
        }
        if (postRequired === 'SAVED') {
            return loadingSave;
        }
        if (postRequired === 'CLUB' && 'clubId' in props) {
            return loadingClub;
        }
        if (postRequired === 'EVENT' && 'eventId' in props) {
            return loadingEvent;
        }
        if (postRequired === 'MY_PROFILE') {
            return loadingFeedMyProfile;
        }
        if (postRequired === 'CONNECTION' && 'username' in props) {
            return loadingFeedConnection;
        }
        return false;
    }, [
        postRequired,
        loadingBulletin,
        loadingOrg,
        loadingFriends,
        loadingSave,
        loadingFeedMyProfile,
        loadingFeedConnection,
        loadingEvent,
        loadingClub,
    ])

    const [postIdSelected, setPostIdSelected] = useState<string>('');
    const [showModalFeatured, setShowModalFeatured] = useState<boolean>(false);

    const onClickFeatured = (id: string) => {
        setShowModalFeatured(true);
        setPostIdSelected(id);
    }

    const handlePostSave = (e: string, type: boolean) => { }

    const reloadPosts = () => {
        console.log('reloading', userInfo?.username, postRequired)
        if (postRequired === 'VIBES') {
            reloadUserPosts(userInfo?.username!);
            // forceReloadUsername()
            return;
        }
        if (postRequired === 'JOURNEY') {
            reloadUserPosts(userInfo?.username!);
            // forceReloadUsername()
            return;
        }
        if (postRequired === 'FRIENDS') {
            reloadUserPosts(userInfo?.username!);
            // forceReloadUsername()
            return;
        }
        if (postRequired === 'SAVED') {
            reloadUserPosts(userInfo?.username!);
            //forceReloadUsername(); //needed to reload pinned posts
            return;
        }
        if (postRequired === 'CLUB' && 'clubId' in props) {
            reloadClubPosts(props.clubId);
            // forceReloadClubId();
            return;
        }
        if (postRequired === 'EVENT' && 'eventId' in props) {
            reloadEventPosts(props.eventId);
            // forceReloadEventId();
            return;
        }
        if (postRequired === 'MY_PROFILE') {
            reloadUserPosts(userInfo?.username!);
            // forceReloadUsername()
            return;
        }
        if (postRequired === 'CONNECTION' && 'username' in props) {
            reloadWallPosts(props.username);
            // forceReloadUsernameWall()
            return;
        }
    }

    const reloadForcePosts = () => {
        console.log('reloading force', userInfo?.username, postRequired)
        if (postRequired === 'VIBES') {
            forceReloadUsername()
            return;
        }
        if (postRequired === 'JOURNEY') {
            forceReloadUsername()
            return;
        }
        if (postRequired === 'FRIENDS') {
            forceReloadUsername()
            return;
        }
        if (postRequired === 'SAVED') {
            forceReloadUsername()
            return;
        }
        if (postRequired === 'CLUB' && 'clubId' in props) {
            forceReloadClubId();
            return;
        }
        if (postRequired === 'EVENT' && 'eventId' in props) {
            forceReloadEventId();
            return;
        }
        if (postRequired === 'MY_PROFILE') {
            forceReloadUsername()
            return;
        }
        if (postRequired === 'CONNECTION' && 'username' in props) {
            forceReloadUsernameWall()
            return;
        }
    }

    const showCreatePost = () => {
        setShowCreate(false);

        if (!['JOURNEY', 'FRIENDS', 'CONNECTION', 'CLUB'].includes(postRequired)) {
            setShowCreate(true);
            return;
        }

        if (postRequired === 'CLUB' && id) {
            const adv = clubsAdvisor.find(c => c.id === id);

            if (adv) {
                setShowCreate(true);
                return;
            }

            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Club.getClub(id, organization?.status.startsWith('PEND'), dataSDK.token);
                })
                .then((results) => {
                    if (results.statusCode === 200) {
                        const club = results.body as IClubExtended;
                        const digest = listClubsDigest.find(c => c.club_id === id);

                        if (
                            digest &&
                            (
                                (club.membersCanPost && (digest?.roles || []).includes('MEMBER')) ||
                                (club.followersCanPost && (digest?.roles || []).includes('FOLLOWER'))
                            )
                        ) {
                            setShowCreate(true);
                        }
                    }
                    else {
                        console.log('Error getting club extended', (results.body as IResponseError).error || (results.body as IResponseError).message);
                    }
                })
                .catch((error) => {
                    console.log('Error getting club extended', error);

                });
        }

    }

    useEffect(() => {
        reloadPosts();
    }, [
        postRequired,
        props,
    ])

    useEffect(() => {
        showCreatePost();
    }, [
        postRequired,
        id,
    ])

    return (
        <>
            {postRequired === 'SAVED' ? (
                <WrapperFeatured>
                    {loading ? (
                        <LoaderWrapper>
                            <LoaderPosts />
                        </LoaderWrapper>
                    ) : (
                        posts.map((post) => (
                            (post.files || []).length > 0 ? (
                                <RectanglePinned
                                    src={(post.files || []).length > 0 ? (post.files || [])[0].link : 'https://cdn.pixabay.com/photo/2017/03/25/17/55/colorful-2174045_1280.png'}
                                    onClick={() => onClickFeatured(post.id)}
                                />
                            ) : (
                                <RectanglePinnedTextWrapper onClick={() => onClickFeatured(post.id)}>
                                    <RectanglePinnedText>
                                        {post.content}
                                    </RectanglePinnedText>
                                </RectanglePinnedTextWrapper>
                            )

                        ))
                    )}
                </WrapperFeatured>
            ) : (
                <Wrapper>
                    {showCreate && (
                        <PostModal
                            whereIs={postRequired}
                            reloadPosts={reloadForcePosts}
                        />
                    )}
                    {loading ? (
                        <LoaderWrapper>
                            <LoaderPosts />
                        </LoaderWrapper>
                    ) : (
                        <WrapperPosts theme={{ main: username ? 'postUsername' : '' }}>
                            {posts.map((post) => (
                                <PostCard data={post} setShowSetup={handlePostSave} reloadPosts={() => userInfo?.username && reloadPosts()} />
                            ))}
                        </WrapperPosts>
                    )}
                </Wrapper>
            )}
            <FeaturedPostsModal show={showModalFeatured} onHide={() => setShowModalFeatured(false)} postId={postIdSelected} />
        </>
    );
}

export default PostsNew;

const LoaderWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const LoaderPosts = styled(Loader)`
    border-bottom-color: var(--t-blue);
`;

const WrapperPosts = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    max-height: 100vh;
    gap: 10px;
    padding-bottom: 60px;

    &.postUsername{
        padding-bottom: 260px;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    // overflow: hidden;
    gap: 10px;
`;

const RectanglePinned = styled.img`
    width: 200px;
    height: 200px;
    min-width: 200px;
  min-height: 200px;
  max-width: 200px;
  max-height: 200px;
    cursor: pointer;
    object-fit: cover;
`;

const RectanglePinnedTextWrapper = styled.div`
    width: 200px;
    height: 200px;
    cursor: pointer;
    background-color: var(--t-light-tan);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

const RectanglePinnedText = styled.div`
    width: 100%;
    color: black;
    max-height: 200px;
    font-size: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
`;

const WrapperFeatured = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: scroll;
    max-height: 100%;
    padding-bottom: 10px;
    gap: 16px;
    background-color: white;
    border: 1px solid var(--simple-gray);
    padding: 16px;
    border-radius: 8px;
    margin-top: 15px;
`;
