import styled from "styled-components";
import { PaymentMethodCard } from "../../../EditProfile/PaymentMethod";
import { capitalize } from "../../../../helpers/letterHelper";

const SavedCard = ({
    card,
    handleCardActive,
    isView = false,
}: {
    card: PaymentMethodCard
    handleCardActive: (data: PaymentMethodCard) => void
    isView?: boolean
}) => {
    return (
        <RowContent onClick={() => handleCardActive(card)} isView={isView}>
            <RowFlex>
                <TypeCard theme={{ main: `card${card.typeCard}` }} />
                <ColumnFlex>
                    <Title>{`${capitalize(card.type)} ... ${card.lastNumber}`}</Title>
                    {card.name}
                </ColumnFlex>
            </RowFlex>
        </RowContent>
    )
}

export default SavedCard;

const Rounded = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    width: fit-content;
    height: fit-content;
    border-radius: 50%;
    background-color: var(--t-blue);
`;

const RowContent = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 5px;

    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    border-bottom: ${({ isView }: { isView: boolean }) => isView ? 'none' : '1px solid var(--simple-gray)'};

    cursor: pointer;
`;

const Title = styled.div`
    font-weight: 600;
`;

const RowFlex = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    width: 100%;
`;

const ColumnFlex = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const TypeCard = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    width: 70px;
    height: 30px;
    display: flex;
    justify-content: center;
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: 75% auto;

    &.cardmastercard{ background-image: url(./mastercardSolid.svg); }
    &.cardvisa{ background-image: url(./visaSolid.svg);}
    &.cardamerican{ background-image: url(./amexSolid.svg); }
    &.cardamex{ background-image: url(./amexSolid.svg); }
    &.carddiners{ background-image: url(./dinersclubSolid.svg); }
`;