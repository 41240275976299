import styled from "styled-components";
import { TitleHome } from "../SearchHome/styles";

export const AdvContainerHome = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  gap: 24px;
  padding: 24px 136px;
  height: calc(100vh - 120px); // Header

  @media only screen and (max-width: 500px) {
    padding: 24px 16px;
  }
`;

export const CardAdvHome = styled.div`
  display: flex;
  width: 368px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--simple-gray);
  background: var(--background-default);
`;

export const AvatarInfoHome = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

export const InfoHome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;

  color: #2E2623;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.1px;
`;

export const AvatarHome = styled.img`
  width: 56px;
  height: 56px;
  min-width: 56px;
  min-height: 56px;
  max-width: 56px;
  max-height: 56px;
  object-fit: cover;
  border-radius: 50%;
`;

export const RowAdvHome = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;

  color: var(--text-headlines);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0.2px;
`;

export const TagsAdvHome = styled.div`
  :after {
    content: "|";
    padding-left: 6px;
  }
`;

export const AdvListRow = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;

  color: var(--text-headlines);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.1px;
  @media only screen and (max-width: 500px) {
    width: 100%;
    align-items: center;
  }
`;

export const TitleHomeAdvs = styled(TitleHome)`
  font-size: 42px;
`;

export const ContentComingSoon = styled(TitleHome)`
  font-size: 38px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;