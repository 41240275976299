import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const SearchContainerHome = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 120px); // Header
  overflow: scroll;
  gap: 48px;
  padding: 24px 136px;

  @media only screen and (max-width: 770px) {
    padding: 24px 20px;
    justify-content: center;
  }
  
`;

export const TitleHome = styled.div`
  color: #1A1A1A;
  text-align: center;
  font-family: 'Source Serif 4', serif;
  font-size: 47px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media only screen and (max-width: 770px) {
    font-size: 29px;
  }
`;

export const RoundedSearchIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  border-radius: 50%;
  background: var(--orange-home);
  padding: 11.67px;
  box-shadow: 0px 0.83333px 1.66667px 0px rgba(27, 36, 44, 0.12);
  -webkit-box-shadow: 0px 0.83333px 1.66667px 0px rgba(27, 36, 44, 0.12);
  -moz-box-shadow: 0px 0.83333px 1.66667px 0px rgba(27, 36, 44, 0.12);
`;

export const SearchHomeInput = styled.div`
  display: flex;
  height: 68px;
  padding: 16px;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid var(--border-subtle-neutral);
  background: var(--background-default);

  -webkit-box-shadow: 0px 8px 16px -2px rgba(27, 36, 44, 0.12), 0px 8px 16px -2px rgba(27, 35, 44, 0.04);
  -moz-box-shadow: 0px 8px 16px -2px rgba(27, 36, 44, 0.12), 0px 8px 16px -2px rgba(27, 35, 44, 0.04);
  box-shadow: 0px 8px 16px -2px rgba(27, 36, 44, 0.12), 0px 8px 16px -2px rgba(27, 35, 44, 0.04);

  @media only screen and (max-width: 770px) {
    height: 55px;
    & ${RoundedSearchIcon}{
      font-size: 14px;
      padding: 8.67px;
    }
  }
`;

export const InputHome = styled.input`
  outline: none;
  color: black;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.1px;
  width: 100%;

  &placeholder {
    color: var(--placeholder-default);
  }

  @media only screen and (max-width: 770px) {
    font-size: 16px;
  }
`;

export const ButtonsHealerHome = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  color: var(--default-dark);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Inter';
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0.1px;

  @media only screen and (max-width: 770px) {
    flex-direction: column;
    gap: 5px;
  }
`;

export const RowHealerHome = styled.div`
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 770px) {
    font-size: 16px;
  }
`;

export const ButtonHealerHome = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 8px 8px 24px;
  align-items: center;
  gap: 24px;

  color: white;
  border-radius: 8px;
  background: var(--orange-home);
  cursor: pointer;

  @media only screen and (max-width: 770px) {
    font-size: 16px;
    padding: 0px 8px 0px 24px;
  }
`;

export const IconHealerHome = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  background-image: url(../../icon_healer.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding: 5px;

  @media only screen and (max-width: 770px) {
    width: 20px;
    height: 20px;
  }
`