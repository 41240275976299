import {AnyAction} from 'redux';
import NotificationStateInterface from '../../interfaces/store/notificationState';
import { GET_NOTIFICATION_ERROR, GET_NOTIFICATION_SUCCESS, LOGOUT_NOTIFICATION, START_GET_NOTIFICATION } from '../constants/notificationConstants';

const initialState: NotificationStateInterface = {
  loadingGetNotifications: false,
  getNotificationsError: undefined,
  getNotifications: [],
};

export const getNotificationReducer = (
  state = initialState,
  action: AnyAction,
) : NotificationStateInterface => {
  switch (action.type) {

    case START_GET_NOTIFICATION:
        return {
            ...state,
            loadingGetNotifications: true,
        }
    case GET_NOTIFICATION_SUCCESS:
        return {
            ...state,
            loadingGetNotifications: false,
            getNotifications: action.payload,
        }
    case GET_NOTIFICATION_ERROR:
        return {
            ...state,
            loadingGetNotifications: false,
            getNotificationsError: action.payload,
        }

    case LOGOUT_NOTIFICATION:
      return initialState;

    default:
      return state;
  }
};
