import { useEffect, useState } from "react";
import Property1AdminProperty2NoProperty3W from "../Property1AdminProperty2NoProperty3W";
import "./styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faUser, faUsers } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ModalComingSoon from "../BaseComponents/Modals/ModalComingSoon";

export const LAVENDER = '#a885d7';
export const T_BLUE = '#27303B';
export const T_BLUE_LIGHTER = '#aeaeae';
export const T_LIGHT_TAN = '#CEBEAD';

export const WHITE = '#FFFFFF';
export const VIOLET = '#27303B';
export const GRAY = '#969696';
export const ZERO = '#003ddb';
export const DARK_VIOLET = '#27303B';

function Index(props:any) {
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState(props.selected);
  const [soon, setSoon] = useState<boolean>(false);

  function handleOnClick(type: any, id: any){
    if(type === "Events"){
      if(process.env.REACT_APP_SHOW_EVENT !== 'true'){
        setSoon(true);
        return;
      }
      setSoon(false);
    }
    props.handleOnClick(id);
    setSelected(type)
  }

  useEffect(() => {
    setSelected(props.selected)
  }, [props.selected])

  return (
    <IconsTabs>
      <div
        className={
          selected === "Connections" ? "icons-column-selected pointer" : "icons-column pointer"
        }
        onClick={() => handleOnClick("Connections", 2)}
      >
        <FontAwesomeIcon
          icon={faUser}
          size='lg'
          color={
            selected === "Connections"
              ? T_BLUE
              : T_BLUE_LIGHTER
          }
        />
        <div
          style={selected === "Connections" ? { color: T_BLUE } : { color: T_BLUE_LIGHTER }}
          className={
            selected === "Connections"
              ? "icon-label robotocondensed-bold-lavender-14px"
              : "icon-label robotocondensed-normal-white-14px"
          }
        >
          {t('navbar__connections')}
        </div>
      </div>
      <div
        className={
          selected === "Clubs" ? "icons-column-selected pointer" : "icons-column pointer"
        }
        onClick={() => handleOnClick("Clubs", 1)}
      >
        <FontAwesomeIcon
          icon={faUsers}
          size='lg'
          color={
            selected === "Clubs"
              ? T_BLUE
              : T_BLUE_LIGHTER
          }
        />
        <div
          style={selected === "Clubs" ? { color: T_BLUE } : { color: T_BLUE_LIGHTER }}
          className={
            selected === "Clubs"
              ? "icon-label robotocondensed-bold-lavender-14px"
              : "icon-label robotocondensed-normal-white-14px"
          }
        >
          Communities
        </div>
      </div>
      <div
        className={
          selected === "Events" ? "icons-column-selected pointer" : "icons-column pointer"
        }
        onClick={() => handleOnClick("Events", 0)}
      >
        <FontAwesomeIcon
          icon={faCalendar}
          size='lg'
          color={
            selected === "Events"
              ? T_BLUE
              : T_BLUE_LIGHTER
          }
        />
        <div
          style={selected === "Events" ? { color: T_BLUE } : { color: T_BLUE_LIGHTER }}
          className={
            selected === "Events"
              ? "icon-label relative robotocondensed-bold-lavender-14px"
              : "icon-label relative robotocondensed-normal-white-14px"
          }
        >
          {t('navbar__events')}
        </div>
        {(soon) && 
        <ModalComingSoon 
          title={'Coming Soon!'} 
          onHide={setSoon}
          bottom={'calc(90vh - 80px)'}
          left={'20%'}
        />}
      </div>
    </IconsTabs>
  );
}

export default Index;

const IconsTabs = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  min-height: 57px;
  gap: 5px;
  overflow: scroll;
`;