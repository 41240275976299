import { useEffect, useMemo, useState } from "react";
import useClubs from "./useClubs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getMyPendingClubs } from "../../store/actions/clubActions";

type ClubBulletinTabs = 'Teams' | 'Communities' | 'Pending';

const useClubsBulletin = () => {
  const [tab, setTab] = useState<ClubBulletinTabs>();
  const [searchTerm, setSearchTerm] = useState<string>();

  const dispatch = useDispatch();

  const username = useSelector((state: RootState) => state.getUser.user.username);
  const pendingClubs = useSelector((state: RootState) => state.getClub.getMyPendingClubs);

  const {
    reloadClubs,
    handleChangeType,
    handleChangeSearchTerm: handleChangeSearchTermP,
    clubs: clubsP,
  } = useClubs({});

  useEffect(() => {
    if (tab === 'Communities') handleChangeType('CLUB');
    else if (tab === 'Teams') handleChangeType('COMM');
    else dispatch(getMyPendingClubs(username));
    setSearchTerm(undefined);
  }, [tab]);

  useEffect(() => handleChangeSearchTermP(searchTerm), [searchTerm]);

  const clubs = useMemo(() => {
    if (tab && [
      'Communities',
      'Teams',
    ].includes(tab)) return clubsP;
    return pendingClubs.filter(({name}) => !searchTerm || name.toLowerCase().includes(searchTerm))
  }, [clubsP, pendingClubs, tab, searchTerm])

  const handleChangeTab = (tabP?: ClubBulletinTabs) => setTab(tabP);

  const handleChangeSearchTerm = (search?: string) => setSearchTerm(search ? search.toLowerCase() : undefined);

  const reload = () => {
    reloadClubs();
    dispatch(getMyPendingClubs(username));
  }

  return {
    tab,
    clubs,
    handleChangeTab,
    handleChangeSearchTerm,
    reload,
  }
};

export default useClubsBulletin;