import styled from 'styled-components';
import {
    StyledFontAwesomeIcon, Name,
} from '../../HealerProfile/components';
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons';
import IHealer from '@vibe/sdk/interfaces/Admin/IHealer';
import IHS from '@vibe/sdk/interfaces/Modalities/hservices/HS';
import { useNavigate } from 'react-router-dom';
import AvatarInitials from './AvatarInitials';
import { notNullish } from 'react-select/dist/declarations/src/utils';

const CardHealer = ({
    data,
    simplified = false,
    setShowModal,
    showLocation = false,
    sizeAvatar = 56
}: {
    data: IHealer,
    simplified?: boolean,
    setShowModal?: (data: boolean) => void,
    showLocation?: boolean
    sizeAvatar?: number
}) => {

    const navigate = useNavigate();

    const getHealerServices = (services: IHS[]): string[] => {
        const result: string[] = [];

        for (let i = 0; i < services.length; i++) {
            if (i === 2) {
                if (services.length > 2) result.push(`+${services.length - 2}`);
                break;
            }
            else {
                result.push(services[i].name);
            }
        }

        return result;
    }

    const handleSeeProfile = () => {
        if (setShowModal) setShowModal(false);
        navigate(`../../../h/${data.username}`);
    }

    return (
        <CardProfile>
            <Info theme={{ main: ((data.education || []).length > 0) ? 'moreDetail' : '' }}>
                <AvatarInitials
                    avatar={data.avatar || ""}
                    name={data.name}
                    size={sizeAvatar}
                />
                <InfoProfile>
                    <NameClick onClick={() => navigate(`../h/${data.username}`)}>{data.name}</NameClick>
                    {showLocation &&
                        (data.city || data.zipcode || data.country) &&
                        (<Location>
                            <StyledFontAwesomeIcon
                                icon={faLocationDot}
                                size='lg'
                                color={"black"}
                                style={{
                                    fontSize: '16px'
                                }}
                            />
                            {data.city ? data.city : data.zipcode || ''}, {data.country}
                        </Location>)
                    }
                    <Items>
                        {getHealerServices(data.services).map((edu, index) => (
                            <Item isFirst={data.services.length === 1} theme={{ main: (data.services.length > 2) ? 'itemPlus' : '' }}>{edu}</Item>
                        ))}
                    </Items>
                </InfoProfile>
            </Info>
        </CardProfile>
    )
}

const NameClick = styled(Name)`
    cursor: pointer;
    line-height: normal;
`

const Location = styled.div`
    font-family: 'Inter';
    font-size: 14px;
    text-align: left;
    margin-top: ${props => props.theme.top || '0px'};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
`

const Avatar = styled.img`
    width: ${({ isSimple }: { isSimple: boolean }) => isSimple ? '110px' : '80px'};
    height: ${({ isSimple }: { isSimple: boolean }) => isSimple ? '110px' : '80px'};
    min-width: ${({ isSimple }: { isSimple: boolean }) => isSimple ? '110px' : '80px'};
    min-height: ${({ isSimple }: { isSimple: boolean }) => isSimple ? '110px' : '80px'};
    max-width: ${({ isSimple }: { isSimple: boolean }) => isSimple ? '110px' : '80px'};
    max-height: ${({ isSimple }: { isSimple: boolean }) => isSimple ? '110px' : '80px'};
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #abb9bf;
`

const Info = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    width: -webkit-fill-available;
    min-height: 50px;

    @media only screen and (max-width: 1400px) {
        &.moreDetail{
            flex-direction: column;
        }
    
        &.moreDetail > div{
            width: 100%;
            overflow: hidden;
        }
    }   
`

const InfoProfile = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    width: 100%;
    overflow: hidden;
`

const ReadMore = styled.div`
  font-family: 'Inter-Bold';
  font-size: 14px;
  color: var(--absolute-zero);
  cursor: pointer;
`

const ItemsP = styled.div`
    display: flex;
    gap: 4px;
`
const ItemP = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    height: 15px;
    background: #FFFFFF;
    border: 1px solid #665D5A;
    border-radius: 16px;
    font-family: 'Inter';
    font-size: 12px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    max-width: 70px;
    white-space: nowrap;
    justify-content: flex-start;
`
const CardProfile = styled.div`
    border-bottom: 1px solid #f2f2f2;
    padding: 10px 17px;
    border-radius: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left; 
    gap: 10px;
`

const Items = styled.div`
    display: flex;
    gap: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

`
const Item = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    padding: 0px 8px;
    //background: #E5DCD0;
    border-radius: 0px;
    font-family: 'Inter';
    font-size: 12px;
    height: 15px;
    display: flex;
    align-items: center;
    border-right: 1px solid black;
    border-right: ${({ isFirst }: { isFirst: boolean }) => isFirst ? '0px solid black' : '1px solid black'};

     &:first-child {
        padding-left: 0px;
    }

    &.itemPlus:last-child {
        position: absolute;
        right: 17px;
        background-color: white;
        border: 0;
        font-family: 'Inter-Bold';
    }

    &.itemPlus:last-child::before {
        content: "...and ";
        display: block;
        background-color: white;
        display: flex;
        margin-right: 3px;
        align-items: center;
    }
`

export default CardHealer;