import "./styles.css";
import "./styles.scoped.css";
import { useContext, useEffect, useState } from "react";
import ModalInviteMembers from "./ModalInviteMembers";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getClubMembers, getClubsImAdv } from "../../store/actions/clubActions";
import ModalConnections from "../ConnectionSelectBar/ModalConnections";
import { getUserFriends, getUserPossibleConnections } from "../../store/actions/connectionActions";
import ModalMembersRegistered from "./ModalMembersRegistered";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices, getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import { onlyFirstLetterCapitalized } from "../../helpers/letterHelper";
import ModalConnection from "../ModalConnection";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";
import { BROWN } from "../SuperCenter/Modalities/Modals/components";
import { ClubCenterWrap } from "../ClubViewInformation";
import styled from "styled-components";
import SearchInput from "../BaseComponents/SearchInput";
import ModalMembers from "../signupsetuphealer/ModalMembers";
import { UserInClub } from "../signupsetuphealer/ModalSetup";
import { IClubCode } from "@vibe/sdk/dist/interfaces/Club/clubCode";
import { BaseFieldState, getFieldState } from "../BaseComponents/utils";
import IClub, { IClubExtended } from "@vibe/sdk/interfaces/Club/ClubInterface";
import { IFetchResponse } from "@vibe/sdk/dist/helpers/fetch.helper";
import IResponseError from "@vibe/sdk/dist/interfaces/IResponseError";
import { ContextSuccess } from "../../Context";
import { ICreateInviteResponse } from "@vibe/sdk/dist/interfaces/Invite/createInvite";
import BlankTable from "../BaseComponents/BlankTable";
import { getIdOrgUser } from "../../helpers/userHelper";
import { RootState } from "../../store/store";
import { IUserGradeResponse } from "@vibe/sdk/interfaces/Admin/User";
import { onHideType } from "../BaseComponents/Modals/ModalUnsaved";

interface ClubMembersProps {
  //todo -> put types
  members: any;
  advisors: any;
  isClubAdvisor: any;
  infoClub: IClubExtended;
  officers: any;
  reloadClub: any;
  reloadMembers: any;
  setInvitesAdded: any;
  ChatServiceInstance: ChatServiceInterface;
}

function Index(props: ClubMembersProps) {
  const { t, i18n } = useTranslation();
  const [loadingSave, setLoadingSave] = useState(false);
  const [members, setMembers] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [typeFiter, setTypeFilter] = useState('1');
  const [setmember, setSetMember] = useState<any>('pass');
  const [isUserAdvisor, setIsUserAdvisor] = useState(false);

  const { id } = useParams();

  const userFriends = useSelector((state: any) => state.getConnection.userFriends || []);
  const userConnections = useSelector((state: any) => state.getConnection.userPossibleConnections || []);
  const userRoles = useSelector((state: RootState) => state.getUser.userRoles);
  const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
  const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);
  const clubsGuide: IClubExtended[] = useSelector((state: RootState) => state.getClub.clubsImAdv);
  const [valueFilter, setValueFilter] = useState("");
  const [infoConnection, setInfoConnection] = useState([]);
  const [showModalConnection, setShowModalConnection] = useState<boolean>(false);

  const [teamIndividuallyMembers] = useState<BaseFieldState<UserInClub[]>>(getFieldState([]));
  const [teamRosters] = useState<BaseFieldState<string[]>>(getFieldState([]));
  const [clubCodes] = useState<Omit<IClubCode, 'club_id'>[]>([]);

  const [showModalMembers, setShowModalMembers] = useState(false);
  const currentSuccess = useContext(ContextSuccess);

  const dispatch = useDispatch();

  const handleOnClickInvite = (e: any) => {
    setShowModalMembers(true);
  };

  const onHideMembers = (
    type: 'CANCEL' | 'SUBMIT',
    individuals: UserInClub[],
    rosters: string[],
    codes?: Omit<IClubCode, 'club_id'>[],
    invites?: number
  ) => {
    if(type === 'CANCEL'){
      setShowModalMembers(false);
      return;
    }

    handleUpdateCommunity(individuals, rosters, codes ?? []);
  }

  const handleUpdateCommunity = (
    individuals: UserInClub[],
    rosters: string[],
    codes: Omit<IClubCode, 'club_id'>[]
  ) => {
    let dataSDK: getDataSDKResponse;
    const errors: string[] = [];
    if (!id) return;

    setLoadingSave(true);

    getDataSDK()
      .then((data: getDataSDKResponse) => {
        dataSDK = data;

        if ((individuals ?? []).length > 0) {
          return Promise.all([
            ...(individuals ?? [])
              .map((inf: UserInClub) => dataSDK.sdk.Invite.createInvite({
                email: inf.email,
                org_id: props.infoClub.org_id,
                club_id: id,
                is_advisor: false,
                firstName: inf.firstName,
                lastName: inf.lastName,
              }, dataSDK.token)),
            ] as Promise<(IFetchResponse<IResponseError | ICreateInviteResponse>)>[]);
        } else {
          return Promise.resolve([{
            body: {},
            statusCode: 201,
            headers: undefined,
          }] as IFetchResponse<IResponseError | ICreateInviteResponse>[]);
        }
      })
      .then((results: (IFetchResponse<IResponseError | ICreateInviteResponse>)[]) => {

        results.forEach((result) => {
          if (result.statusCode !== 201) {
            errors.push("Error adding individual members:", (result.body as IResponseError).error || (result.body as IResponseError).message);
            currentSuccess?.setSuccessData(false, true, "Error adding individual members", (result.body as IResponseError).error || (result.body as IResponseError).message);
          }
        })

        //Add code club link 
        if (codes.length > 0) {
          return Promise.all(codes.map((c) => dataSDK.sdk.Club.relateClubCodeToClub({
            code: c.code,
            club_id: id,
          }, dataSDK.token)))
        }
        else {
          return Promise.resolve([{
            statusCode: 201,
            headers: undefined,
          }] as IFetchResponse<IClubCode>[]);
        }
      })
      .then((results: IFetchResponse<IResponseError | IClubCode>[]) => {

        if (!results.every((r) => r.statusCode === 201)) {
          errors.push("Error adding club code:", (results[0].body as IResponseError).error || (results[0].body as IResponseError).message);
          currentSuccess?.setSuccessData(false, true, "Error adding club code", (results[0].body as IResponseError).error || (results[0].body as IResponseError).message);
        }

        //Invite rosters (if exist)
        if ((rosters || []).length > 0) {
          return Promise.all((rosters || [])
            .map((roster: string) => dataSDK.sdk.Invite.inviteRoster({
              file: roster,
              org_id: props.infoClub.org_id,
              club_id: id,
            }, dataSDK.token)));
        }
        else {
          return Promise.resolve([{
            body: {},
            statusCode: 200,
            headers: undefined,
          }]);
        }
      })
      .then((results: IFetchResponse<IResponseError | undefined>[]) => {

        results.forEach((result) => {
          if (result.statusCode !== 200) {
            errors.push("Error adding rosters:", (result.body as IResponseError).error || (result.body as IResponseError).message);
            currentSuccess?.setSuccessData(false, true, "Error adding rosters", (result.body as IResponseError).error || (result.body as IResponseError).message);
          }
        })
        currentSuccess?.setSuccessData(true, true, "Invites sent and members added correctly.");
        setShowModalMembers(false);
        props.reloadClub();
      })
      .catch((error) => {
        currentSuccess?.setSuccessData(false, true, "Error creating community", error);
        console.log("Error creating community", error);
      })
      .finally(() => {
        setLoadingSave(false);
      })
  }

  useEffect(() => {
    dispatch<any>(getUserFriends(userInfo?.username || ''));
    dispatch<any>(getUserPossibleConnections(userInfo?.username || ''));
    isClubAdvisor();
  }, [id])

  useEffect(() => {
    dispatch<any>(getUserFriends(userInfo?.username || ''));
    dispatch<any>(getUserPossibleConnections(userInfo?.username || ''));
    isClubAdvisor();
  }, [])

  useEffect(() => {
    if (Array.isArray(props.members) && Array.isArray(props.advisors)) {
      setMembers([...props.members, ...props.advisors])
    }
    else if (Array.isArray(props.members)) {
      setMembers([...props.members])
    }
    else if (Array.isArray(props.advisors)) {
      setMembers([...props.advisors])
    }
    else {
      setMembers([])
    }
  }, [props.members, props.advisors]);

  const showButtonSentInvite = () => !!props.isClubAdvisor

  const handleDots = (member: any, username: string) => {
    setTypeFilter('1');
    setSetMember(member);

    //The connection is a friend?
    if (userFriends.find((user: any) => user.friendUsername === username)) {
      setTypeFilter('2');
    }
    //Is a possible connection?
    else if (userConnections.find((user: any) => user.usernameConnection === username)) {
      setTypeFilter('1');
    }
    //The users cannot be friends
    else {
      setTypeFilter('pass');
    }

    setOpenModal(!openModal);
  }

  const isClubAdvisor = () => {
    if (userRoles.includes("SUPER")) {
      setIsUserAdvisor(true);
      return;
    }

    const orgId = getIdOrgUser(user.rolesByOrg, user.organizations);

    if (userRoles.includes("ORGADMIN") && (props?.infoClub?.org_id || '') === orgId) {
      setIsUserAdvisor(true);
      return;
    }

    for (let club of clubsGuide) {
      if (club.id === id) {
        setIsUserAdvisor(true);
        return;
      }
    }
  }

  const showConnection = (e: any) => {
    if (userRoles.includes(`${e.userType}`)) {
      setInfoConnection(e)
      setShowModalConnection(true)
    } else if (!userRoles.includes(`SEEKER`) && e.userType !== 'SEEKER') {
      setInfoConnection(e)
      setShowModalConnection(true)
    }
  }

  return (
    <ClubCenterWrap>
      <Row isAdv={showButtonSentInvite()}>
        <TitleMembers>{members.length} {t('infoMembers__members')}</TitleMembers>
        <SearchInput
          filteredItem={valueFilter}
          setFilteredItem={setValueFilter}
          backgroundColor={'var(--t-blue)'}
          border={'0px'}
          placeholder={`Search member`}
        />
        {(showButtonSentInvite()) && (
          <Button onClick={handleOnClickInvite}>
            <StyledFontAwesomeIcon
              size='sm'
              color='white'
              icon='plus'
            />
            Add Members
          </Button>
        )}
      </Row>

      <div className="listMembersClub">
        {members.filter((item: any) => {
          if (!valueFilter) return true;
          if (item.fullname.toUpperCase().includes(valueFilter) ||
            item.fullname.toLowerCase().includes(valueFilter) ||
            item.fullname.includes(valueFilter)
          ) {
            return true;
          }
          return false;
        }).map((member: any) => (
          <div className="cardMember" key={member.username}>
            <div className="imageMember">
              <img
                className="round-avatar"
                onClick={(e) => { showConnection(member) }}
                src={member.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"}
                alt="" />
            </div>

            <div className="ClubMember-container">
              <p className="ClubMember-Name" onClick={(e) => showConnection(member)}>{member.fullname} </p>
              <p className="ClubMember-Position">
                {(member.userType && member.userType === 'STAFF') ?
                  (onlyFirstLetterCapitalized(member.userType))
                  :
                  ((member.unregistered) ?
                    t('label_unregisteredStaff')
                    :
                    ''
                  )}
              </p>
            </div>
            <button>
              <FontAwesomeIcon
                icon="ellipsis-v-alt"
                size='lg'
                color={'var(--t-blue)'}
                className="ellipsis-v-alt   cursor"
                onClick={() => handleDots(member, member.username)}
              />
              {(openModal && (member.username === setmember.username)) &&
                (<ModalConnections
                  connection={setmember}
                  filterType={typeFiter}
                  setOpenModal={setOpenModal}
                  isAdvisor={isUserAdvisor}
                  username={member.username}
                  officers={props.officers}
                  advisors={props.advisors}
                  reloadClub={props.reloadClub}
                  ChatServiceInstance={props.ChatServiceInstance}
                />)}
            </button>
          </div>
        ))}
      </div>

      {/* {(props?.infoClub?.role_type === 'NO_ROLE_ASSIGNED') ? (
        <ModalMembersRegistered show={showInviteMembers} onHide={handleCloseInvite} club_id={id} reloadMembers={props.reloadMembers} />
      ) : (
        <ModalInviteMembers show={showInviteMembers} onHide={handleCloseInvite} club_id={id} setInvitesAdded={props.setInvitesAdded} />
      )} */}
      <ModalMembers
        showModal={showModalMembers}
        onHide={onHideMembers}
        individuals={teamIndividuallyMembers.value ?? []}
        rosters={teamRosters.value ?? []}
        codes={clubCodes ?? []}
        haveLoading
        loadingSave={loadingSave}
      />
    </ClubCenterWrap>
  );
}

export default Index;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  padding: 10px 10px;
  border-radius: 11px;
  background-color: var(--simple-blue-dark);
  border: 0;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: white;
  gap: 10px;

  cursor: pointer;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: ${({ isAdv = false }: { isAdv: boolean }) => isAdv ? '35% calc(40% - 10px) calc(25% - 10px)' : '50% calc(50% - 10px)'};
  grid-template-rows: 40px;
  grid-column-gap: 10px;
  width: 100%;
  gap: 10px;
`;

const TitleMembers = styled.div`
  font-family: 'Inter-bold';
  font-size: 22px;
  text-align: left;
  color: var(--t-blue);
`;