import { useEffect, useMemo, useState } from "react";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import { useDispatch, useSelector } from "react-redux";
import { sortAlphabeticallyOnProperty } from "../../../containers/Signup/SignupHealerInd";
import { EventType } from "@vibe/sdk/dist/interfaces/Events/eventType";

const useEventTypesSA = ({
    pageSizeProp,
    searchName,
}: {
    pageSizeProp: number
    searchName: string
}) => {
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(pageSizeProp);

    const [filteredEventTypes, setFilteredEventTypes] = useState<EventType[]>([]);

    const [eventTypes, setEventTypes] = useState<EventType[]>([]);

    const searchTerm = useMemo(() => searchName.toLowerCase(), [searchName]);

    const eventTypesPages:EventType[][] = useMemo(() => {
        const pages:EventType[][] = [];

        for (let i = 0; i < eventTypes.length; i+=pageSize) {
            pages.push(eventTypes.slice(i, i+pageSize));
        }

        if (pages.length === 0) {
            pages.push([]);
        }

        return pages;
    }, [pageSize, eventTypes])

    const reloadEventTypes = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Events.getAllEventTypes();
            })
            .then((results): void => {
                if (Array.isArray(results.body)) {
                    setEventTypes(results.body)
                }
            })
    }

    useEffect(() => {
        if (!searchTerm || searchTerm === '') {
            setFilteredEventTypes(sortAlphabeticallyOnProperty(eventTypes, 'name'));
            return;
        }
        setFilteredEventTypes(
            (sortAlphabeticallyOnProperty(eventTypes, 'name') as EventType[])
                .filter(i => i.name.toLowerCase().includes(searchTerm))
        );
    }, [searchTerm, eventTypes]);
    
    useEffect(() => reloadEventTypes(), []);

    return {
        eventTypesPages,
        reloadEventTypes,
        setPageSize,
        filteredEventTypes
    }

}

export default useEventTypesSA;