import { IUserGradeResponse } from "@vibe/sdk/interfaces/Admin/User";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ContextSuccess } from "../../Context";
import { RootState } from "../../store/store";
import { Interest } from "@vibe/sdk/dist/interfaces/Connections/newInterests";
import { Loader } from "../BaseComponents/Loader";
import { t } from "i18next";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import { getInterestSuggested } from "../../store/actions/connectionActions";
import { getUser, getUserInfoProfile } from "../../store/actions/userActions";

const Interests = () => {
    const dispatch = useDispatch();
    const currentSuccess = useContext(ContextSuccess);

    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
    const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);
    const suggestedInterests: Interest[] = useSelector((state: RootState) => state.getConnection.GetISug);
    const organization = useSelector((state: RootState) => state.getOrgs.organizationInfo);

    const [loading, setLoading] = useState<boolean>(false);
    const [updated, setUpdated] = useState<boolean>(false);
    const [userInterests, setUserInterests] = useState<string[]>([]);

    const handleInterest = (int: string) => {
        setUpdated(true);
        if (userInterests.includes(int)) {
            setUserInterests(userInterests.filter(userInterests => userInterests !== int))
        } else {
            setUserInterests([...userInterests, int])
        }
    }

    const saveEditProfile = () => {
        setLoading(true);

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Connections.saveInterests({
                    username: userInfo?.username || '',
                    interests: userInterests,
                    organizationId: organization.id,
                }, dataSDK.token)
            })
            .then((rInterests) => {
                if (rInterests.statusCode === 201) {
                    currentSuccess?.setSuccessData(true, true, t('toast_informationUpdated'));
                    dispatch(getUser(userInfo?.username || '', 'OA'));
                    dispatch(getUserInfoProfile(userInfo?.username || ''));
                    setUpdated(false);
                }
                else{
                    currentSuccess?.setSuccessData(false, true, 'Error updating profile', 'Try again later');
                    console.log(rInterests)
                }
            })
            .catch((error) => {
                console.error(error);
                currentSuccess?.setSuccessData(false, true, 'Error updating profile', error);
            })
            .finally(() => { setLoading(false) });
    }

    useEffect(() => {
        setUserInterests(user.interests || []);
    }, [user]);

    useEffect(() => {
        dispatch(getInterestSuggested());
    }, [])

    return (
        <Container>
            <ListAreas>
                {suggestedInterests.map((area) => (
                    <Area
                        active={userInterests.includes(area.name)}
                        onClick={() => handleInterest(area.name)}
                    >
                        {area.name}
                    </Area>
                ))}
            </ListAreas>
            <ContButtn>
                {loading ?
                    <WrapperSpinner>
                        <Loader />
                    </WrapperSpinner> :
                    <Button onClick={saveEditProfile} color={updated ? '#2e3031' : '#bbbabc'}>Save</Button>
                }
            </ContButtn>
        </Container>
    )
}

export default Interests;

interface ButtonProps {
    color: string;
}

const Button = styled.div`
  background-color: ${(props: ButtonProps) => props.color};
  width: 165px;
  height: 40px;
  display: flex;
  justify-content: center;
  padding: 12px 0;
  border-radius: 8px;
  color: white;
  font-family: 'Inter-Bold';
  cursor: pointer;
`;

const WrapperSpinner = styled.div`
  background-color: #2e3031;
  width: 165px;
  height: 40px;
  display: flex;
  justify-content: center;
  padding: 12px 0;
  border-radius: 8px;
  color: white;
  font-family: 'Inter-Bold';
`;

const ContButtn = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 30px 0;
  flex: 1;
  height: 100vh;
`;

interface AreaProps {
    active: boolean;
}

const Area = styled.div`
      border: 1px solid darkgrey;
      width: fit-content;
      background-color: ${(props: AreaProps) => props.active ? '#FAF0E6' : 'white'};
      padding: 7px 12px;
      border-radius: 17px;
      margin-right: 6px;
      margin-bottom: 16px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
  
      &:hover {
          background-color: #FAF0E6
      }
  `
const ListAreas = styled.div`
      width: 100%;
      margin: 24px auto;
      display: flex;
      flex-wrap: wrap;
  `