import { Backend, MicroServices } from "./backendHelper";
import { capitalize } from "./letterHelper";

export const getRolesUsersView = (role: string[]): string[] =>{
    const result: string[] = [];
    role.forEach(role => {
        const roleCap = capitalize(role);
        if(!result.includes(roleCap)){
            if(roleCap === 'Second#advisor' && !result.includes('Advisor')){
                result.push('Advisor');
                return;
            }
    
            if(roleCap !== 'Orgadmin' && roleCap !== 'Individual'){
                result.push(capitalize(role));
            }
            else if(roleCap === 'Individual'){
                result.push('Individual Healer');
            }
            else{
                result.push('Professional Organization Admin');
            }
        }
    })

    return result;
} 

export const inviteRoleZAA = (role: string) => {
    if (role === ' ' || role === '' || role === undefined) {
        return ''
    }

    const array = role.split('#');
    if (array.length > 2) {
        return capitalize(array[1]) + ' ' + capitalize(array[2])
    }
    else if (array.length === 1) {
        return ''
    }
    else {
        return capitalize(array[1]);
    }
};

export const rolesArray = (roles: any) => {
    const result: any = [];
    try {
        roles.forEach((role: string) => {
            result.push(inviteRoleZAA(role))
        });
    } catch (error) {
        console.error
    }
    return result;
};

export const onlyZAAroles = (roles: any) => {
    const result: any = [];
    try {
        roles.forEach((role: string) => {
            if (role.startsWith('ZAA')) {
                result.push([role, inviteRoleZAA(role)]);
            }
        });
    } catch (error) {
        console.error
    }
    return result;
};

export const onlyZAArolesMain = (roles: any) => {
    const result: any = [];
    try {
        roles.forEach((role: string) => {
            if (role.startsWith('ZAA') && (role !== 'SECOND#ADVISOR') && (role !== 'STAFF#ADVISOR') && (role !== 'SUPER')) {
                result.push([role, inviteRoleZAA(role)]);
            }
        });
    } catch (error) {
        console.error
    }
    console.log('User Roles', result);
    return result;
};

export const getUserRoles = (email: string, org_id: string) => new Promise<any>((resolve, reject) => {
    Backend(
        MicroServices.Admin,
        `/users?email=${email}&org=${org_id}`)
        .then((response) => response.json())
        .then((results) => {

            if(results.users.length > 0) {
                resolve(results.users[0].roles);
            }
            else{
                resolve([])
            }
        })
        .catch((error) => {
            console.log('Error getting user roles', error)
            reject(error);
        });
})