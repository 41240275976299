import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import StartRegister from '../../components/signupsetup/StartRegister';
import Introduction from '../../components/signupsetup/Introduction';
import MyLife from '../../components/signupsetup/MyLife';
import Communities from '../../components/signupsetup/Communities';
import Connections from '../../components/signupsetup/Connections';
import Summary from '../../components/signupsetup/Summary';
import Column2 from "../../components/signupsetup/Column2"
import Footer from "../../components/signupsetup/Footer"
import { ProcessType, ProcessInterface, TabsInterface, Column1_Setup, Content_Signup } from '../SetupHealer/SetupHealer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalUnsaved from '../../components/BaseComponents/Modals/ModalUnsaved';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { getInterestSuggested, getMyConnections, getUserInterests } from '../../store/actions/connectionActions';
import { getClubs, getMyClubs } from '../../store/actions/clubActions';
import { getUsersFiltered } from '../../store/actions/userActions';
import { Container_Signup, Logo } from '../Signup/WrapBusiness';
import HeaderSetup from './HeaderSetup';
import Tab_Setup from './Tab_Setup';

const SetupSeeker = ({
    setShowSetup
}: {
    setShowSetup: (value: boolean) => void
}) => {

    const dispatch = useDispatch();

    const organization = useSelector((state: RootState | any) => state.getOrgs.organizationInfo);
    const userInfo = useSelector((state: RootState | any) => state.getUser.userInfo);

    const [tabActive, setTabActive] = useState<number>(0);
    const [process, setProcess] = useState<ProcessType>('off');
    const [tabsProcessed, setTabsProcessed] = useState<number[]>([]);
    const [newData, setNewData] = useState<boolean>(false);
    const [showUnsavedData, setShowUnsavedData] = useState<boolean>(false);
    const [tabClicked, setTabClicked] = useState<number>(1);

    const tabsSeeker: TabsInterface[] = [
        {
            id: 1,
            label: 'Introduction',
        },
        {
            id: 2,
            label: 'My Life',
        },
        {
            id: 3,
            label: 'Communities',
        },
        {
            id: 4,
            label: 'Connections',
        },
        {
            id: 5,
            label: 'Summary',
        },
    ]

    const getProcessData = (): ProcessInterface => {
        return {
            process,
            setProcess,
            step: tabActive,
            tabsProcessed,
            setTabsProcessed,
            setNewData
        }
    }

    const handleTab = (tab: number) => {
        if (newData) {
            setShowUnsavedData(true);
            setTabClicked(tab)
            return;
        }

        setTabActive(tab);
    }

    const onHideUnsaved = (type = 'close') => {
        switch (type) {
            case 'close':
                setShowUnsavedData(false);
                break;

            case 'discard':
                setShowUnsavedData(false);
                setNewData(false);
                setTabActive(tabClicked);
                break;

            case 'save':
                setShowUnsavedData(false);
                setProcess('loading');
                break;

            default:
                setShowUnsavedData(false);
                break;
        }
    }

    const getClassNameTab = (tab: number) => {
        if (tabActive === tab) return 'tabActive';
        if (tabsProcessed.includes(tab)) return 'processed';
        return '';
    }

    useEffect(() => {
        setProcess('off');
    }, [tabActive])

    useEffect(() => {
        if (organization) {
            dispatch(getInterestSuggested());
            dispatch(getUserInterests(userInfo.username));
            dispatch(getClubs({
                club_type: 'CLUB',
                pending: false,
                deleted: false,
                org_id: organization.id //for now
            }));
            dispatch(getMyClubs(userInfo.username));
            dispatch(getUsersFiltered({ roleRequest: 'OA' }));
            dispatch(getMyConnections(userInfo.username));
        }
    }, [organization])

    return (
        tabActive === 0 ?
            <StartRegister handleTabActive={setTabActive} />
            :
            <ContainerSetup_v2>
                <HeaderSetup />
                <Container_Signup>

                    <Column1_Setup>
                        <Content_Signup>
                            <Tabs_Setup>
                                {tabsSeeker.map((tab) => (
                                    <Tab_Setup
                                        onClick={() => handleTab(tab.id)}
                                        text={tab.label}
                                        active={getClassNameTab(tab.id) === 'tabActive'}
                                        processed={getClassNameTab(tab.id) === 'processed'}
                                    />
                                ))}
                            </Tabs_Setup>
                            {tabActive === 1 ?
                                <Introduction processData={getProcessData()} />
                                :
                                tabActive === 2 ?
                                    <MyLife processData={getProcessData()} />
                                    :
                                    tabActive === 3 ?
                                        <Communities processData={getProcessData()} />
                                        :
                                        tabActive === 4 ?
                                            <Connections processData={getProcessData()} />
                                            :
                                            <Summary
                                                handleTabActive={setTabActive}
                                                processData={getProcessData()}
                                            />
                            }
                        </Content_Signup>
                        <WrapperFooterJames>
                            <Footer
                                tabActive={tabActive}
                                handleTabActive={setTabActive}
                                setShowSetup={setShowSetup}
                                setProcess={setProcess}
                                process={process}
                            />
                        </WrapperFooterJames>
                        <ModalUnsaved
                            show={showUnsavedData}
                            onHide={onHideUnsaved}
                            content={"Do you want to save your changes?"}
                        />
                    </Column1_Setup>

                    <Column2 tabActive={tabActive} />
                </Container_Signup>
            </ContainerSetup_v2>
    )
}

const WrapperFooterJames = styled.div`
    display: flex;
    width: 100%;
    padding-left: 16px;
`;

export const ContainerSetup_v2 = styled.div`
    display: grid;
    grid-template-rows: 80px calc(100vh - 80px);
    grid-template-columns: 1fr;
    width: 100vw;
    overflow: hidden;
`;

export const Tabs_Setup = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 6px;
`

export default SetupSeeker