
import React, { useEffect, useState } from 'react'
import Selector from "../BaseComponents/Selector";
import SelectItem from "../../interfaces/SelectItem";
import { BaseFieldState, getFieldState } from '../BaseComponents/utils';
import styled from "styled-components"

const index = ({
    setYear,
    setMonth,
    year = '',
    month = '',
}: {
    setYear: (data: string) => void,
    setMonth: (data: string) => void,
    year?: string,
    month?: string
}) => {

    const generateRandomNumber = () => {
        setDatePickerIsOpen(Math.floor(Math.random() * 100));
    };

    function rangeYear() {
        const max = new Date().getFullYear()
        const min = max - 100
        const years = []

        for (let i = max; i >= min; i--) {
            years.push({
                label: `${i}`,
                value: `${i}`
            })
        }
        return years
    }

    const months: SelectItem[] = [
        { label: "January", value: "01" },
        { label: "February", value: "02" },
        { label: "March", value: "03" },
        { label: "April", value: "04" },
        { label: "May", value: "05" },
        { label: "June", value: "06" },
        { label: "July", value: "07" },
        { label: "August", value: "08" },
        { label: "September", value: "09" },
        { label: "October", value: "10" },
        { label: "November", value: "11" },
        { label: "December", value: "12" },
    ];

    const [datePickerIsOpen, setDatePickerIsOpen] = useState<number | undefined>(0);

    const [selectedYear, setSelectedYear] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: '', value: '' }));
    const [searchYear, setSearchYear] = useState<string>('');

    const [selectedMonth, setSelectedMonth] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: '', value: '' }));
    const [searchMonth, setSearchMonth] = useState<string>('');

    const onChangeYear = (e: any) => {
        if(e.value && e.value.value !== ""){
            setYear(e.value.value)
        }
    }

    const onChangeMonth = (e: any) => {
        if (e.value && e.value.value !== "") {
            setMonth(e.value.value)
        }
    }

    useEffect(() => {
        setSelectedYear(getFieldState({ label: year, value: year }))
    }, [year])

    useEffect(() => {
        let monthNumber = months.filter((date: any) => date.value == month)
        setSelectedMonth(getFieldState(monthNumber[0]))
    }, [month])

    return (
        <SelectorYearMonth>
            <div>
                <Selector
                    options={rangeYear()}
                    selected={selectedYear}
                    setSelected={onChangeYear}
                    searchItem={searchYear}
                    setSearchItem={setSearchYear}
                    border={'1px solid var(--simple-gray)'}
                    placeholder="YYYY"
                    hideIcon={true}
                    notOrganized
                />
            </div>
            /
            <div className={`${"selectorMonth"}`}>
                <Selector
                    options={months}
                    selected={selectedMonth}
                    setSelected={onChangeMonth}
                    searchItem={searchMonth}
                    setSearchItem={setSearchMonth}
                    border={'1px solid var(--simple-gray)'}
                    placeholder="MM"
                    notOrganized
                    hideIcon={true}
                    showOptionsClick={datePickerIsOpen}
                />
            </div>
            <input className='inputDate' placeholder='MM' onClick={() => generateRandomNumber()} value={selectedMonth.value?.value} />
        </SelectorYearMonth>
    )
}


const SelectorYearMonth = styled.div`
    display: flex;
    padding: 0px;
    align-items: center;
    gap: 5px;

    & .inputDate{
        font-family: 'Inter';
        font-size: 16px;
        width: 80px;
        height: 38px;
        padding: 0 24px;
        border: 1px solid var(--simple-gray);
        border-radius: 9px;
    }

    & input:first-child{
        height: 38px;
        width: 95px;
        padding: 0px 24px;
    }

    & ul{
        width: calc(100% + 50px);
        top: -500%;
    }

    & .selectorMonth input{
        display: none;
    }

    & .selectorMonth ul{
        width: calc(100% + 135px);
        top: -190px !important;
    }
`;

export default index