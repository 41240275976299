import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import {
  START_SAVE_REACTION,
  SAVE_REACTION_SUCCESS,
  SAVE_REACTION_ERROR,
  GET_MY_POSTS_ERROR,
  GET_MY_POSTS_SUCCESS,
  GET_POSTS_BULLETIN_ERROR,
  GET_POSTS_BULLETIN_SUCCESS,
  GET_POSTS_CLUB_ERROR,
  GET_POSTS_CLUB_SUCCESS,
  GET_POSTS_EVENT_ERROR,
  GET_POSTS_EVENT_SUCCESS,
  GET_POSTS_FRIENDS_ERROR,
  GET_POSTS_FRIENDS_SUCCESS,
  GET_POSTS_ORG_ERROR,
  GET_POSTS_ORG_SUCCESS,
  GET_POSTS_SAVED_ERROR,
  GET_POSTS_SAVED_SUCCESS,
  GET_POSTS_WALL_ERROR,
  GET_POSTS_WALL_SUCCESS,
  GET_POST_ALL_ERROR,
  GET_POST_ALL_SUCCESS,
  START_GET_MY_POSTS,
  START_GET_POSTS_BULLETIN,
  START_GET_POSTS_CLUB,
  START_GET_POSTS_EVENT,
  START_GET_POSTS_FRIENDS,
  START_GET_POSTS_ORG,
  START_GET_POSTS_SAVED,
  START_GET_POSTS_WALL,
  START_GET_POST_ALL,
  CLEAN_POST_EXCEPTION,
  START_GET_FEATURED_POSTS,
  GET_FEATURED_POSTS_SUCCESS,
  GET_FEATURED_POSTS_ERROR,
} from "../constants/postConstants";
import { mapPosts } from "../../helpers/postHelper";
import { Backend, MicroServices, getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import IResponseError from "@vibe/sdk/dist/interfaces/IResponseError";
import { IPost, IPostExtended } from "@vibe/sdk/dist/interfaces/Posts/IPost";
import { IGetPostsQuery } from "@vibe/sdk/dist/interfaces/Posts/getPosts";

export const getPostsOrg = (healerUsername: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_POSTS_BULLETIN });

    const query: IGetPostsQuery = {
      reach: 'BULLETIN',
      reach_id: healerUsername,
    }

    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Posts.getPosts(query, undefined, dataSDK.token); // @todo pagination
      })
      .then((results) => {
        if (results.body && 'posts' in results.body) {
          dispatch({
            type: GET_POSTS_BULLETIN_SUCCESS,
            payload: results.body.posts
          })
        }
        else {
          dispatch({
            type: GET_POSTS_BULLETIN_ERROR,
            payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_POSTS_BULLETIN_ERROR,
          payload: error,
        });
      });
  });

export const getPostsHealer = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_MY_POSTS });

    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Posts.getPostsBy(username, undefined, dataSDK.token); // @todo pagination
      })
      .then((results) => {
        if (results.body && 'posts' in results.body) {
          dispatch({
            type: GET_MY_POSTS_SUCCESS,
            payload: results.body.posts
          })
        }
        else {
          dispatch({
            type: GET_MY_POSTS_ERROR,
            payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_MY_POSTS_ERROR,
          payload: error,
        });
      });
  });

export const getPostsHealerFeatured = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_FEATURED_POSTS });

    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Posts.getSavedPosts(username, undefined, dataSDK.token); // @todo pagination
      })
      .then((results) => {
        if (results.body && 'posts' in results.body) {
          dispatch({
            type: GET_FEATURED_POSTS_SUCCESS,
            payload: results.body.posts
          })
        }
        else {
          dispatch({
            type: GET_FEATURED_POSTS_ERROR,
            payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_FEATURED_POSTS_ERROR,
          payload: error,
        });
      });
  });

export const reactToPost = (post_id: string, reaction: string, username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_SAVE_REACTION });
    Backend(
      MicroServices.Posts,
      `/reaction`, {
      method: 'POST',
      body: JSON.stringify(
        {
          username: username,
          post_id: post_id,
          reaction: reaction
        }
      ),
    })
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: SAVE_REACTION_SUCCESS,
          payload: reaction
        })
        resolve(results);
      })
      .catch((error) => {
        dispatch({
          type: SAVE_REACTION_ERROR,
          payload: error,
        });
      });
  });

export const reactToComment = (comment_id: string, reaction: string, username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_SAVE_REACTION });
    Backend(
      MicroServices.Posts,
      `/reaction/comment`, {
      method: 'POST',
      body: JSON.stringify(
        {
          username: username,
          comment_id: comment_id,
          reaction: reaction
        }
      ),
    })
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: SAVE_REACTION_SUCCESS,
          payload: reaction
        })
        resolve(results);
      })
      .catch((error) => {
        dispatch({
          type: SAVE_REACTION_ERROR,
          payload: error,
        });
      });
  });

export const reloadPostsAll = (form = {
  username: '',
  event_id: '',
  club_id: '',
  org_id: '',
  wall_id: '',
}): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_POST_ALL });

    const promises: any[] = [];

    if (form.event_id !== '') {
      promises.push(dispatch(reloadPostsEvent(form.event_id)));
    }

    if (form.club_id !== '') {
      promises.push(dispatch(reloadPostsClub(form.club_id)));
    }

    if (form.org_id !== '') {
      promises.push(dispatch(reloadPostsOrg(form.org_id)));
    }

    if (form.wall_id !== '') {
      promises.push(dispatch(reloadPostsWall(form.wall_id)));
    }

    if (form.username !== '') {
      promises.push(dispatch(reloadPostsSaved(form.username)));
      promises.push(dispatch(reloadPostsFriends(form.username)));
      promises.push(dispatch(getPostsOrg(form.username)));
      promises.push(dispatch(getPostsHealer(form.username)));
    }

    Promise.all(promises)
      .then((results) => {
        dispatch({
          type: GET_POST_ALL_SUCCESS,
          payload: results,
        })
      })
      .catch((error) => {
        console.error('Error on reloadPostAll: ', error)
        dispatch({
          type: GET_POST_ALL_ERROR,
          payload: error,
        });
      });
  });

export const cleanPostsException =
  (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      dispatch({
        type: CLEAN_POST_EXCEPTION
      });
    };

export const reloadPostsFriends = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_POSTS_FRIENDS });
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Posts.getFriendsPosts(username, undefined, dataSDK.token) // @todo pagination
      })
      .then((results) => {
        if (results.body && 'posts' in results.body) {
          dispatch({
            type: GET_POSTS_FRIENDS_SUCCESS,
            payload: results.body.posts
          })
        } else {
          dispatch({
            type: GET_POSTS_FRIENDS_ERROR,
            payload: results.body,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_POSTS_FRIENDS_ERROR,
          payload: error,
        });
      });
  });

export const reloadPostsSaved = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_POSTS_SAVED });
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Posts.getSavedPosts(username, undefined, dataSDK.token) // @todo pagination
      })
      .then((results) => {
        if (results.body && 'posts' in results.body) {
          dispatch({
            type: GET_POSTS_SAVED_SUCCESS,
            payload: results.body.posts
          })
        } else {
          dispatch({
            type: GET_POSTS_SAVED_ERROR,
            payload: results.body,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_POSTS_SAVED_ERROR,
          payload: error,
        });
      });
  });

export const reloadPostsReach = (reach: 'CLUB' | 'EVENT' | 'ORG' | 'WALL' | 'BULLETIN', id: string, types: {
  start: string;
  error: string;
  success: string;
}): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: types.start });
    dispatch(cleanPostsException());
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Posts.getPosts({
          reach,
          reach_id: id,
        }, undefined, dataSDK.token) // @todo pagination
      })
      .then((results) => {
        if (results.body && 'posts' in results.body) {
          dispatch({
            type: types.success,
            payload: results.body.posts
          })
        } else {
          dispatch({
            type: types.error,
            payload: results.body,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: types.error,
          payload: error,
        });
      });
  })

export const reloadPostsEvent = (eventId: string) =>
  reloadPostsReach('EVENT', eventId, {
    start: START_GET_POSTS_EVENT,
    error: GET_POSTS_EVENT_ERROR,
    success: GET_POSTS_EVENT_SUCCESS
  });

export const reloadPostsClub = (clubId: string) =>
  reloadPostsReach('CLUB', clubId, {
    start: START_GET_POSTS_CLUB,
    error: GET_POSTS_CLUB_ERROR,
    success: GET_POSTS_CLUB_SUCCESS
  });

export const reloadPostsOrg = (orgId: string) =>
  reloadPostsReach('ORG', orgId, {
    start: START_GET_POSTS_ORG,
    error: GET_POSTS_CLUB_ERROR,
    success: GET_POSTS_ORG_SUCCESS
  });

export const reloadPostsWall = (username: string) =>
  reloadPostsReach('WALL', username, {
    start: START_GET_POSTS_WALL,
    error: GET_POSTS_WALL_ERROR,
    success: GET_POSTS_WALL_SUCCESS
  });
