import { useState } from "react";
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { WHITE, LAVENDER } from "../../ClubIconsNavBar";
import { BROWN } from "../../SuperCenter/Modalities/Modals/components";
import styled from "styled-components";
import OutsideClickHandler from "react-outside-click-handler";

function ModalComplete({
  onHide,
  title,
  subTitle,
  success,
}: {
  onHide: (type: boolean) => void,
  title: string,
  success: boolean,
  subTitle?: string
}) {
  return (
    <OutsideClickHandler onOutsideClick={() => onHide(false)}>
      <CardWrapper>
        <Card>
            <FontAwesomeIcon
              icon={success ? "circle-check" : "circle-xmark"}
              size='xl'
              color={success ? "var(--accept-green)" : "var(--maroon-flush)"}
            />
            <Title>{title}</Title>
            <FontAwesomeIcon
              icon={faTimes}
              size='xl'
              color={WHITE}
              onClick={() => onHide(false)}
            />
            {subTitle && <SubTitle>{subTitle}</SubTitle>}
        </Card>
      </CardWrapper>
    </OutsideClickHandler>
  );
}

const CardWrapper = styled.div`
  position: fixed;
  z-index: 2070;
  bottom: 10%;
  left: 40%;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Card = styled.div`
  padding: 15px;
  display: grid;
  grid-template-columns: 22px auto 16px;
  grid-template-rows: 26px;
  grid-column-gap: 9px;
  grid-row-gap: 5px;
  background-color: var(--simple-blue-dark);
  border-radius: 8px;
  z-index: 2070;
  justify-items: flex-start;
  text-align: left;

  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
`;

const Title = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: flex-start;
  color: white;
`;

const SubTitle = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: flex-start;
  color: white;
  grid-area: 2 / 2 / 2 / 2;
  min-width: 120px;
`;

export default ModalComplete;