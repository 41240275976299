import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import styled from "styled-components";
import { validatePassword } from "../../helpers/userHelper";

interface IPasswordTip {
    label: string;
    id: number;
    regex: string;
}

interface ISecurityLevel {
    label: string;
    percentage: number;
    color: string;
}

const SecurityBaseData: Record<string, ISecurityLevel> = {
    void: {
        label: "",
        percentage: 0,
        color: "transparent"
    },
    weak: {
        label: "Weak",
        percentage: 0,
        color: "var(--maroon-flush)"
    },
    medium: {
        label: "Medium",
        percentage: 0,
        color: "var(--absolute-zero)"
    },
    strong: {
        label: "Strong",
        percentage: 0,
        color: "var(--accept-green)"
    },
}

const SecurityProgress: Record<string, ISecurityLevel> = {
    "void": {
        ...SecurityBaseData.void
    },
    "weak_20": {
        ...SecurityBaseData.weak,
        percentage: 20,
    },
    "weak_40": {
        ...SecurityBaseData.weak,
        percentage: 40,
    },
    "medium": {
        ...SecurityBaseData.medium,
        percentage: 60,
    },
    "strong_80": {
        ...SecurityBaseData.strong,
        percentage: 80,
    },
    "strong_100": {
        ...SecurityBaseData.strong,
        percentage: 100,
    },
}

export const passwordTips: IPasswordTip[] = [
    {
        label: "Up to 7 characters",
        id: 1,
        regex: ""
    },
    {
        label: "Uppercase letters",
        id: 2,
        regex: "(?=.*[A-Z])"
    },
    {
        label: "Lowercase letters",
        id: 3,
        regex: "(?=.*[a-z])"
    },
    {
        label: "At least one number",
        id: 4,
        regex: "(?=.*[0-9])"
    },
    {
        label: "Special characters",
        id: 5,
        regex: "(?=.*[\-\#\$\.\%\&\@\!\+\=\<\>\*])"
    }
];

const PasswordToolTip = ({
    password,
    setShowModal,
    bottom = false,
}: {
    password: string,
    setShowModal: (state: boolean) => void
    bottom?: boolean
}) => {

    const [passwordCompleteIDs, setPasswordCompleteIDs] = useState<number[]>([]);
    const [securityLevel, setSecurityLevel] = useState<ISecurityLevel>({
        label: "",
        percentage: 0,
        color: "transparent"
    });

    useEffect(() => {
        const completeData = validatePassword(password, true);
        setPasswordCompleteIDs(completeData.completeIDs);
    }, [password])

    useEffect(() => {
        switch (passwordCompleteIDs.length) {
            case 0:
                setSecurityLevel(SecurityProgress.void);
                break;
            case 1:
                setSecurityLevel(SecurityProgress.weak_20);
                break;
            case 2:
                setSecurityLevel(SecurityProgress.weak_40);
                break;
            case 3:
                setSecurityLevel(SecurityProgress.medium);
                break;
            default:
                setSecurityLevel(SecurityProgress.strong_80);
                break;
        }

        if(passwordCompleteIDs.length === 5 && password.length >= 10)
            setSecurityLevel(SecurityProgress.strong_100);

    }, [passwordCompleteIDs])

    return (
        <OutsideClickHandler
            onOutsideClick={() => {
                setShowModal(false);
            }}
        >
            <PasswordWrapper bottom={bottom}>
                <PasswordWrapperCard>
                    <PasswordArrow bottom={bottom}/>
                    <PasswordTitle>Your password must be:</PasswordTitle>

                    {passwordTips.map((tip: IPasswordTip) => (
                        <PasswordTip key={tip.id}>
                            <StyledFontAwesomeIcon
                                icon="circle-check"
                                size='sm'
                                complete={passwordCompleteIDs.includes(tip.id)}
                            />
                            <PasswordLabeL complete={passwordCompleteIDs.includes(tip.id)}>
                                {tip.label}
                            </PasswordLabeL>
                        </PasswordTip>
                    ))}

                    <PasswordTitle>{`Security level: ${securityLevel.label}`}</PasswordTitle>
                    <BaseProgressBar>
                        <VariableProgressBar
                            color={securityLevel.color}
                            percentage={securityLevel.percentage}
                        />
                    </BaseProgressBar>
                </PasswordWrapperCard>
            </PasswordWrapper>
        </OutsideClickHandler>
    );
}

const PasswordWrapper = styled.div`
    width: 200px;
    position: absolute;
    z-index: 2;
    left: 50px;
    bottom: ${({bottom}:{bottom: boolean}) => bottom ? "-240px" : "70px"};

    border-radius: 10px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1), 0px 1px 10px rgba(0, 0, 0, 0.1);
`;

const PasswordTip = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;

    align-items: center;
    justify-items: flex-start;
    margin-left: 20px;
`;

const PasswordTitle = styled.p`
    color: black;
    font-family: 'Inter-bold';
    font-style: bold;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
`;

interface ProgressBarProps {
    color: string;
    percentage: number;
}

const BaseProgressBar = styled.div`
    display: flex;
    height: 5px;
    width: 100%;
    background-color: var(--simple-gray-2);
    border-radius: 6px;
`;

const VariableProgressBar = styled(BaseProgressBar)`
    background-color: ${(props: ProgressBarProps) => props.color};
    width: ${(props: ProgressBarProps) => `${props.percentage}%`};
    transition: all 0.5s;
`;

interface IconProps {
    complete: boolean;
}

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: ${(props: IconProps) => !props.complete ? "var(--simple-gray)" : "var(--simple-green-dark)"};
`;

const PasswordLabeL = styled.p`
    color: ${(props: IconProps) => !props.complete ? "var(--simple-gray)" : "var(--simple-green-dark)"};
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    text-align: left;
`;

const PasswordWrapperCard = styled.div`
    position: relative;

    padding: 20px;
    border-radius: 10px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;

    width: 100%;
    height: 100%;
    background-color: white;
`;
const PasswordArrow = styled.div`
    z-index: -1;
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: white;
    transform: rotate(45deg);
    bottom: ${({bottom}:{bottom: boolean}) => bottom ? "200px" : "-20px"};
    left: 40%;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1), 0px 1px 10px rgba(0, 0, 0, 0.1);
`;

export default PasswordToolTip;