import { faEllipsisVAlt } from "@fortawesome/pro-regular-svg-icons";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LeftPanel } from "../../../containers/AppLayout/components";
import { ChatInterface } from "../../../interfaces/chat/ChatInterface";
import Connection from "../../../interfaces/user/Connection";
import Friend from "../../../interfaces/user/Friend";
import { getUserFriends, getUserPossibleConnections } from "../../../store/actions/connectionActions";
import { RootState } from "../../../store/store";
import { LAVENDER, WHITE } from "../../ClubIconsNavBar";
import { ClickableIcon } from "../../SuperCenter/SuperAdminComponents/basicForSACenter";
import OutsideClickModalChatLeft from "../ChatCenter/components/OutsideClickModalChatLeft";
import { SearchSection, RowFlex, TitleLeftPanel, EllipsisSelector, ChatsSection, ChatLeftWrapper, InputSearch, ChatSearchWrapper, ChatStatusWrapper, NewChatIcon, SelectorConnections, TextBold, TextLight, ColumnFlexNoChat, CreateGroup } from "./components";
import ChatListOption from "./components/ChatListOption";
import ClubsChatLeftPanel from "./components/ClubsChatLeftPanel";
import ConnectionListOption from "./components/ConnectionListOption";
import SelectStatus from "./components/SelectStatus";
import { useTranslation } from "react-i18next";
import ClubListPanel, { TextWrapper } from "./components/ClubListPanel";
import { useNavigate, useParams } from "react-router-dom";
import { ChatServiceInterface } from "../../../interfaces/chat/ChatServiceInterface";
import { ClubChatInfo } from "../../../interfaces/chat/ClubChatInfo";
import GroupChatListOption from "./components/GroupChatListOption";
import styled from "styled-components";

const sortAlphabeticallyOnPropertyChat = (list: any[], property: string, ordSelected: string = 'ASC') => {
    if (list[0] && !(property in list[0]) && property === 'fullname') {
        list = list.map((user: any) => ({ ...user, fullname: `${user.firstname} ${user.lastname}` }))
    }

    if (ordSelected === 'DESC') {
        return list.sort((b, a) => a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0)
    }
    return list.sort((a, b) => a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0)
}

const ChatLeftPanel = ({ ChatServiceInstance }: { ChatServiceInstance: ChatServiceInterface }) => {
    const { id, idGroup, clubId } = useParams();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userInfo = useSelector((state: any) => state.getUser.userInfo);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showConnections, setShowConnections] = useState<boolean>(false);
    const [chatSearched, setChatSearched] = useState<string>('');
    const [optionSelected, setOptionSelected] = useState<string>('Connections');
    const [clubSelected, setClubSelected] = useState<boolean>(true);
    const [addGroup, setAddGroup] = useState<'CLUB' | 'INDIVIDUAL' | ''>('');

    useEffect(() => {
        dispatch<any>(getUserFriends(userInfo.username));
        dispatch<any>(getUserPossibleConnections(userInfo.username));
    }, [])

    useEffect(() => {
        if ((id && idGroup) || (location.pathname.includes('create') && clubId)) {
            setClubSelected(true);
        }
        else {
            setClubSelected(false);
        }
    }, [id, idGroup, location.pathname, clubId])

    useEffect(() => {
        if (location.pathname.includes('create') && clubId) {
            setAddGroup('CLUB');
        }
        else if (location.pathname.includes('create') && !clubId) {
            setAddGroup('INDIVIDUAL');
        }
        else if (!location.pathname.includes('create')) {
            setAddGroup('');
        }
    }, [location.pathname, clubId])

    const getFilteredChats = (listName: ChatInterface[]) => {
        return listName.filter((item: ChatInterface) => {
            if (!chatSearched) return true;
            if (item.from.fullName.toUpperCase().includes(chatSearched) ||
                item.from.fullName.toLowerCase().includes(chatSearched) ||
                item.from.fullName.includes(chatSearched)
            ) {
                return true;
            }
            return false;
        })
    }

    const getFilteredGroups = (listName: ClubChatInfo[]) => {
        return listName.filter((item: ClubChatInfo) => {
            if (!chatSearched) return true;
            if (item.name.toUpperCase().includes(chatSearched) ||
                item.name.toLowerCase().includes(chatSearched) ||
                item.name.includes(chatSearched)
            ) {
                return true;
            }
            return false;
        })
    }

    const getFilteredConnection = (listName: any[]) => {
        return listName.filter((item: any) => {
            if (!chatSearched) return true;

            if (item.fullname && (
                item.fullname.toUpperCase().includes(chatSearched) ||
                item.fullname.toLowerCase().includes(chatSearched) ||
                item.fullname.includes(chatSearched)
            )) {
                return true;
            }

            if (item.firstname && item.lastname && (
                item.firstname.toUpperCase().includes(chatSearched) ||
                item.firstname.toLowerCase().includes(chatSearched) ||
                item.lastname.toUpperCase().includes(chatSearched) ||
                item.lastname.toLowerCase().includes(chatSearched) ||
                item.firstname.includes(chatSearched) ||
                item.lastname.includes(chatSearched)
            )) {
                return true;
            }

            return false;
        })
    }

    const handleSearchChat = (event: React.FormEvent<HTMLInputElement>) => {
        setChatSearched(event.currentTarget.value);
    }

    return (
        <LeftPanel>
            <SearchSection>
                <ChatStatusWrapper>
                    <SelectStatus ChatServiceInstance={ChatServiceInstance} />
                    <NewChatIcon
                        active={showConnections}
                    >
                        <ClickableIcon
                            icon="message-plus"
                            size='lg'
                            color={WHITE}
                            onClick={() => setShowConnections(!showConnections)}
                        />
                    </NewChatIcon>
                </ChatStatusWrapper>
                <ChatSearchWrapper>
                    <ClickableIcon
                        icon="magnifying-glass"
                        size='sm'
                        color={WHITE}
                    />
                    <InputSearch
                        placeholder={t('comm__search')}
                        value={chatSearched}
                        onChange={handleSearchChat}
                    />
                </ChatSearchWrapper>
            </SearchSection>

            {(!showConnections) ? (
                <LeftAligned>
                    <TitleLeftPanel>Communities</TitleLeftPanel>
                    <ClubsChatLeftPanel chatSearched={chatSearched} />
                    {(clubSelected) && (
                        <ClubListPanel
                            addGroup={addGroup}
                            ChatServiceInstance={ChatServiceInstance} />
                    )}

                    <RowFlexPlus>
                        <TextWrapper>
                            <TitleLeftPanel>{`Chats (${ChatServiceInstance.chatsList.length})`}</TitleLeftPanel>
                        </TextWrapper>
                        {(ChatServiceInstance.userGroupsList.length == 0) && (
                            <CreateGroup
                                active={addGroup === 'INDIVIDUAL'}
                            >
                                <ClickableIcon
                                    icon="plus"
                                    size='lg'
                                    color={(addGroup === 'INDIVIDUAL') ? WHITE : 'var(--t-blue)'}
                                    onClick={() => navigate(`./group/create`)}
                                />
                            </CreateGroup>
                        )}
                    </RowFlexPlus>
                    <ChatsSection key={ChatServiceInstance.chatsList.toString()}>
                        {(ChatServiceInstance.chatsList.length > 0) ?
                            getFilteredChats(ChatServiceInstance.chatsList).map((chat: ChatInterface) => (<ChatListOption key={chat.from.username} chat={chat} />)
                            ) : (
                                <ColumnFlexNoChat
                                    onClick={() => setShowConnections(true)}
                                >
                                    <TextBold>{t('chat_startChatting')}</TextBold>
                                    <TextLight>{t('chat_clickToContact')}</TextLight>
                                </ColumnFlexNoChat>
                            )}
                    </ChatsSection>
                    {(ChatServiceInstance.userGroupsList.length > 0) &&
                        (<>
                            <RowFlexPlus>
                                <TextWrapper>
                                    <TitleLeftPanel>{`${t('chat_groupsTitle')} (${ChatServiceInstance.userGroupsList.length})`}</TitleLeftPanel>
                                </TextWrapper>
                                <CreateGroup
                                    active={addGroup === 'INDIVIDUAL'}
                                >
                                    <ClickableIcon
                                        icon="plus"
                                        size='lg'
                                        color={(addGroup === 'INDIVIDUAL') ? WHITE : 'var(--t-blue)'}
                                        onClick={() => navigate(`./group/create`)}
                                    />
                                </CreateGroup>
                            </RowFlexPlus>
                            <ChatsSection key={ChatServiceInstance.userGroupsList.toString()}>
                                {getFilteredGroups(ChatServiceInstance.userGroupsList).map((chat: ClubChatInfo) => (
                                    <GroupChatListOption key={chat.conversationId} chat={chat} />
                                ))}
                            </ChatsSection>
                        </>)}
                </LeftAligned>
            ) : (
                <>
                    <SelectorConnections
                        options={[{
                            name: 'Connections',
                            label: t('navbar__connections'),
                            amount: ChatServiceInstance.connections.length
                        }, {
                            name: 'Friends',
                            label: t('connections__friends'),
                            amount: ChatServiceInstance.connections.filter((conn: Connection) => conn.friends).length
                        }]}
                        optionSelected={optionSelected}
                        onClick={setOptionSelected}
                    />
                    <ChatsSection>
                        {(optionSelected === 'Connections') && (
                            sortAlphabeticallyOnPropertyChat(getFilteredConnection(ChatServiceInstance.connections), 'fullname').map((conn: Connection) => (
                                <ConnectionListOption connection={conn} />
                            ))
                        )}
                        {(optionSelected === 'Friends') && (
                            sortAlphabeticallyOnPropertyChat(getFilteredConnection(ChatServiceInstance.connections.filter((conn: Connection) => conn.friends)), 'fullname').map((conn: Connection) => (
                                <ConnectionListOption connection={conn} />
                            ))
                        )}
                    </ChatsSection>
                </>
            )}
        </LeftPanel>
    );
}

export default ChatLeftPanel;

export const RowFlexPlus = styled(RowFlex)`
    width: calc(100% + 5px);
`;

const LeftAligned = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  gap: 10px;
  align-items: flex-start;
`;