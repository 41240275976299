import styled from "styled-components";
import ClubNavBar from "./ClubNavBar";
import { useContext, useEffect, useState } from "react";
import { ClubType } from "../../../interfaces/club/ClubList";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useClubsList, { getRole } from "../../../hooks/Clubs/useClubsList";
import SelectItem from "../../../interfaces/SelectItem";
import { RootState } from "../../../store/store";
import { unixToDateFormatted } from "../../../helpers/dateHelper";
import BlankTable from "../../BaseComponents/BlankTable";
import Selector from "../../BaseComponents/Selector";
import SearchInput from "../../BaseComponents/SearchInput";
import IPaymentInfo from "../../../interfaces/user/IPaymentINfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { getTCommsByCountry } from "../../../store/actions/TClubsActions";
import { getHealerServices } from "../../../store/actions/modalityActions";
import ModalCommunities from "../../signupsetuphealer/ModalCommunities";
import ModalSetup from "../../signupsetuphealer/ModalSetup";
import TClub from "@vibe/sdk/dist/interfaces/Club/TClubs/TClub/TClub";
import { customService } from "../../signupsetuphealer/Services";
import { customTComm } from "../../signupsetuphealer/InternalTeams";
import useModalitiesSA from "../../../hooks/Superadmin/Modalities/useModalitiesSA";
import IHS from "@vibe/sdk/interfaces/Modalities/hservices/HS";
import { PAGE_SIZES } from "../MyEvents/EventAdmin";
import { ContextSuccess } from "../../../Context";

import { onHideType } from "../../BaseComponents/Modals/ModalUnsaved";
import Modality from "@vibe/sdk/interfaces/Modalities/modalities/Modality/Modality";
import { BaseFieldState, getFieldState } from "../../BaseComponents/utils";
import useClubs from "../../../hooks/Clubs/useClubs";
import { Button, HeaderAdmin } from "../MyOrganization/MyOrganization";
import { StyledFontAwesomeIcon } from "../../HealerProfile/components";
import ModalTemplates from "./ModalTemplates";

const AdminCenterClubs = ({
    clubType
}: {
    clubType: ClubType
}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentSuccess = useContext(ContextSuccess);

    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
    const organization = useSelector((state: RootState) => state.getOrgs.organizationInfo);
    const userRoles = useSelector((state: RootState) => state.getUser.userRoles);
    const userPayment: IPaymentInfo = useSelector((state: RootState) => state.getUser.paymentInfo);
    const templateComms = useSelector((state: RootState) => state.getTClubs.TComm);
    const services = useSelector((state: RootState) => state.getModality.getHealerServices);

    const { modalitiesPages, reloadModalities } = useModalitiesSA({
        pageSizeProp: 1000,
        searchName: '',
    });

    const [search, setSearch] = useState<string>('');
    const [searchPage, setSearchPage] = useState<string>('');
    const [page, setPage] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: '', value: '' }));
    const [templates, setTemplates] = useState<any[]>([]);

    const [showModalCommunity, setShowModalCommunity] = useState<boolean>(false);
    const [showModalTeam, setShowModalTeam] = useState<boolean>(false);
    const [showModalTemplates, setShowModalTemplates] = useState<boolean>(false);

    const [selected, setSelected] = useState<any>({ ...customService });
    const [modalities, setModalities] = useState<Modality[]>([]);
    const [service, setService] = useState<IHS>();

    const {
        clubs,
        handleChangeType,
        handleChangeSearchTerm,
        reloadClubs
    } = useClubs({
        typeP: clubType
    });

    useEffect(() => {
        handleChangeSearchTerm(search);
    }, [search])

    useEffect(() => {
        handleChangeType(clubType)
        setSearch('');
    }, [clubType])

    const perfectClubType = () => {
        return (clubType === 'COMM') ? 'Teams' : 'Communities'
    }

    const clubTypeSingle = () => {
        return (clubType === 'COMM') ? 'Team' : 'Community'
    }

    const options = PAGE_SIZES.map((num) => ({
        value: `${num}`,
        label: `${num} results`,
    }))

    const handleClick = (data: string) => {
        navigate(`../../../../app/clubs/${data}`)
    }

    const handleAddItem = (data: any) => {
        setShowModalTemplates(false);

        if (!userPayment.hasActiveSubscription) {
            currentSuccess?.setModalPayInfo('Ops! To create new ones you need an active subscription.')
            return;
        }

        if (data === 'custom') {
            if (clubType === 'COMM') {
                setSelected({ ...customService });
                setModalities([]);
                setService(undefined);
            }
            else {
                setSelected({ ...customTComm });
            }
        }
        else {
            if (clubType === 'COMM') {
                const mods = (data.modalityIds || [])
                    .map((modIdx: any) =>
                        modalitiesPages[0]
                            .find((mod) => mod.id === modIdx)
                    )
                    .filter((m: any): m is Modality => !!m);
                setModalities(mods);
                setService(data);
            }

            setSelected(data);
        }

        if (clubType === 'COMM') setShowModalTeam(true);
        else setShowModalCommunity(true);
    }

    const handleModalTeam = (show: boolean, value?: TClub) => {
        setShowModalTeam(show);
    }

    const getHeaders = () => {
        return userRoles.includes("SUPER") ? [
            'Name',
            'Organization name',
            'Admin (guides)',
            'Date Added',
            'Last Post',
            'Setup',
            'Modalities',
            'Members',
            'Posts',
            'Events',
            'Open Invites'
        ] : [
            'Name',
            'Date Added',
            'Last Post',
            'Setup',
            'Members',
            'Posts',
            'Events',
            'Open Invites'
        ];
    }

    const getData = () => {
        return userRoles.includes("SUPER") ? clubs.map((club) => ({
            id: club.id,
            labels: [
                club.name,
                club.orgName || '',
                (club.advisorsExtended || []).map(adv => adv.name).join(', '),
                unixToDateFormatted(club.createdAt),
                club.lastPost,
                club.is_setup ? "Yes" : "No",
                `${(club.modalitiesIds || []).length || 0}`,
                `${club.members || 0}`,
                `${club.posts || 0}`,
                `${club.events || 0}`,
                `${club.openInvites || 0}`
            ]
        })) : clubs.map((club) => ({
            id: club.id,
            labels: [
                club.name,
                unixToDateFormatted(club.createdAt),
                club.lastPost,
                club.is_setup ? "Yes" : "No",
                `${club.members || 0}`,
                `${club.posts || 0}`,
                `${club.events || 0}`,
                `${club.openInvites || 0}`
            ]
        }));
    }

    useEffect(() => {
        if (clubType === 'COMM' && templateComms) setTemplates(templateComms)
        else if (services) setTemplates(services)
    }, [clubType, templateComms, services])

    useEffect(() => {
        dispatch(getTCommsByCountry(organization?.countryCode));
        dispatch(getHealerServices(userInfo?.username || ''));
        setPage({
            ...page,
            value: options[0]
        })
    }, [])

    return (
        <ContentAdmin hasTemplates={false/*!userRoles.includes('SUPER')*/}>
            <Label>
                {`Manage ${perfectClubType()}`}
                {!userRoles.includes("SUPER") &&
                    <Button onClick={() => setShowModalTemplates(true)}>
                        {`Create ${perfectClubType()}`}
                        <StyledFontAwesomeIcon
                            size='sm'
                            color='white'
                            icon='plus'
                        />
                    </Button>
                }
            </Label>

            <TableContainer>
                <WrapperRow>
                    <SearchInput
                        filteredItem={search}
                        setFilteredItem={setSearch}
                        border={'1px solid var(--simple-gray)'}
                        placeholder={`Search ${perfectClubType()}`}
                    />
                    {/* <WrapperRow2>
                        <Selector
                            options={options}
                            selected={page}
                            setSelected={setPage}
                            searchItem={searchPage}
                            setSearchItem={setSearchPage}
                            backgroundColor={'var(--t-blue)'}
                            border={'0px'}
                        />
                    </WrapperRow2> */}
                </WrapperRow>
                <Scroll>
                    <BlankTable
                        headerColored
                        border
                        maxWidth={'100px'}
                        backgroundColor={'white'}
                        headers={getHeaders()}
                        items={getData()}
                        onClickRow={handleClick}
                        cursor={'pointer'}
                    />
                </Scroll>
            </TableContainer>

            <ModalCommunities
                showModal={showModalCommunity}
                onHide={setShowModalCommunity}
                data={selected}
                modalities={modalities}
                service={service}
                reloadClubs={reloadClubs}
            />
            <ModalSetup
                showModal={showModalTeam}
                onHide={handleModalTeam}
                data={selected}
                reloadClubs={reloadClubs}
            />
            <ModalTemplates
                templates={templates}
                setTemplate={handleAddItem}
                show={showModalTemplates}
                onHide={() => setShowModalTemplates(false)}
                clubTypeSingle={clubTypeSingle()}
            />
        </ContentAdmin>
    )
}

const Scroll = styled.div`
    display: flex;
    width: 100%;
    overflow-y: scroll;
`;

const IconPlus = styled(FontAwesomeIcon)`
    cursor: pointer;
`;

const Area = styled.div`
    border-bottom: 1px solid var(--simple-gray);
    font-family: 'Inter';
    line-height: 18px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px 10px 10px;
    gap: 8px;
    align-items: center;
    text-align: left;
`

const Label = styled.div`
    color: var(--t-blue);
    font-family: 'Inter-bold';
    font-size: 24px;
    font-weight: bold;
    grid-area: 1 / 1 / 2 / 3;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    width: 100%;
`;

const LabelTemplates = styled.h1`
    color: var(--t-blue);
    font-family: 'Inter-bold';
    font-size: 16px;
    font-weight: bold;
`;

const LabelLittle = styled.h1`
    color: var(--t-blue);
    font-family: 'Inter';
    font-size: 16px;
    font-weight: normal;
`;

const ContentAdmin = styled.div`
    display: grid;
    grid-template-columns: ${({ hasTemplates }: { hasTemplates: boolean }) => hasTemplates ? '20% calc(80% - 20px)' : '100%'};
    grid-template-rows: 40px 1fr;
    grid-column-gap: ${({ hasTemplates }: { hasTemplates: boolean }) => hasTemplates ? '20px' : '0px'};
    grid-row-gap: 10px;
    width: 100%;
    height: 100%;
    padding: 30px;
    justify-items: flex-start;
`;

const Templates = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;
    flex-direction: column;
    background-color: var(--t-off-white);
    height: 100%;
    border-radius: 9px;
    align-items: flex-start;
    padding: 15px;
`;

const TableContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;
    flex-direction: column;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
`;

const WrapperRow = styled.div`
    display: flex;
    width: 100%;
    max-width: 400px;
    gap: 200px;
    align-items: center;
    height: 40px;
`;

const WrapperRow2 = styled(WrapperRow)`
    gap: 10px;
    max-width: 300px;
`;

export default AdminCenterClubs;