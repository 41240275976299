import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getTransactions, toggleBalanceST } from "../../store/actions/GVActions";
import { getBalance } from "../../store/actions/GVActions";
import { useEffect, useMemo, useState } from "react";
import { GVTransactionGet } from "@vibe/sdk/dist/interfaces/Admin/GoodVibes";
import { dateToFormat2, dateToFormat3, unixToDateFormatted } from "../../helpers/dateHelper";

interface TransactionRow {
  name: string;
  amount: string;
  type: boolean;
  date: string;
}

interface TransactionsGrouped {
  date: string;
  transactions: TransactionRow[];
}

type TransactionsMap = {[k: string]: TransactionRow[]};

const parseTransactionToRow = (t: GVTransactionGet, tType: boolean): TransactionRow => ({
  name: t.type,
  amount: `${t.GV}`,
  type: tType,
  date: unixToDateFormatted(t.date, dateToFormat2),
});

const groupTransactions = (ts: GVTransactionGet[], tType: boolean): TransactionsGrouped[] =>
  Object.entries(
    ts
      .sort((a, b) => b.date.localeCompare(a.date))
      .reduce((acc: TransactionsMap, curr) => {
        const key = unixToDateFormatted(curr.date, dateToFormat3);
        if (key in acc) {
          acc[key].push(parseTransactionToRow(curr, tType));
        } else {
          acc[key] = [parseTransactionToRow(curr, tType)];
        }
        return acc;
      }, {})
  )
    .map(([date, transactions]) => ({
      date,
      transactions,
    }));

const extractDates = (creditRows: TransactionsGrouped[], debitRows: TransactionsGrouped[]): string[] => {
  const allDates = [...new Set([
    ...creditRows.map(({ date }) => date),
    ...debitRows.map(({ date }) => date),
  ])]
  return allDates;
}

const mapTransactionsGrouped = (ts: TransactionsGrouped[]): TransactionsMap => ts.reduce((acc: TransactionsMap, curr) => {
  acc[curr.date] = curr.transactions;
  return acc;
}, {});

const mergeTransactionsGrouped = (ts: TransactionsGrouped[], ts2: TransactionsGrouped[]) => {
  const allDates = extractDates(ts, ts2);
  const creditMap = mapTransactionsGrouped(ts);
  const debitMap = mapTransactionsGrouped(ts2);
  const dates: TransactionsMap = {};
  allDates.forEach((d) => dates[d] = [...(d in creditMap ? creditMap[d] : []), ...(d in debitMap ? debitMap[d] : [])]);
  return Object.entries(dates)
    .map(([date, transactions]) => ({
      date,
      transactions,
    }));
}

export type TransactionTabs = 'ALL' | 'DEB' | 'CRE';

const useBalance = () => {
  const [tab, setTab] = useState<TransactionTabs>('ALL');
  const dispatch = useDispatch();
  const balanceVisible = useSelector((state: RootState) => state.getGV.balanceVisible);
  const balance = useSelector((state: RootState) => state.getGV.balance);
  const credits = useSelector((state: RootState) => state.getGV.credits);
  const debits = useSelector((state: RootState) => state.getGV.debits);
  const username = useSelector((state: RootState) => state.getUser.user.username);

  const creditRows = useMemo(() => groupTransactions(credits, true), [credits]);
  const debitRows = useMemo(() => groupTransactions(debits, false), [debits]);
  const allRows = useMemo(() => mergeTransactionsGrouped(creditRows, debitRows), [creditRows, debitRows]);
  const currentRows = useMemo(() => {
    if (tab === 'DEB') return debitRows;
    if (tab === 'CRE') return creditRows;
    return allRows;
  }, [
    creditRows,
    debitRows,
    allRows,
    tab,
  ])

  const toggleBalanceVisible = () => {
    dispatch(toggleBalanceST());
  };

  const reloadBalance = () => {
    dispatch(getBalance(username));
  }

  const reloadTransactions = () => {
    dispatch(getTransactions(username));
  }

  const reloadAll = () => {
    reloadBalance();
    reloadTransactions();
  }

  const handleChangeTab = (t: TransactionTabs) => setTab(t);

  useEffect(() => {
    reloadBalance();
    reloadTransactions();
  }, []);

  return {
    balanceVisible,
    balance,
    credits,
    debits,
    creditRows,
    debitRows,
    allRows,
    currentRows,
    tab,
    toggleBalanceVisible,
    reloadBalance,
    reloadTransactions,
    handleChangeTab,
    reloadAll,
  }
}

export default useBalance;