import { useEffect, useMemo, useState } from "react";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import TClub from "@vibe/sdk/dist/interfaces/Club/TClubs/TClub/TClub";
import { sortAlphabeticallyOnProperty } from "../../containers/Signup/SignupHealerInd";

const useTClubsSA = ({
    pageSizeProp,
    searchName,
}: {
    pageSizeProp: number
    searchName: string
}) => {

    const [pageSize, setPageSize] = useState(pageSizeProp);
    const [tclubs, setTclubs] = useState<TClub[]>([]);
    const [filteredTT, setFilteredTT] = useState<TClub[]>([]);

    const searchTerm = useMemo(() => searchName.toLowerCase(), [searchName]);

    const tclubPages:TClub[][] = useMemo(() => {
        const pages:TClub[][] = [];

        for (let i = 0; i < tclubs.length; i+=pageSize) {
            pages.push(tclubs.slice(i, i+pageSize));
        }

        if (pages.length === 0) {
            pages.push([]);
        }

        return pages;
    }, [pageSize, tclubs])

    const reloadTClubs = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Club.getAllTC(dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode !== 200) {
                    setTclubs([]);
                    return;
                }
                setTclubs(results.body as TClub[]);
            });
    }

    useEffect(() => {
        if (!searchTerm || searchTerm === '') {
            setFilteredTT(sortAlphabeticallyOnProperty(tclubs, 'name'));
            return;
        }
        setFilteredTT(
            (sortAlphabeticallyOnProperty(tclubs, 'name') as TClub[])
                .filter(i => i.name.toLowerCase().includes(searchTerm))
        );
    }, [searchTerm, tclubs]);
    
    useEffect(() => reloadTClubs(), []);

    return {
        tclubPages,
        reloadTClubs,
        setPageSize,
        filteredTT
    }

}

export default useTClubsSA;