import { useState, useRef, useEffect } from 'react';
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faLocationDot } from "@fortawesome/pro-thin-svg-icons";
import { ProcessInterface } from "../../containers/SetupHealer/SetupHealer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { InfoUser } from "../signupsetuphealer";
import { Detail, Name, TitleContent } from "../signupsetuphealerind";
import { IUserGradeResponse } from "@vibe/sdk/dist/interfaces/Admin/User";
import Communities from "./Communities";
import Connections from "./Connections";
import { Scroll_Content_Signup } from "../signupsetuphealer/Introduction";
import { CardSummary } from "../signupsetuphealerind/Summary";
import { cleanHTML, cleanStyleHTML } from "../../helpers/letterHelper";

const Summary = ({
    processData,
    handleTabActive,
}: {
    processData: ProcessInterface,
    handleTabActive: (value: number) => void;
}) => {

    const user: IUserGradeResponse = useSelector((state: RootState | any) => state.getUser.user);
    const userInterests: string[] = useSelector((state: RootState | any) => state.getConnection.getInterestsUser);

    const [height, setHeight] = useState(0)
    const [seeMore, setSeeMore] = useState(false)
    const [lines, setLines] = useState(3)
    const elementRef = useRef(null)

    const sizeRef = (ref: any) => {
        if (ref.current) {
            setHeight(ref.current.offsetHeight);
            if (ref.current.offsetHeight > 40) {
                setLines(2)
            }
        }
    }

    useEffect(() => {
        sizeRef(elementRef)
    }, []);

    return (
        <Scroll_Content_Signup>
            <TitleContent theme={{ align: 'center' }}>Please confirm the information you shared.</TitleContent>
            <ContentSummary>
                <CardSummary theme={{ main: seeMore ? 'active' : '' }}>
                    <HeaderCard>
                        <TitleCardS>Profile</TitleCardS>
                        <ButtonEdit onClick={() => handleTabActive(1)}>Edit</ButtonEdit>
                    </HeaderCard>
                    <CardDetail>
                        <InfoUser>
                            <AvatarInfo src={user.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} />
                            <Detail>
                                <Name>{user.firstname || ''} {user.lastname || ''}</Name>
                                <Location>
                                    <div>
                                        <FontAwesomeIcon
                                            icon={faLocationDot}
                                            size='lg'
                                            color={"black"}
                                            style={{
                                                fontSize: '16px'
                                            }}
                                        />
                                        {user.city ? user.city : user.zipcode || ''},
                                    </div>
                                    <div>
                                        {user.country || ''}
                                    </div>
                                </Location>
                            </Detail>
                        </InfoUser>
                        <Description
                            lines={lines}
                            ref={elementRef}
                            dangerouslySetInnerHTML={{ __html: cleanStyleHTML(user.biography as string ?? '') }}>
                        </Description>
                        {height > 40 &&
                            <SeeMore onClick={() => { setSeeMore(!seeMore); setLines(seeMore ? 2 : 1000) }}>
                                {seeMore ? 'See less' : 'See more'}
                            </SeeMore>
                        }
                    </CardDetail>
                </CardSummary>

                <CardSummary>
                    <HeaderCard>
                        <TitleCardS>Interests</TitleCardS>
                        <ButtonEdit onClick={() => handleTabActive(2)}>Edit</ButtonEdit>
                    </HeaderCard>
                    <ItemsInterests>
                        {(userInterests || []).map(item => (
                            <ItemInt>{item}</ItemInt>
                        ))}
                    </ItemsInterests>
                </CardSummary>

                <CardSummary theme={{ main: seeMore ? 'maxHeight' : '' }}>
                    <HeaderCard>
                        <TitleCardS>Communities</TitleCardS>
                        <ButtonEdit onClick={() => handleTabActive(3)}>Edit</ButtonEdit>
                    </HeaderCard>
                    <Communities
                        processData={processData}
                        isSummary
                    />
                </CardSummary>

                <CardSummary theme={{ main: seeMore ? 'maxHeight' : '' }}>
                    <HeaderCard>
                        <TitleCardS>Connections</TitleCardS>
                        <ButtonEdit onClick={() => handleTabActive(4)}>Edit</ButtonEdit>
                    </HeaderCard>
                    <Connections
                        processData={processData}
                        isSummary
                    />
                </CardSummary>
            </ContentSummary>
        </Scroll_Content_Signup>
    )
}

const Description = styled.p`
    text-align: left;
    margin-top: 2px;
    font-family: 'Inter';
    font-size: 14px;
    color: black;
    line-height: 17px;
    width: 100%;

    box-sizing: border-box;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    -webkit-line-clamp: ${({ lines }: { lines: number }) => lines};
`

const SeeMore = styled.div`
    font-family: 'Inter-Bold';
    font-size: 14px;
    line-height: 17px;
    color: var(--absolute-zero);
    text-align: left;
    cursor: pointer;
    margin-top: 7px;
`

const ContentSummary = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.5fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 500px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
`

const Location = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: initial;
    padding: 4px 0;
    color: black;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
    }
`

const Content = styled.div`
    height: 70%;
    padding-bottom: 200px;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
`

const DescComm = styled.div`
font-style: normal;
font-weight: 400;
font-size: 13px;
font-family: 'Inter';
text-align: left;
padding: 10px 0;
line-height: 17px;
`

const CardItemRw = styled.div`
    width: 48%;
    border: 1px solid var(--mountain-mist);
    background-color: white;
    border-radius: 10px;
    padding: 17px;
    color: black;
    text-align: center;
    height: 224px;
`

const ItemsInterests = styled.div`
    border-radius: 7px;
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    padding: 7px 9px;
    overflow: scroll;
    height: 80%;
    align-content: flex-start;
`

const ItemInt = styled.div`
    background-color: #F5EBE1;
    padding: 10px;
    border-radius: 21px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 7px;
    margin-right: 12px;
    height: fit-content;
`

const CardDetail = styled.div`
    padding: 10px 0;
`

const AvatarInfo = styled.img`
    width: 48px;
    height: 48px;
    min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
    border-radius: 50%;
    object-fit: cover;
`

const HeaderCard = styled.div`
display:flex;
justify-content: space-between;`
const TitleCardS = styled.div`font-family: 'Inter-Bold';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;`
const ButtonEdit = styled.div`
font-family: 'Inter-Bold';
font-size: 14px;
line-height: 19px;
color: var(--absolute-zero);
cursor:pointer;`

const Row1 = styled.div`
    padding: 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
`

const Profile = styled.div`
    background-color: white;
    padding: 12px;
    width: 49%;
    border-radius: 8px;
    margin: 17px 0 0px 0;
    height: 190px;
`

const InterestsList = styled.div`
    background-color: white;
    padding: 12px;
    width: 49%;
    border-radius: 8px;
    margin: 17px 0 0px 0;
    height: 190px;
`
const Inter2 = styled(InterestsList)`
    height: 325px;
`;

const CommunitiesC = styled.div`background-color: white;
padding: 12px;
width: 49%;
border-radius: 8px;
margin: 17px 0 0 0;
height: 280px;`

const ConnectionsC = styled.div`background-color: white;
padding: 12px;
width: 49%;
border-radius: 8px;
margin: 17px 0 0 0;
height: 280px;`


const ListCommRw = styled.div`
    display: flex;
    gap: 5px;
    width: 100%;
    margin-top: 15px;
    overflow: auto;
`

const WrapperConnection = styled.div`
    width: 245px;
    height: 100%;
`;

export default Summary