// REDUCER CONSTANTS
// Get feed
export const START_GET_FEED = 'START_GET_FEED';
export const GET_FEED_SUCCESS = 'GET_FEED_SUCCESS';
export const GET_FEED_ERROR = 'GET_FEED_ERROR';
export const START_SAVE_REACTION = 'START_SAVE_REACTION';
export const SAVE_REACTION_SUCCESS = 'SAVE_REACTION_SUCCESS';
export const SAVE_REACTION_ERROR = 'SAVE_REACTION_ERROR';

// POST RELATED CONSTANTS
// Type of post
export const TYPE_PHOTOS = 10;
export const TYPE_TEXT = 20;
export const TYPE_VIDEO = 30;
export const TYPE_LINK = 40;
export const TYPE_MUSIC = 50;
export const TYPE_FILES = 60;
export const TYPE_EVENT = 70;
export const TYPE_CONFERENCE = 80;

// Type of reaction
export const TYPE_LIKE = 10;
export const TYPE_UNLIKE = 20;
export const TYPE_LOVE = 30;
export const TYPE_SMILE = 40;
export const TYPE_SAD = 50;
export const TYPE_WOW = 60;

// Other club ids
export const MY_WALL_CLUB_ID = -1;
export const SCHOOL_WALL_ID = -2;

//Get posts
export const START_GET_POST_ALL = 'START_GET_POST_ALL';
export const GET_POST_ALL_SUCCESS = 'GET_POST_ALL_SUCCESS';
export const GET_POST_ALL_ERROR = 'GET_POST_ALL_ERROR';

export const START_GET_POSTS_BULLETIN = 'START_GET_POSTS_BULLETIN';
export const GET_POSTS_BULLETIN_SUCCESS = 'GET_POSTS_BULLETIN_SUCCESS';
export const GET_POSTS_BULLETIN_ERROR = 'GET_POSTS_BULLETIN_ERROR';

export const START_GET_POSTS_ORG = 'START_GET_POSTS_ORG';
export const GET_POSTS_ORG_SUCCESS = 'GET_POSTS_ORG_SUCCESS';
export const GET_POSTS_ORG_ERROR = 'GET_POSTS_ORG_ERROR';

export const START_GET_POSTS_FRIENDS = 'START_GET_POSTS_FRIENDS';
export const GET_POSTS_FRIENDS_SUCCESS = 'GET_POSTS_FRIENDS_SUCCESS';
export const GET_POSTS_FRIENDS_ERROR = 'GET_POSTS_FRIENDS_ERROR';

export const START_GET_POSTS_SAVED = 'START_GET_POSTS_SAVED';
export const GET_POSTS_SAVED_SUCCESS = 'GET_POSTS_SAVED_SUCCESS';
export const GET_POSTS_SAVED_ERROR = 'GET_POSTS_SAVED_ERROR';

export const START_GET_MY_POSTS = 'START_GET_MY_POSTS';
export const GET_MY_POSTS_SUCCESS = 'GET_MY_POSTS_SUCCESS';
export const GET_MY_POSTS_ERROR = 'GET_MY_POSTS_ERROR';

export const START_GET_FEATURED_POSTS = 'START_GET_FEATURED_POSTS';
export const GET_FEATURED_POSTS_SUCCESS = 'GET_FEATURED_POSTS_SUCCESS';
export const GET_FEATURED_POSTS_ERROR = 'GET_FEATURED_POSTS_ERROR';

export const START_GET_POSTS_WALL = 'START_GET_POSTS_WALL';
export const GET_POSTS_WALL_SUCCESS = 'GET_POSTS_WALL_SUCCESS';
export const GET_POSTS_WALL_ERROR = 'GET_POSTS_WALL_ERROR';

export const START_GET_POSTS_EVENT = 'START_GET_POSTS_EVENT';
export const GET_POSTS_EVENT_SUCCESS = 'GET_POSTS_EVENT_SUCCESS';
export const GET_POSTS_EVENT_ERROR = 'GET_POSTS_EVENT_ERROR';

export const START_GET_POSTS_CLUB = 'START_GET_POSTS_CLUB';
export const GET_POSTS_CLUB_SUCCESS = 'GET_POSTS_CLUB_SUCCESS';
export const GET_POSTS_CLUB_ERROR = 'GET_POSTS_CLUB_ERROR';

export const CLEAN_POST_EXCEPTION = 'CLEAN_POST_EXCEPTION';

export const LOGOUT_POST = 'LOGOUT_POST';