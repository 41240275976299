import styled, { keyframes } from "styled-components";

export const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  margin-top: 20px;
  -webkit-animation: ${spinAnimation} 2s linear infinite;
  -moz-animation: ${spinAnimation} 2s linear infinite;
  animation: ${spinAnimation} 2s linear infinite;
`;

interface LoaderProps {
  color?: string;
}

export const Loader = styled(Spinner)`
    height: 25px;
    border: 3px solid;
    width: 25px;
    display: block;
    border-radius: 50%;
    border-bottom-color: ${(props: LoaderProps) => props.color && props.color === 'white' ? 'var(--t-blue)' : 'white'};
    border-top-color: #0000ff00;
    border-right-color: #0000ff00;
    border-left-color: #0000ff00;  
    margin-top: -3px !important;
`;

export const LoaderMini = styled(Loader)`
  height: 18px;
  width: 18px;
  border-bottom-color: white;
`;