import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import styled from "styled-components";
import {
    Input, Imp, ButtonAdd, Div_Link
} from "../signupsetuphealerind"
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faSquareCheck, faSearch, faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { faFileLines, faClone } from '@fortawesome/pro-light-svg-icons';
import { UserInClub } from './ModalSetup';
import ErrorField from '../BaseComponents/ErrorField';
import { FileRejection, useDropzone } from 'react-dropzone';
import IResponseError from '@vibe/sdk/dist/interfaces/IResponseError';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { IValidateRosterQuery } from '@vibe/sdk/dist/interfaces/Invite/validateRoster';
import { ContextSuccess } from '../../Context';
import { IClubCode } from '@vibe/sdk/dist/interfaces/Club/clubCode';
import { frontendUrl } from '../../AppConfig';
import ButtonSpinner from '../BaseComponents/ButtonSpinner';
import { getFiltered } from '../signupsetuphealerind/ModalDegrees';
import { formatLink } from '../../helpers/club/clubHelper';
import DatePicker from "react-datepicker";
import { StyledFontAwesomeIcon } from '../HealerProfile/components';
import BlankTable from '../BaseComponents/BlankTable';
import { BaseFieldState, getFieldState } from '../BaseComponents/utils';
import { sortAlphabeticallyOnProperty } from '../../containers/Signup/SignupHealerInd';
import { Loader } from '../BaseComponents/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { SetupInvites } from '../../interfaces/store/inviteState';
import { mockSetupInvites } from './ModalGuide';
import SelectorEmails from '../BaseComponents/SelectorEmails';
import { getEmailsSetup } from '../../store/actions/inviteActions';

export interface PreRoster {
    id: number;
    name: string;
    base64: string;
}

export const ACCEPTED_FILES = {
    'text/csv': ['.csv']
}

const ModalMembers = ({
    showModal,
    onHide,
    individuals,
    rosters,
    codes,
    haveLoading = false,
    loadingSave = false,
    tab = 1
}: {
    showModal: boolean,
    onHide: (type: 'CANCEL' | 'SUBMIT', ind: UserInClub[], rost: string[], codes?: Omit<IClubCode, 'club_id'>[], invites?: number) => void,
    individuals: UserInClub[],
    rosters: string[],
    codes: Omit<IClubCode, "club_id">[],
    haveLoading?: boolean
    loadingSave?: boolean
    tab?: number
}) => {

    const dispatch = useDispatch();
    const currentSuccess = useContext(ContextSuccess);
    const organization = useSelector((state: RootState) => state.getOrgs.organizationInfo);

    const setupUsers: SetupInvites[] = useSelector((state: RootState) => state.getInvites.getInvites_setup);

    const inputMaxMembers = useRef<HTMLInputElement>(null)
    const [tabActive, setTabActive] = useState<number>(tab);

    const fileExampleFile = 'data:text/csv;charset=utf-8,' + encodeURIComponent(`Email,First name,Last name`);
    const [fileDenied, setFileDenied] = useState(false);
    const [checkingFile, setCheckingFile] = useState(false);
    const [fileAccepted, setFileAccepted] = useState(false);
    const [fileRejected, setFileRejected] = useState(false);
    const [notAcceptedMsg, setNotAcceptedMsg] = useState('');

    const [userFirstName, setUserFirstName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userLastName, setUserLastName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userEmail, setUserEmail] = useState<BaseFieldState<SetupInvites>>(getFieldState({ ...mockSetupInvites }));
    const [searchItem, setSearchItem] = useState<string>('');
    const [maxMembers, setMaxMembers] = useState<BaseFieldState<number | undefined>>(getFieldState(undefined));
    const [expirationDate, setExpirationDate] = useState<BaseFieldState<number | undefined>>(getFieldState(undefined));

    const [inds, setInds] = useState<UserInClub[]>([]);
    const [rosts, setRosts] = useState<PreRoster[]>([]);
    const [clubCodes, setClubCodes] = useState<Omit<IClubCode, "club_id">[]>([]);
    const [filteredModality, setFilteredModality] = useState<string>('');
    const [countInvites, setCountInvites] = useState<number>(0);

    const [loading, setLoading] = useState<boolean>(false);

    const convertToBase64 = (file: File) => new Promise<string | ArrayBuffer | null>((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve(reader.result);
        };
        reader.onerror = function (error) {
            reject('Error: ' + error);
        };
    })

    const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        if (acceptedFiles.length === 0 || rejectedFiles.length > 0) {
          setFileDenied(true);
          return;
        }
        setFileDenied(false);
        setCheckingFile(true);
        convertToBase64(acceptedFiles[0])
            .then((bareBase64: string | ArrayBuffer | null) => {
                if (!bareBase64) {
                    console.log('There was an error', bareBase64)
                    return;
                }

                const toSendBase64 = (bareBase64 as string).split('base64,')[1];
                const query: IValidateRosterQuery = {
                    staff: false,
                    countryCode: organization.countryCode
                }

                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        return dataSDK.sdk.Invite.validateRoster(query, toSendBase64, dataSDK.token);
                    })
                    .then((response) => {
                        if (response.statusCode === 200) {
                            const number = response.body
                            setRosts(prevRosts => [
                                ...prevRosts,
                                {
                                    id: prevRosts.length,
                                    name: acceptedFiles[0].name,
                                    base64: toSendBase64
                                }
                            ])
                            setCheckingFile(false);
                            setFileAccepted(false);
                            setFileRejected(false);
                            if (number && 'invites' in number) {
                                setCountInvites(number.invites)
                            }
                        }
                        else {
                            setCheckingFile(false);
                            setFileAccepted(false);
                            setFileRejected(true);
                            setNotAcceptedMsg((response.body as IResponseError).error || (response.body as IResponseError).message || '');
                        }
                    })
            })
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: ACCEPTED_FILES,
    });

    const cleanFields = () => {
        setUserFirstName(getFieldState(''));
        setUserLastName(getFieldState(''));
        setUserEmail(getFieldState({ ...mockSetupInvites }));
        setSearchItem('');
        setExpirationDate(getFieldState(undefined));
        setMaxMembers(getFieldState(undefined));
    }

    const checkData = (): boolean => {
        let hasErrors = false;

        if (!userFirstName.value || userFirstName.value?.length === 0) {
            hasErrors = true;
            setUserFirstName({
                ...userFirstName,
                showError: true,
                error: 'First name is required'
            })
        } else {
            setUserFirstName({
                ...userFirstName,
                showError: false,
                error: ''
            })
        }

        if (!userLastName.value || userLastName.value?.length === 0) {
            hasErrors = true;
            setUserLastName({
                ...userLastName,
                showError: true,
                error: 'Last name is required'
            })
        } else {
            setUserLastName({
                ...userLastName,
                showError: false,
                error: ''
            })
        }

        if (!userEmail.value || userEmail.value?.email.length === 0) {
            hasErrors = true;
            setUserEmail({
                ...userEmail,
                showError: true,
                error: 'Email is required'
            })
        } else {
            setUserEmail({
                ...userEmail,
                showError: false,
                error: ''
            })
        }

        return hasErrors;
    }

    const handleAddMember = () => {
        if (checkData()) return;

        setInds([...inds, {
            firstName: userFirstName.value || '',
            lastName: userLastName.value || '',
            email: userEmail.value?.email || '',
        }])

        cleanFields();
    }

    const handleEraseMember = (email: string) => {
        setInds(inds.filter(adv => adv.email !== email))
    }

    const checkGenerateLinkForm = (): boolean => {
        let hasErrors = false;

        if (!maxMembers.value || maxMembers.value < 0) {
            hasErrors = true;
            setMaxMembers({
                ...maxMembers,
                showError: true,
                error: 'The Number of Members is required'
            })
        } else {
            setMaxMembers({
                ...maxMembers,
                showError: false,
                error: ''
            })
        }

        if (!expirationDate.value) {
            hasErrors = true;
            setExpirationDate({
                ...expirationDate,
                showError: true,
                error: 'Expiration Date is required'
            })
        } else {
            setExpirationDate({
                ...expirationDate,
                showError: false,
                error: ''
            })
        }

        console.log(hasErrors);
        return hasErrors;
    }

    const handleGenerateLink = () => {
        if (checkGenerateLinkForm()) return;
        setLoading(true);
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                const expirationDateToSend = new Date(expirationDate.value!);
                expirationDateToSend.setDate(expirationDateToSend.getDate() + 1)

                return dataSDK.sdk.Club.generateClubCodeWithoutClub({
                    max_members: maxMembers.value!,
                    members_left: 0,
                    expirationTimestamp: expirationDateToSend.valueOf()
                }, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 201 && results.body && 'max_members' in results.body) {
                    setClubCodes([...clubCodes, results.body]);
                    setMaxMembers(getFieldState(undefined));
                    setExpirationDate(getFieldState(undefined));
                    if(inputMaxMembers.current ){
                        inputMaxMembers.current.value = '';
                    }
                }
                else {
                    currentSuccess?.setSuccessData(false, true, "Failed to generate link", "Please try again later.");
                }
            })
            .finally(() => setLoading(false));
    }

    const finalOnHide = (type: 'CANCEL' | 'SUBMIT', ind: UserInClub[], rost: string[], codes: Omit<IClubCode, "club_id">[]) => {
        onHide(type, ind, rost, codes, countInvites);
        setTabActive(tab);
    }

    const copyToClipboard = (toCopy: string) => {
        //Special Code
        navigator.clipboard.writeText(formatLink(toCopy));
        currentSuccess?.setSuccessData(true, true, 'Copied to clipboard');
    }

    const getCorrectRosters = (rosterList: string[]) => {
        return rosterList.map((roster: string, idx: number) => ({
            id: idx,
            base64: roster,
            name: 'roster.csv'
        }))
    }

    const getFiltered = (listName: any[]) => {
        return listName.filter((item: any) => {
            if (!filteredModality) return true;

            if (item.email && (
                item.email.toUpperCase().includes(filteredModality) ||
                item.email.toLowerCase().includes(filteredModality) ||
                item.email.includes(filteredModality)
            )) {
                return true;
            }

            return false;
        })
    }

    const getMembers = () => {
        return (sortAlphabeticallyOnProperty(getFiltered(inds), 'email').map((comm: UserInClub) => ({
            id: comm.email,
            labels: [`${comm.firstName} ${comm.lastName}`, comm.email]
        })))
    }

    const handleUpdateMember = (data: any) => { }

    useEffect(() => {
        console.log('is this running?')
        setInds(individuals)
        setRosts(getCorrectRosters(rosters))
        setClubCodes(codes)
        dispatch(getEmailsSetup(organization.id, organization.status.startsWith('PE')));
    }, [individuals, rosters, codes])

    useEffect(() => {
        if (userEmail.value?.firstName !== '') {
            setUserFirstName({
                ...userFirstName,
                value: userEmail.value?.firstName || ''
            })
        }

        if (userEmail.value?.lastName !== '') {
            setUserLastName({
                ...userLastName,
                value: userEmail.value?.lastName || ''
            })
        }
    }, [userEmail])

    useEffect(() => {
        dispatch(getEmailsSetup(organization.id, organization.status.startsWith('PE')));
    }, [])

    return (
        <>
            <Modal className="modalSetup" show={showModal}>
                <Modal.Header>
                    <Modal.Title>
                        <Label>
                            <BackIcon
                                icon={faArrowLeft}
                                size='lg'
                                color={"black"}
                                onClick={() => finalOnHide('CANCEL', individuals, rosters, codes)}
                            />
                            Invite members
                        </Label>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs>
                        <Tab theme={{ main: tabActive === 1 && 'tabActive' }} onClick={() => { setTabActive(1) }}>Individually</Tab>
                        <Tab theme={{ main: tabActive === 2 && 'tabActive' }} onClick={() => { setTabActive(2) }}>Import Roster</Tab>
                        <Tab theme={{ main: tabActive === 3 && 'tabActive' }} onClick={() => { setTabActive(3) }}>Invitation Link</Tab>
                    </Tabs>
                    {tabActive === 1 ?
                        <ContainerTabs>

                            <Row1>
                                <Labelinput>Email<Imp>*</Imp></Labelinput>
                                <WrapperInput>
                                    <SelectorEmails
                                        options={setupUsers}
                                        selected={userEmail}
                                        setSelected={setUserEmail}
                                        setSearchItem={setSearchItem}
                                        searchItem={searchItem}
                                        backgroundColor={'white'}
                                        border={'1px solid var(--simple-gray)'}
                                        placeholder={' '}
                                    />
                                </WrapperInput>
                                {(userEmail.showError) && (<ErrorField error={userEmail.error} />)}
                            </Row1>
                            <Row1>
                                <Labelinput>First name<Imp>*</Imp></Labelinput>
                                <Input
                                    value={userFirstName.value}
                                    onChange={(e) => setUserFirstName({ ...userFirstName, value: e.currentTarget.value })}
                                />
                                {(userFirstName.showError) && (<ErrorField error={userFirstName.error} />)}
                            </Row1>
                            <Row1>
                                <Labelinput>Last name<Imp>*</Imp></Labelinput>
                                <Input
                                    value={userLastName.value}
                                    onChange={(e) => setUserLastName({ ...userLastName, value: e.currentTarget.value })}
                                />
                                {(userLastName.showError) && (<ErrorField error={userLastName.error} />)}
                            </Row1>
                            <RowButton>
                                <ButtonAdd_Member onClick={handleAddMember}>
                                    Add Member
                                </ButtonAdd_Member>
                            </RowButton>

                            {(inds.length > 0) && (<>
                                <hr />
                                <Row1>
                                    <InputIcon>
                                        <SearchIcon
                                            icon={faSearch}
                                            size='lg'
                                        />
                                        <Input
                                            placeholder="Search by Email Address"
                                            value={filteredModality}
                                            onChange={(e) => setFilteredModality(e.currentTarget.value)}
                                        />
                                    </InputIcon>
                                    <WrapperTable>
                                        <BlankTable
                                            headers={['Name', 'Email']}
                                            items={getMembers()}
                                            actions={['DELETE']}
                                            actionsHandlers={{
                                                handleDelete: handleEraseMember,
                                                handleUpdate: handleUpdateMember
                                            }}
                                            maxWidth={'200px'}
                                        />
                                    </WrapperTable>
                                </Row1>
                            </>)}
                        </ContainerTabs>
                        :
                        tabActive === 2 ?
                            <>
                                <ContainerTabs theme={{ marginTop: '40px', padding: '0 40px' }}>
                                    <LabelBold>Upload invite list file (xls, csv accepted file types)</LabelBold>
                                    <LabelLight>Use following format: Email, First Name, Last Name.</LabelLight>
                                    <LabelUnderline href={fileExampleFile} download="rosterExample.csv">File example</LabelUnderline>
                                    <ContainerDrop>
                                        <input {...getInputProps()} />
                                        <div {...getRootProps()}>
                                            {isDragActive ? (
                                                <>
                                                    <LabelLight>Release to drop</LabelLight>
                                                </>
                                            ) : fileDenied ? (
                                                <>
                                                    <LabelLight>This file is not a CSV</LabelLight>
                                                    <LabelLight>
                                                        <BackIcon
                                                            icon={faFileLines}
                                                            size='lg'
                                                            color={"black"}
                                                        />
                                                        Drag CSV files here
                                                    </LabelLight>
                                                    <LabelLight>Or</LabelLight>
                                                    <LabelUnderline>Browse</LabelUnderline>
                                                </>
                                            ) : checkingFile ? (
                                                <>
                                                    <LabelLight>Checking CSV</LabelLight>
                                                </>
                                            ) : fileAccepted ? (
                                                <>
                                                    <LabelLight>Your file has the correct format. Click Add Roster!</LabelLight>
                                                </>
                                            ) : fileRejected ? (
                                                <>
                                                    <LabelLight>This CSV format is not correct</LabelLight>
                                                    <LabelLight>{notAcceptedMsg}</LabelLight>
                                                    <LabelLight>
                                                        <BackIcon
                                                            icon={faFileLines}
                                                            size='lg'
                                                            color={"black"}
                                                        />
                                                        Drag CSV files here
                                                    </LabelLight>
                                                    <LabelLight>Or</LabelLight>
                                                    <LabelUnderline>Browse</LabelUnderline>
                                                </>
                                            ) : (
                                                <>
                                                    <LabelLight>
                                                        <BackIcon
                                                            icon={faFileLines}
                                                            size='lg'
                                                            color={"black"}
                                                        />
                                                        Drag CSV files here
                                                    </LabelLight>
                                                    <LabelLight>Or</LabelLight>
                                                    <LabelUnderline>Browse</LabelUnderline>
                                                </>
                                            )}
                                        </div>
                                    </ContainerDrop>
                                    {(rosts.length > 0) && (
                                        <ContainerRosters>
                                            {rosts.map((r) => (
                                                <LabelRoster>
                                                    {r.name}
                                                    <ClickerIcon
                                                        icon={'trash'}
                                                        size='lg'
                                                        onClick={() => setRosts(getCorrectRosters(rosters))}
                                                    />
                                                </LabelRoster>
                                            ))}
                                        </ContainerRosters>
                                    )}
                                </ContainerTabs>
                            </>
                            :
                            <>
                                <ContainerTabs>
                                    <Row1>
                                        <Labelinput>Number of Members<Imp>*</Imp></Labelinput>
                                        <Input
                                            ref={inputMaxMembers}
                                            value={maxMembers.value}
                                            onChange={(e) => setMaxMembers({ ...maxMembers, value: parseInt(e.currentTarget.value) })}
                                            type='number'
                                        />
                                        {(maxMembers.showError) && (<ErrorField error={maxMembers.error} />)}
                                    </Row1>
                                    <Row1>
                                        <Labelinput>Expiration date<Imp>*</Imp></Labelinput>
                                        <CalendarWrapper theme={{ main: 'datePickerYearMonth' }}>
                                            <DatePicker
                                                placeholderText={'MM  /  DD  /  YYYY'}
                                                selected={expirationDate.value ? new Date(expirationDate.value) : null}
                                                onChange={(date: Date) => setExpirationDate({
                                                    ...expirationDate,
                                                    value: date.valueOf()
                                                })}
                                                minDate={new Date()}
                                                className={"inputDatePicker"}
                                                showYearDropdown
                                                showMonthDropdown
                                            />
                                            <StyledFontAwesomeIcon
                                                icon={faCalendar}
                                                size='lg'
                                            />
                                        </CalendarWrapper>
                                        {(expirationDate.showError) && (<ErrorField error={expirationDate.error} />)}
                                    </Row1>
                                    <Row1>
                                        <WrapperButton>
                                            <ButtonSpinner
                                                loading={loading}
                                                handleClick={handleGenerateLink}
                                                text={'Generate Link'}
                                                hasIcon={false}
                                                color={"var(--electric-yellow-2)"}
                                            />
                                        </WrapperButton>
                                    </Row1>
                                    {clubCodes.length > 0 && (
                                        <Row1>
                                            <LabelLinks>Invitation Links</LabelLinks>
                                            <BlankTable
                                                headers={['Link', 'Invites', 'Expiration']}
                                                items={(clubCodes || []).map((adv) => ({
                                                    id: adv.code,
                                                    labels: [
                                                        formatLink(adv.code),
                                                        `${adv?.max_members || undefined}`,
                                                        adv?.expirationTimestamp ? new Date(adv.expirationTimestamp).toLocaleDateString() : ''
                                                    ]
                                                }))}
                                                maxWidth={'230px'}
                                                actions={['COPY']}
                                                actionsHandlers={{
                                                    handleCopy: copyToClipboard,
                                                }}
                                            />
                                        </Row1>
                                    )}
                                </ContainerTabs>
                            </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <ButtonCancel onClick={() => finalOnHide('CANCEL', individuals, rosters, codes)}>
                        Cancel
                    </ButtonCancel>
                    <ButtonSave onClick={() => finalOnHide('SUBMIT', inds, rosts.map((r) => r.base64), clubCodes)}>
                        {haveLoading ?
                            (loadingSave ? <Loader /> : 'Save Members') :
                            'Save Members'
                        }
                    </ButtonSave>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const WrapperInput = styled.div`
    height: 40px;
    min-height: 40px;
`;

const WrapperTable = styled.div`
    max-height: calc(100vh - 75vh);
    display: flex;
    overflow: scroll;

    :hover::-webkit-scrollbar{
        display: block;
    }

    ::-webkit-scrollbar {
        width: 5px;
        display: block !important;
    }


    ::-webkit-scrollbar-thumb {
        background: var(--t-blue); 
    }

    ::-webkit-scrollbar-thumb:hover {
        background: var(--t-blue); 
    }
`;

const RowCenter = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const ListServices = styled.div`
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: end;
`


const TableList = styled.table`
    width: 100%;
    background-color: white;
    border-radius: 8px;
`

const Tr = styled.tr`
    & td:last-child,
    & th:last-child {
        width: 85px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

const Th = styled.th`
    vertical-align: middle;
    height: 40px;
    border-bottom: 1px solid var(--simple-gray-2);
    font-family: 'Inter-Bold';
    font-size: 14px;
    padding: 0 15px;
    text-align: left;
`

const Td = styled.td`
    vertical-align: middle;
    height: 40px;
    border-bottom: 1px solid var(--simple-gray-2);
    font-family: 'Inter';
    font-size: 14px; 
    padding: 0 15px; 
    text-align: left;
`

const WrapperButton = styled.div`
    width: 30%;
    margin-top: 15px;
`;

interface PropsButton {
    active: boolean;
}

const ButtonAdd_Member = styled.button`
    width: 30%;
    text-align: center;
    background-color: var(--electric-yellow-2);
    color: white;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 0px;
    cursor: pointer;

    margin-top: 20px;
`

const ButtonAddRoster = styled(ButtonAdd_Member)`
    background-color:${(props: PropsButton) => props.active ? "var(--electric-yellow-2)" : "var(--electric-yellow)"};
    color: ${(props: PropsButton) => props.active ? "white" : "var(--simple-gray)"};
    cursor: ${(props: PropsButton) => props.active ? "pointer" : "not-allowed"};
`;

const Label = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    padding: 15px;
    width: 100%;
    display: flex;
`

const ContainerRosters = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #afafaf;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
    margin-top: 22px;
    text-align: left;

    & ${Label}{
        justify-content: flex-start;
        align-items: flex-start;
    }
`

const ContainerDrop = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #afafaf;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    margin-top: 22px;
    padding: 22px;

    & ${Label}{
        justify-content: center;
        align-items: center;
    }
`

const Row1 = styled.div`
    width: 100%;
    padding: 0 24px;
`

const RowButton = styled(Row1)`
    display: flex;
    justify-items: flex-start;
`;

const ButtonCancel = styled.div`
    width: 49%;
    text-align: center;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 1px solid var(--dark-blue);
    cursor: pointer;
`
const ButtonSave = styled.div`
    width: 49%;
    text-align: center;
    background-color: var(--dark-blue);
    color: white;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 0px;
    display: flex;
    justify-content: center;
    cursor: pointer;
`

const ContainerTabs = styled.div`
    height: 75vh;
    margin-top: ${props => props.theme.marginTop};
    padding: ${props => props.theme.padding};
`

const Tabs = styled.div`
    display: flex;
    justify-content: space-evenly;
    border-radius: 8px;
    overflow: hidden;
    padding: 0 24px;
`

const Tab = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    background-color: var(--simple-gray-2);
    width: 33%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: 'Inter';
    cursor: pointer;

    &.tabActive{
        background-color: #E89F4D;
        color: white;
        font-family: 'Inter-Bold';
    }
`

const Labelinput = styled.div`
    font-family: 'Inter';
    font-size: 14px;
    text-align: left;
    padding: 15px 0;
    width: 100%;
    display: flex;
`

const LabelLinks = styled(Labelinput)`
    font-weight: 700 ;
`;

const LabelBold = styled.div`
    font-family: 'Inter-Bold';
    font-size: 16px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
`

const LabelLight = styled.div`
    font-family: 'Inter';
    font-size: 16px;
    text-align: center;
    padding: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
`

const LabelRoster = styled.div`
    font-family: 'Inter';
    font-size: 16px;
    text-align: center;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const BackIcon = styled(FontAwesomeIcon)`
    font-size: 22px;
    margin-right: 15px;
    color: black;
    margin-left: -15px !important;
    cursor: pointer;
`;

const ClickerIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
`;

const LabelUnderline = styled.a`
    font-family: 'Inter-Bold';
    font-size: 16px;
    text-align: center;
    width: 100%;
    display: flex;
    text-decoration: underline;
    justify-content: center;
    cursor: pointer;
`

const SearchIcon = styled(FontAwesomeIcon)`
    font-size: 12px;
    margin-right: 20px;
    color: var(--black);
    margin-left: 20px;
    cursor: pointer;
`;

const CopyIcon = styled(SearchIcon)`
    font-size: 16px;
    cursor: pointer;
`

const InputIcon = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-radius: 8px;
    align-items: center;
    border: 1px solid var(--simple-gray);
    height: 40px; 
    margin-top: 10px;

    & .react-datepicker-wrapper{
        text-align: left;
    }
    
    & ${Input}{
        border: 0;
        height: 38px;
        background-color: transparent;
        margin: 0 !important;
    } 

    & ${SearchIcon}{
        font-size: 20px;
    }
`

const CalendarWrapper = styled(InputIcon).attrs(props => ({
    className: props.theme.main,
}))`
    margin-top: 0;
    padding-right: 8px;
`;

const Area = styled.div`
    border: 1px solid darkgrey;
    width: fit-content;
    background-color: var(--lavender);
    padding: 7px 12px;
    border-radius: 5px;
    font-family: "Inter";
    font-size: 14px;
    cursor: pointer;

    &:hover {
        border: 1px solid gray;
    }
`
const ListAreas = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    overflow: scroll;
    margin-top: 10px;
    max-height: 200px;
`

const IconPlus = styled(FontAwesomeIcon)`
    color: black;
    font-size: 14px;
    margin-left: 8px
`;

export default ModalMembers