import React, { useState } from 'react'
import styled from "styled-components";

const MenuHover = ({
    title
}: {
    title: string
}) => {

    const [mousePercentage, setMousePercentage] = useState({
        left: 0,
        top: 0
    });

    const [textFill, setTextFill] = useState('#7e8b99');

    function handleMouseMove(ev: any) {
        setTextFill('transperent')
        setMousePercentage({
            left: Math.round((ev.pageX / window.innerWidth) * 100),
            top: Math.round((ev.pageY / window.innerHeight) * 100)
        });
    }

    function handleMouseOut(ev: any) {
        setTextFill('#7e8b99')
        setMousePercentage({
            left: 0,
            top: 0
        });
    }

    return (
        <H1
            onMouseMove={handleMouseMove}
            onMouseOut={handleMouseOut}
            onBlur={handleMouseOut}
            style={{
                background: `radial-gradient(80px at ${mousePercentage.left}% ${mousePercentage.top}%, #c2aa7c, #7e8b99)`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: textFill
            }}
            key={`${mousePercentage.left}% ${mousePercentage.top}%`}
        >
            {title}
        </H1>
    )
}

const H1 = styled.h1`
    padding: 10px 0;

    @media only screen and (max-width: 770px) {
        font-size: 28px;
        padding: 0px;
    }
`

export default MenuHover