import React, { useEffect, useState, useContext } from 'react'
import styled from "styled-components";
import {
    TitleContent
} from "../../components/signupsetuphealerind"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-thin-svg-icons";
import { faSearch, faPen, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { ProcessInterface } from "../../containers/SetupHealer/SetupHealer";
import ModalDegrees from '../../components/signupsetuphealerind/ModalDegrees';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { IEducation } from '@vibe/sdk/dist/interfaces/Roster/UserInfo';
import ModalDelete from '../BaseComponents/Modals/ModalDelete';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { getUserEducation } from '../../store/actions/userActions';
import IResponseError from '@vibe/sdk/dist/interfaces/IResponseError';
import { onHideType } from "../../components/BaseComponents/Modals/ModalUnsaved";
import { ContextSuccess } from "../../Context";
import { sortAlphabeticallyOnProperty } from '../../containers/Signup/SignupHealerInd';
import BlankTable from '../BaseComponents/BlankTable';
import { Scroll_Content_Signup } from '../signupsetuphealer/Introduction';

export const mockEducation = {
    id: '',
    college: '',
    degree_name: '',
    graduation_year: '01/01/2012',
    concentration: '',
    country: '',
    state: '',
    city: '',
    from: 12,
    to: 12,
}

const Education = ({
    processData,
}: {
    processData: ProcessInterface
}) => {

    const dispatch = useDispatch();
    const currentSuccess = useContext(ContextSuccess);
    const education: IEducation[] = useSelector((state: RootState | any) => state.getUser.getUserEducation);
    const username = useSelector((state: RootState | any) => state.getUser.userInfo.username);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
    const [degree, setDegree] = useState<IEducation>({ ...mockEducation });
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<string>();

    const handleModalEdit = (show: boolean, club?: IEducation) => {
        setShowModalEdit(show);
        if (club) setDegree(club);
    }

    const handleDeleteClick = (id: string) => {
        setDeleteId(id);
        setDeleteModal(true);
    }

    useEffect(() => {
        if ((education || []).length > 0) processData.setTabsProcessed([...processData.tabsProcessed, 3]);
    }, [education])

    const onHideDelete = (type: onHideType, id: string) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);
                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        return dataSDK.sdk.Roster.deleteUserEducation(username, id, dataSDK.token)
                    })
                    .then((results: any) => {
                        if (results.statusCode === 201) {
                            dispatch(getUserEducation(username));
                            currentSuccess?.setLoadingModal(false);
                            currentSuccess?.setModalsOff();
                        }
                        else {
                            console.log((results.body as IResponseError).error || (results.body as IResponseError).message)
                        }
                    })
                    .finally(() => {
                        setDeleteModal(false);
                        setDeleteLoading(false);
                        setDeleteId(undefined);
                    })
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                break;

            default:
                break;
        }
    }

    const handleDelete = (data: string) => {
        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => onHideDelete(type, data),
            'Are you sure you want to delete this degree?'
        );
    }

    const handleUpdate = (service_is: string) => {
        const s = education.find(s => s.id === service_is);
        handleModalEdit(true, s);
    }

    return (
        <Scroll_Content_Signup>
            <TitleContent theme={{ align: 'center' }}>Add your degrees...</TitleContent>
            {(education || []).length === 0 ?
                <ListServicesEmp>
                    <Header>
                        <Title>No degrees uploaded yet.</Title>
                        <SubTitle>Share your collage or continuos education degrees.</SubTitle>
                        <Button
                            theme={{ top: '25px' }}
                            onClick={() => setShowModal(true)}>
                            Add new degree
                            <IconPlus
                                icon={faPlus}
                                size='lg'
                                color={"var(--white)"}
                            />
                        </Button>
                    </Header>
                </ListServicesEmp>
                :
                <ListServices>
                    <Button
                        theme={{ top: '0px' }}
                        onClick={() => setShowModal(true)}>
                        Add new degree
                        <IconPlus
                            icon={faPlus}
                            size='lg'
                            color={"var(--white)"}
                        />
                    </Button>
                    <ListServices>
                        <BlankTable
                            headers={['Name', 'Graduation Date', 'University', 'Country']}
                            items={sortAlphabeticallyOnProperty((education || []), 'degree_name').map((service: IEducation) => ({
                                id: service.id,
                                labels: [
                                    service.degree_name,
                                    service.graduation_year || '',
                                    service.college || '',
                                    service.country || '',
                                ],
                            }))}
                            actions={['UPDATE', 'DELETE']}
                            actionsHandlers={{
                                handleUpdate,
                                handleDelete,
                            }}
                            maxWidth={'200px'}
                        />
                    </ListServices>
                </ListServices>

            }
            <ModalDegrees
                showModal={showModal}
                onHide={setShowModal}
                data={degree}
            />
            <ModalDegrees
                showModal={showModalEdit}
                onHide={setShowModalEdit}
                data={degree}
                educationInfo={degree}
                edit
            />
        </Scroll_Content_Signup>
    )
}

const File = styled.div`
    display: flex;
    align-items: center;
`

const IconDrop = styled.img`
    width: 18px;
    margin-left: 10px;
`

const IconPlus = styled(FontAwesomeIcon)`
    font-size: 14px;
    margin-left: 8px;
`;

const Title = styled.p`
    font-family: 'Inter-Bold';
    font-size: 16px;
    padding: 13px 0;
`
const SubTitle = styled.span`
    font-family: 'Inter';
    font-size: 14px;
`

const ListServices = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 10px;
    overflow: scroll;
`

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 210px;
    height: 40px;
    background: var(--electric-yellow-2);
    border-radius: 8px;
    color: var(--white);
    font-family: 'Inter-Bold';
    font-size: 16px;
    margin-top: ${props => props.theme.top || '25px'};
`

const ListServicesEmp = styled.div`
    width: 100%;
    background-color: white;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-image: url(./NoServices.png);
    border-radius: 9px;
    height: 100%;
`

const TableIcon = styled(FontAwesomeIcon)`
    font-size: 18px;
`;

const TableList = styled.table`
    width: 100%;
    background-color: white;
    border-radius: 8px;
    margin-top: 15px;
`

const Tr = styled.tr`
    & td:last-child,
    & th:last-child {
        display: flex;
        justify-content: flex-end;
        gap: 17px;
        align-items: center;
    }
`

const Th = styled.th`
    vertical-align: middle;
    height: 40px;
    border-bottom: 1px solid var(--simple-gray-2);
    font-family: 'Inter-Bold';
    font-size: 14px;
    padding: 0 15px;
    text-align: left;
`

const Td = styled.td`
    vertical-align: middle;
    height: 40px;
    border-bottom: 1px solid var(--simple-gray-2);
    font-family: 'Inter';
    font-size: 14px; 
    padding: 0 15px; 
    text-align: left;
`

export default Education