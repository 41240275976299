import React from "react";
import styled from "styled-components";


function Property1AdminProperty2NoProperty3W({logo} , {logo:any}) {

  return (
    <AvatarAdministrator>
      <MaskGroup src={ logo || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} />
    </AvatarAdministrator>
  );
}

const AvatarAdministrator = styled.div`
  height: 68px;
  display: flex;
  padding: 3.5px 3px;
  justify-content: flex-end;
  align-items: flex-end;
  width: 68px;
  background-color: var(--simple-blue-dark);
  border-radius: 34px;
  min-width: 68px;
  min-height: 68px;
  max-width: 68px;
  max-height: 68px;
  object-fit: cover;
`;

const MaskGroup = styled.img`
  width: 62px;
  height: 61px;
  border-radius: 34px;
  object-fit: cover;
`;

export default Property1AdminProperty2NoProperty3W;
