import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import {
    StyledFontAwesomeIcon,
    Tab
} from '../HealerProfile/components'
import { IUserGradeResponse } from '@vibe/sdk/dist/interfaces/Admin/User';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import Posts from './Posts';
import Communities from '../BaseComponents/Communities/Communities';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { StringLiteralType } from 'typescript';
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FeedRightSection from '../../components/FeedRightSection';
import CardArticle from '../SuperCenter/Articles/CardArticle';
import useArticles from '../../hooks/Superadmin/Articles/useArticles';
import ModalArticle from '../SuperCenter/Articles/ModalArticle';
import { ArticleType } from '@vibe/sdk/dist/interfaces/Modalities/articles/Article';
export interface tabInterface {
    id: number;
    label: string;
    comingSoon: boolean;
    notification?: boolean;
}

const tabsBusiness: tabInterface[] = [
    {
        id: 1,
        label: 'Vibes',
        comingSoon: false,
    },
    {
        id: 2,
        label: 'Communities',
        comingSoon: false,
    },
    {
        id: 5,
        label: 'Events',
        comingSoon: false,
    },
    {
        id: 4,
        label: 'Articles',
        comingSoon: false,
    },
    {
        id: 7,
        label: 'Research',
        comingSoon: false,
    },
    {
        id: 3,
        label: 'Testimonials',
        comingSoon: true,
    },
];

const tabsIndividual: tabInterface[] = [
    {
        id: 1,
        label: 'Vibes',
        comingSoon: false,
    },
    {
        id: 6,
        label: 'Pins',
        comingSoon: false,
    },
    {
        id: 2,
        label: 'Communities',
        comingSoon: false,
    },
    {
        id: 4,
        label: 'Articles',
        comingSoon: false,
    },
    {
        id: 7,
        label: 'Research',
        comingSoon: false,
    },
    {
        id: 3,
        label: 'Testimonials',
        comingSoon: true,
    }
];

const index = ({ tabActiveBack, seeAllSection }: { tabActiveBack: (type: string) => void, seeAllSection: (type: boolean) => void }) => {

    const userProfile: IUserGradeResponse = useSelector((state: RootState) => state.getUser.userProfile);
    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);

    const [tabActive, setTabActive] = useState<number>(1);
    const [tabs, setTabs] = useState<tabInterface[]>([]);
    const [showCreate, setShowCreate] = useState<boolean>(false);
    const [listMS, setListMS] = useState<boolean>(false);
    const [isContributor, setIsContributor] = useState<boolean>(false);
    const [articleTypeCreate, setArticleTypeCreate] = useState<ArticleType>('ART');

    const handleTabActive = (tab: tabInterface) => {
        if (!tab.comingSoon) setTabActive(tab.id)
        tabActiveBack(tab.label)
    }

    const { ArticlesIDFiltered, reloadArticlesID } = useArticles({
        SearchTerm: '',
        type: 'Healer',
        id: userProfile.username,
        artType: tabActive === 7 ? 'RES' : 'ART',
    });

    useEffect(() => {
        checkContributor();
        if (userProfile.roles.includes('INDIVIDUAL')) setTabs(tabsIndividual)
        else setTabs(tabsBusiness)
    }, [userProfile]);

    useEffect(() => {
        reloadArticlesID();
    }, [userProfile.username]);

    const [seeAll, setSeeAll] = useState(false)

    const handleSeeAll = (e: boolean) => {
        setSeeAll(e)
        seeAllSection(e)
    }

    const checkContributor = () => {
        if (userProfile.username && userInfo?.username && userProfile.username === userInfo?.username) {
            // 1/18/24 add articles for my own profile
            setIsContributor(true);
            // getDataSDK()
            //     .then((dataSDK: getDataSDKResponse) => {
            //         return dataSDK.sdk.Modalities.getContributor(userInfo?.username || '', dataSDK.token);
            //     })
            //     .then((results) => {
            //         if (results.statusCode === 200) setIsContributor(true)
            //         else setIsContributor(false)
            //     })
            //     .catch((error) => {
            //         console.error(error)
            //         setIsContributor(false);
            //     });
        }
        else setIsContributor(false);
    }

    const handleCreate = (type: ArticleType) => {
        setArticleTypeCreate(type);
        setShowCreate(true);
    }

    return (
        <>
            <ColumnCenter theme={{ main: seeAll ? `hideMenu contCenter ${userProfile?.roles?.includes('INDIVIDUAL') ? 'individual' : ''}` : `showMenu contCenter ${userProfile?.roles?.includes('INDIVIDUAL') ? 'individual' : ''}` }}>
                {!seeAll ?
                    <Tabs>
                        {tabs.map((tab) => (
                            <Tab
                                theme={{
                                    main: tabActive === tab.id ? 'active ' + tab.label : 'inactive ' + tab.label
                                }}
                                onClick={() => handleTabActive(tab)}
                            >
                                {tab.comingSoon && <ToolTip>Coming soon</ToolTip>}
                                {tab.label}
                            </Tab>
                        ))}
                    </Tabs>
                    :
                    <ButtonBack onClick={() => handleSeeAll(false)}>
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            size='sm'
                            color={"var(--black)"}
                        />
                        Vibes
                    </ButtonBack>
                }
                <WrapperContent theme={{ main: seeAll ? 'hideMenu' : 'showMenu' }}>
                    {(tabActive === 1 || tabActive === 6) ?
                        <Posts handleSeeAll={handleSeeAll} seeAllS={seeAll} tabActive={tabActive} hiddenMenu={seeAll}/>
                        : tabActive === 2 ?
                            <CardPosts>
                                <Communities onClickMS={setListMS} from={'H-Profile'} />
                            </CardPosts>
                            : tabActive === 5 ?
                                <FeedRightSection showInfo={true} />
                                : tabActive === 4 ?
                                    <ArticlesColumn>
                                        {isContributor && <Button onClick={() => handleCreate('ART')}>
                                            Create Article
                                            <StyledFontAwesomeIcon
                                                size='sm'
                                                color='white'
                                                icon='plus'
                                            />
                                        </Button>}
                                        <ArticlesWrap>
                                            {ArticlesIDFiltered.map(ar => (
                                                <CardArticle article={ar} />
                                            ))}
                                        </ArticlesWrap>
                                    </ArticlesColumn>
                                    : tabActive === 7 ?
                                        <ArticlesColumn>
                                            {isContributor && <Button onClick={() => handleCreate('RES')}>
                                                Create Research
                                                <StyledFontAwesomeIcon
                                                    size='sm'
                                                    color='white'
                                                    icon='plus'
                                                />
                                            </Button>}
                                            <ArticlesWrap>
                                                {ArticlesIDFiltered.map(ar => (
                                                    <CardArticle article={ar} />
                                                ))}
                                            </ArticlesWrap>
                                        </ArticlesColumn>
                                        : <></>
                    }
                </WrapperContent>
                <ModalArticle
                    showModal={showCreate}
                    onHide={() => setShowCreate(false)}
                    reload={reloadArticlesID}
                    type={articleTypeCreate}
                    hl={userInfo?.username || ''}
                    isIndividual={userProfile?.roles?.includes('INDIVIDUAL')}
                />
            </ColumnCenter>
        </>
    )
}

export const ArticlesColumn = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    gap: 5px;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    justify-content: flex-start;
`;

const Button = styled.button`
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;

    width: fit-content;
    min-width: 100px;
    height: 40px;
    padding: 10px 20px;
    border-radius: 11px;
    background-color: var(--simple-blue-dark);
    border: 0;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: white;

    cursor: pointer;
`;

const ButtonBack = styled.div`
    display: flex;
    gap: 12px;
    background-color: white;
    padding: 17px;
    align-items: center;
`;

const CardPosts = styled.div`
    width: 100%;
    background-color: white;
    position: relative;
    max-height: calc(100vh - 100px);
    overflow: scroll;
    padding-bottom: 100px;

    @media only screen and (max-width: 900px) {
        background-color: transparent;
        border: 0px solid #bfbfbf;
        padding: 0px 17px;
        border-radius: 8px;
    }

`;

const ArticlesWrap = styled.div`
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 200px);
    display: grid;
    grid-template-columns: repeat(auto-fit,
      minmax(250px, 100%));
    grid-template-rows: repeat(auto-fit, 1fr);
    overflow: scroll;
    grid-row-gap: 15px;
    grid-column-gap: 15px;
    padding-bottom: 47px;

    @media only screen and (max-width: 500px) {
        grid-template-columns: 1fr;
    }
`;

const WrapperContent = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    
    @media only screen and (max-width: 900px) {
        &.hideMenu{
            margin-top: 15px;
        }
    }
`;

const ToolTip = styled.div`
    position: absolute;
    font-family: 'Inter';
    font-size: 10px;
    width: 77px;
    height: 18px;
    border: 1px solid var(--electric-yellow-2);
    border-radius: 3px;
    padding: 0px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    bottom: 40px;
    z-index: 5;
`
const ColumnCenter = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    width: 50%;
    margin-top: 0px;
    background-color: white;
    padding: 0px 15px;
    border-radius: 12px;
    border: 1px solid #aeaeae;

    & ${Tab}{
        font-family: 'Inter';
        font-size: 16px;
        line-height: 130%;
        color: #2E2623;
        padding: 8px 0;
    }
    & ${Tab}.active{
        color: black;
        font-family: 'Inter-Bold';
        border-bottom: 2px solid orange;
    }
    & ${Tab}.Events{
        display: none;
    }
    &.contCenter{
        overflow: hidden;
    }
    @media only screen and (max-width: 900px) {
        width: 100%;
        margin-top: 140px;

        & ${Tab}.Events{
            display: block;
        }

        &.hideMenu{
            margin-top: -22px !important;
            height: 100vh;
        }

        &.individual{
            margin-top: -50px;
        }
    }
`

const Tabs = styled.div`
    display: flex;
    padding: 10px 0;
    gap: 12px;
    z-index: 1027;
    margin-top: 20px;

    @media only screen and (max-width: 900px) {
        overflow: auto;
        display: flex;
        width: 100%;
        padding: 10px 16px 0px 16px;
        background-color: var(--white);
        border-bottom: 1px solid var(--simple-gray);
    }
`

export default index