import { useEffect, useMemo, useState } from "react";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import { sortAlphabeticallyOnProperty } from "../../../containers/Signup/SignupHealerInd";
import Contributor from "@vibe/sdk/interfaces/Modalities/contributors/Contributor";

const useContributorsSA = ({
    SearchTerm
}: {
    SearchTerm: string
}) => {

    const searchParsed = useMemo(() => SearchTerm.toLowerCase(), [SearchTerm]);

    const [Contributors, setContributors] = useState<Contributor[]>([]);
    const [ContributorsFiltered, setContributorsFiltered] = useState<Contributor[]>([]);

    const reloadContributors = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.getAllContributors(dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) {
                    setContributors(results.body as Contributor[]);
                }
                else {
                    setContributors([]);
                }
            });
    };

    useEffect(() => {
        if (!SearchTerm || SearchTerm === '') {
            setContributorsFiltered(sortAlphabeticallyOnProperty(Contributors, 'fullname'));
            return;
        }
        setContributorsFiltered((sortAlphabeticallyOnProperty(Contributors, 'fullname') as Contributor[])
            .filter(l => (l.fullname || '').toLowerCase().includes(searchParsed))
        );
    }, [SearchTerm, Contributors]);

    useEffect(() => {
        reloadContributors();
    }, []);

    return {
        ContributorsFiltered,
        reloadContributors
    };
};

export default useContributorsSA;
