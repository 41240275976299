export const START_GET_HEALER_SERVICES = 'START_GET_HEALER_SERVICES';
export const GET_HEALER_SERVICES_SUCCESS = 'GET_HEALER_SERVICES_SUCCESS';
export const GET_HEALER_SERVICES_ERROR = 'GET_HEALER_SERVICES_ERROR';

export const START_GET_MODALITIES = 'START_GET_MODALITIES';
export const GET_MODALITIES_SUCCESS = 'GET_MODALITIES_SUCCESS';
export const GET_MODALITIES_ERROR = 'GET_MODALITIES_ERROR';

export const START_GET_BE = 'START_GET_BE';
export const GET_BE_SUCCESS = 'GET_BE_SUCCESS';
export const GET_BE_ERROR = 'GET_BE_ERROR';

export const START_GET_TA = 'START_GET_TA';
export const GET_TA_SUCCESS = 'GET_TA_SUCCESS';
export const GET_TA_ERROR = 'GET_TA_ERROR';

export const START_GET_CERTIFICATIONS = 'START_GET_CERTIFICATIONS';
export const GET_CERTIFICATIONS_SUCCESS = 'GET_CERTIFICATIONS_SUCCESS';
export const GET_CERTIFICATIONS_ERROR = 'GET_CERTIFICATIONS_ERROR';

export const START_GET_ACTIVE_MODALITIES = 'START_GET_ACTIVE_MODALITIES';
export const GET_ACTIVE_MODALITIES_SUCCESS = 'GET_ACTIVE_MODALITIES_SUCCESS';
export const GET_ACTIVE_MODALITIES_ERROR = 'GET_ACTIVE_MODALITIES_ERROR';

export const START_GET_ALL_MODALITIES = 'START_GET_ALL_MODALITIES';
export const GET_ALL_MODALITIES_SUCCESS = 'GET_ALL_MODALITIES_SUCCESS';
export const GET_ALL_MODALITIES_ERROR = 'GET_ALL_MODALITIES_ERROR';

export const LOGOUT_MODALITY = 'LOGOUT_MODALITY';