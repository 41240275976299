import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/pro-thin-svg-icons";
import {
    listAdv, IconSeeker, InfoUser,
    Detail, Location, Description, IconHealer,
} from "../../components/signupsetuphealer"
import Slider from '../signupsetup/Slider';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { ContainerInfo_Signup } from '../../containers/Signup/WrapBusiness';
import { SubtitleSetup_v2, TitleSetup_v2 } from '../signupsetup/Column2';
import { IconClicker } from '../Home/HeaderHome/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';

const Column2 = (props: any) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);

    const updateGo = () => {
        const bulletin = searchParams.get('bulletin');
        if (bulletin === 'true') {
            navigate('../../../../../../../app');
        }
        else {
            navigate(`../../../../../../../h/${userInfo?.username || ''}`);
        }
    }

    return (
        <Container_Column_2_Setup>
            {props.isUpdate && <IconClicker
                icon="times"
                color="var(--t-blue)"
                size="2x"
                onClick={updateGo}
            />}

            {props.tabActive === 1 ?
                <ContainerInfo_Signup theme={{ main: 'var(--simple-brown)' }}>
                    <TitleSetup_v2>Introduction</TitleSetup_v2>
                    <SubtitleSetup_v2>To help seekers discover you, please share your organization’s logo, description as well as services offered.</SubtitleSetup_v2>
                    {/* <Slider
                    defaultCard={0}
                    elements={listAdv}
                    renderCard={(user) => (
                        <Card>
                            <InfoUser>
                                <Avatar src={user.avatar} />
                                <Detail>
                                    <Name>{user.name}</Name>
                                    {user.interests.length > 0 && (
                                        <Tags>
                                            {getTwoInterests(user.interests).map((interest) => (
                                                <Tag>{interest}</Tag>
                                            ))}
                                            <Tag>+{user.interests.length - 2}</Tag>
                                        </Tags>
                                    )}

                                    <Icons>
                                        {user.roles.map(role => {
                                            return (role === 'SEEKER') ? <IconSeeker /> : <IconHealer />
                                        })}
                                    </Icons>
                                </Detail>
                            </InfoUser>
                            <Location>
                                <StyledFontAwesomeIcon
                                    icon={faLocationDot}
                                    size='lg'
                                    color={"black"}
                                    style={{
                                        fontSize: '16px'
                                    }}
                                />
                                {user.location}
                            </Location>
                            <Description>
                                {user.description}
                            </Description>
                        </Card>
                    )}
                /> */}
                </ContainerInfo_Signup>
                :
                props.tabActive === 2 ?
                    <ContainerInfo_Signup theme={{ main: 'var(--simple-green)' }}>
                        <TitleSetup_v2>Internal Teams</TitleSetup_v2>
                        <SubtitleSetup_v2>Please create internal teams to support and communicate with groups within your organizations.<br /><br />Internal teams are private and not discoverable by other users. </SubtitleSetup_v2>
                    </ContainerInfo_Signup>
                    :
                    props.tabActive === 3 ?
                        <ContainerInfo_Signup theme={{ main: 'var(--simple-blue-dark)' }}>
                            <TitleSetup_v2 theme={{ main: 'white' }}>
                                Your Services
                            </TitleSetup_v2>
                            <SubtitleSetup_v2 theme={{ main: 'white' }}>
                                Please share the modalities and services you offer.
                            </SubtitleSetup_v2>
                            <SubtitleSetup_v2 theme={{ main: 'white' }}>
                                You can edit the information to align with your practice.
                            </SubtitleSetup_v2>
                        </ContainerInfo_Signup>
                        :
                        props.tabActive === 4 ?
                            <ContainerInfo_Signup theme={{ main: 'var(--electric-yellow)' }}>
                                <TitleSetup_v2>Communities</TitleSetup_v2>
                                <SubtitleSetup_v2>You can build an unlimited number of Vibe communities to collaborate with the different communities of seekers you support. </SubtitleSetup_v2>
                                <SubtitleSetup_v2>Vibe communities will help you support, grow and monetize your seekers.</SubtitleSetup_v2>
                            </ContainerInfo_Signup>
                            :
                            <ContainerInfo_Signup theme={{ main: 'var(--electric-yellow)' }}>
                                <TitleSetup_v2>Congratulations</TitleSetup_v2>
                                <SubtitleSetup_v2>You empowered your Healers and Seekers to raise their vibration and the vibration of the planet.</SubtitleSetup_v2>
                                <SubtitleSetup_v2>Your community of healers and seekers will receive an email invitation to complete their registration.</SubtitleSetup_v2>
                            </ContainerInfo_Signup>}
        </Container_Column_2_Setup>
    )
}

export const Container_Column_2_Setup = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
`;

export const RowInfo_Setup_v2 = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
`;

const Card = styled.div`
    width: 293px;
    border-radius: 10px;
    background-color: white;
    padding: 15px;
    height: fit-content;
    position: relative;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
`

const Avatar = styled.img`
    width: 85px;
    height: 85px;
    min-width: 85px;
  min-height: 85px;
  max-width: 85px;
  max-height: 85px;
    border-radius: 10px;
    object-fit: cover;
`

const Tags = styled.div`
    color: black;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
`

const Tag = styled.p`
    text-align: left;
    font-size: 14px;
    border: 1px solid black;
    padding: 0px 6px;
    border-radius: 16px;
    font-family: 'Inter';
    width: max-content;
    color: black;
    height: 23px;
    align-items: center;
    max-width: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const Icons = styled.div`
    color: black;
    display: flex;
    align-items: center;
    font-size: 9px;
    font-family: 'Inter';
    display: flex;
    gap: 5px;
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: black;
    margin-right: 7px;
`;

const Name = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14.0741px;
    line-height: 17px;
    color: black;
    margin-bottom: 2px;
`

export default Column2
