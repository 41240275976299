import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getUserInfoProfile, updateUser } from "../../../store/actions/userActions";
import { RootState } from "../../../store/store";
import styled from "styled-components";
import { getRolesUsersView } from "../../../helpers/roleHelper";
import { capitalize, cleanHTML, cleanStyleHTML } from "../../../helpers/letterHelper";
import { unixToDateFormatted } from "../../../helpers/dateHelper";
import { ContextSuccess } from "../../../Context";
import ButtonSpinner from "../../BaseComponents/ButtonSpinner";
import { IUpdateUserBody } from "@vibe/sdk/dist/interfaces/Admin/updateUser";
import { BaseFieldState, getFieldState } from "../../BaseComponents/utils";
import useGenders, { GenderOption } from "../../../hooks/users/useGenders";
import SelectorGender from "../../BaseComponents/Genders/SelectorGenders";
import DatePicker from "react-datepicker";
import { ButtonMember } from "../../AdminCenter/MyMembers/AdminCenterMemberView";
import { ContentFlexAdmin, HeaderAdmin } from "../Healers/HealersView";
import Breadcrumb, { CrumbInterface } from "../../BaseComponents/Breadcrumb";
import { FlexColumnWrapper, FlexRowWrapper } from "../Healers/HealerDescription";
import ModalImageEditorCrop from "../../ModalImageEditorCrop";
import AddPhotoPencil from "../../BaseComponents/ProfilePhoto/AddPhotoPencil";
import { LogoInterface } from "../../signupsetuphealer/Introduction";
import { updateLogoOrg, uploadImage } from "../../../store/actions/orgsActions";
import StatusBudged, { StatusType } from "../../BaseComponents/Healers/StatusBudged";
import TextEditor from "../../BaseComponents/TextEditor";

function SeekersUpdate() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id = "" } = useParams();

  const currentSuccess = useContext(ContextSuccess);
  const { GendersReactSelect } = useGenders();

  const userProfile = useSelector((state: RootState) => state.getUser.userProfile);
  const loadingUpdate = useSelector((state: RootState) => state.getUser.loadingUpdateUser);

  const [gender, setGender] = useState<BaseFieldState<GenderOption>>(getFieldState({ label: 'Select a gender', value: 'P' }));
  const [zipcode, setZipcode] = useState<BaseFieldState<string>>(getFieldState(''));
  const [logoOrg, setLogoOrg] = useState<BaseFieldState<LogoInterface>>(getFieldState({ file: '', fileLink: '' }));
  const [newLogo, setNewLogo] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [activeCrop, setActiveCrop] = useState(false);
  const [seeMore, setSeeMore] = useState<boolean>(false);

  const loadData = () => {
    setGender({
      ...gender,
      value: GendersReactSelect.find(gen => userProfile.gender === gen.value),
    });
    setZipcode({
      ...zipcode,
      value: userProfile.zipcode,
    });
    setLogoOrg(getFieldState({
      file: userProfile?.avatar || '',
      fileLink: userProfile?.avatar || ''
    }));
  }

  const handleSave = async () => {

    // let logoLink: string = userProfile?.avatar || '';

    // if (newLogo && logoOrg.value?.file !== '') {
    //   logoLink = await handleSaveImage() as string;
    // }

    const toSend: IUpdateUserBody = {
      gender: gender?.value?.value || 'P',
      // zipcode: zipcode.value || '',
    }

    dispatch<any>(updateUser(id, toSend, 'OA'))
      .then((results: any) => {
        if (results) {
          dispatch(getUserInfoProfile(id));
          navigate(`../../../../superadmin/seekers/${id}/view`);
        }
        else {
          currentSuccess?.setSuccessData(false, true, 'Error updating user');
        }
      })
      .catch((error: any) => currentSuccess?.setSuccessData(false, true, 'Error updating user', error));
  };

  const getCrumbs = (): CrumbInterface[] => {
    if (userProfile.roles.includes('INDIVIDUAL')) return [{
      navigate: () => navigate('../../../../../superadmin/healers'),
      label: 'Healers'
    },
    {
      navigate: () => navigate(`../../../../../superadmin/healers/${id}/view`),
      label: 'Healer Organizations'
    },
    {
      navigate: () => navigate(`../../../../../superadmin/healers/${id}/view`),
      label: `${userProfile?.firstname} ${userProfile?.lastname}` || 'View Healer'
    },
    {
      navigate: () => { },
      label: 'Update healer'
    }];

    return [{
      navigate: () => navigate('../../../../../superadmin/seekers'),
      label: 'Healers'
    },
    {
      navigate: () => navigate(`../../../../../superadmin/seekers/${id}/view`),
      label: `${userProfile?.firstname} ${userProfile?.lastname}` || 'View Healer'
    },
    {
      navigate: () => { },
      label: 'Update seeker'
    }];
  }

  const handleSaveImage = () => new Promise((resolve, reject) => {
    const dataFile = URL.createObjectURL(logoOrg.value?.file as any);
    let contentType: any;
    let file: any;
    fetch(dataFile)
      .then(res => res.blob())
      .then(blob => {
        contentType = dataFile.split(';')[0].split(':')[1];
        const extension = contentType.split('/')[1]
        file = new File([blob], 'Logo' + id, { type: contentType });

        if (file) {
          return dispatch<any>(updateLogoOrg(
            file.type,
            file.name + '.' + extension,
            "school_logo"
          ))
        }
        else {
          return Promise.reject(new Error("File not found"));
        }
      })
      .then((results: any) => {
        if (results.body) {

          dispatch<any>(uploadImage(
            results.body,
            contentType,
            file,
          ))
            .then((result: any) => {
              if (result.status === 200) {
                const urlImage = results.body.split('?')[0];
                setLogoOrg({
                  ...logoOrg,
                  value: {
                    ...logoOrg.value as LogoInterface,
                    fileLink: urlImage,
                    file: urlImage
                  }
                })
                resolve(urlImage);
              }
              else {
                console.log(result)
                reject();
              }
            })
            .catch((error: any) => {
              console.log(error)
              reject();
            })

        }
      })
  });

  function handleCloseModal(e: any, type: any) {
    setShowModal(() => false);
  };

  function handleCropImage(e: any) {
    const dataFile = URL.createObjectURL(logoOrg.value?.file as any);
    let contentType: any;
    let file: any;

    fetch(e)
      .then(res => res.blob())
      .then(blob => {

        // contentType = logo?.value?.file.type;
        contentType = dataFile.split(';')[0].split(':')[1];
        const extension = contentType.split('/')[1]
        file = new File([blob], 'LogoOrg ' + id, { type: contentType });

        setActiveCrop(true)
        setLogoOrg({
          ...logoOrg,
          value: {
            fileLink: '',
            file: file,
          }
        })
      })
  };

  const showModalCrop = (e: BaseFieldState<LogoInterface>, isDelete = false) => {
    setLogoOrg(e);
    if (!isDelete) setShowModal(true);
    setActiveCrop(false);
  }

  useEffect(() => {
    dispatch(getUserInfoProfile(id))
  }, []);

  useEffect(() => {
    dispatch(getUserInfoProfile(id))
  }, [id]);

  useEffect(() => {
    loadData();
  }, [userProfile]);

  return (
    <ContentFlexAdmin>

      <HeaderAdmin>
        <Breadcrumb
          home={() => navigate('../../../../../superadmin')}
          crumbs={getCrumbs()} />
        <WrapperButtons>
          <ButtonCancel onClick={() => navigate(userProfile.roles.includes('INDIVIDUAL') ? `../../../../superadmin/healers/${id}/view` : `../../../../superadmin/seekers/${id}/view`)}>Cancel</ButtonCancel>
          <WrapperButton>
            <ButtonSpinner
              loading={loadingUpdate}
              handleClick={handleSave}
              text={'Update'}
              hasIcon={false}
            />
          </WrapperButton>
        </WrapperButtons>
      </HeaderAdmin>

      <FlexRowWrapper>
        <AddPhotoPencil
          logo={logoOrg}
          setLogo={setLogoOrg}
          hidePen
        />
        <FlexColumnWrapper>
          <Title>{`${userProfile?.firstname} ${userProfile?.lastname}`}</Title>
          <DivOrgStatement>
            <PlaceInfoStatement
              theme={{ main: seeMore ? 'activeMore' : '' }}
              dangerouslySetInnerHTML={{ __html: cleanStyleHTML(userProfile?.biography as string || '') }}
            />
            {userProfile?.biography &&
              <ReadMore onClick={() => setSeeMore(!seeMore)}>{seeMore ? 'Read Less' : 'Read More'}</ReadMore>
            }
          </DivOrgStatement>
        </FlexColumnWrapper>
      </FlexRowWrapper>

      <FlexRowWrapper top>
        <FlexColumnWrapper>
          <Title>Seeker Entrance</Title>
          <FlexRowWrapper>
            <Subtitle>ID</Subtitle>
            {userProfile?.username}
          </FlexRowWrapper>
          <FlexRowWrapper>
            <Subtitle>Status</Subtitle>
            <StatusBudged status={userProfile?.status as StatusType || ''} />
          </FlexRowWrapper>
          <FlexRowWrapper>
            <Subtitle>Created on</Subtitle>
            {unixToDateFormatted(userProfile?.creationDate) || ''}
          </FlexRowWrapper>
          <FlexRowWrapper>
            <Subtitle>Updated on</Subtitle>
            {unixToDateFormatted(userProfile?.creationDate) || '' /*@todo Backend send updateDate */}
          </FlexRowWrapper>
        </FlexColumnWrapper>

        <FlexColumnWrapper>
          <Title>Seeker Information</Title>
          <FlexRowWrapper>
            <Subtitle>Gender</Subtitle>
            <SelectorGender
              gender={gender}
              setGender={setGender}
            />
          </FlexRowWrapper>
          <FlexRowWrapper>
            <Subtitle>Country</Subtitle>
            {userProfile?.country}
          </FlexRowWrapper>
          <FlexRowWrapper>
            <Subtitle>State</Subtitle>
            {userProfile?.state}
          </FlexRowWrapper>
          <FlexRowWrapper>
            <Subtitle>City</Subtitle>
            {userProfile?.city}
          </FlexRowWrapper>
          <FlexRowWrapper>
            <Subtitle>Zip Code</Subtitle>
            {userProfile?.zipcode}
          </FlexRowWrapper>
        </FlexColumnWrapper>
      </FlexRowWrapper>
    </ContentFlexAdmin>
  );
}

const Title = styled.div`
    font-weight: 600;
`;

const Subtitle = styled.div`
    color: var(--simple-gray);
`;

const ButtonCancel = styled(ButtonMember)`
  background-color: var(--t-off-white);
  border: 1px solid var(--t-blue);
  color: var(--t-blue);
  height: 100%;
`;

const WrapperButton = styled.div`
  width: 200px;
  min-width: 100px;
  display: flex;
  height: 100%;
`;

const WrapperButtons = styled.div`
  gap: 20px;
  display: flex;
  height: 50px;
`;

const PlaceInfoNoInput = styled.div`
  align-items: center;
  background-color: var(--t-blue);
  border-radius: 9px;
  display: flex;
  height: 39px;
  width: 100%;
  padding: 0 10px;

  color: white;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: normal;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  text-align: left;
  opacity: 0.7;
`;

const PlaceInfoBirth = styled(PlaceInfoNoInput)`
  opacity: 1;
  background-color: white;
  border: 1px solid var(--simple-gray);

  input {
    background-color: transparent !important;
    color: white !important;
  }
`;

const PlaceInfo = styled.div`
    align-items: center;
    display: flex;
    height: 39px;
    width: 100%;

    font-family: 'Inter';
    font-size: 16px;
    font-weight: normal;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    text-align: left;
`;

const DivOrgStatement = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const ReadMore = styled.div`
    font-family: 'Inter-Bold';
    font-size: 14px;
    line-height: 17px;
    color: var(--absolute-zero);
    text-align: left;
    cursor: pointer;
`

const PlaceInfoStatement = styled(PlaceInfo).attrs(
  props => ({
    className: props.theme.main,
  })
)`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    height: 100%;
    min-height: 39px;
    max-height: 111px;

    overflow: scroll;
    white-space: normal;
    line-height: 22px;

    &.activeMore{
        min-height: auto;
        max-height: max-content;
        display: block;
    }

`;

const WrapperInfo = styled.div`
  display: grid;
  width: 100%;

  color: var(--t-blue);
  font-family: 'Inter-bold';
  font-size: 16px;
  font-weight: bold;

  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  justify-items: flex-start;
  align-items: center;
`;

const WrapperLogo = styled(WrapperInfo)`
  align-items: flex-start;
`;

const LogoPlaceHolder = styled.img`
  width: 180px;
  height: 180px;
  min-width: 180px;
  min-height: 180px;
  max-width: 180px;
  max-height: 180px;
  border-radius: 8px;
  object-fit: cover;
`;

const ContentAdmin = styled.div`
  display: grid;
  width: 100%;
  height: calc(100vh - 80px);

  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 0.1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 20px;

  padding: 50px;
  justify-items: flex-start;
`;

const LabelTitle = styled.div`
  color: var(--t-blue);
  font-family: 'Inter-bold';
  font-size: 24px;
  font-weight: bold;
  grid-area: 1 / 1 / 2 / 3;

  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const LabelInfo = styled.h1`
  color: var(--t-blue);
  font-family: 'Inter-bold';
  font-size: 18px;
  font-weight: bold;
  grid-area: 3 / 1 / 4 / 3;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  align-items: flex-start;
`;

const ColumnA = styled(Column)`
  grid-area: 2 / 1 / 3 / 2;
`;

const ColumnB = styled(Column)`
  grid-area: 2 / 2 / 3 / 3;
`;

const ColumnC = styled(Column)`
  grid-area: 4 / 1 / 5 / 2;
`;

const ColumnD = styled(Column)`
  grid-area: 4 / 2 / 5 / 3;
`;

export default SeekersUpdate;
