import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import useModalitiesSA from "../../../hooks/Superadmin/Modalities/useModalitiesSA";
import styled from "styled-components";
import SearchInput from "../../BaseComponents/SearchInput";
import { ContextSuccess } from "../../../Context";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import { onHideType } from "../../BaseComponents/Modals/ModalUnsaved";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BlankTable from "../../BaseComponents/BlankTable";
import { Title } from "../SuperAdminComponents/basicForSACenter";
import IMessageResponse from "@vibe/sdk/dist/interfaces/IMessageResponse";
import useAllModalities from "../../../hooks/Superadmin/Modalities/useAllModalities";

const ModalitiesView = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const currentSuccess = useContext(ContextSuccess);

    const [ModalityListSearchTerm, setModalityListSearchTerm] = useState('');

    const {
        modalitiesPages,
        reloadModalities,
        setPageSize,
        filteredMod
    } = useAllModalities({
        pageSizeProp: 1000,
        searchName: ModalityListSearchTerm,
    });

    const getNameMapped = (modalityAttrList: any[]) => {
        return modalityAttrList.map(item => item.name);
    }

    const handleUpdate = (data: string) => {
        navigate(`./${data}/update`)
    }

    const handleClickRow = (data: string) => {
        navigate(`./${data}/view`)
    }

    const onHideDelete = (type: onHideType, id: string) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);
                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        return dataSDK.sdk.Modalities.deleteModality(id)
                    })
                    .then((response) => {
                        if (response.statusCode === 201) {
                            currentSuccess?.setSuccessData(true, true, t('toast_modalityDeleted'));
                            reloadModalities();
                            
                        } else {
                            currentSuccess?.setSuccessData(false, true, 'Error deleting modality');
                        }
                    })
                    .finally(() => {
                        currentSuccess?.setLoadingModal(false)
                    })
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                break;

            default:
                break;
        }
        currentSuccess?.setModalsOff();
    }

    const handleDelete = (data: string) => {
        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => onHideDelete(type, data),
            'Do you want to delete this modality?'
        );
    }

    const handleActivate = (data: string) => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.activationModality(data, true, dataSDK.token);
            })
            .then((results) => {
                reloadModalities();
                if (results.statusCode === 201){
                    currentSuccess?.setSuccessData(true, true, 'Modality activated!');
                }else{
                    currentSuccess?.setSuccessData(false, true, 'There was an error');
                }
            })
            .catch((error) => {
                console.error('Error - ', error);
            });
    }

    const handleDeactivate = (data: string) => {
        getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
            return dataSDK.sdk.Modalities.activationModality(data, false, dataSDK.token);
        })
        .then((results) => {
            reloadModalities();
            if (results.statusCode === 201){
                currentSuccess?.setSuccessData(true, true, 'Modality deactivated!');
            }else{
                currentSuccess?.setSuccessData(false, true, 'There was an error');
            }
        });
    }

    return (
        <ContentOrganizations>
            <Title>Modalities and Services</Title>
            <WrapperRow>
                <Button onClick={() => navigate("./create")}>
                    <StyledFontAwesomeIcon
                        size='sm'
                        color='white'
                        icon='plus'
                    />
                    Create Modality
                </Button>
                <WrapInput>
                    <SearchInput
                        filteredItem={ModalityListSearchTerm}
                        setFilteredItem={setModalityListSearchTerm}
                        border={'1px solid var(--simple-gray)'}
                        placeholder={`Search by name`}
                    />
                </WrapInput>
            </WrapperRow>
            <Scroll>
                <BlankTable
                    headerColored
                    border
                    headers={[
                        'Modality',
                        'Associated Modality',
                        'Children Modalities',
                        'Benefits',
                        'Conditions Treated',
                        'Organizations',
                        'Top Healers',
                        'Contributors'
                    ]}
                    items={filteredMod.map(item => ({
                        id: item.id,
                        labels: [
                            item.name,
                            getNameMapped(item.parentMSs || []).join(", "),
                            `${(item.childrenMSs || []).length}`,
                            `${(item.benefits || []).length}`,
                            `${(item.therapeuticAreas || []).length}`,
                            `${(item.professionalOrg || []).length}`,
                            `${(item.topPractitioners || []).length}`,
                            `${(item.contributors || []).length}`,
                        ],
                    }))}
                    actions={['UPDATE', 'DELETE', 'ACTIVATE', 'DEACTIVATE']}
                    actionsHandlers={{
                        handleUpdate,
                        handleDelete,
                        handleActivate,
                        handleDeactivate
                    }}
                    linkLine={[0]}
                    backgroundColor={'white'}
                    maxWidth="200px"
                    cursor="pointer"
                    onClickRow={handleClickRow}
                    actionsConditions={{
                        'ACTIVATE': (id: string) => filteredMod.find((m) => m.id === id)?.active === false,
                        'DEACTIVATE': (id: string) => filteredMod.find((m) => m.id === id)?.active === true,
                    }}
                />
            </Scroll>
        </ContentOrganizations>
    );
}

export default ModalitiesView;

const WrapInput = styled.div`
    width: 50%;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const ContentOrganizations = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  align-items: flex-start;
  gap: 15px;
  flex-direction: column;
`;

const WrapperRow = styled.div`
  display: flex;
  width: 100%;
  gap: 25px;
  align-items: flex-end;
  justify-content: space-between;
`;

const Scroll = styled.div`
  display: flex;
  width: 100%;
  overflow-y: scroll;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 185px;
  min-width: 100px;
  height: 100%;
  padding: 10px 20px;
  border-radius: 11px;
  background-color: var(--simple-blue-dark);
  border: 0;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: white;
  gap: 10px;

  cursor: pointer;
`;