import "./styles.css"
import MemoizedLinkPreview from "./MemoizedLinkPreview"
interface IProps {
    url: string;
    // showX?: boolean;
    onClose?: () => void;

}

function Index(props: IProps) {

    const handleCloseClick = () => {
        (props.onClose ?? (() => {}))();
    }
    return (
        <div className="container-post-link-preview">
            {/* {props.showX && ()} */}
            <span className="close" onClick={handleCloseClick}>&times;</span>
            <MemoizedLinkPreview url={props.url} />
        </div>
    )
}

export default Index;