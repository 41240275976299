import styled from "styled-components";
import { CancelButton, ContentWrapper, SaveButton, Title } from "./Modals/components";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContextSuccess } from "../../../Context";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import Modality from '@vibe/sdk/interfaces/Modalities/modalities/Modality/Modality';
import { Loader } from "../../BaseComponents/Loader";
import useActiveCountry from "../../../hooks/Locations/useActiveCountry";
import TwoColumns from "../../TwoColumns";
import BlankTable from "../../BaseComponents/BlankTable";
import { IOrganizationExtended } from "@vibe/sdk/interfaces/Admin/Organization";
import { cleanHTML, cleanStyleHTML } from "../../../helpers/letterHelper";
import Contributor from "@vibe/sdk/interfaces/Modalities/contributors/Contributor";
import { unixToDateFormatted } from "../../../helpers/dateHelper";

const SeeModality = () => {

    const currentSuccess = useContext(ContextSuccess);
    const navigate = useNavigate();
    const { id } = useParams();

    const { activeCountriesReactSelect } = useActiveCountry();

    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [modality, setModality] = useState<Modality>();

    const getModality = () => {
        setLoadingData(true);

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.getModality(id || '', false, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode !== 200) {
                    currentSuccess?.setSuccessData(false, true, t('toast_errorGettingModality'));
                    console.error(results);
                    return;
                }
                setModality(results.body as Modality);
            })
            .catch((error) => {
                currentSuccess?.setSuccessData(false, true, t('toast_errorGettingModality'), error);
                console.log(error);
            })
            .finally(() => setLoadingData(false));
    }

    useEffect(() => {
        getModality()
    }, []);

    useEffect(() => {
        getModality()
    }, [id]);

    return (
        <ContentAdmin>
            <HeaderWrapper>
                <Title>{modality?.name || 'View Modality'}</Title>
                <UpdateBtn onClick={() => navigate(`../../../../../../superadmin/modalities/${id}/update`)}>
                    Update
                </UpdateBtn>
            </HeaderWrapper>
            {loadingData && <Loader color={"white"} />}


            <ContentView>
                <TwoColumns
                    left={
                        <FormWrapper>
                            <Label>Name</Label>
                            <PlaceInfo>{modality?.name || ''}</PlaceInfo>

                            <Label>Aliases</Label>
                            <PlaceInfo>{(modality?.aliases || []).join(', ')}</PlaceInfo>

                            <Label>Description</Label>
                            <PlaceInfoDescription dangerouslySetInnerHTML={{ __html: cleanStyleHTML(modality?.description || '') }} />

                            <Label>What to Expect</Label>
                            <PlaceInfoDescription dangerouslySetInnerHTML={{ __html: cleanStyleHTML(modality?.expect || '') }} />

                        </FormWrapper>
                    }

                    right={<FormWrapper>
                        <Label>Associated Modalities</Label>
                        <PlaceInfo>{(modality?.parentMSs || []).map(m => m.name).join(', ')}</PlaceInfo>

                        <Label>Children Modalities</Label>
                        <PlaceInfo>{(modality?.childrenMSs || []).map(m => m.name).join(', ')}</PlaceInfo>

                        <Label>Benefits</Label>
                        <PlaceInfo>{(modality?.benefits || []).map(m => m.name).join(', ')}</PlaceInfo>

                        <Label>Conditions Treated</Label>
                        <PlaceInfo>{(modality?.therapeuticAreas || []).map(m => m.name).join(', ')}</PlaceInfo>

                    </FormWrapper>}
                />

                <SubtitleMod>Contributors</SubtitleMod>
                <MinHeigth minheight={(modality?.contributors || []).length > 0 ? "100px" : "10px"}>
                    <BlankTable
                        headers={['Name', 'Assignment date']}
                        items={(modality?.contributors || []).map((p: Contributor) => ({
                            id: p.username,
                            labels: [
                                p.fullname || '',
                                unixToDateFormatted(p.createdAt),
                            ],
                        }))}
                        actions={[]}
                        maxWidth={'300px'}
                    />
                </MinHeigth>

                <SubtitleMod>Professional Organizations</SubtitleMod>
                <MinHeigth minheight={(modality?.professionalOrg || []).length > 0 ? "100px" : "10px"}>
                    <BlankTable
                        headers={['Name', 'Website', 'State', 'Country', 'Zipcode']}
                        items={(modality?.professionalOrg || []).map((p: IOrganizationExtended) => ({
                            id: p.website || '',
                            labels: [
                                p.name || '',
                                p.website || '',
                                p.state || '',
                                p.country || (activeCountriesReactSelect.find((country) => country.value === p.countryCode)?.label) || '',
                                p.zipcode || '',
                            ],
                        }))}
                        actions={[]}
                        maxWidth={'300px'}
                    />
                </MinHeigth>

                <SubtitleMod>Top Healers</SubtitleMod>
                <MinHeigth minheight={(modality?.topPractitioners || []).length > 0 ? "100px" : "10px"}>
                    <BlankTable
                        headers={['Name', 'Website', 'State', 'Country', 'Zipcode']}
                        items={(modality?.topPractitioners || []).map((p: IOrganizationExtended) => ({
                            id: p.website || '',
                            labels: [
                                p.name || '',
                                p.website || '',
                                p.state || '',
                                p.country || (activeCountriesReactSelect.find((country) => country.value === p.countryCode)?.label) || '',
                                p.zipcode || '',
                            ],
                        }))}
                        actions={[]}
                        maxWidth={'300px'}
                    />
                </MinHeigth>
            </ContentView>
        </ContentAdmin>
    )
}

export default SeeModality;

const MinHeigth = styled.div`
    width: 100%;
    min-height: ${({ minheight = "10px" }: { minheight?: string }) => minheight};
    max-height: 400px;
    margin-bottom: 20px;
`;

const SubtitleMod = styled.div`
    margin-top: 20px;
    font-weight: 600;
`;

const PlaceInfo = styled.div`
    align-items: center;
    background-color: var(--t-blue);
    border-radius: 9px;
    display: flex;
    height: fit-content;
    min-height: 40px;
    width: 100%;
    padding: 10px;

    color: white;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: normal;

    overflow: hidden;
    text-overflow: ellipsis;

    text-align: left;
    white-space: break-spaces;
`;

const PlaceInfoDescription = styled(PlaceInfo)`
    display: inline-block;
    //min-height: 130px;
`;

const ContentView = styled(ContentWrapper)`
    align-items: flex-start;
    height: fit-content;
`;

const UpdateBtn = styled(SaveButton)`
    height: 40px;
    align-items: center;
    font-weight: 700;
`;

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  gap: 10px;
  justify-content: space-between;
`;

const Label = styled.div`    
    width: 100%;
    text-align: left;
    margin: 3px 0;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`;

const FormWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const ContentAdmin = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 80px);
  padding: 30px;
  justify-items: flex-start;
  align-items: flex-start;
  flex-direction: column;
  overflow-y: scroll;
  margin-bottom: 30px;
  gap: 10px;
`;