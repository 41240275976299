import styled from "styled-components";
import SignupHealer from "./SignupHealer";
import { useNavigate } from "react-router-dom";

const WrapBusiness = () => {
    const navigate = useNavigate();
    return (
        <Container_Signup>
            <ContainerForm_Signup>
                <SignupHealer />
            </ContainerForm_Signup>
            <ContainerInfo_Signup>
                <TitleSignupWrappers>Raise the Vibration of the Planet</TitleSignupWrappers>
                <SubtitleSignupWrappers>Your commitment to your practice and those you serve deserves to be amplified among seekers who are actively looking to live an optimal and enlightened life.</SubtitleSignupWrappers>
                <SubtitleSignupWrappers>With Vibe, you can easily support, grow, and now monetize your communities.</SubtitleSignupWrappers>
            </ContainerInfo_Signup>
        </Container_Signup>
    )
}

export const ContainerInfo_Signup = styled.div`
    background-color: ${props => props.theme.main || 'var(--simple-blue-dark)'};
    text-align: left;
    color: white;
    width: 100%;
    height: 100%;
    padding: 33px;
    background-image: url(../slideSignUp.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    @media only screen and (max-width: 500px) {
        display: none;
    }

`;

export const TitleSignupWrappers = styled.h1`
    font-family: 'Source Serif 4';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: normal;
`;

export const SubtitleSignupWrappers = styled.h2`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.2px;
`;

const Text = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
`;

export const Logo = styled.div`
    width: 110px;
    height: 50px;
    min-width: 110px;
    min-height: 50px;
    margin-left: ${({isSetup}:{isSetup: boolean}) => isSetup? '3px' : '30px'};
    background-image: url("../logoVibe.png");
    background-size: contain;
    background-repeat: no-repeat;
    grid-area: 1 / 1 / 2 / 2;
    cursor: pointer;
`;

export const Container_Signup = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 360px;
    grid-template-rows: 1fr;
    overflow: hidden;
    padding: 14px 136px 0px 136px;

    @media only screen and (max-width: 1000px) {
        grid-template-columns: 1fr;
        width: 100%;
        height: fit-content;
        padding:  0px;
    }
`;

export const ContainerForm_Signup = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;

    @media only screen and (max-width: 500px) {
        gap: 15px;
        padding-top: 15px;
    }
`;

export const ContainerForm_Signup_Invite = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 120px);
    padding-top: 24px;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 24px 30px 0px 136px;
    overflow: scroll;

    @media only screen and (max-width: 500px) {
        gap: 15px;
        padding-top: 15px;
    }
`;

export default WrapBusiness;