
import "./styles.css";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProfileViewNavBar from "../ProfileViewNavBar";
import { getUserFriends, getUserPendingRequests, getUserPossibleConnections, getUserSentRequests } from "../../store/actions/connectionActions";
import { Post } from "../EventFeed/PostInterface";
import ProfileAccessPhoto from "../ProfileAccessPhoto";
import ProfileInformation from "../ProfileInformation";
import Posts from "../../containers/Posts";
import ProfileSettings from "../ProfileSettings/ProfileSettings";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";
import { getUserInfoProfile } from "../../store/actions/userActions";
import PostsNew from "../../containers/Posts/PostsNew";
import { CenterInsideWrap } from "../../containers/Bulletin";

const Profile = ({ ChatServiceInstance }: { ChatServiceInstance: ChatServiceInterface }) => {
  const [isUserProfile, setIsUserProfile] = useState(false);
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);

  const userInfo = useSelector((state: any) => state.getUser.userInfo);

  const { username } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname.includes('userProfile')) {
      setIsUserProfile(true);
    }
    else {
      setIsUserProfile(false);
    }

    setSettingsVisible(false);
    setEditVisible(false);

  }, [username]);

  function backInfo() {
    setSettingsVisible(false)
  }

  useEffect(() => {
    dispatch(getUserInfoProfile(username || ''));
  }, []);

  useEffect(() => {
    dispatch(getUserInfoProfile(username || ''));
  }, [username]);

  return (
    <CenterInsideWrap>
      <ProfileAccessPhoto
        isUserProfile={isUserProfile}
        setSettingsVisible={setSettingsVisible}
        setEditVisible={setEditVisible}
        settingsVisible={settingsVisible}
        editVisible={editVisible}
      />
      {(isUserProfile) ? (
        (settingsVisible) ? (
          <ProfileSettings backInfo={backInfo} />
        ) : (
          <>
            <ProfileInformation
              isUserProfile={isUserProfile}
              setEditVisible={setEditVisible}
              editVisible={editVisible}
            />
            {(!editVisible) && (
              isUserProfile ? (
                <PostsNew postRequired="MY_PROFILE" />
              ) : (
                username && <PostsNew postRequired="CONNECTION" username={username} />
              )
            )}

          </>
        )) : (
        <ProfileViewNavBar ChatServiceInstance={ChatServiceInstance} />
      )}

    </CenterInsideWrap>
  );
}

export default Profile;
