import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/pro-thin-svg-icons";
import { FileRejection, useDropzone } from "react-dropzone";
import styled from "styled-components";
import { ProcessInterface } from "../../../containers/SetupHealer/SetupHealer";
import { LogoInterface } from "../../signupsetuphealer/Introduction";
import { BaseFieldState } from "../utils";
import { Imp } from "../../signupsetuphealerind";
import ErrorField from "../ErrorField";

const AddPhotoEvent = ({
    title,
    logo,
    setLogo,
    setNewLogo,
    processData,
    notSetup = false,
    vertical = false,
    required = false,
    hasTitle_notSetup = false
}: {
    title: string
    logo: BaseFieldState<LogoInterface>
    setLogo: (value: BaseFieldState<LogoInterface>, isDelete?: boolean) => void
    setNewLogo?: (value: boolean) => void
    processData?: ProcessInterface
    notSetup?: boolean
    vertical?: boolean
    required?: boolean
    hasTitle_notSetup?: boolean
}) => {

    const onDrop = (acceptedFile: File[], rejectedFiles: FileRejection[]) => {
        if(rejectedFiles.length > 0) return;
        setLogo({
            ...logo,
            value: {
                fileLink: '',
                file: acceptedFile[0],
            }
        })
        if (setNewLogo) setNewLogo(true);
        if (processData) processData.setNewData(true);
    }

    const handleDelete = () => {
        setLogo({
            ...logo,
            value: {
                fileLink: '',
                file: '',
            }
        }, true)
        if (setNewLogo) setNewLogo(true);
        if (processData) processData.setNewData(true);
    }

    const getImgObject = () => {
        if (typeof logo.value?.file === 'string' && logo.value?.file.length > 0) {
            return logo.value?.file;
        }
        return URL.createObjectURL(logo.value?.file as any);
    }

    const maxSize = 2 * 1024 * 1024;
    const { getRootProps, getInputProps, open, isDragReject, fileRejections } = useDropzone({
        onDrop,
        noClick: true,
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
        maxSize
    })
    const isFileTooLarge = fileRejections.some(file => file.file.size > maxSize);

    return (
        <ContainerPhoto noSetup={notSetup}>
            {title &&
                <>
                    {(!notSetup) && <SubText>{title} {required && <Imp>*</Imp>}</SubText>}
                    {(hasTitle_notSetup) && <SubText>{title} {required && <Imp>*</Imp>}</SubText>}
                </>
            }
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {(!logo.value?.file || logo.value?.file?.length === 0 || logo.value?.file === 'cover') ? (
                    <DropPhoto onClick={open} padding={'18px'} active={false} noSetup={notSetup} vertical={vertical}>
                        <ContIconDrop>
                            <IconDrop
                                icon={faImage}
                                size='lg'
                                color={"black"}
                                style={{
                                    fontSize: '16px'
                                }}
                            />
                        </ContIconDrop>
                        <TextDropBold>
                            Upload your cover event
                        </TextDropBold>
                        <TextDrop>
                            JPG or PNG (2mb max)
                        </TextDrop>
                    </DropPhoto>
                ) : (
                    <DropPhoto padding={'13px'} active={true} noSetup={notSetup} vertical={vertical}>
                        <DividePhoto onClick={open}>
                            <LogoImg
                                src={getImgObject()}
                            />
                        </DividePhoto>
                        <DividePhoto>
                            <TextDrop_Trash onClick={handleDelete}>
                                <IconDrop
                                    icon='trash'
                                    size='sm'
                                    color={"var(--maroon-flush)"}
                                />
                                Delete
                            </TextDrop_Trash>
                            <TextDrop onClick={open}>
                                <TextDrop_Trash>
                                    <IconDrop
                                        icon='arrows-rotate'
                                        size='sm'
                                        color={"var(--simple-gray)"}
                                    />
                                    Replace
                                </TextDrop_Trash>
                                <SubtextDrop>
                                    JPG or PNG (2mb max)
                                </SubtextDrop>
                            </TextDrop>
                        </DividePhoto>
                    </DropPhoto>
                )}
                {isDragReject && <ErrorField error={'Sorry, only PNG and JPEG files are allowed'} />}
                {isFileTooLarge && <ErrorField error={'File is too large (max 2MB)'} />}
            </div>
        </ContainerPhoto>
    )
}

const DividePhoto = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap:10px;
`;

const LogoImg = styled.img`
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    max-width: 100px;
    max-height: 100px;
    border: 1px solid var(--simple-gray);
    border-radius: 12px;
    object-fit: cover;
`;

const ContainerPhoto = styled.div`
    text-align: initial;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: ${({noSetup}:{noSetup: boolean}) => noSetup ? 'auto': '155px'};
`

interface DropPhotoProps {
    padding: string
    active: boolean
    noSetup: boolean
    vertical: boolean
}

const DropPhoto = styled.div`
    width: 100%;
    margin: 0px 0 0px 0;
    text-align: initial;
    border-radius: 12px;
    background-color: #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${(props: DropPhotoProps) => props.padding};
    gap:10px;
    height: 144px;
    flex-direction: ${(props: DropPhotoProps) => props.active ? 'row': 'column'};
    cursor: pointer;
`

const ContIconDrop = styled.div`
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const TextDrop = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    width: 100%;
    color: var(--simple-brown-dark);
    text-align: center;
    display:flex;
    flex-direction: column;
    gap: 3px;
`

const TextDropBold = styled(TextDrop)`
    font-family: 'Inter-Bold';
    font-weight: 500;
`

const TextDrop_Trash = styled(TextDrop)`
    flex-direction: row;
`;

const SubtextDrop = styled.span`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: var(--onyx);
`

const IconDrop = styled(FontAwesomeIcon)`
    font-size: 18px;
`;

const TextBlue = styled.p`
    color: blue;
`

export const SubText = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
`

export default AddPhotoEvent;