import "./styles.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resolve } from "dns";
import ModalConnections from "../ConnectionSelectBar/ModalConnections";
import { getUserFriends, getUserPossibleConnections } from "../../store/actions/connectionActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import ModalConnection from "../ModalConnection";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";
import { BROWN } from "../SuperCenter/Modalities/Modals/components";
import styled from "styled-components";
import SearchInput from "../BaseComponents/SearchInput";
import { ClubCenterWrap } from "../ClubViewInformation";

interface ClubFollowersProps {
    //todo -> put types
    infoClub: any;
    ChatServiceInstance: ChatServiceInterface;
}

function Index(props: ClubFollowersProps) {
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const userInfo = useSelector((state: any) => state.getUser.userInfo);
    const userFriends = useSelector((state: any) => state.getConnection.userFriends || []);
    const userConnections = useSelector((state: any) => state.getConnection.userPossibleConnections || []);
    const userRoles = useSelector((state: any) => state.getUser.userRoles);

    const [fans, setFans] = useState<any[]>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [typeFiter, setTypeFilter] = useState('1');
    const [setmember, setSetMember] = useState<any>('pass');
    const [valueFilter, setValueFilter] = useState("");
    const [infoConnection, setInfoConnection] = useState([]);
    const [showModalConnection, setShowModalConnection] = useState<boolean>(false);

    const dispatch = useDispatch();

    const getFans = () => {

        Backend(
            MicroServices.Club,
            `/clubs/${id}/followers?username=${userInfo.username}`)
            .then((response) => response.json())
            .then((results) => {
                if (Array.isArray(results) && results.length > 0) {
                    setFans(results);
                }
                else {
                    return Backend(
                        MicroServices.Club,
                        `/clubs/${id}/fans?username=${userInfo.username}`)
                }
            })
            .then((response: any) => response.json())
            .then((results) => {
                if (Array.isArray(results) && results.length > 0) {
                    setFans(results);
                }
                else {
                    setFans([]);
                }
            })
            .catch(console.error);
    };

    useEffect(() => {
        getFans();
        dispatch<any>(getUserFriends(userInfo.username));
        dispatch<any>(getUserPossibleConnections(userInfo.username));
    }, [])

    useEffect(() => {
        getFans();
        dispatch<any>(getUserFriends(userInfo.username));
        dispatch<any>(getUserPossibleConnections(userInfo.username));
    }, [id])

    const handleDots = (member: any, username: string) => {
        setTypeFilter('1');
        setSetMember(member);

        //The connection is a friend?
        if (userFriends.find((user: any) => user.friendUsername === username)) {
            setTypeFilter('2');
        }
        //Is a possible connection?
        else if (userConnections.find((user: any) => user.usernameConnection === username)) {
            setTypeFilter('1');
        }
        //The users cannot be friends
        else {
            setTypeFilter('pass');
        }

        setOpenModal(!openModal);
    }

    const handleChangeName = (e: any) => {
        setValueFilter(e);
    }

    const showConnection = (e: any) => {
        if (userRoles.includes(`${e.userType}`)) {
            setInfoConnection(e)
            setShowModalConnection(true)
        } else if (!userRoles.includes(`SEEKER`) && e.userType !== 'SEEKER') {
            setInfoConnection(e)
            setShowModalConnection(true)
        }
    }

    function handleCloseModal() {
        setShowModalConnection(false)
    }

    return (
        <ClubCenterWrap>
            <Row>
                <TitleMembers>{fans.length} Followers</TitleMembers>
                <SearchInput
                    filteredItem={valueFilter}
                    setFilteredItem={setValueFilter}
                    backgroundColor={'var(--t-blue)'}
                    border={'0px'}
                    placeholder={`Search by name`}
                />
            </Row>

            <div className="listMembersClub">
                {fans.filter((item: any) => {
                    if (!valueFilter) return true;
                    if (item.fullname.toUpperCase().includes(valueFilter) ||
                        item.fullname.toLowerCase().includes(valueFilter) ||
                        item.fullname.includes(valueFilter)
                    ) {
                        return true;
                    }
                    return false;
                }).map((member: any) => (
                    <div className="cardMember" key={member.username} >
                        <img
                            className="round-avatar"
                            onClick={(e) => { showConnection(member) }}
                            src={member.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"}
                            alt="" />
                        <p onClick={(e) => showConnection(member)}>{member.fullname} </p>
                        <button>
                            <FontAwesomeIcon
                                icon="ellipsis-v-alt"
                                size='lg'
                                color={'var(--t-blue)'}
                                className="ellipsis-v-alt   cursor"
                                onClick={() => handleDots(member, member.username)}
                            />
                            {(openModal && (member.username === setmember.username)) && (<ModalConnections connection={setmember} filterType={typeFiter} setOpenModal={setOpenModal} ChatServiceInstance={props.ChatServiceInstance} isAdvisor={undefined} officers={undefined} advisors={undefined} username={undefined} reloadClub={undefined} />)}
                        </button>
                    </div>
                ))}
            </div>

        </ClubCenterWrap>
    );
}

export default Index;

const Row = styled.div`
  display: grid;
  grid-template-columns: 50% calc(50% - 10px);
  grid-template-rows: 40px;
  grid-column-gap: 10px;
  width: 100%;
  gap: 10px;
`;

const TitleMembers = styled.div`
  font-family: 'Inter-bold';
  font-size: 22px;
  text-align: left;
  color: var(--t-blue);
`;