import Modal from 'react-bootstrap/Modal';
import ModalIconClose from '../../Modals/ModalIconClose';
import styled from 'styled-components';
import TwoColumns from '../../TwoColumns';
import Label from '../../../containers/Forms/Label';
import { Imp } from '../../signupsetuphealerind';
import SelectorCountry from '../Location/SelectorCountry';
import { useContext, useEffect, useState } from 'react';
import { CountryOption } from '../../../hooks/useCountry';
import { StateOption } from '../../../hooks/Locations/useActiveState';
import ErrorField from '../ErrorField';
import SelectorState from '../Location/SelectorState';
import { Input } from '../../signupsetuphealer';
import { CityOption } from '../../../hooks/Locations/useStateCities';
import SelectorCity from '../Location/SelectorCity';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentFormButton } from '../../PaymentForm/PaymentForm.styles';
import { IGetDiscountAmountResponse, IPrice } from '@vibe/sdk/dist/interfaces/Admin/payment/subscription/product.interface';
import { ContextSuccess } from '../../../Context';
import { Stripe } from 'stripe'
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../store/actions/userActions';
import { Loader, LoaderMini } from '../Loader';
import { getDataSDK, getDataSDKResponse } from '../../../helpers/backendHelper';
import { SDK } from '@vibe/sdk';
import { BaseFieldState, getFieldState } from '../utils';
import { Items, List, PaymentDetails, TextPayment, calcStripeFee } from '../../PurchaseVibes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import CardsPayment from './Saved/CardsPayment';
import { MockPaymentMethodCard, PaymentMethodCard } from '../../EditProfile/PaymentMethod';
import { IUserGradeResponse } from '@vibe/sdk/interfaces/Admin/User';
import { RootState } from '../../../store/store';
import { isValidZipCode } from '../../../helpers/userHelper';
import { PostalCodeClean } from '@vibe/sdk/dist/interfaces/Roster/postalCodes';

interface ModalPaymentProps {
  showModal: boolean;
  onHide: () => void;
  baseAmount: number;
}

const ModalPayment = ({
  showModal,
  onHide,
  baseAmount,
}: ModalPaymentProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const currentSuccess = useContext(ContextSuccess);
  const username = useSelector((state: any) => state.getUser.userInfo?.username || '');
  const dispatch = useDispatch();

  const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);

  const [country, setCountry] = useState<BaseFieldState<CountryOption>>(getFieldState({ label: '', value: '' }));
  const [state, setState] = useState<BaseFieldState<StateOption>>(getFieldState({ label: '', value: '' }));
  const [address1, setAddress1] = useState<BaseFieldState<string>>(getFieldState(''));
  const [address2, setAddress2] = useState<BaseFieldState<string>>(getFieldState(''));
  const [city, setCity] = useState<BaseFieldState<CityOption>>(getFieldState({ label: '', value: '' }));
  const [zipcode, setZipcode] = useState<BaseFieldState<string>>(getFieldState(''));
  const [nameOnCard, setNameOnCard] = useState<BaseFieldState<string>>(getFieldState(''));
  const [discountCode, setDiscountCode] = useState<BaseFieldState<string>>(getFieldState(''));
  const [discountCodeId, setDiscountCodeId] = useState<string | null>(null);
  const [wrongDiscountCode, setWrongDiscountCode] = useState<boolean>(false);

  const [searchCountry, setSearchCountry] = useState('');
  const [searchState, setSearchState] = useState('');
  const [searchCity, setSearchCity] = useState('');

  const [loading, setLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  // const [lookup_key, set_lookup_key] = useState<IPrice>();

  const [amount, setAmount] = useState<number>(0);
  const [newActive, setNewActive] = useState<boolean>(false);
  const [cardSelected, setCardSelected] = useState<PaymentMethodCard>({ ...MockPaymentMethodCard });

  const [zipcodeLengths, setZipcodeLengths] = useState<PostalCodeClean>({ maxLength: 5, minLength: 5 })

  useEffect(() => {
    if (discountCode.value && discountCode.value.length) {
      getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
          return dataSDK.sdk.Admin.getDiscountCode({ code: discountCode.value! });
        })
        .then(({ body }) => {
          const { amount_off, percent_off, couponId } = body as IGetDiscountAmountResponse
          setDiscountCodeId(couponId)
          if (amount_off) setAmount(baseAmount - amount_off / 100);
          if (percent_off) setAmount(baseAmount - (baseAmount * percent_off / 100));
          // set_lookup_key((lookup_keys.body as IPrice[])[0])
          if (!amount_off && !percent_off) {
            setWrongDiscountCode(true)
            console.log("Discount code not found")
            setAmount(baseAmount);
          } else {
            setWrongDiscountCode(false)
          }
        })
        .catch(e => {
          if (e.statusCode === 404) {
            setWrongDiscountCode(true)
            console.log("Discount code not found")
          }
          console.log(e)
          setAmount(baseAmount);
        })
      // setAmount(parseInt(process.env.REACT_APP_SUBSCRIPTION_DISCOUNT || '0'));
    }
    else {
      setAmount(baseAmount);
      setWrongDiscountCode(false)
    }
  }, [baseAmount, discountCode])

  // useEffect(() => {
  //   getDataSDK()
  //     .then((dataSDK: getDataSDKResponse) => {
  //       return dataSDK.sdk.Admin.listPrices();
  //     })
  //     .then(lookup_keys => {
  //       set_lookup_key((lookup_keys.body as IPrice[])[0])
  //     })
  //     .catch(e => {
  //       currentSuccess?.setSuccessData(false, true, e);
  //     })
  // }, [])

  const getCountry = () => {
    //@todo Two-letter country code ([ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2)) dictionary.
    if (country.value?.value === 'COL') return 'CO';
    return 'US';
  }

  const paymentHandler = () => {
    if (!stripe || !elements) {
      return
    }

    const errorZipCode = isValidZipCode(zipcode.value || '', zipcodeLengths, country.value?.label);
    if (errorZipCode !== '') {
      setZipcode({
        ...zipcode,
        showError: true,
        error: errorZipCode
      })
    } else {
      setZipcode({
        ...zipcode,
        showError: false,
        error: ''
      })
    }

    let paymentIntentId: string;
    let sdkData: SDK;

    if (wrongDiscountCode) {
      currentSuccess?.setPaymentSuccess(false, calcStripeFee(amount) / 100, 'Invalid discount code, please try again');
      return;
    }

    setLoading(true);
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        sdkData = dataSDK.sdk;
        return sdkData.Admin.subscribe({ username, couponId: discountCodeId! });
      })
      .then(paymentIntent => {
        const { body, } = paymentIntent;
        if (paymentIntent.statusCode >= 400) return Promise.reject(new Error((body ?? { error: 'There was an error' } as any).error))
        const { client_secret, id } = body as Stripe.Response<Stripe.PaymentIntent>
        paymentIntentId = id

        if (!client_secret) return Promise.reject(new Error('No client secret'))

        if (newActive) return stripe.confirmCardPayment(client_secret, {
          save_payment_method: true,
          payment_method: {
            card: elements.getElement(CardElement)!,
            billing_details: {
              address: {
                city: city.value?.label || '',
                country: getCountry(),
                line1: address1.value || '',
                line2: address2.value || '',
                postal_code: zipcode.value || '',
                state: state.value?.label || '',
              },
              email: user.email || '',
              name: `${user.firstname || ''} ${user.lastname || ''}`,
            }
          }
        })

        return stripe.confirmCardPayment(client_secret, {
          save_payment_method: true,
          payment_method: cardSelected.id,
        })
      })
      .then((response) => {
        console.log(response)
        const { error, paymentIntent } = response
        if (error ?? paymentIntent?.status !== 'succeeded') return Promise.reject(error)
        return sdkData.Admin.confirmPayment({ username, paymentIntentId })
      })
      .then((r) => {
        if (r.statusCode >= 400) return Promise.reject(new Error('Unable to confirm payment, try again later'))
        dispatch(getUser(username, 'OA'));
        currentSuccess?.setPaymentSuccess(true, calcStripeFee(amount) / 100);
        clearForm();
        onHide();
        setTimeout(() => { currentSuccess?.setModalsOff(); }, 3000);
      })
      .catch((error) => {
        console.log(error)
        currentSuccess?.setPaymentSuccess(false, calcStripeFee(amount) / 100, error.message ?? 'There was an error, try again later');
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const clearForm = () => {
    setCountry(getFieldState({ label: '', value: '' }));
    setState(getFieldState({ label: '', value: '' }));
    setAddress1(getFieldState(''));
    setAddress2(getFieldState(''));
    setCity(getFieldState({ label: '', value: '' }));
    setZipcode(getFieldState(''));
    setNameOnCard(getFieldState(''));
    setDiscountCode(getFieldState(''));
    setSearchCountry("")
    setSearchState("")
    setSearchCity("")
  }

  useEffect(() => {
    clearForm()
  }, [showModal])

  useEffect(() => {
    if (country.value?.value) {
      getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
          return dataSDK.sdk.Roster.postalCodes(country.value?.value, dataSDK.token)
        })
        .then((results) => {
          if (results.body && 'maxLength' in results.body) {
            setZipcodeLengths(results.body)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [country])

  return (
    <Modal show={showModal} className="ModalPayForm">
      <Modal.Header>
        <ModalHeader>
          <TitleForm>Billing Information</TitleForm>
          <ModalIconClose onClick={onHide} />
        </ModalHeader>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Label>Address Line 1<Imp>*</Imp></Label>
            <Input
              value={address1.value}
              onChange={(e) => setAddress1({ ...address1, value: e.currentTarget.value })}
            />
            {(address1.showError) && (<ErrorField error={address1.error} />)}
          </Row>
          <Row>
            <Label>Address Line 2</Label>
            <Input
              value={address2.value}
              onChange={(e) => setAddress2({ ...address2, value: e.currentTarget.value })}
            />
          </Row>
          <TwoColumns
            left={
              <WrapperCountrySelect>
                <Label>Country<Imp>*</Imp></Label>
                <SelectorCountry
                  country={country}
                  setCountry={setCountry}
                  searchCountry={searchCountry}
                  setSearchCountry={setSearchCountry}
                />
                {(country.showError) && (<ErrorField error={country.error} />)}
              </WrapperCountrySelect>
            }
            right={
              <WrapperCountrySelect>
                <Label>State / Region<Imp>*</Imp></Label>
                <SelectorState
                  country={country.value?.value ?? ''}
                  state={state}
                  setState={setState}
                  searchState={searchState}
                  setSearchState={setSearchState}
                />
                {(state.showError) && (<ErrorField error={state.error} />)}
              </WrapperCountrySelect>

            }
          />

          <TwoColumns
            left={
              <>
                <Label>City<Imp>*</Imp></Label>
                <SelectorCity
                  state={state.value?.value}
                  city={city}
                  setCity={setCity}
                  searchCity={searchCity}
                  setSearchCity={setSearchCity}
                />
                {(city.showError) && (<ErrorField error={city.error} />)}</>
            }
            right={
              <>
                <Label>Zip / Postal Code<Imp>*</Imp></Label>
                <Input
                  value={zipcode.value}
                  onChange={(e) => setZipcode({ ...zipcode, value: e.currentTarget.value })}
                />
                {(zipcode.showError) && (<ErrorField error={zipcode.error} />)}
              </>
            }
          />
          <Row>
            <Label>Discount Code</Label>
            <Input
              value={discountCode.value}
              onChange={(e) => setDiscountCode({ ...discountCode, value: e.currentTarget.value })}
            />
            {(wrongDiscountCode) && (<ErrorField error={"Invalid discount code"} />)}
          </Row>

          <CardsPayment
            setCardActive={setCardSelected}
            cardActive={cardSelected}
            setNewActive={setNewActive}
            newActive={newActive}
          />
          {newActive && <>
            <Row>
              <Label>Name on Card</Label>
              <Input
                value={nameOnCard.value}
                onChange={(e) => setNameOnCard({ ...nameOnCard, value: e.currentTarget.value })}
              />
            </Row>
            <CardWrapper>
              <CardElement options={{ hidePostalCode: true }} />
            </CardWrapper>
          </>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <DetailPayment>
          <PaymentDetails>
            <Title>
              Payment Details
              <FontAwesomeIcon
                icon={showDetail ? faChevronUp : faChevronDown}
                size="sm"
                onClick={() => setShowDetail(!showDetail)}
              />
            </Title>
            <ListDown theme={{ main: showDetail ? 'active' : 'inactive' }}>
              <Items>
                <TextPayment>Subtotal</TextPayment>
                <TextPayment>{amount ? amount.toFixed(2) : '0'} US$</TextPayment>
              </Items>
              <Items>
                <TextPayment>Transaction Fee</TextPayment>
                <TextPayment>{amount ? ((calcStripeFee(amount) - (amount * 100)) / 100).toFixed(2) : '0'} US$</TextPayment>
              </Items>
              <Items>
                <TextPayment>Taxes</TextPayment>
                <TextPayment>{amount ? (0).toFixed(2) : '0'} US$</TextPayment>
              </Items>
              {/* <Items>
                            <TextPayment>Net</TextPayment>
                            <TextPayment>{amount ? amount.toFixed(2) : ''} US$</TextPayment>
                        </Items> */}
              <Items>
                <TextPayment>Total</TextPayment>
                <TextPayment>{amount ? (calcStripeFee(amount) / 100).toFixed(2) : '0'} US$</TextPayment>
              </Items>


            </ListDown>
            <Title>
              Total
              <Value>{amount ? (calcStripeFee(amount) / 100).toFixed(2) : '0'} US$</Value>
            </Title>
            <PaymentFormButton save={true} onClick={paymentHandler}>
              {loading ? <Loader /> : 'Pay Now'}
            </PaymentFormButton>
          </PaymentDetails>
        </DetailPayment>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalPayment;

const Value = styled.div``;

const Title = styled.div`
  color: var(--default-dark);
  font-family: "Inter-Bold";
  font-size: 16px;
  letter-spacing: 0.1px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  text-align: left;
`;

const DetailPayment = styled.div`
  position: absolute;
  padding: 20px 30px;
  width: 100%;
  box-shadow: 0px -2px 8px -1px rgba(27, 36, 44, 0.08), 0px -2px 2px -1px rgba(27, 36, 44, 0.04);
  background-color: white;
  bottom: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  left: 0;
`

const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 100%;
  padding-bottom: 70px;
`;

const WrapperCountrySelect = styled.div`
  margin-bottom: 7px;
`

const DiscountCoupon = styled.span`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
    background-color: var(--simple-gray);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: '#aaa';
`

const Row = styled.div`
  width: '100%';
  margin-top: 5px;
  margin-bottom: 5px;
`;

const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TitleForm = styled.div`
  font-family: 'Inter-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
`;

const CardWrapper = styled.div`
  width: 100%;
  border: 1px solid var(--simple-gray);
  border-radius: 10px;
  padding: 10px;
  margin-top: 15px;
`;

const ListDown = styled.div.attrs(
  props => ({
    className: props.theme.main,
  })
)`
    width: 100%;
    height: 0px;
    overflow:hidden;
    -webkit-transition: height 1s ease-in-out;
    -moz-transition: height 1s ease-in-out;
    -o-transition: height 1s ease-in-out;
    transition: height 1s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 0px;

    &.active{
        height:90px;
    }
    &.inactive{
        height:0px;
    }
`;
