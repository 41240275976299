import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconClicker } from "../../Home/HeaderHome/styles"
import { AvatarHealerCardHome, RowAliasesHealerCardContainer, BolderTextCardHome, TitleCardName, BolderTextGridName, BolderTextGridSecond, BolderTextTitleCardHome, CardContainerHome, ContentCardHome, DividerLineHealerHome, IconUser, NoAvatarHealerCardHome, PhotoContainerHome, ReadMoreHealerCard, RowAliasesHealerCard, RowIconCardHome, RowIconCardHomeDivider, RowSocialIcon, TitleRowCardEllipsisWrap, TitleRowCardHome, WrapperDescriptionHomeCard, Row } from "./styles"
import { formatCompactNumber } from "../../../helpers/numberHelper"
import UserSocialMedia from "@vibe/sdk/interfaces/Roster/userSocialMedia"
import { getColorsInitials, getInitials } from "../../../helpers/letterHelper"
import { IconDefinition, IconName } from "@fortawesome/fontawesome-svg-core"
import { faFacebook, faInstagram, faLinkedin, faPinterest, faSnapchat, faTiktok, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { useEffect } from "react"

export type PlatformTypeIcon = 'Facebook' | 'YouTube' | 'Instagram' | 'Twitter' | 'LinkedIn' | 'Pinterest' | 'Snapchat' | 'TikTok';
export type PlatformType = PlatformTypeIcon | 'Substack' | 'Zaahah' | 'Personal';

export interface ILocationHealerCard {
    state: string //State name
    city: string //City name
    state_code: string
    city_code: string
}

export interface IHealerCard {
    username: string
    name: string
    location: ILocationHealerCard
    socialMedia: UserSocialMedia[]
    description: string
    seekers: number
    healers: number
    type: 'TP' | 'PO' | 'ANY'

    services?: string[] //for TPs
    degree_levels?: string[] //for example MD or PHd, for TPs
    avatar?: string
}

const HealerCard = ({
    data,
    onClick,
    type,
}: {
    data: IHealerCard
    onClick: (data: IHealerCard) => void
    type: 'TP' | 'PO'
}) => {

    const getLocation = () => {
        return <RowIconCardHome>
            <Row>
                <FontAwesomeIcon
                    icon="location-dot"
                    size="xs"
                    color="var(--default-dark)"
                />
                {data.location.city},
            </Row>
            <div>
                {data.location.state}
            </div>
        </RowIconCardHome>
    }

    const getHealerNoAvatar = () => {
        const num = [...data.name].reduce((acc, curr) => acc + curr.charCodeAt(0), 0);
        const colors = getColorsInitials(num);
        return <NoAvatarHealerCardHome
            background_color={colors.background}
            text_color={colors.color}
        >
            {getInitials(data.name)}
        </NoAvatarHealerCardHome>;
    }

    const getIcon = (platform: PlatformType) => {
        if (platform === 'Substack') {
            return <>
                <svg width="18" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.9991 5.40625H0V8.24275H20.9991V5.40625Z" fill="var(--default-dark)" />
                    <path d="M0 10.8125V24.0004L10.4991 18.1107L21 24.0004V10.8125H0Z" fill="var(--default-dark)" />
                    <path d="M20.9991 0H0V2.83603H20.9991V0Z" fill="var(--default-dark)" />
                </svg>
            </>; //@todo
        }

        if (platform === 'Zaahah') {
            return <></>; //@todo
        }

        if (platform === 'Personal') {
            return <IconClicker
                icon="globe"
                size="lg"
                color="var(--default-dark)"
            />;
        }

        const platforms: Record<PlatformTypeIcon, IconDefinition> = {
            "Instagram": faInstagram,
            'Facebook': faFacebook,
            'YouTube': faYoutube,
            'Twitter': faTwitter,
            'LinkedIn': faLinkedin,
            'Pinterest': faPinterest,
            'Snapchat': faSnapchat,
            'TikTok': faTiktok,
        }

        return <IconClicker
            icon={platforms[platform]}
            size="lg"
            color="var(--default-dark)"
        />
    }

    const getSocialMedia = () => {
        return <RowSocialIcon>
            {data.socialMedia.map(s => (
                <a href={s.link} target="_blank" rel="noopener noreferrer">
                    {getIcon(s.platform)}
                </a>
            ))}
        </RowSocialIcon>
    }

    const getServices = () => {
        if ((data.services || []).length >= 4) {
            return <RowAliasesHealerCardContainer>
                <RowAliasesHealerCard>
                    {(data.services || []).slice(0, 3).map(s => (
                        ` ${s} |`
                    ))}
                </RowAliasesHealerCard>
            </RowAliasesHealerCardContainer>
        }

        return <RowAliasesHealerCard>
            {(data.services || []).map(s => (
                ` ${s} |`
            ))}
        </RowAliasesHealerCard>
    }

    return (
        <CardContainerHome height={'270px'}>
            <PhotoContainerHome>
                {data.avatar ? (
                    <AvatarHealerCardHome src={data.avatar} />
                ) : (
                    getHealerNoAvatar()
                )}

                <TitleCardName onClick={() => onClick(data)}>{data.name}</TitleCardName>

               {getLocation()}
            </PhotoContainerHome>

            {type === 'PO' ? (
                <RowIconCardHome>
                    <RowIconCardHome>
                        <IconUser src={'../icon_seeker.png'} />
                        {formatCompactNumber(data.seekers)} Seekers
                    </RowIconCardHome>
                    <p>|</p>
                    <RowIconCardHome>
                        <IconUser src={'../icon_healer.png'} />
                        {formatCompactNumber(data.healers)} Healers
                    </RowIconCardHome>
                    {getSocialMedia()}
                </RowIconCardHome>
            ) : (
                <ContentCardHome gap={"8px"} height={"55px"}>
                    {data.socialMedia.length > 0 && getSocialMedia()}
                    {(data.services || []).length > 0 && getServices()}
                </ContentCardHome>
            )}

            <DividerLineHealerHome />

            <WrapperDescriptionHomeCard>
                {data.description.replace(/(<([^>]+)>)/gi, "").replace('&nbsp;', ' ')}
            </WrapperDescriptionHomeCard>

            <ReadMoreHealerCard onClick={() => onClick(data)}>
                Read More
                <IconClicker
                    icon="chevron-right"
                    color="var(--default-dark)"
                    size="sm"
                />
            </ReadMoreHealerCard>
        </CardContainerHome>
    )
}

export default HealerCard;