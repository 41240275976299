import React, { useEffect, useMemo, useState } from 'react'
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { IUserResponse, getUsersResponse } from '@vibe/sdk/interfaces/Admin/User';
import { UserConnection } from '@vibe/sdk/dist/interfaces/Roster/getUserConnections';
import { isConnectionActive } from '../../helpers/Connections/getConnections';
import { rolesType } from '@vibe/sdk/interfaces/AccessManager/signUpUser';
import { UserConnectionHook } from './useUsersList';

interface useUsersProps {
  searchName: string;
  username: string;
}

interface useUsersResponse {
  reloadList: () => void;
  allUserConnections: UserConnectionHook[];
}

const useUserProfileConn = ({
  searchName,
  username,
}: useUsersProps): useUsersResponse => {

  const [allUserConnections, setAllUserConnections] = useState<UserConnectionHook[]>([]);
  const [connections, setConnections] = useState<UserConnection[]>([]);
  const [users, setUsers] = useState<IUserResponse[]>([]);

  const searchParsed = useMemo(() => searchName.toLowerCase(), [searchName]);

  const reloadList = () => {
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return Promise.all([
          dataSDK.sdk.Admin.getUsers({
            roleRequest: 'OA',
          }, dataSDK.token),
          dataSDK.sdk.Roster.getUserConnections({username}, dataSDK.token)
        ]);
      })
      .then(([usersR, connR]) => {
        if (usersR.statusCode === 200) {
          setUsers((usersR.body as getUsersResponse).users);
        }
        else {
          setUsers([]);
        }

        if (connR.statusCode === 200) {
          setConnections(connR.body as UserConnection[]);
        }
        else {
          setConnections([]);
        }
      })
      .catch(console.error);
  }

  const filterUserConn = (user: UserConnectionHook): boolean => {
    let acceptedName = true;
    let acceptedRole = true;

    if (searchName && searchName !== '') {
      acceptedName = (user.name || '').toLowerCase().includes(searchParsed);
    }

    return acceptedRole && acceptedName;
  }

  const mapConnectionUsers = () => {
    const allSystem: UserConnectionHook[] = [];

    (users || []).forEach((user) => {
      const connection = isConnectionActive(user.username || '', connections);

      let connMapped: UserConnectionHook = {
        role: user.roles as rolesType[],
        avatar: user.avatar || '',
        name: `${user.firstname || ''} ${user.lastname || ''}`,
        mutualClubs: connection.connection?.mutualClubs || 0,
        username: user.username,
        relation: [],
        relationComplete: connection.relation,
      }

      if (connection.connection) {
        connMapped = {
          ...connection.connection,
          relationComplete: connection.relation,
        }
      }

      if (filterUserConn(connMapped)) {
        if (connMapped.mutualClubs !== 0) allSystem.push(connMapped);
      }
    })

    setAllUserConnections(allSystem);
  }

  useEffect(() => {
    mapConnectionUsers();
  }, [searchName, users, connections]);

  useEffect(() => reloadList(), []);

  return {
    reloadList,
    allUserConnections,
  }
};

export default useUserProfileConn;