import { useEffect, useMemo, useState } from "react";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import { sortAlphabeticallyOnProperty } from "../../../containers/Signup/SignupHealerInd";
import Article, { ArticleType } from "@vibe/sdk/dist/interfaces/Modalities/articles/Article";

export type SearchArticle = 'Modality' | 'Healer' | 'Contributor';

const useArticles = ({
    SearchTerm,
    type,
    id,
    artType,
}: {
    SearchTerm: string
    type: SearchArticle
    id: string
    artType: ArticleType
}) => {

    const searchParsed = useMemo(() => SearchTerm.toLowerCase(), [SearchTerm]);

    const [Articles, setArticles] = useState<Article[]>([]);
    const [ArticlesIDFiltered, setArticlesIDFiltered] = useState<Article[]>([]);

    const reloadArticlesID = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                if(type === 'Modality') return dataSDK.sdk.Modalities.getArticlesByModality(id, artType === 'RES', dataSDK.token);
                if(type === 'Contributor') return dataSDK.sdk.Modalities.getArticlesByContributor(id, artType === 'RES', dataSDK.token);
                return dataSDK.sdk.Modalities.getArticlesByHealer(id, artType === 'RES', dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) {
                    setArticles(results.body as Article[]);
                }
                else {
                    setArticles([]);
                }
            });
    };

    useEffect(() => {
        if (!SearchTerm || SearchTerm === '') {
            setArticlesIDFiltered(sortAlphabeticallyOnProperty(Articles, 'name'));
            return;
        }
        setArticlesIDFiltered((sortAlphabeticallyOnProperty(Articles, 'name') as Article[])
            .filter(l => (l.name || '').toLowerCase().includes(searchParsed))
        );
    }, [SearchTerm, Articles]);

    useEffect(() => {
        reloadArticlesID();
    }, []);

    useEffect(() => {
        reloadArticlesID();
    }, [artType]);

    return {
        ArticlesIDFiltered,
        reloadArticlesID
    };
};

export default useArticles;
