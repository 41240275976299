import styled, { css, keyframes } from "styled-components";
import { CardConnectionsInterface } from "../BaseComponents/Connections/ConnectionCard";

export const listAdv = [
    {
        id: 1,
        name: 'Rebecca Pollock MD',
        location: "Los Angeles, California",
        avatar: 'https://picsum.photos/200',
        interests: [
            "Happiness & Joy ", "Love", "Health", "Inner peace", "Freedom"
        ],
        description: "I express myself best through dance, and singing...",
        icons: {

        }
    },
    {
        id: 2,
        name: 'Rebecca Pollock MD',
        location: "Los Angeles, California",
        avatar: 'https://picsum.photos/200',
        interests: [
            "Happiness & Joy ", "Love", "Health", "Inner peace", "Freedom"
        ],
        description: "I express myself best through dance, and singing..."
    },
    {
        id: 3,
        name: 'Rebecca Pollock MD',
        location: "Los Angeles, California",
        avatar: 'https://picsum.photos/200',
        interests: [
            "Happiness & Joy ", "Love", "Health", "Inner peace", "Freedom"
        ],
        description: "I express myself best through dance, and singing..."
    },
]

export const listComm = [
    {
        id: 1,
        name: 'Ayurveba',
        healer: 'James Pollock',
        join: false,
        follow: false,
    },
    {
        id: 2,
        name: 'Ayurveba',
        healer: 'James Pollock',
        join: false,
        follow: false,
    },
    {
        id: 3,
        name: 'Ayurveba',
        healer: 'James Pollock',
        join: true,
        follow: false,
    },
    {
        id: 4,
        name: 'Ayurveba',
        healer: 'James Pollock',
        join: false,
        follow: false,
    },
    {
        id: 5,
        name: 'Ayurveba',
        healer: 'James Pollock',
        join: true,
        follow: false,
    },
    {
        id: 6,
        name: 'Ayurveba',
        healer: 'James Pollock',
        join: false,
        follow: false,
    },
    {
        id: 7,
        name: 'Ayurveba',
        healer: 'James Pollock',
        join: true,
        follow: false,
    },
    {
        id: 8,
        name: 'Ayurveba',
        healer: 'James Pollock',
        join: true,
        follow: false,
    },
    {
        id: 9,
        name: 'Ayurveba',
        healer: 'James Pollock',
        join: false,
        follow: false,
    },
]

export const listConnections: CardConnectionsInterface[] = [
    {
        id: "2",
        name: 'Rebecca Pollock Collins',
        avatar: 'https://picsum.photos/200/200',
        interests: [],
        typeConnection: 'ANY',
        zipcode: '11022',
        createdOn: "12/05/2023",
        country: 'United States of America',
        connections: [],
        roles: ['HEALER'],
        city: 'San Francisco',
    },
    {
        id: "2",
        name: 'Rebecca Pollock',
        avatar: 'https://picsum.photos/200/200',
        interests: [
            "Happiness & Joy ", "Love", "Health", "Inner peace", "Freedom"
        ],
        typeConnection: 'REQUESTED',
        zipcode: '11022',
        createdOn: "12/05/2023",
        country: 'United States of America',
        connections: [
            {
                name: "Santa Catalina",
                avatar:'https://picsum.photos/200/200',
            },
            {
                name: "Santa Catalina",
                avatar:'https://picsum.photos/200/200',
            },
            {
                name: "Santa Catalina",
                avatar:'https://picsum.photos/200/200',
            },
            {
                name: "Santa Catalina",
                avatar:'https://picsum.photos/200/200',
            },
        ],
        roles: ['SEEKER'],
        city: 'San Francisco',
    },
    {
        id: "2",
        name: 'Rebecca Pollock',
        avatar: 'https://picsum.photos/200/200',
        interests: [
            "Happiness & Joy ", "Love", "Health", "Inner peace", "Freedom"
        ],
        typeConnection: 'ANY',
        zipcode: '11022',
        createdOn: "12/05/2023",
        country: 'United States of America',
        connections: [],
        roles: ['SEEKER', 'HEALER'],
        city: 'San Francisco',
    },
    {
        id: "2",
        name: 'Rebecca Pollock Collins',
        avatar: 'https://picsum.photos/200/200',
        interests: [
            "Happiness & Joy ", "Love", "Health", "Inner peace", "Freedom"
        ],
        typeConnection: 'ANY',
        zipcode: '11022',
        createdOn: "12/05/2023",
        country: 'United States of America',
        connections: [],
        roles: ['SEEKER', 'HEALER'],
        city: 'San Francisco',
    },
    {
        id: "2",
        name: 'Rebecca Pollock',
        avatar: 'https://picsum.photos/200/200',
        interests: [
            "Happiness & Joy ", "Love", "Health", "Inner peace", "Freedom"
        ],
        typeConnection: 'REQUESTED',
        zipcode: '11022',
        createdOn: "12/05/2023",
        country: 'United States of America',
        connections: [
            {
                name: "Santa Catalina",
                avatar:'https://picsum.photos/200/200',
            },
            {
                name: "Santa Catalina",
                avatar:'https://picsum.photos/200/200',
            },
            {
                name: "Santa Catalina",
                avatar:'https://picsum.photos/200/200',
            },
            {
                name: "Santa Catalina",
                avatar:'https://picsum.photos/200/200',
            },
        ],
        roles: ['SEEKER', 'HEALER'],
        city: 'San Francisco',
    },
    {
        id: "2",
        name: 'Rebecca Pollock',
        avatar: 'https://picsum.photos/200/200',
        interests: [
            "Happiness & Joy ", "Love", "Health", "Inner peace", "Freedom"
        ],
        typeConnection: 'REQUESTED',
        zipcode: '11022',
        createdOn: "12/05/2023",
        country: 'United States of America',
        connections: [
            {
                name: "Santa Catalina",
                avatar:'https://picsum.photos/200/200',
            },
            {
                name: "Santa Catalina",
                avatar:'https://picsum.photos/200/200',
            },
            {
                name: "Santa Catalina",
                avatar:'https://picsum.photos/200/200',
            },
            {
                name: "Santa Catalina",
                avatar:'https://picsum.photos/200/200',
            },
        ],
        roles: ['SEEKER', 'HEALER'],
        city: 'San Francisco',
    },
    {
        id: "2",
        name: 'Rebecca Pollock',
        avatar: 'https://picsum.photos/200/200',
        interests: [
            "Happiness & Joy ", "Love", "Health", "Inner peace", "Freedom"
        ],
        typeConnection: 'ANY',
        zipcode: '11022',
        createdOn: "12/05/2023",
        country: 'United States of America',
        connections: [],
        roles: ['SEEKER', 'HEALER'],
        city: 'San Francisco',
    },
    {
        id: "2",
        name: 'Rebecca Pollock',
        avatar: 'https://picsum.photos/200/200',
        interests: [
            "Happiness & Joy ", "Love", "Health", "Inner peace", "Freedom"
        ],
        typeConnection: 'ANY',
        zipcode: '11022',
        createdOn: "12/05/2023",
        country: 'United States of America',
        connections: [
            {
                name: "Santa Catalina",
                avatar:'https://picsum.photos/200/200',
            },
            {
                name: "Santa Catalina",
                avatar:'https://picsum.photos/200/200',
            },
            {
                name: "Santa Catalina",
                avatar:'https://picsum.photos/200/200',
            },
            {
                name: "Santa Catalina",
                avatar:'https://picsum.photos/200/200',
            },
        ],
        roles: ['SEEKER', 'HEALER'],
        city: 'San Francisco',
    },
]

export const listAreas = [
    'Happiness & Joy', 'Love', 'Fullfillment & Purpose', 'Inner Peace', 'Freedom', 'Creativity', 'Youthfullness',
    'Happiness & Joy', 'Love', 'Fullfillment & Purpose', 'Inner Peace', 'Freedom', 'Creativity', 'Youthfullness',
    'Happiness & Joy', 'Love', 'Fullfillment & Purpose', 'Inner Peace', 'Freedom', 'Creativity', 'Youthfullness'
]

export const ContentWrap = styled.div`
    width: 1250px;
    margin-bottom: 2px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export const TitleContent = styled.p`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
`;

export const IconHealer = styled.div`
    width: 32px;
    height: 32px;
    background-color: var(--electric-yellow-2);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 10px;
    background-image: url(../icon_healer.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 65% auto;

    &:hover::after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-right: 10px solid transparent;
        border-top: 10px solid transparent;
        border-left: 10px solid transparent;
        border-bottom: 10px solid var(--simple-blue-dark-2);
        top: 23px;
        z-index: 1
    }
    &:hover::before {
        content: "Healer";
        background-color: var(--simple-blue-dark-2);
        position: relative;
        top: 40px;
        padding: 4px 11px;
        font-size: 12px;
        font-family: 'Inter';
        border-radius: 10px;
        z-index: 1
    }
`

export const IconSeeker = styled.div`
    width: 32px;
    height: 32px;
    background-color: var(--simple-green);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 10px;
    background-image: url(../icon_seeker.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 65% auto;

    &:hover::after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-right: 10px solid transparent;
        border-top: 10px solid transparent;
        border-left: 10px solid transparent;
        border-bottom: 10px solid var(--simple-blue-dark-2);
        top: 23px;
        z-index: 1
    }
    &:hover::before {
        content: "Seeker";
        background-color: var(--simple-blue-dark-2);
        position: relative;
        top: 40px;
        padding: 4px 11px;
        font-size: 12px;
        font-family: 'Inter';
        border-radius: 10px;
        z-index: 1
    }
`

export const SubText = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
`

export const ButtonBlue = styled.div`
    background-color: var(--simple-blue-dark-2);
    margin-bottom: 16px;
    padding: 12px 0;
    border-radius: 8px;
    color: white;
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
`

export const ButtonBrown = styled.div`
    background-color: #CEBEAD;
    padding: 12px 0;
    border-radius: 8px;
    color: black;
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
`


export const ButtonWhite = styled.div`
    background-color: #ffffff;
    border: 1px solid #29313b;
    margin-bottom: 16px;
    padding: 7px 0;
    border-radius: 8px;
    color: black;
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const CardItem = styled.div`
    width: ${props => props.theme.width || '203px'};
    border: 1px solid var(--mountain-mist);
    background-color: white;
    border-radius: 10px;
    margin: ${props => props.theme.margin || '0 12px 14px 12px'};
    padding: 17px;
    color: black;
    text-align: center;
    position: relative;
`

export const CardItemRw = styled.div`
    width: 48%;
    border: 1px solid var(--mountain-mist);
    background-color: white;
    border-radius: 10px;
    padding: 17px;
    color: black;
    text-align: center;
`

export const InfoUser = styled.div`
    display: flex;
`

export const Detail = styled.div`
    text-align: left;
    margin-left: 15px;
    line-height: 22px;
    width: 280px;
`

export const Name = styled.div`
    color: black;
    font-size: 20px;
    font-weight: 900;
    font-family: Inter-Bold;
`

export const Location = styled.div`
    font-family: 'Inter';
    font-size: 14px;
    text-align: initial;
    padding: 8px 0;
    color: black;
`

export const Description = styled.div`
    text-align: left;
    font-size: 14px;
    padding-top: 10px;
    margin: 5px 0;
    font-family: 'Inter';
    color: black;
    line-height: 17px;
`

export const AvatarConn = styled.img`
    width: 56px;
    height: 56px;
    min-width: 56px;
  min-height: 56px;
  max-width: 56px;
  max-height: 56px;
    border-radius: 50%;
    margin-top: 6px;
    margin-bottom: 14px;
    object-fit: cover;
`

export const NameConnection = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align:center;
`

export const Interests = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin: 18px 0;
    line-height: 17px;
    height: 72px;
`

interface ListProps{
    isSummary: boolean;
}

export const ListComm = styled.div`
    margin-top: ${(props: ListProps) => props.isSummary? '15px': '0px'};

    width: 100%;
    height: 100%;
    display: ${(props: ListProps) => props.isSummary? 'flex': 'grid'};
    grid-template-columns: repeat(auto-fit,
      minmax(175px,
        195px));
    grid-template-rows: ${(props: ListProps) => props.isSummary? '1fr': 'repeat(auto-fit, 250px)'};
    gap: 15px;
    overflow: scroll;

    @media only screen and (max-width: 500px) {
        grid-template-columns: 1fr;
    }
`

export const TitleCard = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align:left;
`