import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import styled from "styled-components";
import { ContextSuccess } from "../../../Context";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { Loader } from "../Loader";
import IPaymentInfo from "../../../interfaces/user/IPaymentINfo";
import { Scroll_Content_Signup } from "../../signupsetuphealer/Introduction";


const ContentPayment = ({
    isCommunities = false
}: {
    isCommunities?: boolean
}) => {

    const currentSuccess = useContext(ContextSuccess);

    const loadingUser = useSelector((state: RootState) => state.getUser.loadingUser);
    const userPayment: IPaymentInfo = useSelector((state: RootState) => state.getUser.paymentInfo);

    const handleVibe = () => {
        currentSuccess?.setPaymentForm(parseInt(process.env.REACT_APP_SUBSCRIPTION_COST || '0'));
    }

    return (
        <Scroll_Content_Signup>
            <Column>
                <HeaderCol>
                    <IconHealer />
                    <div>
                        <TextLine>${process.env.REACT_APP_SUBSCRIPTION_COST || '0'} Annually</TextLine>
                        <TextPrice>${process.env.REACT_APP_SUBSCRIPTION_DISCOUNT || '0'} for 1st 100 Healers</TextPrice>
                    </div>
                </HeaderCol>
                <InfoContainer theme={{ main: 'infoContainer' }}>
                    <InfoContainerHealer>
                        {isCommunities && (
                            <LabelHealer>
                                <TextHeaderHealer>
                                    Extend support to your seekers by creating your own communities independent of those created by the professional organization that has invited you to Vibe
                                </TextHeaderHealer>
                            </LabelHealer>
                        )}
                        <ItemInfo>
                            <TextHeader>
                                Support, Grow and Monetize Communities and Followers
                            </TextHeader>
                            <ItemPricing theme={{ size: '14px' }}>
                                <FontAwesomeIcon icon={faCheck} size='sm' />
                                Grow unlimited internal & external communities & groups.
                            </ItemPricing>
                            <ItemPricing theme={{ size: '14px' }}>
                                <FontAwesomeIcon icon={faCheck} size='sm' />
                                Message individuals or groups.
                            </ItemPricing>
                            <ItemPricing theme={{ size: '14px' }}>
                                <FontAwesomeIcon icon={faCheck} size='sm' />
                                Schedule events.
                            </ItemPricing>
                            <ItemPricing theme={{ size: '14px' }}>
                                <FontAwesomeIcon icon={faCheck} size='sm' />
                                Promote events, news & updates to communities.
                            </ItemPricing>
                            <ItemPricing theme={{ size: '14px' }}>
                                <FontAwesomeIcon icon={faCheck} size='sm' />
                                Retain names and emails of new community members.
                            </ItemPricing>
                            <ItemPricing theme={{ size: '14px' }}>
                                <FontAwesomeIcon icon={faCheck} size='sm' />
                                Zero algorithm content bias; content always appears in followers and members feeds
                            </ItemPricing>
                            <ItemPricing theme={{ size: '14px' }}>
                                <FontAwesomeIcon icon={faCheck} size='sm' />
                                SEO optimized directory listing.
                            </ItemPricing>
                            <TextHeader>
                                Generate Revenue
                            </TextHeader>
                            <ItemPricing theme={{ size: '14px' }}>
                                <FontAwesomeIcon icon={faCheck} size='sm' />
                                Receive referrals of seekers.
                            </ItemPricing>
                            <ItemPricing theme={{ size: '14px' }}>
                                <FontAwesomeIcon icon={faCheck} size='sm' />
                                Create VIP communities with premium content. *
                            </ItemPricing>
                            <ItemPricing theme={{ size: '14px' }}>
                                <FontAwesomeIcon icon={faCheck} size='sm' />
                                Deliver group or individual services online (live classes, memberships, online courses). *
                            </ItemPricing>
                            <ItemPricing theme={{ size: '14px' }}>
                                <FontAwesomeIcon icon={faCheck} size='sm' />
                                Receive tips from Seekers and Healers.*
                            </ItemPricing>
                        </ItemInfo>
                    </InfoContainerHealer>
                    {!userPayment.hasActiveSubscription &&
                        < ButtonLetsVibe onClick={handleVibe}>
                            {
                                loadingUser ? (
                                    <Loader />
                                ) : (
                                    <>
                                        Pay Now
                                        <FontAwesomeIcon
                                            icon="arrow-right"
                                            size="lg"
                                            color="white"
                                        />
                                    </>
                                )
                            }
                        </ButtonLetsVibe>
                    }
                </InfoContainer>
            </Column>
        </Scroll_Content_Signup>
    )
}

export default ContentPayment;

const LabelHealer = styled.div`
    display: flex;
    width: 100%;
    padding: 3px 10px;
    align-items: center;
    justify-content: flex-start;
    border-radius: 8px;
    background-color: var(--simple-gray-2);
`;

const TextHeader = styled.div`
    color: #000;
    font-size: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    text-align: left;
    padding: 8px 0px;
`;

const TextHeaderHealer = styled(TextHeader)`
    font-weight: 400;
    line-height: normal;
`;

const ItemPricing = styled.div`
  color: var(--default-dark);
  font-size: ${props => props.theme.size || '16px'};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align:left;
  display: flex;
  align-items: center;
  gap: 10px;
`

const ItemInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
`;

const IconHealer = styled.div`
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: relative;
  background-image: url(../icon_healer.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80% auto;
  border: 2px solid var(--simple-blue-dark);
`

const HeaderCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.theme.justify || 'space-between'};
  padding: 10px 20px;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid #D3DCE5;
  background-color: #FFF;
  height: fit-content;
  gap: 15px;
`

const TextPrice = styled.div`
  color: var(--default-dark);
  font-size: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`
const TextLine = styled.div`
  color: #808080;
  text-align: right;
  font-size: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: line-through;
`;

const InfoContainer = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    padding: 0px 20px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    justify-content: space-between;
    padding-bottom: 1vw;

    @media (min-width:912px) and (max-width:1366px)
    {
        &.infoContainer
        {
            height: auto;
        }
    }
`;

const InfoContainerHealer = styled(InfoContainer)`
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
`;

const TitleBold = styled.div`
    color: var(--default-dark);
    font-size: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 10px;
`

const ButtonLetsVibe = styled.button` 
  width: fit-content;
  border: none;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--dark-blue);
  color: #FFF;
  display: flex;
  font-size: 16px;
  font-family: Inter-Bold;
  cursor: pointer;
  justify-content: flex-end;
  gap: 10px;
  padding: 8px 20px;
`

export const Column = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
  
    border-radius: 8px;
    border: 1px solid #D3DCE5;
    background: #FFF;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    position: relative;
    gap: 14px;
  
    .column1 { grid-area: 1 / 1 / 2 / 3; }
    .column2 { grid-area: 1 / 3 / 2 / 5; }
`