import React, { useEffect, useState, useContext } from 'react'
import styled from "styled-components";
import { IEducation } from '@vibe/sdk/dist/interfaces/Roster/UserInfo';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-thin-svg-icons";
import { faSearch, faPen, faTrash } from '@fortawesome/pro-regular-svg-icons';
import BlankTable from '../BaseComponents/BlankTable';
import { sortAlphabeticallyOnProperty } from '../../containers/Signup/SignupHealerInd';
import { ContextSuccess } from "../../Context";
import { onHideType } from "../../components/BaseComponents/Modals/ModalUnsaved";
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { getUserEducation } from '../../store/actions/userActions';
import IResponseError from '@vibe/sdk/dist/interfaces/IResponseError';
import ModalDegrees from '../../components/signupsetuphealerind/ModalDegrees';

export const mockEducation = {
  id: '',
  college: '',
  degree_name: '',
  graduation_year: '01/01/2012',
  concentration: '',
  country: '',
  state: '',
  city: '',
  from: 12,
  to: 12,
}

const Education = () => {

  const dispatch = useDispatch();

  const currentSuccess = useContext(ContextSuccess);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [degree, setDegree] = useState<IEducation>({ ...mockEducation });
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>();

  const education: IEducation[] = useSelector((state: RootState | any) => state.getUser.getUserEducation);
  const username = useSelector((state: RootState | any) => state.getUser.userInfo.username);

  const handleModalEdit = (show: boolean, club?: IEducation) => {
    setShowModalEdit(show);
    if (club) setDegree(club);
  }

  const handleDelete = (data: string) => {
    currentSuccess?.setModalWarning(
      'DELETE',
      (type) => onHideDelete(type, data),
      'Are you sure you want to delete this degree?'
    );
  }

  const handleUpdate = (service_is: string) => {
    const s = education.find(s => s.id === service_is);
    handleModalEdit(true, s);
  }

  const onHideDelete = (type: onHideType, id: string) => {
    switch (type) {
      case 'save':
        currentSuccess?.setLoadingModal(true);
        getDataSDK()
          .then((dataSDK: getDataSDKResponse) => {
            return dataSDK.sdk.Roster.deleteUserEducation(username, id, dataSDK.token)
          })
          .then((results: any) => {
            if (results.statusCode === 201) {
              dispatch(getUserEducation(username));
              currentSuccess?.setLoadingModal(false);
              currentSuccess?.setModalsOff();
            }
            else {
              console.log((results.body as IResponseError).error || (results.body as IResponseError).message)
            }
          })
          .finally(() => {
            setDeleteModal(false);
            setDeleteLoading(false);
            setDeleteId(undefined);
          })
        break;

      case 'close':
        currentSuccess?.setModalsOff();
        break;

      case 'discard':
        currentSuccess?.setModalsOff();
        break;

      default:
        break;
    }
  }

  return (
    <Scroll_Content_Signup>
      {(education || []).length === 0 ?
        <ListServicesEmp>
          <Header>
            <Title>No degrees uploaded yet.</Title>
            <SubTitle>Share your collage or continuos education degrees.</SubTitle>
            <Button
              theme={{ top: '25px' }}
              onClick={() => setShowModal(true)}>
              Add new degree
              <IconPlus
                icon={faPlus}
                size='lg'
                color={"var(--white)"}
              />
            </Button>
          </Header>
        </ListServicesEmp>
        :
        <ListServices>
          <Button
            theme={{ top: '0px' }}
            onClick={() => setShowModal(true)}>
            Add new degree
            <IconPlus
              icon={faPlus}
              size='lg'
              color={"var(--white)"}
            />
          </Button>
          <ListServices>
            <BlankTable
              headers={['Name', 'Graduation Date', 'University', 'Country']}
              items={sortAlphabeticallyOnProperty((education || []), 'degree_name').map((service: IEducation) => ({
                id: service.id,
                labels: [
                  service.degree_name,
                  service.graduation_year || '',
                  service.college || '',
                  service.country || '',
                ],
              }))}
              actions={['UPDATE', 'DELETE']}
              actionsHandlers={{
                handleUpdate,
                handleDelete,
              }}
              maxWidth={'200px'}
            />
          </ListServices>
        </ListServices>

      }
      <ModalDegrees
        showModal={showModal}
        onHide={setShowModal}
        data={degree}
      />
      <ModalDegrees
        showModal={showModalEdit}
        onHide={setShowModalEdit}
        data={degree}
        educationInfo={degree}
        edit
      />
    </Scroll_Content_Signup>
  )
}

const Scroll_Content_Signup = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 16px;
  overflow: scroll;
  margin-top: 30px;
`;

const IconPlus = styled(FontAwesomeIcon)`
    font-size: 14px;
    margin-left: 8px;
`;

const Title = styled.p`
    font-family: 'Inter-Bold';
    font-size: 16px;
    padding: 13px 0;
`
const SubTitle = styled.span`
    font-family: 'Inter';
    font-size: 14px;
`

const ListServices = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 10px;
    overflow: scroll;
`

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 210px;
    height: 40px;
    background: var(--electric-yellow-2);
    border-radius: 8px;
    color: var(--white);
    font-family: 'Inter-Bold';
    font-size: 16px;
    margin-top: ${props => props.theme.top || '25px'};
`

const ListServicesEmp = styled.div`
    width: 100%;
    background-color: white;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-image: url(./NoServices.png);
    border-radius: 9px;
    height: 100%;
`

export default Education