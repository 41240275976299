import { IMedia } from "@vibe/sdk/interfaces/Admin/utils";
import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { IconClicker } from "../../Home/HeaderHome/styles";
import { faPause, faPlay } from "@fortawesome/pro-regular-svg-icons";

const ContainerVideo = ({ file }: { file: IMedia }) => {
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const videoRef = useRef<any>(null);

    const togglePlay = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleVideoEnd = () => {
        setIsPlaying(false);
    };

    useEffect(() => {
        videoRef.current.addEventListener('ended', handleVideoEnd);
        return () => {
            videoRef.current.removeEventListener('ended', handleVideoEnd);
        };
    }, []);

    return (
        <VideoWrapper>
            <Video ref={videoRef}>
                <source src={file.link} type={`video/${file.type}`} />
            </Video>
            <PlayButton onClick={togglePlay}>
                {isPlaying ?
                    <IconOnHover
                        icon={faPause}
                        color="white"
                        size="4x"
                    />
                    :
                    <IconClicker
                        icon={faPlay}
                        color="white"
                        size="4x"
                    />
                }
            </PlayButton>
        </VideoWrapper>

    )
}

export default ContainerVideo;

const VideoWrapper = styled.div`
    width: 100%;
    height: 400px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    position: relative;
`;

const Video = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const PlayButton = styled.div`
    position: absolute;
    left: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    width: 100%;
    height: 100%;
`;

const IconOnHover = styled(IconClicker)`
    color: transparent;
    &:hover {
        color: white;
    }
`;