import { AnyAction } from "@reduxjs/toolkit";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { GET_BE_ERROR, GET_BE_SUCCESS, GET_CERTIFICATIONS_ERROR, GET_CERTIFICATIONS_SUCCESS, GET_HEALER_SERVICES_ERROR, GET_HEALER_SERVICES_SUCCESS, GET_MODALITIES_ERROR, GET_MODALITIES_SUCCESS, GET_TA_ERROR, GET_TA_SUCCESS, START_GET_BE, START_GET_CERTIFICATIONS, START_GET_HEALER_SERVICES, START_GET_MODALITIES, START_GET_TA, START_GET_ACTIVE_MODALITIES, GET_ACTIVE_MODALITIES_SUCCESS, GET_ACTIVE_MODALITIES_ERROR, START_GET_ALL_MODALITIES, GET_ALL_MODALITIES_ERROR, GET_ALL_MODALITIES_SUCCESS } from "../constants/healerConstants";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import IResponseError from "@vibe/sdk/dist/interfaces/IResponseError";

export const getHealerServices = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_HEALER_SERVICES });
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.getHSList(username, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) {
                    dispatch({
                        type: GET_HEALER_SERVICES_SUCCESS,
                        payload: results.body,
                    })
                    return resolve();
                }
                dispatch({
                    type: GET_HEALER_SERVICES_ERROR,
                    payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
                })
            })
            .catch((error) => {
                dispatch({
                    type: GET_HEALER_SERVICES_ERROR,
                    payload: error,
                })
            })
    });

export const getModalities = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_MODALITIES});
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.getAllModalities();
            })
            .then((results) => {
                if (!Array.isArray(results.body)) {
                    dispatch({
                        type: GET_MODALITIES_ERROR,
                        payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
                    })
                    return;
                }
                dispatch({
                    type: GET_MODALITIES_SUCCESS,
                    payload: results.body,
                })
            })
            .catch((error) => {
                dispatch({
                    type: GET_MODALITIES_ERROR,
                    payload: error,
                })
            })
    });

export const getBenefits = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_BE});
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.getAllBEs();
            })
            .then((results) => {
                if (!Array.isArray(results.body)) {
                    dispatch({
                        type: GET_BE_ERROR,
                        payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
                    })
                    return;
                }
                dispatch({
                    type: GET_BE_SUCCESS,
                    payload: results.body,
                })
            })
            .catch((error) => {
                dispatch({
                    type: GET_BE_ERROR,
                    payload: error,
                })
            })
    });

export const getTherapeuticAreas = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_TA});
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.getAllTAs();
            })
            .then((results) => {
                if (!Array.isArray(results.body)) {
                    dispatch({
                        type: GET_TA_ERROR,
                        payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
                    })
                    return;
                }
                dispatch({
                    type: GET_TA_SUCCESS,
                    payload: results.body,
                })
            })
            .catch((error) => {
                dispatch({
                    type: GET_TA_ERROR,
                    payload: error,
                })
            })
    });

export const getCertifications = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_CERTIFICATIONS});
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.getCertificationsByUsername(username, dataSDK.token)
            })
            .then((results) => {
                if (!Array.isArray(results.body)) {
                    dispatch({
                        type: GET_CERTIFICATIONS_ERROR,
                        payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
                    })
                    return;
                }
                dispatch({
                    type: GET_CERTIFICATIONS_SUCCESS,
                    payload: results.body,
                })
            })
            .catch((error) => {
                dispatch({
                    type: GET_CERTIFICATIONS_ERROR,
                    payload: error,
                })
            })
    });

export const getActiveModalities = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_ACTIVE_MODALITIES});
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.getActiveModalities();
            })
            .then((results) => {
                if (!Array.isArray(results.body)) {
                    dispatch({
                        type: GET_ACTIVE_MODALITIES_ERROR,
                        payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
                    })
                    return;
                }

                dispatch({
                    type: GET_ACTIVE_MODALITIES_SUCCESS,
                    payload: results.body,
                })
            })
            .catch((error) => {
                dispatch({
                    type: GET_ACTIVE_MODALITIES_SUCCESS,
                    payload: error,
                })
            })
    });

export const getAllModalities = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_ALL_MODALITIES});
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.getAllModalities();
            })
            .then((results) => {
                if (!Array.isArray(results.body)) {
                    dispatch({
                        type: GET_ALL_MODALITIES_ERROR,
                        payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
                    })
                    return;
                }

                dispatch({
                    type: GET_ALL_MODALITIES_SUCCESS,
                    payload: results.body,
                })
            })
            .catch((error) => {
                dispatch({
                    type: GET_ALL_MODALITIES_ERROR,
                    payload: error,
                })
            })
    });