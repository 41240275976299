import { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RightPanel } from "../../../containers/AppLayout/components";
import { AvatarParticipant, ChatFiles, ChatLinks, ChatMedia, ColumFlexParticipant, ImageContainerParticipant, ParticipantWrap, ParticipantsTitle, ParticipantsWrapper, ResourcesSelector, TextTypeMember, Text, ScrollParticipants } from "./components";
import { useTranslation } from "react-i18next";
import { ChatResourcesListInterface } from "../../../interfaces/chat/ChatResourcesListInterface";
import { ChatResourceInterface } from "../../../interfaces/chat/ChatResourceInterface";
import { defaultResources } from "../../../helpers/chat/chatHelper";
import { ChatServiceInterface } from "../../../interfaces/chat/ChatServiceInterface";
import { ChatGroupParticipant } from "../../../interfaces/chat/ChatGroupParticipant";
import { capitalize } from "../../../helpers/letterHelper";
import { CircleStatusHeader } from "../../Header";
import IConversationGroupMediaOutput from "../../../interfaces/chat/IConversationGroupMediaOutput";

const ChatRightPanel = ({ ChatServiceInstance }: { ChatServiceInstance: ChatServiceInterface }) => {
    const { t, i18n } = useTranslation();
    const { id, idGroup } = useParams();

    const [selectedValue, setSelectedValue] = useState<string>('media');
    const [showConnections, setShowConnections] = useState<'media-participants' | 'media' | 'participants' | ''>('');
    // const [resources, setResources] = useState<ChatResourcesListInterface>(
    //     defaultResources(id, idGroup)
    // );

    const resources: ChatResourcesListInterface = useMemo(() => {
        if (!ChatServiceInstance.activeConversationMedia) return defaultResources(id, idGroup);
        console.log(ChatServiceInstance.activeConversationMedia)

        const mediaImg = ChatServiceInstance.activeConversationMedia.filter((el) => el.type === 'IMAGE');
        const mediaFile = ChatServiceInstance.activeConversationMedia.filter((el) => el.type === 'FILE');
        const mediaLink = ChatServiceInstance.activeConversationMedia.filter((el) => el.type === 'LINK');

        return {
            conversation_id: id ?? "",
            images:
                mediaImg.map((img) => {
                    return {
                        media: {
                            file: img.media,
                            type: 'IMG'
                        },
                        from: {
                            fullName: "",
                            username: "",
                            avatar: "",
                            status: "available"
                        },
                        to: {
                            fullName: "",
                            username: "",
                            avatar: "",
                            status: "available"
                        }
                    }
                })
            ,
            files:
                mediaFile.map((fle) => {
                    return {
                        media: {
                            file: fle.media,
                            type: 'FILE'
                        },
                        from: {
                            fullName: "",
                            username: "",
                            avatar: "",
                            status: "available"
                        },
                        to: {
                            fullName: "",
                            username: "",
                            avatar: "",
                            status: "available"
                        }
                    }
                })
            ,
            links:
                mediaLink.map((lnk) => {
                    return {
                        media: {
                            file: lnk.media,
                            type: 'LINK'
                        },
                        from: {
                            fullName: "",
                            username: "",
                            avatar: "",
                            status: "available"
                        },
                        to: {
                            fullName: "",
                            username: "",
                            avatar: "",
                            status: "available"
                        }
                    }
                })
        }
    }, [ChatServiceInstance.activeConversationMedia])

    useEffect(() => {
        if (location.pathname.includes('conversation')) {
            if (id && !idGroup) {
                setShowConnections('media');
            }
            else if (id && idGroup) {
                setShowConnections('media-participants');
            }
        }
        else {
            setShowConnections('');
        }
    }, [location.pathname])



    return (
        <RightPanel>
            {(showConnections.includes('participants')) && (
                <ParticipantsWrapper>
                    <ParticipantsTitle>{t("chat_participants")}</ParticipantsTitle>
                    <ScrollParticipants>
                        {ChatServiceInstance.groupParticipants.map((participant) => (
                            <ParticipantWrap>
                                <ImageContainerParticipant>
                                    <CircleStatusHeader status={participant.status || 'available'} />
                                    <AvatarParticipant src={participant.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} />
                                </ImageContainerParticipant>
                                <ColumFlexParticipant>
                                    <Text>{participant.fullName}</Text>
                                    <TextTypeMember>{capitalize(participant.chatMemberType || 'MEMBER')}</TextTypeMember>
                                </ColumFlexParticipant>
                            </ParticipantWrap>
                        ))}
                    </ScrollParticipants>
                </ParticipantsWrapper>
            )}
            {(showConnections.includes('media')) && (
                <>
                    <ResourcesSelector
                        values={[['media', resources.images.length], ['files', resources.files.length], ['links', resources.links.length]]}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                    />
                    {(selectedValue === 'media') && (
                        <ChatMedia media={resources.images.map((img: ChatResourceInterface) => img.media.file)} />
                    )}

                    {(selectedValue === 'files') && (
                        <ChatFiles files={resources.files.map((file: ChatResourceInterface) => file.media)} />
                    )}

                    {(selectedValue === 'links') && (
                        <ChatLinks links={resources.links.map((link: ChatResourceInterface) => link.media)} />
                    )}
                </>
            )}
        </RightPanel>
    );
}

export default ChatRightPanel;