import { ContactHomeContainer, ContactHomeForm, ImpContactHome, InputContactHome, InputLabelContactHome, 
    InputWrapContactHome, Row2ContactHome, SubtitleContactUs, WrapperSelectContactHome, ContainerSubText, ContainerText,
    TitleContactUs, RowButton } from './styles';
import { TitleHome } from '../SearchHome/styles';
import { useContext, useState } from 'react';
import { getDataSDK, getDataSDKResponse } from '../../../helpers/backendHelper';
import { ContactUsForm } from '@vibe/sdk/dist/interfaces/AccessManager/contactUsForm';
import { ContextSuccess } from '../../../Context';
import ErrorField from '../../BaseComponents/ErrorField';
import TextEditor from '../../BaseComponents/TextEditor';
import ButtonSpinner from '../../BaseComponents/ButtonSpinner';
import Selector from '../../BaseComponents/Selector';
import SelectItem from '../../../interfaces/SelectItem';
import { BaseFieldState, getFieldState } from '../../BaseComponents/utils';
import styled from 'styled-components';

const ContactHome = () => {
    const currentSuccess = useContext(ContextSuccess);

    const [showOptions, setShowOptions] = useState(false);
    const [searchInterest, setSearchInterest] = useState<string>('');
    const [interest, setInterest] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: '', value: '' }));
    const [firstName, setFirstName] = useState<string>('');
    const [firstNameError, setFirstNameError] = useState<[boolean, string]>([false, '']);
    const [lastName, setLastName] = useState<string>('');
    const [lastNameError, setLastNameError] = useState<[boolean, string]>([false, '']);
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<[boolean, string]>([false, '']);
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [tellMore, setTellMore] = useState<string>('');

    const interestOptions: SelectItem[] = [{
        label: 'Seeker',
        value: 'IND',
    },
    {
        label: 'Healer',
        value: 'HEA',
    },
    {
        label: 'Investor',
        value: 'INV',
    },
    {
        label: 'Partner',
        value: 'PAR',
    },
    {
        label: 'Press',
        value: 'PRE',
    },
    {
        label: 'Other',
        value: 'OTH',
    }]

    const cleanFields = () => {
        setInterest(getFieldState({ label: '', value: '' }));
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhoneNumber('');
        setTellMore('');
    }

    const checkForm = (): boolean => {
        let errors = false;
        if (!interest.value || interest.value.value.length === 0) {
            setInterest({
                ...interest,
                showError: true,
                error: 'Please select an option'
            });
            errors = true;
        } else {
            setInterest({
                ...interest,
                showError: false,
                error: ''
            });
        }
        if (!firstName || firstName.length === 0) {
            setFirstNameError([true, 'First Name is required']);
            errors = true;
        } else {
            setFirstNameError([false, '']);
        }
        if (!lastName || lastName.length === 0) {
            setLastNameError([true, 'Last Name is required']);
            errors = true;
        } else {
            setLastNameError([false, '']);
        }
        if (!email || email.length === 0) {
            setEmailError([true, 'Email is required']);
            errors = true;
        } else if (!email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
            setEmailError([true, 'Email is not in the right format']);
            errors = true;
        } else {
            setEmailError([false, '']);
        }
        return !errors;
    }

    const handleSend = (event: any) => {
        event.preventDefault();
        if (!checkForm()) {
            return;
        }
        setLoading(true);

        const toSend: ContactUsForm = {
            interest: interest.value?.value as 'IND' | 'HEA' | 'INV' | 'PAR' | 'PRE' | 'OTH' || 'OTH',
            firstName: firstName || '',
            lastName: lastName || '',
            email: email || '',
        }

        if (tellMore) toSend.tellMore = tellMore;
        if (phoneNumber) toSend.phoneNumber = phoneNumber;

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.AccessManager.contactUsForm(toSend, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 201) {
                    cleanFields();
                    currentSuccess?.setSuccessData(true, true, 'Info sent!', "We'll contact you soon.")
                }
                else {
                    console.log('Error on contact form:', results);
                    currentSuccess?.setSuccessData(false, true, 'Error sending info!', "Try again later.")
                }
            })
            .catch((err) => {
                console.error(err);
                currentSuccess?.setSuccessData(false, true, 'Error sending info!', "Try again later.");
            })
            .finally(() => setLoading(false))
    }

    return (
        <ContactHomeContainer>
            <ContainerText>
                <TitleContactUs>
                    INTERESTED IN RAISING THE VIBRATION OF THE PLANET?
                </TitleContactUs>
                <SubtitleContactUs>
                    Request an invitation to join Vibe.Love!
                </SubtitleContactUs>
            </ContainerText>
            <ContainerSubText>
                Vibe empowers healers to focus on transforming lives with a single tool, to better support and grow their communities while increasing revenue. This provides consumers with the content and a portal to live an optimal, youthful, and enlightened life.
            </ContainerSubText>
            <ContactHomeForm>
                <InputWrapContactHome>
                    <InputLabelContactHome>
                        What is you interest in Vibe<ImpContactHome>*</ImpContactHome>
                    </InputLabelContactHome>
                    <WrapperSelectContactHome>
                        <Selector
                            options={interestOptions}
                            selected={interest}
                            setSelected={setInterest}
                            searchItem={searchInterest}
                            setSearchItem={setSearchInterest}
                            placeholder='Select an option'
                        />
                    </WrapperSelectContactHome>
                    {(interest.showError) && (<ErrorField error={interest.error} />)}
                </InputWrapContactHome>

                <Row2ContactHome>
                    <InputWrapContactHome>
                        <InputLabelContactHome>
                            First name<ImpContactHome>*</ImpContactHome>
                        </InputLabelContactHome>
                        <InputContactHome
                            onChange={(event) => setFirstName(event.currentTarget.value)}
                            value={firstName}
                        />
                        {(firstNameError?.[0]) && (<ErrorField error={firstNameError?.[1]} />)}
                    </InputWrapContactHome>
                    <InputWrapContactHome>
                        <InputLabelContactHome>
                            Last name<ImpContactHome>*</ImpContactHome>
                        </InputLabelContactHome>
                        <InputContactHome
                            onChange={(event) => setLastName(event.currentTarget.value)}
                            value={lastName}
                        />
                        {(lastNameError?.[0]) && (<ErrorField error={lastNameError?.[1]} />)}
                    </InputWrapContactHome>
                </Row2ContactHome>

                <Row2ContactHome>
                    <InputWrapContactHome>
                        <InputLabelContactHome>
                            Email<ImpContactHome>*</ImpContactHome>
                        </InputLabelContactHome>
                        <InputContactHome
                            onChange={(event) => setEmail(event.currentTarget.value)}
                            value={email}
                        />
                        {(emailError?.[0]) && (<ErrorField error={emailError?.[1]} />)}
                    </InputWrapContactHome>
                    <InputWrapContactHome>
                        <InputLabelContactHome>
                            Phone number
                        </InputLabelContactHome>
                        <InputContactHome
                            onChange={(event) => setPhoneNumber(event.currentTarget.value)}
                            value={phoneNumber}
                        />
                    </InputWrapContactHome>
                </Row2ContactHome>

                <InputWrapContactHome>
                    <InputLabelContactHome>
                        Tell us more (optional)
                    </InputLabelContactHome>
                    <TextEditor
                        value={tellMore}
                        setValue={setTellMore}
                        charLimit={1000}
                        placeholder={"Write a personal message to Vibe..."}
                        minHeight={150}
                    />
                </InputWrapContactHome>
                <RowButton>
                    <ButtonSpinner
                        loading={loading}
                        handleClick={handleSend}
                        text={'Vibe with us'}
                        hasIcon={true}
                        icon={'arrow-right'}
                    />
                </RowButton>
                
            </ContactHomeForm>

        </ContactHomeContainer>
    )
}

export default ContactHome; 