import { useContext, useState } from 'react'
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextEditor from "../BaseComponents/TextEditor";
import { useDispatch, useSelector } from 'react-redux';
import { ContextSuccess } from '../../Context';
import { RootState } from '../../store/store';
import { BaseFieldState, getFieldState } from '../BaseComponents/utils';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { getUser, getUserInfoProfile } from '../../store/actions/userActions';
import { CountryOption } from '../../hooks/useCountry';
import { Loader } from '../BaseComponents/Loader';
import SelectorCountry from '../BaseComponents/Location/SelectorCountry';
import { getOrganizationInfo, updateLogoOrg, uploadImage } from '../../store/actions/orgsActions';
import AddPhotoRounded from '../BaseComponents/ProfilePhoto/AddPhotoRounded';
import { LogoInterface } from '../signupsetuphealer/Introduction';
import { IUpdateOrganizationBody } from '@vibe/sdk/dist/interfaces/Admin/updateOrganization';

const GeneralHealer = () => {

  const dispatch = useDispatch();
  const currentSuccess = useContext(ContextSuccess);

  const [loading, setLoading] = useState<boolean>(false);

  const organizationInfo = useSelector((state: RootState) => state.getOrgs.organizationInfo);

  const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
  const organization = useSelector((state: RootState) => state.getOrgs.organizationInfo);

  const [orgname, setOrgname] = useState<string>(organization.name || '');
  const [website, setWebsite] = useState<string>(organization.website || '');
  const [country, setCountry] = useState<BaseFieldState<CountryOption>>(getFieldState({ label: organization.country, value: organization.countryCode }));
  const [city, setCity] = useState<string>(organization.city || '');
  const [address, setAddress] = useState<string>(organization.address || '');
  const [zipcode, setZipcode] = useState<string>(organization.zipcode || '')
  const [statement, setStatement] = useState<string>(organization.mission || '')

  const [updated, setUpdated] = useState<boolean>(false);
  const [searchCountry, setSearchCountry] = useState('');

  const [logoOrg, setLogoOrg] = useState<BaseFieldState<LogoInterface>>(getFieldState({ file: organization.logo || '', fileLink: organization.logo || '' }));
  const [newLogo, setNewLogo] = useState(false);

  const handleSaveImage = () => new Promise((resolve, reject) => {
    const dataFile = URL.createObjectURL(logoOrg.value?.file as any);
    let contentType: any;
    let file: any;
    fetch(dataFile)
      .then(res => res.blob())
      .then(blob => {
        contentType = dataFile.split(';')[0].split(':')[1];
        const extension = contentType.split('/')[1]
        file = new File([blob], 'Logo' + organization?.id, { type: contentType });

        if (file) {
          return dispatch<any>(updateLogoOrg(
            file.type,
            file.name + '.' + extension,
            "school_logo"
          ))
        }
        else {
          return Promise.reject(new Error("File not found"));
        }
      })
      .then((results: any) => {
        if (results.body) {

          dispatch<any>(uploadImage(
            results.body,
            contentType,
            file,
          ))
            .then((result: any) => {
              if (result.status === 200) {
                const urlImage = results.body.split('?')[0];
                setLogoOrg({
                  ...logoOrg,
                  value: {
                    ...logoOrg.value as LogoInterface,
                    fileLink: urlImage,
                    file: urlImage
                  }
                })
                resolve(urlImage);
              }
              else {
                console.log(result)
                reject();
              }
            })
            .catch((error: any) => {
              console.log(error)
              reject();
            })

        }
      })
  });

  const saveEditProfile = async () => {
    setLoading(true);

    const toSend: IUpdateOrganizationBody = {
      name: orgname !== '' ? orgname : undefined,
      website: website !== '' ? website : undefined,
      countryCode: country.value?.value !== 'N' ? country.value?.value : undefined,
      city: city !== '' ? city : undefined,
      zipcode: zipcode !== '' ? zipcode : undefined,
      mission: statement !== '' ? statement : undefined,
      address: address !== '' ? address : undefined,
    }

    if (newLogo && logoOrg.value?.file !== '') {
      toSend.logo = await handleSaveImage() as string;
    }

    getDataSDK()
      .then((dataSDK: getDataSDKResponse) =>
        Promise.all([
          dataSDK.sdk.Admin.updateOrganization(
            organizationInfo.id,
            toSend,
            organizationInfo.status.startsWith('PEND'),
            dataSDK.token),
          dataSDK.sdk.Admin.editUser(
            userInfo?.username || '',
            {
              countryCode: country.value?.value !== 'N' ? country.value?.value : undefined,
              zipcode: zipcode !== '' ? zipcode : undefined,
            },
            'OA',
            dataSDK.token)
        ])
      )
      .then(([orgResults, userResults]) => {
        dispatch(getUser(userInfo?.username || '', 'OA'));
        dispatch(getUserInfoProfile(userInfo?.username || ''));
        dispatch(getOrganizationInfo(organizationInfo.id));

        if (orgResults && userResults && orgResults.statusCode === 200 && userResults.statusCode === 200) {
          currentSuccess?.setSuccessData(true, true, 'Information updated.');
          setUpdated(false);
        } else if (orgResults && orgResults.statusCode === 200) {
          currentSuccess?.setSuccessData(true, true, 'Information updated.');
          setUpdated(false);
        } else if (userResults && userResults.statusCode === 200) {
          currentSuccess?.setSuccessData(true, true, 'Information updated.');
          setUpdated(false);
        } else {
          console.log(orgResults?.body, userResults?.body)
          currentSuccess?.setSuccessData(false, true, 'Error updating information.');
        }
      })
      .catch((error) => {
        console.error(error);
        currentSuccess?.setSuccessData(false, true, 'Error updating information.', error);
      })
      .finally(() => setLoading(false));
  }

  const onChangeField = (data: any, handle: (data: any) => void) => {
    setUpdated(true);
    handle(data);
  }

  return (
    <Container>
      <AddPhotoRounded
        logo={logoOrg}
        setLogo={setLogoOrg}
        setNewLogo={(data: boolean) => onChangeField(data, setNewLogo)}
        text={'Edit company logo'}
      />
      <TwoColumns>
        <Column>
          <Label>Company / Organization Name</Label>
          <Input
            value={orgname}
            type={'text'}
            onChange={(e) => onChangeField(e.target.value, setOrgname)}
          />
        </Column>
        <Column>
          <Label>Website</Label>
          <Input
            value={website}
            type={'text'}
            onChange={(e) => onChangeField(e.target.value, setWebsite)}
          />
        </Column>
      </TwoColumns>
      <TwoColumns>
        <Column>
          <TwoColumns>
            <Column>
              <Label>Address</Label>
              <Input
                value={address}
                type={'text'}
                onChange={(e) => onChangeField(e.target.value, setAddress)}
              />
            </Column>
            <Column>
              <Label>City</Label>
              <Input
                value={city}
                type={'text'}
                onChange={(e) => onChangeField(e.target.value, setCity)}
              />
            </Column>
          </TwoColumns>
        </Column>
        <Column>
          <TwoColumns>
            <Column>
              <Label>Country</Label>
              <SelectorCountry
                country={country}
                setCountry={(data: BaseFieldState<CountryOption>) => onChangeField(data, setCountry)}
                searchCountry={searchCountry}
                setSearchCountry={setSearchCountry}
              />
            </Column>
            <Column>
              <Label>Zip / Postal Code</Label>
              <Input
                value={zipcode}
                type={'text'}
                onChange={(e) => onChangeField(e.target.value, setZipcode)}
              />
            </Column>
          </TwoColumns>
        </Column>
      </TwoColumns>
      <OneColumn>
        <Column>
          <Label>Company Statement</Label>
          <TextEditor
            value={statement}
            setValue={(e: string) => onChangeField(e, setStatement)}
            charLimit={1000}
            maxHeight={'300px'}
            placeholder='Please describe your organization...'
          />
        </Column>
      </OneColumn>
      <ContButtn>
        {loading ?
          <WrapperSpinner>
            <Loader />
          </WrapperSpinner> :
          <Button onClick={saveEditProfile} color={updated ? '#2e3031' : '#bbbabc'}>Save</Button>
        }
      </ContButtn>
    </Container>
  )
}

const ContButtn = styled.div`
  display: flex;
  justify-content: flex-end;
`

const WrapperSpinner = styled.div`
  background-color: #2e3031;
  width: 165px;
  height: 40px;
  display: flex;
  justify-content: center;
  padding: 12px 0;
  border-radius: 8px;
  color: white;
  font-family: 'Inter-Bold';
`;

interface ButtonProps {
  color: string;
}

const Button = styled.div`
  background-color: ${(props: ButtonProps) => props.color};
  width: 165px;
  height: 40px;
  display: flex;
  justify-content: center;
  padding: 12px 0;
  border-radius: 8px;
  color: white;
  font-family: 'Inter-Bold';
  cursor: pointer;
`

const Image = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 56px;
  background-color: #C5F2EC;
  display: flex;
  justify-content: center;
  align-items:center;
  color: #012D26;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  line-height: 32.667px;
  letter-spacing: 0.233px;
`
const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  gap: 8px;
  color: #2A4CD3;
  margin-bottom: 15px;

  text-align: center;
  font-family: Inter-Bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.1px;
`
const OneColumn = styled.div``

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: black;
    cursor: pointer;
`;
const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
`

const InputIcon = styled.div.attrs(props => ({
  className: props.theme.main,
}))`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  border-radius: 9px;
  align-items: center;
  padding-right: 15px;
  border: 1px solid var(--simple-gray);
  height: 40px;
  
  & ${Input}{
      border: 0;
      height: 38px;
  } 
`

const Imp = styled.p`
    color: red;
    margin-left: 5px;
`
const Column = styled.div`
flex: 1;
text-align: left;
display: flex;
flex-direction: column;
gap: 8px;
`
const Label = styled.div`
  display: flex;
  gap: 10px;
`
const TwoColumns = styled.div`
  display: flex;
  gap: 32px;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 30px 0;
  flex: 1;
  height: 100vh;
`


export default GeneralHealer