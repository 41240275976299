import styled from "styled-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getRole } from "../../../hooks/Clubs/useClubsList";
import SelectItem from "../../../interfaces/SelectItem";
import { RootState } from "../../../store/store";
import { unixToDateFormatted } from "../../../helpers/dateHelper";
import BlankTable from "../../BaseComponents/BlankTable";
import Selector from "../../BaseComponents/Selector";
import SearchInput from "../../BaseComponents/SearchInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useUsersList, { UseUserRole } from "../../../hooks/users/useUsersList";
import { getRolesUsersView } from "../../../helpers/roleHelper";
import { PAGE_SIZES } from "../MyEvents/EventAdmin";
import { BaseFieldState, getFieldState } from "../../BaseComponents/utils";

const AdminCenterMembers = () => {

    const navigate = useNavigate();
    const userRoles = useSelector((state: RootState) => state.getUser.userRoles);

    const [search, setSearch] = useState<string>('');
    const [searchPage, setSearchPage] = useState<string>('');
    const [searchRole, setSearchRole] = useState<UseUserRole>('ALL');
    const [page, setPage] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: '', value: '' }));

    const { reloadList, allFilteredUsers } = useUsersList({
        searchName: search,
        role: getRole(userRoles),
        searchRole: searchRole,
    });

    const options = PAGE_SIZES.map((num) => ({
        value: `${num}`,
        label: `${num} results`,
    }))

    const optionsRole: SelectItem[] = [
        {
            label: 'All',
            value: 'ALL'
        },
        {
            label: 'Healers',
            value: 'HEALER'
        },
        {
            label: 'Seekers',
            value: 'SEEKER'
        },
        {
            label: 'Staff',
            value: 'STAFF'
        },
        {
            label: 'Advisory',
            value: 'ADVISORY'
        },
        {
            label: 'Committee',
            value: 'COMMITTEE'
        }
    ]

    const handleClick = (data: string) => {
        navigate(`../../../../admin/members/${data}/view`)
    }

    useEffect(() => {
        setPage({
            ...page,
            value: options[0]
        })
    }, [])

    return (
        <ContentAdmin>
            <Label>Manage Members</Label>

            <UserRoleSelector>
                {optionsRole.map((role) => (
                    <UserRole onClick={() => setSearchRole(role.value as UseUserRole)}>
                        {role.value === searchRole ? 
                            <BlankCircle><Point /></BlankCircle> : <BlankCircle />}
                        {role.label}
                    </UserRole>
                ))}
            </UserRoleSelector>

            <WrapperRow>
                <SearchInput
                    filteredItem={search}
                    setFilteredItem={setSearch}
                    border={'1px solid var(--simple-gray)'}
                    placeholder={`Search by name or email`}
                />
                {/* <WrapperRow2>
                    <LabelLittle>Viewing</LabelLittle>
                    <Selector
                        options={options}
                        selected={page}
                        setSelected={setPage}
                        searchItem={searchPage}
                        setSearchItem={setSearchPage}
                        backgroundColor={'var(--t-blue)'}
                        border={'0px'}
                    />
                </WrapperRow2> */}
            </WrapperRow>
            <Scroll>
                <BlankTable
                    headerColored
                    border
                    maxWidth={'200px'}
                    backgroundColor={'white'}
                    headers={[
                        'Username',
                        'First Name',
                        'Last Name',
                        'Email',
                        'Roles',
                        'Created on'
                    ]}
                    items={allFilteredUsers.map((club) => ({
                        id: club.username,
                        labels: [
                            club.username,
                            club.firstname || '',
                            club.lastname || '',
                            club.email || '',
                            getRolesUsersView(club.roles).join(', '),
                            unixToDateFormatted(club.creationDate),
                        ]
                    }))}
                    onClickRow={handleClick}
                    cursor={'pointer'}
                />
            </Scroll>
        </ContentAdmin>
    )
}

const BlankCircle = styled.div`
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 2px solid var(--simple-gray);
    justify-content: center;
    align-items: center;
`;

const Point = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: var(--simple-gray);
`;

const UserRoleSelector = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-between;
    border: 1px solid var(--simple-gray);
    border-radius: 9px;
    width: 100%;
    padding: 20px;
`;

const UserRole = styled.div`
    font-family: 'Inter';
    line-height: 18px;
    font-size: 16px;
    cursor: pointer;
    width: fit-content;

    display: flex;
    gap: 10px;
`;

const Scroll = styled.div`
    display: flex;
    width: 100%;
    overflow-y: scroll;
`;

const IconPlus = styled(FontAwesomeIcon)`
    cursor: pointer;
`;

const Area = styled.div`
    border-bottom: 1px solid var(--simple-gray);
    font-family: 'Inter';
    line-height: 18px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px 10px 10px;
    gap: 8px;
    align-items: center;
    text-align: left;
`

const Label = styled.h1`
    color: var(--t-blue);
    font-family: 'Inter-bold';
    font-size: 24px;
    font-weight: bold;
    grid-area: 1 / 1 / 2 / 3;
`;

const LabelTemplates = styled.h1`
    color: var(--t-blue);
    font-family: 'Inter-bold';
    font-size: 16px;
    font-weight: bold;
`;

const LabelLittle = styled.h1`
    color: var(--t-blue);
    font-family: 'Inter';
    font-size: 16px;
    font-weight: normal;
`;

const ContentAdmin = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 30px;
    align-items: flex-start;
    gap: 20px;
`;

const Templates = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;
    flex-direction: column;
    background-color: var(--t-off-white);
    height: 100%;
    border-radius: 9px;
    align-items: flex-start;
    padding: 15px;
`;

const TableContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;
    flex-direction: column;
`;

const WrapperRow = styled.div`
    display: flex;
    width: 100%;
    max-width: 400px;
    gap: 200px;
    align-items: center;
    height: 40px;
`;

const WrapperRow2 = styled(WrapperRow)`
    gap: 10px;
    max-width: 300px;
`;

export default AdminCenterMembers;