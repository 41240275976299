import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getUserInfoProfile, updateUser } from "../../../store/actions/userActions";
import { RootState } from "../../../store/store";
import styled from "styled-components";
import { getRolesUsersView } from "../../../helpers/roleHelper";
import { capitalize } from "../../../helpers/letterHelper";
import { unixToDateFormatted } from "../../../helpers/dateHelper";
import { ContextSuccess } from "../../../Context";
import { ButtonMember } from "./AdminCenterMemberView";
import ButtonSpinner from "../../BaseComponents/ButtonSpinner";
import { IUpdateUserBody } from "@vibe/sdk/dist/interfaces/Admin/updateUser";
import { BaseFieldState, getFieldState } from "../../BaseComponents/utils";
import useGenders, { GenderOption } from "../../../hooks/users/useGenders";
import SelectorGender from "../../BaseComponents/Genders/SelectorGenders";
import DatePicker from "react-datepicker";

function AdminCenterMemberUpdate() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id = "" } = useParams();

  const currentSuccess = useContext(ContextSuccess);
  const { GendersReactSelect } = useGenders();

  const userProfile = useSelector((state: RootState) => state.getUser.userProfile);
  const loadingUpdate = useSelector((state: RootState) => state.getUser.loadingUpdateUser);

  const [gender, setGender] = useState<BaseFieldState<GenderOption>>(getFieldState({ label: 'Select a gender', value: 'P' }));
  const [zipcode, setZipcode] = useState<BaseFieldState<string>>(getFieldState(''));
  const [birthdate, setBirthdate] = useState<BaseFieldState<string>>(getFieldState(''));
  const [firstname, setFirstname] = useState<BaseFieldState<string>>(getFieldState(''));
  const [lastname, setLastname] = useState<BaseFieldState<string>>(getFieldState(''));
  const [address, setAddress] = useState<BaseFieldState<string>>(getFieldState(''));
  const [phone, setPhone] = useState<BaseFieldState<number>>(getFieldState(3000000000));


  const loadData = () => {
    setGender({
      ...gender,
      value: GendersReactSelect.find(gen => userProfile.gender === gen.value),
    })
    setZipcode({
      ...zipcode,
      value: userProfile.zipcode,
    })
    setBirthdate({
      ...birthdate,
      value: userProfile.birthdate,
    })
    setFirstname({
      ...firstname,
      value: userProfile.firstname,
    })
    setLastname({
      ...lastname,
      value: userProfile.lastname,
    })
    setPhone({
      ...phone,
      value: userProfile.phoneNumber,
    })
    setAddress({
      ...address,
      value: userProfile.address,
    })
  }

  const handleSave = () => {   
    const toSend: IUpdateUserBody = {
      gender: gender?.value?.value || 'P',
      // zipcode: zipcode.value || '',
      birthday: birthdate.value || '00-00-0000',
      firstname: firstname.value || '',
      lastname: lastname.value || '',
      phoneNumber: phone.value || 3000000000,
      address: address.value || '',
    }    

    dispatch<any>(updateUser(id, toSend, 'OA'))
      .then((results: any) => {
        if(results){
          dispatch(getUserInfoProfile(id));
          navigate(`../../../../admin/members/${id}/view`);
        }
        else{
          currentSuccess?.setSuccessData(false, true, 'Error updating user');
        }
      })
      .catch((error: any) => currentSuccess?.setSuccessData(false, true, 'Error updating user', error));
  };

  useEffect(() => {
    dispatch(getUserInfoProfile(id))
  }, []);

  useEffect(() => {
    dispatch(getUserInfoProfile(id))
  }, [id]);

  useEffect(() => {
    loadData();
  } , [userProfile]);

  return (
    <ContentAdmin>

      <LabelTitle>
        Update Member
        <WrapperButtons>
            <ButtonCancel onClick={() => navigate(`../../../../admin/members/${id}/view`)}>Cancel</ButtonCancel>
            <WrapperButton>
                <ButtonSpinner 
                    loading={loadingUpdate}
                    handleClick={handleSave}
                    text={'Update'}
                    hasIcon={false}
                />
            </WrapperButton>
        </WrapperButtons>
      </LabelTitle>

      <ColumnA>
        <WrapperInfo>
          User
          <WrapperName>
            First Name
            <PlaceInfo
              type="text"
              value={firstname.value || ''}
              onChange={(e) => setFirstname({
                ...firstname,
                value: e.currentTarget.value,
              })}
            />
            Last Name
            <PlaceInfo
              type="text"
              value={lastname.value || ''}
              onChange={(e) => setLastname({
                ...lastname,
                value: e.currentTarget.value,
              })}
            />
          </WrapperName>
        </WrapperInfo>
        <WrapperInfo>
          Email
          <PlaceInfoNoInput>{userProfile.email}</PlaceInfoNoInput>
        </WrapperInfo>
        <WrapperInfo>
          Gender
          <SelectorGender 
            gender={gender}
            setGender={setGender}
          />
        </WrapperInfo>
        <WrapperInfo>
          Phone Number
          <PlaceInfo
            type="text"
            value={`${phone.value}` || '3000000000'}
            onChange={(e) => setPhone({
              ...phone,
              value: parseInt(e.currentTarget.value),
            })}
          />
        </WrapperInfo>
        <WrapperInfo>
          Birthdate
          <PlaceInfoBirth>
            <DatePicker
              placeholderText={'MM  /  DD  /  YYYY'}
              selected={birthdate.value ? new Date(birthdate.value || '') : null}
              onChange={(date: Date) => setBirthdate({
                  ...birthdate,
                  value: date.toLocaleDateString()
              })}
            />
          </PlaceInfoBirth>
        </WrapperInfo>
        <WrapperInfo>
          Roles
          <PlaceInfoNoInput>{getRolesUsersView(userProfile.roles).join(', ')}</PlaceInfoNoInput>
        </WrapperInfo>
        <WrapperInfo>
          Status
          <PlaceInfoNoInput>{capitalize(userProfile.status)}</PlaceInfoNoInput>
        </WrapperInfo>
        <WrapperInfo>
          Address
          <PlaceInfo
            type="text"
            value={address.value || ''}
            onChange={(e) => setAddress({
              ...address,
              value: e.currentTarget.value,
            })}
          />
        </WrapperInfo>
      </ColumnA>

      <ColumnB>
        <WrapperLogo>
          Avatar
          <LogoPlaceHolder src={userProfile.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'} />
        </WrapperLogo>
        <WrapperInfo>
          Country
          <PlaceInfoNoInput>{userProfile.country || ''}</PlaceInfoNoInput>
        </WrapperInfo>
        <WrapperInfo>
          State
          <PlaceInfoNoInput>{userProfile.state || ''}</PlaceInfoNoInput>
        </WrapperInfo>
        <WrapperInfo>
          City
          <PlaceInfoNoInput>{userProfile.city || ''}</PlaceInfoNoInput>
        </WrapperInfo>
        <WrapperInfo>
          Zipcode
          <PlaceInfoNoInput>{userProfile.zipcode || ''}</PlaceInfoNoInput>
          {/* <PlaceInfo
            type="text"
            value={zipcode.value || ''}
            onChange={(e) => setZipcode({
              ...zipcode,
              value: e.currentTarget.value,
            })}
          /> */}
        </WrapperInfo>
      </ColumnB>

      <LabelInfo>Registration Information</LabelInfo>

      <ColumnC>
        <WrapperInfo>
          Username
          <PlaceInfoNoInput>{userProfile.username || ''}</PlaceInfoNoInput>
        </WrapperInfo>
      </ColumnC>

      <ColumnD>
        <WrapperInfo>
          Creation Date
          <PlaceInfoNoInput>{unixToDateFormatted(userProfile.creationDate)}</PlaceInfoNoInput>
        </WrapperInfo>
      </ColumnD>

    </ContentAdmin>
  );
}

const WrapperName = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const ButtonCancel = styled(ButtonMember)`
  background-color: var(--t-off-white);
  border: 1px solid var(--t-blue);
  color: var(--t-blue);
  height: 100%;
`;

const WrapperButton = styled.div`
  width: 200px;
  min-width: 100px;
  display: flex;
  height: 100%;
`;

const WrapperButtons = styled.div`
  gap: 20px;
  display: flex;
  height: 100%;
`;

const PlaceInfoNoInput =  styled.div`
  align-items: center;
  background-color: var(--t-blue);
  border-radius: 9px;
  display: flex;
  height: 39px;
  width: 100%;
  padding: 0 10px;

  color: white;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: normal;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  text-align: left;
  opacity: 0.7;
`;

const PlaceInfoBirth = styled(PlaceInfoNoInput)`
  opacity: 1;
  background-color: white;
  border: 1px solid var(--simple-gray);

  input {
    background-color: transparent !important;
    color: white !important;
  }
`;

const PlaceInfo =  styled.input`
  align-items: center;
  background-color: white;
  border-radius: 9px;
  display: flex;
  height: 39px;
  width: 100%;
  padding: 0 10px;

  color: black;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: normal;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  text-align: left;

  outline: none;
  border: 1px solid var(--simple-gray);
`;

const WrapperInfo = styled.div`
  display: grid;
  width: 100%;

  color: var(--t-blue);
  font-family: 'Inter-bold';
  font-size: 16px;
  font-weight: bold;

  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  justify-items: flex-start;
  align-items: center;
`;

const WrapperLogo = styled(WrapperInfo)`
  align-items: flex-start;
`;

const LogoPlaceHolder = styled.img`
  width: 180px;
  height: 180px;
  min-width: 180px;
  min-height: 180px;
  max-width: 180px;
  max-height: 180px;
  border-radius: 8px;
  object-fit: cover;
`;

const ContentAdmin = styled.div`
  display: grid;
  width: 100%;
  height: calc(100vh - 80px);

  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 45px 1fr 22px 1fr;
  grid-column-gap: 25px;
  grid-row-gap: 35px;

  padding: 50px;
  justify-items: flex-start;
`;

const LabelTitle = styled.div`
  color: var(--t-blue);
  font-family: 'Inter-bold';
  font-size: 24px;
  font-weight: bold;
  grid-area: 1 / 1 / 2 / 3;

  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const LabelInfo = styled.h1`
  color: var(--t-blue);
  font-family: 'Inter-bold';
  font-size: 18px;
  font-weight: bold;
  grid-area: 3 / 1 / 4 / 3;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  align-items: flex-start;
`;

const ColumnA = styled(Column)`
  grid-area: 2 / 1 / 3 / 2;
`;

const ColumnB = styled(Column)`
  grid-area: 2 / 2 / 3 / 3;
`;

const ColumnC = styled(Column)`
  grid-area: 4 / 1 / 5 / 2;
`;

const ColumnD = styled(Column)`
  grid-area: 4 / 2 / 5 / 3;
`;

export default AdminCenterMemberUpdate;
