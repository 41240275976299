import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BaseFieldState, getFieldState } from "../../BaseComponents/utils";
import SelectItem from "../../../interfaces/SelectItem";
import SearchInput from "../../BaseComponents/SearchInput";
import Selector from "../../BaseComponents/Selector";
import BlankTable from "../../BaseComponents/BlankTable";
import ModalCreateEvent from "../../Events/ModalCreateEvent";
import { useSelector } from "react-redux";
import IPaymentInfo from "../../../interfaces/user/IPaymentINfo";
import { RootState } from "../../../store/store";
import { getRole } from "../../../hooks/Clubs/useClubsList";
import useEventsList from "../../../hooks/Events/useEventsList";
import { capitalize } from "../../../helpers/letterHelper";
import { ContextSuccess } from "../../../Context";
import useEventsAdminCenter from "../../../hooks/Events/useEventsAdminCenter";
import { EventsResponse } from "@vibe/sdk/dist/interfaces/Events/getEvent";
import useEventsPanel, { mapEventToCard } from "../../../hooks/Events/useEventsPanel";
import { EventCardInterface, mockEventCard } from "../../BaseComponents/Events/EventCard";
import ModalEventDetail from "../../BaseComponents/Events/ModalEventDetail";

export const PAGE_SIZES: pageSize[] = [10, 50, 1000];
export type pageSize = 10 | 50 | 1000;

export const parseDelivery = (del: 'online' | 'inPerson' | 'mixed') => {
  switch (del) {
    case 'online':
      return 'Online';

    case 'inPerson':
      return 'In Person';

    case 'mixed':
      return 'Mixed';

    default:
      return 'Not specified';
  }
}

const parseLocation = (del: 'online' | 'inPerson' | 'mixed', address1: string | undefined, address2: string | undefined) => {
  switch (del) {
    case 'online':
      return 'Online'
    case 'inPerson':
      return `${address1} ${address2 ? " - "+address2 : ""}`;
    case 'mixed':
      return `Online - ${address1}`;
    default:
      return 'Not specified';
  }
}

function EventAdmin() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentSuccess = useContext(ContextSuccess);

  const userPayment: IPaymentInfo = useSelector((state: RootState) => state.getUser.paymentInfo);
  const userRoles = useSelector((state: RootState) => state.getUser.userRoles);

  const [search, setSearch] = useState<string>('');
  const [searchPage, setSearchPage] = useState<string>('');
  const [showModalEvent, setShowModalEvent] = useState<boolean>(false);
  const [showEventDetail, setShowEventDetail] = useState<boolean>(false);
  const [page, setPage] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: '', value: '' }));
  const [eventSelected, setEventSelected] = useState<EventCardInterface>({ ...mockEventCard });

  const {
    allFilteredEvents,
    reloadOrgEvents
  } = useEventsAdminCenter({
    searchName: search,
  });

  const options = PAGE_SIZES.map((num) => ({
    value: `${num}`,
    label: `${num} results`,
  }))

  const handleClick = (data: string) => {
    const event = allFilteredEvents.find(e => e.event_id === data);
    if (event) {
      setEventSelected(mapEventToCard(event));
      setShowEventDetail(true);
    }
  }

  const handleCreateEvent = () => {
    if (!userPayment.hasActiveSubscription) {
      currentSuccess?.setModalPayInfo("To create new events you need an active subscription or be assigned as a team or community's guide by your organization admin")
      return;
    }
    setShowModalEvent(true);
  }

  const handleDelete = (id: string) => {
    //@todo
  }

  useEffect(() => {
    setPage({
      ...page,
      value: options[0]
    })
  }, [])

  return (
    <ContentAdmin>
      <HeaderAdmin>
        Manage Events
        <Button onClick={handleCreateEvent}>
          Create Event
          <StyledFontAwesomeIcon
            size='sm'
            color='white'
            icon='plus'
          />
        </Button>
      </HeaderAdmin>

      <WrapperRow>
        <SearchInput
          filteredItem={search}
          setFilteredItem={setSearch}
          border={'1px solid var(--simple-gray)'}
          placeholder={`Search by event name`}
        />
        {/* <WrapperRow2>
          <LabelLittle>Viewing</LabelLittle>
          <Selector
            options={options}
            selected={page}
            setSelected={setPage}
            searchItem={searchPage}
            setSearchItem={setSearchPage}
            backgroundColor={'var(--t-blue)'}
            border={'0px'}
          />
        </WrapperRow2> */}
      </WrapperRow>
      <Scroll>
        <BlankTable
          headerColored
          border
          maxWidth={'200px'}
          backgroundColor={'white'}
          headers={[
            'Event Name',
            'Location',
            'Start',
            'End',
            'Delivery',
            'Reach'
          ]}
          items={allFilteredEvents.map((event: EventsResponse) => ({
            id: event.event_id,
            labels: [
              event.name,
              parseLocation(event.delivery, event.addressLine1, event.addressLine2),
              parseDate(event.start),
              parseDate(event.end),
              parseDelivery(event.delivery),
              event.reach.map(r => capitalize(r)).join(', '),
            ]
          }))}
          onClickRow={handleClick}
          cursor={'pointer'}
          actions={['DELETE']}
          actionsHandlers={{
            handleDelete,
          }}
        />
      </Scroll>
      <ModalCreateEvent
        showModal={showModalEvent}
        setShowModal={setShowModalEvent}
        reloadEvents={reloadOrgEvents}
      />
      {showEventDetail &&
        <ModalEventDetail
          event={eventSelected}
          show={showEventDetail}
          setShow={setShowEventDetail}
        />
      }

    </ContentAdmin>
  );
}

const parseDate = (date: string): string => {
  const parts = date.split('-');

  return `${parts[0]}/${parts[1]}/${parts[2]} ${parts[3]}:${parts[4]}`;
}

const Scroll = styled.div`
    display: flex;
    width: 100%;
    overflow-y: scroll;
`;

const LabelLittle = styled.h1`
    color: var(--t-blue);
    font-family: 'Inter';
    font-size: 16px;
    font-weight: normal;
`;

const WrapperRow = styled.div`
    display: flex;
    width: 100%;
    max-width: 400px;
    gap: 200px;
    align-items: center;
    height: 40px;
`;

const WrapperRow2 = styled(WrapperRow)`
    gap: 10px;
    max-width: 300px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 200px;
  min-width: 100px;
  height: 100%;
  padding: 10px 20px;
  border-radius: 11px;
  background-color: var(--simple-blue-dark);
  border: 0;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: white;
  gap: 10px;

  cursor: pointer;
`;

const HeaderAdmin = styled.div`
    display: flex;
    justify-content: space-between;
    color: var(--t-blue);
    font-family: 'Inter-bold';
    font-size: 24px;
    font-weight: bold;

    grid-area: 1 / 1 / 2 / 3;
`;

const ContentAdmin = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 80px);

  flex-direction: column;
  padding: 30px;
  gap: 20px;
`;

export default EventAdmin;
