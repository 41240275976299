import React, { useEffect, useState, useContext, useRef, Dispatch, SetStateAction } from 'react'
import styled from "styled-components";
import { Imp } from "../../containers/Website/Form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextEditor from "../BaseComponents/TextEditor";
import { BaseFieldState, getFieldState } from '../BaseComponents/utils';
import { EventGeneralInfo, MockEventSession, MockSessionFacilitator, categoryTypeEvent, clubReachTypeEvent, reachTypeEvent } from '../../interfaces/Event/CreateEvent';
import Selector from '../BaseComponents/Selector';
import SelectItem from '../../interfaces/SelectItem';
import useClubsList, { getRole } from '../../hooks/Clubs/useClubsList';
import { ClubType } from '../../interfaces/club/ClubList';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { TZ } from '@vibe/sdk/dist/interfaces/Roster/TZ';
import DatePicker from "react-datepicker";
import MyTimePicker from '../MyTimePicker';
import moment, { Moment } from "moment";
import { faCalendar, faCheck, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { ContextSuccess } from '../../Context';
import { useTranslation } from 'react-i18next';
import useClubs from '../../hooks/Clubs/useClubs';
import { IClubExtended } from '@vibe/sdk/interfaces/Club/ClubInterface';
import { EventSchedule } from '@vibe/sdk/dist/interfaces/Events/EventSchedule';

const TabSchedule = ({
    eventGeneral,
    setEventGeneral,
    setTabOk,
    cleanData,
    nextPressed,
    isUpdate = false,
}: {
    eventGeneral: EventGeneralInfo
    setEventGeneral: Dispatch<SetStateAction<EventGeneralInfo>>
    setTabOk: (data: boolean) => void
    cleanData: boolean,
    nextPressed: boolean
    isUpdate?: boolean
}) => {

    const { all } = useClubs({});

    const currentSuccess = useContext(ContextSuccess);
    const userRoles = useSelector((state: RootState) => state.getUser.userRoles);
    const { t, i18n } = useTranslation();

    const tz: TZ[] = useSelector((state: RootState) => state.getLocation.timezones);
    const [timezones, setTimezones] = useState<SelectItem[]>([]);
    const [timezone_search, setTimezone_search] = useState<string>('');
    const [timezone, setTimezone] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: '', value: '' }));
    const [checkDateEnd, setCheckDateEnd] = useState<boolean>(false);

    const [endSelected, setEndSelected] = useState<boolean>(false);
    const [startSelected, setStartSelected] = useState<boolean>(false);
    const [start, setStart] = useState<Moment>(moment());
    const [end, setEnd] = useState<Moment>(moment());

    const [checkMoreDays, setCheckMoreDays] = useState<boolean>(false);
    const [showDetails, setShowDetails] = useState<boolean>(true);
    const [isOpenDate, setIsOpenDate] = useState<boolean>(false);
    const ref = useRef<null | HTMLDivElement>(null);
    const refSession = useRef<null | HTMLDivElement>(null);
    const [numberFacilitator, setNumberFacilitator] = useState<number>(1);

    const handleClick = (refC: any) => {
        const timerAct = setTimeout(() => {
            refC.current?.scrollIntoView({ behavior: 'smooth' });
        }, 500);

    };

    const getMomentDate = (mom: Moment | undefined): Date | undefined => {
        return mom ? mom.toDate() : undefined;
    };

    const getStringDate = (str: string): Date | undefined => getMomentDate(getStringMoment(str))

    const getStringMoment = (str: string): Moment | undefined => {
        if (str === '') return;
        const dateParts = str.split('-').map(Number);
        return moment(`${dateParts[0]}-${dateParts[1] - 1}-${dateParts[2]} ${dateParts[3]}:${dateParts[4]}`);
    };

    const cleanFields = () => {
        setEndSelected(false);
        setStartSelected(false);
        setStart(moment());
        setEnd(moment());
        setTimezone_search('');
        setTimezone(getFieldState({ label: '', value: '' }));
        setCheckDateEnd(false);
    }

    const checkDataComplete = () => {
        let isComplete = true;

        if (eventGeneral.timezone.length === 0) isComplete = false;
        if (eventGeneral.start.length === 0) isComplete = false;
        if (eventGeneral.end.length === 0) isComplete = false;

        if (eventGeneral.schedule.length > 0) {
            eventGeneral.schedule.forEach(s => {
                if (s.name.length === 0) { isComplete = false; }
                if (s.description.length === 0) { isComplete = false; }
                if (s.startDate.length === 0) { isComplete = false; }
                if (s.facilitators.length > 0) {
                    s.facilitators.forEach(f => {
                        if (f.firstName.length === 0) { isComplete = false; }
                        if (f.lastName.length === 0) { isComplete = false; }
                        if (f.bio.length === 0) { isComplete = false; }
                        if (f.email.length === 0) { isComplete = false; }
                    })
                }
            })
        }

        setTabOk(isComplete);
    }

    const editMoment = (mom: Moment, date: Date): Moment => {
        let year: number | string = date.getFullYear();
        let month: number | string = date.getMonth() + 1;
        let day: number | string = date.getDate();
        let hours: number | string = mom?.hours() ?? 0;
        let minutes: number | string = mom?.minutes() ?? 0;
        month < 10 ? (month = `0${month}`) : (month = `${month}`);
        day < 10 ? (day = `0${day}`) : (day = `${day}`);
        hours < 10 ? (hours = `0${hours}`) : (hours = `${hours}`);
        minutes < 10 ? (minutes = `0${minutes}`) : (minutes = `${minutes}`);
        return moment(`${year}-${month}-${day} ${hours}:${minutes}`);
    };

    const formatEventDate = (date: string) => {
        const dateS = date.split('-');
        return `${dateS[0]}-${dateS[1]}-${dateS[2]} ${dateS[3]}:${dateS[4]}`;
    }

    const getMomentTime = (mom: Moment): string => {
        if (mom === null) return "";
        let year: number | string = mom.year();
        let month: number | string = mom.month() + 1;
        let day: number | string = mom.toDate().getDate();
        let hours: number | string = mom?.hours() ?? 0;
        let minutes: number | string = mom?.minutes() ?? 0;
        month < 10 ? (month = `0${month}`) : (month = `${month}`);
        day < 10 ? (day = `0${day}`) : (day = `${day}`);
        hours < 10 ? (hours = `0${hours}`) : (hours = `${hours}`);
        minutes < 10 ? (minutes = `0${minutes}`) : (minutes = `${minutes}`);
        return `${year}-${month}-${day}-${hours}-${minutes}`;
    };

    const getScheduleTime = (date: Date | null, hours?: string, minutes?: string): string => {
        if (date === null) return "";
        let year: number | string = date.getFullYear();
        let month: number | string = date.getMonth() + 1;
        let day: number | string = date.getDate();
        month < 10 ? (month = `0${month}`) : (month = `${month}`);
        day < 10 ? (day = `0${day}`) : (day = `${day}`);
        return `${year}-${month}-${day}-${hours ?? '00'}-${minutes ?? '00'}`;
    };

    const editMomentClock = (mom1: Moment, mom2: Moment): Moment => {
        if (mom1 === null || mom2 === null) return moment();
        let year: number | string = mom1.year();
        let month: number | string = mom1.month() + 1;
        let day: number | string = mom1.toDate().getDate();
        let hours: number | string = mom2?.hours() ?? 0;
        let minutes: number | string = mom2?.minutes() ?? 0;
        month < 10 ? (month = `0${month}`) : (month = `${month}`);
        day < 10 ? (day = `0${day}`) : (day = `${day}`);
        hours < 10 ? (hours = `0${hours}`) : (hours = `${hours}`);
        minutes < 10 ? (minutes = `0${minutes}`) : (minutes = `${minutes}`);
        return moment(`${year}-${month}-${day} ${hours}:${minutes}`);
    };

    const handleOnStartDateChange = (e: any) => {
        let new_moment_e: Moment | undefined
        if (!checkMoreDays) {
            new_moment_e = editMoment(end, e);
            setEnd(new_moment_e)
        }
        const new_moment_s = editMoment(start, e);
        setStart(new_moment_s);
        setEventGeneral({
            ...eventGeneral,
            start: getMomentTime(new_moment_s),
            ...(new_moment_e ? { end: getMomentTime(new_moment_e) } : {}),
        });
    }

    const handleOnEndDateChange = (e: any) => {
        const new_moment_e = editMoment(end, e);
        setEnd(new_moment_e)
        setEventGeneral({
            ...eventGeneral,
            end: getMomentTime(new_moment_e),
        });
    }

    const handleOnEnterDate = () => {
        if (eventGeneral.start) {
            const new_start_moment = moment(formatEventDate(eventGeneral.start));
            setStart(new_start_moment);
            setStartSelected(true);
        }
        else {
            setStart(moment());
        }

        if (eventGeneral.end) {
            const new_End_moment = moment(formatEventDate(eventGeneral.end));
            setEnd(new_End_moment);
            setEndSelected(true);
            setCheckMoreDays(eventGeneral.start.split('-').slice(0, 3).join('-') !== eventGeneral.end.split('-').slice(0, 3).join('-'));
        }
        else {
            setEnd(moment());
            setCheckMoreDays(false);
        }
    }

    const handleOnStartTimeChange = (e: any) => {
        setStartSelected(e !== null);
        const new_moment = editMomentClock(start, e);
        if (new_moment < moment()) {
            currentSuccess?.setSuccessData(false, true, t('toast_invalidStartTime'), 'Please set a start time in the future');
            return;
        }

        if (!endSelected) {
            handleOnEndTimeChange(editMomentClock(start, e).add(1, 'hours'), true)
        }
        setStart(new_moment);

        setEventGeneral({
            ...eventGeneral,
            start: getMomentTime(e !== null ? new_moment : e),
            ...(endSelected ? {} : {
                end: getMomentTime(e !== null ? editMomentClock(start, e).add(1, 'hours') : e),
            })
        });
    }

    const handleOnEndTimeChange = (e: any, triggeredFromStartTime = false) => {

        setEndSelected(e !== null);
        let new_moment = editMomentClock(end, e);

        if (!triggeredFromStartTime) {
            if (new_moment < moment() || new_moment < start) {
                currentSuccess?.setSuccessData(false, true, t('toast_invalidEndTime'), 'Please set an end time in the future');
                new_moment = start.add(1, 'hours');
            }
        }

        setEnd(new_moment);
        setEventGeneral({
            ...eventGeneral,
            end: getMomentTime(e !== null ? new_moment : e),
        });
    }

    const getCurrentTimezone = (): SelectItem => {
        const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const continent = (zone || '').split("/")[0];
        const city = (zone || '').split("/")[1];
        const tzone = tz.find(
            (item: any) => item.continent === continent && item.city === city
        );
        let timezone_string = "";
        let timezone_label = "";
        if (tzone) {
            timezone_string = `${tzone.continent}#${tzone.city}#${tzone.STD}`;
            timezone_label = `${tzone.continent}, ${tzone.city} (${tzone.STD})`;
        }
        if (tzone) return {
            value: timezone_string,
            label: timezone_label
        }
        else return {
            label: "America, Bogota (-05:00)",
            value: "America#Bogota#-05:00",
        }
    };

    useEffect(() => {
        const timezoneCurrent = getCurrentTimezone();
        setTimezones([{
            label: `My Current Time zone (${timezoneCurrent.label})`,
            value: timezoneCurrent.value
        },
        ...tz.map(t => ({
            label: `${t.continent}, ${t.city} (${t.STD})`,
            value: `${t.continent}#${t.city}#${t.STD}`,
        }))])
    }, [tz])

    useEffect(() => {
        if (eventGeneral.timezone.length !== 0) {
            const tm = timezones.find(t => t.value === eventGeneral.timezone)
            setTimezone({
                ...timezone,
                value: {
                    label: tm ? tm.label : eventGeneral.timezone,
                    value: eventGeneral.timezone
                }
            });
        }
    }, [timezones])

    useEffect(() => {
        if (cleanData) cleanFields();
    }, [cleanData])

    const divRef = useRef(null);

    const onClick = (ref: any, top: number) => {
        if (ref.current) {
            ref.current.scrollTo({ top: top, behavior: 'smooth' });
        }
    };

    const onFocus = (e: string, ref: any) => {
        if (e === 'down') {
            if (ref.current) {
                const timeout = setTimeout(() => {
                    const lastChildElement = ref.current?.lastElementChild;
                    lastChildElement?.scrollIntoView({ behavior: 'smooth' });
                }, 200)
            }
        }
    };

    useEffect(() => {
        onClick(divRef, 0)
    }, [divRef])

    useEffect(() => {
        checkDataComplete();
        if (nextPressed) handleOnEnterDate()
    }, [])

    useEffect(() => {
        checkDataComplete();
    }, [eventGeneral])

    const removeSession = (idx: number) => {
        setEventGeneral({
            ...eventGeneral,
            schedule: eventGeneral.schedule.filter((_, id) => id !== idx),
        })
    }

    const removeFacilitator = (idx: number, idxFacilitator: number) => {
        setEventGeneral((prevState: EventGeneralInfo) => {
            // Clone the entire state to avoid direct mutations
            const newState = { ...prevState };

            // Clone the specific session's schedule to a new object to avoid mutations
            const sessionToUpdate = { ...newState.schedule[idx] };

            // Filter out the facilitator to remove
            sessionToUpdate.facilitators = sessionToUpdate.facilitators.filter((_, index) => index !== idxFacilitator);

            // Update the session in the cloned schedule array
            newState.schedule = [
                ...newState.schedule.slice(0, idx),
                sessionToUpdate,
                ...newState.schedule.slice(idx + 1)
            ];

            return newState;
        });
    }

    return (
        <ContentTab_Events ref={divRef}>
            <TitleModal>Date & Time</TitleModal>
            <Inputs4211 theme={{ main: checkDateEnd && 'row211' }}>
                <Row1 onClick={() => handleClick(ref)}>
                    <Label ref={ref}>Start Date<Imp>*</Imp></Label>
                    <InputIcon>
                        <DatePicker
                            placeholderText={'MM / DD / YYYY'}
                            preventOpenOnFocus={true}
                            selected={getMomentDate(start)}
                            onChange={handleOnStartDateChange}
                            className="inputDatePicker"
                            minDate={new Date()}
                            showDisabledMonthNavigation

                        />
                        <StyledFontAwesomeIcon
                            icon={faCalendar}
                            size='lg'
                        />
                    </InputIcon>
                </Row1>

                <Row1 onClick={() => setIsOpenDate(!isOpenDate)}>
                    <Label>Start time<Imp>*</Imp></Label>
                    <Focus onClick={(e) => {/*onFocus('down', divRef)*/ }}>
                        <MyTimePicker
                            id="EventStart"
                            icon="clock"
                            handleClick={handleOnStartTimeChange}
                            value={startSelected ? start : undefined}
                            showToday={true}
                        />
                    </Focus>
                </Row1>

                {!checkMoreDays && (
                    <Row1 onClick={() => setIsOpenDate(!isOpenDate)}>
                        <Label>End time<Imp>*</Imp></Label>
                        <Focus onClick={(e) => {/*onFocus('down', divRef)*/ }}>
                            <MyTimePicker
                                id="EventEnd"
                                icon="clock"
                                handleClick={handleOnEndTimeChange}
                                value={endSelected ? end : undefined}
                                showToday={true}
                                classNameTime={'TimeEnd'}
                            />
                        </Focus>
                    </Row1>
                )}

                <Row1 onClick={() => setIsOpenDate(!isOpenDate)}>
                    <Label>Time zone<Imp>*</Imp></Label>
                    <Focus onClick={(e) => onFocus('down', divRef)}>
                        <Selector
                            options={timezones}
                            selected={timezone}
                            setSelected={(data: BaseFieldState<SelectItem>) => {
                                setTimezone(data);
                                setEventGeneral({
                                    ...eventGeneral,
                                    timezone: data.value?.value || '',
                                });
                            }}
                            searchItem={timezone_search}
                            setSearchItem={setTimezone_search}
                            placeholder={'Select time zone'}
                            notOrganized={true}
                        />
                    </Focus>
                </Row1>

            </Inputs4211>
            {checkMoreDays &&
                <Inputs4211 theme={{ main: checkDateEnd && 'row211' }}>
                    <Row1>
                        <Label>End Date<Imp>*</Imp></Label>
                        <InputIcon>
                            <DatePicker
                                placeholderText={'MM / DD / YYYY'}
                                preventOpenOnFocus={true}
                                selected={getMomentDate(end)}
                                onChange={handleOnEndDateChange}
                                className="inputDatePicker"
                                minDate={getMomentDate(start)}
                                showDisabledMonthNavigation
                            />
                            <StyledFontAwesomeIcon
                                icon={faCalendar}
                                size='lg'
                            />
                        </InputIcon>
                    </Row1>

                    <Row1 onClick={() => setIsOpenDate(!isOpenDate)}>
                        <Label>End time<Imp>*</Imp></Label>
                        <Focus onClick={(e) => onFocus('down', divRef)}>
                            <MyTimePicker
                                id="EventEnd2"
                                icon="clock"
                                handleClick={handleOnEndTimeChange}
                                value={endSelected ? end : undefined}
                                showToday={true}
                                classNameTime={'TimeEnd'}
                            />
                        </Focus>
                    </Row1>
                    <Row1 /> {/* PLEASE, DO NOT ERASE THIS LINE! */}
                </Inputs4211>
            }
            <ContainerCheck onClick={() => setCheckMoreDays(!checkMoreDays)}>
                <Check active={checkMoreDays} /> My event lasts more than one day
            </ContainerCheck>
            <Text>Define the schedule of the event. Add sessions/activities and facilitators.</Text>
            <TitleModalBlue onClick={() => { setShowDetails(!showDetails); handleClick(refSession) }}>{showDetails ? 'Hide' : 'Show'} Details</TitleModalBlue>
            {showDetails &&
                (
                    <>
                        {eventGeneral.schedule.map((activity, idx) => (
                            <ContainerSession ref={refSession} key={`Session${idx}`}>
                                <TitleModal>
                                    {`Session # ${idx + 1}`}
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        color='red'
                                        style={{
                                            'cursor': 'pointer'
                                        }}
                                        onClick={() => removeSession(idx)}
                                    />
                                </TitleModal>
                                <Column3>
                                    <Column>
                                        <Row1>
                                            <Label>Session's name<Imp>*</Imp></Label>
                                            <Input
                                                value={activity.name}
                                                onChange={(e) => setEventGeneral({
                                                    ...eventGeneral,
                                                    schedule: eventGeneral.schedule.map(
                                                        (s, i) => i === idx ? { ...s, name: e.target.value } : s)
                                                })}
                                            />
                                        </Row1>
                                    </Column>
                                    <Column>
                                        <Row1>
                                            <Label>Session's start date<Imp>*</Imp></Label>
                                            <InputIcon>
                                                <DatePicker
                                                    placeholderText={'MM / DD / YYYY'}
                                                    preventOpenOnFocus={true}
                                                    selected={getStringDate(activity.startDate)}
                                                    onChange={(e) => setEventGeneral({
                                                        ...eventGeneral,
                                                        schedule: eventGeneral.schedule.map(
                                                            (s, i) => i === idx ? {
                                                                ...s,
                                                                startDate: getScheduleTime(e, s.startDate.split("-")[3], s.startDate.split("-")[4])
                                                            } : s)
                                                    })}
                                                    className="inputDatePicker"
                                                    minDate={getMomentDate(start)}
                                                    maxDate={getMomentDate(end)}
                                                    showDisabledMonthNavigation
                                                />
                                                <StyledFontAwesomeIcon
                                                    icon={faCalendar}
                                                    size='lg'
                                                />
                                            </InputIcon>
                                        </Row1>
                                    </Column>
                                    <Column>
                                        <Row1 onClick={() => setIsOpenDate(!isOpenDate)}>
                                            <Label>Session's start time<Imp>*</Imp></Label>
                                            <Focus onClick={(e) => {/*onFocus('down', divRef)*/ }}>
                                                <MyTimePicker
                                                    id={`EventSession${idx}`}
                                                    noForceAMP={true}
                                                    icon="clock"
                                                    handleClick={(e: Moment) => setEventGeneral({
                                                        ...eventGeneral,
                                                        schedule: eventGeneral.schedule.map(
                                                            (s, i) => i === idx ? {
                                                                ...s,
                                                                startDate: s.startDate.split('-').splice(0, 3).concat(
                                                                    [e?.hours().toString() ?? '00', e?.minutes().toString() ?? '00']).join('-')
                                                            } : s)
                                                    })}
                                                    value={getStringMoment(activity.startDate)}
                                                    showToday={true}
                                                />
                                            </Focus>
                                        </Row1>
                                    </Column>
                                </Column3>
                                <Column>
                                    <Label>Session's description<Imp>*</Imp></Label>
                                    <TextEditor
                                        value={activity.description || ''}
                                        setValue={(e) => setEventGeneral({
                                            ...eventGeneral,
                                            schedule: eventGeneral.schedule.map(
                                                (s, i) => i === idx ? {
                                                    ...s,
                                                    description: e
                                                } : s)
                                        })}
                                        charLimit={1000}
                                        minHeight={120}
                                        maxHeight={'120px'}
                                        placeholder='Please describe the session you are creating...'
                                    />
                                </Column>
                                <ContainerSession>
                                    <TitleModal>Facilitators</TitleModal>
                                    {activity.facilitators.map((healer, idxHealer) => (
                                        <>
                                            <TitleModal>
                                                {`Facilitator # ${idxHealer + 1}`}
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    color='red'
                                                    style={{
                                                        'cursor': 'pointer'
                                                    }}
                                                    onClick={() => removeFacilitator(idx, idxHealer)}
                                                />
                                            </TitleModal>
                                            <Column3>
                                                <Column>
                                                    <Row1>
                                                        <Label>Facilitator's email<Imp>*</Imp></Label>
                                                        <Input
                                                            value={healer.email || ''}
                                                            onChange={(e) => setEventGeneral({
                                                                ...eventGeneral,
                                                                schedule: eventGeneral.schedule.map(
                                                                    (s, i) => i === idx ? {
                                                                        ...s,
                                                                        facilitators: eventGeneral.schedule[i].facilitators.map((f, j) => j === idxHealer ? {
                                                                            ...f,
                                                                            email: e.target.value
                                                                        } : f)
                                                                    } : s)
                                                            })}
                                                        />
                                                    </Row1>
                                                </Column>
                                                <Column>
                                                    <Row1>
                                                        <Label>Facilitator's first name<Imp>*</Imp></Label>
                                                        <Input
                                                            value={healer.firstName || ''}
                                                            onChange={(e) => setEventGeneral({
                                                                ...eventGeneral,
                                                                schedule: eventGeneral.schedule.map(
                                                                    (s, i) => i === idx ? {
                                                                        ...s,
                                                                        facilitators: eventGeneral.schedule[i].facilitators.map((f, j) => j === idxHealer ? {
                                                                            ...f,
                                                                            firstName: e.target.value
                                                                        } : f)
                                                                    } : s)
                                                            })}
                                                        />
                                                    </Row1>
                                                </Column>
                                                <Column>
                                                    <Row1>
                                                        <Label>Facilitator's last name<Imp>*</Imp></Label>
                                                        <Input
                                                            value={healer.lastName || ''}
                                                            onChange={(e) => setEventGeneral({
                                                                ...eventGeneral,
                                                                schedule: eventGeneral.schedule.map(
                                                                    (s, i) => i === idx ? {
                                                                        ...s,
                                                                        facilitators: eventGeneral.schedule[i].facilitators.map((f, j) => j === idxHealer ? {
                                                                            ...f,
                                                                            lastName: e.target.value
                                                                        } : f)
                                                                    } : s)
                                                            })}
                                                        />
                                                    </Row1>
                                                </Column>
                                            </Column3>
                                            <Column>
                                                <Label>Facilitator's bio<Imp>*</Imp></Label>
                                                <TextEditor
                                                    value={healer.bio || ''}
                                                    setValue={(e) => setEventGeneral({
                                                        ...eventGeneral,
                                                        schedule: eventGeneral.schedule.map(
                                                            (s, i) => i === idx ? {
                                                                ...s,
                                                                facilitators: eventGeneral.schedule[i].facilitators.map((f, j) => j === idxHealer ? {
                                                                    ...f,
                                                                    bio: e
                                                                } : f)
                                                            } : s)
                                                    })}
                                                    charLimit={1000}
                                                    minHeight={120}
                                                    maxHeight={'120px'}
                                                    placeholder='Please describe the biography of the facilitator...'
                                                />
                                            </Column>
                                        </>
                                    ))}
                                    <TextAnother onClick={() => {
                                        setNumberFacilitator(numberFacilitator + 1)
                                        setEventGeneral({
                                            ...eventGeneral,
                                            schedule: eventGeneral.schedule.map(
                                                (s, i) => i === idx ? {
                                                    ...s,
                                                    facilitators: eventGeneral.schedule[i].facilitators.concat([MockSessionFacilitator])
                                                } : s)
                                            })}
                                        }
                                    >
                                        {/* Add new facilitator */}
                                        {`Add Session #${numberFacilitator} Facilitator/Speaker`}
                                    </TextAnother>
                                </ContainerSession>
                            </ContainerSession>
                        ))}
                        <ButtonAddSession onClick={() => setEventGeneral({
                            ...eventGeneral,
                            schedule: eventGeneral.schedule.concat([MockEventSession])
                        })}>
                            Add new session
                            <IconDrop
                                icon={faCheck}
                                size='lg'
                                color={"#babbbd"}
                            />
                        </ButtonAddSession>
                    </>
                )
            }
        </ContentTab_Events>
    )
}

export default TabSchedule;

const ButtonAddSession = styled.div`
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #babbbd;
    padding: 8px 0;
    border-radius: 8px;
    gap: 9px;
    color: white;
    font-size: 14px;
    font-family: 'Inter-SemiBold';
    cursor: pointer;
`

const IconDrop = styled(FontAwesomeIcon)`
    padding: 5px;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
`;

const TextAnother = styled.div`
    text-align: left;
    font-family: 'Inter-SemiBold';
    color: gray;
    cursor: pointer;
`

const ContainerSession = styled.div`
    padding: 20px;
    border-radius: 12px;
    border: 1px solid #aeaeae;
    display: flex;
    gap: 20px;
    flex-direction: column;
`

const Text = styled.div`
    text-align: left;
    font-size: 14px;
    font-family: 'Inter';
`

const Check = styled.div`
    width: 14px;
    height: 14px;
    border-radius: 50%;
    cursor: pointer;
    border: ${({ active }: { active: boolean }) => active ? '4px solid #3062d4' : '1px solid #aeaeae'};
`

const ContainerCheck = styled.div`
    display: flex;
    align-items: center;
    gap: 7px;
    cursor: pointer;
`

const ContentTab_Events = styled.div`
    gap: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
`

const Column3 = styled.div`
    display: flex;
    width: 100%;
    gap: 10px;

    & > div{
        width: 33%;
    }
`

const Column2 = styled.div`
    display: flex;
    width: 100%;
    gap: 10px;

    & > div{
        width: 50%;
    }
`
const Column = styled.div``

const Focus = styled.div`
    height: 40px;
`

const Row1 = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 62px;
    min-height: 62px;
`;

const WrapUp = styled.div`
    display: flex;
    width: 100%;
    height: 62px;
    gap: 10px;
    flex-direction: column;
`;

const Label = styled.div`    
    width: 100%;
    text-align: left;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`

const Inputs = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const Input = styled.input`
    outline: none;
    width: 100%;
    height: 100%;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
`

const InputIcon = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    border-radius: 9px;
    align-items: center;
    padding-right: 15px;
    border: 1px solid var(--simple-gray);
    height: 40px;

    & ${Input}{
        border: 0;
        height: 38px;
    } 
`

const TitleModalBlue = styled.div`
    color: var(--absolute-zero);
    font-family: Inter-Bold;
    font-size: 12px;
    text-align: left;
    display: flex;
    cursor: pointer;
`

const TitleModal = styled.div`
    color: var(--default-dark);
    font-family: Inter-Bold;
    font-size: 16px;
    text-align: left;
    display: flex;
    justify-content: space-between;
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: black;
    cursor: pointer;
`;

const Inputs4211 = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    display: flex;
    gap:24px; 
`