import Article from "@vibe/sdk/dist/interfaces/Modalities/articles/Article";
import Modal from "react-bootstrap/esm/Modal";
import { IconClicker } from "../../Home/HeaderHome/styles";
import styled from "styled-components";
import { faFile, faLink } from "@fortawesome/pro-regular-svg-icons";
import { IconUser } from "../../BaseComponents/HomeCards/styles";

const ViewArticle = ({
    showModal,
    hide,
    article,
}: {
    showModal: boolean
    hide: () => void
    article: Article
}) => {
    return (
        <Modal className="modalViewArticle" show={showModal} onHide={hide}>
            <Modal.Header>
                <Modal.Title>
                    {article.name}
                    <IconClicker
                        onClick={hide}
                        icon="times"
                        size='xl'
                        color={'black'}
                    />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <div dangerouslySetInnerHTML={{ __html: article.description || '' }} />

                <RowClicker onClick={() => window.open(article.link, "_blank")}>
                    <IconClicker
                        icon={article.type === 'FILE' ? faFile : faLink}
                        size='sm'
                        color="var(--absolute-zero)"
                    />
                    {article.type === 'FILE' ? 'File' : 'Link'}
                </RowClicker>

                <RowCreator>
                    Created By {article.createdByFullName}
                </RowCreator>

                <DividerLine />

                <RowModalities>
                    {(article.modalities || []).map((s, idx, arr) => (
                        idx === arr.length - 1 ?
                            <RowIcon>{s.name}</RowIcon>
                            :
                            <RowIconDivider>{s.name}</RowIconDivider>
                    ))}
                </RowModalities>

                <DividerLine />

                <Row>
                    <IconUser src={'../icon_healer.png'} />
                    Healers
                </Row>

                <RowModalities>
                    {(article.healers || []).map((s, idx, arr) => (
                        idx === arr.length - 1 ?
                            <RowIcon>{s.fullname}</RowIcon>
                            :
                            <RowIconDivider>{s.fullname}</RowIconDivider>
                    ))}
                </RowModalities>

            </Modal.Body>
        </Modal>
    )
}

export default ViewArticle;

const RowIcon = styled.div`  
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

const RowIconDivider = styled(RowIcon)`
    :after {
        content: "|";
        padding-left: 8px;
    }
`;

const RowModalities = styled(RowIcon)`
    width: 100%;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
`;

const RowCreator = styled.div`
    width: 100%;
    gap: 20px;
    display: flex;
`;

const Row = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    height: fit-content;
    font-family: 'Inter-bold';
`;

const RowClicker = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    height: fit-content;
    cursor: pointer;
    color: var(--absolute-zero);
    justify-content: flex-start;
    align-items: center;
`;

export const DividerLine = styled.div`
    height: 1px;
    border-radius: 1px;
    width: 100%;
    background-color: var(--simple-gray);
`;