import React, { useState } from 'react'
import ImageProfile from './ImageProfile'
import './styles.scoped.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER } from "../ClubIconsNavBar";
import { SocialMediaLink } from './GeneralInfo';
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from '../../helpers/backendHelper';
import { BROWN } from '../SuperCenter/Modalities/Modals/components';

const SocialMedia = ({ mediaData, username }: { mediaData: SocialMediaLink, username: any }) => {
  const { t, i18n } = useTranslation();
  const [typeSelected, setTypeSelected] = useState('')
  const [mediaLink, setMediaLink] = useState('')

  function handleOnChangeSelect(e: any) {
    setTypeSelected(e.target.value)
  }

  function handleOnChangeInput(e: any) {
    setMediaLink(e.target.value)
  }

  function saveMedia() {
    Backend(
      MicroServices.Roster,
      `/users/${username}/media`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "type": typeSelected,
          "link": mediaLink
        }),
      }
    )
      .then((response) => {

      })
      .catch((error) => {
        console.log(error);
      })
  }

  function deleteMedia(e: string) {
    Backend(
      MicroServices.Roster,
      `/users/${username}/account/media?account_id=${e}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
      }
    )
      .then((response) => {

      })
      .catch((error) => {
        console.log(error);
      })
  }

  return (
    <div>
      {/*<ImageProfile />*/}

      <div className='row row2'>
        <div className='col'>
          <label className='titleInput lessTop' htmlFor="">{t('profile__type')}</label>
          <select
            className="roboto-normal-white-16px selectMediaType"
            onChange={(e) => handleOnChangeSelect(e)}
          >
            <option selected={mediaData.type === ''} disabled>
              Add Social Media
            </option>
            <option value="Facebook" selected={mediaData.type === 'Facebook'}>
              Facebook
            </option>
            <option value="Instagram" selected={mediaData.type === 'Instagram'}>
              Instagram
            </option>
            <option value="Linkedin" selected={mediaData.type === 'Linkedin'}>
              Linkedin
            </option>
          </select>
        </div>
        <div className='col'>
          <label className='titleInput lessTop' htmlFor="">{t('profile__link')}</label>
          <div className='inputWIcon'>
            <input
              type="text"
              defaultValue={mediaData.link}
              onChange={(e) => handleOnChangeInput(e)}
            />
            {mediaData.link ?
              <FontAwesomeIcon
                icon="trash"
                size='lg'
                color={BROWN}
                className="IconTrash pointer"
                onClick={() => deleteMedia(mediaData.id)}
              />
              :
              <FontAwesomeIcon
                icon="save"
                size='lg'
                color={BROWN}
                className="IconTrash pointer"
                onClick={() => saveMedia()}
              />
            }

          </div>
        </div>
      </div>
    </div>
  )
}

export default SocialMedia