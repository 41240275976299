import styled from 'styled-components';
export const FiltersContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;

  color: var(--m-3-sys-light-on-surface-variant);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0.2px;

  @media only screen and (max-width: 770px) {
    display: none;
  }
`;

export const FilterItem = styled.div`
  display: flex;
  height: 32px;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: ${({active}:{active: boolean}) => active ? "0px" : "1px solid var(--m-3-sys-light-on-surface-variant)"};
  border-radius: 8px;
  background-color: ${({active}:{active: boolean}) => active ? "var(--m-3-sys-light-secondary-container)" : "white"};
  cursor: pointer;
  position: relative;

  :hover{
    -webkit-box-shadow: ${({active}:{active: boolean}) => active ? "0px 2px 3px 0px rgba(0, 0, 0, 0.30), 0px 6px 10px 4px rgba(0, 0, 0, 0.15)" : "none"};
    -moz-box-shadow: ${({active}:{active: boolean}) => active ? "0px 2px 3px 0px rgba(0, 0, 0, 0.30), 0px 6px 10px 4px rgba(0, 0, 0, 0.15)" : "none"};
    box-shadow: ${({active}:{active: boolean}) => active ? "0px 2px 3px 0px rgba(0, 0, 0, 0.30), 0px 6px 10px 4px rgba(0, 0, 0, 0.15)" : "none"};
    background-color: ${({active}:{active: boolean}) => active ? "var(--m-3-sys-light-secondary-container)" : "var(--m-3-state-layers-light-on-surface-variant-opacity-012)"};
  }
`;

export const FilterPanelItem = styled.li`
    background-color: white;
    padding: 10px 20px;
    text-align: left;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;

    &:last-child {
        border-radius: 0 0 10px 10px;
        -webkit-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        -moz-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
    }

    &:hover {
        background-color: #e8e8e8;
    }
`;

export const FilterPanelItems = styled.ul`
    width: 100%;
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 10;
    overflow-y: scroll;
    max-height: 200px;
    border-radius: 12px;
    border: 1px solid var(--simple-gray);
    box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
`;