import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { SearchContainerHome, ButtonsHealerHome, RowHealerHome, IconHealerHome, ButtonHealerHome } from "../SearchHome/styles";
import { TitleHomeCenter } from "./styles";
import styled from "styled-components";
import { contactUs } from "../../../AppConfig";

const AboutHome = () => {
    const navigate = useNavigate();

    return (
        <AboutWrapper>
            <TitleHomeCenter>
                We connect practitioners—whom we call healers—with individuals seeking to live optimal and enlightened lives.
            </TitleHomeCenter>

            <TitleHomeCenter>
                Healers are encouraged to build communities to serve their members, whom we call seekers. Seekers can realize the life of their dreams by discovering alternative modalities, healers, communities, and events to support their journey.
            </TitleHomeCenter>

            <ButtonsHealerHome>
                <RowHealerHome>
                    <IconHealerHome />
                    I'm a Healer
                </RowHealerHome>
                <ButtonHealerHome
                    onClick={() => navigate(contactUs ? '/contact' : '../../../../../signup-healer')}
                >
                    Let's Vibe
                    <FontAwesomeIcon
                        icon="chevron-right"
                        size="2xs"
                        color="white"
                    />
                </ButtonHealerHome>
            </ButtonsHealerHome>
        </AboutWrapper>
    )
}

// Change the styles here so the Search container doesn't change
const AboutWrapper = styled(SearchContainerHome)`
    @media only screen and (max-width: 770px) {
        padding: 24px 20px 24px 20px;
        justify-content: flex-start;
        height: 100vh;
        margin-top: 60px;
    }
`;

export default AboutHome;