import React from 'react'
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/pro-thin-svg-icons";
import {
    listAdv, IconSeeker, InfoUser,
    Detail, Location, Description, IconHealer,
} from "../../components/signupsetuphealer";
import {
    ButtonBlue, CardItem, AvatarConn, NameConnection, Interests
} from "../../components/signupsetup"
import Slider from './Slider';
import { IUserGradeResponse } from '@vibe/sdk/interfaces/Admin/User';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { ContainerInfo_Signup, SubtitleSignupWrappers, TitleSignupWrappers } from '../../containers/Signup/WrapBusiness';

const Column2 = (props: any) => {

    const getTwoInterests = (interests: string[]) => {
        return interests.slice(0, 2);
    }

    const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);
    
    return (
        props.tabActive === 1 ?
            <ContainerInfo_Signup theme={{ main: '#E5DCD0' }}>
                <TitleSetup_v2>Introduction</TitleSetup_v2>
                <SubtitleSetup_v2>We're committed to inclusion and positivity; please share your photo and introduce your unique self.</SubtitleSetup_v2>
                {/* <Slider
                    defaultCard={0}
                    elements={listAdv}
                    renderCard={(user) => (
                        <Card>
                            <InfoUser>
                                <Avatar src={user.avatar} />
                                <Detail>
                                    <Name>{user.name}</Name>
                                    {user.interests.length > 0 && (
                                        <Tags>
                                            {getTwoInterests(user.interests).map((interest) => (
                                                <Tag>{interest}</Tag>
                                            ))}
                                            <Tag>+{user.interests.length - 2}</Tag>
                                        </Tags>
                                    )}

                                    <Icons>
                                        {user.roles.map(role => {
                                            return (role === 'SEEKER') ? <IconSeeker /> : <IconHealer />
                                        })}
                                    </Icons>
                                </Detail>
                            </InfoUser>
                            <Location>
                                <StyledFontAwesomeIcon
                                    icon={faLocationDot}
                                    size='lg'
                                    color={"black"}
                                    style={{
                                        fontSize: '16px'
                                    }}
                                />
                                {user.location}
                            </Location>
                            <Description  dangerouslySetInnerHTML={{ __html:  cleanHTML(user.description) }} />
                        </Card>
                    )}
                /> */}
            </ContainerInfo_Signup>
            :
            props.tabActive === 2 ?
                <ContainerInfo_Signup theme={{ main: 'var(--simple-brown);' }}>
                    <TitleSetup_v2>Guide Me</TitleSetup_v2>
                    <SubtitleSetup_v2>By selecting what you choose to manifest, your initial experience on Vibe will be curated. You will be introduced to communities and able to connect with others who can support your journey. </SubtitleSetup_v2>
                </ContainerInfo_Signup>
                :
                props.tabActive === 3 ?
                    <ContainerInfo_Signup theme={{ main: 'var(--simple-green)' }}>
                        <TitleSetup_v2>Vibe Communities</TitleSetup_v2>
                        <SubtitleSetup_v2>Vibe communities support healers and seekers along their journey of living an optimal and enlightened life. In Vibe communities will discover content, conversations and <br />like-minded seekers to manifest your life. </SubtitleSetup_v2>
                    </ContainerInfo_Signup>
                    :
                    props.tabActive === 4 ?
                        <ContainerInfo_Signup theme={{ main: 'var(--simple-blue-dark)' }}>
                            <TitleSetup_v2 theme={{ main: 'white' }}>Inspiring connections</TitleSetup_v2>
                            <SubtitleSetup_v2 theme={{ main: 'white' }}>Build your network of like-minded individuals to grow with and create the life you designed. </SubtitleSetup_v2>
                            {/* <CardInfo>
                                <CardItem>
                                    <AvatarConn src="https://picsum.photos/200/200" />
                                    <NameConnection>Rebecca Pollock</NameConnection>
                                    <Location>
                                        <StyledFontAwesomeIcon
                                            icon={faLocationDot}
                                            size='lg'
                                            color={"black"}
                                            style={{
                                                fontSize: '16px'
                                            }}
                                        />
                                        Los Angeles, California
                                    </Location>
                                    <Interests>Energy, Weightloss, Abundance, Content &...</Interests>
                                    <MemberTime>Member since: 05/12/2023</MemberTime>
                                    <Friends>
                                        <Friend src="https://picsum.photos/200/200" ></Friend>
                                        <Friend src="https://picsum.photos/200/200" ></Friend>
                                        <Friend src="https://picsum.photos/200/200" ></Friend>
                                        <FriendMore>+200</FriendMore>
                                    </Friends>

                                    <ButtonBlue>Add friend</ButtonBlue>
                                </CardItem>
                            </CardInfo> */}
                        </ContainerInfo_Signup>
                        :
                        <ContainerInfo_Signup theme={{ main: 'var(--electric-yellow)' }}>
                            <TitleSetup_v2>Congratulations</TitleSetup_v2>
                            <SubtitleSetup_v2>{`${user.firstname || ''}, congratulations on designing your life, joining communities and making connections to support your journey.`}</SubtitleSetup_v2>
                        </ContainerInfo_Signup>

    )
}

export const TitleSetup_v2 = styled(TitleSignupWrappers)`
    color: ${props => props.theme.main || 'black'};
`;

export const SubtitleSetup_v2 = styled(SubtitleSignupWrappers)`
    color: ${props => props.theme.main || 'black'};
`;

const Cards = styled.div`
    width: 100%;
    flex-direction: row;
    width: 100%;
    padding: 45px px 0;
    display: flex;
    overflow-x: auto;
    margin-top: 20px;
`

const Card = styled.div`
    width: 293px;
    border-radius: 10px;
    background-color: white;
    padding: 15px;
    height: fit-content;
    position: relative;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
`

const Avatar = styled.img`
    width: 85px;
    height: 85px;
    min-width: 85px;
  min-height: 85px;
  max-width: 85px;
  max-height: 85px;
    border-radius: 10px;
    object-fit: cover;
`

const Tags = styled.div`
    color: black;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
`

const Tag = styled.p`
    text-align: left;
    font-size: 14px;
    border: 1px solid black;
    padding: 0px 6px;
    border-radius: 16px;
    font-family: 'Inter';
    width: max-content;
    color: black;
    height: 23px;
    align-items: center;
    max-width: 65px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const Icons = styled.div`
    color: black;
    display: flex;
    align-items: center;
    font-size: 9px;
    font-family: 'Inter';
    display: flex;
    gap: 5px;
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: black;
    margin-right: 7px;
`;

const CardInfo = styled.div`
    width: 100%;
    padding: 15px 12px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: 450px;

`

const MemberTime = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;`
const Friends = styled.div`
    display: flex;
    justify-content: center;
    padding: 12px 0`
const Friend = styled.img`
    border: 1px solid white;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: relative;
    margin-left: -8px;
`
const FriendMore = styled.div`
    border: 1px solid black;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: relative;
    margin-left: -8px;
    font-size: 8px;
    display:flex;
    justify-content: center;
    font-family: 'Inter';
    align-items: center;
    background-color: white
`

const Name = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14.0741px;
    line-height: 17px;
    color: black;
    margin-bottom: 2px;
`

export default Column2
