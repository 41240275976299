import styled from "styled-components";
import { ContentFlexAdmin } from "./HealersView";
import { IOrganizationExtended } from "@vibe/sdk/interfaces/Admin/Organization";
import { IUserGradeResponse } from "@vibe/sdk/interfaces/Admin/User";
import { useSelector } from "react-redux";
import { getRolesUsersView } from "../../../helpers/roleHelper";
import { RootState } from "../../../store/store";
import BlankTable from "../../BaseComponents/BlankTable";
import { getMonth, unixToDateFormatted } from "../../../helpers/dateHelper";
import { StatusType } from "../../BaseComponents/Healers/StatusBudged";
import { useNavigate, useParams } from "react-router-dom";
import { capitalize } from "../../../helpers/letterHelper";
import { useEffect, useState } from "react";
import { OrgJoinedExtended } from "../../AdminCenter/MyOrganization/OtherOrgs";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import Contributor, { ContributorExtended } from "@vibe/sdk/interfaces/Modalities/contributors/Contributor";
import { isDate } from "../../../guards/isDate";

export const ContributorMock: ContributorExtended = {
    username: '',
    fullname: '',
    modalities: [],
    createdAt: '',
    createdBy: '',
}

const HealerTables = ({ setShowTables }: { setShowTables: (data: boolean) => void }) => {

    const { id = '' } = useParams();

    const navigate = useNavigate();

    const orgProfile: IOrganizationExtended = useSelector((state: RootState) => state.getOrgs.orgHealerProfile);
    const userProfile: IUserGradeResponse = useSelector((state: RootState) => state.getUser.userProfile);

    const [accordionsActive, setAccordionsActive] = useState<string[]>([]);
    const [orgs, setOrgs] = useState<OrgJoinedExtended[]>([]);
    const [contributor, setContributor] = useState<ContributorExtended>({ ...ContributorMock });

    const getRolesOrg = (orgId: string): string => {
        const orgIndex = userProfile.organizations.findIndex((o) => o === orgId);

        if (orgIndex > -1 && userProfile.rolesByOrg.length >= orgIndex) {
            return getRolesUsersView(userProfile.rolesByOrg[orgIndex]).join(', ');
        }

        return '';
    };

    const handleClick = () => setShowTables(false);

    const handleClickOrgs = (id: string) => navigate(`../../../../superadmin/healers/${id}/view`);

    const getOrgs = () => {
        if (userProfile.joined) {
            const orgsToSearch = userProfile.joined.filter((org) => org.orgId !== orgProfile.id);

            Promise.all(orgsToSearch.map((org) => getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return Promise.all([
                        dataSDK.sdk.Admin.getOrganization(org.orgId, true, dataSDK.token),
                        dataSDK.sdk.Admin.getOrganization(org.orgId, false, dataSDK.token),
                    ]);
                })))
                .then((resultsArray) => {
                    const orgsResult: OrgJoinedExtended[] = [];

                    resultsArray.forEach(([pending, notPending], idx) => {
                        if (notPending.statusCode === 200) {
                            const org = notPending.body as IOrganizationExtended;
                            orgsResult.push({
                                orgId: org.id,
                                orgName: org.name,
                                dateJoined: (userProfile.joined || [])[idx].dateJoined,
                                logo: org.logo || '',
                                city: org.country || '',
                                state: org.state || '',
                                country: org.country || '',
                                seekers: org.seekers || 0,
                                admin: {
                                    name: `${org.orgAdmin?.firstname} ${org.orgAdmin?.lastname}`,
                                    email: org.orgAdmin?.email || '',
                                    username: org.created_by,
                                },
                                roles: getRolesOrg(org.id),
                                website: org.website || '',
                            });
                        }
                        else if (pending.statusCode === 200) {
                            const org = notPending.body as IOrganizationExtended;
                            orgsResult.push({
                                orgId: org.id,
                                orgName: org.name,
                                dateJoined: (userProfile.joined || [])[idx].dateJoined,
                                logo: org.logo || '',
                                city: org.country || '',
                                state: org.state || '',
                                country: org.country || '',
                                seekers: org.seekers || 0,
                                admin: {
                                    name: `${org.orgAdmin?.firstname} ${org.orgAdmin?.lastname}`,
                                    email: org.orgAdmin?.email || '',
                                    username: org.created_by,
                                },
                                roles: getRolesOrg(org.id),
                                website: org.website || '',
                            });
                        }
                    })

                    setOrgs(orgsResult);
                })
                .catch((error) => {
                    console.log(error, 'Error getting orgs');
                    setOrgs([]);
                })
        }
    }

    const getContributor = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.getContributor(userProfile?.username || '', dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) setContributor(results.body as ContributorExtended)
                else setContributor({ ...ContributorMock })
            })
            .catch((error) => {
                console.error(error)
                setContributor({ ...ContributorMock })
            });
    }

    const dateToFormat = (date: Date): string =>
        isDate(date)
            ? `${getMonth(date.getMonth())} ${date.getDate()}, ${date.getFullYear()}`
            : '';

    useEffect(() => {
        getContributor();

        if (userProfile && userProfile.joined) {
            getOrgs();
        }
        else {
            setOrgs([]);
        }
    }, [userProfile]);

    return (
        <ContentFlexAdmin padding={"0px"}>
            <BlankTable
                maxWidthField={'120px'}
                backgroundColor={'white'}
                headerColored
                headers={[
                    'Name',
                    'Healer ID',
                    'Date Joined',
                    'Status',
                    'Set Up'
                ]}
                items={[{
                    id,
                    labels: [
                        `${userProfile.firstname || ''} ${userProfile.lastname || ''}`,
                        id,
                        unixToDateFormatted(orgProfile.createdAt, dateToFormat),
                        orgProfile?.status as StatusType || '',
                        orgProfile.setupComplete ? 'Completed' : 'Not completed'
                    ]
                }]}
                statusInfo={{
                    statusType: orgProfile?.status as StatusType || '',
                    hasStatus: true,
                    statusIndex: 3,
                }}
                onClickRow={handleClick}
                cursor={'pointer'}
            />

            <FlexColumn>
                <FlexRowWrapper>
                    <Subtitle>Type</Subtitle>
                    {capitalize(orgProfile.type)}
                </FlexRowWrapper>
                <FlexRowWrapper>
                    <Subtitle>City</Subtitle>
                    {orgProfile.city}
                </FlexRowWrapper>
                <FlexRowWrapper>
                    <Subtitle>Country</Subtitle>
                    {orgProfile.country}
                </FlexRowWrapper>
                <FlexRowWrapper>
                    <Subtitle>Phone</Subtitle>
                    {orgProfile.type === 'INDEPENDENT' ? (userProfile?.phoneNumber || '') : orgProfile?.primaryContact?.phoneNumber}
                </FlexRowWrapper>
                <FlexRowWrapper>
                    <Subtitle>Email</Subtitle>
                    {orgProfile.type === 'INDEPENDENT' ? (userProfile?.email || '') : orgProfile?.primaryContact?.email}
                </FlexRowWrapper>
            </FlexColumn>

            <BlankTable
                maxWidthField={'120px'}
                backgroundColor={'white'}
                headerColored
                headers={[
                    'Organizations',
                    'Role',
                    'Date Joined',
                    'Date Left',
                ]}
                items={orgs.map((org) => ({
                    id: org.orgId,
                    labels: [
                        org.orgName,
                        getRolesOrg(org.orgId),
                        unixToDateFormatted(org.dateJoined, dateToFormat),
                        '', /*@todo */
                    ],
                    accordionInfo: [
                        org.city, org.country, org.website || '', org.admin.email
                    ]
                }))}
                accordionInfo={{
                    accordionHeaders: ['City', 'Country', 'Website', 'Email'],
                    hasAccordion: true,
                    accordionsActive: accordionsActive,
                    setAccordionsActive: setAccordionsActive,
                }}
                onClickRow={handleClickOrgs}
                cursor={'pointer'}
            />

            <BlankTable
                maxWidthField={'120px'}
                backgroundColor={'white'}
                headerColored
                headers={[
                    'M/S Contributor',
                    'Date Assigned',
                    'Last Contribution',
                    'End Date',
                ]}
                items={contributor.modalities.map((mod) => ({
                    id: mod.id,
                    labels: [
                        mod.name,
                        unixToDateFormatted(mod.assigned, dateToFormat),
                        unixToDateFormatted(mod.last || '', dateToFormat),
                        unixToDateFormatted(mod.end || '', dateToFormat),
                    ]
                }))}
            />
        </ContentFlexAdmin>
    )
}

export default HealerTables;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 14px;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 0px 15px 15px;
`;

const Title = styled.div`
    font-weight: 600;
`;

const Subtitle = styled.div`
    color: var(--simple-gray);
`;

const FlexRowWrapper = styled.div`
    display: flex;
    gap: 15px;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
`;

const FlexColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
`;

const PlaceInfo = styled.div`
    align-items: center;
    display: flex;
    height: 39px;
    width: 100%;

    font-family: 'Inter';
    font-size: 16px;
    font-weight: normal;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    text-align: left;
`;

const PlaceInfoStatement = styled(PlaceInfo).attrs(
    props => ({
        className: props.theme.main,
    })
)`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    height: 100%;
    min-height: 39px;
    max-height: 111px;

    overflow: scroll;
    white-space: normal;
    line-height: 22px;

    &.activeMore{
        min-height: auto;
        max-height: max-content;
        display: block;
    }

`;

const RowFlex = styled(PlaceInfo)`
  background-color: transparent;
  padding: 0;
  gap: 10px;
`;