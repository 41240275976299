import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import { getFiltered, sortAlphabeticallyOnProperty } from "../../../containers/Signup/SignupHealerInd"
import { useState } from "react";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { BaseFieldState } from "../utils";
import OutsideClickHandler from "react-outside-click-handler";
import SelectItem from "../../../interfaces/SelectItem";

const SelectorLanguage = ({
    language,
    setLanguage,
    backgroundColor = "white",
    border = "1px solid var(--simple-gray)",
}: {
    language: BaseFieldState<SelectItem>,
    setLanguage: (data: BaseFieldState<SelectItem>) => void,
    backgroundColor?: string,
    border?: string,
}) => {

    const languages: SelectItem[] = [{
        label: 'English',
        value: 'english'
    },
    {
        label: 'Spanish',
        value: 'spanish'
    }]

    const [showOptionsCountry, setShowOptionsCountry] = useState<boolean>(false);

    return (
        <WrapperSelect>
            <Select_Input
                onClick={() => setShowOptionsCountry(!showOptionsCountry)}
                value={language.value?.label}
                placeholder='Select a language'
                backgroundColor={backgroundColor}
                border={border}
            />
            <IconDropdownInput
                icon={faChevronDown}
                size='sm'
                color={backgroundColor === 'white' ? 'black' : 'white'}
                onClick={() => setShowOptionsCountry(!showOptionsCountry)}
            />
            {showOptionsCountry &&
                <OutsideClickHandler onOutsideClick={() => setShowOptionsCountry(false)}>
                    <Items>
                        {sortAlphabeticallyOnProperty(languages, 'label').map((activeCountry, index) => (
                            <Item onClick={() => {
                                setShowOptionsCountry(!showOptionsCountry)
                                setLanguage({
                                    ...language,
                                    value: activeCountry
                                })
                            }
                            }
                                key={index}>
                                {activeCountry.label}
                            </Item>
                        ))}
                    </Items>
                </OutsideClickHandler>
            }
        </WrapperSelect>
    )
}

const WrapperSelect = styled.div`
    position: relative;
    display: flex;
    width: 100%;
`;

interface Select_InputProps {
    backgroundColor: string
    border: string
}

const Select_Input = styled.input`
    outline: none;
    width: 100%;
    height: 45px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Inter';
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;

    background-color: ${(props: Select_InputProps) => props.backgroundColor};
    border: ${(props: Select_InputProps) => props.border};
    color: ${(props: Select_InputProps) => props.backgroundColor === 'white' ? 'black' : 'white'};

    &::placeholder{
        color: var(--simple-gray);
        font-family: 'Inter';
    }
`;

const IconDropdownInput = styled(FontAwesomeIcon)`
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: 37%;
    left: 90%;
`;

const Item = styled.li`
    background-color: white;
    padding: 10px 20px;
    text-align: left;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;

    &:last-child {
        border-radius: 0 0 10px 10px;
        -webkit-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        -moz-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
    }

    &:hover {
        background-color: #e8e8e8;
    }
`;

const Items = styled.ul`
    width: 100%;
    position: absolute;
    top: 100%;
    z-index: 1;
    overflow-y: scroll;
    max-height: 200px;
    border-radius: 12px;
    border: 1px solid var(--simple-gray);
    left: 0px;
    box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
`;

export default SelectorLanguage;