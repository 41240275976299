import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { IOrganizationExtended } from '@vibe/sdk/interfaces/Admin/Organization';
import { roleUseClubType } from '../../Clubs/useClubsList';
import { orgStatus, orgType } from '@vibe/sdk/dist/interfaces/Admin/getOrganizations';
import { RootState } from '../../../store/store';
import { getOrganizations } from '../../../store/actions/orgsActions';
import { sortAlphabeticallyOnProperty } from '../../../containers/Signup/SignupHealerInd';

export type useOrgsTypeType = 'ALL' | orgType;
export type useOrgsStatusType = 'ALL' | orgStatus;
export type useOrgsSetupType = 'ALL' | 'COMPLETE' | 'INCOMPLETE';

interface useOrgsProps {
  searchName: string;
  searchType: useOrgsTypeType;
  searchStatus: useOrgsStatusType;
  role: roleUseClubType;
  searchSetup?: useOrgsSetupType;
  withSuper?: boolean;
}

interface useOrgsResponse{
  reloadList: () => void;
  allFilteredOrgs: IOrganizationExtended[];
}

const useOrgList = ({
  searchName,
  searchType,
  searchStatus,
  role,
  searchSetup,
  withSuper = false,
}: useOrgsProps): useOrgsResponse => {

  const dispatch = useDispatch();

  const orgs: IOrganizationExtended[] = useSelector((state: RootState) => state.getOrgs.organizationsLimit);

  const [allFilteredOrgs, setAllFilteredOrgs] = useState<IOrganizationExtended[]>([]);

  const searchParsed = useMemo(() => searchName.toLowerCase(), [searchName]);

  const reloadList = () => {
    dispatch(getOrganizations());
  }

  const filterOrg = (org: IOrganizationExtended): boolean => {
    let acceptedName = true;
    let acceptedType = true;
    let acceptedStatus = true;
    let acceptedSetup = true;

    if(searchName && searchName !== ''){
      acceptedName = (org.name || '').toLowerCase().includes(searchParsed)

    }

    if(searchType && searchType !== 'ALL'){
      acceptedType = org.type === searchType;
    }

    if(searchStatus && searchStatus !== 'ALL'){
      acceptedStatus = org.status === searchStatus;
    }

    if(searchSetup && searchSetup !== 'ALL'){
      acceptedSetup = searchSetup === 'COMPLETE' ? org.setupComplete : org.setupComplete;
    }

    return acceptedType && acceptedName && acceptedStatus && acceptedSetup;
  }

  useEffect(() => {
    if ((!searchName || searchName === '') && 
        (!searchType || searchType === 'ALL') &&
        (!searchStatus || searchStatus === 'ALL') &&
        (!searchSetup || searchSetup === 'ALL')) {
      setAllFilteredOrgs(sortAlphabeticallyOnProperty(orgs, 'name') as IOrganizationExtended[]);
      return;
    }

    setAllFilteredOrgs(
      (sortAlphabeticallyOnProperty(orgs, 'name') as IOrganizationExtended[]).filter(filterOrg)
    )
  }, [searchName, orgs, searchType, searchStatus]);

  useEffect(() => reloadList(), []);

  return {
    reloadList,
    allFilteredOrgs: withSuper ? allFilteredOrgs : allFilteredOrgs.filter(org => org.id !== "vibe"),
  }
};

export default useOrgList;