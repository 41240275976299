import React, { useContext, useState } from 'react'
import Header from '../../Header';
import { ChatServiceInterface } from '../../../interfaces/chat/ChatServiceInterface';
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faUserPlus } from "@fortawesome/pro-regular-svg-icons";
import ModalRefer from './ModalRefer';
import ModalJoin from './ModalJoin';
import ModalSeeWhat from './ModalSeeWhat';
import { useNavigate } from 'react-router-dom';
import HeaderMobile from '../../Website/HeaderMobile'
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { ContextSuccess } from '../../../Context';
import { TitleHome } from '../SearchHome/styles';
import { contactUs } from '../../../AppConfig';

const Pricing = ({
  ChatServiceInstance
}: {
  ChatServiceInstance?: ChatServiceInterface
}) => {

  const navigate = useNavigate();
  const currentSuccess = useContext(ContextSuccess);

  const isLoggedIn = useSelector((state: RootState | any) => state.getUser.isLoggedIn);

  const [showRefer, setShowRefer] = useState<boolean>(false);
  const [showJoin, setShowJoin] = useState<boolean>(false);
  const [showSee, setShowSee] = useState<boolean>(false);
  const [activeNotification, setActiveNotification] = useState(false);
  const [menuActive, setMenuActive] = useState('')
  const [animation, setAnimation] = useState(false);

  const onHideReferModal = (e: string | undefined) => {
    setShowRefer(false);
  }

  const onHideJoinModal = (e: string | undefined) => {
    setShowJoin(false);
  }

  const onHideSeeModal = (e: string | undefined) => {
    setShowSee(false);
  }

  const handleVibe = () => {
    if (isLoggedIn) {
      currentSuccess?.setPaymentForm(parseInt(process.env.REACT_APP_SUBSCRIPTION_COST || '0'));
    }
    else {
      navigate('../../../../signup-healer');
      // if (contactUs) {
      //   navigate('/contact')
      // } else {
      //   navigate('../../../../signup-healer');
      // }
    }
  }

  return (
    <>
      <Content>
        <TitleHome>Value for both Healers and Seekers</TitleHome>
        <Columns>
          <Column theme={{ main: 'column1' }}>
            <HeaderCol>
              <IconUser src={'../icon_healer.png'} healer />
              <Info>
                <TextLine>${process.env.REACT_APP_SUBSCRIPTION_COST || '0'} Annually</TextLine>
                <TextPrice>${process.env.REACT_APP_SUBSCRIPTION_DISCOUNT || '0'} for 1st 100 Healers</TextPrice>
              </Info>
            </HeaderCol>
            <InfoContainer>
              <TitleBold>I'm a Healer or represent a Healer Organization</TitleBold>
              <List>
                <Item>
                  <ICheck icon={faCheck} size='sm' />
                  My organization trains, certifies or supports healerss.
                </Item>
                <Item>
                  <ICheck icon={faCheck} size='sm' />
                  My organization employees or contracts with more than one healers to deliver services.
                </Item>
                <Item>
                  <ICheck icon={faCheck} size='sm' />
                  I'm an independent healers.
                </Item>
              </List>
              <SeeWhats onClick={() => { setShowSee(true); }}>See whats included</SeeWhats>
              <ButtonLetsVibe onClick={handleVibe}>
                {isLoggedIn ? 'Pay Now' : "Let's Vibe"}
                <FontAwesomeIcon
                  icon="arrow-right"
                  size="lg"
                  color="white"
                />
              </ButtonLetsVibe>
            </InfoContainer>
          </Column>
          <Column theme={{ main: 'column2' }}>
            <HeaderCol>
              <InfoRow>
                <IconUser src={'../icon_seeker.png'} />
                <TextPrice>Seekers</TextPrice>
              </InfoRow>
              <InfoRow>
                <Button>Free</Button>
              </InfoRow>
            </HeaderCol>
            <InfoContainer>
              <TitleBold>We're excited that you're raising your vibration and interested in quality information, healers and communities to support your journey.</TitleBold>
              <RegisterVia>
                <ICheck icon={faUserPlus} size='lg' />
                <InfoVia>
                  <TextViaBold>Presently Vibe is invitation only. You may register via:</TextViaBold>
                  <Item>An invitation email or shared invitation link.</Item>
                </InfoVia>
              </RegisterVia>
              <Item>To accelerate the process, please refer your favorite Healers or Join Waitlist</Item>
              <Buttons>
                <ButtonRefer onClick={() => { setShowRefer(true); }}>Refer a Healer</ButtonRefer>
                <ButtonJoin onClick={() => { setShowJoin(true); }}>Join Waitlist</ButtonJoin>
              </Buttons>
            </InfoContainer>
          </Column>
        </Columns>
      </Content>
      <ModalSeeWhat
        show={showSee}
        onHide={onHideSeeModal}
      />
      <ModalRefer
        show={showRefer}
        onHide={onHideReferModal}
      />
      <ModalJoin
        show={showJoin}
        onHide={onHideJoinModal}
      />
    </>
  )
}


const ButtonLetsVibe = styled.div` 
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--dark-blue);
  color: #FFF;
  display: flex;
  font-size: 16px;
  font-family: Inter-Bold;
  cursor: pointer;
  justify-content: flex-end;
  gap: 40%;
  padding-right: 10px;
`
const SeeWhats = styled.div`
  color: var(--absolute-zero);
  font-size: 16px;
  font-family: Inter-Bold;
  text-align: left;
  cursor: pointer;
`
export const Item = styled.div`
  color: var(--default-dark);
  font-size: ${props => props.theme.size || '16px'};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align:left;
  padding: 5px 0;
  display: flex;
  align-items: center;
  gap: 10px;
`
const List = styled.div`
  
`
const ButtonJoin = styled.div`
  color: white;
  border-radius: 8px;
  background: var(--dark-blue);
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: 'Inter-Bold';
  cursor: pointer;
`
const ButtonRefer = styled.div`
  color: white;
  border-radius: 8px;
  background: #E89F4D;
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: 'Inter-Bold';
  cursor: pointer;
`

const Buttons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 0px;
`

const TextViaBold = styled.div`
  color: var(--default-dark);
  font-size: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`
const InfoVia = styled.div``

const RegisterVia = styled.div`
  border-radius: 8px;
  background: #E6EAF7;
  padding: 12px;
  display: flex;
  gap: 10px;
`
const TitleBold = styled.div`
  color: var(--default-dark);
  font-size: 18px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  letter-spacing: 0.1px;
`
export const InfoContainer = styled.div`
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  gap: 16px;
`
const Button = styled.div`
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #E8C57A;
  color: var(--default-dark);
  text-align: center;
  font-size: 16px;
  font-family: Inter-Bold;
`
const TextPrice = styled.div`
  color: var(--default-dark);
  font-size: 24px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (max-width: 768px){
    font-size: 20px; 
  }
`
const TextLine = styled.div`
  color: #808080;
  text-align: right;
  font-size: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: line-through;
`
const Info = styled.div``;

const InfoRow = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
`

const IconUser = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 50%;
  padding: ${({ healer = false }: { healer?: boolean }) => healer ? '9.2px 0px' : '6.67px'};
  background-color: white;
  border: 0.667px solid var(--default-dark);
`
export const HeaderCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.theme.justify || 'space-between'};
  padding: 24px;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid #D3DCE5;
  background-color: #FFF;
  height: 88px;
  gap: 15px;
`

export const Column = styled.div.attrs(
  props => ({
    className: props.theme.main,
  })
)`

  border-radius: 8px;
  border: 1px solid #D3DCE5;
  background: #F7F5F4;
  display: flex;
  flex-direction: column;

  .column1 { grid-area: 1 / 1 / 2 / 3; }
  .column2 { grid-area: 1 / 3 / 2 / 5; }
`

export const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 0px;

  @media (max-width: 700px){
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 10px;
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 24px 136px;
  width: 100%;
  height: calc(100vh - 120px); // Header
  overflow: scroll;
  
  @media only screen and (max-width: 700px) {
    margin-top: 50px; 
    padding: 0 16px;
  }
`
const Title = styled.div` 
  color: #1A1A1A;
  font-size: 48px;
  font-family: 'Inter';
  padding: 40px 0px;
  text-align: left;

  @media (max-width: 700px){
    font-size: 32px;
    padding: 10px 0px;
  }

`
export const ICheck = styled(FontAwesomeIcon)``;

export default Pricing;