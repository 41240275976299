import { faChevronRight, faHome } from "@fortawesome/pro-regular-svg-icons";
import { IconClicker } from "../Home/HeaderHome/styles";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface CrumbInterface {
    navigate: () => void //handler go
    label: string
}

const Breadcrumb = ({
    crumbs,
    home,
}: {
    crumbs: CrumbInterface[]
    home: () => void //handler go
}) => {
    return (
        <Flex>
            <IconClicker
                icon={faHome}
                size="sm"
                onClick={home}
            />

            {crumbs.map((cr, idx) => (
                <Crumb>
                    <FontAwesomeIcon
                        icon={faChevronRight}
                        size="sm"
                    />
                    {(idx === crumbs.length - 1 ) ? <Last>{cr.label}</Last> :<Clicker onClick={cr.navigate}>{cr.label}</Clicker>}
                </Crumb>
            ))}
        </Flex>
    )
}

export default Breadcrumb;

const Clicker = styled.div`
    cursor: pointer;
`;

const Last = styled.div`
    color: var(--simple-gray);
`;

const Flex = styled.div`
    display: flex;
    gap: 15px;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    color: #333;
`;

const Crumb = styled.div`
    display: flex;
    gap: 15px;
`;