import { IPost, IPostExtended } from "@vibe/sdk/dist/interfaces/Posts/IPost";
import { PostCardInterface } from "../components/BaseComponents/Posts/PostCard";
import { formatDateTime } from "../hooks/Events/useEventsPanel";

export const mapPosts = (posts: IPostExtended[]) =>
    posts.map((post) => ({
        id: post.post_id,
        type: (post.type as string).toLowerCase(),
        emoji: post.reactions?.length || 0,
        comments: post.comments?.length || 0,
        nameProfile: post.author_name,
        infoPost: post,
        link: post.link,
        //nameReach: post.reach_name,
        textPost: post.content,
        imageProfile: post.author_avatar ||
            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
        eventName: post.eventName,
        eventTimestamp: post.eventTimestamp,
        eventLocation: post.eventLocation,
        eventCover: post.eventCover,
        author: post.author,
        //authorRole: post.author_role,
    }))

export const mapPostsCard = (posts: IPostExtended[]): PostCardInterface[] =>
    posts.filter(p => !!p).map((post) => ({
        files: post.files || [],
        link: post.link || '',
        comments: post.comments || [],
        shared: 0, //post.shared?.length || 0,
        likes: post.reactions?.length || 0,
        content: post.content,
        author_name: post.author_name || post.author,
        timestamp: post.createdAt,
        author_username: post.author,
        type: post.type,
        id: post.post_id,
        avatar: post.author_avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
        reachName: post.reach_name || '',
        reachID: post.reach_id || '',
        is_tip: post.is_tip ?? false,
        tip_author: post.tip_author || '',
        event: {
            event_id: post.eventId || '',
            name: post.eventName || '',
            cover: post.eventCover || "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_1280.png",
            start_date: formatDateTime(post.eventTimestamp || '')[0] || '',
            start_time: formatDateTime(post.eventTimestamp || '')[1] || '',
            delivery: post.eventDelivery || 'online',
            location: post.eventLocation || '',
            link_online_event: post.eventLink || '',
        }
    }))

export const mapPostCardIndividual = (post: IPostExtended): PostCardInterface =>
({
    files: post.files || [],
    link: post.link || '',
    comments: post.comments || [],
    shared: 0, //post.shared?.length || 0,
    likes: post.reactions?.length || 0,
    content: post.content,
    author_name: post.author_name || post.author,
    timestamp: post.createdAt,
    author_username: post.author,
    type: post.type,
    id: post.post_id,
    avatar: post.author_avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
    reachName: post.reach_name || '',
    reachID: post.reach_id || '',
    is_tip: post.is_tip ?? false,
    tip_author: post.tip_author || '',
    event: {
        event_id: post.eventId || '',
        name: post.eventName || '',
        cover: post.eventCover || "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_1280.png",
        start_date: formatDateTime(post.eventTimestamp || '')[0] || '',
        start_time: formatDateTime(post.eventTimestamp || '')[1] || '',
        delivery: post.eventDelivery || 'online',
        location: post.eventLocation || '',
        link_online_event: post.eventLink || '',
    }
})