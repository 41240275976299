import { useDispatch, useSelector } from "react-redux";
import { getOrgProfile, getOrganizationInfo, updateLogoOrg, uploadImage } from "../../../store/actions/orgsActions";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { updateOrg } from "../../../store/actions/orgsActions";
import { useTranslation } from "react-i18next";
import { CountryOption } from "../../../hooks/Locations/useActiveCountry";
import ButtonSpinner from "../../BaseComponents/ButtonSpinner";
import styled from "styled-components";
import { RootState } from "../../../store/store";
import { ContextSuccess } from "../../../Context";
import SelectorCountry from "../../BaseComponents/Location/SelectorCountry";
import { StateOption } from "../../../hooks/Locations/useActiveState";
import SelectorState from "../../BaseComponents/Location/SelectorState";
import { BaseFieldState, getFieldState } from "../../BaseComponents/utils";
import SelectorCity from "../../BaseComponents/Location/SelectorCity";
import { CityOption } from "../../../hooks/Locations/useStateCities";
import { IOrganizationExtended } from "@vibe/sdk/interfaces/Admin/Organization";
import Selector from "../../BaseComponents/Selector";
import { capitalize } from "../../../helpers/letterHelper";
import { orgTypesZaahah } from "../../../helpers/orgTypeHelper";
import SelectItem from "../../../interfaces/SelectItem";
import { Button, ColumnA, ColumnB, ContentAdmin, ContentFlexAdmin, HeaderAdmin, WrapperInfo } from "./HealersView";
import { unixToDateFormatted } from "../../../helpers/dateHelper";
import { ORGSTATUS } from "./Healers";
import Breadcrumb, { CrumbInterface } from "../../BaseComponents/Breadcrumb";
import { FlexColumnWrapper, FlexRowWrapper } from "./HealerDescription";
import AddPhotoPencil from "../../BaseComponents/ProfilePhoto/AddPhotoPencil";
import { LogoInterface } from "../../signupsetuphealer/Introduction";
import ModalImageEditorCrop from "../../ModalImageEditorCrop";
import TextEditor from "../../BaseComponents/TextEditor";
import StatusBudged, { StatusType } from "../../BaseComponents/Healers/StatusBudged";
import { IUserGradeResponse } from "@vibe/sdk/interfaces/Admin/User";
import { getUserInfoProfile } from "../../../store/actions/userActions";
import UserSocialMedia from "@vibe/sdk/interfaces/Roster/userSocialMedia";
import { IconClicker } from "../../Home/HeaderHome/styles";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { castIGUsername, castFBUsername, castYTUsername, castTTUsername, castTWUsername, castSTUsername, castPLUsername, castFBLink, castIGLink, castSTLink, castTTLink, castTWLink, castYTLink } from "../../../helpers/socialMedia/socialMediaHelper";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import { onHideType } from "../../BaseComponents/Modals/ModalUnsaved";

const HealersUpdate = () => {
    const { t, i18n } = useTranslation();
    const { id = '' } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentSuccess = useContext(ContextSuccess);

    const orgProfile: IOrganizationExtended = useSelector((state: RootState) => state.getOrgs.orgHealerProfile);
    const userProfile: IUserGradeResponse = useSelector((state: RootState) => state.getUser.userProfile);
    const loadingUpdateOrg = useSelector((state: RootState) => state.getOrgs.loadingUpdateOrg || false);
    const orgT = useSelector((state: RootState) => state.getOrgs.organizationTypes);

    const [typeOptions, setTypeOptions] = useState<SelectItem[]>([]);
    const [logoOrg, setLogoOrg] = useState<BaseFieldState<LogoInterface>>(getFieldState({ file: '', fileLink: '' }));
    const [orgType, setOrgType] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: '', value: '' }));

    const [searchType, setSearchType] = useState('');
    const [searchCountry, setSearchCountry] = useState('');
    const [searchState, setSearchState] = useState('');
    const [searchCity, setSearchCity] = useState('');

    const [newLogo, setNewLogo] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [activeCrop, setActiveCrop] = useState(false);

    const [schoolName, setSchoolName] = useState<string>('');
    const [schoolWebsite, setSchoolWebsite] = useState<string>('');
    const [postalCode, setPostalCode] = useState<string>('');
    const [primaryContact, setPrimaryContact] = useState({
        name: '',
        email: '',
        phoneNumber: 3000000000
    });
    const [address, setAddress] = useState<string>('');
    const [orgCountry, setOrgCountry] = useState<BaseFieldState<CountryOption>>(getFieldState({ label: '', value: '' }));
    const [orgState, setOrgState] = useState<BaseFieldState<StateOption>>(getFieldState({ label: '', value: '' }));
    const [orgCity, setOrgCity] = useState<BaseFieldState<CityOption>>(getFieldState({ label: '', value: '' }));
    const [mission, setMission] = useState<string>('');
    const [IG, setIG] = useState<string>('');
    const [FB, setFB] = useState<string>('');
    const [YT, setYT] = useState<string>('');
    const [TT, setTT] = useState<string>('');
    const [TW, setTW] = useState<string>('');
    const [ST, setST] = useState<string>('');

    const loadData = () => {
        setLogoOrg(getFieldState({
            file: orgProfile?.logo || '',
            fileLink: orgProfile?.logo || ''
        }));
        setSchoolName(orgProfile.name || '');
        setSchoolWebsite(orgProfile.website || '');
        setOrgCountry({
            ...orgCountry,
            value: {
                label: orgProfile.country || '',
                value: orgProfile.countryCode || ''
            }
        });
        setOrgState({
            ...orgState,
            value: {
                label: orgProfile.state || '',
                value: orgProfile.stateCode || ''
            }
        });
        setOrgCity({
            ...orgCity,
            value: {
                label: orgProfile.city || '',
                value: orgProfile.city || ''
            }
        });
        setOrgType({
            ...orgType,
            value: {
                label: capitalize(orgProfile.type || ''),
                value: orgProfile.type || ''
            }
        });
        setPostalCode(orgProfile.zipcode || '');
        setPrimaryContact({
            name: orgProfile.type === 'INDEPENDENT' ? `${userProfile.firstname} ${userProfile.lastname}` : orgProfile?.primaryContact?.name || '',
            email: orgProfile.type === 'INDEPENDENT' ? (userProfile?.email || '') : orgProfile?.primaryContact?.email || '',
            phoneNumber: orgProfile.type === 'INDEPENDENT' ? (userProfile?.phoneNumber || 300000000) : orgProfile?.primaryContact?.phoneNumber || 3000000000
        });
        setAddress(orgProfile.address || '');
        setMission(orgProfile.mission || '');
    }

    const handleSaveImage = () => new Promise((resolve, reject) => {
        const dataFile = URL.createObjectURL(logoOrg.value?.file as any);
        let contentType: any;
        let file: any;
        fetch(dataFile)
            .then(res => res.blob())
            .then(blob => {
                contentType = dataFile.split(';')[0].split(':')[1];
                const extension = contentType.split('/')[1]
                file = new File([blob], 'Logo' + id, { type: contentType });

                if (file) {
                    return dispatch<any>(updateLogoOrg(
                        file.type,
                        file.name + '.' + extension,
                        "school_logo"
                    ))
                }
                else {
                    return Promise.reject(new Error("File not found"));
                }
            })
            .then((results: any) => {
                if (results.body) {

                    dispatch<any>(uploadImage(
                        results.body,
                        contentType,
                        file,
                    ))
                        .then((result: any) => {
                            if (result.status === 200) {
                                const urlImage = results.body.split('?')[0];
                                setLogoOrg({
                                    ...logoOrg,
                                    value: {
                                        ...logoOrg.value as LogoInterface,
                                        fileLink: urlImage,
                                        file: urlImage
                                    }
                                })
                                resolve(urlImage);
                            }
                            else {
                                console.log(result)
                                reject();
                            }
                        })
                        .catch((error: any) => {
                            console.log(error)
                            reject();
                        })

                }
            })
    });

    const handleChangePostalCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPostalCode(event.target.value);
    }

    const handleOnChangePrimary = (
        e: React.ChangeEvent<HTMLInputElement>,
        inputName: string,
    ) => {

        if (inputName === 'name') {
            setPrimaryContact({
                ...primaryContact,
                name: e.target.value,
            });
        }

        if (inputName === 'email') {
            setPrimaryContact({
                ...primaryContact,
                email: e.target.value,
            });
        }

        if (inputName === 'phoneNumber') {
            setPrimaryContact({
                ...primaryContact,
                phoneNumber: Number(e.target.value),
            });
        }
    };

    const reloadOrg = () => {
        dispatch(getOrgProfile(id));
    };

    const checkForm = () => {
        if (schoolWebsite === "") {
            currentSuccess?.setSuccessData(false, true, "Website is mandatory", "");
            return false;
        }

        return true;
    }

    const updateSocialMedia = () => new Promise((resolve, reject) => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                const promises = [];
                if (IG.length > 0) {
                    promises.push(() => dataSDK.sdk.Roster.setUserSocialMedia(userProfile.username, {
                        platform: 'Instagram',
                        link: castIGLink(IG),
                        createdAt: `${new Date().valueOf()}`,
                    }, dataSDK.token))
                }
                if (FB.length > 0) {
                    promises.push(() => dataSDK.sdk.Roster.setUserSocialMedia(userProfile.username, {
                        platform: 'Facebook',
                        link: castFBLink(FB),
                        createdAt: `${new Date().valueOf()}`,
                    }, dataSDK.token))
                }
                if (YT.length > 0) {
                    promises.push(() => dataSDK.sdk.Roster.setUserSocialMedia(userProfile.username, {
                        platform: 'YouTube',
                        link: castYTLink(YT),
                        createdAt: `${new Date().valueOf()}`,
                    }, dataSDK.token))
                }
                if (TT.length > 0) {
                    promises.push(() => dataSDK.sdk.Roster.setUserSocialMedia(userProfile.username, {
                        platform: 'TikTok',
                        link: castTTLink(TT),
                        createdAt: `${new Date().valueOf()}`,
                    }, dataSDK.token))
                }
                if (TW.length > 0) {
                    promises.push(() => dataSDK.sdk.Roster.setUserSocialMedia(userProfile.username, {
                        platform: 'Twitter',
                        link: castTWLink(TW),
                        createdAt: `${new Date().valueOf()}`,
                    }, dataSDK.token))
                }
                if (ST.length > 0) {
                    promises.push(() => dataSDK.sdk.Roster.setUserSocialMedia(userProfile.username, {
                        platform: 'Substack',
                        link: castSTLink(ST),
                        createdAt: `${new Date().valueOf()}`,
                    }, dataSDK.token))
                }
                return Promise.all(promises.map((p) => p()));
            })
            .then((response) => {
                if (response.map((p) => !p.body)) {
                    resolve(true);
                    return;
                }
                resolve(false);
            })
            .catch((error) => resolve(false))
    })

    const handleSave = async (e: any) => {

        let logoLink: string = orgProfile?.logo || '';

        if (newLogo && logoOrg.value?.file !== '') {
            logoLink = await handleSaveImage() as string;
        }

        if (!checkForm()) return

        const socialOK = await updateSocialMedia();

        if(!socialOK){
            currentSuccess?.setSuccessData(false, true, "Error updating social media");
            return;
        }

        dispatch<any>(updateOrg(
            {
                name: schoolName,
                website: schoolWebsite,
                countryCode: orgCountry.value?.value || '',
                city: orgCity.value?.value || '',
                zipcode: postalCode,
                primaryContact: primaryContact,
                stateCode: orgState.value?.value || '',
                type: orgType.value?.value || '',
                address: address,
                logo: logoLink || '',
            },
            id,
            orgProfile.status === 'ACTIVE' ? false : true,
        ))
            .then((results: any) => {
                if (results) {
                    reloadOrg();
                    currentSuccess?.setSuccessData(true, true, "Healer data updated");
                    navigate(`../../../../superadmin/healers/${id}/view`);
                } else {
                    currentSuccess?.setSuccessData(false, true, "Error updating healer", '');
                }
            })
            .catch((error: any) => {
                console.log(error)
                currentSuccess?.setSuccessData(false, true, "Error updating healer", error);
            })
    }

    const getCrumbs = (): CrumbInterface[] => {
        if (orgProfile.type !== 'INDEPENDENT') return [{
            navigate: () => navigate('../../../../../superadmin/healers'),
            label: 'Healers'
        },
        {
            navigate: () => navigate(`../../../../../superadmin/healers/${id}/view`),
            label: orgProfile?.name || 'View Healer'
        },
        {
            navigate: () => { },
            label: 'Update healer'
        }];

        return [{
            navigate: () => navigate('../../../../../superadmin/healers'),
            label: 'Healers'
        },
        {
            navigate: () => navigate(`../../../../../superadmin/healers/${id}/view`),
            label: 'Healer Organizations'
        },
        {
            navigate: () => navigate(`../../../../../superadmin/healers/${id}/view`),
            label: orgProfile?.name || 'View Healer'
        },
        {
            navigate: () => { },
            label: 'Update healer'
        }];
    }

    function handleCloseModal(e: any, type: any) {
        setShowModal(() => false);
    };

    function handleCropImage(e: any) {
        const dataFile = URL.createObjectURL(logoOrg.value?.file as any);
        let contentType: any;
        let file: any;

        fetch(e)
            .then(res => res.blob())
            .then(blob => {

                // contentType = logo?.value?.file.type;
                contentType = dataFile.split(';')[0].split(':')[1];
                const extension = contentType.split('/')[1]
                file = new File([blob], 'LogoOrg ' + id, { type: contentType });

                setActiveCrop(true)
                setLogoOrg({
                    ...logoOrg,
                    value: {
                        fileLink: '',
                        file: file,
                    }
                })
            })
    };

    const showModalCrop = (e: BaseFieldState<LogoInterface>, isDelete = false) => {
        setLogoOrg(e);
        if (!isDelete) setShowModal(true);
        setActiveCrop(false);
    }

    const reloadUser = () => {
        dispatch(getUserInfoProfile(orgProfile.created_by, 'OA'));
    };

    const handleDeleteSocial = (id: string) => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Roster.deleteUserSocialMedia(userProfile.username, id as 'Facebook' | 'YouTube' | 'Instagram' | 'Twitter' | 'LinkedIn' | 'Pinterest' | 'Snapchat' | 'TikTok' | 'Zaahah', dataSDK.token)
            })
            .then((results) => {
                if (Object.keys(results?.body || {}).length === 0) {
                    currentSuccess?.setSuccessData(true, true, 'Social media successfully deleted.');
                    currentSuccess?.setModalsOff();
                    reloadUser();
                    return;
                }

                currentSuccess?.setSuccessData(false, true, 'Error deleting social media');
                return;
            })
            .catch(error => {
                currentSuccess?.setSuccessData(false, true, 'Error deleting social media', 'Try later.');
                console.log(error);
            })
            .finally(() => currentSuccess?.setLoadingModal(false))
    }

    const onHideDelete = (type: onHideType, id: string) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);
                handleDeleteSocial(id);
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                break;

            default:
                break;
        }
    }

    const handleDelete = (data: string) => {
        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => onHideDelete(type, data),
            'Are you sure you want to delete this social media?'
        );
    }

    useEffect(() => {
        reloadOrg();
    }, []);

    useEffect(() => {
        if (userProfile.sm) {
            userProfile.sm.forEach((usm) => {
                if (usm.platform === 'Instagram') {
                    setIG(castIGUsername(usm.link));
                }
                else if (usm.platform === 'Facebook') {
                    setFB(castFBUsername(usm.link));
                }
                else if (usm.platform === 'YouTube') {
                    setYT(castYTUsername(usm.link));
                }
                else if (usm.platform === 'TikTok') {
                    setTT(castTTUsername(usm.link));
                }
                else if (usm.platform === 'Twitter') {
                    setTW(castTWUsername(usm.link));
                }
                else if (usm.platform === 'Substack') {
                    setST(castSTUsername(usm.link));
                }
            })
        }
        else {
            setIG('');
            setFB('');
            setYT('');
            setTT('');
            setTW('');
            setST('');
        }
    }, [userProfile]);

    useEffect(() => {
        reloadOrg();
    }, [id]);

    useEffect(() => {
        loadData();
        reloadUser();
    }, [orgProfile]);

    useEffect(() => {
        setTypeOptions([{ label: "All", value: "ALL" }, ...orgTypesZaahah(orgT || []).map(([value, label]: [string, string]) => ({
            label: capitalize(value),
            value: value,
        }))])
    }, [orgT]);

    return (
        <ContentFlexAdmin>
            <HeaderAdmin>
                <Breadcrumb
                    home={() => navigate('../../../../../superadmin')}
                    crumbs={getCrumbs()} />
                <WrapperButtons>
                    <ButtonCancel onClick={() => navigate(`../../../../superadmin/healers/${id}/view`)}>Cancel</ButtonCancel>
                    <WrapperButton>
                        <ButtonSpinner
                            loading={loadingUpdateOrg}
                            handleClick={handleSave}
                            text={'Update'}
                            hasIcon={false}
                        />
                    </WrapperButton>
                </WrapperButtons>
            </HeaderAdmin>

            <ContentFlexAdmin scroll padding={'0px'}>
                <FlexRowWrapper top>
                    <ModalImageEditorCrop
                        preview={logoOrg}
                        handleSaveImage={handleCropImage}
                        show={showModal}
                        onHide={handleCloseModal}
                        isProfilePhoto={true}
                    />
                    <AddPhotoPencil
                        logo={logoOrg}
                        setLogo={(e: BaseFieldState<LogoInterface>, isDelete?: boolean) => showModalCrop(e, isDelete)}
                        setNewLogo={setNewLogo}
                    />
                    <FlexColumnWrapper>
                        <PlaceInfo
                            type="text"
                            value={schoolName || ''}
                            onChange={(e) => setSchoolName(e.currentTarget.value)}
                        />
                        <TextEditor
                            value={mission || ''}
                            setValue={setMission}
                            charLimit={1000}
                            maxHeight={'300px'}
                            placeholder='Please describe your organization...'
                        />
                    </FlexColumnWrapper>
                </FlexRowWrapper>

                <FlexRowWrapper top>
                    <FlexColumnWrapper>
                        <Title>{orgProfile.type === 'INDEPENDENT' ? 'Healer Information' : 'Organization Information'}</Title>
                        <FlexRowWrapper>
                            <Subtitle>ID</Subtitle>
                            {orgProfile?.id || ''}
                        </FlexRowWrapper>
                        <FlexRowWrapper>
                            <Subtitle>Status</Subtitle>
                            <StatusBudged status={orgProfile?.status as StatusType || ''} />
                        </FlexRowWrapper>
                        <FlexRowWrapper>
                            <Subtitle>Website</Subtitle>
                            <PlaceInfo
                                type="text"
                                value={schoolWebsite || ''}
                                onChange={(e) => setSchoolWebsite(e.currentTarget.value)}
                            />
                        </FlexRowWrapper>

                        <FlexColumnWrapper>
                            <Subtitle>Social Media</Subtitle>

                            <FlexRowWrapper>
                                <IconMedia src={'../../../instagram.svg'} />
                                <PlaceInfoMedia>
                                    instagram.com/
                                    <InputMedia
                                        type="text"
                                        value={IG || ''}
                                        onChange={(e) => setIG(e.currentTarget.value)}
                                    />
                                </PlaceInfoMedia>
                                <IconClicker
                                    icon={faTrash}
                                    size='sm'
                                    onClick={() => handleDelete('Instagram')}
                                />
                            </FlexRowWrapper>
                            <FlexRowWrapper>
                                <IconMedia src={'../../../youtube.svg'} />
                                <PlaceInfoMedia>
                                    youtube.com/
                                    <InputMedia
                                        type="text"
                                        value={YT || ''}
                                        onChange={(e) => setYT(e.currentTarget.value)}
                                    />
                                </PlaceInfoMedia>
                                <IconClicker
                                    icon={faTrash}
                                    size='sm'
                                    onClick={() => handleDelete('Youtube')}
                                />
                            </FlexRowWrapper>
                            <FlexRowWrapper>
                                <IconMedia src={'../../../facebook-f.svg'} />
                                <PlaceInfo
                                    type="text"
                                    placeholder="https://"
                                    value={FB || ''}
                                    onChange={(e) => setFB(e.currentTarget.value)}
                                />
                                <IconClicker
                                    icon={faTrash}
                                    size='sm'
                                    onClick={() => handleDelete('Facebook')}
                                />
                            </FlexRowWrapper>
                            <FlexRowWrapper>
                                <IconMedia src={'../../../twitter.svg'} />
                                <PlaceInfoMedia>
                                    twitter.com/
                                    <InputMedia
                                        type="text"
                                        value={TW || ''}
                                        onChange={(e) => setTW(e.currentTarget.value)}
                                    />
                                </PlaceInfoMedia>
                                <IconClicker
                                    icon={faTrash}
                                    size='sm'
                                    onClick={() => handleDelete('Twitter')}
                                />
                            </FlexRowWrapper>
                            <FlexRowWrapper>
                                <IconMedia src={'../../../tiktokicon.svg'} />
                                <PlaceInfoMedia>
                                    tiktok.com/
                                    <InputMedia
                                        type="text"
                                        value={TT || ''}
                                        onChange={(e) => setTT(e.currentTarget.value)}
                                    />
                                </PlaceInfoMedia>
                                <IconClicker
                                    icon={faTrash}
                                    size='sm'
                                    onClick={() => handleDelete('TikTok')}
                                />
                            </FlexRowWrapper>
                            <FlexRowWrapper>
                                <IconMedia src={'../../../substack.svg'} />
                                <PlaceInfoMedia>
                                    substack.com/
                                    <InputMedia
                                        type="text"
                                        value={ST || ''}
                                        onChange={(e) => setST(e.currentTarget.value)}
                                    />
                                    {"  "}.substack.com
                                </PlaceInfoMedia>
                                <IconClicker
                                    icon={faTrash}
                                    size='sm'
                                    onClick={() => handleDelete('Substack')}
                                />
                            </FlexRowWrapper>
                        </FlexColumnWrapper>

                        <FlexRowWrapper>
                            <Subtitle>Address</Subtitle>
                            <PlaceInfo
                                type="text"
                                value={address || ''}
                                onChange={(e) => setAddress(e.currentTarget.value)}
                            />
                        </FlexRowWrapper>
                        <FlexRowWrapper>
                            <Subtitle>City</Subtitle>
                            <SelectorCity
                                state={orgState.value?.value || ''}
                                city={orgCity}
                                setCity={setOrgCity}
                                searchCity={searchCity}
                                setSearchCity={setSearchCity}
                            />
                        </FlexRowWrapper>
                        <FlexRowWrapper>
                            <Subtitle>Zip Code</Subtitle>
                            <PlaceInfo
                                type="text"
                                value={postalCode || ''}
                                onChange={handleChangePostalCode}
                            />
                        </FlexRowWrapper>
                    </FlexColumnWrapper>

                    <FlexColumnWrapper>
                        <Title>Primary Contact Information</Title>
                        <FlexRowWrapper>
                            <Subtitle>Title</Subtitle>
                            {orgProfile.type === 'INDEPENDENT' ? 'Independent Healer' : 'Administrator'}
                        </FlexRowWrapper>
                        <FlexRowWrapper>
                            <Subtitle>Name</Subtitle>
                            <PlaceInfo
                                type="text"
                                value={primaryContact.name || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleOnChangePrimary(e, 'name')
                                }
                            />
                        </FlexRowWrapper>
                        <FlexRowWrapper>
                            <Subtitle>Phone</Subtitle>
                            <PlaceInfo
                                type="text"
                                value={primaryContact.phoneNumber || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleOnChangePrimary(e, 'phoneNumber')
                                }
                            />
                        </FlexRowWrapper>
                        <FlexRowWrapper>
                            <Subtitle>Email</Subtitle>
                            <PlaceInfo
                                type="text"
                                value={primaryContact.email || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleOnChangePrimary(e, 'email')
                                }
                            />
                        </FlexRowWrapper>
                    </FlexColumnWrapper>
                </FlexRowWrapper>
            </ContentFlexAdmin>
        </ContentFlexAdmin>
    )
};

const FlexUpMedia = styled.div`
    display: flex;
    gap: 15px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

const SocialMedia = styled.div`
    display: flex;
    gap: 16px;
    //border-left: 1px solid black;
    padding: 0 11px;
    padding-left: 0px;
`
const IconMedia = styled.img`
    width: 17px;
    height: 17px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    cursor: pointer;
`

const Title = styled.div`
    font-weight: 600;
`;

const Subtitle = styled.div`
    color: var(--simple-gray);
`;

const WrapperInfoType = styled(WrapperInfo)`
    height: 40px;
`;

const ButtonCancel = styled(Button)`
    background-color: var(--t-off-white);
    border: 1px solid var(--t-blue);
    color: var(--t-blue);
    height: 100%;
`;

const WrapperButton = styled.div`
    width: 100%;
    min-width: 100px;
    display: flex;
    height: 100%;
`;

const WrapperButtons = styled.div`
    gap: 20px;
    display: flex;
    height: 100%;
`;

const PlaceInfo = styled.input`
    align-items: center;
    background-color: white;
    border-radius: 9px;
    display: flex;
    height: 39px;
    width: 100%;
    padding: 0 10px;

    color: black;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: normal;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    text-align: left;

    outline: none;
    border: 1px solid var(--simple-gray);

    &::placeholder{
        color: var(--simple-gray);
    }
`;

const PlaceInfoMedia = styled.div`
    align-items: center;
    background-color: white;
    border-radius: 9px;
    display: flex;
    height: 39px;
    width: 100%;
    padding: 0 10px;

    color: var(--simple-gray);
    font-family: 'Inter';
    font-size: 16px;
    font-weight: normal;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    text-align: left;

    outline: none;
    border: 1px solid var(--simple-gray);
`;

const InputMedia = styled.input`
    align-items: center;
    background-color: white;
    border-radius: 9px;
    display: flex;
    width: 100%;

    color: black;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: normal;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    text-align: left;

    outline: none;
`;

const PlaceInfoNoInput = styled.div`
    align-items: center;
    background-color: var(--t-blue);
    border-radius: 9px;
    display: flex;
    height: 39px;
    width: 100%;
    padding: 0 10px;

    color: white;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: normal;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    text-align: left;
    opacity: 0.7;
`;

const RowFlex = styled(PlaceInfoNoInput)`
  background-color: transparent;
  padding: 0;
  gap: 10px;
`;

export default HealersUpdate;
