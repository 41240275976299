import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/pro-thin-svg-icons";
import { FileRejection, useDropzone } from "react-dropzone";
import styled from "styled-components";
import { ProcessInterface } from "../../../containers/SetupHealer/SetupHealer";
import { LogoInterface } from "../../signupsetuphealer/Introduction";
import { BaseFieldState } from "../utils";
import { Imp } from "../../signupsetuphealerind";
import ErrorField from "../ErrorField";

const AddPhotoRounded = ({
    logo,
    setLogo,
    setNewLogo,
    text = '',
}: {
    logo: BaseFieldState<LogoInterface>
    setLogo: (value: BaseFieldState<LogoInterface>) => void
    setNewLogo?: (value: boolean) => void
    required?: boolean
    text?: string
}) => {

    const onDrop = (acceptedFile: File[], rejectedFiles: FileRejection[]) => {
        if(rejectedFiles.length > 0) return;
        setLogo({
            ...logo,
            value: {
                fileLink: '',
                file: acceptedFile[0],
            }
        })
        if (setNewLogo) setNewLogo(true);
    }

    const handleDelete = () => {
        setLogo({
            ...logo,
            value: {
                fileLink: '',
                file: '',
            }
        })
        if (setNewLogo) setNewLogo(true);
    }

    const getImgObject = () => {
        if (typeof logo.value?.file === 'string' && logo.value?.file.length > 0) {
            return logo.value?.file;
        }
        return URL.createObjectURL(logo.value?.file as any);
    }

    const maxSize = 2 * 1024 * 1024;
    const { getRootProps, getInputProps, open, isDragReject, fileRejections } = useDropzone({
        onDrop,
        noClick: true,
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
        maxSize
    })
    const isFileTooLarge = fileRejections.some(file => file.file.size > maxSize);

    return (
        <ContainerPhoto>

            <CenterAddRounded {...getRootProps()}>
                <input {...getInputProps()} />
                {(!logo.value?.file || logo.value?.file?.length === 0 || logo.value?.file === 'cover') ? (
                    <DropPhoto onClick={open}>
                        <IconDrop
                            icon={faImage}
                            size='lg'
                            color={"black"}
                            style={{
                                fontSize: '16px'
                            }}
                        />
                    </DropPhoto>
                ) : (
                    <DropPhoto >
                        <LogoImg
                            src={getImgObject()}
                        />
                    </DropPhoto>
                )}
                <TextClicker onClick={open}>{text ? text : 'Edit profile picture'}</TextClicker>
                {isDragReject && <ErrorField error={'Sorry, only PNG and JPEG files are allowed'} />}
                {isFileTooLarge && <ErrorField error={'File is too large (max 2MB)'} />}
            </CenterAddRounded>
        </ContainerPhoto>
    )
}

const TextClicker = styled.div`
    cursor: pointer;
`;

const LogoImg = styled.img`
    width: 56px;
    height: 56px;
    min-width: 56px;
    min-height: 56px;
    max-width: 56px;
    max-height: 56px;
    object-fit: cover;
    border: 1px solid var(--simple-gray);
    border-radius: 50%;
`;

const ContainerPhoto = styled.div`
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    gap: 8px;
    color: #2A4CD3;
    margin-bottom: 15px;

    text-align: center;
    font-family: Inter-Bold;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.1px;
`;

const CenterAddRounded = styled.div`
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    gap: 8px;
`;

const DropPhoto = styled.div`
    width: 56px;
    height: 56px;
    min-width: 56px;
    min-height: 56px;
    border-radius: 56px;
    border: 1px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const IconDrop = styled(FontAwesomeIcon)`
    font-size: 18px;
`;

export const SubText = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
`

export default AddPhotoRounded;