import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Header from "../../components/Header";
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ChatServiceInterface } from '../../interfaces/chat/ChatServiceInterface';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import General from '../../components/EditProfile/General';
import GeneralHealer from '../../components/EditProfile/GeneralHealer';
import Password from '../../components/EditProfile/Password';
import Publications from '../../components/EditProfile/Publications';
import Credentials from '../../components/EditProfile/Credentials';
import Education from '../../components/EditProfile/Education';
import Services from '../../components/EditProfile/Services';
import AdminAccount from '../../components/EditProfile/AdminAccount';
import PaymentMethod from '../../components/EditProfile/PaymentMethod';
import * as EditComp from './Components';
import { tabsCompany, tabsIndividual, tabsSeeker } from './Constants';
import { getHealerServices, getCertifications } from '../../store/actions/modalityActions';
import { getUserEducation } from '../../store/actions/userActions';
import Interests from '../../components/EditProfile/Interests';
import Preferences from '../../components/EditProfile/Preferences';

const EditProfile = ({
    ChatServiceInstance
}: {
    ChatServiceInstance?: ChatServiceInterface
}) => {
    const { username } = useParams();
    const [activeNotification, setActiveNotification] = useState(false);
    const dispatch = useDispatch();

    const userRoles = useSelector((state: RootState) => state.getUser.userRoles);
    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);

    const tabs = useMemo(() => userRoles.includes('INDIVIDUAL') ? tabsIndividual: userRoles.includes('ORGADMIN') ? tabsCompany : tabsSeeker, [userRoles]);
    const [activeTabId, setActiveTabId] = useState<number>();

    useEffect(() => {
        if (!activeTabId && userRoles) {
            setActiveTabId(tabs[0].id);
        }
    }, [activeTabId, userRoles]);

    const handleTabActive = useCallback((id) => {
        setActiveTabId(id);
    }, []);

    const renderActiveTab = useCallback(() => {
        switch (activeTabId) {
            case 101: case 201: case 301:
                return userRoles.includes('INDIVIDUAL') ? <General /> : userRoles.includes('ORGADMIN') ? <GeneralHealer /> : <General isSeeker/>; 
            case 102: case 203: case 303:
                return <Password />;
            case 103:
                return <Publications />;
            case 104:
                return <Credentials />;
            case 105:
                return <Education />;
            case 106: case 204:
                return <Services />;
            case 202:
                return <General /> // <AdminAccount />;
            case 107: case 205:
                return <PaymentMethod />;

            case 302:
                return <Interests />;

            case 304:
                return <Preferences/>;

            default:
                return null;
        }
    }, [activeTabId, userRoles]);

    useEffect(() => {
        if (userInfo?.username) {
            dispatch(getHealerServices(userInfo?.username || ''));
            dispatch(getCertifications(userInfo?.username || ''));
            dispatch(getUserEducation(userInfo?.username || ''));
        }
    }, [])

    return (
        <EditComp.PrincipalContainer>
            <Header
                handleActiveNotification={setActiveNotification}
                ChatServiceInstance={ChatServiceInstance}
                isHealerProfile={true}
            />
            <EditComp.Tabs>
                {tabs.map(tab => (
                    <EditComp.Tab
                        onClick={() => handleTabActive(tab.id)}
                        theme={{ main: tab.id === activeTabId ? 'tabActive' : '' }}
                        key={tab.id}
                    >
                        {tab.label}
                    </EditComp.Tab>
                ))}
            </EditComp.Tabs>
            <EditComp.ContainerTab>
                {renderActiveTab()}
            </EditComp.ContainerTab>
        </EditComp.PrincipalContainer>
    );
}

export default EditProfile;