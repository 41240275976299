import React, { useEffect, useMemo, useState } from 'react'
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { sortAlphabeticallyOnProperty } from '../../containers/Signup/SignupHealerInd';
import { ClubDigestExtra } from '@vibe/sdk/dist/interfaces/Roster/getUserClubs';
import { IClubExtended } from '@vibe/sdk/interfaces/Club/ClubInterface';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { IUserGradeResponse } from '@vibe/sdk/dist/interfaces/Admin/User';
import { getClubs, getMyClubs, getMyPendingClubs } from '../../store/actions/clubActions';
import { getOrganizationInfo } from '../../store/actions/orgsActions';
import { ClubType } from '../../interfaces/club/ClubList';

export type roleUseClubType = 'ORG' | 'IND' | 'SUPER' | 'SEEK';

interface useClubsProps {
  searchName: string;
  role: roleUseClubType;
  clubType: ClubType;
}

interface useClubsResponse {
  reloadList: () => void;
  allFilteredClubs: IClubExtended[];
  filteredPending: IClubExtended[];
  filteredMyClubs: IClubExtended[];
  myClubs: ClubDigestExtra[];
}

export const getRole = (userRoles: string[]): roleUseClubType => {
  if(userRoles.includes('INDIVIDUAL')) return 'IND';
  if(userRoles.includes('ORGADMIN')) return 'ORG';
  if(userRoles.includes('SUPER')) return 'SUPER';
  return 'SEEK';
}

const useClubsList = ({
  searchName,
  role,
  clubType
}: useClubsProps): useClubsResponse => {

  const dispatch = useDispatch();

  const clubs: IClubExtended[] = useSelector((state: RootState) => state.getClub.getClubsOrg);
  const myClubs: ClubDigestExtra[] = useSelector((state: RootState) => state.getClub.getMyClubs);
  const pendingClubs = useSelector((state: RootState) => state.getClub.getMyPendingClubs);

  const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);
  const organization = useSelector((state: any) => state.getOrgs.organizationInfo);

  const [allFilteredClubs, setAllFilteredClubs] = useState<IClubExtended[]>([]);
  const [filteredMyClubs, setFilteredMyClubs] = useState<IClubExtended[]>([]);
  const [filteredPending, setFilteredPending] = useState<IClubExtended[]>([]);

  const searchParsed = useMemo(() => searchName.toLowerCase(), [searchName]);

  const reloadList = () => {

    dispatch(getMyPendingClubs(user.username));
    dispatch(getMyClubs(user.username));

    if(role !== 'SUPER'){
      dispatch(getClubs({
        club_type: clubType,
        pending: (organization.status || '').startsWith('PE'),
        deleted: false,
        org_id: organization.id,
        org_ids: user.organizations,
      }));
    }
    else{
      dispatch(getClubs({
        club_type: clubType,
      }));
    }
  }

  const filteringMyClubs = (club: IClubExtended): boolean => {
    const areClub = myClubs.find(c => c.club_id === club.id);
    if(areClub) return !(areClub.roles || []).includes('JOIN') && areClub.role !== 'JOIN';
    return false;
  }

  useEffect(() => {
    console.log({pendingClubs, myClubs, clubs})
    if (!searchName || searchName === '') {
      setAllFilteredClubs(clubs);
      setFilteredPending(pendingClubs);
      return;
    }
    setAllFilteredClubs(
      (sortAlphabeticallyOnProperty(clubs, 'name') as IClubExtended[]).filter((m) => 
        m.name.toLowerCase().includes(searchParsed)
      )
    )
    setFilteredPending(
      (sortAlphabeticallyOnProperty(pendingClubs, 'name') as IClubExtended[]).filter((m) => 
        m.name.toLowerCase().includes(searchParsed)
      )
    )
  }, [searchName, clubs, myClubs, pendingClubs]);

  useEffect(() => {
    setFilteredMyClubs(allFilteredClubs.filter(filteringMyClubs));
  }, [allFilteredClubs]);

  useEffect(() => reloadList(), []);

  useEffect(() => reloadList(), [clubType, user]);

  return {
    reloadList,
    allFilteredClubs,
    filteredMyClubs,
    filteredPending,
    myClubs,
  }
};

export default useClubsList;