import ClubForm from "../../interfaces/club/ClubForm";
import ClubInterface from "../../interfaces/ClubExpanded";

const parseClubToForm = (club: ClubInterface): ClubForm => ({
  name: club.name,
  description: club.description,
  skills_learned: club.skills_learned,
  applicationRequired: club.applicationRequired,
  clubDuesRequired: club.clubDuesRequired,
  clubDues: club.clubDues,
  meetingStartTime: club.meetingStartTime,
  meetingEndTime: club.meetingEndTime,
  meetingDays: club.meetingDays,
  meetingLocation: club.meetingLocation,
  meetingFrequency: club.meetingFrequency,
  gradesAllowed: club.gradesAllowed,
  gendersAllowed: club.gendersAllowed,
  maximumNumberMembers: club.maximumNumberMembers,
  participateRegionalCompetitions: club.participateRegionalCompetitions,
  participateNationalCompetitions: club.participateNationalCompetitions,
  participateInternationalCompetitions: club.participateInternationalCompetitions,
  participateLocalCompetitions: club.participateLocalCompetitions,
});

export default parseClubToForm;