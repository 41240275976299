import styled from "styled-components";
import { InspirationalColors } from "../../../BaseComponents/Carrousels/Quotes";

interface InpHomeContProps {
    height: string;
    width: string;
    color: InspirationalColors;
}

interface InpHomeBackgroundProps {
    height: string;
    width: string;
    isBulletin: boolean;
}

const backgroundVibe = require("../../../../assets/img/backgroundQuotes.svg");
export const Background = styled.div`
    position: absolute;
    right: ${({ isBulletin }: InpHomeBackgroundProps) => isBulletin ? '-200px' : '-110px' };
    z-index: 80;
    width: ${({ width }: InpHomeBackgroundProps) => width};
    height: ${({ height }: InpHomeBackgroundProps) => height};
    background-image: url(./backgroundQuotes.svg);
    background-size: contain;
    background-repeat: no-repeat;
`;

export const InspirationHomeContainer = styled.div`
  display: flex;
  width: ${({ width }: InpHomeContProps) => width};
  height: ${({ height }: InpHomeContProps) => height};
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  border-radius: 8px;
  background-color: ${({ color }: InpHomeContProps) => color};
  overflow: hidden;
  position: relative;

  @media only screen and (max-width: 770px) {
    display: none;
  }
`;

export const InspirationContent = styled.div`
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    overflow: hidden;
    height: 100%;
    width: fit-content;// ${({ width }: { width: string }) => width};

    color: #2E2623;
    font-family: 'Source Serif 4 Medium';
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const FromInspirationHome = styled.div`
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.1px;
`;

export const InspirationRow = styled.div`
    display: flex;
    height: 32px;
    min-height: 32px;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
`;

export const InspRows = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
    padding: 15px;
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    max-width: 60px;
    max-height: 60px;
    border-radius: 50%;
    background-color: ${({ color }: { color: string }) => color};
    top: 48%;
    right: -15px;
    z-index: 100;
`;

const logoVibe = require("../../../../assets/img/logoVibe.png");
export const LogoInspirations = styled.div`
    width: 72.017px;
    height: 32px;
    background-image: url("${logoVibe}");
    background-size: contain;
    background-repeat: no-repeat;
`;

export const ScrollInspiration = styled.div`
    height: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    display: flex;
    width: 100%;
`;

export const ContainerContentInspiration = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 22px;
`;

export const Animation = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`

    position: absolute;
    width: calc(100% + 100px);
    height: 100%;
    margin: 0 auto;
    right: -250px;

    &.animAct{
        animation-name: stretch;
        animation-duration: 1.5s; 
        animation-timing-function: ease-out; 
        animation-delay: 0;
        animation-direction: alternate;
        animation-iteration-count: 1;
        animation-fill-mode: none;
        animation-play-state: running;
    }
    
  
  @keyframes stretch {
    0% {
      transform: scale(.1);
      border-radius: 100%;
      background-color: #b3a2a238;
    }
    50% {
        background-color: #b3a2a238;
        border-radius: 75%;
    }
    100% {
      transform: scale(1.5);
    }
  }
`