import "./styles.css";
import { useState, useEffect, useMemo, useCallback } from "react";
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'tui-image-editor/dist/tui-image-editor.css';
// @ts-ignore
import ImageEditor from "@toast-ui/react-image-editor";
import Cropper from 'react-easy-crop'
import getCroppedImg from "../../helpers/imageHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
import { useTranslation } from "react-i18next";

function Index(props: any) {
	const { t, i18n } = useTranslation();
	const [selectedFile, setSelectedFile] = useState<any>()
	const [preview, setPreview] = useState<any>("");
	const myRef = React.useRef<any>(null);
	const [arrayFiles, setArrayFiles] = useState<any[]>([])
	const [showButtons, setShowButtons] = useState<Boolean>(false)
	const [indexCurrent, setIndexCurrent] = useState<number>(0)
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [zoom, setZoom] = useState(1)
	const [imageCropped, setimageCropped] = useState<any>('');
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
	const [rotation, setRotation] = useState(0)

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels)
	}, [])

	function editPhoto(e: any) {
		setArrayFiles([])
		const limit = 5;
		if (e.target.files.length > limit) {
			console.log("Upload Max " + limit + " Files allowed")
		} else {

			setSelectedFile(undefined)
			setPreview('');

			if (!e.target.files || e.target.files.length === 0) {
				setSelectedFile(undefined)
				return
			}

			if (e.target.files.length === 1) {
				setShowButtons(false)
				setSelectedFile(e.target.files[0])
				updateImage(e.target.files[0]);
			} else {
				setArrayFiles(e.target.files)

				setShowButtons(true)
				setSelectedFile(e.target.files[0])

				setPreview(URL.createObjectURL(e.target.files[0]))
				updateImage(e.target.files[0]);
			}
		}
	}

	function updateImage(file: File) {
		if (!file) {
			setPreview(undefined)
			return
		}
		const objectUrl = URL.createObjectURL(file)
		setPreview(objectUrl)
		return () => URL.revokeObjectURL(objectUrl)
	}

	async function handleOnClickOk(e: any, type: any) {
		e.preventDefault();

		if (type === 'save') {
			try {
				const croppedImage = await getCroppedImg(
					preview,
					croppedAreaPixels,
					rotation
				)
				console.log('donee', { croppedImage })
				setimageCropped(croppedImage)
				props.handleSaveImage(croppedImage);
				props.onHide(e, type)
			} catch (e) {
				console.error(e)
			}
		} else if (type === 'cancel') {
			props.onHide(e, type);
		}

		setSelectedFile(undefined)
		setPreview('');
		setArrayFiles([]);
	}

	useEffect(() => {
		if (props.show) {
			if(props.preview.value.file){
				setPreview(URL.createObjectURL(props.preview.value.file))
			}
		}
	}, [props.show])

	return (
		<>
			<Modal className={`modalImageEditor menuTopHidden ${!preview && 'modalWPreview'}`} show={props.show} onHide={props.onHide}>
				<Modal.Header>
					<Modal.Title>{(props.isProfilePhoto) ? t('modalPhoto__profile') : t('modalPhoto__photo')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{!preview ? (
						<>
							<label htmlFor="filePickerModal" className="filePickerModal">
								<div className="iconImageCenter">
									<FontAwesomeIcon
										icon="camera"
										size='lg'
										color={WHITE}
										className="iconCamera  "
									/>
									<p>{t('setupSchool__selectFile')}</p>
								</div>
								<input
									id="filePickerModal"
									type={"file"}
									onChange={(e) => editPhoto(e)}
									multiple
								/>
							</label>
						</>
					) : (
						<>
							<div onClick={(e) => handleOnClickOk(e, 'trash')}>
								<div className="iconImageCenterMini">
									<FontAwesomeIcon
										icon="trash"
										size='lg'
										color={WHITE}
										className="iconCamera  "
									/>
								</div>
							</div>
							{/* <Cropper
								image={preview}
								crop={crop}
								zoom={zoom}
								aspect={1}
								onCropChange={setCrop}
								onCropComplete={onCropComplete}
								onZoomChange={setZoom}
								cropShape="rect"
							/> */}
							<Cropper
								image={preview}
								crop={crop}
								rotation={rotation}
								zoom={zoom}
								aspect={1}
								onCropChange={setCrop}
								onRotationChange={setRotation}
								onCropComplete={onCropComplete}
								onZoomChange={setZoom}
								cropShape="rect"
								minZoom={1}
								maxZoom={3}
								cropSize={props.cropSize}
							/>
							<div className="controls">
								<input
									type="range"
									value={zoom}
									min={1}
									max={3}
									step={0.1}
									aria-labelledby="Zoom"
									onChange={(e) => {
										setZoom(Number(e.target.value))
									}}
									className="zoom-range"
								/>
							</div>
						</>

					)}
				</Modal.Body>
				<Modal.Footer>
					<Button className="buttonSendEvent" onClick={(e) => handleOnClickOk(e, 'cancel')}>
						{t('button__cancel')}
					</Button>
					<Button className="buttonSendEvent" onClick={(e) => handleOnClickOk(e, 'save')}>
						{t('button__save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default Index;