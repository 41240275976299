import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import { getFiltered, sortAlphabeticallyOnProperty } from "../../../containers/Signup/SignupHealerInd"
import { useEffect, useState } from "react";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { BaseFieldState, getFieldState } from "../utils";
import OutsideClickHandler from "react-outside-click-handler";
import useCountryStates from "../../../hooks/Locations/useCountryStates";
import { StateOption } from "../../../hooks/Locations/useActiveState";

const SelectorState = ({
    country,
    state,
    setState,
    searchState,
    setSearchState,
    backgroundColor = "white",
    border = "1px solid var(--simple-gray)",
}: {
    country?: string,
    state: BaseFieldState<StateOption>,
    setState: (data: BaseFieldState<StateOption>) => void,
    searchState: string,
    setSearchState: (data: string) => void,
    backgroundColor?: string,
    border?: string,
}) => {
    const {
        statesReactSelect,
        reloadCountry,
    } = useCountryStates();

    const [showOptionsState, setShowOptionsState] = useState<boolean>(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [statesFiltered, setCitiesFiltered] = useState<StateOption[]>([])

    useEffect(() => {
        console.log({ country })
        if (country) {
            reloadCountry(country);
            if (firstLoad) {
                setFirstLoad(false);
            } else {
                setState(getFieldState({ label: '', value: '' }));
                setSearchState('');
            }
        }
    }, [country]);

    useEffect(() => {
        const itemFound = statesReactSelect.find((country) =>
            country.label &&
            (country.label.toLocaleLowerCase() === searchState ||
                country.label.toLocaleUpperCase() === searchState ||
                country.label === searchState)
        )
        if (itemFound) {
            setSearchState(itemFound.label)
            setShowOptionsState(false)
            setState({
                ...state,
                value: itemFound
            })
        }
    }, [searchState])

    useEffect(() => {
        if (state.value?.value) {
            setSearchState(state.value?.label)
        }
    }, [])

    useEffect(() => {
        if (state.value?.value) {
            setSearchState(state.value?.label)
        }
    }, [state])

    const getFilteredOptions = () => {
        if (searchState !== state.value?.label) {
            return setCitiesFiltered(getFiltered(sortAlphabeticallyOnProperty(statesReactSelect, 'label'), searchState))
        }
        return setCitiesFiltered(sortAlphabeticallyOnProperty(statesReactSelect, 'label'));
    }

    useEffect(() => {
        getFilteredOptions();
    }, [searchState, statesReactSelect])

    return (
        <WrapperSelect>
            <Select_Input
                onClick={() => setShowOptionsState(true)}
                value={searchState}
                placeholder='Select a State / Region'
                onChange={(e) => setSearchState(e.currentTarget.value)}
                backgroundColor={backgroundColor}
                border={border}
                onFocus={() => setShowOptionsState(true)}
                autoComplete={"unInput"}
            />
            <IconDropdownInput
                icon={faChevronDown}
                size='sm'
                color={backgroundColor === 'white' ? 'black' : 'white'}
                onClick={() => setShowOptionsState(!showOptionsState)}
            />
            {showOptionsState &&
                <OutsideClickHandler onOutsideClick={() => setShowOptionsState(false)}>
                    <Items>
                        {statesFiltered.length > 0 ? statesFiltered.map((activeState, index) => (
                            <Item onClick={() => {
                                setSearchState(activeState.label)
                                setShowOptionsState(false)
                                setState({
                                    ...state,
                                    value: activeState
                                })
                            }
                            }
                                key={index}
                            >
                                {activeState.label}
                            </Item>
                        )) :
                            <ItemDisabled>
                                Select country first...
                            </ItemDisabled>
                        }
                    </Items>
                </OutsideClickHandler>
            }
        </WrapperSelect>
    )
}

const WrapperSelect = styled.div`
    position: relative;
    display: flex;
    width: 100%;
`;

interface Select_InputProps {
    backgroundColor: string
    border: string
}

const Select_Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Inter';
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;

    background-color: ${(props: Select_InputProps) => props.backgroundColor};
    border: ${(props: Select_InputProps) => props.border};
    color: ${(props: Select_InputProps) => props.backgroundColor === 'white' ? 'black' : 'white'};

    &::placeholder{
        color: var(--simple-gray);
        font-family: 'Inter';
    }
`;

const IconDropdownInput = styled(FontAwesomeIcon)`
    cursor: pointer;
    position: absolute;
    z-index: 0;
    top: 37%;
    right: 20px;
`;

const Item = styled.li`
    background-color: white;
    padding: 10px 20px;
    text-align: left;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;

    &:last-child {
        border-radius: 0 0 10px 10px;
        -webkit-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        -moz-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
    }

    &:hover {
        background-color: #e8e8e8;
    }
`;

const ItemDisabled = styled(Item)`
    background-color: #e8e8e8;
    cursor: default;
`;

const Items = styled.ul`
    width: 100%;
    position: absolute;
    top: 100%;
    z-index: 10;
    overflow-y: scroll;
    max-height: 200px;
    border-radius: 12px;
    border: 1px solid var(--simple-gray);
    left: 0px;
    box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
`;

export default SelectorState;