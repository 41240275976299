import React, { useContext, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userRestorePassword } from "../../store/actions/userActions";
import ButtonSpinner from "../BaseComponents/ButtonSpinner";
import PasswordToolTip from "../Website/PasswordToolTip";
import { ContextSuccess } from "../../Context";
import { validatePassword } from "../../helpers/userHelper";
import { Imp, Row1 } from "../../containers/Website/Form";
import { RootState } from "../../store/store";

const FormRestorePassword = ({
    onHide,
}: {
    onHide: () => void
}) => {

    const currentSuccess = useContext(ContextSuccess);
    const dispatch = useDispatch();

    const [username, setUsername] = useState('');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');

    const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const loadingForgot = useSelector((state: RootState) => state.getUser.loadingRestorePassword || false);

    const handleSubmit = () => {
        if (username === '' || code === '' || password === '') {
            currentSuccess?.setSuccessData(false, true, 'Missing data!', 'Username, code and new password are required.');
            return;
        }

        const errorPassword = validatePassword(password || '');
        if (errorPassword.lastError !== '') {
            currentSuccess?.setSuccessData(false, true, 'Check your password!', errorPassword.lastError === 'Password is required' ?
                errorPassword.lastError : `Password needs ${errorPassword.lastError.toLowerCase()}`);
            return;
        }

        dispatch<any>(userRestorePassword(username, code, password))
            .then((results: any) => {
                if (results) {
                    onHide();
                    currentSuccess?.setSuccessData(true, true, 'Password Restored!');
                }
                else {
                    currentSuccess?.setSuccessData(false, true, 'Error restoring password.');
                }
            })
            .catch(console.log);
    };

    return (
        <Form>
            <TitleForm>Restore password</TitleForm>
            <Inputs>
                <Row1>
                    <Label>Username<Imp>*</Imp></Label>
                    <Input
                        onChange={(e) => setUsername(e.currentTarget.value)}
                    />
                </Row1>
            </Inputs>
            <Inputs>
                <Row1>
                    <Label>Code<Imp>*</Imp></Label>
                    <Input
                        onChange={(e) => setCode(e.currentTarget.value)}
                    />
                </Row1>
            </Inputs>
            <Inputs>
                <Row1>
                    <Label>New Password<Imp>*</Imp></Label>
                    <InputIcon
                        onClick={() => setShowPasswordTooltip(true)}
                    >
                        <Input
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => setPassword(e.currentTarget.value)}
                            value={password}
                            autoComplete={"new-password"}
                        />
                        <StyledFontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            size='sm'
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </InputIcon>
                    {(showPasswordTooltip) && (<PasswordToolTip password={password} setShowModal={setShowPasswordTooltip} />)}
                </Row1>
            </Inputs>
            <WrapperButton>
                <ButtonSpinner
                    loading={loadingForgot}
                    handleClick={handleSubmit}
                    text={'Restore'}
                    hasIcon={true}
                    icon={'arrow-right'}
                />
            </WrapperButton>
        </Form>
    );
};

export default FormRestorePassword;

const WrapperButton = styled.div`
    margin-top: 28px;
`;

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
`;

const ContainerForm = styled.div`
    width: calc(100% - 410px);
    background-color: var(--background-light);
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 0 135px;
    align-items: center;
    overflow-y: scroll;
`;

const ContainerInfo = styled.div`
    width: 410px;
    background-color: var(--simple-brown);
    height: 100vh;
    text-align: left;
    color: black;
    padding: 33px 135px 33px 33px;
    background-image: url(./slideSignUp.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;
`;

const Title = styled.h1`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 35px;
`;

const Subtitle = styled.h2`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    padding: 20px 0;
`;

const Text = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
`;

const Form = styled.div`
   width: 375px;
   margin-top: 50px;
`;

const TitleForm = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin: 0px 0 15px 0;
`;

export const Logo = styled.div`
    width: 100%;
    height: 50px;
    background-image: url(./logoVibe.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    top: 32px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: black;
    cursor: pointer;
    margin-left: 10px;
`;

const StyledFontAwesomeIconSend = styled(FontAwesomeIcon)`
    color: white;
    margin-left: 15px;
    font-size: 16px;

    position: absolute;
    right: 15px;
`;

const ButtonSend = styled.div`
    width: 100%;
    height: 40px;
    padding: 10px 20px;
    border-radius: 11px;
    background-color: var(--simple-blue-dark-2);
    border: 0;
    color: white;
    font-weight: 700;
    margin-top: 25px;
    margin-right: 26px;
    position: relative;
    cursor: pointer;
    font-size: 24px;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
`

export const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
    margin: 7px 0;
`

const InputIcon = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    border-radius: 9px;
    align-items: center;
    padding-right: 15px;
    border: 1px solid var(--simple-gray);
    height: 40px;
    
    & ${Input}{
        border: 0;
        height: 38px;
    } 
`

export const InputPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0 0px 0 20px;
    font-size: 15px;
    font-family: 'Inter';
`;

const Label = styled.div`    
    width: 100%;
    text-align: left;
    margin: 3px 0;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`
const Inputs = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
`

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const LoginTitle = styled.p`
    font-size: 24px;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
`;