import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/pro-thin-svg-icons";
import { FileRejection, useDropzone } from "react-dropzone";
import styled from "styled-components";
import { LogoInterface } from "../../signupsetuphealer/Introduction";
import { BaseFieldState } from "../utils";
import { useState } from "react";
import { IconClicker } from "../../Home/HeaderHome/styles";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import OutsideClickHandler from "react-outside-click-handler";
import ErrorField from "../ErrorField";

const AddPhotoPencil = ({
    logo,
    setLogo,
    setNewLogo,
    hidePen = false,
}: {
    logo: BaseFieldState<LogoInterface>
    setLogo: (value: BaseFieldState<LogoInterface>, isDelete?: boolean) => void
    setNewLogo?: (value: boolean) => void
    hidePen?: boolean
}) => {

    const [optionsOpened, setOptionsOpened] = useState<boolean>(false);

    const onDrop = (acceptedFile: File[], rejectedFiles: FileRejection[]) => {
        if(rejectedFiles.length > 0) return;
        setLogo({
            ...logo,
            value: {
                fileLink: '',
                file: acceptedFile[0],
            }
        })
        if (setNewLogo) setNewLogo(true);
    }

    const handleDelete = () => {
        setLogo({
            ...logo,
            value: {
                fileLink: '',
                file: '',
            }
        }, true)
        if (setNewLogo) setNewLogo(true);
    }

    const getImgObject = () => {
        if (typeof logo.value?.file === 'string' && logo.value?.file.length > 0) {
            return logo.value?.file;
        }
        return URL.createObjectURL(logo.value?.file as any);
    }

    const maxSize = 2 * 1024 * 1024;
    const { getRootProps, getInputProps, open, isDragReject, fileRejections } = useDropzone({
        onDrop,
        noClick: true,
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
        maxSize
    })
    const isFileTooLarge = fileRejections.some(file => file.file.size > maxSize);

    return (
        <ContainerPhoto>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {(!logo.value?.file || logo.value?.file?.length === 0 || logo.value?.file === 'cover') ? (
                    <DropPhoto>
                        <IconDrop
                            icon={faImage}
                            size='lg'
                            color={"black"}
                            style={{
                                fontSize: '16px'
                            }}
                        />
                        {!hidePen && <Pencil
                            icon={faPencil}
                            size="lg"
                            onClick={() => setOptionsOpened(true)}
                        />}
                    </DropPhoto>
                ) : (
                    <DropPhoto>
                        <LogoImg
                            src={getImgObject()}
                        />
                        {!hidePen && <Pencil
                            icon={faPencil}
                            size="lg"
                            onClick={() => setOptionsOpened(true)}
                        />}
                    </DropPhoto>
                )}
                {isDragReject && <ErrorField error={'Sorry, only PNG and JPEG files are allowed'} />}
                {isFileTooLarge && <ErrorField error={'File is too large (max 2MB)'} />}
            </div>

            {optionsOpened &&
                <OutsideClickHandler onOutsideClick={() => setOptionsOpened(false)}>
                    <DividePhoto>
                        <TextDrop_Trash onClick={handleDelete}>
                            <IconDrop
                                icon='trash'
                                size='sm'
                                color={"var(--maroon-flush)"}
                            />
                            Delete
                        </TextDrop_Trash>
                        <TextDrop onClick={open}>
                            <TextDrop_Trash>
                                <IconDrop
                                    icon='arrows-rotate'
                                    size='sm'
                                    color={"var(--simple-gray)"}
                                />
                                Replace
                            </TextDrop_Trash>
                            <SubtextDrop>
                                JPG or PNG (2mb max)
                            </SubtextDrop>
                        </TextDrop>
                    </DividePhoto>
                </OutsideClickHandler>}
        </ContainerPhoto>
    )
}

const Pencil = styled(IconClicker)`
    position: absolute;
    right: 5px;
    bottom: 5px;
`;

const DividePhoto = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
    gap:5px;
    background-color: white;
    position: absolute;
    bottom: -70px;
    right: -100px;
    border-radius: 12px;
    border: 1px solid var(--simple-gray-2);
    box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
    padding: 10px;
`;

const LogoImg = styled.img`
    width: 128px;
    height: 128px;
    min-width: 128px;
    min-height: 128px;
    max-width: 128px;
    max-height: 128px;
    border: 1px solid var(--simple-gray);
    border-radius: 12px;
    object-fit: cover;
`;

const ContainerPhoto = styled.div`
    text-align: initial;
    display: flex;
    position: relative;
    flex-direction: column;
    width: 128px;
    height: 128px;
`

const DropPhoto = styled.div`
    width: 128px;
    text-align: initial;
    border: 1px solid var(--simple-gray);
    border-radius: 12px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
    height: 128px;
    position: relative;
`

const ContIconDrop = styled.div`
    min-width: 75px;
    min-height: 75px;
    border-radius: 12px;
    background-color: #F2F5FF;
    display: flex;
    justify-content: center;
    align-items: center;
`

const TextDrop = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
    color: var(--simple-brown-dark);

    display:flex;
    flex-direction: column;
    gap: 3px;
    border-radius: 12px;
    padding: 5px;
    cursor: pointer;

    &:hover {
        background-color: #e8e8e8;
    }
`

const TextDrop_Trash = styled(TextDrop)`
    flex-direction: row;
    padding: 5px;
`;

const SubtextDrop = styled.span`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: var(--onyx);
`

const IconDrop = styled(FontAwesomeIcon)`
    font-size: 18px;
`;

const TextBlue = styled.p`
    color: blue;
`

export const SubText = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
`

export default AddPhotoPencil;