import styled from 'styled-components';

export const PaymentFormContainer = styled.div`
    width: 350px;
    height: auto;
    margin-top: 10px;
    margin-left: 10px;
`
interface PropsButton {
    save: boolean
  }

export const PaymentFormButton = styled.button`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  text-align: center;
  /* padding: 10px 20px; */
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  /* margin: auto; */
  margin-top: 10px;
  padding: 5px;

  color: ${(props: PropsButton) => !props.save ? 'var(--simple-blue-dark)' : 'var(--white)'};
  background-color: ${(props: PropsButton) => props.save ? 'var(--simple-blue-dark)' : 'var(--white)'};
  border: 2px solid var(--simple-blue-dark);
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const FormContainer = styled.form`
    
`

export const Input = styled.input`
    outline: none;
    width: 69%;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    height: 30px;
    border-radius: 9px;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
`