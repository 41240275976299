import { useState, useContext } from "react";
import {
    Title,
} from "../SuperAdminComponents/basicForSACenter";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import { useTranslation } from "react-i18next";
import { ContextSuccess } from "../../../Context";
import styled from "styled-components";
import SearchInput from "../../BaseComponents/SearchInput";
import BlankTable from "../../BaseComponents/BlankTable";
import useCountriesSA from "../../../hooks/useCountriesSA";
import IMessageResponse from "@vibe/sdk/dist/interfaces/IMessageResponse";

const CountriesTable = () => {
    const { t, i18n } = useTranslation();
    const currentSuccess = useContext(ContextSuccess);

    const [SearchTerm, setSearchTerm] = useState("");

    const { 
        countriesFiltered, 
        reloadCountries 
    } = useCountriesSA({
        SearchTerm,
    });

    const handleActivate = (langCode: string) => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Roster.activateCountry(langCode, dataSDK.token);
            })
            .then((results) => {
                const message = (results.body as IMessageResponse).message;
                if (message === "Activated") {
                    currentSuccess?.setSuccessData(true, true, t('toast_countryActivated'));
                    reloadCountries();
                } else {
                    currentSuccess?.setSuccessData(false, true, 'There was an error');
                }
            })
    };

    const handleDeactivate = (langCode: string) => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Roster.deactivateCountry(langCode, dataSDK.token);
            })
            .then((results) => {
                const message = (results.body as IMessageResponse).message;
                if (message === "Deactivated") {
                    currentSuccess?.setSuccessData(true, true, t('toast_countryDesactivated'));
                    reloadCountries();
                } else {
                    currentSuccess?.setSuccessData(false, true, 'There was an error');
                }
            });
    };

    return (
        <ContentOrganizations>
            <Title>Countries</Title>
            <WrapperRow>
                <SearchInput
                    filteredItem={SearchTerm}
                    setFilteredItem={setSearchTerm}
                    border={'1px solid var(--simple-gray)'}
                    placeholder={`Search by name`}
                />
            </WrapperRow>
            <Scroll>
                <BlankTable
                    headerColored
                    border
                    headers={['Country', 'Status']}
                    items={countriesFiltered.map(org => ({
                        id: org.location,
                        labels: [
                            org.name,
                            org.active ? 'Active' : 'Disabled',
                        ],
                    }))}
                    actions={['ACTIVATE', 'DEACTIVATE']}
                    actionsHandlers={{
                        handleActivate,
                        handleDeactivate,
                    }}
                    backgroundColor={'white'}
                />
            </Scroll>
        </ContentOrganizations>
    );
};

export default CountriesTable;

const ContentOrganizations = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  align-items: flex-start;
  gap: 15px;
  flex-direction: column;
`;

const WrapperRow = styled.div`
  display: flex;
  width: 40%;
  gap: 25px;
  align-items: flex-end;
`;

const Scroll = styled.div`
  display: flex;
  width: 100%;
  overflow-y: scroll;
`;
