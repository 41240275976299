import { useTranslation } from "react-i18next";
import styled from "styled-components";

const NoChatSelected = () => {
    
    const { t, i18n } = useTranslation();
    
    return (
        <NoChatWrapper>
            <TitleCenterChat>{t('chat_NoChatSelected')}</TitleCenterChat>
            <SubtitleCenterChat>{t('chat_TalkWithFriends')}</SubtitleCenterChat>
        </NoChatWrapper>
    );
}

export default NoChatSelected;

const NoChatWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 200px;
`;

export const TitleCenterChat = styled.h1`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: var(--t-blue);
`;

export const SubtitleCenterChat = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: var(--t-blue);
`;