// GET TEMPLATE CLUB
export const START_GET_TEMPLATE_CLUB = "START_GET_TEMPLATE_CLUB";
export const GET_TEMPLATE_CLUB_SUCCESS = "GET_TEMPLATE_CLUB_SUCCESS";
export const GET_TEMPLATE_CLUB_ERROR = "GET_TEMPLATE_CLUB_ERROR";

export const START_GET_GROUPED_TCLUB = 'START_GET_GROUPED_TCLUB';
export const GET_GROUPED_TCLUB_SUCCESS = 'GET_GROUPED_TCLUB_SUCCESS';
export const GET_GROUPED_TCLUB_ERROR = 'GET_GROUPED_TCLUB_ERROR';

export const START_GET_TEAMS = 'START_GET_TEAMS';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const GET_TEAMS_ERROR = 'GET_TEAMS_ERROR';

export const LOGOUT_TEMPLATE_CLUB = 'LOGOUT_TEMPLATE_CLUB';