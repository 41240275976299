import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import styled from 'styled-components';
import { ContextSuccess } from '../../../Context';
import { onHideType } from '../../BaseComponents/Modals/ModalUnsaved';
import { BaseFieldState, getFieldState } from '../../BaseComponents/utils';
import { Imp } from '../../signupsetuphealerind';
import TextEditor from '../../BaseComponents/TextEditor';
import ErrorField from '../../BaseComponents/ErrorField';
import ButtonSpinner from '../../BaseComponents/ButtonSpinner';
import { getDataSDK, getDataSDKResponse } from '../../../helpers/backendHelper';
import TClub from '@vibe/sdk/dist/interfaces/Club/TClubs/TClub/TClub';
import IResponseError from '@vibe/sdk/dist/interfaces/IResponseError';
import { useTranslation } from 'react-i18next';
import SelectItem from '../../../interfaces/SelectItem';
import Selector from '../../BaseComponents/Selector';


const roleAssignedOptions: SelectItem[] = [
    {
        value: 'SEEKER',
        label: 'Seeker',
    },
    {
        value: 'STAFF',
        label: 'Staff',
    },
    {
        value: 'ADVISORY',
        label: 'Advisory',
    },
    {
        value: 'COMMITTEE',
        label: 'Committee',
    },
    {
        value: 'HEALER',
        label: 'Healer',
    }
]

const ModalCreateTemTeam = ({
    showModal,
    setShowModal,
    reloadTeams,
    edit = false,
    data,
}: {
    showModal: boolean
    setShowModal: (data: boolean) => void
    reloadTeams: () => void
    edit?: boolean
    data?: TClub
}) => {
    const { t } = useTranslation();
    const currentSuccess = useContext(ContextSuccess);

    const [loading, setLoading] = useState<boolean>(false);
    const [Name, setName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [Description, setDescription] = useState<BaseFieldState<string>>(getFieldState(''));
    const [RoleAssigned, setRoleAssigned] = useState<BaseFieldState<SelectItem>>(getFieldState());
    const [searchType, setSearchType] = useState<string>('');

    const checkData = (): boolean => {
        let hasErrors = false;

        if (!Name.value || Name.value.length === 0) {
            hasErrors = true;
            setName({
                ...Name,
                showError: true,
                error: 'Name is required'
            })
        } else {
            setName({
                ...Name,
                showError: false,
                error: ''
            })
        }

        if (!Description.value || Description.value.length === 0) {
            hasErrors = true;
            setDescription({
                ...Description,
                showError: true,
                error: 'Description is required'
            })
        } else {
            setDescription({
                ...Description,
                showError: false,
                error: ''
            })
        }

        if (!RoleAssigned.value || RoleAssigned.value?.value.length === 0) {
            hasErrors = true;
            setRoleAssigned({
                ...RoleAssigned,
                showError: true,
                error: 'Role Assigned is required'
            })
        } else {
            setRoleAssigned({
                ...RoleAssigned,
                showError: false,
                error: ''
            })
        }

        return hasErrors;
    }

    const loadData = () => {
        setName({
            ...Name,
            value: data?.name || '',
        })
        setDescription({
            ...Description,
            value: data?.description || '',
        })
    }

    const cleanData = () => {
        setName(getFieldState(''));
        setDescription(getFieldState(''));
        setRoleAssigned(getFieldState());
        setSearchType('')
    }

    const onHideInner = () => {
        setShowModal(false);
        cleanData();
    }

    const createTeam = () => {
        setLoading(true);

        const toSend: Omit<TClub, "id"> = {
            name: Name.value || '',
            club_type: 'COMM',
            description: Description.value || '',
            member_types: [RoleAssigned.value?.value ?? ''],
            role_assigned: RoleAssigned.value?.value ?? '',
        }

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Club.createTClub(toSend, dataSDK.token);
            })
            .then((results) => {
                if(results.statusCode === 201){
                    reloadTeams();
                    currentSuccess?.setSuccessData(true, true, t('toast_templateClubCreated'));
                    onHideInner();
                    return;
                }
                currentSuccess?.setSuccessData(false, true, t('toast_errorCreatingTemplate'), (results.body as IResponseError).error || (results.body as IResponseError).message);
            })
            .catch((error) => {
                currentSuccess?.setSuccessData(false, true, t('toast_errorCreatingTemplate'), error);
            })
            .finally(() => setLoading(false))
    }

    const updateTeam = () => {
        setLoading(true);

        const toSend: Partial<TClub> = {
            name: Name.value || '',
            description: Description.value || '',
            member_types: [RoleAssigned.value?.value ?? ''],
            role_assigned: RoleAssigned.value?.value ?? '',
        }

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Club.updateTClub(data?.id || '', toSend, dataSDK.token);
            })
            .then((results) => {
                if(results.statusCode === 201){
                    reloadTeams();
                    currentSuccess?.setSuccessData(true, true, t('toast_templateClubUpdated'));
                    onHideInner();
                    return;
                }
                currentSuccess?.setSuccessData(false, true, t('toast_errorUpdatingTemplate'), (results.body as IResponseError).error || (results.body as IResponseError).message);
            })
            .catch((error) => {
                currentSuccess?.setSuccessData(false, true, t('toast_errorUpdatingTemplate'), error);
            })
            .finally(() => setLoading(false))
    }

    const handleSave = () => {
        if (checkData()) return;

        if (edit) updateTeam();
        else createTeam();
    }

    const onHideUnsaved = (type: onHideType) => {
        switch (type) {
            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                onHideInner();
                break;

            case 'save':
                currentSuccess?.setModalsOff();
                handleSave();
                break;

            default:
                currentSuccess?.setModalsOff();
                break;
        }
    }

    const handleUnsaved = () => {
        currentSuccess?.setModalWarning(
            'UNSAVED',
            (type) => onHideUnsaved(type),
            'Do you want to save your changes?'
        );
    }

    const handleOnBlurDescription = (e: string) => {
        setDescription({
            ...Description,
            value: e
        })
    }

    useEffect(() => {
        if (edit) loadData();
    }, [data, edit])

    useEffect(() => {
        if (edit) loadData();
    }, [])

    return (
        <Modal className="modalTeam" show={showModal}>
            <Modal.Header>
                <Title>Create Template Team</Title>
                <IconClicker
                    onClick={handleUnsaved}
                    icon="times"
                    size='xl'
                    color={'black'}
                />
            </Modal.Header>
            <Modal.Body>
                <Wrapper>
                    <Label>Name<Imp>*</Imp></Label>
                    <Input
                        value={Name.value || ''}
                        onChange={(e) => setName({
                            ...Name,
                            value: e.currentTarget.value
                        })}
                        placeholder="Give me a name!"
                    />
                    {(Name.showError) && (<ErrorField error={Name.error} />)}

                    <Label>Description<Imp>*</Imp></Label>
                    <WrapperEditor>
                        <TextEditor
                            value={Description.value || ''}
                            setValue={handleOnBlurDescription}
                            charLimit={2500}
                            minHeight={120}
                            placeholder={'Please describe the internal team template you are creating...'}
                            showError={Description.showError}
                        />
                    </WrapperEditor>

                    <Label>Role Assigned<Imp>*</Imp></Label>
                    <Selector
                        options={roleAssignedOptions}
                        selected={RoleAssigned}
                        setSelected={setRoleAssigned}
                        placeholder={'Select'}
                        searchItem={searchType}
                        setSearchItem={setSearchType}
                    />
                    {(RoleAssigned.showError) && (<ErrorField error={RoleAssigned.error} />)}

                </Wrapper>
            </Modal.Body>
            <Modal.Footer>
                <WrapperButtons>
                    <ButtonCancel onClick={handleUnsaved}>
                        Cancel
                    </ButtonCancel>
                    <WrapperButton>
                        <ButtonSpinner
                            loading={loading}
                            handleClick={handleSave}
                            text={'Save'}
                            hasIcon={false}
                        />
                    </WrapperButton>
                </WrapperButtons>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalCreateTemTeam;

const WrapperButton = styled.div`
    width: 50%;
`;

const ButtonCancel = styled.div`
    width: 49%;
    text-align: center;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 1px solid var(--dark-blue);
    cursor: pointer;
`

const WrapperButtons = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 10px;
`;

const IconClicker = styled(FontAwesomeIcon)`
    cursor: pointer;
`;

const WrapperEditor = styled.div`
    width: 100%;
`

const Label = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    display: flex;
`

const Title = styled(Label)`
    font-size: 20px;
    color: var(--t-blue);
`;

const Input = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    padding: 0;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--black);
    padding-left: 20px;

    &::placeholder{
        color: var(--simple-gray);
        font-family: 'Inter'
    }
`;