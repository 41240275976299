import { InspirationalColors } from "../components/BaseComponents/Carrousels/Quotes";

export const capitalize = (word : string) => {
    if(word === 'YOUTHORG') {
        return 'Youth Organization'
    }
    if(word === 'NONPROFIT'){
        return 'Non Profit'
    }
    if((word === ' ') || (word === '')){
        return ''
    }
    return word[0].toUpperCase() + word.substring(1).toLowerCase();
    
};

export const capitalizeStatus = (word : string) => {
    if(word === 'PENDING_APPROVAL') {
        return 'Pending Approval'
    }
    if(word === 'PENDING_CONFIRMATION'){
        return 'Pending Confirmation'
    }
    if((word === ' ') || (word === '')){
        return ''
    }
    return word[0].toUpperCase() + word.substring(1).toLowerCase();
};

export const onlyFirstLetterCapitalized = (word : string):string =>{
    const text = word.toLowerCase()
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export const getInitials = (name: string) => (name.match(/(\b\S)?/g) || ['']).filter((x) => !!x).slice(0,2).join("").toUpperCase();

export const getColorsInitials = (num = 3) => {
    const possibleColors = [{
        background: "var(--t-blue)",
        color: "white",
    },
    {
        background: "var(--non-contextual-blue-muted)",
        color: "var(--non-contextual-blue-on-muted)",
    },
    {
        background: "var(--non-contextual-grey-muted)",
        color: "var(--non-contextual-grey-on-muted)",
    }]

    return possibleColors[num % 3];
}

export const getInspirationalColor = (lastColor: number) : {
    base: InspirationalColors , 
    button: InspirationalColors,
    id: number
} => {
    const colors: InspirationalColors[] = ["#D6D9CA", "#D9CEC3", "#E5DBDC", "#E7D9B9"]

    if(lastColor === 3 ) return {
        base: colors[0] , 
        button: colors[1],
        id: 0,
    };
    return {
        id: lastColor + 1,
        base: colors[lastColor + 1] , 
        button: colors[(lastColor + 2) === 4 ? 0 : lastColor + 2]
    };
}

export const cleanHTML = (e: string) => e?.replace(/(<([^>]+)>)/gi, "").replace('&nbsp;', ' ') ?? '';
export const cleanStyleHTML = (e: string) => e?.replace(/style="[^"]*"/gm, "").replace('&nbsp;', ' ') ?? '';