import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import BlankTable from "../../BaseComponents/BlankTable";
import SearchInput from "../../BaseComponents/SearchInput";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../store/store";
import { PAGE_SIZES } from "../../AdminCenter/MyEvents/EventAdmin";
import { getRole } from "../../../hooks/Clubs/useClubsList";
import { capitalize, capitalizeStatus } from "../../../helpers/letterHelper";
import SelectItem from "../../../interfaces/SelectItem";
import Selector from "../../BaseComponents/Selector";
import { ContextSuccess } from "../../../Context";
import i18n from "../../../i18n";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import { onHideType } from "../../BaseComponents/Modals/ModalUnsaved";
import { BaseFieldState, getFieldState } from "../../BaseComponents/utils";
import { Title } from "../SuperAdminComponents/basicForSACenter";
import { getUsersStatus } from "../../../store/actions/userActions";
import useOrgList from "../../../hooks/Superadmin/Healers/useOrgList";
import useUsersList, { UseUserRole, UseUserStatus } from "../../../hooks/users/useUsersList";
import { getRolesUsersView } from "../../../helpers/roleHelper";
import { unixToDateFormatted } from "../../../helpers/dateHelper";

function Seekers() {
  const { t } = i18n;

  const currentSuccess = useContext(ContextSuccess);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userRoles = useSelector((state: RootState) => state.getUser.userRoles);
  const userStatus = useSelector((state: RootState) => state.getUser.usersStatus);

  const [search, setSearch] = useState<string>('');
  const [searchPage, setSearchPage] = useState<string>('');
  const [searchStatus, setSearchStatus] = useState<UseUserStatus>('ALL');
  const [searchRole, setSearchRole] = useState<UseUserRole>('ALL');
  const [searchOrg, setSearchOrg] = useState<string>('');

  const [page, setPage] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: '', value: '' }));
  const [selectedOrg, setSelectedOrg] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: 'All', value: 'ALL' }));
  const [selectedStatus, setSelectedStatus] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: 'All', value: 'ALL' }));

  const [statusOptions, setStatusOptions] = useState<SelectItem[]>([]);
  const [typeOptions, setTypeOptions] = useState<SelectItem[]>([]); // Orgs options

  const { allFilteredOrgs } = useOrgList({
    searchName: '',
    role: getRole(userRoles),
    searchType: 'ALL',
    searchStatus: 'ALL',
  });

  const {
    reloadList,
    allFilteredUsers
  } = useUsersList({
    searchName: search,
    role: getRole(userRoles),
    searchRole,
    searchStatus: selectedStatus.value?.value as UseUserStatus || 'ALL',
    notHealers: true,
    orgId: selectedOrg.value?.value || 'ALL',
    from: 'Superadmin',
  });

  const options = PAGE_SIZES.map((num) => ({
    value: `${num}`,
    label: `${num} results`,
  }))

  const optionsRole: SelectItem[] = [
    {
      label: 'All',
      value: 'ALL'
    },
    {
      label: 'Seekers',
      value: 'SEEKER'
    },
    {
      label: 'Staff',
      value: 'STAFF'
    },
    {
      label: 'Advisory',
      value: 'ADVISORY'
    },
    {
      label: 'Committee',
      value: 'COMMITTEE'
    }
  ]

  function handleCloseModalDelete(type: onHideType, username: string) {
    switch (type) {
      case 'discard':
        currentSuccess?.setModalsOff();
        break;

      case 'close':
        currentSuccess?.setModalsOff();
        break;

      case 'save':
        currentSuccess?.setLoadingModal(true);
        getDataSDK()
          .then((dataSDK: getDataSDKResponse) => {
            return dataSDK.sdk.Admin.deleteUser(username, dataSDK.token);
          })
          .then((results) => {
            if (results.statusCode === 201) {
              currentSuccess?.setSuccessData(true, true, t('toast_yoSuccessDelete'));
              currentSuccess?.setModalsOff();
            } else {
              currentSuccess?.setSuccessData(false, true, t('toast_anErrorOcurred'));
            }
          })
          .catch((error) => currentSuccess?.setSuccessData(false, true, t('toast_anErrorOcurred'), error))
          .finally(() => {
            reloadList()
            currentSuccess?.setLoadingModal(false);
          })
        break;

      default:
        break;
    }
  };

  const handleClickRow = (username: string) => {
    navigate(`../../../../../superadmin/seekers/${username}/view`)
  }

  const handleUpdate = (username: string) => {
    navigate(`../../../../../superadmin/seekers/${username}/update`)
  }

  const handleDelete = (username: string) => {
    currentSuccess?.setModalWarning(
      'DELETE',
      (type) => handleCloseModalDelete(type, username),
      'Do you want to delete this seeker?'
    );
  }

  useEffect(() => {
    dispatch(getUsersStatus());
  }, []);

  useEffect(() => {
    setStatusOptions([{ label: "All", value: "ALL" }, ...(userStatus || []).map((e: any) => ({
      label: capitalizeStatus(e),
      value: e,
    }))])
    setTypeOptions([{ label: "All", value: "ALL" }, ...(allFilteredOrgs || []).map((org) => ({
      label: org.name,
      value: org.id,
    }))])
  }, [userStatus, allFilteredOrgs]);

  return (
    <ContentOrganizations>
      <Title>Seekers</Title>

      <UserRoleSelector>
        {optionsRole.map((role) => (
          <UserRole onClick={() => setSearchRole(role.value as UseUserRole)}>
            {role.value === searchRole ?
              <BlankCircle><Point /></BlankCircle> : <BlankCircle />}
            {role.label}
          </UserRole>
        ))}
      </UserRoleSelector>

      <WrapperRow>
        <WrapperRow2>
          <SearchInput
            filteredItem={search}
            setFilteredItem={setSearch}
            border={'1px solid var(--simple-gray)'}
            placeholder={`Search by user ID`}
          />
        </WrapperRow2>
        <WrapperRow2>
          <Selector
            options={typeOptions}
            selected={selectedOrg}
            setSelected={setSelectedOrg}
            searchItem={searchOrg}
            setSearchItem={setSearchOrg as (data: string) => void}
            border={'1px solid var(--simple-gray)'}
            placeholder={`Organization`}
          />
        </WrapperRow2>
        <WrapperRow2>
          <Selector
            options={statusOptions}
            selected={selectedStatus}
            setSelected={setSelectedStatus}
            searchItem={searchStatus}
            setSearchItem={setSearchStatus as (data: string) => void}
            border={'1px solid var(--simple-gray)'}
            placeholder={`Status`}
          />
        </WrapperRow2>
        {/* <WrapperRow2>
          <LabelLittle>Viewing</LabelLittle>
          <Selector
            options={options}
            selected={page}
            setSelected={setPage}
            searchItem={searchPage}
            setSearchItem={setSearchPage}
            backgroundColor={'var(--t-blue)'}
            border={'0px'}
          />
        </WrapperRow2> */}
      </WrapperRow>

      <Scroll>
        <BlankTable
          headerColored
          border
          headers={['User ID', 'Roles', 'Organizations', 'City', 'Country', 'Status', 'Joined in']}
          items={allFilteredUsers.map(user => ({
            id: user.username,
            labels: [
              user.username,
              getRolesUsersView(user.roles || []).join(', '),
              (user.organizationsNames || []).join(', '),
              user.city || '',
              user.country || '',
              capitalize(user.status || ''),
              unixToDateFormatted(user.creationDate)
            ],
            all: user
          }))}
          statusInfo={{
            hasStatus: true,
            statusIndex: 5,
          }}
          actions={['UPDATE', 'DELETE']}
          actionsHandlers={{
            handleDelete,
            handleUpdate,
          }}
          maxWidth={'150px'}
          onClickRow={handleClickRow}
          cursor='pointer'
          backgroundColor={'white'}
        />
      </Scroll>
    </ContentOrganizations>
  );
}

const BlankCircle = styled.div`
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 2px solid var(--simple-gray);
    justify-content: center;
    align-items: center;
`;

const Point = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: var(--simple-gray);
`;

const UserRoleSelector = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-between;
    border: 1px solid var(--simple-gray);
    border-radius: 9px;
    width: 100%;
    padding: 20px;
`;

const UserRole = styled.div`
    font-family: 'Inter';
    line-height: 18px;
    font-size: 16px;
    cursor: pointer;
    width: fit-content;

    display: flex;
    gap: 10px;
`;

const LabelLittle = styled.h1`
  color: var(--t-blue);
  font-family: 'Inter';
  font-size: 16px;
  font-weight: normal;
`;

const WrapperRow = styled.div`
  display: flex;
  width: 100%;
  gap: 25px;
  align-items: flex-end;
`;

const WrapperRow2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  height: 40px;
  gap: 8px;
  max-width: 300px;
`;

const Scroll = styled.div`
  display: flex;
  width: 100%;
  overflow-y: scroll;
`;

const ContentOrganizations = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  align-items: flex-start;
  gap: 15px;
  flex-direction: column;
`;

export default Seekers;