import React from 'react';

const Terms = () => (
  <div style={{ overflow: 'scroll', height: 'calc(100vh - 120px)' }}>
    <div style={{padding: 16}}>
      <h1
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left",
          fontWeight: 700
        }}
      >
        HIGHEST VIBRATIONS TERMS OF SERVICE <span className="s1">(001-2403)</span>
      </h1>
      <p style={{ paddingTop: "13pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        The Services (as defined herein) are provided to you by Highest Vibtrations,
        Inc. (“us” or “Company”). Throughout the site, the terms “we”, “us” and
        “our” refer to The Company. The Company offers the services, including all
        information, tools and services available from this website to you, the
        user, conditioned upon your acceptance of all terms, conditions, policies
        and notices stated here.
      </p>
      <p
        style={{
          paddingTop: "14pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left"
        }}
      >
        By visiting our site, utilizing any communication services or tools or any
        other services provided by us, or by engaging in commerce with any other
        users of our site, you engage in our “Services” and you agree to be bound by
        the following terms and conditions (“Terms of Service”, “Terms”), including
        those additional terms and conditions and policies referenced herein and/or
        available by hyperlink. These Terms of Service apply to all users of this
        site and the Services.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        Please read these Terms of Service carefully before accessing or using our
        Services. By accessing or using any part of the Services, you agree to be
        bound by these Terms of Service. If you do not agree to all the terms and
        conditions of this agreement, then you may not access or use any Services.
        If these Terms of Service are considered an offer, acceptance is expressly
        limited to these Terms of Service.
      </p>
      <p
        style={{
          paddingTop: "14pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left"
        }}
      >
        Any new features or tools, which are added to, the current Services shall
        also be subject to the Terms of Service. You can review the most current
        version of the Terms of Service at any time on this page. We reserve the
        right to update, change or replace any part of these Terms of Service by
        posting updates and/or changes to our website. It is your responsibility to
        check this page periodically for changes. Your continued use of or access to
        the website following the posting of any changes constitutes acceptance of
        those changes.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        PLEASE CAREFULLY READ AND MAKE SURE YOU UNDERSTAND THE “ARBITRATION AND
        WAIVER OF CLASS ACTIONS” PROVISION SET FORTH IN THESE TERMS OF SERVICE, AS
        IT WILL REQUIRE YOU TO RESOLVE DISPUTES
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        WITH US ON AN INDIVIDUAL BASIS THROUGH FINAL AND BINDING ARBITRATION. BY
        ACCESSING OR USING OUR WEBSITE OR OUR SERVICES, YOU EXPRESSLY ACKNOWLEDGE
        THAT YOU HAVE READ, UNDERSTAND AND HAVE TAKEN TIME TO CONSIDER THE
        CONSEQUENCES OF THE ARBITRATION AND WAIVER OF CLASS ACTIONS PROVISION SET
        FORTH BELOW.
      </p>
      <h1
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "200%",
          textAlign: "left",
          fontWeight: 700
        }}
      >
        <u>TERMS OF USE</u><br/> SECTION 1 - TERMS
      </h1>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        By agreeing to these Terms of Service, you represent that you are at least
        the age of majority in your state or province of residence, or that you are
        the age of majority in your state or province of residence and you have
        given us your consent to allow any of your minor dependents to use the
        Services.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        You may not use our Services for any illegal or unauthorized purpose nor may
        you, in the use of the Services, violate any laws in your jurisdiction. WE
        EXPRESSLY DISCLAIM ANY LIABILITY FOR YOUR USAGE OF OUR SERVICES FOR ANY
        PURPOSES OR IN ANY MANNER NOT PERMITTED UNDER APPLICABLE LAW IN YOUR
        JURISDICTION OR IN ANY JURISDICTION WHOSE LAWS ARE APPLICABLE TO YOUR USAGE
        OF OUR SERVICES.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        A breach or violation of any of the Terms will result in an immediate
        termination of your access to and use of the Services. We expressly reserve
        the right to terminate provision of any Services as a result of your sharing
        any login or other access credentials regarding your use of the Services
        with any third parties except as we expressly agree to in writing.
      </p>
      <h1
        style={{
          paddingTop: "14pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left",
          fontWeight: 700
        }}
      >
        SECTION 2 - GENERAL CONDITIONS
      </h1>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
        We reserve the right to refuse service to anyone for any reason at any time
        in our sole, reasonable discretion. The headings used in this agreement are
        included for convenience only and will not limit or otherwise affect these
        Terms.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", fontWeight: 700 }}>
        SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION
      </h1>
      <p
        style={{
          paddingTop: "14pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left"
        }}
      >
        We do not warrant or guarantee the accuracy, completeness or currency of the
        information made available on in these Terms of Service. The material herein
        is provided for general information only and should not be relied upon or
        used as the sole basis for making decisions without consulting primary, more
        accurate, more complete or more timely sources of information. Any reliance
        on the material herein is at your own risk. We reserve the right to modify
        the contents herein at any time, but we have no obligation to update any
        information herein. You agree that it is your responsibility to monitor
        changes herein.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", fontWeight: 700 }}>
        SECTION 4 - MODIFICATIONS TO THE SERVICES AND PRICES
      </h1>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
        Prices, fees payable, and any other consideration required to be paid in
        connection with your usage of our Services are subject to change without
        notice.
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
        We reserve the right at any time to modify or discontinue the Services (or
        any part or content thereof) without notice at any time. We shall not be
        liable to you or to
      </p>
      <p
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left"
        }}
      >
        any third-party for any modification, price change, suspension or
        discontinuance of the Services.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", fontWeight: 700 }}>
        SECTION 5 - SERVICES
      </h1>
      <p
        style={{
          paddingTop: "14pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left"
        }}
      >
        We reserve the right to limit the sales of, or access to, our Services to
        any person, geographic region or jurisdiction. We may exercise this right on
        a case-by-case basis. We reserve the right to limit the services that we
        offer. All descriptions of Services are subject to change at any time
        without notice, at our sole discretion. We reserve the right to discontinue
        any Services at any time. Any offer for any Services made is void where
        prohibited. We do not warrant that the quality of any products, services,
        information, or other material purchased or obtained by you will meet your
        expectations, or that any errors in the Service will be corrected.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", fontWeight: 700 }}>
        SECTION 6 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS
      </h1>
      <p
        style={{
          paddingTop: "14pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left"
        }}
      >
        If, at our request, you send certain specific submissions or without a
        request from us you send creative ideas, suggestions, proposals, plans, or
        other materials, whether online, by email, by postal mail, or otherwise
        (collectively, 'comments'), you agree that we may, at any time, without
        restriction, edit, copy, publish, distribute, translate and otherwise use in
        any medium any comments that you send to us. We are and shall be under no
        obligation (a) to maintain any comments in confidence; (b) to pay
        compensation for any comments; or (c) to respond to any comments.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", fontWeight: 700 }}>
        SECTION 7 - PERSONAL INFORMATION
      </h1>
      <p
        style={{
          paddingTop: "14pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left"
        }}
      >
        Your submission of personal information in connection with our provision of
        the Services is governed by our Privacy Policy. Please review our Privacy
        Policy
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", fontWeight: 700 }}>
        SECTION 8 - ERRORS, INACCURACIES AND OMISSIONS
      </h1>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        Occasionally there may be information published by us that contains
        typographical errors, inaccuracies or omissions that may relate to product
        descriptions, pricing, promotions, offers, charges, and availability with
        respect to the Services. We reserve the right to correct any errors,
        inaccuracies or omissions, and to change or update information published by
        us at any time without prior notice. We undertake no obligation to update,
        amend or clarify information provided or published by us, including but not
        limited to, pricing information, except as required by law.
      </p>
      <h1
        style={{
          paddingTop: "14pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left",
          fontWeight: 700
        }}
      >
        SECTION 9 - PROHIBITED USES
      </h1>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        In addition to the other prohibitions set forth in these Terms, you are
        prohibited from using Services: (a) for any unlawful purpose; (b) to solicit
        others to perform or participate in any unlawful acts; (c) to violate any
        international, federal, provincial or state regulations, rules, laws, or
        local ordinances; (d) to infringe upon or violate
      </p>
      <p
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left"
        }}
      >
        our intellectual property rights or the intellectual property rights of
        others; (e) to harass, abuse, insult, harm, defame, slander, disparage,
        intimidate, or discriminate based on gender, sexual orientation, religion,
        ethnicity, race, age, national origin, or disability; (f) to submit false or
        misleading information; (g) to upload or transmit viruses or any other type
        of malicious code that will or may be used in any way that will affect the
        functionality or operation of the Services or of any related website, other
        websites, or the Internet; (h) to collect or track the personal information
        of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j)
        for any obscene or immoral purpose; or (k) to interfere with or circumvent
        the security features of the Services or any related website, other
        websites, or the Internet. We reserve the right to terminate your use of the
        Service or any related website for violating any of the prohibited uses.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", fontWeight: 700 }}>
        SECTION 10 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY
      </h1>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        WE DO NOT GUARANTEE, REPRESENT OR WARRANT THAT YOUR USE OF OUR SERVICES WILL
        BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE.
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        WE DO NOT WARRANT THAT THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
        SERVICE WILL BE ACCURATE OR RELIABLE.
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        YOU AGREE THAT FROM TIME TO TIME WE MAY REMOVE THE SERVICES FOR INDEFINITE
        PERIODS OF TIME OR CANCEL THE SERVICE AT ANY TIME, WITHOUT NOTICE TO YOU. WE
        EXPRESSLY DO NOT GUARANTEE THAT ANY ADVICE YOUR RECEIVE FROM ANY OTHER USERS
        OF OUR SERVICES OR OUR WEBSITE; OUR HOSTING OF ANY OFFERINGS OR PRODUCTS OF
        OTHER USERS OF OUR WEBSITE OR OUR SERVICES DOES NOT CONSTITUTE AN
        ENDORSEMENT OF SUCH OFFERINGS OR PRODUCTS, OR ANY MEDICAL OR OTHER ADVICE
        REGARDING SUCH OFFERINGS OR PRODUCTS.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        YOU EXPRESSLY AGREE THAT YOUR USE OF, OR INABILITY TO USE, THE SERVICE IS AT
        YOUR SOLE RISK. THE SERVICE AND ALL SERVICES DELIVERED TO YOU ARE (EXCEPT AS
        EXPRESSLY STATED BY US) PROVIDED 'AS IS' AND 'AS AVAILABLE' FOR YOUR USE,
        WITHOUT ANY REPRESENTATION, WARRANTIES OR CONDITIONS OF ANY KIND, EITHER
        EXPRESS OR IMPLIED, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF
        MERCHANTABILITY, MERCHANTABLE QUALITY, FITNESS FOR A PARTICULAR PURPOSE,
        TITLE, AND NON- INFRINGEMENT.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        IN NO CASE SHALL WE, OUR DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES, AGENTS,
        CONTRACTORS, INTERNS, SUPPLIERS, SERVICE PROVIDERS, LICENSORS, SUCCESSORS,
        OR ASSIGNS BE LIABLE FOR ANY INJURY, LOSS, CLAIM, OR ANY DIRECT, INDIRECT,
        INCIDENTAL, PUNITIVE, SPECIAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND,
        INCLUDING, BUT NOT LIMITED TO, LOST PROFITS, LOST REVENUE, LOST SAVINGS,
        LOSS OF DATA, REPLACEMENT COSTS, OR ANY SIMILAR DAMAGES, WHETHER BASED IN
        CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE,
        ARISING FROM YOUR USE OF ANY OF THE SERVICES, OR FOR ANY OTHER CLAIM RELATED
        IN ANY WAY TO YOUR USE OF
      </p>
      <p
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left"
        }}
      >
        THE SERVICE OR ANY PRODUCT, INCLUDING, BUT NOT LIMITED TO, ANY ERRORS OR
        OMISSIONS IN ANY CONTENT, OR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
        RESULT OF THE USE OF THE SERVICE OR ANY CONTENT (OR PRODUCT) POSTED,
        TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICE, EVEN IF ADVISED OF
        THEIR POSSIBILITY.
      </p>
      <p
        style={{
          paddingTop: "14pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left"
        }}
      >
        BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE
        LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH
        STATES OR JURISDICTIONS, OUR LIABILITY SHALL BE LIMITED TO THE MAXIMUM
        EXTENT PERMITTED BY LAW.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", fontWeight: 700 }}>
        SECTION 11 - INDEMNIFICATION
      </h1>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        You agree to indemnify, defend and hold harmless the Company and our parent,
        subsidiaries, affiliates, partners, officers, directors, agents,
        contractors, licensors, service providers, subcontractors, suppliers,
        interns, employees, successors and assigns from any claim or demand,
        including reasonable attorneys’ fees, made by
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
        any third-party due to or arising out of your breach of these Terms of
        Service or the documents they incorporate by reference, or your violation of
        any law or the rights of a third-party.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", fontWeight: 700 }}>
        SECTION 12 - SEVERABILITY
      </h1>
      <p
        style={{
          paddingTop: "14pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left"
        }}
      >
        In the event that any provision of these Terms is determined to be unlawful,
        void or unenforceable, such provision shall nonetheless be enforceable to
        the fullest extent permitted by applicable law, and the unenforceable
        portion shall be deemed to be severed from these Terms, such determination
        shall not affect the validity and enforceability of any other remaining
        provisions.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", fontWeight: 700 }}>
        SECTION 13 - TERMINATION
      </h1>
      <p
        style={{
          paddingTop: "14pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left"
        }}
      >
        The obligations and liabilities of you and us incurred prior to the
        termination date shall survive the termination of this agreement for all
        purposes.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        These Terms are effective unless and until terminated by either you or us.
        You may terminate these Terms at any time by notifying us that you no longer
        wish to use our Services.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        If in our sole judgment you fail, or we suspect that you have failed, to
        comply with any term or provision of these Terms, we also may terminate this
        agreement at any time without notice and you will remain liable for all
        amounts due up to and including the date of termination; and/or accordingly
        may deny you access to our Services (or any part thereof).
      </p>
      <h1
        style={{
          paddingTop: "14pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left",
          fontWeight: 700
        }}
      >
        SECTION 14 - WAIVER
      </h1>
      <p
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left"
        }}
      >
        No waiver by us of any term, condition or provision set out in these Terms
        of Service shall be deemed a further or continuing waiver of such term,
        condition or provision or a waiver of any other term, condition or
        provision, and our failure to exercise or enforce or delay in exercising and
        enforcing any right or provision of these Terms of Service shall not
        constitute a waiver of such right or provision.
      </p>
      <h1
        style={{
          paddingTop: "14pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left",
          fontWeight: 700
        }}
      >
        SECTION 15 - GOVERNING LAW
      </h1>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
        These Terms shall be governed by and construed in accordance with the laws
        of the State of Florida, U.S.A without giving effect to any choice or
        conflict of law provision or rule (whether of the State of Florida, U.S.A or
        any other jurisdiction).
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", fontWeight: 700 }}>
        SECTION 16 - ARBITRATION AND WAIVER OF CLASS ACTIONS
      </h1>
      <p
        style={{
          paddingTop: "14pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left"
        }}
      >
        YOU AND THE COMPANY AGREE THAT ANY DISPUTE, CLAIM OR CONTROVERSY ARISING OUT
        OF OR RELATING TO THIS AGREEMENT OR THE BREACH, TERMINATION, ENFORCEMENT,
        INTERPRETATION OR VALIDITY THEREOF, INCLUDING THE DETERMINATION OF THE SCOPE
        OR APPLICABILITY OF THIS AGREEMENT TO ARBITRATE, SHALL BE DETERMINED BY
        ARBITRATION BEFORE ONE ARBITRATOR. THE ARBITRATION SHALL BE ADMINISTERED BY
        JAMS PURSUANT TO ITS COMPREHENSIVE ARBITRATION RULES AND PROCEDURES AND IN
        ACCORDANCE WITH THE EXPEDITED PROCEDURES IN THOSE RULES.
      </p>
      <p
        style={{
          paddingTop: "14pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left"
        }}
      >
        Judgment on the award of any such arbitration may be entered in any court
        having jurisdiction. This clause shall not prevent parties from seeking
        provisional remedies in aid of arbitration from a court of appropriate
        jurisdiction.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        YOU AND THE COMPANY AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY
        IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER
        IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. FURTHER, UNLESS BOTH
        YOU AND THE COMPANY AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE
        THAN ONE PERSON'S CLAIMS WITH YOUR CLAIMS, AND MAY NOT OTHERWISE PRESIDE
        OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        If a court refuses to enforce the waiver of class-wide arbitration, this
        entire arbitration provision shall be unenforceable and any claims brought
        on behalf of a putative class will proceed in court.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        IF FOR ANY REASON A CLAIM PROCEEDS IN COURT RATHER THAN IN ARBITRATION YOU
        EXPRESSLY WAIVE ANY RIGHT TO A JURY TRIAL, AND ACKNOWLEDGE THIS WAIVER.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        You and the Company shall maintain the confidential nature of any
        arbitration proceeding and any award, including the arbitration hearing,
        except as may be necessary to prepare for or conduct the arbitration hearing
        on the merits, or except
      </p>
      <p
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left"
        }}
      >
        as may be necessary in connection with a court application for a preliminary
        remedy, a judicial challenge to an award or its enforcement, or unless
        otherwise required by law or judicial decision.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        The arbitrator will conduct hearings, if any, by teleconference or
        videoconference, rather than by personal appearances, unless the arbitrator
        determines upon request by you or by us that an in-person hearing is
        appropriate. Any in-person appearances will be held at a location which is
        reasonably convenient to both parties with due consideration of their
        ability to travel and other pertinent circumstances. If the parties are
        unable to agree on a location, such determination should be made by JAMS or
        by the arbitrator. The arbitrator’s decision will follow the terms of these
        Terms and will be final and binding.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        In any arbitration arising out of or related to this agreement, there shall
        be no interrogatories or requests to admit.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        IN ANY ARBITRATION ARISING OUT OF OR RELATED TO THIS AGREEMENT, THE
        ARBITRATOR(S) ARE NOT EMPOWERED TO AWARD PUNITIVE OR EXEMPLARY DAMAGES,
        EXCEPT WHERE PERMITTED BY STATUTE, AND THE PARTIES WAIVE ANY RIGHT TO
        RECOVER ANY SUCH DAMAGES. THE ARBITRATOR(S) MAY NOT AWARD ANY INCIDENTAL,
        INDIRECT OR CONSEQUENTIAL DAMAGES, INCLUDING DAMAGES FOR LOST PROFITS.
      </p>
      <p
        style={{
          paddingTop: "14pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left"
        }}
      >
        As stated in these Terms, Florida law governs this entire agreement, but the
        parties acknowledge that this agreement evidences a transaction involving
        interstate commerce, and that notwithstanding these Terms, any arbitration
        conducted pursuant to the terms of this agreement shall be governed by the
        Federal Arbitration Act.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", fontWeight: 700 }}>
        SECTION 17 - ENTIRE AGREEMENT
      </h1>
      <p
        style={{
          paddingTop: "14pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left"
        }}
      >
        These Terms and any policies or operating rules posted by us on this site or
        in respect to the Services constitutes the entire agreement and
        understanding between you and us and govern your use of the Services,
        superseding any prior or contemporaneous agreements, communications and
        proposals, whether oral or written, between you and us (including, but not
        limited to, any prior versions of the Terms).
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        Any ambiguities in the interpretation of these Terms shall not be construed
        against the drafting party.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", fontWeight: 700 }}>
        SECTION 18 - CONTACT INFORMATION
      </h1>
      <p
        style={{
          paddingTop: "14pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left"
        }}
      >
        <a href="mailto:privacytos@vibe.love" className="s3" target="_blank">
          Questions about the Terms of Service should be sent to us at{" "}
        </a>
        <a href="mailto:privacytos@vibe.love" className="a" target="_blank">
          privacytos@vibe.love
        </a>
        <a href="mailto:privacytos@vibe.love" className="s3" target="_blank">
          .
        </a>
      </p>

    </div>
  </div>
);

export default Terms;
