
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { RootState } from "../../../store/store";
import ButtonSpinner from "../../BaseComponents/ButtonSpinner";
import { ContextSuccess } from "../../../Context";
import { capitalize, cleanHTML, cleanStyleHTML } from "../../../helpers/letterHelper";
import { unixToDateFormatted } from "../../../helpers/dateHelper";
import { IUserGradeResponse, OrgJoined } from "@vibe/sdk/interfaces/Admin/User";
import { getUserInfoProfile } from "../../../store/actions/userActions";
import { getRolesUsersView } from "../../../helpers/roleHelper";
import { formatGender } from "../../../helpers/userHelper";
import BlankTable from "../../BaseComponents/BlankTable";
import { ContentFlexAdmin } from "../Healers/HealersView";
import Breadcrumb from "../../BaseComponents/Breadcrumb";
import { FlexColumnWrapper, FlexRowWrapper } from "../Healers/HealerDescription";
import AddPhotoPencil from "../../BaseComponents/ProfilePhoto/AddPhotoPencil";
import { BaseFieldState, getFieldState } from "../../BaseComponents/utils";
import { LogoInterface } from "../../signupsetuphealer/Introduction";
import StatusBudged, { StatusType } from "../../BaseComponents/Healers/StatusBudged";

const SeekersView = () => {
    const { id = '' } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userProfile: IUserGradeResponse = useSelector((state: RootState) => state.getUser.userProfile);

    const [logoOrg, setLogoOrg] = useState<BaseFieldState<LogoInterface>>(getFieldState({ file: '', fileLink: '' }));
    const [seeMore, setSeeMore] = useState<boolean>(false);

    const getRolesOrg = (orgId: string): string => {
        const orgIndex = userProfile.organizations.findIndex((o) => o === orgId);

        if (orgIndex > -1 && userProfile.rolesByOrg.length >= orgIndex) {
            return getRolesUsersView(userProfile.rolesByOrg[orgIndex]).join(', ');
        }

        return '';
    };

    const reloadUser = () => {
        dispatch(getUserInfoProfile(id, 'OA'));
    };

    useEffect(() => {
        setLogoOrg(getFieldState({
            file: userProfile?.avatar || '',
            fileLink: userProfile?.avatar || ''
        }));

    }, [userProfile]);

    useEffect(() => {
        reloadUser();
    }, []);

    return (
        <ContentFlexAdmin>
            <HeaderAdmin>
                <Breadcrumb
                    home={() => navigate('../../../../../superadmin')}
                    crumbs={[{
                        navigate: () => navigate('../../../../../superadmin/seekers'),
                        label: 'Healers'
                    }, {
                        navigate: () => { },
                        label: `${userProfile?.firstname} ${userProfile?.lastname}` || 'View Healer'
                    }]} />
                <Button onClick={() => navigate(`../../../../../superadmin/seekers/${id}/update`)}>Update</Button>
            </HeaderAdmin>
            <FlexRowWrapper>
                <AddPhotoPencil
                    logo={logoOrg}
                    setLogo={setLogoOrg}
                    hidePen
                />
                <FlexColumnWrapper>
                    <Title>{`${userProfile?.firstname} ${userProfile?.lastname}`}</Title>
                    <DivOrgStatement>
                        <PlaceInfoStatement
                            theme={{ main: seeMore ? 'activeMore' : '' }}
                            dangerouslySetInnerHTML={{ __html: cleanStyleHTML(userProfile?.biography as string || '') }}
                        />
                        {userProfile?.biography &&
                            <ReadMore onClick={() => setSeeMore(!seeMore)}>{seeMore ? 'Read Less' : 'Read More'}</ReadMore>
                        }
                    </DivOrgStatement>
                </FlexColumnWrapper>
            </FlexRowWrapper>

            <FlexRowWrapper top>
                <FlexColumnWrapper>
                    <Title>Seeker Entrance</Title>
                    <FlexRowWrapper>
                        <Subtitle>ID</Subtitle>
                        {userProfile?.username}
                    </FlexRowWrapper>
                    <FlexRowWrapper>
                        <Subtitle>Status</Subtitle>
                        <StatusBudged status={userProfile?.status as StatusType || ''} />
                    </FlexRowWrapper>
                    <FlexRowWrapper>
                        <Subtitle>Created on</Subtitle>
                        {unixToDateFormatted(userProfile?.creationDate) || ''}
                    </FlexRowWrapper>
                    <FlexRowWrapper>
                        <Subtitle>Updated on</Subtitle>
                        {unixToDateFormatted(userProfile?.creationDate) || '' /*@todo Backend send updateDate */}
                    </FlexRowWrapper>
                </FlexColumnWrapper>

                <FlexColumnWrapper>
                    <Title>Seeker Information</Title>
                    <FlexRowWrapper>
                        <Subtitle>Gender</Subtitle>
                        {formatGender(userProfile.gender || '')}
                    </FlexRowWrapper>
                    <FlexRowWrapper>
                        <Subtitle>Country</Subtitle>
                        {userProfile?.country}
                    </FlexRowWrapper>
                    <FlexRowWrapper>
                        <Subtitle>State</Subtitle>
                        {userProfile?.state}
                    </FlexRowWrapper>
                    <FlexRowWrapper>
                        <Subtitle>City</Subtitle>
                        {userProfile?.city}
                    </FlexRowWrapper>
                    <FlexRowWrapper>
                        <Subtitle>Zip Code</Subtitle>
                        {userProfile?.zipcode}
                    </FlexRowWrapper>
                </FlexColumnWrapper>
            </FlexRowWrapper>

            <LabelTitle>Belongs to</LabelTitle>
            <WrapperTable>
                <BlankTable
                    backgroundColor={'white'}
                    headers={[
                        'Organization',
                        'Role',
                        'Date Joined',
                        'Date Left',
                    ]}
                    items={(userProfile.joined || []).map((org) => ({
                        id: org.orgId,
                        labels: [
                            org.orgName,
                            getRolesOrg(org.orgId),
                            unixToDateFormatted(org.dateJoined),
                            '', /*@todo */
                        ]
                    }))}
                    onClickRow={(orgID: string) => navigate(`../../../../../superadmin/healers/${orgID}/view`)}
                    cursor={'pointer'}
                />
            </WrapperTable>
        </ContentFlexAdmin>
    )
};

const Title = styled.div`
    font-weight: 600;
`;

const Subtitle = styled.div`
    color: var(--simple-gray);
`;

const LabelTitle = styled.span`
    color: var(--t-blue);
    font-family: 'Inter-bold';
    font-size: 24px;
    font-weight: bold;
    width: 100%;
    text-align: left;
`;

const WrapperInfo = styled.div`
    display: grid;
    width: 100%;

    color: var(--t-blue);
    font-family: 'Inter-bold';
    font-size: 16px;
    font-weight: bold;

    grid-template-columns: 0.5fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    justify-items: flex-start;
    align-items: center;
`;

const PlaceInfo = styled.div`
    align-items: center;
    display: flex;
    height: 39px;
    width: 100%;

    font-family: 'Inter';
    font-size: 16px;
    font-weight: normal;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    text-align: left;
`;

const DivOrgStatement = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const ReadMore = styled.div`
    font-family: 'Inter-Bold';
    font-size: 14px;
    line-height: 17px;
    color: var(--absolute-zero);
    text-align: left;
    cursor: pointer;
`

const PlaceInfoStatement = styled(PlaceInfo).attrs(
    props => ({
        className: props.theme.main,
    })
)`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    height: 100%;
    min-height: 39px;
    max-height: 111px;

    overflow: scroll;
    white-space: normal;
    line-height: 22px;

    &.activeMore{
        min-height: auto;
        max-height: max-content;
        display: block;
    }

`;

const WrapperTable = styled.div`
    display: flex;
    width: 100%;
    grid-area: 4 / 1 / 5 / 3;
    height: fit-content;
`;

const HeaderAdmin = styled.div`
    display: flex;
    justify-content: space-between;
    color: var(--t-blue);
    font-family: 'Inter-bold';
    font-size: 24px;
    font-weight: bold;
    align-items: center;
    align-items: flex-start;

    width: 100%;
`;

const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100px;
    min-width: 100px;
    height: 32px;
    padding: 10px 20px;
    border-radius: 11px;
    background-color: var(--simple-blue-dark);
    border: 0;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: white;

    cursor: pointer;
`;

export default SeekersView;
