import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { IconClicker } from "../HeaderHome/styles";
import MenuHover from "./MenuHover";
import {
    HeaderMenuHome, LogoHeaderMenuHome, OptionMenuHome, OptionsMenuHome, WrapperMenuHome,
    IconArrow, FooterMenu, SocialMedia, IconMedia, LinksPPTC, LinkP, TextC
} from "./styles";

const MenuHome = ({
    show,
    hide,
}: {
    show: boolean
    hide: () => void
}) => {
    const navigate = useNavigate();
    const handleOption = (route: string) => {
        navigate(route)
        hide();
    }
    const getImageMedia = (social: string) => {
        switch (social) {
            case 'Facebook':
                return '../facebook-f.svg';
            case 'YouTube':
                return '../youtube.svg';
            case 'Instagram':
                return '../instagram.svg';
            case 'Twitter':
                return '../twitter.svg';
            case 'LinkedIn':
                return '../linkedin.svg';
            case 'Pinterest':
                return '../pinterest.svg';
            case 'Snapchat':
                return '../snapchat.svg';
            case 'TikTok':
                return '../tiktokicon.svg';
            case 'Zaahah':
                return '../Zaahah.svg';
            default:
                return '';
        }
    }
    return (
        <WrapperMenuHome active={show} theme={{ main: show ? 'menuActive' : 'menuInactive' }}>
            <HeaderMenuHome>
                <LogoHeaderMenuHome onClick={() => handleOption('../../../../../')} />
                <IconClicker
                    icon="times"
                    size="2x"
                    color="white"
                    onClick={hide}
                />
            </HeaderMenuHome>
            <OptionsMenuHome>
                <OptionMenuHome onClick={() => handleOption('../../../../../about')} >
                    <IconArrow
                        icon="arrow-right"
                        size="lg"
                        color="var(--content-tertiary)"
                    />
                    <MenuHover title={'About Vibe'} />
                </OptionMenuHome>
                <OptionMenuHome onClick={() => handleOption('../../../../../advisors')} >
                    <IconArrow
                        icon="arrow-right"
                        size="lg"
                        color="var(--content-tertiary)"
                    />
                    <MenuHover title={'Team & Advisors'} />

                </OptionMenuHome>
                <OptionMenuHome onClick={() => handleOption('../../../../../pricing')} >
                    <IconArrow
                        icon="arrow-right"
                        size="lg"
                        color="var(--content-tertiary)"
                    />
                    <MenuHover title={'Pricing'} />
                </OptionMenuHome>
                <OptionMenuHome onClick={() => handleOption('../../../../../faq')} >
                    <IconArrow
                        icon="arrow-right"
                        size="lg"
                        color="var(--content-tertiary)"
                    />
                    <MenuHover title={'FAQ'} />

                </OptionMenuHome>
                <OptionMenuHome onClick={() => handleOption('../../../../../contact')} >
                    <IconArrow
                        icon="arrow-right"
                        size="lg"
                        color="var(--content-tertiary)"
                    />
                    <MenuHover title={'Contact Us'} />
                </OptionMenuHome>
            </OptionsMenuHome>
            <FooterMenu>
                <SocialMedia>
                    <IconMedia src={getImageMedia('Instagram')} />
                    <IconMedia src={getImageMedia('TikTok')} />
                    <IconMedia src={getImageMedia('Twitter')} />
                </SocialMedia>
                <LinksPPTC>
                    <LinkP>Policy & Privacy</LinkP>
                    |
                    <LinkP>Terms & Conditions</LinkP>
                </LinksPPTC>
                <TextC>
                    Copyright 2023, Vibe
                </TextC>
            </FooterMenu>
        </WrapperMenuHome>
    )
}


export default MenuHome;