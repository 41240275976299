import styled from "styled-components";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BlankTable from "../BlankTable";
import IHS from "@vibe/sdk/interfaces/Modalities/hservices/HS";
import Filter, { IModFilter, MockModFilter } from "../Filter";

export type methodDelivery = 'InPerson' | 'Online' | 'Combined';

export interface ModMapped {
    name: string;
    delivery: methodDelivery[];
    id: string;
    service: IHS;
}

export const MockModMapped: ModMapped = {
    name: '',
    delivery: [],
    id: '',
    service: {
        id: '',
        username: '',
        name: '',
        description: '',
        delivery: [],
        createdAt: '',
    }
}

const ModalitiesDisplay = ({
    modalities,
    openModal,
}: {
    modalities: ModMapped[],
    openModal: (service: ModMapped) => void
}) => {

    const [showAll, setShowAll] = useState<boolean>(false);
    const [modFilter, setModFilter] = useState<IModFilter>({ ...MockModFilter });
    const [modalitiesFiltered, setModalitiesFiltered] = useState<ModMapped[]>([]);

    useEffect(() => setModalitiesFiltered(
        modalities.filter((mod: ModMapped) =>
            (!modFilter.benID || (mod.service.BEIds || []).includes(modFilter.benID)) &&
            (!modFilter.taID || (mod.service.TAIds || []).includes(modFilter.taID)) &&
            (!modFilter.delivery || getDeliveryFilter(mod.delivery || []).includes(modFilter.delivery))
        ))
        , [modFilter, modalities])

    const getDelivery = (delivery: methodDelivery[]): string => {
        if (delivery.includes('InPerson') && delivery.includes('Online')) return 'Online & in person'
        if (delivery.includes('Combined')) return 'Online & in person'
        if (delivery.includes('InPerson')) return 'In person'
        if (delivery.includes('Online')) return 'Online'
        return ''
    }

    const getDeliveryFilter = (delivery: methodDelivery[]): string => {
        if (delivery.includes('InPerson') && delivery.includes('Online')) return 'mixed'
        if (delivery.includes('Combined')) return 'mixed'
        if (delivery.includes('InPerson')) return 'inPerson'
        if (delivery.includes('Online')) return 'online'
        return ''
    }

    const getModalities = () => {
        const result = [];

        for (let i = 0; i < modalities.length; i++) {
            const mod = modalities[i];
            result.push(
                <Item>
                    <TitleItem>{mod.name}</TitleItem>
                    <InfoYear>{getDelivery(mod.delivery)}</InfoYear>
                </Item>
            );

            if (i === 4 && modalities.length > 5) break;
        }
        return result;
    }

    const handleClick = (id: string) => {
        const service = modalities.find(mod => mod.id === id);
        if (service) openModal(service);
    }

    return (
        <WrapperMod>
            <TitleCard>
                Modalities & Services
                <Filter from={'PROFILE'} type={'MOD'} modFilter={modFilter} setModFilter={setModFilter} areServices />
            </TitleCard>
            <Items>
                <BlankTable
                    maxWidthField={'120px'}
                    backgroundColor={'white'}
                    headerColored
                    headers={[
                        'Modality',
                        'Delivery',
                    ]}
                    items={(showAll ? modalitiesFiltered : modalitiesFiltered.slice(0, 3)).map((mod) => ({
                        id: mod.id,
                        labels: [
                            mod.name,
                            getDelivery(mod.delivery),
                        ]
                    }))}
                    onClickRow={handleClick}
                    cursor={'pointer'}
                    tooltip={[1]}
                    linkLine={[0]}
                />
            </Items>
            {(modalitiesFiltered.length > 5) && <ReadMore onClick={() => setShowAll(!showAll)}>{showAll ? 'See Less' : 'See All'}</ReadMore>}
        </WrapperMod>
    )
}

const ReadMore = styled.div`
    font-family: 'Inter-Bold';
    font-size: 14px;
    line-height: 17px;
    color: var(--absolute-zero);
    text-align: left;
    cursor: pointer;
    padding: 0px 15px 10px;
`

const Tooltip = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    width: 400px;
    background-color: var(--simple-blue-dark-2);
    padding: 8px 0;
    border-radius: 8px;
    color: white;
    margin-right: 12px;
    position: relative;

    &::before{
        content: "";
        width: 0;
        height: 0;
        border-left: 6px solid var(--simple-blue-dark-2);
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        position: absolute;
        right: -6px;
        top: 10px;
    }

`

const InfoYear = styled.span`
    font-family: 'Inter';
    font-size: 12px; 
    font-weight: 400;
`

const WrapperMod = styled.div`
    width: 100%;
    padding: 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const TabsMod = styled.div`
    display: flex;
    gap: 12px;
`

const Items = styled.div`
    display: flex;
    gap: 4px;
    overflow: hidden;
`
const Item = styled.div`
    padding: 5px 10px;
    background: #E5DCD0;
    border-radius: 16px;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    line-height: normal;
    gap: 1px;
    max-width: 150px;
`;

const TitleItem = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    justify-content: flex-start;
    text-align: left;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    margin-right: 7px;
    cursor: pointer;
`;

const TitleCard = styled.div`
    font-family: 'Inter-Bold';
    font-size: 16px;
    line-height: 130%;
    color: #2E2623;
    text-align: left;
    display: flex;
    justify-content: space-between;
    padding: 15px 15px 0px;
`

const Options = styled.div`
    position: absolute;
    background-color: white;
    padding: 7px 15px 15px 15px;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    top: 44px;
    -webkit-box-shadow: 0px 22px 13px -19px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 22px 13px -19px rgba(0,0,0,0.75);
    box-shadow: 0px 22px 13px -19px rgba(0,0,0,0.75);
    z-index: 1;
`

const Option = styled.div`
    padding: 9px 11px;
    border: 1px solid #bfbfbf;
    border-radius: 8px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`

const OptionsFilter = styled.div`
    position: absolute;
    background-color: white;
    width: calc(100% + 2px);
    z-index: 1;
    left: -1px;
    top: 33px;
    border: 1px solid #bfbfbf;
    padding: 7px 0px;
    border-radius: 0 0 9px 9px;
`

const OptionFilter = styled.div`
    font-family: 'Inter';
    padding: 8px 15px;
    cursor: pointer;
    &:hover{
        background-color: #ebe8e6;
    }
`

const Tab = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    
    padding: 2px 0; 
    font-size: 14px;  
    color: #7A7978;
    cursor: pointer;
    position: relative;

    &.active{
        font-family: 'Inter-Bold';
        border-bottom: 3px solid orange;
        color: black;
    }

    &.inactive{
        font-family: 'Inter';
        border-bottom: 0px solid orange;
    }
`

export default ModalitiesDisplay;