import { useContext, useEffect, useMemo, useState } from "react";
import { Backend, MicroServices, getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import INT from "../../interfaces/Interests/INT/INT";
import { INTAssignedComplete, INTList, Interest } from "@vibe/sdk/dist/interfaces/Connections/newInterests";
import { sortAlphabeticallyOnProperty } from "../../containers/Signup/SignupHealerInd";
import { ContextSuccess } from "../../Context";

export const MockIntList: INTList = {
    id: '',
    name: '',
    type: 'CSP',
    countries: [],
    interests: [],
}

const useInterestsSA = ({
    searchTerm,
    listId,
}: {
    searchTerm: string
    listId: string
}) => {

    const [interests, setInterests] = useState<Interest[]>([]);
    const [interFiltered, setInterFiltered] = useState<Interest[]>([]);
    const [interByList, setInterByList] = useState<INTAssignedComplete[]>([]);
    const [intListInfo, setIntListInfo] = useState<INTList>({...MockIntList});

    const searchInt = useMemo(() => searchTerm.toLowerCase(), [searchTerm]);
    const currentSuccess = useContext(ContextSuccess);

    const reloadInterests = () => {
        if(listId !== '0'){
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Connections.getINTList(listId,dataSDK.token);
                })
                .then((results) => {
                    if (results.statusCode === 200) {
                        const response = results.body as INTList;
                        if (response.interests){
                            setInterByList(response.interests);
                            setIntListInfo(response);
                        }
                        else{
                            setInterByList([]);
                            setIntListInfo({...MockIntList});
                        }
                    }
                    else{
                        setInterByList([]);
                        setIntListInfo({...MockIntList});
                        currentSuccess?.setSuccessData(false, true, `Error getting info!`, 'There was an error getting interest list information.');
                    }
                });
        }
        else setInterByList([]);

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Connections.getAllINT(dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) {
                    const response = results.body;
                    if (Array.isArray(response)) setInterests(response);
                    return;
                }
            });
    }

    useEffect(() => {
        if (!searchInt || searchInt === '') {
            setInterFiltered((sortAlphabeticallyOnProperty(interests, 'name') as Interest[]));
            return;
        }
        setInterFiltered(
            (sortAlphabeticallyOnProperty(interests, 'name') as Interest[])
                .filter(i => i.name.toLowerCase().includes(searchInt))
        );
    }, [searchInt, interests]);

    useEffect(() => reloadInterests(), []);
    useEffect(() => reloadInterests(), [listId]);

    return {
        interFiltered,
        interByList,
        reloadInterests,
        intListInfo,
    }
}

export default useInterestsSA;