import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import "./styles.css";
import ClubFilterBar from "../../components/ClubFilterBar";
import ClubRow from "../../components/ClubRow";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useClubsList, { getRole } from "../../hooks/Clubs/useClubsList";
import { ClubType } from "../../interfaces/club/ClubList";
import { IClubExtended } from "@vibe/sdk/interfaces/Club/ClubInterface";
import styled from "styled-components";
import { sortAlphabeticallyOnProperty } from "../../containers/Signup/SignupHealerInd";
import useClubsBulletin from "../../hooks/Clubs/useClubsBulletin";
import ModalCreateEvents from "../Events/ModalCreateEvents";
import Filter, { IComFilter, MockComFilter } from "../BaseComponents/Filter";

function Index() {
  const location = useLocation();

  const userRoles = useSelector((state: RootState) => state.getUser.userRoles);

  const [screen, setScreen] = useState<'Teams' | 'Communities' | 'Pending'>("Teams");
  const [ordSelected, setOrdSelected] = useState<'DESC' | 'ASC'>("ASC");
  const [orderField, setOrderField] = useState("name");

  const [showModalCreateEvent, setShowModalCreateEvent] = useState<boolean>(false)

  const [comFilter, setComFilter] = useState<IComFilter>({ ...MockComFilter });
  const [comsFiltered, setComsFiltered] = useState<IClubExtended[]>([]);

  const {
    clubs,
    handleChangeTab,
    handleChangeSearchTerm,
  } = useClubsBulletin();

  function handleTab(screen: 'Teams' | 'Communities' | 'Pending') {
    setScreen(screen);
    handleChangeTab(screen);
  }

  const onHideCreateModal = () => {
    setShowModalCreateEvent(false);
  }

  const handleReload = () => { }

  useEffect(() => {
    handleChangeTab(screen);
  }, [])

  useEffect(() => {
    setComsFiltered(sortAlphabeticallyOnProperty(clubs, orderField, ordSelected).filter(
      (com: IClubExtended) =>
        (!comFilter.orgID || com.org_id === comFilter.orgID) &&
        (!comFilter.modID || (com.modalitiesIds || []).includes(comFilter.modID))));
  }, [clubs, comFilter, orderField, ordSelected])

  return (
    <WrapClubs>
      {(userRoles.includes('SUPER')) ? (
        <TabsClubs>
          <div
            className={
              screen === "Teams"
                ? "section-column-selected pointer"
                : "section-column pointer"
            }
            id="left-rad"
            onClick={() => handleTab("Teams")}
          >
            <div
              style={{
                backgroundColor:
                  /* Tara new Colors */
                  screen === "Teams" ? "var(--t-blue)" : "transparent",
                color:
                  /* Tara new Colors */
                  screen !== "Teams" ? "var(--t-blue)" : "white",
              }}
              className={"section-label robotocondensed-normal-white-16px"}
            >
              Teams
            </div>
          </div>
          <div
            className={
              screen === "Communities"
                ? "section-column-selected pointer"
                : "section-column pointer"
            }
            id="right-rad"
            onClick={() => handleTab("Communities")}
          >
            <div
              style={{
                backgroundColor:
                  /* Tara new Colors */
                  screen === "Communities" ? "var(--t-blue)" : "transparent",
                color:
                  /* Tara new Colors */
                  screen !== "Communities" ? "var(--t-blue)" : "white",
              }}
              className={"section-label robotocondensed-normal-white-16px"}
            >
              Communities
            </div>
          </div>
        </TabsClubs>
      ) : (
        <TabsClubs>
          <div
            className={
              screen === "Teams"
                ? "section-column-selected pointer"
                : "section-column pointer"
            }
            id="left-rad"
            onClick={() => handleTab("Teams")}
          >
            <div
              style={{
                backgroundColor:
                  /* Tara new Colors */
                  screen === "Teams" ? "var(--t-blue)" : "transparent",
                color:
                  /* Tara new Colors */
                  screen !== "Teams" ? "var(--t-blue)" : "white",
              }}
              className={"section-label robotocondensed-normal-white-16px"}
            >
              Teams
            </div>
          </div>
          <div
            className={
              screen === "Communities"
                ? "section-column-selected pointer"
                : "section-column pointer"
            }
            onClick={() => handleTab("Communities")}
          >
            <div
              style={{
                backgroundColor:
                  /* Tara new Colors */
                  screen === "Communities" ? "var(--t-blue)" : "transparent",
                color:
                  /* Tara new Colors */
                  screen !== "Communities" ? "var(--t-blue)" : "white",
              }}
              className={"section-label robotocondensed-normal-white-16px"}
            >
              Communities
            </div>
          </div>
          <div
            className={
              screen === "Pending"
                ? "section-column-selected pointer"
                : "section-column pointer"
            }
            id="right-rad"
            onClick={() => handleTab("Pending")}
          >
            <div
              style={{
                backgroundColor:
                  /* Tara new Colors */
                  screen === "Pending" ? "var(--t-blue)" : "transparent",
                color:
                  /* Tara new Colors */
                  screen !== "Pending" ? "var(--t-blue)" : "white",
              }}
              className={"section-label robotocondensed-normal-white-16px"}
            >
              Pending
            </div>
          </div>
        </TabsClubs>
      )}




      <Row>
        <ClubFilterBar
          styles="Left"
          inputSearch={handleChangeSearchTerm}
          selectOrder={ordSelected}
          setSelectOrder={setOrdSelected}
          orderField={setOrderField}
        />
        {/* <FiltersEvents
          filterActive={tabActive}
          setFilterActive={setTabActive}
          startDate={pickedDate}
          setStartDate={setPickedDate}
          setFilteredModalityID={setModalityId}
          setFilterDelivery={setDelivery}
          modalities={modalities}
        /> */}
        <Filter from={'BULLETIN'} type={'COM'} comFilter={comFilter} setComFilter={setComFilter} />
      </Row>

      <ContainerList>
        <ListClubs>
          {comsFiltered.map((club: any) => (
            <ClubRow
              infoClub={club}
              screen={screen}
            />
          ))}
          {((userRoles.includes('HEALER') ||
            userRoles.includes("SECOND#ADVISOR"))) && (
              <ButtonCreate onClick={() => setShowModalCreateEvent(true)}>Create an event</ButtonCreate>
            )}
        </ListClubs>
      </ContainerList>
      <ModalCreateEvents
        showModal={showModalCreateEvent}
        setShowModal={setShowModalCreateEvent}
        reloadEvents={() => handleReload()}
      />
    </WrapClubs>

  );
}

export default Index;

const Row = styled.div`
  display: flex;
  width: 100%;
  height: 35px;
  gap: 10px;
  align-items: center;
`;

const ButtonCreate = styled.div`
  width: calc(100% - 25px);
  background-color: #27303b;
  padding: 13px 0;
  color: white;
  font-size: 18px;
  font-family: 'Inter-Bold';
  border-radius: 10px;
  border: 0;
  margin: 15px 0;
  cursor: pointer;
`

const ContainerList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 65vh;
  overflow: auto;
  padding-bottom: 150px;
`;

const ListClubs = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% + 25px);
  height: 100%;
  // overflow: scroll;
`;

const WrapClubs = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 10px;
  flex-direction: column;
  padding-bottom: 60px;
`;

const TabsClubs = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  font-family: 'Inter';
  align-items: center;
  gap: 5px;
  background-color: var(--simple-gray-2);
  border-radius: 8px;
`;
