import { IUserGradeResponse } from "@vibe/sdk/dist/interfaces/Admin/User";
import { IEducationZaahah, IMedia, IWork } from "./myAccountInterfaces";
import IPaymentInfo from "./IPaymentINfo";

export interface UserResponse {
    username: string;
    roles: string[];
    organizations: string[];
    city: string;
    state: string;
    country: string;
    creationDate: string;
    status: string;
    gender: string;
    location: string;
    zipcode: string;
    birthdate?: string;
    phoneNumber?: number;
    rolesByOrg: string[][];
    email?: string;
    firstname?: string;
    lastname?: string;
    organizationsNames: string[];
    preferredLanguage: string;
    socialMedia?: IMedia[];
    work?: string[];
    education?: string[];

    address?: string;
};

 export interface UserResponsePII extends IUserGradeResponse {
    phoneNumber?: number;
    lastname: string;
    firstname: string;
}

export const userMockGraded: IUserGradeResponse = {
    username: '',
    roles: [],
    organizations: [],
    city: '',
    country: '',
    creationDate: '',
    status: '',
    gender: '',
    location: '',
    zipcode: '',
    rolesByOrg: [[]],
    organizationsNames: [],
    preferredLanguage: '',
    clubs: [],
    certs: [],
    clubsAdvisor: [],
    clubsFollower: []
}

export const UserResponsePIIMock: UserResponsePII = {
    ...userMockGraded,
    lastname: '',
    firstname: '',
};

export const PaymentMockUser: IPaymentInfo = {
    hasActiveSubscription: false,
}