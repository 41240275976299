import { UserConnection } from "@vibe/sdk/dist/interfaces/Roster/getUserConnections";
import { relationTypeCard } from "../../components/BaseComponents/Connections/ConnectionCard";

export interface getConnectionActiveResponse{
    relation: relationTypeCard
    connection?: UserConnection
}

export const isConnectionActive = (id: string, connections: UserConnection[]): getConnectionActiveResponse => {
    let isConn: getConnectionActiveResponse = {
        relation: 'ANY'
    }

    const conn = (connections || []).find(cl => cl.username === id);

    if (conn) isConn = {
        relation: conn.relation[0] as relationTypeCard,
        connection: conn
    }

    return isConn;
}