import styled from "styled-components";

export const CardContainerHome = styled.div`
    display: flex;
    min-width: 315px;
    width: 100%;
    height: ${({height} : {height: string}) => height};
    max-height: ${({height} : {height: string}) => height};
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    border-radius: 8px;
    border: 1px solid var(--border-subtle-neutral);
    background-color: var(--background-default);

    color: var(--default-dark);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px;
    letter-spacing: 0.2px;

    position: relative;
`;

export const PhotoContainerHome = styled.div`
    display: grid;
    grid-template-columns: 56px 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 12px;
    text-align: left;
    justify-content: flex-start;
`;

export const BolderTextCardHome = styled.div`
    font-weight: 600;
    font-family: 'Inter-bold';
`;

export const BolderTextTitleCardHome = styled.div`
    font-weight: 600;
    font-family: 'Inter-bold';
    font-size: 16px;
    font-style: normal;
    line-height: 28px;
    letter-spacing: 0.1px;
`;

export const BolderTextGridName = styled(BolderTextTitleCardHome)`
    grid-area: 1 / 2 / 2 / 3;
`;

export const TitleCardName = styled(BolderTextGridName)`
    cursor: pointer;
`;

export const TitleRowCardHome = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 100%;

    color: var(--absolute-zero);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Inter-bold';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.1px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
`;

export const TitleRowCardEllipsisWrap = styled.div`
    //display: flex;
    text-align: left;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

interface ContentCardHomeProps {
    height?: string
    gap?: string
}

export const ContentCardHome = styled.div`
    display: flex;
    width: 100%;
    height: ${({height = "100%"}: ContentCardHomeProps) => height};
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: ${({gap = "8px"}:ContentCardHomeProps) => gap};
`;

export const RowIconCardHome = styled.div`  
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

export const RowGap5CardHome = styled.div`  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

export const RowPostCardHome = styled.div`  
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: 12px;
`;

export const RowSocialIcon = styled(RowIconCardHome)`
    gap: 16px;
`;

export const RowAliasesHealerCard = styled(RowIconCardHome)`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    text-align: left;
`;

export const RowAliasesHealerCardContainer = styled(RowIconCardHome)`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
`;

export const BolderTextGridSecond = styled(RowIconCardHome)`
    grid-area: 2 / 2 / 3 / 3;
`;

export const RowGuideCardEvent = styled(RowIconCardHome)`
    color: var(--simple-brown-dark);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
`;

export const RowIconCardHomeDivider = styled(RowIconCardHome)`
    :after {
        content: "|";
        padding-left: 8px;
    }
`;

export const IconUser = styled.img`
    width: 18px;
    height: 18px;
    object-fit: contain;
`;

export const GuidesContainerHome = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 18px;
`;

export const GuideCardHome = styled.img`
    border: 1px solid white;
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    max-width: 32px;
    max-height: 32px;
    border-radius: 50%;
    position: relative;
    margin-left: -8px;
    object-fit: cover;
    cursor: pointer;
`;

export const AvatarHealerCardHome = styled(GuideCardHome)`
    grid-area: 1 / 1 / 3 / 2;
    margin-left: 0px;
    width: 56px;
    height: 56px;
    min-width: 56px;
    min-height: 56px;
    max-width: 56px;
    max-height: 56px;
    object-fit: cover;
`;

interface GuideCardHomeNoAvatarProps{
    background_color?: string
    text_color?: string
}

export const GuideCardHomeNoAvatar = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: relative;
    margin-left: -8px;
    background-color: ${({background_color = "white"}: GuideCardHomeNoAvatarProps) => background_color};
    color: ${({text_color = "black"}: GuideCardHomeNoAvatarProps) => text_color};
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Inter-bold';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    cursor: pointer;
`;

export const NoAvatarHealerCardHome = styled(GuideCardHomeNoAvatar)`
    grid-area: 1 / 1 / 3 / 2;
    margin-left: 0px;
    width: 56px;
    height: 56px;
    min-width: 56px;
    min-height: 56px;
    max-width: 56px;
    max-height: 56px;
    object-fit: cover;
    font-size: 20px;
`;

export const GuideCardHomePlus = styled(GuideCardHomeNoAvatar)`
    border: 1px solid black;
`;

export const AvatarCreatorHome = styled.img`
    border: 1px solid white;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;
    border-radius: 50%;
    object-fit: cover;
`;

export const CreatorNoAvatar = styled(GuideCardHomeNoAvatar)`
    width: 20px;
    height: 20px;
    margin-left: 0px;
    font-size: 10px;
`;

export const DividerLineHealerHome = styled.div`
    height: 1px;
    border-radius: 1px;
    width: 100%;
    background-color: var(--simple-gray);
`;

export const WrapperDescriptionHomeCard = styled.div`
    overflow: hidden;
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    white-space: nowrap;
    // display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    max-height: 48px;
    width: 100%;
    height: 22px;
`;

export const ReadMoreHealerCard = styled.div`
    display: flex;
    padding: 4px 8px 4px 0px;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    font-weight: 600;
    font-family: 'Inter-bold';
    cursor: pointer;
`;

export const ContHealerSeeker = styled.div``

export const Row = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
`;