import { useEffect, useMemo, useState } from "react";
import { getDataSDK, getDataSDKResponse } from "../helpers/backendHelper";
import { ICountry } from "@vibe/sdk/dist/interfaces/Roster/Location";
import { sortAlphabeticallyOnProperty } from "../containers/Signup/SignupHealerInd";
import { CountryOption } from "./Locations/useActiveCountry";

const useCountriesSA = ({
    SearchTerm
}: {
    SearchTerm: string
}) => {
    const searchParsed = useMemo(() => SearchTerm.toLowerCase(), [SearchTerm]);

    const [countries, setCountries] = useState<ICountry[]>([]);
    const [countriesFiltered, setCountriesFiltered] = useState<ICountry[]>([]);
    const [countriesReactSelect, setCountriesReactSelect] = useState<CountryOption[]>([])

    const reloadCountries = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Roster.getCountries(dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) {
                    const response = results.body as ICountry[];
                    setCountries(response);
                    setCountriesReactSelect(
                        response.map((country) => ({ value: country.location, label: country.name }))
                    )
                }
                else {
                    setCountries([]);
                    setCountriesReactSelect([]);
                }
            });
    };

    useEffect(() => {
        if (!SearchTerm || SearchTerm === '') {
            setCountriesFiltered(countries);
            return;
        }
        setCountriesFiltered(
            (sortAlphabeticallyOnProperty(countries, 'name') as ICountry[])
                .filter(l => (l.name || '').toLowerCase().includes(searchParsed))
        );
    }, [SearchTerm, countries]);

    useEffect(() => {
        reloadCountries();
    }, []);

    return {
        countriesFiltered,
        countriesReactSelect,
        reloadCountries
    };
};

export default useCountriesSA;
