import './styles.scoped.css';

interface TwoColumnsProps {
  left: React.ReactChild,
  right: React.ReactChild
}

const TwoColumns = ({left, right}: TwoColumnsProps) => (
  <div className="TwoColumns__wrapper">
    <div className="TwoColumns__column">
      {left}
    </div>
    <div className="TwoColumns__column">
      {right}
    </div>
  </div>
);

export default TwoColumns;