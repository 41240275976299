import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserBirthdayConnections } from "../../store/actions/connectionActions";
import { RootState } from "../../store/store";
import "./styles.css";
import Notifications from '../Notifications'
import { getNotifications } from "../../store/actions/notificationActions";
import moment from "moment";
import { useTranslation } from "react-i18next";
import 'moment/locale/es';
import { RightPanel } from "../../containers/AppLayout/components";
import styled from "styled-components";
import ContainerBalance from "../../components/ContainerBalance";
import Quotes from "../BaseComponents/Carrousels/Quotes";
import { IInspiration } from "../Home/ResultsHome/CarrouselInspiration/Content";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import { IPostExtended } from "@vibe/sdk/interfaces/Posts/IPost";
import { isPaidHealer } from "../../helpers/userHelper";
import IPaymentInfo from "../../interfaces/user/IPaymentINfo";
import { sortAlphabeticallyOnPropertyPosts } from "../../hooks/Posts/usePosts";
import { IUserGradeResponse } from "@vibe/sdk/interfaces/Admin/User";

const RightSection = (props: any) => {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
  const userRoles = useSelector((state: RootState) => state.getUser.userRoles);
  const userPayment: IPaymentInfo = useSelector((state: RootState) => state.getUser.paymentInfo);
  const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);

  const [quotes, setQuotes] = useState<IInspiration[]>([]);

  const reloadQuotes = () => {
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Posts.getInspirationalQuotes(userInfo?.username || '', undefined, dataSDK.token); // @todo pagination
      })
      .then((results) => {
        if (results.body && 'posts' in results.body) {
          setQuotes(sortAlphabeticallyOnPropertyPosts(results.body.posts, 'createdAt', 'DESC').map(p => ({
            quote: p.content || '',
            from: p.tip_author || '',
          })))
        }
        else {
          console.log('Error getting inspirational quotes.', JSON.stringify(results))
        }
      })
      .catch(console.error)
  }

  useEffect(() => reloadQuotes(), [])

  function handleCloseNtf() {
    props.handleActiveNotification(false)
  }

  const reloadNotifications = (username: string) => {
    dispatch(getNotifications(username));
  }

  /*
  const ahaBalance = useSelector((state: RootState) => state.getUser.GetAhaBalance);
  const ahaBalanceError = useSelector((state: RootState) => state.getUser.GetAhaBalanceError);
  const userBirthdayConnections = useSelector((state: RootState) => state.getConnection.userBirthdayConnections || [])
  const userRoles = useSelector((state: any) => state.getUser.userRoles);

  const [birthdayDate, setBirthdayDate] = useState<Date>(new Date())

  const [showModalDownload, setShowModalDownload] = useState(false);
  const [activeBalance, setActiveBalance] = useState(false);

  console.log(userRoles)

  useEffect(() => {
    dispatch(getUserBirthdayConnections(userInfo.username,
      { month: birthdayDate.getMonth() + 1, day: birthdayDate.getDate() }));
  }, [birthdayDate])

  const getDateText = () => {
    const today = new Date()
    const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
    const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
    if (
      birthdayDate.getFullYear() === today.getFullYear() &&
      birthdayDate.getMonth() === today.getMonth() &&
      birthdayDate.getDate() === today.getDate()
    ) {
      return t("hbdToday")
    } else if (
      birthdayDate.getFullYear() === tomorrow.getFullYear() &&
      birthdayDate.getMonth() === tomorrow.getMonth() &&
      birthdayDate.getDate() === tomorrow.getDate()
    ) {
      return t("hbdTomorrow")
    } else if (
      birthdayDate.getFullYear() === yesterday.getFullYear() &&
      birthdayDate.getMonth() === yesterday.getMonth() &&
      birthdayDate.getDate() === yesterday.getDate()
    ) {
      return t("hbdYesterday")
    } else {
      return `${moment(birthdayDate).locale(i18n.language).format('MMMM DD')}`
    }
  };*/

  return (
    <RightPanel isHealerBulletin={isPaidHealer(userRoles, userPayment.hasActiveSubscription)}>
      {props.activeNotification ?
        <Notifications
          handleCloseNtf={handleCloseNtf}
          reloadNotifications={reloadNotifications}
        />
        :
        <>
          <ContainerBalance showDetails={true} />
          <Quotes
            quotes={quotes}
            height={isPaidHealer(userRoles, userPayment.hasActiveSubscription) ? "78%" : "526px"}
            width={"84%"}
            isBulletin
          />
          {/*
          <div className="hbdToday">
            <button onClick={() => setBirthdayDate(
              new Date(
                birthdayDate.getFullYear(),
                birthdayDate.getMonth(),
                birthdayDate.getDate() - 1)
            )}>
              <FontAwesomeIcon
                icon="chevron-left"
                size="lg"
                color={T_BLUE}
                className=" "
              />
            </button>
            {getDateText()}
            <button onClick={() => setBirthdayDate(
              new Date(
                birthdayDate.getFullYear(),
                birthdayDate.getMonth(),
                birthdayDate.getDate() + 1)
            )}>
              <FontAwesomeIcon
                icon="chevron-right"
                size="lg"
                color={T_BLUE}
                className=" "
              />
            </button>
          </div>
          {userBirthdayConnections.length ? userBirthdayConnections.map((user: any) =>
            <BirthdayTile
              name={user.firstname}
              lastname={user.lastname}
              avatar={user.avatar}
              username={user.usernameConnection}
              unregistered={user.unregistered}
              email={user.email}
              comm={user.community}
            />
          ) : (
            <div className="club-right-title robotocondensed-bold-white-24px" style={{ color: T_BLUE }}>
              {t("hbdNo")}
            </div>
          )*/}
        </>
      }
    </RightPanel>
  );
};

const Balance = styled.div`
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
`

export default RightSection;
