import styled from 'styled-components';
import { Logo } from '../Signup/WrapBusiness';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChatServiceInterface } from '../../interfaces/chat/ChatServiceInterface';
import Menu from '../../components/Menu';
import { useSelector } from 'react-redux';
import { IUserGradeResponse } from '@vibe/sdk/interfaces/Admin/User';
import { RootState } from '../../store/store';
import { useEffect, useState } from 'react';
import { AvatarHeader, CircleStatusHeader, ImageContainerHeader, NotificationChatCircle } from '../../components/Header';
import { ButtonsHeaderHome, IconClicker } from '../../components/Home/HeaderHome/styles';

const HeaderSetup = ({
    ChatServiceInstance,
}: {
    ChatServiceInstance?: ChatServiceInterface
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const isLoggedIn = useSelector((state: RootState) => state.getUser.isLoggedIn);
    const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);

    const [activeChat, setActiveChat] = useState(false);
    const [activeMenuLogged, setActiveMenuLogged] = useState(false);

    const handleToggle = () => {
        setActiveMenuLogged(false);
    };

    const handleNotification = () => {
        navigate('../../../../../app?notifications=true');
    };

    const handleImage = () => {
        if (isLoggedIn) {
            setActiveMenuLogged(true);
        }
    };

    useEffect(() => {
        if (location.pathname.includes('chat')) {
            setActiveChat(true);
        }
    }, [location.pathname])

    return (
        <HeaderSetup_v2>
            <Logo isSetup={true} onClick={() => navigate('../../../../app')} />

            {(location.pathname.includes('update')) && (
                <ButtonsHeaderHome>
                    {process.env.REACT_APP_SHOW_CHAT === 'true' &&
                        <IconClicker
                            icon="message"
                            color={activeChat ? "var(--lavender)" : "black"}
                            size="xl"
                            onClick={() => navigate('/chat')}
                        />
                    }

                    {(!activeChat && ChatServiceInstance?.newMessages !== 0 && isLoggedIn) &&
                        <NotificationChatCircle key={ChatServiceInstance?.newMessages || 0}
                            width={(ChatServiceInstance?.newMessages || 0) > 99 ? '25px' : (ChatServiceInstance?.newMessages || 0) > 10 ? '20px' : '17px'}
                        >
                            {(ChatServiceInstance?.newMessages || 0) > 99 ? '99+' : ChatServiceInstance?.newMessages}
                        </NotificationChatCircle>
                    }

                    {(isLoggedIn) &&
                        <ImageContainerHeader onClick={handleImage}>
                            <CircleStatusHeader status={ChatServiceInstance?.connectedUser.status || 'available'} />
                            <AvatarHeader
                                src={user.avatar ? user.avatar : `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png`}
                                alt="profile-pic"
                            />
                        </ImageContainerHeader>
                    }

                    {(isLoggedIn) &&
                        <Menu
                            show={activeMenuLogged}
                            onHide={handleToggle}
                            activeNotification={handleNotification}
                            ChatServiceInstance={ChatServiceInstance}
                        />
                    }
                </ButtonsHeaderHome>
            )}
        </HeaderSetup_v2>
    )
}

export default HeaderSetup;

export const HeaderSetup_v2 = styled.div`
    height: 80px;
    width: 100%;
    padding: 10px 136px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border-bottom: 1px solid var(--border-default);
`;