import { useEffect, useState } from "react";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import SelectItem from "../../interfaces/SelectItem";

export interface StateOption extends SelectItem { };

const useActiveState = () => {
  const [activeStates, setActiveStates] = useState<string[]>([]);
  const [activeStatesReactSelect, setActiveStatesReactSelect] = useState<StateOption[]>([])

  useEffect(() => {
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Roster.getActiveStates(dataSDK.token);
      })
      .then((results) => {
        if (!Array.isArray(results.body)) {
          return;
        }
        setActiveStates(results.body.map((State) => State.name));
        setActiveStatesReactSelect(
          results.body.map((State) => ({ 
            value: State.location.replaceAll('#','_'), 
            label: State.name 
          }))
        )
      })
  }, []);

  return { 
    activeStates, 
    activeStatesReactSelect 
  };
}

export default useActiveState;