import { IOrganizationExtended } from "@vibe/sdk/interfaces/Admin/Organization";
import { Backend, MicroServices } from "./backendHelper";

class Organization {
  static getOrgById = (org_id: string): Promise<IOrganizationExtended> =>
    new Promise((resolve, reject) => {
      Backend(
        MicroServices.Admin,
        `/organizations/${org_id}?pending=true`)
        .then(async (response) => {
          console.log(response.status)
          if (response.status === 400) {
            const responseNotPending = await Backend(
              MicroServices.Admin,
              `/organizations/${org_id}?pending=false`)
            return responseNotPending.json();
          }
          return response.json()
        })
        .then((results) => {
          resolve(results)
        })
        .catch(reject)
    })
}

export default Organization;