import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ModalImageEditor from "../ModalImageEditor";
import "./styles.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUser, updateProfilePhoto, uploadAsset, uploadImage } from "../../store/actions/userActions";
import { useEffect, useState, useContext } from "react";
import GeneralInfo from "../MyAccount/GeneralInfo";
import Preferences from "../EditProfile/Preferences";
import Education from "../MyAccount/Education";
import AccountInfo from "../MyAccount/AccountInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER, WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";
import { ContextSuccess } from "../../Context";
import styled from "styled-components";
import { CenterInsideWrap } from "../../containers/Bulletin";

toast.configure();

function ProfileSettings({
    backInfo
}:{
    backInfo: () => void
}) {
    const { t, i18n } = useTranslation();

    const [selected, setSelected] = useState("General");
    const [screen, setScreen] = useState("General");

    function selectScreen(screen: string) {

        switch (screen) {
          case "General":
            return <GeneralInfo backInfo={backInfo}/>;
            break;
          case "Account":
            return <AccountInfo />
            break;
        //   case "Work":
        //     return <Education />;
        //     break;
          case "Preferences":
            return <Preferences />;
            break;
          default:
            return <></>;
            break;
        }
      }

    return (
        <CenterInsideWrap>
            <TabsProfile>
                <div
                    className={
                        selected === "General" ? "pointer icons-column-selected" : "pointer icons-column"
                    }
                    onClick={() => {
                        setSelected("General");
                        setScreen("General");
                    }}
                >
                    <FontAwesomeIcon
                        icon="user"
                        size='sm'
                        color={
                        selected === "General"
                            ? 'var(--t-blue)'
                            : 'var(--lavender)'
                        }
                    />
                    <div className={
                        selected === "General"
                        ? "icon-label robotocondensed-bold-t-blue-16px"
                        : "icon-label robotocondensed-normal-lavender-16px"
                    }>
                        {t('profile__information')}
                    </div>
                </div>

                <div
                    className={
                        selected === "Account" ? "pointer icons-column-selected" : "pointer icons-column"
                    }
                    onClick={() => {
                        setSelected("Account");
                        setScreen("Account");
                    }}
                >
                    <FontAwesomeIcon
                        icon="lock-alt"
                        size='sm'
                        color={
                        selected === "Account"
                            ? 'var(--t-blue)'
                            : 'var(--lavender)'
                        }
                    />
                    <div className={
                        selected === "Account"
                        ? "icon-label robotocondensed-bold-t-blue-16px"
                        : "icon-label robotocondensed-normal-lavender-16px"
                    }>
                        {t('profile__account')}
                    </div>
                </div>

                {/* <div
                    className={
                        selected === "Work" ? "pointer icons-column-selected" : "pointer icons-column"
                    }
                    onClick={() => {
                        setSelected("Work");
                        setScreen("Work");
                    }}
                >
                    <FontAwesomeIcon
                        icon="graduation-cap"
                        size='sm'
                        color={
                        selected === "Work"
                            ? 'var(--t-blue)'
                            : 'var(--lavender)'
                        }
                    />
                    <div className={
                        selected === "Work"
                        ? "icon-label robotocondensed-bold-t-blue-16px"
                        : "icon-label robotocondensed-normal-lavender-16px"
                    }>
                        {t('profile__educationAndWork')}
                    </div>
                </div> */}

                <div
                    className={
                        selected === "Preferences" ? "pointer icons-column-selected" : "pointer icons-column"
                    }
                    onClick={() => {
                        setSelected("Preferences");
                        setScreen("Preferences");
                    }}
                >
                    <FontAwesomeIcon
                        icon="cog"
                        size='sm'
                        color={
                        selected === "Preferences"
                            ? 'var(--t-blue)'
                            : 'var(--lavender)'
                        }
                    />
                    <div className={
                        selected === "Preferences"
                        ? "icon-label robotocondensed-bold-t-blue-16px"
                        : "icon-label robotocondensed-normal-lavender-16px"
                    }>
                        {t('profile__preferences')}
                    </div>
                </div>

            </TabsProfile>
            {selectScreen(screen)}
        </CenterInsideWrap>
    )
}

export default ProfileSettings;

export const TabsProfile = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  font-family: 'Inter';
  align-items: center;
`;