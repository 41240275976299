import { AnyAction } from "@reduxjs/toolkit";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { GET_NOTIFICATION_ERROR, GET_NOTIFICATION_SUCCESS, START_GET_NOTIFICATION } from "../constants/notificationConstants";

toast.configure()

export const getNotifications = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_NOTIFICATION });
    Backend(
      MicroServices.Notification,
      `/notification?username=${username}`
    )
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_NOTIFICATION_SUCCESS,
          payload: results.notifications || [],
        })
        resolve();
      })
      .catch((error) => {
        dispatch({
          type: GET_NOTIFICATION_ERROR,
          payload: error,
        });
        reject();
      })
  });