import styled from "styled-components";
import { EventCardInterface } from "./EventCard";

export type StateEventType = 'PRO' | 'FIN' | 'NEW' | 'ANY';

const EventStateLabel = ({
    item,
    isModalDetail = false,
}: {
    item: EventCardInterface
    isModalDetail?: boolean
}) => {

    const getState = (): StateEventType => {

        const startDate = new Date(`${item.startDate} ${item.startTime}`);
        const endDate = new Date(`${item.endDate} ${item.endTime}`);
        const now = new Date();
        const createdAt = new Date(parseInt(item.createdAt));

        if(now.getTime() > endDate.getTime()) return 'FIN';

        if(now.getTime() > startDate.getTime()) return 'PRO';

        now.setMinutes(now.getMinutes() - 10);

        if(now <= createdAt) return 'NEW';

        return 'ANY';
    }

    const getColorState = (): string => {
        switch (getState()) {
            case 'PRO':
                return 'var(--electric-yellow-2)';

            case 'FIN':
                return 'var(--simple-gray-3)';

            default:
                return 'var(--strong-blue)';
        }
    }

    const getNameState = (): string => {
        switch (getState()) {
            case 'PRO':
                return 'Event in progress';

            case 'FIN':
                return 'Finished event';

            default:
                return 'New!';
        }
    }

    return (
        <WrapperEventStateLabel color={getColorState()} anyState={getState() === 'ANY'} isModalDetail={isModalDetail}>
            {getNameState()}
        </WrapperEventStateLabel>
    )
}

export default EventStateLabel;

interface WrapperProps {
    anyState: boolean
    color: string
    isModalDetail: boolean
}

const WrapperEventStateLabel = styled.div`
    position: absolute;
    top: 16px;
    right: ${({ isModalDetail }: WrapperProps) => isModalDetail ? '25px' : '0px'};

    display: ${({ anyState }: WrapperProps) => anyState ? 'none' : 'flex'};
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: fit-content;
    height: fit-content;
    background-color: ${({ color }: WrapperProps) => color};
    color: white;
    font-family: 'Inter-bold';
    font-size: 14px;

    border-radius: ${({ isModalDetail }: WrapperProps) => isModalDetail ? '8px' : '8px 0px 0px 8px'};
`;