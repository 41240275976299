import { getDataSDK, getDataSDKResponse } from "./backendHelper";

export const cleanWebsite = (web: string) => {
    const website = web.replace(/\s+/g, '').toLowerCase();
    const URL_PROTOCOL = /http(s)?:\/\/.*/;
    if (URL_PROTOCOL.exec(website)) {
        return website;
    }
    return `https://${website}`;
}

export const checkOrgWebsite = (
    website: string
): Promise<boolean> => new Promise<boolean>((resolve, reject) => {
    getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
            return dataSDK.sdk.AccessManager.website({
                website: encodeURI(cleanWebsite(website)),
            }, dataSDK.token);
        })
        .then((response) => {
            if (response.statusCode !== 200) {
                console.log(response);
                resolve(false);
            }
            resolve(true);
        })
        .catch((err) => {
            resolve(false);
            console.error(err)
        });
})