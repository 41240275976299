import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Backend, MicroServices, getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import { GET_TCLUBS_ERROR, GET_TCLUBS_SUCCESS, GET_TCOMM_ERROR, GET_TCOMM_SUCCESS, GET_TTEAM_ERROR, GET_TTEAM_SUCCESS, START_GET_TCLUBS, START_GET_TCOMM, START_GET_TTEAM } from "../constants/TClubsConstants";
import IResponseError from "@vibe/sdk/dist/interfaces/IResponseError";

export const getTClubsByCountry = (country: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_TCLUBS });
    Backend(
      MicroServices.Club,
      `/tclubs/by-country?country=${country}&type=CLUB`
    )
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_TCLUBS_SUCCESS,
          payload: results,
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_TCLUBS_ERROR,
          payload: error,
        });
      })
  });

export const getTCommsByCountry = (country: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_TCOMM });
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Club.getTClubsByCountry('USA',"COMM", dataSDK.token);
      })
      .then((results) => {
        if(results.statusCode === 200) {
          dispatch({
            type: GET_TCOMM_SUCCESS,
            payload: results.body,
          })
          return resolve();
        }
        dispatch({
          type: GET_TCOMM_ERROR,
          payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TCOMM_ERROR,
          payload: error,
        });
      })
  });

export const getTTeamsByCountry = (country: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_TTEAM });
    Backend(
      MicroServices.Club,
      `/tclubs/by-country?country=${country}&type=TEAM`
    )
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_TTEAM_SUCCESS,
          payload: results,
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_TTEAM_ERROR,
          payload: error,
        });
      })
  });
