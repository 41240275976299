import React, {
  KeyboardEventHandler,
  useState,
  useEffect,
  useContext
} from 'react';
import styled from "styled-components";
import "./styles.css";
import "./styles.scoped.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser, getUserInfoProfile, updateProfilePhoto, updateUserBio, uploadAsset, uploadImage } from '../../store/actions/userActions';
import { cancelFriendRequest, createFriendRequest, saveNewFriend } from '../../store/actions/connectionActions';
import CreatableSelect from 'react-select/creatable';
import { GroupBase, MultiValue, OnChangeValue, PropsValue, StylesConfig } from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalImageEditor from '../ModalImageEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WHITE } from '../ClubIconsNavBar';
import { useTranslation } from "react-i18next";
import { Backend, MicroServices, getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { ContextSuccess } from "../../Context";
import TextEditor from "../BaseComponents/TextEditor";
import { Interest } from "@vibe/sdk/dist/interfaces/Connections/newInterests";
import { RootState } from "../../store/store";
import { getInterestSuggested } from "../../store/actions/connectionActions";
import { IUserGradeResponse } from '@vibe/sdk/interfaces/Admin/User';
import { CenterInsideWrap } from '../../containers/Bulletin';
import { cleanHTML, cleanStyleHTML } from '../../helpers/letterHelper';

toast.configure();
interface Option {
  readonly label: string;
  readonly value: string;
}

function Index({
  isUserProfile,
  setEditVisible,
  editVisible
}: {
  isUserProfile: boolean
  setEditVisible?: (data: boolean) => void
  editVisible: boolean
}) {
  const { t, i18n } = useTranslation();
  const currentSuccess = useContext(ContextSuccess);
  const { username } = useParams();
  const dispatch = useDispatch();

  const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
  const userAll = useSelector((state: RootState) => state.getUser.user);
  const suggestedInterests: Interest[] = useSelector((state: RootState) => state.getConnection.GetISug);
  const organization = useSelector((state: RootState) => state.getOrgs.organizationInfo);
  const userProfile: IUserGradeResponse = useSelector((state: RootState) => state.getUser.userProfile);

  const [user, setUser] = useState(userAll);
  const [biography, setBiography] = useState('');
  const [userInterests, setUserInterests] = useState<string[]>([]);
  const [loadingEditProfile, setLoadingEditProfile] = useState(false);

  useEffect(() => {
    if (isUserProfile) {
      setUser(userAll);
    }
    else {
      dispatch(getUserInfoProfile(username || ''));
    }
  }, [username, isUserProfile]);

  useEffect(() => {
    if (!isUserProfile) {
      setUser(userProfile);
    }
  }, [userProfile]);

  useEffect(() => {
    setUserInterests(user.interests || []);
  }, [user]);

  useEffect(() => {
    dispatch(getInterestSuggested());
  }, [])

  useEffect(() => {
    if (!isUserProfile) dispatch(getUserInfoProfile(username || ''));
  }, []);

  const handleInterest = (int: string) => {
    if (userInterests.includes(int)) {
      setUserInterests(userInterests.filter(userInterests => userInterests !== int))
    } else {
      setUserInterests([...userInterests, int])
    }
  }

  const saveEditProfile = () => {
    setLoadingEditProfile(true);

    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return Promise.all([
          dataSDK.sdk.Connections.saveInterests({
            username: userInfo?.username || '',
            interests: userInterests,
            organizationId: organization.id,
          }, dataSDK.token),
          dispatch<any>(updateUserBio(
            userInfo?.username || '',
            biography,
          )),
        ])
      })
      .then(([rInterests, rBio]) => {
        if (rInterests.statusCode === 201 && rBio === true) {
          if (setEditVisible) setEditVisible(false);
          currentSuccess?.setSuccessData(true, true, t('toast_informationUpdated'));
          dispatch<any>(getUser(userInfo?.username || ''))
            .then((results: any) => {
              setUser(results)
            })
            .catch((error: any) => currentSuccess?.setSuccessData(false, true, 'Error', error));
        }
      })
      .catch((error) => {
        console.error(error);
        currentSuccess?.setSuccessData(false, true, 'Error updating profile', error);
      })
      .finally(() => { setLoadingEditProfile(false) });
  }

  const cancelEditProfile = () => {
    if (setEditVisible) setEditVisible(false);
    setBiography(user.biography || '');
    setUserInterests(user.interests || []);
  }

  return (
    <>
      {(!editVisible) &&
        (<CenterInsideProfile>
          <h2 className='titleContainer'>
            {(isUserProfile) ? (t('profile__aboutMe')) : (`${t('profile__about')} ${user.firstname}`)}
          </h2>
          
          <span className='textAboutMe' dangerouslySetInnerHTML={{ __html: cleanStyleHTML(user?.biography as string ?? t('profile__noInformationYet')) }} />

          <h2 className='titleContainer'>{t('profile__Interests')}</h2>
          <ul className='listInterest'>
            {(user.interests || []).map((request: any) => (
              <li className='itemInterest'>{request}</li>
            ))}
          </ul>
        </CenterInsideProfile>)}

      {(isUserProfile && editVisible) &&
        (<CenterInsideWrap>

          <div className="titleContainer">
            {(isUserProfile) ? (t('profile__aboutMe')) : (`${t('profile__about')} ${user.firstname}`)}
          </div>
          <div className="inputInfo">
            <TextEditor
              value={user.biography || ''}
              setValue={setBiography}
              charLimit={1000}
              placeholder='Tell us about you!'
            />
          </div>

          <div className="titleContainer">{t('profile__Interests')}</div>
          <ListAreas>
            {suggestedInterests.map((area) => (
              <Area
                active={userInterests.includes(area.name)}
                onClick={() => handleInterest(area.name)}
              >
                {area.name}
              </Area>
            ))}
          </ListAreas>
          <div className="row btnsProfile noPadding">
            <button className='btnEditProfile' onClick={cancelEditProfile}>{t('button__cancel')}</button>
            <button className='btnFriendsProfile' onClick={saveEditProfile}>
              {loadingEditProfile ?
                <div className="icon-spinner-third buttonApproveOrg"></div>
                :
                t('button__save')
              }
            </button>

          </div>
        </CenterInsideWrap>
        )}

      {(!isUserProfile) /*@todo Friend buttons */}

    </>

  );
}

interface AreaProps {
  active: boolean;
}

const Area = styled.div`
    border: 1px solid darkgrey;
    width: fit-content;
    background-color: ${(props: AreaProps) => props.active ? '#FAF0E6' : 'white'};
    padding: 7px 12px;
    border-radius: 17px;
    margin-right: 6px;
    margin-bottom: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;

    &:hover {
        background-color: #FAF0E6
    }
`
const ListAreas = styled.div`
    width: 100%;
    margin: 24px auto;
    display: flex;
    flex-wrap: wrap;
`

export default Index;

const CenterInsideProfile = styled(CenterInsideWrap)`
  height: fit-content;
  align-items: flex-start;
`;