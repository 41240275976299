import { useState, useContext } from "react";
import {
    Title,
} from "../SuperAdminComponents/basicForSACenter";
import { useTranslation } from "react-i18next";
import { ContextSuccess } from "../../../Context";
import styled from "styled-components";
import SearchInput from "../../BaseComponents/SearchInput";
import BlankTable from "../../BaseComponents/BlankTable";
import useContributorsSA from "../../../hooks/Superadmin/Contributors/useContributorsSA";
import Contributor from "@vibe/sdk/interfaces/Modalities/contributors/Contributor";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import { onHideType } from "../../BaseComponents/Modals/ModalUnsaved";
import { Button } from "../../AdminCenter/MyOrganization/MyOrganization";
import { StyledFontAwesomeIcon } from "../../HealerProfile/components";
import ModalContributor from "./ModalContributor";

export const mockContributor: Contributor = {
    username: '',
    fullname: '',
    modalities: [],
    createdAt: '',
    createdBy: '',
}

const Contributors = () => {
    const { t, i18n } = useTranslation();
    const currentSuccess = useContext(ContextSuccess);

    const [SearchTerm, setSearchTerm] = useState("");
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [showCreate, setShowCreate] = useState<boolean>(false);
    const [selected, setSelected] = useState<Contributor>({ ...mockContributor });

    const {
        ContributorsFiltered,
        reloadContributors
    } = useContributorsSA({
        SearchTerm,
    });

    const handleUpdate = (data: string) => {
        const template = ContributorsFiltered.find(t => t.username === data);
        if (template) setSelected(template);
        setShowEdit(true);
    }

    const onHideDelete = (type: onHideType, id: string) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);
                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        return dataSDK.sdk.Modalities.deleteContributor(id, dataSDK.token)
                    })
                    .then((response) => {
                        if (response.statusCode === 204) {
                            reloadContributors();
                            currentSuccess?.setSuccessData(true, true, 'Contributor Deleted.');
                            currentSuccess?.setModalsOff();
                        } else {
                            currentSuccess?.setSuccessData(false, true, 'Error deleting Contributor.');
                        }
                    })
                    .finally(() => currentSuccess?.setLoadingModal(false))
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                break;

            default:
                break;
        }
    }

    const handleDelete = (data: string) => {
        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => onHideDelete(type, data),
            'Do you want to delete this Contributor?'
        );
    }

    return (
        <ContentOrganizations>
            <Label>
                Contributors
                <Button onClick={() => setShowCreate(true)}>
                    Assign Contributor
                    <StyledFontAwesomeIcon
                        size='sm'
                        color='white'
                        icon='plus'
                    />
                </Button>
            </Label>
            <WrapperRow>
                <SearchInput
                    filteredItem={SearchTerm}
                    setFilteredItem={setSearchTerm}
                    backgroundColor={'var(--t-blue)'}
                    border={'0px'}
                    placeholder={`Search by name`}
                />
            </WrapperRow>
            <Scroll>
                <BlankTable
                    headers={['Name', 'Modalities']}
                    items={ContributorsFiltered.map(con => ({
                        id: con.username,
                        labels: [
                            con.fullname,
                            con.modalities.map(m => m.name).join(', '),
                        ],
                    }))}
                    backgroundColor={'white'}
                    actions={['UPDATE', 'DELETE']}
                    actionsHandlers={{
                        handleUpdate,
                        handleDelete,
                    }}
                />
            </Scroll>
            <ModalContributor
                showModal={showCreate}
                onHide={() => setShowCreate(false)}
                reload={reloadContributors}
                modalitiesHealer={[]}
            />
            <ModalContributor
                showModal={showEdit}
                onHide={() => setShowEdit(false)}
                edit
                contributor={selected}
                reload={reloadContributors}
                modalitiesHealer={[]}
            />
        </ContentOrganizations>
    );
};

export default Contributors;

const Label = styled.div`
    color: var(--t-blue);
    font-family: 'Inter-bold';
    font-size: 24px;
    font-weight: bold;
    grid-area: 1 / 1 / 2 / 3;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    width: 100%;
`;

const ContentOrganizations = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  align-items: flex-start;
  gap: 15px;
  flex-direction: column;
`;

const WrapperRow = styled.div`
  display: flex;
  width: 40%;
  gap: 25px;
  align-items: flex-end;
`;

const Scroll = styled.div`
  display: flex;
  width: 100%;
  overflow-y: scroll;
`;
