import { useSelector } from "react-redux";
import { ProcessInterface } from "../../containers/SetupHealer/SetupHealer";
import { RootState } from "../../store/store";
import IPaymentInfo from "../../interfaces/user/IPaymentINfo";
import ContentPayment from "../BaseComponents/Payment/ContentPayment";
import { Scroll_Content_Signup } from "../signupsetuphealer/Introduction";

const PaymentTab = ({
  processData,
  handleTabActive,
}: {
  processData: ProcessInterface,
  handleTabActive: (value: number) => void;
}) => {
  const user = useSelector((state: RootState) => state.getUser.user);
  const userPayment: IPaymentInfo = useSelector((state: RootState) => state.getUser.paymentInfo);

  return <ContentPayment />;
};

export default PaymentTab;