import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JoditEditor from "jodit-react";
import styled from "styled-components";
import ErrorField from "./ErrorField";
import { useEffect, useState } from "react";
import { cleanHTML, cleanStyleHTML } from "../../helpers/letterHelper";

const TextEditor = ({
    value,
    setValue,
    charLimit,
    showError = false,
    maxHeight = '300px',
    placeholder = 'Write something!',
    minHeight = 150,
    buttons = ["bold", "italic", "underline"],
    border = '1px solid var(--simple-gray)',
}:{
    value: string
    setValue: (data: string) => void
    charLimit: number
    showError?: boolean
    placeholder?: string
    minHeight?: number
    maxHeight?: string
    buttons?: string[]
    border?: string
}) => {

    const handleBlur = (e: string) =>  setValue(cleanStyleHTML(e));

    useEffect(() => console.log('value', value), [value])

    return (
        <Wrapper>
            <Textarea
                border={border}
            >
                <JoditEditor
                    value={`<p>${value}</p>`}
                    config={{
                        buttons: buttons,
                        readonly: false,
                        toolbarAdaptive: false,
                        showPlaceholder: true,
                        placeholder: placeholder,
                        minHeight: minHeight,
                        maxHeight: maxHeight,
                        scrollable: true,
                        limitChars: charLimit,
                        showCharsCounter: true,
                        showWordsCounter: false,
                        showXPathInStatusbar: false,
                        askBeforePasteFromWord: false,
                        askBeforePasteHTML: false,
                        sanitize: true,
                        cleanHTML: true,
                        processPasteHTML: true,
                        scrollToPastedContent: true,
                        processPasteFromWord: true,
                        defaultActionOnPasteFromWord: 'insert_clear_html',
                        defaultActionOnPasteFromHTML: 'insert_clear_html',
                    }}
                    onBlur={handleBlur}
                />
                <MaxNum>{`Max Chars: ${charLimit}`}</MaxNum>
            </Textarea>
            {showError && <ErrorField error="Field is required" />}
        </Wrapper>
    )
}

interface textAreaProps {
    border: string
}

const MaxNum = styled.div`
    font-family: 'Inter';
    font-size: 11px;
    line-height: 12px;
    text-align: left;
    color: var(--simple-gray);
    position: absolute;
    bottom: 5px;
`;

const Textarea = styled.div`
    border: ${(props: textAreaProps) => props.border};
    padding: 0 15px;
    border-radius: 9px;
    background-color: white;

    font-family: 'Inter';
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    color: black;

    position: relative;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3px;
`;

export default TextEditor;