import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconClicker } from "../../Home/HeaderHome/styles"
import { CardContainerHome, ContentCardHome, IconUser, RowIconCardHome, RowIconCardHomeDivider, TitleRowCardEllipsisWrap, TitleRowCardHome } from "./styles"
import { formatCompactNumber } from "../../../helpers/numberHelper"

export interface IModalityCard {
    id: string
    name: string
    comms: number
    seekers: number
    healers: number
    aliases: string[]
    description: string
}

const ModalityCard = ({
    data,
    onClick
}: {
    data: IModalityCard
    onClick: (data: IModalityCard) => void,
}) => {
    return (
        <CardContainerHome height={'135px'} onClick={() => onClick(data)}>
            <TitleRowCardHome>
                <TitleRowCardEllipsisWrap>{data.name}</TitleRowCardEllipsisWrap>
                <IconClicker
                    icon="chevron-right"
                    size="sm"
                    color="var(--dark-blue)"
                />
            </TitleRowCardHome>
            <ContentCardHome>
                <RowIconCardHome>
                    <FontAwesomeIcon
                        icon="globe"
                        size="sm"
                        color="var(--default-dark)"
                    />
                    {formatCompactNumber(data.comms)} Communities
                </RowIconCardHome>
                <RowIconCardHome>
                    <RowIconCardHome>
                        <IconUser src={'../icon_healer.png'} />
                        {formatCompactNumber(data.healers)} Healers
                    </RowIconCardHome>
                    <p>|</p>
                    <RowIconCardHome>
                        <IconUser src={'../icon_seeker.png'} />
                        {formatCompactNumber(data.seekers)} Seekers
                    </RowIconCardHome>
                </RowIconCardHome>
            </ContentCardHome>
        </CardContainerHome>
    )
}

export default ModalityCard;