import { Outlet, useLocation, useRoutes } from "react-router-dom";
import Header from "../../components/Header";
import AdminLeftMenu from "../../components/AdminCenter/AdminLeftMenu";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

function AdminLayout() {
  const location = useLocation();
  let screen = location.pathname;

  return (
    <>
      <Header isHealerProfile/>
      <BaseWrapperAdmin>
          <AdminLeftMenu screen={screen} />
          <Outlet />
      </BaseWrapperAdmin>
    </>
  );
}

const BaseWrapperAdmin = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 80px);
  width: 100%;
  margin-top: 80px;
  overflow: hidden;
  padding: 0px 50px;
  background-color: white;
`;

export default AdminLayout;
