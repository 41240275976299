import { faTimes, faCheck, faGlobe, faCircleDollar } from "@fortawesome/pro-regular-svg-icons";
import { WHITE } from "../../ClubIconsNavBar";
import Modal from 'react-bootstrap/Modal';
import ModalBaseProps from "../../../interfaces/modals/ModalBaseProps";
import { BaseInput, CancelButton, ClickableIcon, Row, SaveButton }
    from "../../SuperCenter/SuperAdminComponents/basicForSACenter";
import { Row1, Imp } from "../../../containers/Website/Form"
import styled from 'styled-components';
import { Column, HeaderCol, InfoContainer, Item, ICheck } from "./index"

interface ModalJoinProps extends ModalBaseProps {
}

const ModalJoin = ({
    show,
    onHide,
}: ModalJoinProps) => {

    return (
        <>
            <Modal className="modalSeePayment" show={show} onHide={onHide}>
                <Modal.Header>
                    <Modal.Title>Healer or represent a Healer Organization</Modal.Title>
                    <ClickableIcon
                        icon={faTimes}
                        size='xl'
                        color={'var(--black)'}
                        className="icon-times"
                        onClick={() => onHide()}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Columns>
                        <Column theme={{ main: 'column1' }}>
                            <HeaderCol theme={{ justify: 'flex-start' }}>
                                <ClickableIcon
                                    icon={faGlobe}
                                    size='xl'
                                    color={'var(--black)'}
                                />
                                <TextHeader>
                                    Support, Grow and Monetize Communities and Followers  
                                </TextHeader>
                            </HeaderCol>
                            <InfoContainer>
                                <List>
                                    <Item theme={{ size: '14px' }}>
                                        <ICheck icon={faCheck} size='sm' />
                                        Grow unlimited internal & external communities & groups.
                                    </Item>
                                    <Item theme={{ size: '14px' }}>
                                        <ICheck icon={faCheck} size='sm' />
                                        Message individuals or groups.
                                    </Item>
                                    <Item theme={{ size: '14px' }}>
                                        <ICheck icon={faCheck} size='sm' />
                                        Schedule events.
                                    </Item>
                                    <Item theme={{ size: '14px' }}>
                                        <ICheck icon={faCheck} size='sm' />
                                        Promote events, news & updates to communities.
                                    </Item>
                                    <Item theme={{ size: '14px' }}>
                                        <ICheck icon={faCheck} size='sm' />
                                        Retain names and emails of new community members.
                                    </Item>
                                    <Item theme={{ size: '14px' }}>
                                        <ICheck icon={faCheck} size='sm' />
                                        Zero algorithm content bias; content always appears in followers and members feeds
                                    </Item>
                                    <Item theme={{ size: '14px' }}>
                                        <ICheck icon={faCheck} size='sm' />
                                        SEO optimized directory listing.
                                    </Item>
                                </List>
                            </InfoContainer>
                        </Column>
                        <Column theme={{ main: 'column2' }}>
                            <HeaderCol theme={{ justify: 'flex-start' }}>
                                <ClickableIcon
                                    icon={faCircleDollar}
                                    size='xl'
                                    color={'var(--black)'}
                                />
                                <TextHeader>
                                    Generate Revenue    
                                </TextHeader>
                            </HeaderCol>
                            <InfoContainer>
                                <List>
                                    <Item theme={{ size: '14px' }}>
                                        <ICheck icon={faCheck} size='sm' />
                                        Receive referrals of seekers.
                                    </Item>
                                    <Item theme={{ size: '14px' }}>
                                        <ICheck icon={faCheck} size='sm' />
                                        Create VIP communities with premium content. *
                                    </Item>
                                    <Item theme={{ size: '14px' }}>
                                        <ICheck icon={faCheck} size='sm' />
                                        Deliver group or individual services online (live classes, memberships, online courses). *
                                    </Item>
                                    <Item theme={{ size: '14px' }}>
                                        <ICheck icon={faCheck} size='sm' />
                                        Receive tips from Seekers and Healers.*
                                    </Item>
                                </List>
                            </InfoContainer>
                        </Column>
                    </Columns>
                </Modal.Body>
                <Modal.Footer className='modalDiscardChanges-footer'></Modal.Footer>
            </Modal>
        </>
    );
};

const List = styled.div`
  margin: 24px 0;
`

const TextHeader = styled.div`
    color: #000;
    font-size: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
`

const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 0px;

  @media only screen and (max-width: 500px) {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 78vw;
        max-height: 70vh;
        overflow: scroll;
   }
`

export default ModalJoin;