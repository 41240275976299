import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { IconClicker } from "../Home/HeaderHome/styles";

interface ModalIconCloseProps {
    onClick: () => void;
}

const ModalIconClose = ({ onClick }: ModalIconCloseProps) => (
    <IconClicker
        onClick={onClick}
        icon="times"
        size='xl'
        color={'black'}
    />
)

export default ModalIconClose;