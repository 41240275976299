import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cleanHTML, cleanStyleHTML, getColorsInitials, getInitials } from "../../../../helpers/letterHelper";
import { formatCompactNumber } from "../../../../helpers/numberHelper";
import { IPanelRightHome } from "../../../../interfaces/HomeSearch/ItemFilterHome";
import { AvatarHealerCardHome, CreatorNoAvatar, DividerLineHealerHome, IconUser, NoAvatarHealerCardHome, RowAliasesHealerCard, RowIconCardHome, RowIconCardHomeDivider } from "../../../BaseComponents/HomeCards/styles";
import { IconClicker } from "../../HeaderHome/styles";
import { BaseCardPanelRight, ButtonViewMorePanelRight, DescriptionPanelRight, RowAliasesPanelRight, TitlePanelLeft, TitlePhotoWrapper } from "./styles";
import { useNavigate } from "react-router-dom";
import { faRectangleList } from "@fortawesome/pro-regular-svg-icons";

const PanelRightHome = ({
    data,
    show,
    onHide,
}: {
    data: IPanelRightHome
    show: boolean
    onHide: () => void
}) => {

    const navigate = useNavigate();

    const getHealerNoAvatar = () => {
        const num = [...data.title].reduce((acc, curr) => acc + curr.charCodeAt(0), 0);
        const colors = getColorsInitials(num);
        return <NoAvatarHealerCardHome
            background_color={colors.background}
            text_color={colors.color}
        >
            {getInitials(data.title)}
        </NoAvatarHealerCardHome>;
    }

    const getCreatorNoAvatar = () => {
        const num = [...data.guide?.name || ''].reduce((acc, curr) => acc + curr.charCodeAt(0), 0);
        const colors = getColorsInitials(num);
        return <CreatorNoAvatar
            background_color={colors.background}
            text_color={colors.color}
        >
            {getInitials(data.guide?.name || '')}
        </CreatorNoAvatar>;
    }

    const getLabelCount = () => {
        switch (data.type) {
            case 'PO':
                return ['Healers', 'Seekers'];
            case 'TP':
                return ['Healers', 'Seekers'];
            case 'MOD':
                return ['Healers', 'Seekers'];
            case 'EVENT':
                return ['Interested', 'Attending'];
            case 'COMM':
                return ['Followers', 'Members'];

            default:
                return ['Healers', 'Seekers'];
        }
    }

    const getButtonLabel = (): string => {
        switch (data.type) {
            case 'PO':
                return 'profile';
            case 'TP':
                return 'profile';
            case 'MOD':
                return 'details';

            default:
                return 'more';
        }
    }

    return (
        <BaseCardPanelRight show={show}>

            <TitlePanelLeft>
                <TitlePhotoWrapper>
                    {(data.type === 'PO' || data.type === 'TP') && (
                        data.avatar ? (
                            <AvatarHealerCardHome src={data.avatar} />
                        ) : (
                            getHealerNoAvatar()
                        )
                    )}
                    {data.title}
                </TitlePhotoWrapper>
                <IconClicker
                    icon="times"
                    color="var(--default-dark)"
                    size="lg"
                    onClick={onHide}
                />
            </TitlePanelLeft>

            {(data.type === 'COMM') && (
                <RowIconCardHome>
                    {getCreatorNoAvatar()}
                    {data.guide?.name || ''}
                </RowIconCardHome>
            )}

            <RowIconCardHome>
                {(data.type !== 'COMM') && (
                    <RowIconCardHome>
                        <IconUser src={'../icon_healer.png'} />
                        {formatCompactNumber(data.healers)} {getLabelCount()[0]}
                    </RowIconCardHome>
                )}
                {(data.type !== 'COMM') && (
                    <p>|</p>
                )}
                <RowIconCardHome>
                    <IconUser src={'../icon_seeker.png'} />
                    {formatCompactNumber(data.seekers)} {getLabelCount()[1]}
                </RowIconCardHome>
                {(data.type === 'COMM') && (
                    <p>|</p>
                )}
                {(data.type === 'COMM') && (
                    <RowIconCardHome>
                        <FontAwesomeIcon
                            icon={faRectangleList}
                            color="#333"
                            size="xs"
                        />
                        {formatCompactNumber(data.posts || 0)} Posts
                    </RowIconCardHome>
                )}
            </RowIconCardHome>
            {(data.type === 'COMM' && data.lastPost) && (
                <RowIconCardHome>
                    Last Post in {data.lastPost?.toDateString()}
                </RowIconCardHome>
            )}

            <DividerLineHealerHome />

            <RowAliasesPanelRight>
                {(data.aliases || []).map(s => (
                    <RowIconCardHomeDivider>{s}</RowIconCardHomeDivider>
                ))}
            </RowAliasesPanelRight>

            {(data.type !== 'COMM' && data.type !== 'EVENT') && (
                <ButtonViewMorePanelRight onClick={() => navigate(data.route)}>
                    View {getButtonLabel()}
                    <FontAwesomeIcon
                        icon='chevron-right'
                        color='white'
                        size='lg'
                    />
                </ButtonViewMorePanelRight>
            )}

            <DividerLineHealerHome />
            <DescriptionPanelRight dangerouslySetInnerHTML={{ __html: cleanStyleHTML(data.description) }} />
        </BaseCardPanelRight>
    )
}

export default PanelRightHome;