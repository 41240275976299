export const castIGLink = (username: string): string => {
    if (username.includes('/')) {
        return username;
    }
    return `https://www.instagram.com/${username.replace('@', '')}`
}

export const castFBLink = (username: string): string => {
    return username
}

export const castYTLink = (username: string): string => {
    if (username.includes('/')) {
        return username;
    }
    return `https://www.youtube.com/@${username.replace('@', '')}`
}

export const castTTLink = (username: string): string => {
    if (username.includes('/')) {
        return username;
    }
    return `https://www.tiktok.com/@${username.replace('@', '')}`
}

export const castTWLink = (username: string): string => {
    if (username.includes('/')) {
        return username;
    }
    return `https://www.twitter.com/${username.replace('@', '')}`
}

export const castSTLink = (username: string): string => {
    if (username.includes('/')) {
        return username;
    }
    return `https://${username.replace('@', '')}.substack.com`
}

export const castPLLink = (username: string): string => {
    return username
}

export const castIGUsername = (link: string): string => {
    return link.split('instagram.com/')[1];
}

export const castFBUsername = (link: string): string => {
    return link;
}

export const castYTUsername = (link: string): string => {
    return link.split('www.youtube.com/@')[1];
}

export const castTTUsername = (link: string): string => {
    return link.split('tiktok.com/@')[1];
}

export const castTWUsername = (link: string): string => {
    return link.split('twitter.com/')[1];
}

export const castSTUsername = (link: string): string => {
    return link.split('https://')[1];
}

export const castPLUsername = (link: string): string => {
    return link;
}