import OutsideClickHandler from "react-outside-click-handler";
import styled from "styled-components";
import { getStatusColor, getStatusName } from "../../../../helpers/chat/statusHelper";
import { useTranslation } from "react-i18next";
import { ChatServiceInterface } from "../../../../interfaces/chat/ChatServiceInterface";

const OutsideClickModalChatLeft = ({ setShowModal, status, ChatServiceInstance }: { setShowModal: (e: boolean) => void, status: string[], ChatServiceInstance:ChatServiceInterface }) => {
  const { t, i18n } = useTranslation();

  const updateStatus = (value:string) => {
    // dispatch(updateUserStatus(value,userInfo.username));
    ChatServiceInstance.updateStatus(value as 'available' | 'disconnected' | 'invisible' | 'busy' | 'be_right_back')
    setShowModal(false);
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowModal(false);
      }}
      display="flex"
    >
      <SelectStatusModal>
        <UListStatus>
          {status.map((value: string) => (
            <OptionStatus 
              value={value}
              onClick={() => updateStatus(value)}
            >
              <CircleStatus status={value} />
              <StatusLabel>{t(`chat_${getStatusName(value)}`)}</StatusLabel>
            </OptionStatus>
          ))}
        </UListStatus>
      </SelectStatusModal>
    </OutsideClickHandler>
  );
}

export default OutsideClickModalChatLeft;

const SelectStatusModal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--t-blue);

    border-radius: 0px 0px 3px 3px;
    height: auto;

    position: absolute;
    padding: 20px 10px 10px 10px;
    
    width: 100%;
    top: 30px;
`;

const UListStatus = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const StatusLabel = styled.a`
    color: white;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
`;

const OptionStatus = styled.li`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    gap: 8px;
`;

interface CircleStatusProps {
    status: string;
}

export const CircleStatus = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50px;
    background-color: ${(props: CircleStatusProps) => getStatusColor(props.status)};
`;