import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useEffect } from "react";
import { getClubsDashboardTable, getEventsDashboardTable, getGeneralDashboard, getServicesDashboardTable } from "../../store/actions/DashboardActions";

const useDashboard = () => {
  const dispatch = useDispatch();
  const generalDashboard = useSelector((state: RootState) => state.getDashboard.general)
  const servicesDashboardTable = useSelector((state: RootState) => state.getDashboard.servicesTable)
  const clubsDashboardTable = useSelector((state: RootState) => state.getDashboard.clubsTable)
  const eventsDashboardTable = useSelector((state: RootState) => state.getDashboard.eventsTable)
  const username = useSelector((state: RootState) => state.getUser.user.username);
  const userRoles = useSelector((state: RootState) => state.getUser.userRoles);

  const loadingGeneral = useSelector((state: RootState) => state.getDashboard.loadingGeneral);
  const loadingServicesTable = useSelector((state: RootState) => state.getDashboard.loadingServicesTable);
  const loadingEventsTable = useSelector((state: RootState) => state.getDashboard.loadingEventsTable);
  const loadingClubsTable = useSelector((state: RootState) => state.getDashboard.loadingClubsTable);

  const reloadDashboard = () => {
    if (username && userRoles?.includes('ORGADMIN')) {
      dispatch(getGeneralDashboard(username))
    }
  }

  const reloadDashboardTable = (table: 'services' | 'clubs' | 'events') => {
    if (username && userRoles?.includes('ORGADMIN')) {
      switch (table) {
        case 'services':
          dispatch(getServicesDashboardTable(username))
          break;
        case 'clubs':
          dispatch(getClubsDashboardTable(username))
          break;
        case 'events':
          dispatch(getEventsDashboardTable(username))
          break;
      }
    }
  }

  useEffect(() => {
    reloadDashboard();
  }, [username]);

  const castBigNumbers = (n: number): string => {
    if (n < 1000) {
      return n.toString();
    } else if (n < 1000000) {
      return (n / 1000).toFixed(1) + 'K';
    } else {
      return (n / 1000000).toFixed(1) + 'M';
    }
  }

  const castMoneyNumbers = (n: number): string => {
    const partes = n.toFixed(2).split('.');
    const entero = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    const decimal = partes[1];
    return `${entero}.${decimal}`;
  }

  const castDateTimestamp = (timestamp: string): string => new Date(parseInt(timestamp)).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });

  return {
    generalDashboard,
    servicesDashboardTable,
    clubsDashboardTable,
    eventsDashboardTable,
    loadingGeneral,
    loadingServicesTable,
    loadingEventsTable,
    loadingClubsTable,
    castBigNumbers,
    castMoneyNumbers,
    castDateTimestamp,
    reloadDashboard,
    reloadDashboardTable,
  }
}

export default useDashboard;