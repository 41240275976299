import { useEffect, useMemo, useState, useContext } from "react";
import TwoColumns from "../../../TwoColumns";
import {
    BaseInputWrapper,
    CancelButton,
    ContentWrapper,
    CreatableSelectWrapper,
    Row,
    Title,
    CreateBtn,
} from "../Modals/components";
import { DropdownIndicator, customStylesRSMultiNoDropdownWhite, customStylesRSMultiWhite } from "../../../../helpers/selectHelper";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getDataSDK, getDataSDKResponse } from "../../../../helpers/backendHelper";
import useBenefitsSA from "../../../../hooks/Superadmin/Modalities/useBESA";
import useProfessionalOrgsSA from "../../../../hooks/Superadmin/Modalities/usePOSA";
import useTherapeuticAreasSA from "../../../../hooks/Superadmin/Modalities/useTASA";
import useTopPractitionersSA from "../../../../hooks/Superadmin/Modalities/useTPSA";
import useModalitiesSA from "../../../../hooks/Superadmin/Modalities/useModalitiesSA";
import { ItemCreateExisting, ItemCreateNew, ItemWebsiteCreateExisting, ItemWebsiteCreateNew } from "../../../../interfaces/Superadmin/modalities/Modality/Modality";
import { useSelector } from "react-redux";
import CreatePOModal from "../Modals/CreatePOModal";
import CreateTPModal from "../Modals/CreateTPModal";
import { ISignUpUnregisteredOrganizationRequest } from "@vibe/sdk/dist/interfaces/AccessManager/unregisteredOrg";
import { IOrganizationExtended } from "@vibe/sdk/dist/interfaces/Admin/Organization";
import { ISignUpUnregisteredIndependentRequest } from "@vibe/sdk/dist/interfaces/AccessManager/unregisteredIndependent";
import { ContextSuccess } from "../../../../Context";
import { BaseFieldState, getFieldState } from "../../../BaseComponents/utils";
import { SelectMultiWrapper } from "../../../BaseComponents/SelectMultiWrapper";
import { Imp } from "../../../signupsetuphealerind";
import TextEditor from "../../../BaseComponents/TextEditor";
import Modality from "@vibe/sdk/interfaces/Modalities/modalities/Modality/Modality";
import BlankTable from "../../../BaseComponents/BlankTable";
import { onHideType } from "../../../BaseComponents/Modals/ModalUnsaved";
import useActiveCountry from "../../../../hooks/Locations/useActiveCountry";
import ButtonSpinner from "../../../BaseComponents/ButtonSpinner";
import { ItemCheckbox, SquareIcon } from "../../../Events/TabDateType";
import { faSquare, faSquareCheck } from "@fortawesome/pro-regular-svg-icons";
import { cleanHTML } from "../../../../helpers/letterHelper";
export interface ModalityOptions<T> {
    value: T;
    label: string;
    __isNew__: boolean;
}

export interface valueWebsite {
    id: string;
    website: string;
}

export const CustomOption = ({ innerProps, isDisabled }: any) => <></>;

const CreateModality = () => {
    const { t } = useTranslation();
    const currentSuccess = useContext(ContextSuccess);
    const navigate = useNavigate();
    const username = useSelector((state: any) => state.getUser.userInfo.username);

    const { activeCountriesReactSelect } = useActiveCountry();

    const { benefitsPages, reloadBenefits } = useBenefitsSA({
        pageSizeProp: 100,
        searchName: '',
    });
    const { poPages, reload } = useProfessionalOrgsSA(100);
    const { taPages, reloadTherapeuticAreas } = useTherapeuticAreasSA({
        pageSizeProp: 100,
        searchName: '',
    });
    const { tpPages, reloadTopPractitioners } = useTopPractitionersSA(100);
    const { modalitiesPages, reloadModalities } = useModalitiesSA({
        pageSizeProp: 1000,
        searchName: '',
    });

    const [loadingCreate, setLoadingCreate] = useState<boolean>(false);

    const [ModalityName, setModalityName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [ModalityPrivate, setModalityPrivate] = useState<BaseFieldState<boolean>>(getFieldState(false));
    const [ModalityDescription, setModalityDescription] = useState<BaseFieldState<string>>(getFieldState(''));
    const [ModalityWhat, setModalityWhat] = useState<BaseFieldState<string>>(getFieldState(''));
    const [ModalityParentsID, setModalityParentsID] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [ModalityAliases, setModalityAliases] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [ModalityTA, setModalityTA] = useState<BaseFieldState<(ItemCreateExisting | ItemCreateNew)[]>>(getFieldState([]));
    const [ModalityBE, setModalityBE] = useState<BaseFieldState<(ItemCreateExisting | ItemCreateNew)[]>>(getFieldState([]));

    const [ModalitySelectedTA, setModalitySelectedTA] = useState<ModalityOptions<string>[]>([]);
    const [ModalitySelectedBE, setModalitySelectedBE] = useState<ModalityOptions<string>[]>([]);
    const [ModalitySelectedParents, setModalitySelectedParents] = useState<ModalityOptions<string>[]>();
    const [ModalitySelectedAliases, setModalitySelectedAliases] = useState<ModalityOptions<string>[]>([]);

    const [ModalityOptionsTA, setModalityOptionsTA] = useState<ModalityOptions<string>[]>([]);
    const [ModalityOptionsBE, setModalityOptionsBE] = useState<ModalityOptions<string>[]>([]);
    const [ModalityOptionsPO, setModalityOptionsPO] = useState<ModalityOptions<valueWebsite>[]>([]);
    const [ModalityOptionsTP, setModalityOptionsTP] = useState<ModalityOptions<valueWebsite>[]>([]);
    const [ModalityOptionsParent, setModalityOptionsParent] = useState<ModalityOptions<string>[]>([]);

    const [ModalityPOs, setModalityPOs] = useState<(ISignUpUnregisteredOrganizationRequest | IOrganizationExtended)[]>([]);
    const [ModalityTPs, setModalityTPs] = useState<(ISignUpUnregisteredIndependentRequest | IOrganizationExtended)[]>([]);

    const [showCreate, setShowCreate] = useState<boolean>(false);
    const [showCreateTP, setShowCreateTP] = useState<boolean>(false);

    useEffect(() => {
        setModalityOptionsBE(benefitsPages[0].map((b) => ({
            label: b.name,
            value: b.id,
            __isNew__: false,
        })))
    }, [benefitsPages])

    useEffect(() => {
        setModalityOptionsPO(poPages[0].map((b) => ({
            label: b.name,
            value: {
                id: b.id,
                website: b.website,
            },
            __isNew__: false,
        })))

    }, [poPages])

    useEffect(() => {
        setModalityOptionsTA(taPages[0].map((b) => ({
            label: b.name,
            value: b.id,
            __isNew__: false,
        })))
    }, [taPages])

    useEffect(() => {
        setModalityOptionsTP(tpPages[0].map((b) => ({
            label: b.name,
            value: {
                id: b.id,
                website: b.website,
            },
            __isNew__: false,
        })))
    }, [tpPages])

    useEffect(() => {
        setModalityOptionsParent(modalitiesPages[0].map((b) => ({
            label: b.name,
            value: b.id,
            __isNew__: false,
        })))
    }, [modalitiesPages])

    const cleanForm = () => {
        setModalityName(getFieldState(''));
        setModalityDescription(getFieldState(''));
        setModalityWhat(getFieldState(''));
        setModalityParentsID(getFieldState([]));
        setModalityTA(getFieldState([]));
        setModalityBE(getFieldState([]));
        setModalityAliases(getFieldState([]));
        setModalityPrivate(getFieldState(false));

        setLoadingCreate(false);

        setModalitySelectedTA([]);
        setModalitySelectedBE([]);
        setModalitySelectedParents([]);
        setModalitySelectedAliases([]);
    }

    const getItemCreate = (updatedList: ModalityOptions<string>[]): (ItemCreateExisting | ItemCreateNew)[] => {
        return updatedList.map(item => {
            if (item.__isNew__) {
                return {
                    type: 'NEW',
                    name: item.label,
                }
            }
            return {
                type: 'EXISTING',
                id: item.value
            }
        })
    }

    const onChangeModalityName = (event: React.FormEvent<HTMLInputElement>) => {
        if (event) {
            setModalityName({
                ...ModalityName,
                value: event?.currentTarget.value
            });
        }
    }

    const onChangeModalityDescription = (event: string) => {
        if (event) {
            setModalityDescription({
                ...ModalityDescription,
                value: cleanHTML(event)
            });
        }
    }

    const onChangeModalityWhat = (event: string) => {
        if (event) {
            setModalityWhat({
                ...ModalityWhat,
                value: cleanHTML(event)
            });
        }
    }

    const onChangeModalityTA = (updatedList: ModalityOptions<string>[]) => {
        setModalityTA({
            ...ModalityTA,
            value: getItemCreate(updatedList),
        });
        setModalitySelectedTA(updatedList);
    }

    const onChangeModalityBE = (updatedList: ModalityOptions<string>[]) => {
        setModalityBE({
            ...ModalityBE,
            value: getItemCreate(updatedList),
        });
        setModalitySelectedBE(updatedList);
    }

    const onChangeModalityAliases = (updatedList: ModalityOptions<string>[]) => {
        setModalityAliases({
            ...ModalityAliases,
            value: updatedList.map(item => item.value),
        });
        setModalitySelectedAliases(updatedList);
    }

    const onChangeModalityParents = (updatedList: ModalityOptions<string>[]) => {
        setModalityParentsID({
            ...ModalityParentsID,
            value: updatedList.map(item => item.value),
        })
        setModalitySelectedParents(updatedList);
    }

    const errorsInForm = () => {
        let hasErrors = false;

        if (!ModalityName.value || ModalityName.value.length <= 0) {
            hasErrors = true;
            setModalityName({
                ...ModalityName,
                showError: true,
                error: 'Name is required',
            })
        } else {
            setModalityName(getFieldState(ModalityName.value));
        }

        return hasErrors;
    }

    const onHideUnsaved = (type: onHideType) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);
                onCreate();
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                currentSuccess?.setLoadingModal(false);
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                currentSuccess?.setLoadingModal(false);
                cleanForm();
                navigate(-1);
                break;

            default:
                break;
        }
    }

    const onCancel = () => {
        currentSuccess?.setModalWarning(
            'UNSAVED',
            (type) => onHideUnsaved(type),
            'Do you want to discard changes?'
        );
    }

    const onCreate = () => {
        if (errorsInForm()) return;

        const toSend: Omit<Modality, "id"> = {
            name: ModalityName.value || '',
            description: ModalityDescription.value || '',
            parentMSIds: ModalityParentsID.value || [],
            aliases: ModalityAliases.value,
            therapeuticAreasCreate: ModalityTA.value,
            topPractitionersCreate: ModalityTPs as  ISignUpUnregisteredIndependentRequest[] || [],
            professionalOrgCreate: ModalityPOs as  ISignUpUnregisteredOrganizationRequest[] || [],
            benefitsCreate: ModalityBE.value,
            createdBy: username,
            active: true,
            createdAt: `${new Date().valueOf()}`,
            private: ModalityPrivate.value,
            expect: ModalityWhat.value || '',
        }

        setLoadingCreate(true);
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.createModality(toSend, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 201) {
                    currentSuccess?.setSuccessData(true, true, t('toast_modalityUpdated'));
                    cleanForm();
                    navigate(-1);
                    return;
                }
                currentSuccess?.setSuccessData(false, true, t('toast_errorCreatingModality'));
            })
            .catch(() => currentSuccess?.setSuccessData(false, true, t('toast_errorCreatingModality')))
            .finally(() => setLoadingCreate(false));
    }

    const onHideCreateModalPO = (type: 'discard' | 'save', form?: ISignUpUnregisteredOrganizationRequest) => {
        if (type == 'save' && form) {
            setModalityPOs([...ModalityPOs, form]);
        }
        setShowCreate(false);
    }

    const onHideCreateModalTP = (type: 'discard' | 'save', form?: ISignUpUnregisteredIndependentRequest) => {
        if (type == 'save' && form) {
            setModalityTPs([...ModalityTPs, form]);
        }
        setShowCreateTP(false);
    }

    const onHideDeletePO = (type: onHideType, id: string) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);
                
                setModalityPOs((pos) => {
                    const idx = pos.findIndex((po) => ('organization' in po ? po.organization.website || '' : po.website || '') === id);
                    if (idx !== -1) pos.splice(idx, 1);
                    return pos
                    
                })

                currentSuccess?.setModalsOff();
                currentSuccess?.setLoadingModal(false);
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                currentSuccess?.setLoadingModal(false);
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                currentSuccess?.setLoadingModal(false);
                break;

            default:
                break;
        }
    }

    const handleDeletePO = (data: string) => {
        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => onHideDeletePO(type, data),
            'Are you sure you want to delete this Professional Organization?'
        );
    }

    const onHideDeleteTP = (type: onHideType, id: string) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);
                setModalityTPs((pos) => {
                    const idx = pos.findIndex((po) => ('organization' in po ? po.organization.website || '' : po.website || '') === id);
                    if (idx !== -1) pos.splice(idx, 1);
                    return pos
                    
                })

                currentSuccess?.setModalsOff();
                currentSuccess?.setLoadingModal(false);
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                currentSuccess?.setLoadingModal(false);
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                currentSuccess?.setLoadingModal(false);
                break;

            default:
                break;
        }
    }

    const handleDeleteTP = (data: string) => {
        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => onHideDeleteTP(type, data),
            'Are you sure you want to delete this Top Healer?'
        );
    }

    const handleCreatePrivate = () => {
        setModalityPrivate((old) => ({
            ...old,
            value: !old.value,
        }));
    }

    return (
        <>
            <ContentAdmin>
                <Title>Create Modality and Service</Title>

                <ContentWrapper>
                    <TwoColumns
                        left={<FormWrapper>
                            <Label>Name<Imp>*</Imp></Label>
                            <BaseInputWrapper
                                placeholder=""
                                value={ModalityName.value || ''}
                                onChangeValue={onChangeModalityName}
                                showError={ModalityName.showError || false}
                                error={ModalityName.error || ''}
                            />
                            <Label>Aliases</Label>
                            <CreatableSelectWrapper
                                options={undefined}
                                styles={customStylesRSMultiNoDropdownWhite()}
                                value={ModalitySelectedAliases || []}
                                onChangeValue={onChangeModalityAliases}
                                placeholder="Type and press enter"
                                showError={ModalityBE.showError || false}
                                error={ModalityBE.error || ''}
                            />
                            <Label>Description</Label>
                            <TextEditor
                                value={ModalityDescription.value || ''}
                                setValue={onChangeModalityDescription}
                                charLimit={2500}
                                placeholder='Please describe the modality...'
                                showError={ModalityDescription.showError || false}
                            />
                            <Label>What to Expect</Label>
                            <TextEditor
                                value={ModalityWhat.value || ''}
                                setValue={onChangeModalityWhat}
                                charLimit={2500}
                                placeholder='Please describe what to expect about modality...'
                                showError={ModalityWhat.showError || false}
                            />
                        </FormWrapper>}

                        right={<FormWrapper>
                            <Label>Associated Modalities</Label>
                            <SelectMultiWrapper
                                options={ModalityOptionsParent}
                                styles={customStylesRSMultiWhite()}
                                components={{
                                    DropdownIndicator
                                }}
                                value={ModalitySelectedParents || []}
                                onChangeValue={onChangeModalityParents}
                                placeholder="Select parents for this modality"
                                showError={ModalityParentsID.showError || false}
                                error={ModalityParentsID.error || ''}
                            />
                            <Label>Benefits</Label>
                            <CreatableSelectWrapper
                                options={ModalityOptionsBE}
                                styles={customStylesRSMultiNoDropdownWhite()}
                                value={ModalitySelectedBE || []}
                                onChangeValue={onChangeModalityBE}
                                placeholder="Select or create one by pressing enter"
                                showError={ModalityBE.showError || false}
                                error={ModalityBE.error || ''}
                            />
                            <Label>Conditions Treated</Label>
                            <CreatableSelectWrapper
                                options={ModalityOptionsTA}
                                styles={customStylesRSMultiNoDropdownWhite()}
                                value={ModalitySelectedTA || []}
                                onChangeValue={onChangeModalityTA}
                                placeholder="Select or create one by pressing enter"
                                showError={ModalityTA.showError || false}
                                error={ModalityTA.error || ''}
                            />
                            <ItemCheckbox onClick={handleCreatePrivate}>
                                <SquareIcon
                                    icon={ModalityPrivate?.value ? faSquareCheck : faSquare}
                                    size='xl'
                                    color={ModalityPrivate?.value ? 'black' : "white"}
                                    active={ModalityPrivate?.value ?? false}
                                />
                                Private Modality
                            </ItemCheckbox>
                        </FormWrapper>}
                    />

                    <Row>
                        <h1>Professional Organizations</h1>
                        <CreateBtn onClick={() => { setShowCreate(true); }}>
                            + Create Professional Organization
                        </CreateBtn>
                    </Row>
                    <BlankTable
                        headers={['Name', 'Website', 'State', 'Country', 'Zipcode']}
                        items={ModalityPOs.map((p: ISignUpUnregisteredOrganizationRequest | IOrganizationExtended) => ({
                            id: 'organization' in p ? p.organization.website || '' : p.website || '',
                            labels: 'organization' in p ? [
                                p.organization.organizationName || '',
                                p.organization.website || '',
                                p.organization.state || '',
                                p.organization.country || (activeCountriesReactSelect.find((country) => country.value === p.organization.countryCode)?.label) || '',
                                p.organization.zipcode || '',
                            ] : [
                                p.name || '',
                                p.website || '',
                                p.state || '',
                                p.country || (activeCountriesReactSelect.find((country) => country.value === p.countryCode)?.label) || '',
                                p.zipcode || '',
                            ],
                        }))}
                        actions={['DELETE']}
                        actionsHandlers={{
                            handleDelete: handleDeletePO,
                        }}
                        maxWidth={'200px'}
                    />

                    <Row>
                        <h1>Top Healers</h1>
                        <CreateBtn onClick={() => { setShowCreateTP(true); }}>
                            + Create Top Healer
                        </CreateBtn>
                    </Row>
                    <BlankTable
                        headers={['Name', 'Website', 'State', 'Country', 'Zipcode']}
                        items={ModalityTPs.map((p: ISignUpUnregisteredOrganizationRequest | IOrganizationExtended) => ({
                            id: 'organization' in p ? p.organization.website || '' : p.website || '',
                            labels: 'organization' in p ? [
                                p.organization.organizationName || '',
                                p.organization.website || '',
                                p.organization.state || '',
                                p.organization.country || (activeCountriesReactSelect.find((country) => country.value === p.organization.countryCode)?.label) || '',
                                p.organization.zipcode || '',
                            ] : [
                                p.name || '',
                                p.website || '',
                                p.state || '',
                                p.country || (activeCountriesReactSelect.find((country) => country.value === p.countryCode)?.label) || '',
                                p.zipcode || '',
                            ],
                        }))}
                        actions={['DELETE']}
                        actionsHandlers={{
                            handleDelete: handleDeleteTP,
                        }}
                        maxWidth={'200px'}
                    />

                    <ActionWrapper>
                        <CancelButton onClick={onCancel}>
                            {t('button__cancel')}
                        </CancelButton>
                        <WrapperButton>
                            <ButtonSpinner
                                loading={loadingCreate}
                                handleClick={onCreate}
                                text={t('button__save')}
                                hasIcon={false}
                            />
                        </WrapperButton>
                    </ActionWrapper>
                </ContentWrapper>

            </ContentAdmin>
            <CreatePOModal
                show={showCreate}
                onHide={onHideCreateModalPO}
            />
            <CreateTPModal
                show={showCreateTP}
                onHide={onHideCreateModalTP}
            />
        </>
    )
}

const WrapperButton = styled.div`
  width: 150px;
  height: 100%;
`;

const Label = styled.div`    
    width: 100%;
    text-align: left;
    margin: 3px 0;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`;

const Scroll = styled.div`
    display: flex;
    width: 100%;
    overflow-y: scroll;
`;

const RowTable = styled.div`
    display: flex;
    width: 100%;
    overflow-y: scroll;
`;

const ContentAdmin = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  justify-items: flex-start;
  align-items: flex-start;
  flex-direction: column;
  overflow-y: scroll;
  margin-bottom: 30px;
  gap: 10px;
`;

export const ListWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const ListItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    padding-bottom: 10px;
`;

const FormWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    align-items: flex-start;
`;

const ActionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 20px;
    justify-content: flex-end;
`;

export const AddYOBtn = styled.button`
    align-items: center;
    background-color: var(--electric-yellow);
    border-radius: 12px;
    display: flex;
    height: 33px;
    padding: 18px;
    margin-left: 13px;
    border: none;
    cursor: pointer;
    /* Find a better way */
    color: var(--white);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
`;

const RowLeft = styled(Row)`
    justify-content: flex-end;
`;

export default CreateModality;