import { useDispatch, useSelector } from "react-redux";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";

import "./styles.scoped.css";
import { RootState } from "../../store/store";
import { getEventInfo } from "../../store/actions/eventActions";
import { useParams } from "react-router-dom";
import { CenterInsideWrap, TabCenterInside } from "../Bulletin";
import { TabsCenterClubs, TabsContainerClubs, TitleView_Center } from "../../components/ClubViewNavBar";
import { useEffect, useState } from "react";
import ModalComingSoon from "../../components/BaseComponents/Modals/ModalComingSoon";
import { tabInterface } from "../../components/FeedCenterSection";
import EventViewInformation from "../../components/EventViewInformation";
import PostsNew from "../Posts/PostsNew";
import EventPaticipanting from "../../components/EventPaticipanting";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import { GetEventModeratorsResponse } from "@vibe/sdk/dist/interfaces/Events/getEventModerators/getEventModeratorsResponse";
import styled from "styled-components";
import EventStateLabel from "../../components/BaseComponents/Events/EventStateLabel";
import { mapEventToCard } from "../../hooks/Events/useEventsPanel";

const tabsAll: tabInterface[] = [
  {
    id: 0,
    label: 'Information',
    comingSoon: false,
  },
  {
    id: 1,
    label: 'Participating',
    comingSoon: false,
  }
]

const Event = ({ ChatServiceInstance }: { ChatServiceInstance: ChatServiceInterface }) => {
  const dispatch = useDispatch();
  const { id = '' } = useParams();

  const event = useSelector((state: RootState) => state.getEvent.event);
  const user = useSelector((state: RootState) => state.getUser.userInfo);

  const [selected, setSelected] = useState<number>(0);
  const [soon, setSoon] = useState<boolean>(false);
  const [moderators, setModerators] = useState<GetEventModeratorsResponse[]>([]);

  const getEventModerators = () => {
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Events.getEventModerators(id, dataSDK.token);
      })
      .then((results) => {
        if (results.statusCode === 200) {
          setModerators(results.body as GetEventModeratorsResponse[]);
        }
      })
      .catch(console.error)
  };

  const reloadEvent = () => {
    if (id === '') return;
    dispatch(getEventInfo(id, user?.username || ''))
  };

  useEffect(() => {
    reloadEvent();
  }, [id])

  useEffect(() => {
    reloadEvent();
  }, [])

  function selectScreen(screen: number) {
    switch (screen) {
      case 0:
        return <Scroller_View>
          <EventViewInformation
            getEventModerators={getEventModerators}
            moderators={moderators}
            setModerators={setModerators}
            reloadEvent={reloadEvent}
          />
          <ContainerPosts>
            {id && <PostsNew postRequired={'EVENT'} eventId={id} />}
          </ContainerPosts>

        </Scroller_View>

      case 1:
        return <EventPaticipanting
          id={id}
          event={event}
          getEventModerators={getEventModerators}
          moderators={moderators}
          ChatServiceInstance={ChatServiceInstance}
        />;

      default:
        return <></>;
    }
  }

  return (
    <CenterInsideWrap>
      <TitleView_Center>{event.name || ''}</TitleView_Center>
      <EventStateLabel item={mapEventToCard(event)} isModalDetail/>

      <TabsContainerClubs>

        {(soon) &&
          <ModalComingSoon
            title={'Coming Soon!'}
            onHide={setSoon}
            bottom={'calc(80vh - 40px)'}
            left={'50%'}
          />
        }

        <TabsCenterClubs>
          {tabsAll.map(t => (
            <TabCenterInside
              active={selected === t.id}
              onClick={() => setSelected(t.id)}
            >
              {t.label}
            </TabCenterInside>
          ))}
        </TabsCenterClubs>

      </TabsContainerClubs>

      {selectScreen(selected)}
    </CenterInsideWrap>
  );
}

export default Event;

const ContainerPosts = styled.div`
  margin-top: 20px
`

const Scroller_View = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 380px);
  overflow: scroll;

  
`;
