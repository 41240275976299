import styled from "styled-components";
import { Loader } from "../BaseComponents/Loader";

const TableLoader = () => (
  <tr>
    <td colSpan={10}>
      <CenterLoader><Loader color='white' /></CenterLoader>
    </td>
  </tr>
)

const CenterLoader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px;
`;

export default TableLoader;