import React, { useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { ApproveOrganization, getOrganizationStatus, getOrganizationType } from "../../../store/actions/orgsActions";
import BlankTable from "../../BaseComponents/BlankTable";
import SearchInput from "../../BaseComponents/SearchInput";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../store/store";
import { PAGE_SIZES } from "../../AdminCenter/MyEvents/EventAdmin";
import { orgTypesZaahah } from "../../../helpers/orgTypeHelper";
import { getRole } from "../../../hooks/Clubs/useClubsList";
import { capitalize, capitalizeStatus } from "../../../helpers/letterHelper";
import useOrgList, { useOrgsSetupType, useOrgsStatusType, useOrgsTypeType } from "../../../hooks/Superadmin/Healers/useOrgList";
import SelectItem from "../../../interfaces/SelectItem";
import Selector from "../../BaseComponents/Selector";
import { ContextSuccess } from "../../../Context";
import i18n from "../../../i18n";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import { onHideType } from "../../BaseComponents/Modals/ModalUnsaved";
import { BaseFieldState, getFieldState } from "../../BaseComponents/utils";
import { Title } from "../SuperAdminComponents/basicForSACenter";
import ModalContributor from "../Contributors/ModalContributor";
import Contributor from "@vibe/sdk/interfaces/Modalities/contributors/Contributor";
import { mockContributor } from "../Contributors/Contributors";
import useContributorsSA from "../../../hooks/Superadmin/Contributors/useContributorsSA";
import Modality from "@vibe/sdk/interfaces/Modalities/modalities/Modality/Modality";

export const ORGSTATUS: { [key: string]: string } = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  PENDING_APPROVAL: "Pending Approval",
  PENDING_CONFIRMATION: "Pending Confirmation",
  DELETED: "Deleted"
}

export const setupStatusOptions: SelectItem[] = [{
  label: "All",
  value: "ALL"
},
{
  label: 'Incomplete',
  value: 'INCOMPLETE',
},
{
  label: 'Complete',
  value: 'COMPLETE',
}]

function Healers() {
  const { t } = i18n;

  const currentSuccess = useContext(ContextSuccess);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userRoles = useSelector((state: RootState) => state.getUser.userRoles);
  const orgStatus = useSelector((state: RootState) => state.getOrgs.organizationStatus);
  const orgT = useSelector((state: RootState) => state.getOrgs.organizationTypes);

  const [idActive, setIdActive] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [searchPage, setSearchPage] = useState<string>('');
  const [searchSetupStatus, setSearchSetupStatus] = useState<string>('');
  const [possibleMods, setPossibleMods] = useState<Modality[]>([]);
  const [searchStatus, setSearchStatus] = useState<useOrgsStatusType | any>('');
  const [searchType, setSearchType] = useState<useOrgsTypeType | any>('');
  const [page, setPage] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: '', value: '' }));
  const [selectedType, setSelectedType] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: 'All', value: 'ALL' }));
  const [selectedStatus, setSelectedStatus] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: 'All', value: 'ALL' }));
  const [selectedSetupStatus, setSelectedSetupStatus] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: 'All', value: 'ALL' }));
  const [dotsActive, setDotsActive] = useState<boolean>(false);
  const [listaPos, setListaPos] = useState<boolean>(true);

  const [statusOptions, setStatusOptions] = useState<SelectItem[]>([]);
  const [typeOptions, setTypeOptions] = useState<SelectItem[]>([]);

  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [selected, setSelected] = useState<Contributor>({ ...mockContributor });

  const { reloadList, allFilteredOrgs } = useOrgList({
    searchName: search,
    role: getRole(userRoles),
    searchStatus: (selectedStatus?.value?.value) as useOrgsStatusType || 'ALL',
    searchType: (selectedType?.value?.value) as useOrgsTypeType || 'ALL',
    searchSetup: (selectedSetupStatus?.value?.value) as useOrgsSetupType || 'ALL'
  });

  const {
    ContributorsFiltered,
    reloadContributors
  } = useContributorsSA({
    SearchTerm: '',
  });

  const options = PAGE_SIZES.map((num) => ({
    value: `${num}`,
    label: `${num} results`,
  }))

  function handleCloseModalDelete(type: onHideType, orgId: string) {
    switch (type) {
      case 'discard':
        currentSuccess?.setModalsOff();
        break;

      case 'close':
        currentSuccess?.setModalsOff();
        break;

      case 'save':
        currentSuccess?.setLoadingModal(true);
        getDataSDK()
          .then((dataSDK: getDataSDKResponse) => {
            return dataSDK.sdk.Admin.deleteOrganization(orgId, dataSDK.token);
          })
          .then((results) => {
            if (results.statusCode === 201) {
              currentSuccess?.setSuccessData(true, true, t('toast_yoSuccessDelete'));
              currentSuccess?.setModalsOff();
            } else {
              currentSuccess?.setSuccessData(false, true, t('toast_anErrorOcurred'));
            }
          })
          .catch((error) => currentSuccess?.setSuccessData(false, true, t('toast_anErrorOcurred'), error))
          .finally(() => {
            reloadList()
            currentSuccess?.setLoadingModal(false);
          })
        break;

      default:
        break;
    }
  };

  const handleClickRow = (orgId: string) => {
    navigate(`../../../../../superadmin/healers/${orgId}/view`)
  }

  const handleUpdate = (orgId: string) => {
    navigate(`../../../../../superadmin/healers/${orgId}/update`)
  }

  const handleDelete = (orgId: string) => {
    currentSuccess?.setModalWarning(
      'DELETE',
      (type) => handleCloseModalDelete(type, orgId),
      'Do you want to delete this healer?'
    );
  }

  const handleApprove = (orgId: string) => {
    setIdActive(orgId)
    dispatch<any>(ApproveOrganization(orgId))
      .then((results: string) => {
        if (results === 'Approved') currentSuccess?.setSuccessData(true, true, 'Healer approved!');
        else currentSuccess?.setSuccessData(false, true, 'Error approving Healer.', typeof results === 'string' ? results : '');
        setIdActive('')
      })
      .catch(console.log)
      .finally(() => reloadList());
  }

  const handleContributor = (data: string) => {
    const template = allFilteredOrgs.find(t => t.id === data);
    if (template) {
      setPossibleMods(template.modalities || []);

      const contributor = ContributorsFiltered.find(t => t.username === template.created_by);
      if (contributor) {
        setSelected(contributor);
        setShowEdit(true);
        return;
      }

      const orgadmin = template.orgAdmin;
      setSelected({
        username: template.created_by || '',
        fullname: orgadmin ? `${orgadmin?.firstname || ''} ${orgadmin?.lastname || ''}` : template.name,
        modalities: [],
        createdAt: '',
        createdBy: '',
      })
      setShowCreate(true);
      return;
    }

    currentSuccess?.setSuccessData(false, true, 'No organization or healer selected.');
  }

  useEffect(() => {
    dispatch(getOrganizationStatus());
    dispatch(getOrganizationType());
  }, []);

  useEffect(() => {
    setStatusOptions([{ label: "All", value: "ALL" }, ...(orgStatus || []).map((e: any) => ({
      label: capitalizeStatus(e),
      value: e,
    }))])
    setTypeOptions([{ label: "All", value: "ALL" }, ...orgTypesZaahah(orgT || []).map(([value, label]: [string, string]) => ({
      label: capitalize(value),
      value: value,
    }))])
  }, [orgStatus, orgT]);

  const [height, setHeight] = useState<any>(0)
  const ref = useRef(null)

  const count = (ref: any) => {
    if (ref.current) {
      setHeight(ref.current.clientHeight)
      if((ref.current.clientHeight + 155) > window.innerHeight - 200){
        setDotsActive(false)
      }else{
        setDotsActive(true)
      }
    }
  }

  const handleEvent = (event: any) => 
  {
    const bounds = event.currentTarget.getBoundingClientRect();
    const y = event.clientY - bounds.top;

    if((height - y) > 192){
      setListaPos(true)
    }else{
      setListaPos(false)
    }
  }

  const onResize = React.useCallback(() => {
    count(ref)
  }, []);

  React.useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [allFilteredOrgs]);

  return (
    <ContentOrganizations>
      <Title>Healers</Title>
      
      <WrapperRow>
        <WrapperRow2>
          <SearchInput
            filteredItem={search}
            setFilteredItem={setSearch}
            border={'1px solid var(--simple-gray)'}
            placeholder={`Search by name`}
          />
        </WrapperRow2>
        <WrapperRow2>
          <Selector
            options={typeOptions}
            selected={selectedType}
            setSelected={setSelectedType}
            searchItem={searchType}
            setSearchItem={setSearchType as (data: string) => void}
            border={'1px solid var(--simple-gray)'}
            placeholder="Type"
          />
        </WrapperRow2>
        <WrapperRow2>
          <Selector
            options={statusOptions}
            selected={selectedStatus}
            setSelected={setSelectedStatus}
            searchItem={searchStatus}
            setSearchItem={setSearchStatus as (data: string) => void}
            border={'1px solid var(--simple-gray)'}
            placeholder="Status"
          />
        </WrapperRow2>
        <WrapperRow2>
          <Selector
            options={setupStatusOptions}
            selected={selectedSetupStatus}
            setSelected={setSelectedSetupStatus}
            searchItem={searchSetupStatus}
            setSearchItem={setSearchSetupStatus}
            border={'1px solid var(--simple-gray)'}
            placeholder="Set up"
          />
        </WrapperRow2>
      </WrapperRow>
      <Scroll onClick={handleEvent} ref={ref} theme={{ main: dotsActive? 'dotsActive': listaPos ? 'posDown' : 'posUp' }}>
        <BlankTable
          headerColored
          border
          actionsWrapped
          headers={['ID', 'Name', 'Type', 'City', 'Country', 'Status', 'Setup']}
          items={allFilteredOrgs.map(org => ({
            id: org.id,
            all: org,
            labels: [
              org.id,
              org.name,
              capitalize(org.type),
              org.city,
              org.country,
              ORGSTATUS[((org.status || '') + '') || ''],
              org.setupComplete ? 'Complete' : 'Incomplete'
            ],
          }))}
          statusInfo={{
            hasStatus: true,
            statusIndex: 5,
          }}
          actions={['UPDATE', 'DELETE', 'APPROVE', 'CONTRIBUTOR']}
          actionsHandlers={{
            handleDelete,
            handleUpdate,
            handleApprove,
            handleContributor,
          }}
          maxWidth={'100px'}
          onClickRow={handleClickRow}
          cursor='pointer'
          backgroundColor={'white'}
          iconsHide={['APPROVE', 'CONTRIBUTOR']}
          idActive={idActive}
        />
      </Scroll>
      <ModalContributor
        showModal={showEdit}
        onHide={() => setShowEdit(false)}
        edit
        contributor={selected}
        reload={reloadContributors}
        modalitiesHealer={possibleMods}
      />
      <ModalContributor
        showModal={showCreate}
        onHide={() => setShowCreate(false)}
        reload={reloadContributors}
        contributor={selected}
        modalitiesHealer={possibleMods}
      />
    </ContentOrganizations>
  );
}

const LabelLittle = styled.h1`
  color: var(--t-blue);
  font-family: 'Inter';
  font-size: 16px;
  font-weight: normal;
`;

const WrapperRow = styled.div`
  display: flex;
  width: 100%;
  gap: 25px;
  align-items: flex-end;
`;

const WrapperRow2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  height: 40px;
  gap: 8px;
  max-width: 300px;
`;

const Scroll = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
  display: flex;
  width: 100%;
  overflow-y: scroll;

  &.dotsActive, 
  &.dotsActive > div{
    overflow: visible;
  }

  // &.posDown ul{
  // }

  &.posUp ul{
    top: -145px;
  }

`;

const ContentOrganizations = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  align-items: flex-start;
  gap: 15px;
  flex-direction: column;
`;

export default Healers;