import { useEffect, useState } from "react";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import { INTList } from "@vibe/sdk/dist/interfaces/Connections/newInterests";

const useIntListsSA = () => {
    const [intlists, setIntlists] = useState<INTList[]>([]);

    const reload = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Connections.getAllINTList(dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) {
                    const response = results.body;
                    if(Array.isArray(response)) setIntlists(results.body as INTList[]);
                    return;
                }
            })
    };

    useEffect(() => reload(), []);

    return {
        intlists,
        reload,
    }
}

export default useIntListsSA;