import {
    ButtonFilter
} from '../HealerProfile/components'
import styled from 'styled-components';
import { tabInterface } from '.';
import { useEffect, useState } from 'react';
import PostCard, { PostCardInterface } from '../BaseComponents/Posts/PostCard';
import { IUserGradeResponse } from '@vibe/sdk/dist/interfaces/Admin/User';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { IPostExtended } from '@vibe/sdk/dist/interfaces/Posts/IPost';
import { mapPostsCard } from '../../helpers/postHelper';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import FeaturedPostsModal from './FeaturedPostsModal';
import { sortAlphabeticallyOnProperty } from '../../containers/Signup/SignupHealerInd';
import { sortAlphabeticallyOnPropertyPosts } from '../../hooks/Posts/usePosts';

const tabs: tabInterface[] = [
    {
        id: 1,
        label: 'All',
        comingSoon: false,
        notification: false
    },
    {
        id: 2,
        label: 'Featured',
        comingSoon: false,
        notification: false
    }
];

const Posts = ({ handleSeeAll, seeAllS, tabActive, hiddenMenu }: { handleSeeAll: (type: boolean) => void, seeAllS: boolean, tabActive: number, hiddenMenu: boolean }) => {

    const userProfile: IUserGradeResponse = useSelector((state: RootState) => state.getUser.userProfile);
    const orgPosts: IPostExtended[] = useSelector((state: RootState) => state.getPost.getPostsOrg ?? []);
    const healerPosts: IPostExtended[] = useSelector((state: RootState) => state.getPost.getPostsFeedProfile);
    const healerFeaturedPosts: IPostExtended[] = useSelector((state: RootState) => state.getPost.getPostsFeaturedProfile);

    // const [tabActive, setTabActive] = useState<number>(1);
    const [posts, setPosts] = useState<PostCardInterface[]>([]);
    const [postIdSelected, setPostIdSelected] = useState<string>('');
    const [showModalFeatured, setShowModalFeatured] = useState<boolean>(false);
    const [featuredPosts, setFeaturedPosts] = useState<PostCardInterface[]>([])
    const [showEvent, setShowEvent] = useState<boolean>(true);

    const [tabsN, setTabsN] = useState<any>(tabs);

    const onClickFeatured = (id: string) => {
        setShowModalFeatured(true);
        setPostIdSelected(id);
    }

    useEffect(() => {
        if (userProfile.roles.includes('INDIVIDUAL')) setPosts(sortAlphabeticallyOnPropertyPosts(mapPostsCard(healerPosts), 'timestamp', 'DESC'));
        else setPosts(sortAlphabeticallyOnPropertyPosts(mapPostsCard(orgPosts), 'timestamp', 'DESC'));

        setFeaturedPosts(sortAlphabeticallyOnPropertyPosts(mapPostsCard(healerFeaturedPosts), 'timestamp', 'DESC'));
    }, [userProfile, orgPosts, healerPosts, healerFeaturedPosts])

    const handlePostSave = (e: string, type: boolean) => {
        const AllTabs = tabsN.map((tabN: any) => {
            if (tabN.label === e) {
                return {
                    ...tabN,
                    notification: type,
                };
            } else {
                return tabN;
            }
        });
        setTabsN(AllTabs);
    }

    return (
        <Card>
            <HeaderPost>
                {/* <ButtonsFilter>
                    {tabsN.map((tab: any) => (
                        <ButtonFilter
                            theme={{ main: tabActive === tab.id ? 'active' : 'inactive' }}
                            onClick={() => { setTabActive(tab.id); tab.notification && handlePostSave(tab.label, false) }}
                        >
                            {tab.label}
                            {tab.notification && <IconNtc />}
                        </ButtonFilter>
                    ))}
                </ButtonsFilter> */}
                {!seeAllS &&
                    <ButtonSeeAll onClick={() => handleSeeAll(true)}>See All</ButtonSeeAll>
                }

            </HeaderPost>

            {tabActive === 1 ? (
                <WrapperPosts hiddenMenu={hiddenMenu}>
                    {posts.map((post) => (
                        <PostCard data={post} setShowSetup={handlePostSave} reloadPosts={() => { }} />
                    ))}
                </WrapperPosts>
            ) : (
                <WrapperFeatured>
                    {featuredPosts.map((post) => (
                        (post.files || []).length > 0 ? (
                            <RectanglePinned
                                src={(post.files || []).length > 0 ? (post.files || [])[0].link : 'https://cdn.pixabay.com/photo/2017/03/25/17/55/colorful-2174045_1280.png'}
                                onClick={() => onClickFeatured(post.id)}
                            />
                        ) : (
                            <RectanglePinnedTextWrapper onClick={() => onClickFeatured(post.id)}>
                                <RectanglePinnedText>
                                    {post.content}
                                </RectanglePinnedText>
                            </RectanglePinnedTextWrapper>
                        )

                    ))}
                </WrapperFeatured>
            )}
            <FeaturedPostsModal show={showModalFeatured} onHide={() => setShowModalFeatured(false)} postId={postIdSelected} />
        </Card>
    )
}

const HeaderPost = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const ButtonSeeAll = styled.div`
    display: none;
    @media only screen and (max-width: 900px) {
        display: block;
        color: var(--absolute-zero);
        font-family: 'Inter';
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.1px;
        padding-right: 12px; 
    }
    
`

const IconNtc = styled.div`
    width: 5px;
    height: 5px;
    background-color: red;
    position: relative;
    border-radius: 50%;
    top: -7px;
    right: -5px;
`

const RectanglePinned = styled.img`
    width: 200px;
    height: 200px;
    min-width: 200px;
    min-height: 200px;
    max-width: 200px;
    max-height: 200px;
    cursor: pointer;
    object-fit: cover;
`;

const RectanglePinnedTextWrapper = styled.div`
    width: 200px;
    height: 200px;
    cursor: pointer;
    background-color: var(--t-light-tan);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

const RectanglePinnedText = styled.div`
    width: 100%;
    color: black;
    max-height: 200px;
    font-size: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
`;

const WrapperFeatured = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: scroll;
    max-height: calc(100vh - 140px);
    padding-bottom: 10px;
    gap: 16px;
    background-color: white;
    border: 1px solid var(--simple-gray);
    padding: 16px;
    border-radius: 8px;
`;

const Card = styled.div`
    width: 100%;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const ButtonsFilter = styled.div`
    display: flex;
    gap: 5px;

    @media only screen and (max-width: 900px) {
        padding: 0 12px;
    }

`

const WrapperPosts = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    max-height: calc(100vh - 140px);
    padding-bottom: 70px;
    gap: 10px;

    @media only screen and (max-width: 900px) {
        padding-bottom: 0px;
        max-height: ${({hiddenMenu}:{hiddenMenu: boolean}) => hiddenMenu ? 'calc(100vh - 85px)' : 'calc(100vh - 285px)'};
    }
`;

export const postsExample: PostCardInterface[] = [
    {
        id: '12',
        files: [{
            id: '1234212',
            type: 'IMG',
            link: 'https://cdn.pixabay.com/photo/2023/06/13/10/09/man-8060589_640.jpg',
        }],
        comments: [
            {
                author: 'po1',
                post_id: '1234212',
                content: 'It is worderfull',
                created_at: '1687392401',
                reactions: []
            },
            {
                author: 'po1',
                post_id: '1234212',
                content: 'It is worderfull',
                created_at: '1687392401',
                reactions: []
            },
            {
                author: 'po1',
                post_id: '1234212',
                content: 'It is worderfull',
                created_at: '1687392401',
                reactions: []
            }
        ],
        shared: 0,
        content: 'Hey! Be happy.',
        author_name: 'Natalia Santos',
        timestamp: '1687392401',
        author_username: 'nsantos',
        likes: 12,
        type: 'IMG',
        reachName: '',
        is_tip: false,
        reachID: '',
        event: {
            event_id: '',
            link_online_event: '',
            cover: '',
            name: '',
            location: '',
            delivery: 'online',
            start_date: '',
            start_time: '',
        },
    },
    {
        event: {
            event_id: '',
            link_online_event: '',
            cover: '',
            name: '',
            location: '',
            delivery: 'online',
            start_date: '',
            start_time: '',
        },
        is_tip: false,
        reachID: '',
        reachName: '',
        id: '13',
        files: [{
            id: '1234212',
            type: 'IMG',
            link: 'https://cdn.pixabay.com/photo/2023/06/13/10/09/man-8060589_640.jpg',
        },
        {
            id: '1234212',
            type: 'IMG',
            link: 'https://cdn.pixabay.com/photo/2023/06/13/10/09/man-8060589_640.jpg',
        }],
        comments: [],
        shared: 0,
        content: 'Hey! Be happy.',
        author_name: 'Natalia Santos',
        timestamp: '1687392401',
        author_username: 'nsantos',
        likes: 14,
        type: 'IMG',
    },
    {
        event: {
            event_id: '',
            link_online_event: '',
            cover: '',
            name: '',
            location: '',
            delivery: 'online',
            start_date: '',
            start_time: '',
        },
        is_tip: false,
        reachID: '',
        reachName: '',
        id: '14',
        link: 'https://fontawesome.com/',
        comments: [],
        shared: 0,
        content: 'Hey! Be happy.',
        author_name: 'Natalia Santos',
        timestamp: '1687392401',
        author_username: 'nsantos',
        likes: 0,
        type: 'LINK',
    },
    {
        event: {
            event_id: '',
            link_online_event: '',
            cover: '',
            name: '',
            location: '',
            delivery: 'online',
            start_date: '',
            start_time: '',
        },
        is_tip: false,
        reachID: '',
        reachName: '',
        id: '15',
        link: '<iframe width="560" height="315" src="https://www.youtube.com/embed/KleVchBKZ0M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
        comments: [],
        shared: 0,
        content: 'Hey! Be happy.',
        author_name: 'Natalia Santos',
        timestamp: '1687392401',
        author_username: 'nsantos',
        likes: 0,
        type: 'EMBED',
    },
];

export default Posts;