import { combineReducers } from 'redux';
import { getAhaReducer } from './ahaReducer';
import { getClubReducer } from './clubReducer';
import { getConnectionReducer } from './connectionReducer';
import { getEventReducer } from './eventReducer';
import { getInvitesReducer } from './inviteReducer';
import { getLocationReducer } from './locationReducer';
import { getNotificationReducer } from './notificationReducer';
import { getOrgsReducer } from './orgsReducer';
import { getPostReducer } from './postReducer';
import { getTemplateClubsReducer } from './templateClubsReducer';
import { getTemplateCategoriesReducer } from './templateCategoryReducer';
import { getUserReducer } from './userReducer';
import { getProfileReducer } from './profileReducer';
import { getTokenReducer } from './tokenReducer';
import { getYOReducer } from './yoreducer';
import { getTClubsReducer } from './TClubsReducer';
import { getChatReducer } from './chatReducer';
import { getModalityReducer } from './modalityReducer';
import { getGVReducer } from './GVReducer';
import { getDashboardReducer } from './DashboardReducer';

export default combineReducers({
  getOrgs: getOrgsReducer,
  getUser: getUserReducer,
  getConnection: getConnectionReducer,
  getClub: getClubReducer,
  getEvent: getEventReducer,
  getPost: getPostReducer,
  getNotification: getNotificationReducer,
  getAha: getAhaReducer,
  getProfile: getProfileReducer,
  getLocation: getLocationReducer,
  getInvites: getInvitesReducer,
  getTemplateClubs: getTemplateClubsReducer,
  getTemplateCategories: getTemplateCategoriesReducer,
  getToken: getTokenReducer,
  getYOReducer: getYOReducer,
  getTClubs: getTClubsReducer,
  getChat: getChatReducer,
  getModality: getModalityReducer,
  getGV: getGVReducer,
  getDashboard: getDashboardReducer,
});
