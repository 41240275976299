import { IconName } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { keyframes } from "styled-components";
import { Loader } from "./Loader";

const ButtonSpinner = ({
  loading,
  handleClick,
  text,
  hasIcon,
  icon,
  color,
  hasText,
  disabled = false,
  fontSize = 16,
}:{
  loading: boolean,
  handleClick: (value: any) => void,
  text: string,
  hasIcon: boolean,
  icon?: IconName
  color?: string,
  hasText?: boolean,
  disabled?: boolean,
  fontSize?: number
}) => {

  const onClickButton = (value: any) => {
    if(disabled) return;
    handleClick(value);
  }

  return (
    <ButtonSend
      onClick={onClickButton}
      disabled={disabled || loading}
      color={disabled ? "var(--simple-gray)" : (color || "var(--simple-blue-dark-2)")}
      fontSize={fontSize}
    >
      {(loading) ? (
        <Loader color={color || "var(--simple-blue-dark-2)"}/>
      ) : (
        <ButtonWrapper>
          <LoginTitle>{hasText === false ? '' : text || 'Save'}</LoginTitle>
          {(hasIcon) && (
            <StyledFontAwesomeIconSend
              icon={icon || 'arrow-right'}
              size='xs'
              color={"white"}
            />
          )}
        </ButtonWrapper>
      )}
    </ButtonSend>
  )
}

const StyledFontAwesomeIconSend = styled(FontAwesomeIcon)`
  font-size: 16px;
`;

const LoginTitle = styled.p`
  font-size: 24px;
  font-family: 'Inter';
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  text-overflow: ellipsis;
  overflow: hidden;
`;

interface ButtonProps {
  color: string;
  disabled: boolean;
  fontSize: number;
}

const ButtonSend = styled.button`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 8px 16px;
  border-radius: 11px;
  background-color: ${(props: ButtonProps) => props.color};
  border: ${(props: ButtonProps) => props.color === 'white' ? '1px solid var(--simple-gray)' : '0'};

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: ${(props: ButtonProps) => `${props.fontSize}px`};
  color: ${(props: ButtonProps) => props.color === 'white' ? 'var(--t-blue)' : 'white'};

  cursor: ${(props: ButtonProps) => props.disabled ? 'default' : 'pointer'};

  @media only screen and (max-width: 900px) {
    
    // border: 0px;
    // background-color: transparent;
    // padding: 0;
    // justify-content: flex-end;

    & ${LoginTitle}{
      font-size: ${(props: ButtonProps) => `${props.fontSize}px`};
      // color: var(--absolute-zero);
      text-align: right;
      font-weight: 100;
      font-family: 'Inter';
    }

  }

`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  overflow: hidden;
`;
 
export default ButtonSpinner;