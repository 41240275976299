import { AnyAction } from "@reduxjs/toolkit";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Backend, MicroServices, getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import { ACTIVE_CITIES_ERROR, ACTIVE_CITIES_SUCCESS, ACTIVE_COUNTRIES_ERROR, ACTIVE_COUNTRIES_SUCCESS, CITY_ERROR, CITY_SUCCESS, COUNTRY_ERROR, COUNTRY_SUCCESS, LOCATION_ERROR, LOCATION_SUCCESS, START_ACTIVE_CITIES, START_ACTIVE_COUNTRIES, START_CITY, START_COUNTRY, START_LOCATION, START_TIMEZONE, TIMEZONE_ERROR, TIMEZONE_SUCCESS } from "../constants/locationConstants";
import IResponseError from "@vibe/sdk/dist/interfaces/IResponseError";

export const getActiveCities = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
      dispatch({type: START_ACTIVE_CITIES});
      Backend(
        MicroServices.Roster,
        `/location/activeCities`)
        .then((response) => response.json())
        .then((results) => {
            if (!results) {
                dispatch({
                    type: ACTIVE_CITIES_ERROR,
                    payload: {
                        message: "Can't get active cities",
                    }
                })
                return;
            }
            dispatch({
                type: ACTIVE_CITIES_SUCCESS,
                payload: results,
            })
        })
        .catch((error) => {
            dispatch({
                type: ACTIVE_CITIES_ERROR,
                payload: error,
            })
        })
  });

export const getActiveCountries = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
      dispatch({type: START_ACTIVE_COUNTRIES});
      Backend(
        MicroServices.Roster,
        `/location/activeCountries`)
        .then((response) => response.json())
        .then((results) => {
            if (!results) {
                dispatch({
                    type: ACTIVE_COUNTRIES_ERROR,
                    payload: {
                        message: "Can't get active countries",
                    }
                })
                return;
            }
            dispatch({
                type: ACTIVE_COUNTRIES_SUCCESS,
                payload: results,
            })
        })
        .catch((error) => {
            dispatch({
                type: ACTIVE_COUNTRIES_ERROR,
                payload: error,
            })
        })
  });

export const getLocation = (postalcode : string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
      dispatch({type: START_LOCATION});
      Backend(
        MicroServices.Roster,
        `/location?postalCode=${postalcode}`)
        .then((response) => response.json())
        .then((results) => {
            if (!results) {
                dispatch({
                    type: LOCATION_ERROR,
                    payload: {
                        message: "Location not found",
                    }
                })
                return;
            }
            dispatch({
                type: LOCATION_SUCCESS,
                payload: results.location,
            })
        })
        .catch((error) => {
            dispatch({
                type: LOCATION_ERROR,
                payload: error,
            })
        })
  });

  export const getTimezones = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
      dispatch({type: START_TIMEZONE});

    getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
            return dataSDK.sdk.Roster.getZones(dataSDK.token);
        })
        .then((results) => {
            if (results.statusCode === 200) {
                dispatch({
                    type: TIMEZONE_SUCCESS,
                    payload: results.body,
                })
                return;
            }
            dispatch({
                type: TIMEZONE_ERROR,
                payload: (results.body as IResponseError).error || (results.body as IResponseError).message
            })
        })
        .catch((error) => {
            dispatch({
                type: TIMEZONE_ERROR,
                payload: error,
            })
        })
  });

export const getCity = (citycode : string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
      dispatch({type: START_CITY});
      Backend(
        MicroServices.Roster,
        `/location/cities/${citycode}`)
        .then((response) => response.json())
        .then((results) => {
            if (!results) {
                dispatch({
                    type: CITY_ERROR,
                    payload: {
                        message: "City not found",
                    }
                })
                return;
            }
            dispatch({
                type: CITY_SUCCESS,
                payload: results.name,
            })
        })
        .catch((error) => {
            dispatch({
                type: CITY_ERROR,
                payload: error,
            })
        })
  });

export const getCountry = (countrycode : string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
      dispatch({type: START_COUNTRY});
      Backend(
        MicroServices.Roster,
        `/location/countries/${countrycode}`)
        .then((response) => response.json())
        .then((results) => {
            if (!results) {
                dispatch({
                    type: COUNTRY_ERROR,
                    payload: {
                        message: "Country not found",
                    }
                })
                return;
            }
            dispatch({
                type: COUNTRY_SUCCESS,
                payload: results.name,
            })
        })
        .catch((error) => {
            dispatch({
                type: COUNTRY_ERROR,
                payload: error,
            })
        })
  });