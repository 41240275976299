import OutsideClickHandler from "react-outside-click-handler";
import { ChatClickerModal, ListModalClicker, Title } from "./components";
import { useTranslation } from "react-i18next";
import { ChatServiceInterface } from "../../../../interfaces/chat/ChatServiceInterface";

const OutsideClickModalChatCenter = ({ type, setShowModal, ChatServiceInstance, muted }: { type: 'USER' | 'GROUP', setShowModal: (e: boolean) => void, ChatServiceInstance: ChatServiceInterface, muted: boolean }) => {
  const { t, i18n } = useTranslation();

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowModal(false);
      }}
    >
      <ChatClickerModal>
        <Title>{t('options__whatWouldYou')}</Title>
        <ul>
          <ListModalClicker iconName={muted ? "volume" : "volume-slash"} content={muted ? t('chat_unmuteNotifications') : t('chat_muteNotifications')} onClickMethod={() => ChatServiceInstance.muteMessage(!muted)} />
          {/*<ListModalClicker iconName="trash" content={t('chat_deleteChat')} />
          {(type === 'GROUP') && (<>
            <ListModalClicker iconName="user-plus" content={t('chat_inviteMembers')} />
          </>)} //@todo Felipe backend*/}
        </ul>
      </ChatClickerModal>
    </OutsideClickHandler>
  );
}

export default OutsideClickModalChatCenter;