import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export interface SearchInputProps {
    filteredItem: string,
    setFilteredItem: (value: string) => void,
    backgroundColor?: string,
    border?: string,
    placeholder?: string,
}

const SearchInput = ({
    filteredItem,
    setFilteredItem,
    backgroundColor = "white",
    border = "1px solid var(--simple-gray)",
    placeholder = "Search item",
}: SearchInputProps) => {
    return (
        <InputIcon
            backgroundColor={backgroundColor}
            border={border}
        >
            <SearchIcon
                icon={faSearch}
                size='sm'
                color={backgroundColor === 'white'? 'var(--content-secondary)': 'white'}
            />
            <Input
                placeholder={placeholder}
                value={filteredItem}
                onChange={(e: any) => setFilteredItem(e.currentTarget.value)}
                backgroundColor={backgroundColor}
                border={border}
            />
        </InputIcon>
    )
}

const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 0px;

    color: ${(props: Select_InputProps) => props.backgroundColor === 'white' ? 'black' : 'white'};
`

const SearchIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
`;

interface Select_InputProps{
    backgroundColor: string
    border: string
}

const InputIcon = styled.div`
    display: flex;
    justify-content: space-between;
    border-radius: 9px;
    align-items: center;
    height: 100%; 
    width: 100%;
    padding: 10px;

    background-color: ${(props: Select_InputProps) => props.backgroundColor};
    border: ${(props: Select_InputProps) => props.border};
    color: ${(props: Select_InputProps) => props.backgroundColor === 'white' ? 'black' : 'white'};
    
    & ${Input}{
        border: 0;
        height: calc(100% - 3px);
        background-color: transparent;
        margin: 0 !important;
    }
`

export default SearchInput;