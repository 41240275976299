import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChartPieSimple, faExpand, faCompress, faLocationDot, faChevronRight, faUserPen } from "@fortawesome/pro-regular-svg-icons";
import { ContainerDash } from "./Dashboards.styles"
import TableRevenue from './TableRevenue';
import TableModalities from './TableModalities';
import TableCommunities from './TableCommunities';
import TableEvents from './TableEvents';
import AppLayout from '../../containers/AppLayout';
import useDashboard from '../../hooks/Dashboard/useDashboard';
import { IMedia } from '@vibe/sdk/interfaces/Admin/utils';
import { IconClicker } from '../Home/HeaderHome/styles';
import { useNavigate } from 'react-router-dom';

export type tabsDashboardHealer = 'BULLETIN' | 'PROFILE' | 'REVENUE' | 'MODALITIES' | 'COMMUNITIES' | 'EVENTS';

const DashboardsHeader = ({
  tabActive,
  setTabActive,
}: {
  tabActive: tabsDashboardHealer
  setTabActive: (tab: tabsDashboardHealer) => void
}) => {
  const navigate = useNavigate();

  const [cardActive, setCardActive] = useState<any>(0)
  const [countClick, setCountClick] = useState<any>(0)

  const [height, setHeight] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const elementRef = useRef<null>(null);

  const user = useSelector((state: RootState) => state.getUser.user);
  const userRoles = useSelector((state: RootState) => state.getUser.userRoles);
  const org = useSelector((state: RootState) => state.getOrgs.organizationInfo);
  const {
    generalDashboard,
    castBigNumbers,
    castMoneyNumbers
  } = useDashboard();

  const sizeRef = (ref: any) => {
    if (ref.current) {
      const handleResize = () => {
        setHeight(ref.current.offsetWidth);
      };
      handleResize();
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      }
    }
  }

  const positionScrl = (ref: any) => {
    if (ref.current) {
      ref.current.scrollLeft = scrollPosition
    }
  }

  useEffect(() => {
    positionScrl(elementRef)

  }, [scrollPosition]);

  useEffect(() => {
    sizeRef(elementRef)
  }, []);

  const ClickCard = (e: any, card: any, ref: any, tab: tabsDashboardHealer) => {
    setTabActive(tab)
    setCardActive(card)
    if (ref.current) {
      if (card === 1) {
        setScrollPosition(0)
      } else {
        setScrollPosition((287 * (card - 1)))
      }
    }
  }

  const closeCard = (ref: any) => {
    setCardActive(0)
    if (ref.current) {
      ref.current.scrollLeft = scrollPosition
    }
  }

  function handleClick(ref: any) {
    // console.log(Math.floor(1148/height))
    if (ref.current) {
      setCountClick(countClick + 1)
      if (countClick >= Math.floor(1148 / height)) {
        ref.current.scrollLeft = 0
        setCountClick(0)
      } else {
        ref.current.scrollLeft = ref.current.scrollLeft + 287
      }
      // console.log(ref.current.scrollLeft + 287, height)
    }
  }

  const getImageMedia = (social: string): string => {
    switch (social) {
      case 'Facebook':
        return '../facebook-f.svg';
      case 'YouTube':
        return '../youtube.svg';
      case 'Instagram':
        return '../instagram.svg';
      case 'Twitter':
        return '../twitter.svg';
      case 'LinkedIn':
        return '../linkedin.svg';
      case 'Pinterest':
        return '../pinterest.svg';
      case 'Snapchat':
        return '../snapchat.svg';
      case 'TikTok':
        return '../tiktokicon.svg';
      case 'Zaahah':
        return '../Zaahah.svg';
      case 'Personal':
        return '../globe-solid.svg'
      case 'Substack':
        return '../substack.svg'
      default:
        return '';
    }
  }

  const goEditUser = () => {
    if (userRoles.includes('INDIVIDUAL')) {
      navigate(`../../../../../../edit-healer-ind-profile/${user.username}?bulletin=true`);
      return;
    }

    navigate(`../../../../../../edit-healer-profile/${user.username}?bulletin=true`)
  }

  useEffect(() => {
    if (tabActive === 'BULLETIN') {
      closeCard(elementRef)
    }
  }, [tabActive]);

  return (
    <DashboardAdminWrapper>

      {/* <Tabs>
            <Tab theme={{ main: tabActive === 'BULLETIN' ? 'tabActive' : '' }} onClick={() => setTabActive('BULLETIN')}>
              <FontAwesomeIcon
                icon={faChartPieSimple}
                size="lg"
              />
              Overview
            </Tab>
            <Tab theme={{ main: tabActive === 'PROFILE' ? 'tabActive' : '' }} onClick={() => setTabActive('PROFILE')}>
              <FontAwesomeIcon
                icon={faUser}
                size="lg"
              />
              Profile
            </Tab>
          </Tabs> */}

      <CardsDash>
        <CardInt onClick={() => { closeCard(elementRef); setTabActive('BULLETIN') }}>
          <User>
            <ContDiv>
              <Avatar src={user.avatar ?? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} />
            </ContDiv>
            <InfoUser>
              <UserRow>
                <InfoCard>
                  <InfoRow>
                    <NameUser>
                      {`${user.firstname ?? ''} ${user.lastname ?? ''}`}
                    </NameUser>
                    {process.env.REACT_APP_SHOW_EDIT_PROFILE === 'true' && (<IconClicker
                      icon={faUserPen}
                      size="lg"
                      color='#272E35'
                      onClick={goEditUser}
                    />)}
                  </InfoRow>
                  <InfoRow>
                    <LocationCard>
                      {user.city ? user.city : user.zipcode}, {user.country}
                    </LocationCard>
                    <Tooltip theme={{ top: -30 }}>{user.city ? user.city : user.zipcode}, {user.country}</Tooltip>
                  </InfoRow>
                </InfoCard>
              </UserRow>
              <ItemsP>
                {user.ed?.map(edu => (
                  <ItemP>{edu.degree_name}</ItemP>
                ))}
              </ItemsP>

            </InfoUser>
          </User>
          {/* <Location>
            <div>
              <FontAwesomeIcon
                icon={faLocationDot}
                size='sm'
                color={"black"}
                style={{
                  fontSize: '16px'
                }}
              />
              {user.city ? user.city : user.zipcode},
            </div>
            <div>
              {user.country}
            </div>
          </Location> */}

          <CountMedia>
            <CountS>
              <IconUser src={'../icon_seeker.png'} />
              {castBigNumbers(org.seekers ?? 0)} Seekers
            </CountS>
            <SMedia>
              {user.sm?.filter(social => social.platform != 'Personal').map(filteredPerson => (
                <a href={filteredPerson.link} target="_blank" rel="noopener noreferrer">
                  <IconMedia src={getImageMedia(filteredPerson.platform)} />
                </a>
              ))}

              {user.sm?.filter(social => social.platform == 'Personal').map(filteredPerson => (
                <LinkWeb href={filteredPerson.link} target="_blank" rel="noopener noreferrer">
                  Website
                </LinkWeb>
              ))}
              {/* {user.sm?.map(social => (
                <a href={social.link} target="_blank" rel="noopener noreferrer">
                  <IconMedia src={getImageMedia(social.platform)} />
                </a>
              ))} */}
            </SMedia>
          </CountMedia>
        </CardInt>

        <CardInfo ref={elementRef}>
          <ArrowCar onClick={() => handleClick(elementRef)}>
            <IconClicker
              icon={faChevronRight}
              size='sm'
              color={"black"}
              style={{
                fontSize: '16px'
              }}
            />
          </ArrowCar>

          <Card1 theme={{ width: cardActive === 1 ? height : 275 }}>
            <HeaderCard>
              <Title>
                Revenue
              </Title>
              <Filter>
                All time
                <IconGeoDown />
                {/* {cardActive === 1 ?
                      <IconClicker
                        icon={faCompress}
                        size="lg"
                        color={'black'}
                        onClick={(e) => closeCard(elementRef)}
                      />
                      :
                      <IconClicker
                        icon={faExpand}
                        size="lg"
                        color={'black'}
                        onClick={(e) => ClickCard(e, 1, elementRef, 'REVENUE')}
                      />
                    } */}
              </Filter>
            </HeaderCard>
            <Count>
              <IconGeoUp theme={{ color: '#0F9936' }} />
              <Price theme={{ size: '18px' }}>
                ${castMoneyNumbers(generalDashboard?.revenue.GV ?? 0)}
              </Price>
            </Count>
            <TextTotal>
              Total Revenue in USD
              <SubText>
                ${castMoneyNumbers(generalDashboard?.revenue.USD ?? 0)} USD
              </SubText>
            </TextTotal>
          </Card1>

          <Card1 theme={{ width: cardActive === 2 ? height : 275 }}>
            <HeaderCard>
              <Title>
                Modalities and Services
              </Title>
              <CountCard>
                {castBigNumbers(generalDashboard?.services.count ?? 0)}
                {cardActive === 2 ?
                  <IconClicker
                    icon={faCompress}
                    size="lg"
                    color={'black'}
                    onClick={(e) => closeCard(elementRef)}
                  />
                  :
                  <IconClicker
                    icon={faExpand}
                    size="lg"
                    color={'black'}
                    onClick={(e) => ClickCard(e, 2, elementRef, 'MODALITIES')}
                  />
                }
              </CountCard>
            </HeaderCard>
            <ContentCount>
              <TextCount>
                Revenue
              </TextCount>
              <Count>
                <IconGeoUp theme={{ color: '#0F9936' }} />
                <Price>
                  ${castMoneyNumbers(generalDashboard?.services.GV ?? 0)}
                </Price>
              </Count>
            </ContentCount>
            <CountMembers>
              {/* {castBigNumbers(generalDashboard?.services.members ?? 0)} Members | {castBigNumbers(generalDashboard?.services.followers ?? 0)} Followers */}
              {castBigNumbers(generalDashboard?.services.members ?? 0)} Members

            </CountMembers>
          </Card1>

          <Card1 theme={{ width: cardActive === 3 ? height : 275 }}>
            <HeaderCard>
              <Title>
                Communities
              </Title>
              <CountCard>
                {castBigNumbers(generalDashboard?.clubs.count ?? 0)}
                {cardActive === 3 ?
                  <IconClicker
                    icon={faCompress}
                    size="lg"
                    color={'black'}
                    onClick={(e) => closeCard(elementRef)}
                  />
                  :
                  <IconClicker
                    icon={faExpand}
                    size="lg"
                    color={'black'}
                    onClick={(e) => ClickCard(e, 3, elementRef, 'COMMUNITIES')}
                  />
                }
              </CountCard>
            </HeaderCard>
            <ContentCount>
              <TextCount>
                Total Activity
              </TextCount>
              <Count>
                <Price>
                  {castBigNumbers(generalDashboard?.clubs.activity ?? 0)}
                </Price>
              </Count>
            </ContentCount>
            <CountMembers>
              {/* {castBigNumbers(generalDashboard?.clubs.members ?? 0)} Members | {castBigNumbers(generalDashboard?.clubs.followers ?? 0)} Followers | {castBigNumbers(generalDashboard?.clubs.guides ?? 0)} Guides */}
              {castBigNumbers(generalDashboard?.clubs.members ?? 0)} Members | {castBigNumbers(generalDashboard?.clubs.guides ?? 0)} Guides
            </CountMembers>
          </Card1>

          <Card1 theme={{ width: cardActive === 4 ? height : 275 }}>
            <HeaderCard>
              <Title>
                Events
              </Title>
              <CountCard>
                {castBigNumbers(generalDashboard?.events.count ?? 0)}
                {cardActive === 4 ?
                  <IconClicker
                    icon={faCompress}
                    size="lg"
                    color={'black'}
                    onClick={(e) => closeCard(elementRef)}
                  />
                  :
                  <IconClicker
                    icon={faExpand}
                    size="lg"
                    color={'black'}
                    onClick={(e) => ClickCard(e, 4, elementRef, 'EVENTS')}
                  />
                }
              </CountCard>
            </HeaderCard>
            <ContentCount>
              <TextCount>
                Revenue
              </TextCount>
              <Count>
                <IconGeoUp theme={{ color: '#0F9936' }} />
                <Price>
                  ${castMoneyNumbers(generalDashboard?.events.GV ?? 0)}
                </Price>
              </Count>
            </ContentCount>
            <CountMembers>
              {castBigNumbers(generalDashboard?.events.attendanceOnline ?? 0)} Online | {castBigNumbers(generalDashboard?.events.attendanceInPerson ?? 0)} In person
            </CountMembers>
          </Card1>
        </CardInfo>

      </CardsDash>
    </DashboardAdminWrapper>
  )
}

const ContDiv = styled.div`

`

const UserRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const IconUser = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

const ArrowCar = styled.div`
  position: absolute;
  right: 25px;
  top: 100px;
  background-color: white;
  border: 1px solid #d2d2d2;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const TabBulletin = styled.div`
  & > div{
    margin: 0;
    padding: 0 136px;
  }
`

const CountMedia = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  width: 100%;
  align-items: baseline;
  flex-direction: column;
`
const CountS = styled.div`
display: flex;
    align-items: center;
    gap: 10px;
`
const SMedia = styled.div`
display: flex;
gap: 13px;
`

const IconMedia = styled.img`
    width: 17px;
    height: 17px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    cursor: pointer;
`

const Location = styled.div`
    font-family: 'Inter';
    font-size: 14px;
    text-align: left;
    color: black;
    gap: 5px;
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
    }
`

const InfoUser = styled.div`
    display: flex;
    align-items: center;
    min-width: 180px;
    padding: 0px;
    width: 100%;
    gap: 4px;
    justify-content: space-between;
    flex-direction: column;
    align-items: baseline;
`

const User = styled.div` 
  display: flex;
  width: calc(100%);
  gap: 10px;
  justify-content: space-around;
  align-items: center;
`

const NameUser = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #2E2623;
    font-family: Inter-Bold;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.1px;
    text-align: left;
`

const Avatar = styled.img`
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    max-width: 60px;
    max-height: 60px;
    border-radius: 50%;
    object-fit: cover;
`

const CountMembers = styled.div`
    color: #333;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px;
    letter-spacing: 0.2px;
    width: 100%;
    text-align: left;
`

const Count = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
`

const ContentCount = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
padding: 8px 45px 8px 16px;
background-color: #F5F7F9;
border-radius: 8px;

& ${Count}{
  width: auto;
}

`
const TextCount = styled.div`
overflow: hidden;
color: #333;
font-feature-settings: 'clig' off, 'liga' off;
text-overflow: ellipsis;
/* Strong/75 */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: 0.1px;
`

const CountCard = styled.div`
  color: #2A4CD3;
  text-align: center;
  font-family: Inter-Bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
  gap: 12px;
`

const IconGeoUp = styled.div`
  width: 0;
  height: 0;
  position: relative;
  top: -2px;
  border-right: 4px solid transparent;
  border-top: 4px solid transparent;
  border-left: 4px solid transparent;
  border-bottom: 4px solid ${props => props.theme.color || 'black'};
`

const IconGeoDown = styled.div`
  width: 0;
  height: 0;
  position: relative;
  top: 2px;
  border-right: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  border-top: 4px solid ${props => props.theme.color || 'black'};
`

const SubText = styled.div`
color: #333;
font-feature-settings: 'clig' off, 'liga' off;
text-overflow: ellipsis;
/* Normal/50 */
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 166.667% */
letter-spacing: 0.2px;
text-align: left;
width: 100%;
`
const TextTotal = styled.div`
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px;
letter-spacing: 0.1px;
text-align: left;
width: 100%;
`
const Price = styled.div`
color: #0F9936;
font-feature-settings: 'clig' off, 'liga' off;
/* Strong/200 */
font-family: Inter;
font-size: ${props => props.theme.size || '14px'};
font-style: normal;
font-weight: 600;
line-height: 32px; /* 177.778% */
letter-spacing: 0.1px;
`


const HeaderCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
const Title = styled.div`
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px;
letter-spacing: 0.1px;
`
const Filter = styled.div`
color: #2A4CD3;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
/* Medium/75 */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 450;
line-height: 24px; /* 171.429% */
letter-spacing: 0.2px;
display: flex;
align-items: center;
gap: 12px;
`

const Card1 = styled.div`
  border-radius: 8px;
  border: 1px solid var(--action-neutral-subtle-normal, #F0F3F5);
  background: #FFF;
  display: flex;
  width: ${props => props.theme.width}px;
  min-height: 156px;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`

const CardInt = styled(Card1)`
  grid-area: 1 / 1 / 2 / 2;
  min-width: 280px;
  cursor: pointer;
`

const CardInfo = styled.div`
  grid-area: 1 / 2 / 2 / 5;
  display: grid;
  grid-gap: 12px;
  grid-auto-flow: column;
  overflow: scroll;
  scroll-behavior: smooth;
  width: 110%;
`

const CardsDash = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 0px;
  margin: 12px 0;
  width: 100vw;
  padding: 0px 20px;
`

const DashboardAdminWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  margin-top: 13px; // header height
`

const ItemsP = styled.div`
    display: flex;
    gap: 4px;
`

const ItemP = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    height: 23px;
    background: #FFFFFF;
    border: 1px solid #665D5A;
    border-radius: 16px;
    font-family: 'Inter';
    font-size: 12px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    max-width: 70px;
    white-space: nowrap;
    justify-content: flex-start;
`

const Tabs = styled.div`
  width: 100%;
  display: flex;
  background-color: white;
  height: 50px;
  align-items: center;
  padding: 0px 136px;
`
const Tab = styled.div.attrs(
  props => ({
    className: props.theme.main,
  })
)`
  color: #1D1B20;
  text-align: center;
  font-family: Inter-Bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  display: flex;
  gap: 15px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;

  &:hover{
    background-color: #ededee;
  }

  &.tabActive{
    border-bottom: 2px solid #e59b50;
    
  }
`

const ContainerPadding = styled.div`
  & > div{
    padding: 0 106px;
  }
`

const ContainerPaddingAll = styled.div`
  width: 100%;
  & ${Tabs}, & ${CardsDash}{
    padding: 0 136px;
  }
`

const LinkWeb = styled.a`
    text-decoration: underline !important;
    display: block !important;
    color: var(--absolute-zero);
    font-family: 'Inter-SemiBold';
`

const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    text-align: left;
    width: 100%;
`

const InfoCard = styled(Info)`
    //width: calc(100% - 70px);
`

const Tooltip = styled.div`
    background-color: black;
    color: white;
    content: "";
    position: absolute;
    padding: 0px 9px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 7px;
    top: ${props => props.theme.top}px;
    font-size: 12px;
    left: -70px;
    right: 0;
    margin: 0 auto;
    height: 24px;
    display: flex;
    width: max-content;
    display: none;

    &::after{
        width: 0;
        height: 0;
        display: block;
        position: absolute;
        top: 23px;
        left: 0;
        right: 0;
        margin: 0 auto;
        content: "";
        border-right: 5px solid transparent;
        border-top: 5px solid #000000;
        border-left: 5px solid transparent;
    }
`

const LocationCard = styled(Location)`
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
    cursor: pointer;
    display: inline-block;

    &:hover ~ ${Tooltip}{ display: flex; }
`

const InfoRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100%);
    gap: 10px;
    position: relative;
`;

export default DashboardsHeader;