import React, {
    useState,
    useEffect,
    useContext
} from 'react';
import './styles.scoped.css'
import CreatableSelect from 'react-select/creatable';
import { GroupBase, OnChangeValue, StylesConfig } from 'react-select';
import ImageProfile from './ImageProfile'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SocialMedia from './SocialMedia';
import { UserResponsePII } from '../../interfaces/user/UserResponse';
import { UserResponse, userMockGraded } from "../../interfaces/user/UserResponse";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../store/store";
import { LAVENDER, WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from '../../helpers/backendHelper';
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux';
import useGender from '../../hooks/useGender';
import { IUserGradeResponse } from '@vibe/sdk/dist/interfaces/Admin/User';
import { ContextSuccess } from "../../Context";
import { getUser, updateUser } from '../../store/actions/userActions';
import { IUpdateUserBody } from '@vibe/sdk/dist/interfaces/Admin/updateUser';

interface Option {
    readonly label: string;
    readonly value: string;
}
export interface SocialMediaLink {
    id: string;
    type: string;
    link: string;
}

const GeneralInfo = ({ backInfo }: { backInfo: () => void }) => {
    const { t, i18n } = useTranslation();
    const currentSuccess = useContext(ContextSuccess);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const organization = useSelector((state: any) => state.getOrgs.organizationInfo);
    const user = useSelector((state: any) => state.getUser.user);
    const { genders, setCountry: setCountryGender } = useGender();

    const [userForm, setUserForm] = useState<IUserGradeResponse>(userMockGraded);
    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);

    const [gender, setGender] = useState(user.gender || '')
    const [zipcode, setZipCode] = useState(user.zipcode || '')
    const [phoneNumber, setPhoneNumber] = useState(`${user.phoneNumber}` || '')
    const [address, setAddress] = useState(user.address || '')

    useEffect(() => {
        if (organization && organization?.countryCode) {
            setCountryGender(organization.countryCode);
        }
    }, [])
    useEffect(() => {
        if (organization && organization?.countryCode) {
            setCountryGender(organization.countryCode);
        }
    }, [organization])

    const [socialMediaLinks, setSocialMediaLinks] = useState<SocialMediaLink[]>([]);

    useEffect(() => {
        setUserForm({
            ...userForm,
            gender: user.gender,
        });

        if (user.socialMedia) {
            setSocialMediaLinks(user.socialMedia);
        }
        else {
            setSocialMediaLinks([])
        }

        setAddress(user.address || '');
        setZipCode(user.zipcode || '');
        setPhoneNumber(`${user.phoneNumber}` || '');
    }, [user])

    const addNewLink = () => {
        setSocialMediaLinks([...socialMediaLinks, {
            id: '',
            type: '',
            link: ''
        }])
    }

    const handleChangeGender = (e: any) => {
        setGender(e)
        setUserForm({
            ...userForm,
            gender: e,
        });
    };

    const handleOnChangeInput = (e: any) => setZipCode(e.target.value)

    function updateUserInfo() {
        setLoadingUpdate(true);
        const updateData: IUpdateUserBody = {
            gender: gender,
            zipcode: zipcode,
            phoneNumber: parseInt(phoneNumber),
            address: address,
        }
        dispatch<any>(updateUser(
            user.username,
            updateData,
            'OA',
        ))
            .then((results: boolean) => {
                if (results) {
                    dispatch(getUser(user.username))
                    currentSuccess?.setSuccessData(true, true, t('toast_informationUpdated'));
                }
                else{
                    currentSuccess?.setSuccessData(false, true, 'Error updating information.');
                }
            })
            .catch((error: any) => {
                console.error(error);
                currentSuccess?.setSuccessData(false, true, 'Error updating profile', error);
            })
            .finally(() => { setLoadingUpdate(false) });
    }

    return (
        <div className='containerMyAccount'>
            <div className='row row1'>
                <label className='titleInput' htmlFor="">{t('profile__nickname')}</label>
                <input type="text" />
            </div>
            <div className='row row1'>
                <label className='titleInput' htmlFor="">{t('profile__gender')}</label>
                <div className='listRadioButtons'>
                    {genders.map(({ id: genderKey, label: genderValue }) => (
                        <div className="option-status" onChange={() => handleChangeGender(genderKey)}>
                            <label className="content-input">
                                <input
                                    type="radio"
                                    name="button"
                                    defaultValue={gender}
                                    checked={userForm.gender === genderKey}
                                />
                                <i></i>
                            </label>
                            <label htmlFor="students">{genderValue}</label>
                        </div>
                    ))}
                </div>
            </div>

            <div className='row row2'>
                <div className='col'>
                    <label className='titleInput' htmlFor="">{t('profile__address')}</label>
                    <input type="text" value={address} onChange={(e: any) => setAddress(e.target.value)} />
                </div>
                <div className='col'>
                    <label className='titleInput' htmlFor="">{t('profile__phoneNumber')}</label>
                    <input type="text" value={phoneNumber === 'undefined' || phoneNumber === 'null' ? '' : phoneNumber} onChange={(e: any) => setPhoneNumber(e.target.value)} />
                </div>
            </div>
            <div className='row row2'>
                <div className='col leftColumn'>
                    <label className='titleInput' htmlFor="">{t('profile__city')}</label>
                    <input type="text" className='inputAddress disabled' value={user.city || ''} disabled />
                </div>
                <div className='col leftColumn'>
                    <label className='titleInput' htmlFor="">{t('profile__country')}</label>
                    <input type="text" className='inputAddress disabled' value={user.country || ''} disabled />
                </div>
                <div className='col leftColumn'>
                    <label className='titleInput' htmlFor="">{t('profile__zipcode')}</label>
                    <input type="text" defaultValue={zipcode} onChange={(e) => handleOnChangeInput(e)} />
                </div>
            </div>

            <div className='row row2 middle'>
                <label className='titleInput' htmlFor="">{t('profile__socialMedia')}</label>
                <button className='btnAddNewLink pointer'
                    onClick={() => addNewLink()}
                >
                    {t('profile__addNewLink')}
                </button>
            </div>

            {socialMediaLinks.map((mediaLink: SocialMediaLink) => (
                <SocialMedia mediaData={mediaLink} username={user.username} />
            ))}

            <div className='row row2'>
                <div className='col'>
                    <button onClick={() => backInfo()} className='btnCancel pointer'>
                        {t('button__cancel')}
                    </button>
                </div>
                <div className='col'>
                    <button onClick={() => updateUserInfo()} className='btnSave pointer'>
                        {(loadingUpdate) ? (
                            <div className="icon-spinner-third buttonSendSignUp" />
                        ) : (
                            t('button__save')
                        )}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default GeneralInfo