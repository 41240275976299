import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { components, DropdownIndicatorProps } from "react-select"
import { LAVENDER } from "../components/ClubIconsNavBar";
import SelectItem from "../interfaces/SelectItem"
import { BROWN } from "../containers/SuperAdmin/Modalities/components";

export const customStylesReactSelect = () => ({
  option: (styles: any) => ({
    ...styles,
    color: 'white',
    backgroundColor: '#383838',
    fontFamily: 'Inter',
  }),
  input: (styles: any, state: any) => ({
    ...styles,
    border: 'none',
    color: 'white',
    fontWeight: 200,
    paddingLeft: "5px",
    width: '100%',
    "&:focus": {backgroundColor: 'red'},
  }),
  container: (styles: any) => ({
    ...styles,
    padding: 0,
    width: '100%',
  }),
  valueContainer: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: 'transparent',
    padding: 0,
  }),
  menu: (styles: any) => ({
    ...styles,
    borderRadius: 8,
    backgroundColor: "#383838",
  }),
  control: (styles: any, state: any) => ({
    ...styles,
    textAlign: "left",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // minWidth: "118px",
    borderRadius: "0px",
    boxShadow: "none",
    border: 0,
    fontSize: "16px",
    fontFamily: "Inter",
    // fontWeight: "bold",
    // maxWidth: "200px",
    marginBottom: "5px",
    backgroundColor: 'var(--t-blue)',
    width: "100%",
    // textDecoration: outside ? 'underline' : 'none',
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: '#ffffff99',
    textDecoration: 'none',
    display: 'block',
    paddingLeft: "10px",
  }),
  indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    color: '#a885d7',
    width: "40px"
  }),
  noOptionsMessage: (styles: any) => ({
    ...styles,
    color: 'white',
    backgroundColor: 'grey',
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: "normal",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: 'white',
    backgroundColor: 'transparent',
    border: "none",
    paddingLeft: "5px",
    paddingRight: "5px",
    
  }),
})

export const customStylesRSMultiNoDropdown = () => ({
  ...customStylesReactSelect(),
  multiValue: (styles: any) => ({
    ...styles,
    backgroundColor: "var(--simple-brown)",
    marginLeft: '10px',
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: 'var(--t-blue)'
  }),
  input: (styles: any, state: any) => ({
    ...styles,
    border: 'none',
    color: 'white',
    fontWeight: 200,
    paddingLeft: "5px",
    // width: '100%',
    "&:focus": {backgroundColor: 'red'},
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: '#FFFFFF77',
    textDecoration: 'none',
    display: 'block',
    paddingLeft: "8px",
  }),
  control: (styles: any, state: any) => ({
    ...styles,
    textAlign: "left",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // minWidth: "118px",
    borderRadius: "5px",
    boxShadow: "none",
    border: 0,
    fontSize: "16px",
    fontFamily: "Inter",
    // fontWeight: "bold",
    // maxWidth: "200px",
    // marginBottom: "5px",
    backgroundColor: 'var(--t-blue)',
    width: "100%",
    // textDecoration: outside ? 'underline' : 'none',
  }),
  indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    display: "none" 
  }),
})

export const customStylesRSMultiNoDropdownWhite = () => ({
  ...customStylesReactSelect(),
  multiValue: (styles: any) => ({
    ...styles,
    backgroundColor: "var(--simple-brown)",
    marginLeft: '10px',
  }),
  option: (styles: any) => ({
    ...styles,
    color: 'var(--black)',
    backgroundColor: 'var(--white)',
    fontFamily: 'Inter',
    textAlign: "left",
  }),
  menu: (styles: any) => ({
    ...styles,
    borderRadius: 8,
    backgroundColor: "var(--white)",
    zIndex: 1
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: 'var(--t-blue)'
  }),
  input: (styles: any, state: any) => ({
    ...styles,
    border: 'none',
    color: 'black',
    fontWeight: 200,
    paddingLeft: "5px",
    // width: '100%',
    "&:focus": {backgroundColor: 'red'},
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: 'var(--simple-gray)',
    textDecoration: 'none',
    display: 'block',
    paddingLeft: "8px",
  }),
  control: (styles: any, state: any) => ({
    ...styles,
    textAlign: "left",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    boxShadow: "none",
    border: '1px solid var(--simple-gray)',
    fontSize: "16px",
    fontFamily: "Inter",
    backgroundColor: 'white',
    width: "100%",
  }),
  indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    display: "none" 
  }),
})

export const customStylesRSMulti = () => ({
  ...customStylesReactSelect(),
  multiValue: (styles: any) => ({
    ...styles,
    backgroundColor: "var(--simple-brown)",
    marginLeft: '10px',
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: 'var(--valhalla)'
  }),
  input: (styles: any, state: any) => ({
    ...styles,
    border: 'none',
    color: 'white',
    fontWeight: 200,
    paddingLeft: "5px",
    // width: '100%',
    "&:focus": {backgroundColor: 'red'},
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: '#FFFFFF77',
    textDecoration: 'none',
    display: 'block',
    paddingLeft: "8px",
  }),
  control: (styles: any, state: any) => ({
    ...styles,
    textAlign: "left",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // minWidth: "118px",
    borderRadius: "5px",
    boxShadow: "none",
    border: 0,
    fontSize: "16px",
    fontFamily: "Inter",
    // fontWeight: "bold",
    // maxWidth: "200px",
    // marginBottom: "5px",
    backgroundColor: 'var(--t-blue)',
    width: "100%",
    // textDecoration: outside ? 'underline' : 'none',
  }),
  indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    color: 'var(--simple-brown)',
    width: "40px"
  }),
})

export const customStylesRSMultiWhite = () => ({
  ...customStylesReactSelect(),
  multiValue: (styles: any) => ({
    ...styles,
    backgroundColor: "var(--simple-brown)",
    marginLeft: '10px',
  }),
  option: (styles: any) => ({
    ...styles,
    color: 'var(--black)',
    backgroundColor: 'var(--white)',
    fontFamily: 'Inter',
    textAlign: "left",
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: 'var(--valhalla)'
  }),
  menu: (styles: any) => ({
    ...styles,
    borderRadius: 8,
    backgroundColor: "var(--white)",
    zIndex: 1,
    overflow: 'scroll'
  }),
  input: (styles: any, state: any) => ({
    ...styles,
    border: 'none',
    color: 'white',
    fontWeight: 200,
    paddingLeft: "5px",
    "&:focus": {backgroundColor: 'red'},
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: 'var(--simple-gray)',
    textDecoration: 'none',
    display: 'block',
    paddingLeft: "8px",
  }),
  control: (styles: any, state: any) => ({
    ...styles,
    textAlign: "left",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    boxShadow: "none",
    border: "1px solid var(--simple-gray)",
    fontSize: "16px",
    fontFamily: "Inter",
    backgroundColor: 'var(--white)',
    width: "100%",
  }),
  indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    color: 'var(--black)',
    width: "40px"
  }),
})

export const customStylesRSMultiListModal = () => ({
  ...customStylesReactSelect(),
  multiValue: (styles: any) => ({
    ...styles,
    backgroundColor: "var(--simple-brown)",
    marginLeft: '10px',
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: 'var(--valhalla)'
  }),
  input: (styles: any, state: any) => ({
    ...styles,
    border: 'none',
    color: 'white',
    fontWeight: 200,
    paddingLeft: "5px",
    // width: '100%',
    "&:focus": {backgroundColor: 'red'},
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: '#4e331c8b;',
    textDecoration: 'none',
    display: 'block',
    paddingLeft: "8px",
  }),
  control: (styles: any, state: any) => ({
    ...styles,
    textAlign: "left",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // minWidth: "118px",
    borderRadius: "5px",
    boxShadow: "none",
    border: 0,
    fontSize: "16px",
    fontFamily: "Inter",
    // fontWeight: "bold",
    // maxWidth: "200px",
    // marginBottom: "5px",
    backgroundColor: 'var(--simple-brown)',
    width: "100%",
    // textDecoration: outside ? 'underline' : 'none',
  }),
  indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    color: 'var(--simple-brown)',
    width: "40px"
  }),
})

export const customStylesRSLeft = () => ({
  option: (styles: any) => ({
    ...styles,
    color: 'white',
    backgroundColor: '#383838',
    fontFamily: 'Inter',
    textAlign: "left",
  }),
  input: (styles: any, state: any) => ({
    ...styles,
    border: 'none',
    color: 'white',
    fontWeight: 200,
    paddingLeft: "5px",
    width: '100%',
    "&:focus": {backgroundColor: 'red'},
  }),
  container: (styles: any) => ({
    ...styles,
    padding: 0,
    width: '100%',
  }),
  valueContainer: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: 'transparent',
    padding: 0,
  }),
  menu: (styles: any) => ({
    ...styles,
    borderRadius: 8,
    backgroundColor: "#383838",
  }),
  control: (styles: any, state: any) => ({
    ...styles,
    textAlign: "left",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // minWidth: "118px",
    boxShadow: "none",
    border: 0,
    borderRadius: "5px",
    fontSize: "16px",
    fontFamily: "Inter",
    // fontWeight: "bold",
    // maxWidth: "200px",
    marginBottom: "5px",
    backgroundColor: 'var(--t-blue)',
    width: "100%",
    // textDecoration: outside ? 'underline' : 'none',
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: '#FFFFFF77',
    textDecoration: 'none',
    display: 'block',
    paddingLeft: "8px",
  }),
  indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    color: 'var(--simple-brown)',
    width: "40px"
  }),
  noOptionsMessage: (styles: any) => ({
    ...styles,
    color: 'white',
    backgroundColor: 'grey',
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: "normal",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: 'white',
    backgroundColor: 'transparent',
    border: "none",
    paddingLeft: "5px",
    paddingRight: "5px",
    
  }),
})

export const customStylesReactSelectYO = () => ({
  option: (styles: any) => ({
    ...styles,
    color: 'white',
    backgroundColor: '#383838',
    fontFamily: 'Inter',
  }),
  input: (styles: any, state: any) => ({
    ...styles,
    border: 'none',
    color: 'white',
    fontWeight: 200,
    paddingLeft: "5px",
    width: '100%',
    "&:focus": {backgroundColor: 'red'},
  }),
  container: (styles: any) => ({
    ...styles,
    padding: 0,
    width: '100%',
  }),
  valueContainer: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: 'transparent',
    padding: 0,
  }),
  menu: (styles: any) => ({
    ...styles,
    borderRadius: 8,
    backgroundColor: "#383838",
  }),
  control: (styles: any, state: any) => ({
    ...styles,
    textAlign: "left",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // minWidth: "118px",
    boxShadow: "none",
    fontSize: "16px",
    fontFamily: "Inter",
    // fontWeight: "bold",
    // maxWidth: "200px",
    marginBottom: "5px",
    backgroundColor: '#2c2c2c',
    width: "100%",
    border: "1px solid var(--simple-brown)",
    borderRadius: "3px",
    // textDecoration: outside ? 'underline' : 'none',
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: '#ffffff99',
    textDecoration: 'none',
    display: 'block',
    paddingLeft: "10px",
  }),
  indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    color: 'var(--simple-brown)',
    width: "40px"
  }),
  noOptionsMessage: (styles: any) => ({
    ...styles,
    color: 'white',
    backgroundColor: 'grey',
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: "normal",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: 'white',
    backgroundColor: 'transparent',
    border: "none",
    paddingLeft: "5px",
    paddingRight: "5px",
    
  }),
})

export const DropdownIndicator = (props: DropdownIndicatorProps<SelectItem>) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon
          icon="chevron-down"
          size='sm'
          color={BROWN}
          // className="chevron-down  "
        />
      </components.DropdownIndicator>
    )
  );
};