import React, { useEffect, useState, useContext } from 'react';
import styled from "styled-components";
import {
    Card, InfoProfile, StyledFontAwesomeIcon, TitleCard, Info, Name,
    ReadMore
} from '../HealerProfile/components';
import { faLocationDot } from "@fortawesome/pro-thin-svg-icons";
import { faFileLines, faPlus, faUserPlus, faMessage, faUserPen, faStar } from "@fortawesome/pro-regular-svg-icons";
import { IUserGradeResponse } from '@vibe/sdk/dist/interfaces/Admin/User';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { rolesType } from '@vibe/sdk/dist/interfaces/AccessManager/signUpUser';
import UserSocialMedia from '@vibe/sdk/dist/interfaces/Roster/userSocialMedia';
import { ICertification, IDegreeCertification, IExperienceCertification } from '@vibe/sdk/dist/interfaces/Modalities/certifications/Certification';
import IHS from '@vibe/sdk/dist/interfaces/Modalities/hservices/HS';
import IHealer from '@vibe/sdk/interfaces/Admin/IHealer';
import { IEducation } from '@vibe/sdk/dist/interfaces/Roster/UserInfo';
import Conditions from '../BaseComponents/Conditions';
import NewCardHealer from '../BaseComponents/Healers/NewCardHealer';
import ModalitiesDisplay, { MockModMapped, ModMapped, methodDelivery } from '../BaseComponents/Modalities/ModalitiesDisplay';
import ModalDescription from '../BaseComponents/Modals/ModalDescription';
import HealersModal from './HealersModal';
import { IconClicker } from '../Home/HeaderHome/styles';
import { useNavigate } from 'react-router-dom';
import { cleanHTML, cleanStyleHTML } from '../../helpers/letterHelper';
import BtnFollow from '../BaseComponents/Follow/BtnFollow';
import { IOrganizationExtended } from '@vibe/sdk/interfaces/Admin/Organization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap } from '@fortawesome/pro-solid-svg-icons';
import ModalModalityProfile from '../BaseComponents/Modalities/ModalModalityProfile';
import AvatarInitials from '../BaseComponents/Healers/AvatarInitials';
import { ContextSuccess } from "../../Context";

export const isIDegreeCertification = (obj: ICertification): obj is IDegreeCertification => 'dateEarned' in obj;
export const isIExperienceCertification = (obj: ICertification): obj is IExperienceCertification => !isIDegreeCertification(obj);

const index = ({ tabActiveBack, seeAllSection }: { tabActiveBack: string, seeAllSection: boolean }) => {

    const navigate = useNavigate();
    const currentSuccess = useContext(ContextSuccess);

    const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);
    const userProfile: IUserGradeResponse = useSelector((state: RootState) => state.getUser.userProfile);
    const orgProfile: IOrganizationExtended = useSelector((state: RootState) => state.getOrgs.orgHealerProfile);
    const orgHealers: IHealer[] = useSelector((state: RootState) => state.getOrgs.orgHealers);
    const services: IHS[] = useSelector((state: RootState) => state.getModality.getHealerServices);
    const userRoles = useSelector((state: RootState) => state.getUser.userRoles);
    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
    const isLoggedIn = useSelector((state: RootState) => state.getUser.isLoggedIn);

    const [typeHealer, setTypeHealer] = useState<rolesType>('INDIVIDUAL');

    const [modalDescription, setModalDescription] = useState<boolean>(false);
    const [activeDescription, setActiveDescription] = useState<boolean>(false);
    const [modalModalities, setModalModalities] = useState<boolean>(false);
    const [modalHealers, setModalHealers] = useState<boolean>(false);
    const [showAllEducation, setShowAllEducation] = useState<boolean>(false);
    const [showAllCredentials, setShowAllCredentials] = useState<boolean>(false);

    const [avatar, setAvatar] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [education, setEducation] = useState<IEducation[]>([]);
    const [tagsUser, setTagsUser] = useState<string[]>([]);
    const [seekerCount, setSeekerCount] = useState<number>(0);
    const [media, setMedia] = useState<UserSocialMedia[]>([]);
    const [city, setCity] = useState<string>('');
    const [zipcode, setZipcode] = useState<string>('');
    const [country, setCountry] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [certifications, setCertifications] = useState<ICertification[]>([]);
    const [modalities, setModalities] = useState<ModMapped[]>([]);

    const [modalitySelected, setModalitySelected] = useState<ModMapped>({ ...MockModMapped });
    const [showModModal, setShowModModal] = useState<boolean>(false);

    const getHealers = () => {
        return orgHealers.slice(0, 5);
    }

    const getCertification = (cert: any) => {

        const handleDownloadPdf = (name: string, link?: string) => {
            if (link) {
                fetch(link)
                    .then(response => response.blob())
                    .then(blob => {
                        const url = window.URL.createObjectURL(new Blob([blob]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${name}_Certification.pdf`);
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode?.removeChild(link);
                    })
                    .catch(error => {
                        console.log(error)
                    });
            }
        }
        if (isIDegreeCertification(cert)) {
            return (
                <ItemCertification>

                    {/* <a href={cert.pdfLink || ''} target="_blank" rel="noopener noreferrer"> */}
                    {cert.pdfLink &&
                        <StyledFontAwesomeIcon
                            icon={faFileLines}
                            size='lg'
                            color={"var(--abzolute-zero)"}
                            style={{
                                fontSize: '16px'
                            }}
                            onClick={() => handleDownloadPdf(cert.name, cert.pdfLink || '')}
                        />
                    }

                    {/* </a> */}

                    <InfoCertification>
                        {cert.name}
                        <a href={cert.grantedOrgWebsite || ''} target="_blank" rel="noopener noreferrer">
                            <InfoGarantedOrg hasWeb={'grantedOrgWebsite' in cert && cert.grantedOrgWebsite !== ''}>
                                {cert.grantedOrgName}
                            </InfoGarantedOrg>
                        </a>
                        <Row>
                            <FontAwesomeIcon
                                icon={faGraduationCap}
                                size='sm'
                            />
                            <InfoYear>{cert.dateEarned}</InfoYear>
                        </Row>
                    </InfoCertification>
                </ItemCertification>
            );
        } else {
            return (
                <ItemCertification>
                    <StyledFontAwesomeIcon
                        icon={faStar}
                        size='lg'
                        style={{
                            fontSize: '16px'
                        }}
                    />
                    <InfoCertification>
                        {cert.name}
                        {cert.lineage && <InfoYear>{cert.lineage} lineage</InfoYear>}
                        <InfoYear>{cert.yearsOfExperience} years of experience</InfoYear>
                    </InfoCertification>
                </ItemCertification>
            );
        }
    }

    const copyLink = () => {
        navigator.clipboard.writeText(window.location.href)
        currentSuccess?.setSuccessData(true, true, `Link copied to clipboard`);
    }

    const getCertifications = () => {
        const result = [];

        for (let i = 0; i < certifications.length; i++) {
            result.push(getCertification(certifications[i]));
            if (i === 1 && certifications.length > 2) {
                result.push(
                    <ReadMore onClick={() => setShowAllCredentials(true)}>See All</ReadMore>
                );
                break;
            }
        }
        return result;
    }

    const getEducation = () => {
        const result = [];

        for (let i = 0; i < education.length; i++) {
            const cert = education[i];
            result.push(
                <ItemCertification>
                    <InfoCertification>
                        {cert.degree_name}
                        <InfoYear>{cert.college}</InfoYear>
                        <Row>
                            <FontAwesomeIcon
                                icon={faGraduationCap}
                                size='sm'
                            />
                            <InfoYear>{cert.graduation_year}</InfoYear>
                        </Row>
                    </InfoCertification>
                </ItemCertification>
            );
            if (i === 1 && education.length > 2) {
                result.push(
                    <ReadMore onClick={() => setShowAllEducation(true)}>See All</ReadMore>
                );
                break;
            }
        }
        return result;
    }

    const updateData = () => {
        if (userProfile.roles.includes('INDIVIDUAL')) {
            setAvatar(userProfile.avatar || '');
            setName(`${userProfile.firstname} ${userProfile.lastname}`);
            setTagsUser(userProfile.interests || []);
            setCity(userProfile.city || '');
            setCountry(userProfile.country);
            setZipcode(userProfile.zipcode);
            setDescription(userProfile.biography || '');
            setCertifications(userProfile.certs || []);
            setEducation(userProfile.ed || []);
        }
        else {
            setAvatar(orgProfile.logo || '');
            setName(orgProfile.name);
            setTagsUser([]);
            setCity(orgProfile.city);
            setCountry(orgProfile.country);
            setZipcode(orgProfile.zipcode);
            setDescription(orgProfile.mission || '');
            setCertifications([]);
            setEducation([]);
        }

        setSeekerCount(orgProfile.seekers || 0);
        setMedia(userProfile.sm || [])
        setModalities(services.map(sr => {
            const deliveryMethods: methodDelivery[] = [];
            sr.delivery.forEach((del) => {
                if (del.online && !deliveryMethods.includes('Online')) deliveryMethods.push('Online');
                if (del.inPerson && !deliveryMethods.includes('InPerson')) deliveryMethods.push('InPerson');
                if (del.combined && !deliveryMethods.includes('Combined')) deliveryMethods.push('Combined');
            })
            return ({
                id: sr.id,
                name: sr.name,
                delivery: deliveryMethods,
                service: sr,
            })
        }));
        getHealers();
    }

    const getImageMedia = (social: UserSocialMedia): string => {
        switch (social.platform) {
            case 'Facebook':
                return '../facebook-f.svg';
            case 'YouTube':
                return '../youtube.svg';
            case 'Instagram':
                return '../instagram.svg';
            case 'Twitter':
                return '../twitter.svg';
            case 'LinkedIn':
                return '../linkedin.svg';
            case 'Pinterest':
                return '../pinterest.svg';
            case 'Snapchat':
                return '../snapchat.svg';
            case 'TikTok':
                return '../tiktokicon.svg';
            case 'Zaahah':
                return '../Zaahah.svg';
            case 'Personal':
                return '../globe-solid.svg'
            case 'Substack':
                return '../substack.svg'
            default:
                return '';
        }
    }

    const goEditUser = () => {
        if (userRoles.includes('INDIVIDUAL')) {
            navigate(`../../../../../../edit-healer-ind-profile/${userInfo?.username || ''}?bulletin=false`);
            return;
        }

        navigate(`../../../../../../edit-healer-profile/${userInfo?.username || ''}?bulletin=false`)
    }

    const showFollowBtn = (): boolean => {
        if (!isLoggedIn) return true;

        const arrayA = userProfile.organizations || [];
        const arrayB = user.organizations || []
        const hasCommonElementInA = arrayA.some(elementA => arrayB.includes(elementA));
        const hasCommonElementInB = arrayB.some(elementB => arrayA.includes(elementB));

        return !(hasCommonElementInA || hasCommonElementInB);
    }

    const openModalityModal = (service: ModMapped) => {
        setModalitySelected(service);
        setShowModModal(true);
    }

    useEffect(() => {
        if (userProfile && userProfile.roles.includes('INDIVIDUAL')) setTypeHealer('INDIVIDUAL')
        else setTypeHealer('HEALER')

        updateData();
    }, [userProfile, orgProfile])

    useEffect(() => {
        if (userProfile && userProfile.roles.includes('INDIVIDUAL')) setTypeHealer('INDIVIDUAL')
        else setTypeHealer('HEALER')

        updateData();
    }, [])

    useEffect(() => {
        console.log(tabActiveBack)
    }, [tabActiveBack]);

    return (
        <ColumnLeft>
            {!seeAllSection &&
                <>
                    <CardMdl>
                        <CardDetail theme={{ main: activeDescription ? 'showDesc' : 'HideDesc' }}>
                            <InfoProfile>
                                <AvatarInitials
                                    avatar={avatar || ""}
                                    name={name}
                                    size={56}
                                />
                                <InfoCard>
                                    <InfoRow>
                                        <NameUserCard theme={{ name: name }}>{name}</NameUserCard>
                                        {(process.env.REACT_APP_SHOW_EDIT_PROFILE === 'true' && userProfile.username === userInfo?.username) && (
                                            <IconClicker
                                                icon={faUserPen}
                                                size="lg"
                                                color='#272E35'
                                                onClick={goEditUser}
                                            />
                                        )}
                                        <Tooltip theme={{ top: -25 }}>{name}</Tooltip>
                                    </InfoRow>
                                    <InfoRow>
                                        <LocationCard>
                                            {city ? city : zipcode}, {country}
                                        </LocationCard>
                                        <Tooltip theme={{ top: -30 }}>{city ? city : zipcode}, {country}</Tooltip>
                                    </InfoRow>
                                </InfoCard>
                            </InfoProfile>
                            {/* {!activeDescription && <InfoMedia>
                                <InfoSeekers><IconSeeker /> {`${seekerCount}`} Seekers</InfoSeekers>
                                <SocialMedia>
                                    {media.map(social => (
                                        <a href={social.link} target="_blank" rel="noopener noreferrer">
                                            <IconMedia src={getImageMedia(social)} />
                                        </a>
                                    ))}
                                </SocialMedia>
                            </InfoMedia>} */}
                            {/* {activeDescription && */}
                            <InfoMedia>
                                <InfoSeekers><IconSeeker /> {`${seekerCount} ${seekerCount > 1 ? 'Seekers' : 'Seeker'}`}</InfoSeekers>
                                <SocialMedia>
                                    {userProfile.username === userInfo?.username &&
                                        < LinkVibe onClick={copyLink}>
                                            <Tooltip className="tooltip">Copy your vibe link</Tooltip>
                                            <IconMedia src={'../../../vibeLogo.svg'} />
                                        </LinkVibe>
                                    }
                                    {media.filter(social => social.platform != 'Personal').map(filteredPerson => (
                                        <a href={filteredPerson.link} target="_blank" rel="noopener noreferrer">
                                            <IconMedia src={getImageMedia(filteredPerson)} />
                                        </a>
                                    ))}

                                    {media.filter(social => social.platform == 'Personal').map(filteredPerson => (
                                        <LinkWeb href={filteredPerson.link} target="_blank" rel="noopener noreferrer">
                                            Website
                                        </LinkWeb>
                                    ))}
                                </SocialMedia>
                            </InfoMedia>
                            {/* } */}
                            <Description
                                theme={{ main: activeDescription ? 'active' : '' }}
                                dangerouslySetInnerHTML={{ __html: cleanStyleHTML(description as string ?? '') }}>
                            </Description>

                            <ContainerCertifications>
                                {(typeHealer === 'INDIVIDUAL' && activeDescription) && (
                                    <IndividualInfo>
                                        <Card>
                                            <TitleCard>
                                                Certifications
                                            </TitleCard>
                                            {certifications.map((cert) => getCertification(cert))}
                                        </Card>

                                        <Card>
                                            <TitleCard>
                                                Education
                                            </TitleCard>
                                            {education.map((cert) => (
                                                <ItemCertification>
                                                    <InfoCertification>
                                                        {cert.degree_name}
                                                        <InfoYear>{cert.college}</InfoYear>
                                                        <Row>
                                                            <FontAwesomeIcon
                                                                icon={faGraduationCap}
                                                                size='sm'
                                                            />
                                                            <InfoYear>{cert.graduation_year}</InfoYear>
                                                        </Row>
                                                    </InfoCertification>
                                                </ItemCertification>
                                            ))}
                                        </Card>
                                    </IndividualInfo>
                                )}
                            </ContainerCertifications>
                        </CardDetail>
                        <ButtonsActions>
                            <ReadMorePlus
                                onClick={() => setActiveDescription(!activeDescription)}
                            >
                                <div>{activeDescription ? 'Read Less' : 'Read More'}</div>
                                <IconClicker
                                    icon={faPlus}
                                    size='lg'
                                    color={"black"}
                                    style={{
                                        fontSize: '16px'
                                    }}
                                />
                            </ReadMorePlus>
                            {false/*userProfile.username !== userInfo?.username*/ && ( //@todo add onClick to add connection
                                <ButtonAddFriend>
                                    <StyledFontAwesomeIcon
                                        icon={faUserPlus}
                                        size='lg'
                                        color={"white"}
                                        style={{
                                            fontSize: '20px',
                                            marginRight: '0px'
                                        }}
                                    />
                                </ButtonAddFriend>
                            )}

                            {showFollowBtn() && <WrapperFollow><BtnFollow username={userInfo?.username || ''} orgID={orgProfile.id} /></WrapperFollow>}

                            {process.env.REACT_APP_SHOW_CHAT === 'true' && ( // Improve this logic afterwards
                                <SectionButtonMessage>
                                    <ButtonMessage>
                                        <StyledFontAwesomeIcon
                                            icon={faMessage}
                                            size='lg'
                                            color={"black"}
                                            style={{
                                                fontSize: '18px',
                                                marginRight: '0px'
                                            }}
                                        />

                                    </ButtonMessage>
                                    <Tooltip theme={{ top: -30 }}>Message</Tooltip>
                                </SectionButtonMessage>
                            )}
                        </ButtonsActions>
                        <ViewMore
                            onClick={() => setActiveDescription(!activeDescription)}
                        >
                            {activeDescription ? 'Hide info' : 'View More'}
                        </ViewMore>
                    </CardMdl>

                    {(typeHealer === 'INDIVIDUAL') ? (
                        <IndividualInfo theme={{ main: 'hddCard' }}>
                            <CardMod theme={{ main: tabActiveBack !== 'Vibes' ? 'tabVibes' : '' }}>
                                <ModalitiesDisplay
                                    modalities={modalities}
                                    openModal={openModalityModal}
                                />
                            </CardMod>

                            <Card>
                                <TitleCard>
                                    Credentials
                                </TitleCard>
                                {!showAllCredentials ? getCertifications() : (
                                    <>
                                        {certifications.map((cert) => getCertification(cert))}
                                        <ReadMore onClick={() => setShowAllCredentials(false)}>See Less</ReadMore>
                                    </>
                                )}
                            </Card>

                            <Card>
                                <TitleCard>
                                    Education
                                </TitleCard>
                                {!showAllEducation ? getEducation() : (
                                    <>
                                        {education.map((cert) => (
                                            <ItemCertification>
                                                <InfoCertification>
                                                    {cert.degree_name}
                                                    <InfoYear>{cert.college}</InfoYear>
                                                    <Row>
                                                        <FontAwesomeIcon
                                                            icon={faGraduationCap}
                                                            size='sm'
                                                        />
                                                        <InfoYear>{cert.graduation_year}</InfoYear>
                                                    </Row>
                                                </InfoCertification>
                                            </ItemCertification>
                                        ))}
                                        <ReadMore onClick={() => setShowAllEducation(false)}>See Less</ReadMore>
                                    </>
                                )}
                            </Card>
                        </IndividualInfo>
                    ) : (
                        <BusinessInfo>
                            <CardMod theme={{ main: tabActiveBack !== 'Vibes' ? 'tabVibes' : '' }}>
                                <ModalitiesDisplay
                                    modalities={modalities}
                                    openModal={openModalityModal}
                                />
                            </CardMod>

                            <CardHalers>
                                <TitleCardPd>
                                    <TitleIcon>
                                        <IconHealer />
                                        Healers
                                    </TitleIcon>
                                    <ReadMore onClick={() => setModalHealers(true)}>Total {orgHealers.length}</ReadMore>
                                </TitleCardPd>
                                {getHealers().map(healer => (
                                    <NewCardHealer data={healer} showLocation />
                                ))}
                            </CardHalers>

                            {/* <Conditions /> */}
                        </BusinessInfo>
                    )}
                </>
            }

            <ModalDescription
                showModal={modalDescription}
                setShowModal={setModalDescription}
                description={description}
                title={name}
            />
            <HealersModal
                showModal={modalHealers}
                setShowModal={setModalHealers}
                healers={orgHealers}
            />
            <ModalModalityProfile
                showModal={showModModal}
                onHide={() => setShowModModal(false)}
                modality={modalitySelected}
            />
        </ColumnLeft >
    )
}

const LinkVibe = styled.div`
    cursor: pointer;
    position: relative;

    &:hover .tooltip{
        display: flex;
        left: 0;
        top: -31px;
    }

    & .tooltip::after{
        display: none;
    }

`

const SectionButtonMessage = styled.div`
    position: relative;
`

const InfoCard = styled(Info)`
    width: calc(100% - 70px);
`

const LinkWeb = styled.a`
    text-decoration: underline !important;
    display: block !important;
    color: var(--absolute-zero);
    font-family: 'Inter-SemiBold';
`

const InfoSeekers = styled.div`
    display: flex;
    align-items: center;
`

const Tooltip = styled.div`
    background-color: black;
    color: white;
    content: "";
    position: absolute;
    padding: 0px 9px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 7px;
    top: ${props => props.theme.top}px;
    font-size: 12px;
    left: -70px;
    right: 0;
    margin: 0 auto;
    height: 24px;
    display: flex;
    width: max-content;
    display: none;

    &::after{
        width: 0;
        height: 0;
        display: block;
        position: absolute;
        top: 23px;
        left: 0;
        right: 0;
        margin: 0 auto;
        content: "";
        border-right: 5px solid transparent;
        border-top: 5px solid #000000;
        border-left: 5px solid transparent;
    }
`

const NameUserCard = styled(Name)`
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
    cursor: pointer;

    &:hover ~ ${Tooltip}{ display: flex; }
`

const Location = styled.div`
    font-family: 'Inter';
    font-size: 14px;
    text-align: left;
    margin-top: ${props => props.theme.top || '0px'};
`

const LocationCard = styled(Location)`
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
    cursor: pointer;

    &:hover ~ ${Tooltip}{ display: flex; }
`

const TitleCardPd = styled(TitleCard)`
    padding: 0 17px;
`

const Row = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
`;

const WrapperFollow = styled.div`
    width: fit-content;
`;

const InfoRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: calc(100%);
    gap: 10px;
    position: relative;
`;

const ButtonsActions = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    padding-top: 3px;

    @media only screen and (max-width: 900px) {
        justify-content: flex-start;
        padding-top: 5px;
    } 
`
const ButtonAddFriend = styled.div`
    background-color: #e59b50;
    padding: 7px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
`

const ButtonMessage = styled.div`
    background-color: white;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--simple-gray);

    &:hover ~ ${Tooltip}{
        display: flex;
        left: -10px;
    }

`

const ReadMorePlus = styled.div`
    font-family: 'Inter-SemiBold';
    line-height: 17px;
    color: #333;
    text-align: left;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 0.1px;
    display:flex;
    gap: 5px;
    align-items: center;
    width: fit-content;
`

const ViewMore = styled.div`
    display: none;
    @media only screen and (max-width: 900px) {
        display: flex;
        font-family: 'Inter-Bold';
        font-size: 14px;
        line-height: 17px;
        color: var(--absolute-zero);
        text-align: left;
        cursor: pointer;
        margin-top: 10px;
    } 
`

const CardMod = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    width: 100%;
    background-color: white;
    border-radius: 8px;
    border: 1px solid var(--simple-gray);
    display: flex;
    @media only screen and (max-width: 900px) {
        position: fixed;
        bottom: 0;
        z-index: 9999;
        width: 100%;
        border-radius: 0;  

        &.tabVibes{
            display: none
        }
    }

`

const CardHalers = styled(Card)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 17px 0;

    @media only screen and (max-width: 900px) {
        display: none;
    }
`

const IndividualInfo = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    width: 100%;
    height: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media only screen and (max-width: 900px) {
        &.hddCard ${Card}{
            display: none
        }
        height: 200px;
    }
`;

const BusinessInfo = styled.div`
    width: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const ItemCertification = styled.div`
    display: flex;
    padding: 17px 0;
    border-bottom: 1px solid #c7c7c7;
`
const InfoCertification = styled.div`
    display: flex;
    flex-direction: column;
    align-items: baseline;
    font-family: 'Inter-Bold';
    font-size: 14px;
    gap: 7px;
`
const InfoYear = styled.span`
    font-family: 'Inter';
    font-size: 14px; 
`

const InfoGarantedOrg = styled.span`
    font-family: 'Inter';
    font-size: 14px; 
    color: ${({ hasWeb }: { hasWeb: boolean }) => hasWeb ? 'var(--absolute-zero)' : 'black'};
`

const Avatar2 = styled.img`
    width: 56px;
    height: 56px;
    min-width: 56px;
    min-height: 56px;
    max-width: 56px;
    max-height: 56px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #aeaeae;
`

const ItemsP = styled.div`
    display: flex;
    gap: 4px;
`
const ItemP = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    height: 23px;
    background: #FFFFFF;
    border: 1px solid #665D5A;
    border-radius: 16px;
    font-family: 'Inter';
    font-size: 12px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    max-width: 70px;
    white-space: nowrap;
    justify-content: flex-start;
`

const TitleIcon = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`
const Items = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
`
const Item = styled.div`
    padding: 4px 8px;
    background: #E5DCD0;
    border-radius: 16px;
    font-family: 'Inter';
    font-size: 12px;
    height: 23px;
    display: flex;
    align-items: center;
`
const Description = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    display: -webkit-box;
    width: 100%;
    text-align: initial;
    margin: 5px 0;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &.active {
        max-height: fit-content;
        -webkit-box-orient: initial;
    }

    @media only screen and (max-width: 900px) {
        padding: 0px;
        max-height: calc(100vh - 335px);
        overflow: scroll;
        display: flex;  
    }
`
const SocialMedia = styled.div`
    display: flex;
    gap: 16px;
    width: 100%;
    align-items: center;
    padding: 0 11px;
    padding-left: 0px;
`
const IconMedia = styled.img`
    width: 17px;
    height: 17px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    cursor: pointer;
`
const ColumnLeft = styled.div`
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media only screen and (max-width: 900px) {
        width: 100%;
    }
`
const IconSeeker = styled.div`
    width: 30px;
    height: 30px;
    // background-color: var(--simple-green);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: relative;
    background-image: url(../icon_seeker.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 80% auto;
`
const IconHealer = styled.div`
    width: 25px;
    height: 25px;
    // background-color: var(--electric-yellow-2);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: relative;
    background-image: url(../icon_healer.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 80% auto;
`
const InfoMedia = styled.div`
    display: ${props => props.theme.main || 'flex'};
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
    font-family: 'Inter';
    font-size: 12px;
    gap: 8px;
    overflow: scroll;
    //max-width: 180px;
    margin: 10px 0;
    flex-wrap: wrap;
    @media only screen and (max-width: 900px) {
        justify-content: flex-start;
        margin-top: 6px;
    }

`

const CardDetail = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    @media only screen and (max-width: 900px) {
        -webkit-transition: max-height 1s;
        -moz-transition: max-height 1s;
        -ms-transition: max-height 1s;
        -o-transition: max-height 1s;
        transition: max-height 1s;
        overflow: hidden;
        max-height: 82px;

        &.showDesc{
            max-height: 500px;
        }
    }
`

const CardMdl = styled(Card)`
    @media only screen and (max-width: 900px) {
        border-radius: 0px;
        position: fixed;
        z-index: 9999;
        background-color: white;
        overflow: hidden;
        border: 0px solid var(--simple-gray);

        & ${ReadMorePlus}{
            display: none;
        }
        & ${Avatar2}{
            width: 82px;
            height: 82px;
        }
        & ${IconSeeker}{
            width: 16px;
            height: 16px; 
        }
    }
`

const ContainerCertifications = styled.div`
    display: none;
    @media only screen and (max-width: 900px) {
        display: flex;
        height: 100%;

        & ${Card}{
            border: 0px;
            border: 0px;
            padding: 13px 0;
            border-top: 1px solid var(--simple-gray);
            border-radius: 0;
        }

    }
`

export default index