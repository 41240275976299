import { useState } from 'react';
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faThumbTack, faMagnifyingGlass, faArrowUpArrowDown, faCaretDown, faCalendar } from "@fortawesome/pro-regular-svg-icons";
import {
    ContainerTable, Filters, InputSearch, Input, SelectFilter, StyledTable, THead, TR, TD,
    TextHead, TBody, ViewUser, Avatar, TextBlue, Count, Price, IconGeoUp
} from "./Dashboards.styles"
import useDashboard from '../../hooks/Dashboard/useDashboard';

const getTableRow = () => {
    return (
        <TR>
            <TD>
                <ViewUser>
                    <Avatar src={"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} />
                    Rebecca Pollock
                </ViewUser>
            </TD>
            <TD>
                <TextBlue>Sen Wellness Sanctuaryladhadahsd Sen Wellness Sanctuaryladhadahsd</TextBlue>
            </TD>
            <TD>
                <Count>
                    <Price>
                        $2500.00
                    </Price>
                    <IconGeoUp theme={{ color: '#0F9936' }} />
                </Count>
            </TD>
            <TD>
                <TextBlue>Hypnosis as a clinical taosihdoaisdh</TextBlue>
            </TD>
            <TD>
                Ago 31, 1023
            </TD>
        </TR>
    )
}

const TableRevenue = () => {
    const {} = useDashboard() // @todo FCV
    return (
        <ContainerTable>
            <Filters>
                <InputSearch>
                    <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        size="sm"
                        color={'black'}
                    />
                    <Input />
                </InputSearch>
                <SelectFilter>
                    <FontAwesomeIcon
                        icon={faUser}
                        size="sm"
                        color={'black'}
                    />
                    Gender
                    <FontAwesomeIcon
                        icon={faCaretDown}
                        size="sm"
                        color={'black'}
                    />
                </SelectFilter>
                <SelectFilter>
                    <FontAwesomeIcon
                        icon={faCalendar}
                        size="sm"
                        color={'black'}
                    />
                    Age
                    <FontAwesomeIcon
                        icon={faCaretDown}
                        size="sm"
                        color={'black'}
                    />
                </SelectFilter>
                <SelectFilter>
                    <FontAwesomeIcon
                        icon={faThumbTack}
                        size="sm"
                        color={'black'}
                    />
                    Location
                    <FontAwesomeIcon
                        icon={faCaretDown}
                        size="sm"
                        color={'black'}
                    />
                </SelectFilter>
            </Filters>
            <StyledTable>
                <THead>
                    <TR>
                        <TD>
                            <TextHead>
                                Member
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    size="sm"
                                    color={'black'}
                                />
                            </TextHead>

                        </TD>
                        <TD>
                            <TextHead>
                                Communities
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    size="sm"
                                    color={'black'}
                                />
                            </TextHead>
                        </TD>
                        <TD>
                            <TextHead>
                                Revenue
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    size="sm"
                                    color={'black'}
                                />
                            </TextHead>
                        </TD>
                        <TD>
                            <TextHead>
                                Events
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    size="sm"
                                    color={'black'}
                                />
                            </TextHead>
                        </TD>
                        <TD>
                            <TextHead>
                                Date created
                                <FontAwesomeIcon
                                    icon={faArrowUpArrowDown}
                                    size="sm"
                                    color={'black'}
                                />
                            </TextHead>
                        </TD>
                    </TR>
                </THead>
                <TBody>
                    {getTableRow()}
                    {getTableRow()}
                </TBody>
            </StyledTable>
        </ContainerTable>
    )
}

export default TableRevenue