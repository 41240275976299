import { useEffect, useState, useContext } from 'react'
import { ContextSuccess } from "../../Context";
import { onHideType } from "../../components/BaseComponents/Modals/ModalUnsaved";
import styled from "styled-components";
import {
    TitleContent, Accordion, ContainerMedia, MediaHeader, ContainerInputs, Inputs,
    Label, Input, Row1, MediaFooter, ButtonSave, RadioButtons
} from "../signupsetuphealerind"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDot, faCircle, faPlus, faPen, faSearch, faTrash } from '@fortawesome/pro-regular-svg-icons';
import ModalSetup from '../../components/signupsetuphealerind/ModalSetup';
import { ProcessInterface } from '../../containers/SetupHealer/SetupHealer';
import IHS from '@vibe/sdk/dist/interfaces/Modalities/hservices/HS';
import Delivery from '@vibe/sdk/interfaces/Modalities/hservices/Delivery';
import { useDispatch, useSelector } from 'react-redux';
import useModalitiesSA from '../../hooks/Superadmin/Modalities/useModalitiesSA';
import { RootState } from '../../store/store';
import ModalServices from '../signupsetuphealer/ModalServices';
import { customModality } from '../signupsetuphealer/Services';
import ModalDelete from '../BaseComponents/Modals/ModalDelete';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { getHealerServices } from '../../store/actions/modalityActions';
import IResponseError from '@vibe/sdk/dist/interfaces/IResponseError';
import Modality from '@vibe/sdk/interfaces/Modalities/modalities/Modality/Modality';
import { sortAlphabeticallyOnProperty } from '../../containers/Signup/SignupHealerInd';
import BlankTable from '../BaseComponents/BlankTable';
import { Scroll_Content_Signup } from '../signupsetuphealer/Introduction';

const MyServices = ({
    processData,
}: {
    processData: ProcessInterface
}) => {

    const dispatch = useDispatch();
    const currentSuccess = useContext(ContextSuccess);
    const { modalitiesPages, reloadModalities } = useModalitiesSA({
        pageSizeProp: 1000,
        searchName: '',
    });

    const organization = useSelector((state: RootState ) => state.getOrgs.organizationInfo);
    const services = useSelector((state: RootState ) => state.getModality.getHealerServices);
    const username = useSelector((state: RootState ) => state.getUser.userInfo?.username || '');
    const [showModal, setShowModal] = useState(false)

    const [filteredModality, setFilteredModality] = useState<string>('');
    const [modality, setModality] = useState<Modality>({ ...customModality })

    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<string>();
    const [showModalEdit, setShowModalEdit] = useState(false)
    const [serviceEdit, setServiceEdit] = useState<IHS>()

    const getFiltered = (listName: any[]) => {
        return listName.filter((item: any) => {
            if (!filteredModality) return true;

            if (item.name && (
                item.name.toUpperCase().includes(filteredModality) ||
                item.name.toLowerCase().includes(filteredModality) ||
                item.name.includes(filteredModality)
            )) {
                return true;
            }

            return false;
        })
    }

    const handleModality = (open: boolean, mod?: Modality) => {
        if (mod) setModality(mod);
        else setModality({ ...customModality })
        setShowModal(open);
    }

    const getDeliveryOneName = (delivery: Delivery[]): string => {
        const inPerson = delivery.some(delivery => delivery.inPerson)
        const online = delivery.some(delivery => delivery.online)
        const mixed = inPerson && online
        if (mixed) return 'Online and In Person';
        if (inPerson) return 'In Person';
        if (online) return 'Online';
        return 'Not specified';
    }

    const handleModalityEdit = (open: boolean, mod?: Modality, service?: IHS) => {
        setShowModalEdit(open);
        setServiceEdit(service);
    }

    useEffect(() => {
        if ((services || []).length > 0) processData.setTabsProcessed([...processData.tabsProcessed, 4]);
    }, [services])

    const onHideDelete = (type: onHideType, id: string) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);
                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        return dataSDK.sdk.Modalities.deleteHS(username, id, dataSDK.token);
                    })
                    .then((results) => {
                        if (results.statusCode === 201) {
                            dispatch(getHealerServices(username));
                            currentSuccess?.setLoadingModal(false);
                            currentSuccess?.setModalsOff();
                        }
                        else {
                            console.log((results.body as IResponseError).error || (results.body as IResponseError).message)
                        }
                    })
                    .finally(() => {
                        setDeleteModal(false);
                        setDeleteLoading(false);
                        setDeleteId(undefined);
                    })
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                break;

            default:
                break;
        }
    }

    const handleDelete = (data: string) => {
        currentSuccess?.setModalWarning(
            'DELETE',
            (type) => onHideDelete(type, data),
            'Are you sure you want to delete this service?'
        );
    }

    const handleUpdate = (service_is: string) => {
        const s = services.find( s => s.id === service_is);
        handleModalityEdit(true, undefined, s);
    }

    return (
        <Scroll_Content_Signup>
            <TitleContent theme={{ align: 'center' }}>
                Select the Modalities and Services Offered by {organization.name || 'your organization'}
            </TitleContent>
            <Content_Signup_Columns>
                <LeftColumn>
                    <InputIcon>
                        <SearchIcon
                            icon={faSearch}
                            size='lg'
                        />
                        <InputPlaceholder
                            placeholder="Search"
                            value={filteredModality}
                            onChange={(e) => setFilteredModality(e.currentTarget.value)}
                        />
                    </InputIcon>
                    <BaseAreas>
                        <TitleAreas>Modalities & Services</TitleAreas>
                        <ListAreas>
                            {sortAlphabeticallyOnProperty(getFiltered(modalitiesPages[0]), 'name').map((item: Modality) => (
                                <Area onClick={() => handleModality(true, item)}>
                                    <TextServices>{item.name}</TextServices>
                                    <IconPlus
                                        icon={faPlus}
                                        size='lg'
                                        color={"black"}
                                    />
                                </Area>
                            ))}
                        </ListAreas>
                    </BaseAreas>
                    <Button onClick={() => handleModality(true)}>
                        Add custom service
                        <IconPlus
                            icon={faPlus}
                            size='lg'
                            color={"var(--white)"}
                        />
                    </Button>
                </LeftColumn>
                {(services || []).length === 0 ?
                    <ListServicesEmp>
                        <Header>
                            <Title>No services yet.</Title>
                            <SubTitle>Here you will see the modalities/services you upload</SubTitle>
                        </Header>
                    </ListServicesEmp>
                    :
                    <ListServices>
                        <BlankTable
                            headers={['Name', 'Delivery']}
                            items={sortAlphabeticallyOnProperty(getFiltered(services || []), 'name').map((service: IHS) => ({
                                id: service.id,
                                labels: [
                                    service.name,
                                    getDeliveryOneName(service.delivery || [])
                                ],
                            }))}
                            actions={['UPDATE', 'DELETE']}
                            actionsHandlers={{
                                handleUpdate,
                                handleDelete,
                            }}
                            maxWidth={'200px'}
                        />
                    </ListServices>
                }
            </Content_Signup_Columns>
            <ModalServices
                showModal={showModal}
                onHide={handleModality}
                data={modality}
            />
            <ModalServices
                edit
                showModal={showModalEdit}
                onHide={handleModalityEdit}
                data={modality}
                serviceInfo={serviceEdit}
            />
        </Scroll_Content_Signup>
    )
}

const TextServices = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Area = styled.div`
    border-bottom: 1px solid darkgrey;
    background-color: white;
    font-family: 'Inter';
    font-size: 14px;
    cursor: pointer;
    width: 100%;
    padding: 13px 9px;
    margin: 0;
    display: flex;
    justify-content: space-between;
`;

const ListAreas = styled.div`
    width: 100%;
    padding: 7px 9px;
    background-color: white;
    overflow-y: scroll;
    height: calc(100vh - 385px);

    &::-webkit-scrollbar {
        width: 5px;
        display: block !important;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--t-blue);
    }
`

const BaseAreas = styled.div`
    width: 100%;
    border-radius: 9px;
    background-color: white;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;
`

const TitleAreas = styled.span`
    font-family: 'Inter-Bold';
    font-size: 16px;
    color: black;
    margin: 15px 0px 0px 10px;
`;


const WrapperEmpty = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const TableIcon = styled(FontAwesomeIcon)`
    font-size: 18px;
    cursor: pointer;
`;

const TableList = styled.table`
    width: 100%;
    background-color: white;
    border-radius: 8px;
`

const Tr = styled.tr`
    & td:last-child,
    & th:last-child {
        width: 85px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

const Th = styled.th`
    vertical-align: middle;
    height: 40px;
    border-bottom: 1px solid var(--simple-gray-2);
    font-family: 'Inter-Bold';
    font-size: 14px;
    padding: 0 15px;
    text-align: left;
`

const Td = styled.td`
    vertical-align: middle;
    height: 40px;
    border-bottom: 1px solid var(--simple-gray-2);
    font-family: 'Inter';
    font-size: 14px; 
    padding: 0 15px; 
    text-align: left;
`

const SearchIcon = styled(FontAwesomeIcon)`
    font-size: 12px;
    margin-right: 20px;
    color: var(--black);
    margin-left: 20px;
`;

const InputIcon = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-radius: 8px;
    align-items: center;
    border: 1px solid var(--simple-gray);
    height: 40px;
    width: 100%;
    
    & ${Input}{
        border: 0;
        height: 38px;
    } 
`

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background: var(--electric-yellow-2);
    border-radius: 8px;
    color: var(--white);
    font-family: 'Inter-Bold';
    font-size: 16px;

    cursor: pointer;
`

export const Title = styled.p`
    font-family: 'Inter-Bold';
    font-size: 16px;
    padding: 13px 0;
`
export const SubTitle = styled.span`
    font-family: 'Inter';
    font-size: 14px;
`

export const ListServicesEmp = styled.div`
    width: 100%;
    background-color: white;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-image: url(./NoServices.png);
    border-radius: 9px;
    padding: 20px 0;
`

const ListServices = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    overflow: scroll;
`

export const Content_Signup_Columns = styled.div`
    display: grid;
    grid-template-columns: 30% calc(70% - 20px);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    height: 100%;
`

export const IconPlus = styled(FontAwesomeIcon)`
    font-size: 14px;
    margin-left: 8px
`;

const InputPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0;
    font-size: 15px;
    font-family: 'Inter';
    background-color: transparent;
`;

export default MyServices