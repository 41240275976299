import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconClicker } from "../../Home/HeaderHome/styles"
import { AvatarCreatorHome, BolderTextCardHome, CardContainerHome, ContentCardHome, CreatorNoAvatar, GuideCardHome, GuideCardHomeNoAvatar, GuideCardHomePlus, GuidesContainerHome, IconUser, RowGap5CardHome, RowIconCardHome, RowIconCardHomeDivider, RowPostCardHome, TitleRowCardEllipsisWrap, TitleRowCardHome } from "./styles"
import { formatCompactNumber } from "../../../helpers/numberHelper"
import { GuideInfoExtended } from "../Communities/CommunityCard"
import { getColorsInitials, getInitials } from "../../../helpers/letterHelper"
import { useState } from "react"
import SummaryGuide, { IGuideSummary, MockGuideSummary } from "../Healers/SummaryGuide"

export interface ICommunityHomeCard {
    id: string
    name: string
    guides: GuideInfoExtended[]
    creator: GuideInfoExtended
    followers: number
    members: number
    aliases: string[]
    description: string
    org_id?: string
    modalitiesIDs?: string[]
    posts: number
    lastPostDate?: Date
}

const CommunityHomeCard = ({
    data,
    onClick
}: {
    data: ICommunityHomeCard
    onClick: (data: ICommunityHomeCard) => void,
}) => {

    const [showGuideSummary, setShowGuideSummary] = useState<boolean>(false);
    const [summaryGuideInfo, setSummaryGuideInfo] = useState<IGuideSummary>({ ...MockGuideSummary });

    const handleShowGuideSummary = (guide: GuideInfoExtended) => {
        setSummaryGuideInfo({
            username: guide.username || '',
            name: guide.name || '',
            email: guide.email || '',
            avatar: guide.avatar || '',
        })
        setShowGuideSummary(true);
    }

    const getSliceGuides = () => {
        const guides = data.guides;
        const result = []
        for (let i = 0; i < guides.length; i++) {
            if (i === 3) {
                result.push(<GuideCardHomePlus>
                    <FontAwesomeIcon
                        icon={"plus"}
                        size="xs"
                    />
                    {guides.length - i}
                </GuideCardHomePlus>)
                break
            }
            else if (guides[i].avatar) {
                result.push(<GuideCardHome src={guides[i].avatar} onClick={() => handleShowGuideSummary(guides[i])} />);
            }
            else {
                const num = [...guides[i].name].reduce((acc, curr) => acc + curr.charCodeAt(0), 0);
                const colors = getColorsInitials(num);
                result.push(<GuideCardHomeNoAvatar
                    background_color={colors.background}
                    text_color={colors.color}
                    onClick={() => handleShowGuideSummary(guides[i])}
                >
                    {getInitials(guides[i].name)}
                </GuideCardHomeNoAvatar>);
            }
        }
        return result;
    }

    const getCreatorNoAvatar = () => {
        const num = [...data.creator.name].reduce((acc, curr) => acc + curr.charCodeAt(0), 0);
        const colors = getColorsInitials(num);
        return <CreatorNoAvatar
            background_color={colors.background}
            text_color={colors.color}
        >
            {getInitials(data.creator.name)}
        </CreatorNoAvatar>;
    }

    return (
        <CardContainerHome height={'182px'}>
            <TitleRowCardHome onClick={() => onClick(data)}>
                <TitleRowCardEllipsisWrap>{data.name}</TitleRowCardEllipsisWrap>
                <IconClicker
                    icon="chevron-right"
                    size="sm"
                    color="var(--dark-blue)"
                />
            </TitleRowCardHome>
            <ContentCardHome gap={"12px"}>

                <RowIconCardHome>
                    <BolderTextCardHome>Guides:</BolderTextCardHome>
                    <GuidesContainerHome>
                        {getSliceGuides()}
                    </GuidesContainerHome>
                </RowIconCardHome>

                <RowGap5CardHome>
                    <RowIconCardHome>
                        {/* <RowIconCardHome>
                        {formatCompactNumber(data.followers)} Followers
                    </RowIconCardHome>
                    <p>|</p> */}
                        <RowIconCardHome>
                            {formatCompactNumber(data.members)} Members
                        </RowIconCardHome>
                    </RowIconCardHome>
                </RowGap5CardHome>

                <RowIconCardHome>
                    {data.creator.avatar ? (
                        <AvatarCreatorHome src={data.creator.avatar} />
                    ) : (
                        getCreatorNoAvatar()
                    )}
                    {data.creator.name}
                </RowIconCardHome>
            </ContentCardHome>

            {showGuideSummary && <SummaryGuide
                isOpen={showGuideSummary}
                close={() => setShowGuideSummary(false)}
                username={summaryGuideInfo.username}
                name={summaryGuideInfo.name}
                avatar={summaryGuideInfo.avatar}
                email={summaryGuideInfo.email}
            />}
        </CardContainerHome>
    )
}

export default CommunityHomeCard;