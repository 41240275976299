import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ChatServiceInterface } from "../../../../interfaces/chat/ChatServiceInterface";
import { ClubChatInfo } from "../../../../interfaces/chat/ClubChatInfo";
import { WHITE } from "../../../ClubIconsNavBar";
import { ClickableIcon } from "../../../SuperCenter/SuperAdminComponents/basicForSACenter";

const GroupChatListOption = ({ chat}: { chat: ClubChatInfo}) => {
    const {id} = useParams();
    const navigate = useNavigate();

    return (
        <ChatListWrapper 
            key={chat.conversationId} 
            active={id === chat.conversationId} 
            onClick={() => navigate(`/chat/conversation/${chat.conversationId}`)}>
            <ChatList>
                <ColumnFlex>
                    <RowFlex>
                        <ClubName>{chat.name}</ClubName>
                        {(chat.muted) &&
                            <ClickableIcon
                                icon="volume-slash"
                                size='sm'
                                color={WHITE}
                            />}
                        {(chat.unread) && <PointUnread />}
                    </RowFlex>
                </ColumnFlex>
            </ChatList>
        </ChatListWrapper>
    );
}

export default GroupChatListOption;

export const ClubName = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const ChatList = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap:20px;
    margin-right: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const ColumnFlex = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center !important;
    align-items: flex-start;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const RowFlex = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: center;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const PointUnread = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50px;
    background-color: var(--lavender);
`;

interface ChatListWrapperProps {
    active: boolean;
}

const ChatListWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    background: ${(props: ChatListWrapperProps) => props.active ? 'var(--t-blue)' : 'transparent'};
    border-radius: 60px 0px 0px 60px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    cursor: pointer;
`;