import styled from "styled-components";
import { ProcessInterface } from "../../containers/SetupHealer/SetupHealer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Interest } from "@vibe/sdk/dist/interfaces/Connections/newInterests";
import { useContext, useEffect, useState } from "react";
import { ContextSuccess } from "../../Context";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import { ISaveInterestsBody } from "@vibe/sdk/dist/interfaces/Connections/users";
import { getUserInterests } from "../../store/actions/connectionActions";
import { Scroll_Content_Signup } from "../signupsetuphealer/Introduction";
import { TitleContent } from "../signupsetuphealerind";

const MyLife = ({
    processData,
}: {
    processData: ProcessInterface
}) => {

    const dispatch = useDispatch();

    const currentSuccess = useContext(ContextSuccess);

    const suggestedInterests: Interest[] = useSelector((state: RootState) => state.getConnection.GetISug);
    const userInterests: string[] = useSelector((state: RootState) => state.getConnection.getInterestsUser);
    const organization = useSelector((state: RootState) => state.getOrgs.organizationInfo);
    const username = useSelector((state: RootState) => (state.getUser.userInfo || {}).username);

    const [interests, setInterests] = useState<string[]>([]);

    const handleInterest = (int: string) => {
        processData.setNewData(true);
        if (interests.includes(int)) {
            setInterests(interests.filter(interest => interest !== int))
        }
        else {
            setInterests([...interests, int])
        }
    }

    const CheckDataStep = (): boolean => {
        let hasErrors = false;
        if (interests.length < 3) {
            hasErrors = true;
            currentSuccess?.setSuccessData(false, true, "Select at least 3 areas", `Just ${interests.length} areas selected.`);
        }
        return hasErrors;
    }

    const handleClick = () => {

        if (CheckDataStep()) {
            processData.setProcess("off");
            return;
        }

        const updateData: ISaveInterestsBody = {
            username: username,
            interests: interests,
            organizationId: organization.id,
        }

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Connections.saveInterests(updateData, dataSDK.token);
            })
            .finally(() => {
                dispatch(getUserInterests(username || ''));
                processData.setNewData(false);
                processData.setProcess("continue");
                processData.setTabsProcessed([...processData.tabsProcessed, 2]);
            })
    }

    useEffect(() => {
        if (processData.process === 'loading' && processData.step === 2) handleClick();
    }, [processData.process])

    useEffect(() => {
        if ((userInterests || []).length > 0) {
            setInterests(userInterests);
            processData.setTabsProcessed([...processData.tabsProcessed, 2]);
        }
    }, [userInterests])

    return (
        <Scroll_Content_Signup>
            <TitleContent theme={{ align: 'center' }}>Select 3 – 7 areas to transform and manifest the life of your dreams.</TitleContent>
            <ListAreas>
                {suggestedInterests.map((area) => (
                    <Area
                        active={interests.includes(area.name)}
                        onClick={() => handleInterest(area.name)}
                    >
                        {area.name}
                    </Area>
                ))}
            </ListAreas>
        </Scroll_Content_Signup>
    )
}

interface AreaProps {
    active: boolean;
}

const Area = styled.div`
    border: 1px solid darkgrey;
    width: fit-content;
    background-color: ${(props: AreaProps) => props.active ? '#FAF0E6' : 'white'};
    padding: 7px 25px;
    border-radius: 17px;
    margin-right: 6px;
    margin-bottom: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;

    &:hover {
        background-color: #FAF0E6
    }
`
const ListAreas = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`

export default MyLife