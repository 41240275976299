import { ColumnResultsContainer, RowFiltersContainer, ButtonSeeAll } from "../styles";
import CarrouselLeft from "../../../../BaseComponents/Carrousels/CarrouselLeft";
import ItemFilterHome, { ItemFilterHook, SelectedItemHome } from "../../../../../interfaces/HomeSearch/ItemFilterHome";
import FilterTips from "../../../../BaseComponents/FilterTips";

interface RowResultsProps<T> {
    name: string;
    cards: (t: T) => React.ReactNode;
    elements: T[];
    filters: ItemFilterHook[];
    filtersActive: number[];
    setFiltersActive: (data: number[]) => void;
    setSelectedOption: (data: SelectedItemHome) => void
    selectedOption: SelectedItemHome
    setTabActive: () => void
}

const RowResults = <T,>(props: RowResultsProps<T>) => {
    return (
        <ColumnResultsContainer>
            <RowFiltersContainer>
                {props.name}
                <ButtonSeeAll
                    onClick={() => props.setTabActive()}
                >
                    See all
                </ButtonSeeAll>
                <FilterTips
                    filters={props.filters}
                    filtersActive={props.filtersActive}
                    setFiltersActive={props.setFiltersActive}
                    setSelectedOption={props.setSelectedOption}
                    selectedOption={props.selectedOption}
                />
            </RowFiltersContainer>
            <CarrouselLeft
                defaultCard={0}
                elements={props.elements}
                renderCard={props.cards}
                widthCarrousel="calc(100vw - 572px)"
                idCarrousel={props.name}
            />
        </ColumnResultsContainer>
    )
}

export default RowResults;