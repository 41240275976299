import { useEffect, useState } from "react";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import SelectItem from "../../interfaces/SelectItem";

export interface GenderOption extends SelectItem { };

const useGenders = () => {
  const [Genders, setGenders] = useState<string[]>([]);
  const [GendersReactSelect, setGendersReactSelect] = useState<GenderOption[]>([])

  useEffect(() => {
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Roster.genders('USA', dataSDK.token);
      })
      .then((results) => {
        if (!Array.isArray(results.body)) {
          return;
        }
        setGenders(results.body.map((gender) => gender.label));
        setGendersReactSelect(
          results.body.map((gender) => ({ value: gender.id , label: gender.label }))
        )
      })
  }, []);

  return { 
    Genders, 
    GendersReactSelect 
  };
}

export default useGenders;