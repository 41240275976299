import { chatStatus } from "../../interfaces/chat/UserChat"
import { capitalize } from "../letterHelper"

//@todo status from database
export const getStatusColor = (status: string) => {
    if(status === 'available'){
        return 'var(--absolute-zero)'
    }

    if(status === 'disconnected'){
        return 'var(--onyx)'
    }

    if(status === 'invisible'){
        return 'var(--white)'
    }

    if(status === 'busy'){
        return 'var(--maroon-flush)'
    }

    if(status === 'be_right_back'){
        return 'var(--banana)'
    }
}

export const getStatusName = (status: string) => {
    if(status === 'be_right_back'){
        return 'Be right back'
    }

    return capitalize(status);
}

// Back and front now have the same statuses!!!
// export const castFrontStatusToBackStatus = (status:string):string => {
//     switch(status){
//         case 'available':
//             return 'Available'
//         case 'be_right_back':
//             return 'Be right back'
//         case 'busy':
//             return 'Busy'
//         case 'disconnected':
//             return 'Disconnected'
//         case 'invisible':
//             return 'Invisible'
//         default:
//             return 'Status'
//     }
// }

// export const castBackStatusToFrontStatus = (status:string): chatStatus => {
//     switch(status){
//         case 'Available':
//             return 'available'
//         case 'Be right back':
//             return 'be_right_back'
//         case 'Busy':
//             return 'busy'
//         case 'Disconnected':
//             return 'disconnected'
//         case 'Invisible':
//             return 'invisible'
//         default:
//             return 'disconnected'
//     }
// }