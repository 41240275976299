import React, { useEffect, useState, useRef, useContext } from 'react'
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { IPost, IPostExtended } from "@vibe/sdk/interfaces/Posts/IPost";
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useParams } from 'react-router-dom';
import { getRelativeTime } from "../../helpers/chat/timeChatHelper"
import { cleanHTML } from "../../helpers/letterHelper";
import GridImages from "../../components/BaseComponents/Posts/GridImages";
import PostPreview from "../../components/BaseComponents/Posts/PostPreview";
import { faMessageDots, faShareNodes, faBookmark } from "@fortawesome/pro-regular-svg-icons"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { faBookmark as faBookmarkSolid } from "@fortawesome/pro-solid-svg-icons"
import { ContextSuccess } from '../../Context';
import { timeDelay } from "../../helpers/dateHelper";
import TextEditor from "../../components/BaseComponents/TextEditor";
import { ICommentPostBody } from "@vibe/sdk/dist/interfaces/Posts/commentPost";
import { getPostsHealer, getPostsHealerFeatured, getPostsOrg } from "../../store/actions/postActions"
import { IReactionPostBody } from "@vibe/sdk/dist/interfaces/Posts/reactionPost"
import { IDeleteReactionBody } from "@vibe/sdk/dist/interfaces/Posts/deleteReaction"
import { mapPostCardIndividual } from '../../helpers/postHelper';
import { PostCardInterface } from '../../components/BaseComponents/Posts/PostCard';

const index = () => {

    const currentSuccess = useContext(ContextSuccess);
    const dispatch = useDispatch();

    const { id } = useParams();


    const [data, setData] = useState<PostCardInterface>()
    const ref = useRef(null);
    const [showLink, setShowLink] = useState<boolean>(false);
    const [showText, setShowText] = useState<boolean>(false);
    const [showEvent, setShowEvent] = useState<boolean>(false);
    const [liked, setLiked] = useState<boolean>(false);
    const [savePost, setSavePost] = useState<boolean>(false);
    const [showComments, setShowComments] = useState<boolean>(false);
    const [comment, setComment] = useState<string>('');
    const [sendingComment, setSendingComment] = useState<boolean>(false);
    const [sendingLike, setSendingLike] = useState<boolean>(false);

    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
    const isLoggedIn = useSelector((state: RootState) => state.getUser.isLoggedIn);
    const userProfile = useSelector((state: RootState) => state.getUser.userProfile);

    const reloadPost = (id: string) => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Posts.getPost(id);
            })
            .then((results) => {
                if (results.statusCode === 200 && results.body && 'type' in results.body) {
                    console.log(results.body)
                    setData(mapPostCardIndividual(results.body))
                }
            })
            .catch(console.error)
    }

    const handleSavePost = () => {
        setSavePost(true);
    }

    const commentPost = () => {
        setShowComments(!showComments);
    }

    const getCommAndOrg = () => {
        if (userProfile.roles.includes('INDIVIDUAL')) {
            dispatch(getPostsHealer(userProfile.username));
        }
        else {
            dispatch(getPostsOrg(userProfile.username));
        }
        dispatch(getPostsHealerFeatured(userProfile.username));
    }

    const handleComment = (id: string) => {
        if (isLoggedIn && comment !== '' && data) {
            setSendingComment(true);

            const toSend: ICommentPostBody = {
                author: data.author_username,
                username: userInfo?.username || '',
                post_id: id,
                content: comment,
            }

            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Posts.commentPost(toSend, dataSDK.token);
                })
                .then((results) => {
                    if (results.statusCode === 201) {
                        getCommAndOrg();
                        setComment('')
                        reloadPost(id);
                    } else {
                        currentSuccess?.setSuccessData(false, true, 'Error commenting post.',)
                    }
                })
                .catch((error) => {
                    console.log('Error commenting post', error)
                    currentSuccess?.setSuccessData(false, true, 'Error commenting post.', error)
                })
                .finally(() => setSendingComment(false))
        }
        else {
            currentSuccess?.setLoginModal(true, false);
        }
    }

    const likePost = (id: string) => {
        if (isLoggedIn) {
            setSendingLike(true);

            if (!liked) {
                setLiked(true);
                if (data) {
                    data.likes += 1;
                }

                const toSend: IReactionPostBody = {
                    username: userInfo?.username || '',
                    post_id: id,
                    reaction: 'like',
                }

                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        return dataSDK.sdk.Posts.reactionPost(toSend, dataSDK.token);
                    })
                    .then(() => { /* Nothing */ })
                    .catch(console.log)
                    .finally(() => setSendingLike(false))
            }
            else {
                setLiked(false);
                if (data) {
                    data.likes -= 1;
                }

                const toSend: IDeleteReactionBody = {
                    username: userInfo?.username || '',
                    post_id: id,
                }

                getDataSDK()
                    .then((dataSDK: getDataSDKResponse) => {
                        return dataSDK.sdk.Posts.deleteReaction(toSend, dataSDK.token);
                    })
                    .then(() => { /* Nothing */ })
                    .catch(console.log)
                    .finally(() => setSendingLike(false))
            }
        }
        else {
            currentSuccess?.setLoginModal(true, false);
        }
    }


    useEffect(() => {
        if (id) {
            reloadPost(id)
        }
    }, [])

    return (

        <Post id={id}>
            <InfoUser>
                <Avatar src={data?.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'} />
                <AuthorInfo>
                    <Name>{data?.author_name}</Name>
                    {data &&
                        <Reach>{data?.reachName} - {!data?.timestamp.includes('-') ? getRelativeTime(parseInt(data.timestamp)) : ''}</Reach>
                    }
                </AuthorInfo>
            </InfoUser>
            {(data?.content) && (
                <>
                    <TitlePost
                        theme={{ main: !showText ? 'noShowAll' : '' }}
                        isEvent={data?.type === 'NEWEVENT'}
                        ref={ref}
                        dangerouslySetInnerHTML={{
                            __html: cleanHTML(data?.is_tip ? `"${data?.content}"` : data?.content)
                        }} />
                </>
            )}
            {data &&
                data.is_tip && <Author>- {data.tip_author || ''}</Author>
            }

            {(data && data.files && data.files.length > 0) && <GridImages images={data.files || []} type={'detail'} />}

            {data &&
                (data.type.toLowerCase() === 'link' || (data.type.toLowerCase()) === 'embed') &&
                <PostPreview type={data.type} link={data?.link || ''} />
            }

            {data &&
                <InfoCount>
                    <Interested>{`${data?.comments?.length}`} comments</Interested>
                    {/* {process.env.REACT_APP_SHOW_SHARE === 'true' && (
                        <Attend>{`${data?.shared}`} shared</Attend>
                    )}
                    <Attend>{`${data?.likes}`} Good Vibes</Attend> */}
                </InfoCount>
            }


            <Actions>
                <ActionsWrap>
                    <Share
                        onClick={() => {
                            isLoggedIn ?
                                commentPost() :
                                currentSuccess?.setLoginModal(true, false)
                        }}>
                        Comment
                        <StyledFontAwesomeIcon
                            icon={faMessageDots}
                            size='lg'
                            color={"black"}
                            style={{
                                fontSize: '16px'
                            }}
                        />
                    </Share>
                    {process.env.REACT_APP_SHOW_SHARE === 'true' && (
                        <Share>
                            Share
                            <StyledFontAwesomeIcon
                                icon={faShareNodes}
                                size='lg'
                                color={"black"}
                                style={{
                                    fontSize: '16px'
                                }}
                            />
                        </Share>
                    )}
                    <Share
                        onClick={() => {
                            isLoggedIn ?
                                likePost(id ?? "") :
                                currentSuccess?.setLoginModal(true, false)
                        }}>
                        {!liked ? 'Good Vibes' : 'Vibing!'}
                        {!liked ? <VibeLogo_Light /> : <VibeLogo />}
                    </Share>
                </ActionsWrap>

                <ActionsWrap>
                    {(isLoggedIn && userProfile.username === userInfo?.username) && (
                        <IconSave
                            icon={savePost ? faBookmarkSolid : faBookmark}
                            size='lg'
                            color={"black"}
                            style={{
                                fontSize: '16px'
                            }}
                            onClick={handleSavePost}
                        />
                    )}
                    {data &&
                        (data.author_username !== userInfo?.username) && (
                            <IconVibes />
                        )
                    }
                </ActionsWrap>
            </Actions>
            {showComments && (
                <CommentsContainer>
                    <CommentsScroll>
                        {data &&
                            data?.comments?.map(comm => (
                                <CommentContainer>
                                    <CommentInfoWrap>
                                        <CommentInfo>{comm.author}</CommentInfo>
                                        <Point />
                                        <CommentInfo>{timeDelay(comm.created_at)}</CommentInfo>
                                    </CommentInfoWrap>
                                    <CommentContent dangerouslySetInnerHTML={{ __html: cleanHTML(comm.content) }} />
                                </CommentContainer>
                            ))}
                    </CommentsScroll>
                    <InputCommentWrap>
                        <WrapperTextArea>
                            <TextEditor
                                value={comment}
                                setValue={setComment}
                                charLimit={1000}
                                minHeight={100}
                                placeholder='Write something to comment...'
                                border={'0px'}
                            />
                        </WrapperTextArea>
                        <StyledFontAwesomeIcon
                            icon={'paper-plane-top'}
                            size='xl'
                            color={"black"}
                            style={{
                                fontSize: '18px'
                            }}
                            onClick={() => handleComment(id ?? "")}
                        />
                    </InputCommentWrap>
                </CommentsContainer>
            )}
        </Post>
    )
}

const IconSave = styled(FontAwesomeIcon)`
    cursor: pointer;
`;

const ActionsWrap = styled.div`
    display: flex;
    font-family: 'Inter-Bold';
    gap: 10px;
    font-size: 14px;
    align-items: center;
`;

const IconVibes = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: relative;
  background-image: url(../goodVibes.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80% auto;
  cursor: pointer;
`

const RowFlex = styled.div`
  display: flex;
  height: fit-content;
  gap: 15px;
`;

const RowFlex2 = styled(RowFlex)`
    gap: 5px;
    width: fit-content;
`;

const ImageEvent = styled.img`
    width: 100%;
    height: 400px;
    overflow: hidden;
    object-fit: cover;
    border-radius: 9px;
`;

const InfoUser = styled.div`
  display: grid;
  grid-template-columns: 40px calc(100% - 50px);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  width: 100%;
  height: fit-content;
  text-align: left;
  align-items: center;
`;

const Avatar = styled.img`
    object-fit: cover;
    width: 40px;
    height: 40px;
    min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
    border-radius: 50%;
    border: 1px solid var(--simple-gray);
`;

const AuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  gap: 5px;
`;

const Name = styled.div`
    font-family: 'Inter-Bold';
    font-size: 14px;
    color: var(--t-blue);
`;

const Reach = styled(Name)`
    font-family: 'Inter';
`;

const VibeLogo = styled.img`
    content: url(../../../vibeLogo.svg);
    width: 20px;
    height: 20px;
`;

const VibeLogo_Light = styled(VibeLogo)`
    content: url(../../../vibeLogo_light.svg);
`;

const ReadMore = styled.div`
    color: #666;
    font-family: Inter-Bold;
    font-size: 14px;
    text-align: left;
    cursor:pointer;
`

const ViewDetails = styled(ReadMore)`
    color: var(--absolute-zero);
`

const TitlePost = styled.p.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    font-family: ${({ isEvent }: { isEvent: boolean }) => isEvent ? 'Inter' : 'Inter-bold'};
    font-style: normal;
    font-weight: ${({ isEvent }: { isEvent: boolean }) => isEvent ? '400' : '700'};
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    &.noShowAll{
        -webkit-line-clamp: 1;
    }

    & a{
        font-family: 'Inter';
        font-weight: 400;
        color: var(--absolute-zero);
        text-decoration: underline !important;
    }
`

const Author = styled.div`
    font-family: 'Inter';
    font-style: italic;
    font-size: 14px;
    text-align: left;
    overflow: hidden;
    color: var(--simple-blue-dark);
    width: 100%;
`;

const WrapperTextArea = styled.div`
    width: 95%;
`;

const InputComment = styled.input`
    font-family: 'Inter';
    font-size: 14px;
    color: var(--simple-blue-dark);
    outline: none;
    padding: 2px;
    border: none;
    width: 100%;

    ::placeholder{
        font-family: 'Inter';
        font-size: 14px;
        color: var(--simple-gray);
    }
`;

const InputCommentWrap = styled.div`
    border-top: 1px solid var(--simple-gray);
    display: flex;
    padding-right: 10px;
    width: 100%;
    align-items: center;
`;

const Point = styled.div`
    background-color: var(--simple-blue-dark);
    width: 3px;
    height: 3px;
    border-radius: 100%;
`;

const CommentContent = styled.div`
    font-family: 'Inter';
    font-size: 14px;
    color: var(--simple-blue-dark);
    color: var(--simple-blue-dark);
    width: 100%;
    word-break: break-word;
    padding-bottom: 10px;
`;

const CommentInfo = styled.span`
    font-family: 'Inter-bold';
    font-size: 12px;
    color: var(--simple-blue-dark);
`;

const CommentContainer = styled.div`
    width: 100%;
    border-bottom: 0.5px solid var(--simple-gray);
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const CommentsScroll = styled.div`
    width: 100%;
    display: flex;
    max-height: 150px;
    min-height: 50px;
    flex-direction: column;
    gap: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px;
    text-align: left;
`;

const CommentInfoWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
`;

const CommentsContainer = styled.div`
    width: 100%;
    height: 100%;
    border: 0.5px solid var(--simple-gray);
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 8px;
`;

const DivisionLine = styled.div`
    background-color: var(--simple-gray);
    height: 1px;
    width: 100%;
`;

const Share = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
`
const Actions = styled.div`
    display: flex;
    font-family: 'Inter-Bold';
    gap: 10px;
    font-size: 14px;
    justify-content: space-between;
    position: relative;
`
const Image = styled.img`
    width: 100%;
    height: 350px;
    border-radius: 8px;
`
const Post = styled.div`
    gap: 13px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    border: 1px solid var(--simple-gray);
    padding: 17px;
    width: 700px;
`

export const InfoCount = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #2E2623;
    gap: 10px;
`

export const Interested = styled.div`
    display: flex;
    align-items: center;
`

export const Attend = styled.div`
    padding-left: 9px;
    border-left: 1px solid black;
    
`

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    margin-right: 7px;
    cursor: pointer;
`;


export default index