import {AnyAction} from 'redux';
import { LOGOUT_PROFILE } from '../constants/profileConstants';

const initialState = {};

export const getProfileReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {

    case LOGOUT_PROFILE:
      return initialState;

    default:
      return state;
  }
};
