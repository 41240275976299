export const START_ACTIVE_CITIES = 'START_ACTIVE_CITIES';
export const ACTIVE_CITIES_SUCCESS = 'ACTIVE_CITIES_SUCCESS';
export const ACTIVE_CITIES_ERROR = 'ACTIVE_CITIES_ERROR';

export const START_ACTIVE_COUNTRIES = 'START_ACTIVE_COUNTRIES';
export const ACTIVE_COUNTRIES_SUCCESS = 'ACTIVE_COUNTRIES_SUCCESS';
export const ACTIVE_COUNTRIES_ERROR = 'ACTIVE_COUNTRIES_ERROR';

export const START_LOCATION = 'START_LOCATION';
export const LOCATION_SUCCESS = 'LOCATION_SUCCESS';
export const LOCATION_ERROR = 'LOCATION_ERROR';

export const START_TIMEZONE = 'START_TIMEZONE';
export const TIMEZONE_SUCCESS = 'TIMEZONE_SUCCESS';
export const TIMEZONE_ERROR = 'TIMEZONE_ERROR';

export const START_CITY = 'START_CITY';
export const CITY_SUCCESS = 'CITY_SUCCESS';
export const CITY_ERROR = 'CITY_ERROR';

export const START_COUNTRY = 'START_COUNTRY';
export const COUNTRY_SUCCESS = 'COUNTRY_SUCCESS';
export const COUNTRY_ERROR = 'COUNTRY_ERROR';

export const LOGOUT_LOCATION = 'LOGOUT_LOCATION';