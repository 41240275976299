import { faArrowsRotate, faCircleCheck, faLocationDot } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import styled from "styled-components";
import ButtonSpinner from "../ButtonSpinner";
import { GuideInfo } from "../Communities/CommunityCard";
import { rolesType } from "@vibe/sdk/dist/interfaces/AccessManager/signUpUser";
import { IconHealer, IconSeeker } from "../../signupsetuphealer";
import { capitalize } from "../../../helpers/letterHelper";
import { RelationType } from "@vibe/sdk/dist/interfaces/Roster/getUserConnections";

export type relationTypeCard = RelationType | 'ANY';

export interface CardConnectionsInterface {
    id: string;
    avatar: string;
    name: string;
    roles: rolesType[];
    zipcode: string;
    country: string;
    createdOn: string;
    interests: string[];
    connections: GuideInfo[];
    typeConnection: relationTypeCard;
    city: string;
}

const ConnectionCard = ({
    data,
    onClickButton,
    isSummary,
    isSeeker
}: {
    data: CardConnectionsInterface,
    onClickButton?: (data: CardConnectionsInterface) => void,
    isSummary?: boolean,
    isSeeker?: boolean
}) => {

    const [loadingJoin, setLoadingJoin] = useState<boolean>(false);

    const handleClick = () => {
        if (onClickButton) {
            setLoadingJoin(true);
            onClickButton(data);
        }
    }

    const [cardsActive, setCardsActive] = useState<string[]>([])

    const handleCardActived = (e: string) => {
        setCardsActive([...cardsActive, e])
    }

    const getSliceGuides = () => {
        const guides = data.connections;
        const result = []
        for (let i = 0; i < guides.length; i++) {
            if (i === 3) {
                result.push(
                    <FriendWhite>
                        <FontAwesomeIcon
                            icon={"plus"}
                            size="xs"
                        />
                        {guides.length - i}
                    </FriendWhite>);
                break
            }
            result.push(<Friend src={guides[i].avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} />)
        }
        return result;
    }

    const getDate = (): string => {
        const date = new Date(parseInt(data.createdOn))
        return date.toLocaleDateString();
    }

    return (
        <CardItem theme={{ width: '235px', margin: '0', main: cardsActive.includes(data.id) ? 'CardActive' : '' }} isSummary={isSummary || false}>
            <WrapperUp>
                <AvatarConn src={data.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} />
                <WrapperName>
                    <NameConnection>{data.name}</NameConnection>
                    <WrapperRoles>
                        {data.roles.map(role => {
                            return (role === 'SEEKER') ? <IconSeeker /> : (role === 'HEALER') ? <IconHealer /> : <></>
                        })}
                    </WrapperRoles>
                </WrapperName>
                <Location>
                    <div>
                        <FontAwesomeIcon
                            icon={faLocationDot}
                            size='sm'
                            color={"black"}
                            style={{
                                fontSize: '16px'
                            }}
                        />
                        {isSeeker ?
                            `${data.country || ''}`
                            :
                            `${data.city ? data.city : data.zipcode || ''},`
                        }
                    </div>
                    {!isSeeker &&
                        <div>
                            {data.country || ''}
                        </div>
                    }
                </Location>
            </WrapperUp>
            <WrapperInterests>
                <Interests
                    theme={{ main: cardsActive.includes(data.id) ? 'active' : '' }}
                    isSummary={isSummary || false}
                >
                    {data.interests.join(', ')}
                </Interests>
                {data.interests.length > 0 &&
                    (cardsActive.includes(data.id) ?
                        <ReadMore onClick={() => { setCardsActive(cardsActive.filter(card => card !== data.id)); }}>Read Less</ReadMore>
                        :
                        <ReadMore onClick={() => handleCardActived(data.id)}>Read More</ReadMore>
                    )
                }
            </WrapperInterests>

            <WrapperDown>
                {(!isSummary) && (
                    <>
                        <MemberTime>Member since: {getDate()}</MemberTime>
                        <TypeCard>
                            {getSliceGuides()}
                        </TypeCard>
                    </>
                )}
                {data.typeConnection !== 'ANY' ?
                    <ButtonWhite>
                        <FontAwesomeIcon
                            icon={faCircleCheck}
                            size='lg'
                            color={"black"}
                            style={{
                                fontSize: '25px'
                            }}
                        />
                        {capitalize(data.typeConnection || '')}
                    </ButtonWhite>
                    :
                    <ButtonSpinner
                        loading={loadingJoin}
                        handleClick={handleClick}
                        text={'Add friend'}
                        hasIcon={false}
                    />
                }
            </WrapperDown>
        </CardItem>
    )
}

const WrapperInterests = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 5px;
`;

const ReadMore = styled.div`
    color: var(--absolute-zero);
    text-align: left;
    width: 100%;
    font-size: 12px;
    cursor: pointer;
    font-family: 'Inter-Bold';
`

const WrapperUp = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;

const WrapperDown = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;

const WrapperRoles = styled.div`
    display: flex;
    gap: 2px;
    align-items: center;
`;

const WrapperName = styled.div`
    display: flex;
    gap: 7px;
    align-items: center;
`;

const FriendWhite = styled.div`
    border: 1px solid white;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: relative;
    margin-left: -8px;
    background-color: white;
    border: 1px solid black;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
`

const Friends = styled.div`
    display: flex;
    justify-content: center;
    padding: 12px 0;
    margin-left: 18px;
`

const Friend = styled.img`
    border: 1px solid white;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: relative;
    margin-left: -8px;
`

const TypeCard = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    text-align: left;
    display: flex;
    align-items: center;

    & span{
        color: black;
        font-weight: bold;
        font-family: 'Inter-Bold';
    }
`

const Location = styled.div`
    font-family: 'Inter';
    font-size: 12px;
    text-align: initial;
    color: black;
    gap: 5px;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-wrap: wrap;
    align-items: center;
    & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
    }
`

export const AvatarConn = styled.img`
    width: 56px;
    height: 56px;
    min-width: 56px;
    min-height: 56px;
    max-width: 56px;
    max-height: 56px;
    border-radius: 50%;
    object-fit: cover;
`
const NameConnection = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align:center;

    overflow: hidden;
    text-overflow: ellipsis;
    height: 15px;
    max-width: 128px;
    white-space: nowrap;
`
const Interests = styled.p.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 35px;
    text-align: left;
    width: 100%;

    margin: ${(props: ListProps) => props.isSummary ? '0px' : '25px 0 0 0'};

    &.active{
        max-height: fit-content;
    }
`

const MemberTime = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
`

const ButtonWhite = styled.div`
    background-color: #ffffff;
    border: 1px solid #29313b;
    padding: 6px 10px;
    border-radius: 8px;
    color: black;
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    gap: 10px;
`

interface ListProps {
    isSummary: boolean;
}

const CardItem = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`

    display: flex;
    flex-direction: column;
    gap: 10px;
    width: ${(props: ListProps) => props.isSummary ? '200px' : '100%'};
    min-width: ${(props: ListProps) => props.isSummary ? '200px' : '100%'};

    border: 1px solid var(--mountain-mist);
    background-color: white;
    border-radius: 10px;
    padding: 17px;
    color: black;
    text-align: center;
    position: relative;
    transform: perspective(500px) rotateY(0deg);

    align-items: center;
    justify-items: center;
    justify-content: space-between;

    min-height: ${(props: ListProps) => props.isSummary ? '265px' : '350px'};

    &.CardActive{
        height: max-content !important;
    }
`

export default ConnectionCard