import styled from "styled-components";
import { IconClicker } from "../../Home/HeaderHome/styles";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";

const OnSale = ({
    url
}: {
    url: string
}) => {
    return (
        <WrapperOnSale>
            <LogoWrapper>
                <LogoEventB />
                <TextLogoWrapper>
                    <OnSaleText>
                        On Sale
                    </OnSaleText>
                    Eventbrite
                </TextLogoWrapper>
            </LogoWrapper>

            <WrapperTickets href={url} target="_blank" rel="noreferrer">
                Find tickets
                < IconClicker
                    icon={faArrowUpRightFromSquare}
                    color='black'
                    size='sm'
                />
            </WrapperTickets >
        </WrapperOnSale >
    )
}

export default OnSale;

const logoEventBrite = require("../../../assets/img/eventbrite.png");
const LogoEventB = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    background-image: url("${logoEventBrite}");
    background-size: contain;
    background-repeat: no-repeat;
`;

const WrapperOnSale = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
`;

const LogoWrapper = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    height: fit-content;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: var(--onyx);
`;

const TextLogoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 5px;
    height: fit-content;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: var(--onyx);
`;

const OnSaleText = styled.p`
    font-weight: 600;
    font-size: 14px;
    color: black;
`;

const WrapperTickets = styled.a`
    display: flex !important;
    padding: 5px;
    cursor: pointer;
    font-size: 14px;
    color: var(--absolute-zero);
    align-items: center;
    justify-content: center;
    gap: 5px;
`;