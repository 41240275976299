import React, { useState, useEffect, useContext } from 'react'
import './styles.scoped.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../ClubIconsNavBar";
import moment, { Moment } from "moment";
import OutsideClickHandler from "react-outside-click-handler";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ContextSuccess } from "../../Context";

toast.configure();

const Index = (props: any) => {
    const { t, i18n } = useTranslation();
    const currentSuccess = useContext(ContextSuccess);
    const [value, setValue] = useState(null);
    const [valueHour, setValueHour] = useState<any>('');
    const [valueMin, setValueMin] = useState<any>('');
    const [valueAMP, setValueAMP] = useState<any>('');
    const [time, setTime] = useState<any>('');
    const [showTime, setShowTime] = useState<boolean>(false);

    function formatMinute(e: any) {
        if (parseInt(e) >= 0 && parseInt(e) <= 9) {
            return `0${parseInt(e)}`
        } else {
            return e
        }
    }

    function handleOk() {
        if (valueHour && valueMin && valueAMP) {
            if ((parseInt(valueHour) >= 0 && parseInt(valueHour) <= 12) && (parseInt(valueMin) >= 0 && parseInt(valueMin) <= 59)) {
                props.handleClick(moment(`${valueHour}:${valueMin} ${valueAMP}`, "h:mm a"))
                setTime(`${valueHour}:${formatMinute(valueMin)} ${valueAMP}`)
                setShowTime(false)
            } else {
                currentSuccess?.setSuccessData(false, true, t('toast_invalidTime'));
            }
        }
    }

    function handleHour(e: any) {
        if (e.target.value.length <= 2 && parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 12) {
            setValueHour(e.target.value)
        } else {
            if (parseInt(e.target.value.substr(0, 2)) >= 0 && parseInt(e.target.value.substr(0, 2)) <= 12) {
                setValueHour(e.target.value.substr(0, 2));
            } else {
                setValueHour(e.target.value.substr(0, 1));
            }
        }
    }

    function handleMin(e: any) {
        if (e.target.value.length <= 2 && parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 59) {
            setValueMin(e.target.value)
        } else {
            if (parseInt(e.target.value.substr(0, 2)) > 0 && parseInt(e.target.value.substr(0, 2)) <= 59) {
                setValueMin(e.target.value.substr(0, 2));
            } else {
                setValueMin(e.target.value.substr(0, 1));
            }
        }
    }

    useEffect(() => {
        if (props.showToday) {
            setValueHour(moment().toDate().getHours());
            if (moment().toDate().getHours() > 12) {
                setValueHour(moment().toDate().getHours() - 12);
            } else if (moment().toDate().getHours() === 12) {
                setValueHour(moment().toDate().getHours());
            } else {
                setValueHour(moment().toDate().getHours());
            }
            setValueMin(formatMinute(moment().toDate().getMinutes()));
        }
    }, [])

    useEffect(() => {
        if (props.value && !props.noForceAMP) {
            if (props.value.hours() > 12) {
                setValueHour(props.value.hours() - 12);
                setTime(`${props.value.hours() - 12}:${formatMinute(props.value.minutes())} PM`)
                setValueAMP('PM')
            } else if (props.value.hours() === 12) {
                setValueHour(props.value.hours());
                setTime(`${props.value.hours()}:${formatMinute(props.value.minutes())} PM`)
                setValueAMP('PM')
            } else {
                setValueHour(props.value.hours());
                setTime(`${props.value.hours()}:${formatMinute(props.value.minutes())} AM`)
                setValueAMP('AM')
            }
            setValueMin(formatMinute(props.value.minutes()));
        }
    }, [props.value])

    const onClickOutside = () => {
        handleOk();
        setShowTime(false);
    }

    return (
        <div className='containerTimePicker'>
            <div className={`contentTime ${props.className || ''} `} onClick={() => setShowTime(!showTime)}>
                {props.icon &&
                    <FontAwesomeIcon
                        icon={props.icon}
                        size="xl"
                        color={"var(--t-blue)"}
                        className="iconClock  "
                    />
                }
                <p>
                    {time}
                </p>
            </div>
            <OutsideClickHandler
                onOutsideClick={() => showTime && onClickOutside()}
            ><div className={`myTimePicker ${showTime && 'timePanelActive'} ${props.classNameTime || ''}`}>
                    <span className="titleTimePicker">ENTER TIME</span>
                    <div className="timePickerContainer">
                        <div className='timepickerInputs'>
                            <div className='timepickerInput'>
                                <input id={`HourInput${props.id}`} type="text" value={valueHour} defaultValue={valueHour} onChange={(e) => handleHour(e)} />
                                <label htmlFor="">Hour</label>
                            </div>
                            <p className='separatorTimePicker'>:</p>
                            <div className='timepickerInput'>
                                <input id={`MinInput${props.id}`} type="text" value={valueMin} defaultValue={formatMinute(valueMin)} onChange={(e) => handleMin(e)} />
                                <label htmlFor="">Minute</label>
                            </div>
                        </div>
                        <div className="buttonsAMP">
                            <button
                                id={`AM${props.id}`}
                                onClick={() => setValueAMP('AM')}
                                className={`timeAM ${(valueAMP === 'AM') && 'timeActive'}`}>
                                AM
                            </button>
                            <button
                                id={`PM${props.id}`}
                                onClick={() => setValueAMP('PM')}
                                className={`timePM ${(valueAMP === 'PM') && 'timeActive'}`}>
                                PM
                            </button>
                        </div>
                    </div>
                </div>
            </OutsideClickHandler>
        </div>
    )
}

export default Index