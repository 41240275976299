import { useState } from "react";
import "./styles.css";
import OptionsSort from "./optionsSort";
import OutsideClickHandler from 'react-outside-click-handler';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T_BLUE, WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SelectItem from "../../interfaces/SelectItem";
import { IconClicker } from "../Home/HeaderHome/styles";
import { faCircle } from "@fortawesome/pro-regular-svg-icons";

function Index({
  styles,
  inputSearch,
  orderField,
  selectOrder,
  setSelectOrder,
}: any) {
  const { t, i18n } = useTranslation();
  const [activeOptions, setActiveOptions] = useState(false);

  const handleChangeSearch = (event: any) => {
    inputSearch(event.target.value);
  };

const handleSort = (e: any, type: string) => {
    setSelectOrder(e)
    orderField(type)
  }

  const sort: SelectItem[] = [{
    value: 'ASC',
    label: t('options__az')
  },
  {
    value: 'DESC',
    label: t('options__za')
  }]

  return (
    <WrapperSearch>
      <div className={`inputSearch`} style={{ backgroundColor: T_BLUE }}>
        <IconSearch
          icon="search"
          size='sm'
          color={WHITE}
        />
        <input type="text" placeholder={t('comm__search')} onChange={handleChangeSearch} />
      </div>
      
      <Clicker>
        {styles === "Left" && <IconClicker
          size='lg'
          icon="sort-alpha-up"
          onClick={() => setActiveOptions(past => !past)}
        />}
        {activeOptions && <OutsideClickHandler
          onOutsideClick={() => {
            setActiveOptions(false);
          }}
        >
          <Container>
            {sort.map(d => (
              <ItemList onClick={() => handleSort(d.value as "DESC" | "ASC", 'name')}>
                <IconRadio theme={{ main: selectOrder === d.value ? 'iconCheck' : '' }}>
                  {selectOrder === d.value &&
                    <RadioIcon
                      icon={faCircle}
                      size='lg'
                      color={'var(--absolute-zero)'}
                    />
                  }
                </IconRadio>
                {d.label}
              </ItemList>
            ))}
          </Container>
        </OutsideClickHandler>}
      </Clicker>
    </WrapperSearch>
  );
}

export default Index;

const RadioIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
`;

const ItemList = styled.div`
  display: flex;
  justify-content: start;
  padding: 3px 0px 3px 0;
  align-items: center;
  font-family: 'Inter';
  font-size: 14px;
  gap: 8px;
  cursor: pointer;
`;

const Clicker = styled.div`
  position: relative;
  z-index: 1;
`;

const Container = styled.div`
  background-color: white;
  border-radius: 8px;
  position: absolute;
  width: 150px;
  max-height: 150px;
  padding: 12px;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 100%;
  margin-top: 10px;
  right: 0;
  z-index: 1000001;
`;

const WrapperSearch = styled.div`
  display: flex;
  width: 100%;
  height: 35px;
  gap: 10px;
  align-items: center;
`;

const IconSearch = styled(FontAwesomeIcon)`
  margin: 0px 10px;
`;

const IconRadio = styled.div.attrs(props => ({
  className: props.theme.main,
}))`
  border-radius: 12px;
  border: 2px solid #999796;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.iconCheck{
      background-color: white;
      border: 2px solid #2A4CD3;
  }
`;
