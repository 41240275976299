import Modal from "react-bootstrap/esm/Modal";
import { IconClicker } from "../../Home/HeaderHome/styles";
import { useContext, useEffect, useState } from "react";
import { ContextSuccess } from "../../../Context";
import { onHideType } from "../../BaseComponents/Modals/ModalUnsaved";
import ContributorCreate from "@vibe/sdk/dist/interfaces/Modalities/contributors/ContributorCreate";
import { mockContributor } from "./Contributors";
import Contributor from "@vibe/sdk/interfaces/Modalities/contributors/Contributor";
import IResponseError from "@vibe/sdk/dist/interfaces/IResponseError";
import { userInfo } from "os";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import styled from "styled-components";
import ButtonSpinner from "../../BaseComponents/ButtonSpinner";
import { Imp } from "../../signupsetuphealerind";
import Selector from "../../BaseComponents/Selector";
import ErrorField from "../../BaseComponents/ErrorField";
import { BaseFieldState, getFieldState } from "../../BaseComponents/utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { getRole } from "../../../hooks/Clubs/useClubsList";
import SelectItem from "../../../interfaces/SelectItem";
import useUsersSA from "../../../hooks/users/useUsersSA";
import { ClickableIcon } from "../SuperAdminComponents/basicForSACenter";
import useAllModalities from "../../../hooks/Superadmin/Modalities/useAllModalities";
import ContributorUpdate from "@vibe/sdk/dist/interfaces/Modalities/contributors/ContributorUpdate";
import { SelectMultiWrapper } from "../../BaseComponents/SelectMultiWrapper";
import { DropdownIndicator, customStylesRSMultiWhite } from "../../../helpers/selectHelper";
import { ModalityOptions } from "../Modalities/Create";

const ModalContributorSA = ({
    showModal,
    onHide,
    modalityID,
    reload,
}: {
    showModal: boolean
    modalityID: string
    onHide: () => void
    reload: () => void
}) => {
    const currentSuccess = useContext(ContextSuccess);

    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);

    const [loading, setLoading] = useState<boolean>(false);

    const [searchUser, setSearchUser] = useState<string>('');
    const [healerOptions, setHealerOptions] = useState<ModalityOptions<string>[]>([]);
    const [healers, setHealers] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [healersSelected, setHealersSelected] = useState<ModalityOptions<string>[]>([]);

    useEffect(() => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.getHealersByMod(modalityID, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) {
                    const result = results.body as any;
                    setHealerOptions(result.map((us: any) => ({
                        value: us.username,
                        label: us.name,
                        __isNew__: true,
                    })))
                }
                else setHealerOptions([]);
            })
            .catch((error) => {
                console.error(error)
            })
    }, [modalityID])

    const cleanFields = () => {
        setHealers(getFieldState([]));
        setHealersSelected([]);
        setLoading(false);
        setSearchUser('');
    }

    const checkData = (): boolean => {
        let hasErrors = false;

        if (!healers.value || healers.value?.length === 0) {
            hasErrors = true;
            setHealers({
                ...healers,
                showError: true,
                error: 'Select healers is required'
            })
        }
        else {
            setHealers({
                ...healers,
                showError: false,
                error: ''
            })
        }

        return hasErrors;
    }

    const handleCreate = () => {
        const contributors: ContributorCreate[] = (healers.value || []).map(u => ({
            username: u || '',
            modalitiesIds: [],
            createdBy: userInfo?.username || '',
        }))

        let dataS: getDataSDKResponse;

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                dataS = dataSDK;
                return Promise.all(contributors.map(c => dataSDK.sdk.Modalities.getContributor(c.username || '', dataSDK.token)));
            })
            .then((results) => {
                results.map((r, idx) => {
                    if (r.statusCode === 200) {
                        const response = r.body as Contributor;
                        contributors[idx].modalitiesIds = [modalityID, ...response.modalities.map(m => m.id)]
                    }
                    else contributors[idx].modalitiesIds = [modalityID]
                })

                return Promise.all(contributors.map(c => dataS.sdk.Modalities.createContributor(c, dataS.token)));
            })
            .then((results) => {
                let success = true;
                let body = '';

                for (let i = 0; i < results.length; i++) {
                    if (results[i].statusCode !== 201) {
                        success = false;
                        body = results[i].body;
                        break;
                    }
                }
                if (success) {
                    reload();
                    cleanFields();
                    onHide();
                    currentSuccess?.setModalsOff();
                    currentSuccess?.setSuccessData(true, true, 'Contributors assigned!');
                }
                else {
                    currentSuccess?.setSuccessData(false, true, (body as IResponseError).error || (body as IResponseError).message);
                }
            })
            .catch((error) => {
                console.log(error);
                currentSuccess?.setSuccessData(false, true, 'Error creating contributor', error);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleOnSave = () => {
        if (checkData()) return;
        setLoading(true);
        handleCreate();
    }

    const onHideUnsaved = (type: onHideType) => {
        switch (type) {
            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                onHide();
                cleanFields();
                break;

            case 'save':
                handleOnSave();
                break;

            default:
                break;
        }
    }

    const handleUnsaved = () => {
        currentSuccess?.setModalWarning(
            'UNSAVED',
            (type) => onHideUnsaved(type),
            'Do you want to save your changes?'
        );
    }

    const onChangeHealer = (updatedList: ModalityOptions<string>[]) => {
        setHealers({
            ...healers,
            value: updatedList.map(item => item.value),
        })
        setHealersSelected(updatedList);
    }

    return (
        <Modal className="modalContributorSA" show={showModal}>
            <Modal.Header>
                <Modal.Title>
                    Assign Contributors
                    <ButtonsUp>
                        <IconClicker
                            onClick={handleUnsaved}
                            icon="times"
                            size='xl'
                            color={'black'}
                        />
                    </ButtonsUp>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <SelectMultiWrapper
                    options={healerOptions}
                    styles={customStylesRSMultiWhite()}
                    components={{
                        DropdownIndicator
                    }}
                    value={healersSelected || []}
                    onChangeValue={onChangeHealer}
                    placeholder="Select Multiple Healers"
                    showError={healers.showError}
                    error={healers.error}
                />
            </Modal.Body>

            <Modal.Footer>
                <ButtonCancel onClick={handleUnsaved}>Cancel</ButtonCancel>
                <WrapperButton>
                    <ButtonSpinner
                        loading={loading}
                        handleClick={handleOnSave}
                        text={'Save'}
                        hasIcon={false}
                    />
                </WrapperButton>
            </Modal.Footer>
        </Modal>
    )
};

export default ModalContributorSA;

const ButtonsUp = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: flex-start;
`;

const DisabledInput = styled.div`
    outline: none;
    width: 100%;
    height: 100%;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Inter';
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
    min-height: 40px;

    background-color: #f0efed;
    border: var(--simple-gray);
    color: black;
`;

const ListAreas = styled.div`
    width: 100%;
    margin: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
`;

interface PropsArea {
    isSelected: boolean;
}

const Area = styled.div`
    border: 1px solid darkgrey;
    width: fit-content;
    background-color: ${(props: PropsArea) => !props.isSelected ? "white" : "var(--lavender)"};
    padding: 7px 12px;
    border-radius: 5px;
    font-family: ${(props: PropsArea) => !props.isSelected ? "Inter-bold" : "Inter"};
    font-size: 14px;
    cursor: pointer;

    &:hover {
        border: 1px solid gray;
    }
`;


const AreaDeleteable = styled.div`
    border: 1px solid darkgrey;
    width: fit-content;
    background-color: ${(props: PropsArea) => !props.isSelected ? "white" : "var(--lavender)"};
    padding: 7px 12px;
    border-radius: 5px;
    font-family: ${(props: PropsArea) => !props.isSelected ? "Inter-bold" : "Inter"};
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;

    &:hover {
        border: 1px solid gray;
    }
`;

const ButtonCancel = styled.div`
    width: 49%;
    text-align: center;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 1px solid var(--dark-blue);
    cursor: pointer;
`;

const ButtonDelete = styled.div`
    width: 200px;
    text-align: center;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    background-color: var(--t-blue);
    color: white;
    cursor: pointer;
`;

const WrapperButton = styled.div`
    width: 50%;
`;

const Label = styled.div`    
    width: 100%;
    text-align: left;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`;

const Row1 = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: fit-content;
`;

const WrapperSelector = styled.div`
    height: 40px;
    min-height: 40px;
    width: 100%;
`;