import React, { useEffect, useState, useContext } from "react";
import "./styles.scoped.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, VIOLET } from "../../components/ClubIconsNavBar";
import { useDispatch, useSelector } from "react-redux";
import { unixToDateFormatted } from "../../helpers/dateHelper";
import { NotificationResponse } from "../../interfaces/notifications/notification";
import { rejectFriendRequest, saveNewFriend } from "../../store/actions/connectionActions";
import { toast } from "react-toastify";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { getEventInterested, getEventNotInterested, getEventRSVP } from "../../store/actions/eventActions";
import { ApproveOrganization } from "../../store/actions/orgsActions";
import { useTranslation } from "react-i18next";
import { ContextSuccess } from "../../Context";

const Notifications = ({ handleCloseNtf, reloadNotifications }: { handleCloseNtf: any, reloadNotifications: any }) => {
  const { t, i18n } = useTranslation();
  const currentSuccess = useContext(ContextSuccess);
  const notifications = useSelector((state: any) => state.getNotification.getNotifications);
  const loadingNotifications = useSelector((state: any) => state.getNotification.loadingGetNotifications);
  const userInfo = useSelector((state: any) => state.getUser.userInfo);

  const [loadingYes, setLoadingYes] = useState(false);
  const [loadingNo, setLoadingNo] = useState(false);
  const [loadingGoing, setLoadingGoing] = useState(false);
  const [loadingInt, setLoadingInt] = useState(false);
  const [loadingNotGoing, setLoadingNotGoing] = useState(false);
  const [loadingOther, setLoadingOther] = useState(false);

  const [idNotification, setIdNotification] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    reloadNotifications(userInfo.username)
  }, [])

  const acceptJoinRequest = (id: string, username: string) => {
    Backend(
      MicroServices.Club,
      `/clubs/${id}/join-accepted?username=${username}`,
      {
        method: 'POST'
      }
    )
      .then((response) => {
        if (response.status === 201) {
          currentSuccess?.setSuccessData(true, true, t('toast_joinRequest'));
        }
        else {
          currentSuccess?.setSuccessData(false, true, t('toast_joinFailed'));
        }
        setLoadingYes(false);
        setIdNotification('');
      })
  }

  const denyJoinRequest = (id: string, username: string) => {
    Backend(
      MicroServices.Club,
      `/clubs/${id}/deny/join?username=${username}`,
      {
        method: 'DELETE'
      }
    )
      .then((response) => {
        if (response.status === 200) {
          currentSuccess?.setSuccessData(true, true, t('toast_joinRequestDenied'));
        }
        else {
          currentSuccess?.setSuccessData(false, true, t('toast_errorJoinRequest'));
        }
        setLoadingNo(false);
        setIdNotification('');
      })
  }

  const acceptQuitRequest = (id: string, username: string) => {
    Backend(
      MicroServices.Club,
      `/clubs/${id}/quit-accepted?username=${username}`,
      {
        method: 'POST'
      }
    )
      .then((response) => {
        if (response.status === 201) {
          currentSuccess?.setSuccessData(true, true, t('toast_quitRequestAccepted'));
        }
        else {
          currentSuccess?.setSuccessData(false, true, t('toast_quitFailed'));
        }
        setLoadingYes(false);
        setIdNotification('');
      })
  }

  const denyQuitRequest = (id: string, username: string) => {
    Backend(
      MicroServices.Club,
      `/clubs/${id}/deny/quit?username=${username}`,
      {
        method: 'DELETE'
      }
    )
      .then((response) => {
        if (response.status === 200) {
          currentSuccess?.setSuccessData(true, true, t('toast_quitRequestDenied'));
        }
        else {
          currentSuccess?.setSuccessData(false, true, t('toast_errorQuitRequest'));
        }
        setLoadingNo(false);
        setIdNotification('');
      })
  }

  const responseNo = (notification: NotificationResponse) => {
    setLoadingNo(true);
    setIdNotification(notification.timestamp);

    const rejectData = (notification.actionOptions || []).find(action => action.type.includes('REJECT'));

    if (rejectData) {
      if (rejectData.type === 'REJECT-FRIEND') {
        dispatch<any>(rejectFriendRequest(rejectData.data.user1 || '', rejectData.data.user2 || ''))
          .then((results: any) => {
            setLoadingNo(false)
            setIdNotification('');
          });
      }
      else if (rejectData.type === 'REJECT-QUIT') {
        denyQuitRequest(rejectData.data.id || '', rejectData.data.username || '');
      }
      else if (rejectData.type === 'REJECT-JOIN') {
        denyJoinRequest(rejectData.data.id || '', rejectData.data.username || '');
      }
    }
  }

  const responseYes = (notification: NotificationResponse) => {
    setLoadingYes(true);
    setIdNotification(notification.timestamp);

    const acceptedData = (notification.actionOptions || []).find(action => action.type.includes('ACCEPT'));

    if (acceptedData) {
      if (acceptedData.type === 'ACCEPT-FRIEND') {
        dispatch<any>(saveNewFriend(acceptedData.data.username || '', acceptedData.data.friendUsername || ''))
          .then(() => {
            setLoadingYes(false)
            setIdNotification('');
          })
      }
      else if (acceptedData.type === 'ACCEPT-QUIT') {
        acceptQuitRequest(acceptedData.data.id || '', acceptedData.data.username || '');
      }
      else if (acceptedData.type === 'ACCEPT-JOIN') {
        acceptJoinRequest(acceptedData.data.id || '', acceptedData.data.username || '');
      }
    }
  }

  const responseOther = (notification: NotificationResponse) => {
    setLoadingOther(true);
    setIdNotification(notification.timestamp);

    const acceptedData = (notification.actionOptions || []).find(action => action.type.includes('APPROVE'));

    if (acceptedData && acceptedData.type === 'APPROVE-ORG') {
      dispatch<any>(ApproveOrganization(acceptedData.data.organizationId || ''))
        .then(() => {
          setLoadingOther(false)
          setIdNotification('');
        })
    }
  }

  const responseGoing = (notification: NotificationResponse) => {
    setLoadingGoing(true);
    setIdNotification(notification.timestamp);

    const data = (notification.actionOptions || []).find(action => action.type.includes('RSVP'));

    dispatch<any>(getEventRSVP(data?.data.eventId || '', userInfo.username))
      .then((results: any) => {
        if (results.message === 'RSVP done successfully') {
          currentSuccess?.setSuccessData(true, true, t('toast_yourResponseHas'));
        }
        else {
          currentSuccess?.setSuccessData(false, true, t('toast_somethingWereWrong'));
        }
        setLoadingGoing(false);
        setIdNotification('');
      })
  }

  const responseNotInterested = (notification: NotificationResponse) => {
    setLoadingNotGoing(true);
    setIdNotification(notification.timestamp);

    const data = (notification.actionOptions || []).find(action => action.type.includes('NOT'));
    dispatch<any>(getEventNotInterested(data?.data.eventId || '', userInfo.username))
      .then((results: any) => {
        if (results.message === 'Not-Interested-In done successfully') {
          currentSuccess?.setSuccessData(true, true, t('toast_yourResponseHas'));
        }
        else {
          currentSuccess?.setSuccessData(false, true, t('toast_somethingWereWrong'));
        }
        setLoadingNotGoing(false);
        setIdNotification('');
      })
  }

  const responseInterested = (notification: NotificationResponse) => {
    setLoadingInt(true);
    setIdNotification(notification.timestamp);

    const data = (notification.actionOptions || []).find(action => action.type.includes('INTERESTED'));
    dispatch<any>(getEventInterested(data?.data.eventId || '', userInfo.username))
      .then((results: any) => {
        if (results.message === 'Interested-In done successfully') {
          currentSuccess?.setSuccessData(true, true, t('toast_yourResponseHas'));
        }
        else {
          currentSuccess?.setSuccessData(false, true, t('toast_somethingWereWrong'));
        }
        setLoadingInt(false);
        setIdNotification('');
      })
  }

  const compareTimestamps = (a: NotificationResponse, b: NotificationResponse) => {
    const numberA = parseInt(a.timestamp);
    const numberB = parseInt(b.timestamp);
    return numberB - numberA;
  }

  /*const notificationsMockup = [
    {
      timestamp: "123456789",
      entityImg:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      content: 'Aqui una notificación larguiiiiita',
      actionType: 'YES/NO'
    },
    {
      timestamp: "123456789",
      entityImg:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      content: 'Aqui una notificación larguiiiiita',
      actionType:'EVENT'
    },
    {
      timestamp: "123456789",
      entityImg:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      content: 'Aqui una notificación larguiiiiita'
    },
    {
      timestamp: "123456789",
      entityImg:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      content: 'Aqui una notificación larguiiiiita',
      actionType: 'YES/NO'
    },
    {
      timestamp: "123456789",
      entityImg:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      content: 'Aqui una notificación larguiiiiita',
      actionType:'EVENT'
    },
    {
      timestamp: "123456789",
      entityImg:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      content: 'Aqui una notificación larguiiiiita'
    },
    {
      timestamp: "123456789",
      entityImg:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      content: 'Aqui una notificación larguiiiiita',
      actionType: 'YES/NO'
    },
    {
      timestamp: "123456789",
      entityImg:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      content: 'Aqui una notificación larguiiiiita',
      actionType:'EVENT'
    },
    {
      timestamp: "123456789",
      entityImg:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      content: 'Aqui una notificación larguiiiiita'
    },
    {
      timestamp: "123456789",
      entityImg:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      content: 'Aqui una notificación larguiiiiita',
      actionType: 'YES/NO'
    },
    {
      timestamp: "123456789",
      entityImg:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      content: 'Aqui una notificación larguiiiiita',
      actionType:'EVENT'
    },
    {
      timestamp: "123456789",
      entityImg:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      content: 'Aqui una notificación larguiiiiita'
    },
    {
      timestamp: "123456789",
      entityImg:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      content: 'Aqui una notificación larguiiiiita',
      actionType: 'YES/NO'
    },
    {
      timestamp: "123456789",
      entityImg:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      content: 'Aqui una notificación larguiiiiita',
      actionType:'EVENT'
    },
    {
      timestamp: "123456789",
      entityImg:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      content: 'Aqui una notificación larguiiiiita'
    },
  ];*/

  return (
    <div className="container-notifications">
      <div className="header-ntf">
        <label className="title-ntf">{t('menu__notifications')}</label>
        <button className="buttonCloseNtf" onClick={handleCloseNtf}>
          <FontAwesomeIcon icon="times" size="lg" color={'var(-t--blue)'} className=" " />
        </button>
      </div>
      {(loadingNotifications) && (<div className="icon-spinner-third buttonSendSignUp spinner-centered-youth" />)}
      <div className="list-notificacion visible-scrollbar">
        {(notifications.length === 0) ? (
          <span className="Notifications-no-content">{t('label_noNotificationsYet')}</span>
        ) : (
          notifications.sort(compareTimestamps).map((ntf: NotificationResponse) => (
            <div
              id={ntf.timestamp}
              className="card-notificacion"
            >

              <img src={ntf.entityImg || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" />

              <div className="ntf-information">

                <div className={`card-request ${(ntf.link && ntf.link !== '') ? 'pointer' : ''}`}>

                  {(ntf.link && ntf.link !== '') ? (
                    <a className="name-light" href={ntf.link}>
                      {ntf.content || ''}
                    </a>
                  ) :
                    (
                      <div className="name-light">
                        {ntf.content || ''}
                      </div>
                    )}

                  <div className="name-bold">
                    {unixToDateFormatted(ntf.timestamp) || ''}
                  </div>

                </div>

                {(ntf.actionType && ntf.actionType === 'YES/NO') && (
                  <div className="btnRequest">
                    <button
                      className="buttonCancelRequest pointer"
                      onClick={() => responseNo(ntf)}
                    >
                      {(loadingNo && idNotification === ntf.timestamp) ? (
                        <div className="icon-spinner-third buttonSendSignUp" />
                      ) : (
                        <FontAwesomeIcon
                          icon="times"
                          size="1x"
                          color={'var(--t-blue)'}
                          className=" "
                        />
                      )}
                    </button>
                    <button
                      className="buttonAcceptRequest pointer"
                      onClick={() => responseYes(ntf)}
                    >
                      {(loadingYes && idNotification === ntf.timestamp) ? (
                        <div className="icon-spinner-third buttonSendSignUp" />
                      ) : (
                        <FontAwesomeIcon
                          icon="check"
                          size="1x"
                          color={WHITE}
                          className=" "
                        />
                      )}
                    </button>
                  </div>
                )}

                {(ntf.actionType && ntf.actionType === 'OTHER') && (
                  <div className="btnRequest">
                    <button
                      className="buttonApproveOrgNotification pointer"
                      onClick={() => responseOther(ntf)}
                    >
                      {(loadingOther && idNotification === ntf.timestamp) ? (
                        <div className="icon-spinner-third buttonSendSignUp" />
                      ) : (
                        'Approve'
                      )}
                    </button>
                  </div>
                )}

                {(ntf.actionType && ntf.actionType === 'EVENT') && (
                  <div className="btnRequest">
                    <button
                      className="btnRequestEvent pointer"
                      onClick={() => responseGoing(ntf)}
                    >
                      {(loadingGoing && idNotification === ntf.timestamp) ? (
                        <div className="icon-spinner-third buttonSendSignUp" />
                      ) : (
                        <FontAwesomeIcon
                          icon="star"
                          size="1x"
                          color={WHITE}
                          className=" "
                        />
                      )}
                    </button>
                    <button
                      className="btnRequestEvent pointer"
                      onClick={() => responseInterested(ntf)}
                    >
                      {(loadingInt && idNotification === ntf.timestamp) ? (
                        <div className="icon-spinner-third buttonSendSignUp" />
                      ) : (
                        <FontAwesomeIcon
                          icon="head-side"
                          size="1x"
                          color={WHITE}
                          className=" "
                        />
                      )}
                    </button>
                    <button
                      className="btnRequestEvent pointer"
                      onClick={() => responseNotInterested(ntf)}
                    >
                      {(loadingNotGoing && idNotification === ntf.timestamp) ? (
                        <div className="icon-spinner-third buttonSendSignUp" />
                      ) : (
                        <FontAwesomeIcon
                          icon="times-circle"
                          size="1x"
                          color={WHITE}
                          className=" "
                        />
                      )}
                    </button>
                  </div>
                )}

              </div>
            </div>
          )))}
      </div>
    </div>
  );
};

export default Notifications;
