import { useEffect } from 'react';
import useSearchEngine from '../../../../hooks/SearchBar/useSearchEngine';
import Quotes from '../../../BaseComponents/Carrousels/Quotes';

const CarrouselInspiration = ({ search }: { search: string }) => {

    const {
        quotes,
        doInitialSearch,
    } = useSearchEngine({
        search,
        category: 'Quotes',
    });

    useEffect(() => doInitialSearch(), [])
    
    return (
        <Quotes quotes={quotes} />
    )
}

export default CarrouselInspiration;