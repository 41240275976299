import { AnyAction } from "redux";
import {
  START_GET_TEMPLATE_CLUB,
  GET_TEMPLATE_CLUB_SUCCESS,
  GET_TEMPLATE_CLUB_ERROR,
  START_GET_TEMPLATE_CLUB_LIMIT,
  GET_TEMPLATE_CLUB__LIMIT_SUCCESS,
  GET_TEMPLATE_CLUB__LIMIT_ERROR,
} from "../constants/clubConstants";
import templateClubStateInterface from "../../interfaces/store/templateClubsState";
import { GET_GROUPED_TCLUB_ERROR, GET_GROUPED_TCLUB_SUCCESS, GET_TEAMS_ERROR, GET_TEAMS_SUCCESS, LOGOUT_TEMPLATE_CLUB, START_GET_GROUPED_TCLUB, START_GET_TEAMS } from "../constants/templateClubConstants";

const initialState: templateClubStateInterface = {
  loadingTemplateClubs: false,
  templateClubs: [],
  templateClubsError: undefined,
  loadingTemplateClubsLimit: false,
  templateClubsLimit: [],
  templateClubsErrorLimit: undefined,
  loadingTClubGrouped: false,
  TClubGroupedError: undefined,
  TClubGrouped: {},

  loadingTeams: false,
  TeamsError: undefined,
  Teams: [],
};

export const getTemplateClubsReducer = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case START_GET_TEMPLATE_CLUB:
      return {
        ...state,
        loadingTemplateClubs: true,
      };
    case GET_TEMPLATE_CLUB_SUCCESS:
      return {
        ...state,
        loadingTemplateClubs: false,
        templateClubs: action.payload,
      };
    case GET_TEMPLATE_CLUB_ERROR:
      return {
        ...state,
        loadingTemplateClubs: false,
        templateClubsError: action.payload,
      };

    //LIMIT TEMP CLUB

    case START_GET_TEMPLATE_CLUB_LIMIT:
      return {
        ...state,
        loadingTemplateClubsLimit: true,
      };
    case GET_TEMPLATE_CLUB__LIMIT_SUCCESS:
      return {
        ...state,
        loadingTemplateClubsLimit: false,
        templateClubsLimit: action.payload.templateClubsLimit,
      };
    case GET_TEMPLATE_CLUB__LIMIT_ERROR:
      return {
        ...state,
        loadingTemplateClubs: false,
        templateClubsErrorLimit: action.payload,
      };
    
    case START_GET_GROUPED_TCLUB:
        return {
            ...state,
            loadingTClubGrouped: true,
        }
    case GET_GROUPED_TCLUB_SUCCESS:
        return {
            ...state,
            loadingTClubGrouped: false,
            TClubGrouped: action.payload,
        }
    case GET_GROUPED_TCLUB_ERROR:
        return {
            ...state,
            loadingTClubGrouped: false,
            TClubGroupedError: action.payload,
        }
    
    case START_GET_TEAMS:
        return {
            ...state,
            loadingTeams: true,
        }
    case GET_TEAMS_SUCCESS:
        return {
            ...state,
            loadingTeams: false,
            Teams: action.payload,
        }
    case GET_TEAMS_ERROR:
        return {
            ...state,
            loadingTeams: false,
            TeamsError: action.payload,
        }

    case LOGOUT_TEMPLATE_CLUB:
      return initialState;
    
    default:
      return state;
  }
};
