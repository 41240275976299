import React, { useContext, useEffect, useState } from 'react';
import './App.css';
import RootNavigation from './routes/RootNavigation';
import Amplify from 'aws-amplify';
import { region, userPoolId, userPoolWebClientId } from './AppConfig';
import ModalComplete from './components/BaseComponents/Modals/ModalComplete';
import { ContextType, ContextSuccess } from './Context';
import ModalLogin from './components/BaseComponents/Modals/ModalLogin';
import ModalUnsaved, { onHideType } from './components/BaseComponents/Modals/ModalUnsaved';
import ModalDelete from './components/BaseComponents/Modals/ModalDelete';
import ModalPayInfo from './components/BaseComponents/Payment/ModalPayInfo';
import ModalPayment from './components/BaseComponents/Payment/ModalPayment';
import ModalPurchaseEvent, { EventPurchase } from './components/BaseComponents/Events/ModalPurchaseEvent';
import { deliveryEvent, deliverySimple } from './components/BaseComponents/Events/EventCard';
import { useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import PaymentSuccessful from './components/BaseComponents/Payment/PaymentSuccessful';

Amplify.configure({
  Auth: {
    region,
    userPoolId,
    userPoolWebClientId,
    mandatorySignIn: false,
    // authenticationFlowType: 'USER_PASSWORD_AUTH',
  }
});

export type WarningType = 'DELETE' | 'UNSAVED';

function App() {

  const location = useLocation();

  const [successBool, setSuccessBool] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [titleSuccess, setTitleSuccess] = useState<string>('');
  const [subTitleSuccess, setSubTitleSuccess] = useState<string>('');

  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [onlyLogin, setOnlyLogin] = useState<boolean>(false);

  const [showModalPay, setShowModalPay] = useState<boolean>(false);
  const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false);
  const [showPaymentSuccessful, setShowPaymentSuccessful] = useState<boolean>(false);
  const [showModalUnsaved, setShowModalUnsaved] = useState<boolean>(false);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [showPurchaseEvent, setShowPurchaseEvent] = useState<boolean>(false);
  const [content, setContent] = useState<string>('');
  const [btnContent, setBtnContent] = useState<string>('');
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const [successPayment, setSuccessPayment] = useState<boolean>(false);
  const [paymentError, setPaymentError] = useState<string>('');
  const [onHideModal, setOnHideModal] = useState<(type: onHideType) => void>((type: onHideType) => { });
  const [eventData, setEventData] = useState<EventPurchase>({
    name: '',
    delivery: 'online',
    amount: 0,
    id: '',
    reload: () => { }
  });
  const [loginToken, setLoginToken] = useState<string>();
  const [loginUseInvite, setLoginUseInvite] = useState<boolean>();

  const setSuccessData = (
    successBool: boolean,
    showModalSuccess: boolean,
    titleSuccess: string,
    subTitleSuccess?: string) => {
    setSuccessBool(successBool);
    setShowModalSuccess(showModalSuccess);
    setTitleSuccess(titleSuccess);
    if (subTitleSuccess) setSubTitleSuccess(subTitleSuccess);
    else setSubTitleSuccess('');
  }

  const setLoginModal = (
    showLoginModal: boolean,
    onlyLogin: boolean,
    token?: string,
    useInvite = false,
  ) => {
    setShowLoginModal(showLoginModal);
    setOnlyLogin(onlyLogin);
    setLoginToken(token);
    setLoginUseInvite(useInvite);
  }

  const setModalWarning = (
    type: WarningType,
    onHide: (type: onHideType) => void,
    message: string,
    btnContent?: string
  ) => {
    switch (type) {
      case 'UNSAVED':
        setShowModalUnsaved(true);
        break;

      case 'DELETE':
        setBtnContent(btnContent || 'Delete');
        setShowModalDelete(true);
        break;

      default:
        break;
    }

    setContent(message);
    setOnHideModal(() => onHide);
  }

  const setModalPayInfo = (
    content?: string
  ) => {
    setContent(content || '');
    setShowModalPay(true);
  }

  const setPaymentForm = (
    amount: number
  ) => {
    setShowPaymentForm(true);
    setAmount(amount);
  }

  const setPaymentSuccess = (
    success: boolean,
    amount: number,
    error?: string
  ) => {
    setSuccessPayment(success);
    setAmount(amount);
    if(error) setPaymentError(error);
    setShowPaymentSuccessful(true);
  }

  const setModalsOff = () => {
    setShowModalPay(false);
    setShowPaymentForm(false);
    setShowModalUnsaved(false);
    setShowModalDelete(false);
    setShowModalSuccess(false);
    setShowPurchaseEvent(false);
    setShowPaymentSuccessful(false);
  }

  const openModalPurchaseEvent = (
    id: string,
    name: string,
    delivery: deliverySimple,
    amount: number,
    reload: () => void
  ) => {
    setEventData({
      id,
      name,
      delivery,
      amount,
      reload
    })

    setShowPurchaseEvent(true);
  }

  useEffect(() => {
    const partLoc = location.search.split('&')
    if (partLoc.length > 0 && (partLoc[0].includes('confirmed') && partLoc[0].includes('true'))) {
      setSuccessData(true, true, 'User confirmed!', 'You can now access to your account.')
    }
  }, [location.search])

  const onHideModalPay = () => setShowModalPay(false);
  const onHidePaymentForm = () => setShowPaymentForm(false);
  const onHidePurchaseEvent = () => setShowPurchaseEvent(false);

  const [currentContext, setCurrentContext] = useState<ContextType | null>({
    setSuccessData,
    setLoginModal,
    showLoginModal,
    setModalWarning,
    setLoadingModal,
    setModalPayInfo,
    setPaymentForm,
    setModalsOff,
    openModalPurchaseEvent,
    setPaymentSuccess,
  });

  return (
    <ContextSuccess.Provider value={currentContext}>
      <HelmetProvider>
        <div className="App">
          <RootNavigation />
          {showModalSuccess &&
            <ModalComplete
              onHide={setShowModalSuccess}
              title={titleSuccess}
              subTitle={subTitleSuccess}
              success={successBool}
            />}
          <ModalLogin
            show={showLoginModal}
            setShowModal={setShowLoginModal}
            onlyLogin={onlyLogin}
            token={loginToken}
            loginUseInvite={loginUseInvite}
          />
          <ModalUnsaved
            show={showModalUnsaved}
            onHide={onHideModal}
            content={content}
          />
          <ModalDelete
            show={showModalDelete}
            onHide={onHideModal}
            loading={loadingModal}
            content={content}
            buttonContent={btnContent || 'Delete'}
          />
          <ModalPayInfo
            show={showModalPay}
            onHide={onHideModalPay}
            content={content}
          />
          <ModalPayment
            showModal={showPaymentForm}
            onHide={onHidePaymentForm}
            baseAmount={amount}
          />
          <PaymentSuccessful
            amount={amount}
            show={showPaymentSuccessful}
            success={successPayment}
            error={paymentError}
            close={setModalsOff}
          />
          <ModalPurchaseEvent
            showModal={showPurchaseEvent}
            hide={onHidePurchaseEvent}
            eventData={eventData}
          />
        </div>
      </HelmetProvider>
    </ContextSuccess.Provider>

  );
}

export default App;
