import { faUser, faChevronDown, faCopy, faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { StyledFontAwesomeIcon, InfoCount, Interested, Attend } from "../../HealerProfile/components";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { ContextSuccess } from "../../../Context";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import ModalEventDetail from "./ModalEventDetail";
import { ClickableIcon } from "../../SuperCenter/SuperAdminComponents/basicForSACenter";
import { formatCompactNumber } from "../../../helpers/numberHelper";
import { cleanHTML, getColorsInitials, getInitials } from "../../../helpers/letterHelper";
import { AvatarCreatorHome, CreatorNoAvatar, RowGuideCardEvent, RowIconCardHome } from "../HomeCards/styles";
import { GuideInfoExtended } from "../Communities/CommunityCard";
import { useLocation, useNavigate } from "react-router-dom";
import { DividerLine } from "../../SuperCenter/Articles/ViewArticle";
import SelectorRSVP from "../../Events/SelectorRSVP";
import OnSale from "./OnSale";
import EventStateLabel from "./EventStateLabel";
import Modality from "@vibe/sdk/interfaces/Modalities/modalities/Modality/Modality";

export type deliverySimple = 'online' | 'inPerson';
export type deliveryEvent = deliverySimple | 'mixed';
export type rsvp = 'Going Online' | 'Going In Person' | 'Interested' | 'Not Interested' | 'RSVP';

export interface EventCardInterface {
    id: string;
    cover?: string;
    name: string;
    description: string;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    delivery: deliveryEvent;
    interested: number;
    attending: number;
    isFullOnline: boolean;
    isFullInPerson: boolean;
    rsvp: rsvp;
    fee_online: number;
    fee_person: number;
    address1?: string;
    address2?: string;
    link?: string;
    guide: GuideInfoExtended;
    creator: string;
    eventBriteUrl?: string;
    locationComplete?: string;
    createdAt: string;
    modalities?: Modality[];

    typeID: string;
    start: Date;

    city_id?: string;
    country_id?: string;
    state_id?: string;
    modalitiesIDs?: string[];
}

export const mockEventCard: EventCardInterface = {
    id: '',
    name: '',
    description: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    delivery: 'online',
    interested: 0,
    attending: 0,
    isFullOnline: false,
    isFullInPerson: false,
    rsvp: 'RSVP',
    address1: '',
    fee_online: 0,
    fee_person: 0,
    guide: {
        name: '',
        avatar: '',
        username: '',
    },
    creator: '',
    createdAt: '',
    modalities: [],
    typeID: '',
    start: new Date(),
}

const EventCard = ({
    data,
    reload,
    onClickHome,
    isHome = false,
    width = "100%",
}: {
    data: EventCardInterface
    reload: () => void
    onClickHome?: (data: EventCardInterface) => void
    isHome?: boolean
    width?: string
}) => {

    const currentSuccess = useContext(ContextSuccess);
    const location = useLocation();
    const navigate = useNavigate();

    const username = useSelector((state: RootState) => state.getUser.userInfo?.username);

    const [showEvent, setShowEvent] = useState<boolean>(false);

    const copyToClipboard = (toCopy: string) => {
        navigator.clipboard.writeText(toCopy);
        currentSuccess?.setSuccessData(true, true, 'Copied to clipboard');
    }

    const getDelivery = () => {
        switch (data.delivery) {
            case 'inPerson':
                return (
                    <TextColumn>
                        <TextRow>
                            <ClickableIcon
                                icon='thumbtack'
                                size='sm'
                                color="black"
                            />
                            <div className="testDescEvent" dangerouslySetInnerHTML={{ __html: data.locationComplete || '' }} />
                        </TextRow>
                    </TextColumn>
                );

            case 'online':
                return (
                    <TextColumn>
                        <TextRow>
                            <ClickableIcon
                                icon='video'
                                size='sm'
                                color={"black"}
                            />
                            <a href={`${data.link}`} target="_blank" rel="noreferrer">
                                Link
                            </a>
                            <ClickableIcon
                                icon={faCopy}
                                size='sm'
                                color={'var(--t-blue)'}
                                onClick={() => copyToClipboard(`${data.link}`)}
                            />
                        </TextRow>
                    </TextColumn>

                );

            case 'mixed':
                return (
                    <TextColumn>
                        <TextRow>
                            <ClickableIcon
                                icon='thumbtack'
                                size='sm'
                                color="black"
                            />
                            <div className="testDescEvent" dangerouslySetInnerHTML={{ __html: data.locationComplete || '' }} />
                        </TextRow>
                        <TextRow>
                            <ClickableIcon
                                icon='video'
                                size='sm'
                                color={"black"}
                            />
                            <a href={`${data.link}`} target="_blank" rel="noreferrer">
                                Link
                            </a>
                            <ClickableIcon
                                icon={faCopy}
                                size='sm'
                                color={'var(--t-blue)'}
                                onClick={() => copyToClipboard(`${data.link}`)}
                            />
                        </TextRow>
                    </TextColumn>
                );

            default:
                return <></>;
        }
    }

    const getFee = () => {
        return data.fee_online > data.fee_person ? `$${data.fee_person} - $${data.fee_online}` : `$${data.fee_online} - $${data.fee_person}`;
    }

    const handleOnClickHome = () => {
        if (onClickHome && isHome) onClickHome(data);
    }

    const getCreatorNoAvatar = () => {
        const num = [...data.guide.name].reduce((acc, curr) => acc + curr.charCodeAt(0), 0);

        const colors = getColorsInitials(num);
        return <CreatorNoAvatar
            background_color={colors.background}
            text_color={colors.color}
        >
            {getInitials(data.guide.name)}
        </CreatorNoAvatar>;
    }

    const handleNavigate = () => {
        if (location.pathname.includes('app')) {
            navigate(`../../../../app/event/${data.id}`);
        }
        else {
            setShowEvent(true)
        }
    }

    const showRSVPSelector = () : boolean => {
        const now = new Date();
        const startDate = new Date(`${data.startDate} ${data.startTime}`);
        return !isHome && data.creator !== username && now.getTime() < startDate.getTime()
    }

    const isNotFinished = (): boolean => {
        const endDate = new Date(`${data.endDate} ${data.endTime}`);
        const now = new Date();

        if(now.getTime() > endDate.getTime()) return false;
        return true;
    }

    return (<>
        <CardEvent onClick={handleOnClickHome} width={width}>
            <ClickCard onClick={handleNavigate}>

                {(data.cover && data.cover !== 'cover') ? (
                    <Cover src={data.cover} />
                ) : (
                    <NoCover>
                        <FontAwesomeIcon
                            icon="image"
                            size="2x"
                            color="var(--default-dark)"
                        />
                    </NoCover>
                )}

                <Name>{data.name}</Name>

                <RowGuideCardEvent>
                    {data.guide.avatar ? (
                        <AvatarCreatorHome src={data.guide.avatar} />
                    ) : (
                        getCreatorNoAvatar()
                    )}
                    {data.guide.name}
                </RowGuideCardEvent>

                <TextRow>
                    {data.startDate}
                    <Time>{data.startTime}</Time>
                </TextRow>

                {getDelivery()}

                {data.eventBriteUrl && isNotFinished() && <OnSale url={data.eventBriteUrl} />}
            </ClickCard>


            <FooterCard>
                <TextBetween>
                    <TextRow>
                        {`${formatCompactNumber(data.interested)}`} Interested
                        <Time>{`${formatCompactNumber(data.attending)}`} Attendees</Time>
                    </TextRow>

                    <TextRow>
                        {(data.delivery === 'online') && (
                            data.fee_online > 0 ? <LabelCost>${data.fee_online}</LabelCost> : <LabelFree>Free</LabelFree>
                        )}
                        {(data.delivery === 'inPerson') && (
                            data.fee_person > 0 ? <LabelCost>${data.fee_person}</LabelCost> : <LabelFree>Free</LabelFree>
                        )}
                        {(data.delivery === 'mixed') && (
                            (data.fee_person > 0 || data.fee_online > 0) ? <LabelCost>{getFee()}</LabelCost> : <LabelFree>Free</LabelFree>
                        )}
                    </TextRow>
                </TextBetween>

                {showRSVPSelector() && (
                    <SelectorRSVP
                        reload={reload}
                        id={data.id}
                        delivery={data.delivery}
                        rsvpSelector={data.rsvp}
                        name={data.name}
                        amountOnline={data.fee_online}
                        amountInPerson={data.fee_person}
                        isFullInPerson={data.isFullInPerson}
                        isFullOnline={data.isFullOnline}
                    />
                )}
            </FooterCard>

            <EventStateLabel item={data} />
        </CardEvent >

        <ModalEventDetail
            event={data}
            show={showEvent}
            setShow={setShowEvent}
        />
    </>)
}

export const LabelFree = styled.div`
    padding: 4px 8px;
    width: fit-content;
    border-radius: 8px;
    font-family: 'Inter-Bold';
    font-size: 12px; 
    line-height: 20px;
    color: var(--t-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    background-color: var(--strong-yellow);
`

export const LabelCost = styled(LabelFree)`
    color: white;
    background-color: var(--strong-blue);
`;

const ButtonSeeAll = styled.div`
    padding: 4px 24px;
    width: 100%;
    height: 40px;
    border: 1px solid var(--t-blue);
    border-radius: 8px;
    font-family: 'Inter-Bold';
    font-size: 16px; 
    color: var(--t-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
`

const Cover = styled.img`
    width: 100%;
    height: 120px;
    border-radius: 8px;
    object-fit: cover;
`;

const NoCover = styled.div`
    width: 100%;
    height: 120px;
    border-radius: 8px;
    background-color: var(--non-contextual-grey-muted);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Avatar = styled.img`
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;
    border-radius: 50%;
    object-fit: cover;
`;

const Name = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--default-dark);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: 'Inter-bold';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.1px;

    width: 100%;
    text-align: left;
`;

const OptionsList = styled.div`
    position: absolute;
    background-color: white;
    width: calc(100% + 2px);
    z-index: 100;
    left: -1px;
    top: 33px;
    border: 1px solid #bfbfbf;
    padding: 7px 0px;
    border-radius: 0 0 9px 9px;
`
const OptionList = styled.div`
    font-family: 'Inter';
    padding: 8px 15px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    cursor: ${({ isFull = false }: { isFull?: boolean }) => isFull ? 'default' : 'pointer'};
    opacity: ${({ isFull = false }: { isFull?: boolean }) => isFull ? '0.5' : '1'};

    &:hover{
        background-color: ${({ isFull = false }: { isFull?: boolean }) => isFull ? 'transparent' : '#ebe8e6'};
    }

`
const CardEvent = styled.div`
    border: 1px solid var(--simple-gray);
    padding: 16px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: space-between;
    // overflow: hidden;
    width: ${({ width }: { width: string }) => width};
    height: 395px;
    min-height: 395px;
    //max-height: 380px;
    position: relative;
`;

const FooterCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    // overflow: hidden;
    width: 100%;
`;

const ClickCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    height: fit-content;
    cursor: pointer;
`;

const DetailEvent = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
`
const TextRow = styled.div`
    width: 100%;
    display: flex;
    gap: 5px;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    letter-spacing: 0.2px;
    color: var(--default-black);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-items: center;

    & .testDescEvent{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & a{
        font-family: 'Inter';
        font-weight: 400;
        color: var(--absolute-zero);
        text-decoration: underline !important;
    }
`

const TextColumn = styled(TextRow)`
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    width: 100%;
`;

const TextBetween = styled(TextRow)`
    gap: 20px;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
`;

const InfoEvent = styled(TextColumn)`
    margin-top: 10px;
`;

const Time = styled.div`
    border-left: 1px solid black;
    padding-left: 5px;
`
const TypeEvent = styled.div`
    font-family: 'Inter-Bold';
    font-size: 12px;
    gap: 4px;
    display: flex;
    align-items: center;
    color: #2E2623;
`
const IconChevronRight = styled(FontAwesomeIcon)`
    position: absolute;
    right: 10px;
`;

export default EventCard;