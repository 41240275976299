import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { getStatusColor } from "../../../helpers/chat/statusHelper";

const logoVibe = require("../../../assets/img/logoVibe.png");
export const LogoHeaderHome = styled.div`
    width: 126px;
    height: 56px;
    background-image: url("${logoVibe}");
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
`;

export const HeaderHomeContainer = styled.div`
    display: flex;
    width: 100%;
    height: 120px;
    padding: 56px 136px 16px 136px;
    align-items: center;
    gap: 16px;
    border-bottom: 1px solid var(--border-default);
    justify-content: space-between;
    background-color: ${({ active }: { active: boolean }) => active ? "white" : "transparent"};

    @media only screen and (max-width: 770px) {
        padding: 10px 20px;
        height: 60px;
        position: fixed;

        & ${LogoHeaderHome}{
            height: 40px;
            width: 95px;
        }
    }
`;

export const ButtonsHeaderHome = styled.div`
    display: flex;
    gap: 32px;
    align-items: center;

    color: black;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0.2px;

    @media only screen and (max-width: 770px) {
        gap: 12px;
    }
`;

export const ButtonSignupHome = styled.div`
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 8px;
    background: ${({ active }: { active: boolean }) => active ? "var(--orange-home)" : "var(--dark-blue)"};

    color: white;
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.1px;

    cursor: pointer;
    @media only screen and (max-width: 770px) {
        padding: 4px 16px;
    }
`;

export const TextClicker = styled.div`
    cursor: pointer;

    & a{
        color: ${({colorLink = "white"}:{colorLink?: string}) => colorLink}
    }
`;

export const IconClicker = styled(FontAwesomeIcon)`
    cursor: pointer;
`;

export const MenuButtonWrapper = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;

    @media only screen and (max-width: 770px) {
        padding: 0px 0px;

        & ${TextClicker}{
            display: none;
        }
    }
`;

export const ImageContainerHeaderHome = styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--onyx);
  border-radius: 36px;
  position: relative;
  cursor: pointer;
`;

export const AvatarHeaderHome = styled.img`
  width: 47px;
  height: 47px;
  min-width: 47px;
  min-height: 47px;
  max-width: 47px;
  max-height: 47px;
  border-radius: 100%;
  object-fit: cover;
  border: 1px solid var(--simple-blue-dark);
`;

interface CircleStatusProps {
    status: string;
}

export const CircleStatusHeaderHome = styled.div`
  padding: 8px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  background-color: ${(props: CircleStatusProps) => getStatusColor(props.status.toLocaleLowerCase())};
`;