import React, { useState, useEffect } from 'react';
import "./styles.css";
import "./styles.scoped.css";
import ClubInfoCarousel from "../../components/ClubInfoCarousel";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ClubEditInformation from '../ClubEditInformation';
import { Advisor, Officer } from '../../containers/Club';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from "../../components/ClubIconsNavBar";
import { cleanHTML, cleanStyleHTML, onlyFirstLetterCapitalized } from '../../helpers/letterHelper';
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from '../../helpers/backendHelper';
import styled from 'styled-components';
import { IconClicker } from '../Home/HeaderHome/styles';

function Index(props: any) {
  const { t, i18n } = useTranslation();

  const { id } = useParams();
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const [editClubStatus, setEditClubStatus] = useState(false);

  function handleditClub() {
    setEditClubStatus(true)
  }

  const goToInformation = () => {
    setEditClubStatus(false);
    props.reloadClub();
  }

  const formatAdvs = () => {
    return (props.advisors || []).filter((adv: any) => !adv.fullname.includes('undefined'));
  }

  return (
    <>
      {editClubStatus ?
        <ClubEditInformation
          club={props.infoClub}
          officers={props.officers}
          advisors={formatAdvs()}
          members={props.members}
          goToInformation={goToInformation}
          reloadOfficers={props.reloadOfficers}
          reloadAdvisors={props.reloadAdvisors}
        />
        :
        <ClubCenterWrap>
          <ClubInfo>
            <p
              dangerouslySetInnerHTML={{ __html: cleanStyleHTML(props.infoClub?.description || 'No description') }}
              className="robotocondensed-normal-t-blue-16px descClub">
            </p>
            {(props.isClubAdvisor) &&
              (<IconClicker
                icon="pen"
                size='lg'
                color={'var(--t-blue)'}
                className="pointer "
                onClick={() => handleditClub()}
              />)}

          </ClubInfo>

          {/* <div className="key-words-row">
            {((props.infoClub?.skills_learned || []) as string[]).map((skill) => (
              <div className="key-word robotocondensed-normal-lavender-16px">
                {skill}
              </div>
            ))}
          </div> */}

          <ClubInfoCarousel informationClub={props.infoClub} />

          <div className="information-title-row">
            <div id="officers-row">
              <TitleGuides>Guides ({formatAdvs().length})</TitleGuides>
            </div>
          </div>
          <div className="officers">
            {formatAdvs().map((advisor: any) => (
              <div className="officers-row">
                <img
                  className="officer-avatar"
                  src={advisor.avatar || `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png`}
                  alt="advisor"
                />
                <div className="officer-info">
                  <div className="officer-name robotocondensed-bold-t-blue-16px">
                    {advisor.fullname || ''}
                  </div>
                  <div className="officer-role robotocondensed-normal-t-blue-16px">
                    {advisor.userType ? onlyFirstLetterCapitalized(advisor.userType) : (advisor.unregistered ? t('label_unregistered') : '')}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </ClubCenterWrap>
      }

    </>

  );
}

export default Index;


export const ClubCenterWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 10px;
  overflow: auto;
`;

const TitleGuides = styled.div`
  font-family: 'Inter-bold';
  font-size: 20px;
  color: var(--t-blue);
`;

const ClubInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  padding: 20px 0px;
`;