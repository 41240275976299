import React, { useState } from 'react'
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import useBalance from '../../hooks/GV/useBalance';
import { useNavigate } from 'react-router-dom';
import { IconClicker } from '../Home/HeaderHome/styles';

interface ContainerBalanceProps {
  showDetails: boolean
}

const index = (props: ContainerBalanceProps) => {

  const { balanceVisible, toggleBalanceVisible, balance } = useBalance();
  const navigate = useNavigate();

  return (
    <Balance>
      <InfoBalance>
        <TitleBalance>
          Good Vibes
          <IconClicker
            icon={balanceVisible ? faEyeSlash : faEye}
            size="sm"
            onClick={toggleBalanceVisible}
          />
        </TitleBalance>
        <CountBalance>
          <Logo />
          {balanceVisible ? Number(balance).toFixed(2) : '***'}
        </CountBalance>
      </InfoBalance>
      {props.showDetails &&
        <ButtonDetails onClick={() => navigate('../../../../../good-vibes')}>
          Details
        </ButtonDetails>
      }
    </Balance>
  )
}

const logoVibe = require("../../assets/img/vibeLogo.svg");
const Logo = styled.img`
    width: 24px;
    height: 24px;
    content: url("../../../vibeLogo.svg");
`;

const InfoBalance = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`

const CountBalance = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 24px;
  font-family: 'Inter-Bold';
`

const ButtonDetails = styled.div`
  color: #2A4CD3;
  font-family: "Inter-Bold";
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const TitleBalance = styled.div`
  display: flex;
  width: 100%;
  font-family: 'Inter';
  font-size: 18px;
  gap: 10px;
  align-items: center;
`

const Balance = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  background: #E6EDF7;
  width: 100%;
  height: 80px;
  -webkit-box-shadow: 3px 9px 5px -6px rgba(0,0,0,0.35);
  -moz-box-shadow: 3px 9px 5px -6px rgba(0,0,0,0.35);
  box-shadow: 3px 9px 5px -6px rgba(0,0,0,0.35);
  justify-content: space-between;
`;

export default index