import { useState } from "react";
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { WHITE, LAVENDER } from "../../ClubIconsNavBar";
import { BROWN } from "../../SuperCenter/Modalities/Modals/components";
import styled from "styled-components";
import OutsideClickHandler from "react-outside-click-handler";

function ModalComplete({
  onHide,
  title,
  bottom = '47vh',
  left= '92px',
}: {
  onHide: (type: boolean) => void
  title: string
  bottom?: string
  left?: string
}) {
  return (
    <OutsideClickHandler onOutsideClick={() => onHide(false)}>
      <Card bottom={bottom} left={left}>
          {title}
      </Card>
    </OutsideClickHandler>
  );
}

interface CardProps{
  bottom: string
  left: string
}

const Card = styled.div`
  padding: 15px;
  position: absolute;
  display: flex;
  background-color: var(--simple-blue-dark);
  border: 2px solid white;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0,0,0,0.1);
  z-index: 1070;
  bottom: ${(props: CardProps) => props.bottom};
  left: ${(props: CardProps) => props.left};
  justify-items: flex-start;
  text-align: left;

  font-family: 'Inter';
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: flex-start;
  color: white;
`;

export default ModalComplete;