import Modal from "react-bootstrap/esm/Modal";
import { deliveryEvent, deliverySimple } from "./EventCard";
import { IconClicker } from "../../Home/HeaderHome/styles";
import { useContext, useState } from "react";
import { id } from "date-fns/locale";
import { type } from "os";
import { ContextSuccess } from "../../../Context";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import styled from "styled-components";
import ButtonSpinner from "../ButtonSpinner";
import ContainerBalance from "../../ContainerBalance";
import useBalance from "../../../hooks/GV/useBalance";
import IResponseError from "@vibe/sdk/dist/interfaces/IResponseError";

export interface EventPurchase {
    name: string
    delivery: deliverySimple
    amount: number
    id: string
    reload: () => void
}

const ModalPurchaseEvent = ({
    showModal,
    hide,
    eventData,
}: {
    showModal: boolean
    hide: () => void
    eventData: EventPurchase
}) => {
    const currentSuccess = useContext(ContextSuccess);

    const isLoggedIn = useSelector((state: RootState) => state.getUser.isLoggedIn);
    const username = useSelector((state: RootState) => state.getUser.userInfo?.username);

    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const { balance, reloadBalance } = useBalance();

    const handleGoing = () => {
        if (isLoggedIn && username) {
            setLoading(true);
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Events.RSVP(
                        eventData.id,
                        username,
                        eventData.delivery,
                        dataSDK.token
                    );
                })
                .then((response) => {
                    if (response.statusCode === 201) {
                        eventData.reload();
                        reloadBalance();
                        onHide();
                        currentSuccess?.setSuccessData(true, true, `Response sent`);
                    }
                    else if(response.statusCode === 400){
                        // Error when is full
                        currentSuccess?.setSuccessData(false, true, 'Ops!', (response.body as IResponseError).error);
                    }
                    else {
                        currentSuccess?.setSuccessData(false, true, 'Try again', (response.body as IResponseError).error);
                    }
                })
                .catch((error) => {
                    console.log('error RSVP', error)
                    currentSuccess?.setSuccessData(false, true, 'Try again');
                })
                .finally(() => setLoading(false))
        }
        else {
            currentSuccess?.setLoginModal(true, false);
        }
    }

    const handleContinue = () => {
        if (showConfirm) handleGoing();
        else setShowConfirm(true);
    }

    const onHide = () => {
        setShowConfirm(false);
        setLoading(false);
        hide();
    }

    const getDelivery = () => {
        if (eventData.delivery === 'inPerson') return 'In Person';
        return 'Online'
    }

    return (
        <Modal className="modalPurchaseEvent" show={showModal} onHide={onHide}>
            <Modal.Header className={showConfirm ? '' : "notConfirm"}>
                <Modal.Title>
                    {showConfirm ? 'Purchase with Good Vibes' : eventData.name}
                    <IconClicker
                        onClick={onHide}
                        icon="times"
                        size='xl'
                        color={'black'}
                    />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {showConfirm ? (
                    <>
                        <ContainerBalance showDetails={false} />
                        Amount
                        <AmountPurchase>${eventData.amount} USD</AmountPurchase>
                    </>
                ) : (
                    <>
                        <div>Join the best events from anywhere</div>
                        <Row>
                            {getDelivery()}
                            <Amount>${eventData.amount} USD</Amount>
                        </Row>
                    </>
                )}
            </Modal.Body>

            <Modal.Footer>
                {showConfirm && <ButtonCancel onClick={onHide}>Cancel</ButtonCancel>}
                <ButtonSpinner
                    loading={loading}
                    handleClick={handleContinue}
                    text={showConfirm ? 'Confirm' : 'Purchase'}
                    hasIcon={false}
                    disabled={showConfirm && balance < eventData.amount}
                />
            </Modal.Footer>
        </Modal>
    )
}

export default ModalPurchaseEvent;

const Amount = styled.div`
    color: #555F6D;
`;

const AmountPurchase = styled.div`
    color: #181818;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Inter';
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: 0.1px;
`;

const Row = styled.div`
    font-family: 'Inter';
    padding: 8px 15px;
    text-align: left;
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

const ButtonCancel = styled.div`
    width: 100%;
    border-radius: 8px;
    border: 1px solid #999796;
    background: #FFF;
    font-family: 'Inter-Bold';
    font-size: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    color: #29303B;
`;