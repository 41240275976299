import Modal from "react-bootstrap/esm/Modal";
import styled from "styled-components";
import SelectItem from "../../interfaces/SelectItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CurrencyInput from "react-currency-input-field";
import { Imp } from "../signupsetuphealerind";
import { EventDateInfo, deliveryTypeEvent } from "../../interfaces/Event/CreateEvent";
import { ContextSuccess } from "../../Context";
import { useContext, useEffect, useState } from "react";
import { onHideType } from "../BaseComponents/Modals/ModalUnsaved";
import { IconClicker } from "../Home/HeaderHome/styles";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import ButtonSpinner from "../BaseComponents/ButtonSpinner";

const eventBriteType: SelectItem[] = [
    {
        label: 'Online in Eventbrite',
        value: 'online'
    },
    {
        label: 'In Person in Eventbrite',
        value: 'inPerson'
    },
]

const PopupEventbrite = ({
    showModal,
    hide,
    eventDate,
    setEventDate,
}: {
    showModal: boolean
    hide: () => void
    eventDate: EventDateInfo
    setEventDate: (data: EventDateInfo) => void
}) => {

    const currentSuccess = useContext(ContextSuccess);

    const [freeEvent, setFreeEvent] = useState<boolean>(false);

    const onClickBriteDelivery = (type: deliveryTypeEvent) => {
        switch (type) {
            case 'inPerson':
                setEventDate({
                    ...eventDate,
                    eventBriteOnline: false,
                })
                break;

            case 'online':
                setEventDate({
                    ...eventDate,
                    eventBriteOnline: true,
                })
                break;

            default:
                break;
        }
    }

    const handleBriteCapacity = (data: number) => {
        setEventDate({
            ...eventDate,
            eventBriteCapacity: data,
        })
    }

    const handleBriteCost = (data: any) => {
        setEventDate({
            ...eventDate,
            eventBriteFee: data,
        })
    }

    const cleanEventbrite = () => {
        setEventDate({
            ...eventDate,
            eventBriteOnline: false,
        })
        setEventDate({
            ...eventDate,
            eventBriteCapacity: 0,
        })
        setEventDate({
            ...eventDate,
            eventBriteFee: 0,
        })
        setEventDate({
            ...eventDate,
            createEventbrite: false,
        })
    }

    const saveEventbrite = () => {
        // if (!freeEvent && eventDate.eventBriteFee === 0) {
        //     currentSuccess?.setSuccessData(false, true, 'Missing Data!', 'Please add event Fee for Eventbrite tickets.');
        //     return;
        // }

        if (eventDate.eventBriteCapacity === 0) {
            currentSuccess?.setSuccessData(false, true, 'Missing Data!', 'Please add event capacity for Eventbrite.');
            return;
        }

        hide();
    }

    const onHideDiscard = (type: onHideType) => {
        switch (type) {
            case 'discard':
                currentSuccess?.setModalsOff();
                cleanEventbrite();
                hide();
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'save':
                currentSuccess?.setModalsOff();
                saveEventbrite();
                break;

            default:
                break;
        }
    };

    const onHide = () => currentSuccess?.setModalWarning('UNSAVED', onHideDiscard, 'Do you want to discard Eventbrite changes?', 'Discard');

    return (
        <Modal className="modalCreateEventbrite" show={showModal} onHide={onHide}>
            <Modal.Header>
                <Title>Create event on Eventbrite</Title>
                <IconClicker
                    onClick={onHide}
                    icon="times"
                    size='xl'
                    color={'black'}
                />
            </Modal.Header>

            <Modal.Body>
                {eventDate.delivery === 'mixed' && <ListCheckbox>
                    {eventBriteType.map(d => (
                        <ItemList onClick={() => onClickBriteDelivery(d.value as deliveryTypeEvent)}>
                            <IconRadio theme={{ main: (eventDate.eventBriteOnline && d.value === 'online' || !eventDate.eventBriteOnline && d.value === 'inPerson') ? 'iconCheck' : '' }}>
                                {(eventDate.eventBriteOnline && d.value === 'online' || !eventDate.eventBriteOnline && d.value === 'inPerson') &&
                                    <RadioIcon
                                        icon={faCircle}
                                        size='lg'
                                        color={'var(--absolute-zero)'}
                                    />
                                }
                            </IconRadio>
                            {d.label}
                        </ItemList>
                    ))}
                </ListCheckbox>}

                {eventDate.delivery === 'mixed' && <BriteMessage>
                    Eventbrite only allows for online or in person events, you must pick one. Don't worry, on Vibe we will manage the event as mixed
                </BriteMessage>}

                <Row1>
                    <Label>Capacity for Eventbrite tickets<Imp>*</Imp></Label>
                    <Input
                        type='number'
                        value={eventDate.eventBriteCapacity || ''}
                        onChange={(e) => handleBriteCapacity(parseInt(e.currentTarget.value))}
                    />
                </Row1>
                <Row1>
                    <Label>Cost for Eventbrite tickets (USD)<Imp>*</Imp></Label>
                    <CurrencyInput
                        allowDecimals
                        decimalSeparator="."
                        id="input-currency-field"
                        name="input-currency-field-name"
                        prefix="$"
                        value={eventDate.eventBriteFee || ''}
                        onValueChange={(value) => handleBriteCost(value)}
                        step={1}
                        className={"currencyFee"}
                    />
                </Row1>
                <BriteMessage>
                    * The event may not be created in your Event Brite account. This happens when some targeted club belongs to other organization whose admin has an Event Brite account.
                </BriteMessage>
                <BriteMessage>
                    ** Remember to check the event on your Eventbrite account!
                </BriteMessage>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={onHide}>
                    Cancel
                </Button>
                <ButtonSpinner
                    loading={false}
                    handleClick={saveEventbrite}
                    text={'Save'}
                    hasIcon={false}
                />
            </Modal.Footer>
        </Modal>
    )
}

export default PopupEventbrite;

const ListCheckbox = styled.div`
    display: flex;
    gap: 16px;
`;

const BriteMessage = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 3px 0px 3px 0;
    align-items: center;
    font-family: 'Inter';
    text-align: left;
    font-size: 12px;
    color: #808080;
    gap: 8px;
    cursor: pointer;
`;

const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
`;

const Title = styled.span`
    font-family: 'Inter-Bold';
    color: black;
    font-size: 16px;
    text-align: left;
    width: 100%;
    display: block;
`;

const RadioIcon = styled(FontAwesomeIcon)`
    font-size: 14px;
`;

const ItemList = styled.div`
    display: flex;
    justify-content: start;
    padding: 3px 0px 3px 0;
    align-items: center;
    font-family: 'Inter';
    font-size: 14px;
    gap: 8px;
    cursor: pointer;
`;

const Label = styled.div`    
    width: 100%;
    text-align: left;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`;

const Row1 = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: ${props => props.theme.height || '65px'};

    & .currencyFee{
        outline: none;
        width: 100%;
        height: 40px;
        border-radius: 9px;
        border: 0;
        padding: 0 20px;
        font-size: 16px;
        font-family: 'Inter';
        border: 1px solid var(--simple-gray);
    }

`;

const IconRadio = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    border-radius: 12px;
    border: 2px solid #999796;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.iconCheck{
        background-color: white;
        border: 2px solid #2A4CD3;
    }
`;

const Button = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    width: 100%;
    border-radius: 8px;
    border: 1px solid #999796;
    background: #FFF;
    font-family: 'Inter-Bold';
    font-size: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    color: #6B6B6B;

    &.buttonDisabled{
        background: #EEE; 
    }
    &.buttonActive{
        background: var(--t-blue);
        color: white;
    }

    &.buttonDisabled:hover .tooltip{
        display: block;
    }
`;