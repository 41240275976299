import "./styles.scoped.css";
import React, { KeyboardEventHandler, useState, useEffect, useContext } from "react";
import Select from 'react-select';
import { ActionMeta, OnChangeValue } from "react-select";
import ModalSave from './ModalSave';
import CreatableSelect from "react-select/creatable";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER } from "../ClubIconsNavBar";
import moment, { Moment } from "moment";
import { capitalize } from "../../helpers/letterHelper";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { RootState } from "../../store/store";
import { BROWN } from "../SuperCenter/Modalities/Modals/components";
import { ContextSuccess } from "../../Context";
import { ClubCenterWrap } from "../ClubViewInformation";
import styled from "styled-components";
import SearchInput from "../BaseComponents/SearchInput";
import BlankTable from "../BaseComponents/BlankTable";
import IPaymentInfo from "../../interfaces/user/IPaymentINfo";
import useEventsList from "../../hooks/Events/useEventsList";
import { getRole } from "../../hooks/Clubs/useClubsList";
import { EventsResponse } from "@vibe/sdk/dist/interfaces/Events/getEvent";
import ModalCreateEvent from "../Events/ModalCreateEvent";
import useEventsClub from "../../hooks/Events/useEventsClub";
import { Loader } from "../BaseComponents/Loader";
import { getEventLocation } from "../../helpers/locationHelper";
import { formatDateTime } from "../../hooks/Events/useEventsPanel";

toast.configure();


function Index(props: any) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const currentSuccess = useContext(ContextSuccess);

    const userPayment: IPaymentInfo = useSelector((state: RootState) => state.getUser.paymentInfo);
    const userRoles = useSelector((state: RootState) => state.getUser.userRoles);

    const [search, setSearch] = useState<string>('');
    const [showModalEvent, setShowModalEvent] = useState<boolean>(false);

    let { id } = useParams();

    const {
        setClubId,
        events,
        eventsLoading,
        reloadClubEvents,
    } = useEventsClub();

    const handleDelete = (event_id: string) => {
        //@todo
    }

    // const handleCreateEvent = () => {
    //     if (!userPayment.hasActiveSubscription) {
    //         currentSuccess?.setModalPayInfo('Ops! To create new events you need an active subscription.')
    //         return;
    //     }
    //     setShowModalEvent(true);
    // }

    useEffect(() => {
        if (id) setClubId(id);
    }, [id])

    const handleClick = (data: string) => {
        navigate(`../../../../app/event/${data}`)
    }

    const getDelivery = (delivery: 'online' | 'mixed' | 'inPerson') => {
        return delivery === 'mixed' ? 'Online & in person' : (delivery === 'inPerson' ? 'In person' : 'Online');
    }

    return (
        <ClubCenterEvent>
            <Row>
                <SearchInput
                    filteredItem={search}
                    setFilteredItem={setSearch}
                    backgroundColor={'var(--t-blue)'}
                    border={'0px'}
                    placeholder={`Search by event name`}
                />
                {/*(props.isClubAdvisor) && (
                    <Button onClick={handleCreateEvent}>
                        <StyledFontAwesomeIcon
                            size='sm'
                            color='white'
                            icon='plus'
                        />
                        Create Event
                    </Button>
                )*/}
            </Row>
            <Scroll>
                {eventsLoading ? (
                    <CenterRow>
                        <Loader color="white"></Loader>
                    </CenterRow>
                ) : (
                    <BlankTable
                        maxWidth={'100px'}
                        backgroundColor={'var(--t-off-white)'}
                        headers={[
                            'Event Name',
                            'Location',
                            'Start',
                            'Delivery'
                        ]}
                        items={events.map((event: EventsResponse) => ({
                            id: event.event_id,
                            labels: [
                                event.name,
                                event.delivery === 'online' ? 'Online' :
                                    getEventLocation(
                                        event.addressLine1,
                                        event.addressLine2,
                                        event.location,
                                        event.zipcode,
                                        event.countryCode,
                                        event.country,
                                        event.city,
                                        event.state,
                                        event.stateCode
                                    ),
                                `${formatDateTime(event.start)[0]} | ${formatDateTime(event.start)[1]}`,
                                getDelivery(event.delivery),
                            ]
                        }))}
                        onClickRow={handleClick}
                        cursor={'pointer'}
                        actions={['DELETE']}
                        actionsHandlers={{
                            handleDelete,
                        }}
                    />
                )}
            </Scroll>
            {/* <ModalCreateEvent showModal={showModalEvent} setShowModal={setShowModalEvent} reloadEvents={reloadClubEvents}/> */}
        </ClubCenterEvent>
    );
}

export default Index;

const Row = styled.div`
    display: flex;
    width: 100%;
    height: 40px;
    gap: 10px;
`;

const CenterRow = styled(Row)`
    justify-content: center;
    align-items: center;
`;

const Scroll = styled.div`
    display: flex;
    width: 100%;
    overflow-y: scroll;
`;

const ClubCenterEvent = styled(ClubCenterWrap)`
    overflow: hidden;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 200px;
  min-width: 100px;
  height: 100%;
  padding: 10px 10px;
  border-radius: 11px;
  background-color: var(--simple-blue-dark);
  border: 0;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: white;
  gap: 10px;

  cursor: pointer;
`;