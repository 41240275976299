import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import './styles.css'

function CreatePostText({ postText, setPostText, inspAuthor, setInspAuthor, isInsp }: { postText: string, setPostText: any, inspAuthor: string, setInspAuthor: (p: string) => void, isInsp: boolean }) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="containerPostCreate">
        <textarea
          name=""
          id=""
          placeholder={t('label_writeSomething')}
          value={postText}
          maxLength={2200}
          onChange={(e) => {
            const { value } = e.target
            setPostText(value)
          }}
        ></textarea>
        {postText.length > 0 && <div className="CharCounter__wrapper">
          <span className="CharCounter__text">{postText.length}/2200</span>
        </div>}
      </div>
      {isInsp && (
        <div className="containerPostCreate">
          <textarea
            className="containerPostCreateLink-inputText"
            placeholder={t('label_inspAuthor')}
            value={inspAuthor}
            maxLength={2200}
            onChange={(e) => {
              const { value } = e.target
              setInspAuthor(value)
            }}
          />
          {inspAuthor.length > 0 && <div className="CharCounter__wrapper">
            <span className="CharCounter__text">{inspAuthor.length}/2200</span>
          </div>}
        </div>
      )}
    </>
  );
}

export default CreatePostText;