import { useEffect, useState } from "react";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import SelectItem from "../../interfaces/SelectItem";
import { IState } from "@vibe/sdk/dist/interfaces/Roster/Location";

export interface StateOption extends SelectItem { };

const useCountryStates = () => {
  const [states, setStates] = useState<IState[]>([]);
  const [statesReactSelect, setStatesReactSelect] = useState<StateOption[]>([])

  useEffect(() => {
    setStates([]);
  }, []);

  const reloadCountry = (country: string) => {
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Roster.getCountryStates(country)
      })
      .then((results) => {
        if (!Array.isArray(results.body)) {
          return;
        }
        console.log(results.body)
        setStates(results.body);
        setStatesReactSelect(results.body.map((c) => ({
          label: c?.name ?? c.location.split('#')[c.location.split('#').length - 1],
          value: c.location.replaceAll('#','_'),
        })))
      })
  }

  return { 
    states,
    statesReactSelect,
    reloadCountry
  };
}

export default useCountryStates;