import { useMemo, useState } from "react";
import ItemFilterHome, { ItemFilterHook, ItemFilterType, SelectedItemHome } from "../../interfaces/HomeSearch/ItemFilterHome";

interface useItemFilterHomeProps {
    id: number
    type: ItemFilterType
    dropdown?: boolean
}

const useFilterItem = (preItems: useItemFilterHomeProps[]): {
    items: ItemFilterHook[]
    handleChangeBenefits: (p: string[]) => void,
    handleChangeLocations: (p: string[]) => void,
} => {
    const [benefits, setBenefits] = useState<string[]>([]);
    const [locations, setLocations] = useState<string[]>([]);

    const handleChangeBenefits = (be: string[]) => setBenefits(be);
    const handleChangeLocations = (lo: string[]) => setLocations(lo);


    const getItemData = ({type, id, dropdown}: useItemFilterHomeProps): ItemFilterHook => {
        switch (type) {
            case 'BEN':
                return {
                    id,
                    icon: "star", //@todo
                    dropdown: dropdown || true,
                    label: 'Benefits',
                    options: benefits.map((k) => ({
                        label: k,
                        value: k,
                    }))
                };
            case 'DEL':
                return {
                    id,
                    icon: "laptop", //@todo
                    dropdown: dropdown || true,
                    label: 'Delivery',
                    options: [
                        {
                            label: 'Online',
                            value: 'Online'
                        },
                        {
                            label: 'In Person',
                            value: 'In Person'
                        },
                        {
                            label: 'Combined',
                            value: 'Combined'
                        },
                    ]
                }
            case 'LOC':
                return {
                    id,
                    icon: "thumbtack",
                    dropdown: dropdown || true,
                    label: 'Location',
                    options: locations.map((k) => ({
                        label: k,
                        value: k,
                    }))
                }

            case 'FEE':
                return {
                    id,
                    icon: "wallet",
                    dropdown: dropdown || true,
                    label: 'Type',
                    options: [
                        {
                            label: 'Free',
                            value: 'Free'
                        },
                        {
                            label: 'Paid',
                            value: 'Paid'
                        },
                    ]
                }

            default:
                return {
                    id: 0,
                    icon: 'star',
                    dropdown: false,
                    label: '',
                    options: []
                };
        }
    }

    const items = useMemo(() => preItems.map(getItemData), [preItems, benefits, locations])

    return {
        items,
        handleChangeBenefits,
        handleChangeLocations
    }
}

export default useFilterItem;