import { faClone, faGlobe, faPen, faTrash, faLink, faChevronDown, faEllipsis, faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { Loader } from "../../components/BaseComponents/Loader";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { IconClicker } from "../Home/HeaderHome/styles";
import { cleanHTML } from "../../helpers/letterHelper";
import { Link } from "react-router-dom";
import StatusBudged, { StatusType } from "./Healers/StatusBudged";
import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

export type actionsType = 'DELETE' | 'UPDATE' | 'COPY' | 'APPROVE' | 'ACTIVATE' | 'DEACTIVATE' | 'RESENT' | 'CONTRIBUTOR';

export interface ActionTable {
    action: actionsType
    enabled: boolean
}

export interface actionsHandlersType {
    handleDelete?: (data: string) => void
    handleUpdate?: (data: string) => void
    handleCopy?: (data: string) => void
    handleApprove?: (data: string) => void
    handleActivate?: (data: string) => void
    handleDeactivate?: (data: string) => void
    handleResent?: (data: string) => void
    handleContributor?: (data: string) => void
}

export interface itemType {
    id: string
    labels: string[],
    all?: any,
    icon?: IconProp,
    link?: string,
    noShowIcon?: boolean,
    showIconIndex?: boolean,
    linkPdf?: string
    accordionInfo?: string[]
}

export interface SpecialColumn {
    handle: (data: string, link?: string) => void
    idx: number
}

export interface StatusInfoProps {
    statusType?: StatusType
    hasStatus?: boolean
    statusIndex?: number
}

export interface AccordionInfoProps {
    accordionHeaders?: string[]
    hasAccordion?: boolean
    accordionsActive?: string[]
    setAccordionsActive: (active: string[]) => void
}

export interface ActionsLabel {
    enabled: boolean
    onClick: (id: string) => void
    label: string
}

interface BlankTableProps {
    headers: string[],
    items: itemType[],
    actions?: actionsType[],
    actionsHandlers?: actionsHandlersType,
    maxWidth?: string,
    maxWidthField?: string,
    backgroundColor?: string,
    onClickRow?: (data: string) => void;
    cursor?: string;
    actionsConditions?: Partial<Record<actionsType, (id: string) => boolean>>;
    iconsHide?: string[];
    idActive?: string
    specialColumn?: SpecialColumn
    specialColumnFirst?: SpecialColumn
    headerColored?: boolean
    border?: boolean
    statusInfo?: StatusInfoProps
    accordionInfo?: AccordionInfoProps
    idLoader?: string,
    tooltip?: number[],
    linkLine?: number[],
    actionsWrapped?: boolean
}

const BlankTable = ({
    headers,
    items,
    actions = [],
    actionsHandlers,
    maxWidth,
    maxWidthField,
    backgroundColor = 'white',
    onClickRow = (data: string) => { },
    cursor = 'default',
    actionsConditions,
    iconsHide = [],
    idActive = '',
    specialColumn,
    specialColumnFirst,
    headerColored = false,
    border = false,
    statusInfo,
    accordionInfo,
    idLoader = '',
    tooltip = [],
    linkLine = [],
    actionsWrapped = false,
}: BlankTableProps) => {

    const [openActionsModal, setOpenActionsModal] = useState<boolean>(false);
    const [selected, setSelected] = useState<string>('');

    const getActions = (id: string, actions: ActionTable[]): { actionsDisplay: any[], actionsLabel: ActionsLabel[] } => {
        if (actions.length > 0) {
            const actionsDisplay: any = [];
            const actionsLabel: ActionsLabel[] = [];
            actions.forEach((action: ActionTable) => {
                let onClick = (data: string) => { };
                switch (action.action) {
                    case 'DELETE':
                        if (actionsHandlers?.handleDelete) onClick = actionsHandlers.handleDelete;
                        actionsLabel.push({
                            label: 'Delete',
                            onClick: action.enabled ? () => onClick(id) : () => { },
                            enabled: action.enabled
                        })
                        actionsDisplay.push(
                            // <ContainerTooltip>
                            //     <Tooltip className="tooltip">Delete item</Tooltip>
                            <TableIcon
                                icon={faTrash}
                                size='lg'
                                color={action.enabled ? 'red' : 'var(--simple-gray)'}
                                onClick={action.enabled ? () => onClick(id) : () => { }}
                                enabled={action.enabled}
                            />
                            // </ContainerTooltip>)
                        )
                        break;

                    case 'UPDATE':
                        if (actionsHandlers?.handleUpdate) onClick = actionsHandlers.handleUpdate;
                        actionsLabel.push({
                            label: 'Update',
                            onClick: action.enabled ? () => onClick(id) : () => { },
                            enabled: action.enabled
                        })
                        actionsDisplay.push(
                            // <ContainerTooltip>
                            //     <Tooltip className="tooltip">Update item</Tooltip>
                            <TableIcon
                                icon={faPen}
                                size='lg'
                                color={action.enabled ? 'var(--t-blue)' : 'var(--simple-gray)'}
                                onClick={action.enabled ? () => onClick(id) : () => { }}
                                enabled={action.enabled}
                            />
                            // </ContainerTooltip>
                        )
                        break;

                    case 'COPY':
                        if (actionsHandlers?.handleCopy) onClick = actionsHandlers.handleCopy;
                        actionsLabel.push({
                            label: 'Copy to Clipboard',
                            onClick: action.enabled ? () => onClick(id) : () => { },
                            enabled: action.enabled
                        })
                        actionsDisplay.push(
                            // <ContainerTooltip>
                            //     <Tooltip className="tooltip">Copy item</Tooltip>
                            <TableIcon
                                icon={faClone}
                                size='lg'
                                color={action.enabled ? 'black' : 'var(--simple-gray)'}
                                onClick={action.enabled ? () => onClick(id) : () => { }}
                                enabled={action.enabled}
                            />
                            // </ContainerTooltip>
                        )
                        break;

                    case 'APPROVE':
                        if (actionsHandlers?.handleApprove) onClick = actionsHandlers.handleApprove;
                        actionsLabel.push({
                            label: 'Approve Healer',
                            onClick: action.enabled ? () => onClick(id) : () => { },
                            enabled: action.enabled
                        })
                        actionsDisplay.push(
                            id === idActive ?
                                <Loader color={"white"} />
                                :
                                <ContainerTooltip>
                                    <Tooltip className="tooltip">Approve Healer</Tooltip>
                                    <TableIcon
                                        icon='user-check'
                                        size='lg'
                                        color={action.enabled ? 'black' : 'var(--simple-gray)'}
                                        onClick={action.enabled ? () => onClick(id) : () => { }}
                                        enabled={action.enabled}
                                    />
                                </ContainerTooltip>)
                        break;

                    case 'CONTRIBUTOR':
                        if (actionsHandlers?.handleContributor) onClick = actionsHandlers.handleContributor;
                        actionsLabel.push({
                            label: 'Manage Contributor',
                            onClick: action.enabled ? () => onClick(id) : () => { },
                            enabled: action.enabled
                        })
                        actionsDisplay.push(
                            id === idActive ?
                                <Loader color={"white"} />
                                :
                                <ContainerTooltip>
                                    <Tooltip className="tooltip">Manage Contributor</Tooltip>
                                    <TableIcon
                                        icon='shield-halved'
                                        size='lg'
                                        color={action.enabled ? 'black' : 'var(--simple-gray)'}
                                        onClick={action.enabled ? () => onClick(id) : () => { }}
                                        enabled={action.enabled}
                                    />
                                </ContainerTooltip>)
                        break;

                    case 'ACTIVATE':
                        if (actionsConditions && 'ACTIVATE' in actionsConditions && !actionsConditions.ACTIVATE?.(id)) break;
                        if (actionsHandlers?.handleActivate) onClick = actionsHandlers.handleActivate;
                        actionsLabel.push({
                            label: 'Activate',
                            onClick: action.enabled ? () => onClick(id) : () => { },
                            enabled: action.enabled
                        })
                        actionsDisplay.push(
                            // <ContainerTooltip>
                            //     <Tooltip className="tooltip">Activate item</Tooltip>
                            <TableIcon
                                icon='circle-check'
                                size='lg'
                                color={action.enabled ? 'var(--accept-green)' : 'var(--simple-gray)'}
                                onClick={action.enabled ? () => onClick(id) : () => { }}
                                enabled={action.enabled}
                            />
                            // </ContainerTooltip>
                        )
                        break;

                    case 'DEACTIVATE':
                        if (actionsConditions && 'DEACTIVATE' in actionsConditions && !actionsConditions.DEACTIVATE?.(id)) break;
                        if (actionsHandlers?.handleDeactivate) onClick = actionsHandlers.handleDeactivate;
                        actionsLabel.push({
                            label: 'Deactivate',
                            onClick: action.enabled ? () => onClick(id) : () => { },
                            enabled: action.enabled
                        })
                        actionsDisplay.push(
                            // <ContainerTooltip>
                            //     <Tooltip className="tooltip">Deactivate item</Tooltip>
                            <TableIcon
                                icon='circle-xmark'
                                size='lg'
                                color={action.enabled ? 'red' : 'var(--simple-gray)'}
                                onClick={action.enabled ? () => onClick(id) : () => { }}
                                enabled={action.enabled}
                            />
                            // </ContainerTooltip>
                        )
                        break;

                    case 'RESENT':
                        if (actionsHandlers?.handleResent) onClick = actionsHandlers.handleResent;
                        actionsLabel.push({
                            label: 'Resend invite',
                            onClick: action.enabled ? () => onClick(id) : () => { },
                            enabled: action.enabled
                        })
                        actionsDisplay.push(
                            id === idLoader ?
                                <Loader color={"white"} />
                                :
                                // <ContainerTooltip>
                                //     <Tooltip className="tooltip">Resend invite</Tooltip>
                                <TableIcon
                                    icon='paper-plane'
                                    size='lg'
                                    color={action.enabled ? 'var(--t-blue)' : 'var(--simple-gray)'}
                                    onClick={action.enabled ? () => onClick(id) : () => { }}
                                    enabled={action.enabled}
                                />
                            // </ContainerTooltip>
                        )
                        break;

                    default:
                        break;
                }
            })

            return {
                actionsDisplay,
                actionsLabel
            };
        }
        else {
            return {
                actionsDisplay: [],
                actionsLabel: [],
            }
        }
    }

    const getIconSpecial = (item: itemType): IconProp => {
        if (item.icon) return item.icon;
        return faGlobe
    }

    const getActionsHidden = (item: itemType) => {
        if (item && item.all && item.all.status === 'ACTIVE') {
            const act = actions.map(action => ({
                action: action,
                enabled: action === 'APPROVE' ? false : true
            }))
            return <Td>
                {getActions(item.id, act).actionsDisplay}
            </Td>
        }

        const act = actions.map(action => ({
            action: action,
            enabled: action === 'CONTRIBUTOR' ? false : true
        }))
        return <Td> {getActions(item.id, act).actionsDisplay} </Td>
    }

    const getActionsHiddenWrapped = (item: itemType) => {
        if (item && item.all && item.all.status === 'ACTIVE') {
            const act = actions.map(action => ({
                action: action,
                enabled: action === 'APPROVE' ? false : true
            }))
            return getActions(item.id, act).actionsLabel
        }

        const act = actions.map(action => ({
            action: action,
            enabled: action === 'CONTRIBUTOR' ? false : true
        }))
        return getActions(item.id, act).actionsLabel
    }

    const handleActiveAccordion = (id: string) => {
        if (accordionInfo && accordionInfo?.accordionsActive?.includes(id)) {
            accordionInfo.setAccordionsActive(accordionInfo.accordionsActive.filter(ac => ac !== id));
            return;
        }

        accordionInfo?.setAccordionsActive([...(accordionInfo?.accordionsActive || []), id]);
    }

    return (
        <TableWrapper border={border}>
            <TableList backgroundColor={backgroundColor} cursor={cursor}>
                <Tr headerColored={headerColored}>
                    {headers.map(header => (
                        <Th>{header}</Th>
                    ))}
                    {(actions.length > 0) && (
                        actionsWrapped ? <Th></Th> : <Th>Actions</Th>)}
                </Tr>
                {items.map((item: itemType) => (
                    <>
                        <Tr headerColored={false}>
                            {item.labels.map((label: string, idx: number) => {
                                if (specialColumn && specialColumn.idx === idx) {
                                    return <Td
                                        maxWidth={maxWidth}
                                        maxWidthField={maxWidthField}
                                        onClick={() => onClickRow(item.id)}
                                        isClickable={cursor !== 'default'}
                                    >
                                        {!item.noShowIcon ?
                                            item.link &&
                                            <IconClickerTable
                                                icon={getIconSpecial(item)}
                                                size='sm'
                                                color="var(--absolute-zero)"
                                                onClick={() => specialColumn.handle(item.id, item.link)}
                                            /> : <></>}
                                        {label}
                                    </Td>
                                }

                                if (tooltip) {
                                    if (tooltip.includes(idx)) {
                                        return <TdTooltip
                                            maxWidth={maxWidth}
                                            maxWidthField={maxWidthField}
                                            onClick={() => onClickRow(item.id)}
                                            isClickable={cursor !== 'default'}
                                        >
                                            <ContainerTooltip>
                                                <TooltipTop className="tooltip">{label}</TooltipTop>
                                                <PToolTip>{label}</PToolTip>
                                            </ContainerTooltip>
                                        </TdTooltip>
                                    }
                                }

                                if (idx === 0) {
                                    return <Td
                                        maxWidth={maxWidth}
                                        maxWidthField={maxWidthField}
                                        isClickable={cursor !== 'default'}
                                    >
                                        <ContentTD>
                                            {accordionInfo?.hasAccordion &&
                                                <IconClicker
                                                    icon={faChevronDown}
                                                    size='sm'
                                                    color="#333"
                                                    onClick={() => handleActiveAccordion(item.id)}
                                                />
                                            }
                                            {(item.showIconIndex && specialColumnFirst && specialColumnFirst.idx === idx && item.linkPdf) ?
                                                <IconClickerTable
                                                    icon={faLink}
                                                    size='sm'
                                                    color="var(--absolute-zero)"
                                                    onClick={() => specialColumnFirst.handle(item.labels[0] ?? 'Service', item.linkPdf)}
                                                />
                                                : <></>}
                                            <div className={linkLine.includes(idx) ? 'lineLink' : ''} onClick={() => onClickRow(item.id)}>{cleanHTML(label || '')}</div>
                                        </ContentTD>
                                    </Td>

                                }

                                if (statusInfo && statusInfo.hasStatus && idx === statusInfo.statusIndex) {
                                    return <Td
                                        maxWidth={maxWidth}
                                        maxWidthField={maxWidthField}
                                        onClick={() => onClickRow(item.id)}
                                        isClickable={cursor !== 'default'}
                                    >
                                        <StatusBudged status={statusInfo.statusType || item.all.status || 'INACTIVE'} />
                                    </Td>
                                }

                                return <Td
                                    maxWidth={maxWidth}
                                    maxWidthField={maxWidthField}
                                    dangerouslySetInnerHTML={{ __html: cleanHTML(label || '') }}
                                />
                            })}
                            {(actions.length > 0) && (
                                actionsWrapped ? (
                                    <TdWrappedActions>
                                        <TableIcon
                                            icon={faEllipsisVertical}
                                            size='lg'
                                            color='var(--t-blue)'
                                            onClick={() => {
                                                setOpenActionsModal(true);
                                                setSelected(item.id)
                                            }}
                                            enabled={true}
                                        />
                                        {(openActionsModal && selected === item.id) &&
                                            <OutsideClickHandler onOutsideClick={() => {
                                                setOpenActionsModal(false);
                                                setSelected('')
                                            }}
                                                key={item.id}>
                                                <Items key={item.id}>
                                                    {getActionsHiddenWrapped(item).map((action: ActionsLabel, index) => (
                                                        <Item
                                                            onClick={() => action.onClick(item.id)}
                                                            key={index}
                                                            enabled={action.enabled}
                                                        >
                                                            {action.label}
                                                        </Item>
                                                    ))}
                                                </Items>
                                            </OutsideClickHandler>
                                        }
                                    </TdWrappedActions>
                                ) : getActionsHidden(item))}
                        </Tr>
                        {(accordionInfo?.hasAccordion && accordionInfo.accordionsActive?.includes(item.id)) && (
                            <Tr headerColored={true}>
                                <Td_Accordion>
                                    {accordionInfo.accordionHeaders?.map(ac => (<Title>{ac}</Title>))}
                                </Td_Accordion>
                                <Td_Accordion>
                                    {item.accordionInfo?.map(ac => (<Info>{ac}</Info>))}
                                </Td_Accordion>
                                <Td_Accordion></Td_Accordion>
                                <Td_Accordion></Td_Accordion>
                            </Tr>
                        )}
                    </>
                ))}
            </TableList>
        </TableWrapper>
    )
}

const Item = styled.li`
    background-color: white;
    padding: 10px 20px;
    text-align: left;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    opacity: ${({ enabled }: { enabled: boolean }) => enabled ? '1' : '0.5'};
    cursor: ${({ enabled }: { enabled: boolean }) => enabled ? 'pointer' : 'default'};

    &:last-child {
        border-radius: 0 0 10px 10px;
        -webkit-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        -moz-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
    }

    &:hover {
        background-color: ${({ enabled }: { enabled: boolean }) => enabled ? '#e8e8e8' : 'white'};
    }
`;

const Items = styled.ul`
    width: 235px;
    position: absolute;
    top: 100%;
    z-index: 10;
    overflow-y: scroll;
    max-height: 200px;
    border-radius: 12px;
    right: 5px;
    box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
    background-color: white;
`;

const ContentTD = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    gap: 5px;
    vertical-align: middle;
    align-items: center;
    justify-content: flex-start;
`;

const TdTooltip = styled.td`
    vertical-align: middle;
    height: 40px !important;
    border-bottom: 1px solid var(--simple-gray-2);
    font-family: 'Inter';
    font-size: 14px; 
    padding: 0 15px; 
    text-align: left;
    max-width:${(props: TdProps) => props.maxWidthField ? props.maxWidthField : (props.maxWidth ? props.maxWidth : '500px')};
    cursor: ${(props: TdProps) => props.isClickable ? 'pointer' : 'default'};
`

const PToolTip = styled.div`
    vertical-align: middle;
    font-family: 'Inter';
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
`

const Tooltip = styled.p`
    position: absolute;
    background-color: var(--t-blue);
    color: white;
    font-size: 12px;
    padding: 9px 12px;
    border-radius: 9px;
    right: -25px;
    bottom: 30%;
    display: none;
    z-index: 100000;
`

const TooltipTop = styled(Tooltip)`
    left: 0;
    top: -40px;
    width: max-content;
    height: fit-content;
    &::before{
        content: "";
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-top: 6px solid var(--simple-blue-dark-2);
        border-bottom: 6px solid transparent;
        border-right: 6px solid transparent;
        position: absolute;
        left: 45%;
        top: 30px;
    }
`

const ContainerTooltip = styled.div`
    display: flex;
    cursor: pointer;
    position: relative;
    overflow: visible;
    &:hover p{
        display: block;
    }
`;

const TableIcon = styled(FontAwesomeIcon)`
    font-size: 18px;
    cursor: ${({ enabled }: { enabled: boolean }) => enabled ? 'pointer' : 'default'};
`;

interface TableListProps {
    backgroundColor: string
    cursor: string
}

const TableList = styled.table`
    width: 100%;
    background-color: ${(props: TableListProps) => props.backgroundColor};
    border-radius: 8px;
    overflow: scroll;
    cursor: ${(props: TableListProps) => props.cursor};
`

const TableWrapper = styled.div`
    width: 100%;
    border: ${({ border }: { border: boolean }) => border ? '1px solid var(--simple-gray)' : '0px'};
    overflow: scroll;
    border-radius: 8px;
`;

const Tr = styled.tr`
    background-color: ${({ headerColored }: { headerColored: boolean }) => headerColored ? '#F0F3F5' : 'transparent'};
    border-bottom: ${({ headerColored }: { headerColored: boolean }) => headerColored ? 'none' : '1px solid var(--simple-gray-2)'};
    & td:last-child,
    & th:last-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    & td:last-child{
        justify-content: flex-start;
        gap: 20px;
        overflow: visible;
    }
`

const Th = styled.th`
    vertical-align: middle;
    height: 40px;
    font-family: 'Inter-bold';
    width: 500;
    font-size: 14px;
    padding: 0 15px;
    text-align: left;
`

interface TdProps {
    maxWidth?: string;
    maxWidthField?: string;
    isClickable?: boolean;
}

const Td = styled.td`
    vertical-align: middle;
    height: 40px !important;
    border-bottom: 1px solid var(--simple-gray-2);
    font-family: 'Inter';
    font-size: 14px; 
    padding: 0 15px; 
    text-align: left;
    max-width:${(props: TdProps) => props.maxWidthField ? props.maxWidthField : (props.maxWidth ? props.maxWidth : '500px')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: ${(props: TdProps) => props.isClickable ? 'pointer' : 'default'};
`

const TdWrappedActions = styled(Td)`
    position: relative;
    width: 35px !important;
`;

const Td_Accordion = styled.td`
    vertical-align: middle;
    height: 100px !important;
    border-bottom: 1px solid var(--simple-gray-2);
    font-family: 'Inter';
    font-size: 14px; 
    padding: 0 15px; 
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width:${(props: TdProps) => props.maxWidthField ? props.maxWidthField : (props.maxWidth ? props.maxWidth : '500px')};
`;

const Info = styled.div`
    color: var(--simple-gray);
    margin-bottom: 10px;
`;

const Title = styled.div`
    color: #333;
    margin-bottom: 10px;
`;

const IconClickerTable = styled(IconClicker)`
    margin-right: 10px;
`;

export default BlankTable;