import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const NotFound = (props: any) => {

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(()=>{
        /*if(location.pathname.includes('superadmin') && !props.isLoggedIn){
            navigate('/');
        }*/
    }, [])

    return (
        <>
            <h1 style={{ color: 'white' }}>Not Found</h1>
            <nav>
                <Link to="/" style={{ color: 'white' }} >Home</Link>
            </nav>
        </>
    )
};

export default NotFound;
