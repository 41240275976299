import { ContentResults, GridResultsCenter, GridSearchContent, SearchInputResultsHome, SearchResultsHome } from './styles';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { IconClicker } from '../HeaderHome/styles';
import FooterHome from './FooterHome';
import ContentResultsHome from './ContentResultsHome/Index';
import CarrouselInspiration from './CarrouselInspiration';
import HomeSearchProps from '../../../interfaces/HomeSearch/HomeSearchProps';
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { getDataSDK, getDataSDKResponse } from '../../../helpers/backendHelper'
import { IHasAcceptedDisclaimerResponse } from '@vibe/sdk/dist/interfaces/Connections/connections'
import ModalSearchWarning from '../../BaseComponents/ModalSearchWarning'

const ResultsHome = ({
  searchPreview,
  search,
  setSearch,
  onKeyDown,
  goResults
}: HomeSearchProps) => {

  const [showModalSearchWarning, setShowModalSearchWarning] = useState<boolean>(false);

  const userInfo = useSelector((state: RootState) => state.getUser.userInfo);

  const [ip, setIp] = useState<string>('');
  const [hasAccepted, setHasAccepted] = useState<boolean>(false);

  useEffect(() => {
      fetch('https://api.ipify.org/?format=json')
          .then(res => res.json())
          .then(({ip}) => {
              setIp(ip);
          })
          .catch(err => {
              console.log(err);
          })
  }, [])

  useEffect(() => {
      getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
          return dataSDK.sdk.Connections.hasAcceptedDisclaimer(ip, userInfo?.username);
      })
      .then(({body}) => {
          const {response} = body as IHasAcceptedDisclaimerResponse;
          setHasAccepted(response);
      })
      .catch((error) => {
         console.log("Error checking if disclaimer was accepted", error);
      })
  }, [ip, userInfo])

  const onSearchClick = () => {
    getDataSDK()
    .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Connections.hasAcceptedDisclaimer(ip, userInfo?.username);
    })
    .then(({body}) => {
        const {response} = body as IHasAcceptedDisclaimerResponse;
        setShowModalSearchWarning(!response)
    })
    .catch((error) => {
       console.log("Error checking if disclaimer was accepted", error);
    })
}


  return (
    <ContentResults>
      <SearchResultsHome>
        <IconClicker
          icon={faSearch}
          size='lg'
          color={"black"}
          onClick={goResults}
        />
        <SearchInputResultsHome
          type="text"
          placeholder="Search & Raise Your Vibration..."
          value={searchPreview}
          onChange={(e) => setSearch(e.currentTarget.value)}
          onClick={onSearchClick}
          onKeyDown={(e) => onKeyDown(e)}
        />
      </SearchResultsHome>

      <GridSearchContent>
        <GridResultsCenter>

          <ContentResultsHome search={search} />
          <CarrouselInspiration search={search} />

        </GridResultsCenter>

        <FooterHome />

      </GridSearchContent>
      <ModalSearchWarning
                showModal={showModalSearchWarning}
                setShowModal={setShowModalSearchWarning}
                // home={true}
      />
    </ContentResults>
  )
}

export default ResultsHome;