import React, { useState, useEffect, useContext } from 'react'
import { BaseFieldState, getFieldState } from '../../BaseComponents/utils';
import useActiveCountry, { CountryOption } from '../../../hooks/Locations/useActiveCountry';
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { WHITE } from "../../ClubIconsNavBar";
import Modal from 'react-bootstrap/Modal';
import ModalBaseProps from "../../../interfaces/modals/ModalBaseProps";
import { BaseInput, CancelButton, ClickableIcon, Row, SaveButton }
    from "../../SuperCenter/SuperAdminComponents/basicForSACenter";
import { Row1, Imp } from "../../../containers/Website/Form"
import styled from 'styled-components';
import SelectorCountry from '../../BaseComponents/Location/SelectorCountry';
import { DropdownIndicator, customStylesRSMultiWhite } from "../../../helpers/selectHelper";
import useModalitiesSA from "../../../hooks/Superadmin/Modalities/useModalitiesSA";
import ErrorField from '../../BaseComponents/ErrorField';
import { getDataSDK, getDataSDKResponse } from '../../../helpers/backendHelper';
import { ContextSuccess } from '../../../Context';
import { SelectMultiWrapper } from '../../BaseComponents/SelectMultiWrapper';
import { Loader } from '../../BaseComponents/Loader';
import TextEditor from '../../BaseComponents/TextEditor';
import SelectorState from '../../BaseComponents/Location/SelectorState';
import SelectorCity from '../../BaseComponents/Location/SelectorCity';
import { StateOption } from '../../../hooks/Locations/useActiveState';
import { CityOption } from '../../../hooks/Locations/useStateCities';
export interface ModalityOptions<T> {
    value: T;
    label: string;
    __isNew__: boolean;
}

interface ModalJoinProps extends ModalBaseProps {
}

const ModalJoin = ({
    show,
    onHide,
}: ModalJoinProps) => {

    const [ModalityOptions, setModalityOptions] = useState<ModalityOptions<string>[]>([]);
    const [ModalitySelected, setModalitySelected] = useState<ModalityOptions<string>[]>([]);
    const { modalitiesPages, reloadModalities } = useModalitiesSA({
        pageSizeProp: 1000,
        searchName: '',
    });
    const currentSuccess = useContext(ContextSuccess);

    const [firstName, setFirstName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [lastName, setLastName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [ModalityID, setModalityID] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [country, setCountry] = useState<BaseFieldState<CountryOption>>(getFieldState({ label: '', value: '' }));
    const [state, setState] = useState<BaseFieldState<StateOption>>(getFieldState({ label: '', value: '' }));
    const [city, setCity] = useState<BaseFieldState<CityOption>>(getFieldState({ label: '', value: '' }));
    const [zipCode, setZipCode] = useState<BaseFieldState<string>>(getFieldState(''));
    const [email, setEmail] = useState<BaseFieldState<string>>(getFieldState(''));
    const [message, setMessage] = useState<BaseFieldState<string>>(getFieldState(''));

    const [searchCountry, setSearchCountry] = useState('');
    const [searchState, setSearchState] = useState('');
    const [searchCity, setSearchCity] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setModalityOptions(modalitiesPages[0].map((b) => ({
            label: b.name,
            value: b.id,
            __isNew__: false,
        })))
    }, [modalitiesPages])

    const onHideClean = () => {
        cleanForm();
        onHide();
    }

    const onChangeModality = (updatedList: ModalityOptions<string>[]) => {
        setModalityID({
            ...ModalityID,
            value: updatedList.map(item => item.value),
        })
        setModalitySelected(updatedList);
    }

    const checkForm = () => {
        let hasErrors = false;

        if (!firstName.value || firstName.value.length === 0) {
            hasErrors = true;
            setFirstName({
                ...firstName,
                error: 'First Name is required',
                showError: true,
            })
        } else {
            setFirstName({
                ...firstName,
                error: '',
                showError: false,
            })
        }

        if (!lastName.value || lastName.value.length === 0) {
            hasErrors = true;
            setLastName({
                ...lastName,
                error: 'Last Name is required',
                showError: true,
            })
        } else {
            setLastName({
                ...lastName,
                error: '',
                showError: false,
            })
        }

        if (!ModalityID.value || ModalityID.value.length === 0) {
            hasErrors = true;
            setModalityID({
                ...ModalityID,
                error: 'At least one modality is required',
                showError: true,
            })
        } else {
            setModalityID({
                ...ModalityID,
                error: '',
                showError: false,
            })
        }

        if (!country.value || country.value.value.length === 0) {
            hasErrors = true;
            setCountry({
                ...country,
                error: 'Country is required',
                showError: true,
            })
        } else {
            setCountry({
                ...country,
                error: '',
                showError: false,
            })
        }

        if (!city.value || city.value.value.length === 0) {
            hasErrors = true;
            setCity({
                ...city,
                error: 'City is required',
                showError: true,
            })
        } else {
            setCity({
                ...city,
                error: '',
                showError: false,
            })
        }

        if (!state.value || state.value.value.length === 0) {
            hasErrors = true;
            setState({
                ...state,
                error: 'City is required',
                showError: true,
            })
        } else {
            setState({
                ...state,
                error: '',
                showError: false,
            })
        }

        if (!zipCode.value || zipCode.value.length === 0) {
            hasErrors = true;
            setZipCode({
                ...zipCode,
                error: 'Zipcode is required',
                showError: true,
            })
        } else if (zipCode.value.length < 5 || isNaN(+zipCode.value)) {
            hasErrors = true;
            setZipCode({
                ...zipCode,
                error: 'Zipcode is not in the right format',
                showError: true,
            })
        } else {
            setZipCode({
                ...zipCode,
                error: '',
                showError: false,
            })
        }

        if (!email.value || email.value.length === 0) {
            hasErrors = true;
            setEmail({
                ...email,
                error: 'Email is required',
                showError: true,
            })
        } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email.value)) {
            hasErrors = true;
            setEmail({
                ...email,
                error: 'Email is not in the right format',
                showError: true,
            })
        } else {
            setEmail({
                ...email,
                error: '',
                showError: false,
            })
        }

        return hasErrors;
    }

    const cleanForm = () => {
        setFirstName(getFieldState(''));
        setLastName(getFieldState(''));
        setModalityID(getFieldState([]));
        setModalitySelected([]);
        setCountry(getFieldState({ label: '', value: '' }));
        setCity(getFieldState({ label: '', value: '' }));
        setState(getFieldState({ label: '', value: '' }));
        setSearchCountry('');
        setSearchState('');
        setSearchCity('');
        setZipCode(getFieldState(''));
        setEmail(getFieldState(''));
        setMessage(getFieldState(''));
    }

    const onSubmit = () => {
        if (checkForm() || loading) return;
        setLoading(true);
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.AccessManager.joinWishlistForm({
                    firstName: firstName.value!,
                    lastName: lastName.value!,
                    modality: ModalityID.value!
                        .map((id) => ({
                            id,
                            name: ModalityOptions.find((m) => m.value === id)?.label ?? '',
                        })),
                    countryCode: country.value!.label,
                    stateCode: state.value?.value!,
                    cityCode: city.value?.value!,
                    zipCode: zipCode.value!,
                    email: email.value!,
                    message: message.value
                })
            })
            .then((response) => {
                if (response.statusCode === 200) {
                    currentSuccess?.setSuccessData(true, true, "Info Saved!", "");
                    cleanForm();
                    onHide();
                } else {
                    currentSuccess?.setSuccessData(false, true, "Error saving", "");
                }
            })
            .catch(() => {
                currentSuccess?.setSuccessData(false, true, "Error saving", "");
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            <Modal className="modalRefer" show={show} onHide={onHide}>
                <Modal.Header>
                    <Modal.Title>I'm raising my Vibration and ready to join Vibe.</Modal.Title>
                    <ClickableIcon
                        icon={faTimes}
                        size='xl'
                        color={'var(--black)'}
                        className="icon-times"
                        onClick={onHideClean}
                    />
                </Modal.Header>
                <Modal.Body>
                    <FlexRefer>
                        <Inputs>
                            <Row2>
                                <Label>First name<Imp>*</Imp></Label>
                                <Input value={firstName.value} onChange={(e) => setFirstName({ ...firstName, value: e.currentTarget.value })} />
                                {(firstName.showError) && (<ErrorField error={firstName.error} />)}
                            </Row2>
                            <Row2>
                                <Label>Last name<Imp>*</Imp></Label>
                                <Input value={lastName.value} onChange={(e) => setLastName({ ...lastName, value: e.currentTarget.value })} />
                                {(lastName.showError) && (<ErrorField error={lastName.error} />)}
                            </Row2>
                        </Inputs>
                        <Inputs>
                            <Row1>
                                <Label>Modalities / services<Imp>*</Imp></Label>
                                <WrapperCountrySelect>
                                    <SelectMultiWrapper
                                        options={ModalityOptions}
                                        styles={customStylesRSMultiWhite()}
                                        components={{
                                            DropdownIndicator
                                        }}
                                        value={ModalitySelected || []}
                                        onChangeValue={onChangeModality}
                                        placeholder="Select Multiple Modalities"
                                        showError={false}
                                        error=''
                                    />
                                    {(ModalityID.showError) && (<ErrorField error={ModalityID.error} />)}
                                </WrapperCountrySelect>
                            </Row1>
                        </Inputs>
                        <Inputs>
                            <Row2>
                                <Label>Country<Imp>*</Imp></Label>
                                <WrapperCountrySelect>
                                    <SelectorCountry
                                        country={country}
                                        setCountry={setCountry}
                                        searchCountry={searchCountry}
                                        setSearchCountry={setSearchCountry}
                                    />
                                </WrapperCountrySelect>
                                {(country.showError) && (<ErrorField error={country.error} />)}
                            </Row2>
                            <Row2>
                                <Label>State / Region<Imp>*</Imp></Label>
                                <SelectorState
                                    country={country.value?.value ?? ''}
                                    state={state}
                                    setState={setState}
                                    searchState={searchState}
                                    setSearchState={setSearchState}
                                />
                                {(state.showError) && (<ErrorField error={state.error} />)}
                            </Row2>
                        </Inputs>
                        <Inputs>
                            <Row2>
                                <Label>City<Imp>*</Imp></Label>
                                <SelectorCity
                                    state={state.value?.value}
                                    city={city}
                                    setCity={setCity}
                                    searchCity={searchCity}
                                    setSearchCity={setSearchCity}
                                />
                                {(city.showError) && (<ErrorField error={city.error} />)}
                            </Row2>
                            <Row2>
                                <Label>Zip / Postal Code<Imp>*</Imp></Label>
                                <Input value={zipCode.value} onChange={(e) => setZipCode({ ...zipCode, value: e.currentTarget.value })} />
                                {(zipCode.showError) && (<ErrorField error={zipCode.error} />)}
                            </Row2>
                        </Inputs>
                        <Inputs>
                            <Row1>
                                <Label>Email<Imp>*</Imp></Label>
                                <Input value={email.value} onChange={(e) => setEmail({ ...email, value: e.currentTarget.value })} />
                                {(email.showError) && (<ErrorField error={email.error} />)}
                            </Row1>
                        </Inputs>
                        <Inputs>
                            <Row1>
                                <Label>Message (optional)</Label>
                                <TextEditor
                                    value={message.value || ''}
                                    setValue={(e: string) => setMessage({ ...message, value: e })}
                                    charLimit={1000}
                                    placeholder={"Write a personal message to Vibe..."}
                                    minHeight={90}
                                />
                            </Row1>
                        </Inputs>
                    </FlexRefer>
                </Modal.Body>
                <Modal.Footer className='modalDiscardChanges-footer'>
                    <SendButton onClick={onSubmit}>
                        {loading ? <Loader /> : 'Send'}
                    </SendButton>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const FlexRefer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 10px;
  overflow: scroll;
`;

const WrapperCountrySelect = styled.div`
    margin: 7px 0;
    width: 100%;
`

const SendButton = styled.button`
    background-color: var(--t-blue);
    border-radius: 12px;
    padding: 14px 20px;
    width: 100%;
    cursor: pointer;
    color: #FFF;
    font-size: 16px;
    font-family: Inter-Bold;
    text-align: center;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Row2 = styled.div`
    width: 48%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;

    & > div{
        margin: 7px 0;
    }

    @media only screen and (max-width: 700px) {
        width: 100%;
    }
`

const Label = styled.div`    
    width: 100%;
    text-align: left;
    margin: 3px 0;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`
const Inputs = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 700px) {
        flex-direction: column;
    }
`

const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
    margin: 7px 0;

`

const Textarea = styled.textarea`
    outline: none;
    width: 100%;
    height: 100px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
    margin: 7px 0;

`

export default ModalJoin;