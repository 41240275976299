import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import { useEffect, useState } from "react";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { getFiltered, sortAlphabeticallyOnProperty } from "../../containers/Signup/SignupHealerInd";
import { BaseFieldState } from "./utils";
import SelectItem from "../../interfaces/SelectItem";
import OutsideClickHandler from "react-outside-click-handler";

const SelectorCheckBoxes = ({
    options,
    selected,
    setSelected,
    backgroundColor = "white",
    border = "1px solid var(--simple-gray)",
    placeholder = "Select items",
    selectAllLabel = 'Select All',
    labelAll = '',
    notApplicable = false,
    notApplicableLabel = 'Not Applicable',
    groupItems
}: {
    options: SelectItem[],
    selected: BaseFieldState<SelectItem[]>,
    setSelected: (data: BaseFieldState<SelectItem[]>) => void,
    backgroundColor?: string,
    border?: string,
    placeholder?: string,
    selectAllLabel?: string,
    labelAll?: string,
    notApplicable?: boolean,
    notApplicableLabel?: string,
    groupItems?: string
}) => {

    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [idsSelected, setIdsSelected] = useState<string[]>((selected.value || []).map(s => s.value));
    const [allSelected, setAllSelected] = useState<boolean>(false);
    const [disabled, setDisabled] = useState(false);

    const getSelectedData = () => {
        return (selected.value || []).map(select => select.label).join(", ");
    }

    const onClickAll = () => {
        if (idsSelected.length > 0) {
            setSelected({
                ...selected,
                value: []
            })
            setIdsSelected([]);
            return;
        }

        setSelected({
            ...selected,
            value: options
        })
        setIdsSelected(options.map(s => s.value));
    }

    const onClickNotApplicable = () => {
        // if (!disabled) {
        //     setShowOptions(false);
        // }
        setDisabled(!disabled)
        setSelected({
            ...selected,
            value: []
        })
        setIdsSelected([]);
    }

    const onClickBox = (box: SelectItem) => {
        if (!idsSelected.includes(box.value)) {
            setSelected({
                ...selected,
                value: [box, ...(selected.value || [])]
            });
            setIdsSelected([...idsSelected, box.value]);
            return;
        }

        setSelected({
            ...selected,
            value: (selected.value || []).filter(s => s.value !== box.value)
        });
        setIdsSelected(idsSelected.filter(s => s !== box.value));
    }

    useEffect(() => {
        if ((selected.value || []).length > 0) {
            setIdsSelected((selected.value || []).map(s => s.value));
        }
    }, [])

    return (
        <WrapperSelect>
            <Select_Input
                onClick={() => setShowOptions(!showOptions)}
                backgroundColor={backgroundColor}
                border={border}
                colorInput={idsSelected.length > 0 || disabled ? "black" : "var(--simple-gray)"}
            >
                {disabled ? notApplicableLabel : ((options.length === (selected.value || []).length) && labelAll.length > 0) ?
                    labelAll
                    :
                    (selected.value || []).length > 0 ? getSelectedData() : placeholder
                }
            </Select_Input>
            <IconDropdownInput
                icon={faChevronDown}
                size='sm'
                color={backgroundColor === 'white' ? 'black' : 'white'}
                onClick={() => setShowOptions(!showOptions)}
            />
            {showOptions &&
                <OutsideClickHandler onOutsideClick={() => setShowOptions(false)}>
                    <Items>
                        {notApplicable && (
                            <Item onClick={onClickNotApplicable} disabled={false}>
                                {disabled ? (
                                    <FontAwesomeIcon
                                        color="var(--t-blue)"
                                        icon={"square-check"}
                                        size="lg"
                                    />
                                ) : (
                                    <Square />
                                )}
                                {notApplicableLabel}
                            </Item>
                        )}
                        <Item onClick={disabled ? () => { } : onClickAll} disabled={disabled}>
                            {(idsSelected.length !== 0 && idsSelected.length === options.length) ? (
                                <FontAwesomeIcon
                                    color="var(--t-blue)"
                                    icon={"square-check"}
                                    size="lg"
                                />
                            ) : (
                                <Square />
                            )}
                            {selectAllLabel}
                        </Item>
                        {groupItems ?
                            Object.keys(options.reduce(
                                (result: any, currentValue: any) => {
                                    (result[currentValue[groupItems]] = result[currentValue[groupItems]] || []).push(currentValue);
                                    return result;
                                }, {})).map((keyName: any, i) => (
                                    <ItemGroup>
                                        {keyName !== 'undefined' &&
                                            <span className="input-label">{keyName}</span>
                                        }
                                        {options.reduce(
                                            (result: any, currentValue: any) => {
                                                (result[currentValue[groupItems]] = result[currentValue[groupItems]] || []).push(currentValue);
                                                return result;
                                            }, {})[keyName].map((active: any) => (
                                                <Item onClick={() => disabled ? {} : onClickBox(active)} disabled={disabled}>
                                                    {idsSelected.includes(active.value) ? (
                                                        <FontAwesomeIcon
                                                            color="var(--t-blue)"
                                                            icon={"square-check"}
                                                            size="lg"
                                                        />
                                                    ) : (
                                                        <Square />
                                                    )}
                                                    {active.label}
                                                </Item>
                                            ))}
                                    </ItemGroup>
                                ))
                            :
                            options.map((active: any) => (
                                <>
                                    <Item onClick={() => disabled ? {} : onClickBox(active)} disabled={disabled}>
                                        {idsSelected.includes(active.value) ? (
                                            <FontAwesomeIcon
                                                color="var(--t-blue)"
                                                icon={"square-check"}
                                                size="lg"
                                            />
                                        ) : (
                                            <Square />
                                        )}
                                        {active.label}
                                    </Item>
                                </>
                            ))
                        }
                    </Items>
                </OutsideClickHandler>
            }
        </WrapperSelect>
    )
}

const ItemGroup = styled.div`

    & span{
        height: 33px;
        width: 100%;
        background-color: white;
        display: block;
        margin-top: -7px;
        padding: 15px;
        color: gray;
        text-align: left;
    }
    
`

const Square = styled.div`
    width: 20px;
    height: 20px;
    border: 2px solid var(--t-blue);
    border-radius: 3px;
`;

const WrapperSelect = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    text-overflow: ellipsis;
`;

interface Select_InputProps {
    backgroundColor: string
    border: string
    colorInput?: string
}

const Select_Input = styled.div`
    outline: none;
    width: 100%;
    height: 100%;
    border-radius: 9px;
    border: 0;
    padding: 10px 25px 10px 20px;
    text-align: left;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    font-family: 'Inter';
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;

    background-color: ${(props: Select_InputProps) => props.backgroundColor};
    border: ${(props: Select_InputProps) => props.border};
    color: ${(props: Select_InputProps) => props.colorInput ? props.colorInput : 'black'};

    cursor: pointer;
    white-space: nowrap;
`;

const IconDropdownInput = styled(FontAwesomeIcon)`
    cursor: pointer;
    position: absolute;
    z-index: 0;
    top: 37%;
    left: 94%;
`;

const Item = styled.li`
    background-color: white;
    padding: 10px 20px;
    text-align: left;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    display: flex;
    gap: 10px;
    align-items: center;

    ${({ disabled }: { disabled: boolean }) => disabled ? `
        color: gray;
        cursor: not-allowed;

    ` : `
        cursor: pointer;
    `}

    &:last-child {
        border-radius: 0 0 10px 10px;
        -webkit-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        -moz-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
    }

    &:hover {
        background-color: #e8e8e8;
    }
`;

const Items = styled.ul`
    width: 100%;
    position: absolute;
    top: 100%;
    z-index: 10;
    overflow-y: scroll;
    max-height: 200px;
    border-radius: 12px;
    left: 0px;
    box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
`;

export default SelectorCheckBoxes;