import { userInfo } from "os";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { ContextSuccess } from "../../Context";
import IResponseError from "@vibe/sdk/dist/interfaces/IResponseError";
import { CreateEventRequest } from "@vibe/sdk/dist/interfaces/Events/createEvent";
import { getMyEventsComplete } from "../../store/actions/eventActions";
import { RootState } from "../../store/store";
import Modal from "react-bootstrap/esm/Modal";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import TabGeneral from './TabGeneral'
import TabDateType from './TabDateType'
import ButtonSpinner from "../BaseComponents/ButtonSpinner";
import { EventDateInfo, EventGeneralInfo, MockEventDateInfo, MockEventGeneralInfo, categoryType, clubReachType, clubReachTypeEvent, deliveryType, reachType, reachTypeEvent } from "../../interfaces/Event/CreateEvent";
import { getTimezones } from "../../store/actions/locationActions";
import { onHideType } from "../BaseComponents/Modals/ModalUnsaved";
import { uploadAsset, uploadImage } from "../../store/actions/userActions";
import { BaseFieldState, getFieldState } from "../BaseComponents/utils";
import { LogoInterface } from "../signupsetuphealer/Introduction";
import { EventsResponse } from "@vibe/sdk/dist/interfaces/Events/getEvent";
import { UpdateEventRequest } from "@vibe/sdk/dist/interfaces/Events/updateEvent/updateEventRequest";
import TabSchedule from "./TabSchedule";
import SelectItem from "../../interfaces/SelectItem";
import { IUserGradeResponse } from "@vibe/sdk/interfaces/Admin/User";

const ModalCreateEvent = ({
    showModal,
    setShowModal,
    reloadEvents,
    edit,
}: {
    showModal: boolean
    setShowModal: (data: boolean) => void
    reloadEvents: () => void
    edit?: boolean
}) => {

    const currentSuccess = useContext(ContextSuccess);

    const org = useSelector((state: RootState) => state.getOrgs.organizationInfo);
    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
    const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);
    const event: EventsResponse = useSelector((state: RootState) => state.getEvent.event);

    const [tabActive, setTabActive] = useState<number>(1)

    const [eventGeneral, setEventGeneral] = useState<EventGeneralInfo>({ ...MockEventGeneralInfo });
    const [eventDate, setEventDate] = useState<EventDateInfo>({ ...MockEventDateInfo });

    const [loading, setLoading] = useState<boolean>(false);
    const [generalTab, setGeneralTab] = useState<boolean>(false);
    const [scheduleTab, setScheduleTab] = useState<boolean>(false);
    const [dateTab, setDateTab] = useState<boolean>(false);
    const [cleanData, setCleanData] = useState<boolean>(false);
    const [logo, setLogo] = useState<BaseFieldState<LogoInterface>>(getFieldState({ file: '', fileLink: '' }));
    const [nextPressed, setNextPressed] = useState<boolean>(false);
    const [eventType, setEventType] = useState<SelectItem[]>([]);

    const [eventBriteInfo, setEventBriteInfo] = useState<{ [clubId: string]: { orgId: string, usernameBrite: string | undefined } }>({})

    const dispatch = useDispatch();

    const cleanForm = () => {
        setEventGeneral({ ...MockEventGeneralInfo })
        setEventDate({ ...MockEventDateInfo })
        setCleanData(false);
        setTabActive(1);
        setNextPressed(false);
        setLogo(getFieldState({ file: '', fileLink: '' }));
        setLoading(false);
        setGeneralTab(false);
        setScheduleTab(false);
        setDateTab(false);
    }

    const getReach = (): [reachType[], clubReachType[]] => {
        return [
            eventGeneral.reach.filter((r) => ['ADVISOR', 'HEALER', 'SEEKER', 'ADVISORY', 'COMITEE', 'STAFF'].includes(r)) as reachType[],
            eventGeneral.reach.filter((r) => ['MEMBER', 'FOLLOWER', 'OFFICER', 'ADVISOR'].includes(r)) as clubReachType[],
        ]
    }

    const getCategory = () => {
        // if (eventGeneral.category === 'ANY') return 'ORG' as categoryType;
        return 'CLUB' as categoryType;
    }

    const getDelivery = () => {
        if (eventDate.delivery === 'ANY') return 'mixed' as deliveryType;
        return eventDate.delivery as deliveryType;
    }

    const handleTab = (tab: number) => {
        if (tab === 2 && !generalTab) return;
        if (tab === 3 && !scheduleTab) return;
        setTabActive(tab);
    }

    const handleSaveImage = (fileUp: any): Promise<string> => new Promise((resolve, reject) => {
        const dataFile = URL.createObjectURL(fileUp);
        let contentType: any;
        let file: any;
        fetch(dataFile)
            .then(res => res.blob())
            .then(blob => {
                contentType = dataFile.split(';')[0].split(':')[1];
                const extension = contentType.split('/')[1]
                file = new File([blob], 'event_cover' + userInfo?.username, { type: contentType });

                if (file) {
                    return dispatch<any>(uploadAsset(
                        file.type,
                        file.name + '.' + extension,
                        "event_cover"
                    ))
                }
                else {
                    return Promise.reject(new Error("File not found"));
                }
            })
            .then((results: any) => {
                if (results.body) {

                    dispatch<any>(uploadImage(
                        results.body,
                        contentType,
                        file,
                    ))
                        .then((result: any) => {
                            if (result.status === 200) {
                                const urlImage = results.body.split('?')[0];
                                resolve(urlImage);
                            }
                            else {
                                reject(result);
                            }
                        })
                        .catch((error: any) => {
                            reject(error);
                        })

                }
            })
            .catch((e) => reject(e))
    });

    const handleSave = async () => {
        if (edit) handleEditEvent();
        else handleCreateEvent();
    }

    const getBriteUser = (clubIds: string[]): string | undefined => {
        // If other username comes in some club, it means that the creator of the club has event brite configured
        // As other users are only possible in parents, this event must be on parent's event brite if possible
        let userIsConfigured = false
        for(let clubId of clubIds){
            if(eventBriteInfo[clubId]?.usernameBrite){
                if(userInfo?.username){
                    if(userInfo?.username !== eventBriteInfo[clubId].usernameBrite){
                        return eventBriteInfo[clubId].usernameBrite!
                    }else{
                        userIsConfigured = true
                    }
                }else{
                    return eventBriteInfo[clubId].usernameBrite!
                }
            }
        }
        return userIsConfigured ? userInfo?.username : user.eventbriteConnected ? user.username : undefined
    }

    const handleEditEvent = async () => {
        setLoading(true);

        const toSend: UpdateEventRequest = {};

        if (eventGeneral.name !== '') toSend.name = eventGeneral.name;
        if (eventGeneral.start !== '') toSend.start = eventGeneral.start;
        if (eventGeneral.end !== '') toSend.end = eventGeneral.end;
        if (eventGeneral.timezone !== '') toSend.timezone = eventGeneral.timezone;
        if (eventDate.fee_online !== '') toSend.eventFeeOnline = parseFloat(eventDate.fee_online);
        if (eventDate.fee_inPerson !== '') toSend.eventFeeInPerson = parseFloat(eventDate.fee_inPerson);
        if (eventDate.maxCapacity_inPerson !== 0) toSend.max_event_capacity_inperson = eventDate.maxCapacity_inPerson;
        if (eventDate.maxCapacity_online !== 0) toSend.max_event_capacity_online = eventDate.maxCapacity_online;
        if (eventDate.link && eventDate.link !== '') toSend.link = eventDate.link;
        if (eventGeneral.description !== '') toSend.description = eventGeneral.description;
        if (eventDate.delivery !== 'ANY') toSend.delivery = getDelivery();
        if (eventDate.instructions_online !== '') toSend.instructionsOnline = eventDate.instructions_online;
        if (eventDate.instructions_inPerson !== '') toSend.instructionsInPerson = eventDate.instructions_inPerson;
        if (eventDate.address_1 !== '') toSend.addressLine1 = eventDate.address_1;
        if (eventDate.address_2 !== '') toSend.addressLine2 = eventDate.address_2;
        if (eventDate.zipcode !== '') toSend.zipcode = eventDate.zipcode;
        if (eventDate.country !== '') toSend.country = eventDate.country;
        if (eventDate.countryCode !== '') toSend.countryCode = eventDate.countryCode;
        if (eventDate.state !== '') toSend.state = eventDate.state;
        if (eventDate.stateCode !== '') toSend.stateCode = eventDate.stateCode;
        if (eventDate.city !== '') toSend.city = eventDate.city;
        if (eventDate.cityCode !== '') toSend.cityCode = eventDate.cityCode;
        if (eventGeneral.schedule.length > 0) toSend.scheduleUpdate = eventGeneral.schedule;
        if ((eventGeneral.eventMS || []).length > 0) toSend.modalitiesIds = eventGeneral.eventMS;
        if (eventGeneral.eventType !== '') toSend.eventType = eventGeneral.eventType;

        if (logo.value?.file?.size) {
            try {
                toSend.event_cover = await handleSaveImage(logo.value?.file);
            } catch (e) {
                currentSuccess?.setSuccessData(false, true, "Error uploading image.");
                console.log(e);
                return;
            }
        }

        //@todo createEventBrite Data in update

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Events.updateEvent(event.event_id, toSend, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) {
                    currentSuccess?.setSuccessData(true, true, "Event Edited Successfully");
                    onHideClose();
                }
                else {
                    currentSuccess?.setSuccessData(false, true, "Error editing event", (results?.body as IResponseError)?.error?.message || (results?.body as IResponseError)?.error || (results?.body as IResponseError)?.message);
                }
            })
            .catch((error) => {
                currentSuccess?.setSuccessData(false, true, "Error editing event", error);
            })
            .finally(() => setLoading(false))
    }

    const handleCreateEvent = async () => {
        setLoading(true);

        const toSend: CreateEventRequest = {
            org_id: org.id,
            name: eventGeneral.name,
            start: eventGeneral.start || '',
            end: eventGeneral.end || '',
            creator: userInfo?.username || '',
            category: getCategory(),
            timezone: eventGeneral.timezone,
            reach: getReach()[0],
            eventFeeOnline: parseFloat(eventDate.fee_online),
            eventFeeInPerson: parseFloat(eventDate.fee_inPerson),
            max_event_capacity_inperson: eventDate.maxCapacity_inPerson,
            max_event_capacity_online: eventDate.maxCapacity_online,
            link: getDelivery() === 'inPerson' ? undefined : eventDate?.link,
            description: eventGeneral.description,
            delivery: getDelivery(),
            club_ids: eventGeneral.clubIds?.filter(c => c !== 'FollowOrg') ?? [],
            clubReach: ['MEMBER', 'FOLLOWER', 'OFFICER', 'ADVISOR'], //getReach()[1],
            instructionsOnline: eventDate.instructions_online || '',
            instructionsInPerson: eventDate.instructions_inPerson || '',
            addressLine1: eventDate.address_1 || '',
            addressLine2: eventDate.address_2 || '',
            createEventbrite: eventDate.createEventbrite ? getBriteUser(eventGeneral.clubIds ?? []) : undefined,
            zipcode: eventDate.zipcode || '',
            countryCode: eventDate.countryCode || '',
            country: eventDate.country || '',
            city: eventDate.city || '',
            cityCode: eventDate.cityCode || '',
            state: eventDate.state || '',
            stateCode: eventDate.stateCode || '',
            forOrgFollowers: eventGeneral.clubIds?.includes('FollowOrg'),
            eventType: eventGeneral.eventType || '',
            modalitiesIds: eventGeneral.eventMS || [],
            scheduleCreate: eventGeneral.schedule || [],
        }

        if (logo.value?.file?.size) {
            try {
                toSend.event_cover = await handleSaveImage(logo.value?.file);
            } catch (e) {
                currentSuccess?.setSuccessData(false, true, "Error uploading image.");
                console.log(e);
                return;
            }
        }

        if (eventDate.createEventbrite) {
            toSend.eventBriteFee = parseFloat(`${eventDate.eventBriteFee}`);
            toSend.eventBriteOnline = eventDate.eventBriteOnline;
            toSend.eventBriteCapacity = eventDate.eventBriteCapacity;
        }



        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Events.createEvent(toSend, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 201) {
                    currentSuccess?.setSuccessData(true, true, "Event Created Successfully");
                    onHideClose();
                }
                else {
                    currentSuccess?.setSuccessData(false, true, "Error creating event", (results?.body as IResponseError)?.error?.message || (results?.body as IResponseError)?.error || (results?.body as IResponseError)?.message);
                }
            })
            .catch((error) => {
                currentSuccess?.setSuccessData(false, true, "Error creating event", error);
            })
            .finally(() => setLoading(false))
    }

    const onHideClose = () => {
        setShowModal(false);
        setCleanData(true);
        cleanForm();
    }

    const onHideDiscardSave = (type: onHideType) => {
        switch (type) {
            case 'discard':
                currentSuccess?.setModalsOff();
                onHideClose();
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'save':
                handleSave();
                break;

            default:
                break;
        }
    };

    const onHideDiscard = (type: onHideType) => {
        switch (type) {
            case 'discard':
                currentSuccess?.setModalsOff();
                onHideClose();
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'save':
                currentSuccess?.setModalsOff();
                break;

            default:
                break;
        }
    };

    const onHide = () => {
        if (tabActive === 2 && dateTab) {
            currentSuccess?.setModalWarning('UNSAVED', onHideDiscardSave, 'Do you want to discard changes?');
        }
        else currentSuccess?.setModalWarning('UNSAVED', onHideDiscard, 'Do you want to discard changes?', 'Discard');
    }

    const handleNext = () => {
        if (tabActive === 1 && generalTab) {
            setNextPressed(true);
            setTabActive(2);
        }
        else if (tabActive === 2 && scheduleTab) {
            setNextPressed(true);
            setTabActive(3);
        }
    }

    const handlePrev = () => {
        setNextPressed(true);
        setTabActive(tabActive - 1);
    }

    const loadData = () => {
        setGeneralTab(true);
        setDateTab(true);
        setScheduleTab(true);
        setNextPressed(true);
        setLogo(getFieldState({ file: event.event_cover, fileLink: event.event_cover }));
        setEventGeneral({
            category: event.category || 'CLUB',
            clubIds: event.forOrgFollowers ? ['FollowOrg'].concat(event.club_ids || []) : event.club_ids || [],
            reach: event.reach || [],
            clubReach: event.clubReach || [],
            cover: event.event_cover,
            name: event.name || '',
            description: event.description || '',
            timezone: event.timezone || '',
            start: event.start || '',
            end: event.end || '',
            schedule: event.schedule || [],
            eventType: event.eventType || '',
            eventMS: event.modalitiesIds || [],
        });
        setEventDate({
            delivery: event.delivery || 'online',
            link: event.link || '',
            instructions_online: event.instructionsOnline || '',
            instructions_inPerson: event.instructionsInPerson || '',
            maxCapacity_online: event.max_event_capacity_online || 0,
            maxCapacity_inPerson: event.max_event_capacity_inperson || 0,
            fee_online: event.eventFeeOnline.toString() || '0',
            fee_inPerson: event.eventFeeInPerson.toString() || '0',
            address_1: event.addressLine1 || '',
            address_2: event.addressLine2 || '',
            zipcode: event.zipcode || '',
            countryCode: event.countryCode || '',
            country: event.country || '',
            city: event.city || '',
            cityCode: event.cityCode || '',
            state: event.state || '',
            stateCode: event.stateCode || '',
        });
    }

    const getTypeEvent = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Events.getAllEventTypes();
            })
            .then((results) => {
                if (results) {
                    if (results.statusCode === 200 && 'body' in results && Array.isArray(results.body)) {
                        setEventType(
                            results.body.map(c => ({
                                label: c.name,
                                value: c.id
                            })))
                    }
                }
            })
            .catch((error) => console.error('Error', error))
    }

    useEffect(() => {
        dispatch(getTimezones());
        getTypeEvent();
        getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
            return dataSDK.sdk.Events.eventBriteCreationInfo(userInfo?.username ?? '', dataSDK.token);
        })
        .then((results) => {
            if (results.statusCode === 200) {
                setEventBriteInfo(results.body as Record<string, {
                    orgId: string;
                    usernameBrite: string | undefined;
                }>)
            }
        })
        .catch((error) => { })
        if (edit) loadData();
    }, []);

    useEffect(() => {
        getTypeEvent();
        if (edit) loadData();
    }, [event]);

    return (
        <Modal className="modalCreateNewEvent" show={showModal}>
            <Modal.Header>
                <Title>{edit ? 'Update Event' : 'Create an event'}</Title>
                <IconClicker
                    onClick={onHide}
                    icon="times"
                    size='xl'
                    color={'black'}
                />
            </Modal.Header>
            <Modal.Body>
                <Tabs>
                    <Tab onClick={() => handleTab(1)} theme={{ main: tabActive === 1 ? 'tabActive' : tabActive > 1 ? 'tabComplete' : '' }}>About</Tab>
                    <Tab onClick={() => handleTab(2)} theme={{ main: tabActive === 2 ? 'tabActive' : tabActive > 2 ? 'tabComplete' : '' }}>Schedule & Facilitators</Tab>
                    <Tab onClick={() => handleTab(3)} theme={{ main: tabActive === 3 ? 'tabActive' : '' }}>Location & Fees</Tab>
                </Tabs>

                {tabActive === 1 ?
                    <TabGeneral
                        eventGeneral={eventGeneral}
                        setEventGeneral={setEventGeneral}
                        setTabOk={setGeneralTab}
                        cleanData={cleanData}
                        logo={logo}
                        setLogo={setLogo}
                        nextPressed={nextPressed}
                        isUpdate={edit}
                        eventType={eventType}
                        modalities={(org.modalities || []).map(mod => ({
                            value: mod.id,
                            label: mod.name,
                        }))}
                    />
                    : tabActive === 2 ?
                        <TabSchedule
                            eventGeneral={eventGeneral}
                            setEventGeneral={setEventGeneral}
                            setTabOk={setScheduleTab}
                            cleanData={cleanData}
                            nextPressed={nextPressed}
                            isUpdate={edit}
                        />
                        :
                        <TabDateType
                            eventDate={eventDate}
                            setEventDate={setEventDate}
                            setTabOk={setDateTab}
                            cleanData={cleanData}
                            isUpdate={edit}
                            isEdit={edit ?? false}
                            showBrite={getBriteUser(eventGeneral.clubIds ?? []) !== undefined}
                        />
                }
            </Modal.Body>
            <Modal.Footer>
                <ButtonClose onClick={onHide}>
                    Cancel
                </ButtonClose>
                <Buttons>
                    {(tabActive === 2 || tabActive === 3) &&
                        <Button
                            theme={{ main: 'buttonPreviuos' }}
                            onClick={handlePrev}
                        >
                            Previous
                        </Button>
                    }

                    {tabActive === 1 && <Button
                        theme={{ main: !generalTab ? 'buttonDisabled' : 'buttonActive' }}
                        onClick={handleNext}
                    >
                        {!generalTab &&
                            <Tooltip className="tooltip">All fields with (*) are mandatory</Tooltip>
                        }
                        Next
                    </Button>}

                    {tabActive === 2 && <Button
                        theme={{ main: !scheduleTab ? 'buttonDisabled' : 'buttonActive' }}
                        onClick={handleNext}
                    >
                        {!scheduleTab &&
                            <Tooltip className="tooltip">All fields with (*) are mandatory</Tooltip>
                        }
                        Next
                    </Button>}

                    {tabActive === 3 && (!scheduleTab ? (
                        <Button
                            theme={{ main: 'buttonDisabled' }}
                        >
                            {(!scheduleTab || !dateTab) &&
                                <Tooltip className="tooltip">All fields with (*) are mandatory</Tooltip>
                            }
                            Save
                        </Button>
                    ) : (
                        <ButtonSpinner
                            loading={loading}
                            handleClick={handleSave}
                            text={'Save'}
                            hasIcon={false}
                        />
                    ))}
                </Buttons>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalCreateEvent;

const Buttons = styled.div`
    display: flex;
    gap:  10px;

    & > button{
        width: 135px;
    }
`

const ButtonClose = styled.div`
    border: 0px solid #999796;
    background: #FFF;
    font-family: 'Inter-Bold';
    font-size: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    color: #6B6B6B;
`

export const DisabledFieldEvent = styled.div`
    display: flex;
    gap: 5px;
    border-radius: 8px;
    background-color: var(--simple-gray-2);
    border: 1px solid var(--simple-gray);
    height: 40px;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0px 20px;
    width: 100%;
`;

const Tooltip = styled.div`
    position: absolute;
    background-color: var(--t-blue);
    color: white;
    font-size: 12px;
    padding: 9px 12px;
    border-radius: 9px;
    left: 0px;
    bottom: 44px;
    display: none;
`

export const ContentTab_Event = styled.div`
    gap: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
`

const Button = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    border-radius: 8px;
    border: 1px solid #999796;
    background: #FFF;
    font-family: 'Inter-Bold';
    font-size: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    color: #6B6B6B;
    width: 135px;

    &.buttonDisabled{
        background: #EEE; 
    }
    &.buttonActive{
        background: var(--t-blue);
        color: white;
    }

    &.buttonDisabled:hover .tooltip{
        display: block;
    }

    &.buttonPreviuos{
        background: #ffffff;
        color: #29303b;
    }
`

const IconArrow = styled(FontAwesomeIcon)`
    position: absolute;
    right: 10px;
`;

const Tabs = styled.div`
    width: 90%;
    display: flex;
    margin: 25px auto;
`
const Tab = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    width: 33%;
    font-size: 16px;
    padding: 9px 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    position: relative;

    &::after{
        content: '';
        display: block;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: white;
        border: 4px solid #999796;
        position: relative;
        z-index: 1;
    }

    &.tabActive::after{
        border: 4px solid #E89F4D;
    }

    &.tabComplete::after{
        border: 4px solid #E89F4D;
        background-color: #E89F4D;
    }
    &.tabComplete::before{
        border: 1px solid #E89F4D;
    }

    &::before{
        content: '';
        display: block;
        position: absolute;
        background-color: white;
        border: 1px solid #999796;
        width: 100%;
        left: -50%;
        top: 44px;
        z-index: 0;
    }

    &:hover {
        background-color: #ebebeb;
        border-radius: 12px;
    }

    &.tabActive::before{
        border: 1px solid #E89F4D;
    }

    &:first-child::before {
        display: none;
    }

    :last-child {
        border-radius: 0px 8px 8px 0px;
    }

    &.tabActive{
        font-weight: 700;   
    }
`

const Title = styled.span`
    font-family: 'Inter-SemiBold';
    color: black;
    font-size: 24px;
    text-align: left;
    width: 100%;
`;

const IconClicker = styled(FontAwesomeIcon)`
    cursor: pointer;
`;