import styled from "styled-components";

export const ContentResults = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 68px 1fr;
  overflow: hidden;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 700px) {
    margin-top: 0px; 
    height: 93vh;
  }
`;

export const SearchResultsHome = styled.div`
  width: 100%;
  top: 72px;
  height: 68px;
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 16px 136px;
  z-index: 2;
  
  box-shadow: 0px 2px 8px -1px rgba(27, 36, 44, 0.08), 0px 2px 2px -1px rgba(27, 36, 44, 0.04);
  -webkit-box-shadow: 0px 2px 8px -1px rgba(27, 36, 44, 0.08), 0px 2px 2px -1px rgba(27, 36, 44, 0.04);
  -moz-box-shadow: 0px 2px 8px -1px rgba(27, 36, 44, 0.08), 0px 2px 2px -1px rgba(27, 36, 44, 0.04);
  background-color: white;

  @media only screen and (max-width: 700px) {
    padding: 0px 24px;
  }
`;

export const GridSearchContent = styled.div`
  display: grid;
  width: 100vw;
  height: calc(100vh - 188px); //Search bar + header
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 278px; //Content and footer
  overflow: scroll;
  background-color: var(--background-default);

  @media only screen and (max-width: 770px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 129px); //Search bar + header
  }
`;

export const GridResultsCenter = styled.div`
  display: grid;
  padding: 32px 136px;
  grid-template-columns: 1fr 267px; //Width card inspirational quotes
  grid-template-rows: 1fr;
  grid-column-gap: 33px;
  width: 100vw;

  @media only screen and (max-width: 770px) {
    padding: 32px 0px 32px 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const FooterResultsHome = styled.div`
  display: flex;
  width: 100vw;
  height: 278px;
  padding: 73px 160px;
  justify-content: center;
  align-items: center;
  gap: 190px;
  background-color: var(--simple-blue-dark-2);
  
  @media only screen and (max-width: 770px) {
    display: none;
  }

`;

export const FooterColumnHome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 24px;
  color: white;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 28px;
  letter-spacing: 0.2px;
  width: max-content;
`;

export const FooterRowHome = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-start;
`;

export const SocialMediaFooter = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

const logoVibe = require("../../../assets/img/vibe_white_transparent.png");
export const LogoHomeResults = styled.div`
  min-width: 126px;
  min-height: 56px;
  background-image: url("${logoVibe}");
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const SearchInputResultsHome = styled.input`
  width: 100%;
  height: 36px;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.1px;
  color: black;

  ::placeholder {
    color: var(--simple-gray);
  }

  @media only screen and (max-width: 700px) {
    height: 60px;
    font-size: 16px;
    ::placeholder {
      font-size: 16px;
      white-space:pre-line; 
      position:relative;
      top:-7px;
    } 
  }
`;