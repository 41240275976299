import {
    // Amplify,
    Auth
} from 'aws-amplify';

import {CognitoUser} from 'amazon-cognito-identity-js';
import {Backend, MicroServices} from "./backendHelper";
// import {userPoolId, userPoolWebClientId} from "../AppConfig";

export const backendSignIn = ({username, password}: {username: string, password: string}) =>
    new Promise((resolve, reject) => {
        Backend(
            MicroServices.Dashboard,
            `/sign-in`,
            { body: JSON.stringify({username, password}), method: 'POST' }
            )
        .then((response) => response.json())
        .then(resolve)
        .catch(reject)
})

// We need to make the additional calls that are done in the backend but also keep track of the amplify session object in frontend
export const fullLogin = ({username, password}: {username: string, password: string}) =>
    new Promise((resolve, reject) => {
        Auth.signIn(username, password)
            .then(() => backendSignIn({username, password}))
            .then(resolve)
            .catch(reject)
    })


export const getSignInFunction = ({username, password, custom}: {username: any, password: any, custom: any}) =>
    new Promise((resolve, reject) => {
        const {
            email,
            secret,
        } = custom;

        if (!custom) {
            // resolve(() => Auth.signIn(username, password))
            resolve(() => fullLogin({username, password}))
            return;
        }

        let cognitoUser: CognitoUser;

        // Amplify.configure({
        //     Auth: {
        //         region: 'us-east-1',
        //         userPoolId,
        //         userPoolWebClientId
        //     }
        // });

        Auth.signIn(email)
            .then(results => {
                cognitoUser = results;
                return Backend(
                    MicroServices.AccessManager,
                    `/auth/auth-code?secret=${secret}`)
            })
            .then((response) => response.json())
            .then(({code}) => resolve(() => Auth.sendCustomChallengeAnswer(cognitoUser, code)))
            .catch((error) => reject(error))
    })


// try {
//     let {
//         Username,
//         UserPoolId,
//         ClientId,
//         AccessToken,
//         RefreshToken,
//         IdToken
//     } = props;
//
//     Amplify.configure({
//         Auth: {
//             region: 'us-east-1',
//             userPoolId: UserPoolId,
//             userPoolWebClientId: ClientId
//         }
//     });
//
//
//     const Pool = new CognitoUserPool({
//         UserPoolId,
//         ClientId
//     });
//     const newCognitoUser = new CognitoUser({Pool, Username});
//
//     AccessToken = new CognitoAccessToken({AccessToken})
//     RefreshToken = new CognitoRefreshToken({RefreshToken})
//     IdToken = new CognitoIdToken({IdToken})
//
//     const signInUserSession = new CognitoUserSession({AccessToken, RefreshToken, IdToken})
//     newCognitoUser.setSignInUserSession(signInUserSession)
//     resolve(newCognitoUser)
// } catch (error: any) {
//     reject(error)
// }
