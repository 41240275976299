import { useState } from 'react'
import styled from "styled-components";
import SignupHealerInd from "./SignupHealerInd";
import { ContainerForm_Signup, ContainerForm_Signup_Invite, ContainerInfo_Signup, Container_Signup, Logo, TitleSignupWrappers, SubtitleSignupWrappers } from './WrapBusiness';
import { useNavigate } from 'react-router-dom';

const WrapHealerInd = () => {

    const [emailSent, setEmailSent] = useState<boolean>(false)
    const navigate = useNavigate();

    return (
        <Container_Signup>
            <ContainerForm_Signup>
                <SignupHealerInd emailSent={emailSent} setEmailSent={setEmailSent} />
            </ContainerForm_Signup>
            <ContainerInfo_Signup>
                {emailSent ?
                    <>
                        <TitleSignupWrappers>Next…</TitleSignupWrappers>
                        <SubtitleSignupWrappers>You have completed your registration and are now a member of a healer community. The next step is to complete your profile.</SubtitleSignupWrappers>
                    </>
                    :
                    <>
                        <TitleSignupWrappers>Congratulations</TitleSignupWrappers>
                        <SubtitleSignupWrappers>You've been invited to join a community of healers, to support your commitment and journey to impact others.</SubtitleSignupWrappers>
                        <SubtitleSignupWrappers>First complete your registration and you can then build your profile.</SubtitleSignupWrappers>
                    </>
                }

            </ContainerInfo_Signup>
        </Container_Signup >
    )
}

export default WrapHealerInd;