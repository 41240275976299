export const orgTypesZaahah = (orgType : string[]) => {
    const result : any = []
    orgType.forEach((org : any) => {
        try {
            if((org[0] !== 'SPONSOR') && (org[0] !== 'SUPERADMIN')){
                result.push(org);
            }  
        } catch (error) {
            console.error;
        }
    });
    return result;
};

export const orgTypesZaahahSignup = (orgType : string[]) => {
    const result : any = []
    orgType.forEach((org : any) => {
        try {
            if((org[0] !== 'SPONSOR') && (org[0] !== 'SUPERADMIN') && (org[0] !== 'ASSOCIATION') && (org[0] !== 'NONPROFIT')){
                result.push(org);
            }  
        } catch (error) {
            console.error;
        }
    });
    
    return result.sort(function (a: string[],b: string[]){
        if(a[1]>b[1]){
            return 1;
        }

        if(a[1]<b[1]){
            return -1;
        }

        return 0;
    });
};