import React from 'react'
import styled from "styled-components";
import {
    User, AvatarU, Counts, IconUser, CountS
} from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

const CardDetailComm = (props: any) => {
    return (
        <CardCommDetail>
            <TitleCommDetail>
                Sen Wellness Sanstuary, Sri Lanka
                <FontAwesomeIcon
                    icon={faTimes}
                    size='sm'
                    color={"black"}
                    style={{
                        fontSize: '30px'
                    }}
                    onClick={() => props.handleClick(false)}
                />
            </TitleCommDetail>
            <User>
                <AvatarU theme={{ width: '32px', height: '32px' }} src={"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} />
                Thomas Williams
            </User>
            <Counts>
                <CountS>
                    <IconUser src={'../icon_seeker.png'} />
                    54K Seekers
                </CountS>
                |
                <CountS>
                    <IconUser src={'../icon_healer.png'} />
                    54K Healers
                </CountS>
            </Counts>
            <TagsComm>
                Pranayama |
                Yogic Breathwork |
                Holotropic Breathwork |
                Transformational Breathwork |
                Rebirthing Breathwork |
                Breath Therapy |
                Breath Awareness |
                Conscious Breathing |
            </TagsComm>
            <DescComm>
                La Casa de Loto is a Meditation and Yoga Retreat Center, nestled in the magical jungle-clad mountains of Colombia, founded and run by Karma Pema and Maria Duque. Here, we offer you daily meditation and yoga classes, freshly cooked Ayurvedic food, cosy accommodations, and a variety of optional massages and treatments. Our experienced teachers and staff and the beautiful nature surroundings create the ideal conditions for you to turn inward, let go of stress, and reconnect to the essence of life, where lasting happiness can be found. You will find us just ten minutes up in the mountains of Guatapé, Antioquia.
            </DescComm>
        </CardCommDetail>
    )
}

const CardCommDetail = styled.div`
    position: absolute;
    background-color: white;
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.15);
    width: 600px;
    top: 120px;
    right: 0;
    height: 100%;
    padding: 32px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
`
const TitleCommDetail = styled.div`
    color: #333;
    font-family: Inter-Bold;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const TagsComm = styled.div`
    color: #222;
    font-family: Inter;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.2px;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    border-top: 1px solid #c1c9d2;
    border-bottom: 1px solid #c1c9d2;
    padding: 10px 0;
`
const DescComm = styled.div``

export default CardDetailComm