import styled from "styled-components";
import {
    TitleContent, InfoUser, Detail, Name, AvatarConn, NameConnection,
    Interests, ButtonWhite, TitleCard, ButtonBrown
} from "../../components/signupsetuphealer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faLink, faLocationDot } from "@fortawesome/pro-thin-svg-icons";
import { ProcessInterface } from "../../containers/SetupHealer/SetupHealer";
import { IClubExtended } from "@vibe/sdk/dist/interfaces/Club/ClubInterface";
import IHS from "@vibe/sdk/dist/interfaces/Modalities/hservices/HS";
import { useSelector } from "react-redux"
import { RootState } from "../../store/store";
import { IEducation } from "@vibe/sdk/dist/interfaces/Roster/UserInfo";
import { IUserGradeResponse } from "@vibe/sdk/dist/interfaces/Admin/User";
import { useEffect, useState, useRef } from "react";
import { Scroll_Content_Signup } from "../signupsetuphealer/Introduction";
import UserSocialMedia from "@vibe/sdk/interfaces/Roster/userSocialMedia";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import UserMedia from "@vibe/sdk/dist/interfaces/Roster/userMedia";
import UserPodcast from "@vibe/sdk/dist/interfaces/Roster/userPodcast";
import { IconClicker } from "../Home/HeaderHome/styles";
import { faSpotify, faApple } from "@fortawesome/free-brands-svg-icons";

const Summary = ({
    processData,
    handleTabActive,
}: {
    processData: ProcessInterface,
    handleTabActive: (value: number) => void;
}) => {

    const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);
    const organization = useSelector((state: RootState) => state.getOrgs.organizationInfo);
    const services: IHS[] = useSelector((state: RootState) => state.getModality.getHealerServices);
    const communities: IClubExtended[] = useSelector((state: RootState) => state.getClub.getClubsOrg);
    const certifications: any[] = useSelector((state: RootState) => state.getModality.getCertifications);
    const education: IEducation[] = useSelector((state: RootState) => state.getUser.getUserEducation);

    const [userPublications, setUserPublications] = useState<UserMedia[]>([])
    const [userPodcasts, setUserPodcasts] = useState<UserPodcast[]>([])

    const [height, setHeight] = useState(0)
    const [lines, setLines] = useState(3)
    const [seeMore, setSeeMore] = useState(false)
    const elementRef = useRef(null)

    const reloadUserPublications = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Roster.getUserMediaByUsername(user.username!, dataSDK.token)
            })
            .then((response) => {
                if (response.statusCode === 200 && Array.isArray(response.body)) {
                    setUserPublications(response.body);
                }
            })
    }

    const reloadUserPodcasts = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Roster.getUserPodcastByUsername(user.username!, dataSDK.token)
            })
            .then((response) => {
                if (response.statusCode === 200 && Array.isArray(response.body)) {
                    setUserPodcasts(response.body);
                }
            })
    }

    const sizeRef = (ref: any) => {
        if (ref.current) {
            setHeight(ref.current.offsetHeight);
            if (ref.current.offsetHeight > 34) {
                setLines(2)
            }
        }
    }

    useEffect(() => {
        sizeRef(elementRef)
    }, []);

    useEffect(() => {
        reloadUserPublications();
        reloadUserPodcasts();
        processData.setTabsProcessed([...processData.tabsProcessed, 6]);
    }, [])

    const getImageMedia = (social: UserSocialMedia): string => {
        switch (social.platform) {
            case 'Facebook':
                return '../facebook-f.svg';
            case 'YouTube':
                return '../youtube.svg';
            case 'Instagram':
                return '../instagram.svg';
            case 'Twitter':
                return '../twitter.svg';
            case 'LinkedIn':
                return '../linkedin.svg';
            case 'Pinterest':
                return '../pinterest.svg';
            case 'Snapchat':
                return '../snapchat.svg';
            case 'TikTok':
                return '../tiktokicon.svg';
            case 'Zaahah':
                return '../Zaahah.svg';
            case 'Personal':
                return '../globe-solid.svg'
            case 'Substack':
                return '../substack.svg'
            default:
                return '';
        }
    }

    return (
        <Scroll_Content_Signup>
            <TitleContent theme={{ align: 'center' }}>Please confirm the information you shared.</TitleContent>
            <ContentSummary>
                <CardSummary theme={{ main: seeMore ? 'active' : '' }}>
                    <HeaderCard>
                        <TitleCardS>About Me</TitleCardS>
                        <ButtonEdit onClick={() => handleTabActive(1)}>Edit</ButtonEdit>
                    </HeaderCard>
                    <CardDetail>
                        <InfoUser>
                            <AvatarInfo src={user.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-CardSummary-picture-973460__340.png"} />
                            <Detail>
                                <Name>{user.firstname || organization.name || ''} {user.lastname || ''}</Name>
                                <Location>
                                    <div>
                                        <StyledFontAwesomeIcon
                                            icon={faLocationDot}
                                            size='lg'
                                            color={"black"}
                                            style={{
                                                fontSize: '16px'
                                            }}
                                        />
                                        {user.zipcode || ''},
                                    </div>
                                    <div>
                                        {user.country || ''}
                                    </div>
                                </Location>
                            </Detail>
                        </InfoUser>
                        <SocialMedia>
                            {(user.sm || []).map(social => (
                                <a href={social.link} target="_blank" rel="noopener noreferrer">
                                    <IconMedia src={getImageMedia(social)} />
                                </a>
                            ))}
                        </SocialMedia>
                        <Description
                            lines={lines}
                            ref={elementRef}
                            dangerouslySetInnerHTML={{ __html: (user.biography as string ?? '') }}>
                        </Description>
                        {height > 34 &&
                            <SeeMore onClick={() => { setSeeMore(!seeMore); setLines(seeMore ? 2 : 1000) }}>
                                {seeMore ? 'See less' : 'See more'}
                            </SeeMore>
                        }
                    </CardDetail>
                </CardSummary>

                <CardSummary>
                    <HeaderCard>
                        <TitleCardS>Services</TitleCardS>
                        <ButtonEdit onClick={() => handleTabActive(4)}>Edit</ButtonEdit>
                    </HeaderCard>
                    <ItemsInterests>
                        {(services || []).map((team) => (
                            <ItemInt>{team.name || ''}</ItemInt>
                        ))}
                    </ItemsInterests>
                </CardSummary>

                <CardSummary>
                    <HeaderCard>
                        <TitleCardS>Certifications</TitleCardS>
                        <ButtonEdit onClick={() => handleTabActive(2)}>Edit</ButtonEdit>
                    </HeaderCard>
                    <ItemsInterests>
                        {(certifications || []).map((team) => (
                            <ItemTable>{team.name || ''} - {'yearsOfExperience' in team ? (team.yearsOfExperience || '') : (team.dateEarned || '')}<IconDrop src='./filePDF.svg' /></ItemTable>
                        ))}
                    </ItemsInterests>
                </CardSummary>

                <CardSummary>
                    <HeaderCard>
                        <TitleCardS>Education</TitleCardS>
                        <ButtonEdit onClick={() => handleTabActive(3)}>Edit</ButtonEdit>
                    </HeaderCard>
                    <ItemsInterests>
                        {(education || []).map((team) => (
                            <ItemTable>{team.degree_name} - {team.graduation_year}</ItemTable>
                        ))}
                    </ItemsInterests>
                </CardSummary>

                <CardSummary>
                    <HeaderCard>
                        <TitleCardS>Communities</TitleCardS>
                        <ButtonEdit onClick={() => handleTabActive(5)}>Edit</ButtonEdit>
                    </HeaderCard>
                    <ItemsInterests>
                        {(communities || []).map((team) => (
                            <ItemInt>{team.name || ''}</ItemInt>
                        ))}
                    </ItemsInterests>
                </CardSummary>

                <CardSummary>
                    <HeaderCard>
                        <TitleCardS>Publications, Podcasts, & Social Media</TitleCardS>
                        <ButtonEdit onClick={() => handleTabActive(1)}>Edit</ButtonEdit>
                    </HeaderCard>
                    <ItemsInterests>
                        {(userPublications || []).map((p) => (
                            <CellLink href={p.link} target="_blank" rel="noopener noreferrer">{p.name}</CellLink>
                        ))}
                        {(userPodcasts || []).map((p) => (
                            <ItemTable>
                                {p.name}
                                <TableMediaLinkCellIcons>
                                    {p.spotifyLink && (
                                        <IconClicker
                                            color="#000"
                                            icon={faSpotify}
                                            onClick={() => window.open(p.spotifyLink, '_blank', 'rel=noopener noreferrer')}
                                        />
                                    )}
                                    {p.appleLink && (
                                        <IconClicker
                                            color="#000"
                                            icon={faApple}
                                            onClick={() => window.open(p.appleLink, '_blank', 'rel=noopener noreferrer')}
                                        />
                                    )}
                                    {p.otherLink && (
                                        <IconClicker
                                            color="#000"
                                            icon={faLink}
                                            onClick={() => window.open(p.otherLink, '_blank', 'rel=noopener noreferrer')}
                                        />
                                    )}
                                </TableMediaLinkCellIcons>
                            </ItemTable>
                        ))}
                    </ItemsInterests>
                </CardSummary>

            </ContentSummary>
        </Scroll_Content_Signup>
    )
}

const Location = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: initial;
    padding: 4px 0;
    color: black;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
    }
`

const SeeMore = styled.div`
    font-family: 'Inter-Bold';
    font-size: 14px;
    line-height: 17px;
    color: var(--absolute-zero);
    text-align: left;
    cursor: pointer;
`

const Description = styled.p`
    text-align: left;
    margin-top: 2px;
    font-family: 'Inter';
    font-size: 14px;
    color: black;
    line-height: 17px;
    width: 100%;
    height: 100%;

    overflow: scroll;
    box-sizing: border-box;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    -webkit-line-clamp: ${({ lines }: { lines: number }) => lines};
`

const TableMediaLinkCellIcons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;

const SocialMedia = styled.div`
    display: flex;
    gap: 8px;
`
const IconMedia = styled.img`
    width: 17px;
    height: 17px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    cursor: pointer;
`

export const CardSummary = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    background-color: white;
    padding: 12px;
    width: 100%;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    overflow: hidden;

    &.active{
        height: max-content;
    }

    &.maxHeight{
        height: 325px;
    }

    @media only screen and (max-width: 500px) {
        height: max-content;
    }
`;

const ContentSummary = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    width: 100%;
    height: 100%;
`

const IconDrop = styled.img`
    width: 18px;
    margin-left: 15px;
`

const ItemTable = styled.div`
    font-family: 'Inter';
    display: flex;
    align-items: center;
    padding: 10px 0px 10px 0;
    border-bottom: 1px solid var(--simple-gray);
    width: 100%;
    gap: 10px;
    justify-content: space-between;
`

const CellLink = styled.a`
    color: blue;
    font-family: 'Inter';
    display: flex;
    align-items: center;
    padding: 10px 0px 10px 0;
    border-bottom: 1px solid var(--simple-gray);
    width: 100%;
    text-align: left;
`;

const ItemsInterests = styled.div`
    border-radius: 7px;
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    padding: 7px 9px;
    overflow: scroll;
    height: 80%;
    align-content: flex-start;
`

const ItemInt = styled.div`
    background-color: #F5EBE1;
    padding: 10px;
    border-radius: 21px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 7px;
    margin-right: 12px;
    height: fit-content;
`

const CardDetail = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding: 10px 0;
    overflow: hidden;
`

const AvatarInfo = styled.img`
    width: 48px;
    height: 48px;
    min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
    border-radius: 50%;
    object-fit: cover;
    flex: none;
`

const HeaderCard = styled.div`
display:flex;
justify-content: space-between;`

const TitleCardS = styled.div`font-family: 'Inter-Bold';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;`

const ButtonEdit = styled.div`
font-family: 'Inter-Bold';
font-size: 14px;
line-height: 19px;
color: var(--absolute-zero);
cursor:pointer;`

const Row1 = styled.div`
    padding: 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
`

const Profile = styled.div`background-color: white;
    padding: 12px;
    width: 49%;
    border-radius: 8px;
    margin: 17px 0 0px 0;
    height: 150px;

    display: flex;
    flex-direction: column;
`

const InterestsList = styled.div`
    background-color: white;
    padding: 12px;
    width: 49%;
    border-radius: 8px;
    margin: 17px 0 0px 0;
    height: 150px;
`

const CommunitiesC = styled.div`background-color: white;
padding: 12px;
width: 49%;
border-radius: 8px;
margin: 17px 0 0 0;
height: 280px;`

const ConnectionsC = styled.div`background-color: white;
padding: 12px;
width: 49%;
border-radius: 8px;
margin: 17px 0 0 0;
height: 280px;`


const IconButtonFtr = styled(FontAwesomeIcon)`
    padding: 0 10px
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: black;
    margin-right: 7px;
`;

const ListCommRw = styled.div`
    width: 100%;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow-y: auto;
`

export default Summary