import { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faComment } from "@fortawesome/pro-solid-svg-icons";
import { WHITE } from "../ClubIconsNavBar";
import './styles.scoped.css'
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { useDispatch, useSelector } from "react-redux";
import { cancelFriendRequest, createFriendRequest, saveNewFriend, rejectFriendRequest } from '../../store/actions/connectionActions';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useGrades from "../../hooks/useGrades";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { UserConnection } from "@vibe/sdk/dist/interfaces/Roster/getUserConnections";
import { RootState } from "../../store/store";
import { IUserGradeResponse } from "@vibe/sdk/interfaces/Admin/User";
import { getUserInfoProfile } from "../../store/actions/userActions";
import ButtonSpinner from "../BaseComponents/ButtonSpinner";

export type ConnectionAction = 'ACCEPT' | 'REJECT' | 'CANCEL' | 'REQUEST' | 'DELETE';

function Index({
    info,
    showModal,
    onHide,
    handleButtons,
    loadingAction,
}: {
    info: UserConnection
    showModal: boolean
    onHide: () => void
    handleButtons: (data: ConnectionAction, username: string) => void
    loadingAction: boolean
}) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userProfile: IUserGradeResponse = useSelector((state: RootState) => state.getUser.userProfile);

    const onClickMyConnection = () => {
        if (info.role.includes('HEALER')) navigate(`../../../../h/${info.username}`)
        else navigate(`../../../../app/connections/${info.username}`)
        onHide()
    }

    const getCountClubs = () => {
        return info.mutualClubs === 1 ?
            (info.mutualClubs || 0) + ' ' + t('connections__mutualClub')
            :
            (info.mutualClubs || 0) + ' ' + t('connections__mutualClubs')
    }

    const handle = () => {
        let data: ConnectionAction = 'ACCEPT';

        if (info.relation.includes('FRIEND')) data = 'DELETE';
        if (info.relation.includes('PENDING')) data = 'REJECT';
        if (info.relation.includes('REQUESTED')) data = 'CANCEL';
        if (info.relation.length === 0) data = 'REQUEST';

        handleButtons(data, info.username)
    }

    const getLabel = () => {
        if (info.relation.includes('FRIEND')) return 'Delete Friend';
        if (info.relation.includes('PENDING')) return 'Reject';
        if (info.relation.includes('REQUESTED')) return 'Cancel Request';
        return 'Add Friend';
    }

    useEffect(() => {
        dispatch(getUserInfoProfile(info.username || ''));
    }, [info]);

    return (
        <>
            <Modal className="modalConnection" show={showModal} onHide={onHide}>
                <Modal.Body>
                    <FontAwesomeIcon
                        icon={faTimes}
                        size='xl'
                        color={'var(--t-blue)'}
                        className="icon-times"
                        onClick={(e) => onHide()}
                    />
                    <HiOutlineChatAlt2
                        color={"var(--t-blue)"}
                        size="30px"
                        className="icon-comment"
                        onClick={(e) => {
                            navigate(`../../../../chat/conversation/${info.username}`)
                            onHide()
                        }}
                    />
                    <div className="imageProfile">
                        <img
                            className="round-avatar"
                            src={info.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt=""
                        />
                    </div>
                    <div className="nameFriend" onClick={() => onClickMyConnection()}>
                        <h2>{info.name}</h2>
                    </div>
                    <div className="infoCommonClub">
                        <h2>{getCountClubs()}</h2>
                    </div>
                    {(userProfile.interests || []).length > 0 &&
                        <div className="infoTabs">
                            {(userProfile.interests || []).map((tag: string) => (
                                <span>#{tag} </span>
                            ))}
                        </div>
                    }
                    {info.relation.includes('FRIEND') && (
                        <div className="nameClass">
                            <FontAwesomeIcon
                                icon='star'
                                size='xl'
                                color={'var(--t-blue)'}
                            />
                            You're friends!
                        </div>
                    )}
                    <div className="buttonsConnections">
                        {info.relation.includes('PENDING') && (
                            <ButtonSpinner
                                loading={loadingAction}
                                handleClick={() => handleButtons('ACCEPT', info.username)}
                                text={'Accept'}
                                hasIcon={false}
                            />
                        )}
                        <ButtonSpinner
                            loading={loadingAction}
                            handleClick={handle}
                            text={getLabel()}
                            hasIcon={false}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Index;
