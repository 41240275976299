import { AnyAction } from "redux";
import {
  START_GET_TCATEGORIES,
  GET_TCATEGORIES_SUCCESS,
  GET_TCATEGORIES_ERROR,
  LOGOUT_TEMPLATE_CATEGORY,
} from "../constants/templateCategoryConstants";

import TemplateCategoryStateInterface from "../../interfaces/store/TemplateCategoryState";

const initialState: TemplateCategoryStateInterface = {
    loadingTCategories: false,
    TCategoriesError: undefined,
    TCategories: [],
};

export const getTemplateCategoriesReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_GET_TCATEGORIES:
        return {
            ...state,
            loadingGetTCategories: true,
        }
    case GET_TCATEGORIES_SUCCESS:
        return {
            ...state,
            loadingGetTCategories: false,
            TCategories: action.payload,
        }
    case GET_TCATEGORIES_ERROR:
        return {
            ...state,
            loadingGetTCategories: false,
            TCategoriesError: action.payload,
        }

    case LOGOUT_TEMPLATE_CATEGORY:
      return initialState;

    default:
      return state;
  }
};
