import styled from "styled-components";
import { ContentFlexAdmin } from "./HealersView";
import AddPhotoPencil from "../../BaseComponents/ProfilePhoto/AddPhotoPencil";
import { IOrganizationExtended } from "@vibe/sdk/interfaces/Admin/Organization";
import { IUserGradeResponse } from "@vibe/sdk/interfaces/Admin/User";
import UserSocialMedia from "@vibe/sdk/interfaces/Roster/userSocialMedia";
import { useState } from "react";
import { useSelector } from "react-redux";
import { cleanHTML, cleanStyleHTML } from "../../../helpers/letterHelper";
import { getRolesUsersView } from "../../../helpers/roleHelper";
import { RootState } from "../../../store/store";
import { BaseFieldState } from "../../BaseComponents/utils";
import { LogoInterface } from "../../signupsetuphealer/Introduction";
import StatusBudged, { StatusType } from "../../BaseComponents/Healers/StatusBudged";

const HealerDescription = ({
    logoOrg,
    setLogoOrg
}:{
    logoOrg: BaseFieldState<LogoInterface>
    setLogoOrg: (data: BaseFieldState<LogoInterface>) => void
}) => {

    const orgProfile: IOrganizationExtended = useSelector((state: RootState) => state.getOrgs.orgHealerProfile);
    const userProfile: IUserGradeResponse = useSelector((state: RootState) => state.getUser.userProfile);

    const [seeMore, setSeeMore] = useState(false);

    const getImageMedia = (social: UserSocialMedia): string => {
        console.log(social.platform)
        switch (social.platform) {
            case 'Facebook':
                return '../../../facebook-f.svg';
            case 'YouTube':
                return '../../../youtube.svg';
            case 'Instagram':
                return '../../../instagram.svg';
            case 'Twitter':
                return '../../../twitter.svg';
            case 'LinkedIn':
                return '../../../linkedin.svg';
            case 'Pinterest':
                return '../../../pinterest.svg';
            case 'Snapchat':
                return '../../../snapchat.svg';
            case 'TikTok':
                return '../../../tiktokicon.svg';
            case 'Zaahah':
                return '../../../Zaahah.svg';
            case 'Personal':
                return '../../../globe-solid.svg'
            case 'Substack':
                return '../../../substack.svg'
            default:
                return '';
        }
    }

    return (<ContentFlexAdmin>
        <FlexRowWrapper>
            <AddPhotoPencil
                logo={logoOrg}
                setLogo={setLogoOrg}
                hidePen
            />
            <FlexColumnWrapper>
                <Title>{orgProfile?.name || ''}</Title>
                <DivOrgStatement>
                    <PlaceInfoStatement
                        theme={{ main: seeMore ? 'activeMore' : '' }}
                        dangerouslySetInnerHTML={{ __html: cleanStyleHTML(orgProfile?.mission as string || userProfile?.biography as string || '') }}
                    />
                    {orgProfile?.mission &&
                        <ReadMore onClick={() => setSeeMore(!seeMore)}>{seeMore ? 'Read Less' : 'Read More'}</ReadMore>
                    }
                </DivOrgStatement>
            </FlexColumnWrapper>
        </FlexRowWrapper>
        <FlexRowWrapper top>
            <FlexColumnWrapper>
                <Title>{orgProfile.type === 'INDEPENDENT' ? 'Healer Information' : 'Organization Information'}</Title>
                <FlexRowWrapper>
                    <Subtitle>ID</Subtitle>
                    {orgProfile?.id || ''}
                </FlexRowWrapper>
                <FlexRowWrapper>
                    <Subtitle>Status</Subtitle>
                    <StatusBudged status={orgProfile?.status as StatusType || ''}/>
                </FlexRowWrapper>
                <FlexRowWrapper>
                    <Subtitle>Website</Subtitle>
                    {orgProfile?.website || ''}
                </FlexRowWrapper>
                <FlexRowWrapper>
                    <Subtitle>Social Media</Subtitle>
                    <SocialMedia>
                        {(userProfile?.sm || []).map(social => (
                            <a href={social.link} target="_blank" rel="noopener noreferrer">
                                <IconMedia src={getImageMedia(social)} />
                            </a>
                        ))}
                    </SocialMedia>
                </FlexRowWrapper>
                <FlexRowWrapper>
                    <Subtitle>Address</Subtitle>
                    {orgProfile?.address || ''}
                </FlexRowWrapper>
                <FlexRowWrapper>
                    <Subtitle>City/ ST Region</Subtitle>
                    {orgProfile?.city || orgProfile?.state || ''}
                </FlexRowWrapper>
                <FlexRowWrapper>
                    <Subtitle>Zip Code</Subtitle>
                    {orgProfile?.zipcode || ''}
                </FlexRowWrapper>
            </FlexColumnWrapper>

            <FlexColumnWrapper>
                <Title>Primary Contact Information</Title>
                <FlexRowWrapper>
                    <Subtitle>Title</Subtitle>
                    {orgProfile.type === 'INDEPENDENT' ? 'Independent Healer' : 'Administrator'}
                </FlexRowWrapper>
                <FlexRowWrapper>
                    <Subtitle>Name</Subtitle>
                    {orgProfile.type === 'INDEPENDENT' ? `${userProfile.firstname} ${userProfile.lastname}` : orgProfile?.primaryContact?.name}
                </FlexRowWrapper>
                <FlexRowWrapper>
                    <Subtitle>Phone</Subtitle>
                    {orgProfile.type === 'INDEPENDENT' ? (userProfile?.phoneNumber || '') : orgProfile?.primaryContact?.phoneNumber}
                </FlexRowWrapper>
                <FlexRowWrapper>
                    <Subtitle>Email</Subtitle>
                    {orgProfile.type === 'INDEPENDENT' ? (userProfile?.email || '') : orgProfile?.primaryContact?.email}
                </FlexRowWrapper>
            </FlexColumnWrapper>
        </FlexRowWrapper>
    </ContentFlexAdmin>)
}

export default HealerDescription;

const Title = styled.div`
    font-weight: 600;
`;

const Subtitle = styled.div`
    color: var(--simple-gray);
`;

const SocialMedia = styled.div`
    display: flex;
    gap: 16px;
    //border-left: 1px solid black;
    padding: 0 11px;
    padding-left: 0px;
`
const IconMedia = styled.img`
    width: 17px;
    height: 17px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    cursor: pointer;
`

const DivOrgStatement = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const ReadMore = styled.div`
    font-family: 'Inter-Bold';
    font-size: 14px;
    line-height: 17px;
    color: var(--absolute-zero);
    text-align: left;
    cursor: pointer;
`

export const FlexRowWrapper = styled.div`
    display: flex;
    gap: 15px;
    width: 100%;
    align-items: ${({top = false}:{top ?: boolean}) => top ? 'flex-start' : 'center'};
    justify-content: flex-start;
`;

export const FlexColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
`;

const PlaceInfo = styled.div`
    align-items: center;
    display: flex;
    height: 39px;
    width: 100%;

    font-family: 'Inter';
    font-size: 16px;
    font-weight: normal;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    text-align: left;
`;

const PlaceInfoStatement = styled(PlaceInfo).attrs(
    props => ({
        className: props.theme.main,
    })
)`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    height: 100%;
    min-height: 39px;
    max-height: 111px;

    overflow: scroll;
    white-space: normal;
    line-height: 22px;

    &.activeMore{
        min-height: auto;
        max-height: max-content;
        display: block;
    }

`;